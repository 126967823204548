// This file has been generated from MatterMailToDto.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.Domain.Settings.Setting.Item.MatterMailToDto
export class MatterMailToDto {

	// ReferenceFormat
	referenceFormat: string;

	constructor() {

	}
}