// This file has been generated from XeroTenant.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.Domain.Settings.BillSettings.Common.XeroTenant
export class XeroTenant {

	// XeroTenantId
	xeroTenantId: string;
	// XeroTenantName
	xeroTenantName: string;
	// XeroTenantShortCode
	xeroTenantShortCode: string;
	// XeroTenantType
	xeroTenantType: string;

	constructor() {

	}
}