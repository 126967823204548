// This file has been generated from PaymentTermsDto.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.Domain.Settings.BillSettings.Item.PaymentTermsDto
export class PaymentTermsDto {

	// PaymentTermDays
	paymentTermDays: number;

	constructor() {

	}
}