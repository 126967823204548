import { Injectable } from '@angular/core';

import { interval, of } from 'rxjs';
import { catchError, debounce, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { BillsService } from '@common/services/bills.service';
import { IAppState } from '../../app.state';
import { BillListActions, insertRecords, loading, removeRecords, updateRecords } from './bill-list.actions';
import { EntityChangedEventService } from '../../entity-change-event.service';

@Injectable()
export class BillListEffects {
	loadBills$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				BillListActions.Load,
				BillListActions.SetPageIndex,
				BillListActions.SetPageSize,
				BillListActions.SetSortBy,
				BillListActions.SetSortDirection,
				BillListActions.SetFilters
			),
			tap(action => this.store.dispatch(loading())),
			withLatestFrom(this.store),
			debounce(() => interval(200)),
			switchMap(([action, state]) => {
				return this.billsService.getBillList(state.billList.request).pipe(
					map(response => {
						return { type: BillListActions.LoadSuccess, response };
					}),
					catchError(error => of({ type: BillListActions.LoadFailed, error }))
				);
			})
		)
	);

	billsChanged$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(insertRecords, updateRecords, removeRecords),
				tap(action => {
					this.entityChangedEventService.fireEntityChangedEvent(action.response);
				})
			),
		{ dispatch: false }
	);

	constructor(
		private actions$: Actions,
		private store: Store<IAppState>,
		private billsService: BillsService,
		private entityChangedEventService: EntityChangedEventService
	) {}
}
