import { isNil } from 'lodash-es';

// rounds to given decimal places, or 2 decimals by default
export function round(num: number, decimalPlaces: number = 2): number {
	if (isNil(num)) throw new Error('Number must have a value');
	if (num === 0) return 0;

	// Using toFixed(2) introduces rounding errors when you have exactly half a cent (e.g $1,045.1250000)
	// since JavaScript uses floating point numbers it cannot accurately handle exact numbers. This results
	// in unpredictable rounding (sometime up and sometimes down, depending on the number)
	// To resolve this we use a method suggested here: http://www.jacklmoore.com/notes/rounding-in-javascript/
	// by converting it to exponential notation and then extract the precision we wanted.
	const numAsExp = Math.round(Number(`${num}e${decimalPlaces}`));
	return Number(`${numAsExp}e-${decimalPlaces}`);
}
