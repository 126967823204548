<mat-icon *ngIf="showViewer" throttleButton (throttledClick)="openInExternalWindow()">open_in_new</mat-icon>
<pdfjs-viewer
	*ngIf="showViewer"
	#pdfViewerOnDemand
	[externalWindow]="false"
	[fullScreen]="true"
	[downloadFileName]="previewDto.title"
	[openFile]="false"
	[viewBookmark]="false"
	[download]="false"
></pdfjs-viewer>

<pdfjs-viewer
	*ngIf="showViewer && popoutPDF"
	#pdfViewerExternalWindow
	[externalWindow]="true"
	[fullScreen]="true"
	[downloadFileName]="previewDto.title"
	[openFile]="false"
	[viewBookmark]="false"
	[download]="false"
></pdfjs-viewer>

<div class="pdfContainer" *ngIf="isLargeFile && !showViewer">
	<div class="progress" *ngIf="currentLoaded > 0 && currentLoaded !== totalSize">
		<mat-progress-bar mode="determinate" [value]="loadedPercentage"> </mat-progress-bar>
		<br />
		<span>Downloaded {{ currentLoaded | bytes }} of {{ totalSize | bytes }}</span>
	</div>

	<div *ngIf="!currentLoaded" class="large-pdf">
		Preview might be slow as the document is large ({{ previewDto.size | bytes }}).
		<br />
		Click the 'Preview' button to preview it anyway.
		<br />
		<br />
		<button mat-raised-button color="primary" throttleButton (throttledClick)="preview()">Preview</button>
	</div>
</div>
