<h2 mat-dialog-title class="title">Security Role Members</h2>
<mat-dialog-content class="flex-column">
	<div class="flex-row">
		<user-lookup
			#addMemberInput
			[FormControl]="addMemberFormControl"
			Placeholder="User"
			Hint="User to add as member to this security role"
			[HasAutofocus]="true"
		></user-lookup>
		<button class="add-user" mat-raised-button color="primary" throttleButton (throttledClick)="addUser()" [disabled]="!canAdd()" title="Add User">
			Add User
		</button>
	</div>
	<mat-table [dataSource]="dataSource" matSort matSortActive="user" matSortDirection="asc">
		<ng-container matColumnDef="user">
			<mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<mat-icon>person</mat-icon><span>{{ row?.name }}</span>
			</mat-cell>
		</ng-container>
		<ng-container matColumnDef="remove">
			<mat-header-cell *matHeaderCellDef></mat-header-cell>
			<mat-cell *matCellDef="let row">
				<button mat-icon-button throttleButton (throttledClick)="remove(row)">
					<mat-icon>delete</mat-icon>
				</button>
			</mat-cell>
		</ng-container>

		<mat-header-row *matHeaderRowDef="['user', 'remove']; sticky: true"></mat-header-row>
		<mat-row *matRowDef="let row; columns: ['user', 'remove']"></mat-row>
	</mat-table>
</mat-dialog-content>
<mat-dialog-actions>
	<div>
		<button mat-raised-button color="primary" [mat-dialog-close]="dataSource.data" [disabled]="!dirty">Save</button>
		<button mat-raised-button mat-dialog-close>Cancel</button>
	</div>
</mat-dialog-actions>
