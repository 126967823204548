import { Component, Input } from '@angular/core';

@Component({
	selector: 'quantity-slider',
	template: `
		<mat-form-field>
			<mat-icon class="decrement-icon" (click)="decrementQuantity()">remove</mat-icon>
			<input matInput type="number" [formControl]="control" autocomplete="off" [min]="allowZero ? 0 : 1" />
			<mat-icon class="increment-icon" (click)="incrementQuantity()">add</mat-icon>
			<mat-placeholder>{{ placeholder }}</mat-placeholder>
			<mat-error>
				<error-messages [for]="control"></error-messages>
			</mat-error>
		</mat-form-field>
	`,
	styleUrls: ['./quantity-slider.component.scss']
})
export class QuantitySliderComponent {
	@Input()
	control: FormControl;
	@Input()
	placeholder: string;
	@Input()
	allowZero: boolean = false;

	decrementQuantity() {
		let quantity = this.control.value;

		if (!quantity || quantity === 1 || quantity === 0) {
			this.control.setValue(this.allowZero ? 0 : 1);
		} else this.control.setValue(--quantity);
	}
	incrementQuantity() {
		let quantity = this.control.value;
		if (!quantity) this.control.setValue(1);
		else this.control.setValue(++quantity);
	}
}
