<mat-card>
	<div class="page-path">
		<section><a [routerLink]="['..']">System</a> / Activity Log</section>
		<h1>Activity Log</h1>
	</div>

	<activity-log-list-filter [config]="config"></activity-log-list-filter>
	<filter-mobile-span [filterRoot]="filter.root" [filterParams]="config" [filterComponent]="filterDialog"></filter-mobile-span>
	<activity-log-list #list [filterComponent]="filter.root"></activity-log-list>
</mat-card>
