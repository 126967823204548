import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import AwaitLock from 'await-lock';
import * as moment from 'moment-timezone';

import { BaseDocumentsService } from '../services/documents.service';
import { ClearSystemTagsAction, SetSystemTagsAction } from '../state/actions/system-tags-data.actions';
import { ISystemTagsData } from '../state/models/system-tags-data';
import { getSystemTags } from '../state/reducers/system-tags-data.reducer';
import { arraysEqual } from '../utils/arrayUtils';
import { locked, lockedObservable } from '../utils/lockUtils';

@Injectable({
	providedIn: 'root'
})
export class SystemTagsCachedService {
	private lock = new AwaitLock();

	constructor(private documentsService: BaseDocumentsService, private store: Store<ISystemTagsData>) {}

	getSystemTagsByMatter(matterIds: string): Observable<string[]> {
		return lockedObservable(this.lock, () => {
			return this.store.select(getSystemTags).pipe(
				take(1),
				switchMap((data: ISystemTagsData) => {
					if (this.isExpiredOrDifferent(data, null, matterIds)) {
						return this.fetchAndStoreTagsByMatterId(matterIds);
					}

					return of(data?.tags ?? []);
				})
			);
		});
	}

	getSystemTagsByPracticeAreas(practiceAreaIds: string[]): Observable<string[]> {
		return lockedObservable(this.lock, () => {
			return this.store.select(getSystemTags).pipe(
				take(1),
				switchMap((data: ISystemTagsData) => {
					if (this.isExpiredOrDifferent(data, practiceAreaIds, null)) {
						return this.fetchAndStoreTagsByPracticeAreas(practiceAreaIds);
					}

					return of(data?.tags ?? []);
				})
			);
		});
	}

	clearCache(): Observable<void> {
		return locked(this.lock, () => {
			this.store.dispatch(new ClearSystemTagsAction());
		});
	}

	private isExpiredOrDifferent(data: ISystemTagsData, practiceAreaIds: string[], matterId: string): boolean {
		return (
			!data?.tags || // Tags is null or undefined
			!data?.expiry ||
			!!moment().isSameOrAfter(data.expiry) || // Expired
			!arraysEqual(data?.practiceAreaIds, practiceAreaIds) ||
			data?.matterId !== matterId
		); // Different
	}

	private fetchAndStoreTagsByMatterId(matterId: string): Observable<string[]> {
		return this.documentsService.getSystemAndMatterTags({ matterId }).pipe(
			switchMap((tags: string[]) => {
				this.store.dispatch(
					new SetSystemTagsAction({
						tags,
						practiceAreaIds: null,
						matterId,
						expiry: moment().add(3, 'seconds')
					})
				);

				return of(tags ?? []);
			})
		);
	}

	private fetchAndStoreTagsByPracticeAreas(practiceAreaIds: string[]): Observable<string[]> {
		return this.documentsService.getSystemTags({ practiceAreaIds }).pipe(
			switchMap((tags: string[]) => {
				this.store.dispatch(
					new SetSystemTagsAction({
						tags,
						practiceAreaIds,
						matterId: null,
						expiry: moment().add(3, 'seconds')
					})
				);

				return of(tags ?? []);
			})
		);
	}
}
