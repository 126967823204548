<mat-form-field [style.font-size]="FontSize" [floatLabel]="!!floatLabel ? 'auto' : 'never'">
	<mat-placeholder
		>{{ Placeholder }}
		<sup class="color-warn" *ngIf="Required">*</sup>
	</mat-placeholder>
	<mat-hint>{{ Hint }}</mat-hint>
	<input
		matInput
		#autoCompleteInput
		[matAutocomplete]="auto"
		[formControl]="inputDisplayCtrl"
		[value]="inputDisplayCtrl.value"
		(change)="inputChanged($event)"
		[autofocus]="HasAutofocus"
		(keyup.esc)="escPressed()"
		(keydown.tab)="tabPressed()"
		(focus)="inputFocused($event)"
		autocomplete="off"
	/>
	<mat-error>
		<error-messages [for]="FormControl"></error-messages>
	</mat-error>

	<mat-autocomplete class="lookup-results" #auto="matAutocomplete" (optionSelected)="autocompleteSelected($event)">
		<mat-option *ngIf="!!inputDisplayCtrl.value && !!deferredContactOptionValue" [value]="deferredContactOptionValue">
			<i>{{ getDisplayName(deferredContactOptionValue) }} (Pending)</i>
		</mat-option>
		<mat-option *ngFor="let option of options" [value]="option.id">
			<div class="contact-option">
				<div class="contact-title">
					<div *ngIf="!!showMatterRole && !!option.matterRole" class="contact-label">{{ option.matterRole }}:</div>
					<div class="contact-name">{{ option.fullName }}</div>
				</div>

				<div *ngIf="option.additionalInfo" class="contact-info">{{ option.additionalInfo }}</div>
			</div>
		</mat-option>
		<mat-option *ngIf="!!AllowFreeText && !!inputDisplayCtrl.value" id="freeText" [value]="inputDisplayCtrl.value">
			<div class="contact-option contact-option--action">
				<div class="contact-name">Use custom input</div>
				<div class="contact-info">Text will be used instead of a Contact</div>
			</div>
		</mat-option>
		<mat-option [disabled]="true" *ngIf="!!isSearching">
			<div class="searching">Searching...</div>
		</mat-option>
		<mat-option [disabled]="true" *ngIf="!!noMatchesFound">
			<div class="no-matches">No matches found</div>
		</mat-option>
		<div class="create-contact" *ngIf="IsAllowedToCreate && inputDisplayCtrl.value">
			<a href="" throttleButton (throttledClick)="openDialog($event)">Create New Contact</a>
		</div>
	</mat-autocomplete>
</mat-form-field>
