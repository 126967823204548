import { createAction, props } from '@ngrx/store';

import { ListResponse } from '../../models/Generic/ListResponse';
import { JobViewDto } from '../../models/Settings/Jobs/Item/JobViewDto';
import { JobListItemDto } from '../../models/Settings/Jobs/List/JobListItemDto';

export enum JobActions {
	LoadList = '[Jobs] LoadList',
	ListLoadedSuccess = '[Jobs] List Loaded Success',
	ListLoadedError = '[Jobs] List Loaded Error',
	UpdateJob = '[Jobs] UpdateJob',
	DeleteJob = '[Jobs] DeleteJob'
}

export const listLoadedSuccess = createAction(
	JobActions.ListLoadedSuccess,
	props<{ list: ListResponse<JobListItemDto> }>()
);
export const updateJob = createAction(JobActions.UpdateJob, props<{ job: JobViewDto }>());
export const deleteJob = createAction(JobActions.DeleteJob, props<{ id: string }>());
