import { Pipe, PipeTransform } from '@angular/core';
import { TaxConfiguration } from '@common/models/Settings/BillSettings/Common/TaxConfiguration';

/*
 * Resolve tax configuration display string by the enum key
 */
@Pipe({ name: 'taxConfiguration' })
export class TaxConfigurationPipe implements PipeTransform {
	transform(key: keyof typeof TaxConfiguration): string {
		if (!TaxConfiguration[key]) {
			throw Error(`Dev error: The TaxConfiguration enum does not contain the key ${key}`);
		}
		return TaxConfiguration[key];
	}
}
