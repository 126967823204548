<h2 mat-dialog-title class="title">Add to Brief</h2>
<mat-dialog-content [formGroup]="form">
	<span *ngIf="briefs?.length">
		Please select a Brief below to add <b>{{ data?.documentIds?.length }}</b> document{{ data?.documentIds?.length > 1 ? 's' : '' }}.
	</span>
	<div *ngIf="!!briefs?.length" class="no-section-desc">If a section is not selected, the document will be added to the uncategorised section</div>

	<span *ngIf="!briefs?.length">There are no briefs on the matter yet.</span>

	<mat-radio-group formControlName="briefId" class="brief-select">
		<div class="flexRow" *ngFor="let brief of briefs">
			<mat-radio-button #briefRadio [value]="brief.id">
				<div class="embedded-number" title="Number">Brief : {{ brief.name }}</div>
			</mat-radio-button>

			<mat-form-field *ngIf="briefRadio.checked">
				<mat-placeholder> Section </mat-placeholder>
				<mat-select #costdropdown formControlName="sectionId">
					<mat-option *ngFor="let section of sections" [value]="section.id">{{ section.name }}</mat-option>
				</mat-select>
				<mat-hint>Select a section</mat-hint>
				<mat-error> <error-messages [for]="form.get('sectionId')"></error-messages> </mat-error>
			</mat-form-field>
		</div>
	</mat-radio-group>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-raised-button color="primary" throttleButton (throttledClick)="associateBrief()" [disabled]="form.invalid" *ngIf="briefs?.length">Ok</button>
	<button mat-raised-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
