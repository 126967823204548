<mat-form-field>
	<mat-placeholder>{{ placeHolder }} <sup *ngIf="required" class="color-warn">*</sup> </mat-placeholder>
	<textarea
		#textAreaInput
		[autofocus]="HasAutofocus"
		(keyup)="onManualTyping($event)"
		[formControl]="control"
		matInput
		cdkTextareaAutosize
		[cdkAutosizeMinRows]="4"
	></textarea>

	<mat-icon
		*ngIf="isSpeechRecognitionSupported"
		[matTooltip]="isSpeechRecognitionOn ? 'Stop' : 'Turn On Microphone'"
		class="speech-icon rec"
		[ngClass]="{ recording: isSpeechRecognitionOn }"
		matSuffix
		throttleButton
		(throttledClick)="toggleMic()"
	>
		{{ isSpeechRecognitionOn ? 'stop_circle' : 'mic' }}
	</mat-icon>

	<mat-icon *ngIf="!recognitionStarted && isSpeechRecognitionOn" matTooltip="There may be an issue with the Microphone. Click here to read more." matSuffix>
		<a href="https://support.mattero.com.au/hc/en-us/articles/4408113243289" target="_blank"> warning </a>
	</mat-icon>
	<mat-error>
		<error-messages [for]="control"></error-messages>
	</mat-error>
</mat-form-field>
