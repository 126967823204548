<div class="field-container">
	<span class="help-text">The below list of fields are available for use in your document templates </span>

	<div class="flex-row field-filters">
		<div class="field-search">
			<mat-form-field>
				<mat-placeholder>Search</mat-placeholder>
				<input [formControl]="form.get('search')" matInput autocomplete="off" />
				<button
					*ngIf="!!form.get('search')?.value"
					matSuffix
					mat-icon-button
					aria-label="Clear"
					throttleButton
					(throttledClick)="form.get('search')?.setValue(null)"
				>
					<mat-icon>close</mat-icon>
				</button>
				<mat-error>
					<error-messages [for]="form.get('search')"></error-messages>
				</mat-error>
			</mat-form-field>
		</div>

		<div class="field-preview">
			<mat-form-field class="field-preview-type">
				<mat-select [formControl]="entityType" placeholder="Preview Entity">
					<mat-option *ngFor="let key of entityTypeKeys" [value]="key">{{ key | templateEntityType }}</mat-option>
				</mat-select>
			</mat-form-field>
			<matter-lookup
				Placeholder="Enter a Matter number or title"
				class="field-preview-entity"
				*ngIf="entityType.value === 'Matter' || entityType.value === 'TrustStatement' || entityType.value === 'FinancialStatement'"
				[FormControl]="matterId"
				Hint="Entering a matter number or title will display the data recorded for that matter within the value column"
			></matter-lookup>
			<contact-lookup
				Placeholder="Enter a Contact name"
				class="field-preview-entity"
				*ngIf="entityType.value === 'Contact'"
				[FormControl]="contactId"
				(Selected)="onContactSelected($event)"
				Hint="Entering a contact name will display the data recorded for that contact within the value column"
			></contact-lookup>
			<mat-form-field *ngIf="entityType.value === 'Receipt'" class="full-width">
				<mat-placeholder>Enter a trust receipt number </mat-placeholder>
				<mat-hint>Entering a receipt number will display the data recorded for that receipt within the value column</mat-hint>
				<input [formControl]="trustReceiptId" matInput autocomplete="off" />
				<mat-error>
					<error-messages [for]="form.get('trustReceiptId')"></error-messages>
				</mat-error>
			</mat-form-field>
			<mat-form-field *ngIf="entityType.value === 'Payment'" class="full-width">
				<mat-placeholder>Enter a trust payment number </mat-placeholder>
				<mat-hint>Entering a payment number will display the data recorded for that payment within the value column</mat-hint>
				<input [formControl]="trustPaymentId" matInput autocomplete="off" />
				<mat-error>
					<error-messages [for]="form.get('trustPaymentId')"></error-messages>
				</mat-error>
			</mat-form-field>
			<mat-form-field *ngIf="entityType.value === 'Deposit'" class="full-width">
				<mat-placeholder>Enter a deposit number </mat-placeholder>
				<mat-hint>Entering a deposit number will display the data recorded for that deposit within the value column</mat-hint>
				<input [formControl]="trustDepositId" matInput autocomplete="off" />
				<mat-error>
					<error-messages [for]="form.get('trustDepositId')"></error-messages>
				</mat-error>
			</mat-form-field>
			<mat-form-field *ngIf="entityType.value === 'Invoice'" class="full-width">
				<mat-placeholder>Enter an invoice number </mat-placeholder>
				<mat-hint>Entering an invoice number will display the data recorded for that invoice within the value column</mat-hint>
				<input [formControl]="invoiceId" matInput autocomplete="off" />
				<mat-error>
					<error-messages [for]="form.get('invoiceId')"></error-messages>
				</mat-error>
			</mat-form-field>
			<brief-lookup
				Placeholder="Enter a Brief name"
				class="field-preview-entity"
				*ngIf="entityType.value === 'BriefTitlePage'"
				[FormControl]="briefId"
				(Selected)="onBriefSelected($event)"
				Hint="Entering a brief name will display the data recorded for that contact within the value column"
			></brief-lookup>
		</div>
	</div>

	<mat-table [dataSource]="previewEntity">
		<ng-container matColumnDef="field">
			<mat-header-cell *matHeaderCellDef>Field</mat-header-cell>
			<mat-cell *matCellDef="let row" class="cell-value">
				<div *ngIf="!isCollectionField(row); else collectionField" class="field">
					{{ buildFieldName(row) }}
				</div>
				<ng-template #collectionField>
					<div [innerHTML]="createEmptyTableForCollection(row) | safeHtml"></div>
				</ng-template>
				<div class="value formatted-field-value" [ngSwitch]="row.type">
					<ng-template [ngSwitchCase]="'String'">
						<ng-container [ngSwitch]="row.currentFormat">
							<ng-template [ngSwitchCase]="'upper'"><div [innerHtml]="row.value | uppercase"></div></ng-template>
							<ng-template [ngSwitchCase]="'lower'"><div [innerHtml]="row.value | lowercase"></div></ng-template>
							<ng-template [ngSwitchCase]="'caps'"><div [innerHtml]="row.value | titlecase"></div></ng-template>
							<ng-template [ngSwitchCase]="'firstCap'"><div [innerHtml]="row.value | capitalize"></div></ng-template>
							<ng-template ngSwitchDefault><div [innerHtml]="row.value"></div></ng-template>
						</ng-container>
					</ng-template>
					<ng-template [ngSwitchCase]="'DateTime'">{{ !!row.currentFormat ? (row.value | moment : row.currentFormat) : row.value }}</ng-template>
					<ng-template ngSwitchDefault><div [innerHtml]="row.value"></div></ng-template>
				</div>
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="type">
			<mat-header-cell *matHeaderCellDef>Data Type</mat-header-cell>
			<mat-cell *matCellDef="let row">{{ row.type.toLowerCase() }}</mat-cell>
		</ng-container>

		<ng-container matColumnDef="format">
			<mat-header-cell *matHeaderCellDef>Format</mat-header-cell>
			<mat-cell *matCellDef="let row" [ngSwitch]="row.type">
				<ng-template [ngSwitchCase]="'DateTime'">
					<mat-form-field>
						<mat-select [(value)]="row.currentFormat">
							<mat-option value=""></mat-option>
							<mat-option *ngFor="let frmt of supportedDateFormat" [value]="frmt">{{ frmt }}</mat-option>
						</mat-select>
					</mat-form-field>
				</ng-template>
				<ng-template [ngSwitchCase]="'String'">
					<mat-form-field>
						<mat-select [(value)]="row.currentFormat">
							<mat-option value=""></mat-option>
							<mat-option *ngFor="let frmt of supportedStringFormat" [value]="frmt">{{ frmt }}</mat-option>
						</mat-select>
					</mat-form-field>
				</ng-template>
				<ng-template [ngSwitchCase]="'Decimal'">
					<mat-form-field>
						<mat-select [(value)]="row.currentFormat">
							<mat-option value=""></mat-option>
							<mat-option *ngFor="let frmt of supportedDecimalFormats" [value]="frmt">{{ frmt }}</mat-option>
						</mat-select>
					</mat-form-field>
				</ng-template>
				<ng-template ngSwitchDefault>
					<mat-form-field>
						<mat-select [(value)]="row.currentFormat">
							<mat-option value=""></mat-option>
							<mat-option *ngFor="let frmt of supportedFormats" [value]="frmt">{{ frmt }}</mat-option>
						</mat-select>
					</mat-form-field>
				</ng-template>
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="value">
			<mat-header-cell *matHeaderCellDef>Value</mat-header-cell>
			<mat-cell *matCellDef="let row" class="formatted-field-value" [ngSwitch]="getFieldType(row)">
				<ng-template [ngIf]="isCollectionField(row)" [ngIfElse]="standard">
					<div style="color: grey">{{ getPreviewMessageForCollection(row) }}</div>
				</ng-template>
				<ng-template #standard>
					<ng-template [ngSwitchCase]="'String'">
						<ng-container [ngSwitch]="row.currentFormat">
							<ng-template [ngSwitchCase]="'upper'"><div [innerHtml]="row.value | uppercase"></div></ng-template>
							<ng-template [ngSwitchCase]="'lower'"><div [innerHtml]="row.value | lowercase"></div></ng-template>
							<ng-template [ngSwitchCase]="'caps'"><div [innerHtml]="row.value | titlecase"></div></ng-template>
							<ng-template [ngSwitchCase]="'firstCap'"><div [innerHtml]="row.value | capitalize"></div></ng-template>
							<ng-template ngSwitchDefault><div [innerHtml]="row.value"></div></ng-template>
						</ng-container>
					</ng-template>
					<ng-template [ngSwitchCase]="'DateTime'">{{
						!!row.currentFormat ? (row.value | moment : transformDateTimeFormat(row.currentFormat)) : row.value
					}}</ng-template>
					<ng-template [ngSwitchCase]="'Decimal'">{{ toMicrosoftFormattedDecimal(row.value, row.currentFormat) }}</ng-template>
					<ng-template ngSwitchDefault><div [innerHtml]="row.value"></div></ng-template>
				</ng-template>
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="copy">
			<mat-header-cell *matHeaderCellDef></mat-header-cell>
			<mat-cell *matCellDef="let row">
				<button mat-icon-button matSuffix color="primary" title="Copy {{ row.name }} to clipboard" throttleButton (throttledClick)="copyField(row)">
					<mat-icon>content_copy</mat-icon>
				</button>
			</mat-cell>
		</ng-container>

		<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
		<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
	</mat-table>

	<mat-paginator #previewPaginator [pageSize]="25" [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
</div>
