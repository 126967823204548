// This file has been generated from ListResponse.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.Domain.Generic.ListResponse
export class ListResponse<T> {

	// TotalRecords
	totalRecords: number;
	// PageIndex
	pageIndex: number;
	// Records
	records: T[];

	constructor() {
		this.records = [];
	}
}