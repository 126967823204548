import { AfterViewInit, Directive, ElementRef, Inject, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatOption, MatOptionSelectionChange } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';

import { Subscription } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

import { CustomFieldEntityType } from '@common/models/Settings/CustomFields/Common/CustomFieldEntityType';
import { CustomFieldType } from '@common/models/Settings/CustomFields/Common/CustomFieldType';
import { ContactCustomFieldCreateDto } from '@common/models/Settings/CustomFields/Item/ContactCustomFieldCreateDto';
import { ContactCustomFieldViewDto } from '@common/models/Settings/CustomFields/Item/ContactCustomFieldViewDto';
import { MatterCustomFieldCreateDto } from '@common/models/Settings/CustomFields/Item/MatterCustomFieldCreateDto';
import { MatterCustomFieldViewDto } from '@common/models/Settings/CustomFields/Item/MatterCustomFieldViewDto';
import { CustomFieldGroupListItemDto } from '@common/models/Settings/CustomFields/List/CustomFieldGroupListItemDto';
import { NotificationService } from '@common/notification';
import { CustomFieldsGroupService } from '@common/services/customfields-group.service';
import { isEmpty, orderBy } from 'lodash-es';

import { FeatureFlags } from 'app/app.config';
import { EditableContactComponent } from 'app/shared/editable-controls/editable-contact.component';
import { formattedMasking } from 'app/shared/utils/stringUtil';

import { CustomFieldContactListConfigComponent } from '../custom-field-list-config/custom-field-contact-list-config.component';
import { CustomFieldListConfigComponent } from '../custom-field-list-config/custom-field-list-config.component';
import { ContactCustomFieldDialogComponent } from './contact-custom-fields/contact-custom-field-dialog/contact-custom-field-dialog.component';
import { ExpressionBuilderDialogComponent } from './expressions/expression-builder-dialog.component';
import { MatterCustomFieldDialogComponent } from './matter-custom-fields/matter-custom-field-dialog/matter-custom-field-dialog.component';

@Directive()
export abstract class BaseCustomFieldDialogComponent<
	TView extends MatterCustomFieldViewDto | ContactCustomFieldViewDto,
	TCreate extends MatterCustomFieldCreateDto | ContactCustomFieldCreateDto,
	TDialog extends MatterCustomFieldDialogComponent | ContactCustomFieldDialogComponent
> implements AfterViewInit, OnInit, OnDestroy
{
	@ViewChild(CustomFieldListConfigComponent, { static: true })
	selectionList: CustomFieldListConfigComponent;

	@ViewChild(CustomFieldContactListConfigComponent, { static: true })
	contactSelectionList: CustomFieldContactListConfigComponent;

	@ViewChild('defaultValueContact', { read: EditableContactComponent })
	defaultValueContactComponent: EditableContactComponent;

	@ViewChild('defaultFocusInput')
	nameInput: ElementRef;

	protected view: TView;

	customFieldGroups: CustomFieldGroupListItemDto[];

	@ViewChild('defaultFocusInput', { static: true }) defaultFocusInput: ElementRef;
	@ViewChild('allOption', { static: true }) allOption: MatOption;
	protected subscriptions = new Subscription();

	form: FormGroup;
	fieldTypes: string[];

	get showListConfig(): boolean {
		return this.form.get('fieldType')?.value === CustomFieldType.List;
	}

	get showContactConfig(): boolean {
		return this.form.get('fieldType')?.value === CustomFieldType.Contact;
	}

	get showExpression(): boolean {
		return this.form?.get('fieldType')?.value == CustomFieldType.Calculated;
	}

	get selectionListArray(): string[] {
		const lol =
			(this.form?.get(CustomFieldContactListConfigComponent.selectionListFieldName)?.value as string[]) ?? [];

		return lol;
	}

	private descriptionSubscription: Subscription;

	defaultMaskingTexts: { label: string; value: string }[] = [];

	featureFlags: typeof FeatureFlags = FeatureFlags;

	constructor(
		@Optional()
		@Inject(MAT_DIALOG_DATA)
		public data: ICreateCustomFieldData,
		private _entityType: keyof typeof CustomFieldEntityType,
		private _notificationService: NotificationService,
		private _groupsService: CustomFieldsGroupService,
		private _dialogRef: MatDialogRef<TDialog>,
		private _dialog: MatDialog,
		private _getView$: (id: string) => Observable<TView>
	) {
		this.fieldTypes = orderBy<string>(
			(Object.keys(CustomFieldType) as Array<keyof typeof CustomFieldType>).map(key => CustomFieldType[key]),
			order => order
		);
	}
	ngOnInit() {
		this.subscriptions.add(
			this.form.get('fieldType').valueChanges.subscribe(() => {
				this.form.get('expression').updateValueAndValidity();
				this.form.updateValueAndValidity();
			})
		);

		this.subscriptions.add(
			this._groupsService.getCustomFieldGroupList({ entityType: this._entityType }).subscribe(response => {
				if (response.records.length === 0) {
					this._dialogRef.close();
					this._notificationService.showError(
						'No groups found',
						'Please create a group before creating a custom field.'
					);
				}
				this.customFieldGroups = response.records;
			})
		);

		if (!!this.data.id) {
			this.subscriptions.add(
				this._getView$(this.data.id)
					.pipe(
						tap(item => {
							this.view = item;

							this.form.reset(item);
							// form.reset doesn't work for arrays, so explicitly initialise the selectionList,
							// older custom field definitions may not include the array
							if (item.selectionList) {
								this.selectionList.updateModel(item.selectionList);
							}

							if (item.contactSelectionList) {
								this.contactSelectionList.updateModel(item.contactSelectionList);
							}

							if (!!item.installationRecordIds?.length) {
								this.nameInput.nativeElement.blur();
								this.form.get('name').disable();
							}

							this.form.get('fieldType').disable();
						}),
						filter(() => !!this.data.openBuilder),
						switchMap(() => this.configureExpression$())
					)
					.subscribe()
			);
		}

		this.descriptionSubscription = this.form.get('name').valueChanges.subscribe((next: string) => {
			if (!isEmpty(next) && (next.indexOf('.') >= 0 || next.indexOf('\n') >= 0)) {
				this.stopUpdatingDescription();
			} else {
				this.form.get('description').setValue(next);
			}
		});

		this.subscriptions.add(
			this.form
				.get('description')
				.valueChanges.pipe(filter(value => value !== this.form.get('name').value))
				.subscribe(() => this.stopUpdatingDescription())
		);

		this.defaultMaskingTexts.push(
			{ label: 'TFN number', value: '### ### ###' },
			{ label: 'Medicare number', value: '#### #### # #' }
		);
	}

	get defaultSelectionList(): { id: string; name: string }[] {
		return this.form.value?.selectionList ?? [];
	}

	ngAfterViewInit() {
		if (this.defaultFocusInput) {
			setTimeout(() => this.defaultFocusInput.nativeElement.focus(), 0);
		}
	}

	ngOnDestroy(): void {
		this.stopUpdatingDescription();

		this.subscriptions.unsubscribe();
	}

	allOptionSelected(event: MatOptionSelectionChange, selectedOption: MatSelect) {
		if (event.source.selected) {
			// When selecting the 'All' option, deselect all practice areas
			selectedOption?.options?.forEach(opt => {
				if (opt.selected && opt.id !== this.allOption.id) {
					opt.deselect();
				}
			});
		} else if (!selectedOption.options.some(opt => opt.selected)) {
			// If nothing is selected, select the 'All' option
			this.allOption.select();
		}
	}

	optionSelected(event: MatOptionSelectionChange, selectedOption: MatSelect) {
		if (this.allOption.selected && event.source.selected && event.source.id !== this.allOption.id) {
			// When selecting a practice area, deselect the 'All' option
			this.allOption.deselect();
		} else if (!!selectedOption && !selectedOption?.options?.some(opt => opt.selected)) {
			// If nothing is selected, select the 'All' option
			this.allOption.select();
		}
	}

	stopUpdatingDescription() {
		if (!!this.descriptionSubscription) {
			this.descriptionSubscription.unsubscribe();
			this.descriptionSubscription = null;
		}
	}

	onContactListSelectionChange() {
		this.defaultValueContactComponent?.updateValueAndValidity();
		this.form.updateValueAndValidity();
	}

	onSaveChanges() {
		let value: TCreate = this.form.value;
		this.saveChanges(value);
	}

	protected saveChanges(value: TCreate) {
		if (!!this.view?.installationRecordIds?.length) {
			value.name = this.view.name;
		}

		if (value.fieldType === CustomFieldType.Calculated) {
			value.mandatory = false;
		}

		if (!this.form.get(CustomFieldContactListConfigComponent.showSelectionListFieldName)?.value) {
			value.contactSelectionList = null;
		}

		this._dialogRef.close(value);
	}

	configureExpression() {
		this.subscriptions.add(this.configureExpression$().subscribe());
	}

	private configureExpression$() {
		const expressionControl = this.form.get('expression');
		return this._dialog
			.open(ExpressionBuilderDialogComponent, {
				maxHeight: '100vh',
				width: '920px',
				height: '920px',
				data: {
					type: this._entityType,
					expression: expressionControl.value,
					entityId: this.data.builderEntityId
				}
			})
			.afterClosed()
			.pipe(filter(Boolean))
			.pipe(tap(expression => expressionControl.setValue(expression)));
	}

	formattedHintSample(pattern: string) {
		if (!pattern) {
			return '';
		}

		const num = 0.123456789123456789123456789123456789123456789;
		const digit = pattern.match(/#/g).length;
		const randomNumber = num.toFixed(digit).split('.')[1];
		return formattedMasking(randomNumber, pattern);
	}
}

export interface ICreateCustomFieldData {
	id: string;
	selectedGroupId: string;
	openBuilder: boolean;
	builderEntityId: string;
}
