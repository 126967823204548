// This file has been generated from MarketplaceController.cs
// Do not modify this file directly!!
// tslint:disable

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { TrimStringsOnObject } from '@common/utils/string-format';

import { PricedMarketplaceListingViewDto } from '../../models/Infrastructure/StripeIntegration/Models/PricedMarketplaceListingViewDto';
import { InstallationRecordLicenceStatus } from '../../models/Marketplace/InstallationRecords/Common/InstallationRecordLicenceStatus';
import { InstallationRecordInstallDetailsSlimDto } from '../../models/Marketplace/InstallationRecords/Item/InstallationRecordInstallDetailsSlimDto';
import { InstallationRecordInstallRequest } from '../../models/Marketplace/InstallationRecords/Item/InstallationRecordInstallRequest';
import { InstallationRecordPackageMetadataDto } from '../../models/Marketplace/InstallationRecords/Item/InstallationRecordPackageMetadataDto';
import { InstallationRecordPracticeAreaInstallRequest } from '../../models/Marketplace/InstallationRecords/Item/InstallationRecordPracticeAreaInstallRequest';
import { InstallationRecordUninstallDetailsSlimDto } from '../../models/Marketplace/InstallationRecords/Item/InstallationRecordUninstallDetailsSlimDto';
import { InstallationRecordUninstallRequest } from '../../models/Marketplace/InstallationRecords/Item/InstallationRecordUninstallRequest';
import { InstallationRecordUpdatePracticeAreasRequest } from '../../models/Marketplace/InstallationRecords/Item/InstallationRecordUpdatePracticeAreasRequest';
import { InstallationRecordLookupDto } from '../../models/Marketplace/InstallationRecords/Lookup/InstallationRecordLookupDto';
import { InstallationRecordLookupRequest } from '../../models/Marketplace/InstallationRecords/Lookup/InstallationRecordLookupRequest';
import { MarketplaceListingListRequest } from '../../models/Marketplace/Listings/List/MarketplaceListingListRequest';
import { ServiceRequestCreateDto } from '../../models/Marketplace/ServiceRequests/Item/ServiceRequestCreateDto';

@Injectable({
	providedIn: 'root'
})
export class MarketplaceService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'api/v1/Marketplace');
	}

	installPackage(request: InstallationRecordPracticeAreaInstallRequest): Observable<any> {
		return this.put(`InstallPackage`, request, true);
	}

	uninstallPackage(request: InstallationRecordUninstallRequest): Observable<any> {
		return this.post<InstallationRecordUninstallRequest, any>(`UninstallPackage`, request, true);
	}

	updatePackagePracticeAreas(request: InstallationRecordUpdatePracticeAreasRequest): Observable<any> {
		return this.post<InstallationRecordUpdatePracticeAreasRequest, any>(`UpdatePackagePracticeAreas`, request, true);
	}

	lookupInstalledRecords(request: InstallationRecordLookupRequest): Observable<InstallationRecordLookupDto[]> {
		return this.getArray<InstallationRecordLookupRequest, InstallationRecordLookupDto>(`lookup`, request);
	}

	lookupInstalledRecord(id: string): Observable<InstallationRecordLookupDto> {
		return this.getItem<void, InstallationRecordLookupDto>(`${id}/lookup`);
	}

	getAllCategories(): Observable<string[]> {
		return this.getArray<void, string>(`GetAllCategories`);
	}

	getActiveListings(request?: Partial<MarketplaceListingListRequest>): Observable<any> {
		return this.getItem<MarketplaceListingListRequest, any>(`GetActiveListings`, request);
	}

	getActiveListing(listingType: string, id: string): Observable<PricedMarketplaceListingViewDto> {
		return this.getItem<void, PricedMarketplaceListingViewDto>(`GetActiveListing/${listingType}/${id}`);
	}

	getInstalledMetadata(): Observable<InstallationRecordPackageMetadataDto> {
		return this.getItem<void, InstallationRecordPackageMetadataDto>(`GetInstalledMetadata`);
	}

	getDetailsForInstallPackage(request: InstallationRecordInstallRequest): Observable<InstallationRecordInstallDetailsSlimDto> {
		return this.getItem<InstallationRecordInstallRequest, InstallationRecordInstallDetailsSlimDto>(`GetDetailsForInstallPackage`, request);
	}

	getDetailsForUninstallPackage(request: InstallationRecordUninstallRequest): Observable<InstallationRecordUninstallDetailsSlimDto> {
		return this.getItem<InstallationRecordUninstallRequest, InstallationRecordUninstallDetailsSlimDto>(`GetDetailsForUninstallPackage`, request);
	}

	validateInstallationRecordLicence(id: string): Observable<keyof typeof InstallationRecordLicenceStatus> {
		return this.getItem<void, keyof typeof InstallationRecordLicenceStatus>(`ValidateInstallationRecordLicence/${id}`);
	}

	submitServiceRequest(request: ServiceRequestCreateDto): Observable<any> {
		return this.post<ServiceRequestCreateDto, any>(`SubmitServiceRequest`, TransformDatesOnObject(ServiceRequestCreateDto, TrimStringsOnObject(request)), true);
	}

}
