import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { CalculateExpressionRequestDto } from '@common/models/Calculations/Item/CalculateExpressionRequestDto';
import { CalculateExpressionResultDto } from '@common/models/Calculations/Item/CalculateExpressionResultDto';
import { EntityCalculationDetailsDto } from '@common/models/Calculations/List/EntityCalculationDetailsDto';
import { EntityReference } from '@common/models/Common/EntityReference';
import { ListResponse } from '@common/models/Generic/ListResponse';
import { ChangeOrderNumberDto } from '@common/models/Settings/CustomFields/Item/ChangeOrderNumberDto';
import { CustomFieldIdsDto } from '@common/models/Settings/CustomFields/Item/CustomFieldIdsDto';
import { DeleteCustomFieldResponseDto } from '@common/models/Settings/CustomFields/Item/DeleteCustomFieldResponseDto';
import { MatterCustomFieldCreateDto } from '@common/models/Settings/CustomFields/Item/MatterCustomFieldCreateDto';
import { MatterCustomFieldUpdateDto } from '@common/models/Settings/CustomFields/Item/MatterCustomFieldUpdateDto';
import { MatterCustomFieldViewDto } from '@common/models/Settings/CustomFields/Item/MatterCustomFieldViewDto';
import { MatterCustomFieldListItemDto } from '@common/models/Settings/CustomFields/List/MatterCustomFieldListItemDto';
import { MatterCustomFieldListRequest } from '@common/models/Settings/CustomFields/List/MatterCustomFieldListRequest';
import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TrimStringsOnObject } from '@common/utils/string-format';
import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';

@Injectable({
	providedIn: 'root'
})
export class MatterCustomFieldsService extends GenericHttpService {
	constructor(httpClient: HttpClient, settingsResolver: SettingsResolverService) {
		super(
			httpClient,
			settingsResolver.getAppServerUrl(),
			'api/v1/settings/CustomFields/matters',
			'Matter Custom Field'
		);
	}

	createMatterCustomField(dto: MatterCustomFieldCreateDto): Observable<MutationResponseDto> {
		return this.post<MatterCustomFieldCreateDto, MutationResponseDto>(``, TrimStringsOnObject(dto));
	}

	changeOrderNumber(dto: ChangeOrderNumberDto): Observable<MutationResponseDto> {
		return this.post<ChangeOrderNumberDto, MutationResponseDto>(`changeOrderNumber`, dto);
	}

	getMatterCustomFieldList(
		request?: Partial<MatterCustomFieldListRequest>
	): Observable<ListResponse<MatterCustomFieldListItemDto>> {
		return this.postGetList<MatterCustomFieldListRequest, MatterCustomFieldListItemDto>(
			`getMatterCustomFieldList`,
			request
		);
	}

	getMatterCustomField(id: string): Observable<MatterCustomFieldViewDto> {
		return this.getItem<void, MatterCustomFieldViewDto>(`${id}`);
	}

	updateMatterCustomField(id: string, dto: MatterCustomFieldUpdateDto): Observable<MutationResponseDto> {
		return this.put(`${id}`, TrimStringsOnObject(dto));
	}

	patchMatterCustomFields(
		ids: string[],
		matterPatch: Partial<MatterCustomFieldUpdateDto>
	): Observable<MutationResponseDto> {
		return this.patchMultiple<MatterCustomFieldUpdateDto, MutationResponseDto>(ids, matterPatch);
	}

	deleteMatterCustomField(id: string): Observable<MutationResponseDto> {
		return this.delete<MutationResponseDto>(`${id}`);
	}

	validateMatterCustomField(id: string): Observable<DeleteCustomFieldResponseDto> {
		return this.getItem<void, DeleteCustomFieldResponseDto>(`ValidateDeleteMatterCustomField/${id}`);
	}

	getMatterCalculationDetails(): Observable<EntityCalculationDetailsDto> {
		return this.getItem<void, EntityCalculationDetailsDto>('details');
	}

	calculateMatterExpression(
		matterId: string,
		request: CalculateExpressionRequestDto
	): Observable<CalculateExpressionResultDto> {
		return this.post<CalculateExpressionRequestDto, CalculateExpressionResultDto>(`${matterId}/calculate`, request);
	}

	enableShowInReports(dto: CustomFieldIdsDto): Observable<void> {
		return this.post<CustomFieldIdsDto, void>(`reports/enable`, TrimStringsOnObject(dto));
	}

	disableShowInReports(dto: CustomFieldIdsDto): Observable<void> {
		return this.post<CustomFieldIdsDto, void>(`reports/disable`, TrimStringsOnObject(dto));
	}
}
