import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { EntityReference } from '@common/models/Common/EntityReference';
import { BasicWorkflowType } from '@common/models/Settings/BasicWorkflows/Common/BasicWorkflowType';
import { CustomFieldEntityType } from '@common/models/Settings/CustomFields/Common/CustomFieldEntityType';
import { CustomFieldType } from '@common/models/Settings/CustomFields/Common/CustomFieldType';
import { TaskTemplateAssignedToContactType } from '@common/models/Settings/TaskTemplates/Common/TaskTemplateAssignedToContactType';
import { TaskTemplateDueDateOffsetType } from '@common/models/Settings/TaskTemplates/Common/TaskTemplateDueDateOffsetType';
import { TaskTemplateDueDateSource } from '@common/models/Settings/TaskTemplates/Common/TaskTemplateDueDateSource';
import { CollectionTaskTemplateCreateUpdateDto } from '@common/models/Settings/TaskTemplates/Item/CollectionTaskTemplateCreateUpdateDto';
import { CollectionTaskTemplateViewDto } from '@common/models/Settings/TaskTemplates/Item/CollectionTaskTemplateViewDto';
import { DocumentMergeTaskTemplateCreateUpdateDto } from '@common/models/Settings/TaskTemplates/Item/DocumentMergeTaskTemplateCreateUpdateDto';
import { DocumentMergeTaskTemplateViewDto } from '@common/models/Settings/TaskTemplates/Item/DocumentMergeTaskTemplateViewDto';
import { GenericTaskTemplateCreateUpdateDto } from '@common/models/Settings/TaskTemplates/Item/GenericTaskTemplateCreateUpdateDto';
import { GenericTaskTemplateViewDto } from '@common/models/Settings/TaskTemplates/Item/GenericTaskTemplateViewDto';
import { CustomFieldGroupSelection } from '@common/models/Tasks/Common/CustomFieldGroupSelection';
import { TaskPriority } from '@common/models/Tasks/Common/TaskPriority';
import { TaskStatus } from '@common/models/Tasks/Common/TaskStatus';
import { TaskType } from '@common/models/Tasks/Common/TaskType';
import { DomainError } from '@common/models/Validation/DomainError';
import { NotificationService } from '@common/notification';
import { MatterCustomFieldsService } from '@common/services/customfields-matter.service';
import { TaskTemplateService } from '@common/services/settings/tasktemplate.service';
import { UserTypesService } from '@common/services/settings/usertypes.service';
import { CustomValidators } from '@common/validation/custom.validators';
import { uniq } from 'lodash-es';

import { FeatureFlags, isFeatureFlagEnabled } from 'app/app.config';
import { ICustomFieldGroupSelection } from 'app/shared/components/custom-fields/custom-field-selection.component';
import { IGrouping, IItem } from 'app/shared/components/grouped-local-lookup.component';

@Component({
	selector: './task-template-item',
	styleUrls: ['./task-template-item.component.scss'],
	templateUrl: './task-template-item.component.html'
})
export class TaskTemplateItemComponent implements OnInit, OnDestroy {
	private data: ITaskTemplateItemComponentData;
	private subscriptions = new Subscription();
	private _practiceAreaIds: string[];

	featureFlags: typeof FeatureFlags = FeatureFlags;

	form: FormGroup;

	statuses: Array<keyof typeof TaskStatus>;
	priorities: Array<keyof typeof TaskPriority>;
	assignedToContactTypes: ({
		name: string;
		items: ({ id: string; display: string; userTypeId?: string } & IItem)[];
	} & IGrouping)[];
	offsetTypes: Array<keyof typeof TaskTemplateDueDateOffsetType>;
	dueDateSources: ({
		name: string;
		items: ({ id: string; display: string; customFieldId?: string } & IItem)[];
	} & IGrouping)[];

	defaultPriorityKey: keyof typeof TaskPriority = 'Normal';
	defaultStatusKey: keyof typeof TaskStatus = 'ToDo';
	defaultAssignedToContactTypeKey: keyof typeof TaskTemplateAssignedToContactType = 'AssignedLawyer';
	selectedContactKey: keyof typeof TaskTemplateAssignedToContactType = 'SelectedContact';
	defaultOffsetTypeKey: keyof typeof TaskTemplateDueDateOffsetType = 'Day';
	defaultDueDateSourceKey: keyof typeof TaskTemplateDueDateSource = 'MatterCreationDate';

	private _type: keyof typeof TaskType;

	get isSelectedContactOptionSelected(): boolean {
		return this.form?.get('assignedToContactOptions')?.get('source')?.value === this.selectedContactKey;
	}

	get isCreateMode(): boolean {
		return !this.data?.model?.id;
	}

	get isSaveFormEnabled(): boolean {
		return this.form?.dirty === true && !this.form?.invalid;
	}

	get isUserTypeOptionSelected(): boolean {
		const value = this.form?.get('assignedToContactOptions')?.get('source').value;

		return !!value && !TaskTemplateItemComponent.NonUserTypeContactSourceTypes.includes(value);
	}

	get isAssignedLawyerOptionSelected(): boolean {
		const value = this.form?.get('assignedToContactOptions')?.get('source').value;

		return !!value && value === this.defaultAssignedToContactTypeKey;
	}

	get type(): keyof typeof TaskType {
		return this._type;
	}

	set type(value: keyof typeof TaskType) {
		this._type = value;
	}

	get customFieldEntityType() {
		return CustomFieldEntityType.Matter;
	}

	get fromCustomFieldGroupIds() {
		return this.form.get('customFieldGroupIds') as unknown as FormArray;
	}

	get customFieldGroupIdControls() {
		return this.fromCustomFieldGroupIds?.controls;
	}

	get fromCustomFieldIds() {
		return this.form.get('customFieldIds') as unknown as FormArray;
	}

	get customFieldIdControls() {
		return this.fromCustomFieldIds?.controls;
	}

	get fromDocumentTemplateIds() {
		return this.form.get('documentTemplateIds') as unknown as FormArray;
	}

	get documentTemplateIdControls() {
		return this.fromDocumentTemplateIds?.controls;
	}

	private static readonly NonUserTypeContactSourceTypes: (keyof typeof TaskTemplateAssignedToContactType)[] =
		Object.keys(TaskTemplateAssignedToContactType)
			.map(flag => flag as keyof typeof TaskTemplateAssignedToContactType)
			.filter(flag => flag !== 'UserType');

	get practiceAreaIds() {
		return this._practiceAreaIds ?? [];
	}

	get selectedCustomFieldGroups(): CustomFieldGroupSelection[] {
		return this.form?.get('selectedCustomFieldGroups')?.value;
	}

	constructor(
		@Inject(MAT_DIALOG_DATA) data: ITaskTemplateItemComponentData,
		private taskTemplateService: TaskTemplateService,
		private matterCustomFieldsService: MatterCustomFieldsService,
		private fb: FormBuilder,
		private notifService: NotificationService,
		private userTypesService: UserTypesService,
		private dialogRef: MatDialogRef<TaskTemplateItemComponent>
	) {
		this.statuses = Object.keys(TaskStatus) as Array<keyof typeof TaskStatus>;
		this.priorities = Object.keys(TaskPriority) as Array<keyof typeof TaskPriority>;

		this.offsetTypes = Object.keys(TaskTemplateDueDateOffsetType) as Array<
			keyof typeof TaskTemplateDueDateOffsetType
		>;

		this.data = Object.assign({}, data);

		this.defaultDueDateSourceKey = data.workFlowType === 'StageChanged' ? 'TriggerDate' : 'MatterCreationDate';
		this._practiceAreaIds = data.practiceAreaIds;
	}

	ngOnInit() {
		if (!!this.data?.model?.taskType) {
			this.type = this.data?.model?.taskType;
		} else if (
			!isFeatureFlagEnabled(FeatureFlags.mergeTask) &&
			!isFeatureFlagEnabled(FeatureFlags.collectionTask)
		) {
			this.type = 'Generic';
		}

		const documentTemplateIds = (this.data?.model as DocumentMergeTaskTemplateViewDto)?.documentTemplateIds || [];

		this.subscriptions.add(
			this.userTypesService.getUserTypeList().subscribe({
				next: response =>
					(this.assignedToContactTypes = [
						{
							name: 'Matter Fields',
							items: Object.keys(TaskTemplateAssignedToContactType)
								.map(flag => flag as keyof typeof TaskTemplateAssignedToContactType)
								.filter(flag => flag !== 'UserType')
								.map(flag => ({
									id: flag,
									display: TaskTemplateAssignedToContactType[flag]
								}))
						} as IGrouping
					].concat([
						{
							name: 'Team Members',
							items: (response?.records ?? []).map(type => ({
								id: `UserType-${type.id}`,
								display: type.name,
								userTypeId: type.id
							}))
						}
					])),
				error: errors => this.notifService.showErrors('Failed to get user types', errors)
			})
		);

		const assignedToContactOptionsSource =
			this.data?.model?.assignedToContactOptions?.source === 'UserType'
				? `UserType-${this.data.model.assignedToContactOptions.userTypeId}`
				: this.data?.model?.assignedToContactOptions?.source;

		this.subscriptions.add(
			this.matterCustomFieldsService
				.getMatterCustomFieldList({
					fieldType: [CustomFieldType.Date],
					practiceAreaIds: this.data?.practiceAreaIds ?? []
				})
				.subscribe({
					next: response =>
						(this.dueDateSources = [
							{
								name: 'Matter Fields',
								items: Object.keys(TaskTemplateDueDateSource)
									.map(flag => flag as keyof typeof TaskTemplateDueDateSource)
									.filter(flag => flag !== 'CustomField')
									.map(flag => ({
										id: flag,
										display: TaskTemplateDueDateSource[flag]
									}))
							} as IGrouping
						].concat([
							{
								name: 'Custom Fields',
								items: (response?.records ?? []).map(field => ({
									id: `CustomField-${field.id}`,
									display: field.description ?? field.name,
									customFieldId: field.id
								}))
							}
						])),
					error: errors => this.notifService.showErrors('Faild to get custom fields', errors)
				})
		);

		const dueDateSource =
			this.data?.model?.dueDateOptions?.dueDateSource === 'CustomField'
				? `CustomField-${this.data.model.dueDateOptions.customFieldId}`
				: this.data?.model?.dueDateOptions?.dueDateSource;

		this.form = this.fb.group({
			basicWorkflowId: this.data?.basicWorkflowRef?.id,
			id: this.data?.model?.id,
			description: [this.data?.model?.description, CustomValidators.required('Description')],
			status: this.data?.model?.status || this.defaultStatusKey,
			priority: this.data?.model?.priority || this.defaultPriorityKey,
			assignedToContactOptions: this.fb.group({
				source: assignedToContactOptionsSource || this.defaultAssignedToContactTypeKey,
				contactId: [
					this.data?.model?.assignedToContactOptions?.contactId,
					CustomValidators.requiredWhen(
						() =>
							this.form?.get('assignedToContactOptions')?.get('source')?.value === this.selectedContactKey
					)
				],
				userTypeId: [
					this.data?.model?.assignedToContactOptions?.userTypeId,
					CustomValidators.requiredWhen(
						() => this.form?.get('assignedToContactOptions')?.get('source')?.value === 'UserType'
					)
				]
			}),
			dueDateOptions: this.fb.group({
				offsetFactor: [this.data?.model?.dueDateOptions?.offsetFactor || 0],
				offsetType: this.data?.model?.dueDateOptions?.offsetType || this.defaultOffsetTypeKey,
				dueDateSource: dueDateSource || this.defaultDueDateSourceKey
			}),
			documentTemplateIds: this.fb.array(
				(this.data?.model as DocumentMergeTaskTemplateViewDto)?.documentTemplateIds || [],
				[CustomValidators.nonEmptyArrayWhen(() => this.type === 'DocumentTemplateMerge')]
			),
			allCollectionFieldsMandatory: (this.data?.model as CollectionTaskTemplateViewDto)
				?.allCollectionFieldsMandatory,
			selectedCustomFieldGroups: this.fb.array(
				(this.data?.model as CollectionTaskTemplateViewDto)?.selectedCustomFieldGroups || [],
				[CustomValidators.nonEmptyArrayWhen(() => this.type === 'CollectionTask')]
			)
		});

		if (documentTemplateIds.length <= 0) {
			this.addDocumentTemplate();
		}

		const assignedToContactOptionsGroup = this.form?.get('assignedToContactOptions') as FormGroup;

		this.subscriptions.add(
			assignedToContactOptionsGroup
				.get('source')
				.valueChanges.pipe(debounceTime(100))
				.subscribe(() => {
					assignedToContactOptionsGroup?.get('contactId')?.updateValueAndValidity();
					assignedToContactOptionsGroup?.get('userTypeId')?.updateValueAndValidity();
				})
		);
	}

	save() {
		let dto:
			| GenericTaskTemplateCreateUpdateDto
			| DocumentMergeTaskTemplateCreateUpdateDto
			| CollectionTaskTemplateCreateUpdateDto;

		if (this.type === 'Generic') {
			dto = Object.assign(new GenericTaskTemplateCreateUpdateDto(), this.form.value);
			(dto as DocumentMergeTaskTemplateCreateUpdateDto).documentTemplateIds = null;
		} else if (this.type === 'DocumentTemplateMerge') {
			dto = Object.assign(new DocumentMergeTaskTemplateCreateUpdateDto(), this.form.value);
			(dto as DocumentMergeTaskTemplateCreateUpdateDto).documentTemplateIds =
				this.form.get('documentTemplateIds').value;
		} else if (this.type === 'CollectionTask') {
			dto = Object.assign(new CollectionTaskTemplateCreateUpdateDto(), this.form.value);
		}

		if (dto.assignedToContactOptions.source.startsWith('UserType')) {
			dto.assignedToContactOptions.userTypeId = dto.assignedToContactOptions.source.substring(
				dto.assignedToContactOptions.source.indexOf('-') + 1,
				dto.assignedToContactOptions.source.length
			);

			dto.assignedToContactOptions.source = 'UserType';
		}

		if (dto.dueDateOptions.dueDateSource.startsWith('CustomField')) {
			dto.dueDateOptions.customFieldId = dto.dueDateOptions.dueDateSource.substring(
				dto.dueDateOptions.dueDateSource.indexOf('-') + 1,
				dto.dueDateOptions.dueDateSource.length
			);

			dto.dueDateOptions.dueDateSource = 'CustomField';
		}

		const saveForm$ = this.isCreateMode
			? this.type === 'DocumentTemplateMerge'
				? this.taskTemplateService.createDocumentMergeTaskTemplate(
						dto as DocumentMergeTaskTemplateCreateUpdateDto
				  )
				: this.type === 'CollectionTask'
				? this.taskTemplateService.createCollectionTaskTemplate(dto as CollectionTaskTemplateCreateUpdateDto)
				: this.taskTemplateService.createTaskTemplate(dto)
			: this.type === 'DocumentTemplateMerge'
			? this.taskTemplateService.updateDocumentMergeTaskTemplate(
					this.data.model.id,
					dto as DocumentMergeTaskTemplateCreateUpdateDto
			  )
			: this.type === 'CollectionTask'
			? this.taskTemplateService.updateCollectionTaskTemplate(
					this.data.model.id,
					dto as CollectionTaskTemplateCreateUpdateDto
			  )
			: this.taskTemplateService.updateTaskTemplate(this.data.model.id, dto);

		this.subscriptions.add(
			saveForm$.subscribe({
				next: (result: EntityReference) => {
					this.notifService.showNotification(`Task Template ${this.isCreateMode ? 'created' : 'updated'}`);
					this.dialogRef.close(true);
				},
				error: this.onError
			})
		);
	}

	private onError = (errors: DomainError[]) => {
		this.notifService.showErrors('Error', errors);
	};

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	getHint(): string {
		if (
			this.data.workFlowType === 'StageChanged' &&
			this.form.controls.dueDateOptions.value.dueDateSource === 'TriggerDate'
		)
			return 'The date when the stage of the matter was changed';

		return '';
	}

	addDocumentTemplate(): void {
		const control = this.fb.control([null, this.uniqueDocumentsValidator]);

		this.fromDocumentTemplateIds?.push(control);

		setTimeout(() => {
			this.form.get('documentTemplateIds').updateValueAndValidity();
			this.form.updateValueAndValidity();
		}, 0);
	}

	removeDocumentTemplate(index: number) {
		this.fromDocumentTemplateIds?.removeAt(index);

		setTimeout(() => {
			this.form.get('documentTemplateIds').updateValueAndValidity();
			this.form.updateValueAndValidity();
		}, 0);
	}

	onSelectedGroupsChange(value: ICustomFieldGroupSelection[]) {
		const formArray = this.form.get('selectedCustomFieldGroups') as FormArray;

		formArray.clear();
		value.forEach(element => {
			formArray.push(this.fb.control(element));
		});

		setTimeout(() => {
			this.form.get('selectedCustomFieldGroups').markAsDirty();
			this.form.get('selectedCustomFieldGroups').updateValueAndValidity();
			this.form.updateValueAndValidity();
		}, 0);
	}

	private uniqueDocumentsValidator: ValidatorFn = (control: AbstractControl): ValidationErrors => {
		if (!this.form) {
			return null;
		}

		const hasDuplicates = this.hasDuplicates(
			this.documentTemplateIdControls.filter(control => !!control.value).map(control => control.value as string)
		);

		if (!!hasDuplicates) {
			return { error: `Document Template must be unique` };
		} else if (!control.value) {
			return { error: `Document Template must be entered` };
		} else {
			return null;
		}
	};

	private hasDuplicates(array: any[]) {
		return uniq(array).length !== array.length;
	}
}

export interface ITaskTemplateItemComponentData {
	model: GenericTaskTemplateViewDto | DocumentMergeTaskTemplateViewDto | CollectionTaskTemplateViewDto;
	basicWorkflowRef: EntityReference;
	workFlowType: keyof typeof BasicWorkflowType;
	practiceAreaIds: string[];
}
