// This file has been generated from TaskAssigneeUpdateDto.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.Domain.Tasks.Item.TaskAssigneeUpdateDto
export class TaskAssigneeUpdateDto {

	// Ids
	ids: string[];
	// AssignedTo
	assignedTo: string;

	constructor() {

	}
}