<mat-dialog-content>
	<ng-container *ngIf="!transactionType">
		<h2 mat-dialog-title class="title">Select the trust numbering sequence</h2>
		<br />
		<mat-radio-group [(ngModel)]="transactionType" class="flex-column options" (change)="transactionTypeChange()">
			<mat-radio-button [value]="receiptKey">Receipts</mat-radio-button>
			<mat-radio-button [value]="paymentEFTKey">Payments EFT</mat-radio-button>
			<mat-radio-button [value]="paymentChequeKey">Payments Cheque</mat-radio-button>
			<mat-radio-button [value]="paymentPexaDirectDebitKey">Payments PEXA Direct Debit</mat-radio-button>
			<mat-radio-button [value]="journalKey">Journals</mat-radio-button>
		</mat-radio-group>
	</ng-container>
	<ng-container *ngIf="transactionType">
		<h2 mat-dialog-title class="title">Edit the trust {{ getTitle() }} numbering sequence</h2>
		<div class="description">
			Define the format and starting number of trust {{ getTitle() }}. This number will increment by 1 with each record created.<br />
		</div>
		<br />
		<div class="flex-column" [formGroup]="form">
			<div class="container">
				<span class="title">Prefix</span>
				<mat-form-field>
					<input #prefix matInput formControlName="prefix" />
					<mat-error>
						<error-messages [for]="form.get('prefix')"></error-messages>
					</mat-error>
				</mat-form-field>
			</div>
			<div class="container">
				<span class="title">Number of Digits</span>
				<mat-form-field>
					<input #digits matInput formControlName="digits" />
					<mat-error>
						<error-messages [for]="form.get('digits')"></error-messages>
					</mat-error>
				</mat-form-field>
			</div>
			<div class="container">
				<span class="title">Next Number</span>
				<mat-form-field>
					<input #lastNumber matInput formControlName="nextNumber" />
					<mat-error>
						<error-messages [for]="form.get('nextNumber')"></error-messages>
					</mat-error>
				</mat-form-field>
			</div>
			<div class="container description">
				<span class="title">Sample of next Number</span>
				<div>{{ getFormattedInvoiceNumber() }}</div>
			</div>
		</div>
	</ng-container>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-raised-button color="primary" [disabled]="!form.dirty || form.invalid" throttleButton (throttledClick)="onSave()" *ngIf="transactionType">
		Save
	</button>
	<button mat-raised-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
