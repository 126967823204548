import { HttpErrorResponse } from '@angular/common/http';

import { isArray, isEmpty, isString } from 'lodash-es';

import { DomainError } from '../models/Validation/DomainError';

export class DomainErrorResolver {
	static getDomainErrors(response: HttpErrorResponse, entityType: string = 'item'): DomainError[] {
		let errors = new Array<DomainError>();

		if (isArray(response.error)) {
			errors = response.error as DomainError[];
		} else if (isString(response.error)) {
			const error = new DomainError();
			error.code = response.status;
			error.message = response.error;
			errors.push(error);
		}
		// If a NotFound(404) response is received from the server and there is no error message
		// add a error message that the entity doe snot exist
		else if (!response.error && response.status === 404) {
			const error = new DomainError();
			error.code = response.status;
			error.message = `${entityType} does not exist.`;
			errors.push(error);
		}

		if (isEmpty(errors)) {
			const error = new DomainError();
			error.code = response.status;
			error.message = response.message
				? response.message
				: response.status
				? `${response.statusText}`
				: 'Server error';
			errors.push(error);
		}

		return errors;
	}
}
