// This file has been generated from SecurityOptions.cs
// Do not modify this file directly!!
// tslint:disable

import { SetSimpleType } from '@common/utils/class-transform';



// LawMaster.Amalthea.Domain.DocumentBriefs.Item.SecurityOptions
export class SecurityOptions {

	// IncludeSecurityOptions
	@SetSimpleType(Boolean)
	includeSecurityOptions: boolean;
	// PasswordProtect
	@SetSimpleType(Boolean)
	passwordProtect: boolean;
	// PreventModification
	@SetSimpleType(Boolean)
	preventModification: boolean;
	// UserPassword
	userPassword: string;
	// OwnerPassword
	ownerPassword: string;

	constructor() {

	}
}