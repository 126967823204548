// This file has been generated from ModuleController.cs
// Do not modify this file directly!!
// tslint:disable

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { TrimStringsOnObject } from '@common/utils/string-format';

import { MarketplaceModuleDto } from '../models/Settings/Modules/Item/MarketplaceModuleDto';
import { OverlappingModuleDto } from '../models/Settings/Modules/Item/OverlappingModuleDto';
import { SubscriptionModuleDto } from '../models/Settings/Modules/Item/SubscriptionModuleDto';

@Injectable({
	providedIn: 'root'
})
export class ModuleService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'api/v1/Module', 'Module');
	}

	getSubscriptionModules(): Observable<SubscriptionModuleDto[]> {
		return this.getArray<void, SubscriptionModuleDto>(`GetSubscriptionModules`);
	}

	getMarketplaceModules(): Observable<MarketplaceModuleDto[]> {
		return this.getArray<void, MarketplaceModuleDto>(`GetMarketplaceModules`);
	}

	findOverlappingFeatures(id: string): Observable<OverlappingModuleDto[]> {
		return this.getArray<void, OverlappingModuleDto>(`FindOverlappingFeatures/${id}`);
	}

}
