import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { Subject, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

@Directive({
	selector: '[throttleButton]'
})
export class ThrottleButtonDirective implements OnInit, OnDestroy {
	private _subscription: Subscription;
	private _clicks = new Subject<Event>();
	private _throttleTime = 1000;

	@Input()
	set throttleTime(value: number) {
		this._throttleTime = value;
	}

	@Output()
	throttledClick = new EventEmitter<Event>();

	ngOnInit() {
		this._subscription = this._clicks
			.pipe(throttleTime(this._throttleTime))
			.subscribe(event => this.throttledClick.emit(event));
	}

	ngOnDestroy() {
		if (!!this._subscription) {
			this._subscription.unsubscribe();
			this._subscription = null;
		}
	}

	@HostListener('click', ['$event'])
	clickEvent(event: Event) {
		this._clicks.next(event);
	}
}
