<h2 mat-dialog-title>
	<span>{{ data?.briefTemplateId ? 'Edit' : 'Create' }} Brief Template</span>
</h2>
<div [formGroup]="form">
	<mat-dialog-content [formGroup]="form">
		<mat-form-field>
			<input #NameInput formControlName="name" matInput autocomplete="off" />
			<mat-placeholder
				>Name
				<sup class="color-warn">*</sup>
			</mat-placeholder>
			<mat-error>
				<error-messages [for]="form.get('name')"></error-messages>
			</mat-error>
		</mat-form-field>

		<div class="flex-row">
			<mat-form-field>
				<mat-placeholder>Practice Areas</mat-placeholder>
				<mat-select #selectPracticeAreas formControlName="associatedPracticeAreaIds" (selectionChange)="practiceAreaSelectionChanged($event)" multiple>
					<mat-option (onSelectionChange)="allPracticeAreaOptionSelected($event)" #allOption>All</mat-option>
					<mat-option
						(onSelectionChange)="practiceAreaOptionSelected($event, selectPracticeAreas)"
						*ngFor="let practiceArea of practiceAreas"
						[value]="practiceArea.id"
						>{{ practiceArea.name }}</mat-option
					>
				</mat-select>
			</mat-form-field>
		</div>

		<br />
		<mat-autocomplete #auto="matAutocomplete">
			<mat-option *ngFor="let tag of availableTags" [value]="tag">
				<div>{{ tag }}</div>
			</mat-option>
		</mat-autocomplete>

		<div class="description">
			Define the sections & tags for this brief template. By default, any documents assigned with the associated tag will be added to the corresponding
			section.
		</div>

		<div class="flex-end">
			<button class="add-allocation" mat-button color="primary" throttleButton (throttledClick)="addSection()">
				<mat-icon>add</mat-icon>
				<span>New Section</span>
			</button>
		</div>

		<mat-selection-list class="tags-list" cdkDropList (cdkDropListDropped)="drop($event)">
			<mat-list-option
				class="tag-box flexRow"
				cdkDrag
				*ngFor="let section of sections; index as i; let first = first; let last = last"
				[value]="section.id"
			>
				<div class="flexRow tags-list-container" throttleButton (throttledClick)="handleOptionClicked($event)">
					<mat-icon>drag_indicator</mat-icon>
					<div class="tags-list-container-contents">
						<mat-form-field>
							<input
								matInput
								#autoCompleteInput
								[matAutocomplete]="auto"
								[matAutocompleteDisabled]="!!getControlFromSectionControl(section.id, 'name').value"
								[formControl]="getControlFromSectionControl(section.id, 'name')"
								(keydown)="$event.stopPropagation()"
							/>
							<mat-placeholder>
								Section Name
								<sup class="color-warn">*</sup>
							</mat-placeholder>
						</mat-form-field>
						<document-tag [FormControl]="getSectionControl(section.id).get('documentTags')"></document-tag>
					</div>
					<mat-icon class="delete-icon" throttleButton (throttledClick)="removeSection(section.id, i)">delete</mat-icon>
				</div>
			</mat-list-option>
		</mat-selection-list>
	</mat-dialog-content>
	<mat-dialog-actions>
		<button mat-raised-button throttleButton (throttledClick)="save()" [disabled]="form.invalid" color="primary">Save</button>
		<button mat-raised-button mat-dialog-close>Cancel</button>
	</mat-dialog-actions>
</div>
