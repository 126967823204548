import { Component, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';

import { BehaviorSubject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import 'moment-duration-format';

import { XeroAccountDto } from '@common/models/Bills/XeroSync/XeroAccountDto';
import { XeroAccountsDto } from '@common/models/Bills/XeroSync/XeroAccountsDto';
import { EntityReference } from '@common/models/Common/EntityReference';
import { XeroIntegrationSettings } from '@common/models/Settings/BillSettings/Common/XeroIntegrationSettings';
import { XeroTenant } from '@common/models/Settings/BillSettings/Common/XeroTenant';
import { MatterCustomFieldListItemDto } from '@common/models/Settings/CustomFields/List/MatterCustomFieldListItemDto';
import { MatterCustomFieldsService } from '@common/services/customfields-matter.service';
import { XeroOauth2Service } from '@common/services/settings/xerooauth2.service';
import { CustomValidators } from '@common/validation/custom.validators';

import { isEmptyOrSpaces } from 'app/shared/utils/stringUtil';

import { XeroInvoiceReferenceDialogComponent } from './dialogs/xero-invoice-reference-dialog.component';
import { XeroSyncDescriptionDialogComponent } from './dialogs/xero-sync-description-dialog.component';

@Component({
	selector: 'xero-settings',
	styleUrls: ['./xero-settings.component.scss'],
	templateUrl: './xero-settings.component.html'
})
export class XeroSettingsComponent implements OnInit, OnDestroy {
	@Input() xeroSettings: XeroIntegrationSettings;
	@Input() showOrganisationList: boolean = false;
	@Output() isValidChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	@ViewChild('topPanel') topPanel: MatExpansionPanel;

	form: FormGroupTyped<XeroIntegrationSettings>;
	allRevenueAccounts: XeroAccountDto[];
	allExpenseAccounts: XeroAccountDto[];
	timeRecordAccounts: EntityReference[];
	expenseAccounts: EntityReference[];
	usersXeroTenants: XeroTenant[];

	private subscriptions = new Subscription();

	constructor(
		private fb: FormBuilder,
		private xeroOauth2Service: XeroOauth2Service,
		private dialog: MatDialog,
		private matterCustomFieldsService: MatterCustomFieldsService
	) {}
	get currentState(): XeroIntegrationSettings {
		return {
			...this.xeroSettings,
			...this.form.value
		};
	}
	private customFields: MatterCustomFieldListItemDto[];

	ngOnInit() {
		this.form = this.fb.group({
			xeroTenant: [
				this.xeroSettings?.xeroTenant,
				CustomValidators.requiredMessage('Xero company to connect to for synchronisation is required')
			],
			timeEntriesAccount: [
				this.xeroSettings?.timeEntriesAccount,
				CustomValidators.requiredMessage('Account to use for time record invoice line items is required')
			],
			expensesAccount: [
				this.xeroSettings?.expensesAccount,
				CustomValidators.requiredMessage('Account to use for expense invoice line items is required')
			],
			timeEntriesExGstTaxType: this.xeroSettings?.timeEntriesExGstTaxType,
			expensesExGstTaxType: this.xeroSettings?.expensesExGstTaxType,
			xeroLineItemDescriptionFormat:
				this.xeroSettings?.xeroLineItemDescriptionFormat ?? 'Date,Hyphen,Description',
			xeroInvoiceReferenceFormat: this.xeroSettings?.xeroInvoiceReferenceFormat ?? '',
			numberMonthsToBulkSync: [
				this.xeroSettings?.numberMonthsToBulkSync === undefined ? 6 : this.xeroSettings?.numberMonthsToBulkSync,
				Validators.min(1)
			]
		}) as FormGroupTyped<XeroIntegrationSettings>;

		this.subscriptions.add(
			this.matterCustomFieldsService
				.getMatterCustomFieldList({ enabled: true })
				.subscribe(fields => (this.customFields = fields?.records))
		);

		if (this.showOrganisationList) {
			this.subscriptions.add(
				this.form.controls.xeroTenant.valueChanges.subscribe((value: XeroTenant) =>
					this.loadXeroAccounts(value)
				)
			);
			this.subscriptions.add(
				this.xeroOauth2Service.getUsersXeroTenants().subscribe((response: XeroTenant[]) => {
					this.usersXeroTenants = response;
					if (response && response.length > 0 && !this.form.controls.xeroTenant.value) {
						if (response.length === 1 && !this.form.controls.xeroTenant.value) {
							this.form.controls.xeroTenant.setValue(response[0]);
							this.form.controls.xeroTenant.markAsDirty();
						}
						this.form.controls.xeroTenant.enable();
						this.form.markAllAsTouched();
					}
				})
			);
		}

		if (this.form.controls.xeroTenant.value && (!this.allExpenseAccounts || !this.allRevenueAccounts)) {
			this.loadXeroAccounts(this.form.controls.xeroTenant.value);
		}

		this.subscriptions.add(
			this.form.statusChanges.subscribe(next => {
				// disabled controls are not part of the validation, so we need to check that they are enabled separately
				return this.isValidChange.next(next === 'VALID' && this.form.controls.timeEntriesAccount?.enabled);
			})
		);
	}

	get formattedXeroInvoiceReferenceFormat() {
		const referenceFormat = this.form.get('xeroInvoiceReferenceFormat')?.value as string;

		if (isEmptyOrSpaces(referenceFormat)) {
			return '';
		}

		return referenceFormat
			.split(',')
			.map(variable => {
				const field = this.customFields?.filter(field => field.id === variable)[0];
				return !field ? variable : field.name;
			})
			.reduce((left, right) => `${left} - ${right}`);
	}

	ngOnDestroy() {
		this.isValidChange.complete();
		this.subscriptions.unsubscribe();
	}

	loadXeroAccounts(tenant: XeroTenant) {
		if (tenant) {
			this.subscriptions.add(
				this.xeroOauth2Service.getXeroAccounts(tenant.xeroTenantId).subscribe((response: XeroAccountsDto) => {
					if (!response || (response.revenueAccounts.length <= 0 && response.expenseAccounts.length <= 0)) {
						this.toggleControlState(false);
						if (!!this.xeroSettings.timeEntriesAccount) {
							this.timeRecordAccounts = [this.xeroSettings.timeEntriesAccount];
							if (!!this.xeroSettings.timeEntriesExGstTaxType) {
								this.allRevenueAccounts = [
									{
										...this.xeroSettings.timeEntriesAccount,
										exGstTaxRates: [this.xeroSettings.timeEntriesExGstTaxType]
									}
								];
							}
						}
						if (!!this.xeroSettings.expensesAccount) {
							this.expenseAccounts = [this.xeroSettings.expensesAccount];
							if (!!this.xeroSettings.expensesExGstTaxType) {
								this.allExpenseAccounts = [
									{
										...this.xeroSettings.expensesAccount,
										exGstTaxRates: [this.xeroSettings.expensesExGstTaxType]
									}
								];
							}
						}
					} else {
						this.toggleControlState(true);
						this.allExpenseAccounts = response.expenseAccounts;
						this.allRevenueAccounts = response.revenueAccounts;

						this.timeRecordAccounts = this.allRevenueAccounts?.map(x => ({ id: x.id, name: x.name }));
						this.expenseAccounts = this.allExpenseAccounts?.map(x => ({ id: x.id, name: x.name }));
					}
				})
			);
		} else {
			this.toggleControlState(false);
			this.allExpenseAccounts = null;
			this.allRevenueAccounts = null;
		}
	}

	toggleControlState(enabled: boolean) {
		if (enabled) {
			this.form.controls.timeEntriesAccount.enable();
			this.form.controls.expensesAccount.enable();
			this.form.controls.timeEntriesExGstTaxType.enable();
			this.form.controls.expensesExGstTaxType.enable();
			this.form.controls.xeroInvoiceReferenceFormat.enable();
			this.form.controls.xeroLineItemDescriptionFormat.enable();
			this.form.controls.numberMonthsToBulkSync.enable();
		} else {
			this.form.controls.timeEntriesAccount.disable();
			this.form.controls.expensesAccount.disable();
			this.form.controls.timeEntriesExGstTaxType.disable();
			this.form.controls.expensesExGstTaxType.disable();
			this.form.controls.xeroInvoiceReferenceFormat.disable();
			this.form.controls.xeroLineItemDescriptionFormat.disable();
			this.form.controls.numberMonthsToBulkSync.disable();
		}
	}

	editDescriptionFormat() {
		this.subscriptions.add(
			this.dialog
				.open(XeroSyncDescriptionDialogComponent, {
					data: {
						descriptionFormat: this.form.controls.xeroLineItemDescriptionFormat.value
					},
					width: '1000px'
				})
				.afterClosed()
				.pipe(filter(Boolean))
				.subscribe((descriptionStringFormat: string) => {
					this.form.controls.xeroLineItemDescriptionFormat.setValue(descriptionStringFormat);
					this.form.controls.xeroLineItemDescriptionFormat.markAsDirty();
				})
		);
	}

	editReferenceFormat() {
		this.subscriptions.add(
			this.dialog
				.open(XeroInvoiceReferenceDialogComponent, {
					data: { invoiceFormat: this.form.controls.xeroInvoiceReferenceFormat.value },
					width: '1000px'
				})
				.afterClosed()
				.pipe(filter(Boolean))
				.subscribe((invoiceStringFormat: string) => {
					this.form.controls.xeroInvoiceReferenceFormat.setValue(invoiceStringFormat);
					this.form.controls.xeroInvoiceReferenceFormat.markAsDirty();
				})
		);
	}

	keepOpen(event: any): void {
		event.stopPropagation();
		this.topPanel.open();
	}

	compareIds(o1: any, o2: any): boolean {
		return (!o1 && !o2) || (!!o1 && !!o2 && o1.id === o2.id);
	}

	compareTenantIds(o1: any, o2: any): boolean {
		return (!o1 && !o2) || (!!o1 && !!o2 && o1.xeroTenantId === o2.xeroTenantId);
	}

	get expenseTaxRates(): EntityReference[] {
		const selectedAccount =
			this.allExpenseAccounts && this.form?.controls?.expensesAccount?.value
				? this.allExpenseAccounts.find(x => x.id === this.form.controls.expensesAccount.value.id)
				: null;
		return selectedAccount?.exGstTaxRates;
	}

	get timeRecordTaxRates(): EntityReference[] {
		const selectedAccount =
			this.allRevenueAccounts && this.form?.controls?.timeEntriesAccount?.value
				? this.allRevenueAccounts.find(x => x.id === this.form.controls.timeEntriesAccount.value.id)
				: null;
		return selectedAccount?.exGstTaxRates;
	}

	get valid(): boolean {
		return (
			!!this.form?.valid &&
			!!this.xeroSettings?.xeroTenant &&
			!!this.xeroSettings?.timeEntriesAccount &&
			!!this.xeroSettings?.expensesAccount
		);
	}

	get numberMonthsTooltip(): string {
		return `This setting will check invoices for payments with an issue date up to ${
			this.form?.controls?.numberMonthsToBulkSync?.value || 'X'
		} months ago when syncing all invoices. This setting is ignored when syncing individual invoices or all invoices on a matter.`;
	}
}
