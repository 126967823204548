import { Injectable } from '@angular/core';

import { interval, of } from 'rxjs';
import { catchError, debounce, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { IAppState } from '../../../app.state';
import { loading, CostTemplateListActions } from './cost-template-list.actions';
import { CostTemplatesService } from '@common/services/settings/costtemplates.service';

@Injectable()
export class CostTemplateListEffects {
	loadCostTemplates$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				CostTemplateListActions.Load,
				CostTemplateListActions.SetPageIndex,
				CostTemplateListActions.SetPageSize,
				CostTemplateListActions.SetSortBy,
				CostTemplateListActions.SetSortDirection,
				CostTemplateListActions.SetFilters
			),
			tap(action => this.store.dispatch(loading())),
			withLatestFrom(this.store),
			debounce(() => interval(200)),
			switchMap(([action, state]) => {
				return this.costTemplateService.getCostTemplateList(state.costTemplateList.request).pipe(
					map(response => {
						return { type: CostTemplateListActions.LoadSuccess, response };
					}),
					catchError(error => of({ type: CostTemplateListActions.LoadFailed, error }))
				);
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<IAppState>,
		private costTemplateService: CostTemplatesService
	) {}
}
