import { CollectionViewer, DataSource } from '@angular/cdk/collections';

import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';

import { ListResponse } from '@common/models/Generic/ListResponse';
import { get, isNil, omitBy } from 'lodash-es';

export class GenericDataSource<TListItemDto, TFilter> extends DataSource<TListItemDto> {
	response: ListResponse<TListItemDto>;
	dataLoaded = new BehaviorSubject<boolean>(false);

	constructor(
		private delegate: (dto?: Partial<TFilter>) => Observable<ListResponse<TListItemDto>>,
		// Observable of events with new filter conditions
		private requests: Observable<DataSourceRequest<TFilter>>
	) {
		super();
	}

	connect(collectionViewer: CollectionViewer): Observable<TListItemDto[]> {
		return this.requests.pipe(
			filter(Boolean),
			debounceTime(100),
			distinctUntilChanged((oldRequest: DataSourceRequest<TFilter>, newRequest: DataSourceRequest<TFilter>) => {
				if (!!newRequest.force) {
					return false;
				}

				if (!oldRequest && !newRequest) {
					return true;
				}

				return !!oldRequest && !!newRequest && JSON.stringify(oldRequest) === JSON.stringify(newRequest);
			}),
			map(request => request.filter),
			tap(f => this.dataLoaded.next(false)),
			switchMap(request => this.delegate(omitBy(request, isNil) as Partial<TFilter>)),
			delay(0), // Need it to avoid 'ExpressionChangedAfterItHasBeenCheckedError' in tests
			map((response: ListResponse<TListItemDto>) => {
				this.response = response;
				this.dataLoaded.next(true);
				return get(response, 'records');
			})
		);
	}

	disconnect(collectionViewer: CollectionViewer): void {
		// do nothing
	}
}

// tslint:disable-next-line: max-classes-per-file
export class DataSourceRequest<TFilter> {
	filter: TFilter;
	force: boolean;
}
