<mat-card>
	<section class="page-path"><a [routerLink]="['..']">System</a> / Document Brief Templates</section>
	<h1>Document Brief Templates</h1>
	<div class="description">Create or modify document brief templates by defining the sections, document tags & practice area</div>
	<button mat-button color="primary" title="Create New" throttleButton (throttledClick)="onAddTemplateClick()">
		<mat-icon class="create-new">add_circle</mat-icon>
		<span>Create Brief Template</span>
	</button>
	<mat-table [dataSource]="(isFetching$ | async) ? [] : (data$ | async)" matSort matSortActive="name" matSortDirection="asc">
		<ng-container matColumnDef="name">
			<mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<span class="name">{{ row.name }}</span>

				<div class="embedded-practice-areas">
					<span *ngIf="!row.practiceAreas?.length">All Practice Areas</span>
					<ul>
						<li *ngFor="let pa of row.practiceAreas">{{ pa.name }}</li>
					</ul>
				</div>
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="practiceAreas">
			<mat-header-cell *matHeaderCellDef>Practice Areas</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<span *ngIf="!row.practiceAreas?.length">All</span>
				<ul>
					<li *ngFor="let pa of row.practiceAreas">{{ pa.name }}</li>
				</ul>
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="sections">
			<mat-header-cell *matHeaderCellDef>Sections</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<ul>
					<li *ngFor="let sec of row.sections">{{ sec.name }}</li>
				</ul>
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="action">
			<mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<button mat-icon-button [matMenuTriggerFor]="menu" title="Actions">
					<mat-icon>more_vert</mat-icon>
				</button>
				<mat-menu #menu="matMenu">
					<button mat-menu-item throttleButton (throttledClick)="onEditTemplateClick(row)">
						<mat-icon>edit</mat-icon>
						<span>Edit Details</span>
					</button>
					<button mat-menu-item throttleButton (throttledClick)="onDeleteTemplateClick(row)">
						<mat-icon svgIcon="delete"></mat-icon>
						<span>Delete</span>
					</button>
				</mat-menu>
			</mat-cell>
		</ng-container>

		<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
		<mat-row
			*matRowDef="let row; columns: displayedColumns"
			throttleButton
			(throttledClick)="selectRow(row, $event)"
			[ngClass]="{ highlight: row.isHighlighted }"
		></mat-row>
	</mat-table>
	<mat-paginator
		[length]="totalRecords$ | async"
		[pageIndex]="pageIndex$ | async"
		[pageSize]="pageSize$ | async"
		[pageSizeOptions]="pageSizeOptions"
		[class.hidden]="(isFetching$ | async) || !(data$ | async)"
	></mat-paginator>
</mat-card>
