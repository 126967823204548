import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Subscription } from 'rxjs';

import { AppcuesService } from '@common/appcues/appcues.service';
import { BaseDtoWithTrimmedId } from '@common/models/Common/BaseDtoWithTrimmedId';
import { DocumentHeaderFooterTemplateUpdateDto } from '@common/models/Documents/Item/DocumentHeaderFooterTemplateUpdateDto';
import { DocumentHeaderFooterTemplateViewDto } from '@common/models/Documents/Item/DocumentHeaderFooterTemplateViewDto';
import { NotificationService } from '@common/notification';
import { DocumentHeaderFooterTemplatesService } from '@common/services/settings/documentheaderfootertemplates.service';
import { CustomValidators } from '@common/validation/custom.validators';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';

import { IAppState } from 'app/core/state/app.state';
import { processRecords } from 'app/core/state/lists/document-list/document-list.actions';
import { DocumentTagComponent } from 'app/shared/components/document-tag.component';

@Component({
	selector: 'header-footer-template-properties',
	styleUrls: ['./header-footer-template-properties.component.scss'],
	templateUrl: './header-footer-template-properties.component.html'
})
export class HeaderFooterTemplatePropertiesComponent implements OnInit, OnDestroy {
	@ViewChild('detailsInput')
	detailsInput: ElementRef;
	@ViewChild(DocumentTagComponent, { static: false })
	tagComponent: DocumentTagComponent;

	form: FormGroup;

	private subscription: Subscription = new Subscription();

	constructor(
		private dialogRef: MatDialogRef<HeaderFooterTemplatePropertiesComponent>,
		@Inject(MAT_DIALOG_DATA) private data: BaseDtoWithTrimmedId,
		private fb: FormBuilder,
		private templateService: DocumentHeaderFooterTemplatesService,
		private notifService: NotificationService,
		private appcuesService: AppcuesService,
		private store: Store<IAppState>
	) {}

	ngOnInit(): void {
		this.form = this.fb.group({
			associatedPracticeAreaIds: [],
			documentCategoryId: null,
			entityType: null,
			title: [null, [CustomValidators.required('Title'), CustomValidators.validateFileName()]],
			documentTags: null
		});

		if (this.data.id) {
			// load the record
			this.subscription.add(
				this.templateService
					.getDocumentHeaderFooterTemplate(this.data.id)
					.subscribe((document: DocumentHeaderFooterTemplateViewDto) => {
						this.form.patchValue({
							title: document.title,
							documentTags: document.documentTags
						});
					})
			);
		} else this.form.reset();
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	save(): void {
		const request: DocumentHeaderFooterTemplateUpdateDto = cloneDeep(this.form.value);

		this.templateService.updateTemplate(this.data.id, request).subscribe({
			next: ref => {
				this.store.dispatch(processRecords({ response: ref }));
				this.notifService.showNotification(`Template updated: ${ref.name}`);
				this.dialogRef.close(ref);
			},
			error: errs => {
				this.notifService.showErrors(`Error updating header/footer template`, errs);
			}
		});
		this.appcuesService.trackEvent('SaveHeaderFooterDocumentTemplate');
	}
}
