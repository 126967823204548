import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { BehaviorSubject, Subscription, switchMap } from 'rxjs';

import { BasicWorkflowViewDto } from '@common/models/Settings/BasicWorkflows/Item/BasicWorkflowViewDto';
import { NotificationService } from '@common/notification';
import { BasicWorkflowService } from '@common/services/settings/basicworkflow.service';

import { FeatureFlags, isFeatureFlagEnabled } from 'app/app.config';

import { WorkflowDocumentTemplateListComponent } from './document-templates/list/workflow-document-template-list.component';
import { BasicWorkflowSummaryComponent } from './summary/basic-workflow-summary.component';
import { TaskTemplatesListComponent } from './task-templates/list/task-template-list.component';

enum ActiveTab {
	Details = 0,
	Tasks = 1,
	DocumentTemplates = 2
}
@Component({
	selector: 'basic-workflow-container',
	templateUrl: './basic-workflow-container.component.html',
	styleUrls: ['./basic-workflow-container.component.scss']
})
export class BasicWorkflowContainerComponent implements OnInit, OnDestroy {
	workflowId: string;
	featureFlags: typeof FeatureFlags = FeatureFlags;
	workflow: BehaviorSubject<BasicWorkflowViewDto>;

	get getWorkflow(): BasicWorkflowViewDto {
		return this.workflow.value;
	}

	get isDocumentTemplateFeatureEnabled() {
		return isFeatureFlagEnabled(this.featureFlags.matterUpdatedWorkflows);
	}

	selectedTabIndex: number = 0;
	subscriptions = new Subscription();

	@ViewChild('summaryComponent') summaryComponent: BasicWorkflowSummaryComponent;
	@ViewChild('taskListComponent') taskListComponent: TaskTemplatesListComponent;
	@ViewChild('documentListComponent') documentListComponent: WorkflowDocumentTemplateListComponent;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private basicWorkflowService: BasicWorkflowService,
		private notifService: NotificationService
	) {}

	get practiceAreasText(): string {
		if (this.workflow.value?.practiceAreas === null) return null;

		return this.workflow.value?.practiceAreas?.length
			? `Practice Areas: ${this.workflow.value?.practiceAreas?.map(x => x.name)?.join(', ')}`
			: 'Practice Areas: All';
	}

	get isSummaryTab() {
		return this.selectedTabIndex == ActiveTab.Details;
	}

	get isTaskListTab() {
		return this.selectedTabIndex == ActiveTab.Tasks;
	}

	get isDocumentTemplateTab() {
		return this.selectedTabIndex == ActiveTab.DocumentTemplates;
	}

	ngOnInit() {
		this.subscriptions.add(
			this.activatedRoute.params
				.pipe(
					switchMap((routerParams: Params) => {
						this.workflowId = routerParams.basicWorkflowId;
						return this.basicWorkflowService.getBasicWorkflow(this.workflowId);
					})
				)
				.subscribe({
					next: (workflow: BasicWorkflowViewDto) => {
						this.workflow = new BehaviorSubject<BasicWorkflowViewDto>(workflow);
					},
					error: e => this.notifService.showErrors('Error getting Workflow', e)
				})
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	executeAddAction() {
		if (this.isTaskListTab) this.taskListComponent.create();
		else if (this.isDocumentTemplateTab) this.documentListComponent.add();
	}

	onSelectedTabChange(event: MatTabChangeEvent) {
		const activeTab = event.index as ActiveTab;
		this.selectedTabIndex = activeTab;
		this.router.navigate([], {
			queryParams: {
				activeTab: ActiveTab[activeTab]?.toLowerCase()
			},
			queryParamsHandling: 'merge',
			relativeTo: this.activatedRoute
		});
	}
}
