import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { BehaviorSubject, combineLatest, map, Observable, of, tap } from 'rxjs';

import { BaseLookupComponent } from '@common/components/lookups/base-lookup.component';
import { BaseDtoWithTrimmedId } from '@common/models/Common/BaseDtoWithTrimmedId';
import { Store } from '@ngrx/store';
import { sortBy } from 'lodash-es';

import { IAppState } from 'app/core/state/app.state';
import { load } from 'app/core/state/misc/time-zone-list/time-zone-list.actions';
import { selectTimeZoneList } from 'app/core/state/misc/time-zone-list/time-zone-list.selector';
import { TimeZoneItem } from 'app/core/state/misc/time-zone-list/time-zone-list.state';

@Component({
	selector: 'time-zone-lookup',
	styleUrls: ['../../../../../common/components/lookups/base-lookup.component.scss'],
	templateUrl: '../../../../../common/components/lookups/base-lookup.component.html'
})
export class TimezoneLookupComponent extends BaseLookupComponent<TimeZoneListItemDto> implements OnInit {
	@ViewChild('autoCompleteInput', { static: true })
	inputCtrl: ElementRef;

	private _timeZones$ = new BehaviorSubject<TimeZoneListItemDto[]>(null);

	constructor(private _store: Store<IAppState>) {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();

		this._store.dispatch(load());

		this.subscription.add(
			this._store
				.select(selectTimeZoneList)
				.pipe(tap(timeZones => this._timeZones$.next(this.offsetSort(timeZones))))
				.subscribe()
		);
	}

	inputClicked() {
		this._store.dispatch(load());

		this.subscription.add(
			this.lastestTimezones$().subscribe(timeZones => {
				this.options = timeZones;
				this.trigger?.openPanel();
			})
		);
	}

	optionHtmlText(input: TimeZoneListItemDto): string {
		return input.name;
	}

	displayText(value: TimeZoneListItemDto) {
		return value.name;
	}

	lookup(id: string): Observable<TimeZoneListItemDto> {
		this._store.dispatch(load());

		return this.lastestTimezones$().pipe(map(timeZones => timeZones?.filter(variable => variable.id == id)[0]));
	}

	search(term: string): Observable<TimeZoneListItemDto[]> {
		this._store.dispatch(load());

		const lowercaseTerm = term?.toLowerCase() ?? '';

		const terms = lowercaseTerm.split(/(\s+)/).filter(match => !!match?.trim()?.length);

		return this.lastestTimezones$().pipe(
			map(variables =>
				this.offsetSort(variables)
					?.filter(
						variable =>
							terms.filter(
								term =>
									variable.name.toLowerCase().includes(term) ||
									variable.id.toLowerCase().includes(term)
							).length == terms.length
					)
					?.sort((op1, op2) => {
						let comp1 = op1.name.toLowerCase().indexOf(lowercaseTerm);
						if (comp1 < 0) {
							comp1 = 9999;
						}

						let comp2 = op2.name.toLowerCase().indexOf(lowercaseTerm);
						if (comp2 < 0) {
							comp2 = 9999;
						}

						if (comp1 < comp2) {
							return -1;
						}

						if (comp1 > comp2) {
							return 1;
						}

						return op1.name.localeCompare(op2.name);
					})
			)
		);
	}

	setFocus() {
		if (this.inputCtrl != null) {
			setTimeout(() => {
				this.inputCtrl.nativeElement.focus();
			}, 0);
		}
	}

	searchIfTermEmpty(): boolean {
		return true;
	}

	private offsetSort(timeZones: TimeZoneListItemDto[]): TimeZoneListItemDto[] {
		if (!timeZones?.length) {
			return [];
		}

		return sortBy(timeZones, timeZone => timeZone.offset);
	}

	private lastestTimezones$() {
		const currentValue = this._timeZones$.value;

		return !!currentValue
			? of(currentValue)
			: combineLatest([this._timeZones$.asObservable()]).pipe(map(([timeZone]) => timeZone));
	}
}

class TimeZoneListItemDto extends BaseDtoWithTrimmedId implements TimeZoneItem {
	name: string;
	offset: number;
}
