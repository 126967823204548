import { AfterViewInit, Component, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { BehaviorSubject, Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { ILookupReference } from '@common/components/lookups/base-lookup.component';
import { SpeechTextAreaComponent } from '@common/components/speech-textarea.component';
import { DocumentAssociationType } from '@common/models/Documents/Item/DocumentAssociationType';
import { DocumentViewDto } from '@common/models/Documents/Item/DocumentViewDto';
import { NoteCreateUpdateDto } from '@common/models/Documents/Item/NoteCreateUpdateDto';
import { MatterLookupDto } from '@common/models/Matters/Lookup/MatterLookupDto';
import { DocumentCategoryListItemDto } from '@common/models/Settings/DocumentCategories/List/DocumentCategoryListItemDto';
import { INotificationMessage } from '@common/notification';
import { getIllegalFileNameCharactersExp } from '@common/utils/fileNameUtil';
import { CustomValidators } from '@common/validation/custom.validators';
import { Store } from '@ngrx/store';
import { isEmpty } from 'lodash-es';
import * as moment from 'moment-timezone';

import { EntityType } from 'app/core/dialog.config';
import { processRecords } from 'app/core/state/lists/document-list/document-list.actions';
import { getCurrentPage } from 'app/core/state/misc/current-page/current-page.reducer';
import { CurrentPageType, ICurrentPageState } from 'app/core/state/misc/current-page/current-page.state';
import { DocumentsService } from 'app/services/documents.service';
import { DocumentTagComponent } from 'app/shared/components/document-tag.component';

import { ICreateComponent } from '../create-component.interface';
import { IEditComponent } from '../edit-component.interface';

@Component({
	selector: 'create-note',
	styleUrls: ['./create-note.component.scss'],
	templateUrl: './create-note.component.html'
})
export class CreateNoteComponent implements OnInit, AfterViewInit, OnDestroy, ICreateComponent, IEditComponent {
	@Input()
	editId: string;
	@Input()
	entityType: EntityType = EntityType.Note;
	@Input()
	suppressActivate: boolean;
	@Output()
	isValidChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	@ViewChild(SpeechTextAreaComponent)
	detailsInput: SpeechTextAreaComponent;
	@ViewChild(DocumentTagComponent)
	tagComponent: DocumentTagComponent;

	form: FormGroupTyped<NoteCreateUpdateDto>;
	associatedEntityTypes: DocumentAssociationType[] = [
		DocumentAssociationType.Matter,
		DocumentAssociationType.Contact
	];
	associatedEntityType: DocumentAssociationType = this.associatedEntityTypes[0];
	documentCategories: DocumentCategoryListItemDto[];

	private subscription: Subscription = new Subscription();
	private titleSubscription?: Subscription;

	constructor(private fb: FormBuilder, private service: DocumentsService, private store: Store<ICurrentPageState>) {}

	ngOnInit(): void {
		this.form = this.fb.group({
			associatedContactId: [
				null,
				CustomValidators.requiredWhen(
					() => this.associatedEntityType === DocumentAssociationType.Contact,
					DocumentAssociationType.Contact
				)
			],
			associatedMatterId: [
				null,
				CustomValidators.requiredWhen(
					() => this.associatedEntityType === DocumentAssociationType.Matter,
					DocumentAssociationType.Matter
				)
			],
			date: [null, CustomValidators.required('Created Date')],
			details: [null, CustomValidators.requiredWhen(() => this.isTextNote, 'Details')],
			title: [null, CustomValidators.required('Title')],
			documentTags: null
		}) as FormGroupTyped<NoteCreateUpdateDto>;

		this.subscription.add(
			this.form.statusChanges.subscribe(next => {
				this.isValidChange.next(next === 'VALID');
			})
		);

		if (this.editId) {
			// load the record
			this.subscription.add(
				this.service.getDocument(this.editId).subscribe((document: DocumentViewDto) => {
					if (document.associatedContact) {
						this.associatedEntityType = DocumentAssociationType.Contact;
					}
					if (document.associatedMatter) {
						this.associatedEntityType = DocumentAssociationType.Matter;
					}

					this.form.patchValue({
						associatedContactId: document.associatedContact ? document.associatedContact.id : null,
						associatedMatterId: document.associatedMatter ? document.associatedMatter.id : null,
						date: document.createdDate,
						title: document.title,
						documentTags: document.documentTags
					});
					this.tagComponent.ActiveTags = document.documentTags ?? [];
				})
			);

			if (this.isTextNote) {
				// load the data
				this.subscription.add(
					this.service.getNoteDetails(this.editId).subscribe((contents: string) => {
						this.form.patchValue({
							details: contents
						});
					})
				);
			}
		} else {
			this.resetForm();
		}
	}

	resetForm() {
		// default in the current date
		this.form.reset({ date: moment() });
		this.defaultToCurrentOpenMatterOrContact();

		this.titleSubscription = this.form.controls.details.valueChanges.subscribe((next: string) => {
			if (!isEmpty(next) && (next.indexOf('.') >= 0 || next.indexOf('\n') >= 0)) {
				this.stopUpdatingTitle();
			} else {
				if (next) {
					next = next.replace(getIllegalFileNameCharactersExp(), '');
				}

				this.form.controls.title.setValue(next);
			}
		});
	}

	get isTextNote() {
		return this.entityType === EntityType.Note;
	}

	ngAfterViewInit() {
		if (!this.suppressActivate && this.detailsInput) {
			setTimeout(() => this.detailsInput.textAreaInput.nativeElement.focus(), 0);
		}
	}

	ngOnDestroy() {
		this.isValidChange.complete();
		this.subscription.unsubscribe();
		this.stopUpdatingTitle();
	}

	stopUpdatingTitle() {
		if (this.titleSubscription) {
			this.titleSubscription.unsubscribe();
		}
	}

	associatedEntityTypeChange() {
		// clear out the associated contact and matter whenever the associated entity type changes
		this.form.patchValue({
			associatedContactId: null,
			associatedMatterId: null
		});
	}

	Create(): Observable<INotificationMessage> {
		const request = this.form.value;

		return this.service.createNote(request).pipe(
			tap(ref => this.store.dispatch(processRecords({ response: ref }))),
			map(ref => {
				const contactId = this.form.controls.associatedContactId.value;
				const matterId = this.form.controls.associatedMatterId.value;
				const route = [];

				if (matterId) {
					route.push('/matters', matterId, 'documents');
				} else if (contactId) {
					route.push('/contacts', contactId, 'documents');
				} else {
					route.push('/documents');
				}

				return {
					associatedMatterId: matterId,
					linkParams: { pageIndexForId: ref.id },
					linkRoute: route,
					linkText: ref.name,
					text: `${this.entityType} created:`
				};
			})
		);
	}

	Edit(): Observable<INotificationMessage> {
		const request = this.form.value;

		return this.service.updateNote(this.editId, request).pipe(
			tap(ref => this.store.dispatch(processRecords({ response: ref }))),
			map(ref => ({ text: `${this.entityType} updated: ${ref.name}` }))
		);
	}

	onContactSelected(event: ILookupReference) {
		this.form.controls.associatedContactId.setValue(event.id);
	}

	private defaultToCurrentOpenMatterOrContact() {
		this.subscription.add(
			this.store
				.select(getCurrentPage)
				.pipe(
					filter(page => page.id !== null),
					map(page => this.isPageWithOpenMatter(page))
				)
				.subscribe(page => {
					if (page.pageType === CurrentPageType.Contact) {
						this.associatedEntityType = DocumentAssociationType.Contact;
						this.form.patchValue({ associatedContactId: page.id });
					} else if (page.pageType === CurrentPageType.Matter) {
						this.associatedEntityType = DocumentAssociationType.Matter;
						this.form.patchValue({ associatedMatterId: page.id });
					}
				})
		);
	}

	private isPageWithOpenMatter(page: ICurrentPageState): ICurrentPageState {
		if (page.pageType === CurrentPageType.Matter) {
			if ((page.lookup as MatterLookupDto).status === 'Open') {
				return page;
			}
			return { id: null, lookup: null, pageType: page.pageType };
		}
		return page;
	}
}
