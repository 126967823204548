// Trim all string property values
export function TrimStringsOnObject(obj: any) {
	for (const propKey in obj) {
		if (!!propKey && obj.hasOwnProperty(propKey)) {
			const value = obj[propKey];
			const type = typeof value;
			if (value != null && (type === 'string' || type === 'object') && !value._isAMomentObject) {
				if (type === 'object') {
					TrimStringsOnObject(obj[propKey]);
				} else {
					obj[propKey] = (obj[propKey] as string).trim();
				}
			}
		}
	}
	return obj;
}
