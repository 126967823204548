import { ListResponse } from '@common/models/Generic/ListResponse';
import { DomainError } from '@common/models/Validation/DomainError';

import { DocumentTagListItemDto } from '@common/models/Settings/DocumentTags/List/DocumentTagListItemDto';
import { DocumentTagListRequest } from '@common/models/Settings/DocumentTags/List/DocumentTagListRequest';
import { AppConfig } from 'app/app.config';

export interface IDocumentTagListState {
	isFetching: boolean;
	list: ListResponse<DocumentTagListItemDto>;
	lastSelectedRecordId: string;
	request: Partial<DocumentTagListRequest>;

	// errrors
	error: DomainError;
}

export const initialState: IDocumentTagListState = {
	isFetching: false,
	list: null,
	lastSelectedRecordId: null,
	request: {
		search: null,

		pageSize: AppConfig.PageSize,
		pageIndex: 0,
		pageIndexForId: null,

		sortBy: null,
		sortDirection: null,
		practiceAreaIds: []
	},

	error: null
};
