import { ListRequest } from '@common/models/Generic/ListRequest';
import { InfoTrackStatusListItemDto } from '@common/models/InfoTrack/Orders/List/InfoTrackStatusListItemDto';
import { DomainError } from '@common/models/Validation/DomainError';
import { Moment } from 'moment';

export interface IInfotrackStatusFilterState {
	isFetching: boolean;
	list: InfoTrackStatusListItemDto[];

	request: Partial<ListRequest>;
	oldRequest: Partial<ListRequest>;

	cacheExpiry: Moment;
	// errrors
	error: DomainError;
}

export const initialState: IInfotrackStatusFilterState = {
	isFetching: false,
	list: null,
	request: {},
	oldRequest: null,
	error: null,
	cacheExpiry: null
};
