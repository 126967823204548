import { Component } from '@angular/core';

import { BaseEditableComponent } from './base.editable.component';

@Component({
	selector: 'editable-date',
	styleUrls: ['./base.editable.component.scss'],
	template: `
		<span *ngIf="!editMode" [class]="value() ? '' : 'view-noitem'">
			{{ formatDisplay(value() ? (value() | moment : 'dd/MM/yyyy') : blankMessage) }}
		</span>
		<popup-datepicker
			*ngIf="editMode"
			[Required]="required"
			[placeHolder]="placeHolder"
			[FormControl]="formControl"
			[hintText]="hintText"
		></popup-datepicker>
	`
})
export class EditableDateComponent extends BaseEditableComponent<Date> {}
