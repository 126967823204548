import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { CostCodesService } from '@common/services/settings/costcodes.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash-es';
import moment from 'moment';

import { IAppState } from '../../app.state';
import { CostCodeFilterActions, loading } from './cost-code-filter.actions';

@Injectable()
export class CostCodeFilterEffects {
	loadCostCodes$ = createEffect(() =>
		this._actions$.pipe(
			ofType(CostCodeFilterActions.Load, CostCodeFilterActions.SetFilters, CostCodeFilterActions.InvalidateCache),
			map(action => action as { type: CostCodeFilterActions }),
			withLatestFrom(this._store),
			filter(([action, state]) => {
				const costCodeState = state.costCodeFilterData;

				if (action.type === CostCodeFilterActions.InvalidateCache && !costCodeState.list) {
					return false;
				}

				return (
					!costCodeState.cacheExpiry ||
					!!moment().isSameOrAfter(costCodeState.cacheExpiry) ||
					!isEqual(costCodeState.oldRequest, costCodeState.request)
				);
			}),
			debounceTime(500),
			tap(() => this._store.dispatch(loading())),
			switchMap(([_, state]) =>
				this._costCodesService.getCostCodeList(state.costCodeFilterData.request).pipe(
					map(response => ({
						type: CostCodeFilterActions.LoadSuccess,
						response
					})),
					catchError(error => of({ type: CostCodeFilterActions.LoadFailed, error }))
				)
			)
		)
	);

	constructor(
		private _actions$: Actions,
		private _store: Store<IAppState>,
		private _costCodesService: CostCodesService
	) {}
}
