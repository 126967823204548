import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn } from '@angular/forms';

import { Observable, of as ObservableOf, of, timer as timerObservable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { hasIllegalFileNameCharacters } from '@common/utils/fileNameUtil';
import { isEmptyOrNil } from '@common/utils/stringUtils';
import { Store } from '@ngrx/store';
import { get, isNil, uniq } from 'lodash';

import { ITenantCompanyState } from '../../main/src/app/core/state/misc/tenant-company/tenant-company.state';
import { IAbnAcnValidationInterface } from './abn-acn-validation.interface';
import { IPhoneValidationInterface } from './phone-validation.interface';

// Copied from https://github.com/angular/angular/blob/5.0.0/packages/forms/src/validators.ts#L44
// tslint:disable-next-line:max-line-length
const emailRegex =
	/^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;

const urlRegex = /^(https?:\/\/)?([a-z0-9]+\.)+[a-z0-9]+[a-z0-9\-\/]*$/i;

export class CustomValidators {
	static readonly containsDateRegex = /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])/;

	static required(fieldName?: string): ValidatorFn {
		return (control: AbstractControl): ValidationErrors => {
			return isEmptyOrNil(control.value)
				? { customRequired: `${fieldName || 'This field'} must be entered` }
				: null;
		};
	}

	static requiredMessage(message?: string): ValidatorFn {
		return (control: AbstractControl): ValidationErrors => {
			return isEmptyOrNil(control.value) ? { customRequired: message || 'This field is required' } : null;
		};
	}

	static requiredWhen(condition: () => boolean, fieldName?: string): ValidatorFn {
		return (control: AbstractControl): ValidationErrors => {
			return condition() ? this.required(fieldName)(control) : null;
		};
	}

	static requiredWhenMessage(condition: () => boolean, message?: string): ValidatorFn {
		return (control: AbstractControl): ValidationErrors => {
			return condition() ? this.requiredMessage(message)(control) : null;
		};
	}

	static validateWhen(condition: () => boolean, validateFunc: () => boolean, errorMessage: string = ''): ValidatorFn {
		return (control: AbstractControl): ValidationErrors => {
			if (!condition()) return null;
			return validateFunc() ? null : { nonEmpty: errorMessage ? errorMessage : 'Invalid input' };
		};
	}

	static email(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors => {
			return emailRegex.test(control.value) ? null : { customEmail: 'Invalid email address' };
		};
	}

	static positiveNumber(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors => {
			if (isNil(control.value)) return null;
			return control.value > 0 ? null : { customEmail: 'Enter a positive value' };
		};
	}

	static lessThanControlValue(ctrlName: string): ValidatorFn {
		return (control: AbstractControl): ValidationErrors => {
			if (!control || !control.parent) return null;
			const dependentCtrl = control.parent.get(ctrlName);

			return !dependentCtrl ||
				isEmptyOrNil(dependentCtrl.value) ||
				isEmptyOrNil(control.value) ||
				control.value <= dependentCtrl.value
				? null
				: { customMax: `Cannot be greater than ${dependentCtrl.value}` };
		};
	}

	static lessThanOrEqualTo(max: number, message?: string): ValidatorFn {
		return (control: AbstractControl): ValidationErrors => {
			if (!control || !control.parent) return null;
			if (control.value < 0) return { errorMessage: `Enter a positive number` };
			return isEmptyOrNil(control.value) || control.value <= max
				? null
				: { customMax: message ? message : `Cannot be greater than ${max}` };
		};
	}

	static greaterThan(greaterThan: number, message?: string): ValidatorFn {
		return (control: AbstractControl): ValidationErrors => {
			if (!control || !control.parent) return null;
			return isEmptyOrNil(control.value) || control.value > greaterThan
				? null
				: { customMax: message ? message : `Enter value greater than ${greaterThan}` };
		};
	}

	static optionalEmail(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors => {
			return !isEmptyOrNil(control.value) && this.email()(control);
		};
	}

	static url(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors => {
			return urlRegex.test(control.value) ? null : { customUrl: 'Invalid URL' };
		};
	}

	static optionalUrl(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors => {
			return !isEmptyOrNil(control.value) && this.url()(control);
		};
	}

	static pattern(regex: RegExp, errorMessage: string = ''): ValidatorFn {
		return (control: AbstractControl): ValidationErrors => {
			return isEmptyOrNil(control.value) || regex.test(control.value)
				? null
				: { customPattern: errorMessage ? errorMessage : 'Invalid input' };
		};
	}

	static afterDate(afterDate: Date): ValidatorFn {
		return (control: AbstractControl): ValidationErrors => {
			if (!control || !control.parent || !control.value) {
				return null;
			}

			if (new Date(control.value) < afterDate) {
				return { completionDate: `Must be after ${afterDate}` };
			}
			return null;
		};
	}

	static estimatedCompletionDate(openDate: string): ValidatorFn {
		return (control: AbstractControl): ValidationErrors => {
			if (!control || !control.parent || !control.value) {
				return null;
			}
			const openDateControl = control.parent.get(openDate);

			if (control.value < openDateControl.value) {
				return { completionDate: `Must be after ${new Date(openDateControl.value).toDateString()}` };
			}
			return null;
		};
	}

	static uniqueArrayField(fieldName?: string, errorMessage: string = ''): ValidatorFn {
		return (control: AbstractControl): ValidationErrors => {
			const uniqueValues = uniq(control.value.map((item: any) => (!!fieldName ? item[fieldName] : item)));
			if (uniqueValues.length !== control.value.length) {
				return { uniqueArrayField: errorMessage ? errorMessage : 'Values must be unique' };
			} else {
				return null;
			}
		};
	}

	static minLengthArray(length: number): ValidatorFn {
		return (control: AbstractControl): ValidationErrors => {
			if ((control?.value?.length ?? 0) < length) {
				return { nonEmpty: `At least ${length} value must be entered` };
			} else {
				return null;
			}
		};
	}

	static nonEmptyArray(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors => {
			if (isEmptyOrNil(control.value)) {
				return { nonEmpty: 'At least one value must be entered' };
			} else {
				return null;
			}
		};
	}

	static nonEmptyArrayWhen(condition: () => boolean): ValidatorFn {
		return (control: AbstractControl): ValidationErrors => {
			return condition() ? this.nonEmptyArray()(control) : null;
		};
	}

	/* Async validatation of the phone number
	   Params:
			contactService - service containing the 'isPhoneValid()' method
	*/
	static validatePhoneNumber(
		contactService: IPhoneValidationInterface,
		store: Store<{ tenantCompanyData: ITenantCompanyState }>
	): AsyncValidatorFn {
		return (control: AbstractControl): Observable<ValidationErrors | null> => {
			return timerObservable(500).pipe(
				switchMap(() => {
					return !store || isNil(store)
						? of(null as string)
						: store.select(state => state?.tenantCompanyData?.regionCode).pipe(take(1));
				}),
				switchMap(regionCode => {
					return !get(control, 'value')
						? ObservableOf(true)
						: contactService.isPhoneValid({
								phoneNumber: control.value,
								regionCode: regionCode || 'AU'
						  });
				}),
				switchMap(res => {
					return ObservableOf(res ? null : { nonEmpty: 'Incorrect phone' });
				})
			);
		};
	}

	static validateABN(contactService: IAbnAcnValidationInterface): AsyncValidatorFn {
		return (control: AbstractControl): Observable<ValidationErrors | null> => {
			return timerObservable(500).pipe(
				switchMap(() =>
					!get(control, 'value')
						? ObservableOf(true)
						: contactService.isAbnValid({ regNumber: control.value })
				),
				switchMap(res => ObservableOf(res ? null : { nonEmpty: 'Incorrect ABN format' }))
			);
		};
	}

	static validateACN(contactService: IAbnAcnValidationInterface): AsyncValidatorFn {
		return (control: AbstractControl): Observable<ValidationErrors | null> => {
			return timerObservable(500).pipe(
				switchMap(() =>
					!get(control, 'value')
						? ObservableOf(true)
						: contactService.isAcnValid({ regNumber: control.value })
				),
				switchMap(res => ObservableOf(res ? null : { nonEmpty: 'Incorrect ACN format' }))
			);
		};
	}

	static validateFileName(errorMessage: string = ''): ValidatorFn {
		return (control: AbstractControl): ValidationErrors => {
			return isEmptyOrNil(control.value) || !hasIllegalFileNameCharacters(control.value)
				? null
				: {
						customPattern: errorMessage
							? errorMessage
							: 'The title can\'t contain any of the characters \\ / : * ? " < > |'
				  };
		};
	}

	static validateDomain(errorMessage: string = ''): ValidatorFn {
		return this.pattern(
			/^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/i,
			errorMessage ? errorMessage : 'Invalid Domain or Subdomain'
		);
	}

	static isTrue(fieldName?: string, message?: string): ValidatorFn {
		return (control: AbstractControl): ValidationErrors => {
			return control.value === true
				? null
				: { customRequired: message || `${fieldName || 'This field'} must be checked` };
		};
	}
}
