<mat-checkbox [formControl]="showSelectionListControl">
	<div class="checkbox-body">
		<span>Restrict Contact Selection</span>
		<span class="checkbox-hint"
			>The contact selection list will be displayed for selection on the
			{{ entityType === 'Matter' ? 'matter contacts page' : 'contact details page' }}</span
		>
	</div>
</mat-checkbox>

<ng-container *ngIf="showSelectionList">
	<h3 mat-subheader>Contact Selection List</h3>
	<div [formGroup]="formGroup" class="table">
		<ng-container formArrayName="selectionList">
			<ng-container *ngFor="let row of selectionList.controls; index as i; let first = first; let last = last">
				<div class="flex-row">
					<contact-lookup Placeholder="(value required)" [FormControl]="row" [floatLabel]="false"></contact-lookup>

					<button mat-icon-button title="Move up" throttleButton (throttledClick)="moveUp(i)" [hidden]="first">
						<mat-icon>arrow_upward</mat-icon>
					</button>
					<button mat-icon-button title="Move down" throttleButton (throttledClick)="moveDown(i)" [hidden]="last">
						<mat-icon>arrow_downward</mat-icon>
					</button>
					<button mat-icon-button title="Delete" throttleButton (throttledClick)="delete(i)">
						<mat-icon>delete</mat-icon>
					</button>
				</div>
			</ng-container>
		</ng-container>

		<!-- duplicate the layout for the 'new entry' row at the bottom, to make sure it displays the same as the
		 other rows, despite showing different buttons. -->
		<div class="flex-row">
			<!-- Don't show the label regardless of whether the user has entered anything or not -->
			<contact-lookup
				#newEntryContactLookup
				Placeholder="(new value)"
				[FormControl]="this.formGroup.get('contactSelectionListEntryName')"
				[floatLabel]="false"
			></contact-lookup>

			<button mat-icon-button title="Add" [disabled]="!this.formGroup.get('contactSelectionListEntryName')?.value">
				<mat-icon>add_circle</mat-icon>
			</button>
			<button mat-icon-button title="Move down" [hidden]="true">
				<mat-icon>arrow_downward</mat-icon>
			</button>
			<button mat-icon-button title="Delete" [hidden]="true">
				<mat-icon>delete</mat-icon>
			</button>
		</div>
		<mat-error *ngIf="selectionList.touched">
			<error-messages [for]="selectionList"></error-messages>
		</mat-error>
	</div>
</ng-container>
