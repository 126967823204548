<div class="container">
	<mat-card>
		<mat-card-header>
			<mat-card-title>
				<mat-card-title>Feature Flag Configuration</mat-card-title>
				<mat-card-subtitle>Config Values are stored in local browser storage</mat-card-subtitle>
			</mat-card-title>
		</mat-card-header>
		<mat-card-content>
			<div class="switchbox">
				<div class="switchbox__row" *ngFor="let key of modelKeys">
					<div>
						{{ getKeyDisplayName(key) }}
					</div>
					<div>
						<mat-slide-toggle [checked]="getDisplayModelValue(key)" (toggleChange)="toggle(key)"></mat-slide-toggle>
						<div class="switchbox__row__reset-button-container">
							<button *ngIf="isKeyPersisted(key)" mat-icon-button class="reset" throttleButton (throttledClick)="resetValue(key)">
								<mat-icon matTooltip="Reset">settings_backup_restore</mat-icon>
							</button>
						</div>
					</div>
				</div>
			</div>
		</mat-card-content>
	</mat-card>
</div>
