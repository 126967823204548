import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';

import { Subscription } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

import { BasicWorkflowViewDto } from '@common/models/Settings/BasicWorkflows/Item/BasicWorkflowViewDto';
import { CollectionTaskTemplateViewDto } from '@common/models/Settings/TaskTemplates/Item/CollectionTaskTemplateViewDto';
import { DocumentMergeTaskTemplateViewDto } from '@common/models/Settings/TaskTemplates/Item/DocumentMergeTaskTemplateViewDto';
import { GenericTaskTemplateViewDto } from '@common/models/Settings/TaskTemplates/Item/GenericTaskTemplateViewDto';
import { TaskTemplateListItemDto } from '@common/models/Settings/TaskTemplates/List/TaskTemplateListItemDto';
import { TaskTemplateListRequest } from '@common/models/Settings/TaskTemplates/List/TaskTemplateListRequest';
import { NotificationService } from '@common/notification';
import { TaskTemplateService } from '@common/services/settings/tasktemplate.service';
import { sortBy } from 'lodash-es';

import { ITaskTemplateItemComponentData, TaskTemplateItemComponent } from '../item/task-template-item.component';

@Component({
	selector: 'task-template-list',
	templateUrl: 'task-template-list.component.html',
	styleUrls: ['task-template-list.component.scss']
})
export class TaskTemplatesListComponent implements OnInit {
	@ViewChild(MatTable) table: MatTable<TaskTemplateListItemDto>;
	@Input() workflow: BasicWorkflowViewDto = null;
	dragDisabled: boolean = false;
	datasource: TaskTemplateListItemDto[] = [];
	subscriptions = new Subscription();
	displayedColumns: string[] = [
		'description',
		'type',
		'assignedToContactDisplayName',
		'status',
		'priority',
		'action'
	];

	constructor(
		private _dialog: MatDialog,
		private _notificationService: NotificationService,
		private _taskTemplateService: TaskTemplateService
	) {}

	ngOnInit(): void {
		this.subscriptions.add(this.refreshList$().subscribe());
	}

	create() {
		this.openTaskTemplateItemComponent(null);
	}

	edit(row: TaskTemplateListItemDto) {
		this.openTaskTemplateItemComponent(row);
	}

	remove(row: TaskTemplateListItemDto) {
		this.subscriptions.add(
			this._notificationService
				.showConfirmation('Delete Workflow Task', `Are you sure you want to delete this Workflow Task"?`)
				.pipe(
					filter(Boolean),
					switchMap(() => {
						return row.taskType === 'Generic'
							? this._taskTemplateService.deleteTaskTemplate(row.id)
							: row.taskType === 'CollectionTask'
							? this._taskTemplateService.deleteCollectionTaskTemplate(row.id)
							: this._taskTemplateService.deleteDocumentMergeTaskTemplate(row.id);
					}),
					switchMap(() => this.refreshList$())
				)
				.subscribe({
					next: () => {
						this._notificationService.showNotification(`Workflow Task deleted`);
					},
					error: error => this._notificationService.showErrors('Error deleting Workflow Task', error)
				})
		);
	}

	private openTaskTemplateItemComponent(dto: TaskTemplateListItemDto) {
		const data: ITaskTemplateItemComponentData = {
			model: !dto
				? null
				: dto.taskType === 'Generic'
				? new GenericTaskTemplateViewDto()
				: dto.taskType === 'CollectionTask'
				? new CollectionTaskTemplateViewDto()
				: new DocumentMergeTaskTemplateViewDto(),
			basicWorkflowRef: { id: this.workflow.id, name: this.workflow.name },
			workFlowType: this.workflow.workflowType,
			practiceAreaIds: this.workflow.practiceAreas?.map(area => area.id)
		};

		if (!!dto?.id) {
			const api$ =
				dto.taskType === 'Generic'
					? this._taskTemplateService.getTaskTemplateDto(dto.id)
					: dto.taskType === 'CollectionTask'
					? this._taskTemplateService.getCollectionTaskTemplateDto(dto.id)
					: this._taskTemplateService.getDocumentMergeTaskTemplateDto(dto.id);
			this.subscriptions.add(
				api$
					.pipe(
						switchMap(x => {
							if (x) data.model = x;

							return this._dialog
								.open(TaskTemplateItemComponent, { data })
								.afterClosed()
								.pipe(filter(Boolean));
						}),
						switchMap(() => this.refreshList$())
					)
					.subscribe()
			);
		} else {
			this.subscriptions.add(
				this._dialog
					.open(TaskTemplateItemComponent, { data })
					.afterClosed()
					.pipe(
						filter(Boolean),
						switchMap(() => this.refreshList$())
					)
					.subscribe()
			);
		}
	}

	dropRow(event: CdkDragDrop<string[]>) {
		this.dragDisabled = true;
		if (event.previousIndex === event.currentIndex) {
			this.dragDisabled = false;
			return;
		}
		if (event.previousContainer === event.container) {
			moveItemInArray(this.datasource, event.previousIndex, event.currentIndex);

			this.subscriptions.add(
				this.updateTemplateOrderingOperation$().subscribe({
					next: () => {
						this.dragDisabled = false;
						this._notificationService.showNotification('Task order saved');
					},
					error: error => {
						this.dragDisabled = false;
						this._notificationService.showErrors('Reordering Error', error);
						// Revert the Drag Drop operation in case there is an error on the server
						moveItemInArray(this.datasource, event.currentIndex, event.previousIndex);
						setTimeout(() => {
							this.table.renderRows();
						}, 0);
					}
				})
			);
		}

		// Table needs to be re rendered for the drag drop to take effect.
		setTimeout(() => {
			this.table.renderRows();
		}, 0);
	}

	updateTemplateOrderingOperation$() {
		this.datasource = this.datasource.map((x, i) => {
			x.orderNumber = i + 1;
			return x;
		});

		var ordering = this.datasource.map((x, i) => ({ taskTemplateId: x.id, orderNumber: i }));

		return this._taskTemplateService.updateTaskTemplateOrdering({ taskTemplateOrdering: ordering });
	}

	private refreshList$() {
		const request: Partial<TaskTemplateListRequest> = {
			basicWorkflowIds: [this.workflow.id]
		};

		return this._taskTemplateService.getTaskTemplateList(request).pipe(
			tap(taskTemplates => {
				this.datasource = sortBy([...taskTemplates.records] as TaskTemplateListItemDto[], x => x.orderNumber);
			})
		);
	}
}
