// This file has been generated from CostCodesController.cs
// Do not modify this file directly!!
// tslint:disable

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { TrimStringsOnObject } from '@common/utils/string-format';

import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';
import { ListResponse } from '../../models/Generic/ListResponse';
import { CostCodeDto } from '../../models/Settings/CostCodes/Item/CostCodeDto';
import { CostCodeListItemDto } from '../../models/Settings/CostCodes/List/CostCodeListItemDto';
import { CostCodeListRequest } from '../../models/Settings/CostCodes/List/CostCodeListRequest';
import { CostCodeLookupDto } from '../../models/Settings/CostCodes/Lookup/CostCodeLookupDto';

@Injectable({
	providedIn: 'root'
})
export class CostCodesService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'api/v1/settings/CostCodes', 'Cost Code');
	}

	getCostCode(id: string): Observable<CostCodeDto> {
		return this.getItem<void, CostCodeDto>(`${id}`);
	}

	getCostCodeList(request?: Partial<CostCodeListRequest>): Observable<ListResponse<CostCodeListItemDto>> {
		return this.getList<CostCodeListRequest, CostCodeListItemDto>('', request);
	}

	createCostCode(newCostCodeDto: CostCodeDto): Observable<MutationResponseDto> {
		return this.post<CostCodeDto, MutationResponseDto>(
			'',
			TransformDatesOnObject(CostCodeDto, TrimStringsOnObject(newCostCodeDto))
		);
	}

	updateCostCode(id: string, costCodeDto: CostCodeDto): Observable<MutationResponseDto> {
		return this.put(`${id}`, TransformDatesOnObject(CostCodeDto, TrimStringsOnObject(costCodeDto)));
	}

	deleteCostCode(id: string): Observable<MutationResponseDto> {
		return this.delete(`${id}`);
	}

	lookupItem(id: string): Observable<CostCodeLookupDto> {
		return this.getItem<void, CostCodeLookupDto>(`${id}/lookup`);
	}
}
