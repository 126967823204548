import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	styleUrls: ['./trust-opening-balance-receipt-dialog.scss'],
	template: `
		<h2 mat-dialog-title>Create Opening Balance Receipt</h2>
		<mat-dialog-content>
			<trust-opening-balance-receipt
				#control
				[editId]="data.trustRecordId"
				[trustAccountId]="data.trustAccountId"
				[isReadOnly]="false"
				(dialogCloseRequested)="onDialogCloseRequested()"
			>
			</trust-opening-balance-receipt>
		</mat-dialog-content>
		<mat-dialog-actions>
			<button
				mat-raised-button
				color="primary"
				[disabled]="control.form.invalid || control.saveClicked"
				throttleButton
				(throttledClick)="control.onSave()"
			>
				Save
			</button>
			<button mat-raised-button mat-dialog-close>Cancel</button>
		</mat-dialog-actions>
	`
})
export class TrustOpeningBalanceDialogComponent {
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: ITrustOpeningBalanceDialogParams,
		private dialogRef: MatDialogRef<TrustOpeningBalanceDialogComponent>
	) {
		//
	}

	onDialogCloseRequested() {
		this.dialogRef.close();
	}
}

export interface ITrustOpeningBalanceDialogParams {
	trustAccountId: string;
	trustRecordId: string;
}
