import { createSelector, MemoizedSelector } from '@ngrx/store';

import { MatterCustomFieldListItemDto } from '@common/models/Settings/CustomFields/List/MatterCustomFieldListItemDto';
import { IAppState } from '../../../app.state';

export const selectMatterCustomFieldList = (state: IAppState) => state.matterCustomFieldList;

export const selectMatterCustomFieldListRecords: MemoizedSelector<IAppState, MatterCustomFieldListItemDto[]> =
	createSelector(selectMatterCustomFieldList, state => state?.list?.records || []);
export const selectMatterCustomFieldListRequest: MemoizedSelector<IAppState, Partial<any>> = createSelector(
	selectMatterCustomFieldList,
	state => state?.request
);
export const selectMatterCustomFieldListPageIndex = createSelector(
	selectMatterCustomFieldList,
	state => state?.list?.pageIndex || 0
);
export const selectMatterCustomFieldListTotalRecords = createSelector(
	selectMatterCustomFieldList,
	state => state?.list?.totalRecords || 0
);
export const selectMatterCustomFieldIsFetching = createSelector(
	selectMatterCustomFieldList,
	state => !!state?.isFetching
);
