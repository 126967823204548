<mat-card>
	<mat-icon class="green-icon">check_circle</mat-icon>

	<div class="description">
		<div class="heading">Success</div>
		<div class="sub-heading">Your Payment has been successful.</div>

		<div>To change your plan, or update your payment details, select "Account and Billing" from the System page.</div>
	</div>

	<button mat-raised-button color="accent" mat-dialog-close throttleButton (throttledClick)="close()">OK</button>
</mat-card>
