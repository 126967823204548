import { SortDirection } from '@angular/material/sort';

import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';
import { DocumentListItemDto } from '@common/models/Documents/List/DocumentListItemDto';
import { DocumentListRequest } from '@common/models/Documents/List/DocumentListRequest';
import { ListResponse } from '@common/models/Generic/ListResponse';
import { DomainError } from '@common/models/Validation/DomainError';
import { createAction, props } from '@ngrx/store';

import { SelectionType } from '../../SelectionType';

export enum DocumentListActions {
	Init = '[Document List] Initialise',
	Load = '[Document List] Load',
	Loading = '[Document List] Loading',
	LoadSuccess = '[Document List] Load Success',
	LoadFailed = '[Document List] Load Failed',

	SetFilters = '[Document List] Set Filters',
	SetFilterProp = '[Document List] Set Filter Property',
	SetPageSize = '[Document List] Set Page Size',
	SetPageIndex = '[Document List] Set Page Index',
	SetPageIndexForId = '[Document List] Set Page Index For Id',

	SetSortBy = '[Document List] Set Sort By',
	SetSortDirection = '[Document List] Set Sort Direction',

	ProcessRecords = '[Document List] Process Records',
	SelectRecords = '[Document List] Select Records'
}

export const init = createAction(DocumentListActions.Init);
export const load = createAction(DocumentListActions.Load);
export const loading = createAction(DocumentListActions.Loading);
export const loadSuccess = createAction(
	DocumentListActions.LoadSuccess,
	props<{ response: ListResponse<DocumentListItemDto> }>()
);
export const loadFailed = createAction(DocumentListActions.LoadFailed, props<{ error: DomainError }>());

export const setFilters = createAction(
	DocumentListActions.SetFilters,
	props<{ filter: Partial<DocumentListRequest> }>()
);
export const setFilterProp = createAction(
	DocumentListActions.SetFilterProp,
	props<{ prop: keyof DocumentListItemDto; value: any }>()
);
export const setPageSize = createAction(DocumentListActions.SetPageSize, props<{ pageSize: number }>());
export const setPageIndex = createAction(DocumentListActions.SetPageIndex, props<{ pageIndex: number }>());
export const setPageIndexForId = createAction(DocumentListActions.SetPageIndexForId, props<{ id: string }>());

export const setSortBy = createAction(DocumentListActions.SetSortBy, props<{ sortBy: string }>());
export const setSortDirection = createAction(
	DocumentListActions.SetSortDirection,
	props<{ sortDirection: SortDirection }>()
);

export const processRecords = createAction(
	DocumentListActions.ProcessRecords,
	props<{ response: MutationResponseDto | MutationResponseDto[] }>()
);

export const selectRecords = createAction(
	DocumentListActions.SelectRecords,
	props<{ row: DocumentListItemDto; selectionType: SelectionType }>()
);
