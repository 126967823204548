import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ApplicationInsightsService } from '@common/application-insights/application-insights.service';
import { AccessTokenRequestDto } from '@common/models/Users/Item/AccessTokenRequestDto';
import { UserInfoTrackTokenDto } from '@common/models/Users/Item/UserInfoTrackTokenDto';
import { NotificationService } from '@common/notification';
import { InfoTrackService } from '@common/services/infotrack.service';

import { AuthService } from 'app/core/auth.service';
import { InfoTrackConnectComponent } from 'app/matter/item/infoTrack/infotrack-connect.component';

@Component({
	template: `<infotrack-connect #infoTrackConnect></infotrack-connect>`
})
export class InfoTrackAuthHandlerComponent implements OnInit, OnDestroy {
	private subscriptions = new Subscription();
	private matterId: string;

	@ViewChild('infoTrackConnect') infoTrackConnectHelper: InfoTrackConnectComponent;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private infoTrackService: InfoTrackService,
		private notificationService: NotificationService,
		private appInsights: ApplicationInsightsService,
		private authService: AuthService
	) {}

	ngOnInit() {
		this.subscriptions.add(
			this.activatedRoute.queryParams
				.pipe(
					switchMap(params => {
						const authorizationCode = params['code'];
						this.matterId = params['state'];
						const accessTokenRequestDto: AccessTokenRequestDto = { authorizationCode };
						return this.infoTrackService.getNewAccessTokenWithAuthCode(accessTokenRequestDto);
					})
				)
				.subscribe({
					next: (setting: UserInfoTrackTokenDto) => {
						if (!setting) this.handleError(`Error saving infotrack tokens.`);
						if (!!this.matterId) this.router.navigate([`/matters/${this.matterId}/infotrack`]);
						else this.router.navigate([`/system/users/${this.authService.UserId}/auth`]);
					},
					error: error => {
						this.handleError(`Error saving infotrack tokens: ${error}`);
						if (!!this.matterId) this.router.navigate([`/matters/${this.matterId}/infotrack?error=true`]);
						else this.router.navigate([`/system/users/${this.authService.UserId}/auth`]);
					}
				})
		);
	}

	handleError(error: any) {
		this.subscriptions.add(
			this.notificationService
				.showError('Failed to connect', 'Cannot connect to InfoTrack at this time. Please try again later.')
				.subscribe()
		);
		this.logError(error);
	}

	logError(error: any) {
		const e: Error = { message: error.message, name: error.name, stack: error.error || error };
		this.appInsights.trackException(e);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}
}
