<h2 mat-dialog-title class="title">{{ editId ? 'Edit' : 'Create' }} Document Tag</h2>
<mat-dialog-content class="flex-column" [formGroup]="form">
	<div class="flex-row">
		<mat-form-field>
			<input #defaultFocusInput formControlName="name" matInput autocomplete="off" />
			<mat-hint>Should begin with a letter or a number and can only contain alphanumeric characters, spaces and hyphens</mat-hint>
			<mat-placeholder
				>Name
				<sup class="color-warn">*</sup>
			</mat-placeholder>
			<mat-error>
				<error-messages [for]="form.get('name')"></error-messages>
			</mat-error>
		</mat-form-field>
	</div>
	<div class="flex-row">
		<mat-form-field>
			<mat-placeholder>Practice Areas</mat-placeholder>
			<mat-select #selectPracticeAreas formControlName="practiceAreaIds" multiple
				><mat-select-trigger>{{ displaySelected(selectPracticeAreas.value) }}</mat-select-trigger>
				<mat-option (onSelectionChange)="allOptionSelected($event)" #allOption>All</mat-option>
				<mat-option
					(onSelectionChange)="optionSelected($event, selectPracticeAreas)"
					*ngFor="let practiceArea of practiceAreas"
					[value]="practiceArea.id"
				>
					<div class="c-practice-area-option">
						<span class="c-practice-area-option__title">{{ practiceArea.name }}</span>
						<span class="c-practice-area-option__disabled" *ngIf="!!practiceArea.isDisabled">Disabled</span>
					</div>
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-raised-button color="primary" [mat-dialog-close]="dialogResult" [disabled]="form.invalid">Ok</button>
	<button mat-raised-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
