<mat-dialog-content>
	<h2 mat-dialog-title class="title">Create Matter</h2>
	<div [formGroup]="form">
		<div class="flex-column">
			<div class="flex-row">
				<mat-form-field>
					<input #titleInput formControlName="title" matInput autocomplete="off" />
					<mat-placeholder
						>Title
						<sup class="color-warn">*</sup>
					</mat-placeholder>
					<mat-error>
						<error-messages [for]="form.get('title')"></error-messages>
					</mat-error>
				</mat-form-field>
			</div>
			<div class="flex-row">
				<mat-form-field>
					<textarea formControlName="description" matInput cdkTextareaAutosize placeholder="Description" [cdkAutosizeMinRows]="2"></textarea>
					<mat-error>
						<error-messages [for]="form.get('description')"></error-messages>
					</mat-error>
				</mat-form-field>
			</div>
			<div class="flex-row">
				<mat-form-field>
					<mat-placeholder
						>Practice Area
						<sup class="color-warn">*</sup>
					</mat-placeholder>
					<mat-select formControlName="practiceAreaId" (selectionChange)="practiceAreaChanged($event)">
						<mat-option *ngFor="let area of practiceAreas" [value]="area.id">{{ area.name }}</mat-option>
					</mat-select>
					<mat-error>
						<error-messages [for]="form.get('practiceAreaId')"></error-messages>
					</mat-error>
				</mat-form-field>
				<mat-form-field>
					<mat-placeholder
						>Stage
						<sup class="color-warn">*</sup>
					</mat-placeholder>
					<mat-select formControlName="stageId">
						<mat-option *ngFor="let stage of stages" [value]="stage.id">{{ stage.name }}</mat-option>
					</mat-select>
					<mat-error>
						<error-messages [for]="form.get('stageId')"></error-messages>
					</mat-error>
				</mat-form-field>
			</div>
			<div class="flex-row">
				<mat-form-field>
					<input formControlName="dateOpen" matInput [matDatepicker]="dateOpenPicker" placeholder="Open Date" autocomplete="off" />
					<mat-datepicker-toggle matSuffix [for]="dateOpenPicker"></mat-datepicker-toggle>
					<mat-datepicker #dateOpenPicker></mat-datepicker>
					<mat-error>
						<error-messages [for]="form.get('dateOpen')"></error-messages>
					</mat-error>
				</mat-form-field>
				<mat-form-field>
					<input
						formControlName="dateEstimatedCompletion"
						matInput
						[matDatepicker]="dateEndPicker"
						placeholder="Estimated Completion Date"
						autocomplete="off"
					/>
					<mat-datepicker-toggle matSuffix [for]="dateEndPicker"></mat-datepicker-toggle>
					<mat-datepicker #dateEndPicker></mat-datepicker>
					<mat-error>
						<error-messages [for]="form.get('dateEstimatedCompletion')"></error-messages>
					</mat-error>
				</mat-form-field>
			</div>
			<div class="flex-row">
				<div>
					<contact-lookup
						#clientLookup
						[FormControl]="form.get('clientId')"
						[AllowCreate]="true"
						[CreatePersonsCtrl]="form.get('createPersons')"
						[CreateCompaniesCtrl]="form.get('createCompanies')"
						[CreatePluralCtrl]="form.get('createPlurals')"
						(Selected)="onContactSelected($event)"
						[Required]="true"
						Placeholder="Client"
					></contact-lookup>
				</div>

				<contact-lookup #lawyerLookup [StaffOnly]="true" [Required]="true" [FormControl]="form.get('lawyerId')" Placeholder="Lawyer"></contact-lookup>
			</div>
			<div class="flex-row">
				<mat-form-field>
					<mat-placeholder
						>Costing Method
						<sup class="color-warn">*</sup>
					</mat-placeholder>
					<mat-select formControlName="costingMethod" (selectionChange)="costingMethodChanged()">
						<mat-option *ngFor="let key of costingMethodKeys" [value]="key">{{ key | costingMethod }}</mat-option>
					</mat-select>
					<mat-error>
						<error-messages [for]="form.get('costingMethod')"></error-messages>
					</mat-error>
				</mat-form-field>
				<mat-form-field *ngIf="isCostingMethodCostTemplate">
					<mat-placeholder
						>Cost Template
						<sup class="color-warn">*</sup>
					</mat-placeholder>
					<mat-select formControlName="costTemplateId">
						<mat-option *ngFor="let option of costTemplates" [value]="option.id">{{ option.name }}</mat-option>
					</mat-select>
					<mat-error> <error-messages [for]="form.get('costTemplateId')"></error-messages> </mat-error>
				</mat-form-field>
				<mat-form-field *ngIf="!isCostingMethodCostTemplate">
					<input formControlName="estimatedFee" matInput placeholder="Fee Estimate" type="number" autocomplete="off" />
					<span matPrefix *ngIf="form.get('estimatedFee').value">$</span>
					<mat-error>
						<error-messages [for]="form.get('estimatedFee')"></error-messages>
					</mat-error>
				</mat-form-field>
			</div>
			<div class="flex-row">
				<mat-form-field *ngIf="isCostingMethodCostTemplate">
					<input formControlName="estimatedFee" matInput placeholder="Fee Estimate" type="number" autocomplete="off" />
					<span matPrefix *ngIf="form.get('estimatedFee').value">$</span>
					<mat-error>
						<error-messages [for]="form.get('estimatedFee')"></error-messages>
					</mat-error>
				</mat-form-field>
				<mat-slide-toggle *ngIf="showTaxType" formControlName="calculateTaxes">Apply Taxes on Time Records / Fees</mat-slide-toggle>
			</div>
		</div>
		<input-custom-fields
			[formGroup]="customFieldsControlGroup"
			[practiceAreaId]="currentPracticeAreaId"
			[useDefaults]="true"
			entityType="Matter"
		></input-custom-fields>
	</div>
	<h2 mat-dialog-title class="title">Team Members</h2>
	<div class="add-button">
		<button mat-button color="primary" throttleButton (throttledClick)="addMember()">
			<mat-icon>add_circle</mat-icon>
			<span>&nbsp;Add<span class="allocation-word">Team Member</span></span>
		</button>
	</div>
	<mat-table *ngIf="matterStaffMembers.data.length >=1" [dataSource]="matterStaffMembers" matSort matSortActive="name" matSortDirection="asc">
		<ng-container matColumnDef="staffName">
			<mat-header-cell *matHeaderCellDef mat-sort-heade>Name</mat-header-cell>
			<mat-cell *matCellDef="let row" class.mat-cell--is-last>
				<div>{{ row.contactReference.fullName }}</div>
			</mat-cell>
		</ng-container>
		<ng-container matColumnDef="type">
			<mat-header-cell *matHeaderCellDef mat-sort-header>User Type</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<div>{{ row.userType?.name }}<span *ngIf="!!row.isMain" class="main-user-type">Main</span></div>
			</mat-cell>
		</ng-container>
		<ng-container matColumnDef="rate">
			<mat-header-cell *matHeaderCellDef mat-sort-header>Charge Rate $/hr</mat-header-cell>
			<mat-cell *matCellDef="let row" class.mat-cell--is-last>
				<div *ngIf="!!row?.rate">{{ row.rate | currency : '$' }}</div>
			</mat-cell>
		</ng-container>
		<ng-container matColumnDef="actions">
			<mat-header-cell *matHeaderCellDef></mat-header-cell>
			<mat-cell *matCellDef="let row" class.mat-cell--is-last>
				<button mat-icon-button [matMenuTriggerFor]="menu" throttleButton (throttledClick)="removeMember(row)">
					<mat-icon>delete</mat-icon>
				</button>
			</mat-cell>
		</ng-container>
		<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
		<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
	</mat-table>
</mat-dialog-content>
<mat-dialog-actions>
	<button class="btn-save" mat-raised-button color="primary" [disabled]="form.invalid || saveClicked" throttleButton (throttledClick)="save()">Save</button>
	<button class="btn-save-open" mat-stroked-button color="primary" [disabled]="form.invalid || saveClicked" throttleButton (throttledClick)="saveAndOpen()">
		Save & Open
	</button>
	<button class="btn-cancel" mat-raised-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
