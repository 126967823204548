// This file has been generated from XeroDescriptionVariables.cs
// Do not modify this file directly!!

// LawMaster.Amalthea.Domain.Bills.Common.XeroDescriptionVariables
export enum XeroDescriptionVariables {
	Date = 'Date',
	Description = 'Description',
	MatterNumber = 'Matter Number',
	MatterTitle = 'Matter Title',
	User = 'User',
	Units = 'Units',
	UnitsWithLabel = 'Units with label',
	Duration = 'Duration',
	DurationWithLabel = 'Duration with label',
	NewLine = 'New Line',
	Hyphen = '—',
	Quantity = 'Quantity',
	QuantityWithLabel = 'Quantity with label',
	ChargeRate = 'Charge Rate',
	ChargeRateWithLabel = 'Charge Rate with label'
}
