// This file has been generated from XeroIntegrationSettings.cs
// Do not modify this file directly!!
// tslint:disable

import { SetComplexType } from '@common/utils/class-transform';

import { EntityReference } from '../../../Common/EntityReference';
import { XeroTenant } from './XeroTenant';

// LawMaster.Amalthea.Domain.Settings.BillSettings.Common.XeroIntegrationSettings
export class XeroIntegrationSettings {

	// XeroTenant
	@SetComplexType(XeroTenant)
	xeroTenant: XeroTenant;
	// TimeEntriesAccount
	@SetComplexType(EntityReference)
	timeEntriesAccount: EntityReference;
	// ExpensesAccount
	@SetComplexType(EntityReference)
	expensesAccount: EntityReference;
	// ExpensesExGstTaxType
	@SetComplexType(EntityReference)
	expensesExGstTaxType: EntityReference;
	// TimeEntriesExGstTaxType
	@SetComplexType(EntityReference)
	timeEntriesExGstTaxType: EntityReference;
	// NumberMonthsToBulkSync
	numberMonthsToBulkSync: number;
	// XeroLineItemDescriptionFormat
	xeroLineItemDescriptionFormat: string;
	// XeroInvoiceReferenceFormat
	xeroInvoiceReferenceFormat: string;

	constructor() {
		this.xeroTenant = new XeroTenant();
		this.timeEntriesAccount = new EntityReference();
		this.expensesAccount = new EntityReference();
		this.expensesExGstTaxType = new EntityReference();
		this.timeEntriesExGstTaxType = new EntityReference();
	}
}