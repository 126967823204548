<h2 mat-dialog-title class="title">{{ isCreateMode ? 'Create' : 'Edit' }} Trust Account</h2>
<mat-dialog-content [formGroup]="form" class="flex-column">
	<bank-details *ngIf="!data.id || bankDetails" [mainForm]="form" [bankDetails]="bankDetails" [isEditMode]="!!data.id"></bank-details>
	<matter-lookup
		#matterLookup
		class="related-to-entity"
		Placeholder="Statutory Matter"
		[Status]="'Open'"
		[FormControl]="form.get('statutoryMatterId')"
		[Hint]="'Allocated matter for Law Society trust records'"
	></matter-lookup>
	<editable-numeric-value
		#amount
		placeHolder="Trust Credit Days"
		[control]="form.get('trustCreditDays')"
		[isCurrency]="false"
		[integersOnly]="true"
		hintText="Number of days after invoice issue date that invoice payment can be made"
		[editMode]="true"
	></editable-numeric-value>

	<div class="flex-row">
		<mat-form-field>
			<mat-placeholder>Receipt Template </mat-placeholder>
			<mat-select formControlName="receiptTemplateId">
				<mat-option *ngFor="let template of receiptTemplates" [value]="template.id">{{ template.title }}</mat-option>
			</mat-select>
			<button
				*ngIf="form.controls.receiptTemplateId.value"
				matSuffix
				mat-icon-button
				type="button"
				aria-label="Clear"
				throttleButton
				(throttledClick)="form.controls.receiptTemplateId.setValue(null); $event.stopPropagation()"
			>
				<mat-icon>close</mat-icon>
			</button>
			<mat-error>
				<error-messages [for]="form.get('receiptTemplateId')"></error-messages>
			</mat-error>
		</mat-form-field>
	</div>

	<div class="flex-row">
		<mat-form-field>
			<mat-placeholder>Deposit Listing Template </mat-placeholder>
			<mat-select formControlName="depositTemplateId">
				<mat-option *ngFor="let template of depositTemplates" [value]="template.id">{{ template.title }}</mat-option>
			</mat-select>
			<button
				*ngIf="form.controls.depositTemplateId.value"
				matSuffix
				mat-icon-button
				type="button"
				aria-label="Clear"
				throttleButton
				(throttledClick)="form.controls.depositTemplateId.setValue(null); $event.stopPropagation()"
			>
				<mat-icon>close</mat-icon>
			</button>
			<mat-error>
				<error-messages [for]="form.get('depositTemplateId')"></error-messages>
			</mat-error>
		</mat-form-field>
	</div>

	<div *ngIf="!isCreateMode" class="flex-row">Current Balance: {{ currentBalance() | currency: '$' }}</div>

	<br />
</mat-dialog-content>
<mat-dialog-actions>
	<div>
		<button
			class="delete-btn"
			*ngIf="!isCreateMode"
			mat-stroked-button
			color="warn"
			[mat-dialog-close]="{ accountData: form.value, isDelete: true }"
			[disabled]="form.invalid"
		>
			Delete
		</button>
	</div>
	<div>
		<button mat-raised-button color="primary" [mat-dialog-close]="{ accountData: form.value, isDelete: false }" [disabled]="form.invalid">Save</button>
		<button mat-raised-button mat-dialog-close>Cancel</button>
	</div>
</mat-dialog-actions>
