import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import { UserCurrentService } from '@common/services/usercurrent.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ExternalPortalRouteService } from '../../../main/src/app/services/external-portal-route-service';
import { CurrentUserActions, loadCurrentUserSuccess } from '../actions/current-user.actions';

@Injectable()
export class CurrentUserEffects {
	loadCurrentUser$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CurrentUserActions.LoadCurrentUser),
			exhaustMap(() =>
				this.portalRouteService.isUserRoute
					? this.userCurrentService.getCurrentUser().pipe(
							map(userViewDto => loadCurrentUserSuccess({ currentUser: userViewDto })),
							catchError(error => of({ type: CurrentUserActions.LoadCurrentUserError, error: error }))
					  )
					: of(loadCurrentUserSuccess({ currentUser: null }))
			)
		)
	);

	constructor(
		private actions$: Actions,
		private userCurrentService: UserCurrentService,
		private portalRouteService: ExternalPortalRouteService
	) {}
}
