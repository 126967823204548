<h1 mat-dialog-title class="title">{{ serviceName }}</h1>

<mat-dialog-content class="container flex-column">
	<div *ngIf="isForm" class="flex-column" [formGroup]="form">
		<ng-container *ngIf="!isSubmitted">
			<div class="flex-row">
				<span>{{ submissionPrompt }}</span>
			</div>

			<div class="flex-row">
				<mat-form-field>
					<input formControlName="firstName" matInput autocomplete="off" />
					<mat-placeholder
						>First Name
						<sup class="color-warn">*</sup>
					</mat-placeholder>
					<mat-error>
						<error-messages [for]="form.get('firstName')"></error-messages>
					</mat-error>
				</mat-form-field>
				<mat-form-field>
					<input formControlName="lastName" matInput autocomplete="off" />
					<mat-placeholder
						>Last Name
						<sup class="color-warn">*</sup>
					</mat-placeholder>
					<mat-error>
						<error-messages [for]="form.get('lastName')"></error-messages>
					</mat-error>
				</mat-form-field>
			</div>

			<div class="flex-row">
				<mat-form-field>
					<input formControlName="phone" matInput autocomplete="off" />
					<mat-placeholder
						>Phone
						<sup class="color-warn">*</sup>
					</mat-placeholder>
					<mat-error>
						<error-messages [for]="form.get('phone')"></error-messages>
					</mat-error>
				</mat-form-field>
				<mat-form-field>
					<input formControlName="email" matInput autocomplete="off" />
					<mat-placeholder
						>E-Mail
						<sup class="color-warn">*</sup>
					</mat-placeholder>
					<mat-error>
						<error-messages [for]="form.get('email')"></error-messages>
					</mat-error>
				</mat-form-field>
			</div>

			<div *ngIf="includeUserMessageBox" class="flex-row">
				<mat-form-field>
					<mat-label>Message</mat-label>
					<textarea
						placeholder="Please contact me to discuss..."
						formControlName="message"
						matInput
						cdkTextareaAutosize
						[cdkAutosizeMinRows]="4"
					></textarea>
					<mat-error> <error-messages [for]="form.get('message')"></error-messages> </mat-error>
				</mat-form-field>
			</div>
		</ng-container>
	</div>

	<div *ngIf="isSubmitted">
		{{ submissionCompleteMessage }}
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<button
		*ngIf="isForm && !isSubmitted"
		mat-raised-button
		color="primary"
		throttleButton
		(throttledClick)="onSubmitForm()"
		[disabled]="!form.valid || isProcessing"
	>
		Submit
	</button>
	<button mat-raised-button [color]="!!isSubmitted || !isForm ? 'primary' : ''" mat-dialog-close>{{ !!isSubmitted || !isForm ? 'Ok' : 'Cancel' }}</button>
</mat-dialog-actions>

<mat-progress-bar *ngIf="isProcessing" mode="indeterminate"></mat-progress-bar>
