export interface TimeZoneListState {
	timeZones: TimeZoneItem[];
	isFetching: boolean;
}

export interface TimeZoneItem {
	id: string;
	name: string;
	offset: number;
}

export const initialState: TimeZoneListState = {
	timeZones: [],
	isFetching: false
};
