// This file has been generated from CustomFieldType.cs
// Do not modify this file directly!!

// LawMaster.Amalthea.Domain.Settings.CustomFields.Common.CustomFieldType
export enum CustomFieldType {
	Text = 'Text',
	Date = 'Date',
	Checkbox = 'Checkbox',
	Contact = 'Contact',
	Email = 'Email',
	Numeric = 'Numeric',
	List = 'List',
	Currency = 'Currency',
	Paragraph = 'Paragraph',
	Calculated = 'Calculated'
}
