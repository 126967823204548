import { Action } from '@ngrx/store';

import { ICurrentPageState } from './current-page.state';

// tslint:disable:max-classes-per-file
export const SetCurrentPageType = 'SetCurrentPageType';
export const ClearCurrentPageType = 'ClearCurrentPageType';

export type CurrentPageActions = SetCurrentPageAction | ClearCurrentPageAction;

export class SetCurrentPageAction implements Action {
	readonly type = SetCurrentPageType;

	constructor(public data: ICurrentPageState) {}
}

export class ClearCurrentPageAction implements Action {
	readonly type = ClearCurrentPageType;
}
