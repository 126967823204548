import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class AuthNotificationService {
	// Observable sourcing announcements
	private _successfulRequestSource$ = new BehaviorSubject<boolean>(false);

	// Observable for subscribing on the 1st successful request announcements
	// tslint:disable-next-line:member-ordering
	private _firstSuccessfulRequest$ = this._successfulRequestSource$.pipe(filter(Boolean)) as Observable<boolean>;

	get firstSuccessfulRequest$() {
		return this._firstSuccessfulRequest$;
	}

	announceSuccessfulRequest() {
		if (!this._successfulRequestSource$.value) {
			this._successfulRequestSource$.next(true);
		}
	}
}
