import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

import 'moment-duration-format';

import { XeroConnectionStatus } from '@common/models/Bills/XeroSync/XeroConnectionStatus';
import { XeroIntegrationSettings } from '@common/models/Settings/BillSettings/Common/XeroIntegrationSettings';
import { NotificationService } from '@common/notification';
import { BillsService } from '@common/services/settings/bills.service';
import { XeroOauth2Service } from '@common/services/settings/xerooauth2.service';

import { XeroSettingsDialogComponent } from './dialogs/xero-settings-dialog.component';
import { XeroSettingsComponent } from './xero-settings.component';
import { AppBrandingService } from 'app/services/app-branding.service';

@Component({
	selector: 'xero-connect',
	styleUrls: ['./xero-connect.component.scss'],
	templateUrl: './xero-connect.component.html'
})
export class XeroConnectComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('xeroSettingsControl')
	xeroSettingsControl: XeroSettingsComponent;
	@Input()
	xeroSettings: XeroIntegrationSettings;

	private subscriptions = new Subscription();

	connectionStatus: XeroConnectionStatus;
	invalid: boolean = true;

	get siteName() {
		return AppBrandingService.getSiteName();
	}

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private notificationService: NotificationService,
		private xeroOauth2Service: XeroOauth2Service,
		private billSettingsService: BillsService,
		private dialog: MatDialog
	) {}

	get showConnectButton(): boolean {
		return !!this.connectionStatus && this.connectionStatus != XeroConnectionStatus.UserAndTenantConnected;
	}

	get showSettings(): boolean {
		return (
			!!this.connectionStatus &&
			(this.connectionStatus == XeroConnectionStatus.TenantConnected ||
				this.connectionStatus == XeroConnectionStatus.UserAndTenantConnected)
		);
	}

	get xeroOrganisationDirectUrl(): string {
		return (
			!!this.xeroSettings?.xeroTenant?.xeroTenantShortCode &&
			`https://go.xero.com/OrganisationLogin/?shortCode=${this.xeroSettings.xeroTenant.xeroTenantShortCode}`
		);
	}

	ngOnInit() {
		this.subscriptions.add(
			this.xeroOauth2Service.connectionStatus().subscribe(status => (this.connectionStatus = status))
		);
	}

	ngAfterViewInit() {
		const dialogName = this.activatedRoute.snapshot.queryParamMap.get('dialog');
		const error = this.activatedRoute.snapshot.queryParamMap.get('error');
		if (!error && dialogName === 'configure' && !this.xeroSettings?.xeroTenant?.xeroTenantId) {
			this.openConfigDialog();
		}
		// if the user hits "cancel" on the grant permission page 'access_denied' is returned. No need to handle that
		else if (!!error && error != 'access_denied') {
			this.notificationService.showError('Connect to Xero Failed', `Reason: ${error}`);
		}
	}

	openConfigDialog() {
		const dialogRef = this.dialog.open(XeroSettingsDialogComponent, {
			data: { xeroSettings: this.xeroSettings },
			width: '900px'
		});

		this.subscriptions.add(
			dialogRef
				.afterClosed()
				.pipe(
					tap(() => {
						// remove &dialog=configure query parameter from URL upon closing the dialog, if exists
						this.router.navigate([], {
							queryParams: {
								dialog: null,
								error: null
							},
							queryParamsHandling: 'merge',
							relativeTo: this.activatedRoute
						});
					}),
					filter(Boolean),
					switchMap((newSettings: XeroIntegrationSettings) => {
						return this.xeroOauth2Service.saveXeroSettings(newSettings);
					})
				)
				.subscribe(
					persistedSettings => {
						this.xeroSettings = persistedSettings;
						if (!!persistedSettings.xeroTenant)
							this.connectionStatus =
								this.connectionStatus == XeroConnectionStatus.UserConnected
									? XeroConnectionStatus.UserAndTenantConnected
									: XeroConnectionStatus.TenantConnected;
						this.notificationService.showNotification('Xero settings saved successfully.');
					},
					err => {
						this.notificationService.showErrors('Error saving Xero settings', err);
					}
				)
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	connectToXeroClicked() {
		const redirectUrl = new URL(window.location.href);
		redirectUrl.searchParams.delete('error');
		redirectUrl.searchParams.set('dialog', 'configure');
		this.subscriptions.add(
			this.xeroOauth2Service.getXeroAuthUrl(redirectUrl.toString()).subscribe(
				(url: string) => {
					window.open(url, '_self');
				},
				err => {
					this.notificationService.showErrors('Error opening Xero', err);
				}
			)
		);
	}

	saveClicked() {
		const newSettings = this.xeroSettingsControl?.currentState;
		if (newSettings)
			this.subscriptions.add(
				this.xeroOauth2Service.saveXeroSettings(newSettings).subscribe(
					persistedSettings => {
						this.xeroSettings = persistedSettings;
						this.notificationService.showNotification('Xero settings saved successfully.');
					},
					err => {
						this.notificationService.showErrors('Error saving Xero settings', err);
					}
				)
			);
	}

	onValidChange(valid: boolean): void {
		this.invalid = !valid;
	}

	removeXeroSync() {
		if (!!this.xeroSettings?.xeroTenant) {
			const confirmReference = this.notificationService.showDeleteConfirmation(
				'Remove Xero Sync',
				`This will remove the link between invoices and payments with Xero. This action cannot be undone and the links cannot be restored.<br/><br/>
				Are you sure you wish to permanently disconnect '<b>${
					this.xeroSettings.xeroTenant.xeroTenantName
				}</b>' Xero organisation from ${AppBrandingService.getSiteName()}?`,
				'Remove',
				'Cancel',
				'Enter the name of the Xero organisation name to confirm the removal.',
				this.xeroSettings.xeroTenant.xeroTenantName,
				'Enter Xero organisation name'
			);
			this.subscriptions.add(
				confirmReference
					.pipe(
						filter(Boolean),
						switchMap(() => this.billSettingsService.resetOrganisation())
					)
					.subscribe(
						() => {
							this.xeroSettings = null;
							this.connectionStatus = XeroConnectionStatus.None;
							this.notificationService.showNotification('Xero organisation successfully removed.');
						},
						err => {
							this.notificationService.showErrors('Error removing Xero organisation', err);
						}
					)
			);
		}
	}
}
