// This file has been generated from SecurityRoleCreateUpdateDto.cs
// Do not modify this file directly!!
// tslint:disable

import { RolePermission } from '../../../Common/RolePermission';
import { SecurityPolicies } from '../Common/SecurityPolicies';

// LawMaster.Amalthea.Domain.Settings.SecurityRoles.Item.SecurityRoleCreateUpdateDto
export class SecurityRoleCreateUpdateDto {

	// Name
	name: string;
	// Policies
	policies: Array<keyof typeof SecurityPolicies>;
	// Permissions
	permissions: Array<keyof typeof RolePermission>;

	constructor() {
		this.policies = [];
		this.permissions = [];
	}
}