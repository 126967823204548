// tslint:disable:max-classes-per-file
import { CalculationVariableListItemDto } from '@common/models/Calculations/List/CalculationVariableListItemDto';
import { CustomFieldEntityType } from '@common/models/Settings/CustomFields/Common/CustomFieldEntityType';
import { DomainError } from '@common/models/Validation/DomainError';
import { createAction, props } from '@ngrx/store';

export enum CalculationVariableListActions {
	Load = '[Calculation Variables List] Load',
	Loading = '[Calculation Variables List] Loading',
	LoadSuccess = '[Calculation Variables List] Load Success',
	LoadFailed = '[Calculation Variables List] Load Failed',

	InvalidateCache = '[Calculation Variables List] Invalidate Cache'
}

export const load = createAction(
	CalculationVariableListActions.Load,
	props<{ entityType: keyof typeof CustomFieldEntityType }>()
);

export const loading = createAction(
	CalculationVariableListActions.Loading,
	props<{ entityType: keyof typeof CustomFieldEntityType }>()
);

export const loadSuccess = createAction(
	CalculationVariableListActions.LoadSuccess,
	props<{ entityType: keyof typeof CustomFieldEntityType; response: CalculationVariableListItemDto[] }>()
);

export const loadFailed = createAction(
	CalculationVariableListActions.LoadFailed,
	props<{ entityType: keyof typeof CustomFieldEntityType; error: DomainError }>()
);

export const invalidateCache = createAction(
	CalculationVariableListActions.InvalidateCache,
	props<{ entityType: keyof typeof CustomFieldEntityType }>()
);
