import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CostCodeListRequest } from '@common/models/Settings/CostCodes/List/CostCodeListRequest';
import { FilterRootComponent } from 'app/shared/filter-controls';
import { FilterChangeProperties } from 'app/shared/filter-controls/filter-change-properties';

@Component({
	selector: 'cost-code-list-filter',
	styleUrls: ['./cost-code-list-filter.component.scss'],
	templateUrl: './cost-code-list-filter.component.html'
})
export class CostCodeListFilterComponent {
	@ViewChild(FilterRootComponent, { static: true })
	root: FilterRootComponent<CostCodeListRequest>;

	@Input()
	request: Partial<CostCodeListRequest>;
	@Output()
	filterChange = new EventEmitter<Partial<CostCodeListRequest>>();

	listRequestType: new () => CostCodeListRequest = CostCodeListRequest;

	rootFilterChange($event: FilterChangeProperties<Partial<CostCodeListRequest>>) {
		if (!!$event) this.filterChange.emit($event.filter);
	}
}
