// This file has been generated from Features.cs
// Do not modify this file directly!!

// LawMaster.Amalthea.Domain.Settings.Modules.Features
export enum Features {
	ApiKeys = 'API Keys',
	Briefs = 'Briefs',
	CostRecords = 'Cost Records',
	InfoTrack = 'InfoTrack',
	Invoicing = 'Invoicing',
	Referrals = 'Referrals',
	Tasks = 'Tasks',
	TrustAccounting = 'Trust Accounting',
	XeroIntegration = 'Xero',
	FullTextSearch = 'Full Text Search',
	DocumentVersioning = 'Document Versioning',
	DocumentSharing = 'Document Sharing',
	Marketplace = 'Marketplace',
	MatterWorkflows = 'Matter Workflows',
	MatterWorkflowsV2 = 'Matter Workflows 2.0'
}
