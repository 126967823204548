import {
	AfterViewInit,
	Component,
	EventEmitter,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	Optional,
	Output,
	ViewChild
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect, MatSelectChange } from '@angular/material/select';

import { BehaviorSubject, EMPTY, forkJoin, Observable, of, Subscription } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import { ILookupReference } from '@common/components/lookups/base-lookup.component';
import { MatterStaffRateResult } from '@common/models/CostRecords/Item/MatterStaffRateResult';
import { TimeRecordCreateUpdateDto } from '@common/models/CostRecords/Item/TimeRecordCreateUpdateDto';
import { TimeRecordViewForUpdateDto } from '@common/models/CostRecords/Item/TimeRecordViewForUpdateDto';
import { ListResponse } from '@common/models/Generic/ListResponse';
import { CostingMethod } from '@common/models/Matters/Common/CostingMethod';
import { MatterStatus } from '@common/models/Matters/Common/MatterStatus';
import { MatterLookupDto } from '@common/models/Matters/Lookup/MatterLookupDto';
import { CostCodeListItemDto } from '@common/models/Settings/CostCodes/List/CostCodeListItemDto';
import { MatterTimerConfigDto } from '@common/models/Settings/Setting/Item/MatterTimerConfigDto';
import { UserViewDto } from '@common/models/Users/Item/UserViewDto';
import { INotificationMessage } from '@common/notification';
import { CostCodesService } from '@common/services/settings/costcodes.service';
import { GeneralSettingsService } from '@common/services/settings/generalsettings.service';
import { TimeRecordsService } from '@common/services/timerecords.service';
import { ICurrentUserData } from '@common/state/models/current-user-data';
import { CustomValidators } from '@common/validation/custom.validators';
import { Store } from '@ngrx/store';
import { get, isNumber } from 'lodash-es';
import * as moment from 'moment-timezone';

import { insertRecords, updateRecords } from 'app/core/state/lists/cost-list/cost-list.actions';
import { getCurrentPage } from 'app/core/state/misc/current-page/current-page.reducer';
import { CurrentPageType, ICurrentPageState } from 'app/core/state/misc/current-page/current-page.state';
import { MatterLookupComponent } from 'app/shared/components/matter-lookup.component';
import { WorkTimerStateManager } from 'app/shared/components/work-timer/WorkTimerStateManager';
import { WorkTimerUtils } from 'app/shared/components/work-timer/WorkTimerUtils';
import { round } from 'app/shared/utils/mathUtil';

import { ICreateComponent } from '../create-component.interface';
import { IEditComponent } from '../edit-component.interface';
import { CreateTimeRecordDialogData } from '../matter/CreateTimeRecordDialogData';
import { DurationFieldComponent, maxDurationValidator } from './duration-field/duration-field.component';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
	selector: 'create-time-record',
	styleUrls: ['./create-time-record.component.scss'],
	templateUrl: 'create-time-record.component.html'
})
export class CreateTimeRecordComponent implements OnInit, AfterViewInit, OnDestroy, ICreateComponent, IEditComponent {
	@Input()
	editId: string;
	@Input()
	hideRelatedTo: boolean;
	@Output()
	isValidChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	@ViewChild('costCodeDropdown', { static: true })
	costCodeDropdown: MatSelect;
	@ViewChild('costModeDropdown', { static: true })
	costModeDropdown: MatSelect;
	@ViewChild('matterLookup', { static: true })
	matterLookup: MatterLookupComponent;
	@Input()
	form: FormGroupTyped<TimeRecordCreateUpdateDto>;
	@Input()
	showCopyTo: boolean;
	@Input()
	disableValidation: boolean;
	@Output()
	copyClicked: EventEmitter<void> = new EventEmitter();
	@ViewChild('durationInput', { read: DurationFieldComponent })
	durationFieldComponent: DurationFieldComponent;

	costCodes: CostCodeListItemDto[];
	costCodeRate: number = 0;
	matterStaffRateResult: MatterStaffRateResult = { chargeRate: 0, userTypeName: '' };
	get userChargeRate(): number {
		return this.matterStaffRateResult?.chargeRate ?? 0;
	}

	highlightField: boolean;
	unitsPerHour: number;
	costingMethod: CostingMethod;

	timerMinutesInjected: boolean = false;
	isEditable: boolean = true;
	isWrittenOff: boolean = false;
	isAmountManuallyEntered: boolean;
	private amountAutoUpdateEventInProgress: boolean;
	private loadingComponentInProgress: boolean;
	private prePopulatedDto: CreateTimeRecordDialogData;
	private givenMatterId: string;
	timerId: string;
	timerDescription: string;
	timerConfig: MatterTimerConfigDto;

	get chargeRateHintText(): string {
		if (!this.matterStaffRateResult) return '';

		const hintTextPrefix = !!this.matterStaffRateResult?.userTypeName
			? this.matterStaffRateResult.userTypeName + ' - '
			: '';
		return `${hintTextPrefix}$${this.userChargeRate} /hr = $${(this.userChargeRate / this.unitsPerHour).toFixed(
			2
		)} / unit`;
	}

	get costCodeRateHintText(): string {
		if (!this.costCodes) return '';

		if (this.isCostCodeNonChargeable) return 'Non-Chargeable Activity';

		if (this.pricingMethod() !== 'Fixed') return '';

		const costCode = this.costCodes.find(x => x.id === this.form.controls.costCodeId.value);

		if (!costCode?.chargeRate) return '';

		return `${costCode.chargeRate} / unit`;
	}

	get amountHintText(): string {
		if (!!this.isCostCodeNonChargeable || this.costingMethod === CostingMethod.Fixed) return '';
		if (this.isAmountManuallyEntered) return 'Manually overridden';

		const minutes: number = isNumber(this.form.controls.durationMinutes.value)
			? this.form.controls.durationMinutes.value
			: 0;

		const quantity: number = isNumber(this.form.controls.quantity.value) ? this.form.controls.quantity.value : 0;

		const rate: number = this.form.controls.chargeRate.value;

		if (this.requiredDuration()) {
			const unitCalculation: number = Math.ceil((minutes * this.unitsPerHour) / 60);
			return `${unitCalculation} ${unitCalculation === 1 ? 'unit' : 'units'} X $${(
				rate / this.unitsPerHour
			).toFixed(2)} / unit`;
		} else if (rate && quantity) {
			return `${quantity} ${quantity === 1 ? 'unit' : 'units'} X $${rate.toFixed(2)} / unit`;
		}

		return '';
	}

	get isCostCodeNonChargeable(): boolean {
		if (get(this.costCodes, 'length') > 0) {
			const costCode = this.costCodes.find(x => x.id === this.form.controls.costCodeId.value);
			if (costCode) return costCode.timeType === 'NonChargeable';
		}
		return false;
	}

	get matterHintText(): string {
		return this.costingMethod ? `Costing Method : ${this.costingMethod}` : `Enter a matter number or title `;
	}

	get displayRefreshButton(): boolean {
		return this.form.controls.amountExclTax.enabled;
	}

	private subscription: Subscription = new Subscription();

	constructor(
		@Optional() @Inject(MAT_DIALOG_DATA) data: any,
		private fb: FormBuilder,
		private timeRecordService: TimeRecordsService,
		private costCodeService: CostCodesService,
		private store: Store<{ currentPageData: ICurrentPageState; currentUserData: ICurrentUserData }>,
		private settingsService: GeneralSettingsService,
		private workTimerStateManager: WorkTimerStateManager
	) {
		this.prePopulatedDto = data ? data.payload : null;
		this.isEditable = true;
		this.givenMatterId = data ? data.associatedMatterId : null;
		this.timerId = data?.payload?.timerId;
		this.timerDescription = data?.payload?.timerDescription;
	}

	ngOnInit(): void {
		this.loadingComponentInProgress = true;
		if (!this.form) {
			this.form = this.fb.group({
				amountExclTax: null,
				associatedMatterId: [null, CustomValidators.required('Related To Matter')],
				billed: null,
				chargeRate: null,
				quantity: null,
				costCodeId: [null, CustomValidators.required('Cost Code')],
				pricingMethod: null,
				date: [null, CustomValidators.required('Date')],
				description: [null, CustomValidators.required('Description')],
				durationMinutes: [
					null,
					[CustomValidators.requiredWhen(() => this.requiredDuration(), 'Duration'), maxDurationValidator()]
				],
				staffContactId: [null, CustomValidators.required('User')],
				units: null,
				overrideDescription: null
			}) as FormGroupTyped<TimeRecordCreateUpdateDto>;
		}

		this.subscription.add(
			this.form.statusChanges.subscribe(next => {
				this.isValidChange.next(next === 'VALID');
			})
		);

		this.subscription.add(
			this.form.controls.amountExclTax.valueChanges.subscribe(vale => {
				if (this.amountAutoUpdateEventInProgress || this.loadingComponentInProgress) {
					return;
				}
				if (this.form.controls.amountExclTax.touched) {
					this.isAmountManuallyEntered = true;
				}
			})
		);

		this.subscription.add(
			this.form.controls.description.valueChanges.subscribe(() => {
				if (this.form.controls.description.dirty) {
					this.form.controls.overrideDescription.patchValue(true);
				}
			})
		);

		const unitsPerHourObservable = this.settingsService.getUnits();

		// todo AM-395 if there are many cost codes add search functionality
		const lookupObservable: Observable<ListResponse<CostCodeListItemDto>> = this.costCodeService.getCostCodeList({
			sortBy: 'name',
			costType: 'Time'
		});

		if (this.editId) {
			if (this.form.disabled) this.form.enable();
			// Initialise on receiving the Time record and the list of Activities
			this.subscription.add(
				forkJoin(
					this.timeRecordService.getTimeRecord(this.editId).pipe(
						switchMap(tr => {
							this.isWrittenOff = tr.isWrittenOff;

							if (!tr.isEditable) {
								this.form.disable();
							} else if (!tr.canChangeMatter) {
								this.form.controls.associatedMatterId.disable();
							}
							// Return original Time Record
							return of(tr);
						})
					),
					lookupObservable,
					unitsPerHourObservable
				)
					.pipe(
						switchMap(([dto, costCodeLookupDto, unitsPerHour]) => {
							this.costCodes = this.getApplicatbleCostCodes(costCodeLookupDto.records, dto);
							this.unitsPerHour = unitsPerHour;
							dto.amountExclTax = round(dto.amountExclTax);
							this.form.patchValue(dto);
							this.amountManuallyEnteredDefaultValue();

							this.costingMethod = CostingMethod[dto.costingMethod as keyof typeof CostingMethod];
							this.form.controls.pricingMethod.setValue(dto.pricingMethod);
							this.isEditable = dto.isEditable;

							if (this.isCostCodeNonChargeable) {
								this.form.controls.amountExclTax.patchValue(null);
								this.form.controls.amountExclTax.disable();
							} else if (this.isEditable) {
								this.form.controls.amountExclTax.enable();
							}

							return this.timeRecordService.getStaffRateForTimeRecord({
								contactId: dto.staffContactId,
								matterId: dto.associatedMatterId ? dto.associatedMatterId ?? '' : ''
							});
						})
					)
					.subscribe((matterStaffRateResult: MatterStaffRateResult) => {
						// setting user rate
						if (matterStaffRateResult != null) {
							this.matterStaffRateResult = matterStaffRateResult;
						}

						// setting cost code rate
						const costCode = this.costCodes
							? this.costCodes.find(x => x.id === this.form.controls.costCodeId.value)
							: null;
						if (costCode && costCode.chargeRate) this.costCodeRate = costCode.chargeRate;

						this.loadingComponentInProgress = false;
					})
			);
		} else {
			this.subscription.add(
				forkJoin([lookupObservable, unitsPerHourObservable]).subscribe(([response, resp2]) => {
					this.costCodes = this.getApplicatbleCostCodes(response.records);
					this.unitsPerHour = resp2;
					this.loadingComponentInProgress = false;
					this.updateAmount();
				})
			);
		}

		this.subscription.add(
			this.settingsService
				.getMatterTimerConfig() // Get global timer config
				.subscribe({
					next: dto => (this.timerConfig = dto)
				})
		);
	}

	ngAfterViewInit(): void {
		// Set focus only in create mode. No need to set focus in edit mode.
		if (this.editId || !!this.prePopulatedDto || !!this.form.controls.associatedMatterId.value) {
			setTimeout(() => {
				this.costCodeDropdown.focus();
			}, 0);
		} else {
			if (this.matterLookup) this.matterLookup.setFocus();
		}
		// If duration is changed by the user (dirty) calculate the amount
		// If the duration gets changed during edit (loading existing duration) do not calculate
		this.subscription.add(
			this.form.controls.durationMinutes.valueChanges
				.pipe(filter(() => this.form.controls.durationMinutes.dirty))
				.subscribe(() => this.updateAmountField())
		);

		this.subscription.add(
			this.form.controls.pricingMethod.valueChanges
				.pipe(filter(() => this.form.controls.pricingMethod.dirty))
				.subscribe(() => {
					this.durationFieldComponent.Required = this.requiredDuration();
					this.form.controls.durationMinutes.updateValueAndValidity();
					this.form.controls.quantity.updateValueAndValidity();
					this.updateAmount();
				})
		);

		this.subscription.add(
			this.form.controls.quantity.valueChanges
				.pipe(filter(() => this.form.controls.quantity.dirty))
				.subscribe(() => this.onQuantityChanged(this.form.controls.quantity.value))
		);

		// Auto fill duration field with timer value of the associated matter if selected and no
		// value is present
		this.subscription.add(
			this.durationFieldComponent.focused
				.pipe(
					switchMap(() => (this.timerMinutesInjected ? EMPTY : of(!!this.timerConfig?.isGlobalEnabled))),
					filter(Boolean) // prevent further execution if timer is not enabled
				)
				.subscribe(() => {
					if (!this.form.controls.durationMinutes.value) {
						const timerId = this.timerId || this.form.controls.associatedMatterId.value;

						if (!!timerId) {
							this.timerMinutesInjected = true;
							const state = this.workTimerStateManager.getState(timerId);

							if (!!state) {
								this.timerDescription = state.data.description;
								const milliseconds = WorkTimerUtils.getCurrentDuration(state);
								const minutes = Math.ceil(milliseconds / 60000);
								this.form.controls.durationMinutes.setValue(minutes);
								this.durationFieldComponent.selectText();
								this.updateAmountField(minutes);
							}
						}
					}
				})
		);

		if (!this.editId) {
			this.resetForm(this.prePopulatedDto);
		}
	}

	ngOnDestroy(): void {
		this.isValidChange.complete();
		this.subscription.unsubscribe();
	}

	getReadOnlyReason(): string {
		return 'This record is read only as it has been ' + (this.isWrittenOff ? 'written off' : 'invoiced');
	}

	pricingMethod(): any {
		return this.form?.get('pricingMethod')?.value ?? null;
	}

	requiredDuration(): boolean {
		return this.pricingMethod() === 'Time';
	}

	onMatterSelected(event: ILookupReference) {
		this.costingMethod = CostingMethod[event.additionalInfo as keyof typeof CostingMethod];
		const pricingMethod = this.costingMethod === CostingMethod.Fixed ? 'Fixed' : 'Time';
		this.form.controls.pricingMethod.setValue(pricingMethod);
		this.form.controls.durationMinutes.updateValueAndValidity();
		this.form.controls.quantity.updateValueAndValidity();

		this.durationFieldComponent.Required = this.requiredDuration();

		if (event.id && this.form.controls.staffContactId.value) {
			this.subscription.add(
				this.timeRecordService
					.getStaffRateForTimeRecord({
						contactId: this.form.controls.staffContactId.value ?? '',
						matterId: event.id
					})
					.subscribe(matterStaffRateResult => {
						if (matterStaffRateResult != null) {
							this.matterStaffRateResult = matterStaffRateResult;
							if (this.requiredDuration()) {
								this.updateAmountField();
							}
						}
					})
			);
		}
	}

	onContactSelected(event: ILookupReference) {
		if (event.id) {
			this.subscription.add(
				this.timeRecordService
					.getStaffRateForTimeRecord({
						contactId: event.id,
						matterId: this.form.controls.associatedMatterId.value ?? ''
					})
					.subscribe(matterStaffRateResult => {
						if (matterStaffRateResult != null) {
							this.matterStaffRateResult = matterStaffRateResult;
							if (this.requiredDuration()) {
								this.updateAmountField();
							}
						}
					})
			);
		}
	}

	Create(resetAfterSave: boolean = false): Observable<INotificationMessage> {
		return this.timeRecordService.createTimeRecord(this.form.value).pipe(
			tap(ref => this.store.dispatch(insertRecords({ response: ref }))),
			map(ref => {
				const matterId = this.form.controls.associatedMatterId.value;
				const route = [];

				if (matterId) {
					route.push('/matters', matterId, 'costs');
				} else {
					route.push('/costs');
				}

				return {
					linkParams: { pageIndexForId: ref.id },
					linkRoute: route,
					linkText: ref.name,
					text: 'Time/Fee record created:'
				};
			}),
			tap(() => {
				if (resetAfterSave) this.resetForm();

				// Auto reset timer associated with matter
				if (this.timerId) {
					if (!!this.timerConfig.autoDeleteTimer) {
						this.workTimerStateManager.clearState(this.timerId);
					} else {
						const state = this.workTimerStateManager.getState(this.timerId);
						this.workTimerStateManager.resetState(this.timerId, state);
					}
				}
			})
		);
	}

	Edit(): Observable<INotificationMessage> {
		return this.timeRecordService.updateTimeRecord(this.editId, this.form.getRawValue()).pipe(
			tap(ref => this.store.dispatch(updateRecords({ response: ref }))),
			map(ref => ({ text: `Time/Fee Record updated: ${ref.name}` }))
		);
	}

	// Calculate the amount on typing the duration
	onDurationChanged(minutes: number): void {
		minutes = isNumber(minutes) ? minutes : 0;
		if (!!this.form.controls.units) {
			this.form.controls.units.setValue(Math.ceil((minutes * this.unitsPerHour) / 60));
		}
		this.updateAmountField(minutes ? minutes : 0);
	}

	// Calculate the amount on typing the duration
	onQuantityChanged(quantity: number): void {
		quantity = isNumber(quantity) ? quantity : 0;
		if (!!this.form.controls.units) {
			this.form.controls.units.setValue(0);
		}
		this.updateAmountByQuantityField(quantity ? quantity : 0);
	}

	autoCalculateAmount(): void {
		this.isAmountManuallyEntered = false;
		this.updateAmount();
	}

	costCodeChanged(event: MatSelectChange) {
		const selected = event.source.selected as MatOption;
		const costCode = this.costCodes.filter(x => x.id === selected.value)[0];
		// changing cost code updates description also when it's first filled, then cleared
		if (this.form.controls.description.value === '') {
			this.form.controls.description.markAsPristine();
		}

		if (!this.form.controls.overrideDescription.value) {
			const selectedCostCode = selected.viewValue;
			this.form.controls.description.patchValue(selectedCostCode);
		}
		this.costCodeRate = costCode.chargeRate;

		this.updateAmount();
	}

	updateAmount(): void {
		if (this.pricingMethod() === 'Fixed') {
			this.updateAmountByQuantityField();
		} else {
			this.updateAmountField();
		}
	}

	updateAmountByQuantityField(quantity: number = null, chargeRate: number = this.costCodeRate): void {
		if (this.loadingComponentInProgress) {
			return;
		}
		this.amountAutoUpdateEventInProgress = true;
		const previousAmount = this.form.controls.amountExclTax.value;
		let calculatedAmount: number = null;

		if (this.isCostCodeNonChargeable) {
			this.isAmountManuallyEntered = false;
			this.form.controls.amountExclTax.patchValue(null);
			this.form.controls.amountExclTax.disable();
		} else {
			this.form.controls.amountExclTax.enable();
		}

		if (this.isAmountManuallyEntered) {
			return;
		}

		if (!this.isCostCodeNonChargeable) {
			this.form.patchValue({ chargeRate: chargeRate ?? 0 });
			if (quantity === null) {
				quantity = this.form.get('quantity')?.value ?? 0;
			}
			calculatedAmount = quantity * chargeRate;

			if (isNaN(calculatedAmount)) calculatedAmount = 0;

			if (previousAmount !== calculatedAmount) {
				this.form.controls.amountExclTax.patchValue(calculatedAmount);
				this.highLightField();
			}
		}

		this.amountAutoUpdateEventInProgress = false;
	}

	updateAmountField(minutes: number = null, chargeRate: number = this.userChargeRate): void {
		if (this.loadingComponentInProgress) {
			return;
		}
		this.amountAutoUpdateEventInProgress = true;
		const previousAmount = this.form.controls.amountExclTax.value;
		let calculatedAmount: number = null;

		if (this.isCostCodeNonChargeable) {
			this.isAmountManuallyEntered = false;
			this.form.controls.amountExclTax.patchValue(null);
			this.form.controls.amountExclTax.disable();
		} else {
			this.form.controls.amountExclTax.enable();
		}

		if (this.isAmountManuallyEntered) {
			return;
		}

		if (!this.isCostCodeNonChargeable) {
			if (this.costingMethod === CostingMethod.Fixed) calculatedAmount = 0;
			else {
				this.form.patchValue({ chargeRate: chargeRate ?? 0 });
				if (minutes === null) {
					minutes = this.form.controls.durationMinutes.value ? this.form.controls.durationMinutes.value : 0;
				}
				calculatedAmount = this.calculateAmountusingFormula(minutes, chargeRate);
			}

			if (previousAmount !== calculatedAmount) {
				this.form.controls.amountExclTax.patchValue(calculatedAmount);
				this.highLightField();
			}
		}

		this.amountAutoUpdateEventInProgress = false;
	}

	onCopyClicked() {
		this.copyClicked.emit();
	}

	private resetForm(patchObject: CreateTimeRecordDialogData = null): void {
		// default to the current date
		this.form.reset({ date: moment() });

		let observer: Observable<CreateTimeRecordDialogData>;
		if (!!patchObject) {
			if (patchObject.costingMethod === CostingMethod.Fixed) {
				this.form.patchValue({
					amountExclTax: patchObject.amount
				});
				// make the amount field 'manually overridden' so that it doesn't get reset when cost code or duration changes
				this.amountManuallyEnteredDefaultValue();
			}

			// Continue with passed over parameters
			observer = of(patchObject);
		} else {
			// Get paramters from the currently opened matter
			observer = this.getCurrentOpenMatter().pipe(
				switchMap(page => {
					const dto: CreateTimeRecordDialogData = {
						associatedMatterId: this.givenMatterId ? this.givenMatterId : page ? page.id : null,
						costingMethod: page
							? (page.lookup as MatterLookupDto).costingMethodReference.costingMethod
							: null
					};
					return of(dto);
				})
			);
		}
		this.subscription.add(
			observer
				.pipe(
					// Set current matter in the lookup
					tap(dto => {
						this.costingMethod = CostingMethod[dto.costingMethod];
						this.form.patchValue({
							associatedMatterId: dto.associatedMatterId
						});

						const pricingMethod = this.costingMethod === CostingMethod.Fixed ? 'Fixed' : 'Time';
						this.form.controls.pricingMethod.setValue(pricingMethod);
						this.form.controls.durationMinutes.updateValueAndValidity();
						this.form.controls.quantity.updateValueAndValidity();
						this.durationFieldComponent.Required = this.requiredDuration();
					}),
					// Set current user
					switchMap(() => this.store.select(state => state?.currentUserData?.currentUser)),
					tap((user: UserViewDto) =>
						this.form.patchValue({
							staffContactId: user?.contact?.id
						})
					),
					// Get the chargeRate for the Staff member
					switchMap((user: UserViewDto) =>
						this.timeRecordService.getStaffRateForTimeRecord({
							contactId: user?.contact?.id,
							matterId: this.form.controls.associatedMatterId.value
						})
					)
				)
				// Set the charge rate
				.subscribe((matterStaffRateResult: MatterStaffRateResult) => {
					if (matterStaffRateResult != null) {
						this.matterStaffRateResult = matterStaffRateResult;

						this.form.patchValue({ chargeRate: matterStaffRateResult.chargeRate });
					}

					if (!!patchObject?.durationMinutes) {
						this.form.patchValue({
							durationMinutes: patchObject.durationMinutes
						});

						if (!patchObject.amount) {
							this.autoCalculateAmount();
						}
					}
				})
		);
	}

	private calculateAmountusingFormula(minutes: number, chargeRate: number): number {
		minutes = minutes ? minutes : 0;
		const units = Math.ceil((minutes * this.unitsPerHour) / 60);
		const unitRate = chargeRate / this.unitsPerHour;
		return round(units * unitRate);
	}

	// Highlight the Amount field
	private highLightField(): void {
		// Reset the fading effect on the Amount field to the initial state
		this.highlightField = false;
		// Trigger the fading effect then the style has been removed
		setTimeout(() => {
			this.highlightField = true;
		}, 0);
	}

	private getCurrentOpenMatter(): Observable<ICurrentPageState> {
		// default in matter if it is available
		return this.store
			.select(getCurrentPage)
			.pipe(
				map(page =>
					page.pageType === CurrentPageType.Matter &&
					page.id !== null &&
					(page.lookup as MatterLookupDto).status === MatterStatus.Open
						? page
						: null
				)
			);
	}

	private amountManuallyEnteredDefaultValue() {
		this.isAmountManuallyEntered = false;

		let calcAmount: number = null;
		if (this.requiredDuration()) {
			calcAmount = this.calculateAmountusingFormula(
				this.form.controls.durationMinutes.value,
				this.form.controls.chargeRate.value
			);
		} else {
			calcAmount = this.form.controls.quantity.value * this.form.controls.chargeRate.value;
		}
		if (calcAmount !== this.form.controls.amountExclTax.value) {
			this.isAmountManuallyEntered = true;
		}
	}

	getApplicatbleCostCodes(records: CostCodeListItemDto[], dto?: TimeRecordViewForUpdateDto): CostCodeListItemDto[] {
		const currentId = dto?.costCodeId;
		return records.filter(x => (currentId && x.id === currentId) || !x.isDisabled);
	}

	showCopyTimerDescription() {
		const hasTimerDescription = !!this.timerDescription && this.timerDescription.trim().length > 0;
		return (
			hasTimerDescription &&
			(this.form.controls.description.value || '')
				.toLowerCase()
				.indexOf(this.timerDescription.trim().toLowerCase()) < 0
		);
	}

	useTimerDescription() {
		const descriptionValue = this.form.controls.description.value || '';
		this.form.controls.description.setValue(
			`${descriptionValue}${descriptionValue.length > 0 ? ' - ' : ''}${this.timerDescription.trim()}`
		);
		this.form.controls.description.updateValueAndValidity();
	}

	onDeleteTimerChanged(event: MatCheckboxChange) {
		this.timerConfig.autoDeleteTimer = event.checked;
	}
}
