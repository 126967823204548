import { Injectable } from '@angular/core';

import { interval, of } from 'rxjs';
import { catchError, debounce, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { PracticeAreasService } from '@common/services/settings/practiceareas.service';
import { IAppState } from '../../../app.state';
import { loading, PracticeAreaListActions } from './practice-area-list.actions';

@Injectable()
export class PracticeAreaListEffects {
	loadPracticeAreas$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				PracticeAreaListActions.Load,
				PracticeAreaListActions.SetPageIndex,
				PracticeAreaListActions.SetPageSize,
				PracticeAreaListActions.SetSortBy,
				PracticeAreaListActions.SetSortDirection,
				PracticeAreaListActions.SetFilters
			),
			tap(action => this.store.dispatch(loading())),
			withLatestFrom(this.store),
			debounce(() => interval(200)),
			switchMap(([action, state]) => {
				return this.practiceAreaService.getPracticeAreaList(state.practiceAreaList.request).pipe(
					map(response => {
						return { type: PracticeAreaListActions.LoadSuccess, response };
					}),
					catchError(error => of({ type: PracticeAreaListActions.LoadFailed, error }))
				);
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<IAppState>,
		private practiceAreaService: PracticeAreasService
	) {}
}
