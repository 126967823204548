import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription } from 'rxjs';

import 'moment-duration-format';

enum ActiveTab {
	TrustAccounts = 0,
	BankAccounts = 1
}

@Component({
	selector: 'trust-settings',
	styleUrls: ['./trust-settings.component.scss'],
	templateUrl: './trust-settings-component.html'
})
export class TrustSettingsComponent implements OnDestroy, OnInit {
	selectedTabIndex: number = 0;
	subscriptions = new Subscription();

	constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

	ngOnInit(): void {
		this.subscriptions.add(
			this.activatedRoute.queryParams.subscribe(params => {
				this.selectedTabIndex =
					params['activeTab'] &&
					params['activeTab'].toLowerCase() === ActiveTab[ActiveTab.BankAccounts].toLowerCase()
						? ActiveTab.BankAccounts
						: ActiveTab.TrustAccounts;
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	selectedTabChanged(tabEvent: MatTabChangeEvent): void {
		const activeTab = tabEvent.index as ActiveTab;
		// update 'activeTab' query parameter in the URL when changing tabs
		this.router.navigate([], {
			queryParams: {
				activeTab: ActiveTab[activeTab]?.toLowerCase()
			},
			queryParamsHandling: 'merge',
			relativeTo: this.activatedRoute
		});
	}
}
