import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { IFilterDefaultsConfig } from 'app/services/grid-view.service';

@Component({
	selector: 'grid-layout-changes-dialog',
	styleUrls: ['./grid-layout-changes-dialog.component.scss'],
	templateUrl: './grid-layout-changes-dialog.component.html'
})
export class GridLayoutChangesDialogComponent {
	constructor(@Inject(MAT_DIALOG_DATA) public data: GridLayoutChangesDialogData) {}
}

export interface GridLayoutChangesDialogData {
	old: IFilterDefaultsConfig;
	new: IFilterDefaultsConfig;
	isReset: boolean;
}
