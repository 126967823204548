// This file has been generated from SecurityRolesController.cs
// Do not modify this file directly!!
// tslint:disable

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { TrimStringsOnObject } from '@common/utils/string-format';

import { EntityReference } from '../../models/Common/EntityReference';
import { ListResponse } from '../../models/Generic/ListResponse';
import { SecurityRoleCreateUpdateDto } from '../../models/Settings/SecurityRoles/Item/SecurityRoleCreateUpdateDto';
import { SecurityRoleMembershipUpdateDto } from '../../models/Settings/SecurityRoles/Item/SecurityRoleMembershipUpdateDto';
import { SecurityRoleViewDto } from '../../models/Settings/SecurityRoles/Item/SecurityRoleViewDto';
import { SecurityRoleListItemDto } from '../../models/Settings/SecurityRoles/List/SecurityRoleListItemDto';
import { SecurityRoleListRequest } from '../../models/Settings/SecurityRoles/List/SecurityRoleListRequest';
import { SecurityRoleLookupDto } from '../../models/Settings/SecurityRoles/Lookup/SecurityRoleLookupDto';
import { SecurityRoleLookupRequest } from '../../models/Settings/SecurityRoles/Lookup/SecurityRoleLookupRequest';

@Injectable({
	providedIn: 'root'
})
export class SecurityRolesService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'api/v1/settings/SecurityRoles');
	}

	getSecurityRole(roleName: string): Observable<SecurityRoleViewDto> {
		return this.getItem<void, SecurityRoleViewDto>(`${roleName}`);
	}

	getSecurityRoleList(request?: Partial<SecurityRoleListRequest>): Observable<ListResponse<SecurityRoleListItemDto>> {
		return this.getList<SecurityRoleListRequest, SecurityRoleListItemDto>('', request);
	}

	lookupSecurityRoleList(request: SecurityRoleLookupRequest): Observable<SecurityRoleLookupDto[]> {
		return this.getArray<SecurityRoleLookupRequest, SecurityRoleLookupDto>(`lookup`, request);
	}

	lookupSecurityRole(id: string): Observable<SecurityRoleLookupDto> {
		return this.getItem<void, SecurityRoleLookupDto>(`${id}/lookup`);
	}

	createSecurityRole(securityRoleDto: SecurityRoleCreateUpdateDto): Observable<EntityReference> {
		return this.post<SecurityRoleCreateUpdateDto, EntityReference>('', TransformDatesOnObject(SecurityRoleCreateUpdateDto, TrimStringsOnObject(securityRoleDto)));
	}

	updateSecurityRole(roleName: string, securityRoleDto: SecurityRoleCreateUpdateDto): Observable<EntityReference> {
		return this.put(`${roleName}`, TransformDatesOnObject(SecurityRoleCreateUpdateDto, TrimStringsOnObject(securityRoleDto)));
	}

	deleteSecurityRole(roleName: string): Observable<EntityReference> {
		return this.delete(`${roleName}`);
	}

	updateSecurityRoleMemberships(roleName: string, updateDto: SecurityRoleMembershipUpdateDto): Observable<EntityReference> {
		return this.put(`members/${roleName}`, TransformDatesOnObject(SecurityRoleMembershipUpdateDto, TrimStringsOnObject(updateDto)));
	}

}
