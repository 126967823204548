import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';

import { debounceTime } from 'rxjs/operators';

import { BasicWorkflowType } from '@common/models/Settings/BasicWorkflows/Common/BasicWorkflowType';
import { BasicWorkflowCreateUpdateDto } from '@common/models/Settings/BasicWorkflows/Item/BasicWorkflowCreateUpdateDto';
import { NotificationService } from '@common/notification';
import { BasicWorkflowService } from '@common/services/settings/basicworkflow.service';
import { PracticeAreasService } from '@common/services/settings/practiceareas.service';
import { CustomValidators } from '@common/validation/custom.validators';

import { BaseWorkflowComponent } from './basic-workflow-base.component';

@Component({
	selector: 'app-basic-workflow-create-dialog',
	templateUrl: './basic-workflow-create-dialog.component.html',
	styleUrls: ['./basic-workflow-create-dialog.component.scss']
})
export class BasicWorkflowCreateDialogComponent
	extends BaseWorkflowComponent
	implements OnInit, OnDestroy, AfterViewInit
{
	descriptionText: string = '';

	get matterWorkflowConditions() {
		return this.form?.get('matterWorkflowConditions') as any as FormArray;
	}

	constructor(
		practiceAreasService: PracticeAreasService,
		notifService: NotificationService,
		private basicWorkflowService: BasicWorkflowService,
		private fb: FormBuilder,
		private dialogRef: MatDialogRef<BasicWorkflowCreateDialogComponent>
	) {
		super(practiceAreasService, notifService);
	}

	ngOnInit() {
		this.form = this.fb.group({
			name: [null, CustomValidators.required('Name')],
			workflowType: this.defaultWorkflowTypeKey,
			associatedPracticeAreaIds: [],
			stageName: [
				null,
				CustomValidators.requiredWhen(() => this.form?.controls?.workflowType?.value === 'StageChanged')
			],
			matterWorkflowConditions: this.fb.array(
				[],
				CustomValidators.requiredWhen(() => this.form?.controls?.workflowType?.value === 'MatterUpdated')
			)
		}) as FormGroupTyped<BasicWorkflowCreateUpdateDto>;

		this.subscriptions.add(
			this.form.valueChanges.pipe(debounceTime(100)).subscribe(() => {
				this.updateDescriptionText();
			})
		);

		super.ngOnInit();
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.updateDescriptionText();
		}, 50);
	}

	save() {
		const dto: BasicWorkflowCreateUpdateDto = {
			...this.form.value,
			associatedPracticeAreaIds: this.getAssociatedPracticeAreaIds(),
			matterWorkflowConditions: this.form.value.matterWorkflowConditions
		};

		this.subscriptions.add(
			this.basicWorkflowService.createBasicWorkflow(dto).subscribe(
				result => {
					this.notifService.showNotification(`Matter Workflow ${result.name} created`);
					this.dialogRef.close(result.id);
				},
				errors => this.notifService.showErrors('Error saving workflow', errors)
			)
		);
	}

	private getAssociatedPracticeAreaIds(): string[] {
		let areas = this.form.value.associatedPracticeAreaIds as string[];

		if (!!areas?.length) {
			areas = areas.filter(value => !!value);
		}

		return areas;
	}

	private addMatterWorkflowCondition() {
		const newCondition = this.fb.group({
			variableName: new FormControl(null, CustomValidators.required()),
			operator: new FormControl(null, CustomValidators.required()),
			expectedValue: new FormControl(null, [])
		});

		this.matterWorkflowConditions.push(newCondition);
		this.form.markAsDirty();
	}

	onWorkflowTypeChange(event: MatSelectChange) {
		const currentWorkflowTypeKey = event.value as keyof typeof BasicWorkflowType;
		if (currentWorkflowTypeKey == 'MatterUpdated') {
			this.matterWorkflowConditions.addValidators(
				CustomValidators.requiredWhen(() => this.form?.controls?.workflowType?.value === 'MatterUpdated')
			);
			this.matterWorkflowConditions.clear();
			this.addMatterWorkflowCondition();
		} else {
			this.matterWorkflowConditions.clear();
			this.matterWorkflowConditions.clearValidators();
		}
		this.matterWorkflowConditions.updateValueAndValidity();
		this.form.updateValueAndValidity();
	}

	private updateDescriptionText() {
		switch (this.form.controls.workflowType.value) {
			case this.matterCreated_WorkflowTypeKey:
				this.descriptionText =
					'This workflow will allow configuring automatic generation of tasks when a new Matter is created';
				break;

			case this.matterUpdated_WorkflowTypeKey:
				this.descriptionText =
					'This workflow will allow configuring automatic generation of tasks when a Matter is updated';
				break;

			case this.stageChanged_WorkflowTypeKey:
				this.descriptionText =
					'This workflow will allow configuring automatic generation of tasks when a new Matter Stage is changed';
				break;
		}
	}
}
