// This file has been generated from UserTypeDto.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.Domain.Settings.UserTypes.Item.UserTypeDto
export class UserTypeDto {

	// Id
	id: string;
	// Name
	name: string;
	// Description
	description: string;

	constructor() {

	}
}