// This file has been generated from TaskTemplateAssignedToContactType.cs
// Do not modify this file directly!!

// LawMaster.Amalthea.Domain.Settings.TaskTemplates.Common.TaskTemplateAssignedToContactType
export enum TaskTemplateAssignedToContactType {
	MatterCreator = 'Matter Creator',
	AssignedLawyer = 'Assigned Lawyer',
	SelectedContact = 'Selected Contact',
	UserType = 'User Type'
}
