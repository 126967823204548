<ng-container>
	<div class="flex-row" [formGroup]="allocationGroup">
		<matter-lookup
			#matterLookup
			class="related-to-entity"
			(Selected)="onMatterSelected()"
			Placeholder="Matter"
			[Required]="true"
			[Status]="'Open'"
			[FormControl]="getAllocationGroupControl('matterId')"
			[Hint]="hintText"
		>
		</matter-lookup>
		<editable-numeric-value
			#amountCtrl
			placeHolder="Amount"
			[control]="allocationGroup.get('amount')"
			[isCurrency]="true"
			[editMode]="true"
		></editable-numeric-value>
		<mat-form-field *ngIf="!hideDescription">
			<input formControlName="description" matInput autocomplete="off" />
			<mat-placeholder
				>Description
				<sup class="color-warn">*</sup>
			</mat-placeholder>
			<mat-hint>Reason for allocation</mat-hint>
			<mat-error>
				<error-messages [for]="allocationGroup.get('description')"></error-messages>
			</mat-error>
		</mat-form-field>
		<button class="button--full" mat-icon-button title="Remove" *ngIf="!hideDelete" throttleButton (throttledClick)="removeAllocation()">
			<mat-icon>delete</mat-icon>
		</button>
	</div>
	<div class="flex-row flex-row--mobile" [formGroup]="allocationGroup">
		<button mat-raised-button title="Remove" *ngIf="!hideDelete" throttleButton (throttledClick)="removeAllocation()">
			<mat-icon>delete</mat-icon>
			<span>Remove</span>
		</button>
	</div>
	<div *ngIf="warningText && allocationGroup.valid" class="info-message color-warning">
		<mat-icon>info</mat-icon>
		<span>
			{{ warningText }}
		</span>
	</div>
</ng-container>
