// This file has been generated from CollectionTaskDataDto.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.Domain.Tasks.Item.CollectionTaskDataDto
export class CollectionTaskDataDto {

	// CustomFields
	customFields: { [key: string]: any; };

	constructor() {

	}
}