<ng-container *ngIf="!isEditMode">
	<ng-content></ng-content>
</ng-container>

<ng-container *ngIf="isEditMode">
	<div class="flex-container">
		<editable-numeric-value [control]="form.get('nextNumber')" hintText="The next Matter number" [editMode]="true"></editable-numeric-value>

		<button
			mat-icon-button
			class="small"
			color="primary"
			[disabled]="!!form.invalid || !!form.disabled || !!form.pristine"
			throttleButton
			(throttledClick)="update()"
		>
			<mat-icon>done</mat-icon>
		</button>

		<button mat-icon-button class="small" color="warn" throttleButton (throttledClick)="cancelEditing()">
			<mat-icon>close</mat-icon>
		</button>
	</div>
</ng-container>
