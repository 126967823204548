// This file has been generated from DocumentBriefCreateUpdateDto.cs
// Do not modify this file directly!!
// tslint:disable

import { BriefSection } from './BriefSection';

// LawMaster.Amalthea.Domain.DocumentBriefs.Item.DocumentBriefCreateUpdateDto
export class DocumentBriefCreateUpdateDto {

	// Name
	name: string;
	// MatterId
	matterId: string;
	// TemplateId
	templateId: string;
	// Sections
	sections: BriefSection[];

	constructor() {
		this.sections = [];
	}
}