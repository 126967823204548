import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

import 'moment-duration-format';

import { CustomValidators } from '@common/validation/custom.validators';
import { isEmpty, isNull, isUndefined } from 'lodash-es';
import * as moment from 'moment-timezone';

@Component({
	selector: 'duration-field',
	styleUrls: ['./duration-field.component.scss'],
	templateUrl: 'duration-field.component.html'
})
export class DurationFieldComponent implements OnInit, OnDestroy {
	@Input() FormControl: FormControl;
	@Input() Required: boolean;
	@Input() unitsPerHour: number;
	@Output() durationChanged: EventEmitter<number> = new EventEmitter<number>();
	@Output() focused: EventEmitter<any> = new EventEmitter();
	@ViewChild('durationInput', { static: true }) durationInput: ElementRef;

	userInputtingValue: boolean;

	inputDisplayCtrl: FormControl = new FormControl();

	defaultHint = 'Enter hours or minutes eg. 1.5 = 1 hr 30 min';
	hint: string = this.defaultHint;

	private subscription = new Subscription();
	private _nonFloatCharRegex = /[^0-9\-\.]/g;
	private _intCharRegex = /[0-9\-]/g;

	ngOnInit(): void {
		this.inputDisplayCtrl.setValidators([
			CustomValidators.requiredWhen(() => this.Required && this.FormControl.dirty, 'Duration'),
			maxDurationValidator()
		]);

		this.subscription.add(
			this.inputDisplayCtrl.valueChanges
				.pipe(
					filter(() => this.inputDisplayCtrl.dirty),
					distinctUntilChanged()
				)
				.subscribe((value: string) => {
					const selectedValue = value;
					const minutes = this.parseValue(value);

					if (this.inputDisplayCtrl.dirty) this.durationChanged.emit(minutes);

					this.userInputtingValue = true;
					this.FormControl.setValue(minutes);
					this.FormControl.markAsDirty();
					this.userInputtingValue = false;

					const displayValue = this.formatValue(minutes);
					const units = Math.ceil((minutes * this.unitsPerHour) / 60);
					if (selectedValue !== displayValue) {
						this.hint =
							minutes > 0
								? `${this.formatValue(minutes)} ${this.unitsPerHour ? `= ${units} units` : ''} `
								: this.defaultHint;
					}
				})
		);

		this.subscription.add(
			this.FormControl.valueChanges.subscribe((value: number) => {
				if (isNull(value)) {
					this.inputDisplayCtrl.reset();
					this.hint = this.defaultHint;
				} else if (!isUndefined(value) && !this.userInputtingValue) {
					this.inputDisplayCtrl.setValue(this.formatValue(value));
					this.FormControl.markAsDirty();
				}
			})
		);

		this.subscription.add(
			this.FormControl.statusChanges.pipe(distinctUntilChanged()).subscribe(status => {
				this.inputDisplayCtrl.setErrors(this.inputDisplayCtrl.errors);
				this.inputDisplayCtrl.markAsTouched(); // so any validation messages will be displayed
			})
		);
		// set the initial disabled state
		if (this.FormControl.disabled) {
			this.inputDisplayCtrl.disable();
		}

		// update on any changes of disabled state
		this.FormControl.registerOnDisabledChange((disabled: boolean) => {
			if (disabled) {
				this.inputDisplayCtrl.disable();
			} else {
				this.inputDisplayCtrl.enable();
			}
		});
	}

	setFocus() {
		if (this.durationInput) {
			this.durationInput.nativeElement.focus();
		}
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	onFocus(event: any) {
		this.selectText();
		this.focused.emit(event);
	}

	selectText(): void {
		if (this.durationInput) {
			this.durationInput.nativeElement.select();
		}
	}

	onBlur() {
		// when leaving the input control, update the form control with the parsed value (in minutes)
		this.FormControl.setValue(this.parseValue(this.inputDisplayCtrl.value));
		this.FormControl.markAsDirty();
	}

	private formatValue(value: number): string {
		return moment.duration(value, 'minutes').format('h [hrs] m [min]');
	}

	private parseValue(displayValue?: string): number {
		if (isEmpty(displayValue) || this.inputDisplayCtrl.invalid) return undefined;

		let value: number;

		if (displayValue.indexOf('h') !== -1 || displayValue.indexOf('m') !== -1) {
			value = this.parseDisplay(displayValue);
		} else if (displayValue.indexOf(':') !== -1) {
			value = this.parseTimerMode(displayValue);
		} else if (displayValue.indexOf('.') !== -1) {
			value = this.parseHours(displayValue);
		} else if (!!displayValue && displayValue.search(/[^0-9\-]/g) === -1) {
			value = this.parseMinutes(displayValue);
		} else {
			value = this.parseOther(displayValue);
		}

		return isNaN(value) ? undefined : Math.round(value);
	}

	private parseDisplay(displayValue: string): number {
		let minutes = 0;
		let hours = 0;

		if (displayValue.indexOf('h') !== -1) {
			const hourParts = displayValue.split('h');
			if (hourParts.length !== 2 || hourParts[0].indexOf('m') !== -1) {
				// they tried multiple h's or in the wrong order
				this.addError('Invalid duration input. Try "1h 15m"');
				return NaN;
			}

			hours = parseFloat(this.trimNonNumberChars(hourParts[0]));
			displayValue = hourParts[1];
		}

		if (displayValue.indexOf('m') !== -1) {
			if (!Number.isInteger(hours)) {
				// they tried to enter a decimal with minutes
				this.addError('Invalid duration input. Try "1h 30m" or "1.5h"');
				return NaN;
			}
			const minutesParts = displayValue.split('m');
			if (minutesParts.length !== 2 || minutesParts[1].search(this._intCharRegex) !== -1) {
				// they tried multiple m's or in the wrong order
				this.addError('Invalid duration input. Try "1h 30m"');
				return NaN;
			}

			const mins = parseFloat(this.trimNonNumberChars(minutesParts[0]));
			minutes = mins > 0 ? Math.ceil(mins) : Math.floor(mins);
		}

		return moment
			.duration({
				hours,
				minutes
			})
			.asMinutes();
	}

	private parseMinutes(displayValue: string): number {
		const minutes = parseInt(displayValue, 10);
		return moment.duration(minutes, 'minutes').asMinutes();
	}

	private parseHours(displayValue: string): number {
		if (displayValue.search(this._nonFloatCharRegex) !== -1) {
			// they entered a non float
			this.addError('Invalid duration input. Try "2.5"');
			return NaN;
		}
		const hours = parseFloat(displayValue);
		return moment.duration(hours, 'hours').asMinutes();
	}

	private parseTimerMode(displayValue: string): number {
		const colonsCount = displayValue.split(':').length - 1;
		if (colonsCount !== 1 || displayValue.search(this._intCharRegex) > 0 || displayValue.search(/[0-9]{3,}/g) > 0) {
			// they entered an extra : or other chars
			this.addError('Invalid duration input. Try "1:15"');
			return NaN;
		}

		return moment.duration(`${displayValue}:00`).asMinutes();
	}

	private parseOther(displayValue: string): number {
		if (!displayValue) {
			// they removed the value
			return NaN;
		}

		const duration = moment.duration(displayValue);

		if (!duration || duration.asMinutes() === 0) {
			// they entered something moment couldnt parse
			this.addError('Invalid duration input. Try "1h 15m"');
			return NaN;
		}

		return duration.asMinutes();
	}

	private trimNonNumberChars(input: string): string {
		return input.replace(this._nonFloatCharRegex, '');
	}

	private addError(error: string) {
		this.inputDisplayCtrl.setErrors({ duration: error });
	}
}

export function maxDurationValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors => {
		const value = control.value;
		return value > 525600 ? { maxDuration: 'Duration too long (must be less than 365 days)' } : null;
	};
}
