<div class="c-page-header">
	<mat-icon throttleButton (throttledClick)="goBack()">arrow_back</mat-icon>
	<h2>Marketplace</h2>
</div>

<div class="c-grid">
	<mat-card class="c-header">
		<mat-tab-group (selectedTabChange)="selectedTabChanged($event)" throttleButton (throttledClick)="tabClicked($event)" [selectedIndex]="selectedTabIndex">
			<mat-tab>
				<ng-template mat-tab-label>
					<span>All</span>
				</ng-template>
			</mat-tab>
			<mat-tab>
				<ng-template mat-tab-label>
					<span
						>Installed <ng-container *ngIf="installedCount > 0">({{ installedCount }})</ng-container></span
					>
				</ng-template>
			</mat-tab>
			<mat-tab>
				<ng-template mat-tab-label>
					<span
						>Updates <ng-container *ngIf="updateableCount > 0">({{ updateableCount }})</ng-container></span
					>
				</ng-template>
			</mat-tab>
		</mat-tab-group>
		<div class="mobile-filter">
			<button throttleButton (throttledClick)="toggleMobileFilter()" mat-icon-button>
				<mat-icon *ngIf="!isMobileFilterOpen">filter_list</mat-icon> <mat-icon *ngIf="!!isMobileFilterOpen">close</mat-icon>
				<span class="mobile-filter-text">Discover</span>
			</button>
		</div>
	</mat-card>

	<!-- Displayed on Medium & Up -->
	<mat-card class="c-search" [class.c-search--disabled]="!!this.viewId" [formGroup]="form">
		<mat-form-field floatLabel="never">
			<input matInput placeholder="Search" autocomplete="off" formControlName="search" />
			<button *ngIf="!form.controls.search.value?.length" matSuffix mat-icon-button disabled>
				<mat-icon>search</mat-icon>
			</button>
			<button *ngIf="!!form.controls.search.value?.length" matSuffix mat-icon-button throttleButton (throttledClick)="clearSearch()">
				<mat-icon>clear</mat-icon>
			</button>
		</mat-form-field>
	</mat-card>

	<!-- Displayed on Medium & Up -->
	<mat-card class="c-sidebar" *ngIf="!!categories?.length">
		<div class="c-sidebar__title">Discover</div>

		<div class="c-sidebar__content">
			<div
				class="c-sidebar__content__category"
				[class.c-sidebar__content__category--selected]="!isAnyCategorySelected()"
				throttleButton
				(throttledClick)="deselectCategories()"
			>
				<span>All Add-Ons</span>
			</div>

			<div
				*ngFor="let category of categories"
				class="c-sidebar__content__category"
				[class.c-sidebar__content__category--selected]="!!isCategorySelected(category)"
				throttleButton
				(throttledClick)="toggleCategory(category)"
			>
				<span>{{ category }}</span>
			</div>
		</div>
	</mat-card>

	<!-- Displayed on Small & XSmall -->
	<mat-card *ngIf="!!isMobileFilterOpen" class="c-mobile-filter">
		<div [formGroup]="form" class="c-mobile-filter__search">
			<mat-form-field floatLabel="never">
				<input matInput placeholder="Search" (focus)="this.deselectCategories()" autocomplete="off" formControlName="search" />
				<button *ngIf="!form.controls.search.value?.length" matSuffix mat-icon-button disabled>
					<mat-icon>search</mat-icon>
				</button>
				<button *ngIf="!!form.controls.search.value?.length" matSuffix mat-icon-button throttleButton (throttledClick)="clearSearch()">
					<mat-icon>clear</mat-icon>
				</button>
			</mat-form-field>
		</div>

		<div *ngIf="!searchHasValue" class="c-mobile-filter__title">Discover</div>

		<div *ngIf="!searchHasValue" class="c-mobile-filter__content">
			<div
				class="c-mobile-filter__content__category"
				[class.c-mobile-filter__content__category--selected]="!isAnyCategorySelected()"
				throttleButton
				(throttledClick)="deselectCategories(); toggleMobileFilter()"
			>
				<span>All Integrations</span>
			</div>

			<div
				*ngFor="let category of categories"
				class="c-mobile-filter__content__category"
				[class.c-mobile-filter__content__category--selected]="!!isCategorySelected(category)"
				throttleButton
				(throttledClick)="toggleCategory(category); toggleMobileFilter()"
			>
				<span>{{ category }}</span>
			</div>
		</div>
	</mat-card>

	<div class="c-container" [class.c-container--single-item]="!!viewId">
		<div class="c-container__contents" *ngIf="!viewId && !!filteredResponse?.length">
			<mat-card class="c-item" *ngFor="let record of filteredResponse" throttleButton (throttledClick)="openOverview(record)">
				<div class="c-item__overlay" *ngIf="!!isInstalled(record)">
					<div class="c-item__overlay__installed">
						<span>Subscribed</span>
						<mat-icon>check</mat-icon>
					</div>
				</div>

				<div class="c-item__content">
					<div class="c-item__content__logo">
						<marketplace-icon [id]="record.id" [type]="record.listingType"></marketplace-icon>
					</div>
					<div class="c-item__content__title">{{ record.productName }}</div>

					<div *ngIf="!!record.packageDetails?.vendorName" class="c-item__content__company">{{ record.packageDetails?.vendorName }}</div>

					<div class="c-item__content__price" *ngIf="!!getPricing(record).primary?.length">
						<span>{{ getPricing(record).primary }}</span>
					</div>

					<div class="c-item__content__price_secondary" *ngIf="!_primarySubscriptionInterval && !!getPricing(record).secondary?.length">
						<span>{{ getPricing(record).secondary }}</span>
					</div>

					<div *ngIf="!!record.productSummary" class="c-item__content__description">{{ record.productSummary }}</div>

					<div *ngIf="!!record.lastModifiedBy && !!isInstalled(record)" class="c-item__content__installed-by">
						Subscribed by {{ record.lastModifiedBy?.fullName }}
					</div>
					<div *ngIf="!!showUpdateDate()" class="c-item__content__last-updated">
						<span>Last Updated {{ record.lastUpdatedDate | moment : 'dd/MM/yyyy' }}</span>
					</div>
					<div class="c-item__content__actions">
						<a class="c-item__content__actions__button">
							<span>Learn More</span>
							<mat-icon class="c-item__content__actions__button__icon">arrow_forward</mat-icon>
						</a>
					</div>
				</div>
			</mat-card>
		</div>

		<marketplace-listing-overview
			*ngIf="!!viewId && !isMobileFilterOpen"
			[listingType]="listingType"
			[marketplaceListingId]="viewId"
			(stateChanged)="onListingChanged()"
		></marketplace-listing-overview>
	</div>
</div>
