// This file has been generated from DocumentBriefTemplatesController.cs
// Do not modify this file directly!!
// tslint:disable

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { TrimStringsOnObject } from '@common/utils/string-format';

import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';
import { DocumentBriefTemplateDto } from '../models/DocumentBriefTemplates/Item/DocumentBriefTemplateDto';
import { DocumentBriefTemplateListItemDto } from '../models/DocumentBriefTemplates/List/DocumentBriefTemplateListItemDto';
import { DocumentBriefTemplateListRequest } from '../models/DocumentBriefTemplates/List/DocumentBriefTemplateListRequest';
import { ListResponse } from '../models/Generic/ListResponse';

@Injectable({
	providedIn: 'root'
})
export class DocumentBriefTemplatesService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(
			httpClient,
			settings.getAppServerUrl(),
			'api/v1/settings/DocumentBriefTemplates',
			'Document Brief Template'
		);
	}

	getTemplate(id: string): Observable<DocumentBriefTemplateDto> {
		return this.getItem<void, DocumentBriefTemplateDto>(`${id}`);
	}

	getTemplates(
		request?: Partial<DocumentBriefTemplateListRequest>
	): Observable<ListResponse<DocumentBriefTemplateListItemDto>> {
		return this.getList<DocumentBriefTemplateListRequest, DocumentBriefTemplateListItemDto>('', request);
	}

	addTemplate(request: DocumentBriefTemplateDto): Observable<MutationResponseDto> {
		return this.post<DocumentBriefTemplateDto, MutationResponseDto>(
			'',
			TransformDatesOnObject(DocumentBriefTemplateDto, TrimStringsOnObject(request))
		);
	}

	updateTemplate(id: string, dto: DocumentBriefTemplateDto): Observable<MutationResponseDto> {
		return this.put(`${id}`, TransformDatesOnObject(DocumentBriefTemplateDto, TrimStringsOnObject(dto)));
	}

	deleteTemplate(id: string): Observable<MutationResponseDto> {
		return this.delete(`${id}`);
	}
}
