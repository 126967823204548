<div class="flex-column" [formGroup]="form">
	<ng-container *ngIf="!!isUpdate">
		<p>{{ operationAdjectiveName }} this package could change custom fields and remove document templates.</p>
	</ng-container>

	<ng-container *ngIf="!isUpdate">
		<p *ngIf="!!anyAffectedEntities">{{ operationAdjectiveName }} this package will {{ !!isInstall ? 'add' : 'remove' }} the following items:</p>
		<ul *ngIf="!!anyAffectedEntities">
			<li *ngIf="affectedCustomFieldsCount > 0">{{ affectedCustomFieldsCount }} Custom Fields</li>
			<li *ngIf="affectedCustomFieldGroupsCount > 0">{{ affectedCustomFieldGroupsCount }} Custom Field Groups</li>
			<li *ngIf="affectedDocumentTemplatesCount > 0">{{ affectedDocumentTemplatesCount }} Document Templates</li>
			<li *ngIf="affectedDocumentHeaderFooterTemplatesCount > 0">{{ affectedDocumentHeaderFooterTemplatesCount }} Header/Footer Templates</li>
			<li *ngIf="affectedEmailTemplatesCount > 0">{{ affectedEmailTemplatesCount }} Email Templates</li>
			<li *ngIf="affectedPracticeAreasCount > 0">{{ affectedPracticeAreasCount }} Practice Areas</li>
		</ul>
	</ng-container>

	<!-- <div class="flex-column c-checkbox-container" *ngIf="!!canMapPracticeAreas">
		<mat-checkbox color="primary" formControlName="mapPracticeAreas">
			<span>Map package practice areas to existing practice areas in {{ siteName }}</span>
		</mat-checkbox>
	</div> -->

	<ng-container *ngIf="!!mustMapPracticeAreas">
		<div class="flex-column">
			<div class="flex-row" *ngIf="!canMapPracticeAreas">
				<mat-form-field>
					<mat-placeholder>Practice Areas</mat-placeholder>
					<mat-select #selectPracticeAreas formControlName="practiceAreaIds" multiple>
						<mat-option (onSelectionChange)="allOptionSelected($event)" #allOption>All</mat-option>
						<mat-option (onSelectionChange)="optionSelected($event)" *ngFor="let practiceArea of practiceAreas" [value]="practiceArea.id">{{
							practiceArea.name
						}}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<!-- 
			<ng-container *ngIf="!!canMapPracticeAreas">
				<p>
					Map package practice areas to existing practice areas within {{ siteName }}. Any unmapped package practice area will be added to
					{{ siteName }}.
				</p>

				<div class="flex-practice-area">
					<div class="flex-practice-area-container">
						<h4 class="flex-practice-area-heading flex-practice-area-heading--start">Package</h4>
						<h4 class="flex-practice-area-heading flex-practice-area-heading--end">{{ siteName }}</h4>

						<ng-container *ngFor="let practiceAreaControl of packagePracticeAreaControls">
							<div class="flex-practice-area-mapping-title">
								<span>{{ getPracticeArea(getControlValue(practiceAreaControl, 'practiceAreaId')).name }}</span>
								<span
									*ngIf="!!getPracticeArea(getControlValue(practiceAreaControl, 'practiceAreaId')).description"
									class="flex-practice-area-mapping-description"
									>{{ getPracticeArea(getControlValue(practiceAreaControl, 'practiceAreaId')).description }}</span
								>
							</div>
							<mat-icon class="flex-practice-area-mapping-icon">east</mat-icon>
							<mat-form-field class="flex-practice-area-mapping-field">
								<mat-placeholder>Practice Areas</mat-placeholder>
								<mat-select #selectPracticeAreas [formControl]="getControl(practiceAreaControl, 'practiceAreaMappingId')" multiple>
									<mat-option (onSelectionChange)="allOptionSelected($event)" #allOption>All</mat-option>
									<mat-option
										(onSelectionChange)="optionSelected($event)"
										*ngFor="let practiceArea of practiceAreas"
										[value]="practiceArea.id"
										>{{ practiceArea.name }}</mat-option
									>
								</mat-select>
							</mat-form-field>
						</ng-container>
					</div>
				</div>
			</ng-container> -->
		</div>
	</ng-container>
</div>
