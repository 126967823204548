import { NgModule } from '@angular/core';

import { TenantFeatureDirective } from './tenantFeature.directive';
import { TenantFeatureAnyDirective } from './tenantFeatureAny.directive';
import { TenantFeatureNotDirective } from './tenantFeatureNot.directive';

@NgModule({
	declarations: [TenantFeatureDirective, TenantFeatureAnyDirective, TenantFeatureNotDirective],
	exports: [TenantFeatureDirective, TenantFeatureAnyDirective, TenantFeatureNotDirective]
})
export class DirectivesModule {}
