// This file has been generated from XeroConnectionStatus.cs
// Do not modify this file directly!!

// LawMaster.Amalthea.Domain.Bills.XeroSync.XeroConnectionStatus
export enum XeroConnectionStatus {
	None = 'None',
	UserConnected = 'UserConnected',
	TenantConnected = 'TenantConnected',
	UserAndTenantConnected = 'UserAndTenantConnected'
}
