// This file has been generated from PracticeAreasController.cs
// Do not modify this file directly!!
// tslint:disable

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { TrimStringsOnObject } from '@common/utils/string-format';

import { EntityReference } from '../../models/Common/EntityReference';
import { ListResponse } from '../../models/Generic/ListResponse';
import { PracticeAreaCreateUpdateDto } from '../../models/Settings/PracticeAreas/Item/PracticeAreaCreateUpdateDto';
import { PracticeAreaTimerUpdateDto } from '../../models/Settings/PracticeAreas/Item/PracticeAreaTimerUpdateDto';
import { PracticeAreaViewDto } from '../../models/Settings/PracticeAreas/Item/PracticeAreaViewDto';
import { PracticeAreaListItemDto } from '../../models/Settings/PracticeAreas/List/PracticeAreaListItemDto';
import { PracticeAreaListRequest } from '../../models/Settings/PracticeAreas/List/PracticeAreaListRequest';
import { PracticeAreaStageListItemDto } from '../../models/Settings/PracticeAreas/List/PracticeAreaStageListItemDto';
import { PracticeAreaStageListRequest } from '../../models/Settings/PracticeAreas/List/PracticeAreaStageListRequest';
import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';

@Injectable({
	providedIn: 'root'
})
export class PracticeAreasService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'api/v1/settings/practiceareas', 'Practice Area');
	}

	createPracticeArea(newPracticeAreaDto: PracticeAreaCreateUpdateDto): Observable<MutationResponseDto> {
		return this.post<PracticeAreaCreateUpdateDto, MutationResponseDto>('', TransformDatesOnObject(PracticeAreaCreateUpdateDto, TrimStringsOnObject(newPracticeAreaDto)));
	}

	updatePracticeArea(id: string, dto: PracticeAreaCreateUpdateDto): Observable<MutationResponseDto> {
		return this.put(`${id}`, TransformDatesOnObject(PracticeAreaCreateUpdateDto, TrimStringsOnObject(dto)));
	}

	updatePracticeAreaTimer(id: string, dto: PracticeAreaTimerUpdateDto): Observable<any> {
		return this.put(`${id}/timer`, TransformDatesOnObject(PracticeAreaTimerUpdateDto, TrimStringsOnObject(dto)));
	}

	disablePracticeArea(id: string): Observable<MutationResponseDto> {
		return this.put(`${id}/disable`);
	}

	enablePracticeArea(id: string): Observable<MutationResponseDto> {
		return this.put(`${id}/enable`);
	}

	deletePracticeArea(id: string): Observable<MutationResponseDto> {
		return this.delete(`${id}`);
	}

	getPracticeArea(id: string): Observable<PracticeAreaViewDto> {
		return this.getItem<void, PracticeAreaViewDto>(`${id}`);
	}

	getPracticeAreaForMatter(matterId: string): Observable<PracticeAreaViewDto> {
		return this.getItem<void, PracticeAreaViewDto>(`matter/${matterId}`);
	}

	getPracticeAreaList(request?: Partial<PracticeAreaListRequest>): Observable<ListResponse<PracticeAreaListItemDto>> {
		return this.getList<PracticeAreaListRequest, PracticeAreaListItemDto>('', request);
	}

	getPracticeAreaStageList(request: Partial<PracticeAreaStageListRequest>): Observable<PracticeAreaStageListItemDto[]> {
		return this.getArray<PracticeAreaStageListRequest, PracticeAreaStageListItemDto>(`stages`, request);
	}

}
