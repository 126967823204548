import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { Subscription } from 'rxjs';

import 'moment-duration-format';

import { ContentsPageOptions } from '@common/models/DocumentBriefs/Item/ContentsPageOptions';
import { PageNumberOptions } from '@common/models/DocumentBriefs/Item/PageNumberOptions';
import { PagePosition } from '@common/models/DocumentBriefs/Item/PagePosition';
import { TitlePageOptions } from '@common/models/DocumentBriefs/Item/TitlePageOptions';
import { DocumentBriefExportOptionsDto } from '@common/models/Settings/Setting/Item/DocumentBriefExportOptionsDto';
import { NotificationService } from '@common/notification';
import { GeneralSettingsService } from '@common/services/settings/generalsettings.service';

import { CustomValidators } from '@common/validation/custom.validators';
import { IFontOption } from 'app/shared/components/font-option/font-option.component';

@Component({
	selector: 'document-brief-export-settings',
	styleUrls: ['./document-brief-export-settings.component.scss'],
	templateUrl: './document-brief-export-settings.component.html'
})
export class DocumentBriefExportSettingsComponent implements OnDestroy, OnInit {
	data: DocumentBriefExportOptionsDto;
	form: FormGroupTyped<DocumentBriefExportOptionsDto>;
	pagePositionKeys = Object.keys(PagePosition) as Array<keyof typeof PagePosition>;
	private subscriptions: Subscription = new Subscription();

	constructor(
		private fb: FormBuilder,
		private notificationService: NotificationService,
		private generalSettingsService: GeneralSettingsService
	) {
		this.pagePositionKeys = this.pagePositionKeys.sort();
	}

	ngOnInit(): void {
		this.setupForm();

		this.subscriptions.add(
			this.generalSettingsService.getDocumentBriefExportOptions().subscribe(globalSettings => {
				this.data = globalSettings;
				this.form.patchValue(this.data);
			})
		);

		this.subscriptions.add(
			this.form.controls.titlePageOptions.get('includeTitlePage').valueChanges.subscribe((value: boolean) => {
				setTimeout(() => {
					this.form.controls.titlePageOptions.get('templateId').updateValueAndValidity();
				}, 0);
			})
		);

		this.subscriptions.add(
			this.form.controls.pageNumberOptions.get('includePageNumber').valueChanges.subscribe((value: boolean) => {
				setTimeout(() => {
					this.form.controls.pageNumberOptions.get('pagePosition').updateValueAndValidity();
					this.form.controls.pageNumberOptions.get('pageNumberFormat').updateValueAndValidity();
					this.form.controls.pageNumberOptions.get('horizontalOffset').updateValueAndValidity();
					this.form.controls.pageNumberOptions.get('verticalOffset').updateValueAndValidity();
					this.form.controls.pageNumberOptions.get('fontName').updateValueAndValidity();
					this.form.controls.pageNumberOptions.get('fontSize').updateValueAndValidity();
				}, 0);
			})
		);
	}

	setupForm(): void {
		this.form = this.fb.group({
			documentSummaryPage: this.data?.documentSummaryPage,
			includeEmailAttachments: this.data?.includeEmailAttachments,
			sectionTitlePage: this.data?.sectionTitlePage,
			titlePageOptions: this.fb.group({
				includeTitlePage: [this.data?.titlePageOptions?.includeTitlePage],
				templateId: [
					this.data?.titlePageOptions?.templateId,
					CustomValidators.requiredWhen(() => this.includeTitlePage, 'Template')
				]
			}) as FormGroupTyped<TitlePageOptions>,
			contentsPageOptions: this.fb.group({
				includeContentsPage: [this.data?.contentsPageOptions?.includeContentsPage],
				hidePageNumbering: {
					value: this.data?.contentsPageOptions?.hidePageNumbering
				}
			}) as FormGroupTyped<ContentsPageOptions>,
			pageNumberOptions: this.fb.group({
				includePageNumber: [this.data?.pageNumberOptions?.includePageNumber],
				pagePosition: [
					this.data?.pageNumberOptions?.pagePosition,
					CustomValidators.requiredWhen(
						() => this.form?.value?.pageNumberOptions?.includePageNumber,
						'Page Position'
					)
				],
				pageNumberFormat: [
					this.data?.pageNumberOptions?.pageNumberFormat,
					CustomValidators.requiredWhen(
						() => this.form?.value?.pageNumberOptions?.includePageNumber,
						'Page Number Format'
					)
				],
				horizontalOffset: [
					this.data?.pageNumberOptions?.horizontalOffset,
					CustomValidators.requiredWhen(
						() => this.form?.value?.pageNumberOptions?.includePageNumber,
						'Horizontal Offset'
					)
				],
				verticalOffset: [
					this.data?.pageNumberOptions?.verticalOffset,
					CustomValidators.requiredWhen(
						() => this.form?.value?.pageNumberOptions?.includePageNumber,
						'Vertical Offset'
					)
				],
				fontName: [
					this.data?.pageNumberOptions?.fontName,
					CustomValidators.requiredWhen(
						() => this.form?.value?.pageNumberOptions?.includePageNumber,
						'Font Name'
					)
				],
				fontSize: [
					this.data?.pageNumberOptions?.fontSize,
					CustomValidators.requiredWhen(
						() => this.form?.value?.pageNumberOptions?.includePageNumber,
						'Font Size'
					)
				],
				isBold: this.data?.pageNumberOptions?.isBold,
				isItalic: this.data?.pageNumberOptions?.isItalic,
				isUnderline: this.data?.pageNumberOptions?.isUnderline,
				isStrikeout: this.data?.pageNumberOptions?.isStrikeout
			}) as FormGroupTyped<PageNumberOptions>
		}) as FormGroupTyped<DocumentBriefExportOptionsDto>;
	}

	save(): void {
		this.data = this.form.value;

		this.subscriptions.add(
			this.generalSettingsService.saveDocumentBriefExportOptions(this.data).subscribe(
				() => {
					this.notificationService.showNotification('Settings saved successfully');
					this.form.markAsPristine();
				},
				error => {
					this.notificationService.showError(`Error saving settings`, error);
				}
			)
		);
	}

	get includeTitlePage(): boolean {
		return this.form?.controls?.titlePageOptions?.value?.includeTitlePage;
	}

	get includePageNumber(): boolean {
		return this.form?.value?.pageNumberOptions?.includePageNumber;
	}

	get pageNumberOptionsControl(): FormGroupTyped<IFontOption> {
		return this.form?.get('pageNumberOptions') as any as FormGroupTyped<IFontOption>;
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}
}
