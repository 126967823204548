import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

/*
 * Resolve transaction amount display string
 * Calls currency pipe under the hood, supporting all of its parameters.
 * Always returns positive values with DR / CR (debit / credit) prefix
 */
@Pipe({ name: 'transactionAmount' })
export class TransactionAmountPipe implements PipeTransform {
	transform(
		value: any,
		currencyCode?: string,
		display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
		digitsInfo?: string,
		locale?: string,
		debitPrefix?: string,
		creditPrefix?: string
	): string | null {
		const pipe: CurrencyPipe = new CurrencyPipe('en-AUS');

		return (
			(value > 0 ? creditPrefix || '' : debitPrefix || '- ') +
			pipe.transform(value, currencyCode, display, digitsInfo, locale)
		).replace('-', '');
	}
}
