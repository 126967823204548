// This file has been generated from ImportListRequest.cs
// Do not modify this file directly!!
// tslint:disable

import { EnumSortDirection } from '../../Common/EnumSortDirection';
import { ImportEntityType } from '../Common/ImportEntityType';

// LawMaster.Amalthea.Domain.Imports.List.ImportListRequest
export class ImportListRequest {

	// Search
	search: string;
	// SortBy
	sortBy: string;
	// SortDirection
	sortDirection: keyof typeof EnumSortDirection;
	// PageIndex
	pageIndex: number;
	// PageSize
	pageSize: number;
	// PageIndexForId
	pageIndexForId: string;
	// ImportType
	importType: keyof typeof ImportEntityType;

	constructor() {

	}
}