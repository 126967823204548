import { NgModule } from '@angular/core';

import { FeatureFlagDirective } from './featureFlag.directive';
import { ThrottleButtonDirective } from './throttle-button.directive';

@NgModule({
	declarations: [FeatureFlagDirective, ThrottleButtonDirective],
	exports: [FeatureFlagDirective, ThrottleButtonDirective]
})
export class SharedDirectivesModule {}
