// This file has been generated from DocumentHeaderFooterTemplateUpdateDto.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.Domain.Documents.Item.DocumentHeaderFooterTemplateUpdateDto
export class DocumentHeaderFooterTemplateUpdateDto {

	// Title
	title: string;
	// DocumentTags
	documentTags: string[];

	constructor() {

	}
}