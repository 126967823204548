import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { of } from 'rxjs';
import { concatMap } from 'rxjs/operators';

import { AccessTokenValidationStatus } from '@common/models/Settings/AccessTokens/Common/AccessTokenValidationStatus';
import { AccessTokenValidationDto } from '@common/models/Settings/AccessTokens/Item/AccessTokenValidationDto';
import { DocumentBriefAccessTokensService } from '@common/services/settings/documentbriefaccesstokens.service';
import { SessionStorageService } from '@common/services/storage/session-storage.service';

import { ExternalPortalRouteService } from './external-portal-route-service';

const STORAGE_KEY = 'mattero_portal_access_code';

@Injectable()
export class PortalAuthService {
	private _accessCode: PortalAccessCode;

	private get accessCode(): PortalAccessCode {
		if (!this._accessCode) {
			if (!!this.sessionStorageService.containsKey(STORAGE_KEY)) {
				this._accessCode = this.sessionStorageService.getItemAs<PortalAccessCode>(STORAGE_KEY);
			} else {
				this._accessCode = null;
			}
		}

		return this._accessCode;
	}

	private set accessCode(value: PortalAccessCode) {
		this._accessCode = value;

		if (!value) {
			this.sessionStorageService.removeItem(STORAGE_KEY);
		} else {
			this.sessionStorageService.setItem(STORAGE_KEY, value);
		}
	}

	get isAuthenticated(): boolean {
		return !!this.portalRouteService.routeAccessCode || !!this._accessCode;
	}

	constructor(
		private documentBriefAccessTokenService: DocumentBriefAccessTokensService,
		private sessionStorageService: SessionStorageService,
		private router: Router,
		private portalRouteService: ExternalPortalRouteService
	) {}

	getCode(): string {
		return this.portalRouteService.routeAccessCode ?? this.accessCode?.code;
	}

	trySetCode(code: string, recaptcha: string): Observable<AccessTokenValidationDto> {
		const accessCode = { code, recaptcha };

		return this.documentBriefAccessTokenService.validateToken(this.portalRouteService.briefId, accessCode).pipe(
			concatMap(dto => {
				this.accessCode =
					!!dto?.status && dto.status === AccessTokenValidationStatus.Success ? accessCode : null;

				return of(dto);
			})
		);
	}

	redirectToPortalLoginIfNotLoggedIn() {
		if (!this.accessCode) {
			this.router.navigate([this.portalRouteService.loginUri]);
		}
	}

	redirectToBriefPortalIfLoggedIn() {
		if (!!this.accessCode) {
			this.router.navigate([this.portalRouteService.briefUri]);
		}
	}

	logout(): void {
		this.accessCode = null;
		this.redirectToPortalLoginIfNotLoggedIn();
	}
}

class PortalAccessCode {
	code: string;
}
