// This file has been generated from TaskTemplateDueDateOptions.cs
// Do not modify this file directly!!
// tslint:disable

import { TaskTemplateDueDateOffsetType } from './TaskTemplateDueDateOffsetType';
import { TaskTemplateDueDateSource } from './TaskTemplateDueDateSource';

// LawMaster.Amalthea.Domain.Settings.TaskTemplates.Common.TaskTemplateDueDateOptions
export class TaskTemplateDueDateOptions {

	// OffsetFactor
	offsetFactor: number;
	// OffsetType
	offsetType: keyof typeof TaskTemplateDueDateOffsetType;
	// DueDateSource
	dueDateSource: keyof typeof TaskTemplateDueDateSource;
	// CustomFieldId
	customFieldId: string;

	constructor() {

	}
}