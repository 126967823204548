import { Routes } from '@angular/router';

import { AboutComponent } from './core/about/about.component';
import { AccessRouteGuard } from './core/access-route-guard/access-route.guard';
import { AppConfigPageRouteGuard } from './core/app-config/app-config-page-route-guard';
import { AppConfigPageComponent } from './core/app-config/app-config-page.component';
import { NoContentComponent } from './no-content';
import { PreviewDocumentComponent } from './preview/document/preview-document.component';
import { AppRouteGuardService } from './routing/app-route-guard.service';
import { ReportsRouteGuard } from './routing/reports-route-guard.service';
import { SystemRouteGuardService } from './routing/system-route-guard.service';
import { AppBrandingService } from './services/app-branding.service';
import { InfoTrackAuthHandlerComponent } from './shared/infoTrack-order-list/infotrack-auth-handler.component';
import { TimerRouteGuard } from './system/timer-config/timer-route.guard';

export const ROUTES: Routes = [
	{ path: '', redirectTo: 'dashboard', pathMatch: 'full' },
	{
		path: 'dashboard',
		loadChildren: () => import('./dashboard').then(m => m.DashboardModule),
		data: { title: 'Dashboard — ' + AppBrandingService.getSiteName() }
	},
	{
		path: 'welcome',
		loadChildren: () => import('./dashboard').then(m => m.DashboardModule),
		data: { title: 'Dashboard — ' + AppBrandingService.getSiteName() }
	},
	{
		children: getMatterChildRoutes(),
		path: 'matters'
	},
	{
		children: getPortalChildRoutes(),
		path: 'portal'
	},
	{
		children: [
			{
				path: '',
				loadChildren: () => import('./contact/list').then(m => m.GlobalContactListModule),
				pathMatch: 'full'
			},
			{ path: ':id', loadChildren: () => import('./contact/item').then(m => m.ContactItemModule) }
		],
		path: 'contacts'
	},
	{
		children: getTaskChildRoutes(),
		path: 'tasks',
		canActivate: [AccessRouteGuard],
		data: { protectedFeature: 'Tasks' }
	},
	{
		children: getTimerChildRoutes(),
		path: 'timers',
		canActivate: [AccessRouteGuard, TimerRouteGuard]
	},
	{ path: 'notes', redirectTo: 'documents' },
	{
		children: getDocumentChildRoutes(),
		path: 'documents'
	},
	{
		children: getTimeRecordChildRoutes(),
		path: 'costs',
		canActivate: [AccessRouteGuard],
		data: { protectedFeature: 'CostRecords' }
	},
	{
		children: getBillChildRoutes(),
		path: 'invoices',
		canActivate: [AccessRouteGuard],
		data: { protectedFeature: 'Invoicing' }
	},
	{
		children: getReferralChildRoutes(),
		path: 'referrals',
		canActivate: [AccessRouteGuard],
		data: { protectedFeature: 'Referrals' }
	},
	{
		children: getMergeTaskChildRoutes(),
		path: 'merging',
		canActivate: [AccessRouteGuard]
	},
	{
		children: getCollectionTaskChildRoutes(),
		path: 'collection',
		canActivate: [AccessRouteGuard]
	},
	{
		loadChildren: () => import('./trust/item').then(m => m.TrustListingModule),
		path: 'trust-listing'
	},
	{
		children: getTrustChildRoutes(),
		canActivate: [AppRouteGuardService, AccessRouteGuard],
		data: { protectedFeature: 'TrustAccounting' },
		path: 'trust'
	},
	{
		children: [
			{
				path: '',
				loadChildren: () =>
					import('./reconciliation/trust-reconciliation-list').then(m => m.TrustReconciliationModule),
				pathMatch: 'full',
				data: { title: 'Trust Reconciliation — ' + AppBrandingService.getSiteName() }
			}
		],
		path: 'reconciliations'
	},
	{
		children: [
			{
				path: '',
				loadChildren: () =>
					import('./reconciliation/trust-reconciliation-editor').then(m => m.TrustReconciliationEditorModule),
				pathMatch: 'full',
				data: { title: 'Trust Reconciliation — ' + AppBrandingService.getSiteName() }
			}
		],
		path: 'reconciliation-editor/:id'
	},
	{
		children: getReportsChildRoutes(),
		path: 'reports',
		canActivate: [ReportsRouteGuard],
		canActivateChild: [ReportsRouteGuard]
	},
	{
		component: AboutComponent,
		data: { title: 'About — ' + AppBrandingService.getSiteName() },
		path: 'about',
		pathMatch: 'full'
	},
	{
		children: [
			{
				path: 'users/:id',
				loadChildren: () => import('./system/user-management/item').then(m => m.UserItemModule)
			},
			{
				path: '',
				loadChildren: () => import('./system').then(m => m.SystemModule),
				data: { title: 'System — ' + AppBrandingService.getSiteName() }
			}
		],
		canActivateChild: [SystemRouteGuardService],
		path: 'system'
	},
	{ outlet: 'preview', path: 'document/:id', component: PreviewDocumentComponent },
	{ path: 'infotrack-auth-handler', component: InfoTrackAuthHandlerComponent },
	{ path: 'xero', pathMatch: 'full', redirectTo: '/system/bill-settings?activeTab=xerosync' },
	{ path: 'config', pathMatch: 'full', component: AppConfigPageComponent, canActivate: [AppConfigPageRouteGuard] },
	{ path: '**', component: NoContentComponent }
];

export function getMatterChildRoutes(): Routes {
	return [
		{
			path: '',
			loadChildren: () => import('./matter/list').then(m => m.GlobalMatterListModule),
			pathMatch: 'full'
		},
		{
			loadChildren: () => import('./matter/list').then(m => m.GlobalMatterListModule),
			path: 'my',
			pathMatch: 'full'
		},
		{
			loadChildren: () => import('./matter/list').then(m => m.GlobalMatterListModule),
			path: 'myrecent',
			pathMatch: 'full'
		},
		{ path: ':id', loadChildren: () => import('./matter/item').then(m => m.MatterItemModule) }
	];
}

export function getPortalChildRoutes(): Routes {
	return [
		{
			path: 'brief/:number/:id',
			loadChildren: () => import('./portal/brief').then(m => m.PortalBriefModule)
		},
		{
			path: 'brief/preview/:number/:id',
			loadChildren: () => import('./portal/brief').then(m => m.PortalBriefModule)
		},
		{
			path: 'document/:id',
			loadChildren: () => import('./portal/document').then(m => m.PortalDocumentModule)
		}
	];
}

export function getTrustChildRoutes(): Routes {
	return [
		{ path: '', loadChildren: () => import('./trust/list').then(m => m.GlobalTrustListModule), pathMatch: 'full' },
		{
			loadChildren: () => import('./trust/list').then(m => m.GlobalTrustListModule),
			path: 'ledger',
			pathMatch: 'full'
		},
		{
			loadChildren: () => import('./trust/list').then(m => m.GlobalTrustListModule),
			path: 'cashbook',
			pathMatch: 'full'
		}
	];
}

export function getTaskChildRoutes(): Routes {
	return [
		{ path: '', loadChildren: () => import('./task/list').then(m => m.GlobalTaskListModule), pathMatch: 'full' },
		{
			loadChildren: () => import('./task/list').then(m => m.GlobalTaskListModule),
			path: 'my',
			pathMatch: 'full'
		},
		{
			loadChildren: () => import('./task/list').then(m => m.GlobalTaskListModule),
			path: 'myoverdue',
			pathMatch: 'full'
		},
		{
			loadChildren: () => import('./task/list').then(m => m.GlobalTaskListModule),
			path: 'mytoday',
			pathMatch: 'full'
		},
		{
			loadChildren: () => import('./task/list').then(m => m.GlobalTaskListModule),
			path: 'myfuture',
			pathMatch: 'full'
		},
		{
			loadChildren: () => import('./task/list').then(m => m.GlobalTaskListModule),
			path: 'myreferrals',
			pathMatch: 'full'
		}
	];
}

export function getTimerChildRoutes(): Routes {
	return [{ path: '', loadChildren: () => import('./timers').then(m => m.GlobalTimerListModule), pathMatch: 'full' }];
}

export function getDocumentChildRoutes() {
	return [
		{ path: '', loadChildren: () => import('./document').then(m => m.DocumentModule), pathMatch: 'full' },
		{
			loadChildren: () => import('./document').then(m => m.DocumentModule),
			path: 'myrecent',
			pathMatch: 'full'
		}
	];
}

export function getTimeRecordChildRoutes() {
	return [
		{
			path: '',
			loadChildren: () => import('./cost/list').then(m => m.GlobalCostListModule),
			pathMatch: 'full'
		},
		{
			loadChildren: () => import('./cost/list').then(m => m.GlobalCostListModule),
			path: 'my',
			pathMatch: 'full'
		}
	];
}

export function getBillChildRoutes() {
	return [
		{ path: '', loadChildren: () => import('./bill/list').then(m => m.GlobalBillListModule), pathMatch: 'full' },
		{
			loadChildren: () => import('./bill/list').then(m => m.GlobalBillListModule),
			path: 'my',
			pathMatch: 'full'
		},
		{ path: ':id', loadChildren: () => import('./bill/item').then(m => m.BillItemModule) }
	];
}

export function getReferralChildRoutes() {
	return [{ path: ':id', loadChildren: () => import('./task/referral').then(m => m.ReferralModule) }];
}

export function getMergeTaskChildRoutes() {
	return [{ path: ':id', loadChildren: () => import('./task/merge').then(m => m.MergeTaskModule) }];
}

export function getCollectionTaskChildRoutes() {
	return [{ path: ':id', loadChildren: () => import('./task/collection').then(m => m.CollectionTaskModule) }];
}

export function getReportsChildRoutes() {
	return [
		{
			path: '',
			loadChildren: () => import('./report').then(m => m.ReportModule),
			data: { title: 'Reports — ' + AppBrandingService.getSiteName() }
		},
		{
			loadChildren: () => import('./report').then(m => m.ReportModule),
			path: 'custom',
			pathMatch: 'full'
		},
	];
}
