// tslint:disable:max-classes-per-file
import { Action } from '@ngrx/store';

import { ITrustAccountsData } from '../models/trust-accounts-data';

export const SetTrustAccountsType = 'SetTrustAccountsType';
export const ClearTrustAccountsType = 'ClearTrustAccountsType';

export type TrustAccountsActions = SetTrustAccountsAction | ClearTrustAccountsAction;

export class SetTrustAccountsAction implements Action {
	readonly type = SetTrustAccountsType;

	constructor(public data: ITrustAccountsData) {}
}

export class ClearTrustAccountsAction implements Action {
	readonly type = ClearTrustAccountsType;

	constructor() {}
}
