import { Component, OnInit } from '@angular/core';

import { EntityTypes, IRelatedEntityType } from 'app/shared/entity-type/entity-type';
import { FilterBaseComponent } from './filter-base.component';

@Component({
	selector: 'filter-related-entity-type',
	template: `
	<filter-entity-reference-list [root]="root" [name]="name" [label]="label" [items]="entityTypes"
	 [showTitleAsPrefix]="showTitleAsPrefix"></filter-entity-reference-list>
	`
})
export class FilterRelatedEntityTypeComponent extends FilterBaseComponent implements OnInit {
	entityTypes: IRelatedEntityType[] = EntityTypes.filter(type => type.allowFilter);
}
