import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { Subscription } from 'rxjs';

import { BillSettingDto } from '@common/models/Settings/BillSettings/Item/BillSettingDto';
import { PaymentTermsDto } from '@common/models/Settings/BillSettings/Item/PaymentTermsDto';
import { NotificationService } from '@common/notification';
import { BillsService } from '@common/services/settings/bills.service';

@Component({
	selector: 'global-payment-terms',
	styleUrls: ['./global-payment-terms.scss'],
	templateUrl: './global-payment-terms.html'
})
export class PaymentTermsComponent implements OnInit, OnDestroy {
	@Input() billSettings: BillSettingDto;

	form: FormGroup;

	private subscriptions = new Subscription();

	constructor(
		private fb: FormBuilder,
		private billSettingsService: BillsService,
		private notificationService: NotificationService
	) {}

	ngOnInit() {
		this.form = this.fb.group({
			numberOfDays: this.billSettings?.paymentTermDays
		});
	}

	save(): void {
		const numberOfDays = this.form.controls.numberOfDays.value;
		const dto: PaymentTermsDto = { paymentTermDays: numberOfDays };
		this.subscriptions.add(
			this.billSettingsService.savePaymentTermDays(dto).subscribe(
				() => {
					this.notificationService.showNotification('Payment Terms saved successfully');
					this.form.markAsPristine();
				},
				error => this.notificationService.showError('Error saving Payment Terms', error)
			)
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}
}
