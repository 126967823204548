<h2 mat-dialog-title class="title">Convert Document to PDF</h2>
<mat-dialog-content [formGroup]="form" class="column">
	<div class="flex-row pt-12">
		<div class="column">
			<mat-checkbox formControlName="addDocumentToMatter">Save to {{ saveToText() }}</mat-checkbox>
			<mat-hint class="check-hint">Saves the converted document to the {{ saveToText() }}</mat-hint>
		</div>
		<div class="column">
			<mat-checkbox formControlName="downloadPdf">Download</mat-checkbox>
			<mat-hint class="check-hint">Download the converted document</mat-hint>
		</div>
	</div>

	<div class="security-options">
		<mat-checkbox #includePage formControlName="includeSecurityOptions">Security Options</mat-checkbox>
		<mat-hint class="check-hint">Protect PDF with a password or prevent modification</mat-hint>
	</div>

	<div class="flex-row sub-items" *ngIf="form.get('includeSecurityOptions').value">
		<div class="column">
			<mat-checkbox formControlName="passwordProtect">Protect using Password</mat-checkbox>
			<mat-hint class="check-hint">Protect the document with a password </mat-hint>
		</div>
		<mat-form-field *ngIf="form.get('passwordProtect').value">
			<input formControlName="userPassword" matInput autocomplete="off" />
			<mat-placeholder
				>Enter Password
				<sup class="color-warn">*</sup>
			</mat-placeholder>
			<mat-hint>The password required to view & open the document</mat-hint>
			<mat-error>
				<error-messages [for]="form.get('userPassword')"></error-messages>
			</mat-error>
		</mat-form-field>
	</div>

	<div class="flex-row sub-items pb-12" *ngIf="form.get('includeSecurityOptions').value">
		<div class="column">
			<mat-checkbox formControlName="preventModification">Prevent Modification</mat-checkbox>
			<mat-hint class="check-hint">Prevent editing of the document </mat-hint>
		</div>
		<mat-form-field *ngIf="form.get('preventModification').value">
			<input formControlName="ownerPassword" matInput autocomplete="off" />
			<mat-placeholder
				>Enter Password
				<sup class="color-warn">*</sup>
			</mat-placeholder>
			<mat-hint>The password required to edit the document</mat-hint>
			<mat-error>
				<error-messages [for]="form.get('ownerPassword')"></error-messages>
			</mat-error>
		</mat-form-field>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-raised-button color="primary" [mat-dialog-close]="form.value" [disabled]="form.invalid">Submit</button>
	<button mat-raised-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
