// This file has been generated from BasicWorkflowCreateUpdateDto.cs
// Do not modify this file directly!!
// tslint:disable

import { BasicWorkflowType } from '../Common/BasicWorkflowType';
import { MatterWorkflowCondition } from './MatterWorkflowCondition';

// LawMaster.Amalthea.Domain.Settings.BasicWorkflows.Item.BasicWorkflowCreateUpdateDto
export class BasicWorkflowCreateUpdateDto {

	// Id
	id: string;
	// Name
	name: string;
	// WorkflowType
	workflowType: keyof typeof BasicWorkflowType;
	// AssociatedPracticeAreaIds
	associatedPracticeAreaIds: string[];
	// StageName
	stageName: string;
	// MatterWorkflowConditions
	matterWorkflowConditions: MatterWorkflowCondition[];

	constructor() {
		this.matterWorkflowConditions = [];
	}
}