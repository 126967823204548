<filter-root #theFilter [filter]="config.request" [listRequestType]="listRequestType">
	<filter-date-range-button
		[root]="theFilter"
		label="Started"
		labelStart="From"
		labelEnd="To"
		nameStart="startedFrom"
		nameEnd="startedTo"
		[showPast]="true"
		[showToday]="true"
	></filter-date-range-button>
	<filter-job-status-button [root]="theFilter" name="status" label="Status"></filter-job-status-button>
	<filter-contact-button
		*ngIf="config.showUser"
		[root]="theFilter"
		name="userId"
		label="User"
		[ShowStaffMembersOnly]="true"
		[ShowUnassigned]="false"
		[showTitleAsPrefix]="false"
	></filter-contact-button>
	<filter-search-button [root]="theFilter" name="search"></filter-search-button>
</filter-root>
