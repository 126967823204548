// This file has been generated from DocumentTemplateUpdateDto.cs
// Do not modify this file directly!!
// tslint:disable

import { TemplateEntityType } from '../TemplateDto/TemplateEntityType';

// LawMaster.Amalthea.Domain.Documents.Item.DocumentTemplateUpdateDto
export class DocumentTemplateUpdateDto {

	// Title
	title: string;
	// AssociatedPracticeAreaIds
	associatedPracticeAreaIds: string[];
	// EntityType
	entityType: keyof typeof TemplateEntityType;
	// DocumentCategoryId
	documentCategoryId: string;
	// DocumentTags
	documentTags: string[];
	// DocumentHeaderFooterTemplateId
	documentHeaderFooterTemplateId: string;

	constructor() {

	}
}