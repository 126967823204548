<!-- View Mode -->
<ng-template [ngIf]="!editMode">
	<div class="flex-align formatted-address-view">
		{{ formatDisplay(value('formattedAddress')) }}
	</div>
	<div *ngIf="!!value('regionCode') && value('regionCode') != 'AU'" class="flex-align">
		{{ getCountryNameByCode(value('regionCode')) }}&nbsp;
		<img
			src="/assets/img/country/{{ value('regionCode')?.toLowerCase() }}.png"
			height="16"
			width="16"
			title="{{ getCountryNameByCode(value('regionCode')) }}"
			alt="{{ getCountryNameByCode(value('regionCode')) }}"
		/>
	</div>
	<a *ngIf="!!googleMapsLink" [href]="googleMapsLink" target="_blank">map</a>
</ng-template>

<!-- Edit Mode -->
<div *ngIf="editMode" class="flex-column">
	<mat-form-field>
		<mat-select placeholder="Country" [formControl]="childControl('regionCode')">
			<mat-option *ngFor="let regionCode of regions" [value]="regionCode.value" (onSelectionChange)="onRegionSelected($event)">
				{{ regionCode.label }}
			</mat-option>
		</mat-select>
		<mat-error>
			<error-messages [for]="childControl('regionCode')"></error-messages>
		</mat-error>
	</mat-form-field>

	<mat-form-field>
		<mat-placeholder>
			Address 1
			<sup class="color-warn" *ngIf="isAddressRequired">*</sup>
		</mat-placeholder>
		<input matInput [formControl]="childControl('line1')" autocomplete="off" />
		<mat-error>
			<error-messages [for]="childControl('line1')"></error-messages>
		</mat-error>
	</mat-form-field>
	<mat-form-field>
		<input matInput placeholder="Address 2" [formControl]="childControl('line2')" autocomplete="off" />
		<mat-error>
			<error-messages [for]="childControl('line2')"></error-messages>
		</mat-error>
	</mat-form-field>
	<mat-form-field *ngIf="showLocality">
		<mat-placeholder>
			{{ formatName(regionCode?.locality_name_type) }}
			<sup class="color-warn" *ngIf="isLocalityRequired">*</sup>
		</mat-placeholder>
		<ng-container *ngIf="localities?.length; then selectLocality; else inputLocality"></ng-container>
		<ng-template #inputLocality>
			<input matInput [formControl]="childControl('locality')" autocomplete="off" />
		</ng-template>
		<ng-template #selectLocality>
			<mat-select [formControl]="childControl('locality')">
				<mat-option *ngIf="optional" (onSelectionChange)="onLocalitySelected($event)"></mat-option>
				<mat-option *ngFor="let locality of localities" [value]="locality.value" (onSelectionChange)="onLocalitySelected($event)">
					{{ locality.label }}
				</mat-option>
			</mat-select>
		</ng-template>
		<mat-error>
			<error-messages [for]="childControl('locality')"></error-messages>
		</mat-error>
	</mat-form-field>
	<mat-form-field *ngIf="showSubLocality">
		<mat-placeholder>
			{{ formatName(regionCode?.sublocality_name_type) }}
			<sup class="color-warn" *ngIf="isSubLocalityRequired">*</sup>
		</mat-placeholder>
		<ng-container *ngIf="subLocalities?.length; then selectSubLocality; else inputSubLocality"></ng-container>
		<ng-template #inputSubLocality>
			<input matInput [formControl]="childControl('subLocality')" autocomplete="off" />
		</ng-template>
		<ng-template #selectSubLocality>
			<mat-select [formControl]="childControl('subLocality')">
				<mat-option *ngIf="optional" (onSelectionChange)="onSubLocalitySelected($event)"></mat-option>
				<mat-option *ngFor="let subLocality of subLocalities" [value]="subLocality.value" (onSelectionChange)="onSubLocalitySelected($event)">
					{{ subLocality.label }}
				</mat-option>
			</mat-select>
		</ng-template>
		<mat-error>
			<error-messages [for]="childControl('subLocality')"></error-messages>
		</mat-error>
	</mat-form-field>
	<div class="flex-row always-row">
		<mat-form-field *ngIf="showAdministrativeArea" class="administrative-area">
			<mat-placeholder>
				{{ formatName(regionCode?.state_name_type) }}
				<sup class="color-warn" *ngIf="isAdministrativeAreaRequired">*</sup>
			</mat-placeholder>
			<ng-container *ngIf="administrativeAreas?.length; then selectAdministrativeArea; else inputAdministrativeArea"></ng-container>
			<ng-template #inputAdministrativeArea>
				<input matInput [formControl]="childControl('administrativeArea')" autocomplete="off" />
			</ng-template>
			<ng-template #selectAdministrativeArea>
				<mat-select [formControl]="childControl('administrativeArea')">
					<mat-option *ngIf="optional" (onSelectionChange)="onAdministrativeAreaSelected($event)"></mat-option>
					<mat-option *ngFor="let area of administrativeAreas" [value]="area.value" (onSelectionChange)="onAdministrativeAreaSelected($event)">
						{{ area.label }}
					</mat-option>
				</mat-select>
			</ng-template>
			<mat-error>
				<error-messages [for]="childControl('administrativeArea')"></error-messages>
			</mat-error>
		</mat-form-field>
		<mat-form-field *ngIf="showZipCode" class="postcode">
			<mat-placeholder>
				{{ formatName(regionCode?.zip_name_type) }} Code
				<sup class="color-warn" *ngIf="isZipCodeRequired">*</sup>
			</mat-placeholder>
			<input matInput [formControl]="childControl('postalCode')" autocomplete="off" />
			<mat-hint>
				{{ postalCodeHint }}
			</mat-hint>
			<mat-error>
				<error-messages [for]="childControl('postalCode')"></error-messages>
			</mat-error>
		</mat-form-field>
	</div>
</div>
