import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { NotificationService } from '@common/notification';
import { VersionCheckService } from '@common/services/version.check.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	private noInternetMessageShown = false;

	constructor(private notifService: NotificationService, private versionCheckService: VersionCheckService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			tap((response: HttpEvent<any>) => {
				if (response instanceof HttpResponse) {
					if (response.headers.has('mattero-build')) {
						const version = response.headers.get('mattero-build');
						if (!!version) this.versionCheckService.checkVersion(version);
					}
				}
			}),
			catchError(err => {
				// If the internet connection is lost
				// * status code would be 0
				// * window.navigator.onLine would return false
				if (err instanceof HttpErrorResponse && err.status === 0 && !window.navigator.onLine) {
					if (!this.noInternetMessageShown) {
						this.noInternetMessageShown = true;
						setTimeout(() =>
							this.notifService
								.showConfirmation(
									'Internet Connection Lost',
									'Unable to connect to Internet. Please check your Internet connection.',
									'Reload',
									'Cancel'
								)
								.subscribe((isRefresh: boolean) => {
									if (isRefresh) {
										window.location.reload();
									} else {
										this.noInternetMessageShown = false;
									}
								})
						);
					}
					// Don't throw an error if the notification is already shown to the user
					return EMPTY;
				}
				return throwError(() => err);
			})
		);
	}
}
