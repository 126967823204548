import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription } from 'rxjs';

import 'moment-duration-format';

import { BillSettingDto } from '@common/models/Settings/BillSettings/Item/BillSettingDto';
import { Features } from '@common/models/Settings/Modules/Features';
import { BillsService } from '@common/services/settings/bills.service';

enum ActiveTab {
	NumberFormat = 0,
	XeroSync = 1,
	PaymentTerms = 2,
	Taxes = 3,
	Template = 4
}

@Component({
	selector: 'bill-settings',
	styleUrls: ['./bill-settings.component.scss'],
	templateUrl: './bill-settings.component.html'
})
export class BillSettingsComponent implements OnDestroy, OnInit, AfterViewInit {
	featureTypes: typeof Features = Features;

	selectedTabIndex: number = 0;
	subscriptions = new Subscription();
	billSettings: Observable<BillSettingDto>;
	isMobile: boolean;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private breakpoint: BreakpointObserver,
		private billSettingsService: BillsService
	) {}

	ngOnInit(): void {
		this.subscriptions.add(
			this.activatedRoute.queryParams.subscribe(params => {
				if (params['activeTab']) {
					switch (params['activeTab'].toLowerCase()) {
						case ActiveTab[ActiveTab.XeroSync].toLowerCase():
							this.selectedTabIndex = ActiveTab.XeroSync;
							break;
						case ActiveTab[ActiveTab.PaymentTerms].toLowerCase():
							this.selectedTabIndex = ActiveTab.PaymentTerms;
							break;
						case ActiveTab[ActiveTab.Taxes].toLowerCase():
							this.selectedTabIndex = ActiveTab.Taxes;
							break;
						case ActiveTab[ActiveTab.Template].toLowerCase():
							this.selectedTabIndex = ActiveTab.Template;
							break;
						default:
							this.selectedTabIndex = ActiveTab.NumberFormat;
					}
				} else {
					this.selectedTabIndex = ActiveTab.NumberFormat;
				}
			})
		);

		this.billSettings = this.billSettingsService.get();
	}

	ngAfterViewInit() {
		this.subscriptions.add(
			this.breakpoint.observe([Breakpoints.Handset]).subscribe(result => {
				this.isMobile = result.matches;
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	selectedTabChanged(tabEvent: MatTabChangeEvent): void {
		const activeTab = tabEvent.index as ActiveTab;
		// update 'activeTab' query parameter in the URL when changing tabs
		this.router.navigate([], {
			queryParams: {
				activeTab: ActiveTab[activeTab]?.toLowerCase()
			},
			queryParamsHandling: 'merge',
			relativeTo: this.activatedRoute
		});
	}
}
