// This file has been generated from SearchServiceSettingsDto.cs
// Do not modify this file directly!!
// tslint:disable

import { SetSimpleType } from '@common/utils/class-transform';



// LawMaster.Amalthea.Domain.Settings.Setting.Item.SearchServiceSettingsDto
export class SearchServiceSettingsDto {

	// GstFreeCostCodeId
	gstFreeCostCodeId: string;
	// GstApplicableCostCodeId
	gstApplicableCostCodeId: string;
	// IsSearchServicesEnabled
	@SetSimpleType(Boolean)
	isSearchServicesEnabled: boolean;

	constructor() {

	}
}