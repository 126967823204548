// This file has been generated from ReferralTaskCreateUpdateDto.cs
// Do not modify this file directly!!
// tslint:disable

import { Moment } from 'moment-timezone';
import { DateFormat, SetDateFormat } from '@common/utils/date-format';

import { SetComplexType } from '@common/utils/class-transform';

import { TaskPriority } from '../Common/TaskPriority';
import { TaskStatus } from '../Common/TaskStatus';
import { ReferralDocumentDto } from './ReferralDocumentDto';
import { ReferralRecipientDto } from './ReferralRecipientDto';

// LawMaster.Amalthea.Domain.Tasks.Item.ReferralTaskCreateUpdateDto
export class ReferralTaskCreateUpdateDto {

	// Description
	description: string;
	// Status
	status: keyof typeof TaskStatus;
	// Priority
	priority: keyof typeof TaskPriority;
	// DueDate
	@SetDateFormat(DateFormat.LocalDate)
	dueDate: Moment;
	// AssociatedContactId
	associatedContactId: string;
	// AssociatedMatterId
	associatedMatterId: string;
	// AssignedToId
	assignedToId: string;
	// WorkFlowId
	workFlowId: string;
	// Remarks
	remarks: string;
	// Recipients
	@SetComplexType(ReferralRecipientDto)
	recipients: ReferralRecipientDto[];
	// Documents
	@SetComplexType(ReferralDocumentDto)
	documents: ReferralDocumentDto[];

	constructor() {
		this.recipients = [];
		this.documents = [];
	}
}