import { CustomReportListItemDto } from '@common/models/CustomReports/List/CustomReportListItemDto';
import { CustomReportListRequest } from '@common/models/CustomReports/List/CustomReportListRequest';
import { ListResponse } from '@common/models/Generic/ListResponse';
import { DomainError } from '@common/models/Validation/DomainError';

import { AppConfig } from 'app/app.config';

export interface ICustomReportListState {
	isFetching: boolean;
	list: ListResponse<CustomReportListItemDto>;
	lastSelectedRecordId: string;
	request: Partial<CustomReportListRequest>;
	oldRequest: Partial<CustomReportListRequest>;

	// errrors
	error: DomainError;
}

export const initialState: ICustomReportListState = {
	isFetching: false,
	list: null,
	lastSelectedRecordId: null,
	request: {
		pageSize: AppConfig.PageSize,
		pageIndex: 0,
		pageIndexForId: null,

		sortBy: null,
		sortDirection: null
	},
	oldRequest: null,
	error: null
};
