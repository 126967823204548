import { TrustAccountListItemDto } from '@common/models/Settings/TrustSettings/TrustAccounts/List/TrustAccountListItemDto';
import { DomainError } from '@common/models/Validation/DomainError';
import { createAction, props } from '@ngrx/store';

export enum TrustAccountFilterActions {
	InvalidateCache = '[Trust Account Filter] Invalidate Cache',
	Load = '[Trust Account Filter] Load',
	Loading = '[Trust Account Filter] Loading',
	LoadSuccess = '[Trust Account Filter] Load Success',
	LoadFailed = '[Trust Account Filter] Load Failed'
}

export const load = createAction(TrustAccountFilterActions.Load);
export const loading = createAction(TrustAccountFilterActions.Loading);
export const loadSuccess = createAction(
	TrustAccountFilterActions.LoadSuccess,
	props<{ response: TrustAccountListItemDto[] }>()
);
export const loadFailed = createAction(TrustAccountFilterActions.LoadFailed, props<{ error: DomainError }>());

export const invalidateCache = createAction(TrustAccountFilterActions.InvalidateCache);
