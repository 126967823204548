import {
	AfterContentChecked,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Inject,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Subscription } from 'rxjs';

import { EntityReference } from '@common/models/Common/EntityReference';
import { BankAccountCreateUpdateDto } from '@common/models/Settings/TrustSettings/BankAccounts/Item/BankAccountCreateUpdateDto';
import { BankAccountViewDto } from '@common/models/Settings/TrustSettings/BankAccounts/Item/BankAccountViewDto';
import { BankDetails } from '@common/models/Settings/TrustSettings/BankDetails';
import { BankAccountsService } from '@common/services/settings/bankaccounts.service';

@Component({
	selector: 'bank-account-dialog',
	styleUrls: ['./bank-account-dialog.component.scss'],
	templateUrl: './bank-account-dialog.component.html'
})
export class BankAccountDialogComponent implements OnInit, OnDestroy, AfterContentChecked {
	@ViewChild('bankNameInput')
	bankNameInput: ElementRef;
	validateBSB: boolean;

	form: FormGroupTyped<BankAccountCreateUpdateDto>;
	bankDetailsForm: FormGroupTyped<BankDetails>;
	isCreateMode: boolean;
	countries: EntityReference[] = [
		{ id: 'AU', name: 'Australia' },
		{ id: 'NZ', name: 'New Zealand' }
	];

	private _bankDetails: BankDetails;

	private subscriptions: Subscription = new Subscription();

	constructor(
		@Inject(MAT_DIALOG_DATA) public id: string,
		private fb: FormBuilder,
		private trustAccountService: BankAccountsService,
		private cdref: ChangeDetectorRef
	) {}

	get bankDetails(): BankDetails {
		return this._bankDetails;
	}
	set bankDetails(value: BankDetails) {
		this._bankDetails = value;
	}

	ngOnInit() {
		this.form = this.fb.group({
			bankDetails: null,
			defaultPayeeId: null,
			id: this.id
		}) as FormGroupTyped<BankAccountCreateUpdateDto>;
		this.isCreateMode = !this.id;

		this.bankDetailsForm = this.form.controls.bankDetails as AbstractControl as FormGroupTyped<BankDetails>;

		if (this.id) {
			this.subscriptions.add(
				this.trustAccountService.getBankAccount(this.id).subscribe((record: BankAccountViewDto) => {
					this.bankDetails = record.bankDetails;
					this.form.patchValue(record);
				})
			);
		}
	}

	onContactSelected(contact: EntityReference): void {
		this.form.controls.defaultPayeeId.setValue(contact.id);
	}

	ngAfterContentChecked(): void {
		this.cdref.detectChanges();
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}
}
