import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { filter, switchMap, take, tap } from 'rxjs/operators';

import { TermsAndConditionsService } from '@common/services/settings/termsandconditions.service';
import { ICurrentUserData } from '@common/state/models/current-user-data';
import { Store } from '@ngrx/store';

import { CoreModule } from '../core.module';
import { TermsAndConditionsDialogComponent } from './terms-and-conditions-dialog.component';
import { of } from 'rxjs';

@Injectable({
	providedIn: CoreModule
})
export class TermsAndConditionsCheckService {
	constructor(
		private _store: Store<{ currentUserData: ICurrentUserData }>,
		private _termsAndConditionsService: TermsAndConditionsService,
		private _dialog: MatDialog
	) {}

	checkUserRequiresTerms$(): Observable<any> {
		return this._store
			.select(state => state?.currentUserData)
			.pipe(
				filter(data => !!data?.currentUser),
				take(1),
				switchMap(data => {
					return !!data.currentUser.requiresTermsAndConditionsAgreement
						? this._termsAndConditionsService.get()
						: of(undefined);
				}),
				tap(dto => {
					return dto
						? this._dialog.open(TermsAndConditionsDialogComponent, {
								data: { message: dto?.rawMessage }
						  })
						: of(undefined);
				})
			);
	}
}
