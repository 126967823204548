import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { UserTypeDto } from '@common/models/Settings/UserTypes/Item/UserTypeDto';

import { CustomValidators } from '@common/validation/custom.validators';

@Component({
	templateUrl: './user-type-item.component.html'
})
export class UserTypeItemComponent implements OnInit, AfterViewInit {
	@ViewChild('userTypeNameInput')
	userTypeInput: ElementRef;
	form: FormGroup;
	isCreateMode: boolean;

	constructor(@Inject(MAT_DIALOG_DATA) public data: UserTypeDto, private fb: FormBuilder) {}

	ngOnInit() {
		this.form = this.fb.group({
			description: this.data.description,
			name: [this.data.name, CustomValidators.required('Name')]
		});
		this.isCreateMode = !this.data.id;
	}
	ngAfterViewInit() {
		if (this.userTypeInput) {
			setTimeout(() => this.userTypeInput.nativeElement.focus(), 0);
		}
	}
}
