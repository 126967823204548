// This file has been generated from WorkflowDocumentTemplateDto.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.Domain.Settings.Setting.Item.WorkflowDocumentTemplateDto
export class WorkflowDocumentTemplateDto {

	// ReferenceFormat
	referenceFormat: string;

	constructor() {

	}
}