import { Pipe, PipeTransform } from '@angular/core';
import { TaskPriority } from '@common/models/Tasks/Common/TaskPriority';

/*
 * Resolve task status display string by the enum key
 */
@Pipe({ name: 'taskPriority' })
export class TaskPriorityPipe implements PipeTransform {
	transform(key: keyof typeof TaskPriority): string {
		if (!TaskPriority[key]) {
			throw Error(`Dev error: The Task Priority enum does not contain the key ${key}`);
		}
		return TaskPriority[key];
	}
}
