import { Injectable } from '@angular/core';

import { interval, of } from 'rxjs';
import { catchError, debounce, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { ListResponse } from '@common/models/Generic/ListResponse';
import { InfoTrackOrderListItemDto } from '@common/models/InfoTrack/Orders/List/InfoTrackOrderListItemDto';
import { InfoTrackService } from '@common/services/infotrack.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash-es';

import { IAppState } from '../../app.state';
import { InfoTrackListActions, loading } from './infotrack-list.actions';

@Injectable()
export class InfoTrackListEffects {
	loadInfoTracks$ = createEffect(() =>
		this._actions$.pipe(
			ofType(
				InfoTrackListActions.Load,
				InfoTrackListActions.SetPageIndex,
				InfoTrackListActions.SetPageSize,
				InfoTrackListActions.SetSortBy,
				InfoTrackListActions.SetSortDirection,
				InfoTrackListActions.SetFilters
			),
			withLatestFrom(this._store),
			filter(([_, state]) => !isEqual(state.infotrackList.request, state.infotrackList.oldRequest)),
			tap(() => this._store.dispatch(loading())),
			debounce(() => interval(200)),
			switchMap(([_, state]) => {
				return this._service.getInfoTrackList(state.infotrackList.request).pipe(
					map((response: ListResponse<InfoTrackOrderListItemDto>) => ({
						type: InfoTrackListActions.LoadSuccess,
						response
					})),
					catchError(error => of({ type: InfoTrackListActions.LoadFailed, error }))
				);
			})
		)
	);

	constructor(private _actions$: Actions, private _store: Store<IAppState>, private _service: InfoTrackService) {}
}
