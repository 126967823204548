import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';

import { Observable, of as ObservableOf, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, map, switchMap } from 'rxjs/operators';

import { BaseLookupComponent } from '@common/components/lookups/base-lookup.component';
import { BaseDtoWithTrimmedId } from '@common/models/Common/BaseDtoWithTrimmedId';
import { DocumentTemplateListItemDto } from '@common/models/Documents/List/DocumentTemplateListItemDto';
import { TemplateEntityType } from '@common/models/Documents/TemplateDto/TemplateEntityType';
import { DocumentTemplatesService } from '@common/services/settings/documenttemplates.service';
import { arraysEqual } from '@common/utils/arrayUtils';
import { isEmptyOrWhitespace } from '@common/utils/stringUtils';
import { Store } from '@ngrx/store';
import { Dictionary } from 'lodash';
import { flatten, get, groupBy, orderBy } from 'lodash-es';

import { getCurrentPageLookup } from 'app/core/state/misc/current-page/current-page.reducer';
import { ICurrentPageState } from 'app/core/state/misc/current-page/current-page.state';

@Component({
	selector: 'document-template-lookup',
	styleUrls: ['./document-template-lookup.component.scss'],
	templateUrl: './document-template-lookup.component.html'
})
export class DocumentTemplateLookupComponent extends BaseLookupComponent<IDocumentTemplateLookupDto> {
	@ViewChild('autoCompleteInput', { static: true })
	inputCtrl: ElementRef;
	@Input()
	FormControl: FormControl;
	@Input()
	HasAutofocus: boolean;
	@Input()
	Placeholder: string;
	@Input()
	Hint: string;
	@Input()
	Required: boolean;
	@Output()
	IsNoMatch: EventEmitter<void> = new EventEmitter<void>();
	@Input()
	disabled: boolean;
	@Input()
	autocompletePanelWidth: string;
	@Input()
	autoSelectIfOnlyOne: boolean;
	@ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;

	private _templates: Dictionary<DocumentTemplateListItemDto[]>;
	filteredTemplates: Dictionary<IDocumentTemplateLookupDto[]>;

	private _isRefreshing = true;

	private _refreshDocumentTemplatesSubject$ = new Subject<boolean>();

	private _onRefresh: EventEmitter<Dictionary<IDocumentTemplateLookupDto[]>> = new EventEmitter<
		Dictionary<IDocumentTemplateLookupDto[]>
	>();

	get templates() {
		return this._templates;
	}

	private _ignoreDocumentIds: string[];

	@Input()
	set ignoreDocumentIds(value: string[]) {
		if (!arraysEqual(this._ignoreDocumentIds, value)) {
			this._ignoreDocumentIds = value;
			this.refreshTemplates();
		}
	}

	private _entityType: keyof typeof TemplateEntityType;

	@Input()
	set entityType(value: keyof typeof TemplateEntityType) {
		if (this._entityType !== value) {
			this._entityType = value;
			this.refreshTemplates();
		}
	}

	private _practiceAreaIds: string[];

	@Input()
	set practiceAreaIds(value: string[]) {
		if (!arraysEqual(this._practiceAreaIds, value)) {
			this._practiceAreaIds = value;
			this.refreshTemplates();
		}
	}

	constructor(private service: DocumentTemplatesService, private store: Store<ICurrentPageState>) {
		super();
	}

	ngOnInit() {
		super.ngOnInit();

		this.subscription.add(
			this._refreshDocumentTemplatesSubject$
				.pipe(
					debounceTime(100),
					switchMap(force =>
						this.store.select(getCurrentPageLookup).pipe(map(lookup => ({ lookup, force })))
					),
					map(data => {
						const practiceAreaId = get(data.lookup, ['practiceArea', 'id']);

						var request = {
							entityType: this._entityType,
							practiceAreaIds: !!this._practiceAreaIds?.length
								? this._practiceAreaIds
								: practiceAreaId
								? [practiceAreaId]
								: [],
							ignoreDocumentIds: this._ignoreDocumentIds ?? []
						};

						return { request, force: data.force };
					}),
					distinctUntilChanged((old, current) => {
						if (!!current?.force) {
							return false;
						}

						if (!old?.request && !current?.request) {
							return true;
						}

						if (!!old?.request && !!current?.request) {
							return arraysEqual(Object.values(old.request), Object.values(current.request));
						}

						return false;
					}),
					switchMap(data => this.service.getDocumentTemplateList(data.request))
				)
				.subscribe(list => {
					this._templates = groupBy(orderBy(list.records, ['documentCategory.name', 'title']), x =>
						x.documentCategory ? x.documentCategory.name : 'Uncategorised'
					);

					this.filteredTemplates = this.searchTemplates(this.inputDisplayCtrl?.value);

					if (!!this._templates) {
						const options = flatten(Object.values(this._templates));
						const selectedOption = !!this.FormControl.value
							? options.find(x => x.id === this.FormControl.value)
							: null;
						if (!!selectedOption) {
							this.setSelectedValue(selectedOption);
						} else if (this.autoSelectIfOnlyOne && options.length === 1) {
							this.setValue(options[0]);
						}
					}

					this._isRefreshing = false;
					this._onRefresh.emit(this._templates);
				})
		);
		this.refreshTemplates();
	}

	inputClicked() {
		this.filteredTemplates = this._templates;
		this.trigger.openPanel();
	}

	refreshTemplates(force?: boolean) {
		this._isRefreshing = true;

		this._refreshDocumentTemplatesSubject$.next(!!force);
	}

	clearInput() {
		this.FormControl.setValue(null);
		this.setSelectedValue(null);

		this.FormControl.markAsTouched();
		this.FormControl.markAsDirty();
		this.FormControl.updateValueAndValidity();
	}

	inputChanged(event: any) {
		const inputText = event.target.value;

		this.options = flatten(Object.values(this._templates));
		if (!this.options.find(x => x.title === inputText)) {
			this.IsNoMatch.emit();
		}
	}

	autocompleteSelected(event: MatAutocompleteSelectedEvent): void {
		this.options = flatten(Object.values(this._templates));
		super.autocompleteSelected(event);

		this.filteredTemplates = this.searchTemplates(this.inputDisplayCtrl.value);
	}

	lookup(id: string): Observable<IDocumentTemplateLookupDto> {
		if (!!this._templates) {
			const result = flatten(Object.values(this._templates)).filter(value => value.id === id);

			this.filteredTemplates = this.searchTemplates(id, true);

			return !!result?.length ? of(result[0]) : ObservableOf();
		}

		return ObservableOf();
	}

	optionHtmlText(input: IDocumentTemplateLookupDto): string {
		return input?.title ?? null;
	}

	displayText(input: IDocumentTemplateLookupDto) {
		return input?.title ?? null;
	}

	searchIfTermEmpty(): boolean {
		return true;
	}

	getAvailableTemplates(): Observable<Dictionary<IDocumentTemplateLookupDto[]>> {
		if (!!this._isRefreshing) {
			return this._onRefresh.pipe(first());
		} else {
			return of(this._templates);
		}
	}

	hasAvailableTemplates(): Observable<boolean> {
		return this.getAvailableTemplates().pipe(
			map(templates => {
				if (!templates) {
					return false;
				}

				return !!flatten(Object.values(this._templates))?.length;
			})
		);
	}

	setFocus() {
		if (this.inputCtrl != null) {
			setTimeout(() => {
				this.inputCtrl.nativeElement.focus();
			}, 0);
		}
	}

	private searchTemplates(termOrId: string, isId = false): Dictionary<IDocumentTemplateLookupDto[]> {
		let filtered: Dictionary<IDocumentTemplateLookupDto[]> = {};

		if (!!this._templates) {
			if (!!isEmptyOrWhitespace(termOrId)) {
				filtered = this.cloneDictionary(this._templates);
			} else if (!isId) {
				const lowercaseTerm = termOrId.toLowerCase();

				filtered = this.filterDictionary(
					this._templates,
					(key: string, values: IDocumentTemplateLookupDto[]) => {
						if (key.toLowerCase().includes(lowercaseTerm)) {
							return { success: true, value: values };
						} else {
							const filteredValues = values.filter(value =>
								value.title.toLowerCase().includes(lowercaseTerm)
							);
							return { success: !!filteredValues?.length, value: filteredValues };
						}
					}
				);
			} else {
				filtered = this.filterDictionary(this._templates, (_, values: IDocumentTemplateLookupDto[]) => {
					const filteredValues = values.filter(value => value.id === termOrId);

					return { success: !!filteredValues?.length, value: filteredValues };
				});
			}
		}

		return Object.keys(filtered).length > 0 ? filtered : null;
	}

	search(term: string): Observable<IDocumentTemplateLookupDto[]> {
		this.filteredTemplates = this.searchTemplates(term);

		return of(!!this._templates ? flatten(Object.values(this._templates)) : []);
	}

	private filterDictionary<T>(
		dictionary: Dictionary<T>,
		filterDelegate: (key: string, value: T) => { value: T; success: boolean }
	): Dictionary<T> {
		let clone: any = {};

		Object.keys(dictionary).forEach(key => {
			const result = filterDelegate(key, dictionary[key]);
			if (!!result.success) clone[key] = result.value;
		});

		return clone;
	}

	private cloneDictionary<T>(dictionary: Dictionary<T>): Dictionary<T> {
		let clone: any = {};

		Object.keys(dictionary).forEach(key => {
			clone[key] = dictionary[key];
		});

		return clone;
	}
}

export interface IDocumentTemplateLookupDto extends BaseDtoWithTrimmedId {
	title: string;
}
