// This file has been generated from SecurityPolicies.cs
// Do not modify this file directly!!

// LawMaster.Amalthea.Domain.Settings.SecurityRoles.Common.SecurityPolicies
export enum SecurityPolicies {
	None = 'None',
	Trust = 'Manage Trust',
	DeleteMatters = 'Delete Matters and Contacts',
	SystemSettings = 'Edit System Settings',
	Reports = 'View Reports',
	GlobalListSettings = 'Global Lists Settings',
	MatterSecurity = 'Manage Matter Security'
}
