// This file has been generated from BankAccountCreateUpdateDto.cs
// Do not modify this file directly!!
// tslint:disable

import { SetComplexType } from '@common/utils/class-transform';

import { BankDetails } from '../../BankDetails';

// LawMaster.Amalthea.Domain.Settings.TrustSettings.BankAccounts.Item.BankAccountCreateUpdateDto
export class BankAccountCreateUpdateDto {

	// Id
	id: string;
	// BankDetails
	@SetComplexType(BankDetails)
	bankDetails: BankDetails;
	// DefaultPayeeId
	defaultPayeeId: string;

	constructor() {
		this.bankDetails = new BankDetails();
	}
}