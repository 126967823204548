<h2 mat-dialog-title class="title">
	<span>{{ !isEdit ? 'Create' : 'Update' }} Referral Task</span>
</h2>
<mat-dialog-content [formGroup]="form">
	<div class="flex-column">
		<div class="flex-row">
			<mat-form-field>
				<mat-placeholder
					>Remarks / Instruction
					<sup class="color-warn">*</sup>
				</mat-placeholder>
				<textarea formControlName="remarks" matInput cdkTextareaAutosize [cdkAutosizeMinRows]="2" autocomplete="off"></textarea>
				<mat-error>
					<error-messages [for]="form.get('remarks')"></error-messages>
				</mat-error>
			</mat-form-field>
		</div>

		<div class="flex-row">
			<mat-form-field class="small-form-field">
				<mat-placeholder
					>Due Date
					<sup class="color-warn">*</sup>
				</mat-placeholder>
				<input formControlName="dueDate" matInput [matDatepicker]="dueDatePicker" autocomplete="off" />
				<mat-datepicker-toggle matSuffix [for]="dueDatePicker"></mat-datepicker-toggle>
				<mat-datepicker #dueDatePicker></mat-datepicker>
				<mat-error>
					<error-messages [for]="form.get('dueDate')"></error-messages>
				</mat-error>
			</mat-form-field>

			<mat-form-field class="small-form-field">
				<mat-placeholder
					>Priority
					<sup class="color-warn">*</sup>
				</mat-placeholder>
				<mat-select formControlName="priority">
					<mat-option *ngFor="let option of priorities" [value]="option">{{ option | taskPriority }}</mat-option>
				</mat-select>
				<mat-error>
					<error-messages [for]="form.get('priority')"></error-messages>
				</mat-error>
			</mat-form-field>
		</div>

		<div class="flex-row">
			<div class="list-header">
				<h3 class="title-text">Assignees</h3>
				<button class="add-button" mat-button color="primary" throttleButton (throttledClick)="addRecipient()">
					<mat-icon>add_circle</mat-icon>
					<span>&nbsp;Add <span class="allocation-word">assignee</span></span>
				</button>
			</div>
		</div>

		<div class="flex-row">
			<div class="flex-column">
				<div class="list-item--mobile" *ngFor="let recipient of recipientControls; let i = index" formArrayName="recipients">
					<div class="flex-row" [formGroup]="recipient">
						<contact-lookup
							#recipientControl
							Placeholder="Assignee"
							[FormControl]="getRecipientFormControl(i, 'contactId')"
							(Selected)="onContactSelected(recipient, $event)"
							[Required]="true"
							[StaffOnly]="true"
						></contact-lookup>

						<mat-form-field class="small-form-field">
							<mat-placeholder
								>Reason
								<sup class="color-warn">*</sup>
							</mat-placeholder>
							<mat-select formControlName="reason">
								<mat-option *ngFor="let option of reasons" [value]="option">{{ option }}</mat-option>
							</mat-select>
							<mat-error>
								<error-messages [for]="recipient.get('reason')"></error-messages>
							</mat-error>
						</mat-form-field>

						<mat-form-field class="small-form-field">
							<input formControlName="dueDate" placeholder="Due Date" matInput [matDatepicker]="recipientDueDatePicker" autocomplete="off" />
							<mat-datepicker-toggle matSuffix [for]="recipientDueDatePicker"></mat-datepicker-toggle>
							<mat-datepicker #recipientDueDatePicker></mat-datepicker>
							<mat-error>
								<error-messages [for]="recipient.get('dueDate')"></error-messages>
							</mat-error>
						</mat-form-field>

						<button mat-icon-button title="Remove" class="delete-button delete-button--full" throttleButton (throttledClick)="removeRecipient(i)">
							<mat-icon>delete</mat-icon>
						</button>
					</div>

					<div class="flex-row flex-row--mobile">
						<button
							mat-raised-button
							title="Remove"
							class="delete-button delete-button--mobile"
							throttleButton
							(throttledClick)="removeRecipient(i)"
						>
							<mat-icon>delete</mat-icon>
							<span>Remove</span>
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="flex-row">
			<div class="list-header">
				<h3 class="title-text">Documents</h3>
				<button class="add-button" mat-button color="primary" throttleButton (throttledClick)="addDocument()">
					<mat-icon>add_circle</mat-icon>
					<span>&nbsp;New <span class="allocation-word">document</span></span>
				</button>
			</div>
		</div>

		<div class="flex-row">
			<div class="flex-column">
				<div class="list-item--mobile" *ngFor="let document of documentControls; let i = index" formArrayName="documents">
					<div class="flex-row" [formGroup]="document">
						<document-lookup
							#documentControl
							[Required]="true"
							Placeholder="Associated Document"
							[FormControl]="geDocumentFormControl(i, 'documentId')"
							[forceAssociatedMatterOrContact]="true"
							[associatedMatterId]="form.get('associatedMatterId').value"
							[associatedContactId]="form.get('associatedContactId').value"
							(Selected)="onDocumentSelected(document, $event)"
							class="field-wide"
						></document-lookup>

						<button mat-icon-button title="Remove" class="delete-button delete-button--full" throttleButton (throttledClick)="removeDocument(i)">
							<mat-icon>delete</mat-icon>
						</button>
					</div>

					<div class="flex-row flex-row--mobile">
						<button
							mat-raised-button
							title="Remove"
							class="delete-button delete-button--mobile"
							throttleButton
							(throttledClick)="removeDocument(i)"
						>
							<mat-icon>delete</mat-icon>
							<span>Remove</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions class="dialog-actions">
	<button mat-raised-button color="primary" throttleButton (throttledClick)="save()" [disabled]="form.invalid || saving">Save</button>
	<button *ngIf="!!showSaveAndCreate" mat-stroked-button color="primary" [disabled]="form.invalid || saving" throttleButton (throttledClick)="save(true)">
		Save & Create Time
	</button>
	<button mat-raised-button mat-dialog-close>Close</button>
</mat-dialog-actions>
