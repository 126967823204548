<ng-container>
	<div class="flex-column">
		<div>
			<button class="new-button" mat-button color="primary" title="Create Bank Account" throttleButton (throttledClick)="createBankAccount()">
				<mat-icon>add_circle</mat-icon>
				<span>&nbsp;Create Bank Account</span>
			</button>
		</div>
		<div>
			<mat-table [dataSource]="(isFetching$ | async) ? [] : (data$ | async)" matSort matSortActive="accountName" matSortDirection="asc">
				<ng-container matColumnDef="accountName">
					<mat-header-cell *matHeaderCellDef mat-sort-header>General Account Name</mat-header-cell>
					<mat-cell *matCellDef="let row">
						<div class="field-name">
							<a class="cursor-hand" throttleButton (throttledClick)="editBankAccount(row)">{{ row.bankDetails.accountName }}</a>
							&nbsp;<mat-chip class="default-chip" *ngIf="row.isDefault">Default</mat-chip>
						</div>
					</mat-cell>
				</ng-container>
				<ng-container matColumnDef="bankName">
					<mat-header-cell *matHeaderCellDef mat-sort-header>Bank Name</mat-header-cell>
					<mat-cell *matCellDef="let row">
						<div class="field-balance">{{ row.bankDetails.bankName }}</div>
					</mat-cell>
				</ng-container>

				<ng-container matColumnDef="countryId">
					<mat-header-cell *matHeaderCellDef mat-sort-header>BSB</mat-header-cell>
					<mat-cell *matCellDef="let row">
						<div>{{ row.bankDetails.countryId }}</div>
					</mat-cell>
				</ng-container>

				<ng-container matColumnDef="accountNumber">
					<mat-header-cell *matHeaderCellDef mat-sort-header>Account Number</mat-header-cell>
					<mat-cell *matCellDef="let row">
						<div>{{ row.bankDetails.bsb }} {{ row.bankDetails.bsb ? ' / ' : '' }} {{ row.bankDetails.accountNumber }}</div>
					</mat-cell>
				</ng-container>

				<ng-container matColumnDef="actions">
					<mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
					<mat-cell *matCellDef="let row">
						<button mat-icon-button [matMenuTriggerFor]="menu" title="Actions">
							<mat-icon>more_vert</mat-icon>
						</button>
						<mat-menu #menu="matMenu">
							<button mat-menu-item throttleButton (throttledClick)="editBankAccount(row)">
								<mat-icon>edit</mat-icon>
								<span>Edit Details</span>
							</button>
							<button mat-menu-item throttleButton (throttledClick)="deleteBankAccount(row)">
								<mat-icon>delete</mat-icon>
								<span>Delete</span>
							</button>
							<button *ngIf="!row.isDefault" mat-menu-item throttleButton (throttledClick)="setDefault(row)">
								<mat-icon>check_box</mat-icon>
								<span>Set Default</span>
							</button>
						</mat-menu>
					</mat-cell>
				</ng-container>

				<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
				<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
			</mat-table>
			<p>&nbsp;</p>
		</div>
	</div>
</ng-container>
