import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'export-dialog',
	styles: [
		`
			mat-dialog-actions.mat-dialog-actions {
				margin-top: 5px;
				margin-bottom: 5px;
				place-content: center;
			}
			mat-dialog-content.mat-dialog-content {
				margin: auto;
				padding: 16px;
			}
			mat-dialog-content.mat-dialog-content .message {
				margin-top: 16px;
			}
		`
	],
	template: `
		<h2 mat-dialog-title>Export {{ data.title }}</h2>
		<mat-dialog-content>
			<div>
				<strong>{{ data.recordsCount }}</strong> item{{ data.recordsCount > 1 ? 's' : '' }} will be exported to
				a CSV file.
			</div>
			<div class="message" *ngIf="!!data.message">
				<span>{{ data.message }}</span>
			</div>
		</mat-dialog-content>
		<mat-dialog-actions>
			<button mat-raised-button color="primary" [mat-dialog-close]="true">
				{{ data?.exportButtonText ?? 'Export' }}</button
			>&nbsp;
			<button mat-raised-button [mat-dialog-close]="false">Cancel</button>
		</mat-dialog-actions>
	`
})
export class ExportDialogComponent {
	constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}
