<div [formGroup]="form" class="flex-column">
	<div class="readonly-message color-accent" *ngIf="!this.isEditable">
		<mat-icon color="accent">info</mat-icon>
		<span> {{ getReadOnlyReason() }}</span>
	</div>
	<div class="flex-row" *ngIf="!hideRelatedTo">
		<matter-lookup
			#matterLookup
			class="related-to-entity"
			Placeholder="Related To Matter"
			[Required]="true"
			[Status]="'Open'"
			[FormControl]="form.get('associatedMatterId')"
			[Hint]="matterHintText"
			(Selected)="onMatterSelected($event)"
		></matter-lookup>
	</div>
	<div class="flex-row">
		<mat-form-field>
			<mat-placeholder
				>Cost Code
				<sup class="color-warn" *ngIf="!disableValidation">*</sup>
			</mat-placeholder>
			<mat-select #costCodeDropdown formControlName="costCodeId" (selectionChange)="costCodeChanged($event)">
				<mat-option *ngFor="let option of costCodes" [value]="option.id">{{ option.name }}</mat-option>
			</mat-select>
			<mat-hint>{{ costCodeRateHintText }}</mat-hint>
			<mat-error> <error-messages [for]="form.get('costCodeId')"></error-messages> </mat-error>
		</mat-form-field>

		<mat-form-field>
			<mat-placeholder
				>Mode
				<sup class="color-warn" *ngIf="!disableValidation">*</sup>
			</mat-placeholder>
			<mat-select #costModeDropdown formControlName="pricingMethod" disabled="{{ this.form.disabled }}">
				<mat-option value="Time">Time Based</mat-option>
				<mat-option value="Fixed">Fixed</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
	<div class="flex-row flex-row-with-gap">
		<duration-field
			#durationInput
			[unitsPerHour]="unitsPerHour"
			(durationChanged)="onDurationChanged($event)"
			[FormControl]="form.get('durationMinutes')"
			[class.hide]="form.get('pricingMethod')?.value === 'Fixed'"
		></duration-field>
		<editable-numeric-value
			#quantity
			placeHolder="Quantity"
			[control]="form.get('quantity')"
			[isCurrency]="false"
			[editMode]="true"
			[class.hide]="form.get('pricingMethod')?.value === 'Time'"
		></editable-numeric-value>
		<div class="amount-container">
			<div class="ripple-element" [class.highlight-amount]="highlightField"></div>
			<editable-numeric-value
				#amount
				placeHolder="Amount excl. GST"
				[tooltip]="isCostCodeNonChargeable ? 'Amount is not applicable to non-chargeable activities' : ''"
				[control]="form.get('amountExclTax')"
				[isCurrency]="true"
				[hintText]="amountHintText"
				[editMode]="true"
			>
				<button mat-button *ngIf="displayRefreshButton" matSuffix mat-icon-button throttleButton (throttledClick)="autoCalculateAmount()">
					<mat-icon class="refresh-icon" matTooltip="Recalculate Amount">refresh</mat-icon>
				</button></editable-numeric-value
			>
		</div>
	</div>
	<div class="flex-row">
		<mat-form-field>
			<mat-placeholder> Description <sup class="color-warn" *ngIf="!disableValidation">*</sup> </mat-placeholder>
			<textarea formControlName="description" matInput cdkTextareaAutosize [cdkAutosizeMinRows]="2"></textarea>
			<mat-icon matSuffix throttleButton (throttledClick)="onCopyClicked()" title="Copy from Note Title" *ngIf="showCopyTo">content_copy</mat-icon>
			<mat-error> <error-messages [for]="form.get('description')"></error-messages> </mat-error>
			<button *ngIf="showCopyTimerDescription()" matSuffix mat-icon-button throttleButton (throttledClick)="useTimerDescription()">
				<mat-icon class="refresh-icon" matTooltip="Copy from Timer Description">content_copy</mat-icon>
			</button>
		</mat-form-field>
	</div>
	<div class="flex-row">
		<mat-form-field>
			<mat-placeholder>Date <sup class="color-warn" *ngIf="!disableValidation">*</sup> </mat-placeholder>
			<input formControlName="date" matInput [matDatepicker]="datePicker" />
			<mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
			<mat-datepicker #datePicker></mat-datepicker>
			<mat-error> <error-messages [for]="form.get('date')"></error-messages> </mat-error>
		</mat-form-field>
		<contact-lookup
			Placeholder="User"
			[Required]="!disableValidation"
			[Hint]="chargeRateHintText"
			[StaffOnly]="true"
			[FormControl]="form.get('staffContactId')"
			(Selected)="onContactSelected($event)"
		></contact-lookup>
	</div>
	<div class="flex-row" *ngIf="!!timerId">
		<mat-checkbox #deleteTimer [checked]="!!timerConfig?.autoDeleteTimer" (change)="onDeleteTimerChanged($event)">Delete Timer</mat-checkbox>
	</div>
</div>
