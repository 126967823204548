// This file has been generated from AccessTokenValidationStatus.cs
// Do not modify this file directly!!

// LawMaster.Amalthea.Domain.Settings.AccessTokens.Common.AccessTokenValidationStatus
export enum AccessTokenValidationStatus {
	Success = 'Success',
	Invalid = 'Invalid',
	LockedOut = 'LockedOut',
	RecaptchaFail = 'RecaptchaFail'
}
