// This file has been generated from ExpenseRecordsController.cs
// Do not modify this file directly!!
// tslint:disable

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { TrimStringsOnObject } from '@common/utils/string-format';

import { MutationResponseDto } from '../models/Common/MutationResponseDto';
import { ExpenseRecordCreateDto } from '../models/CostRecords/Item/ExpenseRecordCreateDto';
import { ExpenseRecordUpdateDto } from '../models/CostRecords/Item/ExpenseRecordUpdateDto';
import { ExpenseRecordUpdloadAttachmentsDto } from '../models/CostRecords/Item/ExpenseRecordUpdloadAttachmentsDto';
import { ExpenseRecordViewDto } from '../models/CostRecords/Item/ExpenseRecordViewDto';
import { IUploadMultipleFiles } from '@common/models/Common/IFileUploader';
import { IResultWithAttachments } from '@common/models/Common/IResultWithAttachments';
@Injectable({
	providedIn: 'root'
})
export class ExpenseRecordsService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'api/v1/ExpenseRecords');
	}

	create(dto: ExpenseRecordCreateDto, filesToUpload: IUploadMultipleFiles): Observable<Partial<IResultWithAttachments>> {
		return this.createUpdateWithHttpProgress<ExpenseRecordCreateDto>('', TransformDatesOnObject(ExpenseRecordCreateDto, TrimStringsOnObject(dto)), filesToUpload, 'POST');
	}

	update(id: string, dto: ExpenseRecordUpdateDto, filesToUpload: IUploadMultipleFiles): Observable<Partial<IResultWithAttachments>> {
		return this.createUpdateWithHttpProgress<ExpenseRecordUpdateDto>(`${id}`, TransformDatesOnObject(ExpenseRecordUpdateDto, TrimStringsOnObject(dto)), filesToUpload, 'PUT');
	}

	uploadAttachments(id: string, dto: ExpenseRecordUpdloadAttachmentsDto, filesToUpload: IUploadMultipleFiles): Observable<Partial<IResultWithAttachments>> {
		return this.createUpdateWithHttpProgress<ExpenseRecordUpdloadAttachmentsDto>(`${id}/UploadAttachments`, TransformDatesOnObject(ExpenseRecordUpdloadAttachmentsDto, TrimStringsOnObject(dto)), filesToUpload, 'PUT');
	}

	deleteExpense(id: string): Observable<MutationResponseDto> {
		return this.delete(`${id}/DeleteExpense`);
	}

	getExpenseRecord(id: string): Observable<ExpenseRecordViewDto> {
		return this.getItem<void, ExpenseRecordViewDto>(`${id}`);
	}

}
