import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { LoginOptionRulesUpdateDto } from '@common/models/Settings/Setting/Item/LoginOptionRulesUpdateDto';
import { NotificationService } from '@common/notification';
import { GeneralSettingsService } from '@common/services/settings/generalsettings.service';
import { CustomValidators } from '@common/validation/custom.validators';
import { Subscription } from 'rxjs';

@Component({
	selector: 'login-options-rules',
	styleUrls: ['./login-options-rules.component.scss'],
	templateUrl: './login-options-rules.component.html'
})
export class LoginOptionsRulesComponent implements OnInit, OnDestroy {
	private _subscriptions = new Subscription();
	private _form: FormGroupTyped<LoginOptionRulesUpdateDto>;

	get form() {
		return this._form;
	}

	get allowedDomainControls() {
		return this.allowedDomainFormArray.controls as FormControl[];
	}

	get allowedDomainFormArray() {
		return (this._form as FormGroup).get('allowedDomains') as FormArray;
	}

	constructor(
		private _formBuilder: FormBuilder,
		private _generalSettingsService: GeneralSettingsService,
		private _notificationService: NotificationService
	) {}

	ngOnInit(): void {
		this._form = this._formBuilder.group({
			allowEmailCredentials: false,
			allowGoogleCredentials: false,
			allowMicrosoftCredentials: false,
			allowedDomains: this._formBuilder.array([])
		}) as FormGroupTyped<LoginOptionRulesUpdateDto>;

		this._subscriptions.add(
			this._generalSettingsService.getLoginOptionRules().subscribe(dto => {
				this._form.get('allowEmailCredentials').setValue(dto.allowEmailCredentials);
				this._form.get('allowGoogleCredentials').setValue(dto.allowGoogleCredentials);
				this._form.get('allowMicrosoftCredentials').setValue(dto.allowMicrosoftCredentials);

				if (!!dto.allowedDomains?.length) {
					for (let index = 0; index < dto.allowedDomains.length; index++) {
						this.addDomainControl(dto.allowedDomains[index]);
					}
				}
			})
		);
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}

	isToggleChecked(key: string) {
		return !!this._form.get(key)?.value;
	}

	isToggleDisabled(key: string) {
		const control = this._form.get(key);

		if (!control.value) {
			return false;
		}

		const enabledToggles: number =
			(!!this.form.get('allowEmailCredentials').value ? 1 : 0) +
			(!!this.form.get('allowGoogleCredentials').value ? 1 : 0) +
			(!!this.form.get('allowMicrosoftCredentials').value ? 1 : 0);

		return enabledToggles <= 1;
	}

	onToggleChanged(key: string, event: MatSlideToggleChange) {
		const control = this._form.get(key);
		control.setValue(!!event.checked);

		control.markAsTouched();
		control.markAsDirty();
		control.updateValueAndValidity();

		this._form.markAsTouched();
		this._form.markAsDirty();
		this._form.updateValueAndValidity();
	}

	onSaveClicked() {
		let value = this._form.value;
		value.allowedDomains =
			(this._form.get('allowedDomains') as any as FormArrayTyped<string>)?.controls?.map(
				control => control.value
			) ?? [];

		this._subscriptions.add(
			this._generalSettingsService.updateLoginOptionRules(value).subscribe(
				() => {
					this._form.markAsPristine();
					this._notificationService.showNotification('Successfully updated login options');
				},
				errors => this._notificationService.showErrors('Failed to update login options', errors)
			)
		);
	}

	onAddDomainClicked() {
		this.addDomainControl(null);
	}

	onDeleteDomainClicked(index: number) {
		this.allowedDomainFormArray.removeAt(index);

		this._form.markAsTouched();
		this._form.markAsDirty();
		this._form.updateValueAndValidity();
	}

	private addDomainControl(value: string) {
		const control = this._formBuilder.control(value, [
			CustomValidators.required('Domain'),
			CustomValidators.validateDomain()
		]);

		this.allowedDomainControls.push(control);

		this._subscriptions.add(
			control.valueChanges.subscribe(() => {
				this.allowedDomainFormArray.updateValueAndValidity();

				this._form.markAsTouched();
				this._form.markAsDirty();
				this._form.updateValueAndValidity();
			})
		);

		this._form.markAsTouched();
		this._form.markAsDirty();
		this._form.updateValueAndValidity();
	}
}
