import { Component } from '@angular/core';

import { BaseDocumentTagComponent } from '@common/components/base-document-tag.component';
import { NotificationService } from '@common/notification';
import { SystemTagsCachedService } from '@common/services/system-tags-cache.service';

@Component({
	selector: 'document-tag',
	styleUrls: ['../../../../../common/components/base-document-tag.component.scss'],
	templateUrl: '../../../../../common/components/base-document-tag.component.html'
})
export class DocumentTagComponent extends BaseDocumentTagComponent {
	constructor(
		private _systemTagsCachedService: SystemTagsCachedService,
		private _notificationService: NotificationService
	) {
		super(_systemTagsCachedService, _notificationService);
	}
}
