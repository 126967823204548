// This file has been generated from BatchMoveCostRecordsDto.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.Domain.CostRecords.Item.BatchMoveCostRecordsDto
export class BatchMoveCostRecordsDto {

	// MatterId
	matterId: string;
	// CostRecordIds
	costRecordIds: string[];

	constructor() {

	}
}