<mat-card>
	<section class="page-path"><a [routerLink]="['..']">System</a> / Document Tags</section>
	<h1>Document Tags</h1>

	<div class="flex-filter-row">
		<button mat-button color="primary" title="Create Document Tag" throttleButton (throttledClick)="createDocumentTag()">
			<mat-icon>add_circle</mat-icon>
			<span> Create New Document Tag</span>
		</button>
		<filter-mobile-span [filterRoot]="filter.root" [filterParams]="request" [filterComponent]="filterDialog"></filter-mobile-span>
	</div>
	<document-tag-list-filter [request]="request"></document-tag-list-filter>

	<mat-table [dataSource]="(isFetching$ | async) ? [] : (data$ | async)" matSort matSortActive="name" matSortDirection="asc">
		<ng-container matColumnDef="name">
			<mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<a class="name" throttleButton (throttledClick)="editDocumentTag(row)">{{ row.name }}</a>

				<div class="embedded-practice-areas">
					<span *ngIf="!row.practiceAreas?.length">All Practice Areas</span>
					<ul>
						<li *ngFor="let pa of row.practiceAreas">{{ pa.name }}</li>
					</ul>
				</div>
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="practiceAreas">
			<mat-header-cell *matHeaderCellDef>Practice Areas</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<span *ngIf="!row.practiceAreas?.length">All</span>
				<ul>
					<li *ngFor="let pa of row.practiceAreas">{{ pa.name }}</li>
				</ul>
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="actions">
			<mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<button mat-icon-button [matMenuTriggerFor]="menu" title="Actions">
					<mat-icon>more_vert</mat-icon>
				</button>
				<mat-menu #menu="matMenu">
					<button mat-menu-item throttleButton (throttledClick)="editDocumentTag(row)">
						<mat-icon>edit</mat-icon>
						<span>Edit Details</span>
					</button>
					<button mat-menu-item throttleButton (throttledClick)="deleteDocumentTag(row)">
						<mat-icon>delete</mat-icon>
						<span>Delete</span>
					</button>
				</mat-menu>
			</mat-cell>
		</ng-container>

		<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
		<mat-row
			*matRowDef="let row; columns: displayedColumns"
			throttleButton
			(throttledClick)="selectRow(row, $event)"
			[ngClass]="{ highlight: row.isHighlighted }"
		></mat-row>
	</mat-table>
	<mat-paginator
		[length]="totalRecords$ | async"
		[pageIndex]="pageIndex$ | async"
		[pageSize]="pageSize$ | async"
		[pageSizeOptions]="pageSizeOptions"
		[class.hidden]="(isFetching$ | async) || !(data$ | async)"
	></mat-paginator>
</mat-card>
