import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppcuesService } from '@common/appcues/appcues.service';
import { CompanyContactCreateUpdateDto } from '@common/models/Contacts/Item/CompanyContactCreateUpdateDto';
import { PersonContactCreateUpdateDto } from '@common/models/Contacts/Item/PersonContactCreateUpdateDto';
import { TenantCompanyDto } from '@common/models/Settings/Setting/Item/TenantCompanyDto';
import { NotificationService } from '@common/notification/notification.service';
import { GeneralSettingsService } from '@common/services/settings/generalsettings.service';
import { ContactLookupDialogComponent } from 'app/shared/components/contact-lookup-dialog/contact-lookup-dialog.component';
import { IAssignContact } from 'app/shared/components/contact-lookup-dialog/IAssignContact';
import { Subscription } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

const countries = require('app/shared/editable-controls/countries.json');

@Component({
	selector: 'company-details',
	styleUrls: ['./company-details.component.scss'],
	templateUrl: 'company-details.component.html'
})
export class CompanyDetailsComponent implements OnInit, OnDestroy {
	countries = countries;
	datasource: TenantCompanyDto[] = [];
	matterId: string;
	displayedColumns: string[] = ['role', 'fullName', 'phone', 'email', 'address', 'practiceType', 'actions'];

	private subscriptions = new Subscription();

	constructor(
		private service: GeneralSettingsService,
		private dialog: MatDialog,
		private notifService: NotificationService,
		private appcuesService: AppcuesService
	) {}

	ngOnInit(): void {
		this.subscriptions.add(
			this.service.getTenantContact().subscribe(result => {
				this.datasource = [result];
			})
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	editRelatedContact(row: TenantCompanyDto): void {
		const data: IAssignContact = {
			allowCreate: true,
			allowCreatePlural: true,
			hint: 'company',
			mandatory: true,
			placeHolder: 'Company',
			selectedContactId: row.role.contactId,
			staffOnly: false,
			title: 'Company'
		};
		this.subscriptions.add(
			this.dialog
				.open(ContactLookupDialogComponent, { data })
				.afterClosed()
				.pipe(
					filter(Boolean),
					switchMap((form: FormGroup) => {
						const createPerson: { [key: string]: PersonContactCreateUpdateDto } = form.get('createPersons')
							? form.get('createPersons').value
							: null;
						const createCompany: { [key: string]: CompanyContactCreateUpdateDto } = form.get(
							'createCompanies'
						)
							? form.get('createCompanies').value
							: null;

						return this.service.saveTenantContact({
							contactId: createCompany || createPerson ? null : form.get('contactId').value,
							createCompany: createCompany ? createCompany[form.get('contactId').value] : null,
							createPerson: createPerson ? createPerson[form.get('contactId').value] : null,
							practiceType: null,
							organisationStatus: null
						});
					}),
					tap(() => {
						this.notifService.showNotification(`Company details updated`);
						this.appcuesService.trackEvent('CompanyDetailsSaved');
					}),
					switchMap(() => this.service.getTenantContact())
				)
				.subscribe(result => {
					this.datasource = [result];
				})
		);
	}
}
