// This file has been generated from SaveTaxConfigurationDto.cs
// Do not modify this file directly!!
// tslint:disable

import { TaxConfiguration } from '../Common/TaxConfiguration';

// LawMaster.Amalthea.Domain.Settings.BillSettings.Item.SaveTaxConfigurationDto
export class SaveTaxConfigurationDto {

	// TaxConfiguration
	taxConfiguration: keyof typeof TaxConfiguration;

	constructor() {

	}
}