<mat-form-field [style.font-size]="FontSize">
	<mat-placeholder
		>{{ Placeholder }}
		<sup class="color-warn" *ngIf="Required">*</sup>
	</mat-placeholder>
	<mat-hint>{{ Hint }}</mat-hint>
	<input
		matInput
		#autoCompleteInput
		[matAutocomplete]="auto"
		[formControl]="inputDisplayCtrl"
		[value]="inputDisplayCtrl.value"
		[autofocus]="HasAutofocus"
		(keyup.esc)="escPressed()"
		(keydown.tab)="tabPressed()"
		(focus)="inputFocused($event)"
		autocomplete="off"
	/>
	<mat-error>
		<error-messages [for]="FormControl"></error-messages>
	</mat-error>

	<mat-autocomplete class="lookup-results" #auto="matAutocomplete" (optionSelected)="autocompleteSelected($event)">
		<mat-option *ngFor="let option of options" [value]="option.id">
			<div class="contact-option">
				<div class="contact-name">{{ option.contactName }}</div>
				<div *ngIf="isActivated(option) && option.email" class="contact-info">{{ option.email }}</div>
				<div *ngIf="!isActivated(option)" class="contact-info contact-inactive">Requires Activation</div>
			</div>
		</mat-option>
		<mat-option [disabled]="true" *ngIf="inputDisplayCtrl.value && options?.length == 0">
			<div *ngIf="isSearching" class="searching">Searching...</div>
			<div *ngIf="noLookupResults && inputDisplayCtrl.value && options?.length == 0" class="no-matches">No matches found</div>
		</mat-option>
	</mat-autocomplete>
</mat-form-field>
