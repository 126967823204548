// This file has been generated from CostTemplatesController.cs
// Do not modify this file directly!!
// tslint:disable

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { TrimStringsOnObject } from '@common/utils/string-format';

import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';
import { ListRequest } from '../../models/Generic/ListRequest';
import { ListResponse } from '../../models/Generic/ListResponse';
import { CostTemplateDto } from '../../models/Settings/CostTemplates/Item/CostTemplateDto';
import { CostTemplateViewDto } from '../../models/Settings/CostTemplates/Item/CostTemplateViewDto';
import { CostTemplateListItemDto } from '../../models/Settings/CostTemplates/List/CostTemplateListItemDto';

@Injectable({
	providedIn: 'root'
})
export class CostTemplatesService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'api/v1/settings/CostTemplates', 'Cost Template');
	}

	getCostTemplate(id: string): Observable<CostTemplateViewDto> {
		return this.getItem<void, CostTemplateViewDto>(`${id}`);
	}

	getCostTemplateList(request?: Partial<ListRequest>): Observable<ListResponse<CostTemplateListItemDto>> {
		return this.getList<ListRequest, CostTemplateListItemDto>('', request);
	}

	createCostTemplate(newCostTemplateDto: CostTemplateDto): Observable<MutationResponseDto> {
		return this.post<CostTemplateDto, MutationResponseDto>(
			'',
			TransformDatesOnObject(CostTemplateDto, TrimStringsOnObject(newCostTemplateDto))
		);
	}

	updateCostTemplate(id: string, costTemplateDto: CostTemplateDto): Observable<MutationResponseDto> {
		return this.put(`${id}`, TransformDatesOnObject(CostTemplateDto, TrimStringsOnObject(costTemplateDto)));
	}

	deleteCostTemplate(id: string): Observable<MutationResponseDto> {
		return this.delete(`${id}`);
	}
}
