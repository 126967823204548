import { Component } from '@angular/core';

import * as moment from 'moment-timezone';

import { CustomValidators } from '../../../../../common/validation/custom.validators';
import { BaseEditableTextComponent } from './base-editable-text.component';

@Component({
	selector: 'editable-calculated',
	styleUrls: ['./base.editable.component.scss'],
	template: `
		<span *ngIf="!editMode" [class]="!!value() ? '' : 'view-noitem'">
			<ng-container *ngIf="!!isDate(value())">{{
				!!value() ? (toLocalDate(value()) | moment : 'dd/MM/yyyy') : blankMessage
			}}</ng-container>
			<ng-container *ngIf="!isDate(value())">
				<span>
					<ng-container *ngIf="!!value()">
						<ng-container *ngIf="!displayBlocks?.length">
							<span>{{ value() }}</span>
						</ng-container>
						<ng-container *ngIf="!!displayBlocks?.length">
							<ng-container *ngFor="let block of displayBlocks">
								<span *ngIf="!block.link">{{ block.text }}</span>
								<a *ngIf="!!block.link" [matTooltip]="block.link" [href]="block.link">{{
									block.text
								}}</a>
							</ng-container>
						</ng-container>
					</ng-container>
					<ng-container *ngIf="!value()">
						{{ blankMessage }}
					</ng-container>
				</span>
			</ng-container>
		</span>
	`
})
export class EditableCalculatedComponent extends BaseEditableTextComponent {
	isDate(date: string) {
		return !!date && !!CustomValidators.containsDateRegex.test(date) && !!moment(date).isValid();
	}

	toLocalDate(date: string) {
		return moment(date).format();
	}
}
