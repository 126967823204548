import { SortDirection } from '@angular/material/sort';

import { EnumSortDirection } from '@common/models/Common/EnumSortDirection';
import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';
import { DomainError } from '@common/models/Validation/DomainError';
import { arraysEqual } from '@common/utils/arrayUtils';
import { camelCase, get, set } from 'lodash-es';

import { SelectionType } from './SelectionType';

export class CommonReducers {
	static init(state: any, initialState: any) {
		return {
			...state,
			lastSelectedRecordId: null,
			list: null,
			request: initialState.request,
			oldRequest: initialState.oldRequest,
			error: null
		};
	}
	static loading(state: any) {
		return { ...state, isFetching: true, list: null, lastSelectedRecordId: null };
	}
	static loadSuccess(state: any, response: any) {
		return { ...state, isFetching: false, error: null, list: response, oldRequest: state.request };
	}
	static loadFailed(state: any, error: DomainError) {
		return { ...state, isFetching: false, error };
	}

	static setFilters(state: any, filter: any) {
		if (!filter || arraysEqual(Object.values(filter), Object.values(state.request))) return state;
		const request = { ...filter, pageIndex: 0 };
		return { ...state, request, lastSelectedRecordId: null };
	}
	static setFilterProp(state: any, prop: string, value: any) {
		if (!state) return state;
		const old: any = get(state, prop);
		if (old === value) return state;
		const filter = { ...state.request };
		set(filter, prop, value);
		const request = { ...filter, pageIndex: 0 };
		return { ...state, request, lastSelectedRecordId: null };
	}

	static setPageSize(state: any, pageSize: number) {
		if (pageSize === state.request?.pageSize) return state;
		let request = { ...state.request, pageSize, pageIndex: 0 };
		return { ...state, request, lastSelectedRecordId: null };
	}
	static setPageIndex(state: any, pageIndex: number) {
		if (pageIndex === state.request?.pageIndex) return state;
		let request = { ...state.request, pageIndex };
		return { ...state, request, lastSelectedRecordId: null };
	}
	static setPageIndexForId(state: any, id: string) {
		if (id === state.request?.pageIndexForId) return state;
		let request = { ...state.request, setPageIndexForId: id, pageIndex: 0 };
		return { ...state, request, lastSelectedRecordId: null };
	}

	static setSortBy(state: any, sortBy: string): any {
		if (sortBy === state.request?.sortBy) return state;
		let request = { ...state.request, sortBy, pageIndex: 0 };
		return { ...state, request, lastSelectedRecordId: null };
	}
	static setSortDirection(state: any, sortDirection: SortDirection): any {
		const direction = sortDirection?.toLowerCase()?.startsWith('desc')
			? EnumSortDirection.Desc
			: EnumSortDirection.Asc;
		if (direction === state.request?.sortDirection) return state;

		let request = {
			...state.request,
			pageIndex: 0,
			sortDirection: direction
		};
		return { ...state, request, lastSelectedRecordId: null };
	}

	static insertRecords(state: any, entities: any[]) {
		if (!entities?.length || !state.list) return state;
		const records = [...state.list.records];

		if (records.length >= state.request.pageSize) {
			// remove bottom x number of records
			records.splice(records.length - entities.length, entities.length);
		}

		const list = { ...state.list };
		list.records = entities.concat(records);
		return { ...state, list, lastSelectedRecordId: null };
	}

	static updateRecords(state: any, entities: any[]) {
		if (!entities?.length || !state.list?.records?.length) return state;
		const records = [...state.list.records];
		for (let i = 0; i < records.length; i++) {
			const match = entities.find(x => x.id === records[i].id);
			if (!!match) {
				records[i] = { ...records[i] };
				for (let [key, value] of Object.entries(match)) {
					records[i] = set(records[i], key, value);
				}
			}
		}
		const list = { ...state.list, records };
		return { ...state, list };
	}

	static selectRecords(state: any, row: any, selectionType: SelectionType) {
		if (!state.list?.records) return state;

		const records = [...state.list.records];
		let lastSelectedRecordId = state.lastSelectedRecordId;

		if (selectionType === SelectionType.clear || selectionType === SelectionType.all) {
			for (let i = 0; i < records.length; i++) {
				records[i] = CommonReducers.toggleSelection(records[i], selectionType === SelectionType.all);
			}
			lastSelectedRecordId = null;
		} else if (!!row) {
			const recordIndex = records.findIndex(x => x.id === row.id);
			const lastSelectedRecordIndex = records.findIndex(x => x.id === lastSelectedRecordId);
			if (recordIndex < 0) return state;

			if (
				selectionType === SelectionType.select ||
				selectionType === SelectionType.toggle ||
				!state.lastSelectedRecordId ||
				lastSelectedRecordIndex < 0
			) {
				const newState = selectionType === SelectionType.toggle ? !records[recordIndex].isHighlighted : true;
				if (newState === records[recordIndex].isHighlighted) return state;
				records[recordIndex] = CommonReducers.toggleSelection(records[recordIndex], newState);
				if (selectionType === SelectionType.select) CommonReducers.clearSelections(records, [recordIndex]);
				lastSelectedRecordId = row.id;
			} else if (selectionType === SelectionType.range) {
				const lastSelectedRecordIndex = records.findIndex(x => x.id === lastSelectedRecordId);
				for (let i = 0; i < records.length; i++) {
					const newState =
						i >= Math.min(lastSelectedRecordIndex, recordIndex) &&
						i <= Math.max(lastSelectedRecordIndex, recordIndex);
					records[i] = CommonReducers.toggleSelection(records[i], newState);
				}
			}
		} else {
			return state;
		}

		const list = { ...state.list, records };
		return { ...state, list, lastSelectedRecordId };
	}

	private static toggleSelection(record: any, isHighlighted: boolean): any {
		return { ...record, isHighlighted };
	}
	private static clearSelections(records: any[], exceptionList: number[]): void {
		for (let i = 0; i < records.length; i++) {
			records[i] = !!exceptionList?.includes(i)
				? { ...records[i] }
				: CommonReducers.toggleSelection(records[i], false);
		}
	}

	static processMutations(state: any, mutations: MutationResponseDto | MutationResponseDto[]): any {
		if (!mutations) return state;
		mutations = mutations instanceof Array ? mutations : [mutations];
		if (!mutations?.length) return state;

		// process child mutations first (to ensure that primary create mutations end up on top)
		for (let i = 0; i < mutations.length; i++) {
			if (!!mutations[i].childMutations?.length) {
				for (let j = 0; j < mutations[i].childMutations.length; j++) {
					state = processMutation(state, mutations[i].childMutations[j]);
				}
			}
		}

		// then process primary mutations
		for (let i = 0; i < mutations.length; i++) {
			state = processSingleMutation(state, mutations[i]);
		}
		return state;
	}
}

function processMutation(state: any, mutation: MutationResponseDto): any {
	if (!mutation || !(mutation.id || mutation.values)) return state;

	// process child mutations first (to ensure that primary create mutations end up on top)
	for (let index = 0; mutation.childMutations?.length && index < mutation.childMutations.length; index++) {
		state = processMutation(state, mutation.childMutations[index]);
	}

	// then process primary mutations
	return processSingleMutation(state, mutation);
}

function processSingleMutation(state: any, mutation: MutationResponseDto): any {
	if (!mutation || !(mutation.id || mutation.values)) return state;

	switch (mutation.mutationType) {
		case 'Create':
			return processInsertMutations(state, mutation);

		case 'Update':
			return processUpdateMutations(state, mutation);

		case 'Delete':
			return processDeleteMutations(state, mutation);

		default:
			return { ...state };
	}
}

function processInsertMutations(state: any, mutation: MutationResponseDto): any {
	if (mutation?.mutationType !== 'Create' || !state.list || !mutation.values) return state;
	const records = [...state.list.records];

	if (!!state.request.pageSize) {
		if (records.length >= state.request.pageSize) {
			// if page is full, remove bottom record
			records.splice(records.length - 1, 1);
		}
	}

	const row = {};
	for (const key of Object.keys(mutation.values)) {
		set(row, camelCase(key), mutation.values[key]);
	}
	const list = {
		...state.list,
		records: [row].concat(records)
	};
	return { ...state, list, lastSelectedRecordId: null };
}

function processUpdateMutations(state: any, mutation: MutationResponseDto) {
	if (mutation?.mutationType !== 'Update' || !state.list?.records?.length || !mutation.id || !mutation.values)
		return state;
	const records = [...state.list.records];
	let found = false;
	for (let i = 0; i < records.length; i++) {
		if (records[i].id === mutation.id) {
			found = true;
			records[i] = { ...records[i] };
			for (let key of Object.keys(mutation.values)) {
				records[i] = set(records[i], camelCase(key), mutation.values[key]);
			}
		}
	}
	const list = { ...state.list, records };
	return !found ? state : { ...state, list };
}

function processDeleteMutations(state: any, mutation: MutationResponseDto) {
	if (mutation?.mutationType !== 'Delete' || !state.list?.records?.length || !mutation.id) return state;
	let records = [...state.list.records];
	const index = records.findIndex(x => x.id === mutation.id);
	if (index < 0) return state;
	records.splice(index, 1);
	const list = { ...state.list, records, totalRecords: state.list.totalRecords - 1 };
	return { ...state, list };
}
