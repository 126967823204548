import { SortDirection } from '@angular/material/sort';
import { BillListItemDto } from '@common/models/Bills/List/BillListItemDto';
import { BillListRequest } from '@common/models/Bills/List/BillListRequest';
import { BillListResponse } from '@common/models/Bills/List/BillListResponse';

import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';
import { DomainError } from '@common/models/Validation/DomainError';
import { createAction, props } from '@ngrx/store';

import { SelectionType } from '../../SelectionType';

export enum BillListActions {
	Init = '[Bill List] Initialise',
	Load = '[Bill List] Load',
	Loading = '[Bill List] Loading',
	LoadSuccess = '[Bill List] Load Success',
	LoadFailed = '[Bill List] Load Failed',

	SetFilters = '[Bill List] Set Filters',
	SetPageSize = '[Bill List] Set Page Size',
	SetPageIndex = '[Bill List] Set Page Index',
	SetPageIndexForId = '[Bill List] Set Page Index For Id',

	SetSortBy = '[Bill List] Set Sort By',
	SetSortDirection = '[Bill List] Set Sort Direction',

	InsertRecords = '[Bill List] Insert Records',
	UpdateRecords = '[Bill List] Update Records',
	RemoveRecords = '[Bill List] Remove Records',
	SelectRecords = '[Bill List] Select Records'
}

export const init = createAction(BillListActions.Init);
export const load = createAction(BillListActions.Load);
export const loading = createAction(BillListActions.Loading);
export const loadSuccess = createAction(
	BillListActions.LoadSuccess,
	props<{ response: BillListResponse<BillListItemDto> }>()
);
export const loadFailed = createAction(BillListActions.LoadFailed, props<{ error: DomainError }>());

export const setFilters = createAction(BillListActions.SetFilters, props<{ filter: Partial<BillListRequest> }>());
export const setPageSize = createAction(BillListActions.SetPageSize, props<{ pageSize: number }>());
export const setPageIndex = createAction(BillListActions.SetPageIndex, props<{ pageIndex: number }>());
export const setPageIndexForId = createAction(BillListActions.SetPageIndexForId, props<{ id: string }>());

export const setSortBy = createAction(BillListActions.SetSortBy, props<{ sortBy: string }>());
export const setSortDirection = createAction(
	BillListActions.SetSortDirection,
	props<{ sortDirection: SortDirection }>()
);

export const insertRecords = createAction(
	BillListActions.InsertRecords,
	props<{ response: MutationResponseDto | MutationResponseDto[] }>()
);
export const updateRecords = createAction(
	BillListActions.UpdateRecords,
	props<{ response: MutationResponseDto | MutationResponseDto[] }>()
);
export const removeRecords = createAction(
	BillListActions.RemoveRecords,
	props<{ response: MutationResponseDto | MutationResponseDto[] }>()
);
export const selectRecords = createAction(
	BillListActions.SelectRecords,
	props<{ row: BillListItemDto; selectionType: SelectionType }>()
);
