// This file has been generated from BillsController.cs
// Do not modify this file directly!!
// tslint:disable

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { TrimStringsOnObject } from '@common/utils/string-format';

import { BillNumberFormatDto } from '../../models/Settings/BillSettings/Item/BillNumberFormatDto';
import { BillSettingDto } from '../../models/Settings/BillSettings/Item/BillSettingDto';
import { PaymentTermsDto } from '../../models/Settings/BillSettings/Item/PaymentTermsDto';
import { SaveBillTemplateDto } from '../../models/Settings/BillSettings/Item/SaveBillTemplateDto';
import { SaveTaxConfigurationDto } from '../../models/Settings/BillSettings/Item/SaveTaxConfigurationDto';

@Injectable({
	providedIn: 'root'
})
export class BillsService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'api/v1/settings/Bills');
	}

	get(): Observable<BillSettingDto> {
		return this.getItem<void, BillSettingDto>('');
	}

	saveBillNumberFormat(billNumberFormat: BillNumberFormatDto): Observable<any> {
		return this.post<BillNumberFormatDto, any>(`BillNumberFormat`, TransformDatesOnObject(BillNumberFormatDto, TrimStringsOnObject(billNumberFormat)));
	}

	savePaymentTermDays(paymentTerms: PaymentTermsDto): Observable<any> {
		return this.post<PaymentTermsDto, any>(`SavePaymentTermDays`, TransformDatesOnObject(PaymentTermsDto, TrimStringsOnObject(paymentTerms)));
	}

	saveTaxConfiguration(taxConfiguration: SaveTaxConfigurationDto): Observable<any> {
		return this.post<SaveTaxConfigurationDto, any>(`SaveTaxConfiguration`, TransformDatesOnObject(SaveTaxConfigurationDto, TrimStringsOnObject(taxConfiguration)));
	}

	saveTemplateId(billTemplate: SaveBillTemplateDto): Observable<any> {
		return this.post<SaveBillTemplateDto, any>(`SaveTemplateId`, TransformDatesOnObject(SaveBillTemplateDto, TrimStringsOnObject(billTemplate)));
	}

	resetOrganisation(): Observable<any> {
		return this.post<void, any>(`ResetOrganisation`);
	}

	getLastNumericPart(): Observable<number> {
		return this.getItem<void, number>(`LastNumericPart`);
	}

	updateNextNumber(nextInvoiceNoNumericPart: number): Observable<any> {
		return this.put(`NextNumber/${nextInvoiceNoNumericPart}`);
	}

}
