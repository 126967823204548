import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Subscription } from 'rxjs';

import { ILookupReference } from '@common/components/lookups/base-lookup.component';
import { BlankDocumentType } from '@common/models/Documents/Common/BlankDocumentType';
import { DocumentAssociationType } from '@common/models/Documents/Item/DocumentAssociationType';
import { DocumentCreateBlankDto } from '@common/models/Documents/Item/DocumentCreateBlankDto';
import { getFileIcon } from '@common/utils/file-extensions';
import { CustomValidators } from '@common/validation/custom.validators';
import { Store } from '@ngrx/store';

import { IAppState } from 'app/core/state/app.state';
import { processRecords } from 'app/core/state/lists/document-list/document-list.actions';
import { DocumentsService } from 'app/services/documents.service';

@Component({
	selector: 'create-blank-dialog',
	styleUrls: ['./create-blank-dialog.component.scss'],
	templateUrl: './create-blank-dialog.component.html'
})
export class CreateBlankDialogComponent implements OnInit, OnDestroy {
	form: FormGroup;

	documentTypes: IDocumentType[];

	associatedEntityTypes: DocumentAssociationType[] = [
		DocumentAssociationType.Matter,
		DocumentAssociationType.Contact
	];
	associatedEntityType: DocumentAssociationType = this.associatedEntityTypes[0];

	private _subscriptions = new Subscription();

	get showEntitySelection(): boolean {
		return !this._data?.entityId;
	}

	get selectedDocumentType(): IDocumentType {
		const results = this.documentTypes.filter(type => type.type === this.form.value.type);

		return results.length > 0 ? results[0] : null;
	}

	private _data: ICreateBlankDialogData;

	get associatedMatterIdControl(): FormControl {
		return this.form.get('associatedMatterId') as FormControl;
	}

	get associatedContactIdControl(): FormControl {
		return this.form.get('associatedContactId') as FormControl;
	}

	constructor(
		@Optional() @Inject(MAT_DIALOG_DATA) data: ICreateBlankDialogData,
		private documentsService: DocumentsService,
		private fb: FormBuilder,
		private dialogRef: MatDialogRef<CreateBlankDialogComponent>,
		private store: Store<IAppState>
	) {
		this._data = data;

		this.documentTypes = [
			{ display: 'Word', type: 'WordDocument', icon: getFileIcon('.docx') },
			{ display: 'Excel', type: 'ExcelSpreadsheet', icon: getFileIcon('.xlsx') },
			{ display: 'PowerPoint', type: 'PowerPointPresentation', icon: getFileIcon('.pptx') }
		];
	}

	ngOnInit(): void {
		if (!!this._data?.entityId) {
			if (this._data?.entityType === 'Contact') {
				this.associatedEntityType = DocumentAssociationType.Contact;
			} else if (this._data?.entityType === 'Matter') {
				this.associatedEntityType = DocumentAssociationType.Matter;
			}
		}

		this.form = this.fb.group({
			associatedContactId: [
				this._data?.entityType === 'Contact' && !!this._data?.entityId ? this._data.entityId : null,
				CustomValidators.requiredWhen(
					() => this.associatedEntityType === DocumentAssociationType.Contact,
					DocumentAssociationType.Contact
				)
			],
			associatedMatterId: [
				this._data?.entityType === 'Matter' && !!this._data?.entityId ? this._data.entityId : null,
				CustomValidators.requiredWhen(
					() => this.associatedEntityType === DocumentAssociationType.Matter,
					DocumentAssociationType.Matter
				)
			],
			documentName: [null, [CustomValidators.required('Document Title'), CustomValidators.validateFileName()]],
			type: 'WordDocument'
		});
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}

	createButtonClick() {
		const value = this.form.value;

		const dto: DocumentCreateBlankDto = {
			title: value.documentName,
			type: value.type,
			associatedContactId: value.associatedContactId,
			associatedMatterId: value.associatedMatterId,
			tags: null
		};

		this._subscriptions.add(
			this.documentsService.createBlank(dto).subscribe(response => {
				this.store.dispatch(processRecords({ response: response }));
				this.dialogRef.close(true);
			})
		);
	}

	compareTypes(left: IDocumentType, right: IDocumentType) {
		return left.type === right.type;
	}

	associatedEntityTypeChange() {
		// clear out the associated contact and matter whenever the associated entity type changes
		this.form.patchValue({
			associatedContactId: null,
			associatedMatterId: null
		});
	}

	onContactSelected(event: ILookupReference) {
		this.form.controls.associatedContactId.setValue(event.id);
	}
}

export interface ICreateBlankDialogData {
	entityId: string;
	entityType: 'Matter' | 'Contact';
}

export interface IDocumentType {
	display: string;
	type: keyof typeof BlankDocumentType;
	icon: string;
}
