import { get } from 'lodash-es';

export interface IFileExtension {
	extensions: string[];
	icon: string;
	label: string;
	showInFilter?: boolean;
}

// A dictionary of supported file extensions for different application types
// The dictionary key is a file icon from https://materialdesignicons.com/
export const FileExtensions: IFileExtension[] = [
	{
		extensions: ['.png', '.bmp', '.jpg', '.jpeg', '.gif', '.svg', '.emf', '.wmf', '.eps', '.webp'],
		icon: 'file-image',
		label: 'Images',
		showInFilter: true
	},
	{
		extensions: ['.wav', '.mp3'],
		icon: 'file-music',
		label: 'Audio Files'
	},
	{
		extensions: ['.avi', '.mp4', '.mpg', '.mpeg', '.mpg4', '.wmv'],
		icon: 'file-video',
		label: 'Video Files'
	},
	{
		extensions: ['.txt'],
		icon: 'file-document',
		label: 'Notes',
		showInFilter: true
	},
	{
		extensions: ['.pdf'],
		icon: 'file-pdf',
		label: 'PDF Documents',
		showInFilter: true
	},
	{
		extensions: ['.xml'],
		icon: 'file-xml',
		label: 'XML Documents'
	},
	{
		// File extensions were taken from https://technet.microsoft.com/en-us/library/dd797428.aspx
		extensions: ['.doc', '.docm', '.docx', '.dot', '.dotm', '.dotx', '.xps'],
		icon: 'file-word',
		label: 'Word Documents',
		showInFilter: true
	},
	{
		extensions: [
			'.csv',
			'.dbf',
			'.ods',
			'.slk',
			'.xla',
			'.xlam',
			'.xls',
			'.xlsb',
			'.xlsm',
			'.xlsx',
			'.xlt',
			'.xltm',
			'.xltx',
			'.xlw'
		],
		icon: 'file-excel',
		label: 'Excel Spreadsheets',
		showInFilter: true
	},
	{
		extensions: [
			'.odp',
			'.pot',
			'.potm',
			'.potx',
			'.ppa',
			'.ppam',
			'.pps',
			'.ppsm',
			'.ppsx',
			'.ppt',
			'.pptx',
			'.pptm'
		],
		icon: 'file-powerpoint',
		label: 'Powerpoint Presentations',
		showInFilter: true
	},
	{
		extensions: ['.vsd', '.vsdx', '.vdx', '.vsx'],
		icon: 'file-chart',
		label: 'Visio Diagrams'
	},
	{
		extensions: ['.eml', '.msg'],
		icon: 'email-outline',
		label: 'Email',
		showInFilter: true
	}
];

// File icon for the file extension
export const getFileIcon = (extension: string, hasAttachments: boolean = false): string => {
	if (hasAttachments) return 'email-outline-attachment';

	var sanitizedExtension = extension?.toLowerCase();

	// The dictionary key is a file icon from https://materialdesignicons.com/
	const ret: IFileExtension = FileExtensions.find(k => k.extensions.find(v => v === sanitizedExtension) != null);
	return get(ret, 'icon', 'file');
};

export function isPdfConversionSupported(extension: string): boolean {
	extension = extension.toLowerCase();

	const documentExtensions = FileExtensions.filter(type => type.label === 'Word Documents');

	return documentExtensions.length > 0
		? documentExtensions[0].extensions.filter(format => format === extension).length > 0 || extension === '.rtf'
		: false;
}

export function getFileExtension(fileName: string): string {
	const index = fileName.lastIndexOf('.');

	return index > 0 ? fileName.substr(index) : null;
}

export function getFileNameWithoutExtension(fileName: string): string {
	const index = fileName.lastIndexOf('.');

	return index > 0 ? fileName.substr(0, index) : fileName;
}
