<mat-card *ngIf="!!workflow">
	<section class="page-path"><a [routerLink]="['../../']">System</a> / <a [routerLink]="['..']">Matter Workflows</a> / {{ workflow?.value?.name }}</section>

	<ng-container *ngTemplateOutlet="buttonRow; context: { top: true }"></ng-container>

	<mat-tab-group [selectedIndex]="selectedTabIndex" animationDuration="0ms" (selectedTabChange)="onSelectedTabChange($event)">
		<!-- Workflow Summary Tab -->
		<mat-tab>
			<ng-template mat-tab-label>
				<span>Triggers<span class="no-mobile"> & Conditions</span></span>
			</ng-template>
			<ng-template matTabContent>
				<ng-container *ngTemplateOutlet="buttonRow"></ng-container>

				<div class="container">
					<basic-workflow-summary #summaryComponent [data]="workflow"> </basic-workflow-summary>
				</div>
			</ng-template>
		</mat-tab>

		<!-- Workflow Tasks Tab -->
		<mat-tab>
			<ng-template mat-tab-label>
				<span>Tasks</span>
			</ng-template>
			<ng-template matTabContent>
				<ng-container *ngTemplateOutlet="buttonRow"></ng-container>

				<ng-container *ngIf="!!workflow"
					><div class="container">
						<task-template-list #taskListComponent [workflow]="getWorkflow"> </task-template-list></div
				></ng-container>
			</ng-template>
		</mat-tab>

		<!-- Temporary Feature Flag -->
		<ng-container *ngIf="isDocumentTemplateFeatureEnabled">
			<!-- Document Merge Template -->
			<mat-tab *tenantFeature="'MatterWorkflowsV2'">
				<ng-template mat-tab-label>
					<span>Documents</span>
				</ng-template>
				<ng-template matTabContent>
					<ng-container *ngTemplateOutlet="buttonRow"></ng-container>

					<ng-container *ngIf="!!workflow">
						<div class="container">
							<workflow-document-template-list #documentListComponent [workflow]="getWorkflow"> </workflow-document-template-list>
						</div>
					</ng-container>
				</ng-template>
			</mat-tab>
		</ng-container>
	</mat-tab-group>
</mat-card>

<ng-template #buttonRow let-top="top">
	<div class="button-row" [class.button-row--top]="!!top" [class.button-row--bottom]="!top">
		<button
			*ngIf="!!isTaskListTab || !!isDocumentTemplateTab"
			mat-button
			color="primary"
			title="Add New"
			throttleButton
			(throttledClick)="executeAddAction()"
		>
			<mat-icon class="create-new">add_circle</mat-icon>
			<span>Add {{ isTaskListTab ? ' Task' : isDocumentTemplateTab ? ' Document' : '' }}</span>
		</button>
	</div>
</ng-template>
