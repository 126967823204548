import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { DocumentListRequest } from '@common/models/Documents/List/DocumentListRequest';
import { TemplateEntityType } from '@common/models/Documents/TemplateDto/TemplateEntityType';

import { FeatureFlags, isFeatureFlagEnabled } from 'app/app.config';
import { DocumentListComponent } from 'app/shared/documents/list/document-list.component';
import { DocumentListFilterDialogComponent } from 'app/shared/documents/list/filter-dialog/document-list-filter-dialog.component';
import { DocumentListFilterComponent } from 'app/shared/documents/list/filter/document-list-filter.component';
import { DocumentListFilterConfig } from 'app/shared/documents/list/filter/document-list-filter.config';

enum ActiveTab {
	DocumentTemplates = 0,
	DocumentEmailTemplates = 1,
	AvailableFields = 2,
	DocumentHeaderTemplate = 3,
	DocumentTemplateGroups = 4
}

@Component({
	selector: 'document-template',
	styleUrls: ['./document-template.component.scss'],
	templateUrl: './document-template.component.html'
})
export class DocumentTemplateComponent implements AfterViewInit, OnInit, OnDestroy {
	@ViewChild(DocumentListComponent)
	list: DocumentListComponent;

	@ViewChild('theTemplateFilter', { read: DocumentListFilterComponent })
	theTemplateFilter: DocumentListFilterComponent;
	@ViewChild('theHeaderFooterTemplateFilter', { read: DocumentListFilterComponent })
	theHeaderFooterTemplateFilter: DocumentListFilterComponent;

	filterRequest: Partial<DocumentListRequest> = {
		createdDateEnd: null,
		createdDateStart: null,
		modifiedDateEnd: null,
		modifiedDateStart: null,
		documentCategoryId: null,
		entityType: null,
		exc: [],
		inc: [],
		search: null,
		uploadedById: null,
		showDisabled: false,
		installationRecordId: null,
		practiceAreaIds: []
	};

	templateConfig: Partial<DocumentListFilterConfig> = {
		request: this.filterRequest,
		showEntityFilter: true,
		showReportTypeFilter: false,
		hideCategoryFilter: false,
		showDisabledFilter: true,
		showPackageFilter: true,
		showPracticeArea: true
	};

	emailTemplateConfig: Partial<DocumentListFilterConfig> = {
		request: this.filterRequest,
		showEntityFilter: true,
		showReportTypeFilter: false,
		hideTypeFilter: true,
		hideCategoryFilter: false,
		showDisabledFilter: true,
		showPackageFilter: true,
		entityTypes: [TemplateEntityType.Matter, TemplateEntityType.Contact]
	};

	headerFooterTemplateConfig: Partial<DocumentListFilterConfig> = {
		request: this.filterRequest,
		showEntityFilter: false,
		showReportTypeFilter: false,
		hideCategoryFilter: true
	};

	selectedTabIndex: number = 0;

	hideFilterButton: boolean;

	filterDialog = DocumentListFilterDialogComponent;

	private subscriptions = new Subscription();

	constructor(private router: Router, private activatedRoute: ActivatedRoute, private cdr: ChangeDetectorRef) {}

	ngOnInit() {
		// If arriving directly on a specific tab (refreshed page or direct link, then set the correct tab).
		this.subscriptions.add(
			this.activatedRoute.queryParams
				.pipe(
					first() // Only perform the subscription on init, don't listen to further tab changes.
				)
				.subscribe(params => {
					if (params['activeTab']) {
						switch (params['activeTab'].toLowerCase()) {
							case ActiveTab[ActiveTab.DocumentTemplates].toLowerCase():
								this.selectedTabIndex = ActiveTab.DocumentTemplates;
								break;
							case ActiveTab[ActiveTab.DocumentEmailTemplates].toLowerCase():
								this.selectedTabIndex = ActiveTab.DocumentEmailTemplates;
								break;
							case ActiveTab[ActiveTab.DocumentHeaderTemplate].toLowerCase():
								this.selectedTabIndex = ActiveTab.DocumentHeaderTemplate;
								break;
							case ActiveTab[ActiveTab.DocumentTemplateGroups].toLowerCase():
								this.selectedTabIndex = ActiveTab.DocumentTemplateGroups;
								break;
							case ActiveTab[ActiveTab.AvailableFields].toLowerCase():
								this.selectedTabIndex = ActiveTab.AvailableFields;
								break;
						}

						if (!this.canShowEmailTemplates && this.selectedTabIndex > 0) {
							this.selectedTabIndex--;
						}
					}
				})
		);
	}

	get canShowEmailTemplates() {
		return !!isFeatureFlagEnabled(FeatureFlags.emailTemplates);
	}

	ngAfterViewInit() {
		// Due to a broken dependency between <filter-mobile-span> component and <document-list-filter>,
		// which is nested under <document-list>, 'filterRoot' property of <filter-mobile-span> gets
		// updated after onAfterViewInit
		this.cdr.detectChanges();
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	// Hide the create template button in the available fields tab
	onSelectedTabChange(event: MatTabChangeEvent) {
		const activeTab = event.index as ActiveTab;

		this.hideFilterButton = this.canShowEmailTemplates ? activeTab > 1 : activeTab > 0;

		var params = Object.keys(this.activatedRoute.snapshot.queryParams).reduce((accumlator, next) => {
			accumlator[next] = null;
			return accumlator;
		}, {} as { [key: string]: string });

		params['activeTab'] =
			ActiveTab[!this.canShowEmailTemplates && activeTab > 0 ? activeTab + 1 : activeTab]?.toLowerCase();

		// update 'activeTab' query parameter in the URL when changing tabs
		this.router.navigate([], {
			queryParams: params,
			queryParamsHandling: 'merge',
			relativeTo: this.activatedRoute
		});
	}
}
