// This file has been generated from TasksController.cs
// Do not modify this file directly!!
// tslint:disable

import { HttpClient, HttpResponse } from '@angular/common/http';
import { IDownloadFile } from '../models/Files/IFileDownloader';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { TrimStringsOnObject } from '@common/utils/string-format';

import { EntityReference } from '../models/Common/EntityReference';
import { IdCollectionRequest } from '../models/Common/IdCollectionRequest';
import { MutationResponseDto } from '../models/Common/MutationResponseDto';
import { DocumentFromTemplateDto } from '../models/Documents/Item/DocumentFromTemplateDto';
import { ListResponse } from '../models/Generic/ListResponse';
import { CollectionTaskCreateUpdateDto } from '../models/Tasks/Item/CollectionTaskCreateUpdateDto';
import { CollectionTaskCustomFieldsDto } from '../models/Tasks/Item/CollectionTaskCustomFieldsDto';
import { CollectionTaskDataDto } from '../models/Tasks/Item/CollectionTaskDataDto';
import { DocumentTemplateMergeTaskCreateUpdateDto } from '../models/Tasks/Item/DocumentTemplateMergeTaskCreateUpdateDto';
import { DocumentTemplateMergeTaskTemplatesDto } from '../models/Tasks/Item/DocumentTemplateMergeTaskTemplatesDto';
import { GenericTaskCreateUpdateDto } from '../models/Tasks/Item/GenericTaskCreateUpdateDto';
import { ReferralRecipientDto } from '../models/Tasks/Item/ReferralRecipientDto';
import { ReferralTaskCreateUpdateDto } from '../models/Tasks/Item/ReferralTaskCreateUpdateDto';
import { TaskAssigneeUpdateDto } from '../models/Tasks/Item/TaskAssigneeUpdateDto';
import { TaskPatchDto } from '../models/Tasks/Item/TaskPatchDto';
import { TaskStatusUpdateDto } from '../models/Tasks/Item/TaskStatusUpdateDto';
import { TaskViewDto } from '../models/Tasks/Item/TaskViewDto';
import { TaskListItemDto } from '../models/Tasks/List/TaskListItemDto';
import { TaskListRequest } from '../models/Tasks/List/TaskListRequest';

@Injectable({
	providedIn: 'root'
})
export class TasksService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'api/v1/Tasks', 'Task');
	}

	createTask(dto: GenericTaskCreateUpdateDto): Observable<MutationResponseDto> {
		return this.post<GenericTaskCreateUpdateDto, MutationResponseDto>('', TransformDatesOnObject(GenericTaskCreateUpdateDto, TrimStringsOnObject(dto)));
	}

	createReferralTask(dto: ReferralTaskCreateUpdateDto): Observable<MutationResponseDto> {
		return this.post<ReferralTaskCreateUpdateDto, MutationResponseDto>(`referrals`, TransformDatesOnObject(ReferralTaskCreateUpdateDto, TrimStringsOnObject(dto)));
	}

	createReferralTaskRecipient(id: string, dto: ReferralRecipientDto): Observable<MutationResponseDto> {
		return this.post<ReferralRecipientDto, MutationResponseDto>(`${id}/referrals/recipients`, TransformDatesOnObject(ReferralRecipientDto, TrimStringsOnObject(dto)));
	}

	createTemplateMergeTask(dto: DocumentTemplateMergeTaskCreateUpdateDto): Observable<MutationResponseDto> {
		return this.post<DocumentTemplateMergeTaskCreateUpdateDto, MutationResponseDto>(`CreateTemplateMergeTask`, TransformDatesOnObject(DocumentTemplateMergeTaskCreateUpdateDto, TrimStringsOnObject(dto)));
	}

	createCollectionTask(dto: CollectionTaskCreateUpdateDto): Observable<MutationResponseDto> {
		return this.post<CollectionTaskCreateUpdateDto, MutationResponseDto>(`CreateCollectionTask`, TransformDatesOnObject(CollectionTaskCreateUpdateDto, TrimStringsOnObject(dto)));
	}

	updateTask(id: string, dto: GenericTaskCreateUpdateDto): Observable<MutationResponseDto> {
		return this.put(`${id}`, TransformDatesOnObject(GenericTaskCreateUpdateDto, TrimStringsOnObject(dto)));
	}

	updateTemplateMergeTask(id: string, dto: DocumentTemplateMergeTaskCreateUpdateDto): Observable<MutationResponseDto> {
		return this.post<DocumentTemplateMergeTaskCreateUpdateDto, MutationResponseDto>(`${id}/UpdateTemplateMergeTask`, TransformDatesOnObject(DocumentTemplateMergeTaskCreateUpdateDto, TrimStringsOnObject(dto)));
	}

	updateCollectionTask(id: string, dto: CollectionTaskCreateUpdateDto): Observable<MutationResponseDto> {
		return this.post<CollectionTaskCreateUpdateDto, MutationResponseDto>(`${id}/UpdateCollectionTask`, TransformDatesOnObject(CollectionTaskCreateUpdateDto, TrimStringsOnObject(dto)));
	}

	updateTaskStatus(statusUpdateDto: TaskStatusUpdateDto): Observable<MutationResponseDto[]> {
		return this.put(`updateStatus`, TransformDatesOnObject(TaskStatusUpdateDto, TrimStringsOnObject(statusUpdateDto)));
	}

	updateTaskAssignee(dtoUpdateDto: TaskAssigneeUpdateDto): Observable<MutationResponseDto[]> {
		return this.put(`updateAssignedTo`, TransformDatesOnObject(TaskAssigneeUpdateDto, TrimStringsOnObject(dtoUpdateDto)));
	}

	patchTask(id: string, patchDocument: Partial<TaskPatchDto>): Observable<MutationResponseDto> {
		return this.patch<TaskPatchDto, MutationResponseDto>(`${id}`, TransformDatesOnObject(TaskPatchDto, TrimStringsOnObject(patchDocument)));
	}

	getTaskList(request?: Partial<TaskListRequest>): Observable<ListResponse<TaskListItemDto>> {
		return this.getList<TaskListRequest, TaskListItemDto>('', request);
	}

	getTask(id: string): Observable<TaskViewDto> {
		return this.getItem<void, TaskViewDto>(`${id}`);
	}

	exportTasksByFilter(request?: Partial<TaskListRequest>): Observable<HttpResponse<Blob>> {
		return this.getBlob<TaskListRequest>(`exportByFilter`, request);
	}

	exportTasksByIds(request: IdCollectionRequest): Observable<HttpResponse<Blob>> {
		return this.getBlob<IdCollectionRequest>(`exportByIds`, request);
	}

	deleteTask(id: string): Observable<MutationResponseDto> {
		return this.delete(`${id}`);
	}

	createDocumentFromTemplate(id: string, dto: DocumentFromTemplateDto): Observable<EntityReference> {
		return this.post<DocumentFromTemplateDto, EntityReference>(`${id}/CreateDocumentFromTemplate`, TransformDatesOnObject(DocumentFromTemplateDto, TrimStringsOnObject(dto)));
	}

	addTemplatesToTask(id: string, dto: DocumentTemplateMergeTaskTemplatesDto): Observable<any> {
		return this.post<DocumentTemplateMergeTaskTemplatesDto, any>(`${id}/AddTemplatesToTask`, TransformDatesOnObject(DocumentTemplateMergeTaskTemplatesDto, TrimStringsOnObject(dto)));
	}

	removeTemplatesFromTask(id: string, dto: DocumentTemplateMergeTaskTemplatesDto): Observable<any> {
		return this.post<DocumentTemplateMergeTaskTemplatesDto, any>(`${id}/RemoveTemplatesFromTask`, TransformDatesOnObject(DocumentTemplateMergeTaskTemplatesDto, TrimStringsOnObject(dto)));
	}

	updateCollectionTaskCustomFields(id: string, dto: CollectionTaskCustomFieldsDto): Observable<any> {
		return this.post<CollectionTaskCustomFieldsDto, any>(`${id}/UpdateCollectionTaskCustomFields`, TransformDatesOnObject(CollectionTaskCustomFieldsDto, TrimStringsOnObject(dto)));
	}

	processCollectionTaskData(id: string, dto: CollectionTaskDataDto): Observable<any> {
		return this.post<CollectionTaskDataDto, any>(`${id}/ProcessCollectionTaskData`, TransformDatesOnObject(CollectionTaskDataDto, TrimStringsOnObject(dto)));
	}

}
