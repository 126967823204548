// This file has been generated from CostCodeListRequest.cs
// Do not modify this file directly!!
// tslint:disable

import { SetSimpleType } from '@common/utils/class-transform';

import { EnumSortDirection } from '../../../Common/EnumSortDirection';
import { CostType } from '../../../CostRecords/Common/CostType';

// LawMaster.Amalthea.Domain.Settings.CostCodes.List.CostCodeListRequest
export class CostCodeListRequest {

	// Search
	search: string;
	// SortBy
	sortBy: string;
	// SortDirection
	sortDirection: keyof typeof EnumSortDirection;
	// PageIndex
	pageIndex: number;
	// PageSize
	pageSize: number;
	// PageIndexForId
	pageIndexForId: string;
	// CostType
	costType: keyof typeof CostType;
	// IsEnabled
	@SetSimpleType(Boolean)
	isEnabled: boolean;
	// IsFixedFee
	@SetSimpleType(Boolean)
	isFixedFee: boolean;

	constructor() {

	}
}