// This file has been generated from CostCodeDto.cs
// Do not modify this file directly!!
// tslint:disable

import { SetSimpleType } from '@common/utils/class-transform';

import { CostCodeExpenseType } from '../Common/CostCodeExpenseType';
import { CostCodeTimeType } from '../Common/CostCodeTimeType';

// LawMaster.Amalthea.Domain.Settings.CostCodes.Item.CostCodeDto
export class CostCodeDto {

	// Id
	id: string;
	// Name
	name: string;
	// TimeType
	timeType: keyof typeof CostCodeTimeType;
	// ExpenseType
	expenseType: keyof typeof CostCodeExpenseType;
	// ChargeRate
	chargeRate: number;
	// IsDisabled
	@SetSimpleType(Boolean)
	isDisabled: boolean;

	constructor() {

	}
}