<div [formGroup]="form">
	<mat-expansion-panel #topPanel expanded hideToggle>
		<mat-expansion-panel-header class="fixed" throttleButton (throttledClick)="keepOpen($event)">
			<mat-panel-title><strong>Organisation Settings</strong></mat-panel-title>
		</mat-expansion-panel-header>
		<div class="row" *ngIf="showOrganisationList">
			<span class="title">Organisation name<sup class="color-warn">*</sup></span>
			<mat-form-field>
				<mat-select formControlName="xeroTenant" [compareWith]="compareTenantIds">
					<mat-option *ngFor="let tenant of usersXeroTenants" [value]="tenant"> {{ tenant?.xeroTenantName }} </mat-option>
				</mat-select>
				<mat-hint>Xero company to connect to for synchronisation</mat-hint>
				<mat-error>
					<error-messages [for]="form.get('xeroTenant')"></error-messages>
				</mat-error>
			</mat-form-field>
		</div>
		<div class="row">
			<span class="title">Account for Time/Fee records<sup class="color-warn">*</sup></span>
			<mat-form-field>
				<mat-select formControlName="timeEntriesAccount" [compareWith]="compareIds">
					<mat-option *ngFor="let account of timeRecordAccounts" [value]="account"> {{ account?.id }} - {{ account?.name }} </mat-option>
				</mat-select>
				<mat-hint>Account to use for time record invoice line items</mat-hint>
				<mat-error>
					<error-messages [for]="form.get('timeEntriesAccount')"></error-messages>
				</mat-error>
			</mat-form-field>
		</div>
		<div class="row">
			<span class="title">Account for expenses<sup class="color-warn">*</sup></span>
			<mat-form-field>
				<mat-select formControlName="expensesAccount" [compareWith]="compareIds">
					<mat-option *ngFor="let account of expenseAccounts" [value]="account"> {{ account?.id }} - {{ account?.name }} </mat-option>
				</mat-select>
				<mat-hint>Account to use for expense invoice line items</mat-hint>
				<mat-error>
					<error-messages [for]="form.get('expensesAccount')"></error-messages>
				</mat-error>
			</mat-form-field>
		</div>
	</mat-expansion-panel>

	<mat-expansion-panel [expanded]="!showOrganisationList">
		<mat-expansion-panel-header>
			<mat-panel-title><strong>Advanced</strong></mat-panel-title>
		</mat-expansion-panel-header>
		<div class="row">
			<span class="title">Tax Rate for GST free Time/Fee records</span>
			<mat-form-field>
				<mat-select formControlName="timeEntriesExGstTaxType" [compareWith]="compareIds">
					<mat-option value=""></mat-option>
					<mat-option *ngFor="let taxRate of timeRecordTaxRates" [value]="taxRate"> {{ taxRate?.name }} </mat-option>
				</mat-select>
				<mat-hint>Tax rate to use for Time/Fee records that are GST free</mat-hint>
				<mat-error>
					<error-messages [for]="form.get('timeEntriesExGstTaxType')"></error-messages>
				</mat-error>
			</mat-form-field>
		</div>
		<div class="row">
			<span class="title">Tax Rate for GST free expenses</span>
			<mat-form-field>
				<mat-select formControlName="expensesExGstTaxType" [compareWith]="compareIds">
					<mat-option value=""></mat-option>
					<mat-option *ngFor="let taxRate of expenseTaxRates" [value]="taxRate"> {{ taxRate?.name }} </mat-option>
				</mat-select>
				<mat-hint>Tax rate to use for expenses that are GST free</mat-hint>
				<mat-error>
					<error-messages [for]="form.get('expensesExGstTaxType')"></error-messages>
				</mat-error>
			</mat-form-field>
		</div>
		<div class="row top-margin" *ngIf="!showOrganisationList">
			<span class="title field-space">Description Format in Xero</span>
			<div class="field">
				<div class="description-column">
					<span class="description" *ngIf="form.controls.xeroLineItemDescriptionFormat.value">{{
						form.controls.xeroLineItemDescriptionFormat.value | commaToHyphen
					}}</span>
					<span class="description-blank" *ngIf="!form.controls.xeroLineItemDescriptionFormat.value">BLANK</span>
					<span class="description-hint">Invoice line item description text format</span>
				</div>
			</div>
			<button
				class="action"
				mat-stroked-button
				color="primary"
				throttleButton
				(throttledClick)="editDescriptionFormat()"
				[disabled]="form.controls.timeEntriesAccount.disabled"
			>
				Edit
			</button>
		</div>
		<div class="row top-margin" *ngIf="!showOrganisationList">
			<span class="title field-space">Invoice Reference Format in Xero</span>
			<div class="field">
				<div class="description-column">
					<span class="description" *ngIf="!!formattedXeroInvoiceReferenceFormat">{{ formattedXeroInvoiceReferenceFormat }}</span>
					<span class="description-blank" *ngIf="!formattedXeroInvoiceReferenceFormat">BLANK</span>
					<span class="description-hint">Reference field text format on invoice</span>
				</div>
			</div>
			<button
				class="action"
				mat-stroked-button
				color="primary"
				throttleButton
				(throttledClick)="editReferenceFormat()"
				[disabled]="form.controls.timeEntriesAccount.disabled"
			>
				Edit
			</button>
		</div>
		<div class="row" *ngIf="!showOrganisationList">
			<span class="title">Time frame to sync payments (in months)</span>
			<mat-form-field>
				<input formControlName="numberMonthsToBulkSync" matInput type="number" autocomplete="off" min="0" max="99" />
				<span matPrefix *ngIf="form.get('numberMonthsToBulkSync').value"></span>
				<mat-hint>Numbers of months to sync payments when bulk sync is performed</mat-hint>
				<mat-error>
					<error-messages [for]="form.get('numberMonthsToBulkSync')"></error-messages>
				</mat-error>
			</mat-form-field>
			<mat-icon class="tooltip-icon action" [matTooltip]="numberMonthsTooltip">help</mat-icon>
		</div>
	</mat-expansion-panel>
</div>
