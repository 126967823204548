import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';

import { SharedDirectivesModule } from '@common/directives/shared-directives.module';
import { SharedPipesModule } from '@common/pipes/shared-pipes.module';

import { PipesModule } from '../../main/src/app/shared/pipes/pipes.module';
import { AlertDialogComponent } from './alert-dialog.component';
import { CallToActionComponent } from './call-to-action-dialog';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { CriticalErrorDialogComponent } from './critical-error-dialog.component';
import { NotificationComponent } from './notification.component';
import { NotificationService } from './notification.service';
import { RateLimitDialogComponent } from './rate.limit-dialog.component';
import { ReloadErrorDialogComponent } from './reload-error-dialog.component';
import { TenantMessageDialogComponent } from './tenant-message-dialog.component';

@NgModule({
	declarations: [
		AlertDialogComponent,
		CriticalErrorDialogComponent,
		ConfirmationDialogComponent,
		RateLimitDialogComponent,
		ReloadErrorDialogComponent,
		NotificationComponent,
		TenantMessageDialogComponent,
		CallToActionComponent
	],
	imports: [
		CommonModule,
		MatButtonModule,
		MatDialogModule,
		MatIconModule,
		MatInputModule,
		MatSnackBarModule,
		ReactiveFormsModule,
		RouterModule,
		PipesModule,
		SharedPipesModule,
		SharedDirectivesModule
	]
})
export class NotificationModule {
	// Use forRoot() to import a module with services, which need to be SINGLETON for lazy-loading modules.
	// Otherwise, the lazy modules will have new instances of services created for them
	// See https://angular.io/guide/singleton-services#the-forroot-pattern.
	static forRoot(): ModuleWithProviders<NotificationModule> {
		return {
			ngModule: NotificationModule,
			providers: [NotificationService]
		};
	}
}
