import { Injectable } from '@angular/core';
import * as pdfjsLib from 'pdfjs-dist';
import { Metadata } from 'pdfjs-dist/types/src/display/metadata';
import { Observable, Subject } from 'rxjs';
import { FileExtensions } from '../../../../../common/utils/file-extensions';
import { BaseMetadataFile, BaseMetadataService } from './base-metadata.service';

@Injectable({
	providedIn: 'root'
})
export class PdfMetadataService extends BaseMetadataService<PdfMetadataFile> {
	static readonly TagsKeys: string[] = ['Keywords'];

	static readonly CreatedKeys: string[] = ['CreationDate'];

	private static supportedExtensionLabels: string[] = ['PDF Documents'];

	isSupported(extension: string): boolean {
		return (
			FileExtensions.filter(
				group =>
					PdfMetadataService.supportedExtensionLabels.includes(group.label) &&
					group.extensions.includes(extension.toLowerCase())
			).length > 0
		);
	}

	readArrayBuffer(data: ArrayBuffer | Promise<ArrayBuffer>): Observable<PdfMetadataFile> {
		const subject = new Subject<PdfMetadataFile>();

		this.readArrayBufferAsync(data)
			.then(metadata => subject.next(metadata))
			.catch(error => subject.error(error));

		return subject;
	}

	private async readArrayBufferAsync(data: ArrayBuffer | Promise<ArrayBuffer>): Promise<PdfMetadataFile> {
		const buffer = await Promise.resolve(data);

		const array = new Int8Array(buffer);

		const pdfDoc = await pdfjsLib.getDocument(array).promise;
		const pdfData = await pdfDoc.getMetadata();
		return new PdfMetadataFile(pdfData);
	}
}

export class PdfMetadataFile extends BaseMetadataFile {
	constructor(
		private _data: {
			info: { [key: string]: any };
			metadata: Metadata;
		}
	) {
		super();
	}

	getMetadataValues(keys: string[]): string[] {
		const matches: string[] = [];

		if (!!this._data?.info) {
			keys.forEach(key => {
				if (key === 'PDFFormatVersion' && !!this._data.info['PDFFormatVersion']) {
					matches.push(this._data.info['PDFFormatVersion']);
				} else if (key === 'IsAcroFormPresent') {
					matches.push(`${this._data.info['IsAcroFormPresent']}`);
				} else if (key === 'IsXFAPresent') {
					matches.push(`${this._data.info['IsXFAPresent']}'`);
				} else {
					const value = this._data.info[key];
					if (!!value) {
						matches.push(`${value}`);
					}
				}
			});
		}

		if (!!this._data?.metadata) {
			keys.forEach(key => {
				if (!!this._data.metadata.has(key)) {
					const value = this._data.metadata.get(key);
					if (!!value) {
						matches.push(value);
					}
				}
			});
		}

		return matches;
	}
}
