import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';

import { BehaviorSubject, map, Observable, of } from 'rxjs';

import { BaseLookupComponent } from '@common/components/lookups/base-lookup.component';
import { BaseDtoWithTrimmedId } from '@common/models/Common/BaseDtoWithTrimmedId';
import { arraysEqual } from '@common/utils/arrayUtils';

@Component({
	selector: 'grouped-local-lookup',
	styleUrls: ['./grouped-local-lookup.component.scss'],
	templateUrl: './grouped-local-lookup.component.html'
})
export class GroupedLocalLookupComponent extends BaseLookupComponent<IItem> {
	@ViewChild(MatAutocompleteTrigger)
	trigger: MatAutocompleteTrigger;
	@ViewChild('autoCompleteInput', { static: true })
	inputCtrl: ElementRef;
	@Input()
	autoSelectIfOnlyOne: boolean;

	private _filteredGroups: IGrouping[];

	private _dataSource$ = new BehaviorSubject<IGrouping[]>(null);

	@Input()
	set dataSource(value: IGrouping[]) {
		if (arraysEqual(value, this._dataSource$.value)) {
			return;
		}

		this._dataSource$.next(value);
	}

	get filteredGroups() {
		return this._filteredGroups;
	}

	inputClicked() {
		this._filteredGroups = this._dataSource$.value ?? [];
		this.trigger.openPanel();
	}

	clearInput() {
		this.FormControl.setValue(null);
		this.setSelectedValue(null);

		this.FormControl.markAsTouched();
		this.FormControl.markAsDirty();
		this.FormControl.updateValueAndValidity();
	}

	inputChanged(event: any) {
		this.options = this.flatten(this._dataSource$.value ?? []);
	}

	private flatten(groupings: IGrouping[]) {
		return groupings
			?.map(group => group.items)
			?.reduce((accumlator, next) => {
				accumlator = accumlator ?? [];

				if (!!next?.length) {
					accumlator = accumlator.concat(next);
				}

				return accumlator;
			});
	}

	autocompleteSelected(event: MatAutocompleteSelectedEvent): void {
		this.options = this.flatten(this._dataSource$?.value ?? []);
		super.autocompleteSelected(event);

		this._filteredGroups = this.filter(this.inputDisplayCtrl.value);
	}

	lookup(id: string): Observable<IItem> {
		const observable$ = !this._dataSource$.value ? this._dataSource$.pipe() : of(this._dataSource$.value);

		return observable$.pipe(
			map(dataSource => (!dataSource?.length ? null : this.flatten(dataSource).filter(item => item.id === id)[0]))
		);
	}

	optionHtmlText(input: IItem): string {
		return input?.display ?? null;
	}

	displayText(input: IItem) {
		return input?.display ?? null;
	}

	search(term: string): Observable<IItem[]> {
		this._filteredGroups = this.filter(this.inputDisplayCtrl.value) ?? [];

		return of(this.flatten(this._filteredGroups));
	}

	searchIfTermEmpty(): boolean {
		return true;
	}

	setFocus() {
		if (this.inputCtrl != null) {
			setTimeout(() => {
				this.inputCtrl.nativeElement.focus();
			}, 0);
		}
	}

	private filter(termOrId: string): IGrouping[] {
		if (!this._dataSource$.value?.length) {
			return [];
		}

		const termLowercase = termOrId.toLowerCase();

		return this._dataSource$.value
			.map(group => ({
				...group,
				items: group.items
					?.map(item => ({
						...item
					}))
					.filter(x => x.id === termLowercase || !!x.display?.toLowerCase()?.includes(termLowercase))
			}))
			.filter(group => !!group.name?.toLowerCase()?.includes(termLowercase) || !!group.items?.length);
	}
}

export interface IGrouping {
	name: string;
	items: IItem[];
}

export interface IItem extends BaseDtoWithTrimmedId {
	display: string;
}
