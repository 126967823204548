import { DomainError } from '@common/models/Validation/DomainError';
import { createAction, props } from '@ngrx/store';

export enum JobStatusFilterActions {
	InvalidateCache = '[Job Status Filter] Invalidate Cache',
	Load = '[Job Status Filter] Load',
	Loading = '[Job Status Filter] Loading',
	LoadSuccess = '[Job Status Filter] Load Success',
	LoadFailed = '[Job Status Filter] Load Failed'
}

export const load = createAction(JobStatusFilterActions.Load);
export const loading = createAction(JobStatusFilterActions.Loading);
export const loadSuccess = createAction(JobStatusFilterActions.LoadSuccess, props<{ response: string[] }>());
export const loadFailed = createAction(JobStatusFilterActions.LoadFailed, props<{ error: DomainError }>());

export const invalidateCache = createAction(JobStatusFilterActions.InvalidateCache);
