<h2 mat-dialog-title class="title">{{ isCreateMode ? 'Create' : 'Edit' }} Bank Account</h2>
<mat-dialog-content [formGroup]="form" class="flex-column">
	<bank-details *ngIf="!id || bankDetails" [mainForm]="form" [bankDetails]="bankDetails" [isEditMode]="!!id"></bank-details>
	<div class="flex-row">
		<contact-lookup
			[FormControl]="form.get('defaultPayeeId')"
			(Selected)="onContactSelected($event)"
			[AllowCreate]="false"
			[Required]="false"
			Placeholder="Default Payee"
			Hint="Default payee used for Trust Invoice Payments"
		></contact-lookup>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<div>
		<button
			class="delete-btn"
			*ngIf="!isCreateMode"
			mat-stroked-button
			color="warn"
			[disabled]="form.invalid"
			[mat-dialog-close]="{ accountData: form.value, isDelete: true }"
		>
			Delete
		</button>
	</div>
	<div>
		<button mat-raised-button color="primary" [disabled]="form.invalid" [mat-dialog-close]="{ accountData: form.value, isDelete: false }">Save</button>
		<button mat-raised-button mat-dialog-close>Cancel</button>
	</div>
</mat-dialog-actions>
