import { CustomizableListType } from '@common/models/Users/Common/CustomizableListType';
import { CurrentUserViewDto } from '@common/models/Users/Item/CurrentUserViewDto';
import { createAction, props } from '@ngrx/store';

export enum CurrentUserActions {
	LoadCurrentUser = '[CurrentUser] Load',
	LoadCurrentUserSuccess = '[CurrentUser] Load Success',
	LoadCurrentUserError = '[CurrentUser] Load Error',
	UpdateCurrentUserListColumns = '[CurrentUser] Update List Columns'
}

export const loadCurrentUserSuccess = createAction(
	CurrentUserActions.LoadCurrentUserSuccess,
	props<{ currentUser: CurrentUserViewDto }>()
);

export const loadCurrentUserError = createAction(CurrentUserActions.LoadCurrentUserError, props<{ error: any }>());

export const updateCurrentUserListColumns = createAction(
	CurrentUserActions.UpdateCurrentUserListColumns,
	props<{ listType: keyof typeof CustomizableListType; columns: string[] }>()
);
