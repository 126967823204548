<mat-card>
	<section class="breadcrumb"><a [routerLink]="['..']">System</a> / Searches</section>
	<mat-card>
		<h2>Searching and Services</h2>
		<hr />
		<div class="flex-column" [formGroup]="form">
			<div class="container">
				<span *ngIf="isSearchEnabled" class="title field-space">Search services enabled</span>
				<span *ngIf="!isSearchEnabled" class="title field-space">Search services disabled</span>
				<mat-slide-toggle class="enable-toggle" [checked]="isSearchEnabled" (change)="toggleSearchOnOff($event)"></mat-slide-toggle>
			</div>
			<div class="container field-space">
				<span class="title">GST Free Cost Code</span>
				<mat-form-field class="input-field">
					<mat-select formControlName="gstFreeCostCodeId">
						<mat-option *ngFor="let option of costCodesGstFree" [value]="option.id">{{ option.name }}</mat-option>
					</mat-select>
					<mat-error> <error-messages [for]="form.controls.gstFreeCostCodeId"></error-messages> </mat-error>
				</mat-form-field>
			</div>
			<div class="container">
				<span class="title">GST Applicable Cost Code</span>
				<mat-form-field>
					<mat-select formControlName="gstApplicableCostCodeId">
						<mat-option *ngFor="let option of costCodesGstApplicable" [value]="option.id">{{ option.name }}</mat-option>
					</mat-select>
					<mat-error> <error-messages [for]="form.controls.gstApplicableCostCodeId"></error-messages> </mat-error>
				</mat-form-field>
			</div>
			<div *ngIf="isWarning" class="container warning-message">
				<a [routerLink]="['/system/cost-codes']" class="warning-link">
					<mat-icon class="warning-icon" color="warn">warning</mat-icon>
					<span class="warning-text">{{ warningMessage }}</span>
				</a>
			</div>
			<div class="container cost-code-desc">
				Select the expense&nbsp;<a [routerLink]="['/system/cost-codes']">cost codes</a>&nbsp;to be used for InfoTrack orders.
			</div>
			<div class="container action-pane">
				<button mat-raised-button color="primary" [disabled]="form.invalid || form.disabled || form.pristine" throttleButton (throttledClick)="save()">
					Save
				</button>
			</div>
		</div>
	</mat-card>
</mat-card>
