import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BillSettingDto } from '@common/models/Settings/BillSettings/Item/BillSettingDto';
import { SaveBillTemplateDto } from '@common/models/Settings/BillSettings/Item/SaveBillTemplateDto';
import { NotificationService } from '@common/notification';
import { BillsService } from '@common/services/settings/bills.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'bill-template',
	styleUrls: ['./bill-template.component.scss'],
	templateUrl: './bill-template.component.html'
})
export class BillTemplateComponent implements OnInit, OnDestroy {
	@Input() billSettings: BillSettingDto;

	form: FormGroupTyped<SaveBillTemplateDto>;

	private subscriptions = new Subscription();

	get templateIdControl(): FormControlTyped<string> {
		return this.form.controls.templateId as FormControlTyped<string>;
	}

	constructor(
		private fb: FormBuilder,
		private billSettingsService: BillsService,
		private notificationService: NotificationService
	) {}

	ngOnInit() {
		this.form = this.fb.group({
			templateId: this.billSettings?.templateId
		}) as FormGroupTyped<SaveBillTemplateDto>;
	}

	save(): void {
		this.subscriptions.add(
			this.billSettingsService.saveTemplateId(this.form.value).subscribe(
				() => {
					this.notificationService.showNotification('TemplateId saved successfully');
					this.form.markAsPristine();
				},
				error => this.notificationService.showError('Error saving the TemplateId configuration', error)
			)
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}
}
