import { Component, Input, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EntityReference } from '@common/models/Common/EntityReference';
import { Store } from '@ngrx/store';

import { IAppState } from 'app/core/state/app.state';
import { load } from 'app/core/state/filters/trust-account-filter/trust-account-filter.actions';
import { selectTrustAccountFilterRecords } from 'app/core/state/filters/trust-account-filter/trust-account-filter.selectors';

import { FilterBaseComponent } from './filter-base.component';

@Component({
	selector: 'filter-trust-account',
	template: `
		<filter-entity-reference-list
			[root]="root"
			[name]="name"
			[label]="label"
			[items]="source$ | async"
			[showTitleAsPrefix]="showTitleAsPrefix"
			[hideClear]="hideClear"
			[trimDisplayText]="trimDisplayText"
		></filter-entity-reference-list>
	`
})
export class FilterTrustAccountComponent extends FilterBaseComponent implements OnInit {
	source$: Observable<EntityReference[]> = this._store
		.select(selectTrustAccountFilterRecords)
		.pipe(
			map(
				trustAccounts =>
					trustAccounts?.map(account => ({ id: account.id, name: account.bankDetails.accountName })) ?? []
			)
		);

	@Input()
	trimDisplayText: boolean;

	constructor(private _store: Store<IAppState>) {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();

		this._store.dispatch(load());
	}
}
