import { SortDirection } from '@angular/material/sort';

import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';
import { ContactListItemDto } from '@common/models/Contacts/List/ContactListItemDto';
import { ContactListRequest } from '@common/models/Contacts/List/ContactListRequest';
import { ListResponse } from '@common/models/Generic/ListResponse';
import { DomainError } from '@common/models/Validation/DomainError';
import { createAction, props } from '@ngrx/store';

import { SelectionType } from '../../SelectionType';

export enum ContactListActions {
	Init = '[Contact List] Initialise',
	Load = '[Contact List] Load',
	Loading = '[Contact List] Loading',
	LoadSuccess = '[Contact List] Load Success',
	LoadFailed = '[Contact List] Load Failed',

	SetFilters = '[Contact List] Set Filters',
	SetPageSize = '[Contact List] Set Page Size',
	SetPageIndex = '[Contact List] Set Page Index',
	SetPageIndexForId = '[Contact List] Set Page Index For Id',

	SetSortBy = '[Contact List] Set Sort By',
	SetSortDirection = '[Contact List] Set Sort Direction',

	InsertRecords = '[Contact List] Insert Records',
	UpdateRecords = '[Contact List] Update Records',
	RemoveRecords = '[Contact List] Remove Records',
	SelectRecords = '[Contact List] Select Records'
}

export const init = createAction(ContactListActions.Init);
export const load = createAction(ContactListActions.Load);
export const loading = createAction(ContactListActions.Loading);
export const loadSuccess = createAction(
	ContactListActions.LoadSuccess,
	props<{ response: ListResponse<ContactListItemDto> }>()
);
export const loadFailed = createAction(ContactListActions.LoadFailed, props<{ error: DomainError }>());

export const setFilters = createAction(ContactListActions.SetFilters, props<{ filter: Partial<ContactListRequest> }>());
export const setPageSize = createAction(ContactListActions.SetPageSize, props<{ pageSize: number }>());
export const setPageIndex = createAction(ContactListActions.SetPageIndex, props<{ pageIndex: number }>());
export const setPageIndexForId = createAction(ContactListActions.SetPageIndexForId, props<{ id: string }>());

export const setSortBy = createAction(ContactListActions.SetSortBy, props<{ sortBy: string }>());
export const setSortDirection = createAction(
	ContactListActions.SetSortDirection,
	props<{ sortDirection: SortDirection }>()
);

export const insertRecords = createAction(
	ContactListActions.InsertRecords,
	props<{ response: MutationResponseDto | MutationResponseDto[] }>()
);
export const updateRecords = createAction(
	ContactListActions.UpdateRecords,
	props<{ response: MutationResponseDto | MutationResponseDto[] }>()
);
export const removeRecords = createAction(
	ContactListActions.RemoveRecords,
	props<{ response: MutationResponseDto | MutationResponseDto[] }>()
);
export const selectRecords = createAction(
	ContactListActions.SelectRecords,
	props<{ row: ContactListItemDto; selectionType: SelectionType }>()
);
