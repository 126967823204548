import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { forkJoin } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { EnumSortDirection } from '@common/models/Common/EnumSortDirection';
import { ListRequest } from '@common/models/Generic/ListRequest';
import { RecurringInvoiceDto } from '@common/models/Infrastructure/StripeIntegration/Models/RecurringInvoiceDto';
import { UpcomingInvoiceDto } from '@common/models/Infrastructure/StripeIntegration/Models/UpcomingInvoiceDto';
import { OverlappingModuleDto } from '@common/models/Settings/Modules/Item/OverlappingModuleDto';
import { DomainError } from '@common/models/Validation/DomainError';
import { NotificationService } from '@common/notification';
import { ModuleService } from '@common/services/module.service';
import { GeneralSettingsService } from '@common/services/settings/generalsettings.service';
import { MarketplaceService } from '@common/services/settings/marketplace.service';
import { PracticeAreasService } from '@common/services/settings/practiceareas.service';
import { UsersService } from '@common/services/settings/users.service';
import { StripeService } from '@common/services/stripe.service';
import { isEmptyOrNil } from '@common/utils/stringUtils';
import { CustomValidators } from '@common/validation/custom.validators';
import { uniq } from 'lodash-es';

import { ConfigurePackageComponent, ConfigurePackageOperationType, IPackageConfiguration, IPackageConfigurationData } from '../marketplace/package/configure-package.component';
import { BaseModifyMarketplaceItemSubscription, IBaseModifySubscriptionItemComponent } from './common/base-modify-marketplace-item-subscription.component';

@Component({
	selector: 'add-subscription-item-dialog',
	templateUrl: './add-subscription-item-dialog.component.html',
	styleUrls: ['./add-subscription-item-dialog.component.scss']
})
export class AddSubscriptionItemDialogComponent extends BaseModifyMarketplaceItemSubscription implements OnInit {
	numberOfActiveUsers: number;
	numberOfLicensedUsers: number;
	overlappingModules: OverlappingModuleDto[] = [];
	finishStageMessages: string[] = [];
	upcomingInvoice: UpcomingInvoiceDto;
	recurringInvoice: RecurringInvoiceDto;
	interval: string;

	get isProceedButtonDisabled() {
		return (
			!!this.isLoading ||
			(!!this.isReviewStage && !!this.hasProductTerms && !this.configurePackageForm.controls.termsAccepted.value)
		);
	}

	get nextButtonText() {
		if (this.isLoading) {
			return 'Loading...';
		} else if (this.isFinishStage) {
			return 'Ok';
		} else if (this.isReviewStage) {
			if (this.isTrial) return 'Start Trial';
			return 'Subscribe';
		}

		return 'Next';
	}

	get hasPriceId() {
		return !!this.data.priceId;
	}

	get overlappingFeaturesWarningHtml(): string {
		const overlappingFeatures = uniq(this.overlappingModules.map(x => x.overlappingFeatures.toString()));
		const overlappingFeaturesString = overlappingFeatures.join(', ');
		const overlappingModulesNameString = this.overlappingModules
			.map(x => {
				if (x.type === 'Subscription') return x.name + ' Subscription';
				else return x.name;
			})
			.join(', ');

		return `<b>${this.data.marketplaceListing.productName}</b> ${
			overlappingFeatures.length > 1
				? ' has the features <b>' + overlappingFeaturesString + '</b>'
				: ' has the <b>' + overlappingFeatures + '</b> feature '
		} on 
		${this.overlappingModules.length > 1 ? 'other products' : 'another product'} <b>${overlappingModulesNameString}</b>
		`;
	}

	private _loadModuleData$ = this._moduleService.findOverlappingFeatures(this.marketplaceListingId).pipe(
		map(modules => modules.filter(x => x.tenantHasActiveSubscription === true)),
		tap(filteredModules => (this.overlappingModules = filteredModules))
	);

	private _loadStripeData$ =
		// Fetch the upcoming invoice and user counts
		forkJoin([
			this._stripeService.previewSubscriptionChange(this.data.priceId, this.data.quantity),
			this._usersService.getUserList(new ListRequest())
		]).pipe(
			tap(([preview, userList]) => {
				this.upcomingInvoice = preview?.upcomingCosts;
				this.recurringInvoice = preview?.recurringCosts;
				this.numberOfLicensedUsers = userList.records.filter(x => x.licenseType === 'Licensed')?.length;
				this.numberOfActiveUsers = userList.records.filter(
					x => !x.disabled && x.licenseType !== 'Free' && !isEmptyOrNil(x.primaryRegisteredEmail)
				)?.length;
			})
		);

	private _loadPackageData$ = forkJoin([
		this._practiceAreaService.getPracticeAreaList({
			sortBy: 'name',
			sortDirection: EnumSortDirection.Asc
		}),
		this._marketplaceService.getDetailsForInstallPackage({
			productId: this.marketplaceListing.id,
			packageVersion: this.marketplaceListing.packageDetails.version,
			isTrial: this.isTrial
		})
	]).pipe(
		tap(([practiceAreas, packageInstallDetails]) => {
			if (packageInstallDetails.practiceAreas.length > 0) {
				packageInstallDetails.practiceAreas.forEach(practiceArea => {
					const practiceAreaMappingId =
						this.configurePackageOperationType !== ConfigurePackageOperationType.Install &&
						!!packageInstallDetails.practiceAreaMappings
							? packageInstallDetails.practiceAreaMappings[practiceArea.id] ?? null
							: null;

					const group = this._fb.group({
						practiceAreaId: practiceArea.id,
						practiceAreaMappingId: [practiceAreaMappingId]
					});

					(this.configurePackageForm.get('practiceAreaMappings') as unknown as FormArray).push(group);
				});
			}

			if (this.configurePackageOperationType === ConfigurePackageOperationType.Configure) {
				this.configurePackageForm.get('mapPracticeAreas').setValue(true);
			}

			this.configurePackageData = {
				practiceAreas: practiceAreas?.records,
				installPackageDetails: packageInstallDetails
			};
		})
	);

	// Configure Package Settings
	@ViewChild('configurePackageRef') configRef: ConfigurePackageComponent;
	configurePackageOperationType = ConfigurePackageOperationType.Install; // Adding an item
	configurePackageForm: FormGroupTyped<IPackageConfiguration> = null;
	configurePackageData: IPackageConfigurationData;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: IAddSubscriptionItemDialogData,
		private _stripeService: StripeService,
		private _usersService: UsersService,
		private _dialogRef: MatDialogRef<AddSubscriptionItemDialogComponent, boolean>,
		private _moduleService: ModuleService,
		private _fb: FormBuilder,
		private _marketplaceService: MarketplaceService,
		private _practiceAreaService: PracticeAreasService,
		private _notifService: NotificationService,
		private _settingsService: GeneralSettingsService,
		private _router: Router
	) {
		super(data);
	}

	ngOnInit(): void {
		if (this.isPackage) {
			this.configurePackageForm = this._fb.group({
				practiceAreaMappings: this._fb.array([]),
				practiceAreaIds: [null, CustomValidators.required()],
				termsAccepted: !this.hasProductTerms ? false : [false, CustomValidators.isTrue('Terms and Conditions')],
				mapPracticeAreas: false
			}) as FormGroupTyped<IPackageConfiguration>;

			this.StartLoadingState('Loading Package Data...', true);

			this.subscriptions.add(
				this._loadPackageData$.subscribe({
					next: () => {
						this.StopLoadingState();
						this.startPackageConfigureStage();
					},
					error: (errors: DomainError[]) => {
						this.handleDomainError(errors);
					}
				})
			);
		} else if (this.isModule) {
			if (!this.hasPriceId) {
				this._notifService.showError('Error', 'Module pricing required');
				this._dialogRef.close(false);
			} else if (!this.isTenantSubscriptionActive) {
				this.showSubscriptionRequiredMessageBox();
			} else {
				this.startReviewStage();
				this.StartLoadingState('Preparing Invoice Preview...', true);

				this.subscriptions.add(
					forkJoin([this._loadStripeData$, this._loadModuleData$]).subscribe({
						next: () => this.StopLoadingState(),
						error: (errors: DomainError[]) => {
							this.handleDomainError(errors);
						}
					})
				);
			}
		}

		this.subscriptions.add(
			this._settingsService.getLicencingInfoDetailed().subscribe(info => {
				if (!!info.stripeInfo?.recurringInterval) {
					this.interval = info.stripeInfo.recurringInterval;
				}
			})
		);
	}

	onProceedButtonClick(): void {
		if (this.isConfigureStage) {
			this.startReviewStage(!this.isFree);
		} else if (this.isReviewStage) {
			this.installPackageAndSubscribe();
		} else if (this.isFinishStage) {
			this._dialogRef.close(true);
		}
	}

	private getPracticeAreaMappings() {
		const controls = (this.configurePackageForm.controls.practiceAreaMappings as unknown as FormArray)
			.controls as FormGroup[];
		const practiceAreaMappings: { [key: string]: string[] } = {};
		controls.forEach(control => {
			practiceAreaMappings[control.get('practiceAreaId').value] = control.get('practiceAreaMappingId').value;
		});

		return practiceAreaMappings;
	}

	private startPackageConfigureStage(): void {
		this.currentDialogStage = AddSubscriptionDialogStage.PackageConfigureStage;
	}

	private startReviewStage(loadStripeData?: boolean) {
		this.currentDialogStage = AddSubscriptionDialogStage.ReviewSubscriptionStage;

		if (!this.isTrial && loadStripeData) {
			if (!this.isTenantSubscriptionActive) {
				this.showSubscriptionRequiredMessageBox();
			} else {
				this.StartLoadingState('Preparing Invoice Preview...', true);
				this.subscriptions.add(
					this._loadStripeData$.subscribe({
						next: () => this.StopLoadingState(),
						error: (errors: DomainError[]) => {
							this.handleDomainError(errors);
						}
					})
				);
			}
		}
	}

	private showSubscriptionRequiredMessageBox() {
		const actionButtonText = this.isSubscriptionCancelled
			? 'Renew ' + this.siteName + ' subscription'
			: 'Subscribe to ' + this.siteName;

		this._dialogRef.addPanelClass('mat-dialog-hide');
		this.subscriptions.add(
			this._notifService
				.showCallToAction(
					'Subscription Required',
					`<p>An active subscription to <b>${this.siteName}</b> is required.</p>`,
					actionButtonText,
					'Cancel'
				)
				.subscribe((isConfirmed: boolean) => {
					if (isConfirmed) {
						if (this.isSubscriptionCancelled) {
							this.goToCustomerPortal();
						} else {
							this._router.navigate(['system', 'subscription']);
						}
					} else {
						this._dialogRef.close(false);
					}
				})
		);
	}

	private installPackageAndSubscribe(): void {
		let serverCall$: Observable<any>;

		const addSubscription$ = this._stripeService.changeSubscription(this.data.priceId, this.data.quantity).pipe(
			tap(() => {
				this.setFinishStage('Subscription has been updated.');
			})
		);

		if (this.isPackage) {
			const installPackage$ = this._marketplaceService
				.installPackage({
					productId: this.marketplaceListing.id,
					packageVersion: this.marketplaceListing.packageDetails.version,
					practiceAreaIds: this.configurePackageForm.controls.practiceAreaIds.value as string[],
					practiceAreaMappings: this.getPracticeAreaMappings(),
					isTrial: this.isTrial
				})
				.pipe(
					tap(() => {
						this.setFinishStage('<b>' + this.productName + '</b> is now installed.');
					})
				);

			serverCall$ = installPackage$;
			this.StartLoadingState('Installing package...');
			if (!this.isTrial && !this.isFree) {
				serverCall$ = installPackage$.pipe(
					switchMap(() => {
						this.StartLoadingState('Processing Payment...');
						return addSubscription$;
					})
				);
			}
		} else {
			this.StartLoadingState('Processing Payment...');
			serverCall$ = addSubscription$;
		}

		this.subscriptions.add(
			serverCall$.subscribe({
				error: (errors: DomainError[]) => {
					this.handleDomainError(errors);
				}
			})
		);
	}

	private goToCustomerPortal() {
		this.subscriptions.add(
			this._stripeService.customerPortal({ returnURL: '/system/subscription' }).subscribe(customerPortalURL => {
				window.location.href = customerPortalURL;
			})
		);
	}

	setFinishStage(message: string) {
		this.StopLoadingState();
		this.finishStageMessages.push(message);
		this.currentDialogStage = AddSubscriptionDialogStage.FinishStage;
	}

	handleDomainError(errors: DomainError[]) {
		this.StopLoadingState();
		this._dialogRef.addPanelClass('mat-dialog-hide');

		this.subscriptions.add(this._notifService.showCriticalErrors(errors).subscribe(() => this._dialogRef.close()));
	}

	currentDialogStage = AddSubscriptionDialogStage.None;
	get isConfigureStage() {
		return this.currentDialogStage == AddSubscriptionDialogStage.PackageConfigureStage;
	}
	get isReviewStage() {
		return this.currentDialogStage == AddSubscriptionDialogStage.ReviewSubscriptionStage;
	}
	get isFinishStage() {
		return this.currentDialogStage == AddSubscriptionDialogStage.FinishStage;
	}
}

enum AddSubscriptionDialogStage {
	None,
	PackageConfigureStage,
	ReviewSubscriptionStage,
	FinishStage
}

export interface IAddSubscriptionItemDialogData extends IBaseModifySubscriptionItemComponent {
	priceId?: string;
	quantity?: number;
	billingCycle?: string;
}
