<h2 mat-dialog-title>
	<span>Export Brief To PDF</span>
</h2>

<mat-dialog-content [formGroup]="form">
	<mat-form-field>
		<input formControlName="documentTitle" matInput autocomplete="off" />
		<mat-placeholder>
			Document Title
			<sup class="color-warn">*</sup>
		</mat-placeholder>
		<mat-hint>Name of the output file</mat-hint>
		<mat-error>
			<error-messages [for]="form.get('documentTitle')"></error-messages>
		</mat-error>
	</mat-form-field>

	<div class="checkbox-field" formGroupName="sectionOptions">
		<mat-checkbox #selectedSectionsOnly formControlName="selectedSectionsOnly">Only Selected Sections</mat-checkbox>
		<mat-hint class="check-hint">Export only specific sections in the brief</mat-hint>

		<mat-selection-list *ngIf="selectedSectionsOnly.checked" formControlName="sectionIds">
			<mat-list-option [value]="section.id" *ngFor="let section of data?.parameters?.sections" checkboxPosition="before" class="selected-section-name">
				{{ section.name }}
			</mat-list-option>
		</mat-selection-list>
		<mat-error class="indented">
			<error-messages [for]="sectionIdsControl"></error-messages>
		</mat-error>
	</div>

	<div class="checkbox-field titlePageOptionsSection" formGroupName="titlePageOptions">
		<mat-checkbox #includeTitlePageCheck formControlName="includeTitlePage">Title Page</mat-checkbox>
		<mat-hint class="check-hint">Adds a front page to the document based on a predefined template</mat-hint>
		<div class="titlePageOptionsSettings" *ngIf="includeTitlePageCheck.checked">
			<document-template-lookup
				Placeholder="Template"
				[Required]="true"
				[FormControl]="form.controls.titlePageOptions.get('templateId')"
				[HasAutofocus]="true"
				entityType="BriefTitlePage"
				[autoSelectIfOnlyOne]="true"
			>
			</document-template-lookup>
		</div>
	</div>

	<div class="checkbox-field" formGroupName="contentsPageOptions">
		<mat-checkbox #includeContentsPageCheck formControlName="includeContentsPage">Contents Page</mat-checkbox>
		<mat-hint class="check-hint">Adds a contents page at the beginning of the document</mat-hint>
		<div class="checkbox-field indented" *ngIf="includeContentsPageCheck.checked">
			<mat-checkbox formControlName="hidePageNumbering" [disabled]="!form.controls.pageNumberOptions?.value?.includePageNumber"
				>Hide Page Numbering</mat-checkbox
			>
			<mat-hint class="check-hint">Hide page numbering on contents page</mat-hint>
		</div>
	</div>

	<div class="checkbox-field">
		<mat-checkbox formControlName="sectionTitlePage">Section Title Page</mat-checkbox>
		<mat-hint class="check-hint">Adds a title page before each section</mat-hint>
	</div>

	<div class="flex-row">
		<div class="checkbox-field">
			<mat-checkbox formControlName="documentSummaryPage">Document Summary Pages</mat-checkbox>
			<mat-hint class="check-hint">Adds a summary description page before each document</mat-hint>
		</div>
		<div class="checkbox-field">
			<mat-checkbox formControlName="includeEmailAttachments">Include Email Attachments</mat-checkbox>
			<mat-hint class="check-hint">Includes email attachments as pdf attachments and thier own page under the parent email</mat-hint>
		</div>
	</div>

	<div class="checkbox-field pageOptionsSection" formGroupName="pageNumberOptions">
		<mat-checkbox #includePage formControlName="includePageNumber">Page Numbering</mat-checkbox>
		<mat-hint class="check-hint">Adds a page number to each page</mat-hint>
		<div class="pageOptionsSettings" *ngIf="form.controls.pageNumberOptions?.value?.includePageNumber">
			<div class="flex-row">
				<mat-form-field>
					<mat-placeholder>Position</mat-placeholder>
					<mat-select formControlName="pagePosition">
						<mat-option *ngFor="let key of pagePositionKeys" [value]="key">
							<span>{{ key | pagePosition }}</span>
						</mat-option>
					</mat-select>
				</mat-form-field>

				<mat-form-field>
					<input type="text" formControlName="pageNumberFormat" matInput autocomplete="off" />
					<mat-placeholder>Format</mat-placeholder>
					<mat-hint>Use <b>[page]</b> for page number <b>[total]</b> for total page count</mat-hint>
					<mat-error>
						<error-messages [for]="form.get('pageNumberFormat')"></error-messages>
					</mat-error>
				</mat-form-field>
			</div>
			<div class="flex-row">
				<mat-form-field>
					<input type="number" formControlName="horizontalOffset" matInput autocomplete="off" />
					<mat-placeholder>Horizontal Offset</mat-placeholder>
					<mat-hint>Offset from the edge of the page</mat-hint>
					<mat-error>
						<error-messages [for]="form.get('horizontalOffset')"></error-messages>
					</mat-error>
				</mat-form-field>
				<mat-form-field>
					<input type="number" formControlName="verticalOffset" matInput autocomplete="off" />
					<mat-placeholder>Vertical Offset</mat-placeholder>
					<mat-hint>Offset from the edge of the page</mat-hint>
					<mat-error>
						<error-messages [for]="form.get('verticalOffset')"></error-messages>
					</mat-error>
				</mat-form-field>
			</div>

			<div class="flex-row font-lookup-options">
				<font-lookup Placeholder="Font Name" [FormControl]="form.controls.pageNumberOptions.get('fontName')" [HasAutofocus]="true"></font-lookup>
				<font-option [FormGroup]="fontOptionsGroup"></font-option>
			</div>
		</div>
	</div>
	<div class="checkbox-field securityOptionsSection" formGroupName="securityOptions">
		<mat-checkbox #includePage formControlName="includeSecurityOptions">Security Options</mat-checkbox>
		<mat-hint class="check-hint">Protect PDF with a password or disable modification</mat-hint>
		<div class="securityOptionsSettings" *ngIf="form.controls.securityOptions.value?.includeSecurityOptions">
			<div class="flex-row">
				<div class="column">
					<mat-checkbox formControlName="passwordProtect">Protect using Password</mat-checkbox>
					<mat-hint class="check-hint">Protect the document with a password </mat-hint>
				</div>
				<mat-form-field *ngIf="form.controls.securityOptions.value?.passwordProtect">
					<input formControlName="userPassword" matInput autocomplete="off" />
					<mat-placeholder
						>Enter Password
						<sup class="color-warn">*</sup>
					</mat-placeholder>
					<mat-hint>The password required to view & open the document</mat-hint>
					<mat-error>
						<error-messages [for]="form.get('userPassword')"></error-messages>
					</mat-error>
				</mat-form-field>
			</div>
			<div class="flex-row">
				<div class="column">
					<mat-checkbox formControlName="preventModification">Prevent Modification</mat-checkbox>
					<mat-hint class="check-hint">Prevent editing of the document </mat-hint>
				</div>
				<mat-form-field *ngIf="form.controls.securityOptions.value?.preventModification">
					<input formControlName="ownerPassword" matInput autocomplete="off" />
					<mat-placeholder
						>Enter Password
						<sup class="color-warn">*</sup>
					</mat-placeholder>
					<mat-hint>The password required to edit the document</mat-hint>
					<mat-error>
						<error-messages [for]="form.get('ownerPassword')"></error-messages>
					</mat-error>
				</mat-form-field>
			</div>
		</div>
	</div>
</mat-dialog-content>

<mat-dialog-actions>
	<button mat-raised-button [disabled]="form.invalid" color="primary" throttleButton (throttledClick)="exportPdf()">Export</button>
	<button mat-raised-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
