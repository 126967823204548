// This file has been generated from DocumentBriefExportOptionsDto.cs
// Do not modify this file directly!!
// tslint:disable

import { SetComplexType, SetSimpleType } from '@common/utils/class-transform';

import { ContentsPageOptions } from '../../../DocumentBriefs/Item/ContentsPageOptions';
import { PageNumberOptions } from '../../../DocumentBriefs/Item/PageNumberOptions';
import { SecurityOptions } from '../../../DocumentBriefs/Item/SecurityOptions';
import { TitlePageOptions } from '../../../DocumentBriefs/Item/TitlePageOptions';

// LawMaster.Amalthea.Domain.Settings.Setting.Item.DocumentBriefExportOptionsDto
export class DocumentBriefExportOptionsDto {

	// PageNumberOptions
	@SetComplexType(PageNumberOptions)
	pageNumberOptions: PageNumberOptions;
	// SecurityOptions
	@SetComplexType(SecurityOptions)
	securityOptions: SecurityOptions;
	// TitlePageOptions
	@SetComplexType(TitlePageOptions)
	titlePageOptions: TitlePageOptions;
	// ContentsPageOptions
	@SetComplexType(ContentsPageOptions)
	contentsPageOptions: ContentsPageOptions;
	// DocumentSummaryPage
	@SetSimpleType(Boolean)
	documentSummaryPage: boolean;
	// SectionTitlePage
	@SetSimpleType(Boolean)
	sectionTitlePage: boolean;
	// IncludeEmailAttachments
	@SetSimpleType(Boolean)
	includeEmailAttachments: boolean;

	constructor() {
		this.pageNumberOptions = new PageNumberOptions();
		this.securityOptions = new SecurityOptions();
		this.titlePageOptions = new TitlePageOptions();
		this.contentsPageOptions = new ContentsPageOptions();
	}
}