import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { filter, switchMap } from 'rxjs/operators';

import { CostTemplateListItemDto } from '@common/models/Settings/CostTemplates/List/CostTemplateListItemDto';
import { NotificationService } from '@common/notification';
import { CostTemplatesService } from '@common/services/settings/costtemplates.service';
import { Store } from '@ngrx/store';

import { IAppState } from 'app/core/state/app.state';
import { CostTemplateListActions } from 'app/core/state/lists/settings/cost-template-list/cost-template-list.actions';
import {
	selectCostTemplateIsFetching,
	selectCostTemplateListRecords,
	selectCostTemplateListRequest,
	selectCostTemplateListTotalRecords
} from 'app/core/state/lists/settings/cost-template-list/cost-template-list.selectors';
import { GridViewService } from 'app/services/grid-view.service';
import {
	ActionTypes,
	GenericListStateComponent,
	SelectorTypes
} from 'app/shared/generics/generic.list.state.component';

import { CostTemplateItemComponent } from '../item/cost-template-item.component';
import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';

@Component({
	styleUrls: ['./cost-template-list.component.scss'],
	templateUrl: './cost-template-list.component.html'
})
export class CostTemplateListComponent
	extends GenericListStateComponent<CostTemplateListItemDto, void>
	implements AfterViewInit
{
	get actions(): ActionTypes {
		return {
			init: CostTemplateListActions.Init,
			load: CostTemplateListActions.Load,
			setFilters: CostTemplateListActions.SetFilters,
			setPageIndex: CostTemplateListActions.SetPageIndex,
			setPageIndexForId: CostTemplateListActions.SetPageIndexForId,
			setPageSize: CostTemplateListActions.SetPageSize,
			setSortBy: CostTemplateListActions.SetSortBy,
			setSortDirection: CostTemplateListActions.SetSortDirection,
			selected: CostTemplateListActions.SelectRecords
		};
	}
	get selectors(): SelectorTypes {
		return {
			records: selectCostTemplateListRecords,
			isFetching: selectCostTemplateIsFetching,
			request: selectCostTemplateListRequest,
			totalRecords: selectCostTemplateListTotalRecords
		};
	}
	dialogCfg: MatDialogConfig = {};
	constructor(
		store: Store<IAppState>,
		dialog: MatDialog,
		router: Router,
		activatedRoute: ActivatedRoute,
		private costTemplatesService: CostTemplatesService,
		private notificationService: NotificationService,
		private breakpoint: BreakpointObserver,
		private cdr: ChangeDetectorRef,
		gridViewService: GridViewService
	) {
		super(
			['name', 'description', 'userTypeRates', 'actions'],
			dialog,
			store,
			router,
			activatedRoute,
			gridViewService
		);
	}

	ngAfterViewInit() {
		this.subscriptions.add(
			this.breakpoint.observe([Breakpoints.Handset]).subscribe(result => {
				const isMobile = result.matches;
				if (isMobile) {
					this.dialogCfg = {
						height: '100%',
						maxHeight: '100vh',
						maxWidth: '100vw',
						width: '100%'
					};
				}

				this.cdr.detectChanges();
			})
		);
		super.ngAfterViewInit();
	}

	createCostTemplate() {
		this.dialogCfg.data = '';
		this.subscriptions.add(
			this.dialog
				.open(CostTemplateItemComponent, this.dialogCfg)
				.afterClosed()
				.pipe(filter(Boolean))
				.subscribe((response: MutationResponseDto) =>
					this.store.dispatch({ type: CostTemplateListActions.InsertRecords, response: response })
				)
		);
	}

	editCostTemplate(row: CostTemplateListItemDto) {
		this.dialogCfg.data = row.id;
		this.subscriptions.add(
			this.dialog
				.open(CostTemplateItemComponent, this.dialogCfg)
				.afterClosed()
				.pipe(filter(Boolean))
				.subscribe((response: MutationResponseDto) =>
					this.store.dispatch({ type: CostTemplateListActions.UpdateRecords, response: response })
				)
		);
	}

	deleteCostTemplate(row: CostTemplateListItemDto) {
		this.subscriptions.add(
			this.notificationService
				.showConfirmation(
					'Delete Cost Template',
					`Are you sure you want to delete the cost Template "${row.name}"?`
				)
				.pipe(
					filter(Boolean),
					switchMap(() => this.costTemplatesService.deleteCostTemplate(row.id))
				)
				.subscribe({
					next: response => {
						this.notificationService.showNotification(`Cost Template deleted: ${response.name}`);
						this.store.dispatch({ type: CostTemplateListActions.RemoveRecords, response: response });
					},
					error: error => this.notificationService.showErrors('Error deleting Cost Template', error)
				})
		);
	}
}
