import { PracticeAreaStageListItemDto } from '@common/models/Settings/PracticeAreas/List/PracticeAreaStageListItemDto';
import { PracticeAreaStageListRequest } from '@common/models/Settings/PracticeAreas/List/PracticeAreaStageListRequest';
import { DomainError } from '@common/models/Validation/DomainError';
import { Moment } from 'moment';

export interface IPracticeAreaStagesState {
	isFetching: boolean;
	list: PracticeAreaStageListItemDto[];

	request: Partial<PracticeAreaStageListRequest>;
	oldRequest: Partial<PracticeAreaStageListRequest>;

	cacheExpiry: Moment;
	// errrors
	error: DomainError;
}

export const initialState: IPracticeAreaStagesState = {
	isFetching: false,
	list: null,
	request: {},
	oldRequest: null,
	error: null,
	cacheExpiry: null
};
