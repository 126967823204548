import { NotificationService } from '@common/notification';
import { uniq } from 'lodash';
import * as PizZip from 'pizzip';
import { from, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { FileInfo } from '../multiple-file-uploader';

const zipFileLimitMb = 50;
const zipFileLimit = zipFileLimitMb * 1024 * 1024;

export function getZipLimit() {
	return `${zipFileLimitMb} Megabytes`;
}

export function isZipFile(fileInfo: FileInfo) {
	return fileInfo.file.name.endsWith('.zip');
}

export function hasZipFiles(list: FileInfo[]) {
	return !!filterZipFiles(list)?.length;
}

export function filterZipFiles(list: FileInfo[]) {
	return list?.filter(fileInfo => isZipFile(fileInfo));
}

export function isUnderZipFileLimit(fileInfo: FileInfo) {
	return zipFileLimit >= fileInfo?.file?.size ?? 0;
}

export async function extractZipFile(fileInfo: FileInfo): Promise<FileInfo[]> {
	const data = await fileInfo.file.arrayBuffer();

	const archive = new PizZip(data);
	let archiveTags = fileInfo.tags ?? [];

	if (!fileInfo.isRoot) {
		archiveTags = archiveTags.concat(fileInfo.file.name.slice(0, fileInfo.file.name.length - 4));
	}

	const files = Object.keys(archive.files)
		.map(key => archive.files[key])
		.filter(archiveFile => !archiveFile.dir && !archiveFile.name.startsWith('__MACOSX/'))
		.map(archiveFile => {
			const sections = archiveFile.name?.split('/');
			const tags: string[] = !!sections && sections.length >= 2 ? sections.slice(0, sections.length - 1) : [];
			return {
				file: new File([archiveFile.asArrayBuffer()], sections[sections.length - 1]),
				tags: uniq(archiveTags.concat(tags))
			};
		});

	return files.filter(fileInfo => fileInfo.file.size);
}

export function processFilesForUpload$(
	fileList: FileInfo[],
	notificationService: NotificationService
): Observable<FileInfo[]> {
	if (!!fileList?.length) {
		if (!!hasZipFiles(fileList) && fileList.length === 1) {
			return !!isUnderZipFileLimit(fileList[0])
				? notificationService
						.showConfirmation('Extract Zip', 'Do you wish to extract the contents of the zip archive?')
						.pipe(
							switchMap(flag => {
								return !!flag ? from(extractZipFile(fileList[0])) : of(fileList);
							}),
							tap(files => {
								if (!files?.length) {
									notificationService.showError(
										'Failed to Extract Zip',
										'Cannot extract an empty zip'
									);
								}
							}),
							filter(files => !!files?.length)
						)
				: notificationService
						.showConfirmation(
							'Upload Zip',
							`Cannot extract the contents of a zip archive if its file size exceeds ${getZipLimit()}, do you wish to upload the archive?`
						)
						.pipe(
							filter(Boolean),
							map(() => fileList)
						);
		} else {
			return of(fileList);
		}
	} else {
		notificationService.showNotification(
			'Cannot upload empty files, folders or files/folders from within a zip file'
		);
	}

	return of(null).pipe(filter(Boolean)) as Observable<FileInfo[]>;
}

export function fileListToFileInfo(list: FileList): FileInfo[] {
	if (!list?.length) {
		return [];
	}

	const fileInfos: FileInfo[] = [];
	for (let index = 0; index < list.length; index++) {
		const file = list[index];

		if (!!file?.size) {
			const sections = (file as any).webkitRelativePath?.split('/');
			const tags: string[] =
				!!sections && sections.length > 2 ? uniq(sections.slice(1, sections.length - 1)) : [];

			fileInfos.push({ file: list[index], tags: tags, isRoot: sections.length <= 1 });
		}
	}

	return fileInfos;
}

function dataTransferToArray(dataTransfer: DataTransfer): DataTransferItem[] {
	const items = dataTransfer?.items;

	if (!items?.length) {
		return [];
	}

	const results = [];
	for (let index = 0; index < items.length; index++) {
		results.push(items[index]);
	}

	return results;
}

export async function getFilesFromEvent(event: DragEvent): Promise<FileInfo[]> {
	try {
		return await dataTransferToArray(event.dataTransfer)
			.map(item => item.webkitGetAsEntry())
			.map(entry => parseFileSystemEntry(entry))
			.reduce((accumlator, next) => {
				return accumlator.then(async value => {
					return value.concat(...(await next));
				});
			});
	} catch {
		// Fallback for browser that don't support the webkit api
		return fileListToFileInfo(event.dataTransfer.files);
	}
}

export async function parseFileSystemEntry(entry: FileSystemEntry, depth: number = 0): Promise<FileInfo[]> {
	if (entry.isFile) {
		const file = await parseFileEntry(entry as FileSystemFileEntry);
		return !!file?.size ? [{ file: file, isRoot: depth == 0 }] : [];
	} else if (entry.isDirectory) {
		const entries = await parseDirectoryEntry(entry as FileSystemDirectoryEntry);

		const files: FileInfo[] = [];

		if (!!entries?.length) {
			for (let index = 0; index < entries.length; index++) {
				const nestedEntry = entries[index];

				const nestedFiles = await parseFileSystemEntry(nestedEntry, depth + 1);

				if (!!nestedFiles?.length) {
					files.push(
						...nestedFiles.map(fileInfo => {
							const tags = fileInfo.tags ?? [];

							if (depth > 0) {
								return {
									file: fileInfo.file,
									tags: uniq(tags.concat((entry as FileSystemDirectoryEntry).name)),
									isRoot: fileInfo.isRoot
								};
							} else {
								return fileInfo;
							}
						})
					);
				}
			}
		}

		return files;
	}

	return [];
}

export function parseFileEntry(entry: FileSystemFileEntry): Promise<File> {
	return new Promise<File>((resolve, reject) => {
		entry.file(
			file => {
				resolve(file);
			},
			err => {
				reject(err);
			}
		);
	});
}

export function parseDirectoryEntry(entry: FileSystemDirectoryEntry): Promise<FileSystemEntry[]> {
	const directoryReader = entry.createReader();
	return new Promise<FileSystemEntry[]>((resolve, reject) => {
		directoryReader.readEntries(
			entries => {
				resolve(entries);
			},
			err => {
				reject(err);
			}
		);
	});
}
