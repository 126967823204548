import { DocumentListItemDto } from '@common/models/Documents/List/DocumentListItemDto';
import { DocumentListRequest } from '@common/models/Documents/List/DocumentListRequest';
import { DocumentListResponse } from '@common/models/Documents/List/DocumentListResponse';
import { DomainError } from '@common/models/Validation/DomainError';

import { AppConfig } from 'app/app.config';

export interface IDocumentListState {
	isFetching: boolean;
	list: DocumentListResponse<DocumentListItemDto>;
	lastSelectedRecordId: string;
	request: Partial<DocumentListRequest & { serviceType: keyof typeof DocumentServiceType }>;
	oldRequest: Partial<DocumentListRequest & { serviceType: keyof typeof DocumentServiceType }>;

	// errrors
	error: DomainError;
}

export const initialState: IDocumentListState = {
	isFetching: false,
	list: null,
	lastSelectedRecordId: null,
	request: {
		pageSize: AppConfig.PageSize,
		pageIndex: 0,
		pageIndexForId: null,

		sortBy: null,
		sortDirection: null
	},
	oldRequest: null,
	error: null
};

export enum DocumentServiceType {
	Document = 'Document',
	Template = 'Template',
	HeaderFooterTemplate = 'HeaderFooterTemplate',
	EmailTemplate = 'EmailTemplate',
	Report = 'Report'
}
