import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { Subscription } from 'rxjs';
import { delay, startWith } from 'rxjs/operators';

import { FileExtensions, IFileExtension } from '@common/utils/file-extensions';
import { nameof } from '@common/utils/nameof';
import { flatMap, get, isEqual, orderBy } from 'lodash-es';

import { FilterBaseComponent } from './filter-base.component';
import { FilterButtonComponent } from './filter-button.component';
import { FilterChangeProperties } from './filter-change-properties';

@Component({
	selector: 'filter-file-extension-button',
	styleUrls: ['./filter-base.component.scss'],
	template: `
		<filter-button [root]="root" [name]="name" [label]="label" [showTitleAsPrefix]="showTitleAsPrefix">
			<mat-nav-list>
				<mat-list-item *ngFor="let option of options" throttleButton (throttledClick)="select(option)">
					<mat-icon [ngClass]="option.icon" [svgIcon]="option.icon"></mat-icon>
					<div>{{ option.label }}</div>
				</mat-list-item>
			</mat-nav-list>
			<div class="action-buttons"><span throttleButton (throttledClick)="clear()">Clear</span></div>
		</filter-button>
	`
})
export class FilterFileExtensionButtonComponent extends FilterBaseComponent implements AfterViewInit {
	@ViewChild(FilterButtonComponent, { static: true })
	button: FilterButtonComponent;
	@Input()
	nameExcluded: string;

	options: IFileExtension[] = orderBy(
		FileExtensions.filter(item => item.showInFilter),
		['label']
	);

	private excludeOption: IFileExtension;
	private subscriptions: Subscription = new Subscription();

	private get excludeControl(): AbstractControl {
		return this.root.form.get(this.nameExcluded);
	}

	syncSelection(filterValue: any) {
		const included = get(filterValue, this.name);
		const excluded = get(filterValue, this.nameExcluded);
		const option = this.options.find(
			item => isEqual(item.extensions, included) || isEqual(item.extensions, excluded)
		);
		this.button.selectedFilterTitle = get(option, 'label');
		this.button.IsSelected = !!option;
	}

	ngAfterViewInit() {
		this.subscriptions.add(
			this.root.filterChange
				.pipe(
					// Fire the 1st event with the current value
					startWith(new FilterChangeProperties<any>(this.root.filter)),
					delay(0)
				) // Otherwise get an error on changing the state after it's been checked
				.subscribe(val => this.syncSelection(val.filter))
		);
		if (this.nameExcluded) {
			this.excludeOption = {
				extensions: flatMap(FileExtensions.filter(item => item.showInFilter).map(i => i.extensions)),
				icon: 'file',
				label: 'Other'
			};
			this.options.push(this.excludeOption);
		}
	}

	select(option?: IFileExtension) {
		if (option === this.excludeOption) {
			this.control.setValue(null);
			this.excludeControl.setValue(option.extensions);
		} else {
			this.control.setValue(get(option, nameof<IFileExtension>('extensions')));
			if (this.excludeControl) {
				this.excludeControl.setValue(null);
			}
		}
		this.button.isMenuOpen = false;
		this.root.applyFilter();
	}

	clear() {
		this.select(null);
	}
}
