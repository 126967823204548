// This file has been generated from CreateCheckoutSessionDto.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.AppServer.Infrastructure.StripeIntegration.Models.CreateCheckoutSessionDto
export class CreateCheckoutSessionDto {

	// NumberOfUsers
	numberOfUsers: number;
	// PriceId
	priceId: string;
	// ProductId
	productId: string;
	// Promocode
	promocode: string;
	// Name
	name: string;
	// Email
	email: string;
	// Abn
	abn: string;
	// SuccessURL
	successURL: string;
	// CancelURL
	cancelURL: string;

	constructor() {

	}
}