import { RecurringInterval } from '@common/models/Common/RecurringInterval';
import { MarketplaceListingPricingInfoDto } from '@common/models/Infrastructure/StripeIntegration/Models/MarketplaceListingPricingInfoDto';
import { PricedMarketplaceListingListItemDto } from '@common/models/Infrastructure/StripeIntegration/Models/PricedMarketplaceListingListItemDto';
import { PricedMarketplaceListingViewDto } from '@common/models/Infrastructure/StripeIntegration/Models/PricedMarketplaceListingViewDto';
import { isEmptyOrNil } from '@common/utils/stringUtils';

export class Pricing {
	isFree: boolean;
	primary: MarketplaceListingPricingInfoDto;
	secondary: MarketplaceListingPricingInfoDto;
}

export function ResolvePricing(
	row: PricedMarketplaceListingListItemDto | PricedMarketplaceListingViewDto,
	primaryInterval?: RecurringInterval
): Pricing {
	const isFree = row?.packageDetails?.pricingModel === 'Free';

	if (isFree) {
		return {
			isFree: true,
			primary: null,
			secondary: null
		};
	} else if (primaryInterval === 'year') {
		return {
			isFree: false,
			primary: row.yearlyPricing,
			secondary: row.monthlyPricing
		};
	} else {
		return {
			isFree: false,
			primary: row.monthlyPricing,
			secondary: row.yearlyPricing
		};
	}
}

export function ResolveRecurringInterval(intervalString: string): RecurringInterval {
	if (!isEmptyOrNil(intervalString)) {
		let interval = intervalString.toLowerCase();
		if (interval == 'month' || interval == 'monthly') return RecurringInterval.month;
		else if (interval == 'year' || interval == 'yearly') return RecurringInterval.year;
	}
	return null;
}
