import { Moment } from 'moment-timezone';

export enum UploadStatus {
	Queued,
	Uploading,
	Uploaded,
	Cancelled,
	Completed,
	Error
}

export interface IUploadProgress {
	status: UploadStatus;
	data?: {
		percentage: number;
		speed?: number | null;
		startTime?: number | null;
		timeRemaining?: number | null;
		timeRemainingHuman?: string | null;
	};
}

export interface IUploadFile {
	name: string;
	documentTags:string[];
	size: number;
	type: string;
	progress: IUploadProgress;
	nativeFile?: File;
	errorText: string;
	practiceAreaIds: string;
	categoryId: string;
	lastModified: Moment;
	created: Moment;
	extension: string;
}

export interface IUploadMultipleFiles {
	progress: IUploadProgress;
	nativeFiles?: File[];
	errorText?: string;
}
