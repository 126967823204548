import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { Subscription } from 'rxjs';

import { TaxConfiguration } from '@common/models/Settings/BillSettings/Common/TaxConfiguration';
import { BillSettingDto } from '@common/models/Settings/BillSettings/Item/BillSettingDto';
import { SaveTaxConfigurationDto } from '@common/models/Settings/BillSettings/Item/SaveTaxConfigurationDto';
import { NotificationService } from '@common/notification';
import { BillsService } from '@common/services/settings/bills.service';
import { updateTaxOption } from '@common/state/actions/tenant.actions';
import { ITenantData } from '@common/state/models/tenant-data';
import { Store } from '@ngrx/store';

@Component({
	selector: 'tax-settings',
	styleUrls: ['./tax-settings.scss'],
	templateUrl: './tax-settings.html'
})
export class TaxSettingsComponent implements OnInit, OnDestroy {
	@Input() billSettings: BillSettingDto;

	form: FormGroup;

	private subscriptions = new Subscription();
	taxConfigurationKeys = Object.keys(TaxConfiguration) as (keyof typeof TaxConfiguration)[];
	constructor(
		private store: Store<{ tenantData: ITenantData }>,
		private fb: FormBuilder,
		private billSettingsService: BillsService,
		private notificationService: NotificationService
	) {}

	ngOnInit() {
		this.form = this.fb.group({
			taxSettings: this.billSettings?.taxConfiguration
		});
	}

	save(): void {
		const taxConfig = this.form.controls.taxSettings.value;
		const dto: SaveTaxConfigurationDto = { taxConfiguration: taxConfig };
		this.subscriptions.add(
			this.billSettingsService.saveTaxConfiguration(dto).subscribe({
				next: () => {
					this.notificationService.showNotification('Tax configuration saved successfully');
					this.form.markAsPristine();
					this.store.dispatch(updateTaxOption({ taxConfiguration: dto.taxConfiguration }));
				},
				error: error => this.notificationService.showError('Error saving the Tax configuration', error)
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}
}
