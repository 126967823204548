import { Directive, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ITextUrlBlock, proccessUrlsToBlocks } from '../utils/stringUtil';

import { BaseEditableComponent } from './base.editable.component';

@Directive()
export class BaseEditableTextComponent extends BaseEditableComponent<string> implements OnDestroy {
	private controlSubscription: Subscription;

	@Input()
	set control(value: AbstractControl) {
		if (!!this.controlSubscription) {
			this.controlSubscription.unsubscribe();
			this.controlSubscription = null;
		}

		this._control = value;

		if (!!value) {
			this.controlSubscription = this.createControlSubscription(value);
			this.displayBlocks = proccessUrlsToBlocks(value.value);
		} else {
			this.displayBlocks = [];
		}
	}

	get control(): AbstractControl {
		return this._control;
	}

	displayBlocks: ITextUrlBlock[] = [];

	ngOnDestroy(): void {
		if (!!this.controlSubscription) {
			this.controlSubscription.unsubscribe();
			this.controlSubscription = null;
		}
	}

	createControlSubscription(control: AbstractControl): Subscription {
		return control.valueChanges.subscribe(value => (this.displayBlocks = proccessUrlsToBlocks(value)));
	}
}
