import { Injectable } from '@angular/core';
import * as PizZip from 'pizzip';
import { Observable, Subject } from 'rxjs';
import { FileExtensions } from '../../../../../common/utils/file-extensions';
import { BaseMetadataFile, BaseMetadataService } from './base-metadata.service';

@Injectable({
	providedIn: 'root'
})
export class OfficeXmlMetadataService extends BaseMetadataService<OfficeXmlFile> {
	static readonly TagsKeys: string[] = [
		'cp:keywords',
		'keywords'
	]

	static readonly CreatedKeys: string[] = [
		'dcterms:created xsi:type="dcterms:W3CDTF"',
		'dcterms:created',
		'created xsi:type="dcterms:W3CDTF"',
		'created',
	]

	private static supportedExtensionLabels: string[] = [
		'Word Documents',
		'Excel Spreadsheets',
		'Powerpoint Presentations'
	]

	isSupported(extension: string): boolean {
		return FileExtensions.filter(group =>
			OfficeXmlMetadataService.supportedExtensionLabels.includes(group.label) &&
			group.extensions.includes(extension.toLowerCase())
		).length > 0
	}

	readArrayBuffer(data: ArrayBuffer | Promise<ArrayBuffer>): Observable<OfficeXmlFile> {
		const subject = new Subject<OfficeXmlFile>();
		
		Promise.resolve(data)
			.then(buffer => {
				return subject.next(this._readArrayBuffer(buffer));
			})
			.catch(error => subject.error(error))

		return subject.asObservable();
	}


	private _readArrayBuffer(data: ArrayBuffer): OfficeXmlFile {
		const zip = new PizZip(data);
		const xmlString = zip.files['docProps/core.xml'].asText();
		return new OfficeXmlFile(xmlString);
	}
}

export class OfficeXmlFile extends BaseMetadataFile {
	constructor(private _xmlString: string) {
		super();
	}

	getMetadataValues(keys: string[]): string[] {
		const regexString = this.buildRegexString(keys);
		const replaceStrings = keys.map(key => new RegExp(`<\\/?${key}>`, 'g'));

		return this._xmlString
			.match(regexString)
			?.map(val => {
				replaceStrings.forEach(replaceString => {
					val = val.replace(replaceString, '');
				});

				return val;
			})
	}

	private buildRegexString(keys: string[]): RegExp {
		let openingBrackets: string = `<${keys[0]}>`;
		let closingBrackets: string = `<\\/${keys[0]}>`;

		if (keys.length > 1) {
			keys.slice(1).forEach(key => {
				openingBrackets += `|<${key}>`;
				closingBrackets += `|<\\/${key}>`;
			});
		}

		console.log(new RegExp(`(${openingBrackets})(.*?)(${closingBrackets})`, 'g').source);
		return new RegExp(`(${openingBrackets})(.*?)(${closingBrackets})`, 'g');
	}
}