// This file has been generated from WorkflowDocumentTemplateReferenceVariables.cs
// Do not modify this file directly!!

// LawMaster.Amalthea.Domain.Settings.Setting.Common.WorkflowDocumentTemplateReferenceVariables
export enum WorkflowDocumentTemplateReferenceVariables {
	ClientName = 'Client Name',
	ClientShortName = 'Client Short Name',
	MatterNumber = 'Matter Number',
	MatterTitle = 'Matter Title',
	TemplateName = 'Document Template Name',
	CreatedDate = 'Created Date',
	CreatedMonth = 'Created Month',
	CreatedYear = 'Created Year',
	Hyphen = '—',
	OpenBracket = '(',
	ClosedBracket = ')'
}
