// This file has been generated from PageNumberOptions.cs
// Do not modify this file directly!!
// tslint:disable

import { SetSimpleType } from '@common/utils/class-transform';

import { PagePosition } from './PagePosition';

// LawMaster.Amalthea.Domain.DocumentBriefs.Item.PageNumberOptions
export class PageNumberOptions {

	// FontName
	fontName: string;
	// FontSize
	fontSize: number;
	// IsBold
	@SetSimpleType(Boolean)
	isBold: boolean;
	// IsItalic
	@SetSimpleType(Boolean)
	isItalic: boolean;
	// IsUnderline
	@SetSimpleType(Boolean)
	isUnderline: boolean;
	// IsStrikeout
	@SetSimpleType(Boolean)
	isStrikeout: boolean;
	// IncludePageNumber
	@SetSimpleType(Boolean)
	includePageNumber: boolean;
	// PagePosition
	pagePosition: keyof typeof PagePosition;
	// HorizontalOffset
	horizontalOffset: number;
	// VerticalOffset
	verticalOffset: number;
	// PageNumberFormat
	pageNumberFormat: string;

	constructor() {

	}
}