// This file has been generated from TenantFeatureStateUpdateDto.cs
// Do not modify this file directly!!
// tslint:disable

import { TenantFeatureStateDto } from './TenantFeatureStateDto';

// LawMaster.Amalthea.Domain.Settings.Setting.Item.TenantFeatureStateUpdateDto
export class TenantFeatureStateUpdateDto {

	// States
	states: TenantFeatureStateDto[];

	constructor() {
		this.states = [];
	}
}