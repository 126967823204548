import { Component, Inject, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatOption, MatOptionSelectionChange } from '@angular/material/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';

import { Subscription } from 'rxjs';

import { EnumSortDirection } from '@common/models/Common/EnumSortDirection';
import { DocumentTagDto } from '@common/models/Settings/DocumentTags/Item/DocumentTagDto';
import { PracticeAreaListItemDto } from '@common/models/Settings/PracticeAreas/List/PracticeAreaListItemDto';
import { NotificationService } from '@common/notification';
import { DocumentTagService } from '@common/services/settings/documenttag.service';
import { PracticeAreasService } from '@common/services/settings/practiceareas.service';
import { CustomValidators } from '@common/validation/custom.validators';
import { isNil, remove } from 'lodash-es';

@Component({
	selector: 'document-tag-dialog',
	styleUrls: ['./document-tag-dialog.component.scss'],
	templateUrl: './document-tag-dialog.component.html'
})
export class DocumentTagDialogComponent implements OnInit, OnDestroy {
	@ViewChild('selectPracticeAreas', { static: true })
	selectPracticeAreas: MatSelect;
	@ViewChild('allOption', { static: true }) allOption: MatOption;

	form: FormGroupTyped<DocumentTagDto>;
	practiceAreas: PracticeAreaListItemDto[];
	private subscriptions: Subscription = new Subscription();

	constructor(
		@Optional()
		@Inject(MAT_DIALOG_DATA)
		public editId: string,
		private fb: FormBuilder,
		private service: DocumentTagService,
		private notificationService: NotificationService,
		private practiceAreaService: PracticeAreasService
	) {}

	ngOnInit() {
		this.form = this.fb.group({
			id: this.editId,
			enabled: null,
			name: [
				null,
				[CustomValidators.required('Name'), CustomValidators.pattern(new RegExp('^[a-zA-Z0-9][a-zA-Z0-9- ]*$'))]
			],
			practiceAreaIds: null
		}) as FormGroupTyped<DocumentTagDto>;
		this.subscriptions.add(
			this.practiceAreaService
				.getPracticeAreaList({ sortBy: 'name', sortDirection: EnumSortDirection.Asc, showDisabled: true })
				.subscribe(
					next => (this.practiceAreas = next.records),
					e => this.notificationService.showErrors('Error getting Practice Areas.', e)
				)
		);

		if (this.editId) {
			this.subscriptions.add(
				this.service.getDocumentTag(this.editId).subscribe((item: DocumentTagDto) => {
					this.form.reset(item);
				})
			);
		}
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	displaySelected(values: string[]) {
		if (!values?.length) {
			return 'All';
		}

		const results = this.practiceAreas
			.filter(area => !!values.filter(id => id === area.id)?.length)
			.map(area => area.name);

		if (!results?.length) {
			return 'All';
		}

		if (results.length == 1) {
			return results[0];
		}

		return results.reduce((left, right) => `${left}, ${right}`);
	}

	allOptionSelected(event: MatOptionSelectionChange) {
		if (event.source.selected) {
			// When selecting the 'All' option, deselect all practice areas
			this.selectPracticeAreas.options.forEach(opt => {
				if (opt.selected && opt.id !== this.allOption.id) {
					opt.deselect();
				}
			});
		} else if (!this.selectPracticeAreas.options.some(opt => opt.selected)) {
			// If nothing is selected, select the 'All' option
			this.allOption.select();
		}
	}

	optionSelected(event: MatOptionSelectionChange, selectedOption: MatSelect) {
		if (this.allOption.selected && event.source.selected && event.source.id !== this.allOption.id) {
			// When selecting a practice area, deselect the 'All' option
			this.allOption.deselect();
		} else if (!selectedOption.options.some(opt => opt.selected)) {
			// If nothing is selected, select the 'All' option
			this.allOption.select();
		}
	}

	get dialogResult() {
		const result = this.form.value;
		remove(result.practiceAreaIds, isNil);
		return result;
	}
}
