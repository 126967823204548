<div class="flex-row">
	<mat-form-field floatLabel="never">
		<mat-chip-list #chipList aria-label="Document Tags">
			<ng-container *ngIf="!showBulkOptions">
				<mat-chip *ngFor="let tag of ActiveTags" (removed)="remove(tag)">
					{{ tag }}
					<div class="tag-chip-button-wrapper">
						<mat-icon class="tag-chip-button-icon" matChipRemove>cancel</mat-icon>
					</div>
				</mat-chip>
			</ng-container>

			<ng-container *ngIf="!!showBulkOptions">
				<mat-chip class="clickable" [matMenuTriggerFor]="menu" *ngFor="let tag of ActiveTags" (removed)="remove(tag)">
					{{ tag }}
					<div class="tag-chip-button-wrapper">
						<button mat-icon-button="menu" title="Actions">
							<mat-icon>more_vert</mat-icon>
						</button>
						<mat-menu #menu="matMenu">
							<button mat-menu-item throttleButton (throttledClick)="removeTag(tag)">
								<mat-icon>delete</mat-icon>
								<span>Remove Tag</span>
							</button>
							<button mat-menu-item throttleButton (throttledClick)="bulkAddTags(tag)">
								<mat-icon>beenhere</mat-icon>
								<span>Apply to All</span>
							</button>
							<button mat-menu-item throttleButton (throttledClick)="bulkRemoveTags(tag)">
								<mat-icon>delete_sweep</mat-icon>
								<span>Remove from All</span>
							</button>
						</mat-menu>
					</div>
				</mat-chip>
			</ng-container>

			<input
				[placeholder]="!placeholder ? 'Add Tags' : placeholder"
				#tagInput
				[formControl]="inputCtrl"
				[matAutocomplete]="auto"
				[matChipInputFor]="chipList"
				[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
				(matChipInputTokenEnd)="add($event)"
				(keydown)="onKeydown($event)"
				(blur)="onBlur($event)"
			/>
		</mat-chip-list>
		<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
			<mat-option *ngFor="let tag of filteredTags | async" [value]="tag" [class]="'document-tag-option-' + optionClassId">
				{{ tag }}
			</mat-option>
		</mat-autocomplete>
		<mat-hint>Select an existing tag or type to add a new tag</mat-hint>
	</mat-form-field>
</div>
