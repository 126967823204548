// tslint:disable:max-classes-per-file
import { Action } from '@ngrx/store';

import { ISystemTagsData } from '../models/system-tags-data';

export const SetSystemTagsType = 'SetSystemTagsType';
export const ClearSystemTagsType = 'ClearSystemTagsType';

export type SystemTagsActions = SetSystemTagsAction | ClearSystemTagsAction;

export class SetSystemTagsAction implements Action {
	readonly type = SetSystemTagsType;

	constructor(public data: ISystemTagsData) { }
}

export class ClearSystemTagsAction implements Action {
	readonly type = ClearSystemTagsType;

	constructor() { }
}
