// This file has been generated from TaskTemplateDueDateSource.cs
// Do not modify this file directly!!

// LawMaster.Amalthea.Domain.Settings.TaskTemplates.Common.TaskTemplateDueDateSource
export enum TaskTemplateDueDateSource {
	MatterCreationDate = 'Matter Creation Date',
	EstimatedDateOfCompletion = 'Estimated Date of Completion',
	TriggerDate = 'Trigger Date',
	CustomField = 'Custom Field'
}
