import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Router } from '@angular/router';

import { BehaviorSubject, filter, switchMap } from 'rxjs';

import { BasicWorkflowCreateUpdateDto } from '@common/models/Settings/BasicWorkflows/Item/BasicWorkflowCreateUpdateDto';
import { BasicWorkflowViewDto } from '@common/models/Settings/BasicWorkflows/Item/BasicWorkflowViewDto';
import { MatterWorkflowCondition } from '@common/models/Settings/BasicWorkflows/Item/MatterWorkflowCondition';
import { NotificationService } from '@common/notification';
import { BasicWorkflowService } from '@common/services/settings/basicworkflow.service';
import { PracticeAreasService } from '@common/services/settings/practiceareas.service';
import { CustomValidators } from '@common/validation/custom.validators';

import { BaseWorkflowComponent } from '../create/basic-workflow-base.component';

@Component({
	selector: 'basic-workflow-summary',
	styleUrls: ['basic-workflow-summary.component.scss'],
	templateUrl: 'basic-workflow-summary.component.html'
})
export class BasicWorkflowSummaryComponent extends BaseWorkflowComponent implements OnInit, AfterViewInit {
	@Input() data: BehaviorSubject<BasicWorkflowViewDto>;

	form: FormGroupTyped<BasicWorkflowCreateUpdateDto>;

	public get hasChanges() {
		return !!this?.form?.dirty;
	}

	constructor(
		practiceAreasService: PracticeAreasService,
		notifService: NotificationService,
		private _fb: FormBuilder,
		private _basicWorkflowService: BasicWorkflowService,
		private _router: Router
	) {
		super(practiceAreasService, notifService);
	}

	ngOnInit(): void {
		this.form = this._fb.group({
			name: [null, CustomValidators.required('Name')],
			workflowType: [{ value: this.data.value.workflowType, disabled: true }],
			associatedPracticeAreaIds: [],
			stageName: [
				null,
				CustomValidators.requiredWhen(() => this.form?.controls?.workflowType?.value === 'StageChanged')
			],
			matterWorkflowConditions: this._fb.array(
				[],
				CustomValidators.requiredWhen(() => this.form?.controls?.workflowType?.value === 'MatterUpdated')
			)
		}) as FormGroupTyped<BasicWorkflowCreateUpdateDto>;

		super.ngOnInit();
	}

	ngAfterViewInit(): void {
		this.resetForm();
	}

	public saveChanges(): void {
		var dto: BasicWorkflowCreateUpdateDto = {
			...this.form.value,
			id: this.data.value.id,
			workflowType: this.data.value.workflowType
		};

		this.subscriptions.add(
			this._basicWorkflowService.updateBasicWorkflow(this.data.value.id, dto).subscribe({
				next: () => {
					this.notifService.showNotification('Matter Workflow updated');
					this.data.next(this.mapFormToViewData());
					this.form.markAsUntouched();
					this.form.markAsPristine();
				},
				error: err => this.notifService.showErrors('Error saving workflow', err)
			})
		);
	}

	clearChanges(): void {
		this.resetForm();
		this.form.markAsUntouched();
	}

	public deleteWorkflow(): void {
		this.subscriptions.add(
			this.notifService
				.showConfirmation(
					'Delete Matter Workflow',
					`Are you sure you want to delete the Matter Workflow "${this.data.value.name}"?`
				)
				.pipe(
					filter(Boolean),
					switchMap(() => this._basicWorkflowService.deleteBasicWorkflow(this.data.value.id))
				)
				.subscribe({
					next: () => {
						this.notifService.showNotification(`${this.data.value.name} has been deleted`);
						this._router.navigate(['/system/matter-workflows']);
					},
					error: errors => {
						this.notifService.showErrors(`Error deleting ${this.data.value.name}`, errors);
					}
				})
		);
	}

	get matterWorkflowConditions() {
		return this.form.get('matterWorkflowConditions') as any as FormArray;
	}

	private resetForm() {
		this.form.patchValue({
			name: this.data.value.name,
			workflowType: this.data.value.workflowType,
			stageName: this.data.value.stageName,
			matterWorkflowConditions: [],
			associatedPracticeAreaIds: []
		});

		this.matterWorkflowConditions.clear();
		(this.data?.value?.matterWorkflowConditions ?? []).forEach(x => this.addMatterWorkflowCondition(x));

		if (this.data?.value?.practiceAreas?.length > 0) {
			const practiceAreaIds = this.data.value.practiceAreas.map(x => x.id);
			this.form.controls.associatedPracticeAreaIds.setValue(practiceAreaIds);
		} else if (this.form.controls.associatedPracticeAreaIds.touched) {
			this.form.controls.associatedPracticeAreaIds.setValue([]);
		}

		this.form.updateValueAndValidity();
		this.form.markAsPristine();
	}

	private addMatterWorkflowCondition(data?: MatterWorkflowCondition) {
		const newCondition = this._fb.group({
			variableName: new FormControl(data?.variableName, CustomValidators.required()),
			operator: new FormControl(data?.operator || '', CustomValidators.required()),
			expectedValue: new FormControl(data?.expectedValue, [])
		});

		this.matterWorkflowConditions.push(newCondition);
		this.form.markAsDirty();
	}

	private mapFormToViewData(): BasicWorkflowViewDto {
		const selectedPracticeAreas = this.practiceAreas
			.filter(pa => this.form.value.associatedPracticeAreaIds.includes(pa.id))
			.map(selectedPracticeArea => ({ id: selectedPracticeArea.id, name: selectedPracticeArea.name }));

		const viewData = {
			...this.data.value,
			practiceAreas: selectedPracticeAreas ?? [],
			name: this.form.value.name,
			stageName: this.form.value.stageName,
			matterWorkflowConditions: this.form.value.matterWorkflowConditions
		};

		return viewData;
	}
}
