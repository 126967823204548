import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { interval, Subscription, switchMap } from 'rxjs';

import { NotificationService } from '@common/notification';
import { TimeZoneService } from '@common/services/settings/timezone.service';
import { CustomValidators } from '@common/validation/custom.validators';
import moment from 'moment-timezone';

import { AuthService } from 'app/core/auth.service';

const clockInterval: number = 500;

@Component({
	selector: 'time-zone-config',
	styleUrls: ['./time-zone-config.component.scss'],
	templateUrl: './time-zone-config.component.html'
})
export class TimeZoneConfigComponent implements OnInit, OnDestroy {
	private _subscriptions = new Subscription();
	private _form: FormGroup;
	private _now: string;

	get form() {
		return this._form;
	}

	get now() {
		return this._now;
	}

	constructor(
		private _formBuilder: FormBuilder,
		private _timeZoneService: TimeZoneService,
		private _notificationService: NotificationService,
		private _authService: AuthService
	) {}

	ngOnInit() {
		this._form = this._formBuilder.group({
			timeZone: [this._authService.TimeZoneId, CustomValidators.required('Time Zone')]
		});

		this._subscriptions.add(this._subscriptions.add(interval(clockInterval).subscribe(() => this.updateNow())));

		this._form.get('timeZone').valueChanges.subscribe(() => this.updateNow());
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}

	onSaveClicked(): void {
		if (!this._form?.valid) {
			return;
		}

		const timeZone = this.form.get('timeZone')?.value as string;

		this._subscriptions.add(
			this._timeZoneService
				.updateTimeZone({ timeZoneId: timeZone })
				.pipe(switchMap(() => this._authService.SilentRefresh(true)))
				.subscribe({
					next: () => {
						this.form.markAsPristine();
						this._notificationService.showNotification('Successfully updated Time Zone');
					},
					error: errors => this._notificationService.showErrors('Failed to update Time Zone', errors)
				})
		);
	}

	private updateNow() {
		let timeZone = this._form?.get('timeZone')?.value;

		if (!timeZone || !moment.tz(timeZone)) {
			timeZone = this._authService.TimeZoneId;
		}

		this._now = moment().tz(timeZone).format('DD/MM/YYYY hh:mm:ss A');
	}
}
