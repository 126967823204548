// This file has been generated from DocumentListRequest.cs
// Do not modify this file directly!!
// tslint:disable

import { Moment } from 'moment-timezone';
import { DateFormat, SetDateFormat } from '@common/utils/date-format';

import { SetSimpleType } from '@common/utils/class-transform';

import { EnumSortDirection } from '../../Common/EnumSortDirection';
import { Features } from '../../Settings/Modules/Features';
import { DocumentType } from '../Common/DocumentType';
import { TemplateEntityType } from '../TemplateDto/TemplateEntityType';

// LawMaster.Amalthea.Domain.Documents.List.DocumentListRequest
export class DocumentListRequest {

	// Types
	types: Array<keyof typeof DocumentType>;
	// CreatedDateStart
	@SetDateFormat(DateFormat.DateTime)
	createdDateStart: Moment;
	// CreatedDateEnd
	@SetDateFormat(DateFormat.DateTime)
	createdDateEnd: Moment;
	// ModifiedDateStart
	@SetDateFormat(DateFormat.DateTime)
	modifiedDateStart: Moment;
	// ModifiedDateEnd
	@SetDateFormat(DateFormat.DateTime)
	modifiedDateEnd: Moment;
	// ContactId
	contactId: string;
	// MatterId
	matterId: string;
	// ImportId
	importId: string;
	// Inc
	inc: string[];
	// Exc
	exc: string[];
	// UploadedById
	uploadedById: string;
	// MyRecent
	@SetSimpleType(Boolean)
	myRecent: boolean;
	// PracticeAreaIds
	practiceAreaIds: string[];
	// BriefId
	briefId: string;
	// SectionId
	sectionId: string;
	// OrderNumber
	orderNumber: number;
	// DocumentCategoryId
	documentCategoryId: string;
	// DocumentTags
	documentTags: string[];
	// IsMatchAllTags
	@SetSimpleType(Boolean)
	isMatchAllTags: boolean;
	// EntityType
	entityType: keyof typeof TemplateEntityType;
	// MatteroEmailUniqueId
	matteroEmailUniqueId: string;
	// EmailId
	emailId: string;
	// ConversationId
	conversationId: string;
	// EmailOrConversationId
	@SetSimpleType(Boolean)
	emailOrConversationId: boolean;
	// TrustAccountIds
	trustAccountIds: string[];
	// InstallationRecordId
	installationRecordId: string;
	// FilterVersionsForDocumentId
	filterVersionsForDocumentId: string;
	// Search
	search: string;
	// UseFullTextSearch
	@SetSimpleType(Boolean)
	useFullTextSearch: boolean;
	// SortBy
	sortBy: string;
	// IncludeBriefData
	@SetSimpleType(Boolean)
	includeBriefData: boolean;
	// SortDirection
	sortDirection: keyof typeof EnumSortDirection;
	// IsFavorited
	@SetSimpleType(Boolean)
	isFavorited: boolean;
	// IsShared
	@SetSimpleType(Boolean)
	isShared: boolean;
	// ShowDisabled
	@SetSimpleType(Boolean)
	showDisabled: boolean;
	// PageIndex
	pageIndex: number;
	// PageSize
	pageSize: number;
	// PageIndexForId
	pageIndexForId: string;
	// DocumentIds
	documentIds: string[];
	// IgnoreDocumentIds
	ignoreDocumentIds: string[];
	// Features
	features: Array<keyof typeof Features>;
	// MustHaveAssociatedMatter
	@SetSimpleType(Boolean)
	mustHaveAssociatedMatter: boolean;
	// MustHaveAssociatedContact
	@SetSimpleType(Boolean)
	mustHaveAssociatedContact: boolean;

	constructor() {
		this.types = [];
		this.features = [];
	}
}