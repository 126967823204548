import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

import { Observable } from 'rxjs';

import { BaseLookupComponent } from '@common/components/lookups/base-lookup.component';
import { MatterStatus } from '@common/models/Matters/Common/MatterStatus';
import { MatterLookupDto } from '@common/models/Matters/Lookup/MatterLookupDto';
import { MattersService } from '@common/services/matters.service';
import { Store } from '@ngrx/store';
import * as moment from 'moment-timezone';

import { IMatterNumberingConfigState } from 'app/core/state/misc/matter-numbering-config/matter-numbering-config.state';

@Component({
	selector: 'matter-lookup',
	styleUrls: ['../../../../../common/components/lookups/base-lookup.component.scss', 'matter-lookup.component.scss'],
	templateUrl: '../../../../../common/components/lookups/base-lookup.component.html'
})
export class MatterLookupComponent extends BaseLookupComponent<MatterLookupDto> {
	@Input()
	Status: keyof typeof MatterStatus;
	@Input()
	FormControl: FormControl;
	@Input()
	FontSize: string;
	@Input()
	HasAutofocus: boolean;
	@Input()
	Placeholder: string;
	@Input()
	Hint: string;
	@Input()
	Required: boolean;
	@ViewChild('autoCompleteInput', { static: true })
	inputCtrl: ElementRef;

	matterNumberReferenceFormat: string;

	constructor(
		private matterService: MattersService,
		private store: Store<{ matterNumberingConfigData: IMatterNumberingConfigState }>
	) {
		super();
	}

	ngOnInit() {
		this.subscription.add(
			this.store
				.select(data => data.matterNumberingConfigData)
				.subscribe(data => (this.matterNumberReferenceFormat = data?.dto?.referenceFormat))
		);

		super.ngOnInit();
	}

	optionHtmlText(input: MatterLookupDto): string {
		return `<div class="selected-matter">
					${input.number} - ${input.title}
				</div>
				<div class="lawyer-container">
					<span class="material-icons lawyer-icon">work</span>
					<span class="lawyer-name">${input.lawyer.name}</span>
				</div>`;
	}

	displayText(value: MatterLookupDto) {
		return `${value.number} - ${value.title}`;
	}

	lookup(id: string): Observable<MatterLookupDto> {
		return this.matterService.lookupMatter(id);
	}

	search(term: string): Observable<MatterLookupDto[]> {
		if (!!term?.length && /(^\d+)$/g.test(term)) {
			term = !!this.matterNumberReferenceFormat?.includes('YearShort')
				? `${moment().format('YY')}-${term}`
				: !!this.matterNumberReferenceFormat?.includes('YearFull')
				? `${moment().format('YYYY')}-${term}`
				: term;
		}

		return this.matterService.lookupMatterList({ term, status: this.Status });
	}

	setFocus() {
		if (this.inputCtrl != null) {
			setTimeout(() => {
				this.inputCtrl.nativeElement.focus();
			}, 0);
		}
	}

	// returns empty if not implemented in inherited class
	getAdditionalInfo(value: MatterLookupDto): string {
		return value.costingMethodReference.costingMethod.toString();
	}
}
