import { HttpErrorResponse } from '@angular/common/http';

import { never } from 'rxjs';

import { IResultWithAttachments } from '@common/models/Common/IResultWithAttachments';
import { get, isEmpty } from 'lodash-es';

import { UploadStatus } from '../../../../../common/models/Common/IFileUploader';
import { IErrorContainer } from './IErrorContainer';
import { IProgress } from './IProgress';

export function handleHttpOperationProgress<T>(
	parentContext: any,
	response: Partial<IResultWithAttachments>,
	progress: IProgress,
	completedHandler: (parentContext: any, result: Partial<IResultWithAttachments>, isCreate: boolean) => Observable<T>,
	isCreate: boolean = null
) {
	if (!get(response, 'uploadFiles.progress.status')) {
		// waiting for upload status to be updated
		return never();
	}

	if (response.uploadFiles.progress.status === UploadStatus.Uploading) {
		const uploadProgress = response.uploadFiles.progress;
		progress.percentage = uploadProgress.data.percentage;

		return never();
	}

	if (response.uploadFiles.progress.status === UploadStatus.Completed) {
		return completedHandler(parentContext, response, isCreate);
	}

	return never();
}

export function handleHttpOperationError(errorResponse: HttpErrorResponse, error: IErrorContainer): Observable<never> {
	if (isEmpty(errorResponse.error)) {
		error.message = 'Something went wrong. Please try again';
		return never();
	}

	const errors = errorResponse.error;
	let formattedErrors: string;
	if (typeof errors === 'string') {
		formattedErrors = errors;
	} else if (errors.length > 1) {
		formattedErrors = '<ul>';
		for (const errorItem of errors) {
			formattedErrors += '<li>' + errorItem.message + '</li>';
		}
		formattedErrors += '</ul>';
	} else formattedErrors = errors[0].message;

	if (isEmpty(formattedErrors)) {
		formattedErrors = 'Something went wrong. Please try again';
	}

	error.message = formattedErrors;

	return never();
}
