import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect, MatSelectChange } from '@angular/material/select';

import { BehaviorSubject, forkJoin, Observable, of, Subscription } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';

import { IUploadMultipleFiles } from '@common/models/Common/IFileUploader';
import { IResultWithAttachments } from '@common/models/Common/IResultWithAttachments';
import { ExpenseRecordCreateDto } from '@common/models/CostRecords/Item/ExpenseRecordCreateDto';
import { ExpenseRecordUpdateDto } from '@common/models/CostRecords/Item/ExpenseRecordUpdateDto';
import { ExpenseRecordUpdloadAttachmentsDto } from '@common/models/CostRecords/Item/ExpenseRecordUpdloadAttachmentsDto';
import { ExpenseRecordViewDto } from '@common/models/CostRecords/Item/ExpenseRecordViewDto';
import { DocumentReference } from '@common/models/Documents/PersistenceLayer/DocumentReference';
import { MatterStatus } from '@common/models/Matters/Common/MatterStatus';
import { MatterLookupDto } from '@common/models/Matters/Lookup/MatterLookupDto';
import { CostCodeListItemDto } from '@common/models/Settings/CostCodes/List/CostCodeListItemDto';
import { GstRateDto } from '@common/models/Settings/Setting/Item/GstRateDto';
import { UserViewDto } from '@common/models/Users/Item/UserViewDto';
import { INotificationMessage, NotificationService } from '@common/notification';
import { ExpenseRecordsService } from '@common/services/expenserecords.service';
import { CostCodesService } from '@common/services/settings/costcodes.service';
import { GeneralSettingsService } from '@common/services/settings/generalsettings.service';
import { ICurrentUserData } from '@common/state/models/current-user-data';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { CustomValidators } from '@common/validation/custom.validators';
import { Store } from '@ngrx/store';
import { isNil } from 'lodash';
import * as moment from 'moment-timezone';

import { insertRecords, updateRecords } from 'app/core/state/lists/cost-list/cost-list.actions';
import { getCurrentPage } from 'app/core/state/misc/current-page/current-page.reducer';
import { CurrentPageType, ICurrentPageState } from 'app/core/state/misc/current-page/current-page.state';
import { MatterLookupComponent } from 'app/shared/components/matter-lookup.component';
import { IErrorContainer } from 'app/shared/utils/IErrorContainer';
import { IProgress } from 'app/shared/utils/IProgress';
import { round } from 'app/shared/utils/mathUtil';
import { handleHttpOperationError, handleHttpOperationProgress } from 'app/shared/utils/uploadResponseHandler';

import { ICreateComponent } from '../create-component.interface';
import { IEditComponent } from '../edit-component.interface';

@Component({
	selector: 'create-expense',
	styleUrls: ['./create-expense.component.scss'],
	templateUrl: 'create-expense.component.html'
})
export class CreateExpenseComponent implements OnInit, AfterViewInit, OnDestroy, ICreateComponent, IEditComponent {
	static readonly maxUploadSizeMb: number = 50;

	@Input()
	editId: string;
	@Input()
	hideRelatedTo: boolean;
	@Input()
	form: FormGroupTyped<ExpenseRecordCreateUpdateDtoExt>;
	@Input()
	disableValidation: boolean;

	@Output()
	isValidChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	@ViewChild('costdropdown')
	costdropdown: MatSelect;
	@ViewChild('matterLookup')
	matterLookup: MatterLookupComponent;

	costCodes: CostCodeListItemDto[];
	existingDocuments: DocumentReference[] = [];
	documentIdsToRemove: string[] = [];
	uploadFilesInfo: IUploadMultipleFiles = null;

	highlightField: boolean;
	showProgress: boolean = false;
	progress: IProgress = { percentage: 0 };
	error: IErrorContainer = { message: '' };
	isEditable: boolean = true;
	isWrittenOff: boolean = false;
	amountHint: string;

	get gstAmountHint(): string {
		if (this.isGstFree || !this.isGstManuallyEntered) return '';

		return 'Manually overridden';
	}

	private get isGstManuallyEntered(): boolean {
		return this.form.controls.gstAmount.dirty;
	}
	private set isGstManuallyEntered(val: boolean) {
		if (val) this.form.controls.gstAmount.markAsDirty();
		else this.form.controls.gstAmount.markAsPristine();
	}

	private subscription: Subscription = new Subscription();
	private gstRate: number;

	constructor(
		private fb: FormBuilder,
		private expenseRecordService: ExpenseRecordsService,
		private costCodeService: CostCodesService,
		private generalSettingsService: GeneralSettingsService,
		private store: Store<{ currentPageData: ICurrentPageState; currentUserData: ICurrentUserData }>,
		protected dialog: MatDialog,
		protected notificationService: NotificationService
	) {}

	ngOnInit(): void {
		if (!this.form) {
			this.form = this.fb.group({
				amountInclTax: [null, CustomValidators.required('Amount')],
				associatedMatterId: [null, CustomValidators.required('Related To Matter')],
				billed: null,
				costCodeId: [null, CustomValidators.required('Cost Code')],
				date: [null, CustomValidators.required('Date')],
				description: [null, CustomValidators.required('Description')],
				gstAmount: null,
				staffContactId: [null, CustomValidators.required('User')]
			}) as FormGroupTyped<ExpenseRecordCreateUpdateDtoExt>;
		}

		this.subscription.add(
			this.form.statusChanges.subscribe(next => {
				this.isValidChange.next(next === 'VALID');
			})
		);

		const expenseRecordObservable = this.expenseRecordService.getExpenseRecord(this.editId).pipe(
			switchMap(expenseRecord => {
				this.isWrittenOff = expenseRecord.isWrittenOff;

				if (!expenseRecord.isEditable) {
					this.isEditable = false;
					this.form.disable();

					this.form.controls.associatedMatterId.disable();
					this.form.controls.amountInclTax.disable();
					this.form.controls.costCodeId.disable();
					this.form.controls.date.disable();
					this.form.controls.description.disable();
					this.form.controls.gstAmount.disable();
					this.form.controls.staffContactId.disable();

					//Force enabling the Save button on the parent control (dialog.component)
					this.isValidChange.next(true);
				} else if (!expenseRecord.canChangeMatter) {
					this.form.controls.associatedMatterId.disable();
				}

				return of(expenseRecord);
			})
		);
		const costCodeObservable = this.costCodeService.getCostCodeList({
			costType: 'Expense',
			sortBy: 'name',
			isEnabled: true
		});
		const dto: GstRateDto = {
			costType: 'Expense',
			matterId: null
		};
		const gstObservable = this.generalSettingsService.getGstRate(dto);

		const sources: Observable<any>[] = [costCodeObservable, gstObservable];

		if (this.editId) {
			if (this.form.disabled) {
				this.isEditable = true;
				this.form.enable();
			}
			sources.push(expenseRecordObservable);
		} else {
			this.initForm();
		}

		this.subscription.add(
			forkJoin(...sources)
				.pipe(
					switchMap(values => {
						// value 0: costCodeList
						this.costCodes = values[0].records;
						// value 1: gstRate
						this.gstRate = values[1];
						// value 2: expense record (if edit)
						if (values.length === 3 && this.editId) {
							const expenseRecord: ExpenseRecordViewDto = values[2];
							this.existingDocuments = expenseRecord.existingDocuments;
							this.form.patchValue(this.getExpenseRecordCreateUpdateDtoExt(expenseRecord));

							this.updateGstControlState();
						}

						return this.form.controls.amountInclTax.valueChanges;
					}),
					debounceTime(200)
				)
				.subscribe(() => {
					if (!this.isGstFree() && !this.isGstManuallyEntered) {
						this.autoCalculateGst();
					}
					this.setAmountHint();
				})
		);

		// Refresh the tooltip on users' changes of the GST
		this.subscription.add(
			this.form.controls.gstAmount.valueChanges
				.pipe(filter(() => this.isGstManuallyEntered))
				.subscribe(() => this.setAmountHint())
		);
	}

	ngAfterViewInit(): void {
		// Set focus only in create mode. No need to set focus in edit mode.
		if (this.editId) return;

		if (!!this.form.controls.associatedMatterId.value) {
			setTimeout(() => {
				this.costdropdown.focus();
			}, 0);
		} else if (this.matterLookup) {
			setTimeout(() => {
				this.matterLookup.setFocus();
			}, 0);
		}
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
		this.isValidChange.complete();
	}

	Create(): Observable<INotificationMessage> {
		const dto: ExpenseRecordCreateDto = this.getExpenseRecordCreateDto(this.form.value);

		this.toggleFormBusy(true);

		return this.expenseRecordService.create(dto, this.uploadFilesInfo).pipe(
			switchMap(result =>
				handleHttpOperationProgress<INotificationMessage>(
					this,
					result,
					this.progress,
					(parentContext, result, isCreate) => this.handleCompleted(parentContext, result, isCreate),
					true
				)
			),
			catchError((errorResponse: HttpErrorResponse) => {
				this.toggleFormBusy(false);
				return handleHttpOperationError(errorResponse, this.error);
			})
		);
	}

	Edit(): Observable<INotificationMessage> {
		const updateDto: ExpenseRecordUpdateDto = this.getExpenseRecordUpdateDto(this.form.getRawValue());

		const documentIdsToRemove = this.documentIdsToRemove;

		this.toggleFormBusy(true);

		if (this.isEditable) {
			if (documentIdsToRemove && documentIdsToRemove.length) {
				updateDto.documentIdsToRemove = documentIdsToRemove;
			}
			return this.expenseRecordService.update(this.editId, updateDto, this.uploadFilesInfo).pipe(
				switchMap(result =>
					handleHttpOperationProgress<INotificationMessage>(
						this,
						result,
						this.progress,
						(parentContext, result, isCreate) => this.handleCompleted(parentContext, result, isCreate),
						false
					)
				),
				catchError((errorResponse: HttpErrorResponse) => {
					this.toggleFormBusy(false);
					return handleHttpOperationError(errorResponse, this.error);
				})
			);
		} else {
			const uploadAttachmentDto: ExpenseRecordUpdloadAttachmentsDto = {
				attachments: [],
				documentIdsToRemove: []
			};
			if (documentIdsToRemove && documentIdsToRemove.length) {
				uploadAttachmentDto.documentIdsToRemove = documentIdsToRemove;
			}
			return this.expenseRecordService
				.uploadAttachments(this.editId, uploadAttachmentDto, this.uploadFilesInfo)
				.pipe(
					switchMap(result =>
						handleHttpOperationProgress<INotificationMessage>(
							this,
							result,
							this.progress,
							(parentContext, result, isCreate) => this.handleCompleted(parentContext, result, isCreate),
							false
						)
					),
					catchError((errorResponse: HttpErrorResponse) => {
						this.toggleFormBusy(false);
						return handleHttpOperationError(errorResponse, this.error);
					})
				);
		}
	}

	getReadOnlyReason(): string {
		return 'This Expense Record is read only as it has been ' + (this.isWrittenOff ? 'written off' : 'invoiced');
	}

	costCodeChanged(event: MatSelectChange): void {
		// changing cost code updates description also when it's first filled, then cleared
		if (this.form.controls.description.value === '') {
			this.form.controls.description.markAsPristine();
		}
		if (this.form.controls.description.pristine) {
			const selectedCostCode = (event.source.selected as MatOption).viewValue;
			this.form.controls.description.patchValue(selectedCostCode);
		}
		this.updateGstControlState();
	}

	updateGstControlState() {
		if (this.isGstFree()) {
			// Disable if exclude GST
			this.form.controls.gstAmount.disable();
			this.form.controls.gstAmount.patchValue(null);
			this.form.controls.gstAmount.clearValidators();
			this.form.controls.gstAmount.markAsPristine();
		} else if (this.isEditable && this.form.controls.gstAmount.disabled) {
			// Re-enable if previously disabled
			this.form.controls.gstAmount.enable();
			this.form.controls.gstAmount.setValidators([
				CustomValidators.required('GST Amount'),
				CustomValidators.lessThanControlValue('amountInclTax')
			]);
			this.autoCalculateGst();
		}
		this.setAmountHint();
	}

	onErrorMessageUpdated(errorMessage: string) {
		this.error.message = errorMessage;
	}

	onDocumentIdsToRemoveUpdated(documentIdsToRemove: string[]) {
		this.documentIdsToRemove = documentIdsToRemove;
		if (this.form.disabled) this.form.enable();
	}

	onUploadFilesInfoUpdated(uploadFilesInfo: IUploadMultipleFiles) {
		this.uploadFilesInfo = uploadFilesInfo;
		if (this.form.disabled) this.form.enable();
	}

	isGstFree(costCodeId: string = this.form.value.costCodeId): boolean {
		const costCode = this.costCodes && this.costCodes.find(x => x.id === costCodeId);
		return costCode && costCode.expenseType === 'ExpenseExGst';
	}

	autoCalculateGst() {
		this.isGstManuallyEntered = false;

		const amountInclTax = this.form.controls.amountInclTax.value;
		const calculatedGstAmount: number = round((amountInclTax * this.gstRate) / (1 + this.gstRate), 2);
		const previousGstAmount = this.form.controls.gstAmount.value;

		if (previousGstAmount !== calculatedGstAmount) {
			this.form.controls.gstAmount.patchValue(calculatedGstAmount);
			this.highLightField();
			this.setAmountHint();
		}
	}

	private setAmountHint(): void {
		const amountInclTax = this.form.controls.amountInclTax.value;

		if (this.isGstFree() || isNil(this.gstRate) || isNil(amountInclTax)) {
			this.amountHint = '';
		} else {
			const gstAmount = this.form.controls.gstAmount.value ?? 0;
			const amountExclTax = Number((amountInclTax - gstAmount).toFixed(2));

			this.amountHint =
				`$${amountExclTax} + $${gstAmount}` +
				(!this.isGstManuallyEntered ? ` (${this.gstRate * 100}% GST rate)` : '');
		}
	}

	private toggleFormBusy(isBusy: boolean): void {
		if (isBusy) this.form.disable();
		else this.form.enable();

		this.isValidChange.next(!isBusy);
		this.showProgress = isBusy;
	}

	// this method is passed to a utility and is called from there.
	// 		as a result, 'this' changes context so 'parentContext' is passed to fix it.
	private handleCompleted(
		parentContext: any,
		result: Partial<IResultWithAttachments>,
		isCreate: boolean
	): Observable<INotificationMessage> {
		this.store.dispatch(
			isCreate
				? insertRecords({ response: result.mutationResponse })
				: updateRecords({ response: result.mutationResponse })
		);

		parentContext.toggleFormBusy(false);

		if (!!result.mutationResponse) {
			const matterId = parentContext.form.controls.associatedMatterId.value;

			const notification = isCreate
				? {
						linkParams: { pageIndexForId: result.mutationResponse.id },
						linkRoute: matterId ? ['/matters', matterId, 'costs'] : ['/costs'],
						linkText: result.mutationResponse.name,
						text: 'Expense created:'
				  }
				: { text: `Expense updated: ${result.mutationResponse.name}` };

			return of(notification);
		}

		return of({
			text: 'Something went wrong. Please check if the expense is created.'
		});
	}

	private initForm(): void {
		// default to the current date
		this.form.reset({ date: moment() });

		// Get paramters from the currently opened matter
		this.getCurrentOpenMatter()
			.pipe(
				switchMap(page => {
					return of({ associatedMatterId: page ? page.id : null });
				}),
				tap(dto => {
					this.form.patchValue({
						associatedMatterId: dto.associatedMatterId
					});
				}),
				switchMap(() => this.store.select(state => state?.currentUserData?.currentUser)),
				tap((user: UserViewDto) =>
					this.form.patchValue({
						staffContactId: user?.contact?.id
					})
				)
			)
			.subscribe();
	}

	// Highlight the GST amount field
	private highLightField(): void {
		// Reset the fading effect on the GST Amount field to the initial state
		this.highlightField = false;
		// Trigger the fading effect then the style has been removed
		setTimeout(() => {
			this.highlightField = true;
		}, 0);
	}

	private getCurrentOpenMatter(): Observable<ICurrentPageState> {
		// default in matter if it is available
		return this.store
			.select(getCurrentPage)
			.pipe(
				map(page =>
					page.pageType === CurrentPageType.Matter &&
					page.id !== null &&
					(page.lookup as MatterLookupDto).status === MatterStatus.Open
						? page
						: null
				)
			);
	}

	private getExpenseRecordCreateUpdateDtoExt(dto: ExpenseRecordViewDto): ExpenseRecordCreateUpdateDtoExt {
		return {
			amountExclTax: dto.amountExclTax,
			amountInclTax: dto.amountExclTax + dto.gstAmount,
			associatedMatterId: dto.associatedMatterId,
			attachments: [],
			costCodeId: dto.costCodeId,
			date: dto.date,
			description: dto.description,
			documentIdsToRemove: [],
			gstAmount: dto.gstAmount,
			gstAmountOverride: null,
			staffContactId: dto.staffContactId
		};
	}

	private getExpenseRecordCreateDto(dto: ExpenseRecordCreateUpdateDtoExt): ExpenseRecordCreateDto {
		if (this.isGstFree()) {
			dto.gstAmountOverride = 0;
			dto.gstAmount = 0;
		}
		dto.amountExclTax = dto.amountInclTax - dto.gstAmount;
		return TransformDatesOnObject(ExpenseRecordCreateDto, dto as ExpenseRecordCreateDto);
	}

	private getExpenseRecordUpdateDto(dto: ExpenseRecordCreateUpdateDtoExt): ExpenseRecordUpdateDto {
		if (this.isGstFree()) {
			dto.gstAmountOverride = 0;
			dto.gstAmount = 0;
		}
		dto.amountExclTax = dto.amountInclTax - dto.gstAmount;
		return TransformDatesOnObject(ExpenseRecordUpdateDto, dto as ExpenseRecordUpdateDto);
	}
}

// tslint:disable-next-line:max-classes-per-file
class ExpenseRecordCreateUpdateDtoExt extends ExpenseRecordUpdateDto {
	amountInclTax: number;
}
