import { CostRecordListItemDto } from '@common/models/CostRecords/List/CostRecordListItemDto';
import { CostRecordListRequest } from '@common/models/CostRecords/List/CostRecordListRequest';
import { ListResponse } from '@common/models/Generic/ListResponse';
import { DomainError } from '@common/models/Validation/DomainError';

import { AppConfig } from 'app/app.config';

export interface ICostListState {
	isFetching: boolean;
	list: ListResponse<CostRecordListItemDto>;
	lastSelectedRecordId: string;
	request: Partial<CostRecordListRequest>;
	oldRequest: Partial<CostRecordListRequest>;
	metadata: CostListMetadata;

	// errrors
	error: DomainError;
}

export interface CostListMetadata {
	sumDurationMinutes: number;
	sumAmountExclGst: number;
	sumAmountGst: number;
	sumWrittenOffAmountExclGst: number;
}

export const initialState: ICostListState = {
	isFetching: false,
	list: null,
	lastSelectedRecordId: null,
	metadata: null,
	request: {
		pageSize: AppConfig.PageSize,
		pageIndex: 0,
		pageIndexForId: null,

		sortBy: null,
		sortDirection: null
	},
	oldRequest: null,
	error: null
};
