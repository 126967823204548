import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { EMPTY, of, Subscription } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { ILookupReference } from '@common/components/lookups/base-lookup.component';
import { DocumentAssociationType } from '@common/models/Documents/Item/DocumentAssociationType';
import { DocumentCopyDto } from '@common/models/Documents/Item/DocumentCopyDto';
import { DocumentUniqueCopyTitleDto } from '@common/models/Documents/Item/DocumentUniqueCopyTitleDto';
import { DocumentUpdateDto } from '@common/models/Documents/Item/DocumentUpdateDto';
import { DocumentViewDto } from '@common/models/Documents/Item/DocumentViewDto';
import { NotificationService } from '@common/notification';
import { MattersService } from '@common/services/matters.service';
import { TasksService } from '@common/services/tasks.service';
import { CustomValidators } from '@common/validation/custom.validators';
import { Store } from '@ngrx/store';
import * as moment from 'moment-timezone';

import { IAppState } from 'app/core/state/app.state';
import { processRecords } from 'app/core/state/lists/document-list/document-list.actions';
import { DocumentsService } from 'app/services/documents.service';
import { DocumentTagComponent } from 'app/shared/components/document-tag.component';

@Component({
	selector: 'edit-document',
	styleUrls: ['./edit-document.component.scss'],
	templateUrl: './edit-document.component.html'
})
export class EditDocumentComponent implements OnInit, OnDestroy {
	form: FormGroupTyped<DocumentUpdateDto>;
	associatedEntityTypes: DocumentAssociationType[] = [
		DocumentAssociationType.Matter,
		DocumentAssociationType.Contact
	];
	associatedEntityType: DocumentAssociationType = this.associatedEntityTypes[0];
	@ViewChild(DocumentTagComponent)
	tagComponent: DocumentTagComponent;

	isSaving: boolean = false;
	originalCreatedDate: moment.Moment;

	private subscriptions: Subscription = new Subscription();

	get isCopy(): boolean {
		return !!this.data.isCopy;
	}

	constructor(
		private dialogRef: MatDialogRef<EditDocumentComponent>,
		@Inject(MAT_DIALOG_DATA) private data: IEditDocumentData,
		private fb: FormBuilder,
		private docService: DocumentsService,
		private mattersService: MattersService,
		private notifService: NotificationService,
		private taskService: TasksService,
		private store: Store<IAppState>
	) {}

	ngOnInit(): void {
		this.form = this.fb.group({
			associatedContactId: [
				null,
				CustomValidators.requiredWhen(
					() => this.associatedEntityType === DocumentAssociationType.Contact,
					DocumentAssociationType.Contact
				)
			],
			associatedMatterId: [
				null,
				CustomValidators.requiredWhen(
					() => this.associatedEntityType === DocumentAssociationType.Matter,
					DocumentAssociationType.Matter
				)
			],
			title: [null, CustomValidators.required('Title')],
			documentTags: [],
			createdDate: [null, CustomValidators.required('Date')]
		}) as FormGroupTyped<DocumentUpdateDto>;

		if (this.data.id) {
			this.subscriptions.add(
				this.docService
					.getDocument(this.data.id)
					.pipe(
						switchMap((document: DocumentViewDto) => {
							if (!!this.data.isCopy) {
								return this.docService.getUniqueCopyName(this.data.id).pipe(
									switchMap((dto: DocumentUniqueCopyTitleDto) => {
										document.title = dto.title;
										return of(document);
									})
								);
							}

							return of(document);
						})
					)
					.subscribe((document: DocumentViewDto) => {
						if (!document) return of();
						if (document.associatedContact) {
							this.associatedEntityType = DocumentAssociationType.Contact;
						}
						if (document.associatedMatter) {
							this.associatedEntityType = DocumentAssociationType.Matter;
						}

						this.form.patchValue({
							associatedContactId: document.associatedContact ? document.associatedContact.id : null,
							associatedMatterId: document.associatedMatter ? document.associatedMatter.id : null,
							title: document.title,
							createdDate: !this.isCopy ? document.createdDate : moment()
						});
						this.originalCreatedDate = this.form.value.createdDate;
						this.tagComponent.ActiveTags = document.documentTags ?? [];
						this.tagComponent.matterId = document.associatedMatter.id;
						return this.mattersService.getMatter(document.associatedMatter.id);
					})
			);
		} else this.form.reset();
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	save(): void {
		this.isSaving = true;
		// The Timeout here is use to wait document-tag processing ActiveTags
		// The issue is when type tags without separator and click save, the tags is not saved
		setTimeout(() => {
			if (!this.data.isCopy) {
				const request: DocumentUpdateDto = this.form.value;
				if (this.originalCreatedDate !== request.createdDate) request.createdDateOverwritten = true;
				this.subscriptions.add(
					this.docService
						.getDocumentList({ documentIds: [this.data.id], pageSize: 1, includeBriefData: true })
						.pipe(
							switchMap(response => {
								if (response.totalRecords > 0) {
									const docItem = response.records[0];
									if (
										!!docItem.associatedBriefs &&
										docItem.associatedBriefs.length > 0 &&
										!!docItem.associatedMatter &&
										docItem.associatedMatter.id !== request.associatedMatterId
									) {
										this.isSaving = false;

										const briefUnorderedList = `<ul>${docItem.associatedBriefs
											.map(brief => `<li>${brief.briefName}</li>`)
											.join('')}</ul>`;
										const briefWord = 'brief' + (docItem.associatedBriefs.length > 1 ? 's' : '');

										this.notifService.showError(
											'Error Moving Document',
											`Cannot move the document ${docItem.fullFileName} as it is currently associated with the following ${docItem.associatedBriefs.length} ${briefWord}: ${briefUnorderedList} The document can be moved once it has been removed from the ${briefWord}.`
										);

										return EMPTY;
									} else {
										return this.taskService
											.getTaskList({
												pageIndex: 0,
												pageSize: 1,
												documentId: this.data.id
											})
											.pipe(
												switchMap(response => {
													if (
														response.totalRecords > 0 &&
														!!docItem.associatedMatter &&
														docItem.associatedMatter.id !== request.associatedMatterId
													) {
														return this.notifService
															.showConfirmation(
																'Move Document',
																`This document is associated with a referral. Are you sure you want to move the document ${docItem.fullFileName}?`
															)
															.pipe(
																switchMap(value => {
																	if (value) {
																		return this.updateDocument(request);
																	} else {
																		this.isSaving = false;
																		return EMPTY;
																	}
																})
															);
													} else {
														return this.updateDocument(request);
													}
												})
											);
									}
								} else {
									return this.updateDocument(request);
								}
							})
						)
						.subscribe()
				);
			} else {
				const request: DocumentCopyDto = this.form.value;

				this.subscriptions.add(
					this.docService.copy(this.data.id, request).subscribe(ref => {
						if (!!ref) {
							this.store.dispatch(processRecords({ response: ref }));
						}

						this.notifService.showNotification(`Document updated: ${ref.name}`);
						this.dialogRef.close(ref);
						this.isSaving = false;
					})
				);
			}
		}, 150);
	}

	updateDocument(request: DocumentUpdateDto) {
		return this.docService.update(this.data.id, request).pipe(
			tap(ref => {
				if (!!ref) {
					this.store.dispatch(processRecords({ response: ref }));
				}

				this.notifService.showNotification(`Document updated: ${ref.name}`);
				this.dialogRef.close(ref);
				this.isSaving = false;
			}),
			catchError(errs => {
				this.isSaving = false;
				return this.notifService.showErrors(`Error updating document`, errs);
			})
		);
	}

	associatedEntityTypeChange() {
		// clear out the associated contact and matter whenever the associated entity type changes
		this.form.patchValue({
			associatedContactId: null,
			associatedMatterId: null
		});
	}

	onContactSelected(event: ILookupReference) {
		this.form.controls.associatedContactId.setValue(event.id);
	}
}

export interface IEditDocumentData {
	id: string;
	isCopy: boolean;
}
