import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DocumentTagListRequest } from '@common/models/Settings/DocumentTags/List/DocumentTagListRequest';
import { FilterRootComponent } from 'app/shared/filter-controls';
import { FilterChangeProperties } from 'app/shared/filter-controls/filter-change-properties';

@Component({
	selector: 'document-tag-list-filter',
	styleUrls: ['./document-tag-list-filter.component.scss'],
	templateUrl: './document-tag-list-filter.component.html'
})
export class DocumentTagListFilterComponent {
	@ViewChild(FilterRootComponent, { static: true })
	root: FilterRootComponent<DocumentTagListRequest>;

	@Input()
	request: Partial<DocumentTagListRequest>;
	@Output()
	filterChange = new EventEmitter<Partial<DocumentTagListRequest>>();

	listRequestType: new () => DocumentTagListRequest = DocumentTagListRequest;

	rootFilterChange($event: FilterChangeProperties<Partial<DocumentTagListRequest>>) {
		if (!!$event) this.filterChange.emit($event.filter);
	}
}
