import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { Subscription } from 'rxjs';

import { MatterWorkflowConditionOperator } from '@common/models/Settings/BasicWorkflows/Item/MatterWorkflowConditionOperator';

import { CalculationVariableLookupComponent } from 'app/shared/components/calculation-variable-lookup.component';
import { IOperator } from 'app/system/custom-fields/expressions/expression-builder-dialog.component';

@Component({
	selector: 'basic-workflow-condition',
	templateUrl: './basic-workflow-condition.component.html',
	styleUrls: ['./basic-workflow-condition.component.scss']
})
export class BasicWorkflowConditionComponent {
	private _subscription: Subscription;

	@ViewChild(CalculationVariableLookupComponent)
	calculationVariableLookupComponentReference: CalculationVariableLookupComponent;

	get isContactVariable() {
		return this.calculationVariableLookupComponentReference?.selectedValue?.fieldType === 'Contact';
	}

	get isDateVariable() {
		return !!this.calculationVariableLookupComponentReference?.selectedValue?.fieldType
			?.toLowerCase()
			?.includes('date');
	}

	private static readonly valuelessOperators: (keyof typeof MatterWorkflowConditionOperator)[] = [
		'HasChanged',
		'HasValue',
		'NullOrEmpty'
	];

	private static readonly operators: IOperator[] = Object.keys(MatterWorkflowConditionOperator)
		.map(key => key as keyof typeof MatterWorkflowConditionOperator)
		.map(key => ({
			name: MatterWorkflowConditionOperator[key],
			symbol: key
		}));

	private _formGroup: FormGroup;

	@Input()
	set formGroup(value: FormGroup) {
		this._formGroup = value;

		if (!!this._subscription) {
			this._subscription.unsubscribe();
		}

		this._subscription = this._formGroup?.get('variableName')?.valueChanges?.subscribe(() => {});
	}

	get formGroup(): FormGroup {
		return this._formGroup;
	}

	get operators() {
		return BasicWorkflowConditionComponent.operators;
	}

	@Output()
	removeClicked = new EventEmitter<MouseEvent>();

	constructor() {}

	expectsValue() {
		const value = this._formGroup.get('operator').value;
		return !!value && !BasicWorkflowConditionComponent.valuelessOperators.includes(value);
	}
}
