import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatOption, MatOptionSelectionChange } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelect, MatSelectChange } from '@angular/material/select';

import { EMPTY, of, Subscription } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { AppcuesService } from '@common/appcues/appcues.service';
import { BaseDtoWithTrimmedId } from '@common/models/Common/BaseDtoWithTrimmedId';
import { EntityReference } from '@common/models/Common/EntityReference';
import { EnumSortDirection } from '@common/models/Common/EnumSortDirection';
import { IUploadMultipleFiles } from '@common/models/Common/IFileUploader';
import { IResultWithAttachments } from '@common/models/Common/IResultWithAttachments';
import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';
import { DocumentEmailTemplateCreateUpdateDto } from '@common/models/Documents/Item/DocumentEmailTemplateCreateUpdateDto';
import { DocumentEmailTemplateViewDto } from '@common/models/Documents/Item/DocumentEmailTemplateViewDto';
import { DocumentReference } from '@common/models/Documents/PersistenceLayer/DocumentReference';
import { TemplateEntityType } from '@common/models/Documents/TemplateDto/TemplateEntityType';
import { DocumentCategoryListItemDto } from '@common/models/Settings/DocumentCategories/List/DocumentCategoryListItemDto';
import { PracticeAreaListItemDto } from '@common/models/Settings/PracticeAreas/List/PracticeAreaListItemDto';
import { NotificationService } from '@common/notification';
import { DocumentCategoryService } from '@common/services/settings/documentcategory.service';
import { DocumentEmailTemplatesService } from '@common/services/settings/documentemailtemplates.service';
import { PracticeAreasService } from '@common/services/settings/practiceareas.service';
import { CustomValidators } from '@common/validation/custom.validators';
import { Store } from '@ngrx/store';
import { cloneDeep, isEmpty, isNil, remove, uniq } from 'lodash-es';

import { IAppState } from 'app/core/state/app.state';
import { processRecords } from 'app/core/state/lists/document-list/document-list.actions';
import { DocumentTagComponent } from 'app/shared/components/document-tag.component';
import { DocumentTemplateLookupComponent } from 'app/shared/components/document-template-lookup.component';
import { IErrorContainer } from 'app/shared/utils/IErrorContainer';
import { IProgress } from 'app/shared/utils/IProgress';
import { handleHttpOperationError, handleHttpOperationProgress } from 'app/shared/utils/uploadResponseHandler';

@Component({
	selector: 'email-template-properties',
	styleUrls: ['./email-template-properties.component.scss'],
	templateUrl: './email-template-properties.component.html'
})
export class EmailTemplatePropertiesComponent implements OnInit, OnDestroy {
	@ViewChildren('documentTemplateControl', { read: DocumentTemplateLookupComponent })
	documentTemplateControlReferences: QueryList<DocumentTemplateLookupComponent>;
	@ViewChild('allOption')
	allOption: MatOption;
	@ViewChild('selectPracticeAreas')
	selectPracticeAreas: MatSelect;
	@ViewChild(DocumentTagComponent, { static: false })
	tagComponent: DocumentTagComponent;

	get form() {
		return this._form;
	}

	get practiceAreas() {
		return this._practiceAreas;
	}

	get documentCategories() {
		return this._documentCategories;
	}

	get entityTypeKeys() {
		return this._entityTypeKeys;
	}

	get showMarkup() {
		return this._showMarkup;
	}

	get codeBehindButtonOptions() {
		return this._codeBehindButtonOptions;
	}

	get isEdit() {
		return !!this._data?.id;
	}

	get showProgress() {
		return this._showProgress;
	}

	get existingDocuments() {
		return this._existingDocuments;
	}

	get progressPercentage() {
		return this._progress?.percentage;
	}

	get errorMessage() {
		return this._error?.message;
	}

	get documentTemplateControls() {
		return this.documentTemplatesArray?.controls;
	}

	get documentTemplatesArray() {
		if (!!this._form?.get('documentTemplateIds')) {
			return this._form.get('documentTemplateIds') as FormArray;
		}

		return null;
	}

	get templateEntityType() {
		return this._form?.get('entityType')?.value as keyof typeof TemplateEntityType;
	}

	get associatedPracticeAreaIds() {
		return this._form?.get('associatedPracticeAreaIds')?.value as string[];
	}

	private _subscriptions: Subscription = new Subscription();

	private _form: FormGroup;
	private _practiceAreas: PracticeAreaListItemDto[];
	private _documentCategories: DocumentCategoryListItemDto[];
	private _entityTypeKeys = [TemplateEntityType.Matter, TemplateEntityType.Contact];

	private _showMarkup: boolean;
	private _codeBehindButtonOptions: any = {
		text: 'Show Markup',
		stylingMode: 'text',
		onClick: () => this.toggleHtmlEditor()
	};

	private _showProgress: boolean = false;
	private _progress: IProgress = { percentage: 0 };
	private _existingDocuments: DocumentReference[] = [];
	private _documentIdsToRemove: string[] = [];
	private _error: IErrorContainer = { message: '' };
	private _uploadFilesInfo: IUploadMultipleFiles = null;

	constructor(
		@Inject(MAT_DIALOG_DATA) private _data: BaseDtoWithTrimmedId,
		private _dialogReference: MatDialogRef<EmailTemplatePropertiesComponent>,
		private _formBuilder: FormBuilder,
		private _templateService: DocumentEmailTemplatesService,
		private _practiceAreaService: PracticeAreasService,
		private _documentCategoryService: DocumentCategoryService,
		private _notificationService: NotificationService,
		private _appcuesService: AppcuesService,
		private _store: Store<IAppState>
	) {}

	toggleHtmlEditor() {
		this._showMarkup = !this._showMarkup;
	}

	ngOnInit(): void {
		this._form = this._formBuilder.group({
			associatedPracticeAreaIds: [],
			documentCategoryId: null,
			entityType: [null, CustomValidators.required('Related To')],
			title: [null, [CustomValidators.required('Title'), CustomValidators.validateFileName()]],
			documentTags: null,
			subject: null,
			content: null,
			documentTemplateIds: this._formBuilder.array([])
		});

		this._subscriptions.add(
			this._practiceAreaService
				.getPracticeAreaList({ sortBy: 'name', sortDirection: EnumSortDirection.Asc, showDisabled: true })
				.subscribe(next => (this._practiceAreas = next.records))
		);

		if (!!this._data?.id) {
			// load the record
			this._subscriptions.add(
				this._templateService
					.getDocumentEmailTemplate(this._data.id)
					.pipe(
						tap((document: DocumentEmailTemplateViewDto) => {
							this._existingDocuments = document.attachedDocuments;

							this._form.patchValue({
								associatedPracticeAreaIds: document.associatedPracticeAreaIds ?? [],
								documentCategoryId: document.documentCategoryId ?? null,
								entityType: document.entityType ?? null,
								title: document.title ?? null,
								documentTags: document.documentTags ?? [],
								subject: document.subject ?? null,
								content: document.content ?? null
							});

							document.documentTemplates?.forEach(document => {
								const documentTemplateControl = this._formBuilder.control(
									null,
									this.uniqueDocumentTemplatesValidator
								);

								documentTemplateControl.setValue(document.id);

								this.documentTemplatesArray.push(documentTemplateControl);
							});

							// If no practice areas are available for the template 'All' option is selected
							if (isEmpty(document.associatedPracticeAreaIds)) {
								setTimeout(() => {
									if (this.allOption) {
										this.allOption.select();
									}
								}, 0);
							}
						}),
						switchMap(() => this.populateDocumentCategories$())
					)
					.subscribe()
			);
		} else {
			this._form.reset();
		}

		this._subscriptions.add(
			this._form.get('associatedPracticeAreaIds').valueChanges.subscribe(value => {
				if (this._form.get('entityType').value === TemplateEntityType.Matter) {
					this.tagComponent.practiceAreaIds = value;
				} else {
					this.tagComponent.systemTags = [];
					this.tagComponent.inputCtrl.updateValueAndValidity();
				}
			})
		);
	}

	ngOnDestroy() {
		this._subscriptions.unsubscribe();
	}

	save(): void {
		if (!this._form?.valid) {
			return;
		}

		const request: DocumentEmailTemplateCreateUpdateDto = cloneDeep(this._form.value);
		remove(request.associatedPracticeAreaIds, isNil);

		if (this._form.get('entityType').value !== 'Matter') {
			request.associatedPracticeAreaIds = null;
		}

		request.documentIdsToRemove = this._documentIdsToRemove;

		if (!!this._data?.id) {
			this._subscriptions.add(
				this._templateService
					.update(this._data.id, request, this._uploadFilesInfo)
					.pipe(
						switchMap((result: Partial<IResultWithAttachments>) =>
							handleHttpOperationProgress<MutationResponseDto>(
								this,
								result,
								this._progress,
								this.handleCompleted
							)
						),
						catchError((errorResponse: HttpErrorResponse) => {
							this.toggleFormBusy(false);
							return handleHttpOperationError(errorResponse, this._error);
						})
					)
					.subscribe({
						next: reference => {
							this._store.dispatch(processRecords({ response: reference }));
							this._notificationService.showNotification(`Template updated: ${reference.name}`);
							this._dialogReference.close(reference);
						},
						error: errors => this._notificationService.showErrors(`Error updating template`, errors)
					})
			);

			this._appcuesService.trackEvent('SaveEmailDocumentTemplate');
		} else {
			this._subscriptions.add(
				this._templateService
					.create(request, this._uploadFilesInfo)
					.pipe(
						switchMap((result: Partial<IResultWithAttachments>) =>
							handleHttpOperationProgress<MutationResponseDto>(
								this,
								result,
								this._progress,
								this.handleCompleted
							)
						),
						catchError((errorResponse: HttpErrorResponse) => {
							this.toggleFormBusy(false);
							return handleHttpOperationError(errorResponse, this._error);
						})
					)
					.subscribe({
						next: reference => {
							this._store.dispatch(processRecords({ response: reference }));
							this._notificationService.showNotification(`Template created: ${reference.name}`);
							this._dialogReference.close(reference);
						},
						error: errors => this._notificationService.showErrors(`Error creating template`, errors)
					})
			);

			this._appcuesService.trackEvent('SaveEmailDocumentTemplate');
		}
	}

	displaySelected(values: string[]) {
		if (!values?.length) {
			return 'All';
		}

		const results = this._practiceAreas
			.filter(area => !!values.filter(id => id === area.id)?.length)
			.map(area => area.name);

		if (!results?.length) {
			return 'All';
		}

		if (results.length == 1) {
			return results[0];
		}

		return results.reduce((left, right) => `${left}, ${right}`);
	}

	allOptionSelected(event: MatOptionSelectionChange) {
		if (event.source.selected) {
			// When selecting the 'All' option, deselect all practice areas
			this.selectPracticeAreas.options.forEach(opt => {
				if (opt.selected && opt.id !== this.allOption.id) {
					opt.deselect();
				}
			});
		} else if (!this.selectPracticeAreas.options.some(opt => opt.selected)) {
			// If nothing is selected, select the 'All' option
			this.allOption.select();
		}
	}

	optionSelected(event: MatOptionSelectionChange) {
		if (this.allOption.selected && event.source.selected && event.source.id !== this.allOption.id) {
			// When selecting a practice area, deselect the 'All' option
			this.allOption.deselect();
		} else if (!this.selectPracticeAreas.options.some(opt => opt.selected)) {
			// If nothing is selected, select the 'All' option
			this.allOption.select();
		}
	}

	entityTypeChanged(event: MatSelectChange) {
		if (event.value === TemplateEntityType.Contact) {
			this._form.patchValue(
				{
					associatedPracticeAreaIds: null
				},
				{ emitEvent: false }
			);
		} else if (event.value === TemplateEntityType.Matter) {
			setTimeout(() => this.allOption.select(), 0);
		}
	}

	onErrorMessageUpdated(errorMessage: string) {
		this._error.message = errorMessage;
	}

	onDocumentIdsToRemoveUpdated(documentIdsToRemove: string[]) {
		this._documentIdsToRemove = documentIdsToRemove;
	}

	onUploadFilesInfoUpdated(uploadFilesInfo: IUploadMultipleFiles) {
		this._uploadFilesInfo = uploadFilesInfo;
	}

	private hasDuplicates(array: any[]) {
		return uniq(array).length !== array.length;
	}

	private uniqueDocumentTemplatesValidator: ValidatorFn = (control: AbstractControl): ValidationErrors => {
		if (!this.form) {
			return null;
		}

		const hasDuplicates = this.hasDuplicates(
			this.documentTemplateControls.filter(control => !!control?.value).map(control => control.value)
		);

		if (!!hasDuplicates) {
			return { error: `Document Template must be unique` };
		} else if (!control.value) {
			return { error: `Document Template must be entered` };
		} else {
			return null;
		}
	};

	onDocumentTemplateSelected(documentTemplateControl: AbstractControl, event: EntityReference) {
		documentTemplateControl.setValue(event?.id ?? null);
	}

	onNewTemplateClick(autoFocus = true): void {
		const documentTemplateControl = this._formBuilder.control(null, this.uniqueDocumentTemplatesValidator);

		this.documentTemplatesArray.push(documentTemplateControl);

		if (!!autoFocus) {
			setTimeout(() => {
				this.documentTemplateControlReferences.last?.setFocus();
			}, 0);
		}

		this._subscriptions.add(
			documentTemplateControl.valueChanges.subscribe(() => {
				setTimeout(() => {
					this.documentTemplatesArray.updateValueAndValidity();
				}, 0);
			})
		);

		setTimeout(() => {
			this.documentTemplatesArray.updateValueAndValidity();
		}, 0);
	}

	onRemoveTemplateClick(index: number) {
		this.documentTemplatesArray.removeAt(index);

		setTimeout(() => {
			this.documentTemplatesArray.updateValueAndValidity();
		}, 0);
	}

	private handleCompleted(
		parentContext: any,
		result: Partial<IResultWithAttachments>
	): Observable<MutationResponseDto> {
		parentContext.toggleFormBusy(false);

		if (!!result.mutationResponse) {
			return of(result.mutationResponse);
		}

		parentContext.error.message = 'Could not save payment. Please try again.';
		return EMPTY;
	}

	private toggleFormBusy(isBusy: boolean): void {
		if (isBusy) this.form.disable();
		else this.form.enable();

		this._showProgress = isBusy;
	}

	private populateDocumentCategories$() {
		// Get all available Document Categories
		return this._documentCategoryService
			.getDocumentCategoryList({ sortBy: 'name', sortDirection: EnumSortDirection.Asc })
			.pipe(tap(categories => (this._documentCategories = categories.records)));
	}
}
