import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

import { filter, of, Subscription } from 'rxjs';

import { RolePermission } from '@common/models/Common/RolePermission';
import { SecurityPolicies } from '@common/models/Settings/SecurityRoles/Common/SecurityPolicies';
import { SecurityRoleCreateUpdateDto } from '@common/models/Settings/SecurityRoles/Item/SecurityRoleCreateUpdateDto';
import { SecurityRoleViewDto } from '@common/models/Settings/SecurityRoles/Item/SecurityRoleViewDto';
import { NotificationService } from '@common/notification';
import { CustomValidators } from '@common/validation/custom.validators';

import { FeatureFlags, isFeatureFlagEnabled } from 'app/app.config';

@Component({
	styleUrls: ['./security-role-item.component.scss'],
	templateUrl: './security-role-item.component.html'
})
export class SecurityRoleItemComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('securityRoleNameInput')
	securityRoleInput: ElementRef;
	form: FormGroupTyped<SecurityRoleCreateUpdateDto>;
	data: SecurityRoleViewDto;
	isCreateMode: boolean;

	private subscriptions = new Subscription();
	private oldPolicies: (keyof typeof SecurityPolicies)[];

	constructor(
		@Inject(MAT_DIALOG_DATA) data: SecurityRoleViewDto,
		private fb: FormBuilder,
		private dialogRef: MatDialogRef<SecurityRoleItemComponent>,
		private notificationService: NotificationService
	) {
		this.data = data;
	}

	ngOnInit() {
		this.form = this.fb.group({
			name: [this.data?.name, CustomValidators.required('Name')],
			policies: [this.data?.policies ?? []],
			permissions: [this.data?.permissions ?? []]
		}) as FormGroupTyped<SecurityRoleCreateUpdateDto>;
		this.isCreateMode = !this.data?.id;

		this.oldPolicies = !!this.data?.policies ? [...this.data.policies] : [];
	}

	ngAfterViewInit() {
		if (this.securityRoleInput) {
			setTimeout(() => this.securityRoleInput.nativeElement.focus(), 0);
		}
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	isPermissionChecked(key: keyof typeof RolePermission): boolean {
		const index = this.form.value.permissions?.findIndex(x => x === key);
		return index >= 0 ? true : false;
	}

	onPermissionChanged(event: MatSlideToggleChange, key: keyof typeof RolePermission): void {
		const data = this.form.value;
		if (event.checked && data.permissions.findIndex(x => x === key) === -1) {
			data.permissions.push(key);
		} else {
			data.permissions = data.permissions.filter(x => x !== key);
		}
		this.form.setValue(data);
		this.form.markAsDirty();
	}

	permissionDisplayName(key: keyof typeof RolePermission): string {
		return RolePermission[key];
	}

	permissionHintText(key: keyof typeof RolePermission): string {
		switch (key) {
			case 'TrustCreateTransactions':
				return 'This setting permits users to create or update Receipts, Payments, Journals and Deposit Listings';
			case 'TrustCancelTransactions':
				return 'This setting permits  users to cancel trust transactions';
			default:
				return '';
		}
	}

	isPolicyChecked(key: keyof typeof SecurityPolicies): boolean {
		const index = this.form.value.policies?.findIndex(x => x === key);
		return index >= 0 ? true : false;
	}

	onPolicyChanged(event: MatSlideToggleChange, key: keyof typeof SecurityPolicies): void {
		const data = this.form.value;
		if (event.checked && data.policies.findIndex(x => x === key) === -1) {
			data.policies.push(key);

			if (key === 'SystemSettings') {
				this.addPermissions(this.systemPermissionKeys, data);
				this.form.controls.permissions.setValue(data.permissions);
			}

			if (key === 'Trust') {
				this.addPermissions(this.trustPermissionKeys, data);
				this.form.controls.permissions.setValue(data.permissions);
			}

			if (key === 'GlobalListSettings') {
				this.addPermissions(this.globalListPermissionKeys, data);
				this.form.controls.permissions.setValue(data.permissions);
			}
		} else {
			data.policies = data.policies.filter(x => x !== key);

			if (key === 'SystemSettings') {
				this.removePermissions(this.systemPermissionKeys, data);
				this.form.controls.permissions.setValue(data.permissions);
			}

			if (key === 'Trust') {
				this.removePermissions(this.trustPermissionKeys, data);
				this.form.controls.permissions.setValue(data.permissions);
			}

			if (key === 'GlobalListSettings') {
				this.removePermissions(this.globalListPermissionKeys, data);
				this.form.controls.permissions.setValue(data.permissions);
			}
		}
		this.form.setValue(data);
		this.form.markAsDirty();
	}

	removePermissions(permissionCollection: (keyof typeof RolePermission)[], data: SecurityRoleCreateUpdateDto) {
		permissionCollection.forEach((x: string) => {
			const index = data.permissions.findIndex(y => y === x);
			if (index !== -1) {
				data.permissions.splice(index, 1);
			}
		});
	}

	addPermissions(permissionCollection: (keyof typeof RolePermission)[], data: SecurityRoleCreateUpdateDto) {
		permissionCollection.forEach((x: string) => {
			const index = data.permissions.findIndex(y => y === x);
			if (index === -1) {
				data.permissions.push(x as keyof typeof RolePermission);
			}
		});
	}

	policyDisplayName(key: keyof typeof SecurityPolicies): string {
		return SecurityPolicies[key];
	}

	policyHintText(key: keyof typeof SecurityPolicies): string {
		switch (key) {
			case 'SystemSettings':
				return 'This setting permits users to modify system settings, including users and security roles.';
			case 'Trust':
				return (
					'Permits users to manage trust; including deposits, reconciliations and reports. ' +
					'Note that a user who will be managing trust should also be given access to matters in all practice areas.'
				);
			case 'DeleteMatters':
				return 'This setting permits users to delete matters and contacts.';
			case 'GlobalListSettings':
				return 'Permits users to manage access to global lists; including All Costs and All Invoices. ';
			case 'MatterSecurity':
				return 'Permits users to manage access to Matters, enabling this will give Matter Access irrespective of the Practice Area';
			default:
				return '';
		}
	}

	onSaveClicked() {
		const dto = this.form.value;
		const observable$ =
			!!dto.policies.includes('MatterSecurity') && !this.oldPolicies.includes('MatterSecurity')
				? this.notificationService.showConfirmation(
						'Save Security Role',
						'<p>Enabling <b>Manage Matter Security</b> will give <b>Matter Access</b> irrespective of the <b>Practice Area</b>.</p><p>Are you sure you want to continue?</p>',
						'Confirm',
						'Cancel'
				  )
				: of(true);

		this.subscriptions.add(observable$.pipe(filter(Boolean)).subscribe(() => this.dialogRef.close(dto)));
	}

	get policyKeys(): (keyof typeof SecurityPolicies)[] {
		return Object.keys(SecurityPolicies)
			.filter(
				(key: keyof typeof SecurityPolicies) =>
					SecurityPolicies[key] !== SecurityPolicies.None &&
					(!!isFeatureFlagEnabled(FeatureFlags.matterSecurity) ||
						SecurityPolicies[key] !== SecurityPolicies.MatterSecurity)
			)
			.map(key => key as keyof typeof SecurityPolicies);
	}

	get systemPermissionKeys(): (keyof typeof RolePermission)[] {
		return Object.keys(RolePermission)
			.filter((key: keyof typeof RolePermission) => RolePermission[key] !== RolePermission.None)
			.filter((key: keyof typeof RolePermission) => key.startsWith('System'))
			.map(key => key as keyof typeof RolePermission);
	}

	get trustPermissionKeys(): (keyof typeof RolePermission)[] {
		return Object.keys(RolePermission)
			.filter((key: keyof typeof RolePermission) => RolePermission[key] !== RolePermission.None)
			.filter((key: keyof typeof RolePermission) => key.startsWith('Trust'))
			.map(key => key as keyof typeof RolePermission);
	}

	get globalListPermissionKeys(): (keyof typeof RolePermission)[] {
		return Object.keys(RolePermission)
			.filter((key: keyof typeof RolePermission) => RolePermission[key] !== RolePermission.None)
			.filter((key: keyof typeof RolePermission) => key.startsWith('Global'))
			.map(key => key as keyof typeof RolePermission);
	}
}
