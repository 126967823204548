<mat-card>
	<section class="page-path">
		<a [routerLink]="['../..']">System</a> / <a [routerLink]="['..']">Practice Areas</a> /
		<span *ngIf="editId">{{ practiceAreaItem?.name }}</span>
		<span *ngIf="!editId">Create New</span>
	</section>
	<h1>Practice Area</h1>
	<section class="container">
		<mat-form-field class="practice-area-name">
			<input #practiceAreaInput matInput [(ngModel)]="practiceAreaItem.name" placeholder="Name" [required]="true" #titleCtrl="ngModel" />
			<mat-error *ngIf="titleCtrl.hasError('required')">
				Practice Area Name is
				<strong>required</strong>
			</mat-error>
		</mat-form-field>
		<br />
		<mat-form-field class="practice-area-description">
			<textarea matInput placeholder="Description" [(ngModel)]="practiceAreaItem.description"></textarea>
		</mat-form-field>
		<br />
		<mat-slide-toggle [(ngModel)]="practiceAreaItemEnabled">{{ !practiceAreaItemEnabled ? 'Disabled' : 'Enabled' }}</mat-slide-toggle>
		<br />
		<div class="stages-roles-container">
			<mat-card class="stages-card">
				<mat-card-title>
					Stages
					<button mat-button title="Create New" class="add-stage-button" throttleButton (throttledClick)="addNewStage()">
						<mat-icon class="create-new">add_circle</mat-icon>
						<span>Add Stage</span>
					</button>
				</mat-card-title>
				<mat-card-subtitle> Use stages to track the progress of your matters. </mat-card-subtitle>
				<mat-card-content>
					<div class="stage-container">
						<mat-table [dataSource]="practiceAreaItem.stages" cdkDropList (cdkDropListDropped)="drop($event)">
							<ng-container matColumnDef="Name">
								<mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
								<mat-cell *matCellDef="let row">
									<mat-icon>drag_indicator</mat-icon>
									<mat-form-field class="stage-name">
										<input matInput [(ngModel)]="row.name" [required]="true" #nameCtrl="ngModel" />
										<mat-error *ngIf="nameCtrl.hasError('required')">
											Name
											<strong>required</strong>
										</mat-error>
									</mat-form-field>
								</mat-cell>
							</ng-container>
							<ng-container matColumnDef="Description">
								<mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
								<mat-cell *matCellDef="let row">
									<mat-form-field class="stage-description">
										<input matInput [(ngModel)]="row.description" #titleCtrl="ngModel" />
									</mat-form-field>
								</mat-cell>
							</ng-container>
							<ng-container matColumnDef="MatterClosed">
								<mat-header-cell *matHeaderCellDef>Matter Closed</mat-header-cell>
								<mat-cell *matCellDef="let row">
									<mat-checkbox class="stage-matterclosed" [(ngModel)]="row.isMatterClosed"></mat-checkbox>
								</mat-cell>
							</ng-container>
							<ng-container matColumnDef="Delete">
								<mat-header-cell *matHeaderCellDef></mat-header-cell>
								<mat-cell *matCellDef="let row; index as practiceAreaIndex">
									<button
										mat-icon-button
										[title]="getTooltip(row)"
										[disabled]="row.usedByMatters > 0"
										[ngClass]="{ 'icon-disabled': row.usedByMatters > 0 }"
										throttleButton
										(throttledClick)="deleteStage(practiceAreaIndex)"
									>
										<mat-icon>delete</mat-icon>
									</button>
								</mat-cell>
							</ng-container>
							<mat-header-row *matHeaderRowDef="displayedStagesColumns"></mat-header-row>
							<mat-row *matRowDef="let row; columns: displayedStagesColumns" cdkDrag></mat-row>
						</mat-table>
					</div>
				</mat-card-content>
			</mat-card>
			<mat-card class="roles-card" *ngIf="securityRoles && securityRoles.length > 0">
				<mat-card-title>Security Roles</mat-card-title>
				<mat-card-subtitle>Control which roles can access the practice area. If left blank, then all roles will have access.</mat-card-subtitle>
				<mat-card-content>
					<mat-table [dataSource]="securityRoles">
						<ng-container matColumnDef="Name">
							<mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
							<mat-cell *matCellDef="let row">
								{{ row.name }}
							</mat-cell>
						</ng-container>
						<ng-container matColumnDef="Reader">
							<mat-header-cell *matHeaderCellDef>Access</mat-header-cell>
							<mat-cell *matCellDef="let row">
								<mat-checkbox
									class="role-reader"
									[disabled]="isContributor(row)"
									[checked]="isReader(row)"
									(change)="onReaderChange($event, row)"
								></mat-checkbox>
							</mat-cell>
						</ng-container>
						<ng-container matColumnDef="Contributor">
							<mat-header-cell *matHeaderCellDef>Contributor</mat-header-cell>
							<mat-cell *matCellDef="let row">
								<mat-checkbox
									class="role-contributor"
									[checked]="isContributor(row)"
									(change)="onContributorChange($event, row)"
								></mat-checkbox>
							</mat-cell>
						</ng-container>
						<mat-header-row *matHeaderRowDef="displayedRoleColumns"></mat-header-row>
						<mat-row *matRowDef="let row; columns: displayedRoleColumns"></mat-row>
					</mat-table>
					<div class="current-roles-config">
						Current configuration: <span class="readers-list">{{ readersAsList }}</span> {{ readersAsListPlural }} access to this practice area's
						matters.
					</div>
				</mat-card-content>
			</mat-card>
		</div>
		<div class="buttons">
			<div>
				<button mat-stroked-button color="warn" throttleButton (throttledClick)="onDelete()">Delete</button>
			</div>
			<div class="save">
				<button mat-raised-button color="primary" throttleButton (throttledClick)="onSave()">Save</button>
				<button mat-raised-button throttleButton (throttledClick)="cancelEditing()">Cancel</button>
			</div>
		</div>
	</section>
</mat-card>
