<mat-card>
	<section class="page-path"><a [routerLink]="['..']">System</a> / Subscription</section>

	<div class="header-text" *ngIf="isSubscribed">
		<div class="flexrow">
			<h1>Account</h1>
			<div class="member-since">
				<mat-icon>subscriptions</mat-icon>
				<span>Member since {{ upcomingInvoice?.customerSince | moment }}</span>
			</div>
		</div>
		<mat-divider></mat-divider>
	</div>

	<div class="container" *ngIf="!!subscriptionProducts">
		<div *ngIf="isSubscribed && !showplan" class="header">
			<div class="row" *ngIf="activeSubscriptionProduct?.name">
				<div class="label">
					<p>Current Plan</p>
				</div>
				<div class="value">
					<p>{{ activeSubscriptionProduct?.name }}</p>
				</div>
				<div class="action">
					<a throttleButton (throttledClick)="showplan = true" *ngIf="!isCancelled" class="hyperlink">Change Plan</a>
				</div>
			</div>
			<div class="row" *ngIf="tenantStripeInfo?.recurringInterval">
				<div class="label">
					<p>Billing Cycle</p>
				</div>
				<div class="value">
					<p>
						{{
							tenantStripeInfo?.recurringInterval === 'year' ? 'Yearly' : tenantStripeInfo?.recurringInterval === 'month' ? 'Monthly' : 'Unknown'
						}}
					</p>
				</div>
			</div>
			<div class="row" *ngIf="tenantStripeInfo?.quantity">
				<div class="label">
					<p>Number of Licenses</p>
				</div>
				<div class="value">
					{{ tenantStripeInfo?.quantity }}
				</div>
				<div class="action">
					<a
						*ngIf="tenantStripeInfo?.quantity >= numberOfActiveUsers && !isCancelled"
						throttleButton
						(throttledClick)="changeQuantity()"
						class="hyperlink"
						>Change Quantity</a
					>
				</div>
			</div>
			<div class="row" *ngIf="numberOfActiveUsers">
				<div class="label">
					<p>Number of Active Users</p>
				</div>
				<div class="value">
					{{ numberOfActiveUsers }}
				</div>
				<div class="action">
					<a *ngIf="tenantStripeInfo?.quantity > 0" [routerLink]="['/system/users']" class="hyperlink"> Allocate Licenses </a>
				</div>
			</div>
			<div class="row" *ngIf="upcomingInvoice?.balance < 0">
				<div class="label">
					<p>Available Credit</p>
				</div>
				<div class="value credit-balance">
					{{ -upcomingInvoice.balance | currency : '$' }}
				</div>
			</div>
			<div class="row" *ngIf="upcomingInvoice?.billingName">
				<div class="label">
					<p>Billing Name</p>
				</div>
				<div class="value">
					<p>{{ upcomingInvoice?.billingName }}</p>
				</div>
				<div class="action">
					<a throttleButton (throttledClick)="goToCustomerPortal()" class="hyperlink">Adjust Billing & Invoices</a>
				</div>
			</div>
			<div class="row" *ngIf="upcomingInvoice?.billingEmail">
				<div class="label">
					<p>Billing Email</p>
				</div>
				<div class="value">
					<p>{{ upcomingInvoice?.billingEmail }}</p>
				</div>
			</div>
			<div class="row" *ngIf="upcomingInvoice?.billingAddress">
				<div class="label">
					<p>Billing Address</p>
				</div>
				<div class="value">
					<p>{{ upcomingInvoice?.billingAddress }}</p>
				</div>
			</div>
			<div class="row" *ngIf="upcomingInvoice?.nextPaymentAttempt">
				<div class="label">
					<p>Next Billing Date</p>
				</div>
				<div class="value">
					<p>{{ upcomingInvoice?.nextPaymentAttempt | moment }}</p>
				</div>
			</div>

			<mat-progress-bar *ngIf="!upcomingInvoice" mode="indeterminate"></mat-progress-bar>
		</div>

		<div *ngIf="isSubscribed && tenantStripeInfo?.quantity < numberOfActiveUsers && !showplan" class="license-warning">
			<mat-icon color="warn">warning_amber</mat-icon>
			<span>
				You have {{ numberOfActiveUsers }} active users. However you only have {{ tenantStripeInfo?.quantity }}
				{{ tenantStripeInfo?.quantity === 1 ? ' License' : 'Licenses' }}. Please purchase
				{{ numberOfActiveUsers - tenantStripeInfo.quantity }} additional license(s).
			</span>
			<button
				*ngIf="tenantStripeInfo?.quantity < numberOfActiveUsers"
				class="buy-licenses"
				mat-raised-button
				color="accent"
				throttleButton
				(throttledClick)="buyAdditionalLicenses()"
			>
				Buy licenses
			</button>
		</div>
		<mat-icon *ngIf="isSubscribed && isCancelled" class="cancelled-module">warning</mat-icon>
		<div *ngIf="isSubscribed && isCancelled" class="cancel-text">
			Your plan will be cancelled, but is still available until the end of your billing period on
			{{ this.tenantStripeInfo?.cancelAt | moment : 'fullDate' }}. If you change your mind, you can
			<a class="hyperlink" throttleButton (throttledClick)="goToCustomerPortal()">renew your subscription</a>
		</div>

		<div
			*ngIf="isSubscribed && numberOfActiveUsers > numberOfLicensedUsers && tenantStripeInfo?.quantity > numberOfLicensedUsers && !showplan"
			class="license-warning"
		>
			<mat-icon color="accent">info</mat-icon>
			<span>
				You have {{ numberOfActiveUsers }} active {{ numberOfActiveUsers === 1 ? 'user' : 'users' }}, however you have only allocated
				{{ numberOfLicensedUsers }} {{ numberOfLicensedUsers === 1 ? 'License' : 'Licenses' }}. &nbsp;
			</span>
			<a [routerLink]="['/system/users']" class="hyperlink"> Allocate licenses </a>
		</div>

		<mat-button-toggle-group [(ngModel)]="recurringInterval" *ngIf="showplan || !isSubscribed">
			<mat-button-toggle value="month">Billed Monthly</mat-button-toggle>
			<mat-button-toggle value="year">Billed Annually</mat-button-toggle>
		</mat-button-toggle-group>

		<div class="module-container flexrow" *ngIf="showplan || !isSubscribed">
			<div
				class="module"
				[ngClass]="isSubscribed && (isActive(product) ? 'active-module' : 'inactive-module')"
				*ngFor="let product of subscriptionProducts"
			>
				<div *ngIf="product.ribbonText" class="ribbon">
					<span>{{ product.ribbonText }}</span>
				</div>

				<div class="module-content">
					<div class="heading-row" [ngClass]="{ 'compensate-ribbon-margin': product.ribbonText }">
						<div class="heading">{{ product.name }}</div>
					</div>
					<div class="price">{{ getPrice(product) | currency : '$' : 'symbol' : '1.0' }}</div>
					<span class="price-sub-text">(per user/{{ recurringInterval }})</span>
					<span *ngIf="recurringInterval === 'year' && getMonthlyPrice(product)" class="price-monthly-text">
						{{ getMonthlyPrice(product) }} per user/ month</span
					>
					<span class="gst-subtext"> Excluding GST</span>

					<button
						*ngIf="!isSubscribed && product.isSelectable"
						class="select-plan"
						mat-raised-button
						color="accent"
						throttleButton
						(throttledClick)="selectModule(product)"
					>
						Select Plan
					</button>
					<button
						*ngIf="isSubscribed && product.isSelectable"
						[ngClass]="!isActive(product) ? 'select-plan' : 'active-plan'"
						mat-raised-button
						color="accent"
						throttleButton
						(throttledClick)="!isActive(product) && previewUpcomingInvoice(product)"
					>
						{{ !isActive(product) ? upgradeText(product) : 'Active Plan' }}
					</button>

					<mat-accordion>
						<mat-expansion-panel [expanded]="panelOpenState" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
							<mat-expansion-panel-header>
								<mat-panel-description> {{ panelOpenState ? 'Hide' : 'See' }} Features </mat-panel-description>
							</mat-expansion-panel-header>
							<div class="description" [innerHTML]="safeHtml(product.description)"></div>
						</mat-expansion-panel>
					</mat-accordion>
				</div>
			</div>
		</div>
		<button *ngIf="showplan" mat-raised-button color="accent" throttleButton (throttledClick)="showplan = false">Go Back</button>
	</div>

	<div class="table-container" *ngIf="showProducts">
		<div class="table-heading">
			<h2>Marketplace Items</h2>
		</div>
		<mat-table [dataSource]="products">
			<ng-container matColumnDef="name">
				<mat-header-cell *matHeaderCellDef></mat-header-cell>
				<mat-cell *matCellDef="let row"
					><a [href]="getMarketplaceLink(row)">{{ row.name }}</a>
					<sub [innerHtml]="getProductStatusDisplayHtml(row)"> </sub>
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef="status">
				<mat-header-cell *matHeaderCellDef></mat-header-cell>
				<mat-cell *matCellDef="let row"
					><span [innerHtml]="getProductStatusDisplayHtml(row)"></span
					><button mat-raised-button color="primary" *ngIf="canSubscribe(row)" throttleButton (throttledClick)="subscribe(row)">
						<mat-icon>download</mat-icon>
						<span>Subscribe</span>
					</button>
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef="actions">
				<mat-header-cell *matHeaderCellDef></mat-header-cell>
				<mat-cell *matCellDef="let row">
					<button mat-icon-button [matMenuTriggerFor]="menu" title="Actions">
						<mat-icon>more_vert</mat-icon>
					</button>
					<mat-menu #menu="matMenu">
						<button mat-menu-item throttleButton (throttledClick)="unsubscribe(row)">
							<mat-icon>delete</mat-icon>
							<span>{{ unsubscribeText(row) }}</span>
						</button>
						<button mat-menu-item *ngIf="canSubscribe(row)" throttleButton (throttledClick)="subscribe(row)">
							<mat-icon>download</mat-icon>
							<span>Subscribe</span>
						</button>
					</mat-menu>
				</mat-cell>
			</ng-container>

			<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
			<mat-row *matRowDef="let row; columns: displayedColumns" [class.selected]="row.id == selectedId"></mat-row>
		</mat-table>
	</div>
</mat-card>
