import { animate, state, style, transition, trigger } from '@angular/animations';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';

import { EMPTY, forkJoin, of, Subject, throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';

import { EntityReference } from '@common/models/Common/EntityReference';
import { DocumentToBriefDto } from '@common/models/DocumentBriefs/Common/DocumentToBriefDto';
import { RemoveDocumentSectionDto } from '@common/models/DocumentBriefs/Common/RemoveDocumentSectionDto';
import { BriefSection } from '@common/models/DocumentBriefs/Item/BriefSection';
import { DocumentBriefViewDto } from '@common/models/DocumentBriefs/Item/DocumentBriefViewDto';
import { ExportBriefParametersDto } from '@common/models/DocumentBriefs/Item/ExportBriefParametersDto';
import { DocumentBriefListItemDto } from '@common/models/DocumentBriefs/List/DocumentBriefListItemDto';
import { BriefDetail } from '@common/models/Documents/Common/BriefDetail';
import { DocumentType } from '@common/models/Documents/Common/DocumentType';
import { DocumentComposeWithAttachmentsDto } from '@common/models/Documents/Item/DocumentComposeWithAttachmentsDto';
import { DocumentFromTemplateDto } from '@common/models/Documents/Item/DocumentFromTemplateDto';
import { DocumentReorderRequestDto } from '@common/models/Documents/Item/DocumentReorderRequestDto';
import { DocumentIdsCsvExportRequest } from '@common/models/Documents/List/DocumentIdsCsvExportRequest';
import { DocumentListCsvExportColumnsEnum } from '@common/models/Documents/List/DocumentListCsvExportColumnsEnum';
import { DocumentListCsvExportRequest } from '@common/models/Documents/List/DocumentListCsvExportRequest';
import { DocumentListItemDto } from '@common/models/Documents/List/DocumentListItemDto';
import { DocumentTagsRequest } from '@common/models/Documents/List/DocumentTagsRequest';
import { TemplateEntityType } from '@common/models/Documents/TemplateDto/TemplateEntityType';
import { ListResponse } from '@common/models/Generic/ListResponse';
import { MatterLookupDto } from '@common/models/Matters/Lookup/MatterLookupDto';
import { Features } from '@common/models/Settings/Modules/Features';
import { DomainError } from '@common/models/Validation/DomainError';
import { NotificationService } from '@common/notification';
import { DocumentBriefsService } from '@common/services/documentbriefs.service';
import { DocumentAccessTokensService } from '@common/services/settings/documentaccesstokens.service';
import { LocalStorageService } from '@common/services/storage/local-storage.service';
import { TasksService } from '@common/services/tasks.service';
import { isPdfConversionSupported } from '@common/utils/file-extensions';
import { Store } from '@ngrx/store';
import { cloneDeep, flatten, get, isEmpty, isEqual, isNil, pull, union, uniq, uniqBy } from 'lodash-es';
import moment from 'moment';

import { AppConfig, FeatureFlags, isFeatureFlagEnabled } from 'app/app.config';
import { DialogComponent } from 'app/core/dialog.component';
import { DialogConfig, DialogType, EntityType } from 'app/core/dialog.config';
import { EntityCreatedNotificationService } from 'app/core/entityCreatedNotification.service';
import { SignalrService } from 'app/core/signalr/signalr.service';
import { IAppState } from 'app/core/state/app.state';
import { processRecords } from 'app/core/state/lists/document-list/document-list.actions';
import { selectDocumentListResponses } from 'app/core/state/lists/document-list/document-list.selectors';
import { DocumentServiceType } from 'app/core/state/lists/document-list/document-list.state';
import { getCurrentPage } from 'app/core/state/misc/current-page/current-page.reducer';
import { CurrentPageType, ICurrentPageState } from 'app/core/state/misc/current-page/current-page.state';
import { AppMessagingService, FAVOURITE_DOCUMENT } from 'app/services/app-messaging.service';
import { DocumentsEditService } from 'app/services/documents-edit.service';
import { DocumentsService } from 'app/services/documents.service';
import { GridViewService } from 'app/services/grid-view.service';
import { ITemplateMergeData } from 'app/shared/template-merge/ITemplateMergeData';
import { TemplateMergeDialogComponent } from 'app/shared/template-merge/template-merge-dialog.component';

import { BriefDialogComponent } from '../item/brief-dialog.component';
import { IBriefDialogData } from '../item/briefDialogData';
import {
	CreateBriefDialogComponent,
	DialogOperation,
	IDocumentBriefDialogData,
	IDocumentBriefDialogResponseData
} from '../item/create-brief-dialog.component';
import { EditDocumentComponent } from '../item/edit-document.component';
import { DocumentBriefAccessDialogComponent } from './brief-access-dialog/document-brief-access-dialog.component';
import {
	BriefPDFExportDialogComponent,
	IBriefPDFExportData
} from './brief-pdf-export-dialog/brief-pdf-export-dialog.component';
import { BulkAddTagsDialogComponent } from './bulk-tags-dialog/bulk-add-tags-dialog.component';
import {
	CreateBlankDialogComponent,
	ICreateBlankDialogData
} from './create-blank-dialog/create-blank-dialog.component';
import { DocumentAccessDialogComponent } from './document-access-dialog/document-access-dialog.component';
import { DocumentListBaseComponent } from './document-list-base.component';
import { DocumentListDialogComponent, IDocumentListDialogData } from './document-list-dialog.component';
import { DocumentListItemExtDto } from './DocumentListItemExtDto';
import {
	EmailDocumentsDialogComponent,
	IEmailDocumentsDialogData
} from './email-documents-dialog/email-documents-dialog.component';

@Component({
	selector: 'document-list',
	styleUrls: ['./document-list.component.scss'],
	templateUrl: 'document-list.component.html',
	animations: [
		trigger('slideInOut', [
			state(
				'in',
				style({
					width: '220px'
				})
			),
			state(
				'out',
				style({
					width: '0px'
				})
			),
			transition('in <=> out', animate(200))
		])
	]
})
export class DocumentListComponent
	extends DocumentListBaseComponent<DocumentListItemExtDto>
	implements OnInit, AfterViewInit
{
	featureTypes: typeof Features = Features;
	featureFlags: typeof FeatureFlags = FeatureFlags;

	documentTags: string[];
	filteredDocumentTags: string[];
	form: FormGroup;
	matterId: string;
	matterNumber: string;
	contactId: string;
	selectedTags: string[] = [];
	briefId: string;
	sectionId: string;
	brief: DocumentBriefViewDto;
	briefs: DocumentBriefListItemDto[];
	isMatchAll = false;
	tagsFetched = false;
	isReadonly = false;
	viewMode: 'document' | 'brief' = 'document';
	isXSmall: boolean;
	isSmall: boolean;
	isMedium: boolean;
	isFavorited: boolean;
	isShared: boolean;
	animationState: string = 'in';
	filterVersionsForDocumentId: string;
	chipFilters: IChipFilter[] = [];

	isOutlookActive: boolean;

	@ViewChild(MatTable, { static: true }) table: MatTable<DocumentListItemExtDto>;
	@ViewChild(MatSort) matSort: MatSort;

	private replyDocumentUrlScheme = 'mailread:';

	get tableHeight(): string {
		return '100vh';
	}

	// Displayed columns [overriden]
	get displayedColumns(): string[] {
		// Conditional hiding of columns
		return this.defaultDisplayedColumns.reduce((accumulator, current) => {
			if ((!this.briefId || !this.sectionId) && current === 'reorder') {
				return accumulator;
			}
			if (!this.showRelatedTo && current === 'relatedTo') {
				return accumulator;
			}
			if ((this.isPreviewMode || this.briefs?.length === 0 || this.sectionId) && current === 'associatedBriefs') {
				return accumulator;
			}

			if (
				(current === 'userChangeTimestamp' || current === 'lastModifiedDate') &&
				this.animationState === 'in' &&
				(this.isSmall || this.isMedium)
			) {
				return accumulator;
			} else if (this.showMyLastChangeTime && current === 'lastModifiedDate') {
				return [...accumulator, 'userChangeTimestamp'];
			}

			if (
				this.animationState === 'in' &&
				(this.isMedium || this.isSmall || this.isXSmall) &&
				current === 'documentTags'
			) {
				return accumulator;
			}
			return [...accumulator, current];
		}, []);
	}

	get canCopy(): boolean {
		return !this.briefId && !!this.FilterBase && (!!this.FilterBase.matterId || !!this.FilterBase.contactId);
	}

	get searchTagsControl(): FormControl {
		return this.form.get('searchTags') as FormControl;
	}

	get documentTagsGroup(): FormGroup {
		return this.form.controls['documentTags'] as FormGroup;
	}

	toggleMenu() {
		this.animationState = this.animationState === 'out' ? 'in' : 'out';
	}

	get isVersionFilterActive(): boolean {
		return !isNil(this.filterComponent?.filter?.filterVersionsForDocumentId);
	}

	private _refreshTagsSubject = new Subject<boolean>();

	constructor(
		dialog: MatDialog,
		router: Router,
		activatedRoute: ActivatedRoute,
		gridViewService: GridViewService,
		private currentStore: Store<IAppState>,
		notification: NotificationService,
		private documentService: DocumentsService,
		entityCreationNotifService: EntityCreatedNotificationService,
		@Inject(DOCUMENT) document: Document,
		documentsEditService: DocumentsEditService<DocumentListItemExtDto>,
		taskService: TasksService,
		private documentBriefsService: DocumentBriefsService,
		private fb: FormBuilder,
		private breakpoint: BreakpointObserver,
		private appMessagingService: AppMessagingService,
		private signalRService: SignalrService,
		private localStorageService: LocalStorageService,
		private accessTokensService: DocumentAccessTokensService
	) {
		super(
			[
				'createdDate',
				'title',
				'relatedTo',
				'associatedBriefs',
				'documentTags',
				'lastModifiedDate',
				'reorder',
				'action'
			],
			dialog,
			router,
			activatedRoute,
			gridViewService,
			currentStore,
			notification,
			documentService,
			entityCreationNotifService,
			document,
			documentsEditService,
			taskService
		);

		const urlTree = this.router.parseUrl(this.router.url);
		if (!urlTree.queryParams['briefId']) {
			this.enableSortStorage = true;
		}

		this.columnDefinitionNameMap = {
			title: () => 'Title',
			relatedTo: () => 'Related To',
			createdDate: () => 'Created',
			lastModifiedDate: () => 'Last Modified',
			associatedBriefs: () => (!!this.briefId ? 'Section' : 'Brief'),
			userChangeTimestamp: () => 'My Last Change',
			documentTags: () => 'Tags',
			reorder: () => 'Reorder',
			action: () => 'Actions'
		};
	}

	// Indicates if the page is in preview mode.
	get isPreviewMode(): boolean {
		return this.activatedRoute.root.snapshot.children.find(child => child.outlet === 'preview') != null;
	}

	isUncategorised(row: DocumentListItemDto) {
		if (!!row) {
			const sections = row.associatedBriefs.map(brief => brief.sections).reduce((a, b) => a.concat(b), []);
			return sections?.length === 1 && sections[0].name.toLowerCase() === 'uncategorised';
		}
		return null;
	}

	getDocumentVersionDisplayNumber(row: DocumentListItemDto): string {
		if (!!row) {
			const versions = row?.versions;

			if (versions?.length > 1) {
				const isLatest = versions[versions.length - 1] === row.id;
				return versions.indexOf(row.id) + 1 + (isLatest && !!this.filterVersionsForDocumentId ? ' latest' : '');
			} else {
				return '1 latest';
			}
		}
		return null;
	}

	clearVersionFilter(): void {
		this.filterComponent.filter.filterVersionsForDocumentId = null;
		this.filterVersionsForDocumentId = null;
		this.filterComponent.applyFilter(true);

		this.clearChips(versionFilterChipName);
	}

	email(document: DocumentListItemDto): void {
		if (!!isPdfConversionSupported(document.fileExtension)) {
			const data: IEmailDocumentsDialogData = {
				documents: [
					{
						id: document.id,
						title: document.title,
						fileExtension: document.fileExtension,
						hasAttachments: document.hasAttachments
					}
				]
			};

			this.dialog.open(EmailDocumentsDialogComponent, { data: data });
		} else {
			const dto: DocumentComposeWithAttachmentsDto = {
				documents: [
					{
						documentId: document.id,
						convertToPdf: false
					}
				]
			};

			this.subscriptions.add(
				this.docService.composeEmailWithAttachments(dto).subscribe({
					next: () => this.notification.showNotification('Ensure the Outlook Add-in is open and pinned'),
					error: error => this.notification.showErrors('Error Emailing Documents', error)
				})
			);
		}
	}

	emailMultipleDocuments() {
		this.subscriptions.add(
			this.selected$
				.pipe(
					filter(selected => !isNil(selected) && !isEmpty(selected)),
					switchMap(selected => {
						const haveDifferentMatters =
							uniqBy(
								selected.filter(item => !!item.associatedMatter),
								selected => selected.associatedMatter.id
							).length > 1;

						const data: IEmailDocumentsDialogData = {
							documents: selected.map(selected => ({
								id: selected.id,
								title: selected.title,
								fileExtension: selected.fileExtension,
								hasAttachments: selected.hasAttachments
							}))
						};

						let handler$: Observable<any>;
						if (haveDifferentMatters) {
							handler$ = this.notification
								.showConfirmation(
									'Matters associated to Documents',
									'The selected documents are associated to different matters.  Are you sure you want to continue?'
								)
								.pipe(filter(Boolean));
						}

						if (!!selected.filter(document => !!isPdfConversionSupported(document.fileExtension))?.length) {
							const data: IEmailDocumentsDialogData = {
								documents: selected.map(item => ({
									id: item.id,
									title: item.title,
									fileExtension: item.fileExtension,
									hasAttachments: item.hasAttachments
								}))
							};

							handler$ = handler$.pipe(
								switchMap(() =>
									this.dialog.open(EmailDocumentsDialogComponent, { data: data }).afterClosed()
								)
							);
						} else {
							const dto: DocumentComposeWithAttachmentsDto = {
								documents: selected.map(item => ({
									documentId: item.id,
									convertToPdf: false
								}))
							};

							handler$ = handler$.pipe(
								switchMap(() => this.docService.composeEmailWithAttachments(dto)),
								catchError(error => {
									this.notification.showErrors('Error Emailing Documents', error);
									return throwError(() => error);
								}),
								tap(() =>
									this.notification.showNotification('Ensure the Outlook Add-in is open and pinned')
								)
							);
						}

						return handler$;
					})
				)
				.subscribe({
					next: () => this.notification.showNotification('Ensure the Outlook Add-in is open and pinned'),
					error: error => this.notification.showErrors('Error Emailing Documents', error)
				})
		);
	}

	enableVersionFilter(row: { id: string; title: string }): void {
		if (!!row) {
			this.filterComponent.filter.filterVersionsForDocumentId = row.id;
			this.filterVersionsForDocumentId = row.id;
			this.filterComponent.applyFilter(true);

			this.clearChips(versionFilterChipName);
			this.pushChipFilter({
				title: row.title,
				id: row.id,
				filterName: versionFilterChipName
			});
		}
	}

	toggleVersionFilter(row: DocumentListItemDto) {
		if (!this.isVersionFilterActive) {
			this.enableVersionFilter(row);
			//this.refreshList();
		} else {
			this.clearVersionFilter();
			//this.refreshList();
		}
	}

	clearChips(filterName?: string): void {
		if (filterName) {
			this.chipFilters = this.chipFilters.filter(x => x.filterName !== filterName);
		} else {
			this.chipFilters = [];
		}
	}

	pushChipFilter(chip: IChipFilter): void {
		if (!this.chipFilters.map(x => x.filterName).includes(chip.filterName)) {
			this.chipFilters.push(chip);
			this.animationState = 'in';
		}
	}

	popChip(chip: IChipFilter): void {
		const index = this.chipFilters.indexOf(chip);
		if (index >= 0) {
			this.chipFilters.splice(index, 1);
		}

		if (chip.filterName === versionFilterChipName) {
			this.filterComponent.filter.filterVersionsForDocumentId = null;
			this.filterVersionsForDocumentId = null;
			this.filterComponent.applyFilter(true);
		}
	}

	containsSection(documentId: string, sectionId: string): boolean {
		const result = this.brief?.sections
			.filter(x => x.id === sectionId)
			.map(x => x.documentIds.includes(documentId));
		return result.includes(false);
	}

	shouldDisplayMoveSection(row: DocumentListItemDto) {
		if (!!row) {
			const sections = row.associatedBriefs.map(brief => brief.sections).reduce((a, b) => a.concat(b), []);
			return this.brief?.sections?.length > 1 && sections?.length < this.brief.sections.length;
		}
		return false;
	}

	editDetails(document: DocumentListItemExtDto): void {
		const dialogRef =
			document.type === DocumentType.Note
				? this.dialog.open(DialogComponent, {
						data: new DialogConfig(DialogType.Edit, EntityType.Note, document.id)
				  })
				: this.dialog.open(EditDocumentComponent, { data: { id: document.id, isCopy: false } });

		this.subscriptions.add(
			dialogRef
				.afterClosed()
				.pipe(
					filter(Boolean),
					tap(() => this.refreshDocumentTags(true))
				)
				.subscribe()
		);
	}

	ngOnInit() {
		this.serviceType = DocumentServiceType.Document;

		this.form = this.fb.group({
			documentTags: new FormGroup({}),
			searchTags: null,
			searchSection: null
		});

		// Get query params for sidebar filters
		this.subscriptions = this.activatedRoute.queryParamMap.pipe(distinctUntilChanged()).subscribe(params => {
			if (params.has('isFavorited')) {
				this.isFavorited = !!(params.get('isFavorited').toLocaleLowerCase() === 'true') ? true : null;
			}

			if (params.has('isShared')) {
				this.isShared = !!(params.get('isShared').toLocaleLowerCase() === 'true') ? true : null;
			}

			if (params.has('documentTags')) {
				this.selectedTags = params.getAll('documentTags');
			}
		});

		this.subscriptions.add(
			this._refreshTagsSubject
				.pipe(
					debounceTime(100),
					map(force => {
						let request: Partial<DocumentTagsRequest> = {};
						if (!!this.filterComponent.filter) {
							// Need to deep clone here as Object.assign keeps reference and changing
							// documentTags below changes the original filter request
							request = cloneDeep(Object.assign(this.filterComponent.filter));
						}

						request.matterId = this.matterId;
						request.contactId = this.contactId;
						request.documentTags = this.isMatchAll ? this.selectedTags : [];

						return { request: request, force: force };
					}),
					distinctUntilChanged((old, next) => {
						if (!!next.force) {
							return false;
						}

						if (!old?.request && !next?.request) {
							return true;
						}

						return !!old?.request && !!next?.request && !!isEqual(old.request, next.request);
					}),
					map(tuple => tuple.request),
					switchMap(request => this.docService.getDocumentTags(request))
				)
				.subscribe({
					next: tags => this.setTagsControls(tags),
					error: errors => this.notification.showError('Error getting Tags', errors)
				})
		);

		this.subscriptions.add(
			this.currentStore
				.select(getCurrentPage)
				.pipe(
					map(page => {
						// If global matter, get all tags

						const url = this.router.url.toLowerCase();

						if (url.includes('/matters') || url.includes('/contacts')) {
							return page;
						}

						this.refreshDocumentTags();
						return null;
					}),
					filter(page => !isNil(get(page, 'id'))),
					// If the document list is opened from within the matter or contact context,
					// get the briefs, favorites, shared and tags that are only applicable for that matter/contact
					tap((pageData: ICurrentPageState) => {
						if (
							pageData.pageType === CurrentPageType.Matter &&
							(pageData.lookup as MatterLookupDto).status === 'Closed'
						) {
							this.isReadonly = true;
						}

						this.tagsFetched = true;
						this.matterId =
							this.getEntityType() === EntityType.Matter ? pageData.id || this.FilterBase.matterId : null;
						this.matterNumber =
							this.getEntityType() === EntityType.Matter
								? (pageData.lookup as MatterLookupDto).number
								: null;
						this.contactId = this.getEntityType() === EntityType.Contact ? pageData.id : null;

						this.refreshDocumentTags();
					}),
					switchMap(() =>
						forkJoin([
							this.refreshDocumentBriefList$(),
							this.activatedRoute.queryParams.pipe(
								tap(params => {
									this.briefId = params['briefId'];
									if (!!params['briefId'] && this.viewMode === 'document') {
										this.viewMode = 'brief';
										this.updateBriefMode(true);
									}
									if (!params['briefId'] && this.viewMode === 'brief') {
										this.viewMode = 'document';
										this.updateBriefMode(false);
									}
								})
							)
						])
					)
				)
				.subscribe()
		);

		const urlTree = this.router.parseUrl(this.router.url);
		if (!!urlTree.queryParams['briefId']) {
			this.briefId = urlTree.queryParams['briefId'];
		}

		this.subscriptions.add(
			this.form.get('searchTags').valueChanges.subscribe(term => {
				if (!term) {
					this.assignCopy();
				}
				this.filteredDocumentTags = Object.assign([], this.documentTags).filter(
					item => item.toLowerCase().indexOf(term.toLowerCase()) > -1
				);
			})
		);

		this.subscriptions.add(
			this.filterComponent.filterChange.subscribe(() => {
				this.filterComponent.filter.documentTags = this.selectedTags;
				this.filterComponent.filter.isMatchAllTags = this.isMatchAll;
				this.filterComponent.filter.briefId = this.briefId;
				this.filterComponent.filter.sectionId = this.sectionId;
				this.filterComponent.filter.isFavorited = this.isFavorited;
				this.filterComponent.filter.isShared = this.isShared;
				this.filterComponent.filter.filterVersionsForDocumentId = this.filterVersionsForDocumentId;
				this.refreshDocumentTags();
			})
		);

		this.subscriptions.add(
			this.breakpoint.observe([Breakpoints.XSmall]).subscribe(state => {
				this.isXSmall = state.matches;
			})
		);

		this.subscriptions.add(
			this.breakpoint.observe([Breakpoints.Small]).subscribe(state => {
				this.isSmall = state.matches;
			})
		);

		this.subscriptions.add(
			this.breakpoint.observe([Breakpoints.Medium]).subscribe(state => {
				this.isMedium = state.matches;
			})
		);

		this.subscriptions.add(
			this.signalRService
				.onDocumentLockStatusChanged()
				.pipe(
					switchMap(response =>
						this.data$.pipe(
							take(1),
							map(items => ({ response, items }))
						)
					)
				)
				.subscribe(tuple => {
					const foundDocuments = tuple.items.filter(item => item.id === tuple.response.documentId);

					if (!!foundDocuments?.length) {
						foundDocuments[0].lockExpires = tuple.response.lockExpires;
						foundDocuments[0].lockedBy = tuple.response.lockedBy;
					}
				})
		);

		this.subscriptions.add(
			this.signalRService
				.onEmailDocumentAvailabilityChanged()
				.subscribe(dto => (this.isOutlookActive = !!dto?.canEmail))
		);

		super.ngOnInit();

		this.data$ = this.data$.pipe(
			map(documents =>
				documents.map(document => ({
					...document,
					icon: this.getFileIcon(document.fileExtension, document.hasAttachments),
					class: this.getFileIcon(document.fileExtension)
				}))
			)
		);
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
		// This is required for the ExpressionChangedAfterItHasBeenCheckedError error.
		setTimeout(() => {
			this.briefId = this.filterComponent.filter.briefId;
			if (!!this.briefId) this.pageSize = 10000;
			if (this.isSmall) {
				this.animationState = 'out';
			}
		}, 0);
	}

	favoriteDocument(row: DocumentListItemExtDto, markAsFavorite: boolean) {
		this.subscriptions.add(
			!!markAsFavorite
				? this.documentService.markAsFavorite(row.id).subscribe(response => {
						this.store.dispatch(processRecords({ response: response }));
						this.notification.showNotification(`The document "${row.title}" has been added to favourites`);
						this.appMessagingService.fireMessage(FAVOURITE_DOCUMENT);
				  })
				: this.documentService.unmarkAsFavorite(row.id).subscribe(response => {
						this.store.dispatch(processRecords({ response: response }));
						this.notification.showNotification(
							`The document "${row.title}"" has been removed from favourites`
						);
						this.appMessagingService.fireMessage(FAVOURITE_DOCUMENT);
				  })
		);
	}

	initiateNewMerge(matterId: string): void {
		this.router.navigate([`../merge`]);
	}

	loadBrief(id: string, defaultSectionId: string = null) {
		if (!!id) {
			this.subscriptions.add(
				this.documentBriefsService.getDocumentBrief(id).subscribe(brief => {
					this.brief = brief;
				})
			);
		}

		this.filterComponent.filter.briefId = id;
		this.selectedTags = [];
		this.filterComponent.filter.documentTags = [];
		this.sectionId = defaultSectionId;
		this.filterComponent.filter.sectionId = null;
		this.isFavorited = null;
		this.filterComponent.filter.isFavorited = this.isFavorited;
		this.isShared = null;
		this.filterComponent.filter.isShared = this.isShared;
		this.isMatchAll = null;
		this.filterComponent.filter.isMatchAllTags = this.isMatchAll;
		this.briefId = id;
		this.filterComponent.applyFilter(true);
	}

	reloadBrief() {
		// If the brief and section is already selected, keep the selection.
		if (!!this.briefId) this.loadBrief(this.briefId, this.sectionId ? this.sectionId : null);
	}

	loadBriefs() {
		this.subscriptions.add(this.refreshDocumentBriefList$().subscribe());
	}

	private refreshDocumentBriefList$(): Observable<ListResponse<DocumentBriefListItemDto>> {
		return this.documentBriefsService.getDocumentBriefList({ matterId: this.matterId, sortBy: 'name' }).pipe(
			tap(response => {
				this.briefs = response.records;
			})
		);
	}

	openBriefAccessDialog(): void {
		this.dialog.open(DocumentBriefAccessDialogComponent, {
			data: {
				briefId: this.briefId,
				briefName: this.brief.name,
				matterNumber: this.matterNumber,
				matterId: this.matterId
			}
		});
	}

	openDocumentAccessDialog(row: DocumentListItemDto): void {
		this.subscriptions.add(
			this.dialog
				.open(DocumentAccessDialogComponent, {
					data: {
						documentId: row.id,
						documentName: row.title,
						matterNumber: this.matterNumber,
						matterId: this.matterId
					}
				})
				.afterClosed()
				.subscribe()
		);
	}

	get showExportToPdf(): boolean {
		return !!this.briefId && isFeatureFlagEnabled(FeatureFlags.mergePdf);
	}

	openPDFExportDialog(): void {
		this.subscriptions.add(
			this.documentBriefsService
				.getExportBriefParameters(this.briefId)
				.subscribe((params: ExportBriefParametersDto) => {
					const data: IBriefPDFExportData = { briefId: this.briefId, parameters: params };
					this.dialog.open(BriefPDFExportDialogComponent, {
						data,
						width: '760px',
						maxHeight: '90vh'
					});
				})
		);
	}

	openCsvExportDialogue(): void {
		const standardFields: DocumentListCsvExportColumnsEnum[] = [
			DocumentListCsvExportColumnsEnum.CreatedDate,
			DocumentListCsvExportColumnsEnum.LastModifiedDate,
			DocumentListCsvExportColumnsEnum.MatterNumber,
			DocumentListCsvExportColumnsEnum.MatterTitle,
			DocumentListCsvExportColumnsEnum.AssociatedContactName, // Related To
			DocumentListCsvExportColumnsEnum.FileName, // Title
			DocumentListCsvExportColumnsEnum.DocumentTags,
			DocumentListCsvExportColumnsEnum.VersionNumber,
			DocumentListCsvExportColumnsEnum.Type,
			DocumentListCsvExportColumnsEnum.FileExtension,
			DocumentListCsvExportColumnsEnum.CreatedBy,
			DocumentListCsvExportColumnsEnum.LastModifiedBy
		];

		this.showExportDialog(
			'Document List',
			this.dialog,
			(request: DocumentIdsCsvExportRequest) => {
				request.standardFields = standardFields;
				return this.documentService.exportDocumentsCsvByIds(request);
			},
			(request: DocumentListCsvExportRequest & { serviceType: keyof typeof DocumentServiceType }) => {
				request.standardFields = standardFields;
				return this.documentService.exportDocumentsCsvByFilter(request);
			},
			'Note: Only document metadata will be exported.',
			'Export'
		);
	}

	refreshDocumentTags(force?: boolean): void {
		this._refreshTagsSubject.next(!!force);
	}

	tagClicked(tag: string) {
		this.form.get('searchTags').setValue('');

		if (this.selectedTags.map(x => x.toLowerCase()).indexOf(tag.toLowerCase()) === -1) {
			this.selectedTags.push(tag);
		} else {
			pull(this.selectedTags, tag);
		}

		this.filterComponent.filter.documentTags = this.selectedTags;
		this.filterComponent.applyFilter(true);
	}

	sectionClicked(sectionId: string) {
		this.filterComponent.filter.sectionId = sectionId;
		this.sectionId = sectionId;
		this.rearrangeSorting();
		this.filterComponent.applyFilter(true);
	}

	clearAllTags() {
		this.clearVersionFilter();

		this.selectedTags = [];
		this.isFavorited = null;
		this.isShared = null;
		this.form.get('searchTags').setValue('');
		this.filterComponent.filter.isFavorited = this.isFavorited;
		this.filterComponent.filter.isShared = this.isShared;
		this.filterComponent.filter.documentTags = this.selectedTags;
		this.filterComponent.applyFilter(true);
	}

	isSelected(tag: string) {
		return this.selectedTags?.indexOf(tag) !== -1;
	}

	assignCopy() {
		this.filteredDocumentTags = Object.assign([], this.documentTags);
	}

	briefSelected(entity: EntityReference | MatSelectChange): void {
		const id = get(entity, 'id', get(entity, 'value')?.id);

		this.loadBrief(id);

		if (!!id) {
			this.storeBriefLastSelected(id);
		}
	}

	createDocumentBrief() {
		const data: IDocumentBriefDialogData = {
			briefId: null,
			matterId: this.matterId,
			isTryingToAddNewSection: false
		};

		this.subscriptions.add(
			this.dialog
				.open(CreateBriefDialogComponent, { data })
				.afterClosed()
				.pipe(filter(Boolean))
				.subscribe((briefDialogResponse: IDocumentBriefDialogResponseData) => {
					this.goToBrief(briefDialogResponse?.brief);
					this.notification.showNotification(
						`Brief ${briefDialogResponse?.brief.name} created successfully.`
					);
				})
		);
	}

	addToExistingBrief() {
		this.subscriptions.add(
			this.selected$
				.pipe(
					take(1),
					map(selected => {
						if (!selected?.length) {
							this.notification.showNotification(`Please select the documents by Ctrl + Click`);
							return null;
						}

						const data: IBriefDialogData = {
							matterId: this.matterId,
							documentIds: selected.map(d => d.id)
						};

						return data;
					}),
					filter(Boolean)
				)
				.subscribe(data => this.dialog.open(BriefDialogComponent, { data }))
		);
	}

	openDocumentListDialog() {
		const data: IDocumentListDialogData = {
			briefId: this.briefId,
			matterId: this.matterId,
			sectionId: this.sectionId
		};
		this.dialog
			.open(DocumentListDialogComponent, { data, width: '80%', panelClass: 'mat-dialog-override' })
			.afterClosed()
			.subscribe(() => this.loadBrief(this.briefId, this.sectionId));
	}

	toggleShared() {
		this.isShared = !this.isShared;
		this.filterComponent.filter.isShared = this.isShared;
		this.filterComponent.applyFilter(true);
	}

	toggleFavorites() {
		this.isFavorited = !this.isFavorited;
		this.filterComponent.filter.isFavorited = this.isFavorited;
		this.filterComponent.applyFilter(true);
	}

	private goToBrief(brief: EntityReference) {
		this.briefId = brief.id;
		this.viewMode = 'brief';
		this.loadBriefs();
		this.loadBrief(brief.id);
		this.briefSelected(brief);
	}

	editBrief(isTryingToAddNewSection: boolean = false) {
		const data: IDocumentBriefDialogData = {
			briefId: this.briefId,
			matterId: this.matterId,
			isTryingToAddNewSection
		};
		this.subscriptions.add(
			this.dialog
				.open(CreateBriefDialogComponent, { data })
				.afterClosed()
				.pipe(filter(Boolean))
				.subscribe((response: IDocumentBriefDialogResponseData) => {
					if (response.operation === DialogOperation.Delete) {
						this.onBriefDelete(response.brief.id, response.brief.name);
					} else {
						this.loadBrief(response?.brief?.id);
						this.loadBriefs();
						this.notification.showNotification(`Brief ${response?.brief?.name} updated successfully.`);
					}
				})
		);
	}

	getOrderNumber(briefDetails: BriefDetail[]) {
		return briefDetails?.find(x => x.briefId === this.briefId && x.sectionId === this.sectionId)?.orderNumber;
	}

	showDocumentFromTemplateDialog() {
		this.subscriptions.add(
			this.getFilteredCurrentPageData()
				.pipe(
					filter(
						pageData =>
							pageData.pageType !== CurrentPageType.Matter ||
							(pageData.lookup as MatterLookupDto).status !== 'Closed'
					),
					switchMap((pageData: ICurrentPageState) => {
						const data: ITemplateMergeData = { entityType: this.getEntityType(), entityId: pageData.id };
						return this.dialog
							.open(TemplateMergeDialogComponent, { data })
							.afterClosed()
							.pipe(
								filter(Boolean),
								switchMap((result: DocumentFromTemplateDto) =>
									this.docService.createDocumentFromTemplate(result)
								)
							);
					}),
					tap(() => this.refreshDocumentTags())
				)
				.subscribe({
					next: result => {
						this.notification.showNotificationLink({
							linkParams: { pageIndexForId: result.id },
							linkRoute: flatten(
								this.activatedRoute.snapshot.pathFromRoot.map(path =>
									path.url.map(segment => segment.path)
								)
							),
							linkText: result.name,
							text: 'Document created:'
						});
					},
					error: errors => this.notification.showErrors('Error creating Document from template', errors)
				})
		);
	}

	toggleChanged(event: any) {
		this.isMatchAll = event.value === 'matchAll';
		this.filterComponent.filter.isMatchAllTags = this.isMatchAll;
		this.filterComponent.applyFilter(true);
	}

	setBriefMode(event: MatButtonToggleChange) {
		this.viewMode = event.value;
		this.updateBriefMode(this.viewMode === 'brief');
		if (this.viewMode === 'brief') {
			this.rearrangeSorting();
		}
	}

	addDocumentToBrief(documentId: string) {
		const data: IBriefDialogData = { matterId: this.matterId, documentIds: [documentId] };

		this.subscriptions.add(this.dialog.open(BriefDialogComponent, { data }).afterClosed().subscribe());
	}

	removeSelectedDocumentsFromBrief() {
		this.subscriptions.add(
			this.selected$
				.pipe(
					take(1),
					switchMap(selected => {
						if (!selected?.length) {
							this.notification.showNotification(`Please select the documents by Ctrl + Click`);
							return null;
						}

						return this.removeDocumentFromBrief$(selected.map(x => x.id));
					})
				)
				.subscribe()
		);
	}

	removeDocumentFromBrief(documentIds: string[]) {
		this.subscriptions.add(this.removeDocumentFromBrief$(documentIds).subscribe());
	}

	private removeDocumentFromBrief$(documentIds: string[]) {
		const dto: DocumentToBriefDto = { briefId: this.briefId, sectionId: this.sectionId, documentIds };

		return this.docService.removeDocumentsFromBrief(dto).pipe(
			tap(response => {
				this.store.dispatch(processRecords({ response: response }));

				this.notification.showNotificationLink({
					text: `${dto.documentIds.length} document removed from the Brief`
				});
			}),
			catchError(errors => {
				this.notification.showErrors('Error removing document', errors);
				return throwError(() => errors);
			})
		);
	}

	removeDocumentFromSection(document: DocumentListItemDto, section: BriefSection) {
		const dto: RemoveDocumentSectionDto = { briefId: this.briefId, sectionId: section.id, documentId: document.id };

		this.subscriptions.add(
			this.docService.removeDocumentFromSection(dto).subscribe({
				next: response => {
					this.store.dispatch(processRecords({ response: response }));
					this.loadBrief(this.briefId, this.sectionId);
					this.notification.showNotificationLink({
						text: `The document ${document.title} has been removed from the section ${section.name}`
					});
				},
				error: errors => this.notification.showErrors('Error removing document', errors)
			})
		);
	}

	bulkMoveDocumentsToSection(section: BriefSection) {
		this.subscriptions.add(
			this.selected$
				.pipe(
					take(1),
					switchMap(selected => {
						if (!selected?.length) {
							this.notification.showNotification(`Please select the documents by Ctrl + Click`);
							return of({});
						}

						return this.moveDocumentsToSection$(
							selected.map(x => x.id),
							section
						);
					})
				)
				.subscribe()
		);
	}

	bulkTagDocuments() {
		this.subscriptions.add(
			this.selected$
				.pipe(
					take(1),
					switchMap(selected => {
						if (!selected?.length) {
							this.notification.showNotification(`Please select the documents by Ctrl + Click`);
							return EMPTY;
						}

						return this.dialog
							.open(BulkAddTagsDialogComponent, {
								data: { documentIds: selected.map(document => document.id) }
							})
							.afterClosed();
					}),
					filter(Boolean)
				)
				.subscribe({
					next: () => {},
					error: errors => this.notification.showErrors('Error tagging document(s)', errors)
				})
		);
	}

	moveDocumentsToSection(documentIds: string[], section: BriefSection) {
		this.subscriptions.add(this.moveDocumentsToSection$(documentIds, section).subscribe());
	}

	private moveDocumentsToSection$(documentIds: string[], section: BriefSection) {
		return this.documentBriefsService
			.moveDocumentsToSection({
				briefId: this.briefId,
				documentIds,
				toSectionId: section.id,
				fromSectionId: this.sectionId
			})
			.pipe(
				tap(x => {
					this.store.dispatch(processRecords({ response: x }));
					this.notification.showNotification(
						`${documentIds.length} ${
							documentIds.length > 1 ? 'documents were' : 'document was'
						} moved to section '${section.name}'`
					);
					this.loadBrief(this.briefId, this.sectionId);
				}),
				catchError(errors => {
					this.notification.showErrors('Error moving document(s) to the section', errors);
					return throwError(() => errors);
				})
			);
	}

	deleteBrief() {
		this.subscriptions.add(
			this.notification
				.showConfirmation('Delete Brief', `Are you sure you want to delete the brief '${this.brief.name}'?`)
				.pipe(
					filter(Boolean),
					switchMap(() => this.documentBriefsService.deleteDocumentBrief(this.briefId))
				)
				.subscribe(() => this.onBriefDelete(this.briefId, this.brief.name))
		);
	}

	onBriefDelete(briefId: string, briefName: string) {
		this.briefs.splice(
			this.briefs.findIndex(x => x.id === briefId),
			1
		);
		if (this.briefs?.length > 0) {
			// if there is any other brief, go to the first in the list
			this.goToBrief(this.briefs[0]);
		} else {
			// otherwise go to the documents list
			this.updateBriefMode(false);
		}
		this.notification.showNotification(`Brief '${briefName}' deleted successfully.`);
	}

	openCreateBlankDocumentDialog() {
		this.subscriptions.add(
			this.getFilteredCurrentPageData()
				.pipe(
					filter(
						pageData =>
							pageData.pageType !== CurrentPageType.Matter ||
							(pageData.lookup as MatterLookupDto).status !== 'Closed'
					),
					switchMap(pageData => {
						const data: ICreateBlankDialogData = {
							entityType: this.getEntityType(),
							entityId: pageData.id
						};

						return this.dialog.open(CreateBlankDialogComponent, { data }).afterClosed();
					}),
					filter(Boolean)
				)
				.subscribe()
		);
	}

	// Resolve icons on loading of the data set
	protected onDataLoaded(): void {
		this.rearrangeSorting();

		this.store
			.select(selectDocumentListResponses)
			.pipe(take(1))
			.subscribe(response => (this.isOutlookActive = !!response?.isOutlookActive));
	}

	protected getServiceUrl(): string {
		if (this.FilterBase.matterId) {
			return 'api/v1/matters/' + this.FilterBase.matterId + '/documents';
		} else if (this.FilterBase.contactId) {
			return 'api/v1/contacts/' + this.FilterBase.contactId + '/documents';
		}
		return '';
	}

	protected getBriefId(): string {
		return this.briefId;
	}
	protected getSectionId(): string {
		return this.sectionId;
	}

	dropRow(event: CdkDragDrop<string[]>) {
		if (!this.briefId || !this.sectionId) {
			return;
		}

		if (event.previousIndex === event.currentIndex) {
			return;
		}

		if (event.previousContainer !== event.container) {
			return;
		}

		this.subscriptions.add(
			this.data$
				.pipe(
					take(1),
					switchMap(items => {
						const newRecordIds = items.map(x => x.id);
						const requestDto: DocumentReorderRequestDto = {
							briefId: this.briefId,
							sectionId: this.sectionId,
							documentIds: newRecordIds
						};

						return this.documentService.reorderBriefSection(requestDto).pipe(map(() => items));
					})
				)
				.subscribe({
					next: items => {
						moveItemInArray(items, event.previousIndex, event.currentIndex);
						this.table.renderRows();

						this.notification.showNotification(`Order saved.`);
					},
					error: (errors: DomainError[]) => {
						if (!!errors?.length) {
							this.notification.showReloadError(`Error Ordering`, errors[0].message);
						}
					}
				})
		);
	}

	private setTagsControls(tags: string[]) {
		this.documentTags = uniq(union(tags, this.selectedTags));

		const uniqueTags: string[] = [];
		this.documentTags.forEach(i => {
			if (!(uniqueTags.includes(i) || uniqueTags.includes(i.toLowerCase()))) uniqueTags.push(i);
		});

		this.documentTags = uniqueTags;

		this.assignCopy();
	}

	private updateBriefMode(on: boolean) {
		if (on) {
			this.enableSortStorage = false;
			this.subscriptions.add(
				this.getBriefLastSelected().subscribe(brief => {
					this.rearrangeSorting();
					if (!!brief || !isEmpty(this.briefs)) {
						this.pageSize = 10000;
						this.briefSelected(!brief ? this.briefs[0] : brief);
					}
					this.table.renderRows();
				})
			);
		} else {
			this.enableSortStorage = true;

			if (!this.loadFilterDefaultsValues()) {
				this.pageSize = AppConfig.PageSize;
			}

			this.briefSelected(null);
		}
	}

	private getEntityType(): 'Matter' | 'Contact' {
		if (this.FilterBase.matterId) return TemplateEntityType.Matter;
		if (this.FilterBase.contactId) return TemplateEntityType.Contact;
		throw new Error(
			`This method was written assuming the entity is going to be either contact or matter.
			 If the method writtens null, it is being accessed from outside the contact or matter context.
			 Verify that showDocumentFromTemplateDialog still works`
		);
	}

	private getFilteredCurrentPageData(): Observable<ICurrentPageState> {
		return this.currentStore.select(getCurrentPage).pipe(filter(page => !isNil(get(page, 'id'))));
	}

	private rearrangeSorting() {
		setTimeout(() => {
			if (!!this.briefId || !!this.sectionId) {
				// This is required  to clear any client side sorting as
				// we do not want client side sorting in Briefs mode
				this.matSort.active = '';
				this.matSort.direction = '';
			}
		}, 0);
	}

	private getBriefStorageKey(): Observable<string> {
		return this.getFilteredCurrentPageData().pipe(
			map(
				data =>
					`${briefStorageKey}-${data.pageType == CurrentPageType.Matter ? 'matter' : 'contact'}-${data.id}`
			)
		);
	}

	private storeBriefLastSelected(briefId: string) {
		if (!!this.localStorageService.isSupported) {
			this.subscriptions.add(
				this.getBriefStorageKey().subscribe(key => {
					this.localStorageService.setItem(key, { briefId });
				})
			);
		}
	}

	private getBriefLastSelected(): Observable<DocumentBriefListItemDto> {
		if (this.briefs && !!this.localStorageService.isSupported) {
			return this.getBriefStorageKey().pipe(
				map(key => {
					const briefId = this.localStorageService.getItemAs<IBriefLastSelectedData>(key)?.briefId;
					if (!!briefId) {
						const results = this.briefs.filter(brief => brief.id == briefId);
						if (!!results?.length) {
							return results[0];
						}
					}
					return null;
				})
			);
		}
		return of(null);
	}

	quickReply(row: DocumentListItemDto) {
		this.subscriptions.add(
			this.accessTokensService
				.createAccessToken({
					name: `Reply-Doc-${row.id}`,
					contactId: '',
					email: '',
					expiry: moment().add(1, 'day'),
					associatedDocumentId: row.id
				})
				.pipe(switchMap(reference => this.accessTokensService.getAccessToken(reference.id)))
				.subscribe(accessToken => {
					const link = document.createElement('a');
					link.href = `${this.replyDocumentUrlScheme}${row.id}?server=${
						new URL(AppConfig.AppServerUrl).host
					}&token=${accessToken.token}`;
					console.log(link.href);
					document.getElementById('replyDocumentDiv').appendChild(link);
					link.click();
					document.getElementById('replyDocumentDiv').removeChild(link);
				})
		);
	}
}

interface IBriefLastSelectedData {
	briefId: string;
}

interface IChipFilter {
	id: string;
	title: string;
	filterName: string;
}

const briefStorageKey = 'mattero-last-brief';

const versionFilterChipName = 'FilterVersionsForDocumentId';
