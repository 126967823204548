<mat-card>
	<section class="page-path"><a [routerLink]="['..']">System</a> / Media Types</section>
	<h1>Media Types</h1>
	<mat-table [dataSource]="tableDataSource" matSort matSortActive="name" matSortDirection="asc">
		<ng-container matColumnDef="name">
			<mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<div class="field-name">
					<a class="cursor-hand" throttleButton (throttledClick)="editMediaType(row)">{{ row.name }}</a>
				</div>
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="clearanceDateOffset">
			<mat-header-cell arrowPosition="before" *matHeaderCellDef mat-sort-header>Clearance Days</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<div class="field-balance">{{ row.clearanceDateOffset }}</div>
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="actions">
			<mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<button mat-icon-button [matMenuTriggerFor]="menu" title="Actions">
					<mat-icon>more_vert</mat-icon>
				</button>
				<mat-menu #menu="matMenu">
					<button mat-menu-item throttleButton (throttledClick)="editMediaType(row)">
						<mat-icon>edit</mat-icon>
						<span>Edit Details</span>
					</button>
				</mat-menu>
			</mat-cell>
		</ng-container>

		<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
		<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
	</mat-table>
	<p>&nbsp;</p>
</mat-card>
