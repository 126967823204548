import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { ApplicationInsightsService } from '../application-insights/application-insights.service';

export interface INotificationMessage {
	text: string;
	linkText?: string;
	linkRoute?: string[];
	linkParams?: { [key: string]: string | string[] };
	linkUrl?: string;
	isExternalLink?: boolean;
	associatedMatterId?: string;
}

/*
    Component for showing snack notifications.
    It allows using HTML tags inside the passed text
*/
@Component({
	styles: [
		`
			a {
				color: lightblue;
				margin-left: 0.25em;
				cursor: pointer;
			}
		`
	],
	template: `
		<div *ngFor="let data of messages">
			<span class="notification" [innerHTML]="data.text"></span>
			<a
				*ngIf="data.linkText && !data?.isExternalLink"
				[routerLink]="data.linkRoute"
				[queryParams]="data.linkParams"
				queryParamsHandling="merge"
				throttleButton
				(throttledClick)="onClick($event, data)"
				>{{ data.linkText }}</a
			>

			<a
				*ngIf="!!data.isExternalLink"
				throttleButton
				(throttledClick)="openHelpInNewWindow(data?.linkRoute[0])"
				>{{ data.linkText }}</a
			>
		</div>
	`
})
export class NotificationComponent {
	constructor(
		@Inject(MAT_SNACK_BAR_DATA) public messages: INotificationMessage[],
		private appInsights: ApplicationInsightsService,
		private router: Router
	) {}

	openHelpInNewWindow(url: string) {
		if (!!this.appInsights) this.appInsights.trackEvent(`Clicked on Document Edit Help Toast`);
		window.open(url, '_blank');
	}

	onClick($event: MouseEvent, message: INotificationMessage) {
		if (!!message?.linkUrl) {
			this.router.navigateByUrl(message.linkUrl);

			$event.preventDefault();
			$event.stopPropagation();
		}
	}
}
