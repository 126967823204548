import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ActivatedRoute, Router } from '@angular/router';
import { CostCodeListItemDto } from '@common/models/Settings/CostCodes/List/CostCodeListItemDto';
import { SearchCostCodesDto } from '@common/models/Settings/Setting/Item/SearchCostCodesDto';
import { UserInfoTrackTokenDto } from '@common/models/Users/Item/UserInfoTrackTokenDto';
import { INotificationMessage, NotificationService } from '@common/notification';
import { CostCodesService } from '@common/services/settings/costcodes.service';
import { GeneralSettingsService } from '@common/services/settings/generalsettings.service';
import { CustomValidators } from '@common/validation/custom.validators';
import { filter as _filter } from 'lodash-es';
import 'moment-duration-format';
import { forkJoin, Subscription } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

@Component({
	selector: 'search-settings',
	styleUrls: ['./search-settings.component.scss'],
	templateUrl: './search-settings.component.html'
})
export class SearchSettingsComponent implements OnDestroy, OnInit {
	form: FormGroupTyped<SearchCostCodesDto>;
	subscriptions = new Subscription();
	costCodesGstFree: CostCodeListItemDto[];
	costCodesGstApplicable: CostCodeListItemDto[];
	warningMessage: string;
	isWarning = false;
	settings: UserInfoTrackTokenDto;
	isSearchEnabled = false;

	constructor(
		private fb: FormBuilder,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private costCodeService: CostCodesService,
		private generalSettingsService: GeneralSettingsService,
		private notificationService: NotificationService
	) {}

	ngOnInit(): void {
		this.form = this.fb.group({
			gstFreeCostCodeId: [null, CustomValidators.required('GST Free expense code')],
			gstApplicableCostCodeId: [null, CustomValidators.required('GST Applicable expense code')]
		}) as FormGroupTyped<SearchCostCodesDto>;

		this.subscriptions.add(
			this.activatedRoute.queryParams
				.pipe(
					map(params => params.error),
					filter(Boolean),
					tap(() => {
						// remove &error= query parameter from URL, if exists
						this.router.navigate([], {
							queryParams: {
								error: null
							},
							queryParamsHandling: 'merge',
							relativeTo: this.activatedRoute
						});
					}),
					switchMap((error: string) =>
						this.notificationService.showError(
							'Cannot connect to InfoTrack at this time. Please try again later',
							error
						)
					)
				)
				.subscribe()
		);

		this.subscriptions.add(
			this.costCodeService.getCostCodeList({ costType: 'Expense' }).subscribe(costCodes => {
				this.costCodesGstFree = _filter(
					costCodes.records,
					(c: CostCodeListItemDto) => c.expenseType === 'ExpenseExGst'
				);
				this.costCodesGstApplicable = _filter(
					costCodes.records,
					(c: CostCodeListItemDto) => c.expenseType === 'ExpenseIncGst'
				);

				this.isWarning = !this.costCodesGstFree?.length || !this.costCodesGstApplicable?.length;
				if (this.isWarning) {
					this.createWarningMessage();
				}
			})
		);

		this.subscriptions.add(
			this.generalSettingsService.getSearchServiceSettings().subscribe(generalSettings => {
				this.form.controls.gstFreeCostCodeId.setValue(generalSettings.gstFreeCostCodeId);
				this.form.controls.gstApplicableCostCodeId.setValue(generalSettings.gstApplicableCostCodeId);
				this.isSearchEnabled = generalSettings.isSearchServicesEnabled;
			})
		);
	}

	createWarningMessage() {
		if (!this.costCodesGstFree?.length) this.warningMessage = 'There are no GST Free ';
		if (!this.costCodesGstApplicable?.length) {
			this.warningMessage = !this.costCodesGstFree?.length
				? this.warningMessage + 'and GST Applicable '
				: 'There is no GST Applicable ';
		}
		this.warningMessage += ' expense code(s) in the system. Click to create one.';
	}

	save(): void {
		const gstFreeCostCodeId = this.form.controls.gstFreeCostCodeId.value;
		const gstApplicableCostCodeId = this.form.controls.gstApplicableCostCodeId.value;

		const requests: Observable<INotificationMessage>[] = [];

		requests.push(
			this.generalSettingsService.saveSearchServiceSettings({
				gstFreeCostCodeId,
				gstApplicableCostCodeId,
				isSearchServicesEnabled: this.isSearchEnabled
			})
		);

		this.subscriptions.add(
			forkJoin(requests).subscribe(
				() => {
					this.notificationService.showNotification('Settings saved successfully.');
					this.form.markAsPristine();
				},
				error => {
					this.notificationService.showError(`Error saving settings'`, error);
				}
			)
		);
	}

	toggleSearchOnOff(event: MatSlideToggleChange) {
		this.isSearchEnabled = event.checked;
		this.form.markAsDirty();
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}
}
