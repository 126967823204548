<h2 mat-dialog-title>Configure Xero Synchronisation</h2>
<mat-dialog-content>
	<div>
		<xero-settings
			#xeroSettingsControl
			[xeroSettings]="data.xeroSettings"
			[showOrganisationList]="true"
			(isValidChange)="onValidChange($event)"
		></xero-settings>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-raised-button color="primary" [mat-dialog-close]="this.xeroSettingsControl.currentState" [disabled]="invalid">Save</button>
	<button mat-raised-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
