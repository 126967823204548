import { Input, OnInit, Directive } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { get } from 'lodash-es';

import { FilterRootComponent } from './filter-root.component';

@Directive()
export abstract class FilterBaseComponent implements OnInit {
	@Input() root: FilterRootComponent<any>;
	@Input() label: string;
	@Input() name: string;
	@Input() showTitleAsPrefix: boolean;
	@Input() hideClear: boolean;

	control: AbstractControl;

	get formControl(): FormControl {
		return this.control as FormControl;
	}

	ngOnInit(): void {
		if (!!this.root) {
			const form = get(this.root, 'form');
			if (form) {
				this.control = form.get(this.name);
			}
		}
	}
}
