// This file has been generated from DocumentCategoryController.cs
// Do not modify this file directly!!
// tslint:disable

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { TrimStringsOnObject } from '@common/utils/string-format';

import { EntityReference } from '../../models/Common/EntityReference';
import { ListRequest } from '../../models/Generic/ListRequest';
import { ListResponse } from '../../models/Generic/ListResponse';
import { DocumentCategoryDto } from '../../models/Settings/DocumentCategories/Item/DocumentCategoryDto';
import { DocumentCategoryListItemDto } from '../../models/Settings/DocumentCategories/List/DocumentCategoryListItemDto';

@Injectable({
	providedIn: 'root'
})
export class DocumentCategoryService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'api/v1/settings/DocumentCategory', 'Document Category');
	}

	getDocumentCategory(id: string): Observable<DocumentCategoryDto> {
		return this.getItem<void, DocumentCategoryDto>(`${id}`);
	}

	getDocumentCategoryList(request?: Partial<ListRequest>): Observable<ListResponse<DocumentCategoryListItemDto>> {
		return this.getList<ListRequest, DocumentCategoryListItemDto>('', request);
	}

	createDocumentCategory(newDocumentCategoryDto: DocumentCategoryDto): Observable<EntityReference> {
		return this.post<DocumentCategoryDto, EntityReference>('', TransformDatesOnObject(DocumentCategoryDto, TrimStringsOnObject(newDocumentCategoryDto)));
	}

	updateDocumentCategory(id: string, documentCategoryDto: DocumentCategoryDto): Observable<EntityReference> {
		return this.put(`${id}`, TransformDatesOnObject(DocumentCategoryDto, TrimStringsOnObject(documentCategoryDto)));
	}

	deleteDocumentCategory(id: string): Observable<any> {
		return this.delete(`${id}`);
	}

}
