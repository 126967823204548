import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';

import { BehaviorSubject, forkJoin, of, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

import { RecurringInterval } from '@common/models/Common/RecurringInterval';
import { ListResponse } from '@common/models/Generic/ListResponse';
import { PricedMarketplaceListingListItemDto } from '@common/models/Infrastructure/StripeIntegration/Models/PricedMarketplaceListingListItemDto';
import { InstallationRecordStatus } from '@common/models/Marketplace/InstallationRecords/Common/InstallationRecordStatus';
import { MarketplaceListingType } from '@common/models/Marketplace/Listings/Common/MarketplaceListingType';
import { MarketplaceListingListRequest } from '@common/models/Marketplace/Listings/List/MarketplaceListingListRequest';
import { StripeInfo } from '@common/models/Settings/Setting/Item/StripeInfo';
import { NotificationService } from '@common/notification';
import { GeneralSettingsService } from '@common/services/settings/generalsettings.service';
import { MarketplaceService } from '@common/services/settings/marketplace.service';
import { GetRoutePaths } from '@common/utils/router-util';
import { isNil } from 'lodash-es';

import { AppConfig } from 'app/app.config';
import { SignalrService } from 'app/core/signalr/signalr.service';

import { ResolvePricing, ResolveRecurringInterval } from '../common/pricing-helpers';

enum ListTabs {
	All,
	Installed,
	Updates
}

@Component({
	selector: 'marketplace-listings',
	styleUrls: ['./marketplace-listings.component.scss'],
	templateUrl: 'marketplace-listings.component.html'
})
export class MarketplaceListingsComponent implements OnInit, OnDestroy {
	@HostListener('window:popstate', ['$event'])
	onPopState() {
		this.goBack();
	}

	private _request$: BehaviorSubject<Partial<MarketplaceListingListRequest>>;
	private _categories: string[] = [];
	private _selectedCategories: string[] = [];
	private _tenantStripeInfo: StripeInfo;

	private _response: PricedMarketplaceListingListItemDto[];
	private _filteredResponse: PricedMarketplaceListingListItemDto[];
	private _viewId: string;
	private _listingType: keyof typeof MarketplaceListingType;

	private _selectedTabIndex: number = 0;

	private _installedCount: number = 0;
	private _updateableCount: number = 0;

	private _form: FormGroup;

	promoCode: string;
	isMobileFilterOpen: boolean = false;

	get categories() {
		return this._categories;
	}

	get filteredResponse() {
		return this._filteredResponse;
	}

	get viewId() {
		return this._viewId;
	}

	get listingType() {
		return this._listingType;
	}

	get selectedTabIndex() {
		return this._selectedTabIndex;
	}

	get installedCount() {
		return this._installedCount;
	}

	get updateableCount() {
		return this._updateableCount;
	}

	get pageSize() {
		return this._request$.value.pageSize;
	}

	get form() {
		return this._form;
	}

	get searchHasValue() {
		return !!this._form.get('search')?.value?.length;
	}

	private get _primarySubscriptionInterval(): RecurringInterval {
		return ResolveRecurringInterval(this._tenantStripeInfo?.recurringInterval);
	}

	private _subscriptions = new Subscription();

	constructor(
		private _formBuilder: FormBuilder,
		private _marketplaceService: MarketplaceService,
		private _router: Router,
		private _route: ActivatedRoute,
		private _generalSettingsService: GeneralSettingsService,
		private _notificationService: NotificationService,
		private _signalRService: SignalrService,
		private _breakpointObserver: BreakpointObserver
	) {}

	ngOnInit(): void {
		this._form = this._formBuilder.group({
			search: null
		});

		this._request$ = new BehaviorSubject<Partial<MarketplaceListingListRequest>>({ pageSize: 1000 });

		this.getMarketplaceId();

		this._subscriptions.add(this.getTenantStripeInfo$().subscribe());

		this._subscriptions.add(this.getQueryParams$().subscribe());

		this._subscriptions.add(this.getInstalledMetadata$().subscribe());

		this._subscriptions.add(
			this._signalRService
				.onSubscriptionUpdated()
				.pipe(
					switchMap(() => {
						return this._request$.pipe(switchMap(request => this.getFilteredList$(request, true)));
					})
				)
				.subscribe(() => {
					this._notificationService.showNotification('Subscription Updated');
				})
		);

		const urlTree = this._router.parseUrl(this._router.url);

		const routePaths = GetRoutePaths(urlTree);
		if (routePaths.includes('installed')) {
			this.setActiveTab(ListTabs.Installed);
		} else if (routePaths.includes('updates')) {
			this.setActiveTab(ListTabs.Updates);
		} else {
			this.setActiveTab(ListTabs.All);
		}

		this._subscriptions.add(
			this._request$.pipe(switchMap(request => this.getFilteredList$(request, false))).subscribe()
		);

		this._subscriptions.add(
			this.form.controls.search.valueChanges
				.pipe(debounceTime(250), distinctUntilChanged())
				.subscribe((value: string) => {
					this.refresh(request => {
						request.search = value;
					});
				})
		);

		this._subscriptions.add(
			this._breakpointObserver.observe([Breakpoints.XSmall]).subscribe(() => {
				this.deselectCategories();
			})
		);

		this.promoCode = this._route.snapshot.queryParamMap.get('promo');
	}

	deselectCategories() {
		this._viewId = null;
		this._listingType = null;

		if (!this.isAnyCategorySelected()) {
			this.updateRoute();
			return;
		}

		this._selectedCategories = [];

		this.refresh(request => (request.categories = this._selectedCategories));
	}

	toggleCategory(category: string): void {
		this._viewId = null;
		this._listingType = null;

		if (!this.isCategorySelected(category)) {
			this._selectedCategories = [];
			this._selectedCategories.push(category);
		} else {
			this._selectedCategories = [];
		}

		const params: { [key: string]: string } = {};
		if (!!this._selectedCategories?.length) {
			params['category'] = this._selectedCategories[0].toLocaleLowerCase();
		}

		this.refresh(request => (request.categories = this._selectedCategories));
	}

	isAnyCategorySelected(): boolean {
		return !!this._selectedCategories?.length;
	}

	isCategorySelected(category: string): boolean {
		return this._selectedCategories.includes(category);
	}

	isInstalled(row: PricedMarketplaceListingListItemDto) {
		return row.installationStatus === InstallationRecordStatus.Installed || !!row.isSubscribed;
	}

	hasUpdate(row: PricedMarketplaceListingListItemDto) {
		return !!this.isInstalled(row) && !!row.packageDetails?.hasUpdate;
	}

	selectedTabChanged(tabEvent: MatTabChangeEvent): void {
		this._viewId = null;
		this._listingType = null;

		if (this.selectedTabIndex !== tabEvent.index) {
			switch (tabEvent.index) {
				case 0:
					this.setActiveTab(ListTabs.All);
					break;
				case 1:
					this.setActiveTab(ListTabs.Installed);
					break;
				case 2:
					this.setActiveTab(ListTabs.Updates);
					break;
			}
		}
	}

	tabClicked(event: any) {
		if (!!this.viewId) {
			this._viewId = null;
		}
	}

	getImageSrc(row: PricedMarketplaceListingListItemDto) {
		const baseUrl = AppConfig.AppServerUrl + '/marketplace/icons/';
		switch (row.listingType) {
			case MarketplaceListingType.Package:
				return baseUrl + 'Package/' + row.id;
			case MarketplaceListingType.Module:
				return baseUrl + 'Module/' + row.id;
			case MarketplaceListingType.Service:
				return baseUrl + 'Service/' + row.id;
			default:
				return '';
		}
	}

	goBack() {
		if (!!this.viewId || this.isAnyCategorySelected() || this.searchHasValue) {
			this.clearSearch();
			this.deselectCategories();
		} else if (this.selectedTabIndex != 0) {
			this.setActiveTab(ListTabs.All);
		} else {
			this._router.navigate(['system']);
		}
	}

	clearSearch() {
		this._form.controls.search.setValue(null);
	}

	showUpdateDate() {
		return this._selectedTabIndex !== ListTabs.All;
	}

	openOverview(row: PricedMarketplaceListingListItemDto) {
		this.toggleMobileFilter(false);
		this.clearSearch();
		this.deselectCategories();

		this._viewId = row.id;
		this._listingType = row.listingType;

		this.updateRoute();
	}

	getPricing(row: PricedMarketplaceListingListItemDto): IPricingDisplay {
		const pricing = ResolvePricing(row, this._primarySubscriptionInterval);
		return {
			primary: !!pricing?.primary?.hasPrice
				? `$${pricing.primary.cost} user / ${ResolveRecurringInterval(pricing.primary.billingCycle)}`
				: '',
			secondary: !!pricing?.secondary?.hasPrice
				? `$${pricing.secondary.cost} user / ${ResolveRecurringInterval(pricing.secondary.billingCycle)}`
				: ''
		};
	}

	onListingChanged() {
		this._subscriptions.add(
			forkJoin([this.getInstalledMetadata$(), this.getFilteredList$(null, true)]).subscribe()
		);
	}

	toggleMobileFilter(isEnabled?: boolean) {
		if (!isNil(isEnabled)) this.isMobileFilterOpen = isEnabled;
		else {
			this.isMobileFilterOpen = !this.isMobileFilterOpen;
		}
	}

	clearFilter() {
		if (!!this.viewId || this.isAnyCategorySelected() || !!this._form.get('search')?.value?.length) {
			this.clearSearch();
			this.deselectCategories();
		}
	}

	private getInstalledMetadata$() {
		return this._marketplaceService.getInstalledMetadata().pipe(
			tap(metadata => {
				this._installedCount = metadata.installedCount;
				this._updateableCount = metadata.updateableCount;
			})
		);
	}

	private getTenantStripeInfo$() {
		return this._generalSettingsService.getTenantStripeInfo().pipe(tap(x => (this._tenantStripeInfo = x)));
	}

	private updateRoute() {
		let params: { [key: string]: string } = {};
		let route = ['system', 'marketplace'];

		if (!!this.listingType) route.push(this.listingType.toLowerCase());

		if (!!this.viewId?.length) {
			route.push(this.viewId);
		} else if (this._selectedTabIndex === ListTabs.Installed) {
			route.push('installed');
		} else if (this._selectedTabIndex === ListTabs.Updates) {
			route.push('updates');
		}

		if (!!this._selectedCategories?.length) {
			params['category'] = this._selectedCategories[0].toLocaleLowerCase();
		}

		if (!!this._form.get('search')?.value?.length) {
			params['search'] = this._form.get('search').value;
		}

		// update 'activeTab' query parameter in the URL when changing tabs
		this._router.navigate(route, {
			queryParams: params
		});
	}

	private refresh(delegate?: (request: Partial<MarketplaceListingListRequest>) => void): void {
		if (!!delegate) {
			const request = this._request$.value;
			delegate(request);
			this._request$.next(request);
		} else {
			const request = this._request$.value;
			this._request$.next(request);
		}

		this.updateRoute();
	}

	private setActiveTab(value: ListTabs): void {
		this._selectedTabIndex = value;
		this._selectedCategories = [];

		switch (value) {
			case ListTabs.Installed:
				this.refresh(request => {
					request.status = [InstallationRecordStatus.Installed];
					request.isUpdateable = false;
					request.categories = null;
				});
				break;
			case ListTabs.Updates:
				this.refresh(request => {
					request.status = null;
					request.isUpdateable = true;
					request.categories = null;
				});
				break;
			case ListTabs.All:
				this.refresh(request => {
					request.status = null;
					request.isUpdateable = false;
					request.categories = null;
				});
				break;
			default:
				throw new Error('Invalid list tab value');
		}
	}

	private getMarketplaceId() {
		const urlParts = this._router.url.split('?')[0].split('(')[0].split('/');

		const lastPart = urlParts[urlParts.length - 1];
		if (lastPart !== 'marketplace' && lastPart !== 'installed' && lastPart !== 'updates') {
			this._viewId = lastPart;
		}

		const lastPart2 = urlParts[urlParts.length - 2];
		const listingTypeString = lastPart2.toLowerCase();

		if (
			Object.values(MarketplaceListingType)
				.map(x => x.toLowerCase())
				.includes(listingTypeString)
		) {
			this._listingType = lastPart2 as keyof typeof MarketplaceListingType;
		} else {
			this._listingType = null;
		}
	}

	private getQueryParams$() {
		return this._route.queryParams.pipe(
			switchMap(params => {
				let observable: Observable<any> = of({});

				if (!!params['search']) {
					observable = observable.pipe(tap(() => this._form.get('search')?.setValue(params['search'])));
				}

				observable = observable.pipe(switchMap(() => this.getCategories$(params['category'])));

				return observable.pipe(
					tap(() =>
						this.refresh(request => {
							request.search = this._form.get('search').value;
							request.categories = this._selectedCategories;
						})
					)
				);
			})
		);
	}

	private getCategories$(defaultCategory?: string) {
		return (!!this.categories?.length ? of(this._categories) : this._marketplaceService.getAllCategories()).pipe(
			tap(categories => {
				this._categories = categories
					.filter(category => !!category)
					.sort((left, right) => left.localeCompare(right));

				if (!!defaultCategory?.length) {
					const index = categories
						.map(category => category.toLocaleLowerCase())
						.indexOf(defaultCategory.toLocaleLowerCase());

					if (index !== -1) {
						this._selectedCategories = [categories[index]];
					}
				}
			})
		);
	}

	private applyCritera(request: Partial<MarketplaceListingListRequest>) {
		if (!this._response?.length) {
			return [];
		}

		let query = this._response;

		if (!!request?.categories?.length) {
			const categories = request.categories.map(category => category.toLowerCase());

			query = query.filter(
				item =>
					(item.productCategories ?? [])?.filter(category => categories.includes(category.toLowerCase()))
						?.length > 0
			);
		}

		if (!!request?.status?.length) {
			query = query.filter(item => request.status.includes(item.installationStatus));
		}

		if (!!request?.ids?.length) {
			if (!!request.excludeProductIds) {
				query = query.filter(item => !request.ids.includes(item.id));
			} else {
				query = query.filter(item => !!request.ids.includes(item.id));
			}
		}

		if (!!request?.isUpdateable) {
			query = query.filter(item => !!item.packageDetails?.hasUpdate);
		}

		if (!!request?.search?.length) {
			const search = request.search.toLowerCase();

			query = query.filter(item => item.productName.toLowerCase().search(search) !== -1);
		}

		return query;
	}

	private getListFromServer$() {
		return this._marketplaceService
			.getActiveListings({ sortBy: 'orderNumber' })
			.pipe(
				tap(
					response =>
						(this._response = (response as ListResponse<PricedMarketplaceListingListItemDto>)?.records)
				)
			);
	}

	private getFilteredList$(request?: Partial<MarketplaceListingListRequest>, forceServerCall: boolean = false) {
		if (!request) {
			request = this._request$.value;
		}

		let observable: Observable<any> = !this._response || !!forceServerCall ? this.getListFromServer$() : of({});

		return observable.pipe(tap(() => (this._filteredResponse = this.applyCritera(request))));
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}
}

interface IPricingDisplay {
	primary: string;
	secondary: string;
}
