<mat-card>
	<section class="breadcrumb"><a [routerLink]="['..']">System</a> / Invoice Settings</section>
	<mat-tab-group *ngIf="billSettings | async as settings" [selectedIndex]="selectedTabIndex" (selectedTabChange)="selectedTabChanged($event)">
		<mat-tab *tenantFeature="featureTypes.Invoicing">
			<ng-template mat-tab-label>
				<span *ngIf="isMobile">Numbering</span>
				<span *ngIf="!isMobile">Number Format</span>
			</ng-template>
			<bill-number-format [invoiceNumberFormat]="settings.invoiceNumberFormat"></bill-number-format>
		</mat-tab>
		<mat-tab *tenantFeature="'XeroIntegration'">
			<ng-template mat-tab-label>
				<span>Xero</span>
				<span class="hiddenPhone">Sync Setup</span>
			</ng-template>
			<xero-connect [xeroSettings]="settings.xero"></xero-connect>
		</mat-tab>
		<ng-container *tenantFeature="featureTypes.Invoicing">
			<mat-tab *ngIf="!isMobile">
				<ng-template mat-tab-label>
					<span>Payment Terms</span>
				</ng-template>
				<global-payment-terms [billSettings]="settings"></global-payment-terms>
			</mat-tab>
			<mat-tab *ngIf="!isMobile">
				<ng-template mat-tab-label>
					<span>Taxes</span>
				</ng-template>
				<tax-settings [billSettings]="settings"></tax-settings>
			</mat-tab>
			<mat-tab *ngIf="!isMobile">
				<ng-template mat-tab-label>
					<span>Template</span>
				</ng-template>
				<bill-template [billSettings]="settings"></bill-template>
			</mat-tab>
			<mat-tab *ngIf="!!isMobile">
				<ng-template mat-tab-label>
					<span>Other Settings</span>
				</ng-template>
				<global-payment-terms [billSettings]="settings"></global-payment-terms>
				<tax-settings [billSettings]="settings"></tax-settings>
				<bill-template [billSettings]="settings"></bill-template>
			</mat-tab>
		</ng-container>
	</mat-tab-group>
</mat-card>
