import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { empty, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { NotificationService } from '../notification/notification.service';
import { DomainErrorResolver } from './domain-error.resolver';

@Injectable()
export class HttpResponseStatus500Interceptor implements HttpInterceptor {
	private noInternetMessageShown = false;

	constructor(private notifService: NotificationService) {}

	// function which will be called for all http calls
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError(response => {
				// Catch the 500 status code to redirect to the login page
				if (response instanceof HttpErrorResponse && [500].indexOf(response.status) > -1) {
					const errors = DomainErrorResolver.getDomainErrors(response);

					if (!this.noInternetMessageShown) {
						this.noInternetMessageShown = true;
						setTimeout(() =>
							this.notifService.showCriticalErrors(errors).subscribe(() => {
								this.noInternetMessageShown = false;
							})
						);
					}
					return empty();
				}
				return throwError(response);
			})
		);
	}
}
