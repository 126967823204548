import { DocumentCategoryListItemDto } from '@common/models/Settings/DocumentCategories/List/DocumentCategoryListItemDto';
import { createSelector, MemoizedSelector } from '@ngrx/store';

import { IAppState } from '../../app.state';

export const selectedDocumentCategoryFilterData = (state: IAppState) => state.documentCategoryFilterData;

export const selectDocumentCategoryRecords: MemoizedSelector<IAppState, DocumentCategoryListItemDto[]> = createSelector(
	selectedDocumentCategoryFilterData,
	state => state?.list?.records || []
);
export const selectDocumentCategoryFilterRequest: MemoizedSelector<IAppState, any> = createSelector(
	selectedDocumentCategoryFilterData,
	state => state?.request
);
export const selectDocumentCategoryIsFetching = createSelector(
	selectDocumentCategoryFilterRequest,
	state => !!state?.isFetching
);
