import { Component, ElementRef, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ContactLookupComponent } from '@common/components/lookups/contact-lookup.component';
import { ContactType } from '@common/models/Contacts/Common/ContactType';
import { ContactDetailsDto } from '@common/models/Contacts/Item/ContactDetailsDto';
import { INotificationMessage } from '@common/notification';
import { ContactsService } from '@common/services/contacts.service';
import { normalizeName } from '@common/utils/stringUtils';
import { CustomValidators } from '@common/validation/custom.validators';
import { Store } from '@ngrx/store';

import { ContactListActions } from 'app/core/state/lists/contact-list/contact-list.actions';
import { ITenantCompanyState } from 'app/core/state/misc/tenant-company/tenant-company.state';

import { ICreateComponent } from '../create-component.interface';

const countries = require('app/shared/editable-controls/countries.json');

@Component({
	selector: 'create-contact',
	styleUrls: ['./create-contact.component.scss'],
	templateUrl: 'create-contact.component.html'
})
export class CreateContactComponent implements OnInit, OnDestroy, ICreateComponent {
	@Output()
	isValidChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	@ViewChild('firstNameInput')
	set personContent(content: ElementRef) {
		if (!!this.isPerson) {
			this.setFocus(content);
		}
	}
	@ViewChild('fullNameInput')
	set companyContent(content: ElementRef) {
		if (!!this.isCompany) {
			this.setFocus(content);
		}
	}

	@ViewChildren('member')
	memberComponents: QueryList<ContactLookupComponent>;

	private _memberDetails: ContactDetailsDto[];

	private _type: keyof typeof ContactType;

	get type(): keyof typeof ContactType {
		return this._type;
	}

	set type(value: keyof typeof ContactType) {
		this._type = value;

		if (value === ContactType.Plural) {
			const member1 = this.fb.control(null);
			const member2 = this.fb.control(null);

			this.subscription.add(member1.valueChanges.subscribe(() => this.membersChange(member1)));
			this.subscription.add(member2.valueChanges.subscribe(() => this.membersChange(member2)));

			this.form.addControl(
				'memberIds',
				this.fb.array(
					[member1, member2],
					[CustomValidators.uniqueArrayField(), CustomValidators.minLengthArray(2)]
				)
			);

			setTimeout(() => {
				if (!!this.memberComponents?.length) {
					this.memberComponents.first.setFocus();
				}
			}, 0);
		}
	}

	get memberArray() {
		return this.form.get('memberIds') as FormArray;
	}

	get memberControls() {
		return this.memberArray.controls;
	}

	form: FormGroup;

	private subscription: Subscription = new Subscription();

	private isManual: boolean;
	private ignoreManual: boolean;

	countries = countries;

	constructor(
		private contactService: ContactsService,
		private fb: FormBuilder,
		private store: Store<{ tenantCompanyData: ITenantCompanyState }>
	) {}

	ngOnInit() {
		this.form = this.fb.group({
			abn: [null, null, CustomValidators.validateABN(this.contactService)],
			acn: [null, null, CustomValidators.validateACN(this.contactService)],
			addresses: this.fb.group({
				items: this.fb.array([
					this.fb.group({
						item: this.fb.group({
							administrativeArea: null,
							formattedAddress: null,
							line1: null,
							line2: null,
							locality: null,
							postalCode: null,
							regionCode: 'AU',
							subLocality: null
						})
					})
				])
			}),
			company: null,
			customFields: this.fb.group({}),
			emails: this.fb.group({
				items: this.fb.array([
					this.fb.group({
						item: [null, CustomValidators.optionalEmail()]
					})
				])
			}),
			firstName: [null, CustomValidators.requiredWhen(() => this.isPerson, 'First Name')],
			fullName: [null, CustomValidators.requiredWhen(() => this.isCompany, 'Full Name')],
			jobTitle: null,
			lastName: [null, CustomValidators.requiredWhen(() => this.isPerson, 'Last Name')],
			middleName: null,
			phones: this.fb.group({
				items: this.fb.array([
					this.fb.group({
						item: this.fb.group({
							number: [null, null, CustomValidators.validatePhoneNumber(this.contactService, this.store)]
						})
					})
				])
			}),
			createCompanies: null,
			createPersons: null,
			tenantRegionCode: null
		});

		this.subscription.add(
			this.form.statusChanges.subscribe(next => {
				return this.isValidChange.next(next === 'VALID');
			})
		);

		this.subscription.add(
			this.form.get('fullName').valueChanges.subscribe(value => {
				if (!this.ignoreManual) {
					this.isManual = !!value;
				} else {
					this.ignoreManual = false;
				}
			})
		);

		this.subscription.add(
			this.store
				.select(state => state?.tenantCompanyData?.regionCode)
				.subscribe(data => {
					this.form.get('addresses.items.0.item.regionCode').setValue(data.length > 0 ? data : 'AU');
					this.form.get('tenantRegionCode').setValue(data.length > 0 ? data : 'AU');
				})
		);
	}

	setFocus(inputControl: ElementRef) {
		if (inputControl) setTimeout(() => inputControl.nativeElement.focus(), 0);
	}

	ngOnDestroy() {
		this.isValidChange.complete();
		this.subscription.unsubscribe();
	}

	Create(): Observable<INotificationMessage> {
		if (this.isCompany) {
			return this.contactService.createCompany(this.form.value).pipe(
				tap(ref => this.store.dispatch({ type: ContactListActions.InsertRecords, response: ref })),
				map(ref => ({ text: 'Company created:', linkText: ref.name, linkRoute: ['/contacts', ref.id] }))
			);
		} else if (this.isPerson) {
			return this.contactService.createPerson(this.form.value).pipe(
				tap(ref => this.store.dispatch({ type: ContactListActions.InsertRecords, response: ref })),
				map(ref => ({ text: 'Person created:', linkText: ref.name, linkRoute: ['/contacts', ref.id] }))
			);
		} else if (this.isPlural) {
			return this.contactService.createPlural(this.form.value).pipe(
				tap(ref => this.store.dispatch({ type: ContactListActions.InsertRecords, response: ref })),
				map(ref => ({ text: 'Plural created:', linkText: ref.name, linkRoute: ['/contacts', ref.id] }))
			);
		} else throw new Error('unable to determine if the contact was a company, person or a plural');
	}

	get isPerson(): boolean {
		return this.type === ContactType.Person;
	}

	get isCompany(): boolean {
		return this.type === ContactType.Company;
	}

	get isPlural(): boolean {
		return this.type === ContactType.Plural;
	}

	get emailItemFormControl(): FormControl {
		return this.form.get('emails.items.0.item') as FormControl;
	}

	getCustomFieldsFormGroup(): FormGroup {
		return this.form.get('customFields') as FormGroup;
	}

	fillContactInfoForMember(index: number) {
		const id = this.memberControls[index].value;

		if (!!id) {
			this.subscription.add(
				this.contactService.getContact(id).subscribe(view => {
					if (!!view.phones?.items?.length) {
						const phone = view.phones.items[view.phones.primaryIndex];

						this.form.get('phones.items.0').reset(phone);
					}

					if (!!view.emails?.items?.length) {
						const email = view.emails.items[view.emails.primaryIndex];

						this.form.get('emails.items.0').reset(email);
					}
				})
			);
		}
	}

	fillAddressForMember(index: number) {
		const id = this.memberControls[index].value;

		if (!!id) {
			this.subscription.add(
				this.contactService.getContact(id).subscribe(view => {
					if (!!view.addresses?.items?.length) {
						const address = view.addresses.items[view.addresses.primaryIndex];

						this.form.get('addresses.items.0').reset(address);
					}
				})
			);
		}
	}

	addMember() {
		const control = this.fb.control(null, CustomValidators.required('Contact'));

		this.subscription.add(control.valueChanges.subscribe(() => this.membersChange(control)));

		this.memberArray.push(control);
	}

	removeMember(index: number) {
		this.memberArray.removeAt(index);
		setTimeout(() => this.membersChange(), 0);
	}

	memberHasAddress(control: AbstractControl) {
		if (!this._memberDetails?.length) {
			return false;
		}

		return this._memberDetails.filter(detail => detail.id === control.value && detail.hasAddress).length > 0;
	}

	memberHasContactInfo(control: AbstractControl) {
		if (!this._memberDetails?.length) {
			return false;
		}

		return (
			this._memberDetails.filter(
				detail => detail.id === control.value && (!!detail.hasPhone || !!detail.hasEmail)
			).length > 0
		);
	}

	private refreshMemberDetails$() {
		const ids = this.memberControls.map(control => control.value).filter(id => !!id?.length);

		if (!ids?.length) {
			this._memberDetails = [];
			return of(this._memberDetails);
		}

		return this.contactService
			.getContactDetailsList({ ids: ids })
			.pipe(tap(details => (this._memberDetails = details)));
	}

	private membersChange(control?: AbstractControl) {
		if (!this.isManual) {
			const contacts = this.memberComponents
				.map(component =>
					component.selectedValue?.type === 'Person'
						? normalizeName(component.selectedValue.fullName)
						: component.selectedValue?.fullName
				)
				.filter((name, index) => !!name?.length && !!this.memberControls[index].value);

			let name = '';

			if (contacts.length == 1) {
				name = contacts[0];
			} else if (contacts.length > 1) {
				name = contacts.reduce((left, right) => `${left} & ${right}`);
			}

			this.ignoreManual = true;
			this.form.get('fullName').setValue(name);
		}

		if (!!control?.dirty) {
			this.memberArray.markAsTouched();
		}

		this.subscription.add(this.refreshMemberDetails$().subscribe());
	}
}
