// This file has been generated from GlobalListPersonalization.cs
// Do not modify this file directly!!

// LawMaster.Amalthea.Domain.Common.GlobalListPersonalization
export enum GlobalListPersonalization {
	My = 'My',
	Recent = 'Recent',
	Suggested = 'Suggested',
	All = 'All'
}
