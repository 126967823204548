// This file has been generated from ContactsController.cs
// Do not modify this file directly!!
// tslint:disable

import { HttpClient, HttpResponse } from '@angular/common/http';
import { IDownloadFile } from '../models/Files/IFileDownloader';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { TrimStringsOnObject } from '@common/utils/string-format';

import { EntityReference } from '../models/Common/EntityReference';
import { MutationResponseDto } from '../models/Common/MutationResponseDto';
import { CompanyContactCreateUpdateDto } from '../models/Contacts/Item/CompanyContactCreateUpdateDto';
import { ContactDeleteInfoDto } from '../models/Contacts/Item/ContactDeleteInfoDto';
import { ContactDeleteResponseDto } from '../models/Contacts/Item/ContactDeleteResponseDto';
import { ContactDetailsDto } from '../models/Contacts/Item/ContactDetailsDto';
import { ContactViewDto } from '../models/Contacts/Item/ContactViewDto';
import { MemberReferenceRequestDto } from '../models/Contacts/Item/MemberReferenceRequestDto';
import { PersonContactCreateUpdateDto } from '../models/Contacts/Item/PersonContactCreateUpdateDto';
import { PhoneCheckDto } from '../models/Contacts/Item/PhoneCheckDto';
import { PluralContactCreateUpdateDto } from '../models/Contacts/Item/PluralContactCreateUpdateDto';
import { RegNumberCheckDto } from '../models/Contacts/Item/RegNumberCheckDto';
import { ContactDetailsListRequest } from '../models/Contacts/List/ContactDetailsListRequest';
import { ContactIdsCsvExportRequest } from '../models/Contacts/List/ContactIdsCsvExportRequest';
import { ContactListByIdsRequest } from '../models/Contacts/List/ContactListByIdsRequest';
import { ContactListCsvExportRequest } from '../models/Contacts/List/ContactListCsvExportRequest';
import { ContactListItemDto } from '../models/Contacts/List/ContactListItemDto';
import { ContactListRequest } from '../models/Contacts/List/ContactListRequest';
import { ContactLookupDto } from '../models/Contacts/Lookup/ContactLookupDto';
import { ContactLookupItemRequest } from '../models/Contacts/Lookup/ContactLookupItemRequest';
import { ContactLookupRequest } from '../models/Contacts/Lookup/ContactLookupRequest';
import { ListResponse } from '../models/Generic/ListResponse';
import { DocumentRequest } from '../models/RequestParameters/DocumentRequest';

@Injectable({
	providedIn: 'root'
})
export class ContactsService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'api/v1/Contacts', 'Contact');
	}

	updatePerson(id: string, dto: PersonContactCreateUpdateDto): Observable<any> {
		return this.put(`person/${id}`, TransformDatesOnObject(PersonContactCreateUpdateDto, TrimStringsOnObject(dto)));
	}

	updateCompany(id: string, dto: CompanyContactCreateUpdateDto): Observable<any> {
		return this.put(`company/${id}`, TransformDatesOnObject(CompanyContactCreateUpdateDto, TrimStringsOnObject(dto)));
	}

	updatePlural(id: string, dto: PluralContactCreateUpdateDto): Observable<any> {
		return this.put(`plural/${id}`, TransformDatesOnObject(PluralContactCreateUpdateDto, TrimStringsOnObject(dto)));
	}

	createPerson(dto: PersonContactCreateUpdateDto): Observable<MutationResponseDto> {
		return this.post<PersonContactCreateUpdateDto, MutationResponseDto>(`person`, TransformDatesOnObject(PersonContactCreateUpdateDto, TrimStringsOnObject(dto)));
	}

	createCompany(dto: CompanyContactCreateUpdateDto): Observable<MutationResponseDto> {
		return this.post<CompanyContactCreateUpdateDto, MutationResponseDto>(`company`, TransformDatesOnObject(CompanyContactCreateUpdateDto, TrimStringsOnObject(dto)));
	}

	createPlural(dto: PluralContactCreateUpdateDto): Observable<MutationResponseDto> {
		return this.post<PluralContactCreateUpdateDto, MutationResponseDto>(`plural`, TransformDatesOnObject(PluralContactCreateUpdateDto, TrimStringsOnObject(dto)));
	}

	addPluralMember(id: string, memberReferenceDto: MemberReferenceRequestDto): Observable<MutationResponseDto> {
		return this.put(`plural/${id}/AddMember`, TransformDatesOnObject(MemberReferenceRequestDto, TrimStringsOnObject(memberReferenceDto)));
	}

	removePluralMember(id: string, memberId: string): Observable<MutationResponseDto> {
		return this.delete(`plural/${id}/members/${memberId}`);
	}

	deleteContact(id: string): Observable<ContactDeleteResponseDto> {
		return this.delete(`${id}`);
	}

	getDeleteInfo(id: string): Observable<ContactDeleteInfoDto> {
		return this.getItem<void, ContactDeleteInfoDto>(`GetDeleteInfo/${id}`);
	}

	exportContactsByFilter(request: ContactListCsvExportRequest): Observable<HttpResponse<Blob>> {
		return this.getBlob<ContactListCsvExportRequest>(`exportByFilter`, request);
	}

	exportContactsByIds(request: ContactIdsCsvExportRequest): Observable<HttpResponse<Blob>> {
		return this.getBlob<ContactIdsCsvExportRequest>(`exportByIds`, request);
	}

	lookupContactList(request: ContactLookupRequest): Observable<ContactLookupDto[]> {
		return this.getArray<ContactLookupRequest, ContactLookupDto>(`lookup`, request);
	}

	lookupContact(id: string, request: ContactLookupItemRequest): Observable<ContactLookupDto> {
		return this.getItem<ContactLookupItemRequest, ContactLookupDto>(`${id}/lookup`, request);
	}

	getContactList(request?: Partial<ContactListRequest>): Observable<ListResponse<ContactListItemDto>> {
		return this.getList<ContactListRequest, ContactListItemDto>('', request);
	}

	getContactListByIds(request: ContactListByIdsRequest): Observable<ListResponse<ContactListItemDto>> {
		return this.post<ContactListByIdsRequest, ListResponse<ContactListItemDto>>(`GetContactListByIds`, request);
	}

	getContactDetailsList(request?: Partial<ContactDetailsListRequest>): Observable<ContactDetailsDto[]> {
		return this.getArray<ContactDetailsListRequest, ContactDetailsDto>(`details`, request);
	}

	getContact(id: string): Observable<ContactViewDto> {
		return this.getItem<void, ContactViewDto>(`${id}`);
	}

	getRandomContactReference(): Observable<EntityReference> {
		return this.getItem<void, EntityReference>(`random`);
	}

	isPhoneValid(dto: PhoneCheckDto): Observable<boolean> {
		return this.getItem<PhoneCheckDto, boolean>(`IsPhoneValid`, dto);
	}

	isAbnValid(dto: RegNumberCheckDto): Observable<boolean> {
		return this.getItem<RegNumberCheckDto, boolean>(`IsAbnValid`, dto);
	}

	isAcnValid(dto: RegNumberCheckDto): Observable<boolean> {
		return this.getItem<RegNumberCheckDto, boolean>(`IsAcnValid`, dto);
	}

	addDocument(id: string, request: DocumentRequest): Observable<EntityReference> {
		return this.post<DocumentRequest, EntityReference>(`${id}/documents`, request);
	}

}
