<div class="timer-container" [class.timer-container--reverse]="!!useReverseLayout" [class.timer-container--wrap]="!!useWrap">
	<div class="button-container">
		<button mat-icon-button class="reset" throttleButton (throttledClick)="onButtonResetClick($event)">
			<mat-icon matTooltip="Reset">settings_backup_restore</mat-icon>
		</button>

		<button mat-icon-button class="play" throttleButton (throttledClick)="onButtonTogglePlayPauseClick($event)">
			<ng-container *ngIf="!isRunning">
				<mat-icon matTooltip="Play">play_arrow</mat-icon>
			</ng-container>

			<ng-container *ngIf="isRunning">
				<mat-icon matTooltip="Pause">pause</mat-icon>
			</ng-container>
		</button>

		<button mat-icon-button class="create-time-record" throttleButton (throttledClick)="onCreateTimeRecordClick($event)">
			<mat-icon matTooltip="Create Time/Fee Record">access_time</mat-icon>
		</button>
	</div>

	<div class="duration-container">
		<span [matTooltip]="getTooltip()">
			{{ formattedDuration }}
		</span>
	</div>
</div>
