import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EntityReference } from '@common/models/Common/EntityReference';
import { Store } from '@ngrx/store';

import { IAppState } from 'app/core/state/app.state';
import { selectDocumentCategoryRecords } from 'app/core/state/filters/document-category-filter/document-category-filter.selectors';

import { FilterBaseComponent } from './filter-base.component';
import { setFilters } from 'app/core/state/filters/document-category-filter/document-category-filter.actions';
import { EnumSortDirection } from '@common/models/Common/EnumSortDirection';

@Component({
	selector: 'filter-document-category',
	template: `
		<filter-entity-reference-list
			[root]="root"
			[name]="name"
			[label]="label"
			[items]="source$ | async"
			[showTitleAsPrefix]="showTitleAsPrefix"
		></filter-entity-reference-list>
	`
})
export class FilterDocumentCategoryComponent extends FilterBaseComponent implements OnInit {
	source$: Observable<EntityReference[]> = this._store
		.select(selectDocumentCategoryRecords)
		.pipe(map(categories => categories.map(area => ({ id: area.id, name: area.name }))));

	constructor(private _store: Store<IAppState>) {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();

		this._store.dispatch(
			setFilters({
				filter: { sortBy: 'name', sortDirection: EnumSortDirection.Asc }
			})
		);
	}
}
