// This file has been generated from MediaTypeUpdateDto.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.Domain.Settings.Setting.Item.MediaTypeUpdateDto
export class MediaTypeUpdateDto {

	// Id
	id: string;
	// Name
	name: string;
	// ClearanceDateOffset
	clearanceDateOffset: number;

	constructor() {

	}
}