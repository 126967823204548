<h2 mat-dialog-title>
	<p class="color-warn"><mat-icon class="red-icon">error </mat-icon> &nbsp;Fatal Error</p>
</h2>
<mat-dialog-content>
	<div>
		Please contact technical support at: <a href="tel:{{ phoneNumber }}">{{ phoneNumber }}</a>
	</div>
	<div>
		A critical error occurred at
		<span class="timestamp">{{ date | moment : 'medium' }}</span>
	</div>
	<div [innerHTML]="data.html"></div>
</mat-dialog-content>
<mat-dialog-actions style="place-content: flex-end">
	<button mat-raised-button throttleButton (throttledClick)="goHome()">Home</button>
	&nbsp;
	<button mat-raised-button onclick="location.reload()" color="primary">Reload</button>
</mat-dialog-actions>
