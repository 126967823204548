import { Component, Input, OnInit } from '@angular/core';

import { get } from 'lodash-es';

import { FilterSearchButtonComponent } from './filter-search-button.component';

@Component({
	selector: 'filter-full-text-search-button',
	styleUrls: ['./filter-base.component.scss'],
	template: `
		<filter-button [root]="root" [name]="name" [showTitleAsPrefix]="showTitleAsPrefix" [label]="label">
			<mat-form-field class="search-container">
				<mat-placeholder>Contains text</mat-placeholder>
				<input #searchInput matInput [formControl]="formControl" (keyup.enter)="enter()" type="search" />
				<button
					mat-button
					matSuffix
					mat-icon-button
					aria-label="search"
					throttleButton
					(throttledClick)="applyFilter()"
				>
					<mat-icon>search</mat-icon>
				</button>
			</mat-form-field>
			<div class="action-buttons">
				<span throttleButton (throttledClick)="clear()">Clear</span>
				<mat-checkbox [formControl]="optionFormControl">{{ optionLabel }}</mat-checkbox>
			</div>
		</filter-button>
	`
})
export class FilterFullTextSearchButtonComponent extends FilterSearchButtonComponent implements OnInit {
	@Input() optionLabel: string;
	@Input() optionName: string;
	optionControl: AbstractControl;

	get optionFormControl(): FormControl {
		return this.optionControl as FormControl;
	}

	ngOnInit(): void {
		super.ngOnInit();

		if (!!this.root) {
			const form = get(this.root, 'form');
			if (form) {
				this.optionControl = form.get(this.optionName);
			}
		}
	}
}
