<mat-dialog-content>
	<h1>Please confirm details</h1>

	<div class="container" *ngIf="upcomingInvoice" [formGroup]="form">
		<p *ngIf="!!isChangingSubscriptionModel">
			You are changing your subscription from <b>{{ oldSubscriptionModelText }} </b>to <b>{{ newSubscriptionModelText }}</b>
		</p>
		<p *ngIf="!!isChangingQuantity">
			{{ isChangingSubscriptionModel ? 'and your' : 'You are changing your' }} number of licences from <b>{{ oldQuantity }}</b> to
			<b>{{ quantity }}</b>
		</p>
		<p *ngIf="!!oldQuantity && !!quantity && oldQuantity === quantity">Please enter the quantity below:</p>

		<quantity-slider placeholder=" Number of Licenses " [control]="form.controls.quantity"> </quantity-slider>

		<div *ngIf="!!quantity && !!quantity && quantity < numberOfActiveUsers" class="warning">
			<mat-icon color="warn">warning_amber</mat-icon>
			<span> There are {{ numberOfActiveUsers }} active users. You will need {{ numberOfActiveUsers - quantity }} more License(s) </span>
		</div>

		<div *ngIf="overlappingModules?.length > 0" class="warning">
			<mat-icon color="warn">warning_amber</mat-icon>
			<span
				>The features <b>{{ overlappingFeatures.join(', ') }}</b> are already active on <b>{{ overlappingModulesNameString }}</b></span
			>
		</div>

		<div *ngIf="form.valid">
			<upcoming-invoice-breakdown
				[upcomingInvoice]="upcomingInvoice"
				[recurringInvoice]="recurringInvoice"
				[interval]="interval"
			></upcoming-invoice-breakdown>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-raised-button mat-dialog-close>Cancel</button>
	<button mat-raised-button color="primary" [disabled]="!isFormValid || isLoading" throttleButton (throttledClick)="confirmSubscription()">Confirm</button>
	<div *ngIf="!!isLoading" class="loadingContainer">
		<p *ngIf="loadingMessage">{{ loadingMessage }}</p>
		<mat-progress-bar [mode]="this.isQueryLoadingBar ? 'query' : 'indeterminate'"></mat-progress-bar>
	</div>
</mat-dialog-actions>
