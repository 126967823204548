import { SortDirection } from '@angular/material/sort';

import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';
import { ListResponse } from '@common/models/Generic/ListResponse';
import { TaskListItemDto } from '@common/models/Tasks/List/TaskListItemDto';
import { TaskListRequest } from '@common/models/Tasks/List/TaskListRequest';
import { DomainError } from '@common/models/Validation/DomainError';
import { createAction, props } from '@ngrx/store';

import { SelectionType } from '../../SelectionType';

export enum TaskListActions {
	Init = '[Task List] Initialise',
	Load = '[Task List] Load',
	Loading = '[Task List] Loading',
	LoadSuccess = '[Task List] Load Success',
	LoadFailed = '[Task List] Load Failed',

	SetFilters = '[Task List] Set Filters',
	SetFilterProp = '[Task List] Set Filter Property',
	SetPageSize = '[Task List] Set Page Size',
	SetPageIndex = '[Task List] Set Page Index',
	SetPageIndexForId = '[Task List] Set Page Index For Id',

	SetSortBy = '[Task List] Set Sort By',
	SetSortDirection = '[Task List] Set Sort Direction',

	InsertRecords = '[Task List] Insert Records',
	UpdateRecords = '[Task List] Update Records',
	RemoveRecords = '[Task List] Remove Records',
	SelectRecords = '[Task List] Select Records'
}

export const init = createAction(TaskListActions.Init);
export const load = createAction(TaskListActions.Load);
export const loading = createAction(TaskListActions.Loading);
export const loadSuccess = createAction(
	TaskListActions.LoadSuccess,
	props<{ response: ListResponse<TaskListItemDto> }>()
);
export const loadFailed = createAction(TaskListActions.LoadFailed, props<{ error: DomainError }>());

export const setFilters = createAction(TaskListActions.SetFilters, props<{ filter: Partial<TaskListRequest> }>());
export const setFilterProp = createAction(
	TaskListActions.SetFilterProp,
	props<{ prop: keyof TaskListRequest; value: any }>()
);
export const setPageSize = createAction(TaskListActions.SetPageSize, props<{ pageSize: number }>());
export const setPageIndex = createAction(TaskListActions.SetPageIndex, props<{ pageIndex: number }>());
export const setPageIndexForId = createAction(TaskListActions.SetPageIndexForId, props<{ id: string }>());

export const setSortBy = createAction(TaskListActions.SetSortBy, props<{ sortBy: string }>());
export const setSortDirection = createAction(
	TaskListActions.SetSortDirection,
	props<{ sortDirection: SortDirection }>()
);

export const insertRecords = createAction(
	TaskListActions.InsertRecords,
	props<{ response: MutationResponseDto | MutationResponseDto[] }>()
);
export const updateRecords = createAction(
	TaskListActions.UpdateRecords,
	props<{ response: MutationResponseDto | MutationResponseDto[] }>()
);
export const removeRecords = createAction(
	TaskListActions.RemoveRecords,
	props<{ response: MutationResponseDto | MutationResponseDto[] }>()
);
export const selectRecords = createAction(
	TaskListActions.SelectRecords,
	props<{ row: TaskListItemDto; selectionType: SelectionType }>()
);
