import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { combineLatest, of, Subscription } from 'rxjs';
import { catchError, debounceTime, filter, map, startWith, switchMap, tap } from 'rxjs/operators';

import { CalculateExpressionResultDto } from '@common/models/Calculations/Item/CalculateExpressionResultDto';
import { CalculationConstantListItemDto } from '@common/models/Calculations/List/CalculationConstantListItemDto';
import { CalculationFunctionListItemDto } from '@common/models/Calculations/List/CalculationFunctionListItemDto';
import { CalculationVariableListItemDto } from '@common/models/Calculations/List/CalculationVariableListItemDto';
import { CustomFieldEntityType } from '@common/models/Settings/CustomFields/Common/CustomFieldEntityType';
import { DomainError } from '@common/models/Validation/DomainError';
import { ContactCustomFieldsService } from '@common/services/customfields-contact.service';
import { MatterCustomFieldsService } from '@common/services/customfields-matter.service';
import { CustomValidators } from '@common/validation/custom.validators';
import * as moment from 'moment-timezone';

@Component({
	selector: 'expression-builder-dialog',
	styleUrls: ['./expression-builder-dialog.component.scss'],
	templateUrl: './expression-builder-dialog.component.html'
})
export class ExpressionBuilderDialogComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('expressionInput', { read: ElementRef, static: false })
	expressionElement: ElementRef;

	private _subscriptions = new Subscription();

	private _operators: IOperator[];
	private _variables: CalculationVariableListItemDto[];
	private _functions: CalculationFunctionListItemDto[];
	private _constants: CalculationConstantListItemDto[];
	private _expressionResult: string;

	private _filteredOperators: IOperator[];
	private _filteredVariables: CalculationVariableListItemDto[];
	private _filteredFunctions: CalculationFunctionListItemDto[];
	private _filteredConstants: CalculationConstantListItemDto[];

	get operators(): IOperator[] {
		return this._filteredOperators;
	}

	get variables(): CalculationVariableListItemDto[] {
		return this._filteredVariables;
	}

	get functions(): CalculationFunctionListItemDto[] {
		return this._filteredFunctions;
	}

	get constants(): CalculationConstantListItemDto[] {
		return this._filteredConstants;
	}

	private _form: FormGroup;

	get searchControl() {
		return this._form.get('search');
	}

	get searchValue() {
		return this.searchControl.value ?? '';
	}

	get expressionControl() {
		return this._form.get('expression');
	}

	get expressionValue() {
		return this.expressionControl.value ?? '';
	}

	set expressionValue(value: string) {
		this.expressionControl.setValue(value);
	}

	private get entityId() {
		return this.entityIdControl.value;
	}

	get formValid() {
		return !this._form.invalid;
	}

	get entityIdControl() {
		return this._form.get('entityId');
	}

	get expressionResult() {
		return this._expressionResult;
	}

	get isMatterType() {
		return this._data.type === CustomFieldEntityType.Matter;
	}

	get isContactType() {
		return this._data.type === CustomFieldEntityType.Contact;
	}

	constructor(
		@Inject(MAT_DIALOG_DATA) private _data: IExpressionBuilderDialogData,
		private _dialogRef: MatDialogRef<ExpressionBuilderDialogComponent>,
		private _matterCustomFieldsService: MatterCustomFieldsService,
		private _contactCustomFieldsService: ContactCustomFieldsService,
		private _fb: FormBuilder
	) {}

	ngOnInit(): void {
		this._form = this._fb.group({
			entityId: null,
			expression: this._data?.expression ?? null,
			search: null
		});
	}

	ngAfterViewInit() {
		if (!!this._data?.type) {
			this._subscriptions.add(
				(this._data.type === CustomFieldEntityType.Matter
					? this._matterCustomFieldsService.getMatterCalculationDetails()
					: this._contactCustomFieldsService.getContactCalculationDetails()
				).subscribe(details => {
					this._variables = details?.variables;
					this._functions = details?.functions;
					this._constants = details?.constants;

					this._filteredVariables = this._variables;
					this._filteredFunctions = this._functions;
					this._filteredConstants = this._constants;
				})
			);

			if (!!this._data?.entityId) {
				this.entityIdControl.setValue(this._data.entityId);
			}
		}

		this._operators = [
			{
				name: 'Addition',
				symbol: '+'
			},
			{
				name: 'Subtraction',
				symbol: '-'
			},
			{
				name: 'Multiplication',
				symbol: '*'
			},
			{
				name: 'Division',
				symbol: '/'
			},
			{
				name: 'Modulo',
				symbol: '%'
			},
			{
				name: 'Greater Than',
				symbol: '>'
			},
			{
				name: 'Greater Or Equal Than',
				symbol: '>='
			},
			{
				name: 'Less Than',
				symbol: '<'
			},
			{
				name: 'Less Or Equal Than',
				symbol: '<='
			}
		];

		this._filteredOperators = this._operators;

		this.focusExpressionInput();

		this._subscriptions.add(
			combineLatest([
				this.entityIdControl.valueChanges.pipe(startWith(null as string)),
				this.expressionControl.valueChanges.pipe(startWith(null as string))
			])
				.pipe(
					debounceTime(1000),
					switchMap(() => this.calculateExpression$())
				)
				.subscribe()
		);

		this._subscriptions.add(
			this.searchControl.valueChanges.pipe(map(value => value?.toLowerCase())).subscribe(value => {
				if (!value) {
					this._filteredOperators = this._operators;
					this._filteredVariables = this._variables;
					this._filteredFunctions = this._functions;
					this._filteredConstants = this._constants;
				} else {
					this._filteredOperators = this._operators.filter(operator =>
						operator.name.toLowerCase().includes(value)
					);
					this._filteredVariables = this._variables.filter(variable =>
						variable.name.toLowerCase().includes(value)
					);
					this._filteredFunctions = this._functions.filter($function =>
						$function.name.toLowerCase().includes(value)
					);
					this._filteredConstants = this._constants.filter(constant =>
						constant.name.toLowerCase().includes(value)
					);
				}
			})
		);

		this._subscriptions.add(
			this.entityIdControl.valueChanges.pipe(filter(value => !value)).subscribe(() => {
				this.expressionControl.setErrors(null);
			})
		);
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}

	onVariableClicked(variable: CalculationVariableListItemDto) {
		if (!variable) {
			return;
		}

		const name = (
			variable.isCustomField ? `CustomField__${variable.name.replace(/ /g, '_')}` : variable.name
		).replace(/\./g, '__');

		const position = this.insertExpressionValue(name, false);

		this.selectNextArguement(position, name);
	}

	onOperatorClicked(operator: IOperator) {
		if (!operator) {
			return;
		}

		const position = this.insertExpressionValue(operator.symbol, false);

		this.selectNextArguement(position, operator.symbol);
	}

	onFunctionClicked($function: CalculationFunctionListItemDto) {
		if (!$function?.name) {
			return;
		}

		const functionValue = `${$function.name}(${
			$function.parameters?.map(parameter => `<${parameter}>`)?.join(', ') ?? ''
		})`;

		const position = this.insertExpressionValue(functionValue, true);

		this.selectNextArguement(position, $function.name, true);
	}

	onConstantClicked(constant: CalculationConstantListItemDto) {
		if (!constant?.name) {
			return;
		}

		const position = this.insertExpressionValue(constant.name, false);

		this.selectNextArguement(position, constant.name);
	}

	onSaveClicked() {
		this._dialogRef.close(this.expressionValue);
	}

	clearSearch() {
		this.searchControl.setValue(null);
	}

	clearExpression() {
		this.expressionControl.setValue(null);
	}

	isDate(date: string) {
		return !!date && !!CustomValidators.containsDateRegex.test(date) && !!moment(date).isValid();
	}

	toLocalDate(date: string) {
		return moment(date).format();
	}

	private calculateExpression$() {
		return (
			!!this.entityId
				? this._data.type === CustomFieldEntityType.Matter
					? this._matterCustomFieldsService.calculateMatterExpression(this.entityId, {
							expression: this.expressionValue
					  })
					: this._contactCustomFieldsService.calculateContactExpression(this.entityId, {
							expression: this.expressionValue
					  })
				: of<CalculateExpressionResultDto>(null)
		).pipe(
			tap(dto => {
				this._expressionResult = dto?.result?.toString();
				this.expressionControl.setErrors(null);
			}),
			catchError((errors: DomainError[]) => {
				if (!!errors?.length) {
					this.expressionControl.setErrors({ error: errors[0].message });
				} else {
					this.expressionControl.setErrors({ error: 'Could not parse expression' });
				}

				return of({});
			})
		);
	}

	private insertExpressionValue(value: string, tryWrap: boolean): { start: number; end: number } {
		const start: number = this.expressionElement.nativeElement.selectionStart;
		const end: number = this.expressionElement.nativeElement.selectionEnd;

		const selected = this.expressionValue.substring(start, end);

		const leftPart = start > 0 ? this.expressionValue.substring(0, start) : '';
		const rightPart =
			end < this.expressionValue.length ? this.expressionValue.substring(end, this.expressionValue.length) : '';

		if (!tryWrap || !selected || (selected.startsWith('<') && selected.endsWith('>'))) {
			this.expressionValue = `${leftPart}${value}${rightPart}`;
		} else {
			let newValue = '';

			const openBracketIndex = value.indexOf('<');
			const closeBracketIndex = value.indexOf('>');

			if (openBracketIndex > 0 && closeBracketIndex > openBracketIndex) {
				const endIndex = closeBracketIndex + 1;

				const replaceLeftPart = value.substring(0, openBracketIndex);
				const replaceRightPart = endIndex >= value.length ? '' : value.substring(endIndex, value.length);

				newValue = `${replaceLeftPart}${selected}${replaceRightPart}`;
			} else {
				newValue = value;
			}

			this.expressionValue = `${leftPart}${newValue}${rightPart}`;
		}

		this.focusExpressionInput();

		return { start, end };
	}

	private selectNextArguement(position: { start: number; end: number }, value: string, force?: boolean) {
		if (!!force || position.end > position.start) {
			const relevantValue = this.expressionValue.substring(position.start, this.expressionValue.length);
			const relativeStart = relevantValue.search(/<(.*?)>/);

			if (relativeStart >= 0) {
				const relativeEnd = relevantValue.indexOf('>') + 1;

				const start = relativeStart + this.expressionValue.length - relevantValue.length;
				const end = relativeEnd + this.expressionValue.length - relevantValue.length;

				this.expressionElement.nativeElement.selectionStart = start;
				this.expressionElement.nativeElement.selectionEnd = end;
			}
		} else {
			const start = position.start + value.length;

			this.expressionElement.nativeElement.selectionStart = start;
			this.expressionElement.nativeElement.selectionEnd = start;
		}
	}

	private focusExpressionInput() {
		this.expressionElement.nativeElement.focus();
	}
}

export interface IExpressionBuilderDialogData {
	type: CustomFieldEntityType;
	expression: string;
	entityId: string;
}

export interface IOperator {
	name: string;
	symbol: string;
}
