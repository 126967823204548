import { Component, EventEmitter, Input, Output } from '@angular/core';

import { MatSelectChange } from '@angular/material/select';
import { get, isNil } from 'lodash';
import { BaseEditableComponent } from './base.editable.component';

@Component({
	selector: 'editable-selection',
	styleUrls: ['./base.editable.component.scss'],
	template: `
		<span *ngIf="!editMode" [class]="getReadonlyName() ? '' : 'view-noitem'">
			{{ formatDisplay(getReadonlyName() ? getReadonlyName() : blankMessage) }}
		</span>
		<mat-form-field *ngIf="editMode && !useAutocomplete">
			<mat-placeholder *ngIf="placeHolder"
				>{{ placeHolder }}
				<sup class="color-warn" *ngIf="required">*</sup>
			</mat-placeholder>
			<mat-select [formControl]="formControl" (selectionChange)="selectionChanged($event)">
				<mat-option *ngFor="let val of options" [value]="getValue(val)">{{ getName(val) }}</mat-option>
			</mat-select>
			<mat-hint *ngIf="!!hintText?.length">{{ hintText }}</mat-hint>
			<mat-error>
				<error-messages [for]="control"></error-messages>
			</mat-error>
		</mat-form-field>

		<autocomplete-dropdown
			*ngIf="editMode && useAutocomplete"
			[control]="formControl"
			[options]="options"
			optionValue="id"
			optionName="name"
			[placeHolder]="placeHolder"
			[hintText]="hintText"
			[displayPipe]="displayPipe"
		></autocomplete-dropdown>
	`
})
export class EditableSelectionComponent<T> extends BaseEditableComponent<T> {
	// Available options for binding to the list
	@Input()
	options: T[];
	// The 'ID' field in the 'Options'
	@Input()
	optionValue: keyof T;
	// The 'Name' field in the 'Options'
	@Input()
	optionName: keyof T;
	// The default display 'Name'
	@Input()
	defaultName: string;

	@Input()
	useAutocomplete: boolean;

	@Output()
	selectionChange = new EventEmitter();

	getValue(val: T) {
		return isNil(this.optionValue) ? val : get(val, this.optionValue);
	}

	getName(val: T): string {
		return isNil(this.optionName) ? val : get(val, this.optionName);
	}

	getReadonlyName(): string {
		const val = this.value();
		if (isNil(val)) return this.defaultName;

		// For the 'View' mode, the whole dictionary ('Options') is not available, so show 'DefaultName'
		if (isNil(this.optionValue) || isNil(this.options)) {
			return this.defaultName || val.toString();
		} else {
			// For switching back to the 'View' mode, show the value from the dictionary
			const opt: T = this.options.find(o => get(o, this.optionValue) === val);
			return this.getName(opt);
		}
	}

	selectionChanged(event: MatSelectChange) {
		this.selectionChange.emit(event);
	}
}
