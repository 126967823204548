import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

import { NotificationService } from '@common/notification';

import { SecurityPermissionService } from 'app/core/security-permissions.service';

@Injectable({
	providedIn: 'root'
})
export class AppRouteGuardService {
	hasAccessToTrust = false;

	constructor(
		private securityPermissionService: SecurityPermissionService,
		private notificationService: NotificationService,
		private router: Router
	) {}

	canActivate(route: ActivatedRouteSnapshot) {
		switch (route.routeConfig.path) {
			case 'trust':
				if (!!this.securityPermissionService.hasAccessToTrust) {
					return true;
				}
				this.showNotification('Trust');
				break;
			case 'published':
				if (!!this.securityPermissionService.hasAccessToTrust) {
					return true;
				}
				this.showNotification('Published Reports');
				break;
			case 'reports':
				if (!!this.securityPermissionService.hasAccessToReports) {
					return true;
				}
				this.showNotification('Reports');
				break;
			default:
				return true;
		}
		return false;
	}

	private showNotification(pageName: string) {
		this.notificationService
			.showError('No Permission', `Sorry, you don't have the necessary permissions to access ${pageName} page.`)
			.subscribe(() => {
				this.router.navigate(['/dashboard']);
			});
	}
}
