// This file has been generated from MarketplaceVendorController.cs
// Do not modify this file directly!!
// tslint:disable

import { HttpClient, HttpResponse } from '@angular/common/http';
import { IDownloadFile } from '../../models/Files/IFileDownloader';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { TrimStringsOnObject } from '@common/utils/string-format';

import { ListResponse } from '../../models/Generic/ListResponse';
import { MarketplaceVendorPackageViewDto } from '../../models/Marketplace/Vendor/Item/MarketplaceVendorPackageViewDto';
import { MarketplaceVendorPackageInternalListRequest } from '../../models/Marketplace/Vendor/List/MarketplaceVendorPackageInternalListRequest';
import { MarketplaceVendorPackageListItemDto } from '../../models/Marketplace/Vendor/List/MarketplaceVendorPackageListItemDto';
import { MarketplaceVendorPackageListRequest } from '../../models/Marketplace/Vendor/List/MarketplaceVendorPackageListRequest';
import { MarketplaceVendorRebuildRequest } from '../../models/Marketplace/Vendor/List/MarketplaceVendorRebuildRequest';

@Injectable({
	providedIn: 'root'
})
export class MarketplaceVendorService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'api/v1/MarketplaceVendor');
	}

	generateNewPackage(): Observable<HttpResponse<Blob>> {
		return this.getBlob<void>(`GenerateNewPackage`);
	}

	getMarketplacePackage(id: string): Observable<MarketplaceVendorPackageViewDto> {
		return this.getItem<void, MarketplaceVendorPackageViewDto>(`${id}`);
	}

	getMyMarketplacePackageList(request?: Partial<MarketplaceVendorPackageListRequest>): Observable<ListResponse<MarketplaceVendorPackageListItemDto>> {
		return this.getList<MarketplaceVendorPackageListRequest, MarketplaceVendorPackageListItemDto>('', request);
	}

	getMarketplacePackageList(request?: Partial<MarketplaceVendorPackageInternalListRequest>): Observable<ListResponse<MarketplaceVendorPackageListItemDto>> {
		return this.getList<MarketplaceVendorPackageInternalListRequest, MarketplaceVendorPackageListItemDto>(`all`, request);
	}

	rebuildMyPackageList(): Observable<any> {
		return this.post<void, any>(`RebuildMyPackageList`);
	}

	rebuildPackageList(request: MarketplaceVendorRebuildRequest): Observable<any> {
		return this.post<MarketplaceVendorRebuildRequest, any>(`RebuildPackageList`, request);
	}

	rebuildAllPackageLists(): Observable<any> {
		return this.post<void, any>(`RebuildAllPackageLists`);
	}

}
