// This file has been generated from TaxConfiguration.cs
// Do not modify this file directly!!

// LawMaster.Amalthea.Domain.Settings.BillSettings.Common.TaxConfiguration
export enum TaxConfiguration {
	On = 'On',
	Off = 'Off',
	OptionalOn = 'Optional On',
	OptionalOff = 'Optional Off'
}
