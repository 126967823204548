import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AfterViewInit, Directive, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';

import { NEVER, Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { BaseListItemDto } from '@common/models/Common/BaseListItemDto';
import { EntityReference } from '@common/models/Common/EntityReference';
import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';
import { ChangeOrderNumberDto } from '@common/models/Settings/CustomFields/Item/ChangeOrderNumberDto';
import { DeleteCustomFieldResponseDto } from '@common/models/Settings/CustomFields/Item/DeleteCustomFieldResponseDto';
import { CustomFieldGroupListItemDto } from '@common/models/Settings/CustomFields/List/CustomFieldGroupListItemDto';
import { DomainError } from '@common/models/Validation/DomainError';
import { NotificationService } from '@common/notification';
import { Store } from '@ngrx/store';

import { FeatureFlags, isFeatureFlagEnabled } from 'app/app.config';
import { IAppState } from 'app/core/state/app.state';
import { GridViewService } from 'app/services/grid-view.service';
import { GenericListStateComponent } from 'app/shared/generics/generic.list.state.component';

@Directive()
export abstract class BaseCustomFieldListComponent<
		TCustomFieldCreateDto,
		TCustomFieldListRequest,
		TCustomFieldListItemDto extends BaseListItemDto,
		TCustomFieldUpdateDto,
		TCustomFieldGroupCreateDto
	>
	extends GenericListStateComponent<TCustomFieldListItemDto, TCustomFieldListRequest>
	implements AfterViewInit, OnInit
{
	customFieldGroups: CustomFieldGroupListItemDto[];
	selectedGroupId: string;
	@ViewChild(MatTable) table: MatTable<TCustomFieldListItemDto>;
	protected static componentInstance: any;
	private maxNumberOfRefences: number = 20;
	constructor(
		store: Store<IAppState>,
		columns: string[],
		dialog: MatDialog,
		router: Router,
		activatedRoute: ActivatedRoute,
		protected notificationService: NotificationService,
		gridViewService: GridViewService
	) {
		super(
			BaseCustomFieldListComponent.filterColumns(columns),
			dialog,
			store,
			router,
			activatedRoute,
			gridViewService
		);
		BaseCustomFieldListComponent.componentInstance = this;
	}

	ngAfterViewInit(): void {
		super.ngAfterViewInit();

		const params = this.activatedRoute.snapshot.queryParams;

		const fieldId = params['fieldId'];
		if (!!fieldId) {
			const showBuilder = !!params['showBuilder'];
			const builderEntityId = params['builderEntityId'];

			this.editCustomField(fieldId, showBuilder, builderEntityId);
		}
	}

	ngOnInit(): void {
		super.ngOnInit();
	}

	patchCustomField(ids: string[], propertyName: string, value: any) {
		this.servicePatch(ids, propertyName, value).subscribe(
			result => {
				this.notificationService.showNotification(`${result.name} updated`);
				this.refreshList(result);
			},
			errors => this.notificationService.showErrors('Error updating custom field', errors)
		);
	}

	dropGroup(event: CdkDragDrop<string[]>) {
		if (event.previousIndex === event.currentIndex) return;
		if (event.previousContainer === event.container) {
			const sourceRecord = this.customFieldGroups[event.previousIndex];
			const destinationRecord = this.customFieldGroups[event.currentIndex];
			moveItemInArray(this.customFieldGroups, event.previousIndex, event.currentIndex);
			this.subscriptions.add(
				this.changeGroupOrderNumber(sourceRecord.id, destinationRecord.id).subscribe(
					() => {
						this.notificationService.showNotification(`Order saved for group ${sourceRecord.name}`);
					},
					error => {
						this.notificationService.showError(
							'Error Ordering',
							'There was an error ordering the custom field groups'
						);
						// Revert the Drag Drop operation in case there is an error on the server
						moveItemInArray(this.customFieldGroups, event.currentIndex, event.previousIndex);
					}
				)
			);
		}
	}

	dropFieldRow(event: CdkDragDrop<string[]>) {
		if (event.previousIndex === event.currentIndex) return;
		if (!this.selectedGroupId) {
			this.notificationService.showError(
				'Ordering Disabled',
				`Custom fields can only be ordered within a group. Please select a group to order the custom fields`
			);
			return;
		}

		if (event.previousContainer === event.container) {
			this.subscriptions.add(
				this.data$
					.pipe(
						filter(Boolean),
						map(data => data)
					)
					.subscribe(records => {
						const sourceRecord = records[event.previousIndex];
						const destinationRecord = records[event.currentIndex];
						moveItemInArray(records, event.previousIndex, event.currentIndex);
						this.changeOrderNumber({
							groupId: this.selectedGroupId,
							sourceId: sourceRecord.id,
							destinationId: destinationRecord.id
						}).subscribe(
							() => {
								this.notificationService.showNotification('Custom field order saved');
							},
							error => {
								this.notificationService.showError(
									'Error Ordering',
									'There was an error ordering the custom fields'
								);
								// Revert the Drag Drop operation in case there is an error on the server
								moveItemInArray(records, event.currentIndex, event.previousIndex);
								setTimeout(() => {
									this.table.renderRows();
								}, 0);
							}
						);
					})
			);
		}

		// Table needs to be re rendered for the drag drop to take effect.
		setTimeout(() => {
			this.table.renderRows();
		}, 0);
	}

	protected abstract serviceCreate(data: TCustomFieldCreateDto): Observable<MutationResponseDto>;
	protected abstract serviceCreateGroup(data: TCustomFieldGroupCreateDto): Observable<MutationResponseDto>;
	protected abstract serviceUpdate(id: string, data: TCustomFieldUpdateDto): Observable<MutationResponseDto>;
	protected abstract serviceUpdateGroup(id: string, data: TCustomFieldGroupCreateDto): Observable<EntityReference>;
	protected abstract serviceDelete(id: string): Observable<MutationResponseDto>;
	protected abstract validateServiceDelete(id: string): Observable<DeleteCustomFieldResponseDto>;
	protected abstract serviceDeleteGroup(id: string): Observable<MutationResponseDto>;
	protected abstract servicePatch(
		ids: string[],
		propertyName: string,
		value: string
	): Observable<MutationResponseDto>;
	protected abstract changeOrderNumber(dto: ChangeOrderNumberDto): Observable<MutationResponseDto>;
	protected abstract changeGroupOrderNumber(sourceId: string, destinationId: string): Observable<MutationResponseDto>;
	protected abstract serviceAddGroupToFields(groupId: string, ids: string[]): Observable<MutationResponseDto>;
	protected abstract serviceRemoveGroupToFields(groupId: string, ids: string[]): Observable<MutationResponseDto>;
	protected abstract serviceMoveFieldsToGroup(groupId: string, ids: string[]): Observable<MutationResponseDto>;

	protected abstract refreshGroups(): void;
	protected abstract selectGroup(id: string): void;
	protected abstract editCustomField(id: string, openBuilder?: boolean, builderEntityId?: string): void;
	protected abstract refreshList(dto: MutationResponseDto): void;

	protected afterCreateClosed(closeDialogRef: Observable<any>): void {
		this.subscriptions.add(
			closeDialogRef
				.pipe(
					filter(Boolean),
					switchMap((data: TCustomFieldCreateDto) => this.serviceCreate(data))
				)
				.subscribe(
					(result: MutationResponseDto) => {
						this.notificationService.showNotification(`${result.name} created`);
						this.refreshGroups();
						this.refreshList(result);
					},
					errors => this.notificationService.showErrors('Error creating custom field', errors)
				)
		);
	}

	protected afterGroupCreateClosed(closeDialogRef: Observable<any>): void {
		this.subscriptions.add(
			closeDialogRef
				.pipe(
					filter(Boolean),
					switchMap((data: TCustomFieldGroupCreateDto) => this.serviceCreateGroup(data))
				)
				.subscribe(
					(result: MutationResponseDto) => {
						this.notificationService.showNotification(`Group ${result.name} created`);
						this.refreshGroups();
						this.selectGroup(result.id);
					},
					errors => this.notificationService.showErrors('Error creating custom field group', errors)
				)
		);
	}

	protected afterEditClosed(closeDialogRef: Observable<any>, id: string): void {
		this.subscriptions.add(
			closeDialogRef
				.pipe(
					filter(Boolean),
					switchMap((data: TCustomFieldUpdateDto) => this.serviceUpdate(id, data))
				)
				.subscribe(
					(result: MutationResponseDto) => {
						this.notificationService.showNotification(`${result.name} updated`);
						this.refreshGroups();
						this.refreshList(result);
					},
					errors => this.notificationService.showErrors('Error updating custom field', errors)
				)
		);
	}

	protected afterGroupEditClosed(closeDialogRef: Observable<any>, id: string): void {
		this.subscriptions.add(
			closeDialogRef
				.pipe(
					filter(Boolean),
					switchMap((data: TCustomFieldGroupCreateDto) => this.serviceUpdateGroup(id, data))
				)
				.subscribe(
					(result: MutationResponseDto) => {
						this.notificationService.showNotification(`${result.name} updated`);
						this.refreshGroups();
						this.refreshList(result);
					},
					errors => this.notificationService.showErrors('Error updating custom field', errors)
				)
		);
	}

	deleteCustomField(id: string) {
		this.subscriptions.add(
			this.validateServiceDelete(id)
				.pipe(
					switchMap(resp => {
						let formattedErrors: string = '';
						if (resp.errorMessage && resp.errorMessage.length > 0) {
							formattedErrors = `<div>${resp.errorMessage}</div>`;
							if (resp.references.length > 0) {
								formattedErrors += '<br><div>References:</div><ul>';
								for (const error of resp.references.slice(0, this.maxNumberOfRefences - 1)) {
									formattedErrors += '<li>' + `<a href='/${error.id}'">${error.name}</a>` + '</li>';
								}

								if (resp.references.length > this.maxNumberOfRefences) {
									formattedErrors += `<li>+${
										resp.references.length - this.maxNumberOfRefences
									} more...</li><ul>`;
								}
								formattedErrors += '</ul>';
							}

							this.notificationService.showError(
								'Custom field cannot be deleted',
								formattedErrors,
								false
							);
							return NEVER;
						} else {
							return this.notificationService
								.showConfirmation('Delete Custom Field', 'Are you sure you want to delete this record?')
								.pipe(
									filter(Boolean),
									switchMap(() => this.serviceDelete(id))
								);
						}
					})
				)
				.subscribe({
					next: (resp: MutationResponseDto) => {
						this.notificationService.showNotification(`${resp.name} Deleted`);
						this.refreshGroups();
						this.refreshList(resp);
					},
					error: errors => this.notificationService.showErrors('Error deleting custom field', errors)
				})
		);
	}

	deleteCustomFieldGroup(customFieldGroup: CustomFieldGroupListItemDto) {
		this.subscriptions.add(
			this.notificationService
				.showConfirmation(
					'Delete group',
					`Are you sure you want to delete the group '${customFieldGroup.name}'?`
				)
				.pipe(
					filter(Boolean),
					switchMap(() => this.serviceDeleteGroup(customFieldGroup.id))
				)
				.subscribe(
					(response: MutationResponseDto) => {
						this.refreshGroups();
						this.refreshList(response);
					},
					(errors: DomainError[]) => {
						this.notificationService.showErrors('Cannot Delete Group', errors);
					}
				)
		);
	}

	patchSelectedFields(propertyName: string, value: any) {
		this.selected$.pipe(map(selected => selected)).subscribe(selectedListItems => {
			if (selectedListItems.length === 0) {
				this.notificationService.showNotification(`Please select the fields by Ctrl + Click`);
				return;
			}
			if (selectedListItems.length > 500) {
				this.notificationService.showError(
					'Operation failed',
					`You can only perform bulk operations for up to 500 fields at a time.`
				);
				return;
			}
			this.patchCustomField(
				selectedListItems.map(x => x.id),
				propertyName,
				value
			);
		});
	}

	getGroupsToRemove() {
		if (!this.selected$ || !this.customFieldGroups) {
			return of([] as CustomFieldGroupListItemDto[]);
		}

		return this.selected$.pipe(
			map(selected => {
				return this.customFieldGroups.filter(x =>
					x.customFieldIds.some(fieldId => selected.map(x => x.id).includes(fieldId))
				);
			})
		);
	}

	addGroupToFields(group: CustomFieldGroupListItemDto) {
		this.performBulkOPeration(
			this.serviceAddGroupToFields,
			group,
			`Group ${group.name} has been added to the selected custom fields.`
		);
	}

	removeGroupFromFields(group: CustomFieldGroupListItemDto) {
		this.performBulkOPeration(
			this.serviceRemoveGroupToFields,
			group,
			`Group ${group.name} has been removed from the selected custom fields.`
		);
	}
	moveFieldsToGroup(group: CustomFieldGroupListItemDto) {
		this.notificationService
			.showConfirmation(
				`Move to Group`,
				`This will <b>remove</b> existing groups on 
			the selected custom fields and move them to the group '<b>${group.name}</b>'. Are you sure you want to continue?`
			)
			.pipe(filter(Boolean))
			.subscribe(() => {
				this.performBulkOPeration(
					this.serviceMoveFieldsToGroup,
					group,
					`The selected fields have been moved to the group ${group.name}`
				);
			});
	}

	performBulkOPeration(
		serviceMethod: (groupId: string, ids: string[]) => Observable<MutationResponseDto>,
		group: CustomFieldGroupListItemDto,
		message: string
	) {
		this.selected$.pipe(map(selected => selected)).subscribe(selectedListItems => {
			if (selectedListItems.length === 0) {
				this.notificationService.showNotification(`Please select the fields by Ctrl + Click`);
				return;
			}
			this.subscriptions.add(
				serviceMethod(
					group.id,
					selectedListItems.map(x => x.id)
				).subscribe(
					res => {
						this.refreshList(res);
						this.refreshGroups();
						this.notificationService.showNotification(message);
					},
					(err: DomainError[]) => {
						this.notificationService.showErrors('Action Failed', err);
					}
				)
			);
		});
	}

	hasPackages(group: CustomFieldGroupListItemDto) {
		return !!group.installationRecords?.length;
	}

	getPackageNames(group: CustomFieldGroupListItemDto) {
		return !this.hasPackages(group) ? '' : group.installationRecords.map(record => record.name).join(', ');
	}

	private static filterColumns(columns: string[]) {
		if (!isFeatureFlagEnabled(FeatureFlags.marketplace)) {
			columns = columns.filter(column => column.toLocaleLowerCase() !== 'installationrecords');
		}

		return columns;
	}
}
