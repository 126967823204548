// This file has been generated from CollectionTaskTemplateViewDto.cs
// Do not modify this file directly!!
// tslint:disable

import { SetComplexType, SetSimpleType } from '@common/utils/class-transform';

import { CustomFieldGroupSelection } from '../../../Tasks/Common/CustomFieldGroupSelection';
import { TaskPriority } from '../../../Tasks/Common/TaskPriority';
import { TaskStatus } from '../../../Tasks/Common/TaskStatus';
import { TaskType } from '../../../Tasks/Common/TaskType';
import { TaskTemplateAssignedToContactOptions } from '../Common/TaskTemplateAssignedToContactOptions';
import { TaskTemplateDueDateOptions } from '../Common/TaskTemplateDueDateOptions';

// LawMaster.Amalthea.Domain.Settings.TaskTemplates.Item.CollectionTaskTemplateViewDto
export class CollectionTaskTemplateViewDto {

	// Id
	id: string;
	// BasicWorkflowId
	basicWorkflowId: string;
	// Description
	description: string;
	// Status
	status: keyof typeof TaskStatus;
	// Priority
	priority: keyof typeof TaskPriority;
	// AssignedToContactOptions
	@SetComplexType(TaskTemplateAssignedToContactOptions)
	assignedToContactOptions: TaskTemplateAssignedToContactOptions;
	// DueDateOptions
	@SetComplexType(TaskTemplateDueDateOptions)
	dueDateOptions: TaskTemplateDueDateOptions;
	// TaskType
	taskType: keyof typeof TaskType;
	// SelectedCustomFieldGroups
	selectedCustomFieldGroups: CustomFieldGroupSelection[];
	// AllCollectionFieldsMandatory
	@SetSimpleType(Boolean)
	allCollectionFieldsMandatory: boolean;

	constructor() {
		this.assignedToContactOptions = new TaskTemplateAssignedToContactOptions();
		this.dueDateOptions = new TaskTemplateDueDateOptions();
		this.selectedCustomFieldGroups = [];
	}
}