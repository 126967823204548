import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatExpansionPanel } from '@angular/material/expansion';

import { Subscription } from 'rxjs';
import { delay, startWith } from 'rxjs/operators';

import { get, isNil } from 'lodash-es';
import * as moment from 'moment-timezone';
import { Moment } from 'moment-timezone';

import { FilterBaseComponent } from './filter-base.component';
import { FilterButtonComponent } from './filter-button.component';
import { FilterChangeProperties } from './filter-change-properties';
import {
	FilterDateRangeButtonConfig,
	IDateRangeButtonConfig,
	IDateRangeButtonOption
} from './filter-date-range-button.config';

@Component({
	selector: 'filter-date-range-button',
	styleUrls: ['./filter-base.component.scss'],
	template: `
		<filter-button
			[root]="root"
			[name]="name"
			[label]="label"
			[showTitleAsPrefix]="showTitleAsPrefix"
			[trimDisplayText]="trimDisplayText"
		>
			<mat-nav-list>
				<mat-list-item
					*ngFor="let option of availableOptions"
					throttleButton
					(throttledClick)="selectOption(option)"
				>
					{{ option.label }}
				</mat-list-item>
			</mat-nav-list>
			<mat-expansion-panel *ngIf="btn.isMenuOpen" #customRange [expanded]="showOnlyCustom">
				<mat-expansion-panel-header *ngIf="!showOnlyCustom">
					<mat-panel-title> Custom </mat-panel-title>
				</mat-expansion-panel-header>
				<div class="flex-column">
					<popup-datepicker
						[FormControl]="controlStart"
						[Placeholder]="labelStart"
						[MaxDate]="controlEnd.value"
					>
					</popup-datepicker>
					<popup-datepicker
						[FormControl]="controlEnd"
						[Placeholder]="labelEnd"
						[MinDate]="controlStart.value"
					></popup-datepicker>
				</div>
			</mat-expansion-panel>
			<mat-expansion-panel *ngIf="btn.isMenuOpen && showAsAtDate" #asAtDate [expanded]="showOnlyCustom">
				<mat-expansion-panel-header *ngIf="!showOnlyCustom">
					<mat-panel-title> As at Date </mat-panel-title>
				</mat-expansion-panel-header>
				<div class="flex-column asAtDate">
					<mat-form-field>
						<input matInput [matDatepicker]="picker" (dateChange)="updateAsAtDate($event)" />
						<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
						<mat-datepicker #picker></mat-datepicker>
					</mat-form-field>
				</div>
			</mat-expansion-panel>
			<div class="action-buttons">
				<span mat-button *ngIf="!hideClear" throttleButton (throttledClick)="clear()">Clear</span>
				<span *ngIf="showApplyButton" mat-button throttleButton (throttledClick)="apply()">Apply</span>
			</div>
		</filter-button>
	`
})
export class FilterDateRangeButtonComponent extends FilterBaseComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild(FilterButtonComponent, { static: true })
	btn: FilterButtonComponent;
	@ViewChild('customRange')
	customRange: MatExpansionPanel;

	@ViewChild('asAtDate')
	asAtRange: MatExpansionPanel;

	@Input()
	trimDisplayText: boolean;

	@Input()
	labelEnd: string = 'End date';
	@Input()
	labelStart: string = 'Start date';
	@Input()
	nameEnd: string;
	@Input()
	nameStart: string;
	@Input()
	showPast: boolean;
	@Input()
	showToday: boolean;
	@Input()
	showFuture: boolean;
	@Input()
	showYesterday?: boolean = null;
	@Input()
	showAsAtDate: boolean;

	// If only displaying the custom option then hide the collapsable section and always show the to and from
	showOnlyCustom: boolean = false;
	showApplyButton: boolean = false;

	private config: IDateRangeButtonConfig = FilterDateRangeButtonConfig;
	private subscriptions: Subscription = new Subscription();

	constructor(private cdf: ChangeDetectorRef) {
		super();
	}

	ngOnInit() {
		this.subscriptions.add(
			this.root.filterChange
				.pipe(
					startWith(new FilterChangeProperties<any>(this.root.filter)), // Fire the 1st event with the current value
					delay(0)
				) // Otherwise get an error on changing the state after it's been checked
				.subscribe(val => this.syncSelection(val.filter))
		);
		this.showOnlyCustom = this.availableOptions.length === 0;
	}

	syncSelection(filterValue: any) {
		const start = get(filterValue, this.nameStart);
		const end = get(filterValue, this.nameEnd);
		this.btn.valueSelected(this.selectedLabel(start, end));
	}

	ngAfterViewInit() {
		this.subscriptions.add(
			/// when the button is clicked to show the menu
			this.btn.MenuOpened.subscribe(opened => {
				if (opened) {
					if (!this.customRange) {
						// if the expansion panel isn't bound (because it is in an ngIf), run detect changes
						this.cdf.detectChanges();
					}

					if (!this.asAtRange) {
						// if the expansion panel isn't bound (because it is in an ngIf), run detect changes
						this.cdf.detectChanges();
					}

					if (this.customRange) {
						// subscribe to the open and close events of the expansion panel to toggle the visibility of the apply button
						this.subscriptions.add(this.customRange.opened.subscribe(() => (this.showApplyButton = true)));
						this.subscriptions.add(this.customRange.closed.subscribe(() => (this.showApplyButton = false)));
					}
				} else {
					// when we close the menu, hide the apply button
					this.showApplyButton = this.showOnlyCustom || false;
				}
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	get availableOptions(): IDateRangeButtonOption[] {
		return Object.keys(this.config)
			.filter(
				f =>
					(!!this.config[f].yesterday
						? this.config[f].past && this.showPast && (!!this.showYesterday || this.showYesterday === null)
						: this.config[f].past && this.showPast) ||
					(this.config[f].yesterday && !!this.showYesterday) ||
					(this.config[f].today && this.showToday) ||
					(this.config[f].future && this.showFuture)
			)
			.map(k => this.config[k]);
	}

	get controlEnd() {
		return this.root.form.get(this.nameEnd) as FormControl;
	}

	get controlStart() {
		return this.root.form.get(this.nameStart) as FormControl;
	}

	selectOption(option: IDateRangeButtonOption) {
		this.controlStart.setValue(moment().add(option.start, option.unit).startOf('day'));
		this.controlEnd.setValue(moment().add(option.end, option.unit).endOf('day'));

		this.btn.valueSelected(option.label);
		this.root.applyFilter();
	}

	updateAsAtDate(event: MatDatepickerInputEvent<any>) {
		const value: Moment = event.value;
		this.controlStart.setValue(null);
		if (moment.isMoment(value) && value.hour() < 23) {
			this.controlEnd.setValue(value.endOf('day'));
		}

		this.root.applyFilter();
	}

	apply() {
		if (!isNil(this.controlEnd.value)) {
			const endValue: Moment = moment(this.controlEnd.value);
			if (moment.isMoment(endValue) && endValue.hour() < 23) {
				this.controlEnd.setValue(endValue.endOf('day'));
			}
		}
		const desc = this.selectedLabel(this.controlStart.value, this.controlEnd.value);

		this.btn.valueSelected(desc);
		this.root.applyFilter();
	}

	clear() {
		this.btn.valueSelected(null);
		this.controlStart.reset();
		this.controlEnd.reset();
		this.root.applyFilter();
	}

	private selectedLabel(start?: Moment, end?: Moment): string {
		const dateFormat = 'DD/MM/YYYY';
		let desc = null;

		if (start != null) {
			if (end != null) {
				const selected = this.availableOptions.find(
					option =>
						moment().add(option.start, option.unit).startOf('day').isSame(start, 'day') &&
						moment().add(option.end, option.unit).endOf('day').isSame(end, 'day')
				);
				if (selected) {
					desc = selected.label;
				} else {
					desc = `Between ${moment(start).format(dateFormat)} and ${moment(end).format(dateFormat)}`;
				}
			} else {
				desc = `After ${moment(start).format(dateFormat)}`;
			}
		} else if (end != null) {
			desc = `Before ${moment(end).format(dateFormat)}`;
		}

		return desc;
	}
}
