import { Component } from '@angular/core';
import { ImportEntityType } from '@common/models/Imports/Common/ImportEntityType';

@Component({
	selector: 'import-contacts',
	styleUrls: ['./import-contacts.component.scss'],
	templateUrl: './import-contacts.component.html'
})
export class ImportContactsComponent {
	importType = ImportEntityType.Contact;
}
