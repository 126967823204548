import { Component, Input, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ContactLookupDto } from '@common/models/Contacts/Lookup/ContactLookupDto';
import { ContactLookupEntityType } from '@common/models/Contacts/Lookup/ContactLookupEntityType';
import { ContactLookupType } from '@common/models/Contacts/Lookup/ContactLookupType';
import { UserViewDto } from '@common/models/Users/Item/UserViewDto';
import { ContactsService } from '@common/services/contacts.service';
import { UserCurrentService } from '@common/services/usercurrent.service';
import { ICurrentUserData } from '@common/state/models/current-user-data';
import { Store } from '@ngrx/store';

import { BaseFilterLookupComponent } from './base-filter-lookup.component';

@Component({
	selector: 'contact-filter',
	styleUrls: ['./contact-filter.component.scss'],
	templateUrl: './contact-filter.component.html'
})
export class ContactFilterComponent extends BaseFilterLookupComponent<ContactLookupDto> implements OnInit {
	@Input()
	ShowMe: boolean;
	@Input()
	ShowUnassigned: boolean;
	@Input()
	ShowStaffMembersOnly: boolean;
	@Input()
	hideClear: boolean;
	@Input()
	FreeTextFormControl: FormControl;
	@Input()
	entityType: ContactLookupEntityType = ContactLookupEntityType.Any;

	get showCurrentUser(): boolean {
		return (
			this.ShowMe &&
			(!this.SelectedValue || !this.currentContact || this.SelectedValue.id !== this.currentContact.id)
		);
	}

	currentContact: ContactLookupDto;

	constructor(
		private store: Store<{ currentUserData: ICurrentUserData }>,
		private contactsService: ContactsService,
		private userService: UserCurrentService
	) {
		super();
	}

	ngOnInit() {
		super.ngOnInit();

		// Get list of recently accessed contacts
		this.updateRecentlyAccessedContacts();
		// Get contact for the current user and covert type (from EntityReference to ContactLookupDto)
		this.subscription.add(
			this.store
				.select(state => state?.currentUserData?.currentUser)
				.pipe(filter(Boolean))
				.subscribe((u: UserViewDto) => {
					if (u.contact) {
						this.currentContact = {
							fullName: u.contact.name,
							id: u.contact.id,
							shortName: u.contact.name
						} as ContactLookupDto;
					}
				})
		);

		// trigger the event above to get the description if there is a value pre-populated
		if (!!this.FreeTextFormControl?.value) {
			this.Selected.emit({ id: 'free-text', name: this.FreeTextFormControl.value });
		}
	}

	clear() {
		this.FreeTextFormControl?.setValue(null);
		this.FormControl.setValue(null);
		this.inputCtrl.reset();

		this.Selected.emit(null);

		this.addAndUpdateRecentlyAccessedItems(null);
	}

	displayValue(input: ContactLookupDto): string {
		return input.shortName;
	}

	lookup(id: string): Observable<ContactLookupDto> {
		return this.contactsService.lookupContact(id, null);
	}

	search(term: string): Observable<ContactLookupDto[]> {
		return this.contactsService.lookupContactList({
			term,
			contactLookupType: this.ShowStaffMembersOnly ? ContactLookupType.Staff : ContactLookupType.All,
			contactLookupEntityType: this.entityType,
			matterId: null,
			excludeSelf: false,
			allowPlurals: true,
			ignoreIds: null
		});
	}

	itemSelected(item: ContactLookupDto): void {
		if (!item.id) {
			this.FormControl.setValue(null);
			this.FreeTextFormControl.setValue(item.fullName);

			this.Selected.emit({ id: 'free-text', name: item.fullName });
		} else {
			this.FreeTextFormControl?.setValue(null);
			super.itemSelected(item);
		}
	}

	// Add a new item by ID into the list of recently accessed items
	// and update the bound list of options
	protected addAndUpdateRecentlyAccessedItems(id: string): void {
		// Update list of recently accessed contacts
		if (id) {
			(this.ShowStaffMembersOnly
				? this.userService.addRecentlyAccessedStaffMember(id)
				: this.userService.addRecentlyAccessedClient(id)
			).subscribe(o => (this.options = this.filterSuggestedContacts(o)));
		} else {
			this.updateRecentlyAccessedContacts();
		}
	}

	// Get list of recently accessed contacts
	private updateRecentlyAccessedContacts(): void {
		(this.ShowStaffMembersOnly
			? this.userService.getRecentlyAccessedStaffMembers()
			: this.userService.getRecentlyAccessedClients()
		).subscribe(r => (this.options = this.filterSuggestedContacts(r)));
	}

	// Filter the suggestion list
	private filterSuggestedContacts(contacts: ContactLookupDto[]): ContactLookupDto[] {
		const excludedIds: string[] = [];
		// Remove the currently selected item
		if (this.SelectedValue) excludedIds.push(this.SelectedValue.id.toString());
		// Remove the current user if we show it separetely
		if (this.currentContact && this.ShowMe) excludedIds.push(this.currentContact.id.toString());

		return contacts.filter(c => excludedIds.indexOf(c.id.toString()) === -1);
	}
}
