import { EventEmitter, Injectable, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { of } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';

import { ITenantData } from '@common/state/models/tenant-data';
import { Store } from '@ngrx/store';

import { CoreModule } from '../core.module';
import { WelcomeDialogComponent } from './welcome-dialog.component';

declare var Appcues: any;

@Injectable({
	providedIn: CoreModule
})
export class WelcomeDialogCheckService {
	@Output() DataSeeded = new EventEmitter<void>();
	@Output() DialogOpening = new EventEmitter<void>();

	constructor(
		private store: Store<{ tenantData: ITenantData }>,
		private dialogService: MatDialog,
		private router: Router
	) {}

	DisplayWelcomeDialogOnFirstLogin(): Observable<any> {
		return this.store
			.select(state => state?.tenantData)
			.pipe(
				filter(x => !!x?.tenantInformation),
				take(1),
				switchMap((response: ITenantData) => {
					if (response.tenantInformation?.isSeeded) return of(response);

					this.DialogOpening.emit();
					const dialogRef = this.dialogService.open(WelcomeDialogComponent, {
						backdropClass: 'welcome-backdrop',
						panelClass: 'welcome-panel',
						width: '400px'
					});
					return dialogRef.afterClosed().pipe(
						switchMap(closed => {
							if (closed) {
								this.router.navigate(['/system/bill-settings'], {
									queryParams: { activeTab: 'xerosync', dialog: 'configure' }
								});
							}
							this.DataSeeded.emit();
							Appcues.page();
							return of(closed);
						})
					);
				})
			);
	}
}
