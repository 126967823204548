import { AfterViewInit, Directive, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { BehaviorSubject, of, Subscription } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';

import { AppcuesService } from '@common/appcues/appcues.service';
import { BaseSummaryComponent } from '@common/components/base-summary.component';
import { Variable } from '@common/models/Documents/Item/Variable';
import { DocumentTemplateListItemDto } from '@common/models/Documents/List/DocumentTemplateListItemDto';
import { TemplateEntityType } from '@common/models/Documents/TemplateDto/TemplateEntityType';
import { TemplateFieldDto } from '@common/models/Documents/TemplateDto/TemplateFieldDto';
import { InstallationRecordLicenceStatus } from '@common/models/Marketplace/InstallationRecords/Common/InstallationRecordLicenceStatus';
import { MatterViewDto } from '@common/models/Matters/Item/MatterViewDto';
import { MatterContactListItemDto } from '@common/models/Matters/List/MatterContactListItemDto';
import { NotificationService } from '@common/notification';
import { ContactCustomFieldsService } from '@common/services/customfields-contact.service';
import { CustomFieldsGroupService } from '@common/services/customfields-group.service';
import { MatterCustomFieldsService } from '@common/services/customfields-matter.service';
import { MattersService } from '@common/services/matters.service';
import { DocumentTemplatesService } from '@common/services/settings/documenttemplates.service';
import { MarketplaceService } from '@common/services/settings/marketplace.service';
import { Store } from '@ngrx/store';
import { flatten, get, isNil, replace, uniq } from 'lodash-es';

import { getCurrentPage } from 'app/core/state/misc/current-page/current-page.reducer';
import { ICurrentPageState } from 'app/core/state/misc/current-page/current-page.state';
import { DocumentTemplateLookupComponent } from 'app/shared/components/document-template-lookup.component';

import { ITemplateMergeData } from './ITemplateMergeData';

@Directive()
export abstract class TemplateMergeComponentBase
	extends BaseSummaryComponent
	implements OnInit, OnDestroy, AfterViewInit
{
	protected subscriptions = new Subscription();
	form: FormGroup;
	relatedMatterContacts = new BehaviorSubject<MatterContactListItemDto[]>([]);
	contactsOnMatter: MatterContactListItemDto[];
	showMatterContacts: boolean;
	addDocumentToMatter: boolean;
	selectedTemplate: DocumentTemplateListItemDto;
	selectedTemplateInstallationRecordLicenceStatus: keyof typeof InstallationRecordLicenceStatus;
	templateFieldsWithValues: TemplateFieldDto[];
	nonCustomFieldData: ITemplateMergeFieldData[] = [];
	model: MatterViewDto;
	@ViewChild('templateLookup', { static: false, read: DocumentTemplateLookupComponent })
	templateLookupComponent: DocumentTemplateLookupComponent;

	private syncDocumentName: boolean = true;

	public data: ITemplateMergeData;

	constructor(
		protected store: Store<ICurrentPageState>,
		protected appcuesService: AppcuesService,
		contactCustomFieldsService: ContactCustomFieldsService,
		customFieldsGroupService: CustomFieldsGroupService,
		protected documentTemplateService: DocumentTemplatesService,
		protected marketplaceService: MarketplaceService,
		matterCustomFieldsService: MatterCustomFieldsService,
		protected matterService: MattersService,
		protected notificationService: NotificationService,
		protected fb: FormBuilder
	) {
		super(matterCustomFieldsService, contactCustomFieldsService, customFieldsGroupService, fb);
	}

	get showDocumentTags(): boolean {
		return this.data.entityType === 'Matter' || this.data.entityType === 'Contact';
	}

	get templateIdControl(): FormControl {
		return this.form?.get('templateId') as FormControl;
	}

	get formValue() {
		let createDocumentDto = this.form.getRawValue();
		createDocumentDto.genericContactId = (this.form.get('genericContactId')?.value as string)
			?.split('-')
			?.slice(1)
			?.reduce((left, right) => `${left}-${right}`);

		return createDocumentDto;
	}

	ngOnInit() {
		this.createForm();

		switch (this.data.entityType) {
			case TemplateEntityType.Matter:
				this.form.patchValue({ matterId: this.data.entityId, templateEntityType: this.data.entityType });

				this.subscriptions.add(
					this.documentTemplateService
						.getMatterFieldValues(this.data.entityId)
						.subscribe(templateFields => (this.templateFieldsWithValues = templateFields))
				);
				break;
			case TemplateEntityType.Contact:
				this.form.patchValue({ contactId: this.data.entityId, templateEntityType: this.data.entityType });

				this.subscriptions.add(
					this.documentTemplateService
						.getContactFieldValues(this.data.entityId)
						.subscribe(templateFields => (this.templateFieldsWithValues = templateFields))
				);
				break;
			case TemplateEntityType.Deposit:
			case TemplateEntityType.Receipt:
				this.form.patchValue({
					entityId: this.data.entityId,
					templateEntityType: this.data.entityType,
					isPdf: true
				});
				break;
			case TemplateEntityType.Invoice:
				this.form.patchValue({
					entityId: this.data.entityId,
					templateEntityType: this.data.entityType,
					addDocumentToMatter: true
				});
			default:
				this.form.patchValue({ entityId: this.data.entityId, templateEntityType: this.data.entityType });
				break;
		}

		this.appcuesService.trackEvent('CreateDocumentFromTemplate');

		if (this.data.defaultTemplateGenericContactExists && this.data.entityType === 'Matter') {
			this.showMatterContacts = true;
			if (!this.contactsOnMatter) {
				this.subscriptions.add(this.getContactFields$().subscribe());
			}
		}

		this.subscriptions.add(
			this.customFieldsRefreshComplete
				.pipe(
					filter(Boolean),
					tap(() => {
						this.setupCustomFieldsFormControl();
					})
				)
				.subscribe()
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	ngAfterViewInit(): void {
		if (!!this.data?.defaultTemplateId) {
			this.templateSelected(this.data.defaultTemplateId);
		}
	}

	abstract createForm(): void;
	abstract setupCustomFieldsFormControl(): void;

	private fetchRelatedMatterTemplateContacts$(nonCustomFieldVariables: Variable[]) {
		const fieldPrefix = 'Matter__';
		const nonCustomFieldVariableNames = uniq(
			nonCustomFieldVariables.map(
				x => x.key.substring(x.key.indexOf(fieldPrefix) + fieldPrefix.length).split('__')[0]
			)
		).filter(x => ['Client', 'ReferredBy', 'Lawyer'].includes(x));

		return this.store.select(getCurrentPage).pipe(
			take(1),
			map(page => get(page, 'id') ?? (this.data?.entityType === 'Matter' ? this.data.entityId : null)),
			switchMap(id => (!isNil(id) ? this.matterService.getMatterContactList(id) : of(null))),
			tap(matterContacts => {
				this.relatedMatterContacts.next(
					matterContacts?.filter(x => nonCustomFieldVariableNames.includes(x.role.fieldId))
				);
			})
		);
	}

	stopSyncingDocumentName() {
		this.syncDocumentName = false;
	}

	onDocumentNameBlur() {
		const trimmed = this.form.controls.documentName.value?.trim();
		if (trimmed !== this.form.controls.documentName.value) {
			this.form.controls.documentName.setValue(trimmed);
		}
	}

	templateSelected(templateId: string) {
		this.relatedMatterContacts.next([]);
		this.selectedTemplateInstallationRecordLicenceStatus = null;
		this.nonCustomFieldData = [];
		this.customFieldsGroupedData = [];

		let selectedTemplate$: Observable<DocumentTemplateListItemDto>;
		if (!!templateId) {
			if (!!this.templateLookupComponent?.templates) {
				const results = flatten(Object.values(this.templateLookupComponent.templates)).filter(
					template => template.id === templateId
				);

				this.selectedTemplate = !!results?.length ? results[0] : null;
				selectedTemplate$ = of(this.selectedTemplate);
			} else {
				selectedTemplate$ = this.documentTemplateService
					.getDocumentTemplateList({ documentIds: [templateId], pageSize: 1 })
					.pipe(
						map(results => (!!results?.records?.length ? results.records[0] : null)),
						tap(template => (this.selectedTemplate = template))
					);
			}
		} else {
			this.selectedTemplate = null;
			selectedTemplate$ = of(null);
		}

		this.subscriptions.add(
			selectedTemplate$
				.pipe(
					switchMap(selectedTemplate => {
						if (!!this.syncDocumentName && !!selectedTemplate) {
							this.form.patchValue({
								documentName: this.data.entityNumber
									? `${this.selectedTemplate.title?.trim()} ${this.data.entityNumber}`
									: this.selectedTemplate.title?.trim()
							});
						}

						if (!!selectedTemplate && selectedTemplate.entityType === TemplateEntityType.Matter) {
							if (!!selectedTemplate.genericContactExists) {
								this.showMatterContacts = true;
							} else {
								this.showMatterContacts = false;
							}

							const nonCustomFieldVariables = this.selectedTemplateNonCustomFieldVariables();
							const customFieldVariables = this.selectedTemplateCustomFieldVariables();

							return this.fetchRelatedMatterTemplateContacts$(nonCustomFieldVariables).pipe(
								switchMap(() => {
									nonCustomFieldVariables.forEach(nonCustomFieldVariable => {
										const variableValue = this.templateFieldsWithValues?.filter(
											x => x.name === nonCustomFieldVariable.key
										);
										if (variableValue != null && variableValue.length === 1) {
											this.nonCustomFieldData.push({
												key: nonCustomFieldVariable.key,
												// Replace underscores with a space and insert space before capital letter
												name: replace(nonCustomFieldVariable.key, /__/g, ' ')
													.replace(/([A-Z])/g, ' $1')
													.trim(),
												value: variableValue[0].value
											});
										}
									});

									return !!customFieldVariables && customFieldVariables.length > 0
										? this.matterService.getMatter(this.data.entityId).pipe(
												tap(matter => (this.model = matter)),
												switchMap(matter =>
													this.getMatterCustomFields$(
														Object.keys(matter.customFields).filter(x => !!x),
														[],
														[matter.practiceArea.id],
														customFieldVariables
													)
												),
												catchError(e =>
													this.notificationService.showErrors('Error getting Matter', e)
												)
										  )
										: of(null);
								}),
								switchMap(() => (!this.contactsOnMatter ? this.getContactFields$() : of(null))),
								map(() => selectedTemplate)
							);
						} else {
							this.showMatterContacts = false;
							return of(selectedTemplate);
						}
					}),
					switchMap(selectedTemplate =>
						!!selectedTemplate?.installationRecordId
							? this.marketplaceService
									.validateInstallationRecordLicence(selectedTemplate.installationRecordId)
									.pipe(tap(x => (this.selectedTemplateInstallationRecordLicenceStatus = x)))
							: of(null)
					)
				)
				.subscribe(() => {
					this.form.updateValueAndValidity();
					Object.values(this.form.controls).forEach(control => control.updateValueAndValidity());
				})
		);
	}

	subscriptionRequiredMessageHtml(status: keyof typeof InstallationRecordLicenceStatus): string {
		var html = '';

		if (status == InstallationRecordLicenceStatus.Unlicensed) {
			html +=
				'An active subscription to <b>' + this.selectedTemplate.installationRecordTitle + '</b> is required,';
		} else if (status == InstallationRecordLicenceStatus.UnlicensedTrialExpired) {
			html += 'Your trial of <b>' + this.selectedTemplate.installationRecordTitle + '</b> has ended,';
		}

		if (html.length > 0) {
			return (html += ' please <a href="/system/subscription">subscribe</a> to continue using this template.');
		} else {
			return null;
		}
	}

	selectedTemplateCustomFieldVariables(): Variable[] {
		const customFieldVariables = this?.selectedTemplate?.variables.filter(x => x.key.includes('Matter__Field__'));

		return customFieldVariables;
	}

	selectedTemplateNonCustomFieldVariables(): Variable[] {
		const nonCustomFieldVariables = this?.selectedTemplate?.variables.filter(
			x => !x.key.includes('Matter__Field__')
		);

		return nonCustomFieldVariables;
	}

	private getContactFields$() {
		return this.store.select(getCurrentPage).pipe(
			map(page => get(page, 'id') ?? (this.data?.entityType === 'Matter' ? this.data.entityId : null)),
			filter(id => !isNil(id)),
			switchMap(id => this.matterService.getMatterContactList(id)),
			tap(contactRoles => (this.contactsOnMatter = contactRoles.filter(contact => !!contact.id)))
		);
	}
}

// tslint:disable-next-line: max-classes-per-file
export class ITemplateMergeFieldData {
	key: string;
	name: string;
	value: string;
}
