import { unitOfTime } from 'moment';
import * as moment from 'moment-timezone';

export interface IDateRangeButtonConfig {
	[key: string]: IDateRangeButtonOption;
}

export interface IDateRangeButtonOption {
	label: string;
	past?: boolean;
	yesterday?: boolean;
	today?: boolean;
	future?: boolean;
	unit: unitOfTime.DurationConstructor;
	start: number;
	end: number;
}

export const FilterDateRangeButtonConfig: IDateRangeButtonConfig = {
	LastCalendarMonth: {
		end: getLastCalendarEnd(),
		label: 'Last Month',
		past: true,
		start: getLastCalendarStart(),
		unit: 'day'
	},

	LastMonth: { label: 'Last 30 Days', past: true, unit: 'day', start: -30, end: 0 },
	LastWeek: { label: 'Last 7 Days', past: true, unit: 'day', start: -7, end: 0 },
	NextMonth: { label: 'Next 30 Days', future: true, unit: 'day', start: 0, end: 30 },
	NextWeek: { label: 'Next 7 Days', future: true, unit: 'day', start: 0, end: 7 },
	ThisMonth: {
		end: getThisCalendarEnd(),
		label: 'This Month',
		past: true,
		start: getThisCalendarStart(),
		unit: 'day'
	},
	Today: { label: 'Today', today: true, unit: 'day', start: 0, end: 0 },
	Tomorrow: { label: 'Tomorrow', future: true, unit: 'day', start: 1, end: 1 },
	Yesterday: { label: 'Yesterday', yesterday: true, past: true, unit: 'day', start: -1, end: -1 }
};

export function getLastCalendarStart() {
	return moment().subtract(1, 'months').startOf('month').diff(moment.now(), 'days');
}

export function getLastCalendarEnd() {
	return moment().subtract(1, 'months').endOf('month').diff(moment.now(), 'days') - 1;
}

export function getThisCalendarStart() {
	return moment().startOf('month').diff(moment.now(), 'days');
}

export function getThisCalendarEnd() {
	return moment().endOf('month').diff(moment.now(), 'days');
}
