<ng-container *ngIf="!transactionType">
	<mat-radio-group *ngIf="hasTrustAccounts" [(ngModel)]="transactionType" class="flex-column options">
		<mat-radio-button [value]="receiptKey">Receipt</mat-radio-button>
		<mat-radio-button [value]="paymentKey">Payment</mat-radio-button>
		<mat-radio-button throttleButton (throttledClick)="closeDialog()" [routerLink]="'trust-listing/invoice-payment/new'">Invoice Payment</mat-radio-button>
		<mat-radio-button [value]="journalKey">Journal</mat-radio-button>
		<mat-radio-button throttleButton (throttledClick)="closeDialog()" [routerLink]="'trust-listing/deposit/new'" mat-dialog-close
			>Deposit Listing</mat-radio-button
		>
	</mat-radio-group>
	<p *ngIf="!hasTrustAccounts && hasAccessToSettings">
		A trust account must be configured in order to create trust records. Create a new trust account in your
		<a throttleButton (throttledClick)="closeDialog()" [routerLink]="'system/trust-settings'">system settings</a>.
	</p>
	<p *ngIf="!hasTrustAccounts && !hasAccessToSettings">
		A trust account must be configured in order to create trust records. Speak to your {{ siteName }} administrator about creating a trust account in system
		settings.
	</p>
</ng-container>

<trust-receipt-payment
	*ngIf="transactionType === receiptKey"
	[isReadOnly]="false"
	[transactionType]="receiptKey"
	(formStatusChanged)="onFormStatusChanged($event)"
	(titleChanged)="onTitleChanged($event)"
>
</trust-receipt-payment>
<trust-receipt-payment
	*ngIf="transactionType === paymentKey"
	[isReadOnly]="false"
	[transactionType]="paymentKey"
	(formStatusChanged)="onFormStatusChanged($event)"
	(titleChanged)="onTitleChanged($event)"
>
</trust-receipt-payment>
<create-trust-journal
	*ngIf="transactionType === journalKey"
	[isReadOnly]="false"
	[transactionType]="journalKey"
	(formStatusChanged)="onFormStatusChanged($event)"
	(titleChanged)="onTitleChanged($event)"
>
</create-trust-journal>
