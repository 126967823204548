import { CostCodeListItemDto } from '@common/models/Settings/CostCodes/List/CostCodeListItemDto';
import { createSelector, MemoizedSelector } from '@ngrx/store';

import { IAppState } from '../../../app.state';

export const selectCostCodeList = (state: IAppState) => state.costCodeList;

export const selectCostCodeListRecords: MemoizedSelector<IAppState, CostCodeListItemDto[]> = createSelector(
	selectCostCodeList,
	state => state?.list?.records || []
);
export const selectCostCodeListRequest: MemoizedSelector<IAppState, Partial<any>> = createSelector(
	selectCostCodeList,
	state => state?.request
);
export const selectCostCodeListPageIndex = createSelector(
	selectCostCodeList,
	state => state?.list?.pageIndex || 0
);
export const selectCostCodeListTotalRecords = createSelector(
	selectCostCodeList,
	state => state?.list?.totalRecords || 0
);
export const selectCostCodeIsFetching = createSelector(selectCostCodeList, state => !!state?.isFetching);
