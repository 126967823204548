// This file has been generated from JobsController.cs
// Do not modify this file directly!!
// tslint:disable

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { TrimStringsOnObject } from '@common/utils/string-format';

import { ListResponse } from '../../models/Generic/ListResponse';
import { JobViewDto } from '../../models/Settings/Jobs/Item/JobViewDto';
import { JobListItemDto } from '../../models/Settings/Jobs/List/JobListItemDto';
import { JobListRequest } from '../../models/Settings/Jobs/List/JobListRequest';

@Injectable({
	providedIn: 'root'
})
export class JobsService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'api/v1/settings/Jobs', 'Job');
	}

	getJob(id: string): Observable<JobViewDto> {
		return this.getItem<void, JobViewDto>(`${id}`);
	}

	getJobList(jobListRequest?: Partial<JobListRequest>): Observable<ListResponse<JobListItemDto>> {
		return this.getList<JobListRequest, JobListItemDto>('', jobListRequest);
	}

	getStatusList(): Observable<string[]> {
		return this.getArray<void, string>(`GetStatusList`);
	}

	cancel(jobId: string): Observable<any> {
		return this.getItem<void, any>(`${jobId}/Cancel`);
	}

	deleteJob(id: string): Observable<any> {
		return this.delete(`${id}`);
	}

}
