// This file has been generated from ChangeMatterStageDto.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.Domain.Matters.Item.ChangeMatterStageDto
export class ChangeMatterStageDto {

	// StageId
	stageId: number;

	constructor() {

	}
}