// This file has been generated from OutlookUpdateCurrentUserDto.cs
// Do not modify this file directly!!
// tslint:disable

import { SetSimpleType } from '@common/utils/class-transform';

import { TaskPriority } from '../../Tasks/Common/TaskPriority';

// LawMaster.Amalthea.Domain.Users.Item.OutlookUpdateCurrentUserDto
export class OutlookUpdateCurrentUserDto {

	// AutosaveEmails
	@SetSimpleType(Boolean)
	autosaveEmails: boolean;
	// AutosaveAttachmentsSeperately
	@SetSimpleType(Boolean)
	autosaveAttachmentsSeperately: boolean;
	// AutosavePrompt
	@SetSimpleType(Boolean)
	autosavePrompt: boolean;
	// AutosaveInclusionRules
	autosaveInclusionRules: string[];
	// AutosaveExclusionRules
	autosaveExclusionRules: string[];
	// ReferralsDueDateDays
	referralsDueDateDays: number;
	// ReferralsReason
	referralsReason: string;
	// ReferralsRemarks
	referralsRemarks: string;
	// ReferralsPriority
	referralsPriority: keyof typeof TaskPriority;

	constructor() {

	}
}