<mat-card>
	<div class="description">Define the format and starting number of your invoices. This number will increment by 1 with each invoice you create.<br /></div>

	<hr />

	<div class="flex-column" [formGroup]="form">
		<div class="container">
			<span class="title">Prefix</span>
			<mat-form-field class="input-field">
				<input matInput formControlName="invoiceNumberPrefix" autocomplete="off" />
			</mat-form-field>
		</div>
		<div class="container">
			<span class="title">Number of Digits</span>
			<mat-form-field>
				<input matInput type="number" formControlName="numberOfDigits" autocomplete="off" />
				<mat-error>
					<div *ngIf="form.get('numberOfDigits').hasError('max'); else standardError">Number of digits cannot be more than 20</div>
					<ng-template #standardError>
						<error-messages [for]="form.get('numberOfDigits')"></error-messages>
					</ng-template>
				</mat-error>
			</mat-form-field>
		</div>
		<div class="container description">
			<span class="sample">Sample of next invoice number</span>
			<span class="sample">{{ getFormattedInvoiceNumber() }}</span>
		</div>
	</div>

	<div class="action-pane">
		<button mat-raised-button color="primary" [disabled]="form.invalid || form.disabled || form.pristine" throttleButton (throttledClick)="save()">
			Save
		</button>
		<button mat-raised-button color="secondary" class="set-number-button" throttleButton (throttledClick)="setNextBillNumber()">Set Starting Number</button>
	</div>
</mat-card>
