const complexTypeMetadataKey = Symbol('ComplexType');
const simpleTypeMetadataKey = Symbol('SimpleType');

// Type decorator
export function SetComplexType<T>(value: { new (): T }): PropertyDecorator {
	return (target, property) => {
		Reflect.defineMetadata(complexTypeMetadataKey, value, target, property);
	};
}

export function GetComplexType(target: any, propertyKey: string): { new (): any } {
	return Reflect.getMetadata(complexTypeMetadataKey, target, propertyKey);
}

// Type decorator
export function SetSimpleType<T>(value: { new (): T }): PropertyDecorator {
	return (target, property) => {
		Reflect.defineMetadata(simpleTypeMetadataKey, value, target, property);
	};
}

export function GetSimpleType(target: any, propertyKey: string): { new (): any } {
	return Reflect.getMetadata(simpleTypeMetadataKey, target, propertyKey);
}
