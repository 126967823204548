import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

import { filter, Subscription } from 'rxjs';

import { DocumentTemplateListItemDto } from '@common/models/Documents/List/DocumentTemplateListItemDto';
import { BasicWorkflowViewDto } from '@common/models/Settings/BasicWorkflows/Item/BasicWorkflowViewDto';
import { WorkflowDocumentTemplate } from '@common/models/Settings/BasicWorkflows/Item/WorkflowDocumentTemplate';
import { NotificationService } from '@common/notification';
import { BasicWorkflowService } from '@common/services/settings/basicworkflow.service';
import { DocumentTemplatesService } from '@common/services/settings/documenttemplates.service';
import { isNil } from 'lodash-es';

import {
	WorkflowDocumentTemplateAddItemComponent,
	WorkflowDocumentTemplateAddItemResult
} from '../item/workflow-document-template-add-item.component';

@Component({
	selector: 'workflow-document-template-list',
	templateUrl: 'workflow-document-template-list.component.html',
	styleUrls: ['workflow-document-template-list.component.scss']
})
export class WorkflowDocumentTemplateListComponent implements OnInit, OnDestroy {
	@Input() workflow: BasicWorkflowViewDto;
	subscriptions = new Subscription();
	displayedColumns: string[] = ['title', 'templateTitle', 'actions'];

	dataSource: MatTableDataSource<WorkflowDocumentTemplateListItemDto>;

	documentTemplates: DocumentTemplateListItemDto[] = [];

	constructor(
		private _notificationService: NotificationService,
		private _dialog: MatDialog,
		private _workflowService: BasicWorkflowService,
		private _documentTemplateService: DocumentTemplatesService
	) {}
	ngOnInit(): void {
		this.subscriptions.add(
			this._documentTemplateService.getDocumentTemplateList({}).subscribe(documentTemplates => {
				this.documentTemplates = documentTemplates.records;
				this.refreshList();
			})
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	add(): void {
		this.subscriptions.add(
			this._dialog
				.open(WorkflowDocumentTemplateAddItemComponent, {
					data: { workflowId: this.workflow.id, practiceAreas: this.getAssociatedPracticeAreaIds() }
				})
				.afterClosed()
				.pipe(filter(Boolean))
				.subscribe((result: WorkflowDocumentTemplateAddItemResult) => {
					if (!!result) {
						if (isNil(this.workflow.documentTemplates)) {
							this.workflow.documentTemplates = [result];
						} else {
							this.workflow.documentTemplates.push(result);
						}
						this.refreshList();
						this.save();
					}
				})
		);
	}

	edit(row: WorkflowDocumentTemplateListItemDto): void {
		this.subscriptions.add(
			this._dialog
				.open(WorkflowDocumentTemplateAddItemComponent, {
					data: { workflowId: this.workflow.id, practiceAreas: this.getAssociatedPracticeAreaIds(), dto: row }
				})
				.afterClosed()
				.pipe(filter(Boolean))
				.subscribe((result: WorkflowDocumentTemplateAddItemResult) => {
					if (!!result) {
						this.dataSource.data = this.dataSource.data.map(x => {
							if (x.rowId == row.rowId) {
								x.title = result.title;
								x.documentTemplateId = result.documentTemplateId;
								x.matterContactRoleName = result.matterContactRoleName;
							}
							return x;
						});

						this.workflow.documentTemplates = this.dataSource.data;
						this.save();
					}
				})
		);
	}

	remove(row: WorkflowDocumentTemplateListItemDto): void {
		this.subscriptions.add(
			this._notificationService
				.showConfirmation(
					'Remove Document Template',
					`Are you sure you want to remove this Document Template "${row.title}" from the matter workflow?`
				)
				.pipe(filter(Boolean))
				.subscribe({
					next: () => {
						this.dataSource.data = this.dataSource.data.filter(x => x.rowId != row.rowId);
						this.workflow.documentTemplates = this.dataSource.data;
						this.save();
						this._notificationService.showNotification(`Document Template removed from matter workflow`);
					},
					error: error => this._notificationService.showErrors('Error removing Document Template', error)
				})
		);
	}

	save() {
		this.subscriptions.add(
			this._workflowService
				.saveDocumentTemplates(this.workflow.id, { documentTemplates: this.workflow.documentTemplates })
				.subscribe()
		);
	}

	refreshList() {
		if (this.workflow?.documentTemplates?.length) {
			this.dataSource = new MatTableDataSource<WorkflowDocumentTemplateListItemDto>(
				this.workflow.documentTemplates.map((x, i) => {
					return {
						...x,
						...{
							rowId: i,
							templateTitle: this.documentTemplates.find(template => template.id == x.documentTemplateId)
								?.title
						}
					};
				})
			);
		}
	}

	private getAssociatedPracticeAreaIds(): string[] {
		return this.workflow.practiceAreas?.map(x => x.id)?.filter(value => !!value);
	}
}

export interface WorkflowDocumentTemplateListItemDto extends WorkflowDocumentTemplate {
	rowId: number;
	templateTitle?: string;
}
