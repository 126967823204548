import { ListResponse } from '@common/models/Generic/ListResponse';
import { DomainError } from '@common/models/Validation/DomainError';

import { BankAccountListItemDto } from '@common/models/Settings/TrustSettings/BankAccounts/List/BankAccountListItemDto';

export interface IBankAccountListState {
	isFetching: boolean;
	list: ListResponse<BankAccountListItemDto>;
	lastSelectedRecordId: string;

	// errrors
	error: DomainError;
}

export const initialState: IBankAccountListState = {
	isFetching: false,
	list: null,
	lastSelectedRecordId: null,

	error: null
};
