<h2 mat-dialog-title class="title">Create Blank Document</h2>
<mat-dialog-content [formGroup]="form" class="flex-column">
	<div class="flex-row select-input-field-combo">
		<mat-form-field class="select">
			<mat-select formControlName="type" [compareWith]="compareTypes">
				<mat-select-trigger>
					<mat-icon
						*ngIf="!!selectedDocumentType"
						class="selected-icon"
						[ngClass]="selectedDocumentType.icon"
						[svgIcon]="selectedDocumentType.icon"
					></mat-icon>
					<span *ngIf="!!selectedDocumentType" class="selected-text">{{ selectedDocumentType.display }}</span>
				</mat-select-trigger>
				<mat-option *ngFor="let type of documentTypes" [value]="type.type">
					<mat-icon class="option-icon" [ngClass]="type.icon" [svgIcon]="type.icon"></mat-icon>
					<span>{{ type.display }}</span>
				</mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field class="input-field">
			<mat-placeholder
				>Document Title
				<sup class="color-warn">*</sup>
			</mat-placeholder>
			<input matInput formControlName="documentName" autocomplete="off" />
			<mat-error>
				<error-messages [for]="form.get('documentName')"></error-messages>
			</mat-error>
		</mat-form-field>
	</div>

	<div class="flex-row select-input-field-combo" *ngIf="showEntitySelection">
		<mat-form-field class="select">
			<mat-select [(value)]="associatedEntityType" placeholder="Related To" (selectionChange)="associatedEntityTypeChange()">
				<mat-option *ngFor="let type of associatedEntityTypes" [value]="type">{{ type }}</mat-option>
			</mat-select>
		</mat-form-field>
		<matter-lookup
			class="input-field"
			*ngIf="associatedEntityType == 'Matter'"
			[FormControl]="associatedMatterIdControl"
			Hint="Enter a matter number or title"
			Status="Open"
			[Required]="true"
		></matter-lookup>
		<contact-lookup
			class="input-field"
			*ngIf="associatedEntityType == 'Contact'"
			[FormControl]="associatedContactIdControl"
			(Selected)="onContactSelected($event)"
			Hint="Enter a contact name"
			[Required]="true"
		></contact-lookup>
		<!-- <mat-form-field class="small-form-field">
			<mat-placeholder>Date <sup class="color-warn">*</sup> </mat-placeholder>
			<input formControlName="date" matInput [matDatepicker]="datePicker" />
			<mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
			<mat-datepicker #datePicker></mat-datepicker>
			<mat-error> <error-messages [for]="form.get('date')"></error-messages> </mat-error>
		</mat-form-field> -->
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-raised-button color="primary" throttleButton (throttledClick)="createButtonClick()" [disabled]="form.invalid">Create</button>
	<button mat-raised-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
