// This file has been generated from WorkflowDocumentTemplatesSaveDto.cs
// Do not modify this file directly!!
// tslint:disable

import { WorkflowDocumentTemplate } from './WorkflowDocumentTemplate';

// LawMaster.Amalthea.Domain.Settings.BasicWorkflows.Item.WorkflowDocumentTemplatesSaveDto
export class WorkflowDocumentTemplatesSaveDto {

	// DocumentTemplates
	documentTemplates: WorkflowDocumentTemplate[];

	constructor() {
		this.documentTemplates = [];
	}
}