<mat-card>
	<section class="page-path"><a [routerLink]="['..']">System</a> / Matter Workflows</section>

	<div class="heading-row">
		<h1>Matter Workflows</h1>
		<button mat-button color="primary" title="Create New" throttleButton (throttledClick)="create()">
			<mat-icon class="create-new">add_circle</mat-icon>
			<span>Create Matter Workflow</span>
		</button>
	</div>

	<mat-table [dataSource]="(isFetching$ | async) ? [] : (data$ | async)" matSort matSortActive="name" matSortDirection="asc">
		<ng-container matColumnDef="name">
			<mat-header-cell *matHeaderCellDef mat-sort-header>Workflow Name</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<a class="name" throttleButton (throttledClick)="open(row?.id)">{{ row.name }}</a>
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="practiceAreaNames">
			<mat-header-cell *matHeaderCellDef>Practice Areas</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<span> {{ row.practiceAreaNames }}</span>
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="type">
			<mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<span>{{ row.workflowType | basicWorkflowType }}</span>
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="numTasks">
			<mat-header-cell *matHeaderCellDef>No. of Tasks</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<span>{{ row?.associatedTaskTemplateIds?.length ?? 0 }} </span>
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="action">
			<mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<button mat-icon-button [matMenuTriggerFor]="menu" title="Actions">
					<mat-icon>more_vert</mat-icon>
				</button>
				<mat-menu #menu="matMenu">
					<button mat-menu-item throttleButton (throttledClick)="open(row?.id)">
						<mat-icon>visibility</mat-icon>
						<span>Open</span>
					</button>
					<button mat-menu-item throttleButton (throttledClick)="delete(row)">
						<mat-icon>delete</mat-icon>
						<span>Delete</span>
					</button>
				</mat-menu>
			</mat-cell>
		</ng-container>

		<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
		<mat-row
			*matRowDef="let row; columns: displayedColumns"
			(dblclick)="open(row?.id)"
			throttleButton
			(throttledClick)="selectRow(row, $event)"
			[ngClass]="{ highlight: row.isHighlighted }"
		></mat-row>
	</mat-table>
	<mat-paginator
	[length]="totalRecords$ | async"
	[pageIndex]="pageIndex$ | async"
	[pageSize]="pageSize$ | async"
	[pageSizeOptions]="pageSizeOptions"
	[class.hidden]="(isFetching$ | async) || !(data$ | async)"
	></mat-paginator>
</mat-card>
