// This file has been generated from TaskPatchDto.cs
// Do not modify this file directly!!
// tslint:disable

import { Moment } from 'moment-timezone';
import { DateFormat, SetDateFormat } from '@common/utils/date-format';

import { SetSimpleType } from '@common/utils/class-transform';

import { CustomFieldGroupSelection } from '../Common/CustomFieldGroupSelection';
import { TaskPriority } from '../Common/TaskPriority';
import { TaskStatus } from '../Common/TaskStatus';

// LawMaster.Amalthea.Domain.Tasks.Item.TaskPatchDto
export class TaskPatchDto {

	// Description
	description: string;
	// Status
	status: keyof typeof TaskStatus;
	// Priority
	priority: keyof typeof TaskPriority;
	// DueDate
	@SetDateFormat(DateFormat.LocalDate)
	dueDate: Moment;
	// Reason
	reason: string;
	// Remarks
	remarks: string;
	// AssignedToId
	assignedToId: string;
	// AllCollectionFieldsMandatory
	@SetSimpleType(Boolean)
	allCollectionFieldsMandatory: boolean;
	// SelectedCustomFieldGroups
	selectedCustomFieldGroups: CustomFieldGroupSelection[];

	constructor() {
		this.selectedCustomFieldGroups = [];
	}
}