import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { EntityReference } from '@common/models/Common/EntityReference';
import { CustomFieldType } from '@common/models/Settings/CustomFields/Common/CustomFieldType';
import { MatterCustomFieldListRequest } from '@common/models/Settings/CustomFields/List/MatterCustomFieldListRequest';
import { PracticeAreaListItemDto } from '@common/models/Settings/PracticeAreas/List/PracticeAreaListItemDto';
import { orderBy } from 'lodash';

import { FeatureFlags } from 'app/app.config';
import { FilterRootComponent } from 'app/shared/filter-controls';
import { FilterChangeProperties } from 'app/shared/filter-controls/filter-change-properties';

@Component({
	selector: 'matter-custom-field-list-filter',
	styleUrls: ['./matter-custom-field-list-filter.component.scss'],
	templateUrl: './matter-custom-field-list-filter.component.html'
})
export class MatterCustomFieldListFilterComponent {
	featureFlags: typeof FeatureFlags = FeatureFlags;

	@ViewChild(FilterRootComponent, { static: true })
	root: FilterRootComponent<MatterCustomFieldListRequest>;

	@Input()
	request: Partial<MatterCustomFieldListRequest>;
	@Output()
	filterChange = new EventEmitter<Partial<MatterCustomFieldListRequest>>();

	listRequestType: new () => MatterCustomFieldListRequest = MatterCustomFieldListRequest;
	practiceAreas: PracticeAreaListItemDto[];

	fieldTypes = orderBy<EntityReference>(
		(Object.keys(CustomFieldType) as (keyof typeof CustomFieldType)[]).map(key => {
			return { id: key, name: CustomFieldType[key] };
		}),
		order => order.name
	);

	rootFilterChange($event: FilterChangeProperties<Partial<MatterCustomFieldListRequest>>) {
		if (!!$event) this.filterChange.emit($event.filter);
	}
}
