<div class="filter-row">
	<ng-content></ng-content>
	<button mat-button color="primary" throttleButton (throttledClick)="createTemplate()">
		<mat-icon>add_circle</mat-icon>
		<span>Create Template</span>
	</button>
</div>

<mat-table [dataSource]="(isFetching$ | async) ? [] : (data$ | async)" matSort matSortActive="title" matSortDirection="asc">
	<ng-container matColumnDef="title">
		<mat-header-cell *matHeaderCellDef mat-sort-header>Title</mat-header-cell>
		<mat-cell *matCellDef="let row">
			<mat-icon
				throttleButton
				(throttledClick)="openPreview(row.id)"
				class="add-margin document-preview"
				[svgIcon]="row.icon"
				[ngClass]="row.class"
			></mat-icon>
			<div class="document-title-block">
				<div class="embedded-package" *ngIf="!!row.installationRecordTitle">
					<mat-icon color="accent">storefront</mat-icon>
					<span>{{ row.installationRecordTitle }}</span>
				</div>

				<div class="document-uploaded-block">
					<span class="narrow-column">{{ row.entityType }}</span>
					<span class="narrow-column" title="Created Date: {{ row.createdDate | moment : 'dd/MM/yyyy' }}"
						>{{ row.createdDate | moment : 'dd/MM/yyyy' }}
					</span>
					<span title="User: {{ row.uploadedBy?.name }}">
						<a [routerLink]="['/contacts/' + row.uploadedBy?.id]">{{ row.uploadedBy?.name }}</a>
					</span>
				</div>
				<div class="document-title">
					<a class="document-preview" throttleButton (throttledClick)="openPreview(row.id)">{{ row.title }}</a>
					<span class="document-version" *ngIf="!!row.disabled && !!row.installationRecordVersion">{{
						installationRecordVersion.packageVersion
					}}</span>
				</div>

				<div class="embedded-row">
					<div class="embedded-secondary-title" *ngIf="showRelatedTo">
						<span *ngIf="!row.associatedPracticeAreas?.length">All</span>
						<ul>
							<li *ngFor="let pa of row.associatedPracticeAreas">{{ pa.name }}</li>
						</ul>
					</div>
					<div class="embedded-secondary-title">{{ row.documentCategory?.name }}</div>
				</div>
			</div>
		</mat-cell>
	</ng-container>

	<ng-container matColumnDef="practiceArea">
		<mat-header-cell *matHeaderCellDef mat-sort-header>Practice Area</mat-header-cell>
		<mat-cell *matCellDef="let row">
			<div *ngIf="row.entityType == 'Matter'">
				<span *ngIf="!row.associatedPracticeAreas?.length">All</span>
				<ul>
					<li *ngFor="let pa of row.associatedPracticeAreas">{{ pa.name }}</li>
				</ul>
			</div>
		</mat-cell>
	</ng-container>

	<ng-container matColumnDef="createdDate">
		<mat-header-cell *matHeaderCellDef mat-sort-header>Created Date</mat-header-cell>
		<mat-cell *matCellDef="let row">{{ row.createdDate | moment : 'dd/MM/yyyy' }}</mat-cell>
	</ng-container>

	<ng-container matColumnDef="entityType">
		<mat-header-cell *matHeaderCellDef mat-sort-header>Related To</mat-header-cell>
		<mat-cell *matCellDef="let row">{{ row.entityType | templateEntityType }}</mat-cell>
	</ng-container>

	<ng-container matColumnDef="documentCategory">
		<mat-header-cell *matHeaderCellDef mat-sort-header>Group</mat-header-cell>
		<mat-cell *matCellDef="let row">{{ row.documentCategory?.name }}</mat-cell>
	</ng-container>

	<ng-container matColumnDef="documentTags">
		<mat-header-cell *matHeaderCellDef mat-sort-header>Tags</mat-header-cell>
		<mat-cell *matCellDef="let row">
			<mat-chip *ngFor="let tag of row.documentTags">
				{{ tag }}
			</mat-chip>
		</mat-cell>
	</ng-container>

	<ng-container matColumnDef="lastModifiedBy">
		<mat-header-cell *matHeaderCellDef mat-sort-header>User</mat-header-cell>
		<mat-cell *matCellDef="let row">
			<a [routerLink]="['/contacts/' + row.lastModifiedBy?.id]">{{ row.lastModifiedBy?.name }}</a>
		</mat-cell>
	</ng-container>

	<ng-container matColumnDef="installationRecordTitle">
		<mat-header-cell *matHeaderCellDef>Package</mat-header-cell>
		<mat-cell *matCellDef="let row">
			<div class="package-container" *ngIf="!!row.installationRecordTitle">
				<mat-icon color="accent">storefront</mat-icon>
				<span>{{ row.installationRecordTitle }}</span>
			</div>
		</mat-cell>
	</ng-container>

	<ng-container matColumnDef="action">
		<mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
		<mat-cell *matCellDef="let row">
			<button mat-icon-button [matMenuTriggerFor]="menu" title="Actions">
				<mat-icon>more_vert</mat-icon>
			</button>
			<mat-menu #menu="matMenu">
				<button mat-menu-item throttleButton (throttledClick)="openPreview(row.id)">
					<mat-icon>zoom_in</mat-icon>
					<span>Preview</span>
				</button>
				<button mat-menu-item throttleButton (throttledClick)="updateTemplate(row)" *ngIf="!row.disabled">
					<mat-icon>edit_note</mat-icon>
					<span>Modify Details</span>
				</button>
				<button
					class="hiddenPhone"
					mat-menu-item
					throttleButton
					(throttledClick)="edit(row)"
					*ngIf="!row.disabled && canOpenInNativeApp(row.fileExtension)"
				>
					<mat-icon *ngIf="!isLocked(row)" svgIcon="file-edit"></mat-icon>
					<mat-icon *ngIf="isLocked(row)" class="locked" svgIcon="file-lock" title="File is locked"></mat-icon>
					<span>{{ openInNativeAppName(row) }}</span>
				</button>

				<button
					class="hiddenPhone"
					mat-menu-item
					throttleButton
					(throttledClick)="copyUrl(row)"
					*ngIf="!row.disabled && canCopyUrl(row.fileExtension)"
					title="To Edit this PDF, Copy and Paste this link in Acrobat Reader under File > Open"
				>
					<mat-icon *ngIf="!isLocked(row)" svgIcon="file-document"></mat-icon>
					<mat-icon *ngIf="isLocked(row)" class="locked" svgIcon="file-lock" title="File is locked"></mat-icon>
					<span>Copy URL for Editing</span>
				</button>
				<button mat-menu-item throttleButton (throttledClick)="downloadSingleFile(row)" title="Download ({{ row.size | bytes }})">
					<mat-icon svgIcon="cloud-download"></mat-icon>
					<span>Download ({{ row.size | bytes }})</span>
				</button>
				<button mat-menu-item throttleButton (throttledClick)="deleteDocument(row)">
					<mat-icon svgIcon="delete"></mat-icon>
					<span>Delete</span>
				</button>
			</mat-menu>
		</mat-cell>
	</ng-container>

	<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
	<mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="{ selected: selectedDocumentId == row.id, disabled: row.disabled }"></mat-row>
</mat-table>
<mat-paginator
	[length]="totalRecords$ | async"
	[pageIndex]="pageIndex$ | async"
	[pageSize]="pageSize$ | async"
	[pageSizeOptions]="pageSizeOptions"
	[class.hidden]="(isFetching$ | async) || !(data$ | async)"
></mat-paginator>
