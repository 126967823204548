import { ListResponse } from '@common/models/Generic/ListResponse';
import { CostCodeListItemDto } from '@common/models/Settings/CostCodes/List/CostCodeListItemDto';
import { CostCodeListRequest } from '@common/models/Settings/CostCodes/List/CostCodeListRequest';
import { DomainError } from '@common/models/Validation/DomainError';
import { createAction, props } from '@ngrx/store';

export enum CostCodeFilterActions {
	InvalidateCache = '[Cost Code Filter] Invalidate Cache',
	Load = '[Cost Code Filter] Load',
	Loading = '[Cost Code Filter] Loading',
	LoadSuccess = '[Cost Code Filter] Load Success',
	LoadFailed = '[Cost Code Filter] Load Failed',

	SetFilters = '[Cost Code Filter] Set Filters'
}

export const load = createAction(CostCodeFilterActions.Load);
export const loading = createAction(CostCodeFilterActions.Loading);
export const loadSuccess = createAction(
	CostCodeFilterActions.LoadSuccess,
	props<{ response: ListResponse<CostCodeListItemDto> }>()
);
export const loadFailed = createAction(CostCodeFilterActions.LoadFailed, props<{ error: DomainError }>());

export const setFilters = createAction(
	CostCodeFilterActions.SetFilters,
	props<{ filter: Partial<CostCodeListRequest> }>()
);

export const invalidateCache = createAction(CostCodeFilterActions.InvalidateCache);
