// This file has been generated from OmnisearchController.cs
// Do not modify this file directly!!
// tslint:disable

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { TrimStringsOnObject } from '@common/utils/string-format';

import { OmnisearchResponse } from '../models/Omnisearch/OmnisearchResponse';
import { OmnisearchRequest } from '../models/RequestParameters/OmnisearchRequest';

@Injectable({
	providedIn: 'root'
})
export class OmnisearchService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'api/v1/Omnisearch');
	}

	search(dto: OmnisearchRequest): Observable<OmnisearchResponse> {
		return this.getItem<OmnisearchRequest, OmnisearchResponse>('', dto);
	}

}
