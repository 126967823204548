import { Injectable } from '@angular/core';

import { RolePermission } from '@common/models/Common/RolePermission';
import { SecurityPolicies } from '@common/models/Settings/SecurityRoles/Common/SecurityPolicies';

import { AuthService } from './auth.service';

@Injectable()
export class SecurityPermissionService {
	constructor(private authService: AuthService) {}

	get hasAccessToSettings(): boolean {
		const systemSettingsPolicy: keyof typeof SecurityPolicies = 'SystemSettings';
		return this.hasAuthClaim(systemSettingsPolicy);
	}

	get hasAccessToReports(): boolean {
		const reportsPolicy: keyof typeof SecurityPolicies = 'Reports';
		return this.hasAuthClaim(reportsPolicy);
	}

	get hasAccessToTrust(): boolean {
		const trustPolicy: keyof typeof SecurityPolicies = 'Trust';
		return this.hasAuthClaim(trustPolicy);
	}

	get hasAccessToDeleteMatters(): boolean {
		const deleteMattersPolicy: keyof typeof SecurityPolicies = 'DeleteMatters';
		return this.hasAuthClaim(deleteMattersPolicy);
	}

	get hasAccessToManageMatterSecurity(): boolean {
		const matterSecurityPolicy: keyof typeof SecurityPolicies = 'MatterSecurity';
		return this.hasAuthClaim(matterSecurityPolicy);
	}

	hasRolePermission(role: keyof typeof RolePermission): boolean {
		var index = Object.keys(RolePermission).indexOf(role);
		var value = index == 0 ? 0 : 1 << (index - 1);

		return this.getPermissionClaims().filter(permission => (permission & value) === value).length > 0;
	}

	hasAnyRolePermissions(): boolean {
		return this.getPermissionClaims().filter(permission => permission > 0).length > 0;
	}

	private getPermissionClaims(): number[] {
		const claims = this.authService.getClaim('LM_Permission');

		return claims === null
			? []
			: Array.isArray(claims)
			? (claims as string[]).map(permission => parseInt(permission))
			: [parseInt(claims as string)];
	}

	private hasAuthClaim(claimValue: keyof typeof SecurityPolicies): boolean {
		const authClaims: string[] = this.authService.getClaim('LM_Authorization') as string[];
		if (authClaims?.length) {
			return authClaims.includes(claimValue);
		}
		return false;
	}
}
