// This file has been generated from DocumentAccessTokenCreateUpdateDto.cs
// Do not modify this file directly!!
// tslint:disable

import { Moment } from 'moment-timezone';
import { DateFormat, SetDateFormat } from '@common/utils/date-format';



// LawMaster.Amalthea.Domain.Settings.AccessTokens.Item.DocumentAccessTokenCreateUpdateDto
export class DocumentAccessTokenCreateUpdateDto {

	// Name
	name: string;
	// ContactId
	contactId: string;
	// Email
	email: string;
	// Expiry
	@SetDateFormat(DateFormat.LocalDate)
	expiry: Moment;
	// AssociatedDocumentId
	associatedDocumentId: string;

	constructor() {

	}
}