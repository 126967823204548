<h2 mat-dialog-title class="title">
	<span>Delete Header Footer Templates</span>
</h2>
<mat-dialog-content>
	<div>Are you sure you want to continue? The Header/Footer will be removed from the following templates:</div>
	<ul class="confirm-text-gap">
		<li *ngFor="let data of data.affectedDocuments">{{ data.name }}</li>
	</ul>
</mat-dialog-content>
<mat-dialog-actions class="dialog-actions">
	<button mat-raised-button color="primary" [mat-dialog-close]="true" mat-dialog-close>Yes</button>
	<button mat-raised-button mat-dialog-close>No</button>
</mat-dialog-actions>
