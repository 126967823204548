// This file has been generated from ExportBillToPdfDto.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.Domain.Bills.Item.ExportBillToPdfDto
export class ExportBillToPdfDto {

	// DocumentName
	documentName: string;
	// BillIds
	billIds: string[];

	constructor() {

	}
}