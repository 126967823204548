import { Pipe, PipeTransform } from '@angular/core';

import { BasicWorkflowType } from '@common/models/Settings/BasicWorkflows/Common/BasicWorkflowType';

/*
 * Resolve basic workflow type pipe display string by the enum key
 */
@Pipe({ name: 'basicWorkflowType' })
export class BasicWorkflowTypePipe implements PipeTransform {
	transform(key: keyof typeof BasicWorkflowType): string {
		if (!BasicWorkflowType[key]) {
			throw Error(`Dev error: The Workflow Type enum does not contain the key ${key}`);
		}
		return BasicWorkflowType[key];
	}
}
