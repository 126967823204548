// This file has been generated from InstallationRecordLicenceStatus.cs
// Do not modify this file directly!!

// LawMaster.Amalthea.Domain.Marketplace.InstallationRecords.Common.InstallationRecordLicenceStatus
export enum InstallationRecordLicenceStatus {
	Unlicensed = 'Unlicensed',
	Licensed = 'Licensed',
	TrialActive = 'TrialActive',
	UnlicensedTrialExpired = 'UnlicensedTrialExpired',
	FreeUse = 'FreeUse'
}
