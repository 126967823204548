import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { TrustAccountListItemDto } from '@common/models/Settings/TrustSettings/TrustAccounts/List/TrustAccountListItemDto';
import { ClearTrustAccountsAction, SetTrustAccountsAction } from '@common/state/actions/trust-accounts-data.actions';
import { locked, lockedObservable } from '@common/utils/lockUtils';
import { Store } from '@ngrx/store';
import AwaitLock from 'await-lock';
import * as moment from 'moment-timezone';

import { ITrustAccountsData } from '../../state/models/trust-accounts-data';
import { getTrustAccounts } from '../../state/reducers/trust-accounts-data.reducer';
import { TrustAccountsService } from './trustaccounts.service';
import { ListResponse } from '@common/models/Generic/ListResponse';

@Injectable({
	providedIn: 'root'
})
export class TrustAccountsCachedService {
	private lock = new AwaitLock();

	constructor(private trustAccountsService: TrustAccountsService, private store: Store<ITrustAccountsData>) {}

	getTrustAccountList(): Observable<TrustAccountListItemDto[]> {
		return lockedObservable(this.lock, () => {
			return this.store.select(getTrustAccounts).pipe(
				take(1),
				switchMap((data: ITrustAccountsData) => {
					if (!!this.isExpiredOrMissing(data)) {
						return this.fetchAndStoreTrustAccounts();
					}

					return of(data.trustAccounts);
				})
			);
		});
	}

	clearCache(): Observable<void> {
		return locked(this.lock, () => {
			this.store.dispatch(new ClearTrustAccountsAction());
		});
	}

	private isExpiredOrMissing(data: ITrustAccountsData): boolean {
		return (
			!data?.trustAccounts || // Entities is null or undefined
			!data?.expiry ||
			!!moment().isSameOrAfter(data.expiry)
		); // Expired
	}

	private fetchAndStoreTrustAccounts(): Observable<TrustAccountListItemDto[]> {
		return this.trustAccountsService.getTrustAccountList().pipe(
			// Handle result in the pipe, because we send another requests if this one is empty
			switchMap((list: ListResponse<TrustAccountListItemDto>) => {
				if (!!list) {
					this.store.dispatch(
						new SetTrustAccountsAction({
							trustAccounts: list.records,
							expiry: moment().add(2, 'minutes')
						})
					);
				}

				return of(list.records);
			})
		);
	}
}
