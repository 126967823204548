// This file has been generated from MatterStaffMemberCreateUpdateDto.cs
// Do not modify this file directly!!
// tslint:disable

import { SetSimpleType } from '@common/utils/class-transform';



// LawMaster.Amalthea.Domain.Matters.Item.MatterStaffMemberCreateUpdateDto
export class MatterStaffMemberCreateUpdateDto {

	// ContactId
	contactId: string;
	// UserTypeId
	userTypeId: string;
	// Rate
	rate: number;
	// IsMain
	@SetSimpleType(Boolean)
	isMain: boolean;

	constructor() {

	}
}