import { Injectable } from '@angular/core';

import { interval, of } from 'rxjs';
import { catchError, debounce, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { ReportsService } from '@common/services/reports.service';
import { DocumentEmailTemplatesService } from '@common/services/settings/documentemailtemplates.service';
import { DocumentHeaderFooterTemplatesService } from '@common/services/settings/documentheaderfootertemplates.service';
import { DocumentTemplatesService } from '@common/services/settings/documenttemplates.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash-es';

import { DocumentsService } from 'app/services/documents.service';

import { IAppState } from '../../app.state';
import { DocumentListActions, loading } from './document-list.actions';
import { DocumentServiceType } from './document-list.state';

@Injectable()
export class DocumentListEffects {
	loadDocuments$ = createEffect(() =>
		this._actions$.pipe(
			ofType(
				DocumentListActions.Load,
				DocumentListActions.SetPageIndex,
				DocumentListActions.SetPageSize,
				DocumentListActions.SetSortBy,
				DocumentListActions.SetSortDirection,
				DocumentListActions.SetFilters
			),
			withLatestFrom(this._store),
			filter(([_, state]) => !isEqual(state.documentList.request, state.documentList.oldRequest)),
			tap(() => this._store.dispatch(loading())),
			debounce(() => interval(200)),
			switchMap(([_, state]) => {
				if (state.documentList.request.serviceType === DocumentServiceType.Document) {
					return this._documentsService.getDocumentList(state.documentList.request).pipe(
						map(response => ({ type: DocumentListActions.LoadSuccess, response })),
						catchError(error => of({ type: DocumentListActions.LoadFailed, error }))
					);
				}

				if (state.documentList.request.serviceType === DocumentServiceType.Template) {
					return this._documentTemplatesService.getDocumentTemplateList(state.documentList.request).pipe(
						map(response => ({ type: DocumentListActions.LoadSuccess, response })),
						catchError(error => of({ type: DocumentListActions.LoadFailed, error }))
					);
				}

				if (state.documentList.request.serviceType === DocumentServiceType.HeaderFooterTemplate) {
					return this._documentHeaderFooterTemplatesService
						.getDocumentHeaderFooterTemplateList(state.documentList.request)
						.pipe(
							map(response => ({ type: DocumentListActions.LoadSuccess, response })),
							catchError(error => of({ type: DocumentListActions.LoadFailed, error }))
						);
				}

				if (state.documentList.request.serviceType === DocumentServiceType.EmailTemplate) {
					return this._documentEmailTemplatesService
						.getDocumentEmailTemplateList(state.documentList.request)
						.pipe(
							map(response => ({ type: DocumentListActions.LoadSuccess, response })),
							catchError(error => of({ type: DocumentListActions.LoadFailed, error }))
						);
				}

				if (state.documentList.request.serviceType === DocumentServiceType.Report) {
					return this._reportService.getPublishedReportsList(state.documentList.request).pipe(
						map(response => ({ type: DocumentListActions.LoadSuccess, response })),
						catchError(error => of({ type: DocumentListActions.LoadFailed, error }))
					);
				}

				return of({
					type: DocumentListActions.LoadSuccess,
					response: { totalRecords: 0, pageIndex: 0, records: [] }
				});
			})
		)
	);

	constructor(
		private _actions$: Actions,
		private _store: Store<IAppState>,
		private _documentsService: DocumentsService,
		private _documentTemplatesService: DocumentTemplatesService,
		private _documentHeaderFooterTemplatesService: DocumentHeaderFooterTemplatesService,
		private _documentEmailTemplatesService: DocumentEmailTemplatesService,
		private _reportService: ReportsService
	) {}
}
