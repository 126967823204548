export function padBase64(base64data: string): string {
	while (base64data.length % 4 !== 0) {
		base64data += '=';
	}
	return base64data;
}

export function b64DecodeUnicode(str: string): string {
	const base64 = str.replace(/\-/g, '+').replace(/\_/g, '/');
	return decodeURIComponent(
		atob(base64)
			.split('')
			.map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
			.join('')
	);
}
