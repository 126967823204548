import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router, RoutesRecognized } from '@angular/router';

import { Subscription } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';

import { AppcuesService } from '@common/appcues/appcues.service';
import { ApplicationInsightsService } from '@common/application-insights/application-insights.service';
import { GoogleAnalyticsService } from '@common/google-analytics/google-analytics.service';
import { TenantMessageDialogComponent } from '@common/notification/tenant-message-dialog.component';
import { XeroOauth2Service } from '@common/services/settings/xerooauth2.service';
import { SpinnerService } from '@common/services/spinner.service';
import { LocalStorageService } from '@common/services/storage/local-storage.service';
import { CurrentUserActions } from '@common/state/actions/current-user.actions';
import { TenantActions } from '@common/state/actions/tenant.actions';
import { ICurrentUserData } from '@common/state/models/current-user-data';
import { ITenantData } from '@common/state/models/tenant-data';
import { Store } from '@ngrx/store';
import { detect } from 'detect-browser';
import * as moment from 'moment-timezone';
import { Spinkit } from 'ng-http-loader';

import { AppConfig } from './app.config';
import { AuthNotificationService } from './core/auth-notif.service';
import { AuthService } from './core/auth.service';
import { SignalrService } from './core/signalr/signalr.service';
import { invalidateCache as invalidateCostCodesCache } from './core/state/filters/cost-code-filter/cost-code-filter.actions';
import { invalidateCache as invalidateDocumentCategoriesCache } from './core/state/filters/document-category-filter/document-category-filter.actions';
import { invalidateCache as invalidatePracticeAreaCache } from './core/state/filters/practice-area-filter/practice-area-filter.actions';
import { invalidateCache as invalidateTrustAccountCache } from './core/state/filters/trust-account-filter/trust-account-filter.actions';
import { invalidateCache as invalidateCalculationVariablesCache } from './core/state/lists/calculation-variable-list/calculation-variable-list.actions';
import { MatterNumberingConfigActions } from './core/state/misc/matter-numbering-config/matter-numbering-config.actions';
import { invalidateCache as invalidatePracticeAreaStagesCache } from './core/state/misc/practice-area-stages/practice-area-stages.actions';
import { TenantCompanyActions } from './core/state/misc/tenant-company/tenant-company.actions';
import { TermsAndConditionsCheckService } from './core/terms-and-conditions-dialog/terms-and-conditions-check.service';
import { WelcomeDialogCheckService } from './core/welcome-dialog/welcome-dialog-check.service';
import { AppBrandingService } from './services/app-branding.service';
import { ExternalPortalRouteService } from './services/external-portal-route-service';
// import { ZendeskService } from './services/zendesk.service';
import { PageTitleService } from './shared/utils/page-title.service';

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'app',
	styleUrls: ['./app.component.scss'],
	template: `
		<main-menu *ngIf="authenticated && !isPortal">
			<mat-sidenav-container>
				<mat-sidenav position="end" mode="side" [opened]="showPreview">
					<router-outlet name="preview"></router-outlet>
				</mat-sidenav>
				<mat-sidenav-content> <router-outlet></router-outlet> </mat-sidenav-content>
			</mat-sidenav-container>
			<app-footer></app-footer>
		</main-menu>
		<div *ngIf="isPortal">
			<router-outlet> </router-outlet>
			<router-outlet name="preview"></router-outlet>
		</div>
		<div class="spinner-overlay" *ngIf="forceSpinner">
			<sk-chasing-dots></sk-chasing-dots>
		</div>
		<ng-http-loader
			[debounceDelay]="1000"
			[filteredUrlPatterns]="filteredUrlPatterns"
			[filteredHeaders]="['spinner', 'no-spinner']"
			[spinner]="spinkit.skCubeGrid"
			*ngIf="!!showSpinner && !forceSpinner"
		></ng-http-loader>
	`
})
export class AppComponent implements OnInit, OnDestroy {
	filteredUrlPatterns: string[] = [
		'/currenttasks',
		'/RecentlyAccessed',
		'/lookup',
		'/omnisearch',
		'/practiceAreas',
		'/isPhoneValid',
		'/documenttemplates',
		'contacts/.*/documents',
		'matters/.*/documents',
		'/download',
		'/SendFeedback',
		// Xero sync end-points. Progress bar shown in the sync dialog
		'/bills/xero/SyncContacts',
		'/bills/xero/UpdatePaidStatus',
		'/bills/xero/ExportBills',
		'/bills/xero/SyncWrittenOffPayments',
		'/preview'
	];

	// Offset from the left for the main area
	mainAreaOffset: number;
	authenticated: boolean = false;
	showSpinner: boolean = true;
	_forceSpinner: boolean = false;
	get forceSpinner() {
		return this._forceSpinner;
	}
	private subscriptions: Subscription = new Subscription();

	spinkit = Spinkit;

	get showPreview(): boolean {
		return this.activatedRoute.snapshot.children.find(child => child.outlet === 'preview') != null;
	}

	get isPortal(): boolean {
		return !!this.portalRouteService.isPortal || !!this.portalRouteService.isPortalPreview;
	}

	constructor(
		private store: Store<{ currentUserData: ICurrentUserData; tenantData: ITenantData }>,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private welcomeDialogCheck: WelcomeDialogCheckService,
		private termsAndConditionsCheckService: TermsAndConditionsCheckService,
		@Inject(DOCUMENT) private document: Document,
		private authNotificationService: AuthNotificationService,
		private authService: AuthService,
		private portalRouteService: ExternalPortalRouteService,
		private pageTitle: PageTitleService,
		private breakpointObserver: BreakpointObserver,
		private meta: Meta,
		private dialogService: MatDialog,
		private appInsights: ApplicationInsightsService,
		private googleAnalyticsService: GoogleAnalyticsService,
		private signalrService: SignalrService,
		private appcuesService: AppcuesService,
		// private zendeskService: ZendeskService,
		private xeroOauth2Service: XeroOauth2Service,
		private cdRef: ChangeDetectorRef,
		private localStorageService: LocalStorageService,
		private spinnerService: SpinnerService,
		private appBrandingService: AppBrandingService
	) {
		if (!this.appBrandingService.trySetTheme()) {
			console.error('Failed to set theme');
		}
	}

	ngOnInit() {
		this.handleDuplicateTab();

		// Wait until user has been authenticated before loading the rest of the components.
		this.subscriptions.add(
			this.authService.isAuthenticated$.pipe(filter(Boolean), take(1)).subscribe(() =>
				// Calling initialize() will kick of a bunch of additional subscriptions.
				// It's important that the take(1) remains above to ensure that this is only ever run once.
				// We did it this way to avoid needing to filter on this.authService.isAuthenticated$ on every
				// single subscription inside initialize() as this would have been prone to errors where people
				// miss such filter when adding new subscriptions.
				this.initialize()
			)
		);
		// Setup authentication
		this.subscriptions.add(
			this.authService.configureOAuthLogin().subscribe({
				error: err => {
					console.log(err);
					this.authService.Login();
				}
			})
		);
	}

	private handleDuplicateTab() {
		const browser = detect();

		if (!browser.os.startsWith('Windows') && !browser.os.startsWith('Mac')) {
			return;
		}

		if (browser.name !== 'chrome' && browser.name !== 'edge-chromium') {
			return;
		}

		try {
			window.addEventListener('beforeunload', () => window.sessionStorage.removeItem('__lock'));

			if (window.sessionStorage.getItem('__lock')) {
				window.sessionStorage.clear();
				console.warn('Found a lock in session storage. The storage was cleared.');
			}

			window.sessionStorage.setItem('__lock', '1');
		} catch {
			// Bad data, only care about good data
		}
	}

	private initialize() {
		this.authenticated = true;

		moment.tz.setDefault(this.authService.TimeZoneId);

		this.store.dispatch({ type: CurrentUserActions.LoadCurrentUser });
		this.store.dispatch({ type: TenantActions.LoadTenant });
		this.store.dispatch({ type: MatterNumberingConfigActions.Refresh });
		this.store.dispatch({ type: TenantCompanyActions.RefreshTenantCompany });

		this.subscriptions.add(
			this.store
				.select(state => state?.tenantData)
				.pipe(
					filter(x => !!x?.tenantInformation),
					take(1),
					filter(x => !!x.tenantInformation.tenantMessaging?.enableDialogMessage)
				)
				.subscribe((response: ITenantData) => {
					this.dialogService.open(TenantMessageDialogComponent, {
						data: response.tenantInformation.tenantMessaging,
						width: '512px'
					});
				})
		);

		this.subscriptions.add(
			this.signalrService
				.onCalculationVariablesChanged()
				.pipe(filter(params => !!params?.entityTypes?.length))
				.subscribe(params =>
					params.entityTypes.forEach(entityType =>
						this.store.dispatch(invalidateCalculationVariablesCache({ entityType: entityType }))
					)
				)
		);

		this.subscriptions.add(
			this.signalrService.onCostCodesChanged().subscribe(() => this.store.dispatch(invalidateCostCodesCache()))
		);

		this.subscriptions.add(
			this.signalrService
				.onDocumentCategoriesChanged()
				.subscribe(() => this.store.dispatch(invalidateDocumentCategoriesCache()))
		);

		this.subscriptions.add(
			this.signalrService.onPracticeAreasChanged().subscribe(() => {
				this.store.dispatch(invalidatePracticeAreaCache());
				this.store.dispatch(invalidatePracticeAreaStagesCache());
			})
		);

		this.subscriptions.add(
			this.signalrService
				.onTrustAccountsChanged()
				.subscribe(() => this.store.dispatch(invalidateTrustAccountCache()))
		);

		this.subscriptions.add(
			this.store
				.select(state => state?.currentUserData?.currentUser)
				.pipe(
					filter(Boolean),
					switchMap(() => {
						return this.activatedRoute.queryParams;
					}),
					filter((params: Params) => params?.TransientXeroId),
					switchMap((params: Params) => {
						return this.xeroOauth2Service.linkTransientUserId({ transientXeroId: params?.TransientXeroId });
					}),
					filter(response => response.shouldRouteToXeroConfig)
				)
				.subscribe(() => {
					this.router.navigate(['/system/bill-settings'], {
						queryParams: { activeTab: 'xerosync', dialog: 'configure' }
					});
				})
		);

		this.subscriptions.add(
			this.router.events.pipe(filter(event => event instanceof RoutesRecognized)).subscribe((): void => {
				// Scroll to top on Route Change (still doesn't work on iOS though)
				// taken from http://stackoverflow.com/a/39601987/968003
				this.document.body.scrollTop = 0;
				this.pageTitle.InitializePageTitles();
			})
		);

		// If the user registers from an external site using a promo code add the promo code to the local storage so it can be applied when the user subscribes
		this.subscriptions.add(
			this.activatedRoute.queryParams.subscribe(params => {
				if (!!params.promocode)
					this.localStorageService.setItem(AppConfig.promoCodeLocalStorageKey, params.promocode);
			})
		);

		// Disable zooming on mobiles to avoid iPhones not zooming out after automatically zooming in to edit
		this.subscriptions.add(
			this.breakpointObserver.observe([Breakpoints.Handset]).subscribe(result => {
				const isMobile = result.matches;

				if (isMobile) {
					this.meta.updateTag(
						{
							content:
								'width=device-width, initial-scale=1, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no',
							name: 'viewport'
						},
						`name='viewport'`
					);
				} else {
					this.meta.updateTag(
						{
							content: 'width=device-width, initial-scale=1',
							name: 'viewport'
						},
						`name='viewport'`
					);
				}
			})
		);

		// Initialise Google Analytics
		this.googleAnalyticsService.init();

		// Hide the Spinner if the welcome screen is being shown to give new users a quicker looking response
		this.subscriptions.add(this.welcomeDialogCheck.DialogOpening.subscribe(() => (this.showSpinner = false)));

		this.subscriptions.add(
			this.authNotificationService.firstSuccessfulRequest$
				.pipe(
					tap(() => {
						this.showSpinner = true;

						// Initialize Appcues
						this.appcuesService.init();

						if (!!this.portalRouteService.isUserRoute) {
							// Initialize Zendesk
							// this.zendeskService.activate();
							// Initialize SignalR
							this.signalrService.init();
						}
					}),
					switchMap(() => this.termsAndConditionsCheckService.checkUserRequiresTerms$()),
					filter(() => !!this.authService.UserId && !!this.authService.OrganisationId),
					switchMap(() => {
						this.appInsights.setAuthenticatedUserContext(
							this.authService.UserId,
							this.authService.OrganisationId
						);

						return this.welcomeDialogCheck.DisplayWelcomeDialogOnFirstLogin();
					})
				)
				.subscribe()
		);

		this.subscriptions.add(
			this.spinnerService.onSpinChanged().subscribe(value => {
				this._forceSpinner = value;
				this.cdRef.detectChanges();
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}
}
