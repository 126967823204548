import { createSelector, MemoizedSelector } from '@ngrx/store';

import { ContactCustomFieldListItemDto } from '@common/models/Settings/CustomFields/List/ContactCustomFieldListItemDto';
import { IAppState } from '../../../app.state';

export const selectContactCustomFieldList = (state: IAppState) => state.contactCustomFieldList;

export const selectContactCustomFieldListRecords: MemoizedSelector<IAppState, ContactCustomFieldListItemDto[]> =
	createSelector(selectContactCustomFieldList, state => state?.list?.records || []);
export const selectContactCustomFieldListRequest: MemoizedSelector<IAppState, Partial<any>> = createSelector(
	selectContactCustomFieldList,
	state => state?.request
);
export const selectContactCustomFieldListPageIndex = createSelector(
	selectContactCustomFieldList,
	state => state?.list?.pageIndex || 0
);
export const selectContactCustomFieldListTotalRecords = createSelector(
	selectContactCustomFieldList,
	state => state?.list?.totalRecords || 0
);
export const selectContactCustomFieldIsFetching = createSelector(
	selectContactCustomFieldList,
	state => !!state?.isFetching
);
