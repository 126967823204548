import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { PracticeAreasService } from '@common/services/settings/practiceareas.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash-es';
import moment from 'moment';

import { IAppState } from '../../app.state';
import { loading, PracticeAreaFilterActions } from './practice-area-filter.actions';

@Injectable()
export class PracticeAreaFilterEffects {
	loadPracticeAreas$ = createEffect(() =>
		this._actions$.pipe(
			ofType(
				PracticeAreaFilterActions.Load,
				PracticeAreaFilterActions.SetFilters,
				PracticeAreaFilterActions.InvalidateCache
			),
			map(action => action as { type: PracticeAreaFilterActions }),
			withLatestFrom(this._store),
			filter(([action, state]) => {
				const practiceAreaState = state.practiceAreaFilterData;

				if (action.type === PracticeAreaFilterActions.InvalidateCache && !practiceAreaState.list) {
					return false;
				}

				return (
					!practiceAreaState.cacheExpiry ||
					!!moment().isSameOrAfter(practiceAreaState.cacheExpiry) ||
					!isEqual(practiceAreaState.oldRequest, practiceAreaState.request)
				);
			}),
			debounceTime(500),
			tap(() => this._store.dispatch(loading())),
			switchMap(([_, state]) =>
				this._practiceAreasService.getPracticeAreaList(state.practiceAreaFilterData.request).pipe(
					map(response => ({
						type: PracticeAreaFilterActions.LoadSuccess,
						response
					})),
					catchError(error => of({ type: PracticeAreaFilterActions.LoadFailed, error }))
				)
			)
		)
	);

	constructor(
		private _actions$: Actions,
		private _store: Store<IAppState>,
		private _practiceAreasService: PracticeAreasService
	) {}
}
