import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';
import { BankAccountCreateUpdateDto } from '@common/models/Settings/TrustSettings/BankAccounts/Item/BankAccountCreateUpdateDto';
import { BankAccountListItemDto } from '@common/models/Settings/TrustSettings/BankAccounts/List/BankAccountListItemDto';
import { DomainError } from '@common/models/Validation/DomainError';
import { NotificationService } from '@common/notification';
import { BankAccountsService } from '@common/services/settings/bankaccounts.service';
import { Store } from '@ngrx/store';

import { IAppState } from 'app/core/state/app.state';
import { BankAccountListActions } from 'app/core/state/lists/settings/bank-account-list/bank-account-list.actions';
import {
	selectBankAccountIsFetching,
	selectBankAccountListRecords,
	selectBankAccountListRequest,
	selectBankAccountListTotalRecords
} from 'app/core/state/lists/settings/bank-account-list/bank-account-list.selectors';
import { GridViewService } from 'app/services/grid-view.service';
import {
	ActionTypes,
	GenericListStateComponent,
	SelectorTypes
} from 'app/shared/generics/generic.list.state.component';

import { BankAccountDialogComponent } from '../create-edit-account/bank-account-dialog.component';
import { IBankAccountDialogData } from '../IBankAccountDialogData';

@Component({
	selector: 'bank-accounts-list',
	styleUrls: ['./bank-accounts-list.component.scss'],
	templateUrl: './bank-accounts-list.component.html'
})
export class BankAccountsListComponent
	extends GenericListStateComponent<BankAccountListItemDto, void>
	implements OnInit
{
	get actions(): ActionTypes {
		return {
			init: BankAccountListActions.Init,
			load: BankAccountListActions.Load,
			setPageIndex: BankAccountListActions.SetPageIndex,
			setPageIndexForId: BankAccountListActions.SetPageIndexForId,
			setPageSize: BankAccountListActions.SetPageSize,
			setSortBy: BankAccountListActions.SetSortBy,
			setSortDirection: BankAccountListActions.SetSortDirection,
			selected: BankAccountListActions.SelectRecords
		};
	}
	get selectors(): SelectorTypes {
		return {
			records: selectBankAccountListRecords,
			isFetching: selectBankAccountIsFetching,
			request: selectBankAccountListRequest,
			totalRecords: selectBankAccountListTotalRecords
		};
	}
	constructor(
		store: Store<IAppState>,
		router: Router,
		activatedRoute: ActivatedRoute,
		private bankAccountsService: BankAccountsService,
		dialog: MatDialog,
		private notificationService: NotificationService,
		gridViewService: GridViewService
	) {
		super(
			['accountName', 'bankName', 'accountNumber', 'actions'],
			dialog,
			store,
			router,
			activatedRoute,
			gridViewService
		);
	}

	ngOnInit(): void {
		super.ngOnInit();
	}

	createBankAccount() {
		this.dialog
			.open(BankAccountDialogComponent, { data: null })
			.afterClosed()
			.pipe(
				filter(Boolean),
				switchMap((dialogData: IBankAccountDialogData) =>
					this.bankAccountsService.createBankAccount(dialogData.accountData)
				)
			)
			.subscribe(result => {
				if (result) {
					this.onCreateSuccess(result);
				}
			}, this.onError);
	}

	editBankAccount(row: BankAccountListItemDto) {
		this.dialog
			.open(BankAccountDialogComponent, { data: row.id })
			.afterClosed()
			.pipe(
				filter(Boolean),
				switchMap((dialogData: IBankAccountDialogData) => {
					if (dialogData.isDelete) {
						this.deleteBankAccount(dialogData.accountData);
						return of(null);
					} else {
						return this.bankAccountsService.updateBankAccount(
							dialogData.accountData.id,
							dialogData.accountData
						);
					}
				})
			)
			.subscribe(result => {
				if (result) {
					this.onUpdateSuccess(result);
				}
			}, this.onError);
	}

	deleteBankAccount(row: BankAccountCreateUpdateDto) {
		this.notificationService
			.showConfirmation(
				'Delete Bank Account',
				`Are you sure you want to delete the bank account "${row.bankDetails.accountName}"?`
			)
			.pipe(
				filter(Boolean),
				switchMap(() => this.bankAccountsService.deleteBankAccount(row.id))
			)
			.subscribe({
				next: response => this.onDeleteSuccess(response),
				error: error => this.notificationService.showErrors('Error deleting Bank Account', error)
			});
	}

	setDefault(row: BankAccountCreateUpdateDto) {
		this.subscriptions.add(
			this.bankAccountsService.setDefaultBankAccount(row.id).subscribe({
				next: result => {
					this.notificationService.showNotification(
						`Bank Account ${row.bankDetails.accountName} has been set as the default account`
					);
					this.store.dispatch({ type: BankAccountListActions.UpdateRecords, response: result });
				},
				error: err => {
					this.notificationService.showError(`There was an error completing the operation`, err);
				}
			})
		);
	}

	private onError = (errors: DomainError[]) => {
		this.notificationService.showErrors('Error', errors);
	};

	private onCreateSuccess = (result: MutationResponseDto) => {
		this.notificationService.showNotification(`Bank Account ${result.name} created`);
		this.store.dispatch({ type: BankAccountListActions.InsertRecords, response: result });
	};

	private onUpdateSuccess = (result: MutationResponseDto) => {
		this.notificationService.showNotification(`Bank Account ${result.name} updated`);
		this.store.dispatch({ type: BankAccountListActions.UpdateRecords, response: result });
	};

	private onDeleteSuccess = (result: MutationResponseDto) => {
		this.notificationService.showNotification(`Bank Account deleted: ${result.name}`);
		this.store.dispatch({ type: BankAccountListActions.RemoveRecords, response: result });
	};
}
