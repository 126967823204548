import { createSelector, MemoizedSelector } from '@ngrx/store';

import { IAppState } from '../../app.state';

export const selectedJobStatusFilterData = (state: IAppState) => state.jobStatusFilterData;

export const selectJobStatusRecords: MemoizedSelector<IAppState, string[]> = createSelector(
	selectedJobStatusFilterData,
	state => state?.list || []
);

export const selectJobStatusIsFetching = createSelector(selectedJobStatusFilterData, state => !!state?.isFetching);
