import { Injectable } from '@angular/core';

import { interval, of } from 'rxjs';
import { catchError, debounce, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { IAppState } from '../../../app.state';
import { loading, MatterCustomFieldListActions } from './matter-custom-field-list.actions';
import { MatterCustomFieldsService } from '@common/services/customfields-matter.service';

@Injectable()
export class MatterCustomFieldListEffects {
	loadMatterCustomFields$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				MatterCustomFieldListActions.Load,
				MatterCustomFieldListActions.SetPageIndex,
				MatterCustomFieldListActions.SetPageSize,
				MatterCustomFieldListActions.SetSortBy,
				MatterCustomFieldListActions.SetSortDirection,
				MatterCustomFieldListActions.SetFilters
			),
			tap(action => this.store.dispatch(loading())),
			withLatestFrom(this.store),
			debounce(() => interval(200)),
			switchMap(([action, state]) => {
				return this.matterCustomFieldService.getMatterCustomFieldList(state.matterCustomFieldList.request).pipe(
					map(response => {
						return { type: MatterCustomFieldListActions.LoadSuccess, response };
					}),
					catchError(error => of({ type: MatterCustomFieldListActions.LoadFailed, error }))
				);
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<IAppState>,
		private matterCustomFieldService: MatterCustomFieldsService
	) {}
}
