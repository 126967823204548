// This file has been generated from DocumentTagListRequest.cs
// Do not modify this file directly!!
// tslint:disable

import { EnumSortDirection } from '../../../Common/EnumSortDirection';

// LawMaster.Amalthea.Domain.Settings.DocumentTags.List.DocumentTagListRequest
export class DocumentTagListRequest {

	// Search
	search: string;
	// SortBy
	sortBy: string;
	// SortDirection
	sortDirection: keyof typeof EnumSortDirection;
	// PageIndex
	pageIndex: number;
	// PageSize
	pageSize: number;
	// PageIndexForId
	pageIndexForId: string;
	// Name
	name: string;
	// PracticeAreaIds
	practiceAreaIds: string[];

	constructor() {

	}
}