import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';

import { ApplicationInsightsService } from '@common/application-insights/application-insights.service';
import { GoogleAnalyticsService } from '@common/google-analytics/google-analytics.service';
import { UserRecentItemDto } from '@common/models/ActivityLogs/List/UserRecentItemDto';
import { RolePermission } from '@common/models/Common/RolePermission';
import { TimeRecordCreateUpdateDto } from '@common/models/CostRecords/Item/TimeRecordCreateUpdateDto';
import { ListResponse } from '@common/models/Generic/ListResponse';
import { CurrentTaskResponse } from '@common/models/Tasks/List/CurrentTaskResponse';
import { TaskListItemDto } from '@common/models/Tasks/List/TaskListItemDto';
import { UserViewDto } from '@common/models/Users/Item/UserViewDto';
import { NotificationService } from '@common/notification';
import { UserCurrentService } from '@common/services/usercurrent.service';
import { ICurrentUserData } from '@common/state/models/current-user-data';
import { ITenantData } from '@common/state/models/tenant-data';
import { getFileIcon } from '@common/utils/file-extensions';
import { nameof } from '@common/utils/nameof';
import { Store } from '@ngrx/store';
import { get, remove, some, uniqWith } from 'lodash-es';
import * as moment from 'moment-timezone';

import { AppConfig, FeatureFlags, isFeatureFlagEnabled } from 'app/app.config';
import { OmnisearchDialogComponent } from 'app/core/omnisearch/omnisearch-dialog.component';
import { MatterDialogComponent } from 'app/create-forms/matter/matter-dialog.component';
import { AppBrandingService } from 'app/services/app-branding.service';
// import { ZendeskService } from 'app/services/zendesk.service';

import { AuthService } from '../auth.service';
import { DialogComponent } from '../dialog.component';
import { DialogConfig, DialogType, EntityType } from '../dialog.config';
import { SecurityPermissionService } from '../security-permissions.service';
import { MainMenuMapItem } from './mainMenuMapItem';
import { ZendeskReplacementDialogComponent } from '../zendesk-replacement/zendesk-replacement.dialog.component';

/* Side menu (aka 'Main menu') */
@Component({
	selector: 'main-menu',
	styleUrls: ['./mainMenu.component.scss'],
	templateUrl: 'mainMenu.component.html'
})
export class MainMenuComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild(MatSidenav, { static: true })
	sidenav: MatSidenav;
	featureFlags: typeof FeatureFlags = FeatureFlags;

	get matteroAppClientUrl() {
		return AppConfig.MatteroAppClientUrl + this.router.url;
	}

	get lawMasterAppClientUrl() {
		return AppConfig.LawMasterAppClientUrl + this.router.url;
	}

	get isMattero() {
		return AppConfig.IsMatteroHost;
	}

	get isLawMaster() {
		return AppConfig.IsLawMasterHost;
	}

	get alternativeIcon() {
		return `/assets/img/${this.appBrandingService.alternativeIcon}`;
	}

	get imageAlt() {
		return this.appBrandingService.imageAlt;
	}

	// Items of the side menu
	private _siteMap: MainMenuMapItem[] = [
		new MainMenuMapItem('Dashboard', '/dashboard', ['/dashboard'], 'dashboard'),
		new MainMenuMapItem('Matters', '/matters', ['/matters/my', '/matters/myrecent', '/matters'], 'gavel'),
		new MainMenuMapItem('Contacts', '/contacts', ['/contacts'], 'person'),
		new MainMenuMapItem('Tasks', '/tasks', ['/tasks/my', '/tasks/myoverdue', '/tasks'], 'assignment_turned_in'),
		new MainMenuMapItem('Documents', '/documents', ['/documents/myrecent', '/documents'], 'description'),
		new MainMenuMapItem('Costs', '/costs', ['/costs/my', '/costs'], 'access_time'),
		new MainMenuMapItem('Invoices', '/invoices', ['/invoices/my', '/invoices'], 'attach_money'),
		new MainMenuMapItem('Trust', '/trust', ['/trust'], 'account_balance'),
		new MainMenuMapItem('Reports', '/reports', ['/reports'], 'bar_chart')
	];

	private _siteMap$: Observable<MainMenuMapItem[]>;

	get siteMap$() {
		return this._siteMap$;
	}

	get dashboardLink() {
		return this._siteMap[0].mainLink;
	}

	sections = {
		dueToday: { title: 'Tasks Due Today', link: 'View all tasks due today', color: 'primary' },
		overdue: { title: 'Overdue Tasks', link: 'View all overdue tasks', color: 'warn' }
	};

	isMobile = false;
	isTablet = false;
	gravatarId: string;

	recentItems: UserRecentItemDto[];
	currentTasks: CurrentTaskResponse;

	hasAccessToSettings = false;
	hasAccessToReports = false;

	get siteName() {
		return AppBrandingService.getSiteName();
	}

	// Flag: whether the side menu is collapsed
	private toggleCollapsed: boolean = true;
	private subscriptions: Subscription = new Subscription();
	private user: UserViewDto;

	// get knowledgeBaseUrl(): string {
	// 	return isFeatureFlagEnabled(FeatureFlags.zenDesk) ? '#Zendesk' : 'https://support.mattero.com.au/';
	// }

	isPhoneTabletBreakpoint: boolean;

	constructor(
		private store: Store<{ currentUserData: ICurrentUserData; tenantData: ITenantData }>,
		private authService: AuthService,
		private securityPermissionService: SecurityPermissionService,
		private breakpoint: BreakpointObserver,
		private router: Router,
		private userService: UserCurrentService,
		private cdr: ChangeDetectorRef,
		private dialog: MatDialog,
		private notifService: NotificationService,
		private applicationInsights: ApplicationInsightsService,
		private googleAnalyticsService: GoogleAnalyticsService,
		// private zendeskService: ZendeskService,
		private appBrandingService: AppBrandingService
	) {}

	ngOnInit() {
		// Wait until user has been authenticated before loading the men component.
		this.subscriptions.add(
			this.authService.isAuthenticated$.pipe(filter(Boolean), take(1)).subscribe(() =>
				// Calling initialize() will kick of a bunch of additional subscriptions.
				// It's important that the take(1) remains above to ensure that this is only ever run once.
				// We did it this way to avoid needing to filter on this.authService.isAuthenticated$ on every
				// single subscription inside initialize() as this would have been prone to errors where people
				// miss such filter when adding new subscriptions.
				this.initialize()
			)
		);
	}

	private initialize() {
		this.hasAccessToSettings = this.securityPermissionService.hasAccessToSettings;
		this.hasAccessToReports = this.securityPermissionService.hasAccessToReports;

		if (
			!this.securityPermissionService.hasAccessToTrust ||
			!isFeatureFlagEnabled(FeatureFlags.trustAccountingFeature)
		) {
			remove(this._siteMap, s => {
				return s.title === 'Trust';
			});
		}

		this.subscriptions.add(
			this.store
				.select(state => state?.currentUserData?.currentUser)
				.pipe(filter(Boolean))
				.subscribe(
					(user: UserViewDto) => {
						this.user = user;
						this.gravatarId = user.gravatarId;
					},
					e => this.notifService.showErrors('Error getting user info', e)
				)
		);

		this.subscriptions.add(
			this.userService
				.getCurrentTasks({ count: 5, type: 'ByDueDate' })
				.subscribe(tasks => (this.currentTasks = tasks))
		);

		this._siteMap$ = this.store
			.select(state => state?.tenantData?.tenantInformation?.featureStates)
			.pipe(
				distinctUntilChanged(),
				map(states => (!states ? [] : states.filter(state => !state.isEnabled).map(state => state.type))),
				map(disabledFeatureTypes => {
					return this._siteMap.filter(
						item =>
							!(
								(item.title === 'Trust' && disabledFeatureTypes.includes('TrustAccounting')) ||
								(item.title === 'Invoices' && disabledFeatureTypes.includes('Invoicing')) ||
								(item.title === 'Costs' && disabledFeatureTypes.includes('CostRecords')) ||
								(item.title === 'Tasks' && disabledFeatureTypes.includes('Tasks')) ||
								(item.title === 'Reports' && !this.hasAccessToReports)
							)
					);
				})
			);

		this.subscriptions.add(
			this.breakpoint
				.observe([Breakpoints.Handset, Breakpoints.Tablet])
				.subscribe(state => (this.isPhoneTabletBreakpoint = state.matches))
		);
	}

	ngAfterViewInit() {
		this.subscriptions.add(
			this.breakpoint.observe([Breakpoints.Handset]).subscribe(result => {
				this.isMobile = result.matches;
				this.sidenav.mode = this.isMobile ? 'over' : 'side';
				// When the sidenav is closed it's not visible at all. 'open' means displayed down the left hand edge,
				// regardless of whether it's expanded to show the text labels. Thus:
				// When switching to mobile view make sure the panel is closed (will open on clicking the menu)
				// When switching into desktop view make sure the panel is open and visible (although not necessarily
				// expanded.) If we didn't do this there'd be no way to open/view it in desktop layout.
				if (this.isMobile && this.sidenav.opened) {
					this.sidenav.close();
				} else if (!this.isMobile && !this.sidenav.opened) {
					this.sidenav.open();
				}

				this.cdr.detectChanges();
			})
		);
		this.subscriptions.add(
			this.breakpoint.observe([Breakpoints.Tablet]).subscribe(result => {
				this.isTablet = result.matches;
				this.cdr.detectChanges();
			})
		);
	}

	hasAnyRolePermissions() {
		return this.securityPermissionService.hasAnyRolePermissions();
	}

	gotoRecentDocument(recentItem: UserRecentItemDto) {
		if (recentItem.actionReferenceType) {
			this.router.navigateByUrl(
				`/${recentItem.actionReferenceType.toLowerCase()}/${
					recentItem.actionReference.id
				}/documents(preview:document/${recentItem.relatedTo.id})?pageIndexForId=${recentItem.relatedTo.id}`,
				{}
			);
		} else {
			this.router.navigateByUrl(`/documents?pageIndexForId=${recentItem.relatedTo.id}`);
		}
	}

	gotoRecentTask(recentItem: UserRecentItemDto) {
		if (recentItem.actionReferenceType) {
			this.router.navigateByUrl(
				`/${recentItem.actionReferenceType.toLowerCase()}/${
					recentItem.actionReference.id
				}/tasks?pageIndexForId=${recentItem.relatedTo.id}`,
				{}
			);
		} else {
			this.router.navigateByUrl(`/tasks?pageIndexForId=${recentItem.relatedTo.id}`);
		}
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	// Collapse/expand the menu
	expandCollapseSideMenu() {
		this.toggleCollapsed = !this.toggleCollapsed;
	}

	isMenuButtonHighlighted(item: MainMenuMapItem): boolean {
		return some(item.highlightedLinks, r => this.router.url.split('?')[0] === r);
	}

	isUrl(url: string): boolean {
		return this.router.url.split('?')[0].startsWith(url);
	}

	openMenu() {
		this.sidenav.open();
	}

	closeMenu() {
		this.toggleCollapsed = true;
		if (this.isMobile) {
			this.sidenav.close();
		}
	}

	logout() {
		this.closeMenu();
		this.subscriptions.add(this.authService.Logout().subscribe());
	}

	openedCreateMenu() {
		this.openedMenu('Create');
	}

	openedSearchMenu() {
		this.openedMenu('Search');
	}

	openedRecentItemsMenu() {
		this.googleAnalyticsService.trackRecentItems();

		this.openedMenu('Recent Item');
		this.subscriptions.add(
			this.userService.getRecentlyModified().subscribe(items => {
				this.recentItems = uniqWith(items, this.recentItemsEquality);
			})
		);
	}

	recentItemsEquality(item: UserRecentItemDto, otherItem: UserRecentItemDto) {
		return (
			item.id === otherItem.id &&
			item.relatedTo.id === otherItem.relatedTo.id &&
			item.relatedTo.name === otherItem.relatedTo.name &&
			item.relatedToType === otherItem.relatedToType &&
			item.actionReferenceType === otherItem.actionReferenceType
		);
	}

	openedTaskMenu() {
		this.openedMenu('Task');
	}

	openedAccountMenu() {
		this.openedMenu('Account');
	}

	openedJobsMenu() {
		this.openedMenu('Job');
	}

	openCreateTimeRecordDialog() {
		this.dialog.open(DialogComponent, {
			data: new DialogConfig(DialogType.Create, EntityType.TimeRecord)
		});
	}

	openCreateExpenseDialog() {
		this.dialog.open(DialogComponent, {
			data: new DialogConfig(DialogType.Create, EntityType.Expense)
		});
	}

	openCreateMatterDialog() {
		this.subscriptions.add(
			this.dialog
				.open(MatterDialogComponent)
				.afterClosed()
				.subscribe((prepopulatedData: Partial<TimeRecordCreateUpdateDto>) => {
					if (prepopulatedData) {
						const dialogCfg = new DialogConfig(
							DialogType.Create,
							EntityType.TimeRecord,
							null,
							prepopulatedData
						);
						this.dialog.open(DialogComponent, {
							data: dialogCfg
						});
					}
				})
		);
	}

	openCreateContactDialog() {
		this.dialog.open(DialogComponent, {
			data: new DialogConfig(DialogType.Create, EntityType.Contact)
		});
	}

	openCreateTaskDialog() {
		this.dialog.open(DialogComponent, {
			data: new DialogConfig(DialogType.Create, EntityType.Task)
		});
	}

	openCreateTrustRecordDialog() {
		this.dialog.open(DialogComponent, {
			data: new DialogConfig(DialogType.Create, EntityType.Trust),
			width: '800px'
		});
	}

	openCreateNoteDialog() {
		this.dialog.open(DialogComponent, {
			data: new DialogConfig(DialogType.Create, EntityType.Note)
		});
	}

	openSearchDialog(): void {
		this.dialog.open(OmnisearchDialogComponent, {
			height: '100%',
			maxHeight: '100vh',
			maxWidth: '100vw',
			width: '100%'
		});
	}

	onKnowledgeBaseClick(event: MouseEvent): void {
		const dialogRef = this.dialog.open(ZendeskReplacementDialogComponent);

		this.subscriptions.add(
			dialogRef
				.afterClosed()
				.pipe(filter(Boolean))
				.subscribe()
		);
	}

	humanizeTimestamp(timestamp: moment.MomentInput): string {
		return moment(timestamp).fromNow();
	}

	subRoute(type: string): string {
		switch (type) {
			case nameof<CurrentTaskResponse>('overdue'):
				return 'myoverdue';
			case nameof<CurrentTaskResponse>('dueToday'):
				return 'mytoday';
			default:
				return 'my';
		}
	}

	fileIcon(extension: string): string {
		return getFileIcon(extension);
	}

	hasRolePermission(permission: keyof typeof RolePermission) {
		return this.securityPermissionService.hasRolePermission(permission);
	}

	get taskCount() {
		return (this.currentTasks?.overdue?.totalRecords || 0) + (this.currentTasks?.dueToday?.totalRecords || 0);
	}

	private openedMenu(menuName: string) {
		this.applicationInsights.trackEvent(`Opened ${menuName} menu`);
	}

	get isCollapsed(): boolean {
		return !this.isExpanded;
	}

	get isExpanded(): boolean {
		return !this.toggleCollapsed || this.isMobile;
	}

	get userId(): string {
		return get(this.user, ['id']);
	}
	get userName(): string {
		return get(this.user, ['contact', 'name']);
	}

	get mobileTitle(): string {
		return get(
			this._siteMap.find(item => this.router.url.startsWith(item.uriStartsWith)),
			'title',
			'System'
		);
	}

	get sectionKeys(): string[] {
		return Object.keys(this.sections).filter(section =>
			get(this.currentTasks, [section, nameof<ListResponse<TaskListItemDto>>('totalRecords')], 0)
		);
	}
}
