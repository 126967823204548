import { createFeatureSelector, createSelector } from '@ngrx/store';
import { get } from 'lodash-es';

import { ClearCurrentPageType, CurrentPageActions, SetCurrentPageType } from './current-page.actions';
import { ICurrentPageState } from './current-page.state';

export const initialState: ICurrentPageState = { id: null, pageType: null, lookup: null };

export function currentPageReducer(state = initialState, action: CurrentPageActions): ICurrentPageState {
	switch (action.type) {
		case SetCurrentPageType:
			return get(action, 'data', initialState);
		case ClearCurrentPageType:
			return initialState;
		default:
			return state;
	}
}

export const getCurrentPageState = createFeatureSelector<ICurrentPageState>('currentPageData');

export const getCurrentPage = createSelector(getCurrentPageState, (state: ICurrentPageState) => state);

export const getCurrentPageLookup = createSelector(getCurrentPageState, (state: ICurrentPageState) => state.lookup);
