import { Component, Inject, Optional, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';

import { ContactType } from '@common/models/Contacts/Common/ContactType';
import { CustomFieldEntityType } from '@common/models/Settings/CustomFields/Common/CustomFieldEntityType';
import { ContactCustomFieldCreateDto } from '@common/models/Settings/CustomFields/Item/ContactCustomFieldCreateDto';
import { ContactCustomFieldViewDto } from '@common/models/Settings/CustomFields/Item/ContactCustomFieldViewDto';
import { NotificationService } from '@common/notification';
import { ContactCustomFieldsService } from '@common/services/customfields-contact.service';
import { CustomFieldsGroupService } from '@common/services/customfields-group.service';
import { CustomValidators } from '@common/validation/custom.validators';

import { BaseCustomFieldDialogComponent, ICreateCustomFieldData } from 'app/system/custom-fields/base-custom-field-dialog.component';

@Component({
	selector: 'contact-custom-field-dialog',
	styleUrls: ['../../base-custom-field-dialog.component.scss', './contact-custom-field-dialog.component.scss'],
	templateUrl: './contact-custom-field-dialog.component.html'
})
export class ContactCustomFieldDialogComponent extends BaseCustomFieldDialogComponent<
	ContactCustomFieldViewDto,
	ContactCustomFieldCreateDto,
	ContactCustomFieldDialogComponent
> {
	@ViewChild('selectContactType', { static: true })
	selectContactType: MatSelect;

	contactTypeList: string[] = Object.keys(ContactType);

	constructor(
		@Optional()
		@Inject(MAT_DIALOG_DATA)
		public data: ICreateCustomFieldData,
		private fb: FormBuilder,
		private service: ContactCustomFieldsService,
		notificationService: NotificationService,
		groupsService: CustomFieldsGroupService,
		dialogRef: MatDialogRef<ContactCustomFieldDialogComponent>,
		dialog: MatDialog
	) {
		super(data, CustomFieldEntityType.Contact, notificationService, groupsService, dialogRef, dialog, id =>
			this.service.getContactCustomField(id)
		);
	}

	allOptionSelected(event: MatOptionSelectionChange) {
		super.allOptionSelected(event, this.selectContactType);
	}

	optionSelected(event: MatOptionSelectionChange) {
		super.optionSelected(event, this.selectContactType);
	}

	ngOnInit() {
		this.form = this.fb.group({
			contactTypes: null,
			groupIds: [
				this.data.selectedGroupId ? [this.data.selectedGroupId] : null,
				CustomValidators.nonEmptyArray()
			],
			enabled: true,
			fieldType: [null, CustomValidators.required('Field Type')],
			mandatory: false,
			name: [
				null,
				[CustomValidators.required('Name'), CustomValidators.pattern(new RegExp('^[a-zA-Z][a-zA-Z0-9- ]*$'))]
			],
			description: null,
			helpText: null,
			expression: [null, CustomValidators.requiredWhen(() => !!this.showExpression, 'Expression')],
			defaultValue: [
				null,
				CustomValidators.validateWhen(
					() => !!this.selectionListArray.length,
					() => {
						const value = this.form.get('defaultValue')?.value;
						return !value?.length || this.selectionListArray.includes(value as string);
					},
					'Default Value must match what is contained within the contact selection list'
				)
			],
			maskingText: [null, CustomValidators.pattern(new RegExp('^(?=#*)[\\s#]+$'))]
		});

		super.ngOnInit();
	}

	protected saveChanges(value: ContactCustomFieldCreateDto) {
		if (!!value.contactTypes?.length) {
			value.contactTypes = value.contactTypes.filter(type => !!type);
		}

		super.saveChanges(value);
	}

	inputChanged(event: any) {
		const inputText = event.target.value;
		this.form.value.maskingText = inputText;
	}

	autocompleteSelected(event: MatAutocompleteSelectedEvent): void {
		this.form.value.maskingText = event.option.value;
	}
}
