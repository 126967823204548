import { HttpErrorResponse } from '@angular/common/http';
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';
import { FormBuilder, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';

import { never, Observable, of, Subject } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';

import { EntityReference } from '@common/models/Common/EntityReference';
import { IResultWithAttachments } from '@common/models/Common/IResultWithAttachments';
import { DocumentReference } from '@common/models/Documents/PersistenceLayer/DocumentReference';
import { MatterLookupDto } from '@common/models/Matters/Lookup/MatterLookupDto';
import { TrustAccountListItemDto } from '@common/models/Settings/TrustSettings/TrustAccounts/List/TrustAccountListItemDto';
import { TrustJournalCreateDto } from '@common/models/Trust/Item/TrustJournalCreateDto';
import { TrustJournalUpdateDto } from '@common/models/Trust/Item/TrustJournalUpdateDto';
import { TrustViewDto } from '@common/models/Trust/Item/TrustViewDto';
import { INotificationMessage } from '@common/notification';
import { MattersService } from '@common/services/matters.service';
import { TrustAccountsCachedService } from '@common/services/settings/trustaccounts-cached.service';
import { TrustAccountsService } from '@common/services/settings/trustaccounts.service';
import { TrustService } from '@common/services/trust.service';
import { CustomValidators } from '@common/validation/custom.validators';
import { Store } from '@ngrx/store';
import { get } from 'lodash';
import { isEmpty, isNil } from 'lodash-es';
import * as moment from 'moment-timezone';
import { isNumber } from 'util';

import { SecurityPermissionService } from 'app/core/security-permissions.service';
import { getCurrentPageLookup } from 'app/core/state/misc/current-page/current-page.reducer';
import { ICurrentPageState } from 'app/core/state/misc/current-page/current-page.state';
import { DocumentsService } from 'app/services/documents.service';
import { MatterLookupComponent } from 'app/shared/components/matter-lookup.component';
import { DownloadProgressDialogComponent } from 'app/shared/documents/list/download-dialog/download-progress-dialog.component';
import { IDownloadDialogData } from 'app/shared/documents/list/download-dialog/IDownloadDialogData';
import { IUploadMultipleFiles } from 'app/shared/multiple-file-uploader';
import { TransactionAmountPipe } from 'app/shared/pipes/transaction-amount.pipe';
import { IErrorContainer } from 'app/shared/utils/IErrorContainer';
import { IProgress } from 'app/shared/utils/IProgress';
import { round } from 'app/shared/utils/mathUtil';
import { handleHttpOperationError, handleHttpOperationProgress } from 'app/shared/utils/uploadResponseHandler';

import { BaseTrustRecordComponent } from '../base-trust-record.component';
import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';

@Component({
	selector: 'create-trust-journal',
	styleUrls: ['./create-trust-journal.component.scss'],
	templateUrl: './create-trust-journal.component.html'
})
export class CreateTrustJournalComponent
	extends BaseTrustRecordComponent<TrustJournalCreateDto>
	implements OnInit, AfterViewInit, OnDestroy
{
	@ViewChild('matterLookupFrom', { static: true })
	matterLookupFrom: MatterLookupComponent;
	@ViewChild('matterLookupTo', { static: true })
	matterLookupTo: MatterLookupComponent;
	matterFromClearedBalance: number;
	matterToClearedBalance: number;
	matterFromTotalBalance: number;
	matterToTotalBalance: number;
	errors: string[] = [];
	isViewMode: boolean;
	error: IErrorContainer = { message: '' };
	documentIdsToRemove: string[] = [];
	uploadFilesInfo: IUploadMultipleFiles = null;
	showProgress: boolean = false;
	progress: IProgress = { percentage: 0 };
	existingDocuments: DocumentReference[] = [];
	@Input()
	onSavingJournal: Subject<null>;
	@Output()
	saveCompleted: EventEmitter<INotificationMessage> = new EventEmitter<INotificationMessage>();
	downloadAfterSave: boolean = true;

	get hintFromText(): string {
		const pipe = new TransactionAmountPipe();
		const totalHint =
			this.matterFromTotalBalance !== undefined
				? `Total Balance : ${pipe.transform(round(this.matterFromTotalBalance))} `
				: '';
		const clearedHint =
			this.matterFromClearedBalance !== undefined
				? ` Cleared balance : ${
						this.matterFromClearedBalance > 0 ? pipe.transform(round(this.matterFromClearedBalance)) : 0
				  }`
				: '';

		return totalHint + clearedHint;
	}

	get hintToText(): string {
		const pipe = new TransactionAmountPipe();
		const totalHint =
			this.matterToTotalBalance !== undefined
				? `Total Balance : ${pipe.transform(round(this.matterToTotalBalance))} `
				: '';
		const clearedHint =
			this.matterToClearedBalance !== undefined
				? ` Cleared balance : ${
						this.matterToClearedBalance > 0 ? pipe.transform(round(this.matterToClearedBalance)) : 0
				  }`
				: '';

		return totalHint + clearedHint;
	}

	constructor(
		private fb: FormBuilder,
		private trustService: TrustService,
		private cdr: ChangeDetectorRef,
		private documentService: DocumentsService,
		private matterService: MattersService,
		private securityPermissionService: SecurityPermissionService,
		trustAccountService: TrustAccountsService,
		trustAccountsCachedService: TrustAccountsCachedService,
		private dialog: MatDialog,
		private store: Store<ICurrentPageState>
	) {
		super(trustAccountService, trustAccountsCachedService);
	}

	ngOnInit(): void {
		this.form = this.fb.group({
			amount: [
				null,
				[CustomValidators.required('Amount'), CustomValidators.positiveNumber(), this.balanceValidator]
			],
			date: [moment(), CustomValidators.required('Date')],
			description: [null, CustomValidators.required('Description')],
			matterFromId: [null, [CustomValidators.required('Matter from')]],
			matterToId: [null, CustomValidators.required('Matter to')],
			relevantContactId: [null, CustomValidators.required('Contact')],
			transactionType: this.transactionType,
			trustAccountId: [null, CustomValidators.required('Trust Account')]
		}) as FormGroupTyped<TrustJournalCreateDto>;

		super.ngOnInit();

		if (!!this.editId) {
			this.downloadAfterSave = false;
			this.subscription.add(
				this.trustService.getTrustRecord(this.editId).subscribe((response: TrustViewDto) => {
					this.form.patchValue(response);
					this.existingDocuments = response.existingDocuments;
				})
			);
		}

		this.form.setValidators(this.validateJournal);
		if (!!this.onSavingJournal) {
			this.subscription.add(this.onSavingJournal.subscribe(() => this.saveJournalDocuments()));
		}
	}

	ngAfterViewInit(): void {
		this.subscription.add(
			this.store
				.select(getCurrentPageLookup)
				.pipe(
					map(lookup => lookup as MatterLookupDto),
					filter(lookup => lookup.matterTrustBalance !== undefined)
				)
				.subscribe((dto: MatterLookupDto) => {
					if (dto) {
						this.matterLookupFrom.setValue(dto);
						this.matterLookupTo.inputCtrl.nativeElement.focus();

						if (dto.trustAccountId) {
							this.form.controls.trustAccountId.setValue(dto.trustAccountId);
						}

						if (dto.lawyer) {
							this.form.controls.relevantContactId.setValue(dto.lawyer.id);
						}
					} else {
						this.matterLookupFrom.inputCtrl.nativeElement.focus();
					}
				})
		);

		if (this.isReadOnly) this.form.disable();

		this.cdr.detectChanges();
	}

	onMatterFromSelected() {
		this.subscription.add(
			this.matterService.getMatterTrustBalance(this.matterLookupFrom.selectedValue.id).subscribe(x => {
				this.matterFromClearedBalance = x.clearedBalance;
				this.matterFromTotalBalance = x.totalBalance;
				this.form.controls.amount.updateValueAndValidity();
			})
		);
		this.subscription.add(
			this.matterService.getMatter(this.matterLookupFrom.selectedValue.id).subscribe(matter => {
				this.form.controls.relevantContactId.patchValue(matter.lawyer.id);
				this.form.controls.amount.updateValueAndValidity();
			})
		);
	}
	onMatterToSelected() {
		this.subscription.add(
			this.matterService.getMatterTrustBalance(this.matterLookupTo.selectedValue.id).subscribe(x => {
				this.matterToClearedBalance = x.clearedBalance;
				this.matterToTotalBalance = x.totalBalance;
				this.form.controls.matterFromId.updateValueAndValidity();
				this.form.controls.matterToId.updateValueAndValidity();
			})
		);
	}

	save(): Observable<MutationResponseDto> {
		const request = this.form.value;

		this.toggleFormBusy(true);
		return this.trustService.createJournal(request, this.uploadFilesInfo).pipe(
			switchMap((result: Partial<IResultWithAttachments>) => {
				return handleHttpOperationProgress<MutationResponseDto>(this, result, this.progress, this.handleCompleted);
			}),
			catchError((errorResponse: HttpErrorResponse) => {
				this.toggleFormBusy(false);
				return handleHttpOperationError(errorResponse, this.error);
			})
		);
	}

	validateJournal: ValidatorFn = (group: FormGroup): ValidationErrors => {
		{
			this.errors = [];

			if (get(this.matterLookupFrom, 'selectedValue.id') && get(this.matterLookupTo, 'selectedValue.id')) {
				if (this.matterLookupFrom.selectedValue.id === this.matterLookupTo.selectedValue.id) {
					const msg = `From matter and To matter need to be different`;
					this.errors.push(msg);
					this.form.controls.matterToId.setErrors({ error: msg });
				}
			}

			this.matterLookupValidation(this.matterLookupFrom, 'From');
			this.matterLookupValidation(this.matterLookupTo, 'To');
			return null;
		}
	};

	trustAccountChanged(event: MatOptionSelectionChange, item: TrustAccountListItemDto) {
		setTimeout(() => {
			if (!!this.form.controls.matterFromId) this.form.controls.matterFromId.updateValueAndValidity();
			if (!!this.form.controls.matterToId) this.form.controls.matterToId.updateValueAndValidity();
		}, 0);
		super.trustAccountChanged(event, item);
	}

	onErrorMessageUpdated(errorMessage: string) {
		this.error.message = errorMessage;
	}

	onDocumentIdsToRemoveUpdated(documentIdsToRemove: string[]) {
		this.documentIdsToRemove = documentIdsToRemove;
	}

	onUploadFilesInfoUpdated(uploadFilesInfo: IUploadMultipleFiles) {
		this.uploadFilesInfo = uploadFilesInfo;
	}

	saveJournalDocuments(): void {
		this.toggleFormBusy(true);
		const dto = this.getTrustJournalUpdateDto(this.form.value);
		let route = '/trust';
		if (!this.securityPermissionService.hasAccessToTrust) {
			route = '/dashboard';
		}
		const successNotification: INotificationMessage = {
			linkParams: { pageIndexForId: this.editId },
			linkRoute: [route],
			text: `Trust journal saved.`
		};

		// Check if there is any changes to attached files (for now upload is only about attachments)
		if (
			isEmpty(dto.documentIdsToRemove) &&
			(isNil(this.uploadFilesInfo) || isEmpty(this.uploadFilesInfo.nativeFiles))
		) {
			// close the dialog if there are no changes
			this.saveCompleted.emit(successNotification);
		} else {
			this.trustService
				.updateJournal(this.editId, dto, this.uploadFilesInfo)
				.pipe(
					switchMap((result: Partial<IResultWithAttachments>) =>
						handleHttpOperationProgress<EntityReference>(this, result, this.progress, this.handleCompleted)
					),
					catchError((errorResponse: HttpErrorResponse) => {
						this.toggleFormBusy(false);
						return handleHttpOperationError(errorResponse, this.error);
					})
				)
				.subscribe((res: EntityReference) => {
					if (res.id) {
						this.saveCompleted.emit(successNotification);
					}
					return null;
				});
		}
	}

	onSaveError() {
		if (this.allowCheckNumberEditing() && this.checkNumberIsPristine()) {
			this.populateNextNumberField();
		}
	}

	private toggleFormBusy(isBusy: boolean): void {
		if (isBusy) this.form.disable();
		else this.form.enable();

		// TODO think about this, solve it later
		// this.isValidChange.next(!isBusy);
		this.showProgress = isBusy;
	}

	// this method is passed to a utility and is called from there.
	// 		as a result, 'this' changes context so 'parentContext' is passed to fix it.
	private handleCompleted(
		parentContext: CreateTrustJournalComponent,
		result: Partial<IResultWithAttachments>
	): Observable<MutationResponseDto> {
		parentContext.toggleFormBusy(false);

		if (!!result.mutationResponse) {
			if (parentContext.downloadAfterSave) {
				parentContext.trustService
					.getTrustRecord(result.mutationResponse.id)
					.subscribe((record: TrustViewDto) => {
						const dialogData: Partial<IDownloadDialogData> = {
							downloadFn: (() => parentContext.documentService.downloadDocument(record.document.id)).bind(
								parentContext
							),
							downloadParam: [record.document.id],
							suppressNotification: true
						};
						return parentContext.dialog
							.open(DownloadProgressDialogComponent, { data: dialogData })
							.afterClosed();
					});
			}
			return of(result.mutationResponse);
		}

		parentContext.error.message = 'Could not save journal. Please try again.';
		return never();
	}

	private matterLookupValidation(lookupComponent: MatterLookupComponent, fromTo: string) {
		if (
			!!this &&
			!!this.form &&
			this.form.controls.trustAccountId.value &&
			get(lookupComponent, 'selectedValue.trustAccountId') &&
			get(lookupComponent, 'selectedValue.trustAccountId') !== this.form.controls.trustAccountId.value
		) {
			if (lookupComponent.selectedValue) {
				// tslint:disable-next-line:max-line-length
				const msg = `${fromTo} Matter '${lookupComponent.selectedValue.number}' is not associated to the selected trust account`;
				this.errors.push(msg);
				this.form.controls.matterFromId.setErrors({ error: msg });
			}
		}
	}

	private balanceValidator: ValidatorFn = (): ValidationErrors => {
		if (!this.form) return null;
		if (isNumber(this.form.controls.amount.value)) {
			const amount: number = round(this.form.controls.amount.value);
			if (amount > this.matterFromClearedBalance) {
				return {
					error: `The amount is more than the Cleared Funds available. Trust funds must be cleared prior to proceeding`
				};
			}
		}
		return null;
	};

	private getTrustJournalUpdateDto(dto: TrustJournalCreateDto): TrustJournalUpdateDto {
		const updateDto: TrustJournalUpdateDto = {
			attachments: dto.attachments,
			createCompanies: dto.createCompanies,
			createPersons: dto.createPersons,
			createPlurals: dto.createPlurals,
			date: dto.date,
			documentIdsToRemove: this.documentIdsToRemove,
			isCancelled: dto.isCancelled,
			isReversal: dto.isReversal,
			number: dto.number,
			relevantContactId: dto.relevantContactId,
			transactionType: dto.transactionType,
			trustAccountId: dto.trustAccountId,
			matterFromId: dto.matterFromId,
			matterToId: dto.matterToId
		};

		return updateDto;
	}
}
