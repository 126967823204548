// This file has been generated from BillStatusBulkUpdateDto.cs
// Do not modify this file directly!!
// tslint:disable

import { BillStatusUpdateDto } from './BillStatusUpdateDto';

// LawMaster.Amalthea.Domain.Bills.Item.BillStatusBulkUpdateDto
export class BillStatusBulkUpdateDto {

	// BillStatusUpdateDtos
	billStatusUpdateDtos: BillStatusUpdateDto[];

	constructor() {
		this.billStatusUpdateDtos = [];
	}
}