// This file has been generated from DocumentTemplatesController.cs
// Do not modify this file directly!!
// tslint:disable

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { TrimStringsOnObject } from '@common/utils/string-format';

import { MutationResponseDto } from '../../models/Common/MutationResponseDto';
import { DocumentMarginDetailsDto } from '../../models/Documents/Item/DocumentMarginDetailsDto';
import { DocumentTemplateCheckDto } from '../../models/Documents/Item/DocumentTemplateCheckDto';
import { DocumentTemplateUpdateDto } from '../../models/Documents/Item/DocumentTemplateUpdateDto';
import { DocumentTemplateViewDto } from '../../models/Documents/Item/DocumentTemplateViewDto';
import { DocumentListRequest } from '../../models/Documents/List/DocumentListRequest';
import { DocumentTemplateListItemDto } from '../../models/Documents/List/DocumentTemplateListItemDto';
import { TemplateFieldDto } from '../../models/Documents/TemplateDto/TemplateFieldDto';
import { TrustTemplateRequestDto } from '../../models/Documents/TemplateDto/TrustTemplateRequestDto';
import { ListResponse } from '../../models/Generic/ListResponse';
import { DocumentTemplateRequest } from '../../models/RequestParameters/DocumentTemplateRequest';

@Injectable({
	providedIn: 'root'
})
export class DocumentTemplatesService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'api/v1/settings/DocumentTemplates', 'Document Template');
	}

	getDocumentTemplate(id: string): Observable<DocumentTemplateViewDto> {
		return this.getItem<void, DocumentTemplateViewDto>(`${id}`);
	}

	getDocumentTemplateList(request?: Partial<DocumentListRequest>): Observable<ListResponse<DocumentTemplateListItemDto>> {
		return this.getList<DocumentListRequest, DocumentTemplateListItemDto>('', request);
	}

	addTemplate(request: DocumentTemplateRequest): Observable<MutationResponseDto> {
		return this.post<DocumentTemplateRequest, MutationResponseDto>('', request);
	}

	updateTemplate(id: string, dto: DocumentTemplateUpdateDto): Observable<MutationResponseDto> {
		return this.put(`${id}`, TransformDatesOnObject(DocumentTemplateUpdateDto, TrimStringsOnObject(dto)));
	}

	getContactFields(): Observable<TemplateFieldDto[]> {
		return this.getItem<void, TemplateFieldDto[]>(`fields/contact/`);
	}

	getMatterFields(): Observable<TemplateFieldDto[]> {
		return this.getItem<void, TemplateFieldDto[]>(`fields/matter/`);
	}

	getInvoiceFields(): Observable<TemplateFieldDto[]> {
		return this.getItem<void, TemplateFieldDto[]>(`fields/invoice/`);
	}

	getContactFieldValues(id: string): Observable<TemplateFieldDto[]> {
		return this.getItem<void, TemplateFieldDto[]>(`fields/contact/${id}`);
	}

	getMatterFieldValues(id: string): Observable<TemplateFieldDto[]> {
		return this.getItem<void, TemplateFieldDto[]>(`fields/matter/${id}`);
	}

	getInvoiceFieldValues(number: string): Observable<TemplateFieldDto[]> {
		return this.getItem<void, TemplateFieldDto[]>(`fields/invoice/${number}`);
	}

	getTrustRecordFields(dto: TrustTemplateRequestDto): Observable<TemplateFieldDto[]> {
		return this.getItem<TrustTemplateRequestDto, TemplateFieldDto[]>(`GetTrustRecordFields/fields/trust`, dto);
	}

	getTrustRecordFieldValues(dto: TrustTemplateRequestDto): Observable<TemplateFieldDto[]> {
		return this.getItem<TrustTemplateRequestDto, TemplateFieldDto[]>(`GetTrustRecordFieldValues/fields/trust`, dto);
	}

	getMatterTrustStatementFields(): Observable<TemplateFieldDto[]> {
		return this.getItem<void, TemplateFieldDto[]>(`fields/matter/summary`);
	}

	getMatterTrustStatementFieldValues(matterId: string): Observable<TemplateFieldDto[]> {
		return this.getItem<void, TemplateFieldDto[]>(`fields/matter/summary/${matterId}`);
	}

	getMatterFinancialStatementFields(): Observable<TemplateFieldDto[]> {
		return this.getItem<void, TemplateFieldDto[]>(`fields/matter/financialstatement`);
	}

	getMatterFinancialStatementFieldValues(matterId: string): Observable<TemplateFieldDto[]> {
		return this.getItem<void, TemplateFieldDto[]>(`fields/matter/financialstatement/${matterId}`);
	}

	getBriefFields(): Observable<TemplateFieldDto[]> {
		return this.getItem<void, TemplateFieldDto[]>(`fields/brief/`);
	}

	getBriefFieldValues(id: string): Observable<TemplateFieldDto[]> {
		return this.getItem<void, TemplateFieldDto[]>(`fields/brief/${id}`);
	}

	hasMarginConflict(dto: DocumentTemplateCheckDto): Observable<DocumentMarginDetailsDto[]> {
		return this.getItem<DocumentTemplateCheckDto, DocumentMarginDetailsDto[]>(`HasMarginConflict`, dto);
	}

}
