import { ComponentType } from '@angular/cdk/portal';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { filter } from 'rxjs/operators';

import { FilterRootComponent } from './filter-root.component';

@Component({
	selector: 'filter-mobile-span',
	styleUrls: ['./filter-mobile-span.component.scss'],
	template: `
		<div>
			<mat-divider *ngIf="!isTabbedFilter"></mat-divider>
			<button
				[class.add-margin]="!isTabbedFilter"
				mat-icon-button
				throttleButton
				(throttledClick)="showMobileFilterDialog()"
			>
				<mat-icon>filter_list</mat-icon>
			</button>
		</div>
		<mat-divider *ngIf="!isTabbedFilter"></mat-divider>
	`
})
export class FilterMobileSpanComponent<T, TFilter> {
	@Input()
	filterRoot: FilterRootComponent<TFilter>;
	// The initial setup for the filters.
	// In some cases it'd be just an instance of TFilter, in others a dedicated class with ore parameters
	@Input()
	filterParams: any;
	@Input()
	filterComponent: ComponentType<T>;
	@Input()
	isTabbedFilter: boolean;

	constructor(private dialog: MatDialog) {}

	showMobileFilterDialog(): void {
		this.dialog
			.open(this.filterComponent, {
				data: this.filterParams,
				height: '100%',
				maxWidth: '100%',
				width: '100%'
			})
			.afterClosed()
			.pipe(filter(Boolean))
			.subscribe((result: Partial<TFilter>) => {
				this.filterRoot.resetFilter(result);
			});
	}
}
