<h2 mat-dialog-title class="title">{{ !isEdit ? 'Create' : 'Edit' }} Email Template</h2>

<mat-dialog-content>
	<multi-file-attachment
		[maxNumberOfFiles]="10"
		[isEditable]="true"
		[showProgress]="showProgress"
		[existingDocuments]="existingDocuments"
		(errorMessageUpdated)="onErrorMessageUpdated($event)"
		(documentIdsToRemoveUpdated)="onDocumentIdsToRemoveUpdated($event)"
		(uploadFilesInfoUpdated)="onUploadFilesInfoUpdated($event)"
	>
		<div class="flex-column" [formGroup]="form">
			<div class="flex-row">
				<mat-form-field>
					<mat-placeholder>Title <sup class="color-warn">*</sup> </mat-placeholder>
					<input matInput formControlName="title" autocomplete="off" />
					<mat-error> <error-messages [for]="form.get('title')"></error-messages> </mat-error>
				</mat-form-field>
			</div>
			<div class="flex-row">
				<mat-form-field>
					<mat-placeholder>Related To <sup class="color-warn">*</sup> </mat-placeholder>
					<mat-select formControlName="entityType" (selectionChange)="entityTypeChanged($event)">
						<mat-option *ngFor="let key of entityTypeKeys" [value]="key">{{ key | templateEntityType }}</mat-option>
					</mat-select>
					<mat-error> <error-messages [for]="form.get('entityType')"></error-messages> </mat-error>
				</mat-form-field>
				<mat-form-field *ngIf="form.get('entityType').value === 'Matter'">
					<mat-placeholder>Practice Areas</mat-placeholder>
					<mat-select #selectPracticeAreas formControlName="associatedPracticeAreaIds" multiple
						><mat-select-trigger>{{ displaySelected(selectPracticeAreas.value) }}</mat-select-trigger>
						<mat-option (onSelectionChange)="allOptionSelected($event)" #allOption>All</mat-option>
						<mat-option (onSelectionChange)="optionSelected($event)" *ngFor="let practiceArea of practiceAreas" [value]="practiceArea.id">
							<div class="c-practice-area-option">
								<span class="c-practice-area-option__title">{{ practiceArea.name }}</span>
								<span class="c-practice-area-option__disabled" *ngIf="!!practiceArea.isDisabled">Disabled</span>
							</div>
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="flex-row">
				<mat-form-field>
					<mat-select placeholder="Group" formControlName="documentCategoryId">
						<mat-option></mat-option>
						<mat-option *ngFor="let category of documentCategories" [value]="category.id">{{ category.name }}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<document-tag
				*ngIf="form.get('documentTags')"
				[ActiveTags]="form.get('documentTags').value"
				[FormControl]="form.get('documentTags')"
			></document-tag>
			<div class="flex-row">
				<mat-form-field>
					<mat-placeholder>Subject</mat-placeholder>
					<input matInput formControlName="subject" autocomplete="off" />
					<mat-error> <error-messages [for]="form.get('subject')"></error-messages> </mat-error>
					<mat-hint>Is applied to the subject of a new email</mat-hint>
				</mat-form-field>
			</div>
			<div class="flex-row">
				<div class="c-form-field">
					<mat-label>Content</mat-label>
					<dx-html-editor formControlName="content" *ngIf="!showMarkup" height="447px">
						<dxo-toolbar [multiline]="true">
							<dxi-item name="undo"></dxi-item>
							<dxi-item name="redo"></dxi-item>
							<dxi-item name="separator"></dxi-item>
							<dxi-item name="size" [acceptedValues]="['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt']"></dxi-item>
							<dxi-item
								name="font"
								[acceptedValues]="[
									'Arial',
									'Roboto',
									'Courier New',
									'Georgia',
									'Impact',
									'Lucida Console',
									'Tahoma',
									'Times New Roman',
									'Verdana'
								]"
							></dxi-item>
							<dxi-item name="separator"></dxi-item>
							<dxi-item name="bold"></dxi-item>
							<dxi-item name="italic"></dxi-item>
							<dxi-item name="strike"></dxi-item>
							<dxi-item name="underline"></dxi-item>
							<dxi-item name="separator"></dxi-item>
							<dxi-item name="alignLeft"></dxi-item>
							<dxi-item name="alignCenter"></dxi-item>
							<dxi-item name="alignRight"></dxi-item>
							<dxi-item name="alignJustify"></dxi-item>
							<dxi-item name="separator"></dxi-item>
							<dxi-item name="orderedList"></dxi-item>
							<dxi-item name="bulletList"></dxi-item>
							<dxi-item name="separator"></dxi-item>
							<dxi-item name="header" [acceptedValues]="[false, 1, 2, 3, 4, 5]"></dxi-item>
							<dxi-item name="separator"></dxi-item>
							<dxi-item name="color"></dxi-item>
							<dxi-item name="background"></dxi-item>
							<dxi-item name="separator"></dxi-item>
							<dxi-item name="link"></dxi-item>
							<dxi-item name="image"></dxi-item>
							<dxi-item name="separator"></dxi-item>
							<dxi-item name="clear"></dxi-item>
							<dxi-item name="codeBlock"></dxi-item>
							<dxi-item name="blockquote"></dxi-item>
							<dxi-item name="separator"></dxi-item>
							<dxi-item name="insertTable"></dxi-item>
							<dxi-item name="deleteTable"></dxi-item>
							<dxi-item name="insertRowAbove"></dxi-item>
							<dxi-item name="insertRowBelow"></dxi-item>
							<dxi-item name="deleteRow"></dxi-item>
							<dxi-item name="insertColumnLeft"></dxi-item>
							<dxi-item name="insertColumnRight"></dxi-item>
							<dxi-item name="deleteColumn"></dxi-item>
							<dxi-item name="separator"></dxi-item>
							<dxi-item widget="dxButton" [options]="codeBehindButtonOptions"></dxi-item>
						</dxo-toolbar>
						<dxo-media-resizing [enabled]="true"> </dxo-media-resizing>
					</dx-html-editor>
					<div class="c-dx-text-editor" *ngIf="!!showMarkup">
						<div class="c-dx-text-editor__toolbar">
							<dx-button (onClick)="toggleHtmlEditor()">Show Editor</dx-button>
						</div>
						<dx-text-area formControlName="content" height="400px"></dx-text-area>
					</div>
					<mat-error>
						<error-messages [for]="form.get('content')"></error-messages>
					</mat-error>
				</div>
			</div>
		</div>

		<div class="c-list flex-column">
			<div class="c-list__header">
				<h3 class="c-list__title">Document Templates</h3>
				<button class="c-list__button" mat-button color="primary" throttleButton (throttledClick)="onNewTemplateClick()">
					<mat-icon>add_circle</mat-icon>
					<span>&nbsp;New <span class="allocation-word">template</span></span>
				</button>
			</div>

			<div class="c-list__item" *ngFor="let documentTemplateControl of documentTemplateControls; let i = index">
				<div class="flex-row">
					<document-template-lookup
						#documentTemplateControl
						[Required]="true"
						Placeholder="Associated Document"
						[FormControl]="documentTemplateControl"
						[practiceAreaIds]="associatedPracticeAreaIds"
						[entityType]="templateEntityType"
						(Selected)="onDocumentTemplateSelected(documentTemplateControl, $event)"
						class="field-wide"
					></document-template-lookup>

					<button
						mat-icon-button
						title="Remove"
						class="c-list__delete-button c-list__delete-button--full"
						throttleButton
						(throttledClick)="onRemoveTemplateClick(i)"
					>
						<mat-icon>delete</mat-icon>
					</button>
				</div>

				<div class="flex-row flex-row--mobile">
					<button
						mat-raised-button
						title="Remove"
						class="c-list__delete-button c-list__delete-button--mobile"
						throttleButton
						(throttledClick)="onRemoveTemplateClick(i)"
					>
						<mat-icon>delete</mat-icon>
						<span>Remove</span>
					</button>
				</div>
			</div>
		</div>

		<h3>Document Attachments</h3>
		<div *ngIf="!!showProgress">
			<span>{{ editId ? 'Updating' : 'Creating' }} Email Template, please wait...</span><br />
			<mat-progress-bar [value]="progressPercentage"> </mat-progress-bar>
		</div>
	</multi-file-attachment>

	<div class="upload-error" *ngIf="!!errorMessage"><mat-icon>cancel</mat-icon>&nbsp;<span class="err-text-container" [innerHTML]="errorMessage"></span></div>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-raised-button color="primary" [disabled]="!form.valid" throttleButton (throttledClick)="save()">Save</button>
	<button mat-raised-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
