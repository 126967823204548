import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TenantInformationDto } from '@common/models/Settings/Setting/Item/TenantInformationDto';
import { GeneralSettingsService } from '@common/services/settings/generalsettings.service';
import { ITenantData } from '@common/state/models/tenant-data';
import { Store } from '@ngrx/store';
import { FeatureFlags, isFeatureFlagEnabled } from 'app/app.config';
import { AppBrandingService } from 'app/services/app-branding.service';
import { Subject, Subscription } from 'rxjs';
import { concatMap, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';

/* Footer displayed on each page */
@Component({
	selector: 'app-footer',
	styleUrls: ['./footer.component.scss'],
	templateUrl: 'footer.component.html'
})
export class FooterComponent implements OnInit, OnDestroy {
	private subscriptions = new Subscription();
	hasRequestCompleted: boolean = false;
	featureFlags: typeof FeatureFlags = FeatureFlags;
	isTrialExpired: boolean = false;

	message: string;
	private customMessage: string;
	private showLicensing: boolean;
	private isSmall: boolean;

	constructor(
		private store: Store<{ tenantData: ITenantData }>,
		private settingsService: GeneralSettingsService,
		private breakpoint: BreakpointObserver
	) {}

	ngOnInit(): void {
		this.subscriptions.add(
			this.breakpoint.observe([Breakpoints.Small, Breakpoints.XSmall]).subscribe(state => {
				this.isSmall = state.matches;
			})
		);
		this.subscriptions.add(
			this.store
				.select(state => state.tenantData?.tenantInformation)
				.pipe(
					tap(info => {
						this.showLicensing = !info?.ignoreLicensing;

						if (!!info?.tenantMessaging && info.tenantMessaging.enableFooterMessage) {
							this.customMessage = info.tenantMessaging.footerMessage;
						}
					}),
					concatMap(() => this.settingsService.getLicensingInfo())
				)
				.subscribe(info => {
					this.hasRequestCompleted = true;
					if (!this.customMessage) {
						if (!info.isTenantSubscribed) {
							this.isTrialExpired = !info.isTenantSubscribed && !!info.hasTrialExpired;
							if (!!info.hasTrialExpired) {
								this.message = this.isSmall
									? 'Trial expired. Please&nbsp; <a href="/system/subscription"> subscribe </a>&nbsp;'
									: `Your trial has expired. Please&nbsp;<a href="/system/subscription"> subscribe </a>&nbsp;to continue using ${AppBrandingService.getSiteName()}`;
							} else {
								this.message = this.isSmall
									? `Trial version of ${AppBrandingService.getSiteName()}`
									: `You are running a trial version of ${AppBrandingService.getSiteName()}. Please&nbsp;<a href="/system/subscription"> subscribe </a>&nbsp;to continue using ${AppBrandingService.getSiteName()}`;
							}
						} else if (info.isTenantSubscribed && !info.isUserLicensed) {
							this.message = this.isSmall
								? 'License not allocated'
								: `A license hasn't been allocated to you. Please allocate a license from the &nbsp;<a href="/system/users"> user accounts </a>&nbsp;screen`;
						} else if (info.isTenantSubscribed && info.isUserLicensed && info.hasLicenseLimitBreached) {
							this.message = this.isSmall
								? ''
								: `The number of allocated licenses is more than the available licenses. Please &nbsp;<a href="/system/subscription"> manage your subscription </a>&nbsp;to increase your number of licenses`;
						}
					}
				})
		);
	}

	get canShowMessage() {
		return !!this.showLicensing && !!this.message;
	}
	get canShowCustomMessage() {
		return !!this.customMessage && !this.isSmall;
	}

	get isLicensingEnabled() {
		return isFeatureFlagEnabled(this.featureFlags.licensing) && !!this.showLicensing;
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
}
