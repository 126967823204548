// This file has been generated from CompanyContactCreateUpdateDto.cs
// Do not modify this file directly!!
// tslint:disable

import { SetComplexType, SetSimpleType } from '@common/utils/class-transform';

import { Address } from '../Common/Address';
import { CollectionPrimary } from './CollectionPrimary';
import { PhoneCreateUpdateDto } from './PhoneCreateUpdateDto';

// LawMaster.Amalthea.Domain.Contacts.Item.CompanyContactCreateUpdateDto
export class CompanyContactCreateUpdateDto {

	// FullName
	fullName: string;
	// Addresses
	@SetComplexType(CollectionPrimary)
	addresses: CollectionPrimary<Address>;
	// Phones
	@SetComplexType(CollectionPrimary)
	phones: CollectionPrimary<PhoneCreateUpdateDto>;
	// Emails
	emails: CollectionPrimary<string>;
	// Website
	website: string;
	// Notes
	notes: string;
	// CustomFields
	customFields: { [key: string]: any; };
	// Abn
	abn: string;
	// IgnoreCustomFields
	@SetSimpleType(Boolean)
	ignoreCustomFields: boolean;
	// TenantRegionCode
	tenantRegionCode: string;
	// Acn
	acn: string;

	constructor() {
		this.addresses = new CollectionPrimary<Address>();
		this.phones = new CollectionPrimary<PhoneCreateUpdateDto>();
		this.emails = new CollectionPrimary<string>();
	}
}