<h2 mat-dialog-title class="title">{{ isCopy ? 'Duplicate Document' : 'Document Details' }}</h2>

<mat-dialog-content>
	<div class="flex-column" [formGroup]="form">
		<div class="flex-row">
			<mat-form-field>
				<mat-placeholder>Title <sup class="color-warn">*</sup> </mat-placeholder>
				<input matInput formControlName="title" />
				<mat-error> <error-messages [for]="form.get('title')"></error-messages> </mat-error>
			</mat-form-field>

			<mat-form-field>
				<input formControlName="createdDate" matInput [matDatepicker]="createdDatePicker" placeholder="Created Date" autocomplete="off" />
				<mat-datepicker-toggle matSuffix [for]="createdDatePicker"></mat-datepicker-toggle>
				<mat-datepicker #createdDatePicker></mat-datepicker>
				<mat-error>
					<error-messages [for]="form.get('createdDate')"></error-messages>
				</mat-error>
			</mat-form-field>
		</div>
		<div class="flex-row">
			<mat-form-field class="related-to-type">
				<mat-select [(value)]="associatedEntityType" placeholder="Related To" (selectionChange)="associatedEntityTypeChange()">
					<mat-option *ngFor="let type of associatedEntityTypes" [value]="type">{{ type }}</mat-option>
				</mat-select>
			</mat-form-field>
			<matter-lookup
				*ngIf="associatedEntityType == 'Matter'"
				[FormControl]="form.get('associatedMatterId')"
				Hint="Enter a matter number or title"
				Status="Open"
				[Required]="true"
			></matter-lookup>
			<contact-lookup
				*ngIf="associatedEntityType == 'Contact'"
				[FormControl]="form.get('associatedContactId')"
				(Selected)="onContactSelected($event)"
				Hint="Enter a contact name"
				[Required]="true"
			></contact-lookup>
		</div>
		<document-tag *ngIf="form.get('documentTags')" [FormControl]="form.get('documentTags')"></document-tag>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-raised-button color="primary" [disabled]="!form.valid || isSaving" throttleButton (throttledClick)="save()">Save</button>
	<button mat-raised-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
