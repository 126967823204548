// This file has been generated from DocumentTemplateMergeTaskTemplatesDto.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.Domain.Tasks.Item.DocumentTemplateMergeTaskTemplatesDto
export class DocumentTemplateMergeTaskTemplatesDto {

	// DocumentTemplateIds
	documentTemplateIds: string[];

	constructor() {

	}
}