<div class="drop-container" [class.drag-border]="dragOver" [fileDrop]="enableDragDrop" (filesDragged)="onDragEvent($event)" (drop)="processDrop($event)">
	<div class="filter-row">
		<ng-container *tenantFeature="featureTypes.Briefs">
			<div>
				<div class="hiddenPhone brief-toggle" *ngIf="!isGlobal && briefs?.length > 0">
					<mat-button-toggle-group name="viewMode" [value]="viewMode" appearance="legacy">
						<mat-button-toggle value="document" (change)="setBriefMode($event)"
							><mat-icon class="docs-icon">description</mat-icon></mat-button-toggle
						>
						<mat-button-toggle value="brief" (change)="setBriefMode($event)"><mat-icon class="brief-icon">work</mat-icon></mat-button-toggle>
					</mat-button-toggle-group>
				</div>

				<div *ngIf="!isGlobal && briefs?.length > 0" class="hiddenPhone toggle-desc">
					<span class="toggle-label toggle-document" *ngIf="viewMode === 'document'">Documents Mode</span>
					<span class="toggle-label toggle-brief" *ngIf="viewMode === 'brief'">Briefs Mode</span>
				</div>
			</div>
		</ng-container>

		<ng-content></ng-content>

		<input type="file" multiple #fileUpload onclick="this.value = null" (change)="fileChange($event)" />
		<input type="file" webkitdirectory #folderUpload onclick="this.value = null" (change)="fileChange($event)" />
		<div *ngIf="!!isUploadVisible">
			<button class="new-button" color="primary" mat-button [matMenuTriggerFor]="newMenu" [disabled]="!!isReadonly">
				<mat-icon color="primary">add_circle</mat-icon>
				<span>New</span>
			</button>
			<mat-menu #newMenu="matMenu" [overlapTrigger]="false" xPosition="before">
				<button mat-menu-item throttleButton (throttledClick)="fileUpload.click()" [disabled]="!!isReadonly">
					<mat-icon>file_upload</mat-icon>
					<span>File Upload</span><span *ngIf="briefId">&nbsp;to Brief</span>
				</button>
				<button mat-menu-item throttleButton (throttledClick)="folderUpload.click()" [disabled]="!!isReadonly">
					<mat-icon><span class="material-icons-outlined"> drive_folder_upload </span></mat-icon>
					<span>Folder Upload</span><span *ngIf="briefId">&nbsp;to Brief</span>
				</button>
				<button *ngIf="!briefId && !!matterId" mat-menu-item [routerLink]="['../merge']">
					<mat-icon>how_to_vote</mat-icon>
					<span>Document From Template</span>
				</button>
				<button
					*ngIf="!briefId && !matterId"
					mat-menu-item
					throttleButton
					(throttledClick)="showDocumentFromTemplateDialog()"
					[disabled]="!!isReadonly"
				>
					<mat-icon>how_to_vote</mat-icon>
					<span>Document From Template</span>
				</button>
				<ng-container *tenantFeature="featureTypes.Briefs">
					<button
						*ngIf="matterId"
						class="hiddenPhone"
						mat-menu-item
						throttleButton
						(throttledClick)="createDocumentBrief()"
						[disabled]="!!isReadonly"
					>
						<mat-icon>work</mat-icon>
						<span>New Brief</span>
					</button>
				</ng-container>
				<button *ngIf="!briefId" mat-menu-item throttleButton (throttledClick)="openCreateBlankDocumentDialog()" [disabled]="!!isReadonly">
					<mat-icon>insert_drive_file</mat-icon>
					<span>Create Blank Document</span>
				</button>
			</mat-menu>
			<bulk-actions-menu [isGlobalContext]="false" [menuLabel]="'Actions'" [iconColor]="'#707070'" #bulkActions>
				<button *ngIf="briefId" mat-menu-item throttleButton (throttledClick)="openDocumentListDialog()" [disabled]="!!isReadonly">
					<mat-icon>create_new_folder</mat-icon>
					<span>Add Existing Documents to Brief</span>
				</button>
				<ng-container *tenantFeature="featureTypes.Briefs">
					<button
						*ngIf="!briefId && briefs?.length > 0"
						[disabled]="listItems?.length <= 0 || !!isReadonly"
						mat-menu-item
						throttleButton
						(throttledClick)="addToExistingBrief()"
					>
						<mat-icon>create_new_folder</mat-icon>
						<span>Add to Existing Brief</span>
					</button>
				</ng-container>

				<a *ngIf="briefId" mat-menu-item target="_blank" [routerLink]="['/portal/brief/preview', matterNumber, briefId]"
					><mat-icon title="preview Brief" color="primary" class="view-brief-icon">visibility</mat-icon>
					<span>Preview Brief</span>
				</a>

				<button mat-menu-item throttleButton (throttledClick)="openBriefAccessDialog()" *ngIf="!!briefId">
					<mat-icon>share</mat-icon>
					<span>Share Brief</span>
				</button>
				<button mat-menu-item throttleButton (throttledClick)="openPDFExportDialog()" *ngIf="showExportToPdf">
					<mat-icon svgIcon="file-pdf"></mat-icon>
					<span>Export Brief to PDF</span>
				</button>

				<button mat-menu-item throttleButton (throttledClick)="openCsvExportDialogue()">
					<mat-icon>file_download</mat-icon>
					<span>Export Document List</span>
				</button>

				<button
					*ngIf="listItems?.length > 0"
					mat-menu-item
					[disabled]="(selected$ | async)?.length == 0"
					throttleButton
					(throttledClick)="downloadSelectedFiles()"
				>
					<mat-icon class="menu-icon">cloud_download</mat-icon>
					<span>Download Selected</span>
				</button>

				<div
					[title]="
						(selected$ | async)?.length == 0 || !isOutlookActive
							? 'Open the Outlook add-in to enable this button'
							: 'Ensure the Outlook Add-in is open and pinned'
					"
				>
					<button
						*ngIf="listItems?.length > 0"
						mat-menu-item
						[disabled]="(selected$ | async)?.length == 0 || !isOutlookActive"
						throttleButton
						(throttledClick)="emailMultipleDocuments()"
					>
						<mat-icon class="menu-icon">attach_email</mat-icon>
						<span>Email Documents</span>
					</button>
				</div>
				<button mat-menu-item throttleButton (throttledClick)="removeSelectedDocumentsFromBrief()" *ngIf="!!briefId" [disabled]="!!isReadonly">
					<mat-icon>remove_circle</mat-icon>
					<span>Remove Documents from Brief</span>
				</button>
				<button mat-menu-item [matMenuTriggerFor]="allSectionsMenu" *ngIf="!!briefId" [disabled]="!!isReadonly">
					<mat-icon>drive_file_move</mat-icon>
					<span>Move to Section</span>
				</button>
				<mat-menu #allSectionsMenu="matMenu">
					<div *ngFor="let section of brief?.sections">
						<ng-container>
							<button mat-menu-item throttleButton (throttledClick)="bulkMoveDocumentsToSection(section)">
								<mat-icon>drive_file_move</mat-icon> <span>{{ section.name }}</span>
							</button>
						</ng-container>
					</div>
				</mat-menu>
				<button mat-menu-item throttleButton (throttledClick)="deleteBrief()" *ngIf="!!briefId" [disabled]="!!isReadonly">
					<mat-icon>delete</mat-icon>
					<span>Delete Brief</span>
				</button>
				<button
					*tenantFeature="featureTypes.Referrals"
					mat-menu-item
					[disabled]="!(selected$ | async)?.length || !!isReadonly"
					throttleButton
					(throttledClick)="bulkReferDocument()"
				>
					<mat-icon>reply</mat-icon>
					<span>Refer Documents</span>
				</button>
				<button mat-menu-item [disabled]="!(selected$ | async)?.length || !!isReadonly" throttleButton (throttledClick)="bulkTagDocuments()">
					<mat-icon>label</mat-icon>
					<span>{{ (selected$ | async)?.length === 1 ? 'Tag Document' : 'Tag Documents' }}</span>
				</button>

				<button mat-menu-item *ngIf="!briefId" throttleButton (throttledClick)="storeFilterDefaults()">
					<mat-icon>save</mat-icon>
					<span>Save Layout</span>
				</button>
				<button mat-menu-item *ngIf="!briefId" throttleButton (throttledClick)="resetFilterDefaults()">
					<mat-icon>restart_alt</mat-icon>
					<span>Reset Layout</span>
				</button>
			</bulk-actions-menu>
		</div>
	</div>

	<div class="flexrow bottom-margin-xsmall-screen bottom-container">
		<div class="tags-column" *ngIf="!selectedDocumentId && !isXSmall">
			<div class="tags-column-content" [@slideInOut]="animationState">
				<div
					class="left-panel"
					*ngIf="!briefId && !selectedDocumentId"
					[ngClass]="{ 'show-tags': filteredDocumentTags?.length > 0 || form.get('searchTags').value }"
				>
					<div class="panel-header">
						<span>Filter</span>
						<span class="clear-all" throttleButton (throttledClick)="clearAllTags()"><mat-icon>refresh</mat-icon><a>clear all</a></span>
					</div>

					<mat-chip-list>
						<mat-chip *ngFor="let chipFilter of chipFilters" [selectable]="true" [removable]="true" (removed)="popChip(chipFilter)">
							<small>{{ chipFilter.title }}</small>
							<button matChipRemove>
								<mat-icon>cancel</mat-icon>
							</button>
						</mat-chip>
					</mat-chip-list>

					<button mat-button *ngIf="!isGlobal" class="favorites-button" throttleButton (throttledClick)="toggleFavorites()">
						<mat-icon *ngIf="!isFavorited">star_outline</mat-icon>
						<mat-icon *ngIf="!!isFavorited" class="mat-icon-accent">star</mat-icon>
						<span>Show Favourites</span>
					</button>

					<button mat-button *tenantFeature="'DocumentSharing'" class="shared-button" throttleButton (throttledClick)="toggleShared()">
						<mat-icon *ngIf="!isShared" fontSet="material-icons-outlined">shared</mat-icon>
						<mat-icon *ngIf="!!isShared" class="mat-icon-accent">shared</mat-icon>
						<span>Show Shared</span>
					</button>

					<mat-form-field class="search-tags">
						<input [formControl]="searchTagsControl" matInput autocomplete="off" />
						<mat-placeholder>Search Tags </mat-placeholder>
					</mat-form-field>

					<mat-radio-group class="tag-form" aria-label="Match Tags" value="matchAny" (change)="toggleChanged($event)">
						<mat-radio-button value="matchAny" size="small" class="match-any">Any</mat-radio-button>
						<mat-radio-button value="matchAll" size="small" class="match-all">All</mat-radio-button>
					</mat-radio-group>
					<mat-hint class="tag-hint">
						<span *ngIf="!isMatchAll">Documents with <b>any</b> selected tags</span>
						<span *ngIf="isMatchAll">Documents with <b>all</b> selected tags</span>
					</mat-hint>

					<form [formGroup]="documentTagsGroup" class="tags-container">
						<div *ngFor="let tag of filteredDocumentTags">
							<div class="tagicon-label" [ngClass]="{ 'selected-item': isSelected(tag) }" throttleButton (throttledClick)="tagClicked(tag)">
								<mat-icon class="tag-icon" [ngClass]="{ 'material-icons-outlined': !isSelected(tag) }">label</mat-icon>
								<span>{{ tag }}</span>
							</div>
						</div>
					</form>
				</div>
				<div [formGroup]="form" class="left-panel" *ngIf="briefId">
					<div class="brief-row">
						<p class="tagicon-label" [ngClass]="{ 'selected-item': sectionId === null }" throttleButton (throttledClick)="sectionClicked(null)">
							<mat-icon>work</mat-icon><span class="brief-name">{{ brief?.name }}</span>
						</p>
						<mat-select class="brief-dropdown" (selectionChange)="briefSelected($event)">
							<mat-option *ngFor="let brief of briefs" [value]="brief">{{ brief.name }}</mat-option>
						</mat-select>
						<a class="edit-brief-button" throttleButton (throttledClick)="editBrief()"><mat-icon>edit</mat-icon></a>
					</div>
					<ul class="tree">
						<li
							*ngFor="let section of brief?.sections; first as isFirst"
							[ngClass]="{
								'selected-item': section?.id === sectionId,
								section: !isFirst
							}"
							throttleButton
							(throttledClick)="sectionClicked(section.id)"
						>
							<mat-icon [ngClass]="{ 'material-icons-outlined': section?.id !== sectionId }">{{
								section?.id === sectionId ? 'folder' : 'folder_open'
							}}</mat-icon>
							{{ section?.name.toLowerCase() !== 'uncategorised' ? section?.orderNumber + '.' : '' }}
							{{ section?.name }}
						</li>
						<li throttleButton (throttledClick)="editBrief(true)" class="section" class="new-section-item">
							<mat-icon class="new-section-icon">add</mat-icon><span>new section</span>
						</li>
					</ul>
				</div>
				<div [@slideInOut]="animationState" class="bullet-container">
					<bullet-button
						[tooltipMessage]="animationState === 'in' ? 'Hide Filters' : 'Show Filters'"
						[tooltipPosition]="animationState === 'in' ? 'left' : 'right'"
						[arrowState]="animationState === 'in' ? 'left' : 'right'"
						(onBulletClick)="toggleMenu()"
					></bullet-button>
				</div>
			</div>
		</div>

		<div class="documents-column">
			<div class="document-table">
				<mat-table
					cdkDropList
					[dataSource]="(isFetching$ | async) ? [] : (data$ | async)"
					matSort
					matSortActive="lastModifiedDate"
					matSortDirection="desc"
					(cdkDropListDropped)="dropRow($event)"
				>
					<ng-container matColumnDef="title">
						<mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!!briefId">{{ displayColumnName('title') }}</mat-header-cell>
						<mat-cell *matCellDef="let row">
							<mat-icon
								throttleButton
								(throttledClick)="openPreview(row.id)"
								class="add-margin document-preview"
								[svgIcon]="row.icon"
								[ngClass]="row.class"
							></mat-icon>
							<mat-icon *ngIf="!isGlobal && !briefId && !!row.isFavorite" class="add-margin file-favorite-icon">star</mat-icon>
							<mat-icon *ngIf="!!row.isEncrypted" class="add-margin file-lock-icon">lock</mat-icon>
							<div class="document-title-block">
								<div class="document-uploaded-block">
									<span class="narrow-column" title="Created Date: {{ row.createdDate | moment : 'dd/MM/yyyy' }}">
										Created {{ row.createdDate | moment : 'dd/MM/yyyy' }}
									</span>
									<span class="narrow-column" title="Modified Date: {{ row.lastModifiedDate | moment : 'dd/MM/yyyy' }}">
										Modified {{ row.lastModifiedDate | moment : 'dd/MM/yyyy' }}
									</span>
								</div>
								<div class="document-title">
									<a class="document-preview" throttleButton (throttledClick)="openPreview(row.id)">{{ row.title }}</a
									><span
										[ngClass]="!!selectedDocumentId ? 'document-title-version-preview' : 'document-title-version'"
										*ngIf="row.versions?.length > 1"
									>
										<a
											[matTooltip]="isVersionFilterActive ? 'Clear version filter' : 'Show all versions'"
											*ngIf="!briefId"
											throttleButton
											(throttledClick)="toggleVersionFilter(row)"
											class="version-number"
											>{{ getDocumentVersionDisplayNumber(row) }}</a
										>
										<span *ngIf="!!briefId" class="version-number">{{ getDocumentVersionDisplayNumber(row) }}</span>
									</span>
								</div>
								<div class="email-addresses-block">
									<div *ngIf="row.from"><b>From:</b> {{ row.from | lowercase }}</div>
									<div *ngIf="row.to && row.to.length">
										<b>To: </b>{{ row.to[0] | lowercase }}<span *ngIf="row.to.length > 1"> (+{{ row.to.length - 1 }} others)</span>
									</div>
								</div>

								<div
									class="embedded-associated-matter"
									*ngIf="row.associatedMatter && showRelatedTo"
									title="Associated Matter: {{ row.associatedMatter?.name }}"
								>
									<mat-icon color="accent">gavel</mat-icon>
									<a [routerLink]="['/matters', row.associatedMatter?.id, 'documents']">{{ row.associatedMatter?.name }}</a>
								</div>
								<div class="embedded-row">
									<div
										class="embedded-associated-contact"
										*ngIf="row.associatedContact && showRelatedTo"
										title="Associated Contact: {{ row.associatedContact?.name }}"
									>
										<mat-icon color="accent">person</mat-icon>
										<a [routerLink]="['/contacts', row.associatedContact?.id, 'documents']">{{ row.associatedContact?.name }}</a>
									</div>
								</div>
							</div>
						</mat-cell>
					</ng-container>

					<ng-container matColumnDef="relatedTo">
						<mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!!briefId">{{ displayColumnName('relatedTo') }}</mat-header-cell>
						<mat-cell *matCellDef="let row">
							<div *ngIf="row.associatedContact">
								<mat-icon color="accent">person</mat-icon>
								<a [routerLink]="['/contacts', row.associatedContact?.id, 'documents']">{{ row.associatedContact?.name }}</a>
							</div>
							<div *ngIf="row.associatedMatter">
								<mat-icon color="accent">gavel</mat-icon>
								<a [routerLink]="['/matters', row.associatedMatter?.id, 'documents']">{{ row.associatedMatter?.name }}</a>
							</div>
							<div *ngIf="row.associatedImport">
								<mat-icon color="accent">import_export</mat-icon>
								<a *ngIf="row.associatedImport?.type == 'Contact'" [routerLink]="['/system/import', 'contacts']"
									>{{ row.associatedImport?.id }} - Contacts</a
								>
								<a *ngIf="row.associatedImport?.type == 'Matter'" [routerLink]="['/system/import', 'matters']"
									>{{ row.associatedImport?.id }} - Matters</a
								>
							</div>
						</mat-cell>
					</ng-container>

					<ng-container matColumnDef="createdDate">
						<mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!!briefId">{{ displayColumnName('createdDate') }}</mat-header-cell>
						<mat-cell *matCellDef="let row">
							<div class="date-block">
								<span class="date-block-date">{{ row.createdDate | moment : 'dd/MM/yyyy' }}</span>
								<div class="date-block-contact">
									<mat-icon>person</mat-icon>
									<a [routerLink]="['/contacts/' + row.createdBy?.id]">{{ row.createdBy?.name }}</a>
								</div>
							</div>
						</mat-cell>
					</ng-container>

					<ng-container matColumnDef="lastModifiedDate">
						<mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!!briefId">{{ displayColumnName('lastModifiedDate') }}</mat-header-cell>
						<mat-cell *matCellDef="let row">
							<div class="date-block">
								<span class="date-block-date">{{ row.lastModifiedDate | moment : 'dd/MM/yyyy' }}</span>
								<div class="date-block-contact">
									<mat-icon>person</mat-icon>
									<a [routerLink]="['/contacts/' + row.lastModifiedBy?.id]">{{ row.lastModifiedBy?.name }}</a>
								</div>
							</div>
						</mat-cell>
					</ng-container>

					<ng-container matColumnDef="associatedBriefs">
						<mat-header-cell *matHeaderCellDef>{{ displayColumnName('associatedBriefs') }}</mat-header-cell>
						<mat-cell *matCellDef="let row">
							<ng-container *ngIf="!briefId">
								<div *ngFor="let brief of row.associatedBriefs">
									<span><mat-icon>work</mat-icon>{{ brief.briefName }}</span>
								</div>
							</ng-container>
							<ng-container *ngIf="!!briefId">
								<div *ngFor="let brief of row.associatedBriefs">
									<span *ngFor="let section of brief.sections"><mat-icon>folder</mat-icon>{{ section.name }}</span>
								</div>
							</ng-container>
						</mat-cell>
					</ng-container>

					<ng-container matColumnDef="userChangeTimestamp">
						<mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!!briefId">{{
							displayColumnName('userChangeTimestamp')
						}}</mat-header-cell>
						<mat-cell *matCellDef="let row">{{ row.userChangeTimestamp | moment : 'dd/MM/yyyy' }}</mat-cell>
					</ng-container>

					<ng-container matColumnDef="documentTags">
						<mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!!briefId">{{ displayColumnName('documentTags') }}</mat-header-cell>
						<mat-cell *matCellDef="let row">
							<mat-chip *ngFor="let tag of row.documentTags">
								{{ tag }}
							</mat-chip>
						</mat-cell>
					</ng-container>

					<ng-container matColumnDef="reorder">
						<mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!!briefId">{{ displayColumnName('reorder') }}</mat-header-cell>
						<mat-cell *matCellDef="let row">
							<button mat-icon-button title="Reorder">
								<mat-icon class="reorder-icon">drag_indicator</mat-icon>
							</button>
						</mat-cell>
					</ng-container>

					<ng-container matColumnDef="action">
						<mat-header-cell *matHeaderCellDef>{{ displayColumnName('action') }}</mat-header-cell>
						<mat-cell *matCellDef="let row">
							<button
								mat-icon-button
								*ngIf="!!row.isShared && !isXSmall"
								throttleButton
								(throttledClick)="openDocumentAccessDialog(row)"
								matTooltip="Manage Share"
							>
								<mat-icon>share</mat-icon>
							</button>
							<button
								mat-icon-button
								throttleButton
								(throttledClick)="edit(row)"
								*ngIf="!isXSmall && canOpenInNativeApp(row.fileExtension) && !selectedDocumentId"
								[matTooltip]="openInNativeAppName(row)"
							>
								<mat-icon *ngIf="!isLocked(row)" svgIcon="file-edit"></mat-icon>
								<mat-icon *ngIf="isLocked(row)" class="locked" svgIcon="file-lock" title="File is locked"></mat-icon>
							</button>
							<button mat-icon-button [matMenuTriggerFor]="menu" title="Actions">
								<mat-icon>more_vert</mat-icon>
							</button>
							<mat-menu #menu="matMenu">
								<button mat-menu-item throttleButton (throttledClick)="openPreview(row.id)">
									<mat-icon>zoom_in</mat-icon>
									<span>Preview</span>
								</button>
								<button mat-menu-item throttleButton (throttledClick)="editDetails(row)">
									<mat-icon>edit_note</mat-icon>
									<span>Modify Details</span>
								</button>
								<button mat-menu-item throttleButton (throttledClick)="edit(row)" *ngIf="!isXSmall && canOpenInNativeApp(row.fileExtension)">
									<mat-icon *ngIf="!isLocked(row)" svgIcon="file-edit"></mat-icon>
									<mat-icon *ngIf="isLocked(row)" class="locked" svgIcon="file-lock" title="File is locked"></mat-icon>
									<span>{{ openInNativeAppName(row) }}</span>
								</button>
								<div
									[title]="!!row.isEncrypted ? 'Cannot convert encrypted files' : 'Download as Pdf'"
									*ngIf="canCopy && canDownloadAsPdf(row)"
								>
									<button mat-menu-item throttleButton (throttledClick)="downloadSingleFile(row, true)" [disabled]="!!row.isEncrypted">
										<mat-icon svgIcon="file-pdf"></mat-icon>
										<span>Convert to PDF</span>
									</button>
								</div>
								<div
									[title]="
										!isOutlookActive ? 'Open the Outlook add-in to enable this button' : 'Ensure the Outlook Add-in is open and pinned'
									"
								>
									<button mat-menu-item throttleButton (throttledClick)="email(row)" [disabled]="!isOutlookActive">
										<mat-icon>attach_email</mat-icon>
										<span>Email Document</span>
									</button>
								</div>
								<button mat-menu-item throttleButton (throttledClick)="copy(row)" *ngIf="canCopy">
									<mat-icon>file_copy</mat-icon>
									<span>Duplicate Document</span>
								</button>

								<button
									class="hiddenPhone"
									mat-menu-item
									throttleButton
									(throttledClick)="copyUrl(row)"
									*ngIf="canCopyUrl(row.fileExtension)"
									title="To Edit this PDF, Copy and Paste this link in Acrobat Reader under File > Open"
								>
									<mat-icon *ngIf="!isLocked(row)" svgIcon="file-document"></mat-icon>
									<mat-icon *ngIf="isLocked(row)" class="locked" svgIcon="file-lock" title="File is locked"></mat-icon>
									<span>Copy URL for Editing</span>
								</button>
								<button *tenantFeature="featureTypes.Referrals" mat-menu-item throttleButton (throttledClick)="referDocument(row)">
									<mat-icon>reply</mat-icon>
									<span>Refer Document</span>
								</button>
								<button mat-menu-item throttleButton (throttledClick)="downloadSingleFile(row)" title="Download ({{ row.size | bytes }})">
									<mat-icon svgIcon="cloud-download"></mat-icon>
									<span>Download ({{ row.size | bytes }})</span>
								</button>
								<button mat-menu-item throttleButton (throttledClick)="deleteDocument(row)">
									<mat-icon svgIcon="delete"></mat-icon>
									<span>Delete</span>
								</button>

								<ng-container *tenantFeature="'DocumentVersioning'">
									<button
										[featureFlag]="featureFlags.documentVersioning"
										*ngIf="canOpenInNativeApp(row.fileExtension) && canCopy"
										[matMenuTriggerFor]="versionMenu"
										mat-menu-item
									>
										<mat-icon>library_add</mat-icon>
										<span>Version</span>
									</button>
								</ng-container>

								<mat-menu #versionMenu="matMenu">
									<button mat-menu-item throttleButton (throttledClick)="newVersion(row)">
										<mat-icon>post_add</mat-icon>
										<span>New Version</span>
									</button>
									<button mat-menu-item *ngIf="row.versions?.length > 1" throttleButton (throttledClick)="toggleVersionFilter(row)">
										<mat-icon>list_alt</mat-icon>
										<span>{{ isVersionFilterActive ? 'Clear Version Filter' : 'Show Version History' }} </span>
									</button>
								</mat-menu>

								<ng-container *tenantFeature="featureTypes.Briefs">
									<button *ngIf="!!matterId && briefs?.length > 0" [matMenuTriggerFor]="briefMenu" mat-menu-item>
										<mat-icon>business_center</mat-icon>
										<span>Brief</span>
									</button>
								</ng-container>

								<button mat-menu-item *ngIf="!briefId && !isGlobal" throttleButton (throttledClick)="favoriteDocument(row, !row.isFavorite)">
									<mat-icon>{{ !row.isFavorite ? 'star' : 'star_outline' }}</mat-icon>
									<span>{{ !row.isFavorite ? 'Favourite' : 'Remove Favourite' }}</span>
								</button>

								<ng-container *tenantFeature="'DocumentSharing'">
									<button *ngIf="!row.isShared" mat-menu-item throttleButton (throttledClick)="openDocumentAccessDialog(row)">
										<mat-icon>share</mat-icon>
										<span>Share Document</span>
									</button>
								</ng-container>
								<button *ngIf="!!row.isShared" mat-menu-item throttleButton (throttledClick)="openDocumentAccessDialog(row)">
									<mat-icon>share</mat-icon>
									<span>Manage Share</span>
								</button>

								<button [featureFlag]="featureFlags.openReplyForward" mat-menu-item throttleButton (throttledClick)="quickReply(row)">
									<mat-icon>reply_all</mat-icon>
									<span>Reply or Forward</span>
								</button>

								<mat-menu #briefMenu="matMenu">
									<button mat-menu-item *ngIf="!briefId" throttleButton (throttledClick)="addDocumentToBrief(row.id)">
										<mat-icon>add</mat-icon> <span>Add to Existing Brief</span>
									</button>
									<button *ngIf="briefId" throttleButton (throttledClick)="removeDocumentFromBrief([row.id])" mat-menu-item>
										<mat-icon>remove_circle</mat-icon> <span>Remove Document from Brief</span>
									</button>
									<button *ngIf="briefId && shouldDisplayMoveSection(row)" [matMenuTriggerFor]="sectionMenu" mat-menu-item>
										<mat-icon>drive_file_move</mat-icon> <span>Move to Section</span>
									</button>
									<button *ngIf="briefId && !isUncategorised(row)" [matMenuTriggerFor]="removeSectionMenu" mat-menu-item>
										<mat-icon>remove_circle_outline</mat-icon> <span>Remove from Section</span>
									</button>
								</mat-menu>

								<mat-menu #sectionMenu="matMenu">
									<div *ngFor="let section of brief?.sections">
										<ng-container *ngIf="containsSection(row.id, section.id)">
											<button mat-menu-item throttleButton (throttledClick)="moveDocumentsToSection([row.id], section)">
												<mat-icon>drive_file_move</mat-icon> <span>{{ section.name }}</span>
											</button>
										</ng-container>
									</div>
								</mat-menu>

								<mat-menu #removeSectionMenu="matMenu">
									<div *ngFor="let brief of row?.associatedBriefs">
										<div *ngFor="let section of brief?.sections">
											<button mat-menu-item throttleButton (throttledClick)="removeDocumentFromSection(row, section)">
												<mat-icon>remove_circle_outline</mat-icon> <span>{{ section.name }}</span>
											</button>
										</div>
									</div>
								</mat-menu>
							</mat-menu>
						</mat-cell>
					</ng-container>

					<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
					<mat-row
						cdkDrag
						[cdkDragDisabled]="sectionId === null"
						*matRowDef="let row; columns: displayedColumns"
						throttleButton
						(throttledClick)="selectRow(row, $event)"
						[ngClass]="{ selected: selectedDocumentId == row.id, highlight: row.isHighlighted }"
					>
					</mat-row>
				</mat-table>
				<mat-paginator
					[style.visibility]="!briefId ? 'visible' : 'hidden'"
					[length]="totalRecords$ | async"
					[pageIndex]="pageIndex$ | async"
					[pageSize]="pageSize$ | async"
					[pageSizeOptions]="pageSizeOptions"
					[class.hidden]="(isFetching$ | async) || !(data$ | async)"
				></mat-paginator>
				<div style="display: none;" id="replyDocumentDiv"></div>
			</div>
		</div>
	</div>
</div>
