// This file has been generated from BillStatusUpdateDto.cs
// Do not modify this file directly!!
// tslint:disable

import { Moment } from 'moment-timezone';
import { DateFormat, SetDateFormat } from '@common/utils/date-format';

import { SetSimpleType } from '@common/utils/class-transform';

import { BillStatus } from '../Common/BillStatus';

// LawMaster.Amalthea.Domain.Bills.Item.BillStatusUpdateDto
export class BillStatusUpdateDto {

	// Id
	id: string;
	// Status
	status: keyof typeof BillStatus;
	// AmountPaid
	amountPaid: number;
	// PaymentDate
	@SetDateFormat(DateFormat.DateTime)
	paymentDate: Moment;
	// TrustAccountId
	trustAccountId: string;
	// BankAccountId
	bankAccountId: string;
	// ClearTrustCreditAmount
	@SetSimpleType(Boolean)
	clearTrustCreditAmount: boolean;

	constructor() {

	}
}