// This file has been generated from ListRequest.cs
// Do not modify this file directly!!
// tslint:disable

import { EnumSortDirection } from '../Common/EnumSortDirection';

// LawMaster.Amalthea.Domain.Generic.ListRequest
export class ListRequest {

	// Search
	search: string;
	// SortBy
	sortBy: string;
	// SortDirection
	sortDirection: keyof typeof EnumSortDirection;
	// PageIndex
	pageIndex: number;
	// PageSize
	pageSize: number;
	// PageIndexForId
	pageIndexForId: string;

	constructor() {

	}
}