import { Location } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	styleUrls: ['./reload-error-dialog.component.scss'],
	templateUrl: `./reload-error-dialog.component.html`
})
export class ReloadErrorDialogComponent {
	constructor(public location: Location, @Inject(MAT_DIALOG_DATA) public data: IReloadErrorDialogParams) {}
}

export interface IReloadErrorDialogParams {
	headerText: string;
	bodyText: string;
}
