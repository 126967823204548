// This file has been generated from TrustController.cs
// Do not modify this file directly!!
// tslint:disable

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { TrimStringsOnObject } from '@common/utils/string-format';

import { EntityReference } from '../models/Common/EntityReference';
import { IdCollectionRequest } from '../models/Common/IdCollectionRequest';
import { EntityFromTemplateDto } from '../models/Documents/Item/EntityFromTemplateDto';
import { ListResponse } from '../models/Generic/ListResponse';
import { CancelTransactionDto } from '../models/Trust/Item/CancelTransactionDto';
import { DepositListingViewDto } from '../models/Trust/Item/DepositListingViewDto';
import { TrustBillPaymentCreateDto } from '../models/Trust/Item/TrustBillPaymentCreateDto';
import { TrustBillPaymentUpdateDto } from '../models/Trust/Item/TrustBillPaymentUpdateDto';
import { TrustDepositCreateDto } from '../models/Trust/Item/TrustDepositCreateDto';
import { TrustJournalCreateDto } from '../models/Trust/Item/TrustJournalCreateDto';
import { TrustJournalUpdateDto } from '../models/Trust/Item/TrustJournalUpdateDto';
import { TrustOpeningBalanceReceiptCreateDto } from '../models/Trust/Item/TrustOpeningBalanceReceiptCreateDto';
import { TrustReceiptPaymentCreateDto } from '../models/Trust/Item/TrustReceiptPaymentCreateDto';
import { TrustReceiptPaymentUpdateDto } from '../models/Trust/Item/TrustReceiptPaymentUpdateDto';
import { TrustViewDto } from '../models/Trust/Item/TrustViewDto';
import { UnprocessedReceiptTotalsRequest } from '../models/Trust/Item/UnprocessedReceiptTotalsRequest';
import { ValidateCancelTrustRecordDto } from '../models/Trust/Item/ValidateCancelTrustRecordDto';
import { TrustListItemDto } from '../models/Trust/List/TrustListItemDto';
import { TrustListRequest } from '../models/Trust/List/TrustListRequest';
import { UnprocessedReceiptTotalsDto } from '../models/Trust/List/UnprocessedReceiptTotalsDto';
import { IUploadMultipleFiles } from '@common/models/Common/IFileUploader';
import { IResultWithAttachments } from '@common/models/Common/IResultWithAttachments';
import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';
@Injectable({
	providedIn: 'root'
})
export class TrustService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'api/v1/Trust');
	}

	getTrustList(request?: Partial<TrustListRequest>): Observable<ListResponse<TrustListItemDto>> {
		return this.getList<TrustListRequest, TrustListItemDto>('', request);
	}

	getAnyActiveRecordsForMatter(matterId: string): Observable<boolean> {
		return this.getItem<void, boolean>(`GetAnyActiveRecordsForMatter/${matterId}`);
	}

	getReceiptsAvailableForDepositing(trustAccountId: string): Observable<ListResponse<TrustListItemDto>> {
		return this.getList<void, TrustListItemDto>(`GetReceiptsAvailableForDepositing/${trustAccountId}`);
	}

	getTransactionsNotReconciled(trustAccountId: string): Observable<TrustListItemDto[]> {
		return this.getArray<void, TrustListItemDto>(`GetTransactionsNotReconciled/${trustAccountId}`);
	}

	getUnprocessedReceiptTotals(request: UnprocessedReceiptTotalsRequest): Observable<UnprocessedReceiptTotalsDto> {
		return this.getItem<UnprocessedReceiptTotalsRequest, UnprocessedReceiptTotalsDto>(`GetUnprocessedReceiptTotals`, request);
	}

	getTrustRecord(id: string): Observable<TrustViewDto> {
		return this.getItem<void, TrustViewDto>(`${id}`);
	}

	getDepositListingRecord(id: string): Observable<DepositListingViewDto> {
		return this.getItem<void, DepositListingViewDto>(`GetDepositListingRecord/${id}`);
	}

	create(dto: TrustReceiptPaymentCreateDto): Observable<MutationResponseDto> {
		return this.post<TrustReceiptPaymentCreateDto, MutationResponseDto>('', TransformDatesOnObject(TrustReceiptPaymentCreateDto, TrimStringsOnObject(dto)));
	}

	createEftPayment(dto: TrustReceiptPaymentCreateDto, filesToUpload: IUploadMultipleFiles): Observable<Partial<IResultWithAttachments>> {
		return this.createUpdateWithHttpProgress<TrustReceiptPaymentCreateDto>(`CreateEftPayment`, TransformDatesOnObject(TrustReceiptPaymentCreateDto, TrimStringsOnObject(dto)), filesToUpload, 'POST');
	}

	updateEftPayment(id: string, dto: TrustReceiptPaymentUpdateDto, filesToUpload: IUploadMultipleFiles): Observable<Partial<IResultWithAttachments>> {
		return this.createUpdateWithHttpProgress<TrustReceiptPaymentUpdateDto>(`UpdateEftPayment/${id}`, TransformDatesOnObject(TrustReceiptPaymentUpdateDto, TrimStringsOnObject(dto)), filesToUpload, 'PUT');
	}

	updateJournal(id: string, dto: TrustJournalUpdateDto, filesToUpload: IUploadMultipleFiles): Observable<Partial<IResultWithAttachments>> {
		return this.createUpdateWithHttpProgress<TrustJournalUpdateDto>(`UpdateJournal/${id}`, TransformDatesOnObject(TrustJournalUpdateDto, TrimStringsOnObject(dto)), filesToUpload, 'PUT');
	}

	createOpeningBalanceReceipt(dto: TrustOpeningBalanceReceiptCreateDto): Observable<MutationResponseDto> {
		return this.post<TrustOpeningBalanceReceiptCreateDto, MutationResponseDto>(`CreateOpeningBalanceReceipt`, TransformDatesOnObject(TrustOpeningBalanceReceiptCreateDto, TrimStringsOnObject(dto)));
	}

	createBillPayment(dto: TrustBillPaymentCreateDto, filesToUpload: IUploadMultipleFiles): Observable<Partial<IResultWithAttachments>> {
		return this.createUpdateWithHttpProgress<TrustBillPaymentCreateDto>(`CreateBillPayment`, TransformDatesOnObject(TrustBillPaymentCreateDto, TrimStringsOnObject(dto)), filesToUpload, 'POST');
	}

	updateBillPayment(id: string, dto: TrustBillPaymentUpdateDto, filesToUpload: IUploadMultipleFiles): Observable<Partial<IResultWithAttachments>> {
		return this.createUpdateWithHttpProgress<TrustBillPaymentUpdateDto>(`UpdateBillPayment/${id}`, TransformDatesOnObject(TrustBillPaymentUpdateDto, TrimStringsOnObject(dto)), filesToUpload, 'PUT');
	}

	createJournal(dto: TrustJournalCreateDto, filesToUpload: IUploadMultipleFiles): Observable<Partial<IResultWithAttachments>> {
		return this.createUpdateWithHttpProgress<TrustJournalCreateDto>(`CreateJournal`, TransformDatesOnObject(TrustJournalCreateDto, TrimStringsOnObject(dto)), filesToUpload, 'POST');
	}

	createDeposit(dto: TrustDepositCreateDto): Observable<EntityReference> {
		return this.post<TrustDepositCreateDto, EntityReference>(`CreateDeposit`, TransformDatesOnObject(TrustDepositCreateDto, TrimStringsOnObject(dto)));
	}

	validateCancellationOfTrustRecord(id: string): Observable<ValidateCancelTrustRecordDto> {
		return this.getItem<void, ValidateCancelTrustRecordDto>(`ValidateCancellationOfTrustRecord/${id}`);
	}

	cancelTransaction(dto: CancelTransactionDto): Observable<MutationResponseDto[]> {
		return this.post<CancelTransactionDto, any>(`CancelTransaction`, TransformDatesOnObject(CancelTransactionDto, TrimStringsOnObject(dto)));
	}

	cancelBillTransaction(dto: CancelTransactionDto): Observable<MutationResponseDto[]> {
		return this.post<CancelTransactionDto, any>(`CancelBillTransaction`, TransformDatesOnObject(CancelTransactionDto, TrimStringsOnObject(dto)));
	}

	cancelJournal(dto: CancelTransactionDto): Observable<MutationResponseDto> {
		return this.post<CancelTransactionDto, any>(`CancelJournal`, TransformDatesOnObject(CancelTransactionDto, TrimStringsOnObject(dto)));
	}

	clearReceipt(id: string): Observable<MutationResponseDto> {
		return this.put(`${id}/ClearReceipt`);
	}

	publishReceipt(templateDto: EntityFromTemplateDto): Observable<any> {
		return this.post<EntityFromTemplateDto, any>(`publishReceipt`, TransformDatesOnObject(EntityFromTemplateDto, TrimStringsOnObject(templateDto)));
	}

	publishDepositListing(templateDto: EntityFromTemplateDto): Observable<any> {
		return this.post<EntityFromTemplateDto, any>(`publishDepositListing`, TransformDatesOnObject(EntityFromTemplateDto, TrimStringsOnObject(templateDto)));
	}

	publishEFTPaymentRequisitionForm(templateDto: EntityFromTemplateDto): Observable<any> {
		return this.post<EntityFromTemplateDto, any>(`publishEFTPaymentRequisitionForm`, TransformDatesOnObject(EntityFromTemplateDto, TrimStringsOnObject(templateDto)));
	}

	unclearReceipt(id: string, offsetDays: number): Observable<MutationResponseDto> {
		return this.put(`${id}/UnclearReceipt/${offsetDays}`);
	}

	exportLedgerByFilter(request?: Partial<TrustListRequest>): Observable<HttpResponse<Blob>> {
		return this.getBlob<TrustListRequest>(`ExportLedgerByFilter`, request);
	}

	exportCashbookByFilter(request?: Partial<TrustListRequest>): Observable<HttpResponse<Blob>> {
		return this.getBlob<TrustListRequest>(`ExportCashbookByFilter`, request);
	}

	exportLedgerByIds(request: IdCollectionRequest): Observable<HttpResponse<Blob>> {
		return this.getBlob<IdCollectionRequest>(`ExportLedgerByIds`, request);
	}

	exportCashbookByIds(request: IdCollectionRequest): Observable<HttpResponse<Blob>> {
		return this.getBlob<IdCollectionRequest>(`ExportCashbookByIds`, request);
	}

}
