// This file has been generated from TrustTransactionSequenceTypeUpdateDto.cs
// Do not modify this file directly!!
// tslint:disable

import { TrustTransactionSequenceType } from '../../../../Trust/Common/TrustTransactionSequenceType';

// LawMaster.Amalthea.Domain.Settings.TrustSettings.TrustAccounts.Item.TrustTransactionSequenceTypeUpdateDto
export class TrustTransactionSequenceTypeUpdateDto {

	// TrustAccountId
	trustAccountId: string;
	// TransactionSequenceType
	transactionSequenceType: keyof typeof TrustTransactionSequenceType;
	// Prefix
	prefix: string;
	// Digits
	digits: number;
	// NextNumber
	nextNumber: number;

	constructor() {

	}
}