// This file has been generated from ActivityLogController.cs
// Do not modify this file directly!!
// tslint:disable

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { TrimStringsOnObject } from '@common/utils/string-format';

import { ActivityLogListItemDto } from '../models/ActivityLogs/List/ActivityLogListItemDto';
import { ActivityLogListRequest } from '../models/ActivityLogs/List/ActivityLogListRequest';
import { ListResponse } from '../models/Generic/ListResponse';

@Injectable({
	providedIn: 'root'
})
export class ActivityLogService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'api/v1/ActivityLog', 'Activity Log');
	}

	getActivityLogList(request?: Partial<ActivityLogListRequest>): Observable<ListResponse<ActivityLogListItemDto>> {
		return this.getList<ActivityLogListRequest, ActivityLogListItemDto>('', request);
	}

}
