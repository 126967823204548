<mat-card>
	<section class="page-path"><a [routerLink]="['..']">System</a> / API Keys</section>
	<h1>API Keys</h1>
	<div class="description">
		Manage keys used for integrating with third party systems. Click
		<a href="{{ apiServerUrl }}" target="_blank">here</a> for the developer documentation on how to use the API.
	</div>
	<button mat-button color="primary" title="Create New" throttleButton (throttledClick)="onCreateButtonClick()">
		<mat-icon class="create-new">add_circle</mat-icon>
		<span>Generate New API Key</span>
	</button>
	<mat-table [dataSource]="datasource" matSort matSortActive="status" matSortDirection="asc">
		<ng-container matColumnDef="description">
			<mat-header-cell *matHeaderCellDef mat-sort-header>Description</mat-header-cell>
			<mat-cell class="embedded-cell" *matCellDef="let row">
				{{ row.description }}
				<div class="embedded-status">
					<span [ngClass]="{ 'warning-text': row.status === 'Expired' || row.status === 'Revoked' }">{{ row.status }}</span>
				</div>
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="status">
			<mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<span [ngClass]="{ 'warning-text': row.status === 'Expired' || row.status === 'Revoked' }">{{ row.status }}</span>
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="user_Name">
			<mat-header-cell *matHeaderCellDef mat-sort-header>Created By</mat-header-cell>
			<mat-cell *matCellDef="let row">
				{{ row.user.name }}
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="creation">
			<mat-header-cell *matHeaderCellDef mat-sort-header>Created Date</mat-header-cell>
			<mat-cell *matCellDef="let row">
				{{ row.creation | moment : 'dd/MM/yyyy' }}
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="expiry">
			<mat-header-cell *matHeaderCellDef mat-sort-header>Revoked Date</mat-header-cell>
			<mat-cell *matCellDef="let row">
				{{ row.status === 'Expired' || row.status === 'Revoked' ? (row.expiry | moment : 'dd/MM/yyyy') : '' }}
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="action">
			<mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<button *ngIf="row.status !== 'Expired' && row.status !== 'Revoked'" mat-icon-button [matMenuTriggerFor]="menu" title="Actions">
					<mat-icon>more_vert</mat-icon>
				</button>
				<mat-menu #menu="matMenu">
					<button mat-menu-item throttleButton (throttledClick)="onEditButtonClick(row)" *ngIf="row.status !== 'Expired' && row.status !== 'Revoked'">
						<mat-icon>edit</mat-icon>
						<span>Edit</span>
					</button>
					<button mat-menu-item throttleButton (throttledClick)="onRevokeButtonClick(row)">
						<mat-icon svgIcon="delete"></mat-icon>
						<span>Revoke</span>
					</button>
				</mat-menu>
			</mat-cell>
		</ng-container>

		<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
		<mat-row
			*matRowDef="let row; columns: displayedColumns"
			class="api-keys-row"
			throttleButton
			(throttledClick)="selectRow(row, $event)"
			[ngClass]="{ highlight: row.isHighlighted }"
		></mat-row>
	</mat-table>
	<mat-paginator
		[length]="datasource?.response?.totalRecords"
		[pageIndex]="datasource?.response?.pageIndex"
		[pageSize]="pageSize"
		[pageSizeOptions]="pageSizeOptions"
	></mat-paginator>
</mat-card>
