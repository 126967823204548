import { Component, OnInit } from '@angular/core';
import { InstallationRecordLookupDto } from '@common/models/Marketplace/InstallationRecords/Lookup/InstallationRecordLookupDto';
import { MarketplaceService } from '@common/services/settings/marketplace.service';
import { Observable } from 'rxjs';
import { BaseFilterLookupComponent } from './base-filter-lookup.component';

@Component({
	selector: 'installed-package-filter',
	styleUrls: ['./installed-package-filter.component.scss'],
	templateUrl: './installed-package-filter.component.html'
})
export class InstalledPackageFilterComponent
	extends BaseFilterLookupComponent<InstallationRecordLookupDto>
	implements OnInit
{
	constructor(private marketplaceService: MarketplaceService) {
		super();
	}

	displayValue(input: InstallationRecordLookupDto): string {
		return input.productName;
	}

	lookup(id: string): Observable<InstallationRecordLookupDto> {
		return this.marketplaceService.lookupInstalledRecord(id);
	}

	search(term: string): Observable<InstallationRecordLookupDto[]> {
		return this.marketplaceService.lookupInstalledRecords({
			term
		});
	}

	// Add a new item by ID into the list of recently accessed items
	// and update the bound list of options
	protected addAndUpdateRecentlyAccessedItems(id: string): void {
		// Not Implemented
		this.options = this.options?.filter(p => p.id !== id);
	}
}
