import { Injectable } from '@angular/core';

import { interval, of } from 'rxjs';
import { catchError, debounce, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { ContactListItemDto } from '@common/models/Contacts/List/ContactListItemDto';
import { ListResponse } from '@common/models/Generic/ListResponse';
import { ContactsService } from '@common/services/contacts.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash-es';

import { IAppState } from '../../app.state';
import { EntityChangedEventService } from '../../entity-change-event.service';
import { ContactListActions, insertRecords, loading, removeRecords, updateRecords } from './contact-list.actions';

@Injectable()
export class ContactListEffects {
	loadContacts$ = createEffect(() =>
		this._actions$.pipe(
			ofType(
				ContactListActions.Load,
				ContactListActions.SetPageIndex,
				ContactListActions.SetPageSize,
				ContactListActions.SetSortBy,
				ContactListActions.SetSortDirection,
				ContactListActions.SetFilters
			),
			withLatestFrom(this._store),
			filter(([_, state]) => !isEqual(state.contactList.request, state.contactList.oldRequest)),
			tap(() => this._store.dispatch(loading())),
			debounce(() => interval(200)),
			switchMap(([_, state]) =>
				this._contactsService.getContactList(state.contactList.request).pipe(
					map((response: ListResponse<ContactListItemDto>) => ({
						type: ContactListActions.LoadSuccess,
						response
					})),
					catchError(error => of({ type: ContactListActions.LoadFailed, error }))
				)
			)
		)
	);

	contactsChanged$ = createEffect(
		() =>
			this._actions$.pipe(
				ofType(insertRecords, updateRecords, removeRecords),
				tap(action => {
					this._entityChangedEventService.fireEntityChangedEvent(action.response);
				})
			),
		{ dispatch: false }
	);

	constructor(
		private _actions$: Actions,
		private _store: Store<IAppState>,
		private _contactsService: ContactsService,
		private _entityChangedEventService: EntityChangedEventService
	) {}
}
