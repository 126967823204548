import { Pipe, PipeTransform } from '@angular/core';
import { PracticeType } from '@common/models/Settings/Setting/Item/PracticeType';

/*
 * Resolve practice type display string by the enum key
 */
@Pipe({ name: 'practiceType' })
export class PracticeTypePipe implements PipeTransform {
	transform(key: keyof typeof PracticeType): string {
		if (!PracticeType[key]) {
			throw Error(`Dev error: The Practice Type enum does not contain the key ${key}`);
		}
		return PracticeType[key];
	}
}
