import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';

import { Observable, of as ObservableOf } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { BaseLookupComponent } from '@common/components/lookups/base-lookup.component';
import {
	CreateContactLookupDialogComponent,
	ICreateContactLookupDialogData
} from '@common/components/lookups/create-contact-lookup.component';
import { DefferedContact } from '@common/components/lookups/DefferedContact';
import { ContactType } from '@common/models/Contacts/Common/ContactType';
import { CompanyContactCreateUpdateDto } from '@common/models/Contacts/Item/CompanyContactCreateUpdateDto';
import { PersonContactCreateUpdateDto } from '@common/models/Contacts/Item/PersonContactCreateUpdateDto';
import { PluralContactCreateUpdateDto } from '@common/models/Contacts/Item/PluralContactCreateUpdateDto';
import { ContactLookupDto } from '@common/models/Contacts/Lookup/ContactLookupDto';
import { ContactLookupEntityType } from '@common/models/Contacts/Lookup/ContactLookupEntityType';
import { ContactLookupType } from '@common/models/Contacts/Lookup/ContactLookupType';
import { ContactsService } from '@common/services/contacts.service';
import { newGuid } from '@common/utils/create-guid';

@Component({
	selector: 'contact-lookup',
	styleUrls: ['./contact-lookup.component.scss'],
	templateUrl: './contact-lookup.component.html'
})
export class ContactLookupComponent extends BaseLookupComponent<ContactLookupDto> {
	@ViewChild('autoCompleteInput', { static: true })
	inputCtrl: ElementRef;
	@Input()
	get StaffOnly(): boolean {
		return this.staffOnly;
	}
	set StaffOnly(value: boolean) {
		this.staffOnly = value;
	}
	@Input()
	get ExcludeSelf(): boolean {
		return this.excludeSelf;
	}
	set ExcludeSelf(value: boolean) {
		this.excludeSelf = value;
	}
	@Input()
	ignoreIds: string[];
	@Input()
	allowPlurals: boolean = true;
	@Input()
	showMatterRole: boolean;
	@Input()
	matterId: string;
	@Input()
	FormControl: FormControl;
	@Input()
	FreeTextFormControl: FormControl;
	@Input()
	FontSize: string;
	@Input()
	HasAutofocus: boolean;
	@Input()
	Placeholder: string;
	@Input()
	Hint: string;
	@Input()
	Required: boolean;
	@Input()
	CreatePersonsCtrl: FormControl;
	@Input()
	CreateCompaniesCtrl: FormControl;
	@Input()
	CreatePluralCtrl: FormControl;
	@Input()
	AllowCreate: boolean;
	@Input()
	set AllowFreeText(value: boolean) {
		this._allowFreeText = value;
		this.canHaveNoMatch = value;
	}

	get AllowFreeText(): boolean {
		return this._allowFreeText;
	}

	get noMatchesFound() {
		return this.noLookupResults && this.inputDisplayCtrl.value && this.options?.length == 0 && !this.AllowFreeText;
	}

	@Output()
	IsNoMatch: EventEmitter<void> = new EventEmitter<void>();

	get IsAllowedToCreate(): boolean {
		return this.AllowCreate && !!this.CreatePersonsCtrl && !!this.CreateCompaniesCtrl;
	}

	deferredContactOptionValue: ContactLookupDto;

	@Input()
	floatLabel: boolean = true;

	private staffOnly: boolean;
	private excludeSelf: boolean;

	private _allowFreeText: boolean;

	constructor(private contactsService: ContactsService, private fb: FormBuilder, public dialog: MatDialog) {
		super();
		this.StaffOnly = false;
		this.ExcludeSelf = false;
	}

	ngOnInit() {
		super.ngOnInit();

		this.subscription.add(
			this.FreeTextFormControl?.valueChanges.pipe(distinctUntilChanged(), filter(Boolean)).subscribe(() => {
				if (!!this.FormControl.value) {
					this.FormControl.setValue(null);
				}

				this.ignoreNextDisplayControlChange = true;
				this.inputDisplayCtrl.setValue(this.FreeTextFormControl.value);

				this.FormControl.updateValueAndValidity();
			})
		);

		if (this.matterId) {
			this.subscription.add(
				this.contactsService
					.lookupContactList({
						term: null,
						contactLookupType: ContactLookupType.All,
						contactLookupEntityType: ContactLookupEntityType.Any,
						matterId: this.matterId,
						excludeSelf: false,
						allowPlurals: this.allowPlurals,
						ignoreIds: this.ignoreIds
					})
					.subscribe(results => (this.options = results))
			);
		}
	}

	searchIfTermEmpty(): boolean {
		return !!this.matterId;
	}

	getSupportedCreateTypes(): (keyof typeof ContactType)[] {
		const results: (keyof typeof ContactType)[] = [];

		if (!!this.CreatePersonsCtrl) {
			results.push(ContactType.Person);
		}

		if (!!this.CreateCompaniesCtrl) {
			results.push(ContactType.Company);
		}

		if (!!this.CreatePluralCtrl) {
			results.push(ContactType.Plural);
		}

		return results;
	}

	openDialog(event: Event): void {
		event.preventDefault();
		const dialogRef = this.dialog.open(CreateContactLookupDialogComponent, {
			data: {
				name: this.inputDisplayCtrl.value,
				supportedTypes: this.getSupportedCreateTypes()
			} as ICreateContactLookupDialogData,
			width: '500px'
		});

		this.subscription.add(
			dialogRef
				.afterClosed()
				.pipe(filter(Boolean))
				.subscribe((result: DefferedContact) => {
					this.selectCreatedContact(result);
				})
		);
	}

	selectCreatedContact(deferredContact: DefferedContact): void {
		if (!this.IsAllowedToCreate) throw new Error('No collections for deferred contacts. Contact developers');

		const referenceId = newGuid();

		let newContact: PersonContactCreateUpdateDto | CompanyContactCreateUpdateDto | PluralContactCreateUpdateDto;
		switch (deferredContact.type) {
			case ContactType.Person:
				newContact = Object.assign(new PersonContactCreateUpdateDto(), {
					firstName: deferredContact.firstName,
					middleName: deferredContact.middleName,
					lastName: deferredContact.lastName,
					customFields: deferredContact.customFields
				});
				const personContacts = this.CreatePersonsCtrl.value ? this.CreatePersonsCtrl.value : {};
				personContacts[referenceId] = newContact as PersonContactCreateUpdateDto;
				this.CreatePersonsCtrl.setValue(personContacts);
				break;
			case ContactType.Company:
				newContact = Object.assign(new CompanyContactCreateUpdateDto(), {
					fullName: deferredContact.fullName,
					customFields: deferredContact.customFields
				});
				const companyContacts = this.CreateCompaniesCtrl.value ? this.CreateCompaniesCtrl.value : {};
				companyContacts[referenceId] = newContact as CompanyContactCreateUpdateDto;
				this.CreateCompaniesCtrl.setValue(companyContacts);
				break;
			case ContactType.Plural:
				newContact = Object.assign(new PluralContactCreateUpdateDto(), {
					fullName: deferredContact.fullName,
					memberIds: deferredContact.memberIds,
					customFields: deferredContact.customFields,
					createPersons: deferredContact.createPersons,
					createCompanies: deferredContact.createCompanies
				});
				const pluralContacts = this.CreatePluralCtrl.value ? this.CreatePluralCtrl.value : {};
				pluralContacts[referenceId] = newContact as PluralContactCreateUpdateDto;
				this.CreatePluralCtrl.setValue(pluralContacts);
				break;
		}

		// Skip the validation for 'no records found' if the entered text does not match the new contact created.
		// If the entered text is same as the new contact created,
		// then the 'this.inputDisplayCtrl.valueChanges' event does not fire
		if (this.inputDisplayCtrl.value !== this.getDisplayName(newContact)) this.skipNoMatchValidation = true;
		this.FormControl.setValue(referenceId);

		this.inputDisplayCtrl.setErrors(null);
		this.FormControl.setErrors(null);

		this.deferredContactOptionValue = Object.assign(new ContactLookupDto(), {
			fullName: this.getDisplayName(newContact),
			id: referenceId,
			shortName: ''
		});
		this.setSelectedValue(this.deferredContactOptionValue);
	}

	getCurrentDisplayValue(): string {
		return this.inputDisplayCtrl.value;
	}

	getDisplayName(
		newContact:
			| PersonContactCreateUpdateDto
			| CompanyContactCreateUpdateDto
			| PluralContactCreateUpdateDto
			| ContactLookupDto
	): string {
		return newContact instanceof PersonContactCreateUpdateDto
			? `${newContact.firstName} ${newContact.lastName}`
			: newContact.fullName;
	}

	autocompleteSelected(event: MatAutocompleteSelectedEvent): void {
		if (!!this.FreeTextFormControl && event.option.id === 'freeText') {
			this.FreeTextFormControl.setValue(this.inputDisplayCtrl.value);
			this.FreeTextFormControl.markAsDirty();
			return;
		}

		this.FreeTextFormControl?.setValue(null);
		if (event.option.value === this.deferredContactOptionValue) {
			this.setSelectedValue(this.deferredContactOptionValue);
		} else {
			super.autocompleteSelected(event);
		}

		this.FreeTextFormControl?.updateValueAndValidity();
	}

	inputChanged(event: any) {
		const inputText = event.target.value;
		if (!this.options?.find(x => x.fullName === inputText)) this.IsNoMatch.emit();
	}

	lookup(id: string): Observable<ContactLookupDto> {
		// if the contact is a new contact do not perform a database lookup.
		// if the contact is a new contact the id is in the form of a Guid
		return this.isGuid(id) === false
			? this.contactsService.lookupContact(id, { matterId: this.matterId }).pipe(
					map(lol => {
						return lol;
					})
			  )
			: ObservableOf();
	}

	optionHtmlText(input: ContactLookupDto): string {
		return input.fullName;
	}

	displayText(input: ContactLookupDto) {
		return input.fullName;
	}

	getAdditionalInfo(input: ContactLookupDto) {
		return input.additionalInfo;
	}

	search(term: string): Observable<ContactLookupDto[]> {
		return this.contactsService.lookupContactList({
			term,
			contactLookupType: this.staffOnly ? ContactLookupType.EnabledStaff : ContactLookupType.All,
			contactLookupEntityType: ContactLookupEntityType.Any,
			matterId: this.matterId,
			excludeSelf: this.excludeSelf,
			allowPlurals: this.allowPlurals,
			ignoreIds: this.ignoreIds
		});
	}

	setFocus() {
		if (this.inputCtrl != null) {
			setTimeout(() => {
				this.inputCtrl.nativeElement.focus();
			}, 0);
		}
	}

	reset() {
		super.reset();
		this.options = null;
		this.deferredContactOptionValue = null;
	}

	private isGuid(stringToTest: string) {
		if (stringToTest[0] === '{') {
			stringToTest = stringToTest.substring(1, stringToTest.length - 1);
		}
		// tslint:disable-next-line:max-line-length
		const regexGuid =
			/^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi;
		return regexGuid.test(stringToTest);
	}
}
