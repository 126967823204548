<mat-dialog-content [formGroup]="form">
	<img class="logo" [alt]="imageAlt" [src]="mainIcon" />
	<h3 class="heading">You're almost ready, one last thing</h3>
	<mat-form-field>
		<mat-placeholder> Type of Business </mat-placeholder>
		<mat-select #practiceTypeInput formControlName="practiceType">
			<mat-option *ngFor="let key of practiceTypeKeys" [value]="key">
				<span [ngClass]="{ unspecified: key == 'Unspecified' }">{{ key | practiceType }}</span>
			</mat-option>
		</mat-select>
		<mat-error>
			<error-messages [for]="form.get('practiceType')"></error-messages>
		</mat-error>
	</mat-form-field>
	<mat-form-field>
		<mat-placeholder>
			Business Name
			<span class="optional">(optional)</span>
		</mat-placeholder>
		<input matInput formControlName="companyName" autocomplete="off" />
		<mat-error>
			<error-messages [for]="form.controls.name"></error-messages>
		</mat-error>
	</mat-form-field>
	<!-- <div class="tos-and-pp">
		<mat-checkbox formControlName="agree">
			<span class="agree-select">
				By checking this box I agree to the <b>mattero </b>
				<a href="https://www.mattero.com.au/terms-and-conditions/" target="_blank">terms & conditions</a> and
				<a href="https://www.mattero.com.au/privacy-policy/" target="_blank">privacy policy</a>.
			</span>
		</mat-checkbox>
	</div> -->
</mat-dialog-content>
<mat-dialog-actions>
	<mat-spinner-btn [loading]="requestInProgress">
		<button mat-raised-button color="primary" throttleButton (throttledClick)="getStarted()" [disabled]="form.invalid || !userCreated">
			{{ requestInProgress ? 'Initializing...' : 'Get Started' }}
		</button>
	</mat-spinner-btn>
</mat-dialog-actions>
