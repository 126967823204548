import { SortDirection } from '@angular/material/sort';

import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';
import { CustomReportListItemDto } from '@common/models/CustomReports/List/CustomReportListItemDto';
import { CustomReportListRequest } from '@common/models/CustomReports/List/CustomReportListRequest';
import { ListResponse } from '@common/models/Generic/ListResponse';
import { DomainError } from '@common/models/Validation/DomainError';
import { createAction, props } from '@ngrx/store';

import { SelectionType } from '../../SelectionType';

export enum CustomReportListActions {
	Init = '[Custom Report List] Initialise',
	Load = '[Custom Report List] Load',
	Loading = '[Custom Report List] Loading',
	LoadSuccess = '[Custom Report List] Load Success',
	LoadFailed = '[Custom Report List] Load Failed',

	SetFilters = '[Custom Report List] Set Filters',
	SetFilterProp = '[Custom Report List] Set Filter Property',
	SetPageSize = '[Custom Report List] Set Page Size',
	SetPageIndex = '[Custom Report List] Set Page Index',
	SetPageIndexForId = '[Custom Report List] Set Page Index For Id',

	SetSortBy = '[Custom Report List] Set Sort By',
	SetSortDirection = '[Custom Report List] Set Sort Direction',

	InsertRecords = '[Custom Report List] Insert Records',
	UpdateRecords = '[Custom Report List] Update Records',
	RemoveRecords = '[Custom Report List] Remove Records',
	SelectRecords = '[Custom Report List] Select Records'
}

export const init = createAction(CustomReportListActions.Init);
export const load = createAction(CustomReportListActions.Load);
export const loading = createAction(CustomReportListActions.Loading);
export const loadSuccess = createAction(
	CustomReportListActions.LoadSuccess,
	props<{ response: ListResponse<CustomReportListItemDto> }>()
);
export const loadFailed = createAction(CustomReportListActions.LoadFailed, props<{ error: DomainError }>());

export const setFilters = createAction(
	CustomReportListActions.SetFilters,
	props<{ filter: Partial<CustomReportListRequest> }>()
);
export const setFilterProp = createAction(
	CustomReportListActions.SetFilterProp,
	props<{ prop: keyof CustomReportListRequest; value: any }>()
);
export const setPageSize = createAction(CustomReportListActions.SetPageSize, props<{ pageSize: number }>());
export const setPageIndex = createAction(CustomReportListActions.SetPageIndex, props<{ pageIndex: number }>());
export const setPageIndexForId = createAction(CustomReportListActions.SetPageIndexForId, props<{ id: string }>());

export const setSortBy = createAction(CustomReportListActions.SetSortBy, props<{ sortBy: string }>());
export const setSortDirection = createAction(
	CustomReportListActions.SetSortDirection,
	props<{ sortDirection: SortDirection }>()
);

export const insertRecords = createAction(
	CustomReportListActions.InsertRecords,
	props<{ response: MutationResponseDto | MutationResponseDto[] }>()
);
export const updateRecords = createAction(
	CustomReportListActions.UpdateRecords,
	props<{ response: MutationResponseDto | MutationResponseDto[] }>()
);
export const removeRecords = createAction(
	CustomReportListActions.RemoveRecords,
	props<{ response: MutationResponseDto | MutationResponseDto[] }>()
);
export const selectRecords = createAction(
	CustomReportListActions.SelectRecords,
	props<{ row: CustomReportListItemDto; selectionType: SelectionType }>()
);
