import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Subscription } from 'rxjs';

import { DocumentToBriefDto } from '@common/models/DocumentBriefs/Common/DocumentToBriefDto';
import { BriefSection } from '@common/models/DocumentBriefs/Item/BriefSection';
import { DocumentBriefListItemDto } from '@common/models/DocumentBriefs/List/DocumentBriefListItemDto';
import { NotificationService } from '@common/notification';
import { DocumentBriefsService } from '@common/services/documentbriefs.service';
import { CustomValidators } from '@common/validation/custom.validators';
import { Store } from '@ngrx/store';

import { IAppState } from 'app/core/state/app.state';
import { processRecords } from 'app/core/state/lists/document-list/document-list.actions';
import { DocumentsService } from 'app/services/documents.service';

import { IBriefDialogData } from './briefDialogData';

@Component({
	styleUrls: ['./brief-dialog.component.scss'],
	templateUrl: 'brief-dialog.component.html'
})
export class BriefDialogComponent implements OnInit, OnDestroy {
	form: FormGroup;
	briefs: DocumentBriefListItemDto[];
	sections: BriefSection[];
	private subscriptions: Subscription = new Subscription();

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: IBriefDialogData,
		private dialogRef: MatDialogRef<BriefDialogComponent>,
		private fb: FormBuilder,
		private documentsService: DocumentsService,
		private documentBriefsService: DocumentBriefsService,
		private notifService: NotificationService,
		private store: Store<IAppState>
	) {}
	ngOnInit() {
		this.form = this.fb.group({
			briefId: [null, CustomValidators.required('Brief')],
			sectionId: [null]
		});
		this.subscriptions.add(
			this.documentBriefsService
				.getDocumentBriefList({ matterId: this.data.matterId, sortBy: 'name' })
				.subscribe(res => {
					this.briefs = res.records;
				})
		);

		this.subscriptions.add(
			this.form.get('briefId').valueChanges.subscribe(briefId => {
				this.sections = this.briefs.filter(x => x.id === briefId)[0].sections;
			})
		);
	}
	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}
	associateBrief() {
		const dto: DocumentToBriefDto = {
			briefId: this.form.get('briefId').value,
			sectionId: this.form.get('sectionId').value,
			documentIds: this.data.documentIds
		};
		this.subscriptions.add(
			this.documentsService.addDocumentsToBrief(dto).subscribe({
				next: response => {
					this.store.dispatch(processRecords({ response: response }));

					this.notifService.showNotificationLink({
						text: `${this.data.documentIds.length} documents added to the Brief`
					});
					const briefId = this.form.get('briefId').value;
					this.dialogRef.close({ id: briefId, name: this.briefs.filter(x => x.id === briefId)[0].name });
				},
				error: errors => this.notifService.showErrors('Cannot associate document', errors)
			})
		);
	}
}
