// This file has been generated from ContactLookupDto.cs
// Do not modify this file directly!!
// tslint:disable

import { ContactType } from '../Common/ContactType';

// LawMaster.Amalthea.Domain.Contacts.Lookup.ContactLookupDto
export class ContactLookupDto {

	// Id
	id: string;
	// Title
	title: string;
	// ShortName
	shortName: string;
	// FullName
	fullName: string;
	// Company
	company: string;
	// JobTitle
	jobTitle: string;
	// AdditionalInfo
	additionalInfo: string;
	// Email
	email: string;
	// MatterRole
	matterRole: string;
	// Type
	type: keyof typeof ContactType;

	constructor() {

	}
}