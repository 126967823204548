import { Directive, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatOptionSelectionChange } from '@angular/material/core';

import { Subscription } from 'rxjs';

import { TrustMediaType } from '@common/models/Settings/Setting/Item/TrustMediaType';
import { TrustTransactionSequenceTypeDto } from '@common/models/Settings/TrustSettings/TrustAccounts/Item/TrustTransactionSequenceTypeDto';
import { TrustAccountListItemDto } from '@common/models/Settings/TrustSettings/TrustAccounts/List/TrustAccountListItemDto';
import { TransactionType } from '@common/models/Trust/Common/TransactionType';
import { TrustAccountsCachedService } from '@common/services/settings/trustaccounts-cached.service';
import { TrustAccountsService } from '@common/services/settings/trustaccounts.service';
import { filter as _filter, get } from 'lodash';
import { isNil } from 'lodash-es';

@Directive()
export abstract class BaseTrustRecordComponent<TCreateDto> implements OnInit, OnDestroy {
	@Input()
	editId: string;
	@Input()
	transactionType: keyof typeof TransactionType;
	@Input()
	trustAccountId: string;
	@Input()
	isReadOnly?: boolean;
	@Output()
	formStatusChanged = new EventEmitter<string>();
	@Output()
	titleChanged = new EventEmitter<string>();

	trustAccounts: TrustAccountListItemDto[];
	currentTrustAccount: TrustAccountListItemDto;
	transactionTypeEnum: typeof TransactionType = TransactionType;
	form: FormGroupTyped<TCreateDto>;
	nextChequeNumber: number = null;

	protected subscription: Subscription = new Subscription();

	constructor(
		protected trustAccountService: TrustAccountsService,
		protected trustAccountCachedService: TrustAccountsCachedService
	) {}

	ngOnInit() {
		this.subscription.add(
			this.form.statusChanges.subscribe(next => {
				this.formStatusChanged.emit(next);
			})
		);

		// override standard dialog title
		this.titleChanged.emit(`Create ${this.transactionTypeEnum[this.transactionType]}`);

		if (this.trustAccountId) {
			this.trustAccountService.getTrustAccount(this.trustAccountId).subscribe(response => {
				const trustAccount: TrustAccountListItemDto = {
					bankDetails: response.bankDetails,
					id: response.id,
					isHighlighted: false,
					currentBalance: response.currentBalance,
					clearedBalance: response.clearedBalance,
					isDefault: false,
					trustCreditDays: response.trustCreditDays,
					receiptTemplateId: response.receiptTemplateId,
					depositTemplateId: response.depositTemplateId,
					statutoryMatterId: response.statutoryMatterId
				};
				this.trustAccounts = [trustAccount];
				get(this.form.controls, 'trustAccountId').patchValue(this.trustAccountId);
			});
		} else {
			this.subscription.add(
				this.trustAccountCachedService.getTrustAccountList().subscribe(response => {
					this.trustAccounts = response;
					const defaultAccount = _filter(this.trustAccounts, { isDefault: true });
					if (!isNil(defaultAccount) && defaultAccount.length > 0 && this.trustAccounts.length > 0) {
						const control = get(this.form.controls, 'trustAccountId');
						if (control && !control.value) {
							control.patchValue(defaultAccount[0].id);
						}
					}
				})
			);
		}
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	checkNumberIsPristine() {
		const request = this.form.value;
		return (
			get(this.form, 'controls.number.pristine') === true ||
			(!isNil(get(request, 'number')) &&
				!isNil(this.nextChequeNumber) &&
				get(request, 'number') === this.nextChequeNumber.toString())
		);
	}

	isPaymentCheque() {
		return (
			(this.transactionType === 'Payment' || this.transactionType === 'BillPayment') &&
			get(this.form, 'controls.mediaDetails.controls.mediaType.value') === TrustMediaType.Cheque
		);
	}

	populateNextNumberField() {
		if (this.isReadOnly) return;
		const trustaccountId = get(this.form, 'controls.trustAccountId.value');
		const mediaType = get(this.form, 'controls.mediaDetails.controls.mediaType.value');
		let populateNextNumber: boolean = this.allowCheckNumberEditing();

		if (isNil(trustaccountId)) {
			populateNextNumber = false;
		}

		const numbercontrol = get(this.form, 'controls.number');
		if (populateNextNumber) {
			const dto: TrustTransactionSequenceTypeDto = {
				transactionSequenceType: mediaType === 'EFT' ? 'PaymentEFT' : 'PaymentCheque',
				trustAccountId: trustaccountId
			};

			this.subscription.add(
				this.trustAccountService.getLastSequenceNumber(dto).subscribe(lastVal => {
					this.nextChequeNumber = lastVal + 1;
					numbercontrol.setValue(this.nextChequeNumber.toString());
					numbercontrol.markAsPristine();
				})
			);
		} else {
			numbercontrol.setValue(null);
			numbercontrol.markAsPristine();
		}
	}

	onSaveError() {
		// abstract method, implement if required in childs
	}

	protected trustAccountChanged(event: MatOptionSelectionChange, item: TrustAccountListItemDto) {
		if (event.source.selected) {
			this.currentTrustAccount = item;
		}
	}
	protected allowCheckNumberEditing() {
		return !this.isReadOnly && this.isPaymentCheque();
	}
}
