import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';
import { MutationType } from '@common/models/Common/MutationType';

@Injectable({ providedIn: 'root' })
export class EntityChangedEventService {
	private _onEntityChangedEvent$ = new BehaviorSubject<EntityChangedEventArgs | null>(null);

	get onEntityChangedEvent$(): Observable<EntityChangedEventArgs | null> {
		return this._onEntityChangedEvent$;
	}

	fireEntityChangedEvent(mutations: MutationResponseDto | MutationResponseDto[]) {
		if (!mutations) return;
		mutations = mutations instanceof Array ? mutations : [mutations];
		mutations.forEach(mutation => {
			if (!!mutation?.id && !!mutation.values) {
				this._onEntityChangedEvent$.next({ ...mutation });
				if (mutation.childMutations?.length > 0) this.fireEntityChangedEvent(mutation.childMutations);
			}
		});
	}
}
interface EntityChangedEventArgs {
	id: string;
	mutationType: keyof typeof MutationType;
	values: { [key: string]: any };
}
