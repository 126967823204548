// This file has been generated from TenantMessaging.cs
// Do not modify this file directly!!
// tslint:disable

import { SetSimpleType } from '@common/utils/class-transform';



// LawMaster.Amalthea.Domain.Settings.Setting.Item.TenantMessaging
export class TenantMessaging {

	// IsLockedOut
	@SetSimpleType(Boolean)
	isLockedOut: boolean;
	// EnableDialogMessage
	@SetSimpleType(Boolean)
	enableDialogMessage: boolean;
	// DialogMessage
	dialogMessage: string;
	// DialogButtonUrl
	dialogButtonUrl: string;
	// DialogButtonText
	dialogButtonText: string;
	// EnableFooterMessage
	@SetSimpleType(Boolean)
	enableFooterMessage: boolean;
	// FooterMessage
	footerMessage: string;

	constructor() {

	}
}