import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

import * as moment from 'moment-timezone';
import { Moment } from 'moment-timezone';

@Component({
	selector: 'popup-datepicker',
	template: `
		<mat-form-field>
			<mat-placeholder *ngIf="placeHolder"
				>{{ placeHolder }}
				<sup class="color-warn" *ngIf="!!Required">*</sup>
			</mat-placeholder>
			<input
				matInput
				[formControl]="FormControl"
				(ngModelChange)="onChange($event)"
				[matDatepicker]="datepicker"
				autocomplete="off"
			/>
			<mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
			<mat-datepicker #datepicker></mat-datepicker>
			<mat-hint *ngIf="!!hintText?.length">{{ hintText }}</mat-hint>
			<mat-error>
				<error-messages [for]="FormControl"></error-messages>
			</mat-error>
		</mat-form-field>
	`
})
export class PopupDatepickerComponent {
	// The main value for binding
	@Input()
	FormControl: FormControl;
	@Input()
	Placeholder: string;
	@Input()
	Required: boolean;
	@Input()
	MinDate: Moment;
	@Input()
	MaxDate: Moment;
	@Input()
	placeHolder: string;
	@Input()
	hintText: string;
	@Output()
	ValueChange: EventEmitter<Moment> = new EventEmitter<Moment>();

	// Propagate the event
	onChange(val: Moment) {
		this.ValueChange.emit(val);
	}

	// There appears to be an issue in material when supplying these as [min]/[max] to the input
	// The min/max value from the validator is not updated when the min/max value is changed
	// Removed `[min]="min" [max]="max"` from the html template until this can be rectified
	get min() {
		if (this.MinDate) {
			return moment(this.MinDate).startOf('day');
		}
		return null;
	}

	get max() {
		if (this.MaxDate) {
			return moment(this.MaxDate).endOf('day');
		}
		return null;
	}
}
