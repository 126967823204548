import { Injectable } from '@angular/core';

import { interval, of } from 'rxjs';
import { catchError, debounce, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { IAppState } from '../../../app.state';
import { loading, DocumentTagListActions } from './document-tag-list.actions';
import { DocumentTagService } from '@common/services/settings/documenttag.service';

@Injectable()
export class DocumentTagListEffects {
	loadDocumentTags$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				DocumentTagListActions.Load,
				DocumentTagListActions.SetPageIndex,
				DocumentTagListActions.SetPageSize,
				DocumentTagListActions.SetSortBy,
				DocumentTagListActions.SetSortDirection,
				DocumentTagListActions.SetFilters
			),
			tap(action => this.store.dispatch(loading())),
			withLatestFrom(this.store),
			debounce(() => interval(200)),
			switchMap(([action, state]) => {
				return this.documentTagService.getDocumentTagList(state.documentTagList.request).pipe(
					map(response => {
						return { type: DocumentTagListActions.LoadSuccess, response };
					}),
					catchError(error => of({ type: DocumentTagListActions.LoadFailed, error }))
				);
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<IAppState>,
		private documentTagService: DocumentTagService
	) {}
}
