import { DOCUMENT, formatNumber } from '@angular/common';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { combineLatest, debounceTime, distinctUntilChanged, map, startWith, Subscription, switchMap, tap } from 'rxjs';

import { EntityReference } from '@common/models/Common/EntityReference';
import { TemplateCollectionFieldDto } from '@common/models/Documents/TemplateDto/TemplateCollectionFieldDto';
import { TemplateEntityType } from '@common/models/Documents/TemplateDto/TemplateEntityType';
import { TemplateFieldDto } from '@common/models/Documents/TemplateDto/TemplateFieldDto';
import { TemplateFieldType } from '@common/models/Documents/TemplateDto/TemplateFieldType';
import { DocumentTemplatesService } from '@common/services/settings/documenttemplates.service';
import { isEmptyOrWhitespace } from '@common/utils/stringUtils';
import { concat, filter, forEach, isEqual, some } from 'lodash-es';

import { processUrls } from 'app/shared/utils/stringUtil';

interface IPreviewField {
	name: string;
	type: TemplateFieldType;
	value: any;
	currentFormat: string;
	itemProperties: IPreviewField[];
}

@Component({
	selector: 'available-fields-list',
	styleUrls: ['./available-fields-list.component.scss'],
	templateUrl: './available-fields-list.component.html'
})
export class AvailableFieldsListComponent implements AfterViewInit, OnInit, OnDestroy {
	@ViewChild('previewPaginator', { read: MatPaginator, static: true })
	paginator: MatPaginator;

	form: FormGroup;
	displayedColumns: string[] = ['copy', 'field', 'type', 'format', 'value'];
	entityTypeKeys = Object.keys(TemplateEntityType) as Array<keyof typeof TemplateEntityType>;

	previewEntity = new MatTableDataSource<IPreviewField>([]);

	defaultDecimal: string = '#,##0.00';
	defaultAmount: string = '$#,##0.00';
	supportedDecimalFormats: string[] = [this.defaultDecimal, this.defaultAmount, '#,###.#'];
	defaultDate: string = 'dd/MM/yyyy';
	defaultDateTime: string = 'dd/MM/yyyy hh:mm:ss tt';
	supportedDateFormat: string[] = [
		this.defaultDate,
		'dd.MM.yyyy',
		'd MMM yyyy',
		'd MMMM yyyy',
		'dd/MM/yyyy hh:mm tt',
		this.defaultDateTime,
		'dd/MM/yyyy HH:mm',
		'dd/MM/yyyy HH:mm:ss'
	];
	supportedStringFormat: string[] = ['upper', 'lower', 'caps', 'firstCap'];
	supportedFormats = concat(this.supportedStringFormat, this.supportedDateFormat, this.supportedDecimalFormats);

	templateEntityTypeEnum: typeof TemplateEntityType = TemplateEntityType;

	private subscriptions: Subscription = new Subscription();

	get contactId(): FormControl {
		return this.form.get('contactId') as FormControl;
	}

	get matterId(): FormControl {
		return this.form.get('matterId') as FormControl;
	}

	get invoiceId(): FormControl {
		return this.form.get('invoiceId') as FormControl;
	}

	get trustReceiptId(): FormControl {
		return this.form.get('trustReceiptId') as FormControl;
	}

	get trustDepositId(): FormControl {
		return this.form.get('trustDepositId') as FormControl;
	}

	get trustPaymentId(): FormControl {
		return this.form.get('trustPaymentId') as FormControl;
	}

	get briefId(): FormControl {
		return this.form.get('briefId') as FormControl;
	}

	get entityType(): FormControl {
		return this.form.get('entityType') as FormControl;
	}

	private window: Window;

	constructor(
		@Inject(DOCUMENT) private document: Document,
		private fb: FormBuilder,
		private documentTemplateService: DocumentTemplatesService
	) {
		this.window = this.document.defaultView;
	}

	ngOnInit() {
		this.form = this.fb.group({
			contactId: null,
			entityType: this.entityTypeKeys[0],
			invoiceId: null,
			matterId: null,
			briefId: null,
			trustPaymentId: null,
			trustReceiptId: null,
			trustDepositId: null,
			search: null,
			practiceAreaIds: []
		});

		this.subscriptions.add(
			this.entityType.valueChanges.subscribe(() =>
				this.form.patchValue({ contactId: null, matterId: null, invoiceId: null })
			)
		);

		this.previewEntity.filterPredicate = (data, filter) =>
			!filter?.length || data.name.toLowerCase().includes(filter);
	}

	ngAfterViewInit() {
		this.previewEntity.paginator = this.paginator;
		this.previewEntity.filterPredicate = (data, filter) => {
			if (!!isEmptyOrWhitespace(filter)) {
				return true;
			}

			const lowercaseFilter = filter.toLowerCase();

			if (data.type.toLowerCase().indexOf(lowercaseFilter) !== -1) {
				return true;
			}

			const nameFilter = data.name.toLowerCase();

			if (nameFilter.indexOf(lowercaseFilter) !== -1) {
				return true;
			} else {
				var parts = lowercaseFilter.split(' ');

				if (!!parts?.length) {
					let success = true;
					for (let index = 0; index < parts.length; index++) {
						if (nameFilter.indexOf(parts[index]) === -1) {
							success = false;
						}
					}

					if (!!success) {
						return true;
					}
				}
			}

			return false;
		};

		this.subscriptions.add(
			combineLatest(
				Object.entries(this.form.controls)
					.filter(([key, _]) => key != 'search')
					.map(([_, value]) => value.valueChanges.pipe(startWith(null)))
			)
				.pipe(
					map(() => {
						this.form.updateValueAndValidity();
						return this.form.value;
					}),
					distinctUntilChanged(isEqual),
					debounceTime(100),
					switchMap(() => this.refreshAvailableFields$())
				)
				.subscribe()
		);

		this.subscriptions.add(
			this.form
				.get('search')
				.valueChanges.pipe(debounceTime(500))
				.subscribe((search: string) => {
					if (!!isEmptyOrWhitespace(search)) {
						this.previewEntity.filter = null;
					}

					const parts = search
						.trim()
						.toLocaleLowerCase()
						.split(' ')
						.filter(part => !isEmptyOrWhitespace(part));

					this.previewEntity.filter =
						parts.length > 1 ? parts.reduce((left, right) => `${left} ${right}`) : parts[0];
				})
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	toMicrosoftFormattedDecimal(value: number, microsoftFormat: string) {
		if (!value || !microsoftFormat) {
			return value ? value.toString() : '';
		}
		switch (microsoftFormat) {
			case '$#,##0.00':
				return `$${formatNumber(value, 'en-AU', '1.2-2')}`;
			case '#,##0.00':
				return formatNumber(value, 'en-AU', '1.2-2');
			case '#,###.#':
				return formatNumber(value, 'en-AU', '0.0-1');
			default:
				return value.toString();
		}
	}

	buildFieldName(field: IPreviewField) {
		// exclude custom fields. all other collections should be rendered as a table.
		if (this.isCollectionField(field)) {
			return this.createEmptyTableForCollection(field);
		}
		const type = this.getFieldType(field);
		const needsQuotes = type === TemplateFieldType.DateTime || type === TemplateFieldType.Decimal;
		return `<<[${field.name}]${
			!!field.currentFormat ? `:${needsQuotes ? '"' : ''}${field.currentFormat}${needsQuotes ? '"' : ''}` : ''
		}>>`;
	}

	isCollectionField(field: IPreviewField) {
		if (field && field.name) {
			const fieldParts = field.name.split('__');
			return field.type === TemplateFieldType.Collection && fieldParts[fieldParts.length - 1] !== 'Field';
		}
		// we assume standard
		return false;
	}

	createEmptyTableForCollection(field: IPreviewField): string {
		const collectionName = field.name;

		// top row (titles)
		let table = `<table style='border-collapse:collapse;table-layout: auto; width:90%'><tr>`;
		forEach(field.itemProperties, p => {
			if (p.name.toLowerCase() === 'duration') {
				table += `<th style='border: 1px solid black;padding: 10px;'>${p.name} (hh:mm)</th> `;
			} else {
				table += `<th style='border: 1px solid black;padding: 10px;'>${p.name.replace(
					/([A-Z])/g,
					' $1'
				)}</th> `;
			}
		});

		// middle row (aspose for-each syntax with properties)
		table += `</tr><tr>`;
		for (let i = 0; i < field.itemProperties.length; i++) {
			const itemProperty = field.itemProperties[i];
			table += `<td style='border: 1px solid black; padding: 8px;'>`;
			// first element. add foreach opening tag
			if (i === 0) {
				table += `&lt;&lt;foreach [c in ${collectionName}]>> `;
			}

			const format =
				itemProperty.type === TemplateFieldType.DateTime
					? this.supportedDateFormat[0]
					: itemProperty.type === TemplateFieldType.Decimal
					? this.supportedDecimalFormats[1]
					: null;
			table += `<<[c.${itemProperty.name}]${format ? `:"${format}"` : ''}>>`;

			// last element. add foreach closing tag
			if (i === field.itemProperties.length - 1) {
				table += `&lt;&lt;/foreach -greedy>>`;
			}

			table += `</td>`;
		}

		table += `</tr>`;

		return table;
	}

	getPreviewMessageForCollection(field: IPreviewField) {
		const fieldParts = field.name.split('__');
		const collectionName = fieldParts[fieldParts.length - 1];
		// turn camel case into title case
		return `Preview for ${collectionName.replace(/([A-Z])/g, ' $1')} is not available`;
	}

	onContactSelected(contact: EntityReference): void {
		this.form.controls.contactId.setValue(contact.id);
	}

	onBriefSelected(brief: EntityReference): void {
		this.form.controls.briefId.setValue(brief.id);
	}

	copyField(field: IPreviewField) {
		const clipboardDiv = this.document.createElement('div');
		clipboardDiv.innerHTML = '';
		clipboardDiv.style.position = 'absolute';
		clipboardDiv.style.opacity = '0';
		this.document.body.appendChild(clipboardDiv);

		clipboardDiv.innerHTML = this.buildFieldName(field);
		clipboardDiv.focus();

		this.window.getSelection().removeAllRanges();
		const range = this.document.createRange();
		range.setStartBefore(clipboardDiv.firstChild);
		range.setEndAfter(clipboardDiv.lastChild);
		this.window.getSelection().addRange(range);

		this.document.execCommand('copy');
	}

	private transform(data: TemplateFieldDto[]): IPreviewField[] {
		return data
			.map(
				(d: TemplateCollectionFieldDto) =>
					({
						currentFormat:
							d.type === TemplateFieldType.DateTime
								? d.name.includes('Time')
									? this.defaultDateTime
									: this.defaultDate
								: d.type === TemplateFieldType.Decimal
								? d.name.includes('Total') ||
								  d.name.includes('Amount') ||
								  d.name.includes('Balance') ||
								  d.name.includes('Fee') ||
								  d.name.includes('User__ChargeRate')
									? this.defaultAmount
									: this.defaultDecimal
								: null,
						name: d.name,
						type: d.type,
						value:
							(d.type === TemplateFieldType.Unknown || d.type === TemplateFieldType.String) &&
							!!this.isString(d.value)
								? processUrls(d.value as string)
								: d.value,
						itemProperties: d.itemProperties
					} as IPreviewField)
			)
			.sort((left, right) => {
				if (left.type != right.type) {
					if (left.type === TemplateFieldType.Collection) {
						return -1;
					} else if (right.type === TemplateFieldType.Collection) {
						return 1;
					}
				}

				return 0;
			});
	}
	private isString(obj: any) {
		return Object.prototype.toString.call(obj) === '[object String]';
	}

	transformDateTimeFormat(format: string) {
		return format?.replace('tt', 'a');
	}

	getFieldType(field: IPreviewField): keyof typeof TemplateFieldType {
		return this.supportedDateFormat.includes(field.currentFormat)
			? TemplateFieldType.DateTime
			: this.supportedDecimalFormats.includes(field.currentFormat)
			? TemplateFieldType.Decimal
			: this.supportedStringFormat.includes(field.currentFormat)
			? TemplateFieldType.String
			: field.type;
	}

	private getAvailableFieldsData$() {
		this.form.get('invoiceId').setErrors(null);
		const financialStatement: keyof typeof TemplateEntityType = 'FinancialStatement';
		const trustStatement: keyof typeof TemplateEntityType = 'TrustStatement';
		const briefTitlePage: keyof typeof TemplateEntityType = 'BriefTitlePage';
		switch (this.entityType.value) {
			case financialStatement:
				if (this.matterId.value) {
					return this.documentTemplateService.getMatterFinancialStatementFieldValues(this.matterId.value);
				}
				return this.documentTemplateService.getMatterFinancialStatementFields();
			case TemplateEntityType.Matter:
				if (this.matterId.value) {
					return this.documentTemplateService.getMatterFieldValues(this.matterId.value);
				}
				return this.documentTemplateService.getMatterFields();
			case trustStatement:
				if (this.matterId.value) {
					return this.documentTemplateService.getMatterTrustStatementFieldValues(this.matterId.value);
				}
				return this.documentTemplateService.getMatterTrustStatementFields();
			case TemplateEntityType.Contact:
				if (this.contactId.value) {
					return this.documentTemplateService.getContactFieldValues(this.contactId.value);
				}
				return this.documentTemplateService.getContactFields();
			case TemplateEntityType.Invoice:
				if (this.invoiceId.value) {
					return this.documentTemplateService.getInvoiceFieldValues(this.invoiceId.value);
				}
				return this.documentTemplateService.getInvoiceFields();
			case TemplateEntityType.Receipt:
				if (this.trustReceiptId.value) {
					return this.documentTemplateService.getTrustRecordFieldValues({
						number: this.trustReceiptId.value,
						transactionType: 'Receipt'
					});
				}
				return this.documentTemplateService.getTrustRecordFields({
					transactionType: 'Receipt',
					number: null
				});
			case TemplateEntityType.Deposit:
				if (this.trustDepositId.value) {
					return this.documentTemplateService.getTrustRecordFieldValues({
						number: this.trustDepositId.value,
						transactionType: 'Deposit'
					});
				}
				return this.documentTemplateService.getTrustRecordFields({
					transactionType: 'Deposit',
					number: null
				});
			case TemplateEntityType.Payment:
				if (this.trustPaymentId.value) {
					return this.documentTemplateService.getTrustRecordFieldValues({
						number: this.trustPaymentId.value,
						transactionType: 'Payment'
					});
				}
				return this.documentTemplateService.getTrustRecordFields({
					transactionType: 'Payment',
					number: null
				});
			case briefTitlePage:
				if (this.briefId.value) {
					return this.documentTemplateService.getBriefFieldValues(this.briefId.value);
				}
				return this.documentTemplateService.getBriefFields();
			default:
				throw new Error('Error for devs - Unable to resolve the TemplateEntityType');
		}
	}

	private refreshAvailableFields$() {
		return this.getAvailableFieldsData$().pipe(
			tap(data => {
				this.previewEntity.data = this.transform(data);

				if (this.invoiceId.value) {
					// If the invoice number does not exist, show an error
					if (!some(filter(data, { name: 'Invoice__Number' }), 'value')) {
						this.form.get('invoiceId').setErrors({ errorText: 'Invoice does not exist' });
					}
				}
				if (this.trustReceiptId.value) {
					// If the Receipt number does not exist, show an error
					if (!some(filter(data, { name: 'TrustReceipt__ReceiptNumber' }), 'value')) {
						this.form.get('trustReceiptId').setErrors({ errorText: 'Receipt does not exist' });
					}
				}
				if (this.trustPaymentId.value) {
					// If the Payment number does not exist, show an error
					if (!some(filter(data, { name: 'TrustReceipt__PaymentNumber' }), 'value')) {
						this.form.get('trustPaymentId').setErrors({ errorText: 'Payment does not exist' });
					}
				}
				if (this.trustDepositId.value) {
					// If the Receipt number does not exist, show an error
					if (!some(filter(data, { name: 'TrustDeposit__DepositNumber' }), 'value')) {
						this.form.get('trustDepositId').setErrors({ errorText: 'Deposit does not exist' });
					}
				}
			})
		);
	}
}
