import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { EntityCalculationDetailsDto } from '@common/models/Calculations/List/EntityCalculationDetailsDto';
import { CustomFieldEntityType } from '@common/models/Settings/CustomFields/Common/CustomFieldEntityType';
import { ContactCustomFieldsService } from '@common/services/customfields-contact.service';
import { MatterCustomFieldsService } from '@common/services/customfields-matter.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import moment from 'moment';

import { IAppState } from '../../app.state';
import { CalculationVariableListActions, loadFailed, loading, loadSuccess } from './calculation-variable-list.actions';

@Injectable()
export class CalculationVariableListEffects {
	loadList$ = createEffect(() =>
		this._actions$.pipe(
			ofType(CalculationVariableListActions.Load),
			map(action => action as { entityType: keyof typeof CustomFieldEntityType }),
			withLatestFrom(this._store),
			filter(([action, state]) => {
				const cacheExpiry =
					action.entityType === CustomFieldEntityType.Contact
						? state.calculationVariableList?.contactVariables?.cacheExpiry
						: state.calculationVariableList?.matterVariables?.cacheExpiry;

				return !cacheExpiry || !!moment().isSameOrAfter(cacheExpiry);
			}),
			debounceTime(500),
			tap(([action, _]) => this._store.dispatch(loading({ entityType: action.entityType }))),
			switchMap(([action]) =>
				(action.entityType === CustomFieldEntityType.Contact
					? this._contactCustomFieldsService.getContactCalculationDetails()
					: this._matterCustomFieldsService.getMatterCalculationDetails()
				).pipe(
					map((response: EntityCalculationDetailsDto) =>
						loadSuccess({ entityType: action.entityType, response: response?.variables })
					),
					catchError(error => of(loadFailed({ entityType: action.entityType, error: error })))
				)
			)
		)
	);

	constructor(
		private _actions$: Actions,
		private _store: Store<IAppState>,
		private _matterCustomFieldsService: MatterCustomFieldsService,
		private _contactCustomFieldsService: ContactCustomFieldsService
	) {}
}
