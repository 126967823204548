import { ListRequest } from '@common/models/Generic/ListRequest';
import { InfoTrackStatusListItemDto } from '@common/models/InfoTrack/Orders/List/InfoTrackStatusListItemDto';
import { DomainError } from '@common/models/Validation/DomainError';
import { createAction, props } from '@ngrx/store';

export enum InfotrackStatusFilterActions {
	InvalidateCache = '[Infotrack Status Filter] Invalidate Cache',
	Load = '[Infotrack Status Filter] Load',
	Loading = '[Infotrack Status Filter] Loading',
	LoadSuccess = '[Infotrack Status Filter] Load Success',
	LoadFailed = '[Infotrack Status Filter] Load Failed',

	SetFilters = '[Infotrack Status Filter] Set Filters'
}

export const load = createAction(InfotrackStatusFilterActions.Load);
export const loading = createAction(InfotrackStatusFilterActions.Loading);
export const loadSuccess = createAction(
	InfotrackStatusFilterActions.LoadSuccess,
	props<{ response: InfoTrackStatusListItemDto[] }>()
);
export const loadFailed = createAction(InfotrackStatusFilterActions.LoadFailed, props<{ error: DomainError }>());

export const setFilters = createAction(
	InfotrackStatusFilterActions.SetFilters,
	props<{ filter: Partial<ListRequest> }>()
);

export const invalidateCache = createAction(InfotrackStatusFilterActions.InvalidateCache);
