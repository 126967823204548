import { Action } from '@ngrx/store';

import { IMatterNumberingConfigState } from './matter-numbering-config.state';

// tslint:disable:max-classes-per-file
export enum MatterNumberingConfigActions {
	Refresh = '[MatterNumberingConfig] Refresh',
	Set = '[MatterNumberingConfig] Set'
}

export class SetMatterNumberingConfigAction implements Action {
	readonly type = MatterNumberingConfigActions.Set;

	constructor(public data: IMatterNumberingConfigState) {}
}
