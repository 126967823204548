import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AfterContentInit, Component, Inject, OnDestroy, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatOption, MatOptionSelectionChange } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatListOption } from '@angular/material/list';
import { MatSelect, MatSelectChange } from '@angular/material/select';

import { Observable, Subscription } from 'rxjs';

import { EntityReference } from '@common/models/Common/EntityReference';
import { EnumSortDirection } from '@common/models/Common/EnumSortDirection';
import { BriefTemplateSection } from '@common/models/DocumentBriefTemplates/Item/BriefTemplateSection';
import { DocumentBriefTemplateDto } from '@common/models/DocumentBriefTemplates/Item/DocumentBriefTemplateDto';
import { PracticeAreaListItemDto } from '@common/models/Settings/PracticeAreas/List/PracticeAreaListItemDto';
import { NotificationService } from '@common/notification';
import { DocumentBriefTemplatesService } from '@common/services/documentbrieftemplates.service';
import { PracticeAreasService } from '@common/services/settings/practiceareas.service';

import { DocumentsService } from 'app/services/documents.service';
import { DocumentTagComponent } from 'app/shared/components/document-tag.component';
import { newGuid } from '@common/utils/create-guid';
import { CustomValidators } from '@common/validation/custom.validators';

@Component({
	selector: 'create-brief-template-dialog',
	styleUrls: ['./create-brief-template-dialog.component.scss'],
	templateUrl: './create-brief-template-dialog.component.html'
})
export class CreateBriefTemplateDialogComponent implements OnInit, OnDestroy, AfterContentInit {
	private subscriptions: Subscription = new Subscription();

	form: FormGroup;

	sections: BriefTemplateSection[] = [];
	tags: string[];
	practiceAreas: PracticeAreaListItemDto[];

	@ViewChild(MatListOption)
	sectionList: MatListOption;

	@ViewChild('selectPracticeAreas', { static: true })
	selectPracticeAreas: MatSelect;

	@ViewChild('allOption', { static: true })
	allOption: MatOption;

	@ViewChildren(DocumentTagComponent)
	tagComponents: DocumentTagComponent[];

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: IDocumentBriefTemplateDialogData,
		private fb: FormBuilder,
		private documentService: DocumentsService,
		private documentBriefTemplatesService: DocumentBriefTemplatesService,
		private practiceAreaService: PracticeAreasService,
		private notification: NotificationService,
		private dialogRef: MatDialogRef<CreateBriefTemplateDialogComponent>
	) {}

	ngOnInit(): void {
		this.form = this.fb.group({
			name: [null, CustomValidators.required('Name')],
			sections: this.fb.group([]),
			associatedPracticeAreaIds: null
		});
	}

	fetchRelatedData(): void {
		if (!!this.data?.briefTemplateId) {
			this.subscriptions.add(
				this.documentBriefTemplatesService.getTemplate(this.data.briefTemplateId).subscribe(briefTemplate => {
					this.form.patchValue(briefTemplate);
					this.updateModel(briefTemplate.sections);

					if (
						!briefTemplate.associatedPracticeAreaIds ||
						briefTemplate.associatedPracticeAreaIds.length === 0
					) {
						this.allOption.select();
					}

					this.refreshSystemTags();
				})
			);
		} else {
			this.allOption.select();
			this.refreshSystemTags();
		}
	}

	ngAfterContentInit(): void {
		this.subscriptions.add(
			this.practiceAreaService
				.getPracticeAreaList({ sortBy: 'name', sortDirection: EnumSortDirection.Asc })
				.subscribe(next => {
					this.practiceAreas = next.records;
					this.fetchRelatedData();
				})
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	handleOptionClicked(event: MouseEvent): void {
		event.stopPropagation();
	}

	get availableTags() {
		if (this.tags) {
			return this.tags.filter(tag => !this.sections.map(x => x.name).includes(tag));
		}
		return null;
	}

	// Update the form data when editing an existing custom field
	updateModel(sections: BriefTemplateSection[]) {
		sections.forEach(section => this.addSection(section.name, section.id, section.documentTags));
	}

	drop(event: CdkDragDrop<string[]>) {
		moveItemInArray(this.sections, event.previousIndex, event.currentIndex);
	}

	save() {
		if (this.data?.briefTemplateId) this.saveExistingBrief();
		else this.createBrief();
	}

	saveExistingBrief() {
		this.handleSaveResponse(
			this.documentBriefTemplatesService.updateTemplate(this.data.briefTemplateId, this.createTemplateDto()),
			true
		);
	}

	createBrief() {
		this.handleSaveResponse(this.documentBriefTemplatesService.addTemplate(this.createTemplateDto()), false);
	}

	private getAssociatedPracticeAreaIds(): string[] {
		let areas = this.form.value.associatedPracticeAreaIds as string[];

		if (!!areas?.length) {
			areas = areas.filter(value => !!value);
		}

		return areas;
	}

	private createTemplateDto(): DocumentBriefTemplateDto {
		const sections = this.sections;

		const dto = {
			name: this.form.get('name').value,
			sections: sections.map(
				(section, index) =>
					({
						name: this.getSectionControl(section.id).controls.name.value,
						documentTags: this.getSectionControl(section.id).controls.documentTags.value,
						id: section.id,
						orderNumber: index + 1
					} as BriefTemplateSection)
			),
			associatedPracticeAreaIds: this.getAssociatedPracticeAreaIds()
		};

		return dto;
	}

	private handleSaveResponse(observable: Observable<EntityReference>, isUpdating: boolean) {
		this.subscriptions.add(
			observable.subscribe(
				response => this.dialogRef.close(response),
				errors =>
					this.notification.showErrors(
						isUpdating ? 'Error updating Brief Template' : 'Error creating Brief Template',
						errors
					)
			)
		);
	}

	getSectionControl(sectionId: string): FormGroup {
		return (this.form.controls.sections as FormGroup).controls[sectionId] as FormGroup;
	}

	getControlFromSectionControl(sectionId: string, formControlName: string): FormControl {
		return this.getSectionControl(sectionId).get(formControlName) as FormControl;
	}

	addSection(name: string = null, id: string = null, tags: string[] = null) {
		const sectionId = !!id ? id : newGuid();

		const sectionControl = this.fb.group({
			name: [null, CustomValidators.required()],
			documentTags: []
		});

		(this.form.get('sections') as FormGroup).addControl(sectionId, sectionControl);

		if (!!name) {
			sectionControl.controls.name.setValue(name);
		}

		if (!!tags) {
			sectionControl.controls.documentTags.setValue(tags);
		}

		this.sections.push({
			id: sectionId,
			name,
			orderNumber: this.sections.length,
			documentTags: [],
			documentIds: []
		});

		setTimeout(() => this.refreshTagComponents(), 0);
	}

	removeSection(id: string, index: number) {
		(this.form.get('sections') as FormGroup).removeControl(id);
		this.sections.splice(index, 1);

		setTimeout(() => this.refreshTagComponents(), 0);
	}

	allPracticeAreaOptionSelected(event: MatOptionSelectionChange) {
		if (event.source.selected) {
			if (!!this.selectPracticeAreas?.options) {
				// When selecting the 'All' option, deselect all practice areas
				this.selectPracticeAreas.options.forEach(opt => {
					if (opt.selected && opt.id !== this.allOption.id) {
						opt.deselect();
					}
				});
			}
		} else if (!this.selectPracticeAreas.options.some(opt => opt.selected)) {
			// If nothing is selected, select the 'All' option
			this.allOption.select();
		}
	}

	practiceAreaOptionSelected(event: MatOptionSelectionChange, selectedOption: MatSelect) {
		if (this.allOption.selected && event.source.selected && event.source.id !== this.allOption.id) {
			// When selecting a practice area, deselect the 'All' option
			this.allOption.deselect();
		} else if (!!selectedOption && (!selectedOption.options || !selectedOption.options.some(opt => opt.selected))) {
			// If nothing is selected, select the 'All' option
			this.allOption.select();
		}
	}

	practiceAreaSelectionChanged(event: MatSelectChange) {
		this.form.value.associatedPracticeAreaIds = event.value;

		this.refreshSystemTags();
	}

	private refreshSystemTags() {
		const practiceAreas = this.getAssociatedPracticeAreaIds();

		const observable =
			practiceAreas?.length > 0
				? this.documentService.getSystemTags({ practiceAreaIds: practiceAreas })
				: this.documentService.getSystemTags();

		this.subscriptions.add(
			observable.subscribe(tags => {
				this.tags = tags.sort((a, b) => {
					return a.toLowerCase().localeCompare(b.toLowerCase());
				});
				this.refreshTagComponents();
			})
		);
	}

	private refreshTagComponents() {
		if (!!this.tagComponents && this.tagComponents.length > 0) {
			this.tagComponents.forEach(tagComponent => {
				tagComponent.setTags(this.tags);
			});
		}
	}
}

export interface IDocumentBriefTemplateDialogData {
	briefTemplateId: string;
}
