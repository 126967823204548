<mat-card>
	<button mat-raised-button color="accent" [disabled]="disableButtons" throttleButton (throttledClick)="onGenerateClicked()">
		<mat-icon>note_add</mat-icon>
		<span><span class="hiddenPhone">Generate </span>New Manifest</span>
	</button>
	<button mat-raised-button color="primary" [disabled]="disableButtons" throttleButton (throttledClick)="onRebuildClicked()">
		<mat-icon>build_circle</mat-icon>
		<span>Rebuild<span class="hiddenPhone"> My Marketplace Packages</span></span>
	</button>
	<marketplace-vendor-package-list #list></marketplace-vendor-package-list>
</mat-card>
