<mat-sidenav-container>
	<mat-sidenav [disableClose]="!isMobile" [class.collapsed]="isCollapsed">
		<mat-nav-list>
			<mat-list-item class="logo" [routerLink]="dashboardLink" throttleButton (throttledClick)="closeMenu()">
				<img class="logo" [src]="alternativeIcon" [alt]="imageAlt" />
				<span>{{ siteName }}</span>
			</mat-list-item>
			<mat-list-item
				*ngFor="let item of siteMap$ | async"
				[routerLink]="item.mainLink"
				[class.active]="isMenuButtonHighlighted(item)"
				throttleButton
				(throttledClick)="closeMenu()"
			>
				<a [routerLink]="item.mainLink" class="menu-link">
					<mat-icon [matTooltipDisabled]="!isCollapsed" matTooltipPosition="right" [matTooltip]="item.title">{{ item.icon }}</mat-icon>
					<span>{{ item.title }}</span>
				</a>
			</mat-list-item>
			<mat-divider class="separator" *ngIf="!isMobile"></mat-divider>

			<a throttleButton (throttledClick)="onKnowledgeBaseClick($event)">
				<mat-list-item throttleButton (throttledClick)="closeMenu()">
					<mat-icon [matTooltipDisabled]="!isCollapsed" matTooltipPosition="right" matTooltip="Knowledge Base">help_outline</mat-icon>
					<span>Knowledge Base</span>
				</mat-list-item>
			</a>

			<mat-list-item
				*ngIf="!!hasAccessToSettings && !!hasAnyRolePermissions()"
				[routerLink]="['/system']"
				[class.active]="isUrl('/system')"
				throttleButton
				(throttledClick)="closeMenu()"
			>
				<a [routerLink]="['/system']" class="menu-link">
					<mat-icon [matTooltipDisabled]="!isCollapsed" matTooltipPosition="right" matTooltip="System">settings</mat-icon>
					<span>System</span>
				</a>
			</mat-list-item>
			<mat-list-item *ngIf="!isMobile" throttleButton (throttledClick)="expandCollapseSideMenu()">
				<mat-icon>{{ isCollapsed ? 'arrow_forward' : 'arrow_backward' }}</mat-icon>
			</mat-list-item>
			<ng-template [ngIf]="isMobile">
				<mat-divider class="separator"></mat-divider>
				<mat-list-item routerLink="./system/users/{{ userId }}" throttleButton (throttledClick)="closeMenu()">
					<mat-icon>person_outline</mat-icon>
					<span>User Profile</span>
				</mat-list-item>
				<mat-list-item throttleButton (throttledClick)="logout()">
					<mat-icon>exit_to_app</mat-icon>
					<span>Logout</span>
				</mat-list-item>
			</ng-template>
		</mat-nav-list>
	</mat-sidenav>

	<mat-sidenav-content>
		<ng-container *ngIf="isMobile; then mobileMenu; else desktopMenu"></ng-container>

		<ng-content></ng-content>
	</mat-sidenav-content>
</mat-sidenav-container>

<ng-template #desktopMenu>
	<mat-toolbar>
		<omnisearch class="omni-search"></omnisearch>

		<button
			*ngIf="!isPhoneTabletBreakpoint"
			mat-raised-button
			color="primary"
			class="create-button"
			[matMenuTriggerFor]="createMenu"
			(menuOpened)="openedCreateMenu()"
		>
			<mat-icon>add</mat-icon>
			<span>Create</span>
		</button>

		<button *ngIf="!!isPhoneTabletBreakpoint" mat-icon-button [matMenuTriggerFor]="createMenu" (menuOpened)="openedCreateMenu()">
			<mat-icon color="primary">add</mat-icon>
		</button>

		<button mat-icon-button class="recent-item-button" [matMenuTriggerFor]="recentItemMenu" (menuOpened)="openedRecentItemsMenu()">
			<mat-icon color="primary">history</mat-icon>
		</button>

		<ng-container *tenantFeature="'Tasks'">
			<button mat-icon-button class="task-button" #taskMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="taskMenu" (menuOpened)="openedTaskMenu()">
				<mat-chip-list *ngIf="taskCount">
					<mat-chip color="accent" selected="true" throttleButton (throttledClick)="taskMenuTrigger._handleClick($event)">
						{{ taskCount }}
					</mat-chip>
				</mat-chip-list>
				<mat-icon color="primary">assignment_turned_in</mat-icon>
			</button>
		</ng-container>

		<jobs-menu-button (menuOpened)="openedJobsMenu()"></jobs-menu-button>

		<timers-menu-button [featureFlag]="featureFlags.globalTimer" style="display: flex;">
			<mat-icon color="primary">timer</mat-icon>
		</timers-menu-button>

		<button mat-button class="account-button" [matMenuTriggerFor]="accountMenu" (menuOpened)="openedAccountMenu()">
			<user-avatar [gravatarId]="gravatarId"></user-avatar>
			<span>{{ userName }}</span>
			<mat-icon color="primary">keyboard_arrow_down</mat-icon>
		</button>
	</mat-toolbar>
</ng-template>

<ng-template #mobileMenu>
	<mat-toolbar color="primary">
		<button mat-icon-button class="menu-button" throttleButton (throttledClick)="openMenu()">
			<mat-icon>menu</mat-icon>
		</button>

		<span>{{ mobileTitle }}</span>

		<button mat-icon-button [matMenuTriggerFor]="createMenu" (menuOpened)="openedCreateMenu()">
			<mat-icon>add</mat-icon>
		</button>

		<ng-container *tenantFeature="'Tasks'">
			<button mat-icon-button class="task-button" #taskMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="taskMenu" (menuOpened)="openedTaskMenu()">
				<mat-chip-list *ngIf="taskCount">
					<mat-chip color="accent" selected="true" throttleButton (throttledClick)="taskMenuTrigger._handleClick($event)">
						{{ taskCount }}
					</mat-chip>
				</mat-chip-list>
				<mat-icon>assignment_turned_in</mat-icon>
			</button>
		</ng-container>

		<timers-menu-button [featureFlag]="featureFlags.globalTimer" style="display: flex;">
			<mat-icon>timer</mat-icon>
		</timers-menu-button>

		<button mat-icon-button throttleButton (throttledClick)="openSearchDialog()" (menuOpened)="openedSearchMenu()">
			<mat-icon>search</mat-icon>
		</button>
	</mat-toolbar>
</ng-template>

<mat-menu class="create-menu" #createMenu="matMenu" [overlapTrigger]="false" [xPosition]="isMobile ? 'before' : 'after'">
	<button mat-menu-item disabled *ngIf="isMobile || isTablet">
		<span>Create</span>
	</button>
	<ng-container *tenantFeature="'CostRecords'">
		<button mat-menu-item throttleButton (throttledClick)="openCreateTimeRecordDialog()">
			<mat-icon color="accent">access_time</mat-icon>
			<span>Time/Fee Record</span>
		</button>
		<button mat-menu-item throttleButton (throttledClick)="openCreateExpenseDialog()">
			<mat-icon color="accent">local_atm</mat-icon>
			<span>Expense</span>
		</button>
	</ng-container>
	<button mat-menu-item throttleButton (throttledClick)="openCreateNoteDialog()">
		<mat-icon color="accent">description</mat-icon>
		<span>Note</span>
	</button>
	<button mat-menu-item throttleButton (throttledClick)="openCreateMatterDialog()">
		<mat-icon color="accent">gavel</mat-icon>
		<span>Matter</span>
	</button>
	<button mat-menu-item throttleButton (throttledClick)="openCreateContactDialog()">
		<mat-icon color="accent">person</mat-icon>
		<span>Contact</span>
	</button>
	<ng-container *tenantFeature="'Tasks'">
		<button mat-menu-item throttleButton (throttledClick)="openCreateTaskDialog()">
			<mat-icon color="accent">assignment_turned_in</mat-icon>
			<span>Task</span>
		</button>
	</ng-container>
	<ng-container *tenantFeature="'TrustAccounting'">
		<button
			mat-menu-item
			throttleButton
			(throttledClick)="openCreateTrustRecordDialog()"
			[featureFlag]="featureFlags.trustAccountingFeature"
			*ngIf="hasRolePermission('TrustCreateTransactions')"
		>
			<mat-icon color="accent">account_balance</mat-icon>
			<span>Trust Record</span>
		</button>
	</ng-container>
</mat-menu>

<mat-menu class="recent-menu speech-menu" #recentItemMenu="matMenu" [overlapTrigger]="false" xPosition="before">
	<button mat-menu-item disabled>
		<span>Recent Items</span>
	</button>
	<ng-template ngFor let-recentItem [ngForOf]="recentItems">
		<ng-container [ngSwitch]="recentItem.relatedToType">
			<button mat-menu-item class="mat-menu-text" *ngSwitchCase="'Documents'" throttleButton (throttledClick)="gotoRecentDocument(recentItem)">
				<div class="mat-menu-major-text">
					<span>{{ recentItem.relatedTo.name }}</span>
				</div>
				<div class="mat-menu-minor-text">
					<mat-icon
						[svgIcon]="fileIcon($any(recentItem.relatedTo).fileExtension)"
						[ngClass]="fileIcon($any(recentItem.relatedTo).fileExtension)"
					></mat-icon>
					<span>{{ humanizeTimestamp(recentItem.timestamp) }}</span>
				</div>
			</button>
			<button mat-menu-item class="mat-menu-text" *ngSwitchCase="'Tasks'" throttleButton (throttledClick)="gotoRecentTask(recentItem)">
				<div class="mat-menu-major-text">
					<span>{{ recentItem.relatedTo.name }}</span>
				</div>
				<div class="mat-menu-minor-text" [ngSwitch]="recentItem.actionReferenceType">
					<mat-icon color="accent">assignment_turned_in</mat-icon>
					<span>{{ humanizeTimestamp(recentItem.timestamp) }}</span>
				</div>
			</button>
			<button
				mat-menu-item
				class="mat-menu-text"
				*ngSwitchCase="'Trust'"
				[routerLink]="['/', recentItem.relatedToType?.toLowerCase()]"
				[queryParams]="{ pageIndexForId: recentItem.relatedTo?.id }"
			>
				<div class="mat-menu-major-text">
					<span>Trust record {{ recentItem.relatedTo?.name }}</span>
				</div>
				<div class="mat-menu-minor-text">
					<mat-icon color="accent">account_balance</mat-icon>
					<span>{{ humanizeTimestamp(recentItem.timestamp) }}</span>
				</div>
			</button>
			<button
				mat-menu-item
				class="mat-menu-text"
				*ngSwitchCase="'TrustReconciliations'"
				[routerLink]="['/reconciliation-editor', recentItem.relatedTo?.id]"
			>
				<div class="mat-menu-major-text">
					<span>Trust reconciliation {{ recentItem.relatedTo?.name }}</span>
				</div>
				<div class="mat-menu-minor-text">
					<mat-icon color="accent">ballot</mat-icon>
					<span>{{ humanizeTimestamp(recentItem.timestamp) }}</span>
				</div>
			</button>
			<button mat-menu-item class="mat-menu-text" *ngSwitchDefault [routerLink]="['/', recentItem.relatedToType.toLowerCase(), recentItem.relatedTo.id]">
				<div class="mat-menu-major-text">
					<span>{{ recentItem.relatedTo.name }}</span>
				</div>
				<div class="mat-menu-minor-text" [ngSwitch]="recentItem.relatedToType">
					<mat-icon *ngSwitchCase="'Matters'" color="accent">gavel</mat-icon>
					<mat-icon *ngSwitchCase="'Contacts'" color="accent">person</mat-icon>
					<span>{{ humanizeTimestamp(recentItem.timestamp) }}</span>
				</div>
			</button>
		</ng-container>
	</ng-template>
	<button mat-menu-item disabled *ngIf="!recentItems?.length" class="additional-action">No recent items</button>
	<button mat-menu-item *ngIf="recentItems?.length" [routerLink]="['/system/activity-log']" class="additional-action view-all">
		<mat-icon class="view-all-icon">search</mat-icon>View all recent items
	</button>
</mat-menu>

<mat-menu class="task-menu speech-menu" #taskMenu="matMenu" [overlapTrigger]="false" xPosition="before">
	<ng-template ngFor let-section [ngForOf]="sectionKeys">
		<button mat-menu-item disabled class="mat-menu-item-with-chip">
			<span>{{ $any(sections)[section].title }}</span>
			<mat-chip-list>
				<mat-chip selected="true" [color]="$any(sections)[section].color"
					>{{ $any(currentTasks)[section].records.length }}
					<ng-template [ngIf]="$any(currentTasks)[section].totalRecords > $any(currentTasks)[section].records.length">
						of {{ $any(currentTasks)[section].totalRecords }}</ng-template
					>
				</mat-chip>
			</mat-chip-list>
		</button>
		<ng-template ngFor let-task [ngForOf]="$any(currentTasks)[section]?.records">
			<ng-template [ngIf]="task.associatedMatter">
				<button
					mat-menu-item
					class="mat-menu-text"
					[routerLink]="['/matters', task.associatedMatter.id, 'tasks']"
					[queryParams]="{ pageIndexForId: task.id }"
				>
					<div class="mat-menu-major-text">
						<span> {{ task.description }}</span>
					</div>
					<div class="mat-menu-minor-text">
						<mat-icon color="accent">gavel</mat-icon>
						<span>{{ task.associatedMatter.name }}</span>
					</div>
				</button>
			</ng-template>
			<ng-template [ngIf]="task.associatedContact">
				<button
					mat-menu-item
					class="mat-menu-text"
					[routerLink]="['/contacts', task.associatedContact.id, 'tasks']"
					[queryParams]="{ pageIndexForId: task.id }"
				>
					<div class="mat-menu-major-text">
						<span> {{ task.description }}</span>
					</div>
					<div class="mat-menu-minor-text">
						<mat-icon color="accent">person</mat-icon>
						<span>{{ task.associatedContact.name }}</span>
					</div>
				</button>
			</ng-template>
			<ng-template [ngIf]="!task.associatedContact && !task.associatedMatter">
				<button
					mat-menu-item
					class="mat-menu-text"
					[routerLink]="['/tasks/' + (section === 'overdue' ? 'myoverdue' : 'my')]"
					[queryParams]="{ pageIndexForId: task.id }"
				>
					<div class="mat-menu-major-text">
						<span> {{ task.description }}</span>
					</div>
				</button>
			</ng-template>
		</ng-template>
		<button mat-menu-item [routerLink]="['/tasks/' + subRoute(section)]" class="additional-action">
			<mat-icon color="primary">search</mat-icon>
			{{ $any(sections)[section].link }}
		</button>
	</ng-template>
	<button mat-menu-item disabled *ngIf="!sectionKeys?.length">No current tasks</button>
</mat-menu>

<mat-menu #accountMenu="matMenu" [overlapTrigger]="false" xPosition="before">
	<a mat-menu-item routerLink="./system/users/{{ userId }}">
		<mat-icon color="accent">person_outline</mat-icon>
		<span>User Profile</span>
	</a>
	<button mat-menu-item throttleButton (throttledClick)="logout()">
		<mat-icon color="accent">exit_to_app</mat-icon>
		<span>Logout</span>
	</button>
	<a mat-menu-item *ngIf="isMattero" [href]="lawMasterAppClientUrl" [featureFlag]="featureFlags.swapTheme">
		<img class="menu-logo" src="/assets/img/logo_lawmaster.svg" alt="LawMaster Icon" />
		<span>Switch To LawMaster</span>
	</a>
	<a mat-menu-item *ngIf="isLawMaster" [href]="matteroAppClientUrl" [featureFlag]="featureFlags.swapTheme">
		<img class="menu-logo" src="/assets/img/logo_mattero.svg" alt="Mattero Icon" />
		<span>Switch To Mattero</span>
	</a>
</mat-menu>
