import { HttpResponse } from '@angular/common/http';
import { AfterViewInit, Directive, EventEmitter, Input, OnDestroy, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortHeader, SortDirection } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';

import { BehaviorSubject, fromEvent, merge as mergeObservable, Observable, Subscription } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';

import { BaseListItemDto } from '@common/models/Common/BaseListItemDto';
import { IdCollectionRequest } from '@common/models/Common/IdCollectionRequest';
import { ListResponse } from '@common/models/Generic/ListResponse';
import { arraysEqual } from '@common/utils/arrayUtils';
import { getFilenameFromHttpHeader } from '@common/utils/fileNameUtil';
import { nameof } from '@common/utils/nameof';
import * as FileSaver from 'file-saver';
import { get, isNil, isPlainObject, max, min, omitBy } from 'lodash-es';
import * as moment from 'moment-timezone';

import { AppConfig } from 'app/app.config';
import { GridViewService, IFilterDefaultsConfig } from 'app/services/grid-view.service';

import { ExportDialogComponent } from '../components/export-dialog.component';
import { GridLayoutChangesDialogComponent } from '../components/grid-layout/grid-layout-changes-dialog.component';
import { FilterRootComponent } from '../filter-controls';
import { FilterChangeProperties } from '../filter-controls/filter-change-properties';
import { DataSourceRequest, GenericDataSource } from './generic.data.source';

@Directive()
export abstract class GenericListBaseComponent<TListItemDto extends BaseListItemDto, TFilter>
	implements AfterViewInit, OnDestroy
{
	@Input()
	listElement?: HTMLElement;
	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;
	@ViewChild(MatSort, { static: true })
	sort: MatSort;
	@ViewChild(MatTable, { static: true })
	table: MatTable<TListItemDto>;
	// Reference to the component containing filters
	@Input()
	filterComponent: FilterRootComponent<TFilter>;
	// Add additional filtering applied before the Filter Component
	@Input()
	set FilterBase(val: Partial<TFilter>) {
		if (val !== this.FilterBase) this.filterBase.next(val);
	}
	get FilterBase(): Partial<TFilter> {
		return this.filterBase.getValue();
	}

	// Displayed columns. Override this property if you need conditional hiding of some columns
	get displayedColumns(): string[] {
		return this.defaultDisplayedColumns;
	}

	// List of records from the Data Source
	get listItems(): TListItemDto[] {
		return !!this.datasource && !!this.datasource.response ? this.datasource.response.records : null;
	}

	get selectedListItems(): TListItemDto[] {
		return this.listItems ? this.listItems.filter(r => r.isHighlighted) : null;
	}

	datasource: GenericDataSource<TListItemDto, TFilter>;
	pageSizeOptions: number[] = AppConfig.PageSizeOptions;
	get pageSize(): number {
		return this._pageSize;
	}

	set pageSize(value: number) {
		this._pageSize = value;
	}

	protected subscriptions = new Subscription();
	protected pageIndexForId = new BehaviorSubject<string>(null);

	protected filterBase = new BehaviorSubject<Partial<TFilter>>(null);
	private clearSort: boolean = false;
	// This is used for requested page (from URL) only, bind to the value from the response in the UI
	protected pageIndex: number = 0;
	protected requests = new EventEmitter<DataSourceRequest<TFilter>>();
	private lastSelectedRow: TListItemDto;

	private filterDefaultsSet = false;

	private _pageSize: number = AppConfig.PageSize;

	protected enableSortStorage = false;

	private isManualSort: boolean = false;

	constructor(
		protected dialog: MatDialog,
		protected router: Router,
		protected activatedRoute: ActivatedRoute,
		protected defaultDisplayedColumns: string[],
		protected gridViewService: GridViewService,
		delegate: (dto?: Partial<TFilter>) => Observable<ListResponse<TListItemDto>>
	) {
		this.datasource = new GenericDataSource<TListItemDto, TFilter>(delegate, this.requests);
	}

	// Subscribe for filter triggers after the nested components get initialised.
	// Hence, do it in AfterViewInit, instead of ngOnInit.
	// If we were using 'BehaviorSubject' for 'triggers', then the current value'd have been emited in the subscriber,
	// but we're using 'EventEmitter', so we expect that 'DataSource.connect()' is called and have subscribed for events
	ngAfterViewInit() {
		if (!!this.sort) {
			this.sort.disableClear = true;
		}

		// Initialise the Page Index/Size and Sorting from the QueryString
		this.subscriptions.add(
			this.activatedRoute.queryParamMap
				.pipe(
					distinctUntilChanged(),
					// Make sure we modify the page/sort properties (and receive the data) after a processing delay to avoid
					// ExpressionChangedAfterItHasBeenCheckedError.
					// This seems like a poor design, but given that we rely on the view children to get the sort properties
					// (the html defines the default sort,) I don't see how we can avoid it.
					delay(0)
				)
				.subscribe(params => {
					if (params.has('pageIndexForId')) {
						this.pageIndexForId.next(params.get('pageIndexForId'));
					}

					// Set Page Index / Size (with defaults if not in the url)
					this.pageIndex = +params.get('pageIndex') || 0;
					this.pageSize = this.pageSize || +params.get('pageSize') || AppConfig.PageSize;

					// Set grid Sorting
					if (!!this.sort) {
						if (!!params.has('sortBy') && !!this.sort.sortables.get(params.get('sortBy'))) {
							this.sort.active = params.get('sortBy');
							this.sort.direction = (params.get('sortDirection') || '') as SortDirection;
						} else {
							// AM-679 We can't modify the search options when not supplied because we need the default
							// sort to apply when opening the page. I'd like to apply the defaults sort here, but we
							// can't do that either because we need to clear the sort when searching for a keyword
							// (and display by relevance instead.) The real fix is to put something in the url to
							// differentiate between default sort and sort by relevance. If we did that we could also
							// avoid redirecting the url on initial load (to add the default sort and page params.)
							// this.clearSorting();
						}
					}

					// Refresh the grid
					this.refreshList();
				})
		);

		this.subscriptions.add(
			fromEvent(document, 'keydown')
				.pipe(filter(Boolean))
				.subscribe((e: KeyboardEvent) => {
					if (document.activeElement?.localName !== 'body') return;

					const key = e.key.toLowerCase();

					if (e.ctrlKey && key === 'a') {
						this.selectAllKeyPressed(e);
					} else if (key === 'escape') {
						this.clearSelectionKeyPressed(e);
					}
				})
		);

		// List of all triggers, which can cause refreshing data in the grid
		// Reset back to the first page if we change filters (anything, except the page number)
		const triggers = [
			this.paginator
				? this.paginator.page.pipe(
						tap((page: PageEvent) => {
							if (!!page) {
								this.pageIndex = page.pageSize !== this.pageSize ? 0 : page.pageIndex;
								this.pageSize = page.pageSize;
								this.paginationHandler(page);
							}
						})
				  ) // tslint:disable-line:indent
				: undefined,
			this.sort?.sortChange.pipe(
				map(() => {
					try {
						return !this.isManualSort;
					} finally {
						this.isManualSort = false;
					}
				}),
				filter(Boolean),
				tap(() => {
					this.pageIndex = 0;
				})
			),
			this.filterBase.pipe(filter(Boolean)),
			this.filterComponent ? this.filterComponent.filterChange.pipe(tap(() => (this.pageIndex = 0))) : undefined
		];

		if (this.filterComponent) {
			// Clear out the sorting whenever we change the 'search' field so that results are displayed based on relevance
			const searchControl: AbstractControl = this.filterComponent.form.get('search');
			if (searchControl) {
				this.subscriptions.add(
					searchControl.valueChanges
						.pipe(distinctUntilChanged(), filter(Boolean))
						.subscribe(() => (this.clearSort = true))
				);
			}

			if (this.paginator) {
				this.subscriptions.add(
					this.paginator.page.subscribe(() => {
						this.pageIndexForId = null;
					})
				);
			}
		}

		this.subscriptions.add(
			mergeObservable(...triggers.filter(Boolean))
				.pipe(
					// wait for all of the components to finish updating the filter before refreshing the page
					debounceTime(100),
					map(triggerObject => {
						// clear selection(s) when filter / sort is triggered
						this.clearSelection();

						// If we have changed the search filter then clear the sorting on the next request
						if (this.clearSort) {
							this.clearSort = false;
							this.clearSorting();
						}
						const replaceUrl = get(triggerObject, nameof<FilterChangeProperties<any>>('replaceUrl'), false);
						// Get merged filter from all the Query Parameters
						const accruedFilter = this.mergeFilters(false);

						return {
							queryParams: accruedFilter,
							replaceUrl
						};
					}),
					distinctUntilChanged((a, b) => {
						if (a.replaceUrl !== b.replaceUrl) {
							return false;
						}

						if (!a.queryParams && !b.queryParams) {
							return true;
						}

						if (!!a.queryParams && !!b.queryParams) {
							return arraysEqual(Object.values(a.queryParams), Object.values(b.queryParams));
						}

						return false;
					})
				)
				.subscribe(request => {
					// Update the QueryString
					this.router.navigate([], {
						queryParams: request.queryParams,
						relativeTo: this.activatedRoute,
						replaceUrl: request.replaceUrl
					});
				})
		);

		// Get Filter Values
		// This is required for the ExpressionChangedAfterItHasBeenCheckedError error.
		setTimeout(() => this.loadFilterDefaultsValues(), 0);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	clearSorting(): void {
		this.sort?.sort({ id: null, start: null, disableClear: false });
	}

	isColumnVisible(columnName: string): boolean {
		return this.displayedColumns.indexOf(columnName) !== -1;
	}

	refreshList(force: boolean = true): void {
		const userFilter = this.mergeFilters(true);
		if (!!(userFilter as any)?.sortBy) {
			(userFilter as any).sortBy = this.filterSortBy((userFilter as any).sortBy);
		}

		// Update the Data Source
		this.requests.next({ filter: userFilter, force });
	}

	selectRow(selectedRow: TListItemDto, event: MouseEvent): void {
		if (event.ctrlKey || event.metaKey) {
			this.handleRowSelectionWithCtrlKey(selectedRow);
		} else if (event.shiftKey) {
			this.handleRowSelectionWithShiftKey(selectedRow);
			this.clearTextSelection();
		} else {
			// no keys pressed. default behaviour
			this.handleRowSelectionWithNoKeys(selectedRow);
		}
	}

	protected subscribeToEntityCreationNotificationEvent(event: EventEmitter<void>): void {
		this.subscriptions.add(event.subscribe(() => this.refreshList()));
	}

	protected showExportDialog<TIdCollectionRequest extends IdCollectionRequest>(
		title: string,
		dialog: MatDialog,
		exportByIds: (request: TIdCollectionRequest) => Observable<HttpResponse<Blob>>,
		exportByFilter: (request: TFilter) => Observable<HttpResponse<Blob>>,
		message: string = null,
		exportButtonText: string = 'Export'
	): void {
		// Get total number of exporting records
		const totalFilteredRecords =
			this.datasource && this.datasource.response ? this.datasource.response.totalRecords : 0;

		const totalRecords = this.selectedListItems.length || totalFilteredRecords;

		const dialogRef = dialog.open(ExportDialogComponent, {
			data: {
				recordsCount: totalRecords,
				title: title,
				displayedColumns: this.displayedColumns,
				message: message,
				exportButtonText: exportButtonText
			},
			width: '350px'
		});

		// Initiate download on the prompted action
		this.subscriptions.add(
			dialogRef
				.afterClosed()
				.pipe(
					filter(Boolean),
					switchMap(() => {
						// Selected items have priority over the filter
						if (this.selectedListItems.length) {
							const request = {
								ids: this.selectedListItems.map(r => r.id)
							} as TIdCollectionRequest;
							return exportByIds(request);
						}
						return exportByFilter(this.mergeFilters(true));
					})
				)
				// Call browaser download function for the server response
				.subscribe(response => {
					FileSaver.saveAs(
						response.body,
						getFilenameFromHttpHeader(response.headers.get('content-disposition'))
					);
					this.clearSelection();
				})
		);
	}

	protected paginationHandler(page: PageEvent): void {
		// Implementation in 'document-list.component.ts'
	}

	protected filterSortBy(sortBy: string): string {
		return sortBy;
	}

	// Add filtering for the list
	protected mergeFilters(includeFilterBase: boolean): TFilter {
		const accruedFilter = Object.assign(
			{},
			includeFilterBase ? this.FilterBase : {},
			this.filterComponent ? this.filterComponent.filter : {},
			{
				pageIndex: this.pageIndex,
				pageSize: this.pageSize
			},
			this.sort && this.sort?.active ? { sortBy: this.sort?.active, sortDirection: this.sort?.direction } : {}
		);

		return Object.keys(accruedFilter).reduce<TFilter>((prev, curr) => {
			let value = { [curr]: accruedFilter[curr] };
			// Convert 'Custom Filters' to a dictionary (flatten out filters, so they can go to the query string)
			if (isPlainObject(accruedFilter[curr])) {
				value = Object.keys(omitBy(accruedFilter[curr], isNil)).reduce(
					(p, c) =>
						Object.assign(p, {
							[`${curr}[${c}]`]: moment.isMoment(accruedFilter[curr][c])
								? accruedFilter[curr][c].format('YYYY-MM-DD')
								: accruedFilter[curr][c]
						}),
					{}
				);
			}
			return Object.assign(prev, value);
		}, {} as TFilter);
	}

	protected selectAll(): void {
		if (this.listItems) {
			this.listItems.forEach(record => (record.isHighlighted = true));
		}
	}

	protected clearSelection(): void {
		if (this.listItems) {
			this.listItems.forEach(r => (r.isHighlighted = false));
		}
	}

	// This is a hack to address sorting bugs present in angular MatSort
	// https://github.com/angular/components/issues/10242#issuecomment-470726829
	//
	// Todo: Cleanup when Google fixes the issue
	protected sortBy(column: string, direction: 'asc' | 'desc'): void {
		direction = direction || 'asc';

		this.isManualSort = true;
		this.sort?.sort({ id: null, start: direction, disableClear: false });
		this.sort?.sort({ id: column, start: direction, disableClear: false });

		(this.sort?.sortables.get(column) as MatSortHeader)?._setAnimationTransitionState({ toState: 'active' });
	}

	hasStoredFilterValues() {
		return this.filterDefaultsSet;
	}

	protected loadFilterDefaultsValues() {
		if (!this.enableSortStorage) {
			return false;
		}

		const stored = this.gridViewService.getFilterDefaults();

		let foundValues = false;

		if (!!stored?.pageSize) {
			this._pageSize = stored.pageSize;
			foundValues = true;
		}

		if (!!stored?.sortColumn || !!stored?.sortDirection) {
			this.sortBy(stored.sortColumn, stored.sortDirection === 'desc' ? 'desc' : 'asc');
			foundValues = true;
		}

		this.filterDefaultsSet = true;

		return foundValues;
	}

	getDefaultFilterSettings(): IFilterDefaultsConfig {
		throw new Error('Not Implemented');
	}

	storeFilterDefaults() {
		if (!this.enableSortStorage) {
			return;
		}

		const defaults = this.getDefaultFilterSettings();

		const dto: IFilterDefaultsConfig = {
			pageSize: this.pageSize ?? defaults.pageSize,
			sortColumn: this.sort?.active ?? defaults.sortColumn,
			sortDirection: this.sort?.direction ?? defaults.sortDirection
		};

		this.subscriptions.add(
			this.dialog
				.open(GridLayoutChangesDialogComponent, {
					data: {
						old: this.displayFilterConfig(this.gridViewService.getFilterDefaults() ?? defaults),
						new: this.displayFilterConfig(dto),
						isReset: false
					}
				})
				.afterClosed()
				.pipe(filter(Boolean))
				.subscribe(() => this.gridViewService.storeFilterDefaults(dto))
		);
	}

	private displayFilterConfig(dto: IFilterDefaultsConfig): IFilterDefaultsConfig {
		return {
			sortColumn: this.displayColumnName(dto.sortColumn),
			sortDirection: dto.sortDirection,
			pageSize: dto.pageSize
		};
	}

	protected columnDefinitionNameMap: { [key: string]: () => string };

	private displayColumnName(columnDefintionName: string) {
		const mapFunction = this.columnDefinitionNameMap[columnDefintionName];

		if (!!mapFunction) {
			return mapFunction();
		}

		return columnDefintionName;
	}

	getFilterDefaults() {
		return this.gridViewService.getFilterDefaults() ?? this.getDefaultFilterSettings();
	}

	resetFilterDefaults() {
		if (!this.enableSortStorage) {
			return;
		}

		const defaults = this.getDefaultFilterSettings();

		this.subscriptions.add(
			this.dialog
				.open(GridLayoutChangesDialogComponent, {
					data: {
						old: this.displayFilterConfig(this.gridViewService.getFilterDefaults() ?? defaults),
						new: this.displayFilterConfig(defaults),
						isReset: true
					}
				})
				.afterClosed()
				.pipe(filter(Boolean))
				.subscribe(() => {
					this.gridViewService.resetFilterDefaults();

					this.sortBy(defaults.sortColumn, defaults.sortDirection === 'desc' ? 'desc' : 'asc');
					this.pageSize = defaults.pageSize;
				})
		);
	}

	private handleRowSelectionWithNoKeys(selectedRow: TListItemDto): void {
		this.clearSelection();
		selectedRow.isHighlighted = true;
		this.lastSelectedRow = selectedRow;
	}

	private handleRowSelectionWithCtrlKey(selectedRow: TListItemDto): void {
		selectedRow.isHighlighted = !selectedRow.isHighlighted;
		this.lastSelectedRow = selectedRow;
	}

	private handleRowSelectionWithShiftKey(selectedRow: TListItemDto): void {
		if (!this.lastSelectedRow) {
			this.handleRowSelectionWithNoKeys(selectedRow);
			return;
		}
		if (!this.listItems) return;

		const indexOfPreviouslySelectedRow = this.listItems.indexOf(this.lastSelectedRow);
		const indexOfSelectedRow = this.listItems.indexOf(selectedRow);

		if (indexOfPreviouslySelectedRow === indexOfSelectedRow) {
			return;
		}

		// reset the selected rows unless same row is selected
		this.clearSelection();

		const startIndex = min([indexOfPreviouslySelectedRow, indexOfSelectedRow]);
		const endIndex = max([indexOfPreviouslySelectedRow, indexOfSelectedRow]);

		for (let i = startIndex; i <= endIndex; i++) {
			this.listItems[i].isHighlighted = true;
		}
	}

	private clearTextSelection(): void {
		const selection = window.getSelection ? window.getSelection() : get(document, 'selection');
		if (selection) {
			if (selection.removeAllRanges) {
				selection.removeAllRanges();
			} else if (selection.empty) {
				selection.empty();
			}
		}
	}

	private selectAllKeyPressed(e: KeyboardEvent): void {
		e.stopPropagation();
		e.preventDefault();
		this.selectAll();
	}

	private clearSelectionKeyPressed(e: KeyboardEvent): void {
		e.stopPropagation();
		e.preventDefault();
		this.clearSelection();
	}
}
