// This file has been generated from SecuredUrlMappingRequestDto.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.AppServer.Orders.Item.InfoTrack.SecuredUrlMappingRequestDto
export class SecuredUrlMappingRequestDto {

	// ClientReference
	clientReference: string;
	// RetailerReference
	retailerReference: string;

	constructor() {

	}
}