<mat-form-field class="duration-form-field">
	<mat-placeholder>Duration
		<sup class="color-warn" *ngIf="Required">*</sup>
	</mat-placeholder>
	<input #durationInput matInput [formControl]="inputDisplayCtrl" (blur)="onBlur()" (focus)="onFocus($event)" autocomplete="off" />
	<mat-hint>{{ hint }}</mat-hint>
	<mat-error>
		<error-messages [for]="inputDisplayCtrl"></error-messages>
	</mat-error>
</mat-form-field>
