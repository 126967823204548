import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { filter, switchMap, take } from 'rxjs/operators';

import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';
import { DocumentTemplateListItemDto } from '@common/models/Documents/List/DocumentTemplateListItemDto';
import { TemplateEntityType } from '@common/models/Documents/TemplateDto/TemplateEntityType';
import { TrustAccountCreateUpdateDto } from '@common/models/Settings/TrustSettings/TrustAccounts/Item/TrustAccountCreateUpdateDto';
import { TrustAccountListItemDto } from '@common/models/Settings/TrustSettings/TrustAccounts/List/TrustAccountListItemDto';
import { DomainError } from '@common/models/Validation/DomainError';
import { NotificationService } from '@common/notification';
import { DocumentTemplatesService } from '@common/services/settings/documenttemplates.service';
import { TrustAccountsCachedService } from '@common/services/settings/trustaccounts-cached.service';
import { TrustAccountsService } from '@common/services/settings/trustaccounts.service';
import { Store } from '@ngrx/store';
import { flatten } from 'lodash-es';

import { IAppState } from 'app/core/state/app.state';
import { TrustAccountListActions } from 'app/core/state/lists/settings/trust-account-list/trust-account-list.actions';
import {
	selectTrustAccountIsFetching,
	selectTrustAccountListRecords,
	selectTrustAccountListRequest,
	selectTrustAccountListTotalRecords
} from 'app/core/state/lists/settings/trust-account-list/trust-account-list.selectors';
import { TrustOpeningBalanceDialogComponent } from 'app/create-forms/trust/openingBalanceReceipt/trust-opening-balance-receipt-dialog.component';
import { GridViewService } from 'app/services/grid-view.service';
import {
	ActionTypes,
	GenericListStateComponent,
	SelectorTypes
} from 'app/shared/generics/generic.list.state.component';

import {
	ITrustAccountDialogParams,
	TrustAccountDialogComponent
} from '../create-edit-account/trust-account-dialog.component';
import { TrustAccountNumberingSettingsDialogComponent } from '../edit-trust-numbering-setiings/edit-trust-numbering-setiings.component';
import { ITrustAccountDialogData } from '../ITrustAccountDialogData';

@Component({
	selector: 'trust-accounts-list',
	styleUrls: ['./trust-accounts-list.component.scss'],
	templateUrl: './trust-accounts-list.component.html'
})
export class TrustAccountsListComponent
	extends GenericListStateComponent<TrustAccountListItemDto, void>
	implements OnInit
{
	get actions(): ActionTypes {
		return {
			init: TrustAccountListActions.Init,
			load: TrustAccountListActions.Load,
			setPageIndex: TrustAccountListActions.SetPageIndex,
			setPageIndexForId: TrustAccountListActions.SetPageIndexForId,
			setPageSize: TrustAccountListActions.SetPageSize,
			setSortBy: TrustAccountListActions.SetSortBy,
			setSortDirection: TrustAccountListActions.SetSortDirection,
			selected: TrustAccountListActions.SelectRecords
		};
	}
	get selectors(): SelectorTypes {
		return {
			records: selectTrustAccountListRecords,
			isFetching: selectTrustAccountIsFetching,
			request: selectTrustAccountListRequest,
			totalRecords: selectTrustAccountListTotalRecords
		};
	}
	receiptTemplates: DocumentTemplateListItemDto[];
	depositTemplates: DocumentTemplateListItemDto[];

	constructor(
		store: Store<IAppState>,
		router: Router,
		activatedRoute: ActivatedRoute,
		private trustAccountsService: TrustAccountsService,
		private trustAccountsCachedService: TrustAccountsCachedService,
		dialog: MatDialog,
		private notificationService: NotificationService,
		private templatesService: DocumentTemplatesService,
		gridViewService: GridViewService
	) {
		super(
			['bankName', 'accountName', 'countryId', 'accountNumber', 'currentBalance', 'clearedBalance', 'actions'],
			dialog,
			store,
			router,
			activatedRoute,
			gridViewService
		);

		this.subscriptions.add(
			this.templatesService
				.getDocumentTemplateList({
					entityType: TemplateEntityType.Receipt
				})
				.subscribe(list => {
					this.receiptTemplates = flatten(Object.values(list.records));
				})
		);

		this.subscriptions.add(
			this.templatesService
				.getDocumentTemplateList({
					entityType: TemplateEntityType.Deposit
				})
				.subscribe(list => {
					this.depositTemplates = flatten(Object.values(list.records));
				})
		);
	}

	ngOnInit(): void {
		super.ngOnInit();
	}

	createTrustAccount() {
		const data: ITrustAccountDialogParams = {
			id: null,
			receiptTemplates: this.receiptTemplates,
			depositTemplates: this.depositTemplates
		};
		this.subscriptions.add(
			this.dialog
				.open(TrustAccountDialogComponent, { data })
				.afterClosed()
				.pipe(
					filter(Boolean),
					switchMap((dialogData: ITrustAccountDialogData) =>
						this.trustAccountsService.createTrustAccount(dialogData.accountData)
					)
				)
				.subscribe(result => {
					if (result) {
						this.onCreateSuccess(result);
					}
				}, this.onError)
		);
	}

	editNumberingSettings(row: TrustAccountListItemDto) {
		this.dialog.open(TrustAccountNumberingSettingsDialogComponent, {
			data: row.id,
			width: '450px'
		});
	}

	addOpeningBalanceReceipts(row: TrustAccountListItemDto) {
		this.subscriptions.add(
			this.dialog
				.open(TrustOpeningBalanceDialogComponent, {
					data: {
						trustAccountId: row.id,
						trustRecordId: null
					},
					width: '700px',
					maxWidth: '80vw',
					hasBackdrop: true,
					disableClose: true
				})
				.afterClosed()
				.pipe(filter(Boolean), take(1))
				.subscribe(result => {
					this.store.dispatch({ type: TrustAccountListActions.UpdateRecords });
				}, this.onError)
		);
	}

	editTrustAccount(row: TrustAccountListItemDto) {
		const data: ITrustAccountDialogParams = {
			id: row.id,
			receiptTemplates: this.receiptTemplates,
			depositTemplates: this.depositTemplates
		};

		this.subscriptions.add(
			this.dialog
				.open(TrustAccountDialogComponent, { data })
				.afterClosed()
				.pipe(
					filter(Boolean),
					switchMap((dialogData: ITrustAccountDialogData) => {
						if (dialogData.isDelete) {
							this.deleteTrustAccount(dialogData.accountData);
							return null;
						} else {
							return this.trustAccountsService.updateTrustAccount(
								dialogData.accountData.id,
								dialogData.accountData
							);
						}
					})
				)
				.subscribe(result => {
					if (result) {
						this.onUpdateSuccess(result);
					} else {
						this.onDeleteSuccess(result);
					}
				}, this.onError)
		);
	}

	deleteTrustAccount(row: TrustAccountCreateUpdateDto) {
		this.subscriptions.add(
			this.notificationService
				.showConfirmation(
					'Delete Trust Account',
					`Are you sure you want to delete the trust account "${row.bankDetails.accountName}"?`
				)
				.pipe(
					filter(Boolean),
					switchMap(() => this.trustAccountsService.deleteTrustAccount(row.id))
				)
				.subscribe({
					next: response => this.onDeleteSuccess(response),
					error: error => this.notificationService.showErrors('Error deleting Trust Account', error)
				})
		);
	}

	setDefault(row: TrustAccountCreateUpdateDto) {
		this.subscriptions.add(
			this.trustAccountsService.setDefaultTrustAccount(row.id).subscribe(
				() => {
					this.notificationService.showNotification(
						`Trust Account ${row.bankDetails.accountName} has been set as the default account`
					);
					this.store.dispatch({ type: TrustAccountListActions.UpdateRecords });
				},
				err => {
					this.notificationService.showError(`There was an error completing the operation`, err);
				}
			)
		);
	}

	private onError = (errors: DomainError[]) => {
		this.notificationService.showErrors('Error', errors);
	};

	private onCreateSuccess = (result: MutationResponseDto) => {
		this.notificationService.showNotification(`Trust Account ${result.name} created`);
		this.trustAccountsCachedService.clearCache();
		this.store.dispatch({ type: TrustAccountListActions.InsertRecords, response: result });
	};

	private onUpdateSuccess = (result: MutationResponseDto) => {
		this.notificationService.showNotification(`Trust Account ${result.name} updated`);
		this.trustAccountsCachedService.clearCache();
		this.store.dispatch({ type: TrustAccountListActions.UpdateRecords, response: result });
	};

	private onDeleteSuccess = (result: MutationResponseDto) => {
		this.notificationService.showNotification(`Trust Account deleted: ${name}`);
		this.trustAccountsCachedService.clearCache();
		this.store.dispatch({ type: TrustAccountListActions.RemoveRecords, response: result });
	};
}
