import { TrustAccountListItemDto } from '@common/models/Settings/TrustSettings/TrustAccounts/List/TrustAccountListItemDto';
import { createSelector, MemoizedSelector } from '@ngrx/store';

import { IAppState } from '../../app.state';

export const selectTrustAccountFilterData = (state: IAppState) => state.trustAccountFilterData;

export const selectTrustAccountFilterRecords: MemoizedSelector<IAppState, TrustAccountListItemDto[]> = createSelector(
	selectTrustAccountFilterData,
	state => state?.list || []
);

export const selectTrustAccountFilterIsFetching = createSelector(
	selectTrustAccountFilterData,
	state => !!state?.isFetching
);
