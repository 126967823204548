import { createReducer, on } from '@ngrx/store';

import { CommonReducers } from '../../../common-reducers';
import {
	init,
	insertRecords,
	loadFailed,
	loading,
	loadSuccess,
	removeRecords,
	selectRecords,
	setFilters,
	setPageIndex,
	setPageIndexForId,
	setPageSize,
	setSortBy,
	setSortDirection,
	updateRecords
} from './contact-custom-field-list.actions';
import { initialState, IContactCustomFieldListState } from './contact-custom-field-list.state';

export const contactCustomFieldListReducer = createReducer(
	initialState,
	on(init, state => CommonReducers.init(state, initialState)),
	on(loading, state => CommonReducers.loading(state)),
	on(loadSuccess, (state, { response }) => {
		let result: IContactCustomFieldListState = CommonReducers.loadSuccess(state, response);
		return result;
	}),
	on(loadFailed, (state, { error }) => CommonReducers.loadFailed(state, error)),

	on(setFilters, (state, { filter }) => CommonReducers.setFilters(state, filter)),

	on(setPageSize, (state, { pageSize }) => CommonReducers.setPageSize(state, pageSize)),
	on(setPageIndex, (state, { pageIndex }) => CommonReducers.setPageIndex(state, pageIndex)),
	on(setPageIndexForId, (state, { id }) => CommonReducers.setPageIndexForId(state, id)),

	on(setSortBy, (state, { sortBy }) => CommonReducers.setSortBy(state, sortBy)),
	on(setSortDirection, (state, { sortDirection }) => CommonReducers.setSortDirection(state, sortDirection)),

	on(insertRecords, (state, { response }) => {
		let mutations = response instanceof Array ? response : [response];

		return CommonReducers.processMutations(state, mutations);
	}),
	on(updateRecords, (state, { response }) => CommonReducers.processMutations(state, response)),
	on(removeRecords, (state, { response }) => CommonReducers.processMutations(state, response)),
	on(selectRecords, (state, { row, selectionType }) => CommonReducers.selectRecords(state, row, selectionType))
);
