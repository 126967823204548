<h2 mat-dialog-title class="title">Edit Template</h2>

<mat-dialog-content>
	<div class="flex-column" [formGroup]="form">
		<div class="flex-row">
			<mat-form-field>
				<mat-placeholder>Title <sup class="color-warn">*</sup> </mat-placeholder>
				<input matInput formControlName="title" autocomplete="off" />
				<mat-error> <error-messages [for]="form.get('title')"></error-messages> </mat-error>
			</mat-form-field>
		</div>
		<div class="flex-row">
			<mat-form-field>
				<mat-select formControlName="entityType" placeholder="Related To" (selectionChange)="entityTypeChanged($event)">
					<mat-option *ngFor="let key of entityTypeKeys" [value]="key">{{ key | templateEntityType }}</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field *ngIf="form.get('entityType').value === 'Matter'">
				<mat-placeholder>Practice Areas</mat-placeholder>
				<mat-select #selectPracticeAreas formControlName="associatedPracticeAreaIds" multiple
					><mat-select-trigger>{{ displaySelected(selectPracticeAreas.value) }}</mat-select-trigger>
					<mat-option (onSelectionChange)="allOptionSelected($event)" #allOption>All</mat-option>
					<mat-option (onSelectionChange)="optionSelected($event)" *ngFor="let practiceArea of practiceAreas" [value]="practiceArea.id">
						<div class="c-practice-area-option">
							<span class="c-practice-area-option__title">{{ practiceArea.name }}</span>
							<span class="c-practice-area-option__disabled" *ngIf="!!practiceArea.isDisabled">Disabled</span>
						</div>
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div *ngIf="!hideCategory" class="flex-row">
			<mat-form-field>
				<mat-select placeholder="Group" formControlName="documentCategoryId">
					<mat-option></mat-option>
					<mat-option *ngFor="let category of documentCategories" [value]="category.id">{{ category.name }}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<document-tag *ngIf="form.get('documentTags')" [ActiveTags]="form.get('documentTags').value" [FormControl]="form.get('documentTags')"></document-tag>
		<div class="flex-row" *ngIf="!!allowHeaderFooterTemplate">
			<document-header-footer-template-lookup
				Placeholder="Header Footer Template"
				[FormControl]="form.get('documentHeaderFooterTemplateId')"
				ConflictText="{{ conflictText }}"
			></document-header-footer-template-lookup>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-raised-button color="primary" [disabled]="!form.valid" throttleButton (throttledClick)="save()">Save</button>
	<button mat-raised-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
