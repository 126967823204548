<ng-container [formGroup]="mainForm" class="flex-column">
	<div class="flex-row">
		<mat-form-field>
			<mat-placeholder
				>Bank Name
				<sup class="color-warn">*</sup>
			</mat-placeholder>
			<input #bankNameInput matInput [formControl]="bankDetailsForm.controls.bankName" autocomplete="off" />
			<mat-error>
				<error-messages [for]="bankDetailsForm.controls.bankName"></error-messages>
			</mat-error>
		</mat-form-field>
	</div>
	<div class="flex-column">
		<mat-form-field>
			<mat-placeholder
				>Account Name
				<sup class="color-warn">*</sup>
			</mat-placeholder>
			<input matInput [formControl]="bankDetailsForm.controls.accountName" autocomplete="off" />
			<mat-error>
				<error-messages [for]="bankDetailsForm.controls.accountName"></error-messages>
			</mat-error>
		</mat-form-field>
	</div>
	<div class="flex-row">
		<mat-form-field>
			<mat-placeholder
				>Country
				<sup class="color-warn">*</sup>
			</mat-placeholder>
			<mat-select [formControl]="bankDetailsForm.controls.countryId" (selectionChange)="countryChanged($event)">
				<mat-option *ngFor="let country of countries" [value]="country.id">{{ country.name }}</mat-option>
			</mat-select>
			<mat-error>
				<error-messages [for]="bankDetailsForm.controls.countryId"></error-messages>
			</mat-error>
		</mat-form-field>
		<mat-form-field *ngIf="bankDetailsForm.controls.countryId.value === 'AU'">
			<mat-placeholder
				>BSB
				<sup class="color-warn">*</sup>
			</mat-placeholder>
			<input matInput [formControl]="bankDetailsForm.controls.bsb" autocomplete="off" />
			<mat-error>
				<error-messages [for]="bankDetailsForm.controls.bsb"></error-messages>
			</mat-error>
		</mat-form-field>
		<mat-form-field>
			<mat-placeholder
				>Account Number
				<sup class="color-warn">*</sup>
			</mat-placeholder>
			<input matInput [formControl]="bankDetailsForm.controls.accountNumber" autocomplete="off" />
			<mat-error>
				<error-messages [for]="bankDetailsForm.controls.accountNumber"></error-messages>
			</mat-error>
		</mat-form-field>
	</div>
</ng-container>
