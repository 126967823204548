import { Action, createFeatureSelector, createSelector } from '@ngrx/store';
import { get } from 'lodash-es';

import { MatterNumberingConfigActions } from './matter-numbering-config.actions';
import { IMatterNumberingConfigState } from './matter-numbering-config.state';

export const initialState: IMatterNumberingConfigState = { dto: null };

export function matterNumberingConfigReducer(state = initialState, action: Action): IMatterNumberingConfigState {
	switch (action.type) {
		case MatterNumberingConfigActions.Set:
			return get(action, 'data', initialState);
		default:
			return state;
	}
}

export const getMatterNumberingConfigState =
	createFeatureSelector<IMatterNumberingConfigState>('matterNumberingConfigData');

export const getMatterNumberingConfig = createSelector(
	getMatterNumberingConfigState,
	(state: IMatterNumberingConfigState) => state
);
