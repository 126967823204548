<mat-table [dataSource]="datasource" matSort matSortActive="startedUtc" matSortDirection="desc">
	<ng-container matColumnDef="startedUtc">
		<mat-header-cell *matHeaderCellDef mat-sort-header>Started</mat-header-cell>
		<mat-cell *matCellDef="let row">
			{{ row.startedUtc | moment : 'dd/MM/yyyy' }}
		</mat-cell>
	</ng-container>

	<ng-container matColumnDef="completedUtc">
		<mat-header-cell *matHeaderCellDef mat-sort-header>Completed</mat-header-cell>
		<mat-cell *matCellDef="let row">
			{{ row.completedUtc | moment : 'dd/MM/yyyy' }}
		</mat-cell>
	</ng-container>

	<ng-container matColumnDef="matterNumber">
		<mat-header-cell *matHeaderCellDef mat-sort-header>Matter</mat-header-cell>
		<mat-cell *matCellDef="let row">
			<mat-icon *ngIf="!!row.associatedMatter" color="accent" class="smaller-icon">gavel</mat-icon>
			<a *ngIf="!!row.associatedMatter" [routerLink]="['/matters', row.associatedMatter.id]"
				>{{ row.associatedMatter.number }} - {{ row.associatedMatter.title | longTextEllipsis : 50 }}</a
			>
		</mat-cell>
	</ng-container>

	<ng-container matColumnDef="title">
		<mat-header-cell *matHeaderCellDef mat-sort-header>Title</mat-header-cell>
		<mat-cell *matCellDef="let row">
			<mat-icon *ngIf="getIconName(row)" color="accent" [svgIcon]="getIconName(row)" class="smaller-icon"></mat-icon>
			<a *ngIf="!!canClick(row)" throttleButton (throttledClick)="handleClick(row)">{{ row.title | longTextEllipsis : 50 }}</a>
			<span *ngIf="!canClick(row)">{{ row.title | longTextEllipsis : 50 }}</span>
		</mat-cell>
	</ng-container>

	<ng-container matColumnDef="userName">
		<mat-header-cell *matHeaderCellDef mat-sort-header>User</mat-header-cell>
		<mat-cell *matCellDef="let row">
			<a *ngIf="row.user" [routerLink]="['/contacts', row.user.id]">{{ row.user.name }}</a></mat-cell
		>
	</ng-container>

	<ng-container matColumnDef="status">
		<mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
		<mat-cell *matCellDef="let row">
			{{ row.status }}
		</mat-cell>
	</ng-container>

	<ng-container matColumnDef="progressPercentage">
		<mat-header-cell *matHeaderCellDef mat-sort-header>Progress</mat-header-cell>
		<mat-cell *matCellDef="let row">
			{{ row.progressPercentage / 100.0 | percent }}
		</mat-cell>
	</ng-container>

	<ng-container matColumnDef="progressComment">
		<mat-header-cell *matHeaderCellDef>Comment</mat-header-cell>
		<mat-cell *matCellDef="let row">
			{{ row.progressComment }}
		</mat-cell>
	</ng-container>

	<ng-container matColumnDef="hasErrors">
		<mat-header-cell *matHeaderCellDef>Errors</mat-header-cell>
		<mat-cell *matCellDef="let row">
			{{ row.errors.length > 0 ? row.errors[0] : '' }}
		</mat-cell>
	</ng-container>

	<ng-container matColumnDef="actions">
		<mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
		<mat-cell *matCellDef="let row">
			<button mat-icon-button [matMenuTriggerFor]="menu" title="Actions">
				<mat-icon>more_vert</mat-icon>
			</button>
			<mat-menu #menu="matMenu">
				<button mat-menu-item [disabled]="!canCancel(row)" throttleButton (throttledClick)="cancel(row)">
					<mat-icon>cancel</mat-icon>
					<span>Cancel job</span>
				</button>

				<button mat-menu-item [disabled]="!canDeleteJob(row)" throttleButton (throttledClick)="deleteJob(row)">
					<mat-icon>delete</mat-icon>
					<span>Delete job</span>
				</button>

				<button *ngIf="!!hasDownload(row)" mat-menu-item [disabled]="!canDownload(row)" throttleButton (throttledClick)="download(row.id)">
					<mat-icon>download</mat-icon>
					<span>Download job</span>
				</button>
			</mat-menu>
		</mat-cell>
	</ng-container>

	<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
	<mat-row
		*matRowDef="let row; columns: displayedColumns"
		throttleButton
		(throttledClick)="selectRow(row, $event)"
		[ngClass]="{ highlight: row.isHighlighted }"
	></mat-row>
</mat-table>
<mat-paginator
	[length]="datasource?.response?.totalRecords"
	[pageIndex]="datasource?.response?.pageIndex"
	[pageSize]="pageSize"
	[pageSizeOptions]="pageSizeOptions"
></mat-paginator>
