import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ContactCustomFieldListRequest } from '@common/models/Settings/CustomFields/List/ContactCustomFieldListRequest';

@Component({
	selector: 'contact-custom-field-list-filter-dialog',
	styleUrls: ['./contact-custom-field-list-filter-dialog.component.scss'],
	templateUrl: './contact-custom-field-list-filter-dialog.component.html'
})
export class ContactCustomFieldListFilterDialogComponent {
	currentFilter: Partial<ContactCustomFieldListRequest>;

	constructor(
		@Inject(MAT_DIALOG_DATA) public request: Partial<ContactCustomFieldListRequest>,
		private dialogRef: MatDialogRef<ContactCustomFieldListFilterDialogComponent>
	) {}

	apply() {
		this.dialogRef.close(this.currentFilter);
	}

	clear() {
		this.dialogRef.close({});
	}

	rootFilterChange($event: Partial<ContactCustomFieldListRequest>) {
		this.currentFilter = $event;
	}
}
