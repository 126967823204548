<mat-card>
	<div [formGroup]="form">
		<div class="container">
			<span>Apply Taxes on Invoices, Time Records / Fees&nbsp;</span>
			<mat-form-field>
				<mat-select #practiceTypeInput formControlName="taxSettings">
					<mat-option *ngFor="let key of taxConfigurationKeys" [value]="key"> {{ key | taxConfiguration }} </mat-option>
				</mat-select>
				<mat-error>
					<error-messages [for]="form.get('taxSettings')"></error-messages>
				</mat-error>
			</mat-form-field>
		</div>
	</div>
	<div class="description">
		<div><span>&nbsp;On: Taxes are always calculated</span></div>
		<div><span>&nbsp;Off: Taxes are not calculated</span></div>
		<div><span>&nbsp;Optional (On/Off): Defaults to one of the above with the option of changing the behaviour per matter</span></div>
	</div>
	<div class="action-pane">
		<button mat-raised-button color="primary" [disabled]="form.invalid || form.disabled || form.pristine" throttleButton (throttledClick)="save()">
			Save
		</button>
	</div>
</mat-card>
