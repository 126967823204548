import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { MSGErrorResult, MSGReader } from 'wl-msg-reader';

import { BaseMetadataFile, BaseMetadataService } from './base-metadata.service';

@Injectable({
	providedIn: 'root'
})
export class MsgMetadataService extends BaseMetadataService<MsgMetadataFile> {
	static readonly CreatedKeys: string[] = ['Date'];

	isSupported(extension: string): boolean {
		return extension.toLowerCase() === '.msg';
	}

	readArrayBuffer(data: ArrayBuffer | Promise<ArrayBuffer>): Observable<MsgMetadataFile> {
		const subject = new Subject<MsgMetadataFile>();

		Promise.resolve(data)
			.then(buffer => {
				const reader = new MSGReader(buffer);
				var result = reader.getFileData();
				if ('error' in result) {
					return subject.error((result as MSGErrorResult).error);
				}

				return subject.next(new MsgMetadataFile(result.headers ?? ''));
			})
			.catch(error => subject.error(error));

		return subject.asObservable();
	}
}

export class MsgMetadataFile extends BaseMetadataFile {
	constructor(private _headers: string) {
		super();
	}

	getMetadataValues(keys: string[]): string[] {
		const matches: string[] = [];

		const headerRegEx = /(.*)\: (.*)/g;

		let match: string[];
		while ((match = headerRegEx.exec(this._headers))) {
			if (keys.includes(match[1])) {
				matches.push(match[2]);
			}
		}

		return matches;
	}
}
