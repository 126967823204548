import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { HttpResponseStatus500Interceptor } from '@common/utils/http-response-status-500.interceptor';

import { AuthInterceptor } from './auth.interceptor';
import { ErrorInterceptor } from './error.interceptor';
import { PortalAuthInterceptor } from './portal-auth.interceptor';
import { RateLimitInterceptor } from './rate-limit.interceptor';

export const interceptorProviders = [
	{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
	{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
	{ provide: HTTP_INTERCEPTORS, useClass: PortalAuthInterceptor, multi: true },
	{ provide: HTTP_INTERCEPTORS, useClass: RateLimitInterceptor, multi: true },
	{ provide: HTTP_INTERCEPTORS, useClass: HttpResponseStatus500Interceptor, multi: true }
];
