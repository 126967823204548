// This file has been generated from MatterStaffMemberDto.cs
// Do not modify this file directly!!
// tslint:disable

import { SetComplexType, SetSimpleType } from '@common/utils/class-transform';

import { EntityReference } from '../../Common/EntityReference';
import { ContactReference } from '../../Contacts/Common/ContactReference';

// LawMaster.Amalthea.Domain.Matters.Common.MatterStaffMemberDto
export class MatterStaffMemberDto {

	// ContactReference
	@SetComplexType(ContactReference)
	contactReference: ContactReference;
	// UserType
	@SetComplexType(EntityReference)
	userType: EntityReference;
	// Rate
	rate: number;
	// IsMain
	@SetSimpleType(Boolean)
	isMain: boolean;

	constructor() {
		this.contactReference = new ContactReference();
		this.userType = new EntityReference();
	}
}