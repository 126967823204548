import { isNil } from 'lodash-es';

const plainLinkRegex =
	/(\[([^\[\]]*)\]\(\s*)?https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&;//=]*)/gim;
const globalMarkdownRegex = /\[([^\[\]]*)\]\((.*?)\)/gm;
const singleMarkdownRegex = /\[([^\[\]]*)\]\((.*?)\)/;

export function isEmptyOrSpaces(str: string): boolean {
	return isNil(str) || str.match(/^ *$/) !== null;
}

export function processUrls(text: string): string {
	if (!text) {
		return null;
	}

	text = text.replace(plainLinkRegex, x => {
		if (!x.startsWith('[')) return `<a href="${x}">${x}</a>`;
		return x;
	});
	const matches = text.match(globalMarkdownRegex);

	if (!matches?.length) {
		return text;
	}

	const links = matches.map(match => {
		const foundMatches = singleMarkdownRegex.exec(match);

		return {
			match: foundMatches[0],
			text: foundMatches[1],
			href: foundMatches[2]
		};
	});

	for (let index = 0; index < links.length; index++) {
		const link = links[index];
		text = text.replace(link.match, `<a href="${link.href}">${link.text}</a>`);
	}

	return text;
}

export function proccessUrlsToBlocks(text: string): ITextUrlBlock[] {
	if (!text?.length) {
		return null;
	}

	const urlRanges = getUrlRanges(text);

	const textBlocks: { value: string; isMarkdown: boolean; isLink: boolean }[] = [];
	let lastEnd = 0;

	for (let index = 0; index < urlRanges.length; index++) {
		const urlRange = urlRanges[index];

		if (urlRange.start > lastEnd) {
			const value = text.substring(lastEnd, urlRange.start);
			textBlocks.push({ value: value, isMarkdown: false, isLink: false });
		}

		const linkValue = text.substring(urlRange.start, urlRange.end);
		textBlocks.push({ value: linkValue, isMarkdown: urlRange.isMarkdown, isLink: true });

		lastEnd = urlRange.end;
	}

	if (lastEnd < text.length) {
		const value = text.substring(lastEnd, text.length);
		textBlocks.push({ value: value, isMarkdown: false, isLink: false });
	}

	return textBlocks.map(block => {
		if (!!block.isLink && !!block.isMarkdown) {
			const foundMatches = singleMarkdownRegex.exec(block.value);

			return {
				text: foundMatches[1],
				link: foundMatches[2]
			};
		}

		return {
			text: block.value,
			link: !!block.isLink ? block.value : null
		};
	});
}

function getUrlRanges(text: string): IUrlRangeResult[] {
	if (!text?.length) {
		return null;
	}

	const allLinkRegEx = RegExp(plainLinkRegex);

	const results: IUrlRangeResult[] = [];

	let matches;
	while ((matches = allLinkRegEx.exec(text)) !== null) {
		const currentResult: IUrlRangeResult = {
			text: matches[0],
			start: matches.index,
			end: allLinkRegEx.lastIndex,
			isMarkdown: false
		};

		const mdMatches = singleMarkdownRegex.exec(matches[0]);
		if (mdMatches && mdMatches.length === 3) {
			currentResult.text = mdMatches[1];
			currentResult.link = mdMatches[2];
			currentResult.isMarkdown = true;
		}

		results.push(currentResult);
	}

	return results;
}

export function multirowSelectText(): string {
	if (navigator.platform.toLowerCase().toString().indexOf('mac') != -1) {
		return 'Use Command/Shift + Click to multi-select rows';
	}
	return 'Use Ctrl/Shift + Click to multi-select rows';
}

interface IUrlRangeResult {
	text: string;
	start: number;
	end: number;
	isMarkdown: boolean;
	link?: string;
}

export interface ITextUrlBlock {
	text: string;
	link: string;
}

export function formattedMasking(value: any, pattern: string): string {
	const tobeFormatted: string = value.toString();
	if (!tobeFormatted) {
		return '';
	}
	const regex = patternToRegex(tobeFormatted, pattern);
	const format = patternToFormat(tobeFormatted, regex);
	return tobeFormatted.replace(regex, format);
}

function patternToRegex(value: string, pattern: string) {
	let regexString = '';
	let tempValue = value;
	const patternArray = pattern.split(' ');

	for (let i = patternArray.length - 1; i >= 0; i--) {
		let size = String(patternArray[i]).length;

		if (tempValue.length > size) {
			if (i === 0) {
				size = tempValue.length;
			}

			tempValue = tempValue.slice(0, size * -1);
		} else {
			if (tempValue.length === 0) {
				break;
			}

			size = tempValue.length;
			tempValue = tempValue.slice(0, size * -1);
		}

		regexString = `([^s]{0,${size}})` + regexString;
	}

	return new RegExp(`^${regexString}`, 'g');
}

function patternToFormat(value: string, regex: RegExp) {
	const groups = regex.exec(value);
	groups.shift();
	const formatArr = groups.map((val, index) => `$${index + 1}`);

	return formatArr.join(' ');
}
