<div class="fontSettingsContainer" [formGroup]="FormGroup">
	<mat-form-field class="fontSizeField">
		<input type="number" formControlName="fontSize" matInput autocomplete="off" />
		<mat-placeholder>Font Size</mat-placeholder>
		<mat-error>
			<error-messages [for]="FormGroup.get('fontSize')"></error-messages>
		</mat-error>
	</mat-form-field>

	<div class="buttonContainer">
		<button
			throttleButton
			(throttledClick)="toggleBold()"
			[ngClass]="{ isTicked: isBold }"
			mat-icon-button
			matTooltip="Bold"
			matTooltipPosition="above"
			color="primary"
			aria-label="Example icon button with a home icon"
		>
			<mat-icon><span class="material-icons-outlined">format_bold</span></mat-icon>
		</button>
		<button
			throttleButton
			(throttledClick)="toggleItalic()"
			[ngClass]="{ isTicked: isItalic }"
			mat-icon-button
			matTooltip="Italic"
			matTooltipPosition="above"
			color="primary"
			aria-label="Example icon button with a home icon"
		>
			<mat-icon><span class="material-icons-outlined">format_italic</span></mat-icon>
		</button>
		<button
			throttleButton
			(throttledClick)="toggleUnderline()"
			[ngClass]="{ isTicked: isUnderline }"
			mat-icon-button
			matTooltip="Underline"
			matTooltipPosition="above"
			color="primary"
			aria-label="Example icon button with a home icon"
		>
			<mat-icon><span class="material-icons-outlined">format_underlined</span></mat-icon>
		</button>

		<button
			throttleButton
			(throttledClick)="toggleStrikeout()"
			[ngClass]="{ isTicked: isStrikeout, hidden: isHideStrikeoutOption }"
			mat-icon-button
			matTooltip="Strikethrough"
			matTooltipPosition="above"
			color="primary"
			aria-label="Example icon button with a home icon"
		>
			<mat-icon><span class="material-icons-outlined">strikethrough_s</span></mat-icon>
		</button>
	</div>
</div>
