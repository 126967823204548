// This file has been generated from DocumentReorderRequestDto.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.Domain.Documents.Item.DocumentReorderRequestDto
export class DocumentReorderRequestDto {

	// BriefId
	briefId: string;
	// SectionId
	sectionId: string;
	// DocumentIds
	documentIds: string[];

	constructor() {

	}
}