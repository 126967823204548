import { currentUserReducer } from '@common/state/reducers/current-user.reducer';
import { jobsReducer } from '@common/state/reducers/jobs.reducer';
import { systemTagsReducer } from '@common/state/reducers/system-tags-data.reducer';
import { tenantReducer } from '@common/state/reducers/tenant.reducer';
import { trustAccountsReducer } from '@common/state/reducers/trust-accounts-data.reducer';
import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from 'environments/environment';

import { IAppState } from './app.state';
import { costCodeFilterReducer } from './filters/cost-code-filter/cost-code-filter.reducer';
import { documentCategoryFilterReducer } from './filters/document-category-filter/document-category-filter.reducer';
import { infotrackStatusFilterReducer } from './filters/infotrack-status-filter/infotrack-status-filter.reducer';
import { jobStatusFilterReducer } from './filters/job-status-fiiter/job-status-filter.reducer';
import { matterRoleFilterReducer } from './filters/matter-role-fiiter/matter-role-filter.reducer';
import { practiceAreaFilterReducer } from './filters/practice-area-filter/practice-area-filter.reducer';
import { trustAccountFilterReducer } from './filters/trust-account-filter/trust-account-filter.reducer';
import { billListReducer } from './lists/bill-list/bill-list.reducer';
import { calculationVariableListReducer } from './lists/calculation-variable-list/calculation-variable-list.reducer';
import { contactListReducer } from './lists/contact-list/contact-list.reducer';
import { costListReducer } from './lists/cost-list/cost-list.reducer';
import { customReportListReducer } from './lists/custom-report-list/custom-report-list.reducer';
import { documentListReducer } from './lists/document-list/document-list.reducer';
import { infotrackListReducer } from './lists/infotrack-list/infotrack-list.reducer';
import { matterListReducer } from './lists/matter-list/matter-list.reducer';
import { practiceAreaListReducer } from './lists/settings/practice-area-list/practice-area-list.reducer';
import { taskListReducer } from './lists/task-list/task-list.reducer';
import { trustListReducer } from './lists/trust-list/trust-list.reducer';
import { currentPageReducer } from './misc/current-page/current-page.reducer';
import { matterNumberingConfigReducer } from './misc/matter-numbering-config/matter-numbering-config.reducer';
import { practiceAreaStagesReducer } from './misc/practice-area-stages/practice-area-stages.reducer';
import { taskViewReducer } from './misc/task-view/task-view.reducer';
import { tenantCompanyReducer } from './misc/tenant-company/tenant-company.reducer';
import { timeZoneListReducer } from './misc/time-zone-list/time-zone-list.reducer';
import { bankAccountListReducer } from './lists/settings/bank-account-list/bank-account-list.reducer';
import { userTypeListReducer } from './lists/settings/user-type-list/user-type-list.reducer';
import { trustAccountListReducer } from './lists/settings/trust-account-list/trust-account-list.reducer';
import { referralReasonListReducer } from './lists/settings/referral-reason-list/referral-reason-list.reducer';
import { basicWorkflowListReducer } from './lists/settings/matter-workflow-list/basic-workflow-list.reducer';
import { documentTagListReducer } from './lists/settings/document-tag-list/document-tag-list.reducer';
import { costTemplateListReducer } from './lists/settings/cost-template-list/cost-template-list.reducer';
import { costCodeListReducer } from './lists/settings/cost-code-list/cost-code-list.reducer';
import { documentBriefTemplateListReducer } from './lists/settings/document-brief-template-list/document-brief-template-list.reducer';
import { matterCustomFieldListReducer } from './lists/settings/matter-custom-field-list/matter-custom-field-list.reducer';
import { contactCustomFieldListReducer } from './lists/settings/contact-custom-field-list/contact-custom-field-list.reducer';

export const reducers: ActionReducerMap<IAppState> = {
	// Misc
	currentPageData: currentPageReducer,
	systemTagsData: systemTagsReducer,
	trustAccountsData: trustAccountsReducer,
	currentUserData: currentUserReducer,
	tenantData: tenantReducer,
	taskViewData: taskViewReducer,
	jobsData: jobsReducer,
	stagesData: practiceAreaStagesReducer,
	matterNumberingConfigData: matterNumberingConfigReducer,
	tenantCompanyData: tenantCompanyReducer,

	// Lists
	matterList: matterListReducer,
	contactList: contactListReducer,
	costList: costListReducer,
	infotrackList: infotrackListReducer,
	taskList: taskListReducer,
	calculationVariableList: calculationVariableListReducer,
	customReportList: customReportListReducer,
	timeZoneList: timeZoneListReducer,
	practiceAreaList: practiceAreaListReducer,
	billList: billListReducer,
	trustList: trustListReducer,
	bankAccountList: bankAccountListReducer,
	userTypeList: userTypeListReducer,
	trustAccountList: trustAccountListReducer,
	referralReasonList: referralReasonListReducer,
	basicWorkflowList: basicWorkflowListReducer,
	documentTagList: documentTagListReducer,
	costTemplateList: costTemplateListReducer,
	costCodeList: costCodeListReducer,
	documentBriefTemplateList: documentBriefTemplateListReducer,
	matterCustomFieldList: matterCustomFieldListReducer,
	contactCustomFieldList: contactCustomFieldListReducer,
	documentList: documentListReducer,

	// Filters
	costCodeFilterData: costCodeFilterReducer,
	documentCategoryFilterData: documentCategoryFilterReducer,
	infotrackStatusFilterData: infotrackStatusFilterReducer,
	jobStatusFilterData: jobStatusFilterReducer,
	matterRoleFilterData: matterRoleFilterReducer,
	practiceAreaFilterData: practiceAreaFilterReducer,
	trustAccountFilterData: trustAccountFilterReducer
};

export function logger(reducer: ActionReducer<IAppState>): ActionReducer<IAppState> {
	return (state: IAppState, action: any): IAppState => {
		return reducer(state, action);
	};
}

export const metaReducers: Array<MetaReducer<IAppState>> = !environment.production ? [logger] : [];
