// This file has been generated from CustomReportCreateUpdateDto.cs
// Do not modify this file directly!!
// tslint:disable

import { Moment } from 'moment-timezone';
import { DateFormat, SetDateFormat } from '@common/utils/date-format';

import { CustomReportType } from '../Common/CustomReportType';
import { DataGridColumn } from '../Common/DataGridColumn';

// LawMaster.Amalthea.Domain.CustomReports.Item.CustomReportCreateUpdateDto
export class CustomReportCreateUpdateDto {

	// Title
	title: string;
	// Description
	description: string;
	// Type
	type: keyof typeof CustomReportType;
	// CreatedDate
	@SetDateFormat(DateFormat.DateTime)
	createdDate: Moment;
	// CreatedByContactId
	createdByContactId: string;
	// DataGridColumns
	dataGridColumns: DataGridColumn[];
	// Filter
	filter: string;

	constructor() {
		this.dataGridColumns = [];
	}
}