import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { IUploadMultipleFiles, UploadStatus } from '@common/models/Common/IFileUploader';
import { DocumentReference } from '@common/models/Documents/PersistenceLayer/DocumentReference';
import { NotificationService } from '@common/notification';
import { getFileIcon } from '@common/utils/file-extensions';
import * as fileSaver from 'file-saver';
import { map as _map, sumBy } from 'lodash';
import { trimStart } from 'lodash-es';

import { DocumentsService } from 'app/services/documents.service';
import { DownloadProgressDialogComponent } from 'app/shared/documents/list/download-dialog/download-progress-dialog.component';
import { IDownloadDialogData } from 'app/shared/documents/list/download-dialog/IDownloadDialogData';
import { DragEvents } from 'app/shared/multiple-file-uploader';
import { FileValidationService } from 'app/shared/multiple-file-uploader/filevalidation.service';

@Component({
	selector: 'multi-file-attachment',
	styleUrls: ['./multi-file-attachment.component.scss'],
	templateUrl: 'multi-file-attachment.component.html'
})
export class MultiFileAttachmentComponent implements OnDestroy {
	static readonly maxUploadSizeMb: number = 50;

	@Input()
	maxNumberOfFiles: number;
	@Input()
	isEditable: boolean;
	@Input()
	showProgress: boolean;
	@Input()
	set existingDocuments(val: DocumentReference[]) {
		if (!val) this.currentDocuments = [];
		else {
			this.currentDocuments = _map(val, this.convertToFileDocument);
		}
	}

	@Output()
	errorMessageUpdated = new EventEmitter<string>();
	@Output()
	documentIdsToRemoveUpdated = new EventEmitter<string[]>();
	@Output()
	uploadFilesInfoUpdated = new EventEmitter<IUploadMultipleFiles>();

	dragOver: boolean;

	currentDocuments: IFileDocument[] = [];
	documentIdsToRemove: string[] = [];

	private subscription: Subscription = new Subscription();

	constructor(
		private documentService: DocumentsService,
		protected dialog: MatDialog,
		protected notificationService: NotificationService,
		private fileValidationService: FileValidationService
	) {}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	onDragEvent(output: DragEvents): void {
		if (output === DragEvents.dragOver) {
			this.dragOver = true;
		} else if (output === DragEvents.dragOut) {
			this.dragOver = false;
		} else if (output === DragEvents.drop) {
			this.dragOver = false;
		}
	}

	onFilesDropped(fileList: FileList) {
		this.onFilesSelected(fileList);
	}

	onFilesBrowsed(event: Event) {
		// Get a file object from the input[type='file']
		const target: HTMLInputElement = event.target as HTMLInputElement;
		if (target == null) {
			throw new Error("The onChange event wasn't thrown on a file input control");
		}
		const fileList: FileList = target.files;
		this.onFilesSelected(fileList);
	}

	fileIcon(fileName: string): string {
		const exp = /(?:\.([^.]+))?$/;
		const extension = exp.exec(fileName)[0];
		return getFileIcon(extension);
	}

	removeFile(index: number): void {
		this.errorMessageUpdated.emit('');

		// add to the list of documents to be removed, if it's already in the server (has an id)
		if (this.currentDocuments[index].id) {
			this.documentIdsToRemove.push(this.currentDocuments[index].id);
			this.documentIdsToRemoveUpdated.emit(this.documentIdsToRemove);
		}

		// remove from current documents
		this.currentDocuments.splice(index, 1);
	}

	downloadFile(index: number): void {
		if (this.currentDocuments[index].id) {
			// download already existing files from server
			const document = this.currentDocuments[index];
			const data: IDownloadDialogData = {
				documentIds: [document.id],
				downloadFn: ((id: string) => this.documentService.downloadDocument(id)).bind(this),
				totalSize: document.file.size
			};
			this.subscription.add(
				this.dialog
					.open(DownloadProgressDialogComponent, { data })
					.afterClosed()
					.pipe(filter(Boolean))
					.subscribe()
			);
		} else {
			// newly added files, either from create or edit dialog
			fileSaver.saveAs(this.currentDocuments[index].file);
		}
	}

	private onFilesSelected(fileList: FileList): void {
		const errorMessage = this.validateFiles(fileList);
		this.errorMessageUpdated.emit(errorMessage);
		if (errorMessage) return;

		if (fileList != null && fileList.length > 0) {
			// tslint:disable-next-line:prefer-for-of
			for (let i = 0; i < fileList.length; i++) {
				const currentFile = fileList[i];
				this.currentDocuments.push({ id: null, file: currentFile });
				this.uploadFilesInfoUpdated.emit(this.makeMultipleUploadFiles(this.currentDocuments));
			}
		}
	}

	private convertToFileDocument(document: DocumentReference): IFileDocument {
		return {
			file: {
				lastModified: null,
				name: `${document.name}.${trimStart(document.fileExtension, '.')}`,
				size: document.size,
				slice: null,
				type: null,
				arrayBuffer: null,
				stream: null,
				text: null,
				webkitRelativePath: null
			},
			id: document.id
		};
	}

	private makeMultipleUploadFiles(documents: IFileDocument[]): IUploadMultipleFiles {
		if (!documents || !documents.length) return null;

		return {
			errorText: null,
			nativeFiles: _map(documents, 'file'),
			progress: {
				data: {
					percentage: 0,
					speed: 0,
					startTime: null,
					timeRemaining: null,
					timeRemainingHuman: null
				},
				status: UploadStatus.Queued
			}
		};
	}

	private validateFiles(addedFiles: FileList): string {
		if (this.currentDocuments.length + addedFiles.length > this.maxNumberOfFiles) {
			return `Cannot attach more than ${this.maxNumberOfFiles} files.`;
		}

		const existingFiles = _map(this.currentDocuments, doc => doc.file);
		const totalSize = this.getTotalFilesSizeInMB(existingFiles) + this.getTotalFilesSizeInMB(addedFiles);
		if (totalSize > MultiFileAttachmentComponent.maxUploadSizeMb) {
			return `The total file size exceeds the maximum size of ${MultiFileAttachmentComponent.maxUploadSizeMb}MB.`;
		}

		for (let i = 0; i < addedFiles.length; i++) {
			const file = addedFiles.item(i);
			if (!this.fileValidationService.validFileType(file)) {
				return `Executable files are not allowed. Cannot add "${file.name}"`;
			}
		}

		return null;
	}

	private getTotalFilesSizeInMB(files: FileList | File[]): number {
		const totalSize = sumBy(files, file => file.size);
		return totalSize / 1024 / 1024;
	}
}

interface IFileDocument {
	id: string;
	file: File;
}
