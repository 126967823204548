import { Directive, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, convertToParamMap, Router } from '@angular/router';

import { EMPTY, forkJoin, from, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';

import { ConvertDocumentToPdfDto } from '@common/models/Documents/Item/ConvertDocumentToPdfDto';
import { DocumentLockStatus } from '@common/models/Documents/Item/DocumentLockStatus';
import { DocumentBaseListItemDto } from '@common/models/Documents/List/DocumentBaseListItemDto';
import { DocumentListItemDto } from '@common/models/Documents/List/DocumentListItemDto';
import { DocumentListRequest } from '@common/models/Documents/List/DocumentListRequest';
import { NotificationService } from '@common/notification';
import { NotificationIcon } from '@common/notification/confirmation-dialog.component';
import { TasksService } from '@common/services/tasks.service';
import { arraysEqual } from '@common/utils/arrayUtils';
import { getFileIcon, isPdfConversionSupported } from '@common/utils/file-extensions';
import { Store } from '@ngrx/store';
import { get, sumBy } from 'lodash-es';
import * as moment from 'moment-timezone';

import { EntityCreatedNotificationService } from 'app/core/entityCreatedNotification.service';
import { IAppState } from 'app/core/state/app.state';
import { DocumentListActions, processRecords } from 'app/core/state/lists/document-list/document-list.actions';
import {
	selectDocumentIsFetching,
	selectDocumentListRecords,
	selectDocumentListRequest,
	selectDocumentListTotalRecords
} from 'app/core/state/lists/document-list/document-list.selectors';
import { DocumentServiceType } from 'app/core/state/lists/document-list/document-list.state';
import { CreateReferralDialogComponent } from 'app/create-forms/referrals/create-referral-dialog.component';
import { DocumentsEditService } from 'app/services/documents-edit.service';
import { DocumentsService } from 'app/services/documents.service';
import { GridViewService, IFilterDefaultsConfig } from 'app/services/grid-view.service';
import {
	ActionTypes,
	GenericListStateComponent,
	SelectorTypes
} from 'app/shared/generics/generic.list.state.component';
import {
	DocumentConfirmDialogComponent,
	DragEvents,
	FileInfo,
	IFileDialogData
} from 'app/shared/multiple-file-uploader';
import { fileListToFileInfo, getFilesFromEvent, processFilesForUpload$ } from 'app/shared/utils/fileUtil';

import { EditDocumentComponent } from '../item/edit-document.component';
import { VersionDocumentComponent } from '../item/version-document.component';
import {
	DownloadConvertDialogComponent,
	IDownloadConvertDialog
} from './download-convert-dialog/download-convert-dialog.component';
import { DownloadProgressDialogComponent } from './download-dialog/download-progress-dialog.component';
import { IDownloadDialogData } from './download-dialog/IDownloadDialogData';

@Directive()
export class DocumentListBaseComponent<TListItemDto extends DocumentBaseListItemDto>
	extends GenericListStateComponent<
		TListItemDto,
		DocumentListRequest & { serviceType: keyof typeof DocumentServiceType }
	>
	implements OnInit, OnDestroy
{
	@Input()
	showRelatedTo: boolean = true;
	@Input()
	showMyLastChangeTime: boolean = false;
	@Input()
	enableDragDrop: boolean = true;
	@ViewChild('fileUpload', { static: true })
	fileUpload: ElementRef;
	@Input()
	isGlobal = false;

	@ViewChild(MatSort, { static: true })
	matSort: MatSort;
	dragOver: boolean;

	get selectedDocumentId(): string {
		return get(
			this.activatedRoute.root.snapshot.children.find(child => child.outlet === 'preview'),
			'paramMap',
			convertToParamMap({})
		).get('id');
	}

	get isUploadVisible(): boolean {
		return this.FilterBase && !!(this.FilterBase.contactId || this.FilterBase.matterId);
	}

	// Displayed columns [overriden]
	get displayedColumns(): string[] {
		// Need conditional hiding of some columns
		return this.defaultDisplayedColumns.reduce((accumulator, current) => {
			if (!this.showRelatedTo && current === 'relatedTo') {
				return accumulator;
			}
			if (this.showMyLastChangeTime && current === 'lastModifiedDate') {
				return [...accumulator, 'userChangeTimestamp'];
			}
			return [...accumulator, current];
		}, []);
	}

	get actions(): ActionTypes {
		return {
			init: DocumentListActions.Init,
			load: DocumentListActions.Load,
			setFilters: DocumentListActions.SetFilters,
			setPageIndex: DocumentListActions.SetPageIndex,
			setPageIndexForId: DocumentListActions.SetPageIndexForId,
			setPageSize: DocumentListActions.SetPageSize,
			setSortBy: DocumentListActions.SetSortBy,
			setSortDirection: DocumentListActions.SetSortDirection,
			selected: DocumentListActions.SelectRecords
		};
	}

	get selectors(): SelectorTypes {
		return {
			records: selectDocumentListRecords,
			isFetching: selectDocumentIsFetching,
			request: selectDocumentListRequest,
			totalRecords: selectDocumentListTotalRecords
		};
	}

	constructor(
		defaultColumns: string[],
		dialog: MatDialog,
		router: Router,
		activatedRoute: ActivatedRoute,
		gridViewService: GridViewService,
		store: Store<IAppState>,
		protected notification: NotificationService,
		protected docService: DocumentsService,
		protected entityCreationNotifService: EntityCreatedNotificationService,
		private document: Document,
		private documentsEditService: DocumentsEditService<TListItemDto>,
		private taskService: TasksService
	) {
		super(defaultColumns, dialog, store, router, activatedRoute, gridViewService);
	}

	ngOnInit() {
		super.ngOnInit();

		// If a Document ID for highlighting is specified in the Query String and all data loaded,
		// then set 'highlightDocumentId' and trigger opening the preview for this record
		this.subscriptions.add(
			this.data$
				.pipe(
					filter(Boolean),
					tap(() => this.onDataLoaded()),
					switchMap(data =>
						this.store.select(selectDocumentListRequest).pipe(map(request => ({ data, request })))
					),
					filter(tuple => !!tuple.request?.pageIndexForId)
				)
				.subscribe(tuple => {
					if (!!tuple.data?.some(item => item.id === tuple.request.pageIndexForId)) {
						this.openPreview(tuple.request.pageIndexForId);
					}
				})
		);

		this.subscriptions.add(
			this.store
				.select(state => state?.tenantData?.tenantInformation?.featureStates)
				.pipe(
					distinctUntilChanged((left, right) => !!arraysEqual(left, right)),
					map(
						featureStates =>
							featureStates?.filter(state => !!state.isEnabled).map(state => state.type) ?? []
					)
				)
				.subscribe(features => {
					this.FilterBase = {
						...this.FilterBase,
						features: features,
						includeBriefData: true
					};
				})
		);
	}

	ngOnDestroy() {
		super.ngOnDestroy();
		if (/\(preview:[\w\/\-]+\)/i.test(this.router.url)) {
			setTimeout(() =>
				this.router.navigate([{ outlets: { preview: null } }], { queryParamsHandling: 'preserve' })
			);
		}
	}

	private _serviceType: keyof typeof DocumentServiceType;

	set serviceType(value: keyof typeof DocumentServiceType) {
		if (this._serviceType !== value) {
			this._serviceType = value;

			this.setFilterBase(this.FilterBase);
		}
	}

	protected setFilterBase(
		val: Partial<DocumentListRequest & { serviceType: keyof typeof DocumentServiceType }>
	): void {
		if (!!this._serviceType) {
			val = { ...val, serviceType: this._serviceType };
		}

		super.setFilterBase(val);
	}

	openPreview(id: string) {
		this.router.navigate([{ outlets: { preview: ['document', id] } }], { queryParamsHandling: 'preserve' });
	}

	referDocument(document: TListItemDto) {
		this.subscriptions.add(
			this.dialog
				.open(CreateReferralDialogComponent, {
					data: {
						documentIds: [document.id]
					},
					autoFocus: true
				})
				.afterClosed()
				.pipe(filter(Boolean))
				.subscribe()
		);
	}

	bulkReferDocument(): void {
		this.subscriptions.add(
			this.selected$
				.pipe(
					take(1),
					switchMap(selected =>
						this.dialog
							.open(CreateReferralDialogComponent, {
								data: {
									documentIds: selected.map(item => item.id)
								},
								autoFocus: true
							})
							.afterClosed()
					),
					filter(Boolean)
				)
				.subscribe()
		);
	}

	private _defaults: IFilterDefaultsConfig = {
		pageSize: 25,
		sortColumn: 'lastModifiedDate',
		sortDirection: 'desc'
	};

	getDefaultFilterSettings(): IFilterDefaultsConfig {
		return this._defaults;
	}

	sortByLastChangeColumn(): void {
		if (!!this.matSort) {
			this.matSort.active = 'userChangeTimestamp';
			this.matSort.direction = 'desc';
		}
	}

	sortByDefaultColumn(): void {
		if (!!this.matSort) {
			this.matSort.active = 'lastModifiedDate';
			this.matSort.direction = 'desc';
		}
	}

	processFileUploads$(fileList: FileInfo[]) {
		return processFilesForUpload$(fileList, this.notification).pipe(
			switchMap(files =>
				this.validateProcessFileUploads$(files).pipe(
					switchMap(flag => {
						if (!flag) {
							return of(flag);
						}

						const data: IFileDialogData = {
							fileList: files,
							serviceUrl: this.getServiceUrl(),
							isTemplate: this.isTemplate(),
							isEmailTemplate: this.isEmailTemplate(),
							briefId: this.getBriefId(),
							sectionId: this.getSectionId()
						};

						return this.dialog
							.open(DocumentConfirmDialogComponent, {
								data
							})
							.afterClosed();
					})
				)
			)
		);
	}

	validateProcessFileUploads$(files: FileInfo[]): Observable<boolean> {
		return of(true);
	}

	processDrop(event: DragEvent) {
		this.subscriptions.add(
			from(getFilesFromEvent(event))
				.pipe(
					switchMap(files => this.processFileUploads$(files)),
					filter(Boolean)
				)
				.subscribe(() => {
					this.clearVersionFilter();
					this.refreshDocumentTags(true);
					this.reloadBrief();
				})
		);
	}
	// Event handler on choosing a file
	// It initiates uploading of the file once it's confirmed
	fileChange(event: Event) {
		// Get a file object from the input[type='file']
		const target: HTMLInputElement = event.target as HTMLInputElement;
		if (target == null) {
			throw new Error("The onChange event wasn't thrown on a file input control");
		}

		const files = fileListToFileInfo(target.files);

		this.subscriptions.add(
			this.processFileUploads$(files)
				.pipe(filter(Boolean))
				.subscribe(() => {
					this.clearVersionFilter();
					this.refreshDocumentTags(true);
					this.reloadBrief();
				})
		);
	}

	clearVersionFilter(): void {
		// This method needs to be overriden if the page needs to implement document version filtering
	}

	enableVersionFilter(row: { id: string; title: string }): void {}

	refreshDocumentTags(force?: boolean): void {
		// This method needs to be overridden if te page needs to implement filtering by tags
	}

	reloadBrief(): void {
		// This method needs to be overridden if te page needs to reload brief
	}

	canOpenInNativeApp(fileExtension: string): boolean {
		return !!this.documentsEditService.getUriScheme(fileExtension);
	}

	openInNativeAppName(row: TListItemDto): string {
		return !!this.isLocked(row)
			? !!row.lockedBy
				? `Locked by ${row.lockedBy}`
				: `Locked`
			: `Edit in ${this.documentsEditService.getNativeAppName(row.fileExtension)}`;
	}

	canCopyUrl(fileExtension: string): boolean {
		return fileExtension.toLowerCase() === '.pdf';
	}

	canDownloadAsPdf(row: TListItemDto): boolean {
		return isPdfConversionSupported(row?.fileExtension);
	}

	edit(document: TListItemDto): void {
		const doc = document as DocumentBaseListItemDto;

		if (doc.versions?.length > 0 && doc.versions.indexOf(doc.id) !== doc.versions.length - 1) {
			this.notification
				.showConfirmation(
					'Later Version Exists',
					`Would you like to continue editing this version?`,
					'Edit',
					'Cancel',
					NotificationIcon.Warning
				)
				.subscribe((isConfirmed: boolean) => {
					if (isConfirmed) {
						this.documentsEditService.editDocument(document);
					}
				});
		} else {
			this.documentsEditService.editDocument(document);
		}
	}

	copy(document: TListItemDto): void {
		this.subscriptions.add(
			this.dialog
				.open(EditDocumentComponent, { data: { id: document.id, isCopy: true } })
				.afterClosed()
				.subscribe(reference => {
					this.filterComponent.filter.pageIndexForId = reference.id;
					this.pageIndex = 0;
				})
		);
	}

	newVersion(document: TListItemDto): void {
		this.subscriptions.add(
			this.dialog
				.open(VersionDocumentComponent, {
					data: { versionFromDocumentId: document.id, versions: document.versions }
				})
				.afterClosed()
				.subscribe()
		);
	}

	copyUrl(document: TListItemDto): void {
		const elem = this.document.createElement('input');
		elem.style.position = 'absolute';
		elem.style.opacity = '0';
		elem.value = this.documentsEditService.getDocUrlForEditing(document);
		this.document.body.appendChild(elem);
		elem.select();
		this.document.execCommand('copy');
		this.document.body.removeChild(elem);
	}

	deleteDocument(document: TListItemDto): void {
		const basedDoc = document as DocumentBaseListItemDto;
		const doc = basedDoc != null ? (basedDoc as DocumentListItemDto) : null;

		if (!!doc && !!doc.associatedBriefs && doc.associatedBriefs.length > 0) {
			const briefUnorderedList = `<ul>${doc.associatedBriefs
				.map(brief => `<li>${brief.briefName}</li>`)
				.join('')}</ul>`;
			const briefWord = 'brief' + (doc.associatedBriefs.length > 1 ? 's' : '');

			this.notification.showError(
				'Error Deleting Document',
				`Cannot delete the document ${doc.fullFileName} as it is currently associated with the following ${doc.associatedBriefs.length} ${briefWord}: ${briefUnorderedList} The document can be deleted once it has been removed from the ${briefWord}.`
			);
		} else {
			this.subscriptions.add(
				this.taskService
					.getTaskList({
						pageIndex: 0,
						pageSize: 1,
						documentId: document.id
					})
					.pipe(
						switchMap(response => {
							if (!!response.records && response.records.length > 0) {
								return this.notification
									.showConfirmation(
										'Delete Document',
										`This document is associated with a referral. Are you sure you want to delete the document ${document.fullFileName}?`
									)
									.pipe(
										switchMap(value => {
											if (value) {
												return this.deleteOperation$(document);
											} else {
												return of(null);
											}
										})
									);
							} else {
								return this.notification
									.showConfirmation(
										'Delete ' + (this.isTemplate() ? 'Template' : 'Document'),
										`Are you sure you want to delete the document "${document.fullFileName}"?`
									)
									.pipe(
										filter(Boolean),
										switchMap(() => {
											return this.deleteOperation$(document);
										})
									);
							}
						})
					)
					.subscribe()
			);
		}
	}

	deleteOperation$(document: TListItemDto) {
		return this.docService.getLockStatus(document.id).pipe(
			switchMap((status: DocumentLockStatus) => {
				if (status.isLocked) {
					this.notification.showError(
						'Document is Locked',
						`Unable to delete the document "${document.fullFileName}"
						as it is currently locked by ${status.lockedBy.name}. Please try again later.`
					);
					return EMPTY;
				} else {
					return this.docService.deleteDocument(document.id);
				}
			}),
			tap(next => {
				if (!!next) {
					this.store.dispatch(processRecords({ response: next }));
				}

				this.notification.showNotification(`Document deleted: ${next.name}`);
				this.clearVersionFilter();
			}),
			catchError(error => this.notification.showErrors('Error deleting Document', error))
		);
	}

	downloadSingleFile(doc: TListItemDto, convert: boolean = false): void {
		if (convert) {
			this.subscriptions.add(
				this.dialog
					.open(DownloadConvertDialogComponent, {
						data: {
							matterId: this.FilterBase.matterId
						},
						width: '600px'
					})
					.afterClosed()
					.pipe(
						filter(Boolean),
						switchMap((value: IDownloadConvertDialog) => {
							const convertToPdfDto: ConvertDocumentToPdfDto = {
								id: doc.id,
								userPassword: value.userPassword,
								ownerPassword: value.ownerPassword,
								passwordProtect: value.passwordProtect,
								preventModification: value.preventModification,
								saveToAssociatedObject: !!value?.addDocumentToMatter
							};

							if (!!value?.downloadPdf) {
								return this.download$([doc], convertToPdfDto, true, !!value?.addDocumentToMatter);
							} else if (!!value?.addDocumentToMatter) {
								return this.convert$([doc], convertToPdfDto);
							}

							return EMPTY;
						})
					)
					.subscribe()
			);
		} else {
			this.subscriptions.add(this.download$([doc]).subscribe());
		}
	}

	downloadSelectedFiles(): void {
		this.subscriptions.add(
			this.selected$
				.pipe(
					take(1),
					switchMap(selected => this.download$(selected))
				)
				.subscribe()
		);
	}

	isLocked(document: TListItemDto): boolean {
		const expiration = moment(document.lockExpires);
		return expiration.isAfter(moment.utc());
	}

	onDragEvent(output: DragEvents): void {
		if (output === DragEvents.dragOver) {
			this.dragOver = true;
		} else if (output === DragEvents.dragOut) {
			this.dragOver = false;
		} else if (output === DragEvents.drop) {
			this.dragOver = false;
		}
	}

	// File icon for the file extension
	protected getFileIcon(extension: string, hasAttachments: boolean = false): string {
		return getFileIcon(extension, hasAttachments);
	}

	// Extra processing on loading of the data set
	protected onDataLoaded(): void {
		/* overwritten in inheritted classes */
	}

	protected getServiceUrl(): string {
		throw new Error('getServiceUrl() not implemented');
	}

	// This method needs to be overridden by the inherited class that needs to send the Brief info
	protected getBriefId(): string {
		return null;
	}

	// This method needs to be overridden by the inherited class that needs to send the Section info
	protected getSectionId(): string {
		return null;
	}

	protected isTemplate(): boolean {
		return false;
	}

	protected isEmailTemplate(): boolean {
		return false;
	}

	private convert$(docs: TListItemDto[], dto: ConvertDocumentToPdfDto) {
		return forkJoin(docs.map(() => this.docService.convertDocumentToPdf(dto))).pipe(
			tap(responses => {
				if (!!responses?.length) {
					this.store.dispatch(processRecords({ response: responses }));
				}
			}),
			catchError(errors => this.notification.showErrors('Error Converting Document', errors))
		);
	}

	private download$(
		docs: TListItemDto[],
		dto: ConvertDocumentToPdfDto = null,
		asPdf: boolean = false,
		savePdf: boolean = false
	) {
		const documentIds = docs.map(doc => doc.id);
		const data: IDownloadDialogData = {
			documentIds,
			downloadFn: asPdf
				? (() => this.docService.downloadDocumentAsPdf(dto, savePdf)).bind(this)
				: ((id: string) => this.docService.downloadDocument(id)).bind(this),
			totalSize: !asPdf ? sumBy(docs, item => item.size) : 0
		};

		return this.dialog
			.open(DownloadProgressDialogComponent, { data })
			.afterClosed()
			.pipe(
				filter(Boolean),
				tap(() => this.clearSelection())
			);
	}
}
