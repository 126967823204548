import { Component } from '@angular/core';

import { AppBrandingService } from 'app/services/app-branding.service';

@Component({
	selector: 'app-about',
	styleUrls: ['./about.component.scss'],
	templateUrl: 'about.component.html'
})
export class AboutComponent {
	constructor(private _appBrandingService: AppBrandingService) {}

	get mainIcon() {
		return `../../../assets/img/${this._appBrandingService.mainIcon}`;
	}

	get imageAlt() {
		return this._appBrandingService.imageAlt;
	}
}
