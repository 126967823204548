import { SortDirection } from '@angular/material/sort';

import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';
import { BasicWorkflowListItemDto } from '@common/models/Settings/BasicWorkflows/List/BasicWorkflowListItemDto';
import { BasicWorkflowListRequest } from '@common/models/Settings/BasicWorkflows/List/BasicWorkflowListRequest';
import { DomainError } from '@common/models/Validation/DomainError';
import { createAction, props } from '@ngrx/store';

import { SelectionType } from '../../../SelectionType';

export enum BasicWorkflowListActions {
	Init = '[Basic Workflow List] Initialise',
	Load = '[Basic Workflow List] Load',
	Loading = '[Basic Workflow List] Loading',
	LoadSuccess = '[Basic Workflow List] Load Success',
	LoadFailed = '[Basic Workflow List] Load Failed',

	SetFilters = '[Basic Workflow List] Set Filters',
	SetPageSize = '[Basic Workflow List] Set Page Size',
	SetPageIndex = '[Basic Workflow List] Set Page Index',
	SetPageIndexForId = '[Basic Workflow List] Set Page Index For Id',

	SetSortBy = '[Basic Workflow List] Set Sort By',
	SetSortDirection = '[Basic Workflow List] Set Sort Direction',

	InsertRecords = '[Basic Workflow List] Insert Records',
	UpdateRecords = '[Basic Workflow List] Update Records',
	RemoveRecords = '[Basic Workflow List] Remove Records',
	SelectRecords = '[Basic Workflow List] Select Records'
}

export const init = createAction(BasicWorkflowListActions.Init);
export const load = createAction(BasicWorkflowListActions.Load);
export const loading = createAction(BasicWorkflowListActions.Loading);
export const loadSuccess = createAction(
	BasicWorkflowListActions.LoadSuccess,
	props<{ response: BasicWorkflowListItemDto }>()
);
export const loadFailed = createAction(BasicWorkflowListActions.LoadFailed, props<{ error: DomainError }>());

export const setFilters = createAction(
	BasicWorkflowListActions.SetFilters,
	props<{ filter: Partial<BasicWorkflowListRequest> }>()
);
export const setPageSize = createAction(BasicWorkflowListActions.SetPageSize, props<{ pageSize: number }>());
export const setPageIndex = createAction(BasicWorkflowListActions.SetPageIndex, props<{ pageIndex: number }>());
export const setPageIndexForId = createAction(BasicWorkflowListActions.SetPageIndexForId, props<{ id: string }>());

export const setSortBy = createAction(BasicWorkflowListActions.SetSortBy, props<{ sortBy: string }>());
export const setSortDirection = createAction(
	BasicWorkflowListActions.SetSortDirection,
	props<{ sortDirection: SortDirection }>()
);

export const insertRecords = createAction(
	BasicWorkflowListActions.InsertRecords,
	props<{ response: MutationResponseDto | MutationResponseDto[] }>()
);
export const updateRecords = createAction(
	BasicWorkflowListActions.UpdateRecords,
	props<{ response: MutationResponseDto | MutationResponseDto[] }>()
);
export const removeRecords = createAction(
	BasicWorkflowListActions.RemoveRecords,
	props<{ response: MutationResponseDto | MutationResponseDto[] }>()
);
export const selectRecords = createAction(
	BasicWorkflowListActions.SelectRecords,
	props<{ row: BasicWorkflowListItemDto; selectionType: SelectionType }>()
);
