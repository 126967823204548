// This file has been generated from DocumentFromTemplateDto.cs
// Do not modify this file directly!!
// tslint:disable

import { SetSimpleType } from '@common/utils/class-transform';

import { TemplateEntityType } from '../TemplateDto/TemplateEntityType';

// LawMaster.Amalthea.Domain.Documents.Item.DocumentFromTemplateDto
export class DocumentFromTemplateDto {

	// TemplateId
	templateId: string;
	// DocumentName
	documentName: string;
	// BriefId
	briefId: string;
	// MatterId
	matterId: string;
	// ContactId
	contactId: string;
	// EntityId
	entityId: string;
	// GenericContactId
	genericContactId: string;
	// TemplateEntityType
	templateEntityType: keyof typeof TemplateEntityType;
	// DocumentTags
	documentTags: string[];
	// IsPdf
	@SetSimpleType(Boolean)
	isPdf: boolean;
	// IsPreviewMode
	@SetSimpleType(Boolean)
	isPreviewMode: boolean;

	constructor() {

	}
}