<div class="page-container">
	<div *ngIf="!!this.practiceAreas" [formGroup]="form" class="form-container">
		<div class="flex-column">
			<div class="flex-row">
				<mat-form-field>
					<mat-placeholder>
						Name
						<sup class="color-warn">*</sup>
					</mat-placeholder>
					<input #costCodeInput matInput formControlName="name" autocomplete="off" />
					<mat-error>
						<error-messages [for]="form.controls.name"></error-messages>
					</mat-error>
				</mat-form-field>
			</div>
			<div class="flex-row">
				<mat-form-field>
					<mat-placeholder>Practice Areas</mat-placeholder>
					<mat-select #selectPracticeAreas formControlName="associatedPracticeAreaIds" multiple
						><mat-select-trigger>{{ displaySelectedPracticeAreas(selectPracticeAreas.value) }}</mat-select-trigger>
						<mat-option (onSelectionChange)="allOptionSelected($event)" #allOption>All</mat-option>
						<mat-option
							(onSelectionChange)="optionSelected($event, selectPracticeAreas)"
							*ngFor="let practiceArea of practiceAreas"
							[value]="practiceArea.id"
						>
							<div class="c-practice-area-option">
								<span class="c-practice-area-option__title">{{ practiceArea.name }}</span>
								<span class="c-practice-area-option__disabled" *ngIf="!!practiceArea.isDisabled">Disabled</span>
							</div>
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="flex-row">
				<mat-form-field>
					<mat-label>Workflow Type</mat-label>
					<mat-select formControlName="workflowType">
						<mat-option *ngFor="let option of workflowTypes" [value]="option">{{ option | basicWorkflowType }}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="flex-row" *ngIf="form.controls.workflowType.value === 'StageChanged'">
				<mat-form-field>
					<mat-placeholder>
						Stage
						<sup class="color-warn">*</sup>
					</mat-placeholder>
					<mat-select #selectStage formControlName="stageName">
						<mat-option *ngFor="let stageName of stageNames" [value]="stageName">{{ stageName }}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>

		<basic-workflow-conditions
			*ngIf="form.get('workflowType').value === 'MatterUpdated'"
			[form]="form"
			formArrayName="matterWorkflowConditions"
		></basic-workflow-conditions>

		<mat-action-row class="action-buttons">
			<button mat-stroked-button color="warn" throttleButton (throttledClick)="deleteWorkflow()">
				<span>Delete<span class="no-mobile"> Workflow</span></span>
			</button>
			<div class="right-action-buttons">
				<button *ngIf="hasChanges" mat-raised-button color="primary" throttleButton (throttledClick)="saveChanges()" [disabled]="!form.valid">
					Save
				</button>
				<button *ngIf="hasChanges" mat-raised-button throttleButton (throttledClick)="clearChanges()">Cancel</button>
			</div>
		</mat-action-row>
	</div>
</div>
