<h2 mat-dialog-title class="title">Create New Version</h2>

<mat-dialog-content>
	<div class="flex-column" [formGroup]="form">
		<div class="flex-row">
			<mat-form-field>
				<mat-placeholder>Title <sup class="color-warn">*</sup> </mat-placeholder>
				<input matInput formControlName="title" />
				<mat-error> <error-messages [for]="form.get('title')"></error-messages> </mat-error>
			</mat-form-field>
			<mat-form-field>
				<input formControlName="createdDate" matInput [matDatepicker]="createdDatePicker" placeholder="Created Date" autocomplete="off" />
				<mat-datepicker-toggle matSuffix [for]="createdDatePicker"></mat-datepicker-toggle>
				<mat-datepicker #createdDatePicker></mat-datepicker>
				<mat-error>
					<error-messages [for]="form.get('createdDate')"></error-messages>
				</mat-error>
			</mat-form-field>
		</div>

		<document-tag *ngIf="form.get('documentTags')" [FormControl]="form.get('documentTags')"></document-tag>

		<div *ngIf="briefs?.length > 0" class="flex-column briefs-container">
			<div class="flex-row">
				<h4>Add new version to existing brief{{ briefs?.length > 1 ? 's' : '' }}:</h4>
			</div>
			<div class="flex-row">
				<div class="flex-column">
					<label formArrayName="checkboxes" *ngFor="let brief of briefControls.controls; let i = index">
						<mat-checkbox [formControlName]="i">
							{{ briefs[i].briefName }}
						</mat-checkbox>
					</label>
				</div>
			</div>
		</div>

		<div class="flex-row">
			<div *ngIf="warnings?.length > 0 && form.valid" class="info-message color-warning">
				<mat-icon>info</mat-icon>
				<div *ngFor="let warning of warnings">
					{{ warning }}
				</div>
			</div>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-raised-button color="primary" [disabled]="!form.valid || this.isFormSubmissionActive" throttleButton (throttledClick)="save()">Save</button>
	<button mat-raised-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
