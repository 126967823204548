import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { MatterCustomFieldsService } from '@common/services/customfields-matter.service';
import { PracticeAreasService } from '@common/services/settings/practiceareas.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import moment from 'moment';

import { IAppState } from '../../app.state';
import { loading, MatterRoleFilterActions } from './matter-role-filter.actions';

@Injectable()
export class MatterRoleFilterEffects {
	loadMatterRoles$ = createEffect(() =>
		this._actions$.pipe(
			ofType(MatterRoleFilterActions.Load, MatterRoleFilterActions.InvalidateCache),
			map(action => action as { type: MatterRoleFilterActions }),
			withLatestFrom(this._store),
			filter(([action, state]) => {
				const matterRolesState = state.matterRoleFilterData;

				if (action.type === MatterRoleFilterActions.InvalidateCache && !matterRolesState.list) {
					return false;
				}

				return !matterRolesState.cacheExpiry || !!moment().isSameOrAfter(matterRolesState.cacheExpiry);
			}),
			debounceTime(500),
			tap(() => this._store.dispatch(loading())),
			switchMap(() =>
				this.fetch$().pipe(
					map(response => ({
						type: MatterRoleFilterActions.LoadSuccess,
						response
					})),
					catchError(error => of({ type: MatterRoleFilterActions.LoadFailed, error }))
				)
			)
		)
	);

	private static _defaultRoles = [
		{
			id: 'Client',
			name: 'Client'
		},
		{
			id: 'ActiveClients',
			name: 'Active Clients'
		},
		{
			id: 'Lawyer',
			name: 'Lawyer'
		},
		{
			id: 'ReferredBy',
			name: 'Referrer'
		}
	];

	private fetch$() {
		return this._practiceAreaService.getPracticeAreaList().pipe(
			switchMap(areas =>
				this._matterCustomFieldsService.getMatterCustomFieldList({
					fieldType: ['Contact'],
					enabled: true,
					practiceAreaIds: areas?.records?.map(area => area.id)
				})
			),
			map(fields =>
				MatterRoleFilterEffects._defaultRoles.concat(
					fields.records
						.map(field => ({
							id: field.id,
							name: field.description ?? field.name
						}))
						.sort((left, right) => left.name.localeCompare(right.name))
				)
			)
		);
	}

	constructor(
		private _actions$: Actions,
		private _store: Store<IAppState>,
		private _matterCustomFieldsService: MatterCustomFieldsService,
		private _practiceAreaService: PracticeAreasService
	) {}
}
