import { EntityReference } from '@common/models/Common/EntityReference';
import { DomainError } from '@common/models/Validation/DomainError';
import { Moment } from 'moment';

export interface IMatterRoleFilterState {
	isFetching: boolean;
	list: EntityReference[];

	cacheExpiry: Moment;
	// errrors
	error: DomainError;
}

export const initialState: IMatterRoleFilterState = {
	isFetching: false,
	list: null,
	error: null,
	cacheExpiry: null
};
