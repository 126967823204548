import { Injectable } from '@angular/core';

import { SettingsResolverService } from '@common/services/settings-resolver.service';

import { AppConfig } from 'app/app.config';

@Injectable({
	providedIn: 'root'
})
export class SystemSettingsResolverService extends SettingsResolverService {
	getAppServerUrl(): string {
		return AppConfig.AppServerUrl;
	}
}
