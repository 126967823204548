import { ContactLookupDto } from '@common/models/Contacts/Lookup/ContactLookupDto';
import { MatterLookupDto } from '@common/models/Matters/Lookup/MatterLookupDto';

export interface ICurrentPageState {
	id: string;
	pageType: CurrentPageType;
	lookup: ContactLookupDto | MatterLookupDto;
}

export enum CurrentPageType {
	Matter,
	Contact
}
