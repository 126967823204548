import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AlertDialogComponent } from '@common/notification/alert-dialog.component';
import { GeneralSettingsService } from '@common/services/settings/generalsettings.service';
import { map, Observable, of, Subscription, switchMap } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class TimerRouteGuard implements CanActivate {

	constructor(
		private dialog: MatDialog,
		private generalSettingsService: GeneralSettingsService,
		private router: Router
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		return this.isTimerEnabled();
	}

	isTimerEnabled() {
		return this.generalSettingsService.getMatterTimerConfig().pipe(
			switchMap(config => {
				if (!!config?.isGlobalEnabled) {
					return of(true);
				}

				return this.dialog
					.open(AlertDialogComponent, {
						data: {
							html: "This page is not available as the matter timers are disabled.",
							title: 'Matter Timers Disabled'
						}
					})
					.afterClosed()
					.pipe(
						map(() => {
							this.router.navigateByUrl('/');
							return false;
						})
					);
			})
		);
	}
}
