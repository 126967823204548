// This file has been generated from MatterWorkflowConditionOperator.cs
// Do not modify this file directly!!

// LawMaster.Amalthea.Domain.Settings.BasicWorkflows.Item
export enum MatterWorkflowConditionOperator {
	HasValue = "Has A Value",
	NullOrEmpty = "Doesn't Have A Value",
	EqualTo = "Equal To",
	NotEqualTo = "Not Equal To",
	GreaterThan = "Greater Than",
	GreaterThanOrEqualTo = "Greater Than Or Equal To",
	LessThan = "Less Than",
	LessThanOrEqualTo = "Less Than or Equal To",
	HasChanged = "Has Changed"
}
