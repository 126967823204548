import { SortDirection } from '@angular/material/sort';

import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';
import { DomainError } from '@common/models/Validation/DomainError';
import { createAction, props } from '@ngrx/store';

import { SelectionType } from '../../../SelectionType';
import { MatterCustomFieldListItemDto } from '@common/models/Settings/CustomFields/List/MatterCustomFieldListItemDto';
import { MatterCustomFieldListRequest } from '@common/models/Settings/CustomFields/List/MatterCustomFieldListRequest';

export enum MatterCustomFieldListActions {
	Init = '[Matter Custom Field List] Initialise',
	Load = '[Matter Custom Field List] Load',
	Loading = '[Matter Custom Field List] Loading',
	LoadSuccess = '[Matter Custom Field List] Load Success',
	LoadFailed = '[Matter Custom Field List] Load Failed',

	SetFilters = '[Matter Custom Field List] Set Filters',
	SetPageSize = '[Matter Custom Field List] Set Page Size',
	SetPageIndex = '[Matter Custom Field List] Set Page Index',
	SetPageIndexForId = '[Matter Custom Field List] Set Page Index For Id',

	SetSortBy = '[Matter Custom Field List] Set Sort By',
	SetSortDirection = '[Matter Custom Field List] Set Sort Direction',

	InsertRecords = '[Matter Custom Field List] Insert Records',
	UpdateRecords = '[Matter Custom Field List] Update Records',
	RemoveRecords = '[Matter Custom Field List] Remove Records',
	SelectRecords = '[Matter Custom Field List] Select Records'
}

export const init = createAction(MatterCustomFieldListActions.Init);
export const load = createAction(MatterCustomFieldListActions.Load);
export const loading = createAction(MatterCustomFieldListActions.Loading);
export const loadSuccess = createAction(
	MatterCustomFieldListActions.LoadSuccess,
	props<{ response: MatterCustomFieldListItemDto }>()
);
export const loadFailed = createAction(MatterCustomFieldListActions.LoadFailed, props<{ error: DomainError }>());

export const setFilters = createAction(
	MatterCustomFieldListActions.SetFilters,
	props<{ filter: Partial<MatterCustomFieldListRequest> }>()
);
export const setPageSize = createAction(MatterCustomFieldListActions.SetPageSize, props<{ pageSize: number }>());
export const setPageIndex = createAction(MatterCustomFieldListActions.SetPageIndex, props<{ pageIndex: number }>());
export const setPageIndexForId = createAction(MatterCustomFieldListActions.SetPageIndexForId, props<{ id: string }>());

export const setSortBy = createAction(MatterCustomFieldListActions.SetSortBy, props<{ sortBy: string }>());
export const setSortDirection = createAction(
	MatterCustomFieldListActions.SetSortDirection,
	props<{ sortDirection: SortDirection }>()
);

export const insertRecords = createAction(
	MatterCustomFieldListActions.InsertRecords,
	props<{ response: MutationResponseDto | MutationResponseDto[] }>()
);
export const updateRecords = createAction(
	MatterCustomFieldListActions.UpdateRecords,
	props<{ response: MutationResponseDto | MutationResponseDto[] }>()
);
export const removeRecords = createAction(
	MatterCustomFieldListActions.RemoveRecords,
	props<{ response: MutationResponseDto | MutationResponseDto[] }>()
);
export const selectRecords = createAction(
	MatterCustomFieldListActions.SelectRecords,
	props<{ row: MatterCustomFieldListItemDto; selectionType: SelectionType }>()
);
