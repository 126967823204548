<mat-card>
	<section class="page-path"><a [routerLink]="['..']">System</a> / Units</section>
	<h1>Units</h1>
	<div class="description">Units are used to auto-calculate the chargeable amount based on entered duration.</div>

	<div *ngIf="!isEditMode">
		<div class="view-container">
			<div class="view-title">Units per hour</div>
			<div class="unit-sizes-spaced">
				<div class="default-font">{{ this.form.get('unitsPerHour').value }}</div>
				<div class="hint">1 hour = {{ getNumberOfUnitsText() }}</div>
				<div class="hint">1 unit = {{ getUnitDurationText() }}</div>
			</div>
		</div>

		<button mat-stroked-button class="edit-button" throttleButton (throttledClick)="startEditing()">
			<span>Edit </span>
			<mat-icon>edit</mat-icon>
		</button>
	</div>

	<div [formGroup]="form" *ngIf="isEditMode">
		<div class="edit-container">
			<div class="unit-form">
				<label>Units per hour</label>
				<editable-selection [control]="form.get('unitsPerHour')" [options]="unitsList" [editMode]="isEditMode"></editable-selection>
			</div>
			<div class="unit-sizes hint">
				<div></div>
				<div>
					<div>1 hour = {{ getNumberOfUnitsText() }}</div>
					<div>1 unit = {{ getUnitDurationText() }}</div>
				</div>
			</div>
		</div>
		<div class="save-action">
			<button mat-raised-button class="save-button" color="primary" [disabled]="form.invalid" throttleButton (throttledClick)="saveChanges()">
				Save
			</button>
			<button mat-raised-button throttleButton (throttledClick)="cancelEditing()">Cancel</button>
		</div>
	</div>
</mat-card>
