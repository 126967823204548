import { Pipe, PipeTransform } from '@angular/core';

/*
 * Cuts the string down to specified number of chars and adds ellipsis at the end.
 */
@Pipe({ name: 'longTextEllipsis' })
export class LongTextEllipsisPipe implements PipeTransform {
	transform(text: string, characterCount: number): string | null {
		let sub = text.substring(0, characterCount);
		if (text.length > characterCount) sub += ' . . .';

		return sub;
	}
}
