<h2 mat-dialog-title>
	<mat-icon>campaign</mat-icon>
	<span>Announcement</span>
</h2>
<mat-dialog-content>
	<div class="flex-column">
		<div>
			For any support requests, please email
		</div>
		<a href="mailto:helpdesk@mattero.com.au">helpdesk&#64;mattero.com.au</a>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-raised-button mat-dialog-close>Close</button>
</mat-dialog-actions>
