<h2 mat-dialog-title class="title">Create Matter Workflow</h2>
<mat-dialog-content [formGroup]="form" class="flex-column">
	<div class="flex-row">
		<mat-form-field>
			<mat-placeholder>
				Name
				<sup class="color-warn">*</sup>
			</mat-placeholder>
			<input #costCodeInput matInput formControlName="name" autocomplete="off" />
			<mat-error>
				<error-messages [for]="form.controls.name"></error-messages>
			</mat-error>
		</mat-form-field>
	</div>
	<div class="flex-row">
		<mat-form-field>
			<mat-placeholder>Practice Areas</mat-placeholder>
			<mat-select #selectPracticeAreas formControlName="associatedPracticeAreaIds" multiple
				><mat-select-trigger>{{ displaySelectedPracticeAreas(selectPracticeAreas.value) }}</mat-select-trigger>
				<mat-option (onSelectionChange)="allOptionSelected($event)" #allOption>All</mat-option>
				<mat-option
					(onSelectionChange)="optionSelected($event, selectPracticeAreas)"
					*ngFor="let practiceArea of practiceAreas"
					[value]="practiceArea.id"
				>
					<div class="c-practice-area-option">
						<span class="c-practice-area-option__title">{{ practiceArea.name }}</span>
						<span class="c-practice-area-option__disabled" *ngIf="!!practiceArea.isDisabled">Disabled</span>
					</div>
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
	<div class="flex-row">
		<mat-form-field>
			<mat-label>Workflow Type</mat-label>
			<mat-select (selectionChange)="onWorkflowTypeChange($event)" formControlName="workflowType">
				<mat-option *ngFor="let option of workflowTypes" [value]="option">{{ option | basicWorkflowType }}</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
	<div class="flex-row" *ngIf="form.controls.workflowType.value === 'StageChanged'">
		<mat-form-field>
			<mat-placeholder>
				Stage
				<sup class="color-warn">*</sup>
			</mat-placeholder>
			<mat-select #selectStage formControlName="stageName">
				<mat-option *ngFor="let stageName of stageNames" [value]="stageName">{{ stageName }}</mat-option>
			</mat-select>
		</mat-form-field>
	</div>

	<basic-workflow-conditions
		*ngIf="form.get('workflowType').value === 'MatterUpdated'"
		[form]="form"
		formArrayName="matterWorkflowConditions"
	></basic-workflow-conditions>

	<div class="description">{{ descriptionText }}</div>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-raised-button color="primary" throttleButton (throttledClick)="save()" [disabled]="form.invalid">Ok</button>
	<button mat-raised-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
