import { Injectable, OnDestroy } from '@angular/core';

import { Observable, Subscription } from 'rxjs';

import { SecuredUrlMappingRequestDto } from '@common/models/InfoTrack/Orders/Item/SecuredUrlMappingRequestDto';
import { SecuredUrlMappingResponseDto } from '@common/models/InfoTrack/Orders/Item/SecuredUrlMappingResponseDto';
import { InfoTrackService } from '@common/services/infotrack.service';

import { AuthService } from 'app/core/auth.service';

import { infoTrackAuthConfig } from './infotrack-auth.config';

@Injectable({
	providedIn: 'root'
})
export class InfoTrackAuthService implements OnDestroy {
	private subscriptions = new Subscription();

	constructor(private authService: AuthService, private infoTrackService: InfoTrackService) {}

	requestAuthorizationCode(matterId?: string) {
		let requestUri = `${infoTrackAuthConfig.authorizationUrl}?client_id=${infoTrackAuthConfig.clientId}&response_type=${infoTrackAuthConfig.responseType}&scope=${infoTrackAuthConfig.scope}&redirect_uri=${infoTrackAuthConfig.redirectUri}`;
		if (!!matterId) requestUri += `&state=${matterId}`;

		window.open(requestUri, '_self');
	}

	requestSecuredInfoTrackUrl(matterNumber: string): Observable<SecuredUrlMappingResponseDto> {
		const request: SecuredUrlMappingRequestDto = {
			clientReference: matterNumber,
			retailerReference: `${infoTrackAuthConfig.retailerReferencePrefix}_{"tenantId":"${this.authService.OrganisationId}","userId":"${this.authService.UserId}"}`
		};
		return this.infoTrackService.getSecuredSearchUrl(request);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}
}

export interface IInfoTrackAuthResponse {
	id_token: string;
	access_token: string;
	refresh_token: string;
	expires_in: string;
	token_type: string;
	url: string;
}
