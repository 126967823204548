import { Injectable } from '@angular/core';

import { interval, of } from 'rxjs';
import { catchError, debounce, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { ListResponse } from '@common/models/Generic/ListResponse';
import { MatterListItemDto } from '@common/models/Matters/List/MatterListItemDto';
import { MattersService } from '@common/services/matters.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash-es';

import { IAppState } from '../../app.state';
import { loading, MatterListActions } from './matter-list.actions';

@Injectable()
export class MatterListEffects {
	loadMatters$ = createEffect(() =>
		this._actions$.pipe(
			ofType(
				MatterListActions.Load,
				MatterListActions.SetPageIndex,
				MatterListActions.SetPageSize,
				MatterListActions.SetSortBy,
				MatterListActions.SetSortDirection,
				MatterListActions.SetFilters
			),
			withLatestFrom(this._store),
			filter(([_, state]) => !isEqual(state.matterList.request, state.matterList.oldRequest)),
			tap(() => this._store.dispatch(loading())),
			debounce(() => interval(200)),
			switchMap(([_, state]) => {
				return this._mattersService.getMatterList(state.matterList.request).pipe(
					map((response: ListResponse<MatterListItemDto>) => ({
						type: MatterListActions.LoadSuccess,
						response
					})),
					catchError(error => of({ type: MatterListActions.LoadFailed, error }))
				);
			})
		)
	);

	constructor(
		private _actions$: Actions,
		private _store: Store<IAppState>,
		private _mattersService: MattersService
	) {}
}
