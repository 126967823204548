import { BillListItemDto } from '@common/models/Bills/List/BillListItemDto';
import { BillListRequest } from '@common/models/Bills/List/BillListRequest';
import { ListResponse } from '@common/models/Generic/ListResponse';
import { DomainError } from '@common/models/Validation/DomainError';

import { AppConfig } from 'app/app.config';

export interface IBillListState {
	isFetching: boolean;
	list: ListResponse<BillListItemDto>;
	lastSelectedRecordId: string;
	request: Partial<BillListRequest>;
	metadata: BillListMetadata;

	// errrors
	error: DomainError;
}

export interface BillListMetadata {
	sumAmountInclGst: number;
	sumAmountGst: number;
	sumPaidAmountInclGst: number;
	sumDueAmountInclGst: number;
	sumWriteOffAmountInclGst: number;
}

export const initialState: IBillListState = {
	isFetching: false,
	list: null,
	lastSelectedRecordId: null,
	metadata: null,
	request: {
		clientId: null,
		dueDateEnd: null,
		dueDateStart: null,
		issueDateStart: null,
		issueDateEnd: null,
		lawyerId: null,
		search: null,
		statuses: [],

		pageSize: AppConfig.PageSize,
		pageIndex: 0,
		pageIndexForId: null,

		sortBy: null,
		sortDirection: null
	},

	error: null
};
