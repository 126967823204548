// This file has been generated from TaskTemplateDueDateOffsetType.cs
// Do not modify this file directly!!

// LawMaster.Amalthea.Domain.Settings.TaskTemplates.Common.TaskTemplateDueDateOffsetType
export enum TaskTemplateDueDateOffsetType {
	Day = 'Day',
	Week = 'Week',
	Month = 'Month',
	BusinessDay = 'Business Day'
}
