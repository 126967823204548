// This file has been generated from PracticeType.cs
// Do not modify this file directly!!

// LawMaster.Amalthea.Domain.Settings.Setting.Item.PracticeType
export enum PracticeType {
	Unspecified = 'Unspecified',
	LawFirm = 'Law Firm',
	Barrister = 'Barrister',
	CorporateLegalDepartment = 'Corporate Legal Department',
	Government = 'Government'
}
