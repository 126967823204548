<h2 mat-dialog-title class="title">{{ data?.id ? 'Edit' : 'Create' }} Custom Field</h2>
<mat-dialog-content class="flex-column" [formGroup]="form">
	<div class="flex-row">
		<mat-form-field>
			<input #defaultFocusInput formControlName="name" matInput autocomplete="off" />
			<mat-hint>Should begin with a letter and can only contain alphanumeric characters, spaces and hyphens</mat-hint>
			<mat-placeholder
				>Name
				<sup class="color-warn">*</sup>
			</mat-placeholder>
			<mat-error>
				<error-messages [for]="form.get('name')"></error-messages>
			</mat-error>
		</mat-form-field>
	</div>
	<div class="flex-row">
		<mat-form-field>
			<input formControlName="description" matInput autocomplete="off" />
			<mat-hint>Displayed name for custom field</mat-hint>
			<mat-placeholder>Description </mat-placeholder>
			<mat-error>
				<error-messages [for]="form.get('description')"></error-messages>
			</mat-error>
		</mat-form-field>
	</div>
	<div class="flex-row">
		<mat-form-field>
			<mat-placeholder>Practice Areas</mat-placeholder>
			<mat-select #selectPracticeAreas formControlName="practiceAreaIds" (selectionChange)="practiceAreaSelectionChanged($event)" multiple>
				<mat-select-trigger>{{ displaySelected(selectPracticeAreas.value) }}</mat-select-trigger>
				<mat-option (onSelectionChange)="allOptionSelected($event, selectPracticeAreas)" #allOption>All</mat-option>
				<mat-option
					(onSelectionChange)="optionSelected($event, selectPracticeAreas)"
					*ngFor="let practiceArea of practiceAreas"
					[value]="practiceArea.id"
				>
					<div class="c-practice-area-option">
						<span class="c-practice-area-option__title">{{ practiceArea.name }}</span>
						<span class="c-practice-area-option__disabled" *ngIf="!!practiceArea.isDisabled">Disabled</span>
					</div>
				</mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field>
			<mat-select formControlName="fieldType">
				<mat-option *ngFor="let fieldType of fieldTypes" [value]="fieldType">{{ fieldType }}</mat-option>
			</mat-select>
			<mat-placeholder
				>Field Type
				<sup class="color-warn">*</sup>
			</mat-placeholder>
			<mat-error>
				<error-messages [for]="form.get('fieldType')"></error-messages>
			</mat-error>
		</mat-form-field>
	</div>
	<div class="flex-row">
		<mat-form-field>
			<mat-placeholder>Custom Field Groups<sup class="color-warn">*</sup></mat-placeholder>
			<mat-select #selectGroups formControlName="groupIds" multiple>
				<mat-option (onSelectionChange)="optionSelected($event)" *ngFor="let group of customFieldGroups" [value]="group.id">{{
					group.name
				}}</mat-option>
			</mat-select>
			<mat-hint>The field will be displayed within the selected group on the matter details page</mat-hint>
			<mat-error>
				<error-messages [for]="form.get('groupIds')"></error-messages>
			</mat-error>
		</mat-form-field>
	</div>
	<div class="flex-row" *ngIf="!!showExpression">
		<mat-form-field>
			<input formControlName="expression" matInput autocomplete="off" />
			<mat-hint>Expression for a calculated variable</mat-hint>
			<mat-placeholder
				>Expression
				<sup class="color-warn">*</sup>
			</mat-placeholder>
			<mat-error>
				<error-messages [for]="form.get('expression')"></error-messages>
			</mat-error>
		</mat-form-field>

		<button mat-icon-button color="accent" class="no-flex" throttleButton (throttledClick)="configureExpression()" matTooltip="Edit Expression">
			<mat-icon>edit</mat-icon>
		</button>
	</div>
	<div class="flex-row">
		<mat-form-field>
			<input formControlName="helpText" matInput autocomplete="off" />
			<mat-hint>Text to be displayed to the user when editing the matter details</mat-hint>
			<mat-placeholder>Help Text</mat-placeholder>
			<mat-error>
				<error-messages [for]="form.get('helpText')"></error-messages>
			</mat-error>
		</mat-form-field>
	</div>
	<div [featureFlag]="featureFlags.maskingText" class="flex-row" *ngIf="form.get('fieldType')?.value === 'Numeric'">
		<mat-form-field>
			<mat-placeholder>Mask Pattern</mat-placeholder>
			<mat-hint
				>Describes how the numerical value should be displayed. Each hash character represents a digit; add spaces around them.
				<p>{{ form.get('maskingText')?.value }} displayed as {{ formattedHintSample(form.get('maskingText')?.value) }}</p></mat-hint
			>
			<input
				matInput
				#autoCompleteInput
				[matAutocomplete]="auto"
				[formControl]="form.get('maskingText')"
				[value]="form.get('maskingText')?.value"
				(change)="inputChanged($event)"
				autocomplete="off"
			/>
			<mat-error>
				<error-messages [for]="form.get('maskingText')"></error-messages>
			</mat-error>
			<mat-autocomplete class="masking-text" #auto="matAutocomplete" (optionSelected)="autocompleteSelected($event)">
				<mat-option class="option-masking" *ngFor="let masking of defaultMaskingTexts" [value]="masking.value">
					<p class="value-text">{{ masking.value }}</p>
					<span class="hint-text">{{ masking.label }}</span>
				</mat-option>
			</mat-autocomplete>
		</mat-form-field>
	</div>
	<ng-container [ngSwitch]="form.get('fieldType')?.value">
		<div class="flex-row" *ngSwitchCase="'Paragraph'">
			<editable-long-text
				[control]="form.get('defaultValue')"
				[editMode]="true"
				placeHolder="Default Value"
				hintText="Sets value on matter create"
			></editable-long-text>
		</div>
		<div class="flex-row" *ngSwitchCase="'Text'">
			<editable-short-text
				[control]="form.get('defaultValue')"
				[editMode]="true"
				placeHolder="Default Value"
				hintText="Sets value on matter create"
			></editable-short-text>
		</div>
		<div class="flex-row" *ngSwitchCase="'Checkbox'">
			<editable-checkbox
				[control]="form.get('defaultValue')"
				[editMode]="true"
				placeHolder="Default Value"
				hintText="Sets value on matter create"
			></editable-checkbox>
		</div>
		<div class="flex-row" *ngSwitchCase="'Contact'">
			<editable-contact
				#defaultValueContact
				[control]="form.get('defaultValue')"
				[editMode]="true"
				placeHolder="Default Value"
				hintText="Sets value on matter create"
				[allowedContactIds]="selectionListArray"
			></editable-contact>
		</div>
		<div class="flex-row" *ngSwitchCase="'Email'">
			<editable-email-address
				[control]="form.get('defaultValue')"
				[editMode]="true"
				placeHolder="Default Value"
				hintText="Sets value on matter create"
			></editable-email-address>
		</div>
		<div class="flex-row" *ngSwitchCase="'List'">
			<editable-selection
				[control]="form.get('defaultValue')"
				[editMode]="true"
				placeHolder="Default Value"
				hintText="Sets value on matter create"
				[options]="defaultSelectionList"
				optionValue="id"
				optionName="name"
			></editable-selection>
		</div>
		<div class="flex-row" *ngSwitchCase="'Numeric'">
			<editable-numeric-value
				[control]="form.get('defaultValue')"
				[editMode]="true"
				placeHolder="Default Value"
				hintText="Sets value on matter create"
			></editable-numeric-value>
		</div>
		<div class="flex-row" *ngSwitchCase="'Currency'">
			<editable-numeric-value
				[control]="form.get('defaultValue')"
				[editMode]="true"
				placeHolder="Default Value"
				hintText="Sets value on matter create"
				[isCurrency]="true"
			></editable-numeric-value>
		</div>
	</ng-container>
	<div class="flex-row" *ngIf="!showExpression">
		<mat-checkbox formControlName="mandatory">Mandatory</mat-checkbox>
		<mat-slide-toggle formControlName="enabled">{{ form.get('enabled').value ? 'Enabled' : 'Disabled' }}</mat-slide-toggle>
	</div>
	<div class="flex-row" *ngIf="!!canShowReportField && form.get('fieldType')?.value !== 'Paragraph' && form.get('fieldType')?.value !== 'Calculated'">
		<mat-checkbox formControlName="showInReports">
			<div class="flex-checkbox">
				<span>Show in Reports</span>
				<mat-hint>This field will be displayed for selection on the Matter Balances Report and the Matter List Report.</mat-hint>
			</div>
		</mat-checkbox>
	</div>
	<custom-field-list-config [hidden]="!showListConfig" [formGroup]="form" [enabled]="showListConfig"></custom-field-list-config>
	<custom-field-contact-list-config
		[hidden]="!showContactConfig"
		[formGroup]="form"
		[enabled]="showContactConfig"
		entityType="Matter"
		(selectionChanged)="onContactListSelectionChange()"
	></custom-field-contact-list-config>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-raised-button color="primary" [disabled]="form.invalid" throttleButton (throttledClick)="onSaveChanges()">Ok</button>
	<button mat-raised-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
