// This file has been generated from EntityFromTemplateDto.cs
// Do not modify this file directly!!
// tslint:disable

import { SetSimpleType } from '@common/utils/class-transform';

import { TemplateEntityType } from '../TemplateDto/TemplateEntityType';

// LawMaster.Amalthea.Domain.Documents.Item.EntityFromTemplateDto
export class EntityFromTemplateDto {

	// TemplateId
	templateId: string;
	// DocumentName
	documentName: string;
	// EntityId
	entityId: string;
	// IsPdf
	@SetSimpleType(Boolean)
	isPdf: boolean;
	// TemplateEntityType
	templateEntityType: keyof typeof TemplateEntityType;
	// AddDocumentToMatter
	@SetSimpleType(Boolean)
	addDocumentToMatter: boolean;

	constructor() {

	}
}