// This file has been generated from UserTypesController.cs
// Do not modify this file directly!!
// tslint:disable

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { TrimStringsOnObject } from '@common/utils/string-format';

import { EntityReference } from '../../models/Common/EntityReference';
import { ListRequest } from '../../models/Generic/ListRequest';
import { ListResponse } from '../../models/Generic/ListResponse';
import { UserTypeDto } from '../../models/Settings/UserTypes/Item/UserTypeDto';
import { UserTypeListItemDto } from '../../models/Settings/UserTypes/List/UserTypeListItemDto';
import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';

@Injectable({
	providedIn: 'root'
})
export class UserTypesService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'api/v1/settings/UserTypes', 'User Type');
	}

	getUserType(id: string): Observable<UserTypeDto> {
		return this.getItem<void, UserTypeDto>(`${id}`);
	}

	getUserTypeList(request?: Partial<ListRequest>): Observable<ListResponse<UserTypeListItemDto>> {
		return this.getList<ListRequest, UserTypeListItemDto>('', request);
	}

	createUserType(newUserTypeDto: UserTypeDto): Observable<MutationResponseDto> {
		return this.post<UserTypeDto, MutationResponseDto>('', TransformDatesOnObject(UserTypeDto, TrimStringsOnObject(newUserTypeDto)));
	}

	updateUserType(id: string, userTypeDto: UserTypeDto): Observable<MutationResponseDto> {
		return this.put(`${id}`, TransformDatesOnObject(UserTypeDto, TrimStringsOnObject(userTypeDto)));
	}

	deleteUserType(id: string): Observable<MutationResponseDto> {
		return this.delete(`${id}`);
	}

}
