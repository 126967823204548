import { Component, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AppcuesService } from '@common/appcues/appcues.service';
import { NotificationService } from '@common/notification';
import { ContactCustomFieldsService } from '@common/services/customfields-contact.service';
import { CustomFieldsGroupService } from '@common/services/customfields-group.service';
import { MatterCustomFieldsService } from '@common/services/customfields-matter.service';
import { MattersService } from '@common/services/matters.service';
import { DocumentTemplatesService } from '@common/services/settings/documenttemplates.service';
import { MarketplaceService } from '@common/services/settings/marketplace.service';
import { CustomValidators } from '@common/validation/custom.validators';
import { Store } from '@ngrx/store';

import { ICurrentPageState } from 'app/core/state/misc/current-page/current-page.state';

import { ITemplateMergeData } from './ITemplateMergeData';
import { TemplateMergeComponentBase } from './template-merge-base.component';

@Component({
	selector: 'template-merge-dialog',
	styleUrls: ['./template-merge-dialog.component.scss'],
	templateUrl: './template-merge-dialog.component.html'
})
export class TemplateMergeDialogComponent extends TemplateMergeComponentBase {
	constructor(
		@Inject(MAT_DIALOG_DATA) data: ITemplateMergeData,
		protected fb: FormBuilder,
		store: Store<ICurrentPageState>,
		appcuesService: AppcuesService,
		contactCustomFieldsService: ContactCustomFieldsService,
		customFieldsGroupService: CustomFieldsGroupService,
		documentTemplateService: DocumentTemplatesService,
		marketplaceService: MarketplaceService,
		matterCustomFieldsService: MatterCustomFieldsService,
		matterService: MattersService,
		notificationService: NotificationService
	) {
		super(
			store,
			appcuesService,
			contactCustomFieldsService,
			customFieldsGroupService,
			documentTemplateService,
			marketplaceService,
			matterCustomFieldsService,
			matterService,
			notificationService,
			fb
		);

		this.data = data;
	}

	createForm(): void {
		this.form = this.fb.group({
			contactId: null,
			documentName: [
				this.data?.defaultDocumentTitle ?? null,
				[CustomValidators.required('Document Title'), CustomValidators.validateFileName()]
			],
			genericContactId: [null, CustomValidators.requiredWhen(() => this.showMatterContacts)],
			isPdf: false,
			matterId: null,
			templateEntityType: null,
			entityId: null,
			templateId: [this.data?.defaultTemplateId ?? null, CustomValidators.required('Document Template')],
			documentTags: null,
			addDocumentToMatter: false,
			customFields: null
		}) as FormGroup;
	}

	setupCustomFieldsFormControl() {
		const result = this.createCustomFieldsFormGroup({
			config: this.customFieldsGroupedData,
			values: this.model.customFields,
			skipFilter: true,
			validateContacts: false
		});

		this.form.setControl('customFields', result.formGroup);
	}
}
