import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { AppConfigHelper } from 'app/app.config';

@Injectable({
	providedIn: 'root'
})
export class AppConfigPageRouteGuard implements CanActivate {
	constructor(private _router: Router) {}

	canActivate() {
		if (AppConfigHelper.environment == 'dev' || AppConfigHelper.environment == 'test') {
			return true;
		} else {
			this._router.navigate(['no-content'], { skipLocationChange: true });
			return false;
		}
	}
}
