// This file has been generated from ExpenseRecordUpdloadAttachmentsDto.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.Domain.CostRecords.Item.ExpenseRecordUpdloadAttachmentsDto
export class ExpenseRecordUpdloadAttachmentsDto {

	// Attachments
	attachments: File[];
	// DocumentIdsToRemove
	documentIdsToRemove: string[];

	constructor() {

	}
}