import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { XeroAccountsDto } from '@common/models/Bills/XeroSync/XeroAccountsDto';
import { XeroConnectionStatus } from '@common/models/Bills/XeroSync/XeroConnectionStatus';
import { XeroIntegrationSettings } from '@common/models/Settings/BillSettings/Common/XeroIntegrationSettings';
import { XeroTenant } from '@common/models/Settings/BillSettings/Common/XeroTenant';
import { LinkTransientUserDto } from '@common/models/Users/Item/LinkTransientUserDto';
import { LinkTransientUserResponseDto } from '@common/models/Users/Item/LinkTransientUserResponseDto';
import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { TrimStringsOnObject } from '@common/utils/string-format';

@Injectable({
	providedIn: 'root'
})
export class XeroOauth2Service extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'XeroOauth2');
	}

	getXeroAuthUrl(redirectUrl: string): Observable<string> {
		return this.getItem('XeroAuthUrl', { redirectUrl });
	}

	getUsersXeroTenants(): Observable<XeroTenant[]> {
		return this.getItem('UsersXeroTenants');
	}

	getXeroAccounts(xeroTenantId: string): Observable<XeroAccountsDto> {
		return this.getItem('XeroAccounts', { xeroTenantId });
	}

	disconnectUser(): Observable<any> {
		return this.put('DisconnectUser');
	}

	disconnectAllUsers(): Observable<any> {
		return this.put('DisconnectAllUsers');
	}

	connectionStatus(): Observable<XeroConnectionStatus> {
		return this.getItem('ConnectionStatus');
	}

	linkTransientUserId(dto: LinkTransientUserDto): Observable<LinkTransientUserResponseDto> {
		return this.post<LinkTransientUserDto, LinkTransientUserResponseDto>('LinkTransientUserId', dto);
	}

	saveXeroSettings(dto: XeroIntegrationSettings): Observable<XeroIntegrationSettings> {
		return this.post<XeroIntegrationSettings, XeroIntegrationSettings>(
			'SaveXeroSettings',
			TransformDatesOnObject(XeroIntegrationSettings, TrimStringsOnObject(dto))
		);
	}
}
