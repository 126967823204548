import { Component, EventEmitter, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { Observable, Subscription, throwError } from 'rxjs';

import { AppcuesService } from '@common/appcues/appcues.service';
import { GoogleAnalyticsService } from '@common/google-analytics/google-analytics.service';
import { DomainError } from '@common/models/Validation/DomainError';
import { INotificationMessage, NotificationService } from '@common/notification';
import { trimEnd } from 'lodash-es';

import { FeatureFlags, isFeatureFlagEnabled } from 'app/app.config';
import { CreateExpenseComponent } from 'app/create-forms/expense/create-expense.component';
import { CreateTrustRecordComponent } from 'app/create-forms/trust/create-trust-record.component';

import { CreateContactComponent } from '../create-forms/contact/create-contact.component';
import { CreateNoteComponent } from '../create-forms/note/create-note.component';
import { CreateTaskComponent } from '../create-forms/task/create-task.component';
import { CreateTimeRecordComponent } from '../create-forms/time-record/create-time-record.component';
import { DialogConfig, DialogType, EntityType } from './dialog.config';
import { EntityCreatedNotificationService } from './entityCreatedNotification.service';

@Component({
	styleUrls: ['./dialog.component.scss'],
	template: `
		<div class="title-flex">
			<h2 mat-dialog-title class="title">{{ dialogTitle }}</h2>

			<a
				*ngIf="!!dialogConfig?.navLink && !!dialogConfig?.navText"
				class="title-navigation"
				throttleButton
				(throttledClick)="onNavLinkPressed()"
			>
				<mat-icon>link</mat-icon>
				<span>{{ dialogConfig?.navText }}</span>
			</a>
		</div>
		<mat-dialog-content [ngSwitch]="dialogConfig.entityType">
			<create-time-record
				*ngSwitchCase="entityTypes.TimeRecord"
				[editId]="dialogConfig.editId"
				[defaultDuration]="dialogConfig.defaultDuration"
				(isValidChange)="onValidChange($event)"
			></create-time-record>
			<create-expense
				*ngSwitchCase="entityTypes.Expense"
				[editId]="dialogConfig.editId"
				(isValidChange)="onValidChange($event)"
			></create-expense>
			<create-contact
				*ngSwitchCase="entityTypes.Contact"
				(isValidChange)="onValidChange($event)"
			></create-contact>
			<create-task
				*ngSwitchCase="entityTypes.Task"
				[editId]="dialogConfig.editId"
				(isValidChange)="onValidChange($event)"
				(titleChanged)="onTitleChanged($event)"
			>
			</create-task>
			<create-note
				*ngSwitchCase="entityTypes.Note"
				[editId]="dialogConfig.editId"
				[entityType]="dialogConfig.entityType"
				(isValidChange)="onValidChange($event)"
			></create-note>
			<create-trust-record
				*ngSwitchCase="entityTypes.Trust"
				(isValidChange)="onValidChange($event)"
				(titleChanged)="onTitleChanged($event)"
				(dialogClosed)="closeDialog()"
			></create-trust-record>
		</mat-dialog-content>
		<mat-dialog-actions>
			<button
				mat-raised-button
				color="primary"
				[disabled]="invalid || saveClicked"
				throttleButton
				(throttledClick)="onSave()"
			>
				Save
			</button>
			<button
				*ngIf="!!showSaveAndCreate"
				mat-stroked-button
				color="primary"
				[disabled]="invalid || saveClicked"
				throttleButton
				(throttledClick)="onSave(true)"
			>
				Save & Create Time
			</button>
			<button mat-raised-button mat-dialog-close>Cancel</button>
		</mat-dialog-actions>
	`
})
export class DialogComponent implements OnInit, OnDestroy {
	invalid: boolean = true;
	entityTypes: typeof EntityType = EntityType;
	onErrorEvent = new EventEmitter<void>();
	saveClicked: boolean;
	private title: string = this.dialogConfig.dialogType + ' ' + this.dialogConfig.entityType;
	get dialogTitle() {
		return this.title;
	}

	private subscriptions: Subscription = new Subscription();

	@ViewChild(CreateTimeRecordComponent)
	private createTimeRecordComponent: CreateTimeRecordComponent;
	@ViewChild(CreateExpenseComponent)
	private createExpenseComponent: CreateExpenseComponent;
	@ViewChild(CreateContactComponent)
	private createContactComponent: CreateContactComponent;
	@ViewChild(CreateTaskComponent)
	private createTaskComponent: CreateTaskComponent;
	@ViewChild(CreateNoteComponent)
	private createNoteComponent: CreateNoteComponent;
	@ViewChild(CreateTrustRecordComponent)
	private createTrustRecordComponent: CreateTrustRecordComponent;

	get showSaveAndCreate() {
		const allowedEntityType = [EntityType.Task, EntityType.Note]
		return !!isFeatureFlagEnabled(FeatureFlags.activityTimeEntry) && allowedEntityType.includes(this.dialogConfig.entityType);
	}

	constructor(
		private dialogRef: MatDialogRef<DialogComponent>,
		@Inject(MAT_DIALOG_DATA) public dialogConfig: DialogConfig,
		private notifService: NotificationService,
		private entityCreationNotifService: EntityCreatedNotificationService,
		private googleAnalyticsService: GoogleAnalyticsService,
		private appcuesService: AppcuesService,
		private router: Router,
		private dialog: MatDialog
	) {
		switch (this.dialogConfig.entityType) {
			case EntityType.TimeRecord:
			case EntityType.Expense:
			case EntityType.Contact:
			case EntityType.Task:
			case EntityType.Note:
			case EntityType.Trust:
				break;
			default:
				throw new Error('Unsupported form type: ' + this.dialogConfig.entityType);
		}
	}

	ngOnInit(): void {
		this.googleAnalyticsService.trackDialog(this.dialogConfig.dialogType, this.dialogConfig.entityType);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	onNavLinkPressed(): void {
		this.router.navigate(this.dialogConfig.navLink);
		this.dialogRef.close();
	}

	onSave(isEnterTimeRecord: boolean = false): void {
		this.saveClicked = true;
		let save: Observable<INotificationMessage>;
		switch (this.dialogConfig.entityType) {
			case EntityType.TimeRecord:
				save = this.createTimeRecordComponent[this.dialogConfig.dialogType]();
				break;
			case EntityType.Expense:
				save = this.createExpenseComponent[this.dialogConfig.dialogType]();
				break;
			case EntityType.Contact:
				if (this.dialogConfig.dialogType === DialogType.Edit) {
					throw new Error('Developer error. Contact cannot be edited in a dialog');
				}
				save = this.createContactComponent[this.dialogConfig.dialogType]();
				break;
			case EntityType.Task:
				save = this.createTaskComponent[this.dialogConfig.dialogType]();
				break;
			case EntityType.Note:
				save = this.createNoteComponent[this.dialogConfig.dialogType]();
				break;
			case EntityType.Trust:
				if (this.dialogConfig.dialogType === DialogType.Edit) {
					throw new Error(
						'Developer error. Trust does not have an edit feature. Should not satisfy this case statement'
					);
				}
				save = this.createTrustRecordComponent[this.dialogConfig.dialogType]();
				this.createTrustRecordComponent.subscribeToErrors(this);
				break;
			default:
				save = throwError(() => [{ message: 'Unsupported form type: ' + this.dialogConfig.entityType }]);
				break;
		}
		this.subscriptions.add(
			save.subscribe({
				next: (result: INotificationMessage) => {
					if (isEnterTimeRecord) {
						this.dialog.open(DialogComponent, {
							data: new DialogConfig(
								DialogType.Create,
								EntityType.TimeRecord,
								null,
								null,
								null,
								null,
								result.associatedMatterId
							)
						});
					}
					this.entityCreationNotifService.entityCreated(this.dialogConfig.entityType);
					this.appcuesService.trackDialog(this.dialogConfig.entityType);
					this.notifService.showNotificationLink(result);
					this.dialogRef.close(result);
				},
				error: (errs: DomainError[]) => {
					const verb = trimEnd(this.dialogConfig.dialogType.toLowerCase(), 'e');
					const typeLower = this.dialogConfig.entityType.toLowerCase();
					this.notifService.showErrors(`Error ${verb}ing ${typeLower}`, errs);
					this.onErrorEvent.emit();
					this.saveClicked = false;
				}
			})
		);
	}

	onValidChange(valid: boolean): void {
		this.invalid = !valid;
	}

	onTitleChanged(newTitle: string): void {
		if (newTitle) this.title = newTitle;
	}

	closeDialog() {
		this.dialogRef.close();
	}
}
