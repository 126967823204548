import { Component, Input } from '@angular/core';
import { BaseEditableTextComponent } from './base-editable-text.component';

@Component({
	selector: 'editable-short-text',
	styleUrls: ['./base.editable.component.scss'],
	template: `
		<span *ngIf="!editMode" [class]="value() ? '' : 'view-noitem'">
			<ng-container *ngIf="!!value()">
				<ng-container *ngFor="let block of displayBlocks">
					<span *ngIf="!block.link">{{ block.text }}</span>
					<a *ngIf="!!block.link" [matTooltip]="block.link" [href]="block.link">{{ block.text }}</a>
				</ng-container>
			</ng-container>
			<ng-container *ngIf="!value()">
				{{ blankMessage }}
			</ng-container>
		</span>
		<mat-form-field *ngIf="editMode">
			<mat-placeholder *ngIf="placeHolder"
				>{{ placeHolder }}
				<sup class="color-warn" *ngIf="required">*</sup>
			</mat-placeholder>
			<input matInput [formControl]="formControl" [autofocus]="HasAutofocus" autocomplete="off" />
			<mat-hint *ngIf="!!hintText?.length">{{ hintText }}</mat-hint>
			<mat-error>
				<error-messages [for]="control"></error-messages>
			</mat-error>
		</mat-form-field>
	`
})
export class EditableShortTextComponent extends BaseEditableTextComponent {
	@Input()
	HasAutofocus: boolean;
}
