// This file has been generated from SettingTenantContactCreateUpdateDto.cs
// Do not modify this file directly!!
// tslint:disable

import { SetComplexType } from '@common/utils/class-transform';

import { CompanyContactCreateUpdateDto } from '../../../Contacts/Item/CompanyContactCreateUpdateDto';
import { PersonContactCreateUpdateDto } from '../../../Contacts/Item/PersonContactCreateUpdateDto';
import { OrganisationStatusEnum } from '../../OrganisationStatus/Common/OrganisationStatusEnum';
import { PracticeType } from './PracticeType';

// LawMaster.Amalthea.Domain.Settings.Setting.Item.SettingTenantContactCreateUpdateDto
export class SettingTenantContactCreateUpdateDto {

	// CreatePerson
	@SetComplexType(PersonContactCreateUpdateDto)
	createPerson: PersonContactCreateUpdateDto;
	// CreateCompany
	@SetComplexType(CompanyContactCreateUpdateDto)
	createCompany: CompanyContactCreateUpdateDto;
	// ContactId
	contactId: string;
	// PracticeType
	practiceType: keyof typeof PracticeType;
	// OrganisationStatus
	organisationStatus: keyof typeof OrganisationStatusEnum;

	constructor() {
		this.createPerson = new PersonContactCreateUpdateDto();
		this.createCompany = new CompanyContactCreateUpdateDto();
	}
}