<mat-dialog-content>
	<h1>
		Unsubscribe <b>{{ productName }}</b>
	</h1>

	<ng-container *ngIf="!!marketplaceListing && !!configurePackageData">
		<configure-package
			#configurePackageRef
			[operation]="configurePackageOperationType"
			[productName]="marketplaceListing.productName"
			[data]="configurePackageData"
			[form]="configurePackageForm"
		>
		</configure-package>
	</ng-container>

	<div class="container" *ngIf="isReviewSubscriptionStage && !!upcomingInvoice">
		<upcoming-invoice-breakdown
			[upcomingInvoice]="upcomingInvoice"
			[recurringInvoice]="recurringInvoice"
			[interval]="interval"
		></upcoming-invoice-breakdown>
	</div>

	<div class="container" *ngIf="isFinishStage">
		<div *ngIf="finishStageMessages">
			<span *ngFor="let msg of finishStageMessages">
				<p [innerHtml]="msg"></p>
			</span>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-raised-button mat-dialog-close *ngIf="!isFinishStage">Cancel</button>
	<button mat-raised-button color="primary" [disabled]="!!isLoading" throttleButton (throttledClick)="onProceedButtonClick()">{{ nextButtonText }}</button>
	<div *ngIf="!!isLoading" class="loadingContainer">
		<p *ngIf="loadingMessage">{{ loadingMessage }}</p>
		<mat-progress-bar [mode]="this.isQueryLoadingBar ? 'query' : 'indeterminate'"></mat-progress-bar>
	</div>
</mat-dialog-actions>
