<h2 mat-dialog-title class="title">Create Contact</h2>
<mat-dialog-content>
	<div [formGroup]="contactForm" class="flex-column">
		<ng-template [ngIf]="!(isPerson || isCompany || isPlural)">
			<mat-radio-group [(ngModel)]="type" class="flex-column" formControlName="type">
				<mat-radio-button *ngIf="supportsType('Person')" value="Person">Person</mat-radio-button>
				<mat-radio-button *ngIf="supportsType('Company')" value="Company">Company</mat-radio-button>
				<mat-radio-button *ngIf="supportsType('Plural')" value="Plural">Plural</mat-radio-button>
			</mat-radio-group>
		</ng-template>
		<ng-template [ngIf]="isPlural">
			<div class="flex-column">
				<div class="flex-row">
					<div class="list-header">
						<h3 class="title-text">Members</h3>
						<button class="add-button" mat-button color="primary" throttleButton (throttledClick)="addMember()">
							<mat-icon>add_circle</mat-icon>
							<span>&nbsp;New <span class="allocation-word">member</span></span>
						</button>
					</div>
				</div>

				<div class="flex-row">
					<div class="flex-column">
						<div class="flex-row flex-row--list" *ngFor="let contactControl of memberControls; let i = index" formArrayName="memberIds">
							<contact-lookup
								#member
								[allowPlurals]="false"
								[FormControl]="contactControl"
								[AllowCreate]="true"
								[CreatePersonsCtrl]="contactForm.get('createPersons')"
								[CreateCompaniesCtrl]="contactForm.get('createCompanies')"
								[Required]="true"
								Placeholder="Contact"
							></contact-lookup>

							<button mat-icon-button title="Remove" class="list-button" throttleButton (throttledClick)="removeMember(i)">
								<mat-icon>delete</mat-icon>
							</button>
						</div>
					</div>
				</div>

				<mat-error class="members-error" [class.hide-opacity]="!!memberArray.pristine || !memberArray.touched">
					<error-messages [for]="contactForm.get('memberIds')"></error-messages>
				</mat-error>
			</div>

			<div class="flex-row">
				<mat-form-field>
					<input formControlName="fullName" matInput autocomplete="off" />
					<mat-placeholder>
						Full Name
						<sup class="color-warn">*</sup>
					</mat-placeholder>
					<mat-error>
						<error-messages [for]="contactForm.controls.fullName"></error-messages>
					</mat-error>
				</mat-form-field>
			</div>

			<div class="flex-row">
				<input-custom-fields [formGroup]="getControlGroup('customFields')" entityType="Contact"></input-custom-fields>
			</div>
		</ng-template>
		<ng-template [ngIf]="isCompany">
			<div class="flex-row">
				<mat-form-field>
					<input formControlName="fullName" matInput autocomplete="off" />
					<mat-placeholder>
						Full Name
						<sup class="color-warn">*</sup>
					</mat-placeholder>
					<mat-error>
						<error-messages [for]="contactForm.controls.fullName"></error-messages>
					</mat-error>
				</mat-form-field>
			</div>

			<div class="flex-row">
				<input-custom-fields [formGroup]="getControlGroup('customFields')" entityType="Contact"></input-custom-fields>
			</div>
		</ng-template>
		<ng-template [ngIf]="isPerson">
			<div class="flex-row">
				<mat-form-field>
					<input formControlName="firstName" matInput autocomplete="off" />
					<mat-placeholder
						>First Name
						<sup class="color-warn">*</sup>
					</mat-placeholder>
					<mat-error>
						<error-messages [for]="contactForm.controls.firstName"></error-messages>
					</mat-error>
				</mat-form-field>

				<mat-form-field>
					<input formControlName="lastName" matInput autocomplete="off" />
					<mat-placeholder
						>Last Name
						<sup class="color-warn">*</sup>
					</mat-placeholder>
					<mat-error>
						<error-messages [for]="contactForm.controls.lastName"></error-messages>
					</mat-error>
				</mat-form-field>
			</div>

			<div class="flex-row">
				<mat-form-field>
					<input formControlName="middleName" matInput autocomplete="off" />
					<mat-placeholder>Middle Name</mat-placeholder>
					<mat-error>
						<error-messages [for]="contactForm.controls.middleName"></error-messages>
					</mat-error>
				</mat-form-field>
			</div>

			<div class="flex-row">
				<input-custom-fields [formGroup]="getControlGroup('customFields')" entityType="Contact" [contactType]="type"></input-custom-fields>
			</div>
		</ng-template>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-raised-button *ngIf="isPerson || isCompany || isPlural" [mat-dialog-close]="contactForm.value" color="primary" [disabled]="!contactForm.valid">
		Save
	</button>
	<button mat-raised-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
