import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSlideToggle, MatSlideToggleChange } from '@angular/material/slide-toggle';

import { Subscription } from 'rxjs';

import { DomainError } from '@common/models/Validation/DomainError';
import { NotificationService } from '@common/notification';
import { GeneralSettingsService } from '@common/services/settings/generalsettings.service';

import { SignalrService } from 'app/core/signalr/signalr.service';
import { MatterTimerConfigDto } from '@common/models/Settings/Setting/Item/MatterTimerConfigDto';

@Component({
	selector: 'timer-config',
	styleUrls: ['./timer-config.component.scss'],
	templateUrl: './timer-config.component.html'
})
export class TimerConfigComponent implements OnInit, OnDestroy {
	@ViewChild('globalTimerToggle', { read: MatSlideToggle })
	protected globalTimerToggle: MatSlideToggle;

	@ViewChild('globalDeleteTimerToggle', { read: MatSlideToggle })
	protected globalDeleteTimerToggle: MatSlideToggle;

	subscriptions: Subscription = new Subscription();

	constructor(
		private notificationService: NotificationService,
		private generalSettingsService: GeneralSettingsService,
		private _signalrService: SignalrService
	) {}

	ngOnInit() {
		this.subscriptions.add(
			this.generalSettingsService.getMatterTimerConfig().subscribe({
				next: success => {
					this.onTimerConfigChanged(success);
				},
				error: this.onError
			})
		);

		this.subscriptions.add(
			this._signalrService
				.onTimerConfigChanged()
				.subscribe(dto => this.onTimerConfigChanged(dto))
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	private onTimerConfigChanged(dto: MatterTimerConfigDto) {
		this.globalTimerToggle.checked = dto.isGlobalEnabled;
		this.globalDeleteTimerToggle.checked = dto.autoDeleteTimer;
	}

	onGlobalTimerChange(event: MatSlideToggleChange) {
		this.subscriptions.add(
			this.generalSettingsService
				.saveMatterTimerConfig({
					isGlobalEnabled: event.checked,
					autoDeleteTimer: this.globalDeleteTimerToggle.checked
				})
				.subscribe()
		);
	}

	onAutoDeleteTimerChange(event: MatSlideToggleChange) {
		this.subscriptions.add(
			this.generalSettingsService
				.saveMatterTimerConfig({
					isGlobalEnabled: this.globalTimerToggle.checked,
					autoDeleteTimer: event.checked
				})
				.subscribe()
		);
	}

	private onError(errors: DomainError[]) {
		this.notificationService.showErrors('Error', errors);
	}
}
