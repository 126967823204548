<mat-card>
	<div class="flex-column" [formGroup]="form">
		<div class="container">
			<span class="title">Default Document Template</span>
			<document-template-lookup
				class="document-lookup"
				Placeholder="Invoice Template"
				entityType="Invoice"
				[FormControl]="templateIdControl"
				[autoSelectIfOnlyOne]="false"
			></document-template-lookup>
		</div>
	</div>
	<div class="action-pane">
		<button mat-raised-button color="primary" [disabled]="form.invalid || form.disabled || form.pristine" throttleButton (throttledClick)="save()">
			Save
		</button>
	</div>
</mat-card>
