import { createFeatureSelector, createSelector } from '@ngrx/store';
import { get } from 'lodash-es';

import { ClearTaskViewType, SetTaskViewType, TaskViewActions } from './task-view.action';
import { ITaskViewState } from './task-view.state';

export const initialState: ITaskViewState = { id: null, view: null, expiry: null };

export function taskViewReducer(state = initialState, action: TaskViewActions): ITaskViewState {
	switch (action.type) {
		case SetTaskViewType:
			return get(action, 'data', initialState);
		case ClearTaskViewType:
			return initialState;
		default:
			return state;
	}
}

export const getTaskViewState = createFeatureSelector<ITaskViewState>('taskViewData');

export const getTaskView = createSelector(getTaskViewState, (state: ITaskViewState) => state);
