import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { InviteUserDto } from '@common/models/Users/Item/InviteUserDto';
import { CustomValidators } from '@common/validation/custom.validators';

@Component({
	selector: 'invite-user',
	styles: [
		`
			input[readonly] {
				font-family: monospace;
			}

			mat-form-field.mat-form-field {
				display: block;
			}
		`
	],
	templateUrl: './invite-user.component.html'
})
export class InviteUserComponent implements OnInit {
	@ViewChild('inviteUrl')
	inviteUrlElement: ElementRef;
	form: FormGroupTyped<InviteUserDto>;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: InviteUserDto,
		@Inject(DOCUMENT) private document: Document,
		private fb: FormBuilder
	) {}

	ngOnInit() {
		this.form = this.fb.group({
			email: [this.data.email, CustomValidators.email()],
			firstName: this.data.firstName,
			lastName: this.data.lastName,
			registrationUrl: this.data.registrationUrl
		}) as FormGroupTyped<InviteUserDto>;
	}

	copyUrl() {
		this.inviteUrlElement.nativeElement.select();
		this.document.execCommand('copy');
	}
}
