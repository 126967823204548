// This file has been generated from TermsAndConditionsController.cs
// Do not modify this file directly!!
// tslint:disable

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { TrimStringsOnObject } from '@common/utils/string-format';

import { TermsAndConditionsViewDto } from '../../models/Settings/Terms/Item/TermsAndConditionsViewDto';

@Injectable({
	providedIn: 'root'
})
export class TermsAndConditionsService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'api/v1/TermsAndConditions', 'TermsAndConditions');
	}

	get(): Observable<TermsAndConditionsViewDto> {
		return this.getItem<void, TermsAndConditionsViewDto>('');
	}

}
