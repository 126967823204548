// This file has been generated from SecurityRoleViewDto.cs
// Do not modify this file directly!!
// tslint:disable

import { SetComplexType } from '@common/utils/class-transform';

import { RolePermission } from '../../../Common/RolePermission';
import { UserContactReference } from '../../../Contacts/Common/UserContactReference';
import { SecurityPolicies } from '../Common/SecurityPolicies';

// LawMaster.Amalthea.Domain.Settings.SecurityRoles.Item.SecurityRoleViewDto
export class SecurityRoleViewDto {

	// Id
	id: string;
	// Name
	name: string;
	// Members
	@SetComplexType(UserContactReference)
	members: UserContactReference[];
	// Policies
	policies: Array<keyof typeof SecurityPolicies>;
	// Permissions
	permissions: Array<keyof typeof RolePermission>;

	constructor() {
		this.members = [];
		this.policies = [];
		this.permissions = [];
	}
}