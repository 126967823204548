// This file has been generated from BankAccountsController.cs
// Do not modify this file directly!!
// tslint:disable

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { TrimStringsOnObject } from '@common/utils/string-format';

import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';
import { ListResponse } from '@common/models/Generic/ListResponse';
import { BankAccountCreateUpdateDto } from '../../models/Settings/TrustSettings/BankAccounts/Item/BankAccountCreateUpdateDto';
import { BankAccountViewDto } from '../../models/Settings/TrustSettings/BankAccounts/Item/BankAccountViewDto';
import { BankAccountListItemDto } from '../../models/Settings/TrustSettings/BankAccounts/List/BankAccountListItemDto';

@Injectable({
	providedIn: 'root'
})
export class BankAccountsService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'api/v1/settings/BankAccounts', 'Bank Account');
	}

	getBankAccount(id: string): Observable<BankAccountViewDto> {
		return this.getItem<void, BankAccountViewDto>(`${id}`);
	}

	getBankAccountList(): Observable<ListResponse<BankAccountListItemDto>> {
		return this.getList<void, BankAccountListItemDto>('');
	}

	createBankAccount(newBankAccountDto: BankAccountCreateUpdateDto): Observable<MutationResponseDto> {
		return this.post<BankAccountCreateUpdateDto, MutationResponseDto>(
			'',
			TransformDatesOnObject(BankAccountCreateUpdateDto, TrimStringsOnObject(newBankAccountDto))
		);
	}

	updateBankAccount(id: string, bankAccountDto: BankAccountCreateUpdateDto): Observable<MutationResponseDto> {
		return this.put(
			`${id}`,
			TransformDatesOnObject(BankAccountCreateUpdateDto, TrimStringsOnObject(bankAccountDto))
		);
	}

	deleteBankAccount(id: string): Observable<MutationResponseDto> {
		return this.delete(`${id}`);
	}

	setDefaultBankAccount(id: string): Observable<MutationResponseDto> {
		return this.put(`SetDefaultBankAccount/${id}`);
	}
}
