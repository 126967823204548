import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';

import { RolePermission } from '@common/models/Common/RolePermission';
import { NotificationService } from '@common/notification';
import { isEmptyOrWhitespace } from '@common/utils/stringUtils';

import { AuthService } from 'app/core/auth.service';
import { SecurityPermissionService } from 'app/core/security-permissions.service';

@Injectable({
	providedIn: 'root'
})
export class SystemRouteGuardService implements CanActivateChild {
	static routePermissions: { pageName: string; route: string; permission: keyof typeof RolePermission }[] = [
		{ pageName: 'Account and Billing', route: 'subscription', permission: 'SystemMarketplace' },
		{ pageName: 'Marketplace', route: 'marketplace', permission: 'SystemMarketplace' },
		{ pageName: 'Manage Features', route: 'features', permission: 'SystemMarketplace' },

		{ pageName: 'Document Templates', route: 'document-templates', permission: 'SystemDocuments' },
		{ pageName: 'Document Template Groups', route: 'document-template-groups', permission: 'SystemDocuments' },
		{ pageName: 'Document Tags', route: 'document-tags', permission: 'SystemDocuments' },
		{ pageName: 'Document Brief Templates', route: 'document-brief-templates', permission: 'SystemDocuments' },
		{
			pageName: 'Document Brief Export Setttings',
			route: 'document-brief-export-settings',
			permission: 'SystemDocuments'
		},

		{ pageName: 'Matter Custom Fields', route: 'matter-custom-fields', permission: 'SystemCustomFields' },
		{ pageName: 'Contact Custom Fields', route: 'contact-custom-fields', permission: 'SystemCustomFields' },

		{ pageName: 'Invoice and Xero Settings', route: 'bill-settings', permission: 'SystemTimeCost' },
		{ pageName: 'Cost Codes', route: 'cost-codes', permission: 'SystemTimeCost' },
		{ pageName: 'Units', route: 'units', permission: 'SystemTimeCost' },
		{ pageName: 'Costing Templates', route: 'cost-templates', permission: 'SystemTimeCost' },

		{ pageName: 'Practice Areas', route: 'practice-areas', permission: 'SystemMatters' },
		{ pageName: 'Searching and Services', route: 'search-settings', permission: 'SystemMatters' },
		{ pageName: 'Timers', route: 'timers', permission: 'SystemMatters' },
		{ pageName: 'Numbering', route: 'matter-numbering-config', permission: 'SystemMatters' },
		{ pageName: 'Matter Mail Config', route: 'matter-mailto-config', permission: 'SystemMatters' },

		{ pageName: 'Trust & Bank Accounts', route: 'trust-settings', permission: 'SystemTrust' },
		{ pageName: 'Transaction Media Types', route: 'media-types', permission: 'SystemTrust' },

		{ pageName: 'User Accounts', route: 'users', permission: 'SystemUserAccounts' },
		{ pageName: 'User Types', route: 'user-types', permission: 'SystemUserAccounts' },
		{ pageName: 'Security Roles', route: 'security-roles', permission: 'SystemUserAccounts' },

		{ pageName: 'Referral Reasons', route: 'referral-reasons', permission: 'SystemReferralReasons' },

		{ pageName: 'Company Details', route: 'company-details', permission: 'SystemSettingCompany' },
		{ pageName: 'Import', route: 'import', permission: 'SystemSettingCompany' },
		{ pageName: 'API Keys', route: 'api-keys', permission: 'SystemSettingCompany' },
		{ pageName: 'Share Links', route: 'share-links', permission: 'SystemSettingCompany' },

		{ pageName: 'Activity Log', route: 'activity-log', permission: 'SystemReports' }
	];

	hasAccessToTrust = false;

	constructor(
		private authService: AuthService,
		private securityPermissionService: SecurityPermissionService,
		private notificationService: NotificationService,
		private router: Router
	) {}

	canActivateChild(
		childRoute: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		const parts = state.url
			.split('?')[0]
			.split('(')[0]
			.split('/')
			.filter(str => !isEmptyOrWhitespace(str));

		if (
			parts.length > 0 &&
			parts[0]?.toLowerCase() === 'system' &&
			parts.length > 1 &&
			parts[1].toLowerCase() === 'users' &&
			parts.length > 2 &&
			this.authService.UserId.toLowerCase() === parts[2]?.toLowerCase()
		) {
			return true;
		}

		if (
			!!this.securityPermissionService.hasAccessToSettings &&
			parts.length > 0 &&
			parts[0]?.toLowerCase() === 'system'
		) {
			if (parts.length > 1) {
				var match = SystemRouteGuardService.routePermissions.filter(
					row => row.route == parts[1].toLowerCase()
				)[0];

				if (!!match) {
					var hasPermission = this.securityPermissionService.hasRolePermission(match.permission);
					if (!hasPermission) {
						this.showNotification(match.pageName);
						return false;
					}
				}
			}

			return true;
		}

		this.showNotification('Settings');

		return false;
	}

	private showNotification(pageName: string) {
		this.notificationService
			.showError('No Permission', `Sorry, you don't have the necessary permissions to access ${pageName} page.`)
			.subscribe(() => {
				this.router.navigate(['/dashboard']);
			});
	}
}
