import { HttpParameterCodec } from '@angular/common/http';

/*
 *	Enforce the URI on query string parameters.
 *	Need to use for encoding '+' and '-' until Angular fixes issue #18261
 *	https://github.com/angular/angular/issues/18261
*/
export class HttpParamForcedUriCodec implements HttpParameterCodec {
	encodeKey(key: string): string {
		return encodeURIComponent(key);
	}

	encodeValue(value: string): string {
		return encodeURIComponent(value);
	}

	decodeKey(key: string): string {
		return decodeURIComponent(key);
	}

	decodeValue(value: string): string {
		return decodeURIComponent(value);
	}
}
