import { createSelector, MemoizedSelector } from '@ngrx/store';

import { IAppState } from '../../../app.state';
import { TrustAccountListItemDto } from '@common/models/Settings/TrustSettings/TrustAccounts/List/TrustAccountListItemDto';

export const selectTrustAccountList = (state: IAppState) => state.trustAccountList;

export const selectTrustAccountListRecords: MemoizedSelector<IAppState, TrustAccountListItemDto[]> = createSelector(
	selectTrustAccountList,
	state => state?.list?.records || []
);
export const selectTrustAccountListRequest: MemoizedSelector<IAppState, Partial<any>> = createSelector(
	selectTrustAccountList,
	state => state
);
export const selectTrustAccountListPageIndex = createSelector(
	selectTrustAccountList,
	state => state?.list?.pageIndex || 0
);
export const selectTrustAccountListTotalRecords = createSelector(
	selectTrustAccountList,
	state => state?.list?.totalRecords || 0
);
export const selectTrustAccountIsFetching = createSelector(selectTrustAccountList, state => !!state?.isFetching);
