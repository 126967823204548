import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

import { CustomFieldSelectionListItem } from '@common/models/Settings/CustomFields/Common/CustomFieldSelectionListItem';
import { newGuid } from '@common/utils/create-guid';
import { CustomValidators } from '@common/validation/custom.validators';

const selectionListFieldName = 'selectionList';
const newEntryFieldName = 'selectionListEntryName';

@Component({
	selector: 'custom-field-list-config',
	styleUrls: ['./custom-field-list-config.component.scss'],
	templateUrl: './custom-field-list-config.component.html'
})
export class CustomFieldListConfigComponent implements OnInit {
	selectionList: FormArray;
	validationEnabled: boolean;

	@Input()
	formGroup: FormGroup;

	@Input()
	set enabled(value: boolean) {
		this.validationEnabled = value;

		// Force validation when the field type changes, clear the list when changing to a non-list type
		// protect against calls before ngOnInit
		if (this.selectionList) {
			if (!this.validationEnabled) {
				while (this.selectionList.length > 0) {
					this.selectionList.removeAt(0);
				}
			}
			this.selectionList.updateValueAndValidity();
		}
	}

	constructor(private fb: FormBuilder) {}

	ngOnInit(): void {
		const uniqueValidator = CustomValidators.uniqueArrayField('name');
		const nonEmptyValidator = CustomValidators.nonEmptyArray();
		this.selectionList = this.fb.array(
			[],
			[
				c => (this.validationEnabled ? uniqueValidator(c) : null),
				c => (this.validationEnabled ? nonEmptyValidator(c) : null)
			]
		);
		this.formGroup.addControl(selectionListFieldName, this.selectionList);
		this.formGroup.addControl(newEntryFieldName, this.fb.control(null));
	}

	// Update the form data when editing an existing custom field
	updateModel(data: CustomFieldSelectionListItem[]) {
		data.forEach(item => this.addNewEntry(item.name, item.id));
	}

	addNewEntry(name: string, id: string) {
		const nameControl = this.fb.control(name, CustomValidators.required());
		this.selectionList.push(this.fb.group({ id, name: nameControl }));
		nameControl.markAsTouched();
	}

	// When first changing the new item line, add another new item below it and update the available options
	onNewNamedEntered() {
		const control = this.formGroup.get(newEntryFieldName);
		const newName = control.value;
		if (newName) {
			this.addNewEntry(newName, newGuid());
		}
		this.formGroup.get(newEntryFieldName).reset();
	}

	moveUp(index: number) {
		if (index > 0) {
			const controlToMove = this.selectionList.controls[index];
			// Remove the lower item first, so the indexes don't change for the insert
			this.selectionList.removeAt(index);
			this.selectionList.insert(index - 1, controlToMove);
		}
	}

	moveDown(index: number) {
		// prevent moving past the end of the list
		if (index >= 0 && index < this.selectionList.length - 1) {
			// Move down is the same as moving the next item up
			this.moveUp(index + 1);
		}
	}

	delete(index: number) {
		this.selectionList.removeAt(index);
	}
}
