import { FormBuilder, FormControl, ValidatorFn } from '@angular/forms';

import { BehaviorSubject, debounceTime, forkJoin, of, Subscription, switchMap, tap } from 'rxjs';

import { EntityReference } from '@common/models/Common/EntityReference';
import { ContactType } from '@common/models/Contacts/Common/ContactType';
import { Variable } from '@common/models/Documents/Item/Variable';
import { ListResponse } from '@common/models/Generic/ListResponse';
import { CustomFieldEntityType } from '@common/models/Settings/CustomFields/Common/CustomFieldEntityType';
import { CustomFieldType } from '@common/models/Settings/CustomFields/Common/CustomFieldType';
import { CustomFieldGroupListItemDto } from '@common/models/Settings/CustomFields/List/CustomFieldGroupListItemDto';
import { CustomFieldListItemDto } from '@common/models/Settings/CustomFields/List/CustomFieldListItemDto';
import { ContactCustomFieldsService } from '@common/services/customfields-contact.service';
import { CustomFieldsGroupService } from '@common/services/customfields-group.service';
import { MatterCustomFieldsService } from '@common/services/customfields-matter.service';
import { arrayAs, arrayDeepCopy } from '@common/utils/arrayUtils';
import { CustomValidators } from '@common/validation/custom.validators';
import { flatten, get, isEmpty, isNil, isString, reverse, sortBy, uniqBy } from 'lodash-es';
import * as moment from 'moment-timezone';

/**
 * Contains functions and observables for setting up custom fields for matter or contact summary components.
 */
export class BaseSummaryComponent {
	customFields: CustomFieldListItemDto[] = [];
	customFieldsGroupedData: ISummaryCustomFields[] = [];
	customFieldsRefreshComplete: BehaviorSubject<boolean> = new BehaviorSubject(null);

	protected subscriptions = new Subscription();
	constructor(
		protected matterCustomFieldsService: MatterCustomFieldsService,
		protected contactCustomFieldsService: ContactCustomFieldsService,
		protected customFieldsGroupService: CustomFieldsGroupService,
		protected fb: FormBuilder
	) {}

	/**
	 *
	 * @param config An array of ISummaryCustomFields or CustomFieldListItemDto.
	 * @param fieldValues Matter or Contact Custom Field values for use in filter (readonly).
	 * @param filterConfig How to filter the data
	 * @returns A filtered configuration in the same shape of type T.
	 */
	protected baseFilter<T extends ISummaryCustomFields | CustomFieldListItemDto>(
		data: T[],
		filterConfig?: ISummaryCustomFieldFilterConfiguration
	): T[] {
		if (isEmpty(data)) return [];

		// Deep copy here as map operations below will mutate the summary groups
		const dataCopy: T[] = arrayDeepCopy(data);

		const isISummaryCustomFields = this.isSummaryType(dataCopy[0]);

		if (isISummaryCustomFields) {
			return arrayAs<ISummaryCustomFields>(dataCopy)
				.map(grp => {
					// recursively filter the customFields array
					grp.customFields = this.baseFilter(grp.customFields, filterConfig);

					return grp;
				})
				.filter(grp => grp.customFields.length > 0) as T[];
		} else {
			return this.baseFilterCore(arrayAs<CustomFieldListItemDto>(dataCopy), filterConfig) as T[];
		}
	}

	/**
	 *
	 * @param data The data used to create the formGroup.
	 * @param data.skipFilter Should this apply the default filter?
	 * @param filterConfig Provides the 'baseFilter<T>()' defined in this base class with a configuration
	 * @returns A new FormGroup containing custom field FormControls based on the provided data.
	 */
	protected createCustomFieldsFormGroup<T extends ISummaryCustomFields | CustomFieldListItemDto>(
		data: ICreateCustomFieldFormControlData<T>,
		filterConfig?: ISummaryCustomFieldFilterConfiguration
	): ICreateCustomFieldFormGroupResult<T> {
		const formGroup = this.fb.group({});
		const result: ICreateCustomFieldFormGroupResult<T> = { formGroup, filteredConfig: [] };

		if (isEmpty(data.config)) return result;

		// Apply filter
		result.filteredConfig = data.skipFilter ? data.config : this.baseFilter(data.config, filterConfig);

		if (isEmpty(result.filteredConfig)) return result;

		const configListItemsFlattened = this.isSummaryType(result.filteredConfig[0])
			? this.flattenGroupData(arrayAs<ISummaryCustomFields>(result.filteredConfig))
			: arrayAs<CustomFieldListItemDto>(result.filteredConfig);

		configListItemsFlattened.forEach(dto => {
			let currentValue = get(data.values, dto.id);

			const validators: ValidatorFn[] = [];
			if (!!dto.mandatory && (!!data.validateContacts || dto.fieldType !== CustomFieldType.Contact)) {
				validators.push(CustomValidators.required(dto.name));
			}

			if (dto.fieldType === CustomFieldType.Email) validators.push(CustomValidators.optionalEmail());

			if (dto.fieldType === CustomFieldType.Date && !isNil(currentValue)) {
				currentValue = moment(currentValue).format('YYYY-MM-DD');
			}

			formGroup.addControl(dto.id, new FormControl({ value: currentValue, disabled: !dto.enabled }, validators));
		});

		return result;
	}

	/**
	 * Listens to value changes on custom fields which exist in multiple groups and sets the value again {emitEvent:false} to prevent loops.
	 */
	protected detectMultiGroupCustomFieldUpdates<T extends ISummaryCustomFields | CustomFieldListItemDto>(
		config: T[],
		formGroup: AbstractControl
	) {
		if (!formGroup?.value || isEmpty(config)) return;
		const formKeys = Object.keys(formGroup.value);
		if (isEmpty(formKeys)) return;

		const transformedDataConfig: CustomFieldListItemDto[] = this.isSummaryType(config[0])
			? this.flattenGroupData(arrayAs<ISummaryCustomFields>(config))
			: arrayAs<CustomFieldListItemDto>(config);
		if (isEmpty(transformedDataConfig)) return;

		const customFieldIdGroupCountMap = transformedDataConfig.reduce((curr: { [key: string]: number }, prev) => {
			curr[prev.id] = prev.customFieldGroups.length;
			return curr;
		}, {});

		formKeys.forEach(key => {
			if (get(customFieldIdGroupCountMap, key, 0) > 1) {
				this.subscriptions.add(
					formGroup
						.get(key)
						.valueChanges.pipe(
							debounceTime(100),
							tap(val => formGroup.get(key).setValue(val, { emitEvent: false }))
						)
						.subscribe()
				);
			}
		});
	}

	protected flattenGroupData(groupData: ISummaryCustomFields[]) {
		return uniqBy(flatten(groupData.map(x => x.customFields)), x => x.id);
	}

	protected getMatterCustomFields$(
		customFieldIds: string[],
		customFieldGroupIds: string[],
		practiceAreaIds: string[],
		variables: Variable[] = null
	) {
		const requests: Observable<ListResponse<CustomFieldGroupListItemDto> | ListResponse<CustomFieldListItemDto>>[] =
			[];

		requests.push(
			this.customFieldsGroupService.getCustomFieldGroupList({
				includeCustomFieldIds: customFieldIds,
				includeCustomFieldGroupIds: customFieldGroupIds,
				practiceAreaIds,
				entityType: 'Matter'
			})
		);
		requests.push(
			this.matterCustomFieldsService.getMatterCustomFieldList({
				includeCustomFieldIds: customFieldIds,
				includeCustomFieldGroupIds: customFieldGroupIds,
				practiceAreaIds
			})
		);

		return this.getCustomFields$(requests, CustomFieldEntityType.Matter, variables);
	}

	protected getContactCustomFields$(
		customFieldIds: string[],
		customFieldGroupIds: string[],
		contactTypes: ContactType[]
	) {
		const requests: Observable<ListResponse<CustomFieldGroupListItemDto> | ListResponse<CustomFieldListItemDto>>[] =
			[];

		requests.push(
			this.customFieldsGroupService.getCustomFieldGroupList({
				includeCustomFieldIds: customFieldIds,
				includeCustomFieldGroupIds: customFieldGroupIds,
				contactTypes,
				entityType: 'Contact'
			})
		);
		requests.push(
			this.contactCustomFieldsService.getContactCustomFieldList({
				includeCustomFieldIds: customFieldIds,
				includeCustomFieldGroupIds: customFieldGroupIds,
				contactTypes
			})
		);

		return this.getCustomFields$(requests, CustomFieldEntityType.Contact, null);
	}

	private getCustomFields$(
		requests: Observable<ListResponse<CustomFieldGroupListItemDto> | ListResponse<CustomFieldListItemDto>>[],
		customFieldEntityType: CustomFieldEntityType,
		variables: Variable[] = null
	) {
		this.customFieldsRefreshComplete.next(false);
		this.customFieldsGroupedData = [];

		return forkJoin(requests).pipe(
			switchMap(([groupsResponse, customFieldsresponse]) => {
				const sortedGroups = sortBy(
					(groupsResponse as ListResponse<CustomFieldGroupListItemDto>).records,
					x => x.orderNumber
				);

				// Get all the custom Fields Meta Data
				const customFieldResponseRecords = (customFieldsresponse as ListResponse<CustomFieldListItemDto>)
					.records;

				if (!!variables && variables.length > 0) {
					const filteredCustomFields = customFieldResponseRecords.filter(
						field =>
							variables
								.map(x => x.key)
								.filter(
									y =>
										y.indexOf(
											`${customFieldEntityType}__Field__${field.name.replace(/\s/g, '_')}`
										) > -1
								)?.length > 0
					);
					this.customFields = filteredCustomFields;
				} else {
					this.customFields = customFieldResponseRecords;
				}

				// Get the groups that are used in the Custom Fields
				const activeGroups = uniqBy(flatten(this.customFields.map(x => x.customFieldGroups)), x => x.id);

				// Sort the groups based on the order defined in the Groups
				const applicableGroups = activeGroups.sort((a, b) => {
					return sortedGroups.map(x => x.id).indexOf(a.id) - sortedGroups.map(x => x.id).indexOf(b.id);
				});

				// build the customFieldsGroupedData used to display
				const customFieldsGroupedData: ISummaryCustomFields[] = [];
				applicableGroups.forEach(group => {
					const groupDto = sortedGroups.filter(x => x.id === group.id)[0];

					let customFields = this.customFields.filter(
						x => x.customFieldGroups.map(y => y.id).indexOf(group.id) !== -1
					);

					customFields = reverse(
						customFields.sort((a, b) => {
							return groupDto?.customFieldIds.indexOf(a.id) - groupDto?.customFieldIds.indexOf(b.id);
						})
					);

					customFieldsGroupedData.push({
						group,
						customFields
					});
				});

				this.customFieldsGroupedData = customFieldsGroupedData;
				this.customFieldsRefreshComplete.next(true);

				return of({ customFields: this.customFields, customFieldsGroupedData: this.customFieldsGroupedData });
			})
		);
	}

	private baseFilterCore = (
		dtos: CustomFieldListItemDto[],
		filter: ISummaryCustomFieldFilterConfiguration = null
	) => {
		if (isNil(filter)) return dtos;

		return dtos.filter(
			dto =>
				// Ignore if the custom field is disabled and has no value
				(dto.enabled || (!!filter.fieldValues && !this.isValueEmpty(get(filter.fieldValues, dto.id)))) &&
				// Filter in types
				(isNil(filter.allowedTypes) ||
					isEmpty(filter.allowedTypes) ||
					filter.allowedTypes.includes(dto.fieldType)) &&
				// Filter out types
				(isNil(filter.excludeTypes) ||
					isEmpty(filter.excludeTypes) ||
					!filter.excludeTypes.includes(dto.fieldType))
		);
	};

	private isSummaryType(value: any) {
		return typeof value.customFields === 'object';
	}

	private isValueEmpty(value: any): boolean {
		return isNil(value) || (isString(value) && value === '');
	}
}

export interface ISummaryCustomFields {
	group: EntityReference;
	customFields: CustomFieldListItemDto[];
}

export interface ICreateCustomFieldFormControlData<T extends ISummaryCustomFields | CustomFieldListItemDto> {
	config: T[];
	readonly values: {
		[key: string]: any;
	};
	readonly validateContacts?: boolean;
	skipFilter?: boolean;
}

export interface ICreateCustomFieldFormGroupResult<T extends ISummaryCustomFields | CustomFieldListItemDto> {
	formGroup: FormGroup;
	filteredConfig: T[];
}

export type ISummaryCustomFieldFilterConfiguration = {
	allowedTypes?: (keyof typeof CustomFieldType)[];
	excludeTypes?: (keyof typeof CustomFieldType)[];
	// Instructs the filter to include disabled fields which have a value
	fieldValues?: { [key: string]: any };
};
