import { Injectable } from '@angular/core';

import { interval, of } from 'rxjs';
import { catchError, debounce, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { IAppState } from '../../../app.state';
import { loading, CostCodeListActions } from './cost-code-list.actions';
import { CostCodesService } from '@common/services/settings/costcodes.service';

@Injectable()
export class CostCodeListEffects {
	loadCostCodes$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				CostCodeListActions.Load,
				CostCodeListActions.SetPageIndex,
				CostCodeListActions.SetPageSize,
				CostCodeListActions.SetSortBy,
				CostCodeListActions.SetSortDirection,
				CostCodeListActions.SetFilters
			),
			tap(action => this.store.dispatch(loading())),
			withLatestFrom(this.store),
			debounce(() => interval(200)),
			switchMap(([action, state]) => {
				return this.costCodeService.getCostCodeList(state.costCodeList.request).pipe(
					map(response => {
						return { type: CostCodeListActions.LoadSuccess, response };
					}),
					catchError(error => of({ type: CostCodeListActions.LoadFailed, error }))
				);
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<IAppState>,
		private costCodeService: CostCodesService
	) {}
}
