import { createSelector, MemoizedSelector } from '@ngrx/store';

import { IAppState } from '../../../app.state';
import { BankAccountListItemDto } from '@common/models/Settings/TrustSettings/BankAccounts/List/BankAccountListItemDto';

export const selectBankAccountList = (state: IAppState) => state.bankAccountList;

export const selectBankAccountListRecords: MemoizedSelector<IAppState, BankAccountListItemDto[]> = createSelector(
	selectBankAccountList,
	state => state?.list?.records || []
);
export const selectBankAccountListRequest: MemoizedSelector<IAppState, Partial<any>> = createSelector(
	selectBankAccountList,
	state => state
);
export const selectBankAccountListPageIndex = createSelector(
	selectBankAccountList,
	state => state?.list?.pageIndex || 0
);
export const selectBankAccountListTotalRecords = createSelector(
	selectBankAccountList,
	state => state?.list?.totalRecords || 0
);
export const selectBankAccountIsFetching = createSelector(selectBankAccountList, state => !!state?.isFetching);
