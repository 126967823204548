// This file has been generated from TaskTemplateAssignedToContactOptions.cs
// Do not modify this file directly!!
// tslint:disable

import { TaskTemplateAssignedToContactType } from './TaskTemplateAssignedToContactType';

// LawMaster.Amalthea.Domain.Settings.TaskTemplates.Common.TaskTemplateAssignedToContactOptions
export class TaskTemplateAssignedToContactOptions {

	// Source
	source: keyof typeof TaskTemplateAssignedToContactType;
	// ContactId
	contactId: string;
	// UserTypeId
	userTypeId: string;

	constructor() {

	}
}