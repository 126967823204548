import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { ActivityLogListFilterComponent } from '../filter/activity-log-list-filter.component';
import { ActivityLogListFilterConfig } from '../filter/ActivityLogListFilterConfig';

@Component({
	selector: 'activity-log-list-filter-dialog',
	styleUrls: ['./activity-log-list-filter-dialog.component.scss'],
	templateUrl: './activity-log-list-filter-dialog.component.html'
})
export class ActivityLogListFilterDialogComponent implements OnInit, OnDestroy {
	@ViewChild(ActivityLogListFilterComponent, { static: true }) filter: ActivityLogListFilterComponent;

	private _subscriptions = new Subscription();

	constructor(
		@Inject(MAT_DIALOG_DATA) public config: ActivityLogListFilterConfig,
		private dialogRef: MatDialogRef<ActivityLogListFilterDialogComponent>
	) {}

	ngOnInit(): void {
		this._subscriptions.add(this.dialogRef.beforeClosed().subscribe(() => this.filter.root.applyFilter()));
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}

	apply() {
		this.dialogRef.close(this.filter.root.filter);
	}

	clear() {
		this.dialogRef.close({});
	}
}
