<div class="flex-column" [formGroup]="form">
	<div class="flex-row">
		<mat-form-field>
			<mat-placeholder
				>Trust Account
				<sup class="color-warn">*</sup>
			</mat-placeholder>
			<mat-select formControlName="trustAccountId">
				<mat-option (onSelectionChange)="trustAccountChanged($event, trustAccount)" *ngFor="let trustAccount of trustAccounts" [value]="trustAccount.id"
					>{{ trustAccount.bankDetails.accountName }}</mat-option
				>
			</mat-select>
			<mat-hint *ngIf="!isReadOnly && currentTrustAccount?.currentBalance !== undefined">
				<div class="flex-row">
					<span>Total : {{ currentTrustAccount?.currentBalance | currency: '$' }}</span>
				</div>
			</mat-hint>
		</mat-form-field>
		<editable-numeric-value
			#amount
			placeHolder="Amount"
			tooltip="Total Amount on the transaction"
			[control]="form.controls.amount"
			[isCurrency]="true"
			[editMode]="true"
		>
		</editable-numeric-value>
		<mat-form-field class="small-form-field">
			<mat-placeholder>Date of {{ transactionTypeEnum[transactionType] }}<sup class="color-warn">*</sup> </mat-placeholder>
			<input formControlName="date" matInput [matDatepicker]="datePicker" autocomplete="off" />
			<mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
			<mat-datepicker #datePicker></mat-datepicker>
			<mat-error>
				<error-messages [for]="form.controls.date"></error-messages>
			</mat-error>
		</mat-form-field>
	</div>

	<h3>Matter Allocations</h3>
	<div class="matter-allocation-container" *ngFor="let allocationgroup of matterAllocationControls; let i = index" formArrayName="matterAllocations">
		<matter-allocations
			*ngIf="!isReadOnly"
			[trustAccountId]="form.get('trustAccountId').value"
			[transactionType]="transactionType"
			[totalTrustAccounBalance]="currentTrustAccount?.currentBalance"
			[statutoryMatterId]="currentTrustAccount?.statutoryMatterId"
			[allocationGroup]="allocationgroup"
			(addAllocationClicked)="addAllocation()"
			(removeAllocationClicked)="removeAllocation($event)"
			[index]="i"
			[hideDelete]="matterAllocationControls.length === 1"
			[hideDescription]="true"
		></matter-allocations>
	</div>
	<ng-container *ngIf="isReadOnly">
		<div class="matter-readonly-container" *ngFor="let matterAllocation of matterAllocations">
			<div class="matter-readonly">
				<div class="matter-heading-readonly">Matter:&nbsp;</div>
				<a throttleButton (throttledClick)="closeDialog()" [routerLink]="['/matters', matterAllocation.matter.id]"
					>{{ matterAllocation.matter.name }}</a
				>
				<div class="matter-heading-readonly">&nbsp;&nbsp;&nbsp;&nbsp;Amount:&nbsp;</div>
				{{ matterAllocation.amount | currency: '$' }}&nbsp;&nbsp;&nbsp;&nbsp;
			</div>
		</div>
	</ng-container>
	<button *ngIf="!isReadOnly" class="add-allocation" mat-stroked-button color="primary" throttleButton (throttledClick)="addAllocation()">
		<mat-icon>add</mat-icon>
		<span>New Allocation</span>
	</button>
	<div *ngIf="warningText && form.valid" class="info-message color-warning">
		<mat-icon>info</mat-icon>
		<span> {{ warningText }} </span>
	</div>
	<mat-error> <error-messages [for]="form.get('matterAllocations')"></error-messages> </mat-error>
</div>
