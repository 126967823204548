import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { NotificationService } from '@common/notification';

import { ExternalPortalRouteService } from 'app/services/external-portal-route-service';
import { PortalAuthService } from 'app/services/portal-auth-service';
import { SystemSettingsResolverService } from 'app/services/settings-resolver.service';

import { AuthService } from './auth.service';

@Injectable()
export class PortalAuthInterceptor implements HttpInterceptor {
	private shareLinkErrorShown: boolean;

	constructor(
		private authService: AuthService,
		private portalService: PortalAuthService,
		private portalRouteService: ExternalPortalRouteService,
		private settingResolverService: SystemSettingsResolverService,
		private notifService: NotificationService,
		private router: Router
	) {}

	setAuthorization(req: HttpRequest<any>): HttpRequest<any> {
		const accessToken = this.portalService.getCode();
		return !accessToken ? req : req.clone({ setHeaders: { 'X-ACCESS-TOKEN': accessToken } });
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (!this.portalRouteService.useExternalPortalAuth) {
			return next.handle(req);
		}

		// Only add access token headers when talking to the app server, auth server requests mustn't use withCredentials
		if (!req.url.startsWith(this.settingResolverService.getAppServerUrl())) {
			return next.handle(req);
		}

		const updatedRequest = this.setAuthorization(req);
		return next.handle(updatedRequest).pipe(
			catchError(err => {
				// Catch the 401/403 status code to redirect to the login page
				if (err instanceof HttpErrorResponse && [401, 403].indexOf(err.status) > -1) {
					if (!!this.portalRouteService.isPortalLogin) {
						return throwError(() => err);
					}

					if (!!this.portalRouteService.isPortalDocument) {
						if (!this.shareLinkErrorShown) {
							this.shareLinkErrorShown = true;

							const title = 'Invalid Share Link';
							const html = 'The document share link provided is either invalid or expired';

							if (!!this.authService.hasValidAccessToken) {
								this.notifService.showError(title, html).subscribe(async () => {
									this.router.navigate(['/']).then(() => window.location.reload());
								});
							} else {
								this.notifService.showError(title, html, true);
							}
						}
					} else if (!!this.portalRouteService.isPortal) {
						this.router.navigateByUrl(this.portalRouteService.loginUri);
					}
					return EMPTY;
				}
				return throwError(() => err);
			})
		);
	}
}
