import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { CustomValidators } from '@common/validation/custom.validators';

import { DocumentCategoryDto } from '@common/models/Settings/DocumentCategories/Item/DocumentCategoryDto';

@Component({
	selector: 'add-document-category',
	styleUrls: ['./add-document-category.component.scss'],
	templateUrl: './add-document-category.component.html'
})
export class AddDocumentCategoryComponent implements OnInit, AfterViewInit {
	@ViewChild('documentCategoryInput', { static: true })
	documentCategoryInput: ElementRef;
	form: FormGroup;
	isCreateMode: boolean;

	constructor(@Inject(MAT_DIALOG_DATA) public data: DocumentCategoryDto, private fb: FormBuilder) {}

	ngOnInit() {
		this.form = this.fb.group({
			id: this.data.id,
			name: [this.data.name, CustomValidators.required('Document Template Group')]
		});
		this.isCreateMode = !this.data.id;
	}
	ngAfterViewInit() {
		if (this.documentCategoryInput) {
			setTimeout(() => this.documentCategoryInput.nativeElement.focus(), 0);
		}
	}
}
