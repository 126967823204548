import { Component, OnInit } from '@angular/core';

import { map } from 'rxjs/operators';

import { EntityReference } from '@common/models/Common/EntityReference';
import { Store } from '@ngrx/store';

import { IAppState } from 'app/core/state/app.state';
import { load } from 'app/core/state/filters/job-status-fiiter/job-status-filter.actions';
import { selectJobStatusRecords } from 'app/core/state/filters/job-status-fiiter/job-status-filter.selectors';
import { FilterBaseComponent } from 'app/shared/filter-controls/filter-base.component';

@Component({
	selector: 'filter-job-status-button',
	styleUrls: ['./filter-base.component.scss'],
	template: `
		<filter-entity-reference-list
			[root]="root"
			[name]="name"
			[label]="label"
			[items]="source$ | async"
			[showTitleAsPrefix]="showTitleAsPrefix"
		></filter-entity-reference-list>
	`
})
export class FilterJobStatusButtonComponent extends FilterBaseComponent implements OnInit {
	source$: Observable<EntityReference[]> = this._store
		.select(selectJobStatusRecords)
		.pipe(map(statuses => statuses.map(status => ({ id: status, name: status })).sort()));

	constructor(private _store: Store<IAppState>) {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();

		this._store.dispatch(load());
	}
}
