<div [formGroup]="formGroup" class="condition">
	<calculation-variable-lookup
		class="variable-lookup"
		[FormControl]="formGroup.get('variableName')"
		[Required]="true"
		entityType="Matter"
		Placeholder="Variable"
	></calculation-variable-lookup>
	<mat-form-field>
		<mat-placeholder>
			Operator
			<sup class="color-warn">*</sup>
		</mat-placeholder>
		<mat-select formControlName="operator">
			<mat-option *ngFor="let option of this.operators" [value]="option.symbol">{{ option.name }}</mat-option>
		</mat-select>
	</mat-form-field>
	<ng-container *ngIf="!!expectsValue()">
		<contact-lookup *ngIf="!!isContactVariable" class="value-field" Placeholder="Value" [FormControl]="formGroup.get('expectedValue')"></contact-lookup>
		<mat-form-field *ngIf="!!isDateVariable" class="value-field">
			<mat-placeholder>Value</mat-placeholder>
			<input formControlName="expectedValue" matInput [matDatepicker]="dueDatePicker" autocomplete="off" />
			<mat-datepicker-toggle matSuffix [for]="dueDatePicker"></mat-datepicker-toggle>
			<mat-datepicker #dueDatePicker></mat-datepicker>
			<mat-error>
				<error-messages [for]="formGroup.get('expectedValue')"></error-messages>
			</mat-error>
		</mat-form-field>
		<mat-form-field *ngIf="!isContactVariable && !isDateVariable" class="value-field">
			<mat-placeholder>Value</mat-placeholder>
			<input matInput type="text" formControlName="expectedValue" autocomplete="off" />
		</mat-form-field>
	</ng-container>
	<div class="remove-icon">
		<a throttleButton (throttledClick)="removeClicked.emit($event)"><mat-icon class="remove-icon">delete_outline</mat-icon></a>
	</div>
	<div class="remove-button">
		<button mat-raised-button title="Remove" class="delete-button" throttleButton (throttledClick)="removeClicked.emit($event)">
			<mat-icon>delete</mat-icon>
			<span>Remove</span>
		</button>
	</div>
</div>
