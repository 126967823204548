<mat-table cdkDropList [dataSource]="datasource" (cdkDropListDropped)="dropRow($event)">
	<ng-container matColumnDef="description">
		<mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
		<mat-cell throttleButton (throttledClick)="edit(row)" *matCellDef="let row">
			<div class="description-container">
				<mat-icon>drag_indicator</mat-icon>
				<div>
					<span class="description">{{ row.description }}</span>
					<div class="dueDateCalcDescription">{{ row.dueDateCalcDescription }}</div>
				</div>
			</div>
		</mat-cell>
	</ng-container>

	<ng-container matColumnDef="type">
		<mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
		<mat-cell *matCellDef="let row">
			<span class="type">{{ row.taskType | taskType }}</span>
		</mat-cell>
	</ng-container>

	<ng-container matColumnDef="assignedToContactDisplayName">
		<mat-header-cell *matHeaderCellDef>Assigned To</mat-header-cell>
		<mat-cell *matCellDef="let row">
			<span class="assignedToContactDisplayName">{{ row.assignedToContactDisplayName }}</span>
		</mat-cell>
	</ng-container>

	<ng-container matColumnDef="status">
		<mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
		<mat-cell *matCellDef="let row">
			<span class="status">{{ row.status | taskStatus }}</span>
		</mat-cell>
	</ng-container>

	<ng-container matColumnDef="priority">
		<mat-header-cell *matHeaderCellDef>Priority</mat-header-cell>
		<mat-cell *matCellDef="let row">
			<span class="priority">{{ row.priority | taskPriority }}</span>
		</mat-cell>
	</ng-container>

	<ng-container matColumnDef="action">
		<mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
		<mat-cell *matCellDef="let row">
			<button mat-icon-button [matMenuTriggerFor]="menu" title="Actions">
				<mat-icon>more_vert</mat-icon>
			</button>
			<mat-menu #menu="matMenu">
				<button mat-menu-item throttleButton (throttledClick)="edit(row)">
					<mat-icon>edit</mat-icon>
					<span>Edit Details</span>
				</button>
				<button mat-menu-item throttleButton (throttledClick)="remove(row)">
					<mat-icon>delete</mat-icon>
					<span>Delete</span>
				</button>
			</mat-menu>
		</mat-cell>
	</ng-container>

	<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
	<mat-row [cdkDragDisabled]="dragDisabled" cdkDrag *matRowDef="let row; columns: displayedColumns"></mat-row
></mat-table>
