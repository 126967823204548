// This file has been generated from DocumentCategoryDto.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.Domain.Settings.DocumentCategories.Item.DocumentCategoryDto
export class DocumentCategoryDto {

	// Id
	id: string;
	// Name
	name: string;

	constructor() {

	}
}