<mat-card>
	<section class="page-path"><a [routerLink]="['..']">System</a> / User Accounts</section>

	<h1>User Accounts</h1>
	<div class="container">
		<button mat-button color="primary" title="Create User" throttleButton (throttledClick)="createUser()">
			<mat-icon>person_add</mat-icon>
			<span>Create User</span>
		</button>
		<div class="license-container" [featureFlag]="featureFlags.licensing" *ngIf="!ignoreLicensing">
			<div class="col">
				<div>License Information</div>
				<span>{{ allocatedLicenses }} out of {{ numberOfLicenses }} allocated</span>
			</div>
		</div>
		<filter-mobile-span [filterRoot]="filter.root" [filterParams]="request" [filterComponent]="filterDialog"></filter-mobile-span>
	</div>

	<user-list-filter [request]="request"></user-list-filter>

	<mat-table [dataSource]="datasource" matSort matSortActive="contact" matSortDirection="asc">
		<ng-container matColumnDef="contact">
			<mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
			<mat-cell class="embedded-cell" *matCellDef="let row">
				<a [routerLink]="row?.id">{{ row?.contact?.name }}</a>
				<div class="embedded-email">
					<a href="mailto:{{ row.email }}">{{ row.email }}</a>
				</div>
				<mat-icon [featureFlag]="featureFlags.licensing" class="green-tick" *ngIf="!ignoreLicensing && row.licenseType == 'Licensed'"
					>verified</mat-icon
				>
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="email">
			<mat-header-cell *matHeaderCellDef>Active Logins</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<div class="providers">
					<div class="provider" *ngIf="!!row?.mastrinEmail">
						<div class="provider-icon">
							<img class="icon" [alt]="imageAlt" [src]="favIcon" />
						</div>

						<a href="mailto:{{ row.mastrinEmail }}">{{ row.mastrinEmail }}</a>
					</div>

					<div class="provider" *ngIf="!!row?.hasMicrosoftLogin">
						<div class="provider-icon">
							<img class="icon" src="/assets/img/microsoft_icon.png" />
						</div>

						<a *ngIf="!!row?.microsoftEmail" href="mailto:{{ row.microsoftEmail }}">{{ row.microsoftEmail }}</a>
						<span *ngIf="!row?.microsoftEmail" class="provider-login-help">Microsoft Login</span>
					</div>

					<div class="provider" *ngIf="!!row?.hasGoogleLogin">
						<div class="provider-icon">
							<img class="icon" src="/assets/img/google_icon.png" />
						</div>

						<a *ngIf="!!row?.googleEmail" href="mailto:{{ row.googleEmail }}">{{ row.googleEmail }}</a>
						<span *ngIf="!row?.googleEmail" class="provider-login-help">Google Login</span>
					</div>
				</div>
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="license">
			<mat-header-cell *matHeaderCellDef>License Status</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<mat-icon class="green-tick" *ngIf="row.licenseType == 'Licensed'">verified</mat-icon>
				<span *ngIf="row.licenseType == 'Free'">No License Required</span>

				<button
					*ngIf="row.licenseType == 'Unlicensed' && !row.disabled"
					color="warn"
					mat-stroked-button
					throttleButton
					(throttledClick)="assignLicense(row)"
				>
					<span>Allocate a License</span>
				</button>
			</mat-cell>
		</ng-container>
		<ng-container matColumnDef="userStatus">
			<mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<span *ngIf="!row.isRegistered && row.disabled">Disabled</span>
				<span *ngIf="!!row.isRegistered">{{ row.disabled ? 'Disabled' : 'Enabled' }}</span>
				<button
					mat-raised-button
					*ngIf="!row.isRegistered && !row.disabled"
					mat-button
					color="primary"
					throttleButton
					(throttledClick)="inviteUser(row)"
				>
					Invite User
				</button>
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="actions">
			<mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<button mat-icon-button [matMenuTriggerFor]="menu" title="Actions">
					<mat-icon>more_vert</mat-icon>
				</button>
				<mat-menu #menu="matMenu">
					<button *ngIf="!row.isRegistered && !row.disabled" mat-menu-item throttleButton (throttledClick)="inviteUser(row)">
						<mat-icon>person_add</mat-icon>
						<span>Invite User</span>
					</button>
					<button *ngIf="canBeRevoked(row)" mat-menu-item throttleButton (throttledClick)="revokeInvitation(row)">
						<mat-icon>person_add_disabled</mat-icon>
						<span>Revoke Invite</span>
					</button>

					<button
						[disabled]="getIsCurrentUser(row)"
						mat-menu-item
						throttleButton
						(throttledClick)="row.disabled ? enableUser(row) : disableUser(row)"
					>
						<mat-icon>{{ row.disabled ? 'person' : 'person_off' }}</mat-icon>
						<span>{{ row.disabled ? 'Enable User' : 'Disable User' }}</span>
					</button>
					<button
						*ngIf="!ignoreLicensing && row.licenseType == 'Unlicensed' && !row.disabled"
						mat-menu-item
						throttleButton
						(throttledClick)="assignLicense(row)"
					>
						<mat-icon>verified</mat-icon>
						<span>Allocate a License</span>
					</button>
					<button *ngIf="!ignoreLicensing && row.licenseType == 'Licensed'" mat-menu-item throttleButton (throttledClick)="removeLicense(row)">
						<mat-icon>block</mat-icon>
						<span>Unallocate License</span>
					</button>

					<button *ngIf="!row.isRegistered" mat-menu-item throttleButton (throttledClick)="deleteUser(row)">
						<mat-icon>delete</mat-icon>
						<span>Delete User</span>
					</button>
				</mat-menu>
			</mat-cell>
		</ng-container>

		<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
		<mat-row
			*matRowDef="let row; columns: displayedColumns"
			[ngClass]="{ disable: row.disabled, highlight: row.isHighlighted }"
			throttleButton
			(throttledClick)="selectRow(row, $event)"
		></mat-row>
	</mat-table>
	<mat-paginator
		[length]="datasource?.response?.totalRecords"
		[pageIndex]="datasource?.response?.pageIndex"
		[pageSize]="pageSize"
		[pageSizeOptions]="pageSizeOptions"
	></mat-paginator>
</mat-card>
