<h2 mat-dialog-title class="title">Email Documents</h2>
<mat-dialog-content class="flex-column">
	<mat-table [dataSource]="documents">
		<ng-container matColumnDef="name">
			<mat-header-cell *matHeaderCellDef>Title</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<div class="c-row-title">
					<mat-icon [svgIcon]="row.icon" [ngClass]="row.class"></mat-icon>
					<span>{{ row.title }}</span>
				</div>
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="action">
			<mat-header-cell *matHeaderCellDef>Convert to PDF</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<mat-checkbox *ngIf="!!row.canConvertToPdf" [checked]="!!row.convertToPdf" (change)="row.convertToPdf = !!$event.checked"> </mat-checkbox>
			</mat-cell>
		</ng-container>

		<mat-header-row *matHeaderRowDef="!!canConvertAnyToPdf ? ['name', 'action'] : ['name']; sticky: true"></mat-header-row>
		<mat-row *matRowDef="let row; columns: !!canConvertAnyToPdf ? ['name', 'action'] : ['name']"></mat-row>
	</mat-table>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-raised-button color="primary" throttleButton (throttledClick)="onEmailClick()">Email</button>
	<button mat-raised-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
