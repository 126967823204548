import { HttpClient, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';

import { IUploadFile, UploadStatus } from '@common/models/Common/IFileUploader';
import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';
import { DocumentToBriefDto } from '@common/models/DocumentBriefs/Common/DocumentToBriefDto';
import { RemoveDocumentSectionDto } from '@common/models/DocumentBriefs/Common/RemoveDocumentSectionDto';
import { DownloadSectionDto } from '@common/models/DocumentBriefs/Item/DownloadSectionDto';
import { BulkTagDocumentsDto } from '@common/models/Documents/Item/BulkTagDocumentsDto';
import { ConvertDocumentToPdfDto } from '@common/models/Documents/Item/ConvertDocumentToPdfDto';
import { DocumentComposeWithAttachmentsDto } from '@common/models/Documents/Item/DocumentComposeWithAttachmentsDto';
import { DocumentCopyDto } from '@common/models/Documents/Item/DocumentCopyDto';
import { DocumentCreateBlankDto } from '@common/models/Documents/Item/DocumentCreateBlankDto';
import { DocumentCreateNewVersionDto } from '@common/models/Documents/Item/DocumentCreateNewVersionDto';
import { DocumentFromTemplateDto } from '@common/models/Documents/Item/DocumentFromTemplateDto';
import { DocumentLockStatus } from '@common/models/Documents/Item/DocumentLockStatus';
import { DocumentReorderRequestDto } from '@common/models/Documents/Item/DocumentReorderRequestDto';
import { DocumentUniqueCopyTitleDto } from '@common/models/Documents/Item/DocumentUniqueCopyTitleDto';
import { DocumentUpdateDto } from '@common/models/Documents/Item/DocumentUpdateDto';
import { DocumentViewDto } from '@common/models/Documents/Item/DocumentViewDto';
import { EmailPreviewDto } from '@common/models/Documents/Item/EmailPreviewDto';
import { EntityFromTemplateDto } from '@common/models/Documents/Item/EntityFromTemplateDto';
import { NoteCreateUpdateDto } from '@common/models/Documents/Item/NoteCreateUpdateDto';
import { AvailableTagsRequest } from '@common/models/Documents/List/AvailableTagsRequest';
import { DocumentIdsCsvExportRequest } from '@common/models/Documents/List/DocumentIdsCsvExportRequest';
import { DocumentListCsvExportRequest } from '@common/models/Documents/List/DocumentListCsvExportRequest';
import { DocumentListItemDto } from '@common/models/Documents/List/DocumentListItemDto';
import { DocumentListRequest } from '@common/models/Documents/List/DocumentListRequest';
import { DocumentListRequestUnpaginated } from '@common/models/Documents/List/DocumentListRequestUnpaginated';
import { DocumentTagsRequest } from '@common/models/Documents/List/DocumentTagsRequest';
import { DocumentLookupDto } from '@common/models/Documents/Lookup/DocumentLookupDto';
import { DocumentLookupRequest } from '@common/models/Documents/Lookup/DocumentLookupRequest';
import { DownloadStatus, IDownloadFile } from '@common/models/Files/IFileDownloader';
import { ListResponse } from '@common/models/Generic/ListResponse';
import { DocumentRequest } from '@common/models/RequestParameters/DocumentRequest';
import { BaseDocumentsService } from '@common/services/documents.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { getFilenameFromHttpHeader } from '@common/utils/fileNameUtil';
import { TrimStringsOnObject } from '@common/utils/string-format';
import { toHttpParams } from '@common/utils/toHttpParams';
import * as FileSaver from 'file-saver';
import { isNil } from 'lodash-es';
import * as moment from 'moment-timezone';

import { SystemSettingsResolverService } from './settings-resolver.service';

@Injectable({
	providedIn: 'root'
})
export class DocumentsService extends BaseDocumentsService {
	constructor(httpClient: HttpClient, settingsResolver: SystemSettingsResolverService) {
		super(httpClient, settingsResolver.getAppServerUrl());
	}

	getWebDavLink(id: string, isMacOS: boolean = false): Observable<any> {
		return isMacOS ? this.getItem<void, any>(`${id}/webdavlink/true`) : this.getItem<void, any>(`${id}/webdavlink`);
	}

	createBlank(dto: DocumentCreateBlankDto): Observable<MutationResponseDto> {
		return this.post<DocumentCreateBlankDto, MutationResponseDto>(`blank`, dto);
	}

	createNote(dto: NoteCreateUpdateDto): Observable<MutationResponseDto> {
		return this.post<NoteCreateUpdateDto, MutationResponseDto>(`note`, dto);
	}

	copy(id: string, dto: DocumentCopyDto): Observable<MutationResponseDto> {
		return this.post<DocumentCopyDto, MutationResponseDto>(`${id}/copy`, dto);
	}

	composeEmailWithAttachments(dto: DocumentComposeWithAttachmentsDto): Observable<string> {
		return this.post<any, string>('compose', dto);
	}

	updateNote(id: string, dto: NoteCreateUpdateDto): Observable<MutationResponseDto> {
		return this.put<NoteCreateUpdateDto, MutationResponseDto>(`${id}/note`, dto).pipe(take(1));
	}

	update(id: string, dto: DocumentUpdateDto): Observable<MutationResponseDto> {
		return this.put<DocumentUpdateDto, MutationResponseDto>(`${id}`, dto).pipe(take(1));
	}

	newVersion(dto: DocumentCreateNewVersionDto): Observable<MutationResponseDto> {
		return this.post<DocumentCreateNewVersionDto, MutationResponseDto>('newVersion', dto).pipe(take(1));
	}

	addDocumentsToBrief(dto: DocumentToBriefDto): Observable<MutationResponseDto[]> {
		return this.post<DocumentToBriefDto, MutationResponseDto[]>(`addDocumentsToBrief`, dto);
	}

	removeDocumentsFromBrief(dto: DocumentToBriefDto): Observable<MutationResponseDto[]> {
		return this.post<DocumentToBriefDto, MutationResponseDto[]>(`removeDocumentsFromBrief`, dto);
	}

	removeDocumentFromSection(dto: RemoveDocumentSectionDto): Observable<MutationResponseDto> {
		return this.post<RemoveDocumentSectionDto, MutationResponseDto>(`removeDocumentFromSection`, dto);
	}

	bulkTagDocuments(dto: BulkTagDocumentsDto): Observable<MutationResponseDto[]> {
		return this.put<BulkTagDocumentsDto, MutationResponseDto[]>('bulk-tag', dto);
	}

	reorderBriefSection(dto: DocumentReorderRequestDto): Observable<MutationResponseDto> {
		return this.put<DocumentReorderRequestDto, MutationResponseDto>(
			`ReorderBriefSection`,
			TransformDatesOnObject(DocumentReorderRequestDto, TrimStringsOnObject(dto))
		).pipe(take(1));
	}

	downloadDocument(id: string): Observable<IDownloadFile> {
		const req = new HttpRequest('GET', this.getFullUrl(`${id}/download`), {
			reportProgress: true,
			responseType: 'blob'
		});
		return this.reportProgress(req);
	}

	convertDocumentToPdf(dto: ConvertDocumentToPdfDto): Observable<MutationResponseDto> {
		return this.post<ConvertDocumentToPdfDto, MutationResponseDto>(`${dto.id}/convert/pdf`, dto);
	}

	downloadDocumentAsPdf(dto: ConvertDocumentToPdfDto, savePdf: boolean): Observable<IDownloadFile> {
		const req = new HttpRequest('GET', this.getFullUrl(`${dto.id}/download/pdf`), {
			reportProgress: true,
			responseType: 'blob',
			params: toHttpParams({
				saveToAssociatedObject: savePdf,
				passwordProtect: dto.passwordProtect,
				userPassword: dto.userPassword,
				ownerPassword: dto.ownerPassword,
				preventModification: dto.preventModification
			})
		});
		return this.reportProgress(req);
	}

	downloadAttachment(id: string, attachmentId: string, contentId: string): Observable<any> {
		const url = !!attachmentId
			? `${id}/downloadAttachment?attachmentId=${attachmentId}`
			: `${id}/downloadAttachment?contentId=${contentId}`;

		return this.getBlob<void>(url);
	}

	downloadEntity(dto: EntityFromTemplateDto): Observable<IDownloadFile> {
		const req = new HttpRequest('GET', this.getFullUrl(`/downloadEntity`), {
			params: toHttpParams(dto),
			reportProgress: true,
			responseType: 'blob'
		});

		return this.reportProgress(req);
	}

	downloadBriefSection(dto: DownloadSectionDto): Observable<IDownloadFile> {
		const req = new HttpRequest('GET', this.getFullUrl(`/downloadBriefSection`), {
			params: toHttpParams(dto),
			reportProgress: true,
			responseType: 'blob'
		});

		return this.reportProgress(req);
	}

	downloadReceipt(dto: EntityFromTemplateDto): Observable<IDownloadFile> {
		const req = new HttpRequest('GET', this.getFullUrl(`/downloadReceipt`), {
			params: toHttpParams(dto),
			reportProgress: true,
			responseType: 'blob'
		});

		return this.reportProgress(req);
	}

	previewDocument(id: string): Observable<any> {
		return this.getItem<void, any>(`${id}/preview`);
	}

	getEmailPreviewItem(id: string): Observable<EmailPreviewDto> {
		return this.getItem<void, EmailPreviewDto>(`${id}/preview-email-item`);
	}

	downloadEmailPreview<T>(id: string, progressCallback: (request: HttpRequest<any>) => Observable<T>): Observable<T> {
		const req = new HttpRequest('GET', this.getFullUrl(`${id}/preview-email-data`), {
			reportProgress: true,
			responseType: 'blob'
		});

		return progressCallback(req);
	}

	getNoteDetails(id: string): Observable<string> {
		return this.getText<void>(`${id}/download`);
	}

	getTemporaryDocument(): Observable<DocumentViewDto> {
		return this.getItem<void, DocumentViewDto>(`getTemporaryDocument`);
	}

	getDocument(id: string): Observable<DocumentViewDto> {
		return this.getItem<void, DocumentViewDto>(`${id}`);
	}

	getDocumentList(request?: Partial<DocumentListRequest>): Observable<ListResponse<DocumentListItemDto>> {
		return this.getList<DocumentListRequest, DocumentListItemDto>('', request);
	}

	getDocumentTags(request?: Partial<DocumentTagsRequest>): Observable<string[]> {
		return this.getArray<DocumentTagsRequest, string>(`getDocumentTags`, request);
	}

	getDocumentsNotInBrief(request?: Partial<DocumentListRequestUnpaginated>): Observable<DocumentListItemDto[]> {
		return this.getArray<DocumentListRequestUnpaginated, DocumentListItemDto>(`GetDocumentsNotInBrief`, request);
	}

	getSystemAndMatterTags(request?: Partial<AvailableTagsRequest>): Observable<string[]> {
		return this.getArray<AvailableTagsRequest, string>(`getSystemAndMatterTags`, request);
	}

	getSystemTags(request?: Partial<AvailableTagsRequest>): Observable<string[]> {
		return this.getArray<AvailableTagsRequest, string>(`getSystemTags`, request);
	}

	getUniqueCopyName(id: string): Observable<DocumentUniqueCopyTitleDto> {
		return this.getItem<AvailableTagsRequest, DocumentUniqueCopyTitleDto>(`${id}/copyName`);
	}

	getLockStatus(id: string): Observable<DocumentLockStatus> {
		return this.getItem<void, DocumentLockStatus>(`${id}/lockStatus`);
	}

	lookupDocumentList(request: DocumentLookupRequest): Observable<DocumentLookupDto[]> {
		return this.getArray<DocumentLookupRequest, DocumentLookupDto>(`lookup`, request);
	}

	lookupDocument(id: string): Observable<DocumentLookupDto> {
		return this.getItem<void, DocumentLookupDto>(`${id}/lookup`);
	}

	deleteDocument(id: string): Observable<MutationResponseDto> {
		return this.delete(`${id}`);
	}

	hasShared(request?: Partial<DocumentListRequest>): Observable<boolean> {
		return this.getItem<DocumentListRequest, boolean>('has-shared', request);
	}

	hasFavorites(request?: Partial<DocumentListRequest>): Observable<boolean> {
		return this.getItem<DocumentListRequest, boolean>('has-favorites', request);
	}

	hasPreviewEmailData(id: string): Observable<boolean> {
		return this.getItem<void, boolean>(`${id}/has-preview-email-data`);
	}

	markAsFavorite(id: string): Observable<MutationResponseDto> {
		return this.put<void, MutationResponseDto>(`${id}/favorite`);
	}

	unmarkAsFavorite(id: string): Observable<MutationResponseDto> {
		return this.delete<MutationResponseDto>(`${id}/favorite`);
	}

	createDocumentFromTemplate(dto: DocumentFromTemplateDto): Observable<MutationResponseDto> {
		return this.post<DocumentFromTemplateDto, MutationResponseDto>(`fromTemplate`, dto);
	}

	// Matter document upload with progress report
	uploadMatterDocument(
		file: IUploadFile,
		url: string,
		request: DocumentRequest
	): Observable<IUploadFile & { mutation: MutationResponseDto }> {
		const formData = new FormData();
		// All controller methods for file upload must have a parameter named 'file'
		formData.append('file', file.nativeFile);
		for (const property in request) {
			if (!isNil(request[property as keyof DocumentRequest])) {
				const value = request[property as keyof DocumentRequest];
				if (moment.isMoment(value)) {
					formData.append(property, value.utc().format('YYYY-MM-DDTHH:mm:ss[Z]'));
				} else {
					formData.append(property, value as string | Blob);
				}
			}
		}
		// All controller methods for file upload must have a parameter named 'file'
		const req = new HttpRequest('POST', this.getFullUrl(url), formData, {
			reportProgress: true
		});

		const startTime: number = new Date().getTime();
		let speed = 0;
		let eta: number | null = null;

		return this.httpClient.request(req).pipe(
			switchMap(e => {
				let fileProgress = file as IUploadFile & { mutation: MutationResponseDto };
				// Via this API, you get access to the raw event stream.
				// Look for upload progress events.
				if (e.type === HttpEventType.UploadProgress) {
					const percentage = Math.round((e.loaded * 100) / e.total);
					const totalTime = (new Date().getTime() - startTime) / 1000; // in seconds
					speed = Math.round(e.loaded / totalTime);
					eta = Math.ceil((e.total - e.loaded) / speed);
					fileProgress.progress = {
						data: {
							percentage,
							speed,
							startTime,
							timeRemaining: eta,
							timeRemainingHuman: this.secondsToHuman(eta)
						},
						status: UploadStatus.Uploading
					};
				} else if (e.type === HttpEventType.Response) {
					fileProgress.progress = {
						data: {
							percentage: 100,
							speed,
							startTime,
							timeRemaining: eta,
							timeRemainingHuman: this.secondsToHuman(eta)
						},
						status: UploadStatus.Completed
					};

					fileProgress = { ...fileProgress, mutation: e.body as MutationResponseDto };
				}
				return of(fileProgress);
			}),
			catchError(e => this.handleErrorObservable(e))
		);
	}

	exportDocumentsCsvByFilter(request: DocumentListCsvExportRequest): Observable<HttpResponse<Blob>> {
		return this.getBlob<DocumentListCsvExportRequest>(`exportCsvByFilter`, request);
	}

	exportDocumentsCsvByIds(request: DocumentIdsCsvExportRequest): Observable<HttpResponse<Blob>> {
		return this.getBlob<DocumentIdsCsvExportRequest>(`exportCsvByIds`, request);
	}

	private secondsToHuman(sec: number): string {
		return new Date(sec * 1000).toISOString().substr(11, 8);
	}

	private reportProgress(request: HttpRequest<any>): Observable<IDownloadFile> {
		const file: IDownloadFile = {
			progress: { data: { currentLoaded: 0 }, status: DownloadStatus.Queued },
			size: 0
		};
		return this.httpClient.request(request).pipe(
			switchMap(httpEvent => {
				// Via this API, you get access to the raw event stream.
				// Look for download progress events.
				if (httpEvent.type === HttpEventType.DownloadProgress) {
					file.size = httpEvent.total;
					file.progress = {
						data: {
							currentLoaded: httpEvent.loaded
						},
						status: DownloadStatus.Downloading
					};
				} else if (httpEvent instanceof HttpResponse) {
					const fileName = getFilenameFromHttpHeader(httpEvent.headers.get('content-disposition'));
					FileSaver.saveAs(httpEvent.body as Blob, fileName);
					file.size = (httpEvent.body as Blob).size;
					file.progress = {
						data: {
							currentLoaded: file.size
						},
						status: DownloadStatus.Completed
					};
				}
				return of(file);
			})
		);
	}
}
