import { WorkTimerState } from 'app/shared/components/work-timer/WorkTimerState';
import { WorkTimerStateData } from 'app/shared/components/work-timer/WorkTimerStateData';
import { WorkTimerStateDataVolatile } from 'app/shared/components/work-timer/WorkTimerStateDataVolatile';
import momentDurationFormatSetup from 'moment-duration-format';
import moment from 'moment';

momentDurationFormatSetup(moment);

// A set of static utility functions for use with work timers
export class WorkTimerUtils {
	// Format number as string witth correct number of digits, by
	// padding 0's where digits are missing
	static formatNumber(value: number, digits: number = 2): string {
		let str = String(value);

		if (str.length < digits) {
			str = str.padStart(digits, '0');
		}

		return str;
	}

	// Update Timer Volatile Data
	static updateVolatileData(state: WorkTimerState): void {
		if (!state) {
			return;
		}

		const now = WorkTimerUtils.getDateNowMilliseconds();

		if (
			!!state?.data?.canAutostart &&
			!!state?.data?.isRunning &&
			!!state?.volatileData?.lastCheckedDate
		) {
			if (!state.data.elapsedMilliseconds) {
				state.data.elapsedMilliseconds = 0;
			}

			const milliseconds = Math.max(
				WorkTimerUtils.getMillisecondsFromDate(state.data.startDate, state.volatileData.lastCheckedDate),
				0
			);

			if (milliseconds > 0) {
				state.data.elapsedMilliseconds += milliseconds;
				state.data.startDate = now;
				state.raiseDataChanged();
			}

			return;
		}

		state.volatileData ??= new WorkTimerStateDataVolatile();

		state.volatileData.lastCheckedDate = now;
		state.raiseVolatileDataChanged();
	}

	// Calculate what the current time value should be in milliseconds for
	// the provide state
	static getCurrentDuration(state: WorkTimerState): number {
		if (!state?.data) {
			return 0;
		}

		return (!state.data.startDate ? 0 : WorkTimerUtils.getMillisecondsFromNow(state.data.startDate)) + (state.data.elapsedMilliseconds || 0);
	}

	static formatDuration(milliseconds: number): string {
		return moment.duration(milliseconds, 'milliseconds').format('dd:hh:mm:ss', {
			stopTrim: 'h'
		});
	}

	// Clone State values to a new instance
	static cloneState(state: WorkTimerState): WorkTimerState {
		let newState = null;

		if (state) {
			newState = new WorkTimerState({
				data: !!state.data
					? {
							isRunning: state.data.isRunning,
							startDate: state.data.startDate,
							elapsedMilliseconds: state.data.elapsedMilliseconds,
							canAutostart: state.data.canAutostart
					  }
					: null,
				volatileData: !!state.volatileData
					? {
							lastCheckedDate: state.volatileData.lastCheckedDate
					  }
					: null
			});

			newState.hasDataChanged = state.hasDataChanged;
			newState.hasVolatileDataChanged = state.hasVolatileDataChanged;
		}

		return newState;
	}

	// Get difference in millisecond from provide date number
	static getMillisecondsFromDate(date: number, now: number): number {
		return now - date;
	}

	// Get difference in millisecond from now number
	static getMillisecondsFromNow(date: number): number {
		const now = WorkTimerUtils.getDateNowMilliseconds();
		return now - date;
	}

	// Get current date in milliseconds
	static getDateNowMilliseconds() {
		return new Date().valueOf();
	}

	// Allocates and returns completely allocated state object if it has
	// not been allocated, or has only been partially allocated
	static ensureStateAllocated(state: WorkTimerState): WorkTimerState {
		if (!state || !state.data || !state.volatileData) {
			if (!state) {
				state = new WorkTimerState({
					data: new WorkTimerStateData(),
					volatileData: new WorkTimerStateDataVolatile()
				});
			} else {
				if (!state.data) {
					state.data = new WorkTimerStateData();
				}

				if (!state.data.setupDate) {
					state.data.setupDate = this.getDateNowMilliseconds();
				}

				if (!state.volatileData) {
					state.volatileData = new WorkTimerStateDataVolatile();
				}
			}
		}

		return state;
	}
}
