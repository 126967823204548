import { Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

import { Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';

import { isEmpty } from 'lodash-es';

import { DomainError } from '../models/Validation/DomainError';
import { AlertDialogComponent, IAlertDialogData } from './alert-dialog.component';
import { CallToActionComponent } from './call-to-action-dialog';
import { ConfirmationDialogComponent, NotificationIcon } from './confirmation-dialog.component';
import { CriticalErrorDialogComponent } from './critical-error-dialog.component';
import { INotificationMessage, NotificationComponent } from './notification.component';
import { IReloadErrorDialogParams, ReloadErrorDialogComponent } from './reload-error-dialog.component';

@Injectable()
export class NotificationService {
	constructor(private dialog: MatDialog, private notifSnack: MatSnackBar, private zone: NgZone) {}

	/* Notifications */
	showNotification(text: string) {
		this.showNotificationLink({ text });
	}

	showNotificationLink(data: INotificationMessage, duration: number = 3000) {
		this.showNotificationLinks([data], duration);
	}

	showNotificationLinks(data: INotificationMessage[], duration: number = 3000) {
		const config = new MatSnackBarConfig<INotificationMessage[]>();
		config.duration = duration;
		config.data = data;
		this.zone.run(() => {
			this.notifSnack.openFromComponent(NotificationComponent, config);
		});
	}

	/* Error messages */
	showStandardError(err: DomainError[], entityName: string, hideButton?: boolean): Observable<void> {
		if (err.length === 1 && err[0].code === 404) {
			return this.showError('Not found', `${entityName} doesn't exist`, hideButton);
		} else if (err.length >= 1 && err[0].code === 500) {
			return this.showErrors('Fatal error', err, hideButton);
		} else {
			return this.showErrors('Error', err, hideButton);
		}
	}

	showError(title: string, html: string, hideButton?: boolean): Observable<void> {
		const dialogRef = this.dialog.open(AlertDialogComponent, {
			data: {
				html,
				title,
				hideButton
			} as IAlertDialogData
		});
		return dialogRef.afterClosed().pipe(take(1));
	}

	showErrors(title: string, errors: DomainError[], hideButton?: boolean): Observable<void> {
		if (isEmpty(errors)) return of(null);
		let formattedErrors: string;
		if (errors.length > 1) {
			formattedErrors = '<ul>';
			for (const error of errors) {
				formattedErrors += '<li>' + error.message + '</li>';
			}
			formattedErrors += '</ul>';
		} else formattedErrors = errors[0].message;
		return this.showError(title, formattedErrors, hideButton);
	}

	showCriticalErrors(errors: DomainError[]): Observable<void> {
		let html: string;
		if (isEmpty(errors)) html = 'Critical Error';

		if (errors.length > 1) {
			html = '<ul>';
			for (const error of errors) {
				html += '<li>' + error.message + '</li>';
			}
			html += '</ul>';
		} else html = errors[0].message;

		const dialogRef = this.dialog.open(CriticalErrorDialogComponent, {
			data: {
				html
			}
		});
		return dialogRef.afterClosed().pipe(take(1));
	}

	showReloadError(headerText: string, bodyText: string): Observable<void> {
		const data: IReloadErrorDialogParams = { bodyText, headerText };

		const dialogRef = this.dialog.open(ReloadErrorDialogComponent, {
			data
		});
		return dialogRef.afterClosed().pipe(take(1));
	}

	/* Show a Yes/Cancel dialog */
	showConfirmation(
		title: string,
		html: string,
		confirmationText: string = '',
		cancellationText: string = '',
		icon: NotificationIcon = NotificationIcon.None
	): Observable<boolean> {
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
			data: {
				cancellationText,
				confirmationText,
				html,
				title,
				icon
			}
		});
		return dialogRef.afterClosed().pipe(take(1));
	}

	/* Show a Yes/Cancel dialog with a delete verification feature */
	showDeleteConfirmation(
		title: string,
		html: string,
		confirmationText: string = '',
		cancellationText: string = '',
		deleteValidationText: string = '',
		deleteValidationValue: string = '',
		deleteValidationPlaceHolder: string = ''
	): Observable<boolean> {
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
			data: {
				cancellationText,
				confirmationText,
				deleteValidationPlaceHolder,
				deleteValidationText,
				deleteValidationValue,
				html,
				title
			}
		});
		return dialogRef.afterClosed().pipe(take(1));
	}

	/* Show a Call to Action Dialog - Styled/Centered more for Marketing Purposes */
	showCallToAction(
		title: string,
		html: string,
		confirmationText: string = '',
		cancellationText: string = '',
		icon: NotificationIcon = NotificationIcon.Information
	): Observable<boolean> {
		const dialogRef = this.dialog.open(CallToActionComponent, {
			data: {
				cancellationText,
				confirmationText,
				html,
				title,
				icon
			},
			width: '600px'
		});
		return dialogRef.afterClosed().pipe(take(1));
	}
}
