import { TrustAccountListItemDto } from '@common/models/Settings/TrustSettings/TrustAccounts/List/TrustAccountListItemDto';
import { DomainError } from '@common/models/Validation/DomainError';
import { Moment } from 'moment';

export interface ITrustAccountFilterState {
	isFetching: boolean;
	list: TrustAccountListItemDto[];

	cacheExpiry: Moment;
	// errrors
	error: DomainError;
}

export const initialState: ITrustAccountFilterState = {
	isFetching: false,
	list: null,
	error: null,
	cacheExpiry: null
};
