<ng-template [ngIf]="!type">
	<mat-radio-group [(ngModel)]="type" class="flex-column">
		<mat-radio-button value="Generic">{{ 'Generic' | taskType }}</mat-radio-button>
		<mat-radio-button [featureFlag]="featureFlags.collectionTask" value="CollectionTask">{{ 'CollectionTask' | taskType }}</mat-radio-button>
		<mat-radio-button [featureFlag]="featureFlags.mergeTask" value="DocumentTemplateMerge">{{ 'DocumentTemplateMerge' | taskType }}</mat-radio-button>
	</mat-radio-group>
</ng-template>

<div *ngIf="!!type" [formGroup]="form" class="flex-column">
	<div class="flex-row">
		<mat-form-field>
			<mat-placeholder
				>Description
				<sup class="color-warn">*</sup>
			</mat-placeholder>
			<textarea #descriptionInput formControlName="description" matInput cdkTextareaAutosize [cdkAutosizeMinRows]="2"></textarea>
			<mat-error>
				<error-messages [for]="form.get('description')"></error-messages>
			</mat-error>
		</mat-form-field>
	</div>
	<div class="flex-row">
		<contact-lookup [Required]="true" [StaffOnly]="true" [FormControl]="form.get('assignedToId')" Placeholder="Assigned To"></contact-lookup>
		<mat-form-field class="small-form-field">
			<mat-placeholder
				>Due Date
				<sup class="color-warn">*</sup>
			</mat-placeholder>
			<input formControlName="dueDate" matInput [matDatepicker]="dueDatePicker" />
			<mat-datepicker-toggle matSuffix [for]="dueDatePicker"></mat-datepicker-toggle>
			<mat-datepicker #dueDatePicker></mat-datepicker>
			<mat-error>
				<error-messages [for]="form.get('dueDate')"></error-messages>
			</mat-error>
		</mat-form-field>
		<mat-form-field class="small-form-field">
			<mat-select formControlName="priority" placeholder="Priority">
				<mat-option *ngFor="let option of priorities" [value]="option">{{ option | taskPriority }}</mat-option>
			</mat-select>
			<mat-error>
				<error-messages [for]="form.get('priority')"></error-messages>
			</mat-error>
		</mat-form-field>
	</div>
	<div class="flex-row">
		<div class="related-to">
			<mat-form-field class="related-to-type">
				<mat-select [(value)]="associatedEntityType" placeholder="Related To" (selectionChange)="associatedEntityTypeChange()">
					<mat-option *ngFor="let type of associatedEntityTypes" [value]="type">{{ type }}</mat-option>
				</mat-select>
			</mat-form-field>
			<matter-lookup
				class="related-to-entity"
				*ngIf="associatedEntityType == 'Matter'"
				[FormControl]="form.get('associatedMatterId')"
				Hint="Enter a matter number or title"
				[Status]="'Open'"
				[Required]="true"
			></matter-lookup>
			<contact-lookup
				class="related-to-entity"
				*ngIf="associatedEntityType == 'Contact'"
				[FormControl]="form.get('associatedContactId')"
				(Selected)="onContactSelected($event)"
				Hint="Enter a contact name"
				[Required]="true"
			></contact-lookup>
		</div>
		<mat-form-field class="small-form-field">
			<mat-select formControlName="status" placeholder="Status">
				<mat-option *ngFor="let option of statuses" [value]="option">{{ option | taskStatus }}</mat-option>
			</mat-select>
			<mat-error>
				<error-messages [for]="form.get('status')"></error-messages>
			</mat-error>
		</mat-form-field>
	</div>

	<ng-container *ngIf="!!isMergeTask && (!!form.get('associatedMatterId')?.value || !!form.get('associatedContactId')?.value)">
		<div class="flex-row">
			<div class="list-header">
				<h3 class="title-text">Document Templates</h3>
				<button class="add-button" mat-button color="primary" throttleButton (throttledClick)="addDocumentTemplate()">
					<mat-icon>add_circle</mat-icon>
					<span>&nbsp;New document template</span>
				</button>
			</div>
		</div>

		<div class="flex-row">
			<div class="flex-column">
				<div
					class="list-item--mobile"
					*ngFor="let documentTemplateIdControl of documentTemplateIdControls; let i = index"
					formArrayName="documentTemplateIds"
				>
					<div class="flex-row">
						<document-template-lookup
							#documentTemplateControl
							Placeholder="Document Template"
							[Required]="true"
							[FormControl]="documentTemplateIdControl"
							[entityType]="documentTemplateEntityType"
							[practiceAreaIds]="practiceAreaIds"
						>
						</document-template-lookup>

						<button
							mat-icon-button
							title="Remove"
							class="delete-button delete-button--full"
							throttleButton
							(throttledClick)="removeDocumentTemplate(i)"
						>
							<mat-icon>delete</mat-icon>
						</button>
					</div>

					<div class="flex-row flex-row--mobile">
						<button
							mat-raised-button
							title="Remove"
							class="delete-button delete-button--mobile"
							throttleButton
							(throttledClick)="removeDocumentTemplate(i)"
						>
							<mat-icon>delete</mat-icon>
							<span>Remove</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</ng-container>

	<ng-container *ngIf="!editId && !!isCollectionTask && (!!form.get('associatedMatterId')?.value || !!form.get('associatedContactId')?.value)">
		<div class="flex-row">
			<custom-field-selection
				[entityType]="customFieldEntityType"
				[practiceAreaIds]="practiceAreaIds"
				(SelectedGroupsChange)="onSelectedGroupsChange($event)"
				[isStackedLayout]="true"
			></custom-field-selection>
		</div>

		<div class="flex-row">
			<mat-checkbox formControlName="allCollectionFieldsMandatory">All fields are required before task can be completed</mat-checkbox>
		</div>
	</ng-container>
</div>
