// This file has been generated from BillBatchCreateDto.cs
// Do not modify this file directly!!
// tslint:disable

import { Moment } from 'moment-timezone';
import { DateFormat, SetDateFormat } from '@common/utils/date-format';



// LawMaster.Amalthea.Domain.Bills.Item.BillBatchCreateDto
export class BillBatchCreateDto {

	// MatterIds
	matterIds: string[];
	// DueDate
	@SetDateFormat(DateFormat.LocalDate)
	dueDate: Moment;
	// IssueDate
	@SetDateFormat(DateFormat.LocalDate)
	issueDate: Moment;
	// TemplateId
	templateId: string;

	constructor() {

	}
}