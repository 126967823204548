import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, ValidationErrors } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Subscription } from 'rxjs';

import { CustomValidators } from '@common/validation/custom.validators';

@Component({
	selector: 'download-convert-dialog',
	styleUrls: ['./download-convert-dialog.component.scss'],
	templateUrl: './download-convert-dialog.component.html'
})
export class DownloadConvertDialogComponent implements OnInit, OnDestroy {
	form: FormGroup;
	private subscriptions: Subscription = new Subscription();

	get isDownload(): boolean {
		return !!this.form?.controls?.downloadPdf?.value ?? false;
	}

	private isInvalid = false;

	constructor(private fb: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any) {}

	get isPasswordProtect(): boolean {
		return !!this.form?.get('passwordProtect').value;
	}

	get isPreventModification(): boolean {
		return !!this.form?.get('preventModification').value;
	}

	ngOnInit(): void {
		this.form = this.fb.group({
			addDocumentToMatter: true,
			downloadPdf: false,
			includeSecurityOptions: false,
			passwordProtect: false,
			userPassword: [null, CustomValidators.requiredWhen(() => this.isPasswordProtect, 'Enter Password')],
			ownerPassword: [null, CustomValidators.requiredWhen(() => this.isPreventModification, 'Enter Password')],
			preventModification: false
		});
		this.subscriptions.add(
			this.form.get('passwordProtect').valueChanges.subscribe(() => {
				this.form.get('userPassword').updateValueAndValidity();
			})
		);
		this.subscriptions.add(
			this.form.get('preventModification').valueChanges.subscribe(() => {
				this.form.get('ownerPassword').updateValueAndValidity();
			})
		);

		this.form.setValidators((group: FormGroup): ValidationErrors | null => {
			const addDocumentToMatter = group.controls.addDocumentToMatter;
			const downloadPdf = group.controls.downloadPdf;

			if (!addDocumentToMatter.value && !downloadPdf.value) {
				addDocumentToMatter.setErrors({ customRequired: 'Must have at least one option enabled' });
				downloadPdf.setErrors({ customRequired: 'Must have at least one option enabled' });
				this.isInvalid = true;
			} else if (!!this.isInvalid) {
				this.isInvalid = false;
				addDocumentToMatter.updateValueAndValidity();
				downloadPdf.updateValueAndValidity();
			}

			return null;
		});
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	saveToText(): string {
		return this.data?.matterId ? 'Matter' : 'Contact Profile';
	}
}

export interface IDownloadConvertDialog {
	addDocumentToMatter: boolean;
	downloadPdf: boolean;
	passwordProtect: boolean;
	userPassword: string;
	ownerPassword: string;
	preventModification: boolean;
}
