<mat-card>
	<section class="page-path"><a [routerLink]="['..']">System</a> / Import</section>
	<h1>Data Import</h1>
	<div class="description">Use this feature to import your data via an Excel file upload.</div>
	<mat-nav-list>
		<mat-card>
			<a mat-list-item href="https://support.mattero.com.au/hc/en-us/sections/4409330308633-Importing-and-Migrating-Data" target="_blank">
				<mat-icon color="accent" matListIcon>help</mat-icon>
				<h4 matLine class="title">How To</h4>
				<p matLine>Learn how to import your existing contacts, matters and invoices to {{ siteName }}</p>
			</a>
		</mat-card>
		<mat-card>
			<a mat-list-item routerLink="./contact">
				<mat-icon color="accent" matListIcon>person</mat-icon>
				<h4 matLine class="title">Contacts</h4>
				<p matLine>Import your contacts</p>
			</a>
			<a mat-list-item routerLink="./matter">
				<mat-icon color="accent" matListIcon>gavel</mat-icon>
				<h4 matLine class="title">Matters</h4>
				<p matLine>Import your matters</p>
			</a>
			<a mat-list-item routerLink="./invoice">
				<mat-icon color="accent" matListIcon>attach_money</mat-icon>
				<h4 matLine class="title">Invoices</h4>
				<p matLine>Import your invoices</p>
			</a>
		</mat-card>
	</mat-nav-list>
</mat-card>
