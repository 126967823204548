<div [formGroup]="formGroup" class="custom-fields-collection">
	<div *ngFor="let field of visibleFields" class="custom-field">
		<ng-container [ngSwitch]="field.fieldType">
			<mat-form-field *ngSwitchCase="'Text'">
				<input matInput [formControlName]="field.id" />
				<mat-placeholder
					>{{ field.name }}
					<sup *ngIf="field.mandatory" class="color-warn">*</sup>
				</mat-placeholder>
				<mat-error>
					<error-messages [for]="formGroup.get(field.id)"></error-messages>
				</mat-error>
			</mat-form-field>
			<mat-form-field *ngSwitchCase="'Paragraph'">
				<textarea matInput [formControlName]="field.id"></textarea>
				<mat-placeholder
					>{{ field.name }}
					<sup *ngIf="field.mandatory" class="color-warn">*</sup>
				</mat-placeholder>
				<mat-error>
					<error-messages [for]="formGroup.get(field.id)"></error-messages>
				</mat-error>
			</mat-form-field>
			<mat-form-field *ngSwitchCase="'Date'">
				<!-- TODO Replace with popup-datepicker -->
				<input matInput [formControlName]="field.id" [matDatepicker]="datePicker" />
				<mat-placeholder
					>{{ field.name }}
					<sup *ngIf="field.mandatory" class="color-warn">*</sup>
				</mat-placeholder>
				<mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
				<mat-datepicker #datePicker></mat-datepicker>
				<mat-error>
					<error-messages [for]="formGroup.get(field.id)"></error-messages>
				</mat-error>
			</mat-form-field>
			<div
				*ngSwitchCase="'Checkbox'"
				class="mat-form-field"
				[ngClass]="{ 'mat-form-field-invalid': formGroup.get(field.id).touched && formGroup.get(field.id).invalid }"
			>
				<div class="mat-form-field-wrapper">
					<div class="mat-form-field-flex">
						<mat-checkbox
							class="mat-form-field-infix"
							[formControl]="formGroup.get(field.id)"
							[indeterminate]="formGroup.get(field.id).value == null"
							>{{ field.name }}</mat-checkbox
						>
					</div>
					<div class="mat-form-field-underline"></div>
					<mat-error class="mat-form-field-subscript-wrapper" *ngIf="formGroup.get(field.id).touched">
						<error-messages [for]="formGroup.get(field.id)"></error-messages>
					</mat-error>
				</div>
			</div>
			<ng-container *ngSwitchCase="'Contact'">
				<contact-lookup
					*ngIf="!field.contactSelectionList?.length"
					[FormControl]="formGroup.get(field.id)"
					(Selected)="onContactSelected($event, formGroup.get(field.id))"
					[Required]="field.mandatory"
					[Placeholder]="field.name"
				></contact-lookup>
				<contact-lookup-local
					*ngIf="!!field.contactSelectionList?.length"
					[FormControl]="formGroup.get(field.id)"
					(Selected)="onContactSelected($event, formGroup.get(field.id))"
					[Required]="field.mandatory"
					[Placeholder]="field.name"
					[allowedContactIds]="field.contactSelectionList"
				></contact-lookup-local>
			</ng-container>
			<mat-form-field *ngSwitchCase="'Email'">
				<input matInput type="email" [formControlName]="field.id" />
				<mat-placeholder
					>{{ field.name }}
					<sup *ngIf="field.mandatory" class="color-warn">*</sup>
				</mat-placeholder>
				<mat-error>
					<error-messages [for]="formGroup.get(field.id)"></error-messages>
				</mat-error>
			</mat-form-field>
			<mat-form-field *ngSwitchCase="'List'">
				<mat-select [formControlName]="field.id">
					<mat-option *ngFor="let option of field.selectionList" [value]="option.id">{{ option.name }}</mat-option>
				</mat-select>
				<mat-placeholder
					>{{ field.name }}
					<sup *ngIf="field.mandatory" class="color-warn">*</sup>
				</mat-placeholder>
				<mat-error>
					<error-messages [for]="formGroup.get(field.id)"></error-messages>
				</mat-error>
			</mat-form-field>
			<mat-form-field *ngSwitchCase="'Numeric'">
				<input matInput type="number" [formControlName]="field.id" />
				<mat-placeholder
					>{{ field.name }}
					<sup *ngIf="field.mandatory" class="color-warn">*</sup>
				</mat-placeholder>
				<mat-error>
					<error-messages [for]="formGroup.get(field.id)"></error-messages>
				</mat-error>
			</mat-form-field>
			<mat-form-field *ngSwitchCase="'Currency'">
				<input matInput type="number" [formControlName]="field.id" />
				<span matPrefix *ngIf="formGroup.get(field.id).value">$</span>
				<mat-placeholder
					>{{ field.name }}
					<sup *ngIf="field.mandatory" class="color-warn">*</sup>
				</mat-placeholder>
				<mat-error>
					<error-messages [for]="formGroup.get(field.id)"></error-messages>
				</mat-error>
			</mat-form-field>
		</ng-container>
	</div>
</div>
