<multi-file-attachment
	[maxNumberOfFiles]="5"
	[isEditable]="true"
	[showProgress]="showProgress"
	[existingDocuments]="existingDocuments"
	(errorMessageUpdated)="onErrorMessageUpdated($event)"
	(documentIdsToRemoveUpdated)="onDocumentIdsToRemoveUpdated($event)"
	(uploadFilesInfoUpdated)="onUploadFilesInfoUpdated($event)"
>
	<div [formGroup]="form" class="flex-column">
		<div class="readonly-message color-accent" *ngIf="!isEditable">
			<mat-icon color="accent">info</mat-icon>
			<span> {{ getReadOnlyReason() }}</span>
		</div>
		<div class="flex-row" *ngIf="!hideRelatedTo">
			<matter-lookup
				#matterLookup
				class="related-to-entity"
				Placeholder="Related To Matter"
				[Required]="true"
				[Status]="'Open'"
				[FormControl]="form.get('associatedMatterId')"
			></matter-lookup>
		</div>
		<div class="flex-row">
			<mat-form-field>
				<mat-placeholder>
					Cost Code
					<sup class="color-warn" *ngIf="!disableValidation">*</sup>
				</mat-placeholder>
				<mat-select #costdropdown formControlName="costCodeId" (selectionChange)="costCodeChanged($event)">
					<mat-option *ngFor="let option of costCodes" [value]="option.id">{{ option.name }}</mat-option>
				</mat-select>
				<mat-hint *ngIf="isGstFree()">GST Free</mat-hint>
				<mat-error> <error-messages [for]="form.get('costCodeId')"></error-messages> </mat-error>
			</mat-form-field>
			<div class="amount-container">
				<div class="ripple-element" [class.highlight-amount]="highlightField"></div>
				<editable-numeric-value
					#amount
					placeHolder="Amount{{ isGstFree() ? '' : ' incl. GST' }}"
					[hintText]="amountHint"
					[control]="form.controls.amountInclTax"
					[isCurrency]="true"
					[editMode]="true"
				></editable-numeric-value>
			</div>
			<div class="amount-container">
				<div class="ripple-element" [class.highlight-amount]="highlightField"></div>
				<editable-numeric-value
					#gst
					placeHolder="GST Amount"
					[control]="form.get('gstAmount')"
					[hintText]="gstAmountHint"
					[isCurrency]="true"
					[editMode]="true"
				>
					<button mat-button *ngIf="!form.disabled && !isGstFree()" matSuffix mat-icon-button throttleButton (throttledClick)="autoCalculateGst()">
						<mat-icon class="refresh-icon" matTooltip="Recalculate Amount">refresh</mat-icon>
					</button>
				</editable-numeric-value>
			</div>
		</div>
		<div class="flex-row">
			<mat-form-field>
				<mat-placeholder> Description <sup class="color-warn" *ngIf="!disableValidation">*</sup> </mat-placeholder>
				<textarea formControlName="description" matInput cdkTextareaAutosize [cdkAutosizeMinRows]="2"></textarea>
				<mat-error> <error-messages [for]="form.get('description')"></error-messages> </mat-error>
			</mat-form-field>
		</div>
		<div class="flex-row">
			<mat-form-field>
				<mat-placeholder>Date <sup class="color-warn" *ngIf="!disableValidation">*</sup> </mat-placeholder>
				<input formControlName="date" matInput [matDatepicker]="datePicker" />
				<mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
				<mat-datepicker #datePicker></mat-datepicker>
				<mat-error> <error-messages [for]="form.get('date')"></error-messages> </mat-error>
			</mat-form-field>
			<contact-lookup Placeholder="User" [Required]="!disableValidation" [StaffOnly]="true" [FormControl]="form.get('staffContactId')"></contact-lookup>
		</div>
		<div *ngIf="showProgress">
			<span>{{ editId ? 'Updating' : 'Creating' }} expense, please wait...</span><br />
			<mat-progress-bar [value]="progress.percentage"> </mat-progress-bar>
		</div>
		<div class="upload-error" *ngIf="error.message">
			<mat-icon>cancel</mat-icon>&nbsp;<span class="err-text-container" [innerHTML]="error.message"></span>
		</div>
	</div>
</multi-file-attachment>
