// This file has been generated from SecurityRoleMembershipUpdateDto.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.Domain.Settings.SecurityRoles.Item.SecurityRoleMembershipUpdateDto
export class SecurityRoleMembershipUpdateDto {

	// ContactIds
	contactIds: string[];

	constructor() {

	}
}