<mat-card>
	<section class="breadcrumb"><a [routerLink]="['..']">System</a> / Trust & Bank Accounts</section>
	<mat-tab-group [selectedIndex]="selectedTabIndex" (selectedTabChange)="selectedTabChanged($event)">
		<mat-tab>
			<ng-template mat-tab-label>
				<span>Trust Accounts</span>
			</ng-template>
			<div class="description">
				A Trust Account is required for the creation of your trust records. These details will filter through to your trust reports.<br />
			</div>
			<hr />
			<trust-accounts-list></trust-accounts-list>
		</mat-tab>
		<mat-tab>
			<ng-template mat-tab-label>
				<span>General Bank Accounts</span>
			</ng-template>
			<div class="description">
				A General Account is required for Trust Invoice Payments and Manual Payments. These details will filter through to your trust reports and will
				be used when Syncing with Xero to match the general accounts.<br />
			</div>
			<hr />
			<bank-accounts-list></bank-accounts-list>
		</mat-tab>
	</mat-tab-group>
</mat-card>
