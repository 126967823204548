import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Inject, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Subscription } from 'rxjs';

import { AppcuesService } from '@common/appcues/appcues.service';
import { ApiKeyCreateUpdateDto } from '@common/models/Settings/ApiKeys/Item/ApiKeyCreateUpdateDto';
import { ApiKeysService } from '@common/services/settings/apikeys.service';

import { CustomValidators } from '@common/validation/custom.validators';
import { AppBrandingService } from 'app/services/app-branding.service';

@Component({
	styleUrls: ['api-key-dialog.component.scss'],
	templateUrl: 'api-key-dialog.component.html'
})
export class ApiKeyDialogComponent implements OnInit, OnDestroy {
	private onGenerateButtonClickFirstPass: boolean = true;

	key: string;
	id: string;
	isEdit: boolean;
	description: string;

	@Input()
	form: FormGroupTyped<ApiKeyCreateUpdateDto>;

	protected subscriptions = new Subscription();

	get siteName() {
		return AppBrandingService.getSiteName();
	}

	constructor(
		@Optional() @Inject(MAT_DIALOG_DATA) data: any,
		private formBuilder: FormBuilder,
		private apiKeysService: ApiKeysService,
		private clipboard: Clipboard,
		private dialogRef: MatDialogRef<ApiKeyDialogComponent>,
		private appcuesService: AppcuesService
	) {
		if (!!data) {
			if (!!data.isEdit) {
				this.isEdit = data.isEdit;
			}

			if (!!data.id) {
				this.id = data.id;
			}

			if (!!data.description) {
				this.description = data.description;
			}
		}
	}

	ngOnInit(): void {
		if (!this.form) {
			this.form = this.formBuilder.group({
				description: [null, CustomValidators.required('Description')]
			}) as FormGroupTyped<ApiKeyCreateUpdateDto>;

			if (!!this.description) {
				this.form.controls.description.setValue(this.description);
			}
		}
	}

	onGenerateButtonClick(): void {
		this.appcuesService.trackEvent('APIKeyCreated');
		if (!!this.onGenerateButtonClickFirstPass) {
			this.onGenerateButtonClickFirstPass = false;
			if (!!this.isEdit) {
				this.subscriptions.add(
					this.apiKeysService
						.updateApiKey(this.id, this.form.getRawValue())
						.subscribe(() => this.dialogRef.close())
				);
			} else {
				this.subscriptions.add(
					this.apiKeysService.createApiKey(this.form.getRawValue()).subscribe(key => (this.key = key))
				);
			}
		}
	}

	onCopyButtonClick(): void {
		this.clipboard.copy(this.key);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}
}
