import { Injectable } from '@angular/core';

import { from, Observable } from 'rxjs';

import moment, { Moment } from 'moment';

import { AppConfig } from 'app/app.config';

@Injectable({
	providedIn: 'root'
})
export class SystemInformationService {
	private controller = 'api/v1/SystemInformation';

	getServerTime(): Observable<Moment> {
		const url = `${AppConfig.AppServerUrl}/${this.controller}/time`;
		return from(
			fetch(url)
				.then(response => response.json())
				.then(json => moment(json))
		);
	}
}
