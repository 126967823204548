import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Subscription } from 'rxjs';

import { UserCurrentService } from '@common/services/usercurrent.service';

@Component({
	selector: 'terms-and-conditions-dialog',
	styleUrls: ['./terms-and-conditions-dialog.component.scss'],
	templateUrl: 'terms-and-conditions-dialog.component.html'
})
export class TermsAndConditionsDialogComponent implements OnDestroy {
	private _subscriptions = new Subscription();

	get termsMessage(): string {
		return this.data.message;
	}

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: ITermsAndConditionsDialogData,
		private _userCurrentService: UserCurrentService,
		private _dialogRef: MatDialogRef<TermsAndConditionsDialogComponent>
	) {}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}

	onCloseClick(): void {
		this._subscriptions.add(
			this._userCurrentService.markTermsAndConditionsAcknowledged().subscribe(() => this._dialogRef.close())
		);
	}
}

export interface ITermsAndConditionsDialogData {
	message: string;
}
