// This file has been generated from DocumentAccessTokensController.cs
// Do not modify this file directly!!
// tslint:disable

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { TrimStringsOnObject } from '@common/utils/string-format';

import { EntityReference } from '../../models/Common/EntityReference';
import { ListResponse } from '../../models/Generic/ListResponse';
import { AccessTokenValidationDto } from '../../models/Settings/AccessTokens/Item/AccessTokenValidationDto';
import { DocumentAccessTokenCreateUpdateDto } from '../../models/Settings/AccessTokens/Item/DocumentAccessTokenCreateUpdateDto';
import { DocumentAccessTokenViewDto } from '../../models/Settings/AccessTokens/Item/DocumentAccessTokenViewDto';
import { ValidateAccessTokenDto } from '../../models/Settings/AccessTokens/Item/ValidateAccessTokenDto';
import { DocumentAccessTokenListItemDto } from '../../models/Settings/AccessTokens/List/DocumentAccessTokenListItemDto';
import { DocumentAccessTokenListRequest } from '../../models/Settings/AccessTokens/List/DocumentAccessTokenListRequest';

@Injectable({
	providedIn: 'root'
})
export class DocumentAccessTokensService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'api/v1/settings/access-tokens/documents', 'DocumentAccessToken');
	}

	getAccessToken(tokenOrId: string): Observable<DocumentAccessTokenViewDto> {
		return this.getItem<void, DocumentAccessTokenViewDto>(`${tokenOrId}`);
	}

	getAccessTokenList(request?: Partial<DocumentAccessTokenListRequest>): Observable<ListResponse<DocumentAccessTokenListItemDto>> {
		return this.getList<DocumentAccessTokenListRequest, DocumentAccessTokenListItemDto>('', request);
	}

	createAccessToken(dto: DocumentAccessTokenCreateUpdateDto): Observable<EntityReference> {
		return this.post<DocumentAccessTokenCreateUpdateDto, EntityReference>('', TransformDatesOnObject(DocumentAccessTokenCreateUpdateDto, TrimStringsOnObject(dto)));
	}

	updateAccessToken(tokenOrId: string, dto: DocumentAccessTokenCreateUpdateDto): Observable<any> {
		return this.put(`${tokenOrId}`, TransformDatesOnObject(DocumentAccessTokenCreateUpdateDto, TrimStringsOnObject(dto)));
	}

	revokeAccessToken(tokenOrId: string): Observable<any> {
		return this.delete(`${tokenOrId}`);
	}

	validateToken(documentId: string, token: ValidateAccessTokenDto): Observable<AccessTokenValidationDto> {
		return this.getItem<ValidateAccessTokenDto, AccessTokenValidationDto>(`validate/${documentId}`, token);
	}

}
