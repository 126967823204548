<mat-card class="mat-card">
	<section class="page-path"><a [routerLink]="['..']">System</a> / Time Zone</section>
	<h2>Time Zone</h2>
	<div class="description">Time is {{ now }}</div>

	<div class="flex-column">
		<div class="control-row">
			<div class="input-control">
				<time-zone-lookup [FormControl]="form.get('timeZone')"></time-zone-lookup>
			</div>
		</div>
	</div>

	<div class="actions">
		<button mat-raised-button [disabled]="!!form?.invalid || !!form?.pristine" color="primary" throttleButton (throttledClick)="onSaveClicked()">
			Save
		</button>
	</div>
</mat-card>
