// This file has been generated from AccessTokenRequestDto.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.Domain.Users.Item.AccessTokenRequestDto
export class AccessTokenRequestDto {

	// AuthorizationCode
	authorizationCode: string;

	constructor() {

	}
}