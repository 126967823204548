// This file has been generated from TenantInitializationDto.cs
// Do not modify this file directly!!
// tslint:disable

import { PracticeType } from './PracticeType';

// LawMaster.Amalthea.Domain.Settings.Setting.Item.TenantInitializationDto
export class TenantInitializationDto {

	// PracticeType
	practiceType: keyof typeof PracticeType;
	// CompanyName
	companyName: string;

	constructor() {

	}
}