import { Component, ElementRef, HostListener, Input, OnInit, Output } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { EntityReference } from '@common/models/Common/EntityReference';
import { isEmpty } from 'lodash-es';

import { FilterBaseComponent } from './filter-base.component';

@Component({
	selector: 'filter-button',
	styleUrls: ['./filter-base.component.scss', './filter-button.component.scss'],
	template: `
		<button
			mat-stroked-button
			[class.filter-selected]="!keepBackground && IsSelected"
			cdk-overlay-origin
			#filterButton="cdkOverlayOrigin"
			throttleButton
			(throttledClick)="filterButtonClick()"
			[ngStyle]="{ width: !!fixedWidth ? fixedWidth : 'auto' }"
			[disabled]="isDisabled"
		>
			<mat-icon *ngIf="!!matIconName" class="icon" [ngStyle]="{ color: isDisabled ? '#d3d3d3' : matIconColor }">{{
				matIconName
			}}</mat-icon>
			<span [ngStyle]="{ color: isDisabled ? '#d3d3d3' : textColor }">{{
				!!this.trimDisplayText ? trimText(buttonText) : buttonText
			}}</span>
			<mat-icon *ngIf="!toggle" [ngStyle]="{ color: isDisabled ? '#d3d3d3' : 'black' }">arrow_drop_down</mat-icon>
		</button>

		<ng-template
			cdk-connected-overlay
			[cdkConnectedOverlayOrigin]="filterButton"
			[cdkConnectedOverlayFlexibleDimensions]="true"
			[cdkConnectedOverlayOpen]="isMenuOpen"
			[cdkConnectedOverlayViewportMargin]="16"
			cdkConnectedOverlayHasBackdrop
			cdkConnectedOverlayBackdropClass="hidden-backdrop"
			(backdropClick)="isMenuOpen = false"
		>
			<div id="overlayPane" class="overlay-panel">
				<ng-content></ng-content>
			</div>
		</ng-template>
	`
})
export class FilterButtonComponent extends FilterBaseComponent implements OnInit {
	constructor(private elementRef: ElementRef) {
		super();
		this.elementRef = elementRef;
	}

	@Input()
	trimDisplayText: boolean = true;
	@Input()
	selectedFilterTitle: string;
	@Input()
	showTitleAsPrefix = false;
	@Input()
	toggle = false;
	@Input()
	isDisabled = false;
	@Input()
	keepBackground = false;
	@Input()
	fixedWidth: string;
	@Input()
	matIconName: string;
	@Input()
	matIconColor: string;
	@Input()
	textColor: string;
	@Input()
	set IsSelected(val: boolean) {
		if (val !== this.isSelected && !this.toggle) this.isMenuOpen = false;
		this.isSelected = val;
	}
	get IsSelected(): boolean {
		return this.isSelected;
	}

	get buttonText(): string {
		const prefix = this.showTitleAsPrefix ? `${this.label}: ` : '';
		return this.IsSelected && !this.toggle ? prefix + this.selectedFilterTitle : this.label;
	}

	@Output()
	MenuOpened: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	set isMenuOpen(value: boolean) {
		this.MenuOpened.next(value);
	}
	get isMenuOpen(): boolean {
		return this.MenuOpened.value;
	}

	private isSelected: boolean;

	@HostListener('document:click', ['$event'])
	clickout(event: { target: HTMLInputElement }): void {
		const overlays = document.querySelectorAll('.cdk-overlay-pane, .cdk-overlay-container');

		let hitSuccess = false;

		overlays.forEach(overlay => {
			if (!hitSuccess) {
				hitSuccess = overlay && overlay.contains(event.target);
			}
		});

		if (!this.elementRef.nativeElement.contains(event.target) && !hitSuccess) {
			this.isMenuOpen = false;
		}
	}

	filterButtonClick(): void {
		if (this.toggle) {
			this.IsSelected = !this.IsSelected;
		} else {
			this.isMenuOpen = !this.isMenuOpen;
		}
	}

	valueSelected(val: string) {
		this.selectedFilterTitle = val;
		this.isSelected = !isEmpty(val);
		if (this.isMenuOpen) {
			this.isMenuOpen = false;
			// Apply filter only when we manually clicked
			if (this.control) {
				this.control.setValue(val);
				this.root?.applyFilter();
			}
		}
	}

	valuesSelected(val: string[]) {
		this.selectedFilterTitle = val.join(', ');
		this.isSelected = !isEmpty(val);
		if (this.isMenuOpen) {
			this.isMenuOpen = false;
			// Apply filter only when we manually clicked
			if (this.control) {
				this.control.setValue(val);
				this.root?.applyFilter();
			}
		}
	}

	entitySelected(entity: EntityReference) {
		if (entity) {
			this.selectedFilterTitle = entity.name;
		}
		this.isSelected = !!entity && !!entity.id;
		if (this.isMenuOpen) {
			this.isMenuOpen = false;
			// Apply filter only when we manually clicked
			if (this.control) {
				this.control.setValue(!!entity ? entity.id : null);
				this.root?.applyFilter();
			}
		}
	}

	entitiesSelected(entities: EntityReference[]) {
		if (entities) {
			this.selectedFilterTitle = entities.map(item => item.name).join(', ');
		}
		this.isSelected = !!entities && !isEmpty(entities);
		if (this.isMenuOpen && this.control) {
			this.control.setValue(!!entities ? entities.map(item => item.id) : null);
			this.root?.applyFilter();
		}
	}

	trimText(buttonText: string) {
		return (buttonText?.length ?? 0) > 15 ? buttonText.substring(0, 15) + '...' : buttonText;
	}
}
