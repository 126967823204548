import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn } from '@angular/forms';

import { BehaviorSubject, Subscription } from 'rxjs';

import { ContactReference } from '@common/models/Contacts/Common/ContactReference';
import { ListResponse } from '@common/models/Generic/ListResponse';
import { CustomFieldType } from '@common/models/Settings/CustomFields/Common/CustomFieldType';
import { ContactCustomFieldListItemDto } from '@common/models/Settings/CustomFields/List/ContactCustomFieldListItemDto';
import { MatterCustomFieldListItemDto } from '@common/models/Settings/CustomFields/List/MatterCustomFieldListItemDto';
import { ContactCustomFieldsService } from '@common/services/customfields-contact.service';
import { MatterCustomFieldsService } from '@common/services/customfields-matter.service';
import { CustomValidators } from '@common/validation/custom.validators';
import { isEmpty, sortBy } from 'lodash-es';

@Component({
	selector: 'input-custom-fields',
	styleUrls: ['./input-custom-fields.component.scss'],
	templateUrl: './input-custom-fields.component.html'
})
export class InputCustomFieldsComponent implements OnInit, OnDestroy {
	@Input()
	formGroup: FormGroup;
	@Input()
	set practiceAreaId(value: string) {
		this._practiceAreaId.next(value);
	}

	@Input()
	set entityType(value: 'Matter' | 'Contact') {
		this._entityType.next(value);
	}

	@Input()
	set contactType(value: 'Person' | 'Company') {
		this._contactType.next(value);
	}

	@Input()
	useDefaults: boolean;

	private _lastEntityType: 'Matter' | 'Contact';
	private _entityType: BehaviorSubject<'Matter' | 'Contact'> = new BehaviorSubject<'Matter' | 'Contact'>(null);
	private _practiceAreaId: BehaviorSubject<string> = new BehaviorSubject<string>(null);
	private _contactType: BehaviorSubject<'Person' | 'Company'> = new BehaviorSubject<'Person' | 'Company'>(null);

	private _subscriptions: Subscription = new Subscription();

	get visibleFields(): (MatterCustomFieldListItemDto | ContactCustomFieldListItemDto)[] {
		return (this.customFieldsConfig || []).filter(field => this.showField(field));
	}

	private customFieldsConfig: (MatterCustomFieldListItemDto | ContactCustomFieldListItemDto)[];

	constructor(
		private fb: FormBuilder,
		private matterCustomFieldsService: MatterCustomFieldsService,
		private contactCustomFieldsService: ContactCustomFieldsService
	) {}

	ngOnInit(): void {
		this._subscriptions.add(
			this._entityType.subscribe(value => {
				if (this._lastEntityType === value) {
					return;
				}

				// Clear existing controls
				if (!!this.formGroup.controls) {
					Object.keys(this.formGroup.controls).forEach(control => this.formGroup.removeControl(control));
				}

				if (value === 'Matter') {
					// Get the list of custom fields from the server and use it to initialize the form with fields matching
					// the DTO we eventually need to send back to the server
					this._subscriptions.add(
						this.matterCustomFieldsService
							.getMatterCustomFieldList(
								!!this._practiceAreaId.value
									? { enabled: true, practiceAreaIds: [this._practiceAreaId.value] }
									: { enabled: true }
							)
							.subscribe(result => this.handleCustomFieldsFetchResponse(result))
					);
				} else if (value === 'Contact') {
					// Get the list of custom fields from the server and use it to initialize the form with fields matching
					// the DTO we eventually need to send back to the server
					this._subscriptions.add(
						this.contactCustomFieldsService
							.getContactCustomFieldList(
								!!this._contactType.value
									? { enabled: true, contactTypes: [this._contactType.value] }
									: { enabled: true }
							)
							.subscribe(result => this.handleCustomFieldsFetchResponse(result))
					);
				}

				this._lastEntityType = value;
			})
		);

		this._subscriptions.add(this._practiceAreaId.subscribe(() => this.toggleControlsByVisibility()));
		this._subscriptions.add(this._contactType.subscribe(() => this.toggleControlsByVisibility()));
	}

	ngOnDestroy() {
		this._entityType.complete();
		this._practiceAreaId.complete();
		this._contactType.complete();

		this._subscriptions.unsubscribe();
	}

	onContactSelected(event: ContactReference, control: FormControl) {
		control.setValue(event.id);
	}

	private handleCustomFieldsFetchResponse(
		result: ListResponse<MatterCustomFieldListItemDto | ContactCustomFieldListItemDto>
	) {
		// Save the custom field config for use in the html, only show mandatory fields
		this.customFieldsConfig = sortBy(
			result.records.filter(f => f.mandatory),
			f => f.name
		);

		this.customFieldsConfig.forEach(field => {
			const validators: ValidatorFn[] = [];

			if (field.mandatory) validators.push(CustomValidators.required(field.name));
			if (field.fieldType === CustomFieldType.Email) validators.push(CustomValidators.optionalEmail());

			this.formGroup.addControl(
				field.id,
				this.fb.control(!!this.useDefaults ? field.defaultValue : null, validators)
			);

			this.toggleControlByVisibility(field);
		});
	}

	private showMatterField(field: MatterCustomFieldListItemDto): boolean {
		return isEmpty(field.practiceAreas) || field.practiceAreas.some(area => area.id === this._practiceAreaId.value);
	}

	private showContactField(field: ContactCustomFieldListItemDto): boolean {
		return isEmpty(field.contactTypes) || field.contactTypes.some(type => type == this._contactType.value);
	}

	private showField(field: MatterCustomFieldListItemDto | ContactCustomFieldListItemDto): boolean {
		return this._lastEntityType === 'Contact'
			? this.showContactField(field as ContactCustomFieldListItemDto)
			: this.showMatterField(field as MatterCustomFieldListItemDto);
	}

	private toggleControlsByVisibility() {
		(this.customFieldsConfig || []).forEach(field => this.toggleControlByVisibility(field));
	}

	private toggleControlByVisibility(field: MatterCustomFieldListItemDto | ContactCustomFieldListItemDto) {
		const control = this.formGroup.get(field.id);
		if (this.showField(field)) {
			control.enable();
		} else {
			control.disable();
		}
	}
}
