import { SortDirection } from '@angular/material/sort';

import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';
import { CostTemplateListItemDto } from '@common/models/Settings/CostTemplates/List/CostTemplateListItemDto';
import { DomainError } from '@common/models/Validation/DomainError';
import { createAction, props } from '@ngrx/store';

import { SelectionType } from '../../../SelectionType';
import { ListRequest } from '@common/models/Generic/ListRequest';

export enum CostTemplateListActions {
	Init = '[Cost Template List] Initialise',
	Load = '[Cost Template List] Load',
	Loading = '[Cost Template List] Loading',
	LoadSuccess = '[Cost Template List] Load Success',
	LoadFailed = '[Cost Template List] Load Failed',

	SetFilters = '[Cost Template List] Set Filters',
	SetPageSize = '[Cost Template List] Set Page Size',
	SetPageIndex = '[Cost Template List] Set Page Index',
	SetPageIndexForId = '[Cost Template List] Set Page Index For Id',

	SetSortBy = '[Cost Template List] Set Sort By',
	SetSortDirection = '[Cost Template List] Set Sort Direction',

	InsertRecords = '[Cost Template List] Insert Records',
	UpdateRecords = '[Cost Template List] Update Records',
	RemoveRecords = '[Cost Template List] Remove Records',
	SelectRecords = '[Cost Template List] Select Records'
}

export const init = createAction(CostTemplateListActions.Init);
export const load = createAction(CostTemplateListActions.Load);
export const loading = createAction(CostTemplateListActions.Loading);
export const loadSuccess = createAction(
	CostTemplateListActions.LoadSuccess,
	props<{ response: CostTemplateListItemDto }>()
);
export const loadFailed = createAction(CostTemplateListActions.LoadFailed, props<{ error: DomainError }>());

export const setFilters = createAction(
	CostTemplateListActions.SetFilters,
	props<{ filter: Partial<ListRequest> }>()
);
export const setPageSize = createAction(CostTemplateListActions.SetPageSize, props<{ pageSize: number }>());
export const setPageIndex = createAction(CostTemplateListActions.SetPageIndex, props<{ pageIndex: number }>());
export const setPageIndexForId = createAction(CostTemplateListActions.SetPageIndexForId, props<{ id: string }>());

export const setSortBy = createAction(CostTemplateListActions.SetSortBy, props<{ sortBy: string }>());
export const setSortDirection = createAction(
	CostTemplateListActions.SetSortDirection,
	props<{ sortDirection: SortDirection }>()
);

export const insertRecords = createAction(
	CostTemplateListActions.InsertRecords,
	props<{ response: MutationResponseDto | MutationResponseDto[] }>()
);
export const updateRecords = createAction(
	CostTemplateListActions.UpdateRecords,
	props<{ response: MutationResponseDto | MutationResponseDto[] }>()
);
export const removeRecords = createAction(
	CostTemplateListActions.RemoveRecords,
	props<{ response: MutationResponseDto | MutationResponseDto[] }>()
);
export const selectRecords = createAction(
	CostTemplateListActions.SelectRecords,
	props<{ row: CostTemplateListItemDto; selectionType: SelectionType }>()
);
