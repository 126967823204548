import { Component, Input } from '@angular/core';

import { BaseEditableComponent } from './base.editable.component';

@Component({
	selector: 'editable-email-address',
	styleUrls: ['./base.editable.component.scss'],
	template: `
		<span *ngIf="!editMode && !value()" [class]="'view-noitem'">{{ formatDisplay(blankMessage) }}</span>
		<a *ngIf="!editMode && value()" [href]="link">{{ formatDisplay(value()) }}</a>
		<mat-form-field *ngIf="editMode">
			<mat-placeholder *ngIf="placeHolder"
				>{{ placeHolder }}
				<sup class="color-warn" *ngIf="required">*</sup>
			</mat-placeholder>
			<input matInput type="email" [formControl]="formControl" [autofocus]="HasAutofocus" autocomplete="off" />
			<mat-hint *ngIf="!!hintText?.length">{{ hintText }}</mat-hint>
			<mat-error>
				<error-messages [for]="control"></error-messages>
			</mat-error>
		</mat-form-field>
	`
})
export class EditableEmailAddressComponent extends BaseEditableComponent<string> {
	get link() {
		return `mailto:${this.value()}`;
	}
	@Input()
	HasAutofocus: boolean;
}
