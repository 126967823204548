import { Observable } from 'rxjs';

export class BaseMetadataService<TMetadataFile extends BaseMetadataFile> {
	isSupported(extension: string): boolean {
		throw new Error('isSupported() not implemented');
	}

	readFile(data: ArrayBuffer | Promise<ArrayBuffer> | File): Observable<TMetadataFile> {
		if (data instanceof ArrayBuffer || 'Promise<ArrayBuffer>' in data) {
			return this.readArrayBuffer(data);
		}

		if (data instanceof File) {
			return this.readArrayBuffer(data.arrayBuffer());
		}

		throw new Error('data is not instance of ArrayBuffer or File');
	}

	readArrayBuffer(data: ArrayBuffer | Promise<ArrayBuffer>): Observable<TMetadataFile> {
		throw new Error('readArrayBuffer() not implemented');
	}
}

export class BaseMetadataFile {
	getFirstMetadataValue(keys: string[]): string {
		const found = this.getMetadataValues(keys);

		return !!found && found.length > 0 ? found[0] : null;
	}

	getFirstMetadataValueAsArray(keys: string[]) {
		const found = this.getMetadataValues(keys);

		return !!found && found.length > 0
			? found[0].split(/,|;/)
			: null;
	}

	getMetadataValues(keys: string[]): string[] {
		throw new Error('getMetadataValues() not implemented');
	}

	getMetadataValuesAsArray(keys: string[]) {
		const values = this.getMetadataValues(keys)

		return !!values && values.length > 0
			? values
				.map(value => value.split(/,|;/))
				.reduce((left, right) => left.concat(right))
			: null;
	}
}