// This file has been generated from PagePosition.cs
// Do not modify this file directly!!

// LawMaster.Amalthea.Domain.DocumentBriefs.Item.PagePosition
export enum PagePosition {
	BottomRight = 'Bottom Right',
	BottomMiddle = 'Bottom Middle',
	BottomLeft = 'Bottom Left',
	TopRight = 'Top Right',
	TopMiddle = 'Top Middle',
	TopLeft = 'Top Left'
}
