import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { forkJoin, of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ApplicationInsightsService } from '@common/application-insights/application-insights.service';
import { DocumentViewDto } from '@common/models/Documents/Item/DocumentViewDto';
import { DocumentBaseListItemDto } from '@common/models/Documents/List/DocumentBaseListItemDto';
import { INotificationMessage, NotificationService } from '@common/notification';
import { getFileExtension, getFileIcon } from '@common/utils/file-extensions';

import { DocumentsService } from './documents.service';
import { SystemSettingsResolverService } from './settings-resolver.service';

@Injectable({
	providedIn: 'root'
})
export class DocumentsEditService<
	TListItemDto extends
		| DocumentBaseListItemDto
		| DocumentViewDto
		| { fileExtension: string; title: string; id: string }
> {
	private subscriptions: Subscription = new Subscription();
	private currentWebsite: string;

	constructor(
		private docService: DocumentsService,
		@Inject(DOCUMENT) private document: Document,
		private notification: NotificationService,
		private appInsights: ApplicationInsightsService,
		settingResolverService: SystemSettingsResolverService
	) {
		this.currentWebsite = settingResolverService.getAppServerUrl();
	}

	editDocument(document: TListItemDto): void {
		// round trip to the server to check the locking status
		this.subscriptions.add(
			forkJoin([
				this.docService.getLockStatus(document.id),
				this.docService.getWebDavLink(document.id, this.isMac())
			])
				.pipe(
					switchMap(([lockStatus, webdavlink]) => {
						let error = null;
						let message: INotificationMessage = null;
						let link: string = null;
						if (!lockStatus.isLocked) {
							if (this.isMac() && document.fileExtension === '.dotx') {
								error = {
									title: 'Document Template cannot be edited',
									html: `The document template '${document.title}.dotx' cannot be edited on a Mac.
								To edit this document, upload it as a .docx file or edit in windows`
								};
							} else {
								link = this.getUrlWithScheme(webdavlink);
								if (!!this.appInsights) this.appInsights.trackEvent(`Showed the Edit Document Toast`);
								message = {
									text: 'Trouble editing document? ',
									linkRoute: [
										'https://support.mattero.com.au/hc/en-us/articles/900005678983-Blocked-Microsoft-Office-Documents-When-You-Attempt-to-Edit'
									],
									linkText: 'Click here',
									isExternalLink: true
								};
							}
						} else {
							error = {
								title: 'Document is Locked',
								html: `The document '${document.title}' is currently locked by ${lockStatus.lockedBy.name}`
							};
						}
						return of({ error, message, link });
					})
				)
				.subscribe(
					response => {
						if (!!response.message) this.notification.showNotificationLink(response.message, 7000);
						if (!!response.error) this.notification.showError(response.error.title, response.error.html);
						if (!!response.link) this.document.location.href = response.link;
					},
					error => this.notification.showErrors('Error editing Document', error)
				)
		);
	}

	// Get URL schema for the file extension
	getUriScheme(fileExtension: string): string {
		switch (this.getFileIcon(fileExtension)) {
			case 'file-word':
				return 'ms-word:';
			case 'file-excel':
				return 'ms-excel:';
			case 'file-powerpoint':
				return 'ms-powerpoint:';
			case 'file-chart':
				return 'ms-visio:';
			default:
				return null;
		}
	}

	// Get URL schema for the file extension
	getNativeAppName(fileExtension: string): string {
		switch (this.getFileIcon(fileExtension)) {
			case 'file-word':
				return 'Word';
			case 'file-excel':
				return 'Excel';
			case 'file-powerpoint':
				return 'PowerPoint';
			case 'file-chart':
				return 'Visio';
			default:
				return null;
		}
	}

	// File icon for the file extension
	getDocUrlForEditing(document: TListItemDto): string {
		const scheme = this.getUriScheme(document.fileExtension);
		const uri =
			(!!scheme ? scheme + 'ofe|u|' : '') +
			this.currentWebsite +
			'/webdav/documents/' +
			document.id +
			'/' +
			`${document.title}${document.fileExtension}`?.replace(/[\<\>\:\"\|\?\*\/\\]/g, ''); // remove unsupported filename characters
		return encodeURI(uri);
	}

	getUrlWithScheme(url: string): string {
		const scheme = this.getUriScheme(getFileExtension(url));
		const uri = (!!scheme ? scheme + 'ofe|u|' : '') + url;
		return uri;
	}

	private getFileIcon(extension: string, hasAttachments: boolean = false): string {
		return getFileIcon(extension, hasAttachments);
	}

	private isMac(): boolean {
		return navigator.userAgent.indexOf('Mac OS X') !== -1;
	}
}
