import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { NEVER, of, tap } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ApplicationInsightsService } from '@common/application-insights/application-insights.service';
import { SessionStorageService } from '@common/services/storage/session-storage.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { get } from 'lodash-es';
import moment, { Moment } from 'moment-timezone';

import { AppConfig } from 'app/app.config';
import { ExternalPortalRouteService } from 'app/services/external-portal-route-service';
import { SystemInformationService } from 'app/services/systeminformation.service';
import { b64DecodeUnicode, padBase64 } from 'app/shared/utils/encodingUtil';

@Injectable()
export class AuthService {
	static maxIatOffsetAllowedInMinutes = 5;

	private loginResturnUrlKey = 'loginReturnUrl';

	constructor(
		private applicationInsightsService: ApplicationInsightsService,
		public oidcSecurityService: OidcSecurityService,
		private portalRouteService: ExternalPortalRouteService,
		private sessionStorageService: SessionStorageService,
		private systemInformationService: SystemInformationService,
		private router: Router
	) {}

	get isAuthenticated$(): Observable<boolean> {
		return this.oidcSecurityService.isAuthenticated$.pipe(map(result => !!result?.isAuthenticated));
	}

	get hasValidAccessToken(): boolean {
		if (!this.accessToken) return false;
		const exp = this.getFirstClaim(ClaimTypes.exp);
		const expiry = parseInt(exp) * 1000;
		const now = Date.now();
		return now < expiry;
	}

	get accessToken(): string {
		return this.oidcSecurityService.getAccessToken();
	}

	get HttpAuthorizationHeader(): string {
		const token = this.accessToken;
		return !!token ? `Bearer ${token}` : null;
	}

	get UserName(): string {
		return this.getFirstClaim(ClaimTypes.email);
	}

	get UserId(): string {
		return this.getFirstClaim(ClaimTypes.sub);
	}

	get OrganisationId(): string {
		return this.getFirstClaim(ClaimTypes.LM_OrganizationId);
	}

	get TenantId(): string {
		return this.OrganisationId;
	}

	get TimeZoneId(): string {
		return this.getFirstClaim(ClaimTypes.LM_TimeZone) ?? 'Australia/Sydney';
	}

	getClaim(claimType: string): string | string[] {
		const token = this.accessToken;
		if (!token) return null;
		const accessTokenClaims = JSON.parse(b64DecodeUnicode(padBase64(token.split('.')[1])));
		return !accessTokenClaims ? null : get(accessTokenClaims, claimType);
	}

	getFirstClaim(claimType: string): string {
		const claims = this.getClaim(claimType);
		if (!!claims && Array.isArray(claims)) {
			return claims.length > 0 ? claims[0] : null;
		}
		return claims as string;
	}

	configureOAuthLogin(): Observable<boolean> {
		const windowLocationPath =
			window.location.pathname + (window.location.search.length > 1 ? window.location.search : '');

		if (!!this.portalRouteService.useExternalPortalAuth) return of(true);

		return this.checkClientClock().pipe(
			switchMap(() => this.oidcSecurityService.checkAuth()),
			switchMap(response => {
				return !response?.isAuthenticated && !!this.oidcSecurityService.getRefreshToken()
					? this.SilentRefresh(true)
					: of(this.oidcSecurityService.isAuthenticated());
			}),
			tap((isAuthenticated: boolean) => {
				if (!this.hasValidAccessToken) {
					this.Login(windowLocationPath);
				} else if (this.sessionStorageService.containsKey(this.loginResturnUrlKey)) {
					// set the router path to the return url stored prior to login
					const returnUrl = this.sessionStorageService.getItemAs<string>(this.loginResturnUrlKey);
					this.router.navigateByUrl(returnUrl).then(() => {
						this.sessionStorageService.removeItem(this.loginResturnUrlKey);
					});
				}
			})
		);
	}

	SilentRefresh(forcedRefresh: boolean = false): Observable<boolean> {
		if (!!this.oidcSecurityService.getRefreshToken() && (!!forcedRefresh || !this.hasValidAccessToken)) {
			return this.oidcSecurityService.forceRefreshSession().pipe(
				catchError(err => {
					console.log(err);
					return of({ isAuthenticated: false });
				}),
				map(response => !!response?.isAuthenticated || this.hasValidAccessToken)
			);
		}
		return of(this.hasValidAccessToken);
	}

	Login(loginReturnUrlOverride: string = null): void {
		// cache the current url path so that we can set it again when we return
		this.sessionStorageService.setItem(this.loginResturnUrlKey, loginReturnUrlOverride ?? window.location.pathname);
		this.oidcSecurityService.authorize();
	}

	Logout(): Observable<any> {
		return this.oidcSecurityService.logoffAndRevokeTokens();
	}

	private checkClientClock(): Observable<any> {
		return this.systemInformationService.getServerTime().pipe(
			switchMap((serverTime: Moment) => {
				const clientTime = moment();
				// if the server and client are out by more than 5 minutes
				if (Math.abs(clientTime.diff(serverTime, 'minutes')) >= AuthService.maxIatOffsetAllowedInMinutes) {
					const message = `[${clientTime.format(
						'HH:mm:ss'
					)}] Client time ${clientTime.toISOString()} does not match server time ${serverTime.toISOString()}`;
					console.error(message);
					this.applicationInsightsService.trackException(new Error(message), {
						eventId: 'HLXO5W0', // a random Id you can use to search the logs
						eventType: 'clock' // a term you can use to search the logs
					});
					window.location.href = `${AppConfig.AppServerUrl}/clock-skew.html`;
					return NEVER;
				}
				return of({});
			}),
			// If there is an error getting the server time, no need to prevent auth starting up. Just hope for the best.
			catchError(() => of({}))
		);
	}
}

enum ClaimTypes {
	email = 'email',
	sub = 'sub',
	exp = 'exp',
	LM_OrganizationId = 'LM_OrganizationId',
	LM_TimeZone = 'LM_TimeZone'
}
