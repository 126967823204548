import { WorkTimerStateData } from 'app/shared/components/work-timer/WorkTimerStateData';
import { WorkTimerStateDataVolatile } from 'app/shared/components/work-timer/WorkTimerStateDataVolatile';

// Represents the complete state of the work timer
export class WorkTimerState {
	hasDataChanged: boolean = false;
	hasVolatileDataChanged: boolean = false;

	private _data: WorkTimerStateData = null;
	private _volatileData: WorkTimerStateDataVolatile = null;

	constructor({ data, volatileData } : { data: WorkTimerStateData, volatileData: WorkTimerStateDataVolatile }){
		this._data = data;
		this._volatileData = volatileData;
	}

	get data(): WorkTimerStateData {
		return this._data;
	}

	set data(data: WorkTimerStateData) {
		this.hasDataChanged = true;

		if (data) {
			this._data = data;
		} else {
			this._data = null;
		}
	}

	get volatileData(): WorkTimerStateDataVolatile {
		return this._volatileData;
	}

	set volatileData(metadata: WorkTimerStateDataVolatile) {
		this.hasVolatileDataChanged = true;

		if (metadata) {
			this._volatileData = metadata;
		} else {
			this._volatileData = null;
		}
	}

	raiseDataChanged(): void {
		this.hasDataChanged = true;
	}

	raiseVolatileDataChanged(): void {
		this.hasVolatileDataChanged = true;
	}
}