import { ListResponse } from '@common/models/Generic/ListResponse';
import { InfoTrackOrderListItemDto } from '@common/models/InfoTrack/Orders/List/InfoTrackOrderListItemDto';
import { InfoTrackOrderListRequest } from '@common/models/InfoTrack/Orders/List/InfoTrackOrderListRequest';
import { DomainError } from '@common/models/Validation/DomainError';

import { AppConfig } from 'app/app.config';

export interface IInfoTrackListState {
	isFetching: boolean;
	list: ListResponse<InfoTrackOrderListItemDto>;
	lastSelectedRecordId: string;
	request: Partial<InfoTrackOrderListRequest>;
	oldRequest: Partial<InfoTrackOrderListRequest>;

	// errrors
	error: DomainError;
}

export const initialState: IInfoTrackListState = {
	isFetching: false,
	list: null,
	lastSelectedRecordId: null,
	request: {
		pageSize: AppConfig.PageSize,
		pageIndex: 0,
		pageIndexForId: null,

		sortBy: null,
		sortDirection: null
	},
	oldRequest: null,
	error: null
};
