<h2 mat-dialog-title class="title">Edit Header/Footer Template</h2>

<mat-dialog-content>
	<div class="flex-column" [formGroup]="form">
		<div class="flex-row">
			<mat-form-field>
				<mat-placeholder>Title <sup class="color-warn">*</sup> </mat-placeholder>
				<input matInput formControlName="title" />
				<mat-error> <error-messages [for]="form.get('title')"></error-messages> </mat-error>
			</mat-form-field>
		</div>
		<document-tag *ngIf="form.get('documentTags')" [ActiveTags]="form.get('documentTags').value" [FormControl]="form.get('documentTags')"></document-tag>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-raised-button color="primary" [disabled]="!form.valid" throttleButton (throttledClick)="save()">Save</button>
	<button mat-raised-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
