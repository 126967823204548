// This file has been generated from IgnoreXeroSyncDto.cs
// Do not modify this file directly!!
// tslint:disable

import { SetSimpleType } from '@common/utils/class-transform';



// LawMaster.Amalthea.Domain.Bills.Item.IgnoreXeroSyncDto
export class IgnoreXeroSyncDto {

	// Id
	id: string;
	// Value
	@SetSimpleType(Boolean)
	value: boolean;

	constructor() {

	}
}