// This file has been generated from StripeController.cs
// Do not modify this file directly!!
// tslint:disable

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { TrimStringsOnObject } from '@common/utils/string-format';

import { CreateCheckoutSessionDto } from '../models/Infrastructure/StripeIntegration/Models/CreateCheckoutSessionDto';
import { CustomerPortalDto } from '../models/Infrastructure/StripeIntegration/Models/CustomerPortalDto';
import { PreviewCostChangesDto } from '../models/Infrastructure/StripeIntegration/Models/PreviewCostChangesDto';
import { StripeSubscriptionProduct } from '../models/Infrastructure/StripeIntegration/Models/StripeSubscriptionProduct';

@Injectable({
	providedIn: 'root'
})
export class StripeService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'api/v1/Stripe', 'Stripe');
	}

	createCheckoutSession(dto: CreateCheckoutSessionDto): Observable<any> {
		return this.post<CreateCheckoutSessionDto, any>(`createCheckoutSession`, TransformDatesOnObject(CreateCheckoutSessionDto, TrimStringsOnObject(dto)));
	}

	customerPortal(dto: CustomerPortalDto): Observable<any> {
		return this.post<CustomerPortalDto, any>(`customerPortal`, TransformDatesOnObject(CustomerPortalDto, TrimStringsOnObject(dto)));
	}

	getSubscriptionProducts(): Observable<StripeSubscriptionProduct[]> {
		return this.getItem<void, StripeSubscriptionProduct[]>(`subscriptionProducts`);
	}

	unsubscribeProduct(productId: string): Observable<any> {
		return this.post<void, any>(`unsubscribeProduct/${productId}`);
	}

	changeSubscription(priceId: string, quantity: number): Observable<any> {
		return this.post<void, any>(`changeSubscription/${priceId}/${quantity}`);
	}

	previewUnsubscribeProduct(stripeProductId: string): Observable<PreviewCostChangesDto> {
		return this.post<void, PreviewCostChangesDto>(`previewUnsubscribeProduct/${stripeProductId}`);
	}

	previewSubscriptionChange(priceId: string, quantity: number): Observable<PreviewCostChangesDto> {
		return this.post<void, PreviewCostChangesDto>(`previewSubscriptionChange/${priceId}/${quantity}`);
	}

	webhook(): Observable<any> {
		return this.post<void, any>(`webhook`);
	}

	pushOrderToHubspot(): Observable<void> {
		return this.post<void, void>(`pushOrderToHubspot`);
	}

}
