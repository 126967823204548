import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { forkJoin, of, Subscription } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

import { RecurringInterval } from '@common/models/Common/RecurringInterval';
import { ListRequest } from '@common/models/Generic/ListRequest';
import { PricedMarketplaceListingViewDto } from '@common/models/Infrastructure/StripeIntegration/Models/PricedMarketplaceListingViewDto';
import { RecurringInvoiceDto } from '@common/models/Infrastructure/StripeIntegration/Models/RecurringInvoiceDto';
import { StripeSubscriptionProduct } from '@common/models/Infrastructure/StripeIntegration/Models/StripeSubscriptionProduct';
import { UpcomingInvoiceDto } from '@common/models/Infrastructure/StripeIntegration/Models/UpcomingInvoiceDto';
import { LicencedProductInfoDto } from '@common/models/Settings/Setting/Item/LicencedProductInfoDto';
import { LicencingInfoDetailed } from '@common/models/Settings/Setting/Item/LicencingInfoDetailed';
import { NotificationService } from '@common/notification/notification.service';
import { GeneralSettingsService } from '@common/services/settings/generalsettings.service';
import { MarketplaceService } from '@common/services/settings/marketplace.service';
import { UsersService } from '@common/services/settings/users.service';
import { StripeService } from '@common/services/stripe.service';
import { sortBy } from 'lodash';
import * as moment from 'moment-timezone';

import { SignalrService } from 'app/core/signalr/signalr.service';

import {
	AddSubscriptionItemDialogComponent,
	IAddSubscriptionItemDialogData
} from './add-subscription-item-dialog.component';
import {
	ChangeSubscriptionDialogComponent,
	IChangeSubscriptionDialogData
} from './change-subscription-dialog.component';
import {
	IRemoveSubscriptionItemDialogData,
	RemoveSubscriptionItemDialogComponent
} from './remove-subscription-item-dialog.component';
import { ISubscriptionDialogData, SubscriptionDialogComponent } from './subscription-dialog.component';
import { SubscriptionSuccessDialogComponent } from './subscription-success-dialog.component';

@Component({
	selector: 'subscription',
	styleUrls: ['./subscription.component.scss'],
	templateUrl: 'subscription.component.html'
})
export class SubscriptionComponent implements OnInit, OnDestroy {
	private subscriptions = new Subscription();

	recurringInterval: keyof typeof RecurringInterval = 'month';
	subscriptionProducts: StripeSubscriptionProduct[];
	licenceInfo: LicencingInfoDetailed;

	private getSubscriptionProducts$ = this.stripeService.getSubscriptionProducts().pipe(
		tap(products => {
			this.subscriptionProducts = sortBy(products, x => x.orderNumber);
		})
	);

	private getLicencingInfo$ = this.settingsService.getLicencingInfoDetailed().pipe(
		tap((info: LicencingInfoDetailed) => {
			this.licenceInfo = info;
			if (!!info.stripeInfo?.recurringInterval) {
				this.recurringInterval = info.stripeInfo.recurringInterval as keyof typeof RecurringInterval;
			}
		})
	);

	private refreshData$ = forkJoin([this.getLicencingInfo$, this.getSubscriptionProducts$]).pipe(
		switchMap(() => {
			return !!this.tenantStripeInfo?.priceId
				? this.stripeService
						.previewSubscriptionChange(this.tenantStripeInfo.priceId, this.tenantStripeInfo.quantity)
						.pipe(
							tap(preview => {
								this.upcomingInvoice = preview?.upcomingCosts;
								this.recurringInvoice = preview?.recurringCosts;
							})
						)
				: of();
		})
	);

	get tenantStripeInfo() {
		return this.licenceInfo?.stripeInfo;
	}

	get products() {
		return this.licenceInfo?.tenantProducts;
	}

	get showProducts() {
		if (this.showplan && this.isSubscribed) return false;
		return !!this.products && this.products.length > 0;
	}

	displayedColumns: string[] = ['name', 'status', 'actions'];

	numberOfActiveUsers: number;
	numberOfLicensedUsers: number;
	upcomingInvoice: UpcomingInvoiceDto;
	recurringInvoice: RecurringInvoiceDto;
	showplan: boolean;
	panelOpenState: boolean;

	constructor(
		private activatedRoute: ActivatedRoute,
		private settingsService: GeneralSettingsService,
		private dialog: MatDialog,
		private stripeService: StripeService,
		private sanitizer: DomSanitizer,
		private usersService: UsersService,
		private signalrService: SignalrService,
		private notifService: NotificationService,
		private cdr: ChangeDetectorRef,
		private _marketplaceService: MarketplaceService
	) {}

	ngOnInit(): void {
		this.subscriptions.add(this.refreshData$.subscribe());

		this.subscriptions.add(
			this.activatedRoute.queryParams.subscribe(params => {
				if (params['success']) {
					this.dialog
						.open(SubscriptionSuccessDialogComponent, { height: '40%', width: '30%' })
						.afterClosed()
						.pipe(switchMap(() => this.refreshData$))
						.subscribe();
				}
			})
		);

		this.subscriptions.add(
			this.usersService.getUserList(new ListRequest()).subscribe(userList => {
				this.numberOfLicensedUsers = userList.records.filter(x => x.licenseType === 'Licensed')?.length;
				this.numberOfActiveUsers = userList.records.filter(
					x => !x.disabled && x.licenseType !== 'Free'
				)?.length;
			})
		);

		this.subscriptions.add(
			this.signalrService
				.onSubscriptionUpdated()
				.pipe(switchMap(() => this.refreshData$))
				.subscribe(() => {
					setTimeout(() => {
						this.cdr.detectChanges();
					}, 0);
				})
		);
	}

	get isSubscribed() {
		return !!this.licenceInfo?.isTenantSubscribed;
	}

	get isCancelled() {
		return !!this.tenantStripeInfo?.cancelAt && moment(this.tenantStripeInfo?.cancelAt).isSameOrAfter(moment());
	}

	getPrice(product: StripeSubscriptionProduct) {
		if (!!product) {
			return product.prices.filter(x => x.interval === this.recurringInterval)[0]?.amount;
		}
		return null;
	}

	get activeSubscriptionProduct(): StripeSubscriptionProduct {
		return this.subscriptionProducts.filter(x => x.prices.filter(p => p.isSelected)?.length > 0)[0];
	}

	upgradeText(product: StripeSubscriptionProduct): string {
		return product.orderNumber < this.activeSubscriptionProduct?.orderNumber ? 'Downgrade' : 'Upgrade';
	}

	isActive(product: StripeSubscriptionProduct) {
		if (!!product) {
			return product.prices.filter(x => x.interval === this.recurringInterval)[0]?.isSelected;
		}
		return null;
	}

	getMonthlyPrice(product: StripeSubscriptionProduct): number {
		if (this.recurringInterval === 'year') {
			const amount = this.getPrice(product);
			return Number((amount / 12).toFixed(0));
		}
		return null;
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	goToCustomerPortal() {
		this.subscriptions.add(
			this.stripeService.customerPortal({ returnURL: '/system/subscription' }).subscribe(customerPortalURL => {
				window.location.href = customerPortalURL;
			})
		);
	}

	selectModule(product: StripeSubscriptionProduct) {
		const data: ISubscriptionDialogData = {
			priceId: product.prices.filter(x => x.interval === this.recurringInterval)[0].id,
			productId: product.id,
			planName: product.name
		};

		this.subscriptions.add(this.dialog.open(SubscriptionDialogComponent, { data }).afterClosed().subscribe());
	}

	changeQuantity() {
		const data: IChangeSubscriptionDialogData = {
			moduleId: null,
			priceId: this.tenantStripeInfo.priceId,
			oldProductName: null,
			newProductName: this.activeSubscriptionProduct?.name,
			oldQuantity: this.tenantStripeInfo.quantity,
			quantity: this.tenantStripeInfo.quantity,
			numberOfActiveUsers: this.numberOfActiveUsers,
			paymentInterval: this.recurringInterval,
			oldPaymentInterval: this.tenantStripeInfo.recurringInterval === 'year' ? 'year' : 'month',
			currentStripeInfo: this.tenantStripeInfo
		};

		this.subscriptions.add(
			this.dialog
				.open(ChangeSubscriptionDialogComponent, { data })
				.afterClosed()
				.subscribe(x => {
					if (!!x) window.location.reload();
				})
		);
	}

	buyAdditionalLicenses() {
		const data: IChangeSubscriptionDialogData = {
			moduleId: null,
			priceId: this.tenantStripeInfo.priceId,
			oldProductName: null,
			newProductName: this.activeSubscriptionProduct?.name,
			oldQuantity: this.tenantStripeInfo.quantity,
			quantity: this.numberOfActiveUsers,
			numberOfActiveUsers: this.numberOfActiveUsers,
			paymentInterval: this.recurringInterval,
			oldPaymentInterval: this.tenantStripeInfo.recurringInterval === 'year' ? 'year' : 'month',
			currentStripeInfo: this.tenantStripeInfo
		};

		this.subscriptions.add(
			this.dialog
				.open(ChangeSubscriptionDialogComponent, { data })
				.afterClosed()
				.subscribe(x => {
					if (!!x) window.location.reload();
				})
		);
	}

	previewUpcomingInvoice(product: StripeSubscriptionProduct) {
		const priceId = product.prices.filter(x => x.interval === this.recurringInterval)[0].id;

		const data: IChangeSubscriptionDialogData = {
			moduleId: this.activeSubscriptionProduct?.name !== product.name ? product.moduleId : null,
			priceId,
			oldProductName: this.activeSubscriptionProduct?.name,
			newProductName: product.name,
			oldQuantity: null,
			quantity: this.tenantStripeInfo.quantity,
			numberOfActiveUsers: this.numberOfActiveUsers,
			paymentInterval: this.recurringInterval,
			oldPaymentInterval: this.tenantStripeInfo.recurringInterval === 'year' ? 'year' : 'month',
			currentStripeInfo: this.tenantStripeInfo
		};

		this.subscriptions.add(
			this.dialog
				.open(ChangeSubscriptionDialogComponent, { data })
				.afterClosed()
				.subscribe(x => {
					if (!!x) window.location.reload();
				})
		);
	}

	getProductStatusDisplayHtml(row: LicencedProductInfoDto) {
		if (row?.pricingModel == 'Free') {
			return 'Free';
		} else if (row.isSubscribed) {
			return 'Subscribed';
		} else if (!!row.trialExpiryDate && row.isTrialExpired) {
			return `Trial expired`;
		} else if (!!row.trialExpiryDate) {
			return `Trial until ${moment(row.trialExpiryDate).format('DD/MM/YYYY')}`;
		}

		return '';
	}

	unsubscribe(row: LicencedProductInfoDto) {
		this.subscriptions.add(
			this._marketplaceService
				.getActiveListing(row.marketplaceListingType, row.marketplaceListingId)
				.pipe(
					switchMap(dto => {
						return this.openRemoveSubscriptionDialog$(row, dto);
					})
				)
				.subscribe()
		);
	}

	unsubscribeText(row: LicencedProductInfoDto) {
		if (row.isSubscribed) return 'Unsubscribe';
		return 'Uninstall';
	}

	canSubscribe(row: LicencedProductInfoDto) {
		if (this.licenceInfo.isTenantSubscribed && !row.isSubscribed && row.pricingModel == 'Subscription') return true;
		return false;
	}

	subscribe(row: LicencedProductInfoDto) {
		this.subscriptions.add(
			this._marketplaceService
				.getActiveListing(row.marketplaceListingType, row.marketplaceListingId)
				.pipe(
					switchMap(dto => {
						return this.openAddSubscriptionDialog$(row, dto);
					})
				)
				.subscribe()
		);
	}

	getMarketplaceLink(row: LicencedProductInfoDto) {
		return `/system/marketplace/${row.marketplaceListingType}/${row.marketplaceListingId}`;
	}

	private openRemoveSubscriptionDialog$(row: LicencedProductInfoDto, dto: PricedMarketplaceListingViewDto) {
		var data: IRemoveSubscriptionItemDialogData = {
			marketplaceListing: dto,
			productId: dto.stripeProductId,
			stripeInfo: this.tenantStripeInfo
		};

		return this.dialog
			.open(RemoveSubscriptionItemDialogComponent, { data })
			.afterClosed()
			.pipe(
				filter(Boolean),
				switchMap(() => {
					return this.refreshData$;
				})
			);
	}

	private openAddSubscriptionDialog$(row: LicencedProductInfoDto, dto: PricedMarketplaceListingViewDto) {
		var pricing = this.tenantStripeInfo.recurringInterval == 'month' ? dto.monthlyPricing : dto.yearlyPricing;

		var data: IAddSubscriptionItemDialogData = {
			marketplaceListing: dto,
			priceId: pricing.priceId,
			quantity: this.tenantStripeInfo?.quantity,
			billingCycle: pricing.billingCycle,
			stripeInfo: this.tenantStripeInfo
		};

		return this.dialog
			.open(AddSubscriptionItemDialogComponent, { data })
			.afterClosed()
			.pipe(
				filter(Boolean),
				switchMap(() => {
					return this.refreshData$;
				})
			);
	}

	safeHtml(html: string) {
		return this.sanitizer.bypassSecurityTrustHtml(html);
	}
}
