import { ListResponse } from '@common/models/Generic/ListResponse';
import { PracticeAreaListItemDto } from '@common/models/Settings/PracticeAreas/List/PracticeAreaListItemDto';
import { PracticeAreaListRequest } from '@common/models/Settings/PracticeAreas/List/PracticeAreaListRequest';
import { DomainError } from '@common/models/Validation/DomainError';
import { Moment } from 'moment';

export interface IPracticeAreaFilterState {
	isFetching: boolean;
	list: ListResponse<PracticeAreaListItemDto>;

	request: Partial<PracticeAreaListRequest>;
	oldRequest: Partial<PracticeAreaListRequest>;

	cacheExpiry: Moment;
	// errrors
	error: DomainError;
}

export const initialState: IPracticeAreaFilterState = {
	isFetching: false,
	list: null,
	request: {},
	oldRequest: null,
	error: null,
	cacheExpiry: null
};
