// This file has been generated from ApiKeysController.cs
// Do not modify this file directly!!
// tslint:disable

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { TrimStringsOnObject } from '@common/utils/string-format';

import { ApiKeyCreateUpdateDto } from '../../models/Settings/ApiKeys/Item/ApiKeyCreateUpdateDto';
import { ApiKeyListRequest } from '../../models/Settings/ApiKeys/List/ApiKeyListRequest';

@Injectable({
	providedIn: 'root'
})
export class ApiKeysService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'api/v1/api-keys');
	}

	getApiKeyList(request?: Partial<ApiKeyListRequest>): Observable<any> {
		return this.getItem<ApiKeyListRequest, any>('', request);
	}

	createApiKey(dto: ApiKeyCreateUpdateDto): Observable<any> {
		return this.post<ApiKeyCreateUpdateDto, any>('', TransformDatesOnObject(ApiKeyCreateUpdateDto, TrimStringsOnObject(dto)));
	}

	updateApiKey(id: string, dto: ApiKeyCreateUpdateDto): Observable<any> {
		return this.put(`${id}`, TransformDatesOnObject(ApiKeyCreateUpdateDto, TrimStringsOnObject(dto)));
	}

	revokeApiKey(id: string): Observable<any> {
		return this.delete(`${id}`);
	}

}
