import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { DocumentViewDto } from '@common/models/Documents/Item/DocumentViewDto';
import { EmailAttachmentDto } from '@common/models/Documents/Item/EmailAttachmentDto';
import { getFileIcon } from '@common/utils/file-extensions';
import { getFilenameFromHttpHeader } from '@common/utils/fileNameUtil';
import * as FileSaver from 'file-saver';

import { DocumentsEditService } from 'app/services/documents-edit.service';
import { DocumentsService } from 'app/services/documents.service';

@Component({
	selector: 'preview-document',
	styleUrls: ['./preview-document.component.scss'],
	templateUrl: './preview-document.component.html'
})
export class PreviewDocumentComponent implements OnInit, OnDestroy {
	previewDocument: DocumentViewDto;
	previewSrc: SafeUrl;
	emailDocumentId: string;
	previewPdf: boolean;
	isPreviewNote: boolean;
	isAttachmentOpened: boolean;
	fileTypeLimit: IFileTypeLimit;
	isOfficeDocument: boolean;
	attachmentFilename: string;

	private readonly MAX_WORD_SIZE_IN_MB = 10;
	private readonly MAX_POWERPOINT_SIZE_IN_MB = 10;
	private readonly MAX_EXCEL_SIZE_IN_MB = 5;

	private subscriptions: Subscription = new Subscription();

	@Input()
	canClose: boolean = true;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private docService: DocumentsService,
		private documentsEditService: DocumentsEditService<DocumentViewDto>,
		private sanitizer: DomSanitizer,
		protected dialog: MatDialog
	) {}

	ngOnInit() {
		this.preview();
	}
	preview() {
		this.isAttachmentOpened = false;
		this.subscriptions.add(
			this.activatedRoute.paramMap
				.pipe(
					map(params => params.get('id')),
					filter(Boolean),
					switchMap((id: string) => {
						if (this.isTemporaryPreviewFile(id)) {
							return this.docService.getTemporaryDocument();
						} else {
							return this.docService.getDocument(id);
						}
					})
				)
				.subscribe((dto: DocumentViewDto) => {
					this.previewDocument = dto;
					this.attachmentFilename = undefined;
					this.setPreviewSrc();
				})
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	isTemporaryPreviewFile(num: string) {
		return Boolean(num.match(/^0x[0-9a-f]+$/i)) && num.length == 17;
	}

	// File icon for the file extension
	getFileIconType(extension: string): string {
		return getFileIcon(extension);
	}

	setPreviewSrc(): void {
		this.isOfficeDocument = false;
		this.emailDocumentId = null;
		this.previewPdf = false;
		this.isPreviewNote = false;
		this.previewSrc = null;

		if (
			this.previewDocument.type === 'EmailTemplate' &&
			this.previewDocument.fileExtension.toLowerCase() === '.html'
		) {
			this.subscriptions.add(
				this.docService
					.hasPreviewEmailData(this.previewDocument.id)
					.pipe(filter(Boolean))
					.subscribe(() => (this.emailDocumentId = this.previewDocument.id))
			);
			return;
		}

		this.setPreviewSrcForIcon();
	}

	setPreviewSrcForIcon(): void {
		const fileTypeIcon = this.getFileIconType(this.previewDocument.fileExtension);
		switch (fileTypeIcon) {
			case 'file-image':
			case 'file-music':
			case 'file-video':
				this.subscriptions.add(
					this.docService.getBlob(this.previewDocument.previewUrl).subscribe(response => {
						const imgSrc = window.URL.createObjectURL(response.body);
						this.previewSrc = this.sanitizer.bypassSecurityTrustResourceUrl(imgSrc);
					})
				);
				break;
			case 'file-document':
				this.isPreviewNote = true;
				this.previewSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.previewDocument.previewUrl);
				break;
			case 'file-pdf':
				this.previewPdf = true;
				break;
			case 'file-word':
				this.previewSrc = this.getOfficePreviewSrc();
				this.fileTypeLimit = this.getFileTypeLimit(fileTypeIcon, this.MAX_WORD_SIZE_IN_MB);
				break;
			case 'file-excel':
				this.previewSrc = this.getOfficePreviewSrc();
				this.fileTypeLimit = this.getFileTypeLimit(fileTypeIcon, this.MAX_EXCEL_SIZE_IN_MB);
				break;
			case 'file-powerpoint':
				this.previewSrc = this.getOfficePreviewSrc();
				this.fileTypeLimit = this.getFileTypeLimit(fileTypeIcon, this.MAX_POWERPOINT_SIZE_IN_MB);
				break;
			case 'email-outline':
				this.emailDocumentId = this.previewDocument.id;
				break;
			default:
				// Google docs has been adding features to preview new file types.
				// If we do not know how to preview a file, send it to google docs to see if it can be previewed.
				if (this.previewDocument.fileExtension.toLowerCase() !== '.zip') {
					this.previewSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
						`https://docs.google.com/viewer?url=${encodeURIComponent(
							this.previewDocument.previewUrl
						)}&embedded=true`
					);
				}
				break;
		}
	}

	getOfficeEditorName(fileExtension: string) {
		switch (this.getFileIconType(fileExtension)) {
			case 'file-word':
				return 'Word';
			case 'file-excel':
				return 'Excel';
			case 'file-powerpoint':
				return 'PowerPoint';
			default:
				return 'editor';
		}
	}

	getOfficePreviewSrc(): SafeResourceUrl {
		this.isOfficeDocument = true;
		return this.sanitizer.bypassSecurityTrustResourceUrl(
			`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
				this.previewDocument.previewUrl
			)}&wdEmbedCode=0`
		);
	}

	editFile(): void {
		this.documentsEditService.editDocument(this.previewDocument);
	}

	closePreview() {
		this.attachmentFilename = undefined;
		this.isAttachmentOpened
			? this.preview()
			: this.router.navigate([{ outlets: { preview: null } }], {
					queryParams: {
						pageIndexForId: null
					},
					queryParamsHandling: 'merge'
			  });
	}

	emailAttachmentPreviewClicked(attachment: EmailAttachmentDto) {
		this.previewDocument.previewUrl = attachment.previewUrl;
		this.previewDocument.fileExtension = attachment.fileExtension;
		this.attachmentFilename = attachment.fullFileName;
		this.setPreviewSrc();
		this.isAttachmentOpened = true;
	}

	emailAttachmentDownloadClicked(attachment: EmailAttachmentDto) {
		this.subscriptions.add(
			this.docService
				.downloadAttachment(this.previewDocument.id, attachment.documentId, attachment.contentId)
				.subscribe(response => {
					FileSaver.saveAs(
						response.body,
						getFilenameFromHttpHeader(response.headers.get('content-disposition'))
					);
				})
		);
	}

	getFileTypeLimit(fileType: string, megaBytes: number): IFileTypeLimit {
		return {
			fileType,
			maxBytesAllowed: megaBytes * 1024 * 1024
		};
	}
}

interface IFileTypeLimit {
	fileType: string;
	maxBytesAllowed: number;
}
