// This file has been generated from ImportStatus.cs
// Do not modify this file directly!!

// LawMaster.Amalthea.Domain.Imports.Common.ImportStatus
export enum ImportStatus {
	Empty = 'Empty',
	Invalid = 'Invalid',
	Valid = 'Valid',
	Errored = 'Errored',
	Imported = 'Imported'
}
