import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { UserDeleteInfoDto } from '@common/models/Users/Item/UserDeleteInfoDto';
import { UserListRequest } from '@common/models/Users/List/UserListRequest';
import { UserGravatarLookupItemDto } from '@common/models/Users/Lookup/UserGravatarLookupItemDto';
import { UserGravatarLookupRequestDto } from '@common/models/Users/Lookup/UserGravatarLookupRequestDto';
import { UserLookupDto } from '@common/models/Users/Lookup/UserLookupDto';
import { UserLookupRequestDto } from '@common/models/Users/Lookup/UserLookupRequestDto';
import { TransformDatesOnObject } from '@common/utils';
import { TrimStringsOnObject } from '@common/utils/string-format';

import { EntityReference } from '../../models/Common/EntityReference';
import { ListResponse } from '../../models/Generic/ListResponse';
import { CreateUserDto } from '../../models/Users/Item/CreateUserDto';
import { InviteUserDto } from '../../models/Users/Item/InviteUserDto';
import { UpdateUserDto } from '../../models/Users/Item/UpdateUserDto';
import { UserViewDto } from '../../models/Users/Item/UserViewDto';
import { UserListItemDto } from '../../models/Users/List/UserListItemDto';
import { GenericHttpService } from '../../services/generic.http.service';
import { SettingsResolverService } from './../settings-resolver.service';

@Injectable({
	providedIn: 'root'
})
export class UsersService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'api/v1/settings/Users', 'User');
	}

	getUserList(request?: Partial<UserListRequest>): Observable<ListResponse<UserListItemDto>> {
		return this.getItem<UserListRequest, ListResponse<UserListItemDto>>('', request);
	}

	getUser(id: string): Observable<UserViewDto> {
		return this.getItem<void, UserViewDto>(`${id}`);
	}

	getUserByContactId(contactId: string): Observable<UserViewDto> {
		return this.getItem<void, UserViewDto>(`/getUserByContactId/${contactId}`);
	}

	lookupUserList(request: UserLookupRequestDto): Observable<UserLookupDto[]> {
		return this.getArray<UserLookupRequestDto, UserLookupDto>(`lookup`, request);
	}

	createUser(dto: CreateUserDto): Observable<EntityReference> {
		return this.post<CreateUserDto, EntityReference>(`CreateUser`, dto);
	}

	updateUser(id: string, updateUserDto: UpdateUserDto): Observable<any> {
		return this.put(`${id}`, TransformDatesOnObject(UpdateUserDto, TrimStringsOnObject(updateUserDto)));
	}

	sendEmailInvite(userId: string, email: string): Observable<any> {
		return this.post<void, any>(`${userId}/sendInvite/${email}`);
	}

	inviteUser(userId: string): Observable<InviteUserDto> {
		return this.getItem<void, InviteUserDto>(`${userId}/invite`);
	}

	enableUser(userId: string): Observable<boolean> {
		return this.post<void, boolean>(`${userId}/enable`);
	}

	disableUser(userId: string): Observable<boolean> {
		return this.post<void, boolean>(`${userId}/disable`);
	}

	deleteUser(userId: string): Observable<boolean> {
		return this.delete<boolean>(`${userId}/delete`);
	}

	assignLicense(userId: string): Observable<boolean> {
		return this.post<void, boolean>(`${userId}/assignLicense`);
	}

	unassignLicense(userId: string): Observable<boolean> {
		return this.post<void, boolean>(`${userId}/unassignLicense`);
	}

	lookupGravatarIds(dto: UserGravatarLookupRequestDto): Observable<UserGravatarLookupItemDto[]> {
		return this.getArray<UserGravatarLookupRequestDto, UserGravatarLookupItemDto>('gravatar/lookup', dto);
	}

	getDeleteInfo(id: string): Observable<UserDeleteInfoDto> {
		return this.getItem<void, UserDeleteInfoDto>(`GetDeleteInfo/${id}`);
	}

	revokeInvitation(userId: string): Observable<boolean> {
		return this.post<void, boolean>(`${userId}/revokeInvitation`);
	}
}
