import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Subscription } from 'rxjs';
import {WorkflowDocumentTemplateReferenceVariables} from '@common/models/Settings/Setting/Common/WorkflowDocumentTemplateReferenceVariables';
import { ILookupReference } from '@common/components/lookups/base-lookup.component';
import { DocumentTemplateListItemDto } from '@common/models/Documents/List/DocumentTemplateListItemDto';
import { TemplateEntityType } from '@common/models/Documents/TemplateDto/TemplateEntityType';
import { WorkflowDocumentTemplate } from '@common/models/Settings/BasicWorkflows/Item/WorkflowDocumentTemplate';
import { NotificationService } from '@common/notification';
import { MatterCustomFieldListRequest } from '@common/models/Settings/CustomFields/List/MatterCustomFieldListRequest';
import { MatterCustomFieldsService } from '@common/services/customfields-matter.service';
import { CustomValidators } from '@common/validation/custom.validators';
import { each, flatten, isNil, join, replace } from 'lodash-es';
import { GeneralSettingsService } from '@common/services/settings/generalsettings.service';
import { DocumentTemplateLookupComponent } from 'app/shared/components/document-template-lookup.component';

@Component({
	selector: './workflow-document-template-add-item',
	styleUrls: ['./workflow-document-template-add-item.component.scss'],
	templateUrl: './workflow-document-template-add-item.component.html'
})
export class WorkflowDocumentTemplateAddItemComponent implements OnInit, OnDestroy, AfterViewInit {
	private subscriptions = new Subscription();
	separator: string = ',';
	items: IDrapDropData[] = [];
	basket: IDrapDropData[] = [];
	isDirty: boolean;
	private _availableVariable: IDrapDropData[] = [
		{
			variableKey: 'ClientName',
			title: WorkflowDocumentTemplateReferenceVariables.ClientName,
			example: 'Smith, John'
		},
		{
			variableKey: 'ClientShortName',
			title: WorkflowDocumentTemplateReferenceVariables.ClientShortName,
			example: 'Smith J'
		},
		{
			variableKey: 'MatterNumber',
			title: WorkflowDocumentTemplateReferenceVariables.MatterNumber,
			example: '167'
		},
		{
			variableKey: 'MatterTitle',
			title: WorkflowDocumentTemplateReferenceVariables.MatterTitle,
			example: 'Jones vs. White'
		},
		{
			variableKey: 'TemplateName',
			title: WorkflowDocumentTemplateReferenceVariables.TemplateName,
			example: 'Costs Agreement',
		},
		{
			variableKey: 'CreatedDate',
			title: WorkflowDocumentTemplateReferenceVariables.CreatedDate,
			example: 'DD-MM-YYYY',
		},
		{
			variableKey: 'CreatedMonth',
			title: WorkflowDocumentTemplateReferenceVariables.CreatedMonth,
			example: 'January',
		},
		{
			variableKey: 'CreatedYear',
			title: WorkflowDocumentTemplateReferenceVariables.CreatedYear,
			example: 'YYYY',
		},
		{
			variableKey: 'Hyphen',
			title: WorkflowDocumentTemplateReferenceVariables.Hyphen,
			example: '',
			allowMany: true
		},
		{
			variableKey: 'OpenBracket',
			title: WorkflowDocumentTemplateReferenceVariables.OpenBracket,
			example: '',
			allowMany: true
		},
		{
			variableKey: 'ClosedBracket',
			title: WorkflowDocumentTemplateReferenceVariables.ClosedBracket,
			example: '',
			allowMany: true
		}
	];


	matterTemplateEntityType: keyof typeof TemplateEntityType = 'Matter';
	form: FormGroupTyped<WorkflowDocumentTemplate>;
	@ViewChild('templateLookup', { static: false, read: DocumentTemplateLookupComponent })
	templateLookupComponent: DocumentTemplateLookupComponent;
	selectedTemplate: DocumentTemplateListItemDto;
	defaultRoles = ['Client', 'Referrer'];
	customFieldRoles: string[] = [];
	get roles() {
		return this.defaultRoles.concat(this.customFieldRoles);
	}

	get isCreateMode(): boolean {
		return isNil(this.data.dto);
	}

	get isSaveFormEnabled(): boolean {
		return (this.form?.dirty  === true || this.isDirty === true) && !this.form?.invalid;
	}

	get isGenericContactRequired(): boolean {
		return !!this.selectedTemplate?.genericContactExists;
	}

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: WorkflowDocumentTemplateAddItemComponentData,
		private _fb: FormBuilder,
		private _dialogRef: MatDialogRef<
			WorkflowDocumentTemplateAddItemComponent,
			WorkflowDocumentTemplateAddItemResult
		>,
		private _matterCustomFieldsService: MatterCustomFieldsService,
		private _generalSettingsService: GeneralSettingsService,
		private _notificationService: NotificationService
	) {}

	ngAfterViewInit(): void {
		if (!!this.data?.dto?.documentTemplateId)
			this.templateLookupComponent.FormControl.setValue(this.data.dto.documentTemplateId);
	}

	ngOnInit(): void {
		this.subscriptions.add(
			this._generalSettingsService.getWorkflowDocumentTemplateConfig().subscribe(dto => {
				let selectedKeys = dto.referenceFormat?.split(this.separator).filter((el: string) => {
					return !isNil(el) && el !== '';
				}) ?? ['TemplateName','MatterNumber'];

				this.items = selectedKeys.map((key: string) => {
					return this._availableVariable.filter(x => x.variableKey === key)[0];
				});

				this.basket = this._availableVariable.filter(item => {
					return item.allowMany || selectedKeys.indexOf(item.variableKey) === -1;
				});
			})
		);
			
		this.form = this._fb.group({
			title: [this.data?.dto?.title, CustomValidators.required('Document Title')],
			documentTemplateId: [this.data?.dto?.documentTemplateId, CustomValidators.required('Document Template')],
			matterContactRoleName: [
				this.isCreateMode ? 'Client' : this.data.dto?.matterContactRoleName,
				CustomValidators.requiredWhen(
					() => !!this.selectedTemplate?.genericContactExists,
					'Matter Contact Role'
				)
			]
		}) as FormGroupTyped<WorkflowDocumentTemplate>;

		this.fetchRoles();
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	get templateFormat(): string {
		return this.items
			? join(
					this.items.map(x => x.variableKey),
					this.separator
			  )
			: null;
	}

	get templateName(): string {
		var saveName = '';
		this.items.forEach((item,index) => {
			if (item.variableKey == "TemplateName") {
				saveName += this.selectedTemplate.title;
			}
			else if (item.variableKey == "Hyphen") {
				saveName += " - ";
			}
			else if (item.variableKey == "OpenBracket") {
				saveName += "(";
			}
			else if (item.variableKey == "ClosedBracket") {
				saveName += ")";
			}
			else {
				saveName += item.variableKey;
			}
		})
		return saveName
	}

	fetchRoles(): void {
		var dto: Partial<MatterCustomFieldListRequest> = {
			fieldType: ['Contact'],
			enabled: true,
			practiceAreaIds: this.data.practiceAreas
		};

		this.subscriptions.add(
			this._matterCustomFieldsService.getMatterCustomFieldList(dto).subscribe(cf => {
				this.customFieldRoles = cf.records.map(item => item.description ?? item.name);
			})
		);
	}

	save() {
		if (this.form.valid) {
			this._dialogRef.close({ ...this.form.value, title: this.templateFormat });
		}
		this.subscriptions.add(
			this._generalSettingsService.setWorkflowDocumentTemplateConfig({ referenceFormat: this.templateFormat, }).subscribe(
				() => {
					this._notificationService.showNotification('Saved Template Name Format');
					this.isDirty = false;
				},
				errors => this._notificationService.showErrors('Error Saving Template Name Format', errors)
			)
		);
	}

	templateSelected(reference: ILookupReference) {
		if (!!reference && !!this.templateLookupComponent.templates) {
			const results = flatten(Object.values(this.templateLookupComponent.templates)).filter(
				template => template.id === reference.id
			);

			this.selectedTemplate = !!results?.length ? results[0] : null;

			this.form.controls.documentTemplateId.setValue(this.selectedTemplate.title);

			this.form.controls.title.setValue(this.templateName);
				
			//update example text to match the template selected
			var objIndex = this.items.findIndex(x => x.variableKey == "TemplateName")
			this.items[objIndex].example = this.selectedTemplate.title;

		} else {
			this.selectedTemplate = null;
		}
	}

	remove(index: number) {
		if (index > -1) {
			const removed = this.items.splice(index, 1);
			if (removed) {
				this.basket.push(removed[0]);
				this.form.controls.title.setValue(this.templateName);
				this.cleanupBasket();
			}
		}

		this.isDirty = true;
	}

	drop(event: CdkDragDrop<IDrapDropData[]>) {
		if (event.previousContainer === event.container) {
			moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
		} else {
			transferArrayItem(
				event.previousContainer.data,
				event.container.data,
				event.previousIndex,
				event.currentIndex
			);
			this.cleanupBasket();
		}
		this.form.controls.title.setValue(this.templateName);

		this.isDirty = true;
	}

	cleanupBasket() {
		// Ensure that as we drag and drop, the basket always keeps a hyphen and new line, but never more than one.
		const permittedDupes = this._availableVariable.filter(x => x.allowMany);
		permittedDupes.forEach(v => {
			this.basket = this.basket.filter(b => b.variableKey !== v.variableKey);
			this.basket.push(v);
		});
	}
}

interface WorkflowDocumentTemplateAddItemComponentData {
	workflowId: string;
	practiceAreas: string[];
	dto?: WorkflowDocumentTemplate;
}

interface IDrapDropData {
	variableKey: keyof typeof WorkflowDocumentTemplateReferenceVariables;
	title: string;
	example: string;
	allowMany?: boolean;
}

export interface WorkflowDocumentTemplateAddItemResult extends WorkflowDocumentTemplate {
	// templateTitle: string;
}
