import { ListResponse } from '@common/models/Generic/ListResponse';
import { DomainError } from '@common/models/Validation/DomainError';

import { PracticeAreaListItemDto } from '@common/models/Settings/PracticeAreas/List/PracticeAreaListItemDto';
import { PracticeAreaListRequest } from '@common/models/Settings/PracticeAreas/List/PracticeAreaListRequest';
import { AppConfig } from 'app/app.config';

export interface IPracticeAreaListState {
	isFetching: boolean;
	list: ListResponse<PracticeAreaListItemDto>;
	lastSelectedRecordId: string;
	request: Partial<PracticeAreaListRequest>;

	// errrors
	error: DomainError;
}

export const initialState: IPracticeAreaListState = {
	isFetching: false,
	list: null,
	lastSelectedRecordId: null,
	request: {
		search: null,

		pageSize: AppConfig.PageSize,
		pageIndex: 0,
		pageIndexForId: null,

		sortBy: null,
		sortDirection: null,
		showDisabled: false,
		showOnlyMyAreas: false,
		includePracticeAreaIds: []
	},

	error: null
};
