import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { combineLatest } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { TenantInformationDto } from '@common/models/Settings/Setting/Item/TenantInformationDto';
import { ICurrentUserData } from '@common/state/models/current-user-data';
import { ITenantData } from '@common/state/models/tenant-data';
import { Store } from '@ngrx/store';
import * as moment from 'moment-timezone';

import { EntityType } from '../../main/src/app/core/dialog.config';
// import { ZendeskService } from '../../main/src/app/services/zendesk.service';
import { UserViewDto } from '../models/Users/Item/UserViewDto';

declare var Appcues: any;

@Injectable({
	providedIn: 'root'
})
export class AppcuesService {
	constructor(
		private store: Store<{ currentUserData: ICurrentUserData; tenantData: ITenantData }>,
		private router: Router
	) // private zendeskService: ZendeskService
	{}

	init(): void {
		if (typeof Appcues === 'undefined') {
			console.log("AppCues object doesn't exist");
			return;
		}

		const user$ = this.store.select(state => state?.currentUserData?.currentUser).pipe(filter(Boolean));
		const tenant$ = this.store
			.select(state => state?.tenantData?.tenantInformation)
			.pipe(filter(x => !!x?.isSeeded));

		combineLatest([user$, tenant$])
			.pipe(take(1))
			.subscribe(([user, tenantInfo]: [UserViewDto, TenantInformationDto]) => {
				Appcues.identify(user?.id, {
					// Unique identifier for current user
					name: user?.contact?.name, // current user's name
					role: user?.userType?.name, // Current user’s type
					practiceType: tenantInfo ? tenantInfo.practiceType : null, // The Type of Practice
					tenantActivationDays: tenantInfo
						? moment(moment.now()).diff(tenantInfo.activationDate, 'days') // days - tenant was activated
						: null,
					tenantContact: tenantInfo ? tenantInfo.tenantName : null, // Company Information of the Tenant,
					userActivationDays: moment(moment.now()).diff(user?.activationDate, 'days') // days since user was activated
				});
			});

		this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
			// if (this.zendeskService.widgetOpened) {
			// 	// Need this timeout as the Appcues checklists cannot be hidden before they show.
			// 	setTimeout(() => {
			// 		Appcues.clearShow();
			// 	}, 100);
			// }
			Appcues.page();
		});
	}

	// Track open dialogues
	trackDialog(entityType: EntityType): void {
		switch (entityType) {
			case EntityType.TimeRecord:
				this.trackEvent('CreatedTimeRecord');
				break;
			case EntityType.Expense:
				this.trackEvent('CreatedExpense');
				break;
			case EntityType.Contact:
				this.trackEvent('CreatedContact');
				break;
			case EntityType.Task:
				this.trackEvent('CreatedTask');
				break;
			case EntityType.Note:
				this.trackEvent('CreatedNote');
				break;
			case EntityType.Matter:
				this.trackEvent('CreatedMatter');
				break;
		}
	}

	// Track Events
	trackEvent(appcuesEvent: keyof typeof AppcuesEvent): void {
		if (typeof Appcues !== 'undefined') Appcues.track(appcuesEvent);
	}
}

export enum AppcuesEvent {
	APIKeyCreated,
	CompanyDetailsSaved,
	CreatedTimeRecord,
	CreatedExpense,
	CreatedContact,
	CreatedTask,
	CreatedNote,
	CreatedMatter,
	CreatedMatterCustomField,
	CreatedContactCustomField,
	UploadDocument,
	CreateDocumentFromTemplate,
	SaveDocumentTemplate,
	SaveEmailDocumentTemplate,
	SaveHeaderFooterDocumentTemplate,
	InfoTrackClicked,
	CreateInvoice,
	PracticeAreaCreated,
	UserInvited,
	UnitsConfigured,
	XeroSync
}
