import { ListRequest } from '@common/models/Generic/ListRequest';
import { ListResponse } from '@common/models/Generic/ListResponse';
import { DocumentCategoryListItemDto } from '@common/models/Settings/DocumentCategories/List/DocumentCategoryListItemDto';
import { DomainError } from '@common/models/Validation/DomainError';
import { createAction, props } from '@ngrx/store';

export enum DocumentCategoryFilterActions {
	InvalidateCache = '[Document Category Filter] Invalidate Cache',
	Load = '[Document Category Filter] Load',
	Loading = '[Document Category Filter] Loading',
	LoadSuccess = '[Document Category Filter] Load Success',
	LoadFailed = '[Document Category Filter] Load Failed',

	SetFilters = '[Document Category Filter] Set Filters'
}

export const load = createAction(DocumentCategoryFilterActions.Load);
export const loading = createAction(DocumentCategoryFilterActions.Loading);
export const loadSuccess = createAction(
	DocumentCategoryFilterActions.LoadSuccess,
	props<{ response: ListResponse<DocumentCategoryListItemDto> }>()
);
export const loadFailed = createAction(DocumentCategoryFilterActions.LoadFailed, props<{ error: DomainError }>());

export const setFilters = createAction(
	DocumentCategoryFilterActions.SetFilters,
	props<{ filter: Partial<ListRequest> }>()
);

export const invalidateCache = createAction(DocumentCategoryFilterActions.InvalidateCache);
