import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

import { Observable, of as ObservableOf, of } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { BaseLookupComponent } from '@common/components/lookups/base-lookup.component';
import { BaseDtoWithTrimmedId } from '@common/models/Common/BaseDtoWithTrimmedId';
import { DocumentHeaderFooterTemplateListItemDto } from '@common/models/Documents/List/DocumentHeaderFooterTemplateListItemDto';
import { DocumentHeaderFooterTemplatesService } from '@common/services/settings/documentheaderfootertemplates.service';
import { isEmptyOrWhitespace } from '@common/utils/stringUtils';
import { flatten, orderBy } from 'lodash-es';

@Component({
	selector: 'document-header-footer-template-lookup',
	styleUrls: ['./document-header-footer-template-lookup.component.scss'],
	templateUrl: './document-header-footer-template-lookup.component.html'
})
export class DocumentHeaderFooterTemplateLookupComponent extends BaseLookupComponent<IDocumentHeaderFooterTemplateLookupDto> {
	@Input()
	FormControl: FormControl;
	@Input()
	HasAutofocus: boolean;
	@Input()
	Placeholder: string;
	@Input()
	Hint: string;
	@Input()
	Required: boolean;
	@Output()
	IsNoMatch: EventEmitter<void> = new EventEmitter<void>();
	@Input()
	disabled: boolean;
	@Input()
	autocompletePanelWidth: string;
	@Input()
	autoSelectIfOnlyOne: boolean;
	@Input()
	ConflictText: string;

	private _templates: DocumentHeaderFooterTemplateListItemDto[];
	private _filteredTemplates: IDocumentHeaderFooterTemplateLookupDto[];

	private _isRefreshing = true;

	private _onRefresh: EventEmitter<IDocumentHeaderFooterTemplateLookupDto[]> = new EventEmitter<
		IDocumentHeaderFooterTemplateLookupDto[]
	>();

	get templates() {
		return this._templates;
	}

	get filteredTemplates() {
		return this._filteredTemplates;
	}

	constructor(private service: DocumentHeaderFooterTemplatesService) {
		super();
	}

	ngOnInit() {
		super.ngOnInit();

		this.refreshTemplates();
	}

	refreshTemplates() {
		this._isRefreshing = true;

		this.subscription.add(
			this.service.getDocumentHeaderFooterTemplateList().subscribe(list => {
				this._templates = orderBy(list.records, ['documentCategory.name', 'title']);

				this._filteredTemplates = this.searchTemplates(this.inputDisplayCtrl?.value);

				if (!!this._templates) {
					const options = flatten(Object.values(this._templates));
					const selectedOption = !!this.FormControl.value
						? options.find(x => x.id === this.FormControl.value)
						: null;
					if (!!selectedOption) {
						this.setSelectedValue(selectedOption);
					} else if (this.autoSelectIfOnlyOne && options.length === 1) {
						this.setValue(options[0]);
					}
				}

				this._isRefreshing = false;
				this._onRefresh.emit(this._templates);
			})
		);
	}

	clearInput() {
		this.FormControl.setValue(null);
		this.setSelectedValue(null);

		this.FormControl.markAsTouched();
		this.FormControl.markAsDirty();
		this.FormControl.updateValueAndValidity();
	}

	inputChanged(event: any) {
		const inputText = event.target.value;

		this.options = flatten(Object.values(this._templates));
		if (!this.options.find(x => x.title === inputText)) {
			this.IsNoMatch.emit();
		}
	}

	autocompleteSelected(event: MatAutocompleteSelectedEvent): void {
		this.options = flatten(Object.values(this._templates));
		super.autocompleteSelected(event);

		this._filteredTemplates = this.searchTemplates(this.inputDisplayCtrl.value);
	}

	lookup(id: string): Observable<IDocumentHeaderFooterTemplateLookupDto> {
		if (!!this._templates) {
			const result = flatten(Object.values(this._templates)).filter(value => value.id === id);

			this._filteredTemplates = this.searchTemplates(id, true);

			return !!result?.length ? of(result[0]) : ObservableOf();
		}

		return ObservableOf();
	}

	optionHtmlText(input: IDocumentHeaderFooterTemplateLookupDto): string {
		return input?.title ?? null;
	}

	displayText(input: IDocumentHeaderFooterTemplateLookupDto) {
		return input?.title ?? null;
	}

	searchIfTermEmpty(): boolean {
		return true;
	}

	getAvailableTemplates(): Observable<IDocumentHeaderFooterTemplateLookupDto[]> {
		if (!!this._isRefreshing) {
			return this._onRefresh.pipe(first());
		} else {
			return of(this._templates);
		}
	}

	hasAvailableTemplates(): Observable<boolean> {
		return this.getAvailableTemplates().pipe(
			map(templates => {
				if (!templates) {
					return false;
				}

				return !!flatten(Object.values(this._templates))?.length;
			})
		);
	}

	private searchTemplates(termOrId: string, isId = false): IDocumentHeaderFooterTemplateLookupDto[] {
		let filtered: IDocumentHeaderFooterTemplateLookupDto[];

		if (!!this._templates) {
			if (!!isEmptyOrWhitespace(termOrId)) {
				filtered = this._templates;
			} else if (!isId) {
				const lowercaseTerm = termOrId.toLowerCase();

				filtered = this._templates.filter(value => value.title.toLowerCase().includes(lowercaseTerm));
			} else {
				filtered = this._templates.filter(value => value.id === termOrId);
			}
		}

		return Object.keys(filtered).length > 0 ? filtered : null;
	}

	search(term: string): Observable<IDocumentHeaderFooterTemplateLookupDto[]> {
		this._filteredTemplates = this.searchTemplates(term);

		return of(!!this._templates ? flatten(Object.values(this._templates)) : []);
	}
}

export interface IDocumentHeaderFooterTemplateLookupDto extends BaseDtoWithTrimmedId {
	title: string;
}
