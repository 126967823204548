import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges } from '@angular/core';

import { get } from 'lodash-es';

@Component({
	selector: 'user-avatar',
	styles: [
		`
			:host {
				text-align: center;
				font-size: 70%;
			}
			.avatar {
				height: 40px;
				width: 40px;
				border-radius: 50%;
			}
		`
	],
	template: `
		<img class="avatar" [src]="userImageUrl" /><br />
		<ng-template [ngIf]="showEditLink">
			<a *ngIf="!!profileUrl" href="{{ profileUrl }}" target="_blank">Change</a>
			<a *ngIf="!!newAvatarUrl" href="{{ newAvatarUrl }}" target="_blank" title="Create your avatar on Gravatar">
				Set Avatar
			</a>
		</ng-template>
	`
})
export class UserAvatarComponent implements OnChanges {
	@Input()
	gravatarId: string;
	@Input()
	showEditLink: boolean;

	profileUrl: string;
	newAvatarUrl: string;

	get userImageUrl(): string {
		const emailHash = this.gravatarId || '00000000000000000000000000000000';
		return `//www.gravatar.com/avatar/${emailHash}?r=g&d=mm&s=40`;
	}

	constructor(private http: HttpClient) {}

	ngOnChanges() {
		if (this.showEditLink && !!this.gravatarId) {
			this.http.get(`//en.gravatar.com/${this.gravatarId}.json`).subscribe(
				data => {
					this.profileUrl = get(data, 'entry[0].profileUrl', null);
					if (!this.profileUrl) {
						this.newAvatarUrl = '//www.gravatar.com';
					}
				},
				() => (this.newAvatarUrl = '//www.gravatar.com')
			);
		}
	}
}
