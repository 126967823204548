import { Component } from '@angular/core';

import { EntityReference } from '@common/models/Common/EntityReference';

import { FilterBaseComponent } from './filter-base.component';

@Component({
	selector: 'filter-checkbox-button',
	template: `
	<filter-entity-reference-list [root]="root" [name]="name" [label]="label" [items]="items"
	 [showTitleAsPrefix]="showTitleAsPrefix"></filter-entity-reference-list>
	`
})
export class FilterCheckboxButtonComponent extends FilterBaseComponent {
	items: EntityReference[] = [{ id: 'true', name: 'Yes' }, { id: 'false', name: 'No' }];
}
