<filter-root #theFilter [filter]="config.request" [listRequestType]="listRequestType">
	<filter-date-range-button
		[root]="theFilter"
		label="Date"
		labelStart="Start date"
		labelEnd="End date"
		nameStart="timestampStart"
		nameEnd="timestampEnd"
		[showPast]="true"
		[showToday]="true"
	></filter-date-range-button>
	<filter-related-entity-type [root]="theFilter" label="Related To" name="relatedToType"></filter-related-entity-type>
	<filter-search-button [root]="theFilter" label="Contains text" name="search"></filter-search-button>
</filter-root>
