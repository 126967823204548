import { DocumentListItemDto } from '@common/models/Documents/List/DocumentListItemDto';
import { DocumentListResponse } from '@common/models/Documents/List/DocumentListResponse';
import { createSelector, MemoizedSelector } from '@ngrx/store';

import { IAppState } from '../../app.state';

export const selectDocumentList = (state: IAppState) => state.documentList;

export const selectDocumentListRecords: MemoizedSelector<IAppState, DocumentListItemDto[]> = createSelector(
	selectDocumentList,
	state => state?.list?.records || []
);

export const selectDocumentListRequest: MemoizedSelector<IAppState, Partial<any>> = createSelector(
	selectDocumentList,
	state => state?.request
);

export const selectDocumentListResponses: MemoizedSelector<
	IAppState,
	DocumentListResponse<DocumentListItemDto>
> = createSelector(selectDocumentList, state => state?.list);

export const selectDocumentListPageIndex = createSelector(selectDocumentList, state => state?.list?.pageIndex || 0);
export const selectDocumentListTotalRecords = createSelector(
	selectDocumentList,
	state => state?.list?.totalRecords || 0
);
export const selectDocumentIsFetching = createSelector(selectDocumentList, state => !!state?.isFetching);
