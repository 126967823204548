import { isNil } from 'lodash-es';

export function isEmptyOrNil(value: string): boolean {
	return isNil(value) || value.length === 0;
}

export function isEmptyOrWhitespace(value: string): boolean {
	return isEmptyOrNil(value) || !!value.match(/^\s*$/);
}

export function normalizeName(value: string): string {
	if (!value || !value.includes(',')) {
		return value;
	}

	let matches = value.split(',');

	if (matches.length > 1) {
		matches = matches.slice(1, matches.length).concat(matches[0]);
	}

	return matches.reduce((left, right) => `${left} ${right}`).trim();
}

export function fullNameToShortName(text: string): string {
	if (!text?.length) {
		return text;
	}

	let commaIndex = text.lastIndexOf(',');

	if (commaIndex == -1) {
		return text;
	}

	do {
		if (++commaIndex >= text.length) {
			return text;
		}
	} while (text[commaIndex] === ' ');

	return text.substring(0, commaIndex + 1);
}
