export enum DialogType {
	Create = 'Create',
	Edit = 'Edit'
}

export enum EntityType {
	TimeRecord = 'Time/Fee Record',
	Expense = 'Expense',
	Matter = 'Matter',
	Contact = 'Contact',
	Task = 'Task',
	Note = 'Note',
	Trust = 'Trust Record',
	CustomReport = 'Custom Report'
}

export class DialogConfig {
	constructor(
		public dialogType: DialogType,
		public entityType: EntityType,
		public editId: string = null,
		public payload: any = null,
		public navLink: string[] = null,
		public navText: string = null,
		public associatedMatterId: string = null
	) {}
}
