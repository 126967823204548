export enum DownloadStatus {
	Queued,
	Downloading,
	Completed,
	Error
}

export interface IDownloadProgress {
	status: DownloadStatus;
	data?: {
		currentLoaded: number | null;
	};
}

export interface IDownloadFile {
	size: number;
	progress: IDownloadProgress;
}

export interface IDownloadFileBlob extends IDownloadFile {
	data: Blob;
}