<mat-card class="mat-card">
	<section class="page-path"><a [routerLink]="['..']">System</a> / Timers</section>
	<h2>Timers</h2>
	<div class="description">
		A timer is a simple counter that tracks time spent on a matter.
	</div>

	<div class="options">
		<div class="option">
			<mat-slide-toggle #globalTimerToggle (change)="onGlobalTimerChange($event)">
				<div class="global global-text">
					<span>Add Timers</span>
					<span class="hint">Allows timers to be added for specific matters.</span>
				</div>
			</mat-slide-toggle>
		</div>
	</div>
	<div class="options">
		<div class="option">
			<mat-slide-toggle #globalDeleteTimerToggle (change)="onAutoDeleteTimerChange($event)">
				<div class="global global-text">
					<span>Delete Timer</span>
					<span class="hint">Delete the timer after the Time/Fee record created</span>
				</div>
			</mat-slide-toggle>
		</div>
	</div>
</mat-card>
