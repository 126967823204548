import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { filter, Subscription } from 'rxjs';

@Injectable()
export class ExternalPortalRouteService {
	private _subscriptions = new Subscription();

	private _isPortal: boolean = false;
	private _isPortalPreview: boolean = false;
	private _isPortalLogin: boolean = false;
	private _isPortalDocument: boolean = false;

	private _matterId: string;
	private _briefId: string;
	private _routeAccessCode: string;

	get useExternalPortalAuth() {
		return !!this.isPortal && !this.isPortalPreview;
	}

	get isUserRoute() {
		return (!this.isPortalLogin && !this.isPortal) || !!this.isPortalPreview;
	}

	get briefId() {
		return this._briefId;
	}

	get isPortal() {
		return this._isPortal;
	}

	get isPortalPreview() {
		return this._isPortalPreview;
	}

	get isPortalLogin() {
		return this._isPortalLogin;
	}

	get isPortalDocument() {
		return this._isPortalDocument;
	}

	get briefUri(): string {
		return `/portal/brief/${this._matterId}/${this._briefId}`;
	}

	get loginUri(): string {
		return `${this.briefUri}/login`;
	}

	get routeAccessCode(): string {
		return this._routeAccessCode;
	}

	constructor(router: Router) {
		this.updateRoute();

		this._subscriptions.add(
			router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => this.updateRoute())
		);
	}

	updateRoute() {
		const parts = window.location.href.replace('https://', '').replace('http://', '').split('/');

		if (
			parts.length >= 4 &&
			parts[1].toLowerCase() === 'portal' &&
			(parts[2].toLowerCase() === 'brief' || parts[2].toLowerCase() === 'document')
		) {
			this._isPortal = true;

			if (parts[2].toLowerCase() === 'document') {
				this._isPortalDocument = true;

				const idCode = parts[3].split('?');

				if (idCode.length === 2) {
					this._routeAccessCode = decodeURIComponent(
						idCode[1]
							.split('&')
							.filter(param => param.toLocaleLowerCase().startsWith('code='))
							.map(param => param.substring(5, param.length))[0]
					);
				}
			}

			if (parts[3].toLowerCase() === 'preview' && parts.length >= 6) {
				this._isPortalPreview = true;

				this._matterId = parts[4];
				this._briefId = parts[5].split('#')[0];

				if (parts.length >= 7 && parts[6].toLowerCase().startsWith('login')) {
					this._isPortalLogin = true;
				}
			} else {
				this._matterId = parts[3];

				if (parts.length >= 5) {
					this._briefId = parts[4].split('#')[0];

					if (parts.length >= 6 && parts[5].toLowerCase().startsWith('login')) {
						this._isPortalLogin = true;
					}
				}
			}
		}
	}
}
