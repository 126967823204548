import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { AccessTokenRequestDto } from '@common/models/Users/Item/AccessTokenRequestDto';
import { UserInfoTrackTokenDto } from '@common/models/Users/Item/UserInfoTrackTokenDto';
import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { TrimStringsOnObject } from '@common/utils/string-format';

import { ListRequest } from '../models/Generic/ListRequest';
import { ListResponse } from '../models/Generic/ListResponse';
import { SecuredUrlMappingRequestDto } from '../models/InfoTrack/Orders/Item/SecuredUrlMappingRequestDto';
import { SecuredUrlMappingResponseDto } from '../models/InfoTrack/Orders/Item/SecuredUrlMappingResponseDto';
import { InfoTrackOrderListItemDto } from '../models/InfoTrack/Orders/List/InfoTrackOrderListItemDto';
import { InfoTrackOrderListRequest } from '../models/InfoTrack/Orders/List/InfoTrackOrderListRequest';
import { InfoTrackStatusListItemDto } from '../models/InfoTrack/Orders/List/InfoTrackStatusListItemDto';

@Injectable({
	providedIn: 'root'
})
export class InfoTrackService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'api/v1/InfoTrack');
	}

	getInfoTrackList(
		request?: Partial<InfoTrackOrderListRequest>
	): Observable<ListResponse<InfoTrackOrderListItemDto>> {
		return this.getList<InfoTrackOrderListRequest, InfoTrackOrderListItemDto>('', request);
	}

	getStatusList(request?: Partial<ListRequest>): Observable<InfoTrackStatusListItemDto[]> {
		return this.getArray<ListRequest, InfoTrackStatusListItemDto>(`GetStatusList`, request);
	}

	getSecuredSearchUrl(mappingRequest: SecuredUrlMappingRequestDto): Observable<SecuredUrlMappingResponseDto> {
		return this.post<SecuredUrlMappingRequestDto, SecuredUrlMappingResponseDto>(
			`GetSecuredSearchUrl`,
			TransformDatesOnObject(SecuredUrlMappingRequestDto, TrimStringsOnObject(mappingRequest))
		);
	}

	collectAccessTokenAndGetExpiryDate(): Observable<UserInfoTrackTokenDto> {
		return this.getItem<string, UserInfoTrackTokenDto>(`CollectAccessTokenAndGetExpiryDate`);
	}

	getNewAccessTokenWithAuthCode(accessTokenRequestDto: AccessTokenRequestDto): Observable<UserInfoTrackTokenDto> {
		return this.post<AccessTokenRequestDto, UserInfoTrackTokenDto>(
			`GetNewAccessTokenWithAuthCode`,
			TransformDatesOnObject(AccessTokenRequestDto, TrimStringsOnObject(accessTokenRequestDto))
		);
	}
}
