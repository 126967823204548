import { HttpErrorResponse } from '@angular/common/http';
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';
import { FormBuilder, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';

import { merge, never, Observable, of, Subject } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';

import { ILookupReference } from '@common/components/lookups/base-lookup.component';
import { EntityReference } from '@common/models/Common/EntityReference';
import { IUploadMultipleFiles } from '@common/models/Common/IFileUploader';
import { IResultWithAttachments } from '@common/models/Common/IResultWithAttachments';
import { DocumentType } from '@common/models/Documents/Common/DocumentType';
import { DocumentListItemDto } from '@common/models/Documents/List/DocumentListItemDto';
import { DocumentListRequest } from '@common/models/Documents/List/DocumentListRequest';
import { DocumentReference } from '@common/models/Documents/PersistenceLayer/DocumentReference';
import { TemplateEntityType } from '@common/models/Documents/TemplateDto/TemplateEntityType';
import { ListResponse } from '@common/models/Generic/ListResponse';
import { MatterLookupDto } from '@common/models/Matters/Lookup/MatterLookupDto';
import { TrustMediaType } from '@common/models/Settings/Setting/Item/TrustMediaType';
import { MediaTypeListItemDto } from '@common/models/Settings/Setting/List/MediaTypeListItemDto';
import { TrustAccountListItemDto } from '@common/models/Settings/TrustSettings/TrustAccounts/List/TrustAccountListItemDto';
import { MatterAllocationWithReference } from '@common/models/Trust/Common/MatterAllocationWithReference';
import { TransactionType } from '@common/models/Trust/Common/TransactionType';
import { TrustRecordMediaDetails } from '@common/models/Trust/Common/TrustRecordMediaDetails';
import { TrustReceiptPaymentCreateDto } from '@common/models/Trust/Item/TrustReceiptPaymentCreateDto';
import { TrustReceiptPaymentUpdateDto } from '@common/models/Trust/Item/TrustReceiptPaymentUpdateDto';
import { TrustViewDto } from '@common/models/Trust/Item/TrustViewDto';
import { INotificationMessage } from '@common/notification';
import { TrustAccountsCachedService } from '@common/services/settings/trustaccounts-cached.service';
import { TrustAccountsService } from '@common/services/settings/trustaccounts.service';
import { TrustService } from '@common/services/trust.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { CustomValidators } from '@common/validation/custom.validators';
import { Store } from '@ngrx/store';
import { find, get, map as lmap } from 'lodash';
import { isEmpty, isNil } from 'lodash-es';
import * as moment from 'moment-timezone';

import { SecurityPermissionService } from 'app/core/security-permissions.service';
import { getCurrentPageLookup } from 'app/core/state/misc/current-page/current-page.reducer';
import { ICurrentPageState } from 'app/core/state/misc/current-page/current-page.state';
import { AppBrandingService } from 'app/services/app-branding.service';
import { DocumentsService } from 'app/services/documents.service';
import { DownloadProgressDialogComponent } from 'app/shared/documents/list/download-dialog/download-progress-dialog.component';
import { IDownloadDialogData } from 'app/shared/documents/list/download-dialog/IDownloadDialogData';
import { IErrorContainer } from 'app/shared/utils/IErrorContainer';
import { IProgress } from 'app/shared/utils/IProgress';
import { round } from 'app/shared/utils/mathUtil';
import { handleHttpOperationError, handleHttpOperationProgress } from 'app/shared/utils/uploadResponseHandler';

import { BaseTrustRecordComponent } from '../base-trust-record.component';
import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';

@Component({
	selector: 'trust-receipt-payment',
	styleUrls: ['./trust-receipt-payment.component.scss'],
	templateUrl: './trust-receipt-payment.component.html'
})
export class TrustReceiptPaymentComponent
	extends BaseTrustRecordComponent<TrustReceiptPaymentCreateDto>
	implements OnInit, AfterViewInit, OnDestroy
{
	@Input()
	isReadOnly?: boolean;
	@Input()
	matterAllocationList: FormArray;
	@Input()
	onSavingEFTPayment: Subject<null>;
	@Output()
	dialogCloseRequested = new EventEmitter<null>();
	@Output()
	saveCompleted: EventEmitter<INotificationMessage> = new EventEmitter<INotificationMessage>();

	@ViewChild('amount', { read: ElementRef })
	amountCtrl: ElementRef;

	isDownloadReceiptAfterSave: boolean = false;
	globalSystemReceiptTemplateId: string;
	autoPublishWarningText: string;
	systemReceiptTemplates: ListResponse<DocumentListItemDto> = null;

	receiptDateChanged: Subject<moment.Moment> = new Subject();
	matterAllocations: MatterAllocationWithReference[];
	warningText: string;
	existingDocuments: DocumentReference[] = [];

	isEftPayment: boolean = false;
	isPEXAPayment: boolean = false;
	error: IErrorContainer = { message: '' };
	documentIdsToRemove: string[] = [];
	uploadFilesInfo: IUploadMultipleFiles = null;
	showProgress: boolean = false;
	progress: IProgress = { percentage: 0 };

	get matterAllocationControls() {
		if (this.form && this.form.controls.matterAllocations) {
			return (this.form.controls.matterAllocations as AbstractControl as FormArray).controls as FormGroup[];
		}
		return null;
	}

	get siteName() {
		return AppBrandingService.getSiteName();
	}

	constructor(
		private fb: FormBuilder,
		private trustService: TrustService,
		private cdr: ChangeDetectorRef,
		private store: Store<ICurrentPageState>,
		private dialog: MatDialog,
		private securityPermissionService: SecurityPermissionService,
		trustAccountService: TrustAccountsService,
		trustAccountsCachedService: TrustAccountsCachedService,
		private documentService: DocumentsService
	) {
		super(trustAccountService, trustAccountsCachedService);
	}
	ngOnInit(): void {
		const uniqueValidator = CustomValidators.uniqueArrayField('matterId', 'Matter allocations should be unique');
		const nonEmptyValidator = CustomValidators.nonEmptyArray();
		this.form = this.fb.group({
			amount: [null, [CustomValidators.required('Amount'), CustomValidators.positiveNumber()]],
			createCompanies: null,
			createPersons: null,
			createPlurals: null,
			date: [null, CustomValidators.required('Date')],
			matterAllocations: this.fb.array(
				[],
				[c => uniqueValidator(c), c => nonEmptyValidator(c), c => this.matterAllocationsSumValidator(c)]
			),
			mediaDetails: null,
			number: [null, [CustomValidators.requiredWhen(() => this.allowCheckNumberEditing(), 'Cheque Number')]],
			relevantContactId: [
				null,
				CustomValidators.requiredWhen(
					() => !this.form?.controls?.relevantContactFreeTextName?.value,
					'Relevant Contact'
				)
			],
			relevantContactFreeTextName: [
				null,
				CustomValidators.requiredWhen(() => !this.form?.controls?.relevantContactId?.value, 'Relevant Contact')
			],
			transactionType: this.transactionType,
			trustAccountId: [null, CustomValidators.required('Trust Account')]
		}) as FormGroupTyped<TrustReceiptPaymentCreateDto>;

		this.subscription.add(
			this.form.get('amount').valueChanges.subscribe(() => {
				const matterAllocationControls = (this.form.get('matterAllocations') as AbstractControl as FormArray)
					?.controls;

				if (!!matterAllocationControls?.length) {
					matterAllocationControls.forEach(control => control.updateValueAndValidity());
				}
			})
		);

		this.addAllocation();

		this.subscription.add(
			this.form.controls.date.valueChanges.subscribe(() => {
				this.changeReceiptDate();
			})
		);

		this.subscription.add(
			this.form.controls.relevantContactId.valueChanges.pipe(filter(Boolean)).subscribe(value => {
				if (!this.form.controls.relevantContactFreeTextName.valid) {
					this.form.controls.relevantContactFreeTextName.updateValueAndValidity();
				}

				if (!this.form.valid) {
					this.form.updateValueAndValidity();
				}
			})
		);

		this.subscription.add(
			this.form.controls.relevantContactFreeTextName.valueChanges.pipe(filter(Boolean)).subscribe(() => {
				if (!this.form.controls.relevantContactId.valid) {
					this.form.controls.relevantContactId.updateValueAndValidity();
				}

				if (!this.form.valid) {
					this.form.updateValueAndValidity();
				}
			})
		);

		super.ngOnInit();

		if (!!this.editId) {
			this.subscription.add(
				this.trustService.getTrustRecord(this.editId).subscribe((response: TrustViewDto) => {
					const trustRecord = this.convertToTrustReceiptPaymentCreateDto(response);
					// isEFTPayment is later set on media type change, but it needs to be in ngOnInit for attachments visibility
					this.isEftPayment =
						trustRecord.transactionType === TransactionType.Payment &&
						trustRecord.mediaDetails.mediaType === TrustMediaType.EFT;
					this.isPEXAPayment =
							trustRecord.transactionType === TransactionType.Payment &&
							trustRecord.mediaDetails.mediaType === 'PEXADirectDebit';
					this.form.patchValue(trustRecord);

					if (this.isEftPayment || this.isPEXAPayment) {
						this.existingDocuments = response.existingDocuments;
					}

					if (this.isReadOnly) {
						this.matterAllocations = response.matterAllocations;
					}
				})
			);
		}
		this.fetchSystemReceiptTemplates();

		this.subscription.add(
			this.form.controls.date.valueChanges.subscribe(() => {
				this.changeReceiptDate();
			})
		);

		this.subscription.add(
			this.form.controls.trustAccountId.valueChanges.subscribe(() => {
				this.populateNextNumberField();
			})
		);

		if (!!this.onSavingEFTPayment) {
			this.subscription.add(this.onSavingEFTPayment.subscribe(() => this.saveEFTPaymentDocuments()));
		}
	}

	ngAfterViewInit(): void {
		const nativeAmountInput = this.amountCtrl.nativeElement.querySelector('input');
		nativeAmountInput.focus();

		this.subscription.add(
			this.store
				.select(getCurrentPageLookup)
				.pipe(
					filter(Boolean),
					map(lookup => lookup as MatterLookupDto),
					filter(lookup => lookup.matterTrustBalance !== undefined)
				)
				.subscribe((dto: MatterLookupDto) => {
					if (dto) {
						if (dto.trustAccountId) {
							this.form.controls.trustAccountId.setValue(dto.trustAccountId);
						}
					}
				})
		);

		if (this.isReadOnly) this.form.disable();

		this.cdr.detectChanges();
	}

	save(): Observable<MutationResponseDto> {
		const request = this.form.value;
		// Since clearance date property is inside media details property, need to transform it manually.
		// Auto-transform in the service doesn't work with nested properties.
		request.mediaDetails = TransformDatesOnObject(TrustRecordMediaDetails, this.form.value.mediaDetails);

		if (this.allowCheckNumberEditing() && this.checkNumberIsPristine()) {
			request.number = null;
		}

		if (this.isEftPayment || this.isPEXAPayment) {
			this.toggleFormBusy(true);
			return this.trustService.createEftPayment(request, this.uploadFilesInfo).pipe(
				switchMap((result: Partial<IResultWithAttachments>) =>
					handleHttpOperationProgress<MutationResponseDto>(this, result, this.progress, this.handleCompleted)
				),
				catchError((errorResponse: HttpErrorResponse) => {
					this.toggleFormBusy(false);
					return handleHttpOperationError(errorResponse, this.error);
				})
			);
		} else {
			return this.trustService.create(request).pipe(
				switchMap((result: MutationResponseDto) => {
					if (this.isDownloadReceiptAfterSave) {
						this.trustService.getTrustRecord(result.id).subscribe((record: TrustViewDto) => {
							const dialogData: Partial<IDownloadDialogData> = {
								downloadFn: (() => this.documentService.downloadDocument(record.document.id)).bind(
									this
								),
								downloadParam: [record.document.id],
								suppressNotification: true
							};
							return this.dialog
								.open(DownloadProgressDialogComponent, { data: dialogData })
								.afterClosed();
						});
					}
					return of(result);
				})
			);
		}
	}

	fetchSystemReceiptTemplates() {
		if (this.isReadOnly) return;
		if (this.transactionType !== this.transactionTypeEnum.Receipt) return;

		const queryReceiptTemplates: Partial<DocumentListRequest> = {
			entityType: TemplateEntityType.Receipt,
			types: [DocumentType.Template]
		};

		this.subscription.add(
			this.documentService
				.getDocumentList(queryReceiptTemplates)
				.subscribe((response: ListResponse<DocumentListItemDto>) => {
					filter(Boolean);
					this.systemReceiptTemplates = response;
					if (response?.records?.length === 1) {
						this.globalSystemReceiptTemplateId = response?.records[0].id;
					} else {
						this.validateTrustDefaultReceiptTemplate();
					}
				})
		);
	}

	// Check for server side auto publishing of receipts.
	validateTrustDefaultReceiptTemplate() {
		if (this.isReadOnly) return;
		if (this.transactionType !== this.transactionTypeEnum.Receipt) return;

		// The backend can handle only one single receipt template
		// even if the trust does not have one selected.
		// So dont bother the user and bail out here if there is only one in the system.
		if (this.globalSystemReceiptTemplateId) return;

		this.autoPublishWarningText = null;

		if (this.systemReceiptTemplates?.records?.length === 0) {
			this.autoPublishWarningText = `There are no Receipt Templates in the system. The receipt will not be published.`;
		} else {
			// When a trust account has been selected.
			if (this.form.controls.trustAccountId) {
				const selectedTrustAccount = find(
					this.trustAccounts,
					x => x.id === this.form.controls.trustAccountId.value
				);

				// Check that the currently selected trust account has a receiptTemplate assigned to it.
				if (!selectedTrustAccount || !selectedTrustAccount.receiptTemplateId) {
					this.autoPublishWarningText =
						'There is no Receipt Template configured for this Trust Account. The receipt will not be published.';
				}
			}
		}

		if (this.autoPublishWarningText) this.isDownloadReceiptAfterSave = false;
	}

	saveEFTPaymentDocuments(): void {
		this.toggleFormBusy(true);

		const dto = this.getTrustReceiptPaymentUpdateDto(this.form.value);
		let route = '/trust';
		if (!this.securityPermissionService.hasAccessToTrust) {
			route = '/dashboard';
		}

		const successNotification: INotificationMessage = {
			linkParams: { pageIndexForId: this.editId },
			linkRoute: [route],
			text: `Trust ${this.isPEXAPayment ? 'PEXA Direct Debit' : 'EFT'} payment saved.`
		};

		// Check if there is any changes to attached files (for now upload is only about attachments)
		if (
			isEmpty(dto.documentIdsToRemove) &&
			(isNil(this.uploadFilesInfo) || isEmpty(this.uploadFilesInfo.nativeFiles))
		) {
			// close the dialog if there are no changes
			this.saveCompleted.emit(successNotification);
		} else {
			this.trustService
				.updateEftPayment(this.editId, dto, this.uploadFilesInfo)
				.pipe(
					switchMap((result: Partial<IResultWithAttachments>) =>
						handleHttpOperationProgress<EntityReference>(this, result, this.progress, this.handleCompleted)
					),
					catchError((errorResponse: HttpErrorResponse) => {
						this.toggleFormBusy(false);
						return handleHttpOperationError(errorResponse, this.error);
					})
				)
				.subscribe((res: EntityReference) => {
					if (res.id) {
						this.saveCompleted.emit(successNotification);
					}
					return null;
				});
		}
	}

	onSaveError() {
		if (this.allowCheckNumberEditing() && this.checkNumberIsPristine()) {
			this.populateNextNumberField();
		}
	}

	initAllocation(): FormGroup {
		var group = this.fb.group({
			amount: [null, CustomValidators.required('Amount')],
			matterId: [null, CustomValidators.required('Matter')],
			description: [null, CustomValidators.required('Description')]
		});

		this.subscription.add(
			merge(
				group.controls.amount.valueChanges,
				group.controls.matterId.valueChanges,
				group.controls.description.valueChanges
			).subscribe(() => {
				group.updateValueAndValidity();
				this.form.updateValueAndValidity();
			})
		);

		return group;
	}

	addAllocation() {
		const control = this.form.controls['matterAllocations'] as AbstractControl as FormArray;
		control.push(this.initAllocation());
	}

	removeAllocation(index: number) {
		const control = this.form.controls['matterAllocations'] as AbstractControl as FormArray;
		control.removeAt(index);
		setTimeout(() => {
			this.form.controls.matterAllocations.updateValueAndValidity();
		}, 0);
	}

	trustAccountChanged(event: MatOptionSelectionChange, item: TrustAccountListItemDto) {
		setTimeout(() => {
			if (!!this.form.controls.matterAllocations) this.form.controls.matterAllocations.updateValueAndValidity();

			this.validateTrustDefaultReceiptTemplate();
		}, 0);
		super.trustAccountChanged(event, item);
	}

	closeDialog() {
		this.dialogCloseRequested.emit();
	}

	onMediaTypeChanged(mediaType: MediaTypeListItemDto) {
		if (isNil(mediaType)) {
			return;
		}
		this.isEftPayment =
			this.transactionType === TransactionType.Payment && mediaType.name === 'EFT';
		this.isPEXAPayment =
			this.transactionType === TransactionType.Payment && mediaType.name === TrustMediaType.PEXADirectDebit;

		this.populateNextNumberField();
	}

	onErrorMessageUpdated(errorMessage: string) {
		this.error.message = errorMessage;
	}

	onDocumentIdsToRemoveUpdated(documentIdsToRemove: string[]) {
		this.documentIdsToRemove = documentIdsToRemove;
	}

	onUploadFilesInfoUpdated(uploadFilesInfo: IUploadMultipleFiles) {
		this.uploadFilesInfo = uploadFilesInfo;
	}

	onContactSelected(contact: ILookupReference) {
		this.form.controls.relevantContactId.setValue(contact.id);
	}

	// this method is passed to a utility and is called from there.
	// 		as a result, 'this' changes context so 'parentContext' is passed to fix it.
	private handleCompleted(
		parentContext: any,
		result: Partial<IResultWithAttachments>
	): Observable<MutationResponseDto> {
		parentContext.toggleFormBusy(false);

		if (!!result.mutationResponse) {
			return of(result.mutationResponse);
		}

		parentContext.error.message = 'Could not save payment. Please try again.';
		return never();
	}

	private toggleFormBusy(isBusy: boolean): void {
		if (isBusy) this.form.disable();
		else this.form.enable();

		// TODO think about this, solve it later
		// this.isValidChange.next(!isBusy);
		this.showProgress = isBusy;
	}

	private changeReceiptDate() {
		if (this.transactionType === TransactionType.Receipt && get(this.form, 'controls.date')) {
			this.receiptDateChanged.next(this.form.controls.date.value);
		}
	}

	private matterAllocationsSumValidator: ValidatorFn = (): ValidationErrors => {
		if (!this.form) return null;
		this.warningText = '';

		const allocatedAmount = this.form.controls.matterAllocations.value
			.map(x => x.amount)
			.reduce((sum: number, current: number) => round(sum + current), 0);

		const totalAmount = this.form.controls.amount.value;

		if (this.transactionType === 'Payment' && allocatedAmount > this.currentTrustAccount?.currentBalance) {
			this.warningText = `Insufficient funds available in trust bank. This transaction
			will overdraw the trust bank by $${allocatedAmount - this.currentTrustAccount?.currentBalance}`;
		}

		if (allocatedAmount < totalAmount) {
			return { error: `The allocated Amount is less than the total amount on the ${this.transactionType}` };
		} else if (allocatedAmount > totalAmount) {
			return { error: `The allocated Amount is greater than the total amount on the ${this.transactionType}` };
		} else {
			return null;
		}
	};

	private convertToTrustReceiptPaymentCreateDto(viewDto: TrustViewDto) {
		const dto: Partial<TrustReceiptPaymentCreateDto> = {
			amount: viewDto.amount,
			date: viewDto.date,
			isCancelled: viewDto.isCancelled,
			isReversal: viewDto.isReversal,
			matterAllocations: lmap(viewDto.matterAllocations, m => {
				return {
					amount: m.amount,
					matterId: m.matter.id,
					description: m.description
				};
			}),
			mediaDetails: viewDto.mediaDetails,
			number: viewDto.number,
			relevantContactId: viewDto.relevantContactId,
			relevantContactFreeTextName: viewDto.relevantContactFreeTextName,
			transactionType: viewDto.transactionType,
			trustAccountId: viewDto.trustAccountId
		};
		return dto;
	}

	private getTrustReceiptPaymentUpdateDto(dto: TrustReceiptPaymentCreateDto): TrustReceiptPaymentUpdateDto {
		const updateDto: TrustReceiptPaymentUpdateDto = {
			attachments: dto.attachments,
			createCompanies: dto.createCompanies,
			createPersons: dto.createPersons,
			createPlurals: dto.createPlurals,
			date: dto.date,
			documentIdsToRemove: this.documentIdsToRemove,
			isCancelled: dto.isCancelled,
			isReversal: dto.isReversal,
			mediaDetails: dto.mediaDetails,
			number: dto.number,
			relevantContactId: dto.relevantContactId,
			relevantContactFreeTextName: dto.relevantContactFreeTextName,
			transactionType: dto.transactionType,
			trustAccountId: dto.trustAccountId,
			// following 2 are relevant to journals only
			matterFromId: null,
			matterToId: null
		};

		return updateDto;
	}
}
