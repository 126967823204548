import {
	AfterContentChecked,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Inject,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';

import { Subscription } from 'rxjs';

import { EntityReference } from '@common/models/Common/EntityReference';
import { DocumentType } from '@common/models/Documents/Common/DocumentType';
import { DocumentListItemDto } from '@common/models/Documents/List/DocumentListItemDto';
import { DocumentListRequest } from '@common/models/Documents/List/DocumentListRequest';
import { DocumentTemplateListItemDto } from '@common/models/Documents/List/DocumentTemplateListItemDto';
import { TemplateEntityType } from '@common/models/Documents/TemplateDto/TemplateEntityType';
import { ListResponse } from '@common/models/Generic/ListResponse';
import { BankDetails } from '@common/models/Settings/TrustSettings/BankDetails';
import { TrustAccountCreateUpdateDto } from '@common/models/Settings/TrustSettings/TrustAccounts/Item/TrustAccountCreateUpdateDto';
import { TrustAccountViewDto } from '@common/models/Settings/TrustSettings/TrustAccounts/Item/TrustAccountViewDto';
import { TrustAccountsService } from '@common/services/settings/trustaccounts.service';
import { flatten } from 'lodash-es';

import { DocumentsService } from 'app/services/documents.service';

@Component({
	selector: 'trust-account-dialog',
	styleUrls: ['./trust-account-dialog.component.scss'],
	templateUrl: './trust-account-dialog.component.html'
})
export class TrustAccountDialogComponent implements OnInit, OnDestroy, AfterContentChecked {
	@ViewChild('bankNameInput')
	bankNameInput: ElementRef;
	validateBSB: boolean;

	receiptTemplates: DocumentTemplateListItemDto[];
	depositTemplates: DocumentTemplateListItemDto[];

	form: FormGroupTyped<TrustAccountCreateUpdateDto>;
	bankDetailsForm: FormGroupTyped<BankDetails>;
	isCreateMode: boolean;
	countries: EntityReference[] = [
		{ id: 'AU', name: 'Australia' },
		{ id: 'NZ', name: 'New Zealand' }
	];
	private subscriptions: Subscription = new Subscription();
	private _bankDetails: BankDetails;
	private _currentBalance: number;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: ITrustAccountDialogParams,
		private fb: FormBuilder,
		private trustAccountService: TrustAccountsService,
		private cdref: ChangeDetectorRef,
		private docService: DocumentsService
	) {}

	currentBalance(): number {
		return this._currentBalance;
	}

	get bankDetails(): BankDetails {
		return this._bankDetails;
	}
	set bankDetails(value: BankDetails) {
		this._bankDetails = value;
	}

	ngOnInit() {
		this.receiptTemplates = flatten(Object.values(this.data.receiptTemplates));
		this.depositTemplates = flatten(Object.values(this.data.depositTemplates));

		this.form = this.fb.group({
			bankDetails: null,
			id: this.data.id,
			statutoryMatterId: null,
			receiptTemplateId: null,
			depositTemplateId: null,
			trustCreditDays: null
		}) as FormGroupTyped<TrustAccountCreateUpdateDto>;
		this.isCreateMode = !this.data.id;

		this.bankDetailsForm = this.form.controls.bankDetails as AbstractControl as FormGroupTyped<BankDetails>;

		if (this.data.id) {
			this.subscriptions.add(
				this.trustAccountService.getTrustAccount(this.data.id).subscribe((record: TrustAccountViewDto) => {
					this.form.patchValue({
						id: this.data.id,
						statutoryMatterId: record.statutoryMatterId,
						trustCreditDays: record.trustCreditDays,
						receiptTemplateId: record.receiptTemplateId,
						depositTemplateId: record.depositTemplateId
					});

					this._currentBalance = record.currentBalance;
					this.bankDetails = record.bankDetails;
				})
			);
		}

		if (this.isCreateMode) {
			const queryReceiptTemplates: Partial<DocumentListRequest> = {
				entityType: TemplateEntityType.Receipt,
				types: [DocumentType.Template]
			};

			const queryDepositTemplates: Partial<DocumentListRequest> = {
				entityType: TemplateEntityType.Deposit,
				types: [DocumentType.Template]
			};

			this.subscriptions.add(
				this.docService
					.getDocumentList(queryReceiptTemplates)
					.subscribe((response: ListResponse<DocumentListItemDto>) => {
						if (response.records.length === 1) {
							this.form.controls.receiptTemplateId.setValue(response.records[0].id);
						}
					})
			);

			this.subscriptions.add(
				this.docService
					.getDocumentList(queryDepositTemplates)
					.subscribe((response: ListResponse<DocumentListItemDto>) => {
						if (response.records.length === 1) {
							this.form.controls.depositTemplateId.setValue(response.records[0].id);
						}
					})
			);
		}
	}

	ngAfterContentChecked(): void {
		this.cdref.detectChanges();
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	countryChanged(countryEvent: MatSelectChange): void {
		if (countryEvent.value === 'AU') {
			this.validateBSB = true;
		} else {
			this.validateBSB = false;
			// Clear BSB when NZ is selected
			this.bankDetailsForm.controls.bsb.patchValue('');
		}
		// run updateValueAndValidity so BSB validations fire.
		this.bankDetailsForm.controls.bsb.updateValueAndValidity();
	}
}

export interface ITrustAccountDialogParams {
	id: string;
	receiptTemplates: DocumentTemplateListItemDto[];
	depositTemplates: DocumentTemplateListItemDto[];
}
