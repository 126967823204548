<mat-card>
	<mat-tab-group (selectedTabChange)="selectedTabChanged($event)" [selectedIndex]="selectedTabIndex">
		<mat-tab>
			<ng-template mat-tab-label>
				<span>My</span>
				<span class="hiddenPhone">Scheduled Jobs</span>
			</ng-template>
		</mat-tab>
		<mat-tab>
			<ng-template mat-tab-label>
				<span>All</span>
				<span class="hiddenPhone">Scheduled Jobs</span>
			</ng-template>
		</mat-tab>
		<mat-tab [disabled]="true">
			<ng-template mat-tab-label>
				<button *ngIf="!isMobile" mat-button (click)="toggleFilters()">
					<span>Filters</span>
					<mat-icon>{{ filter.root.showFilters ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
				</button>
				<filter-mobile-span
					[filterRoot]="filter.root"
					[filterParams]="config"
					[filterComponent]="filterDialog"
					[isTabbedFilter]="true"
				></filter-mobile-span>
			</ng-template>
		</mat-tab>
	</mat-tab-group>
	<job-list-filter [config]="config"></job-list-filter>
	<filter-mobile-span [filterRoot]="filter.root" [filterParams]="config" [filterComponent]="filterDialog"></filter-mobile-span>
	<job-list #list [filterComponent]="filter.root"></job-list>
</mat-card>
