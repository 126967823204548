import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

import { NEVER, of } from 'rxjs';
import { delay, switchMap, tap } from 'rxjs/operators';

import * as moment from 'moment-timezone';

import { SpinnerService } from './spinner.service';

@Injectable({
	providedIn: 'root'
})
export class VersionCheckService {
	private currentVersion = '#{Build.BuildNumber}';
	private reloadTime: Date = null;
	private snackBarShowing: boolean = false;
	constructor(private snackBar: MatSnackBar, private spinnerService: SpinnerService) {}

	checkVersion(versionNumber: string) {
		if (!versionNumber) return;

		if (!this.snackBarShowing && this.isNewVersion(this.currentVersion, versionNumber)) {
			this.snackBarShowing = true;
			const config: MatSnackBarConfig = {
				panelClass: 'version-check-panel'
			};

			if (!this.reloadTime) this.reloadTime = moment().add(10, 'seconds').toDate();
			this.snackBar
				.open(
					'We have made some changes to mattero. Please refresh to be on the latest version.',
					'Refresh',
					config
				)
				.afterDismissed()
				.pipe(
					switchMap(x => {
						if (!x.dismissedByAction) {
							// The user didn't click Refresh, mark it as not shown so that it pops up again on next request.
							this.snackBarShowing = false;
							return NEVER;
						}
						return of(x);
					}),
					tap(() => this.spinnerService.setSpin(true)),
					// we add a delay to give the app server enough time for all instances to swap.
					// else we risk that the user reloads and re-downloads the same old version, repeating the cycle.
					delay(this.reloadTime)
				)
				.subscribe(x => {
					window.location.reload();
				});
		}
	}

	// Check each 'part' of the version number (e.g. 1.1.4493)
	// If any part is a larger number, then it is a new version
	private isNewVersion(currentVersion: string, newVersion: string) {
		if (currentVersion === newVersion) return false;

		const currentParts = currentVersion.split('.');
		const newParts = newVersion.split('.');
		if (currentParts.length !== newParts.length) return true;

		for (let i = 0; i < currentParts.length; i++) {
			if (currentParts[i] === newParts[i]) continue;

			const currentNumber = Number(currentParts[i]);
			const newNumber = Number(newParts[i]);
			return currentNumber < newNumber;
		}
		return false;
	}
}
