import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';

import { BehaviorSubject, combineLatest, interval, Subscription } from 'rxjs';
import { catchError, debounce, filter, map, mergeMap, pairwise, startWith, switchMap, tap } from 'rxjs/operators';

import { JobListItemDto } from '@common/models/Settings/Jobs/List/JobListItemDto';
import { UserViewDto } from '@common/models/Users/Item/UserViewDto';
import { INotificationMessage, NotificationService } from '@common/notification';
import { BillsService } from '@common/services/bills.service';
import { UserCurrentService } from '@common/services/usercurrent.service';
import { JobActions } from '@common/state/actions/jobs.actions';
import { ICurrentUserData } from '@common/state/models/current-user-data';
import { IJobsData } from '@common/state/models/jobs-data';
import { Store } from '@ngrx/store';
import * as moment from 'moment-timezone';
import { Moment } from 'moment-timezone';

import { FeatureFlags, isFeatureFlagEnabled } from 'app/app.config';
import { DownloadProgressDialogComponent } from 'app/shared/documents/list/download-dialog/download-progress-dialog.component';
import { IDownloadDialogData } from 'app/shared/documents/list/download-dialog/IDownloadDialogData';

@Component({
	selector: 'jobs-menu-button',
	styleUrls: ['./jobs-menu-button.component.scss'],
	templateUrl: 'jobs-menu-button.component.html'
})
export class JobsMenuButtonComponent implements OnInit, OnDestroy {
	@ViewChild('jobMenuTrigger', { read: MatButton })
	buttonReference: MatButton;

	@ViewChild('jobMenuTrigger', { read: MatMenuTrigger })
	buttonTriggerReference: MatMenuTrigger;

	private _subscriptions = new Subscription();

	jobsList$: Observable<JobListItemDto[]>;
	hasJobsInProgress$: Observable<boolean>;
	hasJobsDone$: Observable<boolean>;

	private _lastHasJobsDone: boolean;
	private _cachedLastChecked$: Observable<Moment>;

	private _jobsDone$: Observable<JobListItemDto[]>;

	private _userData$: Observable<UserViewDto>;
	private _userLastChecked$ = new BehaviorSubject<Moment>(null);

	@Output()
	menuOpened = new EventEmitter<void>();

	get showJobsMenu(): boolean {
		return isFeatureFlagEnabled(FeatureFlags.mergePdf);
	}

	constructor(
		private store: Store<{ currentUserData: ICurrentUserData; jobsData: IJobsData }>,
		private router: Router,
		private currentUserService: UserCurrentService,
		private notificationService: NotificationService,
		private billsService: BillsService,
		private dialog: MatDialog
	) {}

	ngOnInit(): void {
		if (this.showJobsMenu) {
			this._userData$ = this.store.select(state => state?.currentUserData?.currentUser);

			this.store.dispatch({ type: JobActions.LoadList });
			this.jobsList$ = this.store.select(state => state?.jobsData?.list);

			this.hasJobsInProgress$ = this.jobsList$.pipe(
				debounce(() => interval(250)),
				map(jobs => {
					return (
						jobs.filter(
							job => job.status !== 'Complete' && job.status !== 'Failed' && job.status !== 'Cancelled'
						).length > 0
					);
				})
			);

			this._cachedLastChecked$ = combineLatest([this._userData$, this._userLastChecked$]).pipe(
				map(([user, cachedLastChecked]) => {
					if (!!user?.lastCheckedJobs || !!cachedLastChecked) {
						const userLastChecked = moment(user.lastCheckedJobs);

						return !!userLastChecked && !!cachedLastChecked
							? userLastChecked > cachedLastChecked
								? userLastChecked
								: cachedLastChecked
							: !!userLastChecked
							? userLastChecked
							: cachedLastChecked;
					}

					return null;
				})
			);

			this._jobsDone$ = combineLatest([this.jobsList$, this._cachedLastChecked$]).pipe(
				debounce(() => interval(250)),
				map(([jobs, cachedLastChecked]) => {
					if (!jobs) {
						return null;
					}

					let query = jobs.filter(
						job => job.status === 'Complete' || job.status === 'Failed' || job.status === 'Cancelled'
					);

					if (!!cachedLastChecked) {
						query = query.filter(job => moment(job.lastUpdateUtc) > cachedLastChecked);
					}

					return query;
				})
			);

			this.hasJobsDone$ = this._jobsDone$.pipe(
				map(jobs => jobs.length > 0),
				tap(hasJobsDone => (this._lastHasJobsDone = hasJobsDone))
			);

			// Feedback on job completion
			this._subscriptions.add(
				this._jobsDone$
					.pipe(
						startWith(null as JobListItemDto[]),
						pairwise(),
						map(([oldJobs, currentJobs]) => {
							if (oldJobs === null || !currentJobs?.length) {
								return null;
							}

							return currentJobs.filter(job => oldJobs.filter(oldJob => oldJob.id == job.id).length == 0);
						}),
						filter(jobs => !!jobs?.length),
						mergeMap(jobs => jobs)
					)
					.subscribe(job => {
						const notif: INotificationMessage = {
							linkUrl: this.getDocumentUrl(job),
							linkText: job.title,
							text: 'Job completed:'
						};

						this.notificationService.showNotificationLink(notif);

						this.buttonReference.ripple.launch({
							centered: true
						});
					})
			);

			// Changed to In Progress
			this._subscriptions.add(
				this.hasJobsInProgress$
					.pipe(
						startWith(null as boolean),
						pairwise(),
						map(([oldStatus, newStatus]) => {
							if (oldStatus === null) {
								return false;
							}

							return !!newStatus && !oldStatus;
						}),
						filter(Boolean)
					)
					.subscribe(() => {
						this.buttonReference.ripple.launch({
							centered: true
						});
					})
			);

			// Jobs initialized
			this._subscriptions.add(
				this.jobsList$
					.pipe(
						map(jobs =>
							jobs.filter(
								job =>
									job.status !== 'Complete' && job.status !== 'Failed' && job.status !== 'Cancelled'
							)
						),
						startWith(null as JobListItemDto[]),
						pairwise(),
						map(([oldJobs, currentJobs]) => {
							if (!oldJobs) {
								return false;
							}

							return (
								currentJobs.filter(job => oldJobs.filter(oldJob => oldJob.id === job.id).length === 0)
									.length > 0
							);
						}),
						filter(Boolean)
					)
					.subscribe(() => this.buttonTriggerReference.openMenu())
			);
		}
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}

	canClick(job: JobListItemDto): boolean {
		return (
			job.status === 'Complete' &&
			((job.type === 'BriefPdf' && !!job.targetEntityId) || job.type === 'InvoicePdf')
		);
	}

	onJobClick(job: JobListItemDto) {
		if (!this.canClick(job)) {
			return;
		}

		if (job.type === 'BriefPdf') {
			const url = this.getDocumentUrl(job);

			if (!url) {
				return;
			}

			this.router.navigateByUrl(url);
		} else if (job.type === 'InvoicePdf') {
			this.download(job.id);
		}
	}

	getDocumentUrl(job: JobListItemDto) {
		return !job?.targetEntityId
			? null
			: !job.associatedMatter
			? `/documents?pageIndexForId=${job.targetEntityId}`
			: `/matters/${job.associatedMatter.id}/documents(preview:document/${job.targetEntityId})?pageIndexForId=${job.targetEntityId}&sortBy=lastModifiedDate&sortDirection=desc`;
	}

	progressBarColour(job: JobListItemDto) {
		if (!job) return 'primary';
		return job.errors?.length > 0 ? 'warn' : job.progressPercentage >= 100 ? 'primary' : 'accent';
	}

	humanizeTimestamp(timestamp: moment.MomentInput): string {
		return moment(timestamp).fromNow();
	}

	onMenuOpened() {
		this.menuOpened.emit();

		this.updateLastChecked();
	}

	onMenuClosed() {
		this.updateLastChecked();

		this.buttonReference._elementRef.nativeElement.blur();
	}

	private updateLastChecked() {
		if (this._lastHasJobsDone) {
			this._userLastChecked$.next(moment());
			this._subscriptions.add(this.currentUserService.updateLastCheckedJobs().subscribe());
		}
	}

	private download(jobId: string): void {
		this._subscriptions.add(
			this.billsService
				.getFileSizeFromJob(jobId)
				.pipe(
					switchMap(sizeDto => {
						const data: IDownloadDialogData = {
							documentIds: [jobId],
							downloadFn: ((id: string) => this.billsService.downloadFromJob(id)).bind(this),
							totalSize: sizeDto.fileSize
						};

						return this.dialog.open(DownloadProgressDialogComponent, { data }).afterClosed();
					}),
					catchError(error => this.notificationService.showErrors('Error Downloading File', error)),
					filter(Boolean)
				)
				.subscribe()
		);
	}
}
