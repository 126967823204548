import { Injectable } from '@angular/core';

import { interval, of } from 'rxjs';
import { catchError, debounce, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { IAppState } from '../../../app.state';
import { ReferralReasonsService } from '@common/services/settings/referralreasons.service';
import { ReferralReasonListActions, loading } from './referral-reason-list.actions';

@Injectable()
export class ReferralReasonListEffects {
	loadReferralReasons$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				ReferralReasonListActions.Load,
				ReferralReasonListActions.SetPageIndex,
				ReferralReasonListActions.SetPageSize,
				ReferralReasonListActions.SetSortBy,
				ReferralReasonListActions.SetSortDirection,
				ReferralReasonListActions.SetFilters
			),
			tap(action => this.store.dispatch(loading())),
			withLatestFrom(this.store),
			debounce(() => interval(200)),
			switchMap(([action, state]) => {
				return this.referralReasonService.getReferralReasons(state.referralReasonList.request).pipe(
					map(response => {
						return { type: ReferralReasonListActions.LoadSuccess, response };
					}),
					catchError(error => of({ type: ReferralReasonListActions.LoadFailed, error }))
				);
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<IAppState>,
		private referralReasonService: ReferralReasonsService
	) {}
}
