import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EntityReference } from '@common/models/Common/EntityReference';

@Component({
	styleUrls: ['delete-header-footer-template-dialog.component.scss'],
	templateUrl: 'delete-header-footer-template-dialog.component.html'
})
export class DeleteHeaderFooterTemplateDialogComponent {
	constructor(@Inject(MAT_DIALOG_DATA) public data: IDeleteHeaderFooterTemplateDialogData) {}
}

export interface IDeleteHeaderFooterTemplateDialogData {
	affectedDocuments: EntityReference[];
}
