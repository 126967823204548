import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { PersonContactViewDto } from '@common/models/Contacts/Item/PersonContactViewDto';
import { PricedMarketplaceListingViewDto } from '@common/models/Infrastructure/StripeIntegration/Models/PricedMarketplaceListingViewDto';
import { ServiceRequestCreateDto } from '@common/models/Marketplace/ServiceRequests/Item/ServiceRequestCreateDto';
import { UserViewDto } from '@common/models/Users/Item/UserViewDto';
import { NotificationService } from '@common/notification';
import { ContactsService } from '@common/services/contacts.service';
import { MarketplaceService } from '@common/services/settings/marketplace.service';
import { ICurrentUserData } from '@common/state/models/current-user-data';
import { CustomValidators } from '@common/validation/custom.validators';
import { Store } from '@ngrx/store';

@Component({
	selector: 'app-service-request-dialog',
	templateUrl: './service-request-dialog.component.html',
	styleUrls: ['./service-request-dialog.component.scss']
})
export class ServiceRequestDialogComponent implements OnInit, OnDestroy {
	private _subscriptions = new Subscription();

	form: FormGroup;
	currentContact: PersonContactViewDto;
	currentUser: UserViewDto;

	isProcessing: boolean = false;
	isSubmitted: boolean = false;

	get serviceName() {
		return this._data.dto.productName;
	}

	get summary() {
		return this._data.dto.productSummary;
	}

	get submissionPrompt(): string {
		return this._data.dto?.serviceDetails?.submissionPrompt;
	}

	get includeUserMessageBox(): boolean {
		return !!this._data.dto?.serviceDetails?.includeUserMessageBox;
	}

	get submissionCompleteMessage(): string {
		return this._data.dto?.serviceDetails?.submissionCompleteMessage;
	}

	get isRedirect(): boolean {
		return !!this._data?.dto?.serviceDetails?.redirectUrl;
	}

	get isForm() {
		return !!this._data?.dto?.serviceDetails?.isContactFormSubmissionRequired;
	}

	constructor(
		@Inject(MAT_DIALOG_DATA) private _data: IServiceRequestDialogData,
		private _dialogRef: MatDialogRef<ServiceRequestDialogComponent>,
		private _fb: FormBuilder,
		private _notifService: NotificationService,
		private _marketplaceService: MarketplaceService,
		private _contactService: ContactsService,
		private store: Store<{ currentUserData: ICurrentUserData }>
	) {}

	ngOnInit(): void {
		if (this.isForm) {
			this.form = this._fb.group({
				firstName: ['', CustomValidators.requiredWhen(() => this.isForm, 'First Name')],
				lastName: ['', CustomValidators.requiredWhen(() => this.isForm, 'Last Name')],
				email: ['', CustomValidators.requiredWhen(() => this.isForm, 'Email')],
				phone: ['', CustomValidators.requiredWhen(() => this.isForm, 'Phone')],
				message: ['', CustomValidators.requiredWhen(() => this.isForm && this.includeUserMessageBox, 'Message')]
			});

			this._subscriptions.add(
				this.store
					.select(state => state.currentUserData)
					.pipe(
						switchMap(currentUserData => {
							this.currentUser = currentUserData.currentUser;
							return this._contactService.getContact(this.currentUser.contact.id);
						})
					)
					.subscribe((person: PersonContactViewDto) => {
						this.currentContact = person;
						this.form.controls.firstName.setValue(this.currentContact?.firstName);
						this.form.controls.lastName.setValue(this.currentContact?.lastName);
						if (this.currentUser.phones.length > 0) {
							this.form.controls.phone.setValue(this.currentUser.phones[0]);
						}
						this.form.controls.email.setValue(this.currentUser.primaryEmail);
					})
			);
		} else {
			this.onRedirect();
		}
	}

	onSubmitForm(): void {
		this.form.disable();
		this.isProcessing = true;

		const dto: ServiceRequestCreateDto = {
			marketplaceServiceId: this._data.dto.id,
			firstName: this.form.controls.firstName.value,
			lastName: this.form.controls.lastName.value,
			email: this.form.controls.email.value,
			phone: this.form.controls.phone.value,
			message: this.form.controls.message?.value
		};

		this.submitServiceRequest(dto);
	}

	onRedirect(): void {
		this.isProcessing = true;

		const dto: ServiceRequestCreateDto = {
			marketplaceServiceId: this._data.dto.id,
			firstName: null,
			lastName: null,
			email: null,
			phone: null,
			message: null
		};

		this.submitServiceRequest(dto);
	}

	submitServiceRequest(dto: ServiceRequestCreateDto) {
		this._subscriptions.add(
			this._marketplaceService.submitServiceRequest(dto).subscribe(
				() => {
					this.isSubmitted = true;
					this.isProcessing = false;
				},
				errors => {
					if (this.isForm) this._notifService.showErrors('Error submitting service request', errors);
					this.form.enable();
					this.isProcessing = false;
				},
				() => {
					this.redirect();
					if (!this.submissionCompleteMessage) this._dialogRef.close();
				}
			)
		);
	}

	private redirect() {
		if (this.isRedirect) {
			var url = this._data.dto.serviceDetails.redirectUrl;
			var scheme = !(url.toLowerCase().startsWith('http:') || url.toLowerCase().startsWith('https:'))
				? 'https://'
				: '';
			url = scheme + url;

			window.open(url, '_blank');
		}
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}
}

export interface IServiceRequestDialogData {
	dto: PricedMarketplaceListingViewDto;
}
