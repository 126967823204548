import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'bullet-button',
	templateUrl: 'bullet-button.component.html',
	styleUrls: ['bullet-button.component.scss']
})
export class BulletButtonComponent {
	@Input() flipArrowOnClick: boolean;
	@Input() arrowState: ArrowDirection = 'right';
	@Input() tooltipMessage: string = '';
	@Input() tooltipPosition: TooltipPosition = 'right';

	// Click Event fired when clicking on the actual bullet element
	@Output() onBulletClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

	// Recently clicked, disable mouse events and tooltips for a short duration
	clickCooldown: boolean;

	flipArrow() {
		this.arrowState = this.arrowState == 'right' ? 'left' : 'right';
	}

	bulletClicked(e: MouseEvent) {
		this.clickCooldown = true;
		setTimeout(() => {
			this.clickCooldown = false;
		}, 250);

		if (this.flipArrowOnClick) {
			this.flipArrow();
		}
		this.onBulletClick.emit(e);
	}
}

type ArrowDirection = 'left' | 'right';

type TooltipPosition = 'left' | 'right' | 'above' | 'below' | 'before' | 'after';
