import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { RateLimitDialogComponent } from '@common/notification/rate.limit-dialog.component';

@Injectable()
export class RateLimitInterceptor implements HttpInterceptor {
	private rateLimitHit: boolean;

	constructor(private dialog: MatDialog) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			catchError(err => {
				if (err instanceof HttpErrorResponse && err.status === 429) {
					if (!this.rateLimitHit) {
						this.rateLimitHit = true;
						const dialogRef = this.dialog.open(RateLimitDialogComponent, { data: err.error });
					}
					return EMPTY;
				}
				return throwError(() => err);
			})
		);
	}
}
