<button *ngIf="isGlobalContext" class="button-fade" mat-raised-button [matMenuTriggerFor]="menu">
	<mat-icon *ngIf="matIconName && !iconColor" class="preceding-icon" color="accent">{{ matIconName }}</mat-icon>
	<mat-icon *ngIf="matIconName && !!iconColor" class="preceding-icon" [ngStyle]="{ color: iconColor }">{{ matIconName }}</mat-icon>
	{{ menuLabel }}
	<mat-icon>keyboard_arrow_down</mat-icon>
</button>
<button
	*ngIf="!isGlobalContext"
	class="action-button"
	[class.action-button--margins]="!!includeMargins"
	[class.action-button--always-show]="!!alwaysShow"
	mat-raised-button
	[matMenuTriggerFor]="menu"
>
	<mat-icon *ngIf="matIconName && !iconColor" class="preceding-icon" color="accent">{{ matIconName }}</mat-icon>
	<mat-icon *ngIf="matIconName && !!iconColor" class="preceding-icon" [ngStyle]="{ color: iconColor }">{{ matIconName }}</mat-icon>
	{{ menuLabel }}
	<mat-icon>keyboard_arrow_down</mat-icon>
</button>
<mat-menu #menu="matMenu" [overlapTrigger]="false">
	<ng-content></ng-content>
	<mat-divider></mat-divider>
	<button *ngIf="!hideFooter" mat-menu-item [disabled]="true">
		<span class="info-text">{{ rowSelectionLabel }}</span>
	</button>
</mat-menu>
