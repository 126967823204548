// This file has been generated from DomainError.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.Domain.Validation.DomainError
export class DomainError {

	// Code
	code: number;
	// Message
	message: string;

	constructor() {

	}
}