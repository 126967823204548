<mat-card>
	<section class="page-path"><a [routerLink]="['..']">System</a> / Security Roles</section>
	<h1>Security Roles</h1>
	<div class="description">
		Security roles are used to restict areas and functionality of {{ siteName }} for different teams or groups. If no security roles are created, everyone
		will have full access.
	</div>
	<button mat-button color="primary" title="Create Security Roles" throttleButton (throttledClick)="create()">
		<mat-icon>group_add</mat-icon>
		<span class="create-new">Create New</span>
	</button>
	<mat-table [dataSource]="datasource" matSort matSortActive="name" matSortDirection="asc">
		<ng-container matColumnDef="name">
			<mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<a class="field-name" throttleButton (throttledClick)="edit(row)">{{ row?.name }}</a>
			</mat-cell>
		</ng-container>
		<ng-container matColumnDef="members">
			<mat-header-cell *matHeaderCellDef>Members</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<a class="field-name" throttleButton (throttledClick)="editMembers(row)"
					>{{ row?.members?.length }} member{{ row?.members?.length === 1 ? '' : 's' }}</a
				>
			</mat-cell>
		</ng-container>
		<ng-container matColumnDef="practiceAreas">
			<mat-header-cell *matHeaderCellDef>Practice Areas</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<a *ngFor="let item of row.practiceAreas" [routerLink]="['..', 'practice-areas', item.id]">{{ item.name }}</a>
			</mat-cell>
		</ng-container>
		<ng-container *ngFor="let key of policyKeys; let i = index" matColumnDef="{{ key }}">
			<mat-header-cell *matHeaderCellDef class="column-policy">{{ policyDisplayName(key) }}</mat-header-cell>
			<mat-cell *matCellDef="let row" class="column-policy">
				<mat-icon *ngIf="hasPolicy(row, key); else falseBlock">check</mat-icon>
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="actions">
			<mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<button mat-icon-button [matMenuTriggerFor]="menu" title="Actions">
					<mat-icon>more_vert</mat-icon>
				</button>
				<mat-menu #menu="matMenu">
					<button mat-menu-item throttleButton (throttledClick)="edit(row)">
						<mat-icon>edit</mat-icon>
						<span>Edit Details</span>
					</button>
					<button mat-menu-item throttleButton (throttledClick)="editMembers(row)">
						<mat-icon>person</mat-icon>
						<span>Add/Remove Users</span>
					</button>
					<button mat-menu-item [routerLink]="['/system', 'practice-areas']">
						<mat-icon>view_list</mat-icon>
						<span>Edit Practice Area Access</span>
					</button>
					<button mat-menu-item throttleButton (throttledClick)="delete(row)">
						<mat-icon>delete</mat-icon>
						<span>Delete</span>
					</button>
				</mat-menu>
			</mat-cell>
		</ng-container>

		<mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
		<mat-row
			*matRowDef="let row; columns: displayedColumns"
			throttleButton
			(throttledClick)="selectRow(row, $event)"
			[ngClass]="{ highlight: row.isHighlighted }"
		></mat-row>
	</mat-table>
	<mat-paginator
		[length]="datasource?.response?.totalRecords"
		[pageIndex]="datasource?.response?.pageIndex"
		[pageSize]="pageSize"
		[pageSizeOptions]="pageSizeOptions"
	></mat-paginator>
</mat-card>

<ng-template #falseBlock><div>&nbsp;</div></ng-template>
