import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { of as ObservableOf, Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { AppcuesService } from '@common/appcues/appcues.service';
import { EntityReference } from '@common/models/Common/EntityReference';
import { ListResponse } from '@common/models/Generic/ListResponse';
import { PracticeAreaViewDto } from '@common/models/Settings/PracticeAreas/Item/PracticeAreaViewDto';
import { StageDto } from '@common/models/Settings/PracticeAreas/Item/StageDto';
import { Stage } from '@common/models/Settings/PracticeAreas/Stage';
import { SecurityRoleListItemDto } from '@common/models/Settings/SecurityRoles/List/SecurityRoleListItemDto';
import { DomainError } from '@common/models/Validation/DomainError';
import { NotificationService } from '@common/notification';
import { PracticeAreasService } from '@common/services/settings/practiceareas.service';
import { SecurityRolesService } from '@common/services/settings/securityroles.service';
import { remove } from 'lodash';

@Component({
	selector: 'practice-areas',
	styleUrls: ['./practice-areas-item.component.scss'],
	templateUrl: './practice-areas-item.component.html'
})
export class PracticeAreasComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('practiceAreaInput', { static: true })
	practiceAreaInput: ElementRef;
	@ViewChild(MatTable, { static: true }) table: MatTable<PracticeAreaViewDto>;
	editId: string;
	practiceAreaItem = new PracticeAreaViewDto();
	securityRoles: SecurityRoleListItemDto[] = [];
	displayedRoleColumns: string[] = ['Name', 'Reader' /*, 'Contributor'*/];
	displayedStagesColumns: string[] = ['Name', 'Description', 'MatterClosed', 'Delete'];
	protected displayedColumns: string[] = ['name', 'description'];
	private subscriptions: Subscription = new Subscription();

	get practiceAreaItemEnabled(): boolean {
		return !this.practiceAreaItem?.isDisabled;
	}

	set practiceAreaItemEnabled(value: boolean) {
		if (!!this.practiceAreaItem) {
			this.practiceAreaItem.isDisabled = !value;
		}
	}

	drop(event: CdkDragDrop<string[]>) {
		const stages = Object.assign([], this.practiceAreaItem.stages);
		moveItemInArray(stages, event.previousIndex, event.currentIndex);
		this.practiceAreaItem.stages = stages; // required to update the listing with new order
	}
		
	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private notifService: NotificationService,
		private paService: PracticeAreasService,
		private roleService: SecurityRolesService,
		private appcuesService: AppcuesService
	) {}

	ngOnInit() {
		this.subscriptions.add(
			this.route.params
				.pipe(
					switchMap((routerParams: Params) => {
						this.editId = routerParams.id && routerParams.id !== 'create' ? routerParams.id : null;
						return this.editId
							? this.paService.getPracticeArea(this.editId)
							: ObservableOf(new PracticeAreaViewDto());
					})
				)
				.subscribe((data: PracticeAreaViewDto) => {
					this.practiceAreaItem = data;
				})
		);

		this.subscriptions.add(
			this.roleService.getSecurityRoleList().subscribe((data: ListResponse<SecurityRoleListItemDto>) => {
				this.securityRoles = data.records;
			})
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	ngAfterViewInit() {
		if (this.practiceAreaInput) {
			setTimeout(() => this.practiceAreaInput.nativeElement.focus(), 0);
		}
	}

	addNewStage() {
		const stages = Object.assign([], this.practiceAreaItem.stages);
		stages.push(new Stage());
		this.practiceAreaItem.stages = stages;
	}

	deleteStage(stageIndex: number) {
		const stageName = this.practiceAreaItem.stages[stageIndex].name;
		const stageId = this.practiceAreaItem.stages[stageIndex].id;

		if (!stageId) {
			this.practiceAreaItem.stages.splice(stageIndex, 1);
			this.table.renderRows();
			return;
		}

		if (!this.practiceAreaItem.stages[stageIndex].usedByMatters) {
			this.notifService
				.showConfirmation('Delete Stage', `"${stageName}" will be deleted on click of Save`, 'Ok', 'Cancel')
				.pipe(
					filter(Boolean),
					switchMap(() => {
						return this.practiceAreaItem.stages.splice(stageIndex, 1);
					})
				)
				.subscribe(
					() => {
						this.table.renderRows();
						this.notifService.showNotification(`Stage listed for deletion: ${stageName}`);
					},
					error => {
						const errorText = 'Error deleting Stage';
						if (error.length > 0) {
							this.notifService.showError(errorText, error[0].message);
						} else this.notifService.showError(errorText, error);
					}
				);
		}
	}

	onSave() {
		// Validate the DTO - remove all stages with empty name & description
		remove(this.practiceAreaItem.stages, s => !s.name);
		this.appcuesService.trackEvent('PracticeAreaCreated');

		// remove all invalid roles (a way to clean up old or out of sync roles)
		remove(this.practiceAreaItem.readers, x => !this.securityRoles.find((s, i) => s.name === x));
		remove(this.practiceAreaItem.contributors, x => !this.securityRoles.find((s, i) => s.name === x));

		// Send the 'save' request to the server
		const action = !this.editId
			? this.paService.createPracticeArea(this.practiceAreaItem)
			: this.paService.updatePracticeArea(this.editId, this.practiceAreaItem);
		action.subscribe(
			(ref: EntityReference) => {
				this.notifService.showNotification(`Practice Area '${ref.name}' was saved`);
				this.router.navigate(['..'], { relativeTo: this.route });
			},
			(errs: DomainError[]) => this.notifService.showErrors(`Error saving Practice Area`, errs)
		);
	}

	onDelete() {
		this.paService.deletePracticeArea(this.editId).subscribe(
			() => {
				this.notifService.showNotification(`Practice Area '${this.practiceAreaItem.name}' was deleted`);
				this.router.navigate(['..'], { relativeTo: this.route });
			},
			(errs: DomainError[]) => this.notifService.showErrors(`Error deleting Practice Area`, errs)
		);
	}

	cancelEditing() {
		this.router.navigate(['..'], { relativeTo: this.route });
	}

	getTooltip(stage: StageDto) {
		return stage.usedByMatters > 0
			? `Unable to delete. Stage is used by ${stage.usedByMatters} matters`
			: 'Remove Stage';
	}

	get readersAsList(): string {
		const readers = this.practiceAreaItem?.readers ?? [];
		return readers.join(', ') || 'All users';
	}
	get readersAsListPlural(): string {
		return this.practiceAreaItem?.readers?.length === 1 ? 'has' : 'have';
	}

	isReader(row: SecurityRoleListItemDto): boolean {
		return this.practiceAreaItem?.readers && this.practiceAreaItem.readers.indexOf(row.name) >= 0;
	}

	isContributor(row: SecurityRoleListItemDto): boolean {
		return this.practiceAreaItem?.contributors && this.practiceAreaItem.contributors.indexOf(row.name) >= 0;
	}

	onReaderChange(event: MatCheckboxChange, row: SecurityRoleListItemDto) {
		if (!!this.practiceAreaItem) {
			this.practiceAreaItem.readers = this.practiceAreaItem.readers || [];
			if (!event.checked) {
				remove(this.practiceAreaItem.readers, x => x === row.name);
			} else if (!this.isReader(row)) {
				this.practiceAreaItem.readers.push(row.name);
			}
		}
	}

	onContributorChange(event: MatCheckboxChange, row: SecurityRoleListItemDto) {
		if (!!this.practiceAreaItem) {
			this.practiceAreaItem.contributors = this.practiceAreaItem.contributors || [];
			if (!event.checked) {
				remove(this.practiceAreaItem.contributors, x => x === row.name);
				this.onReaderChange(event, row);
			} else if (!this.isContributor(row)) {
				this.practiceAreaItem.contributors.push(row.name);
				this.onReaderChange(event, row);
			}
		}
	}
}
