import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';
import { TaskListRequest } from '@common/models/Tasks/List/TaskListRequest';
import { createReducer, on } from '@ngrx/store';
import { get } from 'lodash-es';

import { CommonReducers } from '../../common-reducers';
import {
	init,
	insertRecords,
	loadFailed,
	loading,
	loadSuccess,
	removeRecords,
	selectRecords,
	setFilterProp,
	setFilters,
	setPageIndex,
	setPageIndexForId,
	setPageSize,
	setSortBy,
	setSortDirection,
	updateRecords
} from './task-list.actions';
import { initialState } from './task-list.state';

export const taskListReducer = createReducer(
	initialState,
	on(init, state => CommonReducers.init(state, initialState)),
	on(loading, state => CommonReducers.loading(state)),
	on(loadSuccess, (state, { response }) => CommonReducers.loadSuccess(state, response)),
	on(loadFailed, (state, { error }) => CommonReducers.loadFailed(state, error)),

	on(setFilters, (state, { filter }) => CommonReducers.setFilters(state, filter)),
	on(setFilterProp, (state, { prop, value }) => CommonReducers.setFilterProp(state, prop, value)),

	on(setPageSize, (state, { pageSize }) => CommonReducers.setPageSize(state, pageSize)),
	on(setPageIndex, (state, { pageIndex }) => CommonReducers.setPageIndex(state, pageIndex)),
	on(setPageIndexForId, (state, { id }) => CommonReducers.setPageIndexForId(state, id)),

	on(setSortBy, (state, { sortBy }) => CommonReducers.setSortBy(state, sortBy)),
	on(setSortDirection, (state, { sortDirection }) => CommonReducers.setSortDirection(state, sortDirection)),

	on(insertRecords, (state, { response }) => {
		if (!response) return state;
		response = response instanceof Array ? response : [response];

		// Check if the mutation is unrelated to the current filtered list data.
		if (response.some((x: MutationResponseDto) => notRelatedToCurrentRequest(state.request, x))) {
			return state;
		}

		return CommonReducers.processMutations(state, response);
	}),
	on(updateRecords, (state, { response }) => CommonReducers.processMutations(state, response)),
	on(removeRecords, (state, { response }) => CommonReducers.processMutations(state, response)),
	on(selectRecords, (state, { row, selectionType }) => CommonReducers.selectRecords(state, row, selectionType))
);

function notRelatedToCurrentRequest(request: Partial<TaskListRequest>, mutation: MutationResponseDto): Boolean {
	if (mutation?.mutationType !== 'Create' || !request) return false;

	// Referrals child task list
	if (!!request.parentReferralTaskId && get(mutation.values, 'Type') !== 'Referred') {
		return true;
	}
	// Matter Task List
	if (!!request.associatedMatterId && get(mutation.values, 'AssociatedMatter')?.id !== request.associatedMatterId) {
		return true;
	}
	// Conatact Task List
	if (
		!!request.associatedContactId &&
		get(mutation.values, 'AssociatedContact')?.id !== request.associatedContactId
	) {
		return true;
	}
	return false;
}
