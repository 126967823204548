import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { EntityReference } from '@common/models/Common/EntityReference';
import { ListResponse } from '@common/models/Generic/ListResponse';
import { CustomFieldGroupCreateUpdateDto } from '@common/models/Settings/CustomFields/Item/CustomFieldGroupCreateUpdateDto';
import { GroupFieldsDto } from '@common/models/Settings/CustomFields/Item/GroupFieldsDto';
import { CustomFieldGroupListItemDto } from '@common/models/Settings/CustomFields/List/CustomFieldGroupListItemDto';
import { CustomFieldGroupListRequest } from '@common/models/Settings/CustomFields/List/CustomFieldGroupListRequest';
import { CustomFieldGroupLookupDto } from '@common/models/Settings/CustomFields/Lookup/CustomFieldGroupLookupDto';
import { CustomFieldGroupLookupRequest } from '@common/models/Settings/CustomFields/Lookup/CustomFieldGroupLookupRequest';
import { PracticeAreaFieldsDto } from '@common/models/Settings/PracticeAreas/Item/PracticeAreaFieldsDto';
import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';

@Injectable({
	providedIn: 'root'
})
export class CustomFieldsGroupService extends GenericHttpService {
	constructor(httpClient: HttpClient, settingsResolver: SettingsResolverService) {
		super(httpClient, settingsResolver.getAppServerUrl(), 'api/v1/settings/CustomFields', 'Custom Field Group');
	}

	createCustomFieldGroup(dto: CustomFieldGroupCreateUpdateDto): Observable<MutationResponseDto> {
		return this.post<CustomFieldGroupCreateUpdateDto, MutationResponseDto>(`createCustomFieldGroup`, dto);
	}

	addGroupToFields(dto: GroupFieldsDto): Observable<MutationResponseDto> {
		return this.post<GroupFieldsDto, MutationResponseDto>(`addGroupToFields`, dto);
	}

	removeGroupFromFields(dto: GroupFieldsDto): Observable<MutationResponseDto> {
		return this.post<GroupFieldsDto, MutationResponseDto>(`removeGroupToFields`, dto);
	}

	moveFieldsToGroup(dto: GroupFieldsDto): Observable<MutationResponseDto> {
		return this.post<GroupFieldsDto, MutationResponseDto>(`moveFieldsToGroup`, dto);
	}

	updateCustomFieldGroup(id: string, dto: CustomFieldGroupCreateUpdateDto): Observable<MutationResponseDto> {
		return this.put(`updateCustomFieldGroup/${id}`, dto);
	}

	changeGroupOrderNumber(sourceId: string, destinationId: string): Observable<MutationResponseDto> {
		return this.post<string, MutationResponseDto>(`changeGroupOrderNumber/${sourceId}/${destinationId}`);
	}

	getCustomFieldGroupList(
		request?: Partial<CustomFieldGroupListRequest>
	): Observable<ListResponse<CustomFieldGroupListItemDto>> {
		return this.postGetList<CustomFieldGroupListRequest, CustomFieldGroupListItemDto>(
			`getCustomFieldGroupList`,
			request
		);
	}

	deleteCustomFieldGroup(id: string): Observable<MutationResponseDto> {
		return this.delete<MutationResponseDto>(`deleteCustomFieldGroup/${id}`);
	}

	addPracticeAreaToFields(dto: PracticeAreaFieldsDto): Observable<MutationResponseDto> {
		return this.post<PracticeAreaFieldsDto, MutationResponseDto>(`addPracticeAreaToFields`, dto);
	}

	removePracticeAreaFromFields(dto: PracticeAreaFieldsDto): Observable<MutationResponseDto> {
		return this.post<PracticeAreaFieldsDto, MutationResponseDto>(`removePracticeAreaFromFields`, dto);
	}

	lookupCustomFieldGroupList(request: CustomFieldGroupLookupRequest): Observable<CustomFieldGroupLookupDto[]> {
		return this.getArray<CustomFieldGroupLookupRequest, CustomFieldGroupLookupDto>(`groups/lookup`, request);
	}

	lookupCustomFieldGroup(id: string): Observable<CustomFieldGroupLookupDto> {
		return this.getItem<void, CustomFieldGroupLookupDto>(`groups/${id}/lookup`);
	}
}
