// This file has been generated from CustomizeUserListColumnsFieldsDto.cs
// Do not modify this file directly!!
// tslint:disable

import { CustomizableListType } from '../Common/CustomizableListType';

// LawMaster.Amalthea.Domain.Users.Item.CustomizeUserListColumnsFieldsDto
export class CustomizeUserListColumnsFieldsDto {

	// ListType
	listType: keyof typeof CustomizableListType;
	// Columns
	columns: string[];

	constructor() {

	}
}