import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSelectionList } from '@angular/material/list';

import { BehaviorSubject, Subscription } from 'rxjs';
import { delay, filter, startWith } from 'rxjs/operators';

import { EntityReference } from '@common/models/Common/EntityReference';
import { isEmpty } from 'lodash-es';

import { FilterBaseComponent } from './filter-base.component';
import { FilterButtonComponent } from './filter-button.component';
import { FilterChangeProperties } from './filter-change-properties';

@Component({
	selector: 'filter-multi-entity-reference-list',
	styleUrls: ['./filter-base.component.scss'],
	template: `
		<filter-button
			[root]="root"
			[name]="name"
			[label]="label"
			[showTitleAsPrefix]="showTitleAsPrefix"
			[matIconName]="matIconName"
			[matIconColor]="matIconColor"
		>
			<mat-selection-list>
				<mat-list-option
					*ngFor="let item of items"
					[value]="item.id"
					[disabled]="!!hitsUpperBound && !isSelected(item.id)"
				>
					{{ item.name }}
				</mat-list-option>
			</mat-selection-list>
			<div class="action-buttons">
				<span throttleButton (throttledClick)="clear()">Clear</span>
			</div>
		</filter-button>
	`
})
export class FilterMultiEntityReferenceListComponent
	extends FilterBaseComponent
	implements OnInit, AfterViewInit, OnDestroy
{
	@Input()
	selectedMax: number;

	get hitsUpperBound() {
		return !!this.selectedMax && (this.list.selectedOptions?.selected?.length ?? 0) >= this.selectedMax;
	}

	@ViewChild(MatSelectionList, { static: true }) list: MatSelectionList;
	@ViewChild(FilterButtonComponent, { static: true }) btn: FilterButtonComponent;
	@Input()
	get items(): EntityReference[] {
		return this._items.getValue();
	}
	set items(value: EntityReference[]) {
		this._items.next(value);
	}
	protected subscriptions: Subscription = new Subscription();
	private _items: BehaviorSubject<EntityReference[]> = new BehaviorSubject<EntityReference[]>(null);

	@Input()
	matIconName: string;

	@Input()
	matIconColor: string;

	ngOnInit() {
		this.subscriptions.add(
			this.root.filterChange
				.pipe(
					startWith(new FilterChangeProperties<any>(this.root.filter)), // Fire the 1st event with the current value
					delay(0), // Otherwise get an error on changing the state after it's been checked
					filter(f => !!f.filter)
				)
				.subscribe(() => this.updateLabel())
		);

		// if we use the AsyncPipe for items, it doesn't set the label on page load for queryParams
		// so wait until it populates the data and then update the label
		this.subscriptions.add(this._items.subscribe(next => this.updateLabel()));
	}

	ngAfterViewInit() {
		this.subscriptions.add(this.list.selectionChange.subscribe(() => this.applyFilter()));
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	applyFilter() {
		const selected: EntityReference[] = this.list.selectedOptions.selected
			.map(option => this.items.find(item => option.value === item.id))
			.filter(Boolean);
		this.btn.entitiesSelected(selected);
	}

	isSelected(id: string) {
		return !!this.list?.selectedOptions?.selected?.filter(option => option.value === id)?.length;
	}

	clear() {
		this.list.deselectAll();
		this.applyFilter();
		this.btn.isMenuOpen = false;
	}

	protected updateLabel(): void {
		const selectedIds: string[] = this.root?.form?.get(this.name)?.value ?? [];

		if (!isEmpty(this.items) && !isEmpty(this.list?.options)) {
			this.list.options.forEach(option => option._setSelected(!!selectedIds.find(id => id === option.value)));
		}

		const selectedItems: EntityReference[] = this.items
			? this.items.filter(p => !!selectedIds.find(selected => selected === p.id))
			: [];

		this.btn.entitiesSelected(selectedItems);
	}
}
