import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

import { Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { ILookupReference } from '@common/components/lookups/base-lookup.component';
import { ContactLookupComponent } from '@common/components/lookups/contact-lookup.component';
import { AccessTokenStatus } from '@common/models/Settings/AccessTokens/Common/AccessTokenStatus';
import { UserViewDto } from '@common/models/Users/Item/UserViewDto';
import { NotificationService } from '@common/notification';
import { ConfirmationDialogComponent, NotificationIcon } from '@common/notification/confirmation-dialog.component';
import { DocumentAccessTokensService } from '@common/services/settings/documentaccesstokens.service';
import { GeneralSettingsService } from '@common/services/settings/generalsettings.service';
import { ICurrentUserData } from '@common/state/models/current-user-data';
import { CustomValidators } from '@common/validation/custom.validators';
import { Store } from '@ngrx/store';
import { detect } from 'detect-browser';
import * as moment from 'moment-timezone';
import { Moment } from 'moment-timezone';

import { AppConfig } from 'app/app.config';
import { AppBrandingService } from 'app/services/app-branding.service';

@Component({
	selector: 'document-access-dialog',
	styleUrls: ['./document-access-dialog.component.scss'],
	templateUrl: './document-access-dialog.component.html'
})
export class DocumentAccessDialogComponent implements OnInit, OnDestroy {
	@ViewChild('contactLookup', { read: ContactLookupComponent })
	private _contactLookup: ContactLookupComponent;

	private _subscriptions: Subscription = new Subscription();
	private _dataSource: MatTableDataSource<DocumentAccessTokenRow>;

	private _form: FormGroup;

	private _newIds: string[] = [];

	get canSaveForm(): boolean {
		this._form.updateValueAndValidity();
		return this._form.valid;
	}

	get dataSource(): MatTableDataSource<DocumentAccessTokenRow> {
		return this._dataSource;
	}

	constructor(
		private store: Store<{ currentUserData: ICurrentUserData }>,
		@Inject(MAT_DIALOG_DATA) public config: Partial<DocumentAccessDialogComponentConfig>,
		private accessTokensService: DocumentAccessTokensService,
		private fb: FormBuilder,
		private clipboard: Clipboard,
		private dialog: MatDialog,
		private notificationService: NotificationService,
		private generalSettingsService: GeneralSettingsService
	) {
		this._dataSource = new MatTableDataSource([]);
	}

	ngOnInit(): void {
		this._form = this.fb.group({
			name: [null, CustomValidators.requiredWhen(() => !this._form?.controls?.contactId?.value, 'Contact')],
			contactId: [null, CustomValidators.requiredWhen(() => !this._form?.controls?.name?.value, 'Contact')],
			email: [null, CustomValidators.optionalEmail()],
			expiry: [
				null,
				Validators.compose([
					CustomValidators.required('Expiry'),
					CustomValidators.afterDate(new Date(Date.now() - 86400000))
				])
			]
		});

		this._subscriptions.add(
			this.generalSettingsService.getShareLinkExpiry().subscribe(dto => {
				this._form.patchValue({ expiry: moment().add(dto.defaultExpiryInDays, 'days') });
			})
		);

		this.refresh();
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}

	onContactSelected(contact: ILookupReference) {
		if (!!this._contactLookup?.selectedValue?.email) {
			this._form.controls.email.setValue(this._contactLookup.selectedValue.email);
		}

		this._form.controls.contactId.setValue(contact.id);
		this._form.controls.contactId.updateValueAndValidity();
		this._form.controls.name.updateValueAndValidity();
	}

	refresh(): void {
		this._subscriptions.add(
			this.accessTokensService
				.getAccessTokenList({
					pageIndex: 0,
					pageSize: Number.MAX_VALUE,
					statuses: [AccessTokenStatus.Active || AccessTokenStatus.Expired],
					associatedDocumentId: this.config.documentId
				})
				.subscribe(tokens => {
					const mapped = tokens.records.map(
						dto =>
							({
								id: dto.id,
								name: dto.name,
								contactId: dto.contactId,
								email: dto.email,
								expiry: dto.expiry,
								code: dto.code,
								documentId: dto.documentId
							} as DocumentAccessTokenRow)
					).filter(dto => !!dto.contactId);

					const toKeepValues = !!this._dataSource.data
						? this._dataSource.data.filter(row => mapped.map(dto => dto.id).includes(row.id))
						: [];

					const newValues = mapped.filter(row => !toKeepValues.map(dto => dto.id).includes(row.id));

					const data = newValues.concat(toKeepValues).sort((left, right) => {
						if (this._newIds.includes(left.id)) {
							return -1;
						} else if (this._newIds.includes(right.id)) {
							return 1;
						} else if (left.expiry > right.expiry) {
							return 1;
						} else if (left.expiry < right.expiry) {
							return -1;
						} else {
							return 0;
						}
					});

					this._dataSource.data = data;
				})
		);
	}

	getControl(name: string): FormControl {
		return this._form.get(name) as FormControl;
	}

	onSaveFormClick(): void {
		const formValues = this._form.getRawValue();

		this._subscriptions.add(
			this.accessTokensService
				.createAccessToken({
					name: formValues.name,
					email: formValues.email,
					contactId: formValues.contactId,
					expiry: formValues.expiry,
					associatedDocumentId: this.config.documentId
				})
				.subscribe(reference => {
					this._newIds.push(reference.id);

					this._form.controls.name.setValue(null);
					this._form.controls.name.markAsUntouched();

					this._form.controls.email.setValue(null);
					this._form.controls.email.markAsUntouched();

					this._form.controls.expiry.setValue(null);
					this._form.controls.expiry.markAsUntouched();

					this._contactLookup.reset();

					this.refresh();
				})
		);
	}

	onCopyAccessLinkButtonClick(row: DocumentAccessTokenRow): void {
		this.clipboard.copy(this.shareLink(row));
	}

	mailTo(row: DocumentAccessTokenRow) {
		this._subscriptions.add(
			this.store
				.select(state => state?.currentUserData?.currentUser)
				.pipe(
					filter(Boolean),
					map((user: UserViewDto) => ({ user, code: row.code }))
				)
				.subscribe(data => {
					const system = detect();
					let lineEnding: string;
					if (!!system && system.os.toLowerCase().startsWith('windows')) {
						lineEnding = '\r\n';
					} else {
						lineEnding = '\n';
					}

					const contact: string = this.formatName(data.user.contact.name);
					let body: string = `Hi ${row.name},${lineEnding}${lineEnding}You have been granted access to the document '${this.config.documentName}'${lineEnding}${lineEnding}`;
					body += `Share Link:${lineEnding}${this.shareLink(row)}${lineEnding}${lineEnding}`;
					body += `View the document using the share link provided above.${lineEnding}${lineEnding}Regards,${lineEnding}${contact}`;

					const subject: string = `${AppBrandingService.getSiteName()} document access code - ${
						this.config.documentName
					}`;

					const mailTo = `mailto:${row.email ?? ''}?subject=${encodeURIComponent(
						subject
					)}&body=${encodeURIComponent(body)}`;

					window.location.href = mailTo;
				})
		);
	}

	shareLink(row: DocumentAccessTokenRow): string {
		return `${AppConfig.AppServerUrl}/portal/document/${row.documentId}?code=${encodeURIComponent(row.code)}`;
	}

	remove(row: DocumentAccessTokenRow) {
		const data = {
			confirmationText: 'Confirm',
			html: `Revoke guest access for ${row.name}?`,
			title: 'Revoke access?',
			icon: NotificationIcon.None
		};

		if (data) {
			this._subscriptions.add(
				this.dialog
					.open(ConfirmationDialogComponent, { data })
					.afterClosed()
					.pipe(
						filter(Boolean),
						switchMap(() => this.accessTokensService.revokeAccessToken(row.id))
					)
					.subscribe(
						() => {
							this.notificationService.showNotification(`Guest access for ${row.name} has been removed`);
						},
						errors => {
							this.notificationService.showErrors('Error removing guest access', errors);
						},
						() => {
							this.refresh();
						}
					)
			);
		}
	}

	onCopyShareLinkButtonClick(row: DocumentAccessTokenRow): void {
		this.clipboard.copy(this.shareLink(row));
	}

	private formatName(input: string): string {
		if (!input) {
			return input;
		}

		const parts = input.split(',');
		if (parts.length == 1) {
			return parts[0];
		}

		return parts
			.reverse()
			.map(part => part.trim())
			.join(' ');
	}
}

export class DocumentAccessDialogComponentConfig {
	matterNumber: string;
	matterId: string;
	documentId: string;
	documentName: string;
}

export class DocumentAccessTokenRow {
	id: string;
	name: string;
	contactId: string;
	email: string;
	expiry: Moment;
	code: string;
	documentId: string;
}
