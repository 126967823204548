// This file has been generated from TaskTemplateController.cs
// Do not modify this file directly!!
// tslint:disable

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { TrimStringsOnObject } from '@common/utils/string-format';

import { EntityReference } from '../../models/Common/EntityReference';
import { CollectionTaskTemplateCreateUpdateDto } from '../../models/Settings/TaskTemplates/Item/CollectionTaskTemplateCreateUpdateDto';
import { CollectionTaskTemplateViewDto } from '../../models/Settings/TaskTemplates/Item/CollectionTaskTemplateViewDto';
import { DocumentMergeTaskTemplateCreateUpdateDto } from '../../models/Settings/TaskTemplates/Item/DocumentMergeTaskTemplateCreateUpdateDto';
import { DocumentMergeTaskTemplateViewDto } from '../../models/Settings/TaskTemplates/Item/DocumentMergeTaskTemplateViewDto';
import { GenericTaskTemplateCreateUpdateDto } from '../../models/Settings/TaskTemplates/Item/GenericTaskTemplateCreateUpdateDto';
import { GenericTaskTemplateViewDto } from '../../models/Settings/TaskTemplates/Item/GenericTaskTemplateViewDto';
import { TaskTemplateUpdateOrderNumberDto } from '../../models/Settings/TaskTemplates/Item/TaskTemplateUpdateOrderNumberDto';
import { TaskTemplateListRequest } from '../../models/Settings/TaskTemplates/List/TaskTemplateListRequest';

@Injectable({
	providedIn: 'root'
})
export class TaskTemplateService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'api/v1/settings/TaskTemplate', 'Task Template');
	}

	getTaskTemplateDto(id: string): Observable<GenericTaskTemplateViewDto> {
		return this.getItem<void, GenericTaskTemplateViewDto>(`generic/${id}`);
	}

	getDocumentMergeTaskTemplateDto(id: string): Observable<DocumentMergeTaskTemplateViewDto> {
		return this.getItem<void, DocumentMergeTaskTemplateViewDto>(`merge/${id}`);
	}

	getCollectionTaskTemplateDto(id: string): Observable<CollectionTaskTemplateViewDto> {
		return this.getItem<void, CollectionTaskTemplateViewDto>(`collection/${id}`);
	}

	getTaskTemplateList(request: Partial<TaskTemplateListRequest>): Observable<any> {
		return this.getItem<TaskTemplateListRequest, any>('', request);
	}

	createTaskTemplate(dto: GenericTaskTemplateCreateUpdateDto): Observable<EntityReference> {
		return this.post<GenericTaskTemplateCreateUpdateDto, EntityReference>(`generic`, TransformDatesOnObject(GenericTaskTemplateCreateUpdateDto, TrimStringsOnObject(dto)));
	}

	updateTaskTemplate(id: string, dto: GenericTaskTemplateCreateUpdateDto): Observable<EntityReference> {
		return this.put(`generic/${id}`, TransformDatesOnObject(GenericTaskTemplateCreateUpdateDto, TrimStringsOnObject(dto)));
	}

	deleteTaskTemplate(id: string): Observable<any> {
		return this.delete(`generic/${id}`);
	}

	createDocumentMergeTaskTemplate(dto: DocumentMergeTaskTemplateCreateUpdateDto): Observable<EntityReference> {
		return this.post<DocumentMergeTaskTemplateCreateUpdateDto, EntityReference>(`merge`, TransformDatesOnObject(DocumentMergeTaskTemplateCreateUpdateDto, TrimStringsOnObject(dto)));
	}

	updateDocumentMergeTaskTemplate(id: string, dto: DocumentMergeTaskTemplateCreateUpdateDto): Observable<EntityReference> {
		return this.put(`merge/${id}`, TransformDatesOnObject(DocumentMergeTaskTemplateCreateUpdateDto, TrimStringsOnObject(dto)));
	}

	deleteDocumentMergeTaskTemplate(id: string): Observable<any> {
		return this.delete(`merge/${id}`);
	}

	createCollectionTaskTemplate(dto: CollectionTaskTemplateCreateUpdateDto): Observable<EntityReference> {
		return this.post<CollectionTaskTemplateCreateUpdateDto, EntityReference>(`collection`, TransformDatesOnObject(CollectionTaskTemplateCreateUpdateDto, TrimStringsOnObject(dto)));
	}

	updateCollectionTaskTemplate(id: string, dto: CollectionTaskTemplateCreateUpdateDto): Observable<EntityReference> {
		return this.put(`collection/${id}`, TransformDatesOnObject(CollectionTaskTemplateCreateUpdateDto, TrimStringsOnObject(dto)));
	}

	deleteCollectionTaskTemplate(id: string): Observable<any> {
		return this.delete(`collection/${id}`);
	}

	updateTaskTemplateOrdering(dto: TaskTemplateUpdateOrderNumberDto): Observable<any> {
		return this.post<TaskTemplateUpdateOrderNumberDto, any>(`updateTaskTemplateOrdering`, TransformDatesOnObject(TaskTemplateUpdateOrderNumberDto, TrimStringsOnObject(dto)));
	}

}
