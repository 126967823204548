import { Component, OnDestroy, ViewChild } from '@angular/core';
import { NotificationService } from '@common/notification/notification.service';
import { MarketplaceVendorService } from '@common/services/settings/marketplacevendor.service';
import { getFilenameFromHttpHeader } from '@common/utils/fileNameUtil';
import * as FileSaver from 'file-saver';
import { Subscription } from 'rxjs';
import { MarketplaceVendorPackageListComponent } from './marketplace-vendor-package-list.component';

@Component({
	selector: 'marketplace-vendor',
	styleUrls: ['./marketplace-vendor.component.scss'],
	templateUrl: 'marketplace-vendor.component.html'
})
export class MarketplaceVendorComponent implements OnDestroy {
	private subscriptions: Subscription = new Subscription();
	@ViewChild('list')
	list: MarketplaceVendorPackageListComponent;
	disableButtons: boolean;

	constructor(
		private marketplaceVendorService: MarketplaceVendorService,
		private notificationService: NotificationService
	) {}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	onGenerateClicked() {
		this.disableButtons = true;
		this.subscriptions.add(
			this.marketplaceVendorService.generateNewPackage().subscribe(response => {
				FileSaver.saveAs(response.body, getFilenameFromHttpHeader(response.headers.get('content-disposition')));
				this.disableButtons = false;
			})
		);
	}

	onRebuildClicked() {
		this.disableButtons = true;
		this.subscriptions.add(
			this.marketplaceVendorService.rebuildMyPackageList().subscribe(
				response => {
					this.disableButtons = false;
					this.notificationService.showNotification('Your packages have been re-validated and rebuilt.');
					this.list.refreshList();
				},
				errors => {
					const messages = (errors as { message: string }[])?.filter(
						error =>
							error.message.startsWith('Critical: ') || error.message.startsWith('Invalid JSON format')
					);

					if (!!messages?.length) {
						const message = messages
							.map(error => {
								if (error.message.startsWith('Critical:')) {
									return `<li>${error.message.substr(10, error.message.length)}</li>`;
								} else {
									return `<li>${error.message}</li>`;
								}
							})
							.reduce((left, right) => left + right);

						this.disableButtons = false;
						this.notificationService.showError(
							`Failed to build all Packages`,
							`<p>Ran into the following issues:</p><ul>${message}</ul>`
						);
						this.list.refreshList();
					} else {
						this.disableButtons = false;
						this.notificationService.showNotification(
							'Your packages have been re-validated and rebuilt with errors'
						);
						this.list.refreshList();
					}
				}
			)
		);
	}
}
