import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { filter, switchMap } from 'rxjs/operators';

import { AppcuesService } from '@common/appcues/appcues.service';
import { EntityReference } from '@common/models/Common/EntityReference';
import { CreateUserDto } from '@common/models/Users/Item/CreateUserDto';
import { InviteUserDto } from '@common/models/Users/Item/InviteUserDto';
import { UserViewDto } from '@common/models/Users/Item/UserViewDto';
import { UserListItemDto } from '@common/models/Users/List/UserListItemDto';
import { UserListRequest } from '@common/models/Users/List/UserListRequest';
import { NotificationService } from '@common/notification';
import { GeneralSettingsService } from '@common/services/settings/generalsettings.service';
import { UsersService } from '@common/services/settings/users.service';
import { ITenantData } from '@common/state/models/tenant-data';
import { Store } from '@ngrx/store';
import * as moment from 'moment-timezone';

import { FeatureFlags, isFeatureFlagEnabled } from 'app/app.config';
import { AuthService } from 'app/core/auth.service';
import { AppBrandingService } from 'app/services/app-branding.service';
import { GridViewService } from 'app/services/grid-view.service';
import { GenericListComponent } from 'app/shared/generics/generic.list.component';

import { CreateUserComponent } from '../create-user/create-user.component';
import { InviteUserComponent } from '../invite-user/invite-user.component';
import { DeleteUserDialogComponent } from './delete-user-dialog.component';
import { UserListFilterDialogComponent } from './filter-dialog/user-list-filter-dialog.component';
import { UserListFilterComponent } from './filter/user-list-filter.component';

@Component({
	selector: 'user-management-list',
	styleUrls: ['./user-management-list.component.scss'],
	templateUrl: 'user-management-list.component.html'
})
export class UserManagementListComponent
	extends GenericListComponent<UserListItemDto, UserListRequest>
	implements OnInit
{
	@ViewChild(UserListFilterComponent, { static: true })
	filter: UserListFilterComponent;
	request: Partial<UserListRequest> = {
		isEnabled: null,
		search: null
	};
	filterDialog = UserListFilterDialogComponent;

	numberOfLicenses: number;
	featureFlags: typeof FeatureFlags = FeatureFlags;
	ignoreLicensing: boolean;

	constructor(
		dialog: MatDialog,
		router: Router,
		activatedRoute: ActivatedRoute,
		private usersService: UsersService,
		private notificationService: NotificationService,
		private appcuesService: AppcuesService,
		private authService: AuthService,
		gridViewService: GridViewService,
		private settingsService: GeneralSettingsService,
		private appBrandingService: AppBrandingService,
		private store: Store<{ tenantData: ITenantData }>
	) {
		super(dialog, router, activatedRoute, usersService, [], gridViewService);
	}

	get favIcon() {
		return `/assets/icon/${this.appBrandingService.favIcon}`;
	}

	get imageAlt() {
		return this.appBrandingService.imageAlt;
	}

	ngOnInit(): void {
		this.filterComponent = this.filter.root;

		this.subscriptions.add(
			this.subscriptions.add(
				this.settingsService.getTenantStripeInfo().subscribe(info => {
					this.numberOfLicenses = info?.quantity ?? 0;
				})
			)
		);

		this.subscriptions.add(
			this.store
				.select(state => state.tenantData?.tenantInformation)
				.subscribe(info => {
					this.ignoreLicensing =
						(info?.ignoreLicensing ?? true) || !isFeatureFlagEnabled(FeatureFlags.licensing);
					this.defaultDisplayedColumns = !this.ignoreLicensing
						? ['contact', 'email', 'license', 'userStatus', 'actions']
						: ['contact', 'email', 'userStatus', 'actions'];
				})
		);
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
		this.filter.root.resetFilter({
			isEnabled: true
		});
	}

	get allocatedLicenses(): number {
		return this.datasource?.response?.records?.filter(x => x.licenseType == 'Licensed').length;
	}

	currentUserId = this.authService.UserId;

	createUser(): void {
		this.subscriptions.add(
			this.dialog
				.open(CreateUserComponent)
				.afterClosed()
				.pipe(
					filter(Boolean),
					switchMap((data: CreateUserDto) => this.usersService.createUser(data))
				)
				.subscribe(
					(result: EntityReference) => {
						this.notificationService.showNotification(`${result.name} created`);
						this.refreshList();
					},
					errors => this.notificationService.showErrors('Error creating user', errors)
				)
		);
	}

	inviteUser(user: UserViewDto): void {
		this.usersService.inviteUser(user.id).subscribe((result: InviteUserDto) => {
			if (!!result) {
				this.refreshList();
			}
			this.dialog
				.open(InviteUserComponent, { data: result })
				.afterClosed()
				.pipe(
					filter(Boolean),
					switchMap((data: InviteUserDto) => this.usersService.sendEmailInvite(user.id, data.email))
				)
				.subscribe(
					() => this.notificationService.showNotification('Invitation email sent'),
					errors => this.notificationService.showErrors('Error sending invitation', errors)
				);
		});
		this.appcuesService.trackEvent('UserInvited');
	}

	enableUser(user: UserViewDto) {
		this.EnableDisableUser(user, true);
	}

	disableUser(user: UserViewDto) {
		if (this.getIsCurrentUser(user)) return;
		this.EnableDisableUser(user, false);
	}

	deleteUser(user: UserViewDto) {
		const dialogRef = this.dialog.open(DeleteUserDialogComponent, {
			data: { user },
			width: '450px'
		});
		this.subscriptions.add(
			dialogRef
				.afterClosed()
				.pipe(
					filter(Boolean),
					switchMap(() => this.usersService.deleteUser(user.id))
				)
				.subscribe(
					() => {
						this.notificationService.showNotification(`User ${user.contact.name} deleted`);
						this.refreshList();
					},
					error => {
						const errorText = `Error deleting the user`;
						if (error.length > 0) {
							this.notificationService.showError(errorText, error[0].message);
						} else this.notificationService.showError(errorText, error);
					}
				)
		);
	}

	getIsCurrentUser(user: UserViewDto): boolean {
		if (!user) return false;
		return this.currentUserId === user.id;
	}

	assignLicense(row: UserViewDto) {
		this.manageLicense(row, true);
	}
	removeLicense(row: UserViewDto) {
		this.manageLicense(row, false);
	}

	private EnableDisableUser(user: UserViewDto, isEnable: boolean) {
		const messageText = isEnable ? 'Enable' : 'Disable';
		this.subscriptions.add(
			this.notificationService
				.showConfirmation(
					`${messageText} User`,
					`Are you sure you want to ${messageText} the user "${user.contact.name}"?`
				)
				.pipe(
					filter(Boolean),
					switchMap(() => {
						return isEnable
							? this.usersService.enableUser(user.id)
							: this.usersService.disableUser(user.id);
					})
				)
				.subscribe(
					() => {
						this.notificationService.showNotification(
							`User ${user.contact.name} ${isEnable ? 'enabled' : 'disabled'}`
						);
						this.refreshList();
					},
					error => {
						const errorText = `Error ${isEnable ? 'enabling' : 'disabling'} the user`;
						if (error.length > 0) {
							this.notificationService.showError(errorText, error[0].message);
						} else this.notificationService.showError(errorText, error);
					}
				)
		);
	}

	private manageLicense(user: UserViewDto, isAssign: boolean) {
		const messageText = isAssign ? 'Allocate' : 'Unallocate';
		this.subscriptions.add(
			this.notificationService
				.showConfirmation(
					`${messageText} User`,
					`Are you sure you want to ${messageText} a license ${isAssign ? 'to' : 'from'} the the user "${
						user.contact.name
					}"?`
				)
				.pipe(
					filter(Boolean),
					switchMap(() => {
						return isAssign
							? this.usersService.assignLicense(user.id)
							: this.usersService.unassignLicense(user.id);
					})
				)
				.subscribe(
					() => {
						this.notificationService.showNotification(
							`License ${isAssign ? 'allocated to' : 'unallocated from'} user ${user.contact.name}`
						);
						this.refreshList();
					},
					error => {
						const errorText = `Error ${isAssign ? 'allocating' : 'unallocating'} license`;

						if (error.length > 0) {
							if (error[0].message === 'InsufficientLicenses') {
								this.notificationService.showError(
									errorText,
									`You do not have enough licenses to assign. Please purchase additional licenses from the <a href="/system/subscription">subscription</a> page`
								);
							} else this.notificationService.showError(errorText, error[0].message);
						} else this.notificationService.showError(errorText, error);
					}
				)
		);
	}

	revokeInvitation(user: UserViewDto): void {
		this.usersService.revokeInvitation(user.id).subscribe(
			() => {
				this.notificationService.showNotification('Invite has been revoked');
				this.refreshList();
			},
			errors => this.notificationService.showErrors('Error revoking invitation', errors)
		);
	}

	canBeRevoked(user: UserListItemDto): boolean {
		if (!user.invitationExpirationDates) return false;

		return (
			!user.disabled &&
			!user.isRegistered &&
			user.invitationExpirationDates.filter(date => moment(date).isAfter).length > 0
		);
	}
}
