import { Pipe, PipeTransform } from '@angular/core';
import { TemplateEntityType } from '@common/models/Documents/TemplateDto/TemplateEntityType';

/*
 * Resolve template entity type display string by the enum key
 */
@Pipe({ name: 'templateEntityType' })
export class TemplateEntityTypePipe implements PipeTransform {
	transform(key: keyof typeof TemplateEntityType): string {
		if (key) {
			if (!TemplateEntityType[key]) {
				throw Error(`Dev error: The template entity type enum does not contain the key ${key}`);
			}
			return TemplateEntityType[key];
		} else return null;
	}
}
