<mat-card *ngIf="isViewDataLoaded" class="c-item">
	<div class="c-item__header">
		<div class="c-item__header__details">
			<div class="c-item__header__details__logo">
				<marketplace-icon [id]="id" [type]="listingType"></marketplace-icon>
			</div>

			<div class="c-item__header__details__title">{{ productName }}</div>
			<div class="c-item__header__details__company">{{ vendorName }}</div>

			<div *ngIf="!!lastModifiedBy && !!isPackageInstalled" class="c-item__header__details__installed-by">Installed by {{ lastModifiedBy }}</div>

			<div class="c-item__header__details__price" *ngIf="!!primaryPriceDisplay?.length">
				<div class="c-item__header__details__price__value">{{ primaryPriceDisplay }}</div>
				<div *ngIf="!!primaryPriceDescription?.length" class="c-item__header__details__price__description">{{ primaryPriceDescription }}</div>
			</div>

			<div *ngIf="!isTenantSubscriptionActive && secondaryPriceDescription?.length > 0" class="c-item__header__details__price_secondary">
				<span>{{ secondaryPriceDescription }}</span>
			</div>
		</div>

		<div class="c-item__header__details c-item__header__details--reverse c-item__header__details--overlay">
			<div class="c-item__header__details__actions">
				<!-- Service Request  -->
				<button *ngIf="!!isService" mat-raised-button color="primary" throttleButton (throttledClick)="requestService()">
					<span>{{ requestServiceButtonText }}</span>
				</button>

				<!-- Subscribe to product  -->
				<button mat-raised-button color="primary" *ngIf="!!showSubscribeButton" throttleButton (throttledClick)="addSubscription()">
					<mat-icon>download</mat-icon>
					<span>{{ subscribeButtonText }}</span>
				</button>

				<!-- Unsubscribe/Uninstall Product -->
				<button mat-raised-button *ngIf="!!showUnsubscribeButton" throttleButton (throttledClick)="unsubscribe()">
					<mat-icon>delete</mat-icon>
					<span>{{ unsubscribeButtonText }}</span>
				</button>

				<!-- Update Product  -->
				<button mat-raised-button color="primary" *ngIf="!!canUpdate" throttleButton (throttledClick)="update()">
					<mat-icon>upgrade</mat-icon>
					<span>Update</span>
				</button>

				<!-- Configure Product  -->
				<button mat-raised-button *ngIf="!!canConfigure" throttleButton (throttledClick)="configure()">
					<mat-icon>edit</mat-icon>
					<span>Configure</span>
				</button>
			</div>

			<div class="c-item__header__details__actions c-item__header__details__actions--mobile">
				<button
					mat-raised-button
					*ngIf="!!isService || !!showSubscribeButton || !!showUnsubscribeButton || !!canUpdate || !!canConfigure"
					[matMenuTriggerFor]="actionsMenu"
				>
					Actions <mat-icon>keyboard_arrow_down</mat-icon>
				</button>
				<mat-menu #actionsMenu="matMenu">
					<!-- Service Request  -->
					<button *ngIf="!!isService" mat-menu-item color="primary" throttleButton (throttledClick)="requestService()">
						<span>{{ requestServiceButtonText }}</span>
					</button>

					<!-- Subscribe to product  -->
					<button mat-menu-item color="primary" *ngIf="!!showSubscribeButton" throttleButton (throttledClick)="addSubscription()">
						<mat-icon>download</mat-icon>
						<span>{{ subscribeButtonText }}</span>
					</button>

					<!-- Unsubscribe/Uninstall Product -->
					<button mat-menu-item *ngIf="!!showUnsubscribeButton" throttleButton (throttledClick)="unsubscribe()">
						<mat-icon>delete</mat-icon>
						<span>{{ unsubscribeButtonText }}</span>
					</button>

					<!-- Update Product  -->
					<button mat-menu-item color="primary" *ngIf="!!canUpdate" throttleButton (throttledClick)="update()">
						<mat-icon>upgrade</mat-icon>
						<span>Update</span>
					</button>

					<!-- Configure Product  -->
					<button mat-menu-item *ngIf="!!canConfigure" throttleButton (throttledClick)="configure()">
						<mat-icon>edit</mat-icon>
						<span>Configure</span>
					</button>
				</mat-menu>
			</div>
		</div>
	</div>

	<div class="c-item__content" *ngIf="!!productDescription" [innerHtml]="productDescription"></div>
</mat-card>
