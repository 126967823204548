import { Directive, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';

import { StripeInfo } from '@common/models/Settings/Setting/Item/StripeInfo';

import { AppBrandingService } from 'app/services/app-branding.service';

@Directive()
export class BaseStripeInfoComponent implements OnDestroy {
	private _tenantStripeInfo: StripeInfo;
	public get stripeInfo() {
		return this._tenantStripeInfo;
	}

	protected subscriptions = new Subscription();

	private _isLoading: boolean = false;
	public loadingMessage: string;
	public isQueryLoadingBar: boolean = false;

	protected StartLoadingState(msg: string = null, isQuery: boolean = false) {
		this._isLoading = true;
		if (msg) this.loadingMessage = msg;
		this.isQueryLoadingBar = isQuery;
	}

	protected StopLoadingState() {
		this._isLoading = false;
		this.loadingMessage = null;
		this.isQueryLoadingBar = false;
	}

	public get isLoading(): boolean {
		return this._isLoading;
	}

	get siteName() {
		return AppBrandingService.getSiteName();
	}

	public get isTenantSubscriptionActive() {
		return (
			!!this._tenantStripeInfo?.subscriptionId &&
			this._tenantStripeInfo.quantity > 0 &&
			!this.isSubscriptionCancelled
		);
	}

	public get isSubscriptionCancelled() {
		return this._tenantStripeInfo.status == 'canceled' || this._tenantStripeInfo.cancelAt != null;
	}

	constructor(data: IBaseStripeInfoComponent) {
		this._tenantStripeInfo = data.stripeInfo;
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
}

export interface IBaseStripeInfoComponent {
	stripeInfo?: StripeInfo;
}
