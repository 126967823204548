import { Injectable } from '@angular/core';
import { BaseStorageService } from './base-storage.service';

// Implementation of native local browser storage
@Injectable({
	providedIn: 'root'
})
export class LocalStorageService extends BaseStorageService {
	constructor() {
		super();
		
		try {
			this.storage = window.localStorage;
		} catch {
			console.warn('Failed to attach local storage');
		}
	}
}