<mat-form-field class="search-form-field">
	<mat-placeholder>{{ Placeholder }}</mat-placeholder>
	<mat-hint>{{ Hint }}</mat-hint>
	<input matInput type="search" [formControl]="inputCtrl" [value]="inputCtrl.value" [autofocus]="HasAutofocus" autocomplete="off" />
	<mat-icon matSuffix *ngIf="!inputCtrl.value" class="search-icon">search</mat-icon>
</mat-form-field>

<mat-nav-list>
	<ng-template [ngIf]="!inputCtrl.value">
		<ng-template [ngIf]="SelectedValue">
			<mat-list-item class="selected-item">{{ SelectedValue.productName }}</mat-list-item>
			<mat-divider></mat-divider>
		</ng-template>
	</ng-template>
	<mat-list-item *ngFor="let option of options" throttleButton (throttledClick)="itemSelected(option)" [class.selected-item]="option.id == SelectedValue?.id">
		{{ option.productName }}
	</mat-list-item>
</mat-nav-list>

<div *ngIf="isSearching" class="searching">Searching...</div>
<div *ngIf="noLookupResults && inputCtrl.value && options?.length == 0" class="no-matches">No matches found</div>

<div class="action-buttons">
	<span throttleButton (throttledClick)="itemSelected(null)">Clear</span>
</div>
