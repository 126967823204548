<div class="drop-container" [class.drag-border]="dragOver" [fileDrop]="true" (filesDragged)="onDragEvent($event)" (filesDropped)="onFilesDropped($event)">
	<ng-content></ng-content>
	<div *ngIf="!showProgress && isEditable" class="file-browse-container">
		<div class="img-upload"><mat-icon class="material-icons-outlined">cloud_upload</mat-icon>&nbsp;&nbsp;</div>
		<div>
			Drop files to attach or <label for="file_input">browse</label><input type="file" id="file_input" multiple (change)="onFilesBrowsed($event)" />
		</div>
	</div>
</div>
<div class="file-list">
	<div *ngFor="let doc of currentDocuments; let i = index">
		<div class="file-container">
			<mat-card [title]="doc.file.name">
				<mat-icon [svgIcon]="fileIcon(doc.file.name)" [ngClass]="fileIcon(doc.file.name)"></mat-icon>
				<div class="flex-column">
					<span>{{ doc.file.name }}</span>
					<span>{{ doc.file.size | bytes }}</span>
				</div>
				<div class="flex-column-menu" *ngIf="!showProgress">
					<div *ngIf="isEditable" title="Remove"><mat-icon throttleButton (throttledClick)="removeFile(i)">close</mat-icon></div>
					<div title="Download">
						<a class="download-icon" throttleButton (throttledClick)="downloadFile(i)">
							<mat-icon>cloud_download</mat-icon>
						</a>
					</div>
				</div>
			</mat-card>
		</div>
	</div>
</div>
