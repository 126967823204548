import { createReducer, on } from '@ngrx/store';

import { CommonReducers } from '../../common-reducers';
import {
	init,
	insertRecords,
	loadFailed,
	loading,
	loadSuccess,
	removeRecords,
	selectRecords,
	setFilters,
	setPageIndex,
	setPageIndexForId,
	setPageSize,
	setSortBy,
	setSortDirection,
	updateRecords
} from './bill-list.actions';
import { IBillListState, initialState } from './bill-list.state';

export const billListReducer = createReducer(
	initialState,
	on(init, state => CommonReducers.init(state, initialState)),
	on(loading, state => CommonReducers.loading(state)),
	on(loadSuccess, (state, { response }) => {
		let result: IBillListState = CommonReducers.loadSuccess(state, response);

		if (!!response) {
			result.metadata = {
				sumAmountGst: response.sumAmountGst,
				sumAmountInclGst: response.sumAmountInclGst,
				sumDueAmountInclGst: response.sumDueAmountInclGst,
				sumPaidAmountInclGst: response.sumPaidAmountInclGst,
				sumWriteOffAmountInclGst: response.sumWriteOffAmountInclGst
			};
		}

		return result;
	}),
	on(loadFailed, (state, { error }) => CommonReducers.loadFailed(state, error)),

	on(setFilters, (state, { filter }) => CommonReducers.setFilters(state, filter)),

	on(setPageSize, (state, { pageSize }) => CommonReducers.setPageSize(state, pageSize)),
	on(setPageIndex, (state, { pageIndex }) => CommonReducers.setPageIndex(state, pageIndex)),
	on(setPageIndexForId, (state, { id }) => CommonReducers.setPageIndexForId(state, id)),

	on(setSortBy, (state, { sortBy }) => CommonReducers.setSortBy(state, sortBy)),
	on(setSortDirection, (state, { sortDirection }) => CommonReducers.setSortDirection(state, sortDirection)),

	on(insertRecords, (state, { response }) => CommonReducers.processMutations(state, response)),
	on(updateRecords, (state, { response }) => CommonReducers.processMutations(state, response)),
	on(removeRecords, (state, { response }) => CommonReducers.processMutations(state, response)),
	on(selectRecords, (state, { row, selectionType }) => CommonReducers.selectRecords(state, row, selectionType))
);
