import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

import { Subscription } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';

import { Features } from '@common/models/Settings/Modules/Features';
import { TenantFeatureStateDto } from '@common/models/Settings/Setting/Item/TenantFeatureStateDto';
import { TenantFeatureStateUpdateDto } from '@common/models/Settings/Setting/Item/TenantFeatureStateUpdateDto';
import { NotificationService } from '@common/notification';
import { GeneralSettingsService } from '@common/services/settings/generalsettings.service';
import { updateTenantFeatures } from '@common/state/actions/tenant.actions';
import { ITenantData } from '@common/state/models/tenant-data';
import { Store } from '@ngrx/store';

import { AppBrandingService } from 'app/services/app-branding.service';

@Component({
	selector: 'tenant-features',
	styleUrls: ['./tenant-features.component.scss'],
	templateUrl: './tenant-features.component.html'
})
export class TenantFeaturesComponent implements OnInit, OnDestroy {
	private static readonly featureDescriptions: IFeatureTypeDescription[] = [
		{
			type: 'ApiKeys',
			description: 'Manage keys used for integrating with third party systems.',
			icon: 'vpn_key'
		},
		{
			type: Features.Briefs,
			description: 'Collate documents into briefs.',
			icon: 'work'
		},
		{
			type: 'CostRecords',
			description: 'Create and manage fees and expenses.',
			icon: 'access_time'
		},
		{
			type: Features.InfoTrack,
			description: 'Integrate with InfoTrack for Searches and Services.',
			icon: 'find_in_page'
		},
		{
			type: Features.Invoicing,
			description: 'Create and manage invoicing.',
			icon: 'attach_money'
		},
		{
			type: Features.Referrals,
			description: 'Refer documents internally within your organisation.',
			icon: 'reply'
		},
		{
			type: Features.Tasks,
			description: 'Create and manage tasks to help keep track of what needs to be done.',
			icon: 'assignment_turned_in'
		},
		{
			type: 'TrustAccounting',
			description: 'Manage Trust Account.',
			icon: 'account_balance'
		},
		{
			type: 'XeroIntegration',
			description: 'Integrate with Xero to sync invoices.',
			icon: 'cached'
		},
		{
			type: 'FullTextSearch',
			description: 'Search documents by their contents',
			icon: 'search'
		},
		{
			type: 'DocumentVersioning',
			description: 'Create new versions of existing documents from within the matter documents listing.',
			icon: 'library_add'
		},
		{
			type: 'DocumentSharing',
			description: 'Share an existing document from the documents listing.',
			icon: 'share'
		},
		{
			type: 'MatterWorkflows',
			description: 'Define matter workflows such as automatically generating tasks on newly created matters.',
			icon: 'auto_awesome_mosaic'
		},
		{
			type: 'MatterWorkflowsV2',
			description:
				'Define advanced matter workflows such as automatically merging document templates on newly created matters.',
			icon: 'auto_awesome_mosaic'
		} //,
		// {
		// 	type: 'Marketplace',
		// 	description: `Add additional functionality to your ${AppBrandingService.getSiteName()}`,
		// 	icon: 'storefront'
		// }
	];

	isFeatureDescriptionAvailable(featureType: keyof typeof Features): boolean {
		return TenantFeaturesComponent.featureDescriptions.filter(x => x.type == featureType).length > 0;
	}

	private _subscriptions: Subscription = new Subscription();
	private _form: FormGroupTyped<TenantFeatureStateUpdateDto>;
	tenantFeatures: TenantFeatureStateDto[];
	hiddenFeatures: TenantFeatureStateDto[];

	get formFeatureStates(): FormGroupTyped<TenantFeatureStateDto>[] {
		return (this._form.controls.states as any as FormArray).controls as FormGroupTyped<TenantFeatureStateDto>[];
	}

	get form(): FormGroupTyped<TenantFeatureStateUpdateDto> {
		return this._form;
	}

	get siteName() {
		return AppBrandingService.getSiteName();
	}

	constructor(
		private _formBuilder: FormBuilder,
		private _store: Store<{ tenantData: ITenantData }>,
		private _generalSettingsService: GeneralSettingsService,
		private _notificationService: NotificationService
	) {}

	ngOnInit(): void {
		this._form = this._formBuilder.group({
			states: this._formBuilder.array([])
		}) as FormGroupTyped<TenantFeatureStateUpdateDto>;

		this._subscriptions.add(
			this._store
				.select(state => state?.tenantData?.tenantInformation?.featureStates)
				.pipe(
					filter(states => !!states?.length),
					first(),
					tap(states => (this.hiddenFeatures = states.slice().filter(state => !state.isVisible))),
					map(states =>
						states
							.slice()
							.sort((left, right) => {
								if (left.type < right.type) {
									return -1;
								}

								if (left.type > right.type) {
									return 1;
								}

								return 0;
							})
							.filter(state => !!state.isVisible)
					)
				)
				.subscribe(states => {
					this.tenantFeatures = states;
					states.forEach(state => {
						const moduleGroup = this._formBuilder.group({
							type: state.type,
							isEnabled: state.isEnabled,
							canUserEnable: state.canUserEnable,
							isVisible: state.isVisible
						}) as FormGroupTyped<TenantFeatureStateDto>;

						this.formFeatureStates.push(moduleGroup);
					});
				})
		);
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}

	getFormGroupControl(formGroup: FormGroup, key: string) {
		return formGroup.get(key);
	}

	getTypeDescription(key: string) {
		const found = TenantFeaturesComponent.featureDescriptions.filter(data => data.type === key);

		return !!found.length ? found[0].description : '';
	}

	getAvailableIn(key: keyof typeof Features) {
		const availableIn = this.tenantFeatures.filter(x => x.type === key)[0]?.availableIn;
		if (!!availableIn) {
			return [availableIn.slice(0, -1).join(', '), availableIn.slice(-1)[0]].join(
				availableIn.length < 2 ? '' : ' and '
			);
		}
		return null;
	}

	getIcon(key: string) {
		const found = TenantFeaturesComponent.featureDescriptions.filter(data => data.type === key);
		return !!found.length ? found[0].icon : '';
	}

	handleSlideToggle(formGroup: FormGroupTyped<TenantFeatureStateDto>, event: MatSlideToggleChange) {
		const control = this.getFormGroupControl(formGroup, 'isEnabled');
		control.setValue(event.checked);

		control.markAsTouched();
		control.markAsDirty();
		control.updateValueAndValidity();

		this._form.markAsTouched();
		this._form.markAsDirty();
		this._form.updateValueAndValidity();
	}

	save() {
		if (!this.form.pristine && !!this.form.valid) {
			const value = {
				states: this.formFeatureStates.map(formGroup => {
					const stateValue = formGroup.value;

					return {
						type: stateValue.type,
						isEnabled: stateValue.isEnabled,
						canUserEnable: stateValue.canUserEnable,
						isVisible: stateValue.isVisible
					};
				})
			} as TenantFeatureStateUpdateDto;

			this._subscriptions.add(
				this._generalSettingsService.updateFeatures(value).subscribe(
					() => {
						this._notificationService.showNotification('Successfully updated feature settings');
						this._store.dispatch(
							updateTenantFeatures({ featureStates: value.states.concat(this.hiddenFeatures) })
						);
					},
					errors => this._notificationService.showErrors('Failed to update feature settings', errors)
				)
			);

			this._form.markAsUntouched();
			this._form.markAsPristine();
		}
	}
}

interface IFeatureTypeDescription {
	type: keyof typeof Features;
	description: string;
	icon: string;
}
