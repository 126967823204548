// This file has been generated from TimeZoneDto.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.Common.Models.TimeZoneDto
export class TimeZoneDto {

	// TimeZoneId
	timeZoneId: string;

	constructor() {

	}
}