<filter-root #theFilter [filter]="request" [listRequestType]="listRequestType" (filterChange)="rootFilterChange($event)">
	<filter-practice-area
		[root]="theFilter"
		name="practiceAreaIds"
		label="Practice Areas"
		[showTitleAsPrefix]="true"
		[showDisabled]="true"
		[showOnlyMyAreas]="false"
	></filter-practice-area>
	<filter-search-button [root]="theFilter" label="Search" name="search" [showTitleAsPrefix]="true"></filter-search-button>
</filter-root>
