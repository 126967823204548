import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatterMailToReferenceVariables } from '@common/models/Settings/Setting/Common/MatterMailToReferenceVariables';
import { NotificationService } from '@common/notification';
import { GeneralSettingsService } from '@common/services/settings/generalsettings.service';
import { isNil, join } from 'lodash-es';
import { Subscription } from 'rxjs';

@Component({
	styleUrls: ['./matter-mailto-config.component.scss'],
	templateUrl: './matter-mailto-config.component.html'
})
export class MatterMailToConfigComponent implements OnInit, OnDestroy {
	private _subscriptions = new Subscription();

	errorMessage: string;
	separator: string = ',';
	items: IDrapDropData[] = [];
	basket: IDrapDropData[] = [];
	isDirty: boolean;

	private _availableVariable: IDrapDropData[] = [
		{
			variableKey: 'ClientName',
			title: MatterMailToReferenceVariables.ClientName,
			example: 'Smith, John'
		},
		{
			variableKey: 'ClientShortName',
			title: MatterMailToReferenceVariables.ClientShortName,
			example: 'Smith J'
		},
		{
			variableKey: 'MatterNumber',
			title: MatterMailToReferenceVariables.MatterNumber,
			example: '167'
		},
		{
			variableKey: 'MatterTitle',
			title: MatterMailToReferenceVariables.MatterTitle,
			example: 'Jones vs. White'
		},
		{
			variableKey: 'MatterConstant',
			title: MatterMailToReferenceVariables.MatterConstant,
			example: '',
			allowMany: true
		},
		{
			variableKey: 'Hyphen',
			title: MatterMailToReferenceVariables.Hyphen,
			example: '',
			allowMany: true
		}
	];

	constructor(
		private _generalSettingsService: GeneralSettingsService,
		private _notificationService: NotificationService
	) {}

	ngOnInit() {
		this._subscriptions.add(
			this._generalSettingsService.getMatterMailToConfig().subscribe(dto => {
				let selectedKeys = dto.referenceFormat?.split(this.separator).filter((el: string) => {
					return !isNil(el) && el !== '';
				}) ?? ['MatterConstant', 'MatterNumber'];

				this.items = selectedKeys.map((key: string) => {
					return this._availableVariable.filter(x => x.variableKey === key)[0];
				});

				this.basket = this._availableVariable.filter(item => {
					return item.allowMany || selectedKeys.indexOf(item.variableKey) === -1;
				});
			})
		);
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}

	get mailFormat(): string {
		return this.items
			? join(
					this.items.map(x => x.variableKey),
					this.separator
			  )
			: null;
	}

	remove(index: number) {
		if (index > -1) {
			const removed = this.items.splice(index, 1);
			if (removed) {
				this.basket.push(removed[0]);
				this.cleanupBasket();
			}
		}

		this.isDirty = true;
	}

	drop(event: CdkDragDrop<IDrapDropData[]>) {
		if (event.previousContainer === event.container) {
			moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
		} else {
			transferArrayItem(
				event.previousContainer.data,
				event.container.data,
				event.previousIndex,
				event.currentIndex
			);
			this.cleanupBasket();
		}

		this.isDirty = true;
	}

	cleanupBasket() {
		// Ensure that as we drag and drop, the basket always keeps a hyphen and new line, but never more than one.
		const permittedDupes = this._availableVariable.filter(x => x.allowMany);
		permittedDupes.forEach(v => {
			this.basket = this.basket.filter(b => b.variableKey !== v.variableKey);
			this.basket.push(v);
		});
	}

	saveChanges() {
		this._subscriptions.add(
			this._generalSettingsService.setMatterMailToConfig({ referenceFormat: this.mailFormat }).subscribe(
				() => {
					this._notificationService.showNotification('Saved Matter Mail Config');
					this.isDirty = false;
				},
				errors => this._notificationService.showErrors('Error Saving Matter Mail Config', errors)
			)
		);
	}
}

interface IDrapDropData {
	variableKey: keyof typeof MatterMailToReferenceVariables;
	title: string;
	example: string;
	allowMany?: boolean;
}
