import { Component } from '@angular/core';
import { ImportEntityType } from '@common/models/Imports/Common/ImportEntityType';

@Component({
	selector: 'import-invoices',
	styleUrls: ['./import-invoices.component.scss'],
	templateUrl: './import-invoices.component.html'
})
export class ImportInvoicesComponent {
	importType = ImportEntityType.Invoice;
}
