import { createSelector, MemoizedSelector } from '@ngrx/store';

import { IAppState } from '../../../app.state';
import { DocumentBriefTemplateListItemDto } from '@common/models/DocumentBriefTemplates/List/DocumentBriefTemplateListItemDto';

export const selectDocumentBriefTemplateList = (state: IAppState) => state.documentBriefTemplateList;

export const selectDocumentBriefTemplateListRecords: MemoizedSelector<IAppState, DocumentBriefTemplateListItemDto[]> =
	createSelector(selectDocumentBriefTemplateList, state => state?.list?.records || []);
export const selectDocumentBriefTemplateListRequest: MemoizedSelector<IAppState, Partial<any>> = createSelector(
	selectDocumentBriefTemplateList,
	state => state?.request
);
export const selectDocumentBriefTemplateListPageIndex = createSelector(
	selectDocumentBriefTemplateList,
	state => state?.list?.pageIndex || 0
);
export const selectDocumentBriefTemplateListTotalRecords = createSelector(
	selectDocumentBriefTemplateList,
	state => state?.list?.totalRecords || 0
);
export const selectDocumentBriefTemplateIsFetching = createSelector(
	selectDocumentBriefTemplateList,
	state => !!state?.isFetching
);
