import { PracticeAreaListItemDto } from '@common/models/Settings/PracticeAreas/List/PracticeAreaListItemDto';
import { createSelector, MemoizedSelector } from '@ngrx/store';

import { IAppState } from '../../app.state';

export const selectPracticeAreaFilterData = (state: IAppState) => state.practiceAreaFilterData;

export const selectPracticeAreaFilterRecords: MemoizedSelector<IAppState, PracticeAreaListItemDto[]> = createSelector(
	selectPracticeAreaFilterData,
	state => state?.list?.records || []
);
export const selectPracticeAreaFilterRequest: MemoizedSelector<IAppState, any> = createSelector(
	selectPracticeAreaFilterData,
	state => state?.request
);
export const selectPracticeAreaFilterIsFetching = createSelector(
	selectPracticeAreaFilterData,
	state => !!state?.isFetching
);
