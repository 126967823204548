import { Component, Input } from '@angular/core';

import { multirowSelectText } from 'app/shared/utils/stringUtil';

@Component({
	selector: 'bulk-actions-menu',
	styleUrls: ['./bulk-actions-menu.scss'],
	templateUrl: './bulk-actions-menu.component.html'
})
export class BulkActionsMenuComponent {
	@Input() isGlobalContext: boolean;
	@Input() hideFooter: boolean;
	@Input() menuLabel: string = 'Bulk Actions';
	@Input() matIconName: string;
	@Input() iconColor: string;
	@Input() includeMargins: boolean = true;
	@Input() alwaysShow: boolean = true;
	@Input() rowSelectionLabel: string = multirowSelectText();
}
