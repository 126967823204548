// This file has been generated from DocumentType.cs
// Do not modify this file directly!!

// LawMaster.Amalthea.Domain.Documents.Common.DocumentType
export enum DocumentType {
	Expense = 'Expense',
	Document = 'Document',
	Email = 'Email',
	Note = 'Note',
	Receipt = 'Receipt',
	Template = 'Template',
	EmailTemplate = 'Email Template',
	EmailTemplateAttachment = 'Email Template Attachment',
	HeaderFooterTemplate = 'Header Footer Template',
	EFTPayment = 'EFT Payment',
	Journal = 'Journal',
	TrustLedgerReport = 'Trust Ledger Report',
	TrustJournalReport = 'Trust Journal Report',
	TrustCashbookReport = 'Trust Cash Book Report',
	TrustOverdrawnReport = 'Trust Overdrawn Report',
	TrustReconciliationReport = 'Trust Reconciliation Report',
	TrustTrialBalanceReport = 'Trust Trial Balance Report',
	TrustDormantBalanceReport = 'Trust Dormant Balances Balance Report',
	TrustAuditReport = 'Trust Audit Report',
	Deposit = 'Deposit Listing'
}
