import { createReducer, on } from '@ngrx/store';

import { loadFailed, loading, loadSuccess } from './time-zone-list.actions';
import { initialState } from './time-zone-list.state';

export const timeZoneListReducer = createReducer(
	initialState,
	on(loading, state => ({ ...state, isFetching: true })),
	on(loadSuccess, (state, props) => ({ ...state, timeZones: props?.response ?? [], isFetching: false })),
	on(loadFailed, (state, props) => ({ ...state, error: props?.error, isFetching: false }))
);
