import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { get } from 'lodash-es';
import * as moment from 'moment-timezone';

@Component({
	selector: 'error-messages',
	template: ` <span *ngFor="let item of filteredErrors"> {{ item }}<br /> </span> `
})
export class ErrorMessagesComponent {
	@Input() for: AbstractControl;

	// Replace the messages for these error types
	replace: any = {
		matDatepickerMax: 'Date must be before {max}',
		matDatepickerMin: 'Date must be after {min}',
		matDatepickerParse: 'Invalid date',
		min: 'Cannot be less than {min}',
		pattern: 'Invalid Input',
		required: 'This field is required'
	};

	get filteredErrors(): string[] {
		if (!this.for) {
			return [];
		}

		return Object.keys(this.for.errors || {}).map((key: string) =>
			Object.keys(this.replace).findIndex(r => r === key) >= 0 ? this.replaceMessage(key) : this.for.errors[key]
		);
	}

	private replaceMessage(key: string): string {
		const original = this.for.errors[key];
		const replacement = this.replace[key];

		return replacement.replace(/{([^}]*)}/, (str: string, match: string) => {
			let value = get(original, match, '');

			if (moment.isMoment(value)) {
				value = value.format('DD-MM-YYYY');
			}
			return value;
		});
	}
}
