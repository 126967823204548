import { Component } from '@angular/core';

import { BaseEditableComponent } from './base.editable.component';

@Component({
	selector: 'editable-checkbox',
	styleUrls: ['./editable-checkbox.component.scss', './base.editable.component.scss'],
	template: `
		<span *ngIf="!editMode">{{ formatDisplay(displayValue) }}</span>
		<div *ngIf="!!editMode" class="flex-column">
			<div class="flex-row">
				<mat-placeholder *ngIf="placeHolder" class="checkboxLabel"
					>{{ placeHolder }}
					<sup class="color-warn" *ngIf="required">*</sup>
				</mat-placeholder>
				<mat-checkbox [indeterminate]="value() == null" [formControl]="formControl">
					{{ displayValue }}
				</mat-checkbox>
			</div>

			<div class="flex-row" *ngIf="!!hintText?.length">
				<mat-hint>{{ hintText }}</mat-hint>
			</div>
		</div>
	`
})
export class EditableCheckboxComponent extends BaseEditableComponent<boolean> {
	get displayValue(): string {
		switch (this.value()) {
			case true:
				return 'Yes';
			case false:
				return 'No';
			default:
				return 'Unknown';
		}
	}
}
