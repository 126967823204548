import { Component, Input, ViewChild } from '@angular/core';

import { EntityReference } from '@common/models/Common/EntityReference';
import { DocumentListRequest } from '@common/models/Documents/List/DocumentListRequest';
import { TemplateEntityType } from '@common/models/Documents/TemplateDto/TemplateEntityType';

import { FeatureFlags } from 'app/app.config';
import { FilterRootComponent } from 'app/shared/filter-controls';

import { DocumentListFilterConfig } from './document-list-filter.config';

@Component({
	selector: 'document-list-filter',
	styleUrls: ['./document-list-filter.component.scss'],
	templateUrl: './document-list-filter.component.html'
})
export class DocumentListFilterComponent {
	featureFlags: typeof FeatureFlags = FeatureFlags;

	@ViewChild(FilterRootComponent, { static: true })
	root: FilterRootComponent<DocumentListRequest>;

	@Input()
	set config(value: Partial<DocumentListFilterConfig>) {
		this._config = value;

		this.entityTypes =
			this.config.entityTypes?.map(key => ({ id: key, name: TemplateEntityType[key] })) ??
			DocumentListFilterComponent.defaultEntityTypes;
	}

	get config() {
		return this._config;
	}

	private _config: Partial<DocumentListFilterConfig>;

	@Input()
	documentTypes: EntityReference[];

	listRequestType: new () => DocumentListRequest = DocumentListRequest;

	private static defaultEntityTypes = Object.keys(TemplateEntityType).map((key: keyof typeof TemplateEntityType) => {
		return { id: key, name: TemplateEntityType[key] };
	});

	entityTypes = DocumentListFilterComponent.defaultEntityTypes;
}
