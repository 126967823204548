<h2 mat-dialog-title>
	<span>Set Xero Description Format</span>
</h2>
<mat-dialog-content>
	<div class="description">Select the format of the description for the invoiced cost records when synced with Xero</div>
	<br />

	<div class="flexrow">
		<span class="description">Example invoice line item description in Xero : &nbsp; </span>
		<h3 class="sample-text">
			<span *ngFor="let item of items; let first = first">
				<span *ngIf="!first">&nbsp;</span>
				<br *ngIf="item.variableKey == 'NewLine'; else notNewLine" />
				<ng-template #notNewLine>
					<b *ngIf="item.variableKey == 'Hyphen'; else notSpacer">-</b>
					<ng-template #notSpacer>
						<b>{{ item.example }}</b>
					</ng-template>
				</ng-template>
			</span>
		</h3>
	</div>

	<div cdkDropListGroup>
		<span class="error">{{ errorMessage }}</span>
		<h2 class="description">Description</h2>

		<div cdkDropList cdkDropListOrientation="horizontal" [cdkDropListData]="items" class="drag-drop-box" (cdkDropListDropped)="drop($event)">
			<span [ngClass]="items?.length > 0 ? 'drag-drop-label-right' : 'drag-drop-label-center'"
				><mat-icon>highlight_alt</mat-icon>Drag and drop variables here</span
			>
			<div class="flexrow">
				<div *ngFor="let item of items; let first = first; let i = index" class="chip-flex-row">
					<span class="chip-hyphen" *ngIf="!first"> &nbsp; </span>
					<div class="chip-flex-column">
						<mat-chip
							[ngClass]="item.allowMany ? 'chip-primary-multi' : 'chip-primary'"
							cdkDrag
							[selectable]="true"
							[removable]="true"
							(removed)="remove(i)"
						>
							{{ item.title }}
							<mat-icon [ngClass]="item.allowMany ? 'multi-remove' : 'primary-remove'" matChipRemove *ngIf="true">cancel</mat-icon>
						</mat-chip>
						<span *ngIf="item.example">({{ item.example }})</span>
					</div>
				</div>
			</div>
		</div>

		<h2 class="description">Available variables</h2>

		<div cdkDropList cdkDropListOrientation="horizontal" [cdkDropListData]="basket" class="drag-drop-box" (cdkDropListDropped)="drop($event)">
			<div class="flexrow">
				<div *ngFor="let item of basket" class="chip-flex-column">
					<mat-chip cdkDrag [selectable]="true" [removable]="false" [ngClass]="item.allowMany ? 'chip-basket-multi' : 'chip-basket'">
						{{ item.title }}<mat-icon>drag_indicator</mat-icon>
					</mat-chip>
					<span *ngIf="item.example">({{ item.example }})</span>
				</div>
			</div>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-raised-button [disabled]="errorMessage?.length > 0" color="primary" [mat-dialog-close]="this.descriptionFormat">OK</button>
</mat-dialog-actions>
