import { PracticeAreaListItemDto } from '@common/models/Settings/PracticeAreas/List/PracticeAreaListItemDto';
import { createSelector, MemoizedSelector } from '@ngrx/store';

import { IAppState } from '../../../app.state';

export const selectPracticeAreaList = (state: IAppState) => state.practiceAreaList;

export const selectPracticeAreaListRecords: MemoizedSelector<IAppState, PracticeAreaListItemDto[]> = createSelector(
	selectPracticeAreaList,
	state => state?.list?.records || []
);
export const selectPracticeAreaListRequest: MemoizedSelector<IAppState, Partial<any>> = createSelector(
	selectPracticeAreaList,
	state => state?.request
);
export const selectPracticeAreaListPageIndex = createSelector(
	selectPracticeAreaList,
	state => state?.list?.pageIndex || 0
);
export const selectPracticeAreaListTotalRecords = createSelector(
	selectPracticeAreaList,
	state => state?.list?.totalRecords || 0
);
export const selectPracticeAreaIsFetching = createSelector(selectPracticeAreaList, state => !!state?.isFetching);
