import { AfterContentInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { map, Subscription } from 'rxjs';

import { MatterStatus } from '@common/models/Matters/Common/MatterStatus';
import { MatterLookupDto } from '@common/models/Matters/Lookup/MatterLookupDto';
import { ICurrentUserData } from '@common/state/models/current-user-data';
import { Store } from '@ngrx/store';

import { getCurrentPage } from 'app/core/state/misc/current-page/current-page.reducer';
import { CurrentPageType, ICurrentPageState } from 'app/core/state/misc/current-page/current-page.state';
import { MatterLookupComponent } from 'app/shared/components/matter-lookup.component';
import { ICreateMatterTimer } from 'app/shared/components/work-timer/WorkTimerStateManager';

export interface ICreateMatterTimerDialogComponentData {
	matterId?: string;
	description?: string;
}

@Component({
	selector: 'create-matter-timer-dialog',
	styleUrls: ['./create-matter-timer-dialog.component.scss'],
	templateUrl: './create-matter-timer-dialog.component.html'
})
export class CreateMatterTimerDialogComponent implements OnInit, AfterContentInit, OnDestroy {
	@ViewChild('matterLookup')
	matterLookupRef: MatterLookupComponent;

	@ViewChild('timerDescription')
	descriptionRef: ElementRef;

	private subscriptions: Subscription = new Subscription();

	form: FormGroupTyped<ICreateMatterTimer>;

	get disableMatterLookup() {
		return !!this.data && !!this.data.matterId;
	}

	constructor(
		@Inject(MAT_DIALOG_DATA) private data: ICreateMatterTimerDialogComponentData,
		private fb: FormBuilder,
		private store: Store<{ currentPageData: ICurrentPageState; currentUserData: ICurrentUserData }>
	) {}

	ngOnInit(): void {
		this.form = this.fb.group({
			matterId: [null, Validators.required],
			description: null
		}) as FormGroupTyped<ICreateMatterTimer>;
	}

	ngAfterContentInit(): void {
		if (!this.data || !this.data.matterId) {
			this.subscriptions.add(
				this.getCurrentOpenMatter().subscribe(matter => {
					let matterId = null;
					if (matter !== null) {
						matterId = matter.id;
						setTimeout(() => {
							this.descriptionRef.nativeElement.focus();
						});
					} else {
						setTimeout(() => {
							this.matterLookupRef.setFocus();
						});
					}
					this.form.controls.matterId.setValue(matterId);
				})
			);
		} else {
			this.form.controls.matterId.setValue(this.data.matterId);
			this.form.controls.matterId.disable();
			this.form.controls.matterId.updateValueAndValidity();
			this.form.controls.description.setValue(this.data.description || '');
			this.form.controls.description.updateValueAndValidity();
		}
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	private getCurrentOpenMatter(): Observable<MatterLookupDto> {
		// default in matter if it is available
		return this.store
			.select(getCurrentPage)
			.pipe(
				map(page =>
					page.pageType === CurrentPageType.Matter &&
					page.id !== null &&
					(page.lookup as MatterLookupDto).status === MatterStatus.Open
						? (page.lookup as MatterLookupDto)
						: null
				)
			);
	}

	get dialogResult(): ICreateMatterTimer {
		if (this.form.invalid) {
			return null;
		}
		return this.form.value;
	}
}
