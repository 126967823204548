<div class="cost-list">
	<mat-accordion>
		<mat-expansion-panel>
			<mat-expansion-panel-header>
				<mat-panel-title>Recurring Costs per {{ interval }} </mat-panel-title>
				<mat-panel-description> {{ recurringInvoice?.amountDue | currency : '$' }} </mat-panel-description>
			</mat-expansion-panel-header>
			<div *ngFor="let lineItem of recurringInvoice?.lineItems">
				<div class="row">
					<div class="label">
						<p>{{ lineItem?.reason }}</p>
					</div>
					<div class="amount" [ngClass]="{ 'negative-number': lineItem?.amount < 0 }">
						<p>{{ lineItem?.amount | currency : '$' }}</p>
					</div>
				</div>
			</div>
		</mat-expansion-panel>
	</mat-accordion>

	<mat-accordion>
		<mat-expansion-panel>
			<mat-expansion-panel-header>
				<mat-panel-title>Immediate {{ upcomingInvoice?.total > 0 ? 'Costs' : 'Credits' }}</mat-panel-title>
				<mat-panel-description> {{ upcomingInvoice?.total | currency : '$' }} </mat-panel-description>
			</mat-expansion-panel-header>

			<div class="row row--margin">
				<div class="label">
					<b>
						<p *ngIf="!!upcomingInvoice?.nextPaymentAttempt">
							Amount will be automatically deducted on <b> {{ upcomingInvoice?.nextPaymentAttempt | moment }}</b>
						</p>

						<p *ngIf="!upcomingInvoice?.nextPaymentAttempt">Amount will be automatically deducted</p>
					</b>
				</div>
			</div>

			<div class="row" *ngIf="upcomingInvoice?.startingBalance !== 0">
				<div class="label">
					<p>Starting Credit Balance</p>
				</div>
				<div class="amount credit-balance" [ngClass]="{ 'negative-number': upcomingInvoice?.startingBalance > 0 }">
					<p>{{ -upcomingInvoice?.startingBalance | currency : '$' }}</p>
				</div>
			</div>

			<div *ngFor="let lineItem of upcomingInvoice?.lineItems">
				<div class="row">
					<div class="label">
						<p>{{ lineItem?.reason }}</p>
					</div>
					<div class="amount" [ngClass]="{ 'negative-number': lineItem?.amount < 0 }">
						<p>{{ lineItem?.amount | currency : '$' }}</p>
					</div>
				</div>
			</div>

			<div class="row" *ngIf="upcomingInvoice?.startingBalance !== 0 || upcomingInvoice?.endingBalance !== 0">
				<div class="label">
					<p>Ending Credit Balance</p>
				</div>
				<div class="amount credit-balance" [ngClass]="{ 'negative-number': upcomingInvoice.endingBalance > 0 }">
					<h2>{{ -upcomingInvoice.endingBalance | currency : '$' }}</h2>
				</div>
			</div>
		</mat-expansion-panel>
	</mat-accordion>
</div>
