import { PRIMARY_OUTLET, UrlTree } from '@angular/router';

import { has, isArray } from 'lodash-es';

export function GetRoutePaths(urlTree: UrlTree): string[] {
	let routePaths: string[] = [];

	if (
		has(urlTree, 'root.children') &&
		urlTree.root.children[PRIMARY_OUTLET] &&
		isArray(urlTree.root.children[PRIMARY_OUTLET].segments)
	) {
		routePaths = urlTree.root.children[PRIMARY_OUTLET].segments.map(r => r.path);
	}

	return routePaths;
}
