// This file has been generated from ReferralReasonsController.cs
// Do not modify this file directly!!
// tslint:disable

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { TrimStringsOnObject } from '@common/utils/string-format';

import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';
import { ListRequest } from '../../models/Generic/ListRequest';
import { ListResponse } from '../../models/Generic/ListResponse';
import { ReferralReasonDto } from '../../models/Settings/ReferralReasons/Item/ReferralReasonDto';
import { ReferralReasonListItemDto } from '../../models/Settings/ReferralReasons/List/ReferralReasonListItemDto';

@Injectable({
	providedIn: 'root'
})
export class ReferralReasonsService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'api/v1/settings/ReferralReasons', 'Referral Reasons');
	}

	getUserType(id: string): Observable<ReferralReasonDto> {
		return this.getItem<void, ReferralReasonDto>(`${id}`);
	}

	getAllReferralReasons(): Observable<ReferralReasonListItemDto[]> {
		return this.getArray<void, ReferralReasonListItemDto>(`all`);
	}

	getReferralReasons(request?: Partial<ListRequest>): Observable<ListResponse<ReferralReasonListItemDto>> {
		return this.getList<ListRequest, ReferralReasonListItemDto>('', request);
	}

	createReferralReason(dto: ReferralReasonDto): Observable<MutationResponseDto> {
		return this.post<ReferralReasonDto, MutationResponseDto>(
			'',
			TransformDatesOnObject(ReferralReasonDto, TrimStringsOnObject(dto))
		);
	}

	updateReferralReason(id: string, dto: ReferralReasonDto): Observable<MutationResponseDto> {
		return this.put(`${id}`, TransformDatesOnObject(ReferralReasonDto, TrimStringsOnObject(dto)));
	}

	deleteReferralReason(id: string): Observable<MutationResponseDto> {
		return this.delete(`${id}`);
	}
}
