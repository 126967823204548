import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, startWith } from 'rxjs/operators';

import { FilterBaseComponent } from './filter-base.component';
import { FilterButtonComponent } from './filter-button.component';
import { FilterChangeProperties } from './filter-change-properties';

@Component({
	selector: 'filter-search-button',
	styleUrls: ['./filter-base.component.scss'],
	template: `
		<filter-button [root]="root" [name]="name" [showTitleAsPrefix]="showTitleAsPrefix" [label]="label">
			<mat-form-field class="search-container">
				<mat-placeholder>Contains text</mat-placeholder>
				<input #searchInput matInput [formControl]="formControl" (keyup.enter)="enter()" type="search" />
				<button
					mat-button
					matSuffix
					mat-icon-button
					aria-label="search"
					throttleButton
					(throttledClick)="applyFilter()"
				>
					<mat-icon>search</mat-icon>
				</button>
			</mat-form-field>
			<div class="action-buttons">
				<span throttleButton (throttledClick)="clear()">Clear</span>
			</div>
		</filter-button>
	`
})
export class FilterSearchButtonComponent extends FilterBaseComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild(FilterButtonComponent, { static: true }) btn: FilterButtonComponent;
	@ViewChild('searchInput', { static: true }) searchInput: ElementRef;

	protected subscriptions = new Subscription();

	constructor(private cdr: ChangeDetectorRef) {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();

		if (!this.label) {
			this.label = 'Search';
		}

		// The control can be changed by the filter-root, so we need to subscribe
		this.subscriptions.add(
			this.root.filterChange
				.pipe(
					// Fire the 1st event with the current value
					startWith(new FilterChangeProperties<any>(this.root.filter)),
					distinctUntilChanged(),
					filter(f => !!f.filter)
				)
				.subscribe(val => {
					this.applyFilter();
				})
		);
	}

	ngAfterViewInit() {
		this.subscriptions.add(
			this.btn.MenuOpened.pipe(filter(Boolean)).subscribe(() =>
				setTimeout(() => this.searchInput.nativeElement.focus(), 0)
			)
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	enter(): void {
		this.applyFilter();
		// Without that we get ExpressionChangedAfterItHasBeenCheckedError down the stream
		// on closing the overlay (the [open] property)
		this.cdr.detectChanges();
	}

	applyFilter(): void {
		this.btn.valueSelected(this.control.value);
	}

	clear(): void {
		this.btn.valueSelected(null);
	}
}
