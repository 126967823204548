import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { MarketplaceVendorPackageListItemDto } from '@common/models/Marketplace/Vendor/List/MarketplaceVendorPackageListItemDto';
import { MarketplaceVendorPackageListRequest } from '@common/models/Marketplace/Vendor/List/MarketplaceVendorPackageListRequest';
import { DomainError } from '@common/models/Validation/DomainError';
import { NotificationService } from '@common/notification';
import { MarketplaceVendorService } from '@common/services/settings/marketplacevendor.service';

import { GridViewService } from 'app/services/grid-view.service';
import { GenericListComponent } from 'app/shared/generics/generic.list.component';

@Component({
	selector: 'marketplace-vendor-package-list',
	styleUrls: ['./marketplace-vendor-package-list.component.scss'],
	templateUrl: 'marketplace-vendor-package-list.component.html'
})
export class MarketplaceVendorPackageListComponent extends GenericListComponent<
	MarketplaceVendorPackageListItemDto,
	MarketplaceVendorPackageListRequest
> {
	request: Partial<MarketplaceVendorPackageListRequest> = {};

	constructor(
		dialog: MatDialog,
		router: Router,
		activatedRoute: ActivatedRoute,
		marketplaceVendorService: MarketplaceVendorService,
		private notificationService: NotificationService,
		gridViewService: GridViewService
	) {
		super(
			dialog,
			router,
			activatedRoute,
			marketplaceVendorService,
			[
				'productName',
				'version',
				'status',
				'documentTemplateCount',
				'documentEmailTemplateCount',
				'documentHeaderFooterTemplateCount',
				'customFieldCount',
				'practiceAreaCount',
				'lastModifiedUtc',
				'validationStatus'
			],
			gridViewService
		);
	}

	showErrors(row: MarketplaceVendorPackageListItemDto) {
		const errors = row.errors.map(x => Object.assign(new DomainError(), { message: x }));
		this.notificationService.showErrors('Validation Errors', errors);
	}
}
