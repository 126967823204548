// This file has been generated from CollectionPrimary.cs
// Do not modify this file directly!!
// tslint:disable

import { LabelledItem } from '../Common/LabelledItem';

// LawMaster.Amalthea.Domain.Contacts.Item.CollectionPrimary
export class CollectionPrimary<T> {

	// Items
	items: LabelledItem<T>[];
	// PrimaryIndex
	primaryIndex: number;

	constructor() {
		this.items = [];
	}
}