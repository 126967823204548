import { ListResponse } from '@common/models/Generic/ListResponse';
import { DomainError } from '@common/models/Validation/DomainError';

import { CostCodeListItemDto } from '@common/models/Settings/CostCodes/List/CostCodeListItemDto';
import { CostCodeListRequest } from '@common/models/Settings/CostCodes/List/CostCodeListRequest';
import { AppConfig } from 'app/app.config';

export interface ICostCodeListState {
	isFetching: boolean;
	list: ListResponse<CostCodeListItemDto>;
	lastSelectedRecordId: string;
	request: Partial<CostCodeListRequest>;

	// errrors
	error: DomainError;
}

export const initialState: ICostCodeListState = {
	isFetching: false,
	list: null,
	lastSelectedRecordId: null,
	request: {
		search: null,

		pageSize: AppConfig.PageSize,
		pageIndex: 0,
		pageIndexForId: null,

		sortBy: null,
		sortDirection: null,
		isEnabled: true,
		isFixedFee: false
	},

	error: null
};
