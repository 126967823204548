import { UserTypeListItemDto } from '@common/models/Settings/UserTypes/List/UserTypeListItemDto';
import { createSelector, MemoizedSelector } from '@ngrx/store';

import { IAppState } from '../../../app.state';

export const selectUserTypeList = (state: IAppState) => state.userTypeList;

export const selectUserTypeListRecords: MemoizedSelector<IAppState, UserTypeListItemDto[]> = createSelector(
	selectUserTypeList,
	state => state?.list?.records || []
);
export const selectUserTypeListRequest: MemoizedSelector<IAppState, Partial<any>> = createSelector(
	selectUserTypeList,
	state => state?.request
);
export const selectUserTypeListPageIndex = createSelector(
	selectUserTypeList,
	state => state?.list?.pageIndex || 0
);
export const selectUserTypeListTotalRecords = createSelector(
	selectUserTypeList,
	state => state?.list?.totalRecords || 0
);
export const selectUserTypeIsFetching = createSelector(selectUserTypeList, state => !!state?.isFetching);
