<ng-template [ngIf]="!(isPerson || isCompany || isPlural)">
	<mat-radio-group [(ngModel)]="type" class="flex-column">
		<mat-radio-button value="Person">Person</mat-radio-button>
		<mat-radio-button value="Company">Company</mat-radio-button>
		<mat-radio-button value="Plural">Plural</mat-radio-button>
	</mat-radio-group>
</ng-template>

<div [formGroup]="form" class="flex-column">
	<ng-template [ngIf]="isPlural">
		<div class="flex-row">
			<div class="list-header">
				<h3 class="title-text">Members</h3>
				<button class="add-button" mat-button color="primary" throttleButton (throttledClick)="addMember()">
					<mat-icon>add_circle</mat-icon>
					<span>&nbsp;New <span class="allocation-word">member</span></span>
				</button>
			</div>
		</div>

		<div class="flex-row">
			<div class="flex-column">
				<div class="flex-row flex-row--list" *ngFor="let contactControl of memberControls; let i = index" formArrayName="memberIds">
					<contact-lookup
						#member
						[allowPlurals]="false"
						[FormControl]="contactControl"
						[AllowCreate]="true"
						[CreatePersonsCtrl]="form.get('createPersons')"
						[CreateCompaniesCtrl]="form.get('createCompanies')"
						[Required]="true"
						Placeholder="Contact"
					></contact-lookup>

					<button
						mat-icon-button
						[title]="memberHasContactInfo(contactControl) ? 'Fill Contact Info' : 'No Contact Info Recorded'"
						[disabled]="!memberHasContactInfo(contactControl)"
						class="list-button"
						throttleButton
						(throttledClick)="fillContactInfoForMember(i)"
					>
						<mat-icon>contact_mail</mat-icon>
					</button>

					<button
						mat-icon-button
						[title]="memberHasAddress(contactControl) ? 'Fill Address' : 'No Address Recorded'"
						[disabled]="!memberHasAddress(contactControl)"
						class="list-button"
						throttleButton
						(throttledClick)="fillAddressForMember(i)"
					>
						<mat-icon>place</mat-icon>
					</button>

					<button mat-icon-button title="Remove" class="list-button" throttleButton (throttledClick)="removeMember(i)">
						<mat-icon>delete</mat-icon>
					</button>
				</div>
			</div>
		</div>

		<mat-error class="members-error" [class.hide-opacity]="!!memberArray.pristine || !memberArray.touched">
			<error-messages [for]="memberArray"></error-messages>
		</mat-error>
	</ng-template>

	<ng-template [ngIf]="isPlural || isCompany">
		<div class="flex-row" *ngIf="isPlural">
			<h3 class="title-text">Details</h3>
		</div>
		<div class="flex-row">
			<mat-form-field>
				<input #fullNameInput formControlName="fullName" matInput autocomplete="off" />
				<mat-placeholder
					>Full Name
					<sup class="color-warn">*</sup>
				</mat-placeholder>
				<mat-error>
					<error-messages [for]="form.get('fullName')"></error-messages>
				</mat-error>
			</mat-form-field>
		</div>
	</ng-template>

	<ng-template [ngIf]="isPerson">
		<div class="flex-row">
			<mat-form-field>
				<input #firstNameInput formControlName="firstName" matInput autocomplete="off" />
				<mat-placeholder
					>First Name
					<sup class="color-warn">*</sup>
				</mat-placeholder>
				<mat-error>
					<error-messages [for]="form.get('firstName')"></error-messages>
				</mat-error>
			</mat-form-field>
			<mat-form-field>
				<input formControlName="middleName" matInput placeholder="Middle Name" autocomplete="off" />
				<mat-error>
					<error-messages [for]="form.get('middleName')"></error-messages>
				</mat-error>
			</mat-form-field>
			<mat-form-field>
				<input formControlName="lastName" matInput autocomplete="off" />
				<mat-placeholder
					>Last Name
					<sup class="color-warn">*</sup>
				</mat-placeholder>
				<mat-error>
					<error-messages [for]="form.get('lastName')"></error-messages>
				</mat-error>
			</mat-form-field>
		</div>
		<div class="flex-row">
			<mat-form-field>
				<input formControlName="company" matInput placeholder="Organisation" autocomplete="off" />
				<mat-error>
					<error-messages [for]="form.get('company')"></error-messages>
				</mat-error>
			</mat-form-field>
			<mat-form-field>
				<input formControlName="jobTitle" matInput placeholder="Job Title" autocomplete="off" />
				<mat-error>
					<error-messages [for]="form.get('jobTitle')"></error-messages>
				</mat-error>
			</mat-form-field>
		</div>
	</ng-template>

	<ng-template [ngIf]="isPerson || isCompany || isPlural">
		<div class="flex-row">
			<mat-form-field>
				<input formControlName="abn" matInput placeholder="ABN" autocomplete="off" />
				<mat-error>
					<error-messages [for]="form.get('abn')"></error-messages>
				</mat-error>
			</mat-form-field>
			<mat-form-field *ngIf="isCompany">
				<input formControlName="acn" matInput placeholder="ACN" autocomplete="off" />
				<mat-error>
					<error-messages [for]="form.get('acn')"></error-messages>
				</mat-error>
			</mat-form-field>
		</div>

		<div class="flex-row">
			<editable-phone [control]="form.get('phones.items.0.item')" [editMode]="true" class="flex-row"></editable-phone>
			<mat-form-field>
				<input [formControl]="emailItemFormControl" matInput placeholder="Email" autocomplete="off" />
				<mat-error>
					<error-messages [for]="form.get('emails.items.0.item')"></error-messages>
				</mat-error>
			</mat-form-field>
		</div>
		<editable-address [control]="form.get('addresses.items.0.item')" [editMode]="true" [optional]="true"></editable-address>
		<input-custom-fields [formGroup]="getCustomFieldsFormGroup()" entityType="Contact" [contactType]="type" [useDefaults]="true"></input-custom-fields>
	</ng-template>

	<div class="info-message color-accent" *ngIf="isPerson || isCompany || isPlural">
		<mat-icon color="accent">info</mat-icon>
		<span> Once created you can update your contact with additional information</span>
	</div>
</div>
