import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { GoogleAnalyticsService } from '@common/google-analytics/google-analytics.service';
import { PracticeType } from '@common/models/Settings/Setting/Item/PracticeType';
import { DomainError } from '@common/models/Validation/DomainError';
import { NotificationService } from '@common/notification';
import { GeneralSettingsService } from '@common/services/settings/generalsettings.service';
import { TenantActions } from '@common/state/actions/tenant.actions';
import { ICurrentUserData } from '@common/state/models/current-user-data';
import { Store } from '@ngrx/store';

import { AppBrandingService } from 'app/services/app-branding.service';

@Component({
	selector: 'welcome-dialog',
	styleUrls: ['./welcome-dialog.component.scss'],
	templateUrl: 'welcome-dialog.component.html'
})
export class WelcomeDialogComponent implements OnInit, OnDestroy {
	form: FormGroup;
	error: string;
	requestInProgress: boolean = false;
	practiceTypeKeys = Object.keys(PracticeType) as Array<keyof typeof PracticeType>;
	userCreated: boolean = false;

	get mainIcon() {
		return `/assets/img/${this.appBrandingService.mainIcon}`;
	}

	get imageAlt() {
		return this.appBrandingService.imageAlt;
	}

	private subscriptions = new Subscription();

	constructor(
		private store: Store<{ currentUserData: ICurrentUserData }>,
		private fb: FormBuilder,
		private dialogRef: MatDialogRef<WelcomeDialogComponent>,
		private notificationService: NotificationService,
		private generalSettingsService: GeneralSettingsService,
		private googleAnalyticsService: GoogleAnalyticsService,
		private route: ActivatedRoute,
		private appBrandingService: AppBrandingService
	) {}

	ngOnInit(): void {
		this.form = this.fb.group({
			practiceType: ['', [Validators.required]],
			companyName: [''],
			transientXeroId: [null]
		});
		this.googleAnalyticsService.trackRegistrationPage('loaded');

		this.subscriptions.add(
			this.route.queryParams.subscribe(params => {
				if (params.TransientXeroId) this.form.controls.transientXeroId.setValue(params.TransientXeroId);
			})
		);

		this.subscriptions.add(
			this.store
				.select(state => state?.currentUserData?.currentUser)
				.pipe(filter(Boolean))
				.subscribe(() => {
					this.userCreated = true;
				})
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	getStarted(): void {
		this.requestInProgress = true;

		if (this.form.valid) {
			this.googleAnalyticsService.trackRegistrationPage('submitted');
			this.subscriptions.add(
				this.generalSettingsService.initializeTenant(this.form.value).subscribe(() => {
					this.store.dispatch({ type: TenantActions.LoadTenant });
					this.dialogRef.close(this.form.value.transientXeroId);
				}, this.onError)
			);
		} else {
			this.requestInProgress = false;
			Object.keys(this.form.controls).forEach(field => {
				const control = this.form.get(field);
				control.markAsTouched({ onlySelf: true });
			});
		}
	}

	private onError = (errors: DomainError[]) => {
		this.notificationService.showErrors('Error', errors);
		this.requestInProgress = false;
	};
}
