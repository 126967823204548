import { Pipe, PipeTransform } from '@angular/core';

/*
 * Resolve task status display string by the enum key
 */
@Pipe({ name: 'formatAcn' })
export class AcnFormatterPipe implements PipeTransform {
	transform(acn: string): string {
		if (!!acn && acn.length == 9) {
			return `${acn.substr(0, 3)} ${acn.substr(3, 3)} ${acn.substr(6, 3)}`;
		}

		return acn;
	}
}
