<h2 mat-dialog-title class="title">{{ isCreateMode ? 'Create' : 'Edit' }} Security Role</h2>
<mat-dialog-content [formGroup]="form" class="flex-column">
	<div class="flex-column">
		<mat-form-field>
			<mat-placeholder
				>Name
				<sup class="color-warn">*</sup>
			</mat-placeholder>
			<input #securityRoleNameInput matInput formControlName="name" autocomplete="off" />
			<mat-error>
				<error-messages [for]="form.get('name')"></error-messages>
			</mat-error>
		</mat-form-field>
		<div class="policies">
			<h4>Policies</h4>
			<ng-container *ngFor="let policyKey of policyKeys">
				<mat-slide-toggle [checked]="isPolicyChecked(policyKey)" (change)="onPolicyChanged($event, policyKey)">
					<div class="policy-text">
						<span>{{ policyDisplayName(policyKey) }}</span>
						<span class="hint">{{ policyHintText(policyKey) }}</span>
					</div>
				</mat-slide-toggle>

				<ng-container *ngIf="policyKey === 'SystemSettings'">
					<div class="permissions">
						<mat-slide-toggle
							*ngFor="let permissionKey of systemPermissionKeys"
							[checked]="isPermissionChecked(permissionKey)"
							(change)="onPermissionChanged($event, permissionKey)"
							[disabled]="!isPolicyChecked('SystemSettings')"
						>
							<div class="permission-text">
								<span>{{ permissionDisplayName(permissionKey) }}</span>
								<span class="hint">{{ permissionHintText(permissionKey) }}</span>
							</div>
						</mat-slide-toggle>
					</div>
				</ng-container>

				<ng-container *ngIf="policyKey === 'Trust'">
					<div class="permissions">
						<mat-slide-toggle
							*ngFor="let permissionKey of trustPermissionKeys"
							[checked]="isPermissionChecked(permissionKey)"
							(change)="onPermissionChanged($event, permissionKey)"
							[disabled]="!isPolicyChecked('Trust')"
						>
							<div class="permission-text">
								<span>{{ permissionDisplayName(permissionKey) }}</span>
								<span class="hint">{{ permissionHintText(permissionKey) }}</span>
							</div>
						</mat-slide-toggle>
					</div>
				</ng-container>

				<ng-container *ngIf="policyKey === 'GlobalListSettings'">
					<div class="permissions">
						<mat-slide-toggle
							*ngFor="let permissionKey of globalListPermissionKeys"
							[checked]="isPermissionChecked(permissionKey)"
							(change)="onPermissionChanged($event, permissionKey)"
							[disabled]="!isPolicyChecked('GlobalListSettings')"
						>
							<div class="permission-text">
								<span>{{ permissionDisplayName(permissionKey) }}</span>
								<span class="hint">{{ permissionHintText(permissionKey) }}</span>
							</div>
						</mat-slide-toggle>
					</div>
				</ng-container>
			</ng-container>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<div>
		<button mat-raised-button color="primary" [disabled]="form.pristine || form.invalid" (click)="onSaveClicked()">Save</button>
		<button mat-raised-button mat-dialog-close>Cancel</button>
	</div>
</mat-dialog-actions>
