import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';

import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { NotificationService } from '@common/notification';
import { SystemTagsCachedService } from '@common/services/system-tags-cache.service';
import { newGuid } from '@common/utils/create-guid';

import { isEmptyOrSpaces } from '../../main/src/app/shared/utils/stringUtil';

@Component({
	styleUrls: ['./base-document-tag.component.scss'],
	templateUrl: './base-document-tag.component.html'
})
export class BaseDocumentTagComponent implements OnDestroy {
	@Output()
	BulkAddCalled = new EventEmitter<string>();

	@Output()
	BulkRemoveCalled = new EventEmitter<string>();

	@Input()
	placeholder: string;

	@Input()
	showBulkOptions: boolean;

	inputCtrl = new FormControl();

	@Input() Id: string;
	private _formControl: AbstractControl;

	@Input()
	set FormControl(val: AbstractControl) {
		this._formControl = val;

		if (!!this._formControl) {
			this.setTagsFromControl();
		}
	}
	get FormControl() {
		return this._formControl;
	}

	filteredTags: Observable<string[]>;

	private _activeTags: string[] = [];
	@Input()
	set ActiveTags(val: string[]) {
		this._activeTags = val;
		this.FormControl?.setValue(this._activeTags);
		this.inputCtrl.updateValueAndValidity();
		this.ActiveTagsChange.emit(this._activeTags);
	}
	get ActiveTags() {
		return this._activeTags;
	}

	@Output()
	ActiveTagsChange = new EventEmitter<string[]>();

	systemTags: string[];
	separatorKeysCodes: number[] = [ENTER, COMMA];

	private subscriptions: Subscription = new Subscription();

	private _matterId: string;
	@Input()
	set matterId(val: string) {
		this._matterId = val;

		this.getSystemTags();
	}
	get matterId(): string {
		return this._matterId;
	}

	private _practiceAreaIds: string[];
	@Input()
	set practiceAreaIds(val: string[]) {
		if (!val || !val[0]) this._practiceAreaIds = [];
		else this._practiceAreaIds = val;

		this.getSystemTags();
	}
	get practiceAreaIds(): string[] {
		return this._practiceAreaIds;
	}

	get availableTags(): string[] {
		return this.systemTags?.filter(
			systemTag => !this.ActiveTags?.map(v => v.toLowerCase())?.includes(systemTag.toLowerCase())
		);
	}

	@ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
	@ViewChild('auto') matAutocomplete: MatAutocomplete;

	optionClassId = newGuid();

	constructor(
		private systemTagsCachedService: SystemTagsCachedService,
		private notificationService: NotificationService
	) {}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	getSystemTags() {
		let getTagsObservable: Observable<string[]>;
		if (!!this.matterId) {
			getTagsObservable = this.systemTagsCachedService.getSystemTagsByMatter(this.matterId);
		} else if (!!this.practiceAreaIds) {
			getTagsObservable = this.systemTagsCachedService.getSystemTagsByPracticeAreas(this.practiceAreaIds);
		}

		if (!!getTagsObservable) {
			this.subscriptions.add(
				getTagsObservable.subscribe(
					(tags: string[]) => this.setTags(tags),
					errors => this.notificationService.showErrors(`Failed to obtain tags`, errors)
				)
			);
		}
	}

	setTags(tags: string[]) {
		if (!!tags) {
			this.systemTags = tags;
			this.filteredTags = this.inputCtrl.valueChanges.pipe(
				startWith(null),
				map((tag: string | null) => (tag ? this.filterTags(tag) : this.availableTags?.slice()))
			);

			this.inputCtrl.updateValueAndValidity();
		}
	}

	private setTagsFromControl(): void {
		this.ActiveTags = this.FormControl?.value;
		this.ActiveTagsChange.emit(this.ActiveTags);
	}

	onKeydown(event: KeyboardEvent) {
		event.stopPropagation();
		if (event.key?.toLowerCase() === 'enter' && this.matAutocomplete.isOpen) {
			this.add({ input: this.tagInput.nativeElement, value: this.inputCtrl.value });
		}
	}

	onBlur(event: FocusEvent) {
		if (!!(event?.relatedTarget as HTMLElement)?.classList?.contains(`document-tag-option-${this.optionClassId}`)) {
			return;
		}

		this.add({ input: this.tagInput.nativeElement, value: this.inputCtrl.value });
	}

	add(event: MatChipInputEvent): void {
		if (isEmptyOrSpaces(event.value)) {
			return;
		}

		if (!this.ActiveTags?.filter(tag => tag.toLowerCase() === event.value.toLowerCase())?.length) {
			let value = event.value?.trim();

			let matchingTag = this.systemTags?.filter(tag => tag.toLowerCase() === value.toLowerCase())[0];
			if (!!matchingTag) {
				value = matchingTag;
			}

			if (!this.ActiveTags) this.ActiveTags = [];
			// Add our tag
			if (!!this.inputCtrl.value) {
				this.ActiveTags.push(value);
			}

			this.FormControl?.markAsDirty();
			this.FormControl?.markAsTouched();
			this.FormControl?.setValue(this.ActiveTags);

			this.ActiveTagsChange.emit(this.ActiveTags);
		}

		const input = event.input;

		// Reset the input value
		if (input) {
			input.value = '';
		}
		this.inputCtrl.setValue(null);
	}

	remove(tag: string): void {
		const index = this.ActiveTags?.map(v => v.toLowerCase()).indexOf(tag.toLowerCase());
		if (index >= 0) {
			this.ActiveTags.splice(index, 1);
		}

		this.FormControl?.markAsDirty();
		this.FormControl?.markAsTouched();
		this.FormControl?.setValue(this.ActiveTags);

		this.ActiveTagsChange.emit(this.ActiveTags);

		if (!!this.systemTags && this.systemTags?.map(v => v.toLowerCase()).indexOf(tag.toLowerCase()) >= 0) {
			this.inputCtrl.updateValueAndValidity();
		}
	}

	removeTag(tag: string) {
		this.remove(tag);
	}

	bulkAddTags(tag: string) {
		this.BulkAddCalled.emit(tag);
	}

	bulkRemoveTags(tag: string) {
		this.BulkRemoveCalled.emit(tag);
	}

	selected(event: MatAutocompleteSelectedEvent): void {
		if (!this.ActiveTags) this.ActiveTags = [];
		this.ActiveTags.push(event.option.viewValue);
		this.tagInput.nativeElement.value = '';
		this.inputCtrl.setValue(null);
		this.FormControl?.setValue(this.ActiveTags);
		this.inputCtrl.updateValueAndValidity();
		this.tagInput.nativeElement.blur();
		this.ActiveTagsChange.emit(this.ActiveTags);
	}

	private filterTags(value: string): string[] {
		const filterValue = value.toLowerCase();
		return this.availableTags?.filter(tag => tag.toLowerCase().includes(filterValue));
	}
}
