import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NotificationService } from '@common/notification';
import { AppBrandingService } from 'app/services/app-branding.service';
import { detect } from 'detect-browser';

export enum DragEvents {
	dragOver,
	dragOut,
	drop
}

// The code has been inspired by https://github.com/bleenco/ngx-uploader
@Directive({
	selector: '[fileDrop]'
})
export class NgFileDropDirective implements OnInit, OnDestroy {
	@Input('fileDrop')
	get fileDrop(): boolean {
		return this._fileDrop;
	}
	set fileDrop(val: boolean) {
		this._fileDrop = val;
		this.setListeners(val);
	}
	@Output()
	filesDragged: EventEmitter<DragEvents>;
	@Output()
	filesDropped: EventEmitter<FileList>;

	private _fileDrop: boolean = true;
	private _listenersAreSet: boolean = false;

	constructor(public elementRef: ElementRef, private notification: NotificationService) {
		this.filesDropped = new EventEmitter<FileList>();
		this.filesDragged = new EventEmitter<DragEvents>();
	}

	ngOnInit() {
		this.setListeners(this.fileDrop);
	}

	stopEvent = (e: Event) => {
		e.stopPropagation();
		e.preventDefault();
	};

	onDrop = (e: any) => {
		const event: DragEvents = DragEvents.drop;
		this.filesDragged.emit(event);

		if (!!e.dataTransfer.files && e.dataTransfer.files.length > 0) {
			this.filesDropped.emit(e.dataTransfer.files);
		} else {
			const browser = detect();

			if (!!browser && !!browser.name && browser.name === 'firefox') {
				this.notification.showError(
					'Cannot Upload Document',
					`Firefox has a known issue with drag and drop that the team at Mozilla are currently addressing. Until their bug is resolved ${AppBrandingService.getSiteName()} is unable to support drag and drop on Firefox.<br /><br />
					As a workaround you can use another browser like Chrome or Edge to drag and drop files and emails.`
				);
			}
		}
		this.stopEvent(e);
	};

	onDragOver = (e: Event) => {
		if (!e) {
			return;
		}

		const event: DragEvents = DragEvents.dragOver;
		this.filesDragged.emit(event);
		// This adds the copy behaviour to files on DragOver so the file gets copied and not moved
		(e as any).dataTransfer.dropEffect = 'copy';
		this.stopEvent(e);
	};

	onDragLeave = (e: Event) => {
		if (!e) {
			return;
		}
		this.stopEvent(e);
		const event: DragEvents = DragEvents.dragOut;
		this.filesDragged.emit(event);
	};

	ngOnDestroy() {
		this.setListeners(false);
	}

	private setListeners(set: boolean): void {
		const el = this.elementRef.nativeElement;
		if (!!el && !this._listenersAreSet) {
			if (set) {
				el.addEventListener('drop', this.onDrop, false);
				el.addEventListener('dragenter', this.stopEvent, false);
				el.addEventListener('dragover', this.onDragOver, false);
				el.addEventListener('dragleave', this.onDragLeave, false);
				this._listenersAreSet = true;
			} else {
				el.removeEventListener('drop', this.onDrop, false);
				el.removeEventListener('dragenter', this.stopEvent, false);
				el.removeEventListener('dragover', this.onDragOver, false);
				el.removeEventListener('dragleave', this.onDragLeave, false);
				this._listenersAreSet = false;
			}
		}
	}
}
