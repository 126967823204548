// This file has been generated from TrustMediaType.cs
// Do not modify this file directly!!

// LawMaster.Amalthea.Domain.Settings.Setting.Item.TrustMediaType
export enum TrustMediaType {
	Cheque = 'Cheque',
	BankCheque = 'Bank Cheque',
	CreditCard = 'Credit Card',
	EFT = 'EFT',
	Cash = 'Cash',
	OpeningBalance = 'Opening Balance',
	DirectDepositCash = 'Direct Deposit Cash',
	DirectDepositCheque = 'Direct Deposit Cheque',
	PEXADirectDebit = 'PEXA Direct Debit'
}
