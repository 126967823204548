import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Subscription } from 'rxjs';

import { ExportBriefParametersDto } from '@common/models/DocumentBriefs/Item/ExportBriefParametersDto';
import { ExportPDFDto } from '@common/models/DocumentBriefs/Item/ExportPDFDto';
import { PageNumberOptions } from '@common/models/DocumentBriefs/Item/PageNumberOptions';
import { PagePosition } from '@common/models/DocumentBriefs/Item/PagePosition';
import { NotificationService } from '@common/notification';
import { DocumentBriefsService } from '@common/services/documentbriefs.service';

import { CustomValidators } from '@common/validation/custom.validators';
import { IFontOption } from 'app/shared/components/font-option/font-option.component';

@Component({
	selector: 'brief-pdf-export',
	styleUrls: ['./brief-pdf-export-dialog.component.scss'],
	templateUrl: './brief-pdf-export-dialog.component.html'
})
export class BriefPDFExportDialogComponent implements OnInit, OnDestroy {
	form: FormGroupTyped<ExportPDFDto>;
	pagePositionKeys = Object.keys(PagePosition) as Array<keyof typeof PagePosition>;

	private subscriptions: Subscription = new Subscription();

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: Partial<IBriefPDFExportData>,
		private fb: FormBuilder,
		private notificationService: NotificationService,
		private dialogRef: MatDialogRef<BriefPDFExportDialogComponent>,
		private documentBriefsService: DocumentBriefsService,
		private cdr: ChangeDetectorRef
	) {
		this.pagePositionKeys = this.pagePositionKeys.sort();
	}

	get isPasswordProtect(): boolean {
		return !!this.form?.controls?.securityOptions?.value?.passwordProtect;
	}

	get isPreventModification(): boolean {
		return !!this.form?.controls?.securityOptions?.value?.preventModification;
	}

	ngOnInit(): void {
		const params = this.data.parameters.lastUsedExportParameters;

		this.form = this.fb.group({
			documentTitle: [
				params?.documentTitle ?? this.data?.parameters?.briefName,
				CustomValidators.required('Document Title')
			],
			sectionOptions: this.fb.group({
				selectedSectionsOnly: [params?.sectionOptions?.selectedSectionsOnly],
				sectionIds: [
					params?.sectionOptions?.sectionIds?.filter(
						x => this.data.parameters.sections.findIndex(s => s.id === x) >= 0
					),
					CustomValidators.requiredWhenMessage(
						() => this.selectedSectionsOnly,
						'At least one section must be selected'
					)
				]
			}),
			titlePageOptions: this.fb.group({
				includeTitlePage: [params?.titlePageOptions?.includeTitlePage],
				templateId: [
					params?.titlePageOptions?.templateId,
					CustomValidators.requiredWhen(() => this.includeTitlePage, 'Template')
				]
			}),
			contentsPageOptions: this.fb.group({
				includeContentsPage: [params?.contentsPageOptions?.includeContentsPage],
				hidePageNumbering: [params?.contentsPageOptions?.hidePageNumbering]
			}),
			documentSummaryPage: params?.documentSummaryPage,
			includeEmailAttachments: params?.includeEmailAttachments,
			sectionTitlePage: params?.sectionTitlePage,
			securityOptions: this.fb.group({
				includeSecurityOptions: [params?.securityOptions?.includeSecurityOptions],
				passwordProtect: [params?.securityOptions?.passwordProtect],
				userPassword: [
					params?.securityOptions?.userPassword,
					CustomValidators.requiredWhen(() => this.isPasswordProtect, 'Enter Password')
				],
				ownerPassword: [
					params?.securityOptions?.ownerPassword,
					CustomValidators.requiredWhen(() => this.isPreventModification, 'Enter Password')
				],
				preventModification: [params?.securityOptions?.preventModification]
			}) as FormGroupTyped<PageNumberOptions>,
			pageNumberOptions: this.fb.group({
				includePageNumber: [params?.pageNumberOptions?.includePageNumber],
				pagePosition: [
					params?.pageNumberOptions?.pagePosition,
					CustomValidators.requiredWhen(
						() => this.form?.value?.pageNumberOptions?.includePageNumber,
						'Page Position'
					)
				],
				pageNumberFormat: [
					params?.pageNumberOptions?.pageNumberFormat,
					CustomValidators.requiredWhen(
						() => this.form?.value?.pageNumberOptions?.includePageNumber,
						'Page Number Format'
					)
				],
				horizontalOffset: [
					params?.pageNumberOptions?.horizontalOffset,
					CustomValidators.requiredWhen(
						() => this.form?.value?.pageNumberOptions?.includePageNumber,
						'Horizontal Offset'
					)
				],
				verticalOffset: [
					params?.pageNumberOptions?.verticalOffset,
					CustomValidators.requiredWhen(
						() => this.form?.value?.pageNumberOptions?.includePageNumber,
						'Vertical Offset'
					)
				],
				fontName: [
					params?.pageNumberOptions?.fontName,
					CustomValidators.requiredWhen(
						() => this.form?.value?.pageNumberOptions?.includePageNumber,
						'Font Name'
					)
				],
				fontSize: [
					params?.pageNumberOptions?.fontSize,
					CustomValidators.requiredWhen(
						() => this.form?.value?.pageNumberOptions?.includePageNumber,
						'Font Size'
					)
				],
				isBold: params?.pageNumberOptions?.isBold,
				isItalic: params?.pageNumberOptions?.isItalic,
				isUnderline: params?.pageNumberOptions?.isUnderline,
				isStrikeout: params?.pageNumberOptions?.isStrikeout
			}) as FormGroupTyped<PageNumberOptions>
		}) as FormGroupTyped<ExportPDFDto>;

		this.subscriptions.add(
			this.form.valueChanges.subscribe(x => {
				this.cdr.detectChanges();
			})
		);

		this.subscriptions.add(
			this.form
				.get('securityOptions')
				.get('passwordProtect')
				.valueChanges.subscribe(() => {
					setTimeout(() => {
						this.form.get('securityOptions').get('userPassword').updateValueAndValidity();
					}, 0);
				})
		);

		this.subscriptions.add(
			this.form
				.get('securityOptions')
				.get('preventModification')
				.valueChanges.subscribe(() => {
					setTimeout(() => {
						this.form.get('securityOptions').get('ownerPassword').updateValueAndValidity();
					}, 0);
				})
		);

		this.subscriptions.add(
			this.form.controls.sectionOptions.get('selectedSectionsOnly').valueChanges.subscribe((value: boolean) => {
				setTimeout(() => {
					this.form.controls.sectionOptions.get('sectionIds').updateValueAndValidity();
				}, 0);
			})
		);
		this.subscriptions.add(
			this.form.controls.titlePageOptions.get('includeTitlePage').valueChanges.subscribe((value: boolean) => {
				setTimeout(() => {
					this.form.controls.titlePageOptions.get('templateId').updateValueAndValidity();
				}, 0);
			})
		);
		this.subscriptions.add(
			this.form.controls.pageNumberOptions.get('includePageNumber').valueChanges.subscribe((value: boolean) => {
				setTimeout(() => {
					this.form.controls.pageNumberOptions.get('pagePosition').updateValueAndValidity();
					this.form.controls.pageNumberOptions.get('pageNumberFormat').updateValueAndValidity();
					this.form.controls.pageNumberOptions.get('horizontalOffset').updateValueAndValidity();
					this.form.controls.pageNumberOptions.get('verticalOffset').updateValueAndValidity();
					this.form.controls.pageNumberOptions.get('fontName').updateValueAndValidity();
					this.form.controls.pageNumberOptions.get('fontSize').updateValueAndValidity();
				}, 0);
			})
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	get sectionIdsControl(): AbstractControl {
		const group = this.form?.controls?.sectionOptions as AbstractControl as FormGroup;
		return group?.controls.sectionIds;
	}

	get selectedSectionsOnly(): boolean {
		return this.form?.value?.sectionOptions?.selectedSectionsOnly;
	}

	get includeTitlePage(): boolean {
		return this.form?.controls?.titlePageOptions?.value?.includeTitlePage;
	}

	get includePageNumber(): boolean {
		return this.form?.value?.pageNumberOptions?.includePageNumber;
	}

	get fontOptionsGroup(): FormGroupTyped<IFontOption> {
		return this.form?.controls?.pageNumberOptions as any as FormGroupTyped<IFontOption>;
	}

	exportPdf() {
		this.subscriptions.add(
			this.documentBriefsService.exportToPDF(this.data.briefId, this.form.value).subscribe(
				() => {
					this.notificationService.showNotification(`Export started...`);
					this.dialogRef.close();
				},
				errs => {
					this.notificationService.showErrors(`Error exporting brief`, errs);
				}
			)
		);
	}
}

export interface IBriefPDFExportData {
	briefId: string;
	parameters: ExportBriefParametersDto;
}
