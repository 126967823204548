import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { Observable, of } from 'rxjs';

import { FontLookupDto, SystemFontsService } from 'app/services/system-fonts.service';

import { BaseLookupComponent } from '@common/components/lookups/base-lookup.component';

@Component({
	selector: 'font-lookup',
	styleUrls: ['../../../../../common/components/lookups/base-lookup.component.scss', 'font-lookup.component.scss'],
	templateUrl: 'font-lookup.component.html'
})
export class FontLookupComponent extends BaseLookupComponent<FontLookupDto> {
	@Input()
	FormControl: FormControl;
	@Input()
	FontSize: string;
	@Input()
	HasAutofocus: boolean;
	@Input()
	Placeholder: string;
	@Input()
	Hint: string;
	@Input()
	Required: boolean;

	constructor(private systemFontsService: SystemFontsService) {
		super();
	}

	optionHtmlText(input: FontLookupDto): string {
		return input.id;
	}

	displayText(input: FontLookupDto) {
		return input.id;
	}

	lookup(id: string): Observable<FontLookupDto> {
		return of(this.systemFontsService.lookupFont(id));
	}

	search(term: string): Observable<FontLookupDto[]> {
		return of(this.systemFontsService.lookupFontList({ term }));
	}
}
