<h2 mat-dialog-title class="title">
	<span>{{ !!isEdit ? 'Update' : 'Generate' }} API Key</span>
</h2>
<mat-dialog-content [formGroup]="form">
	<div *ngIf="!isEdit">Create a user specific key used for integrating with third party systems.</div>

	<div class="api-key-description" *ngIf="!key || !!isEdit">
		<mat-form-field>
			<mat-placeholder> Description <sup class="color-warn">*</sup> </mat-placeholder>
			<input formControlName="description" matInput />
			<mat-error> <error-messages [for]="form.get('description')"></error-messages> </mat-error>
		</mat-form-field>
	</div>

	<div *ngIf="!!key && !isEdit" class="api-key-block text-gap">
		<div>API Key</div>
		<div class="api-key">
			<span>{{ key }}</span>

			<button mat-icon-button color="primary" throttleButton (throttledClick)="onCopyButtonClick()">
				<mat-icon>file_copy</mat-icon>
			</button>
		</div>
	</div>

	<div *ngIf="!!key && !isEdit" class="warning-text text-gap">
		After this dialog is closed you will no longer be able to retreive this key from {{ siteName }}.
	</div>
</mat-dialog-content>
<mat-dialog-actions class="dialog-actions">
	<button mat-raised-button color="primary" throttleButton (throttledClick)="onGenerateButtonClick()" [disabled]="form.invalid || !!key">
		{{ !!isEdit ? 'Save' : 'Generate' }}
	</button>
	<button mat-raised-button mat-dialog-close>Close</button>
</mat-dialog-actions>
