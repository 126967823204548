// This file has been generated from ImportUploadDto.cs
// Do not modify this file directly!!
// tslint:disable

import { ImportEntityType } from '../Common/ImportEntityType';

// LawMaster.Amalthea.Domain.Imports.Item.ImportUploadDto
export class ImportUploadDto {

	// Attachments
	attachments: File[];
	// EntityType
	entityType: keyof typeof ImportEntityType;

	constructor() {

	}
}