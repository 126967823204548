import { Component, Inject, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatOption, MatOptionSelectionChange } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';

import { Subscription } from 'rxjs';

import { EnumSortDirection } from '@common/models/Common/EnumSortDirection';
import { InstallationRecordInstallDetailsSlimDto } from '@common/models/Marketplace/InstallationRecords/Item/InstallationRecordInstallDetailsSlimDto';
import { InstallationRecordUninstallDetailsSlimDto } from '@common/models/Marketplace/InstallationRecords/Item/InstallationRecordUninstallDetailsSlimDto';
import { PracticeAreaListItemDto } from '@common/models/Settings/PracticeAreas/List/PracticeAreaListItemDto';
import { NotificationService } from '@common/notification';
import { GeneralSettingsService } from '@common/services/settings/generalsettings.service';
import { MarketplaceService } from '@common/services/settings/marketplace.service';
import { PracticeAreasService } from '@common/services/settings/practiceareas.service';
import { CustomValidators } from '@common/validation/custom.validators';

import { AppBrandingService } from 'app/services/app-branding.service';

@Component({
	selector: 'marketplace-listing-operation-dialog',
	styleUrls: ['./marketplace-listing-operation-dialog.component.scss'],
	templateUrl: 'marketplace-listing-operation-dialog.component.html'
})
export class MarketplaceListingOperationDialogComponent implements OnInit, OnDestroy {
	@ViewChild('allOption') allOption: MatOption;
	@ViewChild('selectPracticeAreas') selectPracticeAreas: MatSelect;

	private _subscriptions = new Subscription();

	private _details: InstallationRecordInstallDetailsSlimDto | InstallationRecordUninstallDetailsSlimDto;
	private _stageType = StageType.Loading;

	private _practiceAreas: PracticeAreaListItemDto[];

	private _form: FormGroup;

	get isUninstall() {
		return (
			!!this._data?.operationType &&
			this._data.operationType === MarketplaceListingOperationDialogDataType.Uninstall
		);
	}

	get isSubscriptionRequired() {
		return false;
	}

	get isInstall() {
		return (
			!!this._data?.operationType &&
			this._data.operationType === MarketplaceListingOperationDialogDataType.Install
		);
	}

	get isUpdate() {
		return (
			!!this._data?.operationType && this._data.operationType === MarketplaceListingOperationDialogDataType.Update
		);
	}

	get isConfigure() {
		return (
			!!this._data?.operationType &&
			this._data.operationType === MarketplaceListingOperationDialogDataType.Configure
		);
	}

	get isTermsAgreementRequired() {
		return !!this.productTermsUrl && !this.isConfigure && !this.isUninstall;
	}

	get productName() {
		return this._data.productName;
	}

	get productTermsUrl() {
		return this._data.productTermsUrl;
	}

	get operationName(): string {
		return !!this.isInstall ? 'Install' : !!this.isUninstall ? 'Uninstall' : !!this.isUpdate ? 'Update' : '';
	}

	get operationAdjectiveName(): string {
		return !!this.isInstall
			? 'Installing'
			: !!this.isUninstall
			? 'Uninstalling'
			: !!this.isUpdate
			? 'Updating'
			: '';
	}

	get affectedCustomFieldsCount() {
		return (
			(this._details as InstallationRecordInstallDetailsSlimDto)?.installableCustomFieldsCount ??
			(this._details as InstallationRecordUninstallDetailsSlimDto)?.removableCustomFieldsCount ??
			0
		);
	}

	get affectedCustomFieldGroupsCount() {
		return (
			(this._details as InstallationRecordInstallDetailsSlimDto)?.installableCustomFieldGroupsCount ??
			(this._details as InstallationRecordUninstallDetailsSlimDto)?.removableCustomFieldGroupsCount ??
			0
		);
	}

	get affectedDocumentTemplatesCount() {
		return (
			(this._details as InstallationRecordInstallDetailsSlimDto)?.installableDocumentTemplatesCount ??
			(this._details as InstallationRecordUninstallDetailsSlimDto)?.removableDocumentTemplatesCount ??
			0
		);
	}

	get affectedDocumentHeaderFooterTemplatesCount() {
		return (
			(this._details as InstallationRecordInstallDetailsSlimDto)?.installableDocumentHeaderFooterTemplatesCount ??
			(this._details as InstallationRecordUninstallDetailsSlimDto)?.removableDocumentHeaderFooterTemplatesCount ??
			0
		);
	}

	get affectedEmailTemplatesCount() {
		return (
			(this._details as InstallationRecordInstallDetailsSlimDto)?.installableDocumentEmailTemplatesCount ??
			(this._details as InstallationRecordUninstallDetailsSlimDto)?.removableDocumentEmailTemplatesCount ??
			0
		);
	}

	get affectedPracticeAreasCount() {
		return (this._details as InstallationRecordInstallDetailsSlimDto)?.practiceAreas?.length ?? 0;
	}

	get anyAffectedEntities() {
		return (
			this.affectedCustomFieldsCount > 0 ||
			this.affectedCustomFieldGroupsCount > 0 ||
			this.affectedDocumentTemplatesCount > 0 ||
			this.affectedEmailTemplatesCount > 0
		);
	}

	get packagePracticeAreaControls() {
		return (this._form.get('practiceAreaMappings') as FormArray).controls as FormGroup[];
	}

	get packagePracticeAreas() {
		return (this._details as InstallationRecordInstallDetailsSlimDto)?.practiceAreas;
	}

	get isConfirmationStage() {
		return this._stageType == StageType.Confirmation;
	}

	get isConfigureStage() {
		return this._stageType == StageType.Configure;
	}

	get isProcessingStage() {
		return this._stageType == StageType.Processing;
	}

	get practiceAreas(): PracticeAreaListItemDto[] {
		return this._practiceAreas;
	}

	get mapPracticeAreas() {
		return !!this.isConfigureStage && this.affectedPracticeAreasCount > 0;
	}

	get form(): FormGroup {
		return this._form;
	}

	get siteName() {
		return AppBrandingService.getSiteName();
	}

	constructor(
		@Optional() @Inject(MAT_DIALOG_DATA) private _data: IMarketplaceListingOperationDialogData,
		private _formBuilder: FormBuilder,
		private _marketplaceService: MarketplaceService,
		private _notificationService: NotificationService,
		private _practiceAreaService: PracticeAreasService,
		private _matDialogRef: MatDialogRef<MarketplaceListingOperationDialogComponent>,
		private _generalSettingsService: GeneralSettingsService
	) {}

	ngOnInit(): void {
		this._form = this._formBuilder.group({
			practiceAreaMappings: this._formBuilder.array([]),
			practiceAreaIds: [null, CustomValidators.required()],
			termsAccepted: !this.productTermsUrl ? false : [false, CustomValidators.isTrue('Terms and Conditions')],
			mapPracticeAreas: false
		});

		this._subscriptions.add(
			this._practiceAreaService
				.getPracticeAreaList({ sortBy: 'name', sortDirection: EnumSortDirection.Asc })
				.subscribe(practiceAreas => (this._practiceAreas = practiceAreas?.records))
		);

		if (!!this._data?.operationType) {
			if (this._data.operationType === MarketplaceListingOperationDialogDataType.Uninstall) {
				this._subscriptions.add(
					this._marketplaceService
						.getDetailsForUninstallPackage({
							productId: this._data.productId
						})
						.subscribe(details => {
							this._details = details;
							this._stageType = StageType.Confirmation;
						})
				);
			} else if (
				this._data.operationType === MarketplaceListingOperationDialogDataType.Install ||
				this._data.operationType === MarketplaceListingOperationDialogDataType.Update ||
				this._data.operationType === MarketplaceListingOperationDialogDataType.Configure
			) {
				this._subscriptions.add(
					this._marketplaceService
						.getDetailsForInstallPackage({
							productId: this._data.productId,
							packageVersion: this._data.packageVersion,
							isTrial: null
						})
						.subscribe(details => {
							this._details = details;
							this._stageType =
								this._data.operationType === MarketplaceListingOperationDialogDataType.Configure
									? StageType.Configure
									: StageType.Confirmation;

							if (this.packagePracticeAreas.length > 0) {
								this.packagePracticeAreas.forEach(practiceArea => {
									const practiceAreaMappingId =
										this._data.operationType !==
											MarketplaceListingOperationDialogDataType.Install &&
										!!details.practiceAreaMappings
											? details.practiceAreaMappings[practiceArea.id] ?? null
											: null;

									const group = this._formBuilder.group({
										practiceAreaId: practiceArea.id,
										practiceAreaMappingId: [practiceAreaMappingId]
									});

									(this._form.get('practiceAreaMappings') as FormArray).push(group);
								});
							}

							if (this._data.operationType === MarketplaceListingOperationDialogDataType.Configure) {
								this._form.get('mapPracticeAreas').setValue(true);
							}
						})
				);
			}
		}
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}

	executeOperation(): void {
		if (
			this.isConfirmationStage &&
			this._data.operationType === MarketplaceListingOperationDialogDataType.Install
		) {
			if (!this.mapPracticeAreas || (!!this.mapPracticeAreas && !!this._form.get('mapPracticeAreas').value)) {
				this._stageType = StageType.Configure;
			} else {
				this._stageType = StageType.Processing;

				this.processOperation();
			}

			this.form.markAsUntouched();
			this.form.markAsPristine();
		} else {
			this._stageType = StageType.Processing;

			this.form.markAsUntouched();
			this.form.markAsPristine();

			this.processOperation();
		}
	}

	processOperation() {
		const practiceAreaMappings: { [key: string]: string[] } = {};

		this.packagePracticeAreaControls?.forEach(control => {
			practiceAreaMappings[control.get('practiceAreaId').value] = control.get('practiceAreaMappingId').value;
		});

		const processing =
			this._data.operationType === MarketplaceListingOperationDialogDataType.Uninstall
				? this._marketplaceService.uninstallPackage({
						productId: this._data.productId
				  })
				: this._data.operationType === MarketplaceListingOperationDialogDataType.Configure
				? this._marketplaceService.updatePackagePracticeAreas({
						productId: this._data.productId,
						practiceAreaIds: this.form.controls.practiceAreaIds.value as string[],
						practiceAreaMappings: practiceAreaMappings
				  })
				: this._marketplaceService.installPackage({
						productId: this._data.productId,
						packageVersion: this._data.packageVersion,
						practiceAreaIds: this.form.controls.practiceAreaIds.value as string[],
						practiceAreaMappings: practiceAreaMappings,
						isTrial: null
				  });

		this._subscriptions.add(
			processing.subscribe(
				() => {
					this._matDialogRef.close(true);
				},
				errors => {
					this._notificationService.showCriticalErrors(errors);
					this._matDialogRef.close();
				}
			)
		);
	}

	getControl(formGroup: FormGroup, key: string) {
		return formGroup.get(key);
	}

	getControlValue(formGroup: FormGroup, key: string) {
		return formGroup.get(key)?.value;
	}

	getPracticeArea(id: string) {
		var results = this.packagePracticeAreas?.filter(practiceArea => practiceArea.id === id);

		return !!results?.length ? results[0] : null;
	}

	allOptionSelected(event: MatOptionSelectionChange): void {
		if (event.source.selected) {
			// When selecting the 'All' option, deselect all practice areas
			this.selectPracticeAreas.options.forEach(opt => {
				if (opt.selected && opt.id !== this.allOption.id) {
					opt.deselect();
				}
			});
		} else if (!this.selectPracticeAreas.options.some(opt => opt.selected)) {
			// If nothing is selected, select the 'All' option
			this.allOption.select();
		}
	}

	optionSelected(event: MatOptionSelectionChange): void {
		if (this.allOption.selected && event.source.selected && event.source.id !== this.allOption.id) {
			// When selecting a practice area, deselect the 'All' option
			this.allOption.deselect();
		} else if (!this.selectPracticeAreas.options.some(opt => opt.selected)) {
			// If nothing is selected, select the 'All' option
			this.allOption.select();
		}
	}
}

export interface IMarketplaceListingOperationDialogData {
	operationType: MarketplaceListingOperationDialogDataType;
	productId: string;
	productName: string;
	productTermsUrl: string;
	packageVersion: string;
}

export enum MarketplaceListingOperationDialogDataType {
	None = 0,
	Uninstall = 1,
	Install = 2,
	Update = 3,
	Configure = 4
}

enum StageType {
	Loading = 0,
	Confirmation = 1,
	Configure = 2,
	Processing = 3
}
