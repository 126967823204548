import { Pipe, PipeTransform } from '@angular/core';

import { PagePosition } from '@common/models/DocumentBriefs/Item/PagePosition';

/*
 * Resolve page position display string by the enum key
 */
@Pipe({ name: 'pagePosition' })
export class PagePositionPipe implements PipeTransform {
	transform(key: keyof typeof PagePosition): string {
		if (!PagePosition[key]) {
			throw Error(`Dev error: The Page Position enum does not contain the key ${key}`);
		}
		return PagePosition[key];
	}
}
