import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { filter, switchMap } from 'rxjs/operators';

import { EntityReference } from '@common/models/Common/EntityReference';
import { MediaTypeUpdateDto } from '@common/models/Settings/Setting/Item/MediaTypeUpdateDto';
import { MediaTypeListItemDto } from '@common/models/Settings/Setting/List/MediaTypeListItemDto';
import { DomainError } from '@common/models/Validation/DomainError';
import { NotificationService } from '@common/notification';
import { GeneralSettingsService } from '@common/services/settings/generalsettings.service';

import { GenericBasicListComponent } from 'app/shared/generics/generic.basic.list.component';

import { MediaTypeDialogComponent } from '../edit-media-type/media-type-dialog.component';

@Component({
	selector: 'media-type-list',
	styleUrls: ['./media-type-list.component.scss'],
	templateUrl: './media-type-list.component.html'
})
export class MediaTypeListComponent extends GenericBasicListComponent<MediaTypeListItemDto> {
	constructor(
		private settingsService: GeneralSettingsService,
		private dialog: MatDialog,
		private notificationService: NotificationService
	) {
		super(settingsService.getMediaTypes(null), ['name', 'clearanceDateOffset', 'actions']);
	}

	editMediaType(row: MediaTypeListItemDto) {
		this.dialog
			.open(MediaTypeDialogComponent, { data: row as MediaTypeUpdateDto })
			.afterClosed()
			.pipe(
				filter(Boolean),
				switchMap((dialogData: MediaTypeUpdateDto) => {
					return this.settingsService.saveMediaType(dialogData);
				})
			)
			.subscribe(() => {
				this.onUpdateSuccess(row.name);
			}, this.onError);
	}

	private onError = (errors: DomainError[]) => {
		this.notificationService.showErrors('Error', errors);
	};

	private onUpdateSuccess = (name: string) => {
		this.notificationService.showNotification(`Media Type '${name}' updated`);
		this.refreshList();
	};
}
