import { from, Observable, ObservedValueOf, OperatorFunction } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import AwaitLock from 'await-lock';

export async function lockedAsync<R>(lock: AwaitLock, delegate: () => R | PromiseLike<R>): Promise<R> {
	await lock.acquireAsync();

	try {
		return await Promise.resolve(delegate());
	} finally {
		lock.release();
	}
}

export function locked<R>(lock: AwaitLock, delegate: () => R): Observable<R> {
	return from(lockedAsync(lock, delegate));
}

export function lockedObservable<R>(lock: AwaitLock, delegate: () => Observable<R>): Observable<R> {
	return from(lockedAsync(lock, () => {
		return delegate().toPromise(); // force the results to resolve inside lock
	}))
}

export function lockedSwitchMap<T, R>(lock: AwaitLock, delegate: (value: T, index: number) => Observable<R>): OperatorFunction<T, ObservedValueOf<Observable<R>>> {
	return switchMap((value: T, index: number) => { // Top level switchMap
		return lockedObservable(lock, () => delegate(value, index)); // force the results to resolve inside lock
	});
}