import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { filter } from 'rxjs/operators';

import { DocumentBriefTemplateListItemDto } from '@common/models/DocumentBriefTemplates/List/DocumentBriefTemplateListItemDto';
import { DocumentBriefTemplateListRequest } from '@common/models/DocumentBriefTemplates/List/DocumentBriefTemplateListRequest';
import { DocumentBriefTemplatesService } from '@common/services/documentbrieftemplates.service';
import { Store } from '@ngrx/store';

import { IAppState } from 'app/core/state/app.state';
import { DocumentBriefTemplateListActions } from 'app/core/state/lists/settings/document-brief-template-list/document-brief-template-list.actions';
import {
	selectDocumentBriefTemplateIsFetching,
	selectDocumentBriefTemplateListRecords,
	selectDocumentBriefTemplateListRequest,
	selectDocumentBriefTemplateListTotalRecords
} from 'app/core/state/lists/settings/document-brief-template-list/document-brief-template-list.selectors';
import { GridViewService } from 'app/services/grid-view.service';
import {
	ActionTypes,
	GenericListStateComponent,
	SelectorTypes
} from 'app/shared/generics/generic.list.state.component';

import {
	CreateBriefTemplateDialogComponent,
	IDocumentBriefTemplateDialogData
} from './create-brief-template-dialog.component';

@Component({
	selector: 'document-brief-template-list',
	styleUrls: ['./document-brief-template-list.component.scss'],
	templateUrl: 'document-brief-template-list.component.html'
})
export class DocumentTemplateBriefListComponent extends GenericListStateComponent<
	DocumentBriefTemplateListItemDto,
	DocumentBriefTemplateListRequest
> {
	get actions(): ActionTypes {
		return {
			init: DocumentBriefTemplateListActions.Init,
			load: DocumentBriefTemplateListActions.Load,
			setFilters: DocumentBriefTemplateListActions.SetFilters,
			setPageIndex: DocumentBriefTemplateListActions.SetPageIndex,
			setPageIndexForId: DocumentBriefTemplateListActions.SetPageIndexForId,
			setPageSize: DocumentBriefTemplateListActions.SetPageSize,
			setSortBy: DocumentBriefTemplateListActions.SetSortBy,
			setSortDirection: DocumentBriefTemplateListActions.SetSortDirection,
			selected: DocumentBriefTemplateListActions.SelectRecords
		};
	}
	get selectors(): SelectorTypes {
		return {
			records: selectDocumentBriefTemplateListRecords,
			isFetching: selectDocumentBriefTemplateIsFetching,
			request: selectDocumentBriefTemplateListRequest,
			totalRecords: selectDocumentBriefTemplateListTotalRecords
		};
	}
	constructor(
		store: Store<IAppState>,
		dialog: MatDialog,
		protected router: Router,
		protected activatedRoute: ActivatedRoute,
		protected service: DocumentBriefTemplatesService,
		gridViewService: GridViewService
	) {
		super(['name', 'practiceAreas', 'sections', 'action'], dialog, store, router, activatedRoute, gridViewService);
	}

	onAddTemplateClick(): void {
		this.openCreateDialog();
	}

	onEditTemplateClick(dto: DocumentBriefTemplateListItemDto): void {
		this.openCreateDialog({ briefTemplateId: dto.id });
	}

	onDeleteTemplateClick(dto: DocumentBriefTemplateListItemDto): void {
		this.subscriptions.add(
			this.service.deleteTemplate(dto.id).subscribe({
				next: response => {
					this.store.dispatch({ type: DocumentBriefTemplateListActions.RemoveRecords, response: response });
				}
			})
		);
	}

	private openCreateDialog(data: IDocumentBriefTemplateDialogData = null): void {
		let dialogRef: MatDialogRef<any>;
		if (!!data) {
			dialogRef = this.dialog.open(CreateBriefTemplateDialogComponent, { data });
		} else {
			dialogRef = this.dialog.open(CreateBriefTemplateDialogComponent);
		}

		this.subscriptions.add(
			dialogRef
				.afterClosed()
				.pipe(filter(Boolean))
				.subscribe(() => this.store.dispatch({ type: DocumentBriefTemplateListActions.RemoveRecords }))
		);
	}
}
