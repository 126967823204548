import { createFeatureSelector, createSelector } from '@ngrx/store';
import { get } from 'lodash-es';
import * as moment from 'moment-timezone';

import { ClearSystemTagsType, SetSystemTagsType, SystemTagsActions } from '../actions/system-tags-data.actions';
import { ISystemTagsData } from '../models/system-tags-data';

export const initialState: ISystemTagsData = {
	tags: null,
	practiceAreaIds: null,
	matterId: null,
	expiry: moment().add(3, 'seconds')
};

export function systemTagsReducer(state = initialState, action: SystemTagsActions): ISystemTagsData {
	switch (action.type) {
		case SetSystemTagsType:
			return get(action, 'data', initialState);
		case ClearSystemTagsType:
			return initialState;
		default:
			return state;
	}
}

export const getSystemTagsState = createFeatureSelector<ISystemTagsData>('systemTagsData');

export const getSystemTags = createSelector(getSystemTagsState, (state: ISystemTagsData) => state);
