<h2 mat-dialog-title class="title">Create User</h2>
<mat-dialog-content [formGroup]="form">
	<mat-accordion>
		<mat-expansion-panel expanded="true" [disabled]="existingPanelDisabled()">
			<mat-expansion-panel-header>
				<mat-panel-title> Create user for an existing contact </mat-panel-title>
			</mat-expansion-panel-header>
			<contact-lookup [FormControl]="contactId" Placeholder="Select contact"></contact-lookup>
		</mat-expansion-panel>
		<mat-expansion-panel [disabled]="createPanelDisabled()">
			<mat-expansion-panel-header>
				<mat-panel-title> Create user for a new contact </mat-panel-title>
			</mat-expansion-panel-header>
			<div class="flex-row">
				<mat-form-field>
					<input formControlName="firstName" matInput autocomplete="off" />
					<mat-placeholder>
						First Name
						<sup class="color-warn">*</sup>
					</mat-placeholder>
					<mat-error>
						<error-messages [for]="form.get('firstName')"></error-messages>
					</mat-error>
				</mat-form-field>
				<mat-form-field>
					<input formControlName="lastName" matInput autocomplete="off" />
					<mat-placeholder>
						Last Name
						<sup class="color-warn">*</sup>
					</mat-placeholder>
					<mat-error>
						<error-messages [for]="form.get('lastName')"></error-messages>
					</mat-error>
				</mat-form-field>
			</div>
		</mat-expansion-panel>
	</mat-accordion>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-raised-button color="primary" [mat-dialog-close]="form.value" [disabled]="form.invalid">Ok</button>
	<button mat-raised-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
