// This file has been generated from DocumentHeaderFooterTemplatesController.cs
// Do not modify this file directly!!
// tslint:disable

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { TrimStringsOnObject } from '@common/utils/string-format';

import { EntityReference } from '../../models/Common/EntityReference';
import { MutationResponseDto } from '../../models/Common/MutationResponseDto';
import { DocumentHeaderFooterTemplateUpdateDto } from '../../models/Documents/Item/DocumentHeaderFooterTemplateUpdateDto';
import { DocumentHeaderFooterTemplateViewDto } from '../../models/Documents/Item/DocumentHeaderFooterTemplateViewDto';
import { DocumentHeaderFooterTemplateListItemDto } from '../../models/Documents/List/DocumentHeaderFooterTemplateListItemDto';
import { DocumentListRequest } from '../../models/Documents/List/DocumentListRequest';
import { ListResponse } from '../../models/Generic/ListResponse';
import { DocumentRequest } from '../../models/RequestParameters/DocumentRequest';

@Injectable({
	providedIn: 'root'
})
export class DocumentHeaderFooterTemplatesService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'api/v1/settings/DocumentHeaderFooterTemplates', 'Document Header Footer Template');
	}

	getDocumentHeaderFooterTemplate(id: string): Observable<DocumentHeaderFooterTemplateViewDto> {
		return this.getItem<void, DocumentHeaderFooterTemplateViewDto>(`${id}`);
	}

	getDocumentHeaderFooterTemplateList(request?: Partial<DocumentListRequest>): Observable<ListResponse<DocumentHeaderFooterTemplateListItemDto>> {
		return this.getList<DocumentListRequest, DocumentHeaderFooterTemplateListItemDto>('', request);
	}

	getTemplatesAssociatedWithHeaderFooters(id: string): Observable<EntityReference[]> {
		return this.getArray<void, EntityReference>(`${id}/templates`);
	}

	addTemplate(request: DocumentRequest): Observable<MutationResponseDto> {
		return this.post<DocumentRequest, MutationResponseDto>('', request);
	}

	updateTemplate(id: string, dto: DocumentHeaderFooterTemplateUpdateDto): Observable<MutationResponseDto> {
		return this.put(`${id}`, TransformDatesOnObject(DocumentHeaderFooterTemplateUpdateDto, TrimStringsOnObject(dto)));
	}

}
