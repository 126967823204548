// This file has been generated from DocumentTemplateMergeTaskCreateUpdateDto.cs
// Do not modify this file directly!!
// tslint:disable

import { Moment } from 'moment-timezone';
import { DateFormat, SetDateFormat } from '@common/utils/date-format';

import { TaskPriority } from '../Common/TaskPriority';
import { TaskStatus } from '../Common/TaskStatus';

// LawMaster.Amalthea.Domain.Tasks.Item.DocumentTemplateMergeTaskCreateUpdateDto
export class DocumentTemplateMergeTaskCreateUpdateDto {

	// Description
	description: string;
	// Status
	status: keyof typeof TaskStatus;
	// Priority
	priority: keyof typeof TaskPriority;
	// DueDate
	@SetDateFormat(DateFormat.LocalDate)
	dueDate: Moment;
	// AssociatedContactId
	associatedContactId: string;
	// AssociatedMatterId
	associatedMatterId: string;
	// AssignedToId
	assignedToId: string;
	// WorkFlowId
	workFlowId: string;
	// DocumentTemplateIds
	documentTemplateIds: string[];

	constructor() {

	}
}