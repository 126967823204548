import { Action, createReducer, on } from '@ngrx/store';

import { setTenantCompany, updateRegionCode } from './tenant-company.actions';
import { ITenantCompanyState } from './tenant-company.state';

export const initialState: ITenantCompanyState = { regionCode: null };

const _tenantCompanyReducer = createReducer(
	initialState,

	on(setTenantCompany, (state, { regionCode }) => {
		return { ...state, regionCode: regionCode };
	}),

	on(updateRegionCode, (state, { regionCode }) => {
		return { ...state, regionCode: regionCode };
	})
);

export function tenantCompanyReducer(state: ITenantCompanyState, action: Action) {
	return _tenantCompanyReducer(state, action);
}
