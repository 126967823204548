import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { Features } from '@common/models/Settings/Modules/Features';
import { TenantFeatureStateDto } from '@common/models/Settings/Setting/Item/TenantFeatureStateDto';
import { ITenantData } from '@common/state/models/tenant-data';
import { Store } from '@ngrx/store';

import { FeatureFlags, isFeatureFlagEnabled } from 'app/app.config';

@Directive({
	selector: '[tenantFeature]'
})
export class TenantFeatureDirective implements OnInit, OnDestroy {
	@Input('tenantFeature')
	tenantFeature: keyof typeof Features;

	private _subscriptions = new Subscription();

	constructor(
		private _store: Store<{ tenantData: ITenantData }>,
		private _templateRef: TemplateRef<any>,
		private _viewContainer: ViewContainerRef
	) {}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}

	ngOnInit(): void {
		// No need to evaluate a feature state if the Licensing feature is diabled.
		// The below snippet of code can be safely removed once Licensing has been enabled on prod.
		var isLicensingEnabled = isFeatureFlagEnabled(FeatureFlags.licensing);
		if (!isLicensingEnabled) {
			this._viewContainer.createEmbeddedView(this._templateRef);
			return;
		}
		// The above snippet of code can be safely removed once Licensing has been enabled on prod.

		if (!!this.tenantFeature) {
			this._viewContainer.clear();

			this._subscriptions.add(
				this._store
					.select(state => state?.tenantData?.tenantInformation?.featureStates)
					.pipe(
						filter(Boolean),
						distinctUntilChanged(),
						map((states: TenantFeatureStateDto[]) =>
							states
								.filter(states => !!states.isEnabled)
								.map(states => states.type)
								.includes(this.tenantFeature)
						)
					)
					.subscribe(flag => {
						this._viewContainer.clear();

						if (!!flag) {
							this._viewContainer.createEmbeddedView(this._templateRef);
						}
					})
			);
		}
	}
}
