<h2 mat-dialog-title class="title">
	<span>Delete User</span>
</h2>
<mat-dialog-content [formGroup]="form">
	<ng-container *ngIf="userDeleteInfo?.numberOfAssignedTasks + userDeleteInfo?.numberOfStaffCostRecords + userDeleteInfo?.mattersOfLawyer?.length > 0">
		<div class="confirm-text-gap">Contact cannot be deleted as it is associated with the following items:</div>
		<ul class="confirm-text-gap">
			<li *ngIf="userDeleteInfo?.mattersOfLawyer?.length">
				<a target="_blank" [routerLink]="['/matters']"> {{ userDeleteInfo?.mattersOfLawyer.length }} matter(s)</a>
			</li>
			<li *ngIf="userDeleteInfo?.numberOfAssignedTasks > 0">
				<a target="_blank" [routerLink]="['/tasks']">{{ userDeleteInfo?.numberOfTrustRecords }} task(s)</a>
			</li>
			<li *ngIf="userDeleteInfo?.numberOfStaffCostRecords > 0">
				<a target="_blank" [routerLink]="['/costs']">{{ userDeleteInfo?.numberOfTrustRecords }} cost record(s)</a>
			</li>
		</ul>
	</ng-container>
	<div *ngIf="userDeleteInfo?.numberOfAssignedTasks + userDeleteInfo?.numberOfStaffCostRecords + userDeleteInfo?.mattersOfLawyer?.length === 0">
		<div class="confirm-text-gap warning-text">Deleting a user cannot be undone. Are you sure?</div>
		<div class="confirm-text-gap">
			Enter the users full name (<i> {{ contact?.name }} </i>) below to confirm the deletion.
		</div>
		<mat-form-field>
			<mat-placeholder
				>User Full Name
				<sup class="color-warn">*</sup>
			</mat-placeholder>
			<input formControlName="fullName" matInput autocomplete="off" />
			<mat-error>
				<error-messages [for]="form.get('fullName')"></error-messages>
			</mat-error>
		</mat-form-field>
	</div>
</mat-dialog-content>
<mat-dialog-actions class="dialog-actions">
	<div
		*ngIf="
			userDeleteInfo?.numberOfAssignedTasks + userDeleteInfo?.numberOfStaffCostRecords + userDeleteInfo?.mattersOfLawyer?.length > 0;
			else deleteEnabled
		"
	>
		<button mat-raised-button color="primary" mat-dialog-close>OK</button>
	</div>
	<ng-template #deleteEnabled>
		<button
			mat-raised-button
			color="warn"
			[mat-dialog-close]="true"
			[disabled]="form.invalid || form.get('fullName').value.trim() !== contact?.name"
			mat-dialog-close
		>
			Delete
		</button>
		<button mat-raised-button mat-dialog-close>Cancel</button>
	</ng-template>
</mat-dialog-actions>
