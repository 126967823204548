<mat-table [dataSource]="datasource" matSort matSortActive="timestamp" matSortDirection="desc">
	<ng-container matColumnDef="timestamp" class="timestamp">
		<mat-header-cell *matHeaderCellDef mat-sort-header>Date/Time</mat-header-cell>
		<mat-cell *matCellDef="let row">
			{{ row.timestamp | moment : 'dd/MM/yyyy HH:mm:ss' }}
		</mat-cell>
	</ng-container>

	<ng-container matColumnDef="relatedTo_Name">
		<mat-header-cell *matHeaderCellDef mat-sort-header>Related To</mat-header-cell>
		<mat-cell *matCellDef="let row">
			<ng-container [ngSwitch]="true">
				<div class="flex-align" *ngSwitchCase="row.relatedToType === 'Documents' || row.relatedToType === 'DocumentTemplate'">
					<mat-icon [svgIcon]="getIconFromExtension(row)" [ngClass]="getIconFromExtension(row)"></mat-icon>
					<a class="related-to-link" throttleButton (throttledClick)="navigateRelatedTo(row)">
						{{ row.relatedTo?.name }}
					</a>
				</div>
				<div class="flex-align" *ngSwitchDefault>
					<mat-icon color="accent">{{ getIconFromType(row.relatedToType) }}</mat-icon>
					<a class="related-to-link" throttleButton (throttledClick)="navigateRelatedTo(row)">
						{{ row.relatedTo?.name }}
					</a>
				</div>
			</ng-container>
		</mat-cell>
	</ng-container>

	<ng-container matColumnDef="action">
		<mat-header-cell *matHeaderCellDef mat-sort-header>Action</mat-header-cell>
		<mat-cell *matCellDef="let row">
			<div class="embedded-timestamp-user-block">
				<span class="narrow-column">
					{{ row.timestamp | moment : 'dd/MM/yyyy HH:mm:ss' }}
				</span>
				<span class="wide-column">
					<a [routerLink]="['/contacts/' + row.user?.id]">{{ row.user?.name }}</a>
				</span>
			</div>
			<div class="log-action">
				{{ row.action }}
			</div>
			<div class="embedded-related-to-block">
				<ng-container [ngSwitch]="row.relatedToType">
					<div class="flex-align" *ngSwitchCase="'Documents'">
						<mat-icon [svgIcon]="getIconFromExtension(row)" [ngClass]="getIconFromExtension(row)"></mat-icon>
						<a class="related-to-link" throttleButton (throttledClick)="navigateRelatedTo(row)">
							{{ row.relatedTo?.name }}
						</a>
					</div>
					<div class="flex-align" *ngSwitchDefault>
						<mat-icon color="accent">{{ getIconFromType(row.relatedToType) }}</mat-icon>
						<a class="related-to-link" throttleButton (throttledClick)="navigateRelatedTo(row)">
							{{ row.relatedTo?.name }}
						</a>
					</div>
				</ng-container>
			</div>
		</mat-cell>
	</ng-container>

	<ng-container matColumnDef="user_Name">
		<mat-header-cell *matHeaderCellDef mat-sort-header>User</mat-header-cell>
		<mat-cell *matCellDef="let row">
			<a [routerLink]="['/contacts/' + row.user?.id]">{{ row.user?.name }}</a>
		</mat-cell>
	</ng-container>

	<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
	<mat-row
		*matRowDef="let row; columns: displayedColumns"
		throttleButton
		(throttledClick)="selectRow(row, $event)"
		[ngClass]="{ highlight: row.isHighlighted }"
	></mat-row>
</mat-table>
<mat-paginator
	[length]="datasource?.response?.totalRecords"
	[pageIndex]="datasource?.response?.pageIndex"
	[pageSize]="pageSize"
	[pageSizeOptions]="pageSizeOptions"
></mat-paginator>
