import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DocumentBriefLookupDto } from '@common/models/DocumentBriefs/Lookup/DocumentBriefLookupDto';
import { DocumentBriefsService } from '@common/services/documentbriefs.service';
import { BaseLookupComponent } from '@common/components/lookups/base-lookup.component';
import { Observable } from 'rxjs';

@Component({
	selector: 'brief-lookup',
	styleUrls: ['../../../../../common/components/lookups/base-lookup.component.scss', 'brief-lookup.component.scss'],
	templateUrl: '../../../../../common/components/lookups/base-lookup.component.html'
})
export class BriefLookupComponent extends BaseLookupComponent<DocumentBriefLookupDto> {
	@Input()
	FormControl: FormControl;
	@Input()
	FontSize: string;
	@Input()
	HasAutofocus: boolean;
	@Input()
	Placeholder: string;
	@Input()
	Hint: string;
	@Input()
	Required: boolean;
	@ViewChild('autoCompleteInput', { static: true })
	inputCtrl: ElementRef;

	constructor(private documentBriefsService: DocumentBriefsService) {
		super();
	}

	optionHtmlText(input: DocumentBriefLookupDto): string {
		return `<div class="selected-brief">
					${input.name}
				</div>
				<div class="extra-details">
					<span class="material-icons icon">gavel</span>
					<span class="text">${input.matter.name}</span>
				</div>`;
	}

	displayText(value: DocumentBriefLookupDto) {
		return value.name;
	}

	lookup(id: string): Observable<DocumentBriefLookupDto> {
		return this.documentBriefsService.lookupDocumentBrief(id);
	}

	search(term: string): Observable<DocumentBriefLookupDto[]> {
		return this.documentBriefsService.lookupDocumentBriefList({ term });
	}

	setFocus() {
		if (this.inputCtrl != null) {
			setTimeout(() => {
				this.inputCtrl.nativeElement.focus();
			}, 0);
		}
	}
}
