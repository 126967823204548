import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

import { Observable, of } from 'rxjs';

import { BaseLookupComponent } from '@common/components/lookups/base-lookup.component';
import { DocumentLookupDto } from '@common/models/Documents/Lookup/DocumentLookupDto';
import { DocumentLookupRequest } from '@common/models/Documents/Lookup/DocumentLookupRequest';
import { getFileIcon } from '@common/utils/file-extensions';
import { DocumentsService } from 'app/services/documents.service';

@Component({
	selector: 'document-lookup',
	styleUrls: [
		'../../../../../common/components/lookups/base-lookup.component.scss',
		'document-lookup.component.scss'
	],
	templateUrl: 'document-lookup.component.html'
})
export class DocumentLookupComponent extends BaseLookupComponent<DocumentLookupDto> {
	@ViewChild('autoCompleteInput', { static: true })
	inputCtrl: ElementRef;
	@Input()
	FormControl: FormControl;
	@Input()
	FontSize: string;
	@Input()
	HasAutofocus: boolean;
	@Input()
	Placeholder: string;
	@Input()
	Hint: string;
	@Input()
	Required: boolean;
	@Input()
	associatedMatterId: string;
	@Input()
	associatedContactId: string;
	@Input()
	forceAssociatedMatterOrContact: boolean;
	documentIcon: string;

	constructor(private documentsService: DocumentsService) {
		super();
	}

	optionHtmlText(input: DocumentLookupDto): string {
		return null;
	}

	getFileIcon(fileExtension: string) {
		return getFileIcon(fileExtension, false);
	}

	displayText(input: DocumentLookupDto) {
		return input.title;
	}

	lookup(id: string): Observable<DocumentLookupDto> {
		return this.documentsService.lookupDocument(id);
	}

	search(term: string): Observable<DocumentLookupDto[]> {
		const request: Partial<DocumentLookupRequest> = {
			term
		};

		if (!!this.associatedMatterId) {
			request.associatedMatterId = this.associatedMatterId;
		}

		if (!!this.associatedContactId) {
			request.associatedContactId = this.associatedContactId;
		}

		if (!!this.forceAssociatedMatterOrContact) {
			request.mustHaveAnAssociatedMatterOrContact = !!this.forceAssociatedMatterOrContact;
		}

		return this.documentsService.lookupDocumentList(request as DocumentLookupRequest);
	}

	setFocus() {
		if (this.inputCtrl != null) {
			setTimeout(() => {
				this.inputCtrl.nativeElement.focus();
			}, 0);
		}
	}

	autoCompleteChanged() {
		this.documentIcon = null;
	}

	getDocumentVersionDisplayNumber(row: DocumentLookupDto): string {
		if (!row) {
			return null;
		}
		const versions = row?.versions;

		let versionText = '';
		if (versions?.length > 1) {
			const isLatest = versions[versions.length - 1] === row.id;
			versionText = `v${versions.indexOf(row.id) + 1}${isLatest ? ' latest' : ''}`;
		}
		return versionText.toUpperCase();
	}

	protected setSelectedValue(value: DocumentLookupDto) {
		super.setSelectedValue(value);
		this.documentIcon = !!value?.fileExtension ? this.getFileIcon(value.fileExtension) : null;
	}

	protected formControlChanged(value: DocumentLookupDto) {
		super.formControlChanged(value);
		this.documentIcon = !!value?.fileExtension ? this.getFileIcon(value.fileExtension) : null;
	}
}
