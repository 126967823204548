// This file has been generated from ReferralDocumentDto.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.Domain.Tasks.Item.ReferralDocumentDto
export class ReferralDocumentDto {

	// DocumentId
	documentId: string;

	constructor() {

	}
}