import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FilterButtonComponent } from 'app/shared/filter-controls/filter-button.component';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { InstalledPackageFilterComponent } from '../components/installed-package-filter.component';
import { FilterBaseComponent } from './filter-base.component';

@Component({
	selector: 'filter-installed-package-button',
	template: `
		<filter-button [root]="root" [name]="name" [showTitleAsPrefix]="showTitleAsPrefix" [label]="label">
			<installed-package-filter
				Placeholder="Find Package"
				[FormControl]="btn.control"
				(Selected)="btn.entitySelected($event)"
			>
			</installed-package-filter>
		</filter-button>
	`
})
export class FilterInstalledPackageButtonComponent extends FilterBaseComponent implements OnInit, OnDestroy {
	@ViewChild(FilterButtonComponent, { static: true }) btn: FilterButtonComponent;
	@ViewChild(InstalledPackageFilterComponent, { static: true }) installedPackaged: InstalledPackageFilterComponent;

	private subscription = new Subscription();

	ngOnInit(): void {
		// Handle a corner-case, when we clear the filtering condition
		// via emptying the corresponding FormControls without updating the page.
		// We still need to be careful if the same filter can be modified from two or more controls on the page
		if (this.root && this.name) {
			this.subscription = this.root.filterChange
				.pipe(
					distinctUntilChanged(),
					filter(() => !(this.root.form.get(this.name) && this.root.form.get(this.name).value))
				)
				.subscribe(() => {
					this.btn.selectedFilterTitle = '';
					this.btn.IsSelected = false;
					this.installedPackaged.SelectedValue = null;
				});
		}
	}
	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
