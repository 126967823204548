// This file has been generated from BillWriteOffDto.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.Domain.Bills.Item.BillWriteOffDto
export class BillWriteOffDto {

	// ConfirmTotal
	confirmTotal: number;

	constructor() {

	}
}