import { Directive } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { BaseListItemDto } from '@common/models/Common/BaseListItemDto';
import { GenericHttpService } from '@common/services/generic.http.service';

import { GridViewService } from 'app/services/grid-view.service';

import { GenericListBaseComponent } from './generic.list.base.component';

@Directive()
export abstract class GenericListComponent<
	TListItemDto extends BaseListItemDto,
	TFilter
> extends GenericListBaseComponent<TListItemDto, TFilter> {
	constructor(
		dialog: MatDialog,
		router: Router,
		activatedRoute: ActivatedRoute,
		protected service: GenericHttpService,
		defaultDisplayedColumns: string[],
		gridViewService: GridViewService
	) {
		super(dialog, router, activatedRoute, defaultDisplayedColumns, gridViewService, request =>
			this.service.getList('', request)
		);
	}
}
