import { ContactListItemDto } from '@common/models/Contacts/List/ContactListItemDto';
import { ContactListRequest } from '@common/models/Contacts/List/ContactListRequest';
import { ListResponse } from '@common/models/Generic/ListResponse';
import { DomainError } from '@common/models/Validation/DomainError';

import { AppConfig } from 'app/app.config';

export interface IContactListState {
	isFetching: boolean;
	list: ListResponse<ContactListItemDto>;
	lastSelectedRecordId: string;
	request: Partial<ContactListRequest>;
	oldRequest: Partial<ContactListRequest>;

	// errrors
	error: DomainError;
}

export const initialState: IContactListState = {
	isFetching: false,
	list: null,
	lastSelectedRecordId: null,
	request: {
		pageSize: AppConfig.PageSize,
		pageIndex: 0,
		pageIndexForId: null,

		sortBy: null,
		sortDirection: null
	},
	oldRequest: null,
	error: null
};
