import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

import { SharedDirectivesModule } from '@common/directives/shared-directives.module';
import { SharedPipesModule } from '@common/pipes/shared-pipes.module';

import { DirectivesModule } from 'app/directives/directives.module';
import { ExternalPortalRouteService } from 'app/services/external-portal-route-service';
import { PortalAuthService } from 'app/services/portal-auth-service';
import { ComponentsModule } from 'app/shared/components';
import { PipesModule } from 'app/shared/pipes/pipes.module';
import { UserAvatarModule } from 'app/shared/user-avatar/user-avatar.module';

import { CreateFormsModule } from '../create-forms';
import { PageTitleService } from '../shared/utils/page-title.service';
import { AboutComponent } from './about/about.component';
import { PaywallDialogComponent } from './access-route-guard/paywall-dialog.component';
import { AppConfigPageComponent } from './app-config/app-config-page.component';
import { DialogComponent } from './dialog.component';
import { FooterComponent } from './footer';
import { interceptorProviders } from './interceptors';
import { MainMenuComponent } from './main-menu';
import { CreateMatterTimerDialogComponent } from './main-menu/create-matter-timer-dialog/create-matter-timer-dialog.component';
import { JobsMenuButtonComponent } from './main-menu/jobs/jobs-menu-button.component';
import { TimersMenuButtonComponent } from './main-menu/timers/timers-menu-button.component';
import { OmnisearchDialogComponent } from './omnisearch/omnisearch-dialog.component';
import { OmnisearchComponent } from './omnisearch/omnisearch.component';
import { SecurityPermissionService } from './security-permissions.service';
import { TermsAndConditionsDialogComponent } from './terms-and-conditions-dialog/terms-and-conditions-dialog.component';
import { WelcomeDialogComponent } from './welcome-dialog/welcome-dialog.component';
import { ZendeskReplacementDialogComponent } from './zendesk-replacement/zendesk-replacement.dialog.component';

@NgModule({
	declarations: [
		AboutComponent,
		DialogComponent,
		FooterComponent,
		JobsMenuButtonComponent,
		TimersMenuButtonComponent,
		MainMenuComponent,
		OmnisearchComponent,
		OmnisearchDialogComponent,
		PaywallDialogComponent,
		WelcomeDialogComponent,
		TermsAndConditionsDialogComponent,
		CreateMatterTimerDialogComponent,
		AppConfigPageComponent,
		ZendeskReplacementDialogComponent
	],
	exports: [FooterComponent, MainMenuComponent],
	imports: [
		CommonModule,
		ComponentsModule,
		CreateFormsModule,
		DirectivesModule,
		FormsModule,
		MatAutocompleteModule,
		MatButtonModule,
		MatCardModule,
		MatCheckboxModule,
		MatChipsModule,
		MatDialogModule,
		MatIconModule,
		MatInputModule,
		MatListModule,
		MatMenuModule,
		MatProgressBarModule,
		MatRadioModule,
		MatSelectModule,
		MatSidenavModule,
		MatSnackBarModule,
		MatSlideToggleModule,
		MatToolbarModule,
		MatTooltipModule,
		ReactiveFormsModule, // For auto-suggestions in the look-up controls
		RouterModule,
		SharedDirectivesModule,
		PipesModule,
		SharedPipesModule,
		UserAvatarModule
	],
	providers: [
		interceptorProviders,
		PortalAuthService,
		ExternalPortalRouteService,
		SecurityPermissionService,
		PageTitleService
	]
})
export class CoreModule {
	static forRoot(): ModuleWithProviders<CoreModule> {
		return {
			ngModule: CoreModule
		};
	}
}
