// This file has been generated from ReportsController.cs
// Do not modify this file directly!!
// tslint:disable

import { HttpClient, HttpResponse } from '@angular/common/http';
import { IDownloadFile } from '../models/Files/IFileDownloader';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { TrimStringsOnObject } from '@common/utils/string-format';

import { DocumentListItemDto } from '../models/Documents/List/DocumentListItemDto';
import { DocumentListRequest } from '../models/Documents/List/DocumentListRequest';
import { DocumentReference } from '../models/Documents/PersistenceLayer/DocumentReference';
import { ListResponse } from '../models/Generic/ListResponse';
import { ClientContactDetailsListItemDto } from '../models/Reports/Dto/ClientContactDetailsListItemDto';
import { ClientContactDetailsRequest } from '../models/Reports/Dto/ClientContactDetailsRequest';
import { MatterBalanceReportListItemDto } from '../models/Reports/Dto/MatterBalanceReportListItemDto';
import { MatterBalanceReportRequest } from '../models/Reports/Dto/MatterBalanceReportRequest';
import { MatterListReportListItemDto } from '../models/Reports/Dto/MatterListReportListItemDto';
import { MatterListReportRequest } from '../models/Reports/Dto/MatterListReportRequest';
import { NotInvoicedCostsByPeriodReportListItemDto } from '../models/Reports/Dto/NotInvoicedCostsByPeriodReportListItemDto';
import { NotInvoicedCostsByPeriodReportRequest } from '../models/Reports/Dto/NotInvoicedCostsByPeriodReportRequest';
import { ProductivityReportListItemDto } from '../models/Reports/Dto/ProductivityReportListItemDto';
import { ProductivityReportRequest } from '../models/Reports/Dto/ProductivityReportRequest';
import { PublishReportDto } from '../models/Reports/Dto/PublishReportDto';
import { TasksDueReportListItemDto } from '../models/Reports/Dto/TasksDueReportListItemDto';
import { TasksDueReportRequest } from '../models/Reports/Dto/TasksDueReportRequest';
import { InvoiceHistoryReportListItemDto } from '../models/Reports/InvoiceHistoryReportListItemDto';
import { InvoiceHistoryReportRequest } from '../models/Reports/InvoiceHistoryReportRequest';

@Injectable({
	providedIn: 'root'
})
export class ReportsService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'api/v1/Reports', 'Report');
	}

	mattersBalances(request: MatterBalanceReportRequest): Observable<MatterBalanceReportListItemDto[]> {
		return this.getArray<MatterBalanceReportRequest, MatterBalanceReportListItemDto>(`MattersBalances`, request);
	}

	notInvoicedCostsByPeriod(request: NotInvoicedCostsByPeriodReportRequest): Observable<NotInvoicedCostsByPeriodReportListItemDto[]> {
		return this.getArray<NotInvoicedCostsByPeriodReportRequest, NotInvoicedCostsByPeriodReportListItemDto>(`NotInvoicedCostsByPeriod`, request);
	}

	invoiceHistory(request: InvoiceHistoryReportRequest): Observable<InvoiceHistoryReportListItemDto[]> {
		return this.getArray<InvoiceHistoryReportRequest, InvoiceHistoryReportListItemDto>(`InvoiceHistory`, request);
	}

	matterList(request: MatterListReportRequest): Observable<MatterListReportListItemDto[]> {
		return this.getArray<MatterListReportRequest, MatterListReportListItemDto>(`MatterList`, request);
	}

	clientContactDetails(request: ClientContactDetailsRequest): Observable<ClientContactDetailsListItemDto[]> {
		return this.getArray<ClientContactDetailsRequest, ClientContactDetailsListItemDto>(`ClientContactDetails`, request);
	}

	userProductivity(request: ProductivityReportRequest): Observable<ProductivityReportListItemDto[]> {
		return this.getArray<ProductivityReportRequest, ProductivityReportListItemDto>(`UserProductivity`, request);
	}

	tasksDue(request: TasksDueReportRequest): Observable<TasksDueReportListItemDto[]> {
		return this.getItem<TasksDueReportRequest, TasksDueReportListItemDto[]>(`TasksDue`, request);
	}

	tasksByPriority(request: TasksDueReportRequest): Observable<TasksDueReportListItemDto[]> {
		return this.getItem<TasksDueReportRequest, TasksDueReportListItemDto[]>(`TasksByPriority`, request);
	}

	clientProductivity(request: ProductivityReportRequest): Observable<ProductivityReportListItemDto[]> {
		return this.getArray<ProductivityReportRequest, ProductivityReportListItemDto>(`ClientProductivity`, request);
	}

	publishTrustLedgerReport(dto: PublishReportDto): Observable<DocumentReference> {
		return this.getItem<PublishReportDto, DocumentReference>(`PublishTrustLedgerReport`, dto);
	}

	publishTrustOverdrawnReport(dto: PublishReportDto): Observable<DocumentReference> {
		return this.getItem<PublishReportDto, DocumentReference>(`PublishTrustOverdrawnReport`, dto);
	}

	publishTrustCashbookReport(dto: PublishReportDto): Observable<DocumentReference> {
		return this.getItem<PublishReportDto, DocumentReference>(`PublishTrustCashbookReport`, dto);
	}

	publishTrustTrialBalanceReport(dto: PublishReportDto): Observable<DocumentReference> {
		return this.getItem<PublishReportDto, DocumentReference>(`PublishTrustTrialBalanceReport`, dto);
	}

	publishTrustDormantBalanceReport(dto: PublishReportDto): Observable<DocumentReference> {
		return this.getItem<PublishReportDto, DocumentReference>(`PublishTrustDormantBalanceReport`, dto);
	}

	publishTrustReconciliationReport(dto: PublishReportDto): Observable<DocumentReference> {
		return this.getItem<PublishReportDto, DocumentReference>(`PublishTrustReconciliationReport`, dto);
	}

	publishTrustAuditTrailReport(dto: PublishReportDto): Observable<DocumentReference> {
		return this.getItem<PublishReportDto, DocumentReference>(`PublishTrustAuditTrailReport`, dto);
	}

	publishJournalReport(dto: PublishReportDto): Observable<DocumentReference> {
		return this.getItem<PublishReportDto, DocumentReference>(`PublishJournalReport`, dto);
	}

	downloadTrustLedgerReport(dto: PublishReportDto): Observable<HttpResponse<Blob>> {
		return this.getBlob<PublishReportDto>(`DownloadTrustLedgerReport`, dto);
	}

	downloadTrustOverdrawnReport(dto: PublishReportDto): Observable<HttpResponse<Blob>> {
		return this.getBlob<PublishReportDto>(`DownloadTrustOverdrawnReport`, dto);
	}

	downloadTrustCashbookReport(dto: PublishReportDto): Observable<HttpResponse<Blob>> {
		return this.getBlob<PublishReportDto>(`DownloadTrustCashbookReport`, dto);
	}

	downloadTrustTrialBalanceReport(dto: PublishReportDto): Observable<HttpResponse<Blob>> {
		return this.getBlob<PublishReportDto>(`DownloadTrustTrialBalanceReport`, dto);
	}

	downloadTrustDormantBalanceReport(dto: PublishReportDto): Observable<HttpResponse<Blob>> {
		return this.getBlob<PublishReportDto>(`DownloadTrustDormantBalanceReport`, dto);
	}

	downloadTrustReconciliationReport(dto: PublishReportDto): Observable<HttpResponse<Blob>> {
		return this.getBlob<PublishReportDto>(`DownloadTrustReconciliationReport`, dto);
	}

	downloadTrustAuditTrailReport(dto: PublishReportDto): Observable<HttpResponse<Blob>> {
		return this.getBlob<PublishReportDto>(`DownloadTrustAuditTrailReport`, dto);
	}

	downloadJournalReport(dto: PublishReportDto): Observable<HttpResponse<Blob>> {
		return this.getBlob<PublishReportDto>(`DownloadJournalReport`, dto);
	}

	getPublishedReportsList(request?: Partial<DocumentListRequest>): Observable<ListResponse<DocumentListItemDto>> {
		return this.getList<DocumentListRequest, DocumentListItemDto>('', request);
	}

	publishJournalAuthorization(id: string): Observable<DocumentReference> {
		return this.post<void, DocumentReference>(`${id}/PublishJournalAuthorization`);
	}

}
