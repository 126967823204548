import { ListResponse } from '@common/models/Generic/ListResponse';
import { DomainError } from '@common/models/Validation/DomainError';

import { AppConfig } from 'app/app.config';
import { ListRequest } from '@common/models/Generic/ListRequest';
import { UserTypeListItemDto } from '@common/models/Settings/UserTypes/List/UserTypeListItemDto';

export interface IUserTypeListState {
	isFetching: boolean;
	list: ListResponse<UserTypeListItemDto>;
	lastSelectedRecordId: string;
	request: Partial<ListRequest>;

	// errrors
	error: DomainError;
}

export const initialState: IUserTypeListState = {
	isFetching: false,
	list: null,
	lastSelectedRecordId: null,
	request: {
		search: null,

		pageSize: AppConfig.PageSize,
		pageIndex: 0,
		pageIndexForId: null,

		sortBy: null,
		sortDirection: null
	},

	error: null
};
