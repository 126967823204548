import { Injectable } from '@angular/core';

import { map, of } from 'rxjs';

import { MatterListRequest } from '@common/models/Matters/List/MatterListRequest';
import { MattersService } from '@common/services/matters.service';
import { LocalStorageService } from '@common/services/storage/local-storage.service';
import { endsWith, groupBy, startsWith, take, uniq } from 'lodash-es';

import { WorkTimerStateManager } from 'app/shared/components/work-timer/WorkTimerStateManager';
import { WorkTimerUtils } from 'app/shared/components/work-timer/WorkTimerUtils';
import { ListResponse } from '@common/models/Generic/ListResponse';
import { MatterListItemDto } from '@common/models/Matters/List/MatterListItemDto';

@Injectable({
	providedIn: 'root'
})
export class WorkTimerService {
	constructor(
		private _localStorageService: LocalStorageService,
		private _workTimerStateManager: WorkTimerStateManager,
		private _mattersService: MattersService
	) {}

	getMatters$(request: Partial<MatterListRequest>) {
		const query = this._localStorageService
			.getKeys()
			.filter(
				key =>
					(!!startsWith(key, WorkTimerStateManager.dataStorageKeyPrefix) ||
						!!startsWith(key, WorkTimerStateManager.volatileDataStorageKeyPrefix)) &&
					!endsWith(key, WorkTimerStateManager.globalStorageKeySuffix)
			)
			.map(key => ({
				key: key,
				id: !startsWith(key, WorkTimerStateManager.dataStorageKeyPrefix)
					? key.substring(WorkTimerStateManager.volatileDataStorageKeyPrefix.length, key.length)
					: key.substring(WorkTimerStateManager.dataStorageKeyPrefix.length, key.length)
			}));

		let keys = Object.entries(groupBy(query, 'id'))
			.filter(entry => entry[1].length == 2)
			.map(entry => ({
				id: entry[0],
				state: this._workTimerStateManager.getState(entry[0])
			}))
			.filter(entry => !!entry.state)
			.sort((left, right) => {
				const leftActive = !!left.state.data.isRunning;
				const rightActive = !!right.state.data.isRunning;
				if (leftActive != rightActive) {
					return +rightActive - +leftActive;
				}

				const leftDuration = WorkTimerUtils.getCurrentDuration(left.state);
				const rightDuration = WorkTimerUtils.getCurrentDuration(right.state);
				if (leftDuration != rightDuration) {
					return rightDuration - leftDuration;
				}

				return 0;
			})
			.map(entry => entry.id.split('-').slice(0, 2).join('-'));

		if ((keys?.length || 0) <= 0) {
			return of(new ListResponse<MatterListItemDto>());
		}

		keys = [...uniq(keys)];

		if (!request?.sortBy && !!request?.pageSize) {
			return this._mattersService
				.getMatterListPost({
					...request,
					containIds: keys,
					pageSize: 1000
				})
				.pipe(
					map(response => {
						if (!!response?.records) {
							response.records = take(
								response.records.sort((left, right) => keys.indexOf(left.id) - keys.indexOf(right.id)),
								request.pageSize
							);
						}

						return response;
					})
				);
		} else {
			return this._mattersService.getMatterListPost({
				containIds: keys,
				...request
			});
		}
	}
}
