import { Component, Input, ViewChild } from '@angular/core';

import { JobListRequest } from '@common/models/Settings/Jobs/List/JobListRequest';
import { FilterRootComponent } from 'app/shared/filter-controls';
import { JobListFilterConfig } from './JobListFilterConfig';

@Component({
	selector: 'job-list-filter',
	styleUrls: ['./job-list-filter.component.scss'],
	templateUrl: './job-list-filter.component.html'
})
export class JobListFilterComponent {
	@ViewChild(FilterRootComponent, { static: true }) root: FilterRootComponent<JobListRequest>;
	@Input() config: JobListFilterConfig;

	listRequestType: { new (): JobListRequest } = JobListRequest;
}
