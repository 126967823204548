import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { XeroDescriptionVariables } from '@common/models/Bills/Common/XeroDescriptionVariables';
import { isNil, join } from 'lodash-es';

@Component({
	selector: 'xero-sync-description-dialog',
	styleUrls: ['./xero-sync-description-dialog.component.scss'],
	templateUrl: './xero-sync-description-dialog.component.html'
})
export class XeroSyncDescriptionDialogComponent implements OnInit {
	errorMessage: string;
	separator: string = ',';
	items: IDragDropData[] = [];
	basket: IDragDropData[] = [];
	private availableVariable: IDragDropData[] = [
		{
			variableKey: 'Date',
			title: XeroDescriptionVariables.Date,
			example: '19/05/2020'
		},
		{
			variableKey: 'Description',
			title: XeroDescriptionVariables.Description,
			example: 'Phone call with client'
		},
		{
			variableKey: 'Units',
			title: XeroDescriptionVariables.Units,
			example: '12'
		},
		{
			variableKey: 'UnitsWithLabel',
			title: XeroDescriptionVariables.UnitsWithLabel,
			example: 'Units:12'
		},
		{
			variableKey: 'User',
			title: XeroDescriptionVariables.User,
			example: 'Smith J'
		},
		{
			variableKey: 'Duration',
			title: XeroDescriptionVariables.Duration,
			example: '02:15'
		},
		{
			variableKey: 'DurationWithLabel',
			title: XeroDescriptionVariables.DurationWithLabel,
			example: 'Duration:02:15'
		},
		{
			variableKey: 'Hyphen',
			title: XeroDescriptionVariables.Hyphen,
			example: '',
			allowMany: true
		},
		{
			variableKey: 'NewLine',
			title: XeroDescriptionVariables.NewLine,
			example: '',
			allowMany: true
		},
		{
			variableKey: 'MatterNumber',
			title: XeroDescriptionVariables.MatterNumber,
			example: '2734'
		},
		{
			variableKey: 'MatterTitle',
			title: XeroDescriptionVariables.MatterTitle,
			example: 'Jones v. White'
		},
		{
			variableKey: 'Quantity',
			title: XeroDescriptionVariables.Quantity,
			example: '0.5'
		},
		{
			variableKey: 'QuantityWithLabel',
			title: XeroDescriptionVariables.QuantityWithLabel,
			example: 'Quantity: 0.5'
		},
		{
			variableKey: 'ChargeRate',
			title: XeroDescriptionVariables.ChargeRate,
			example: '$600'
		},
		{
			variableKey: 'ChargeRateWithLabel',
			title: XeroDescriptionVariables.ChargeRateWithLabel,
			example: 'Charge rate: $600'
		}
	];

	constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

	ngOnInit() {
		const selectedKeys = (this.data?.descriptionFormat || '').split(this.separator).filter((el: string) => {
			return !isNil(el) && el !== '';
		});

		this.items = selectedKeys.map((key: string) => {
			return this.availableVariable.filter(x => x.variableKey === key)[0];
		});

		this.basket = this.availableVariable.filter(item => {
			return item.allowMany || selectedKeys.indexOf(item.variableKey) === -1;
		});
	}

	get descriptionFormat(): string {
		return this.items
			? join(
					this.items.map(x => x.variableKey),
					this.separator
			  )
			: null;
	}

	remove(index: number) {
		if (index > -1) {
			const removed = this.items.splice(index, 1);
			if (removed) {
				this.basket.push(removed[0]);
				this.cleanupBasket();
			}
		}
	}

	drop(event: CdkDragDrop<IDragDropData[]>) {
		if (event.previousContainer === event.container) {
			moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
		} else {
			transferArrayItem(
				event.previousContainer.data,
				event.container.data,
				event.previousIndex,
				event.currentIndex
			);
			this.cleanupBasket();
		}
	}

	cleanupBasket() {
		// Ensure that as we drag and drop, the basket always keeps a hyphen and new line, but never more than one.
		const permittedDupes = this.availableVariable.filter(x => x.allowMany);
		permittedDupes.forEach(v => {
			this.basket = this.basket.filter(b => b.variableKey !== v.variableKey);
			this.basket.push(v);
		});
	}
}

export interface IDragDropData {
	variableKey: keyof typeof XeroDescriptionVariables;
	title: string;
	example: string;
	allowMany?: boolean;
}
