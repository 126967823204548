import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { filter, map } from 'rxjs/operators';

import { DocumentListItemDto } from '@common/models/Documents/List/DocumentListItemDto';
import { NotificationService } from '@common/notification';
import { TasksService } from '@common/services/tasks.service';
import { Store } from '@ngrx/store';

import { EntityCreatedNotificationService } from 'app/core/entityCreatedNotification.service';
import { IAppState } from 'app/core/state/app.state';
import { DocumentServiceType } from 'app/core/state/lists/document-list/document-list.state';
import { DocumentsEditService } from 'app/services/documents-edit.service';
import { DocumentsService } from 'app/services/documents.service';
import { GridViewService } from 'app/services/grid-view.service';
import { DocumentListBaseComponent } from 'app/shared/documents/list/document-list-base.component';
import { DocumentListItemExtDto } from 'app/shared/documents/list/DocumentListItemExtDto';

import { EmailTemplatePropertiesComponent } from '../email-template-properties.component';

@Component({
	selector: 'document-email-template-list',
	styleUrls: ['./document-email-template-list.component.scss'],
	templateUrl: 'document-email-template-list.component.html'
})
export class DocumentEmailTemplateListComponent extends DocumentListBaseComponent<DocumentListItemExtDto> {
	constructor(
		dialog: MatDialog,
		router: Router,
		activatedRoute: ActivatedRoute,
		notification: NotificationService,
		docService: DocumentsService,
		documentsEditService: DocumentsEditService<DocumentListItemExtDto>,
		@Inject(DOCUMENT) document: Document,
		entityCreationNotifService: EntityCreatedNotificationService,
		gridViewService: GridViewService,
		store: Store<IAppState>,
		tasksService: TasksService
	) {
		super(
			[
				'createdDate',
				'title',
				'entityType',
				'practiceArea',
				'documentCategory',
				'documentTags',
				'lastModifiedBy',
				'installationRecordTitle',
				'action'
			],
			dialog,
			router,
			activatedRoute,
			gridViewService,
			store,
			notification,
			docService,
			entityCreationNotifService,
			document,
			documentsEditService,
			tasksService
		);
	}

	ngOnInit(): void {
		this.serviceType = DocumentServiceType.EmailTemplate;

		super.ngOnInit();

		this.data$ = this.data$.pipe(
			map(documents =>
				documents.map(document => {
					const icon = 'email-outline';

					return {
						...document,
						icon: icon,
						class: icon
					};
				})
			)
		);
	}

	createTemplate(): void {
		this.subscriptions.add(
			this.dialog.open(EmailTemplatePropertiesComponent).afterClosed().pipe(filter(Boolean)).subscribe()
		);
	}

	updateTemplate(document: DocumentListItemDto): void {
		const data = { id: document.id };

		this.subscriptions.add(
			this.dialog.open(EmailTemplatePropertiesComponent, { data }).afterClosed().pipe(filter(Boolean)).subscribe()
		);
	}

	protected getServiceUrl(): string {
		return 'api/v1/settings/documentemailtemplates/upload';
	}

	protected isTemplate(): boolean {
		return true;
	}

	protected isEmailTemplate(): boolean {
		return true;
	}
}
