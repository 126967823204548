export enum FeatureFlags {
	trustAccountingFeature = 'trustAccountingFeature',
	mergePdf = 'mergePdf',
	infoTrack = 'infoTrack',
	publicApi = 'publicApi',
	// zenDesk = 'zenDesk',
	invoiceWriteOff = 'invoiceWriteOff',
	licensing = 'licensing',
	marketplace = 'marketplace',
	documentVersioning = 'documentVersioning',
	basicWorkflows = 'basicWorkflows',
	swapTheme = 'swapTheme',
	maskingText = 'maskingText',
	reportCustomFields = 'reportCustomFields',
	emailTemplates = 'emailTemplates',
	activityTimeEntry = 'activityTimeEntry',
	globalTimer = 'globalTimer',
	matterUpdatedWorkflows = 'matterUpdatedWorkflows',
	matterSecurity = 'matterSecurity',
	mergeTask = 'mergeTask',
	taskNotifications = 'taskNotifications',
	collectionTask = 'collectionTask',
	openReplyForward = 'openReplyForward'
}

export type IFeatureFlags = {
	readonly [key in FeatureFlags]: boolean;
};

const activeFlagsDevTest: IFeatureFlags = {
	trustAccountingFeature: true,
	mergePdf: true,
	infoTrack: true,
	publicApi: true,
	// zenDesk: true,
	invoiceWriteOff: true,
	licensing: true,
	marketplace: true,
	documentVersioning: true,
	basicWorkflows: true,
	swapTheme: true,
	maskingText: false,
	reportCustomFields: true,
	emailTemplates: true,
	activityTimeEntry: true,
	globalTimer: true,
	matterUpdatedWorkflows: true,
	matterSecurity: true,
	mergeTask: true,
	taskNotifications: true,
	collectionTask: true,
	openReplyForward: true
};

const activeFlagsDemo: IFeatureFlags = {
	trustAccountingFeature: true,
	mergePdf: true,
	infoTrack: true,
	publicApi: true,
	// zenDesk: true,
	invoiceWriteOff: true,
	licensing: true,
	marketplace: true,
	documentVersioning: true,
	basicWorkflows: true,
	swapTheme: true,
	maskingText: false,
	reportCustomFields: true,
	emailTemplates: true,
	activityTimeEntry: true,
	globalTimer: true,
	matterUpdatedWorkflows: false,
	matterSecurity: true,
	mergeTask: false,
	taskNotifications: false,
	collectionTask: false,
	openReplyForward: false
};

const activeFlagsProd: IFeatureFlags = {
	trustAccountingFeature: true,
	mergePdf: true,
	infoTrack: true,
	publicApi: true,
	// zenDesk: true,
	invoiceWriteOff: true,
	licensing: true,
	marketplace: true,
	documentVersioning: true,
	basicWorkflows: true,
	swapTheme: false,
	maskingText: false,
	reportCustomFields: true,
	emailTemplates: true,
	activityTimeEntry: true,
	globalTimer: true,
	matterUpdatedWorkflows: false,
	matterSecurity: true,
	mergeTask: false,
	taskNotifications: false,
	collectionTask: false,
	openReplyForward: false
};

export const isFeatureFlagEnabled = (flagName: FeatureFlags) => {
	return AppConfigHelper.environment === 'dev' || AppConfigHelper.environment === 'test'
		? getLocalStorageOrDefault(activeFlagsDevTest[flagName])
		: AppConfigHelper.environment === 'demo'
		? activeFlagsDemo[flagName]
		: activeFlagsProd[flagName];

	function getLocalStorageOrDefault(defaultValue: boolean) {
		const persistedModel = AppConfigHelper.ReadAppConfigLocalStorage() as any;
		if (!!persistedModel && Object.keys(persistedModel).includes(flagName)) return persistedModel[flagName];
		else return defaultValue;
	}
};

export const AppConfig = {
	get PageSizeOptions(): number[] {
		return [10, 25, 50, 100];
	},

	get PageSize(): number {
		return this.PageSizeOptions[1];
	},

	get LawMasterAppServerUrl(): string {
		const octopusValue: string = '#{LawMasterAppServerUrl}';
		return octopusValue.lastIndexOf('#', 0) === 0 ? 'https://lawmaster:45319' : octopusValue;
	},

	get MatteroAppServerUrl(): string {
		const octopusValue: string = '#{MatteroAppServerUrl}';
		return octopusValue.lastIndexOf('#', 0) === 0 ? 'https://localhost:44319' : octopusValue;
	},

	get LawMasterAppClientUrl(): string {
		let host = AppConfig.LawMasterAppServerUrl;

		if (host.includes(':44319') || host.includes(':45319')) {
			host = host.replace('https://', 'http://').replace('44319', '3000').replace('45319', '3002');
		}

		return host;
	},

	get MatteroAppClientUrl(): string {
		let host = AppConfig.MatteroAppServerUrl;

		if (host.includes(':44319') || host.includes(':45319')) {
			host = host.replace('https://', 'http://').replace('44319', '3000').replace('45319', '3002');
		}

		return host;
	},

	get LawMasterAuthServerUrl(): string {
		const octopusValue: string = '#{LawMasterAuthServerUrl}';
		return octopusValue.lastIndexOf('#', 0) === 0 ? 'https://lawmaster:45329' : octopusValue;
	},

	get MatteroAuthServerUrl(): string {
		const octopusValue: string = '#{MatteroAuthServerUrl}';
		return octopusValue.lastIndexOf('#', 0) === 0 ? 'https://localhost:44329' : octopusValue;
	},

	get IsLawMasterHost(): boolean {
		const url = new URL(window.location.href);

		return new URL(AppConfig.LawMasterAppServerUrl).hostname == url.hostname;
	},

	get IsMatteroHost(): boolean {
		const url = new URL(window.location.href);

		return new URL(AppConfig.MatteroAppServerUrl).hostname == url.hostname;
	},

	get AppServerUrl(): string {
		return AppConfig.IsLawMasterHost ? AppConfig.LawMasterAppServerUrl : AppConfig.MatteroAppServerUrl;
	},

	get AppClientUrl(): string {
		return AppConfig.IsLawMasterHost ? AppConfig.LawMasterAppClientUrl : AppConfig.MatteroAppClientUrl;
	},

	get AuthServerUrl(): string {
		return AppConfig.IsLawMasterHost ? AppConfig.LawMasterAuthServerUrl : AppConfig.MatteroAuthServerUrl;
	},

	get ApiDocServerUrl(): string {
		const octopusValue: string = '#{ApiDocServerUrl}';
		return octopusValue.lastIndexOf('#', 0) === 0 ? 'https://localhost:44319/developer' : octopusValue;
	},

	get InstrumentationKey(): string {
		const octopusValue: string = '#{InstrumentationKey}';
		return octopusValue.lastIndexOf('#', 0) === 0 ? '22f917d2-d75b-4bbf-9535-68f4ff20d78a' : octopusValue;
	},

	get GoogleAnalyticsTrackingId(): string {
		const octopusValue: string = '#{GoogleAnalyticsTrackingId}';
		return octopusValue.lastIndexOf('#', 0) === 0 ? 'UA-146467339-2' : octopusValue;
	},

	get GoogleMarketingAnalyticsTrackingId(): string {
		const devopsValue: string = '#{GoogleMarketingAnalyticsTrackingId}';
		return devopsValue.lastIndexOf('#', 0) === 0 ? 'UA-146467339-5' : devopsValue;
	},

	get RecaptchaSiteKey(): string {
		const devopsValue: string = '#{Recaptcha.SiteKey}';
		return devopsValue.lastIndexOf('#', 0) === 0 ? '6Lch1OMUAAAAAAuqQu6sz0_aiVA3UGAYxH4F-fnK' : devopsValue;
	},

	get ReportActionRoute(): string {
		return '/DXXRDV';
	},

	get promoCodeLocalStorageKey(): string {
		return 'promocode';
	},

	get calendarModeCodeLocalStorageKey(): string {
		return 'calendarMode';
	},

	get AppMxServer(): string {
		const octopusValue: string = '#{AppMxServer}';
		return octopusValue.lastIndexOf('#', 0) === 0 ? 'docs-debug.mastrin.com.au' : octopusValue;
	}
};

export type EnvironmentOptions = 'dev' | 'test' | 'demo' | 'prod';

// Single out this variable to reduce footprint in the generated JS for 'fast-oidc-redirects.ts'
export let AuthServerUrl = AppConfig.AuthServerUrl;

export class AppConfigHelper {
	private static _environment: EnvironmentOptions;
	public static get environment(): EnvironmentOptions {
		if (!this._environment) {
			const deploymentServerUrl: string = AppConfig.AppServerUrl;

			this._environment =
				deploymentServerUrl.indexOf('dev') > -1 ||
				deploymentServerUrl.includes('44319') ||
				deploymentServerUrl.includes('45319')
					? 'dev'
					: deploymentServerUrl.indexOf('test') > -1
					? 'test'
					: deploymentServerUrl.indexOf('demo') > -1
					? 'demo'
					: 'prod';
		}
		return this._environment;
	}

	private static _activeFlags: IFeatureFlags;
	public static get activeFlags(): IFeatureFlags {
		if (!this._activeFlags) {
			if (this._environment === 'dev' || this._environment === 'test') {
				this._activeFlags = activeFlagsDevTest;
			} else if (this._environment === 'demo') {
				this._activeFlags = activeFlagsDemo;
			} else {
				this._activeFlags = activeFlagsProd;
			}
		}

		return this._activeFlags;
	}

	/**
	 * Returns only persisted values so this should be merged with the default feature flags
	 */
	public static ReadAppConfigLocalStorage(): object {
		const value = localStorage.getItem(`app-config-${AppConfigHelper.environment}`);
		if (!!value) return JSON.parse(value);
		else return {};
	}

	public static UpdateAppConfigLocalStorage(persistence: any) {
		localStorage.setItem(`app-config-${AppConfigHelper.environment}`, JSON.stringify(persistence));
	}
}
