import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { OmnisearchResponse } from '@common/models/Omnisearch/OmnisearchResponse';
import { OmnisearchService } from '@common/services/omnisearch.service';

@Component({
	selector: 'omnisearch-dialog',
	styleUrls: ['./omnisearch-dialog.component.scss'],
	templateUrl: 'omnisearch-dialog.component.html'
})
export class OmnisearchDialogComponent {
	readonly input: FormControl = new FormControl();
	readonly sections: string[];
	options: OmnisearchResponse;

	private readonly MATTER_ROUTE: string = 'matters';
	private readonly CONTACT_ROUTE: string = 'contacts';
	private readonly DOCUMENT_ROUTE: string = 'documents';

	constructor(
		private dialogRef: MatDialogRef<OmnisearchDialogComponent>,
		httpClient: HttpClient,
		private service: OmnisearchService
	) {
		this.sections = [this.MATTER_ROUTE, this.CONTACT_ROUTE, this.DOCUMENT_ROUTE];
	}

	closeDialog() {
		this.dialogRef.close();
	}
}
