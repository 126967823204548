import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { CustomValidators } from '@common/validation/custom.validators';

import { ILookupReference } from '@common/components/lookups/base-lookup.component';
import { IAssignContact } from './IAssignContact';

@Component({
	selector: 'contact-lookup-dialog',
	styleUrls: ['./contact-lookup-dialog.component.scss'],
	templateUrl: './contact-lookup-dialog.component.html'
})
export class ContactLookupDialogComponent implements OnInit {
	form: FormGroup;
	data: IAssignContact;
	constructor(
		private dialogRef: MatDialogRef<ContactLookupDialogComponent>,
		@Optional()
		@Inject(MAT_DIALOG_DATA)
		public relatedContactdata: IAssignContact,
		private fb: FormBuilder
	) {
		this.data = relatedContactdata;
	}

	ngOnInit() {
		if (!this.data.overrideHint) {
			this.data.hint = 'Start typing the name to find a ' + this.data.hint;
		}

		this.form = this.fb.group({
			contactId: [this.data.selectedContactId, CustomValidators.requiredWhen(() => this.data.mandatory)],
			createCompanies: null,
			createPersons: null
		});

		if (!!this.data.allowCreatePlural) {
			this.form.addControl('createPlurals', this.fb.control(null));
		}
	}

	saveRole() {
		this.dialogRef.close(this.form);
	}

	onContactSelected(event: ILookupReference) {
		this.form.controls.contactId.setValue(event.id);
	}

	getControl(name: string): FormControl {
		return this.form?.get(name) as FormControl;
	}
}
