import { ListResponse } from '@common/models/Generic/ListResponse';
import { DomainError } from '@common/models/Validation/DomainError';

import { CostTemplateListItemDto } from '@common/models/Settings/CostTemplates/List/CostTemplateListItemDto';
import { AppConfig } from 'app/app.config';
import { ListRequest } from '@common/models/Generic/ListRequest';

export interface ICostTemplateListState {
	isFetching: boolean;
	list: ListResponse<CostTemplateListItemDto>;
	lastSelectedRecordId: string;
	request: Partial<ListRequest>;

	// errrors
	error: DomainError;
}

export const initialState: ICostTemplateListState = {
	isFetching: false,
	list: null,
	lastSelectedRecordId: null,
	request: {
		search: null,

		pageSize: AppConfig.PageSize,
		pageIndex: 0,
		pageIndexForId: null,

		sortBy: null,
		sortDirection: null
	},

	error: null
};
