<mat-card>
	<section class="page-path"><a [routerLink]="['..']">System</a> / Share Links</section>
	<h1>Share Links</h1>
	<div class="description">Manage settings for Document and Brief sharing links</div>

	<div class="flex-column">
		<div class="control-row">
			<div class="input-label">Default Expiry in Days</div>

			<div class="input-control">
				<editable-numeric-value [control]="getControl('defaultExpiryInDays')" tooltip="" [editMode]="true"></editable-numeric-value>
			</div>
		</div>
	</div>

	<div class="actions">
		<button mat-raised-button [disabled]="!!isFormValid" color="primary" throttleButton (throttledClick)="saveChanges()">Save</button>
	</div>
</mat-card>
