<ng-container *ngIf="!!showButton">
	<button
		class="c-menu-button"
		mat-button
		#buttonMenuTrigger="matMenuTrigger"
		[matMenuTriggerFor]="actionMenu"
		(menuOpened)="onActionMenuOpened()"
		[matTooltip]="tooltip"
		matTooltipClass="timer-tooltip"
	>
		<div class="c-timer-duration">
			<ng-content></ng-content>
			<div class="c-timer-duration-text">{{ activeDuration }}</div>
		</div>
	</button>

	<mat-menu #actionMenu="matMenu" [overlapTrigger]="false" xPosition="before">
		<button mat-menu-item class="menu-actions">
			<a (click)="openCreateTimer()" class="additional-action view-all">
				<mat-icon class="view-all-icon">more_time</mat-icon>Add Timer
			</a>
			<a [routerLink]="['/timers']" class="additional-action view-all">
				<mat-icon class="view-all-icon">pending_actions</mat-icon>View All
			</a>
		</button>
		

		<button mat-menu-item class="mat-menu-text c-timer-button" *ngFor="let item of timers" [routerLink]="['/matters', item.id.split('-').slice(0, 2).join('-')]">
			<div class="c-timer-button__header">
				<mat-icon>gavel</mat-icon>
				<span>{{ item.number }} - {{ item.title }}</span>
				<div *ngIf="getDescription(item).length > 0" class="description">{{ getDescription(item) }}</div>
			</div>
			<work-timer class="c-timer-button__controls" [timerId]="item.id" [allowAutostart]="false" [useWrap]="false" [useReverseLayout]="true"></work-timer>
		</button>
	</mat-menu>
</ng-container>
