import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';

import { get, isEmpty, sortBy, startCase } from 'lodash-es';

import { Address } from '@common/models/Contacts/Common/Address';

import { BaseEditableComponent } from './base.editable.component';
import { AddressField, EditableAddressService, IAddressData } from './editable-address.service';
import { Subscription } from 'rxjs';

const countries = require('./countries.json');

interface IOption {
	value: string;
	label: string;
}

@Component({
	selector: 'editable-address',
	styleUrls: ['./editable-address.component.scss'],
	templateUrl: 'editable-address.component.html'
})
export class EditableAddressComponent extends BaseEditableComponent<Address> implements OnInit, OnDestroy {
	@Input()
	optional: boolean;

	regions: IOption[] = Object.keys(countries).map(key => ({
		label: get(countries, key),
		value: key
	}));
	regionCode: IAddressData;
	administrativeAreas: IOption[];
	administrativeArea: IAddressData;
	localities: IOption[];
	locality: IAddressData;
	subLocalities: IOption[];
	subLocality: IAddressData;

	private subscriptions: Subscription = new Subscription();

	constructor(private service: EditableAddressService) {
		super();
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	ngOnInit() {
		// remove any existing validators and replace it with the one group validator
		Object.keys((this.control as FormGroup).controls).forEach(name => {
			this.childControl(name).clearValidators()

			this.subscriptions.add(
				this.childControl(name).valueChanges
					.subscribe((control: AbstractControl) => control.markAsTouched())
			);
		});
		this.control.setValidators(this.formValidator);

		this.subscriptions.add(
			this.service
				.getCountry(this.value('regionCode'))
				.subscribe(defaults => {
					this.regionCode = defaults;
				})
		);
	}

	formValidator: ValidatorFn = (group: FormGroup): ValidationErrors => {
		const administrativeArea = group.get('administrativeArea');
		const line1 = group.get('line1');
		const locality = group.get('locality');
		const postalCode = group.get('postalCode');
		const subLocality = group.get('subLocality');

		if (this.isAdministrativeAreaRequired && isEmpty(administrativeArea.value)) {
			administrativeArea.setErrors({
				customRequired: `${this.formatName(get(this.regionCode, 'state_name_type'))} must be entered`
			});
		} else {
			administrativeArea.setErrors(null);
		}

		if (this.isLocalityRequired && isEmpty(locality.value)) {
			locality.setErrors({
				customRequired: `${this.formatName(get(this.regionCode, 'locality_name_type'))} must be entered`
			});
		} else {
			locality.setErrors(null);
		}

		if (this.isSubLocalityRequired && isEmpty(subLocality.value)) {
			subLocality.setErrors({
				customRequired: `${this.formatName(get(this.regionCode, 'sublocality_name_type'))} must be entered`
			});
		} else {
			subLocality.setErrors(null);
		}

		if (this.isZipCodeRequired && isEmpty(postalCode.value)) {
			postalCode.setErrors({
				customRequired: `${this.formatName(get(this.regionCode, 'zip_name_type') + ' code')} must be entered`
			});
		} else if (
			this.isZipCodeRequired &&
			(!new RegExp(`^${get(this.regionCode, 'zip', '.*')}$`).test(postalCode.value) ||
				!new RegExp(`^${get(this.administrativeArea, 'zip', '')}`).test(postalCode.value))
		) {
			postalCode.setErrors({
				customPattern: `Invalid ${this.formatName(get(this.regionCode, 'zip_name_type') + ' code')}`
			});
		} else {
			postalCode.setErrors(null);
		}

		if (this.isAddressRequired && isEmpty(line1.value)) {
			line1.setErrors({ customRequired: 'Address 1 must be entered' });
		} else {
			line1.setErrors(null);
		}

		return null;
	};

	onRegionSelected(event: MatOptionSelectionChange) {
		if (event.source.selected) {
			this.subscriptions.add(
				this.service
					.getCountry(event.source.value)
					.subscribe(regionCode => {
						this.regionCode = regionCode;
						this.administrativeArea = null;
						this.locality = null;
						this.subLocality = null;
						this.administrativeAreas = this.extractSubRegionOptions(regionCode);
						this.localities = null;
						this.subLocalities = null;
					})
			);
		}
	}

	onAdministrativeAreaSelected(event: MatOptionSelectionChange) {
		if (event.source.selected) {
			this.subscriptions.add(
				this.service
					.getAdministrativeArea(this.value('regionCode'), event.source.value)
					.subscribe(administrativeArea => {
						this.administrativeArea = administrativeArea;
						this.locality = null;
						this.subLocality = null;
						this.localities = this.extractSubRegionOptions(administrativeArea);
						this.subLocalities = null;
					})
			);
		}
	}

	onLocalitySelected(event: MatOptionSelectionChange) {
		if (event.source.selected) {
			this.subscriptions.add(
				this.service
					.getLocality(this.value('regionCode'), this.value('administrativeArea'), event.source.value)
					.subscribe(locality => {
						this.locality = locality;
						this.subLocality = null;
						this.subLocalities = this.extractSubRegionOptions(locality);
					})
			);
		}
	}

	onSubLocalitySelected(event: MatOptionSelectionChange) {
		if (event.source.selected) {
			this.subscriptions.add(
				this.service
					.getSubLocality(
						this.value('regionCode'),
						this.value('administrativeArea'),
						this.value('locality'),
						event.source.value
					)
					.subscribe(subLocality => {
						this.subLocality = subLocality;
					})
			);
		}
	}

	formatName(input: string): string {
		return startCase(input);
	}

	getCountryNameByCode(regionCode: string): string {
		const region = this.regions.find(c => c.value === regionCode);
		return !!region ? region.label : '';
	}

	get showAdministrativeArea(): boolean {
		return get(this.regionCode, 'fmt', '').indexOf(`%${AddressField.AdministrativeArea}`) >= 0;
	}

	get showLocality(): boolean {
		return get(this.regionCode, 'fmt', '').indexOf(`%${AddressField.Locality}`) >= 0;
	}

	get showSubLocality(): boolean {
		return get(this.regionCode, 'fmt', '').indexOf(`%${AddressField.SubLocality}`) >= 0;
	}

	get showZipCode(): boolean {
		return get(this.regionCode, 'fmt', '').indexOf(`%${AddressField.ZipCode}`) >= 0;
	}

	get isAddressRequired(): boolean {
		return this.isRequired && get(this.regionCode, 'require', '').indexOf(AddressField.Address) >= 0;
	}

	get isAdministrativeAreaRequired(): boolean {
		return this.isRequired && get(this.regionCode, 'require', '').indexOf(AddressField.AdministrativeArea) >= 0;
	}

	get isLocalityRequired(): boolean {
		return this.isRequired && get(this.regionCode, 'require', '').indexOf(AddressField.Locality) >= 0;
	}

	get isSubLocalityRequired(): boolean {
		return this.isRequired && get(this.regionCode, 'require', '').indexOf(AddressField.SubLocality) >= 0;
	}

	get isZipCodeRequired(): boolean {
		return this.isRequired && get(this.regionCode, 'require', '').indexOf(AddressField.ZipCode) >= 0;
	}

	get postalCodeHint(): string {
		const examples = get(this.administrativeArea, 'zipex', get(this.regionCode, 'zipex'));
		return examples ? `e.g. ${examples}` : '';
	}

	get googleMapsLink(): string {
		let addr = this.value('formattedAddress');
		if (!addr) return null;

		addr = addr.replace('\n', ', ') + ' ' + this.getCountryNameByCode(this.value('regionCode'));

		return `http://maps.google.com/maps?q=${encodeURIComponent(addr)}`;
	}

	private extractSubRegionOptions(region: IAddressData): IOption[] {
		return sortBy(
			(get(region, 'sub_keys') || []).map<IOption>((val, idx) => ({
				// try sub_lnames, then sub_names, then fall back to sub_keys
				label: get(
					region,
					['sub_lnames', idx],
					get(region, ['sub_names', idx], get(region, ['sub_keys', idx]))
				),
				value: val
			})),
			'label'
		);
	}

	get isRequired(): boolean {
		return (
			!this.optional ||
			!isEmpty(this.value('line1')) ||
			!isEmpty(this.value('line2')) ||
			!isEmpty(this.value('locality')) ||
			!isEmpty(this.value('subLocality')) ||
			!isEmpty(this.value('administrativeArea')) ||
			!isEmpty(this.value('postalCode'))
		);
	}
}
