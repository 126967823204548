import { Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { filter, switchMap } from 'rxjs/operators';

import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';
import { UserTypeDto } from '@common/models/Settings/UserTypes/Item/UserTypeDto';
import { UserTypeListItemDto } from '@common/models/Settings/UserTypes/List/UserTypeListItemDto';
import { DomainError } from '@common/models/Validation/DomainError';
import { NotificationService } from '@common/notification';
import { UserTypesService } from '@common/services/settings/usertypes.service';
import { Store } from '@ngrx/store';

import { IAppState } from 'app/core/state/app.state';
import { UserTypeListActions } from 'app/core/state/lists/settings/user-type-list/user-type-list.actions';
import {
	selectUserTypeIsFetching,
	selectUserTypeListRecords,
	selectUserTypeListRequest,
	selectUserTypeListTotalRecords
} from 'app/core/state/lists/settings/user-type-list/user-type-list.selectors';
import { GridViewService } from 'app/services/grid-view.service';
import {
	ActionTypes,
	GenericListStateComponent,
	SelectorTypes
} from 'app/shared/generics/generic.list.state.component';

import { UserTypeItemComponent } from '../item/user-type-item.component';

@Component({
	styleUrls: ['./user-type-list.component.scss'],
	templateUrl: './user-type-list.component.html'
})
export class UserTypeListComponent extends GenericListStateComponent<UserTypeListItemDto, void> implements OnDestroy {
	get actions(): ActionTypes {
		return {
			init: UserTypeListActions.Init,
			load: UserTypeListActions.Load,
			setFilters: UserTypeListActions.SetFilters,
			setPageIndex: UserTypeListActions.SetPageIndex,
			setPageIndexForId: UserTypeListActions.SetPageIndexForId,
			setPageSize: UserTypeListActions.SetPageSize,
			setSortBy: UserTypeListActions.SetSortBy,
			setSortDirection: UserTypeListActions.SetSortDirection,
			selected: UserTypeListActions.SelectRecords
		};
	}
	get selectors(): SelectorTypes {
		return {
			records: selectUserTypeListRecords,
			isFetching: selectUserTypeIsFetching,
			request: selectUserTypeListRequest,
			totalRecords: selectUserTypeListTotalRecords
		};
	}
	constructor(
		store: Store<IAppState>,
		dialog: MatDialog,
		router: Router,
		activatedRoute: ActivatedRoute,
		private userTypesService: UserTypesService,
		private notificationService: NotificationService,
		gridViewService: GridViewService
	) {
		super(['name', 'description', 'actions'], dialog, store, router, activatedRoute, gridViewService);
	}

	createUserType() {
		this.subscriptions.add(
			this.dialog
				.open(UserTypeItemComponent, { data: new UserTypeDto() })
				.afterClosed()
				.pipe(
					filter(Boolean),
					switchMap((data: UserTypeDto) => this.userTypesService.createUserType(data))
				)
				.subscribe(this.onCreateSuccess, this.onError)
		);
	}

	editUserType(row: UserTypeListItemDto) {
		this.subscriptions.add(
			this.dialog
				.open(UserTypeItemComponent, { data: row })
				.afterClosed()
				.pipe(
					filter(Boolean),
					switchMap((data: UserTypeDto) =>
						this.userTypesService.updateUserType(row.id, { ...data, id: row.id })
					)
				)
				.subscribe(this.onUpdateSuccess, this.onError)
		);
	}

	deleteUserType(row: UserTypeListItemDto) {
		this.subscriptions.add(
			this.notificationService
				.showConfirmation('Delete User Type', `Are you sure you want to delete the user type "${row.name}"?`)
				.pipe(
					filter(Boolean),
					switchMap(() => this.userTypesService.deleteUserType(row.id))
				)
				.subscribe({
					next: response => {
						this.notificationService.showNotification(`User Type deleted: ${response.name}`);
						this.store.dispatch({ type: UserTypeListActions.RemoveRecords, response: response });
					},
					error: (error: DomainError[]) =>
						this.notificationService.showErrors('Error deleting User Type', error)
				})
		);
	}

	private onError = (errors: DomainError[]) => {
		this.notificationService.showErrors('Error', errors);
	};

	private onCreateSuccess = (result: MutationResponseDto) => {
		this.notificationService.showNotification(`User Type ${result.name} created`);
		this.store.dispatch({ type: UserTypeListActions.InsertRecords, response: result });
	};

	private onUpdateSuccess = (result: MutationResponseDto) => {
		this.notificationService.showNotification(`User Type ${result.name} updated`);
		this.store.dispatch({ type: UserTypeListActions.UpdateRecords, response: result });
	};
}
