<h2 mat-dialog-title>
	<span>Set Xero Invoice Reference Format</span>
</h2>
<mat-dialog-content>
	<div class="description">Select the format that should appear for the invoice reference field in Xero once synced</div>

	<div class="flexrow">
		<span class="description">Example invoice reference in Xero : &nbsp; </span>
		<h3 class="sample-text">
			<span *ngFor="let item of items; let first = first">
				<span *ngIf="!first"> - </span>

				<b>{{ item.example }}</b>
			</span>
		</h3>
	</div>

	<div cdkDropListGroup>
		<span class="error">{{ errorMessage }}</span>
		<h2 class="description">Invoice reference</h2>

		<div cdkDropList cdkDropListOrientation="horizontal" [cdkDropListData]="items" class="drag-drop-box" (cdkDropListDropped)="drop($event)">
			<span [ngClass]="items?.length > 0 ? 'drag-drop-label-right' : 'drag-drop-label-center'"
				><mat-icon>highlight_alt</mat-icon>Drag and drop variables here</span
			>
			<div class="flexrow">
				<div *ngFor="let item of items; let first = first" class="chip-flex-row">
					<span class="chip-hyphen" *ngIf="!first"> - </span>
					<div class="chip-flex-column">
						<mat-chip class="chip-primary" cdkDrag [cdkDragData]="item" [selectable]="true" [removable]="true" (removed)="remove(item)">
							{{ item.title }}
							<mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
						</mat-chip>
						<span>({{ item.example }})</span>
					</div>
				</div>
			</div>
		</div>

		<div class="basket-heading">
			<h2 class="description">Available variables</h2>

			<mat-form-field>
				<input [formControl]="form.get('basketSearch')" matInput autocomplete="off" />
				<mat-placeholder>Search</mat-placeholder>
				<button
					*ngIf="!!form.get('basketSearch')?.value"
					matSuffix
					mat-icon-button
					aria-label="Clear"
					throttleButton
					(throttledClick)="form.get('basketSearch')?.setValue(null)"
				>
					<mat-icon>close</mat-icon>
				</button>
			</mat-form-field>
		</div>

		<div cdkDropList [cdkDropListData]="basket" class="drag-drop-box drag-drop-box--basket" (cdkDropListDropped)="drop($event)">
			<div class="flex-column">
				<div *ngFor="let group of basket" class="chip-flex-group">
					<div class="chip-flex-group-heading">{{ group.name }}</div>
					<div class="flexrow flexrow--wrap">
						<div *ngFor="let item of group.items" class="chip-flex-column">
							<mat-chip cdkDrag [cdkDragData]="item" [selectable]="true" [removable]="false">
								{{ item.title }}
								<mat-icon>drag_indicator</mat-icon>
							</mat-chip>
							<span>({{ item.example }})</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-raised-button [disabled]="errorMessage?.length > 0" color="primary" [mat-dialog-close]="this.invoiceFormat">OK</button>
</mat-dialog-actions>
