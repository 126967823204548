// This file has been generated from MutationType.cs
// Do not modify this file directly!!

// LawMaster.Amalthea.Domain.Common.MutationType
export enum MutationType {
	Unspecified = 'Unspecified',
	Create = 'Create',
	Update = 'Update',
	Delete = 'Delete'
}
