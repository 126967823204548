import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { TrustAccountsService } from '@common/services/settings/trustaccounts.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import moment from 'moment';

import { IAppState } from '../../app.state';
import { loading, TrustAccountFilterActions } from './trust-account-filter.actions';

@Injectable()
export class TrustAccountFilterEffects {
	loadTrustAccounts$ = createEffect(() =>
		this._actions$.pipe(
			ofType(TrustAccountFilterActions.Load, TrustAccountFilterActions.InvalidateCache),
			map(action => action as { type: TrustAccountFilterActions }),
			withLatestFrom(this._store),
			filter(([action, state]) => {
				const trustAccountState = state.trustAccountFilterData;

				if (action.type === TrustAccountFilterActions.InvalidateCache && !trustAccountState.list) {
					return false;
				}

				return !trustAccountState.cacheExpiry || !!moment().isSameOrAfter(trustAccountState.cacheExpiry);
			}),
			debounceTime(500),
			tap(() => this._store.dispatch(loading())),
			switchMap(() =>
				this._trustAccountsService.getTrustAccountList().pipe(
					map(response => ({
						type: TrustAccountFilterActions.LoadSuccess,
						response
					})),
					catchError(error => of({ type: TrustAccountFilterActions.LoadFailed, error }))
				)
			)
		)
	);

	constructor(
		private _actions$: Actions,
		private _store: Store<IAppState>,
		private _trustAccountsService: TrustAccountsService
	) {}
}
