import { TaxConfiguration } from '@common/models/Settings/BillSettings/Common/TaxConfiguration';
import { TenantFeatureStateDto } from '@common/models/Settings/Setting/Item/TenantFeatureStateDto';
import { TenantInformationDto } from '@common/models/Settings/Setting/Item/TenantInformationDto';
import { createAction, props } from '@ngrx/store';

export enum TenantActions {
	LoadTenant = '[Tenant] Load',
	LoadTenantSuccess = '[Tenant] Load Success',
	LoadTenantError = '[Tenant] Load Error',
	UpdateTenantFeatures = '[Tenant] Update Tenant Features',
	UpdateTaxOption = '[Tenant] Update Tax Option'
}

export const loadTenantSuccess = createAction(
	TenantActions.LoadTenantSuccess,
	props<{ tenantInformation: TenantInformationDto }>()
);

export const loadTenantError = createAction(TenantActions.LoadTenantError, props<{ error: any }>());

export const updateTenantFeatures = createAction(
	TenantActions.UpdateTenantFeatures,
	props<{ featureStates: TenantFeatureStateDto[] }>()
);

export const updateTaxOption = createAction(
	TenantActions.UpdateTaxOption,
	props<{ taxConfiguration: keyof typeof TaxConfiguration }>()
);
