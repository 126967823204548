// This file has been generated from DocumentMergeTaskTemplateViewDto.cs
// Do not modify this file directly!!
// tslint:disable

import { SetComplexType } from '@common/utils/class-transform';

import { TaskPriority } from '../../../Tasks/Common/TaskPriority';
import { TaskStatus } from '../../../Tasks/Common/TaskStatus';
import { TaskType } from '../../../Tasks/Common/TaskType';
import { TaskTemplateAssignedToContactOptions } from '../Common/TaskTemplateAssignedToContactOptions';
import { TaskTemplateDueDateOptions } from '../Common/TaskTemplateDueDateOptions';

// LawMaster.Amalthea.Domain.Settings.TaskTemplates.Item.DocumentMergeTaskTemplateViewDto
export class DocumentMergeTaskTemplateViewDto {

	// Id
	id: string;
	// BasicWorkflowId
	basicWorkflowId: string;
	// Description
	description: string;
	// Status
	status: keyof typeof TaskStatus;
	// Priority
	priority: keyof typeof TaskPriority;
	// AssignedToContactOptions
	@SetComplexType(TaskTemplateAssignedToContactOptions)
	assignedToContactOptions: TaskTemplateAssignedToContactOptions;
	// DueDateOptions
	@SetComplexType(TaskTemplateDueDateOptions)
	dueDateOptions: TaskTemplateDueDateOptions;
	// TaskType
	taskType: keyof typeof TaskType;
	// DocumentTemplateIds
	documentTemplateIds: string[];

	constructor() {
		this.assignedToContactOptions = new TaskTemplateAssignedToContactOptions();
		this.dueDateOptions = new TaskTemplateDueDateOptions();
	}
}