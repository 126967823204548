import { AppConfig } from 'app/app.config';

export const infoTrackAuthConfig = {
	// Set to code to indicate an authorisation code flow
	responseType: 'code',

	// The scope of the requested authorisation in OAuth.
	scope: 'openid+profile+infsec:basic+infsec:identity+offline_access',

	// Client Id for mattero access
	get clientId(): string {
		const value: string = '#{InfoTrackClientId}';
		return value.lastIndexOf('#', 0) === 0 ? 'a2c621b0-d74a-4ebf-af3e-8b853e0b3323' : value;
	},

	// The prefix of the retailer reference determining the environment for SecureCallback endpoint from InfoTrack
	get retailerReferencePrefix(): string {
		const value: string = '#{InfoTrackRetailerReferencePrefix}';
		return value.lastIndexOf('#', 0) === 0 ? 'Mattero' : value;
	},

	// The RP callback URI for the authentication response.
	get redirectUri(): string {
		return AppConfig.AppClientUrl + '/infotrack-auth-handler';
	},

	// URL to connect to the infotrack token server
	get authorizationUrl(): string {
		return this.authUrl + '/connect/authorize';
	},

	// URL to connect to the infotrack authorization server
	get authUrl(): string {
		const value: string = '#{InfoTrackAuthUrl}';
		return value.lastIndexOf('#', 0) === 0 ? 'https://stageauth.infotrack.com.au' : value;
	}
};
