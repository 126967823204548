import { HttpClient, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { Component, Input, ViewChild } from '@angular/core';

import { DocumentViewDto } from '@common/models/Documents/Item/DocumentViewDto';

@Component({
	selector: 'pdf-preview',
	styleUrls: ['./pdf-preview.component.scss'],
	templateUrl: './pdf-preview.component.html'
})
export class PdfPreviewComponent {
	popoutPDF: boolean = false;
	totalSize: number;
	currentLoaded: number;
	loadedPercentage: number;
	showViewer: boolean;
	isLargeFile: boolean;

	@ViewChild('pdfViewerOnDemand') pdfViewerOnDemand: any;
	@ViewChild('pdfViewerExternalWindow') pdfViewerExternalWindow: any;
	private _previewDto: DocumentViewDto;
	@Input() set previewDto(value: DocumentViewDto) {
		this._previewDto = value;
		this.processPdfFile(this._previewDto);
	}

	get previewDto(): DocumentViewDto {
		return this._previewDto;
	}

	constructor(private http: HttpClient) {}

	processPdfFile(dto: DocumentViewDto): void {
		this.isLargeFile = dto.size > 52428800;
		this.currentLoaded = 0;
		this.totalSize = 0;
		this.loadedPercentage = 0;

		if (!this.isLargeFile) {
			this.showViewer = true;
			this.downloadFile(dto.previewUrl);
		} else {
			this.showViewer = false;
		}
	}

	// method to preview a large file. Should be explicitly clicked by the user for the preview to start.
	preview() {
		this.downloadFile(this.previewDto.previewUrl);
	}

	downloadFile(url: string): void {
		const req = new HttpRequest('GET', url, {
			reportProgress: true,
			responseType: 'blob'
		});
		this.reportProgress(req);
	}

	openInExternalWindow() {
		this.popoutPDF = true;
		setTimeout(() => {
			this.pdfViewerExternalWindow.pdfSrc = this.pdfViewerOnDemand.pdfSrc;
			this.pdfViewerExternalWindow.refresh(); // Ask pdf viewer to load/refresh pdf
		}, 0);
	}

	private reportProgress(request: HttpRequest<any>): void {
		this.http.request(request).subscribe(httpEvent => {
			// Via this API, you get access to the raw event stream.
			// Look for download progress events.
			if (httpEvent.type === HttpEventType.DownloadProgress) {
				this.totalSize = httpEvent.total;
				this.currentLoaded = httpEvent.loaded;
				this.loadedPercentage = (this.currentLoaded * 100) / this.totalSize;
			} else if (httpEvent instanceof HttpResponse) {
				this.showViewer = true;
				this.totalSize = this.currentLoaded = (httpEvent.body as Blob).size;
				setTimeout(() => {
					this.pdfViewerOnDemand.pdfSrc = httpEvent.body as Blob; // pdfSrc can be Blob or Uint8Array
					this.pdfViewerOnDemand.refresh(); // Ask pdf viewer to load/refresh pdf
				}, 0);
			}
		});
	}
}
