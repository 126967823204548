import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { filter } from 'rxjs/operators';

import { get, template } from 'lodash-es';

@Injectable()
export class PageTitleService {
	title: string;

	constructor(private router: Router, private titleService: Title, private activatedRoute: ActivatedRoute) {}

	InitializePageTitles() {
		this.getTitleFromRoute();
		this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(
			(event: NavigationEnd): void => {
				this.getTitleFromRoute();
			}
		);
	}

	getTitleFromRoute() {
		let root = this.activatedRoute.snapshot.root;

		while (root.children && root.children.length) {
			root.children.forEach(childRoute => {
				root = childRoute;
				this.title = get(childRoute, 'data.title', this.title);
			});
		}

		if (this.title && this.title.indexOf('{{') === -1) this.titleService.setTitle(this.title);
	}

	updateData(data: any) {
		if (this.title && data) {
			const compiled = template(this.title, {
				interpolate: /{{([\s\S]+?)}}/g
			});
			this.title = compiled(data);
			if (this.title && this.title.indexOf('{{') === -1) this.titleService.setTitle(this.title);
		}
	}
}
