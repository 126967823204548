import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { forkJoin } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { EnumSortDirection } from '@common/models/Common/EnumSortDirection';
import { RecurringInvoiceDto } from '@common/models/Infrastructure/StripeIntegration/Models/RecurringInvoiceDto';
import { UpcomingInvoiceDto } from '@common/models/Infrastructure/StripeIntegration/Models/UpcomingInvoiceDto';
import { DomainError } from '@common/models/Validation/DomainError';
import { NotificationService } from '@common/notification';
import { GeneralSettingsService } from '@common/services/settings/generalsettings.service';
import { MarketplaceService } from '@common/services/settings/marketplace.service';
import { PracticeAreasService } from '@common/services/settings/practiceareas.service';
import { StripeService } from '@common/services/stripe.service';
import { CustomValidators } from '@common/validation/custom.validators';

import { ConfigurePackageComponent, ConfigurePackageOperationType, IPackageConfiguration, IPackageConfigurationData } from '../marketplace/package/configure-package.component';
import { BaseModifyMarketplaceItemSubscription, IBaseModifySubscriptionItemComponent } from './common/base-modify-marketplace-item-subscription.component';

@Component({
	selector: 'remove-subscription-item-dialog',
	templateUrl: './remove-subscription-item-dialog.component.html',
	styleUrls: ['./remove-subscription-item-dialog.component.scss']
})
export class RemoveSubscriptionItemDialogComponent extends BaseModifyMarketplaceItemSubscription implements OnInit {
	finishStageMessages: string[] = [];
	upcomingInvoice: UpcomingInvoiceDto;
	recurringInvoice: RecurringInvoiceDto;

	interval: string;

	get nextButtonText() {
		if (this.isLoading) {
			return 'Loading...';
		} else if (this.isFinishStage) {
			return 'Ok';
		} else if (this.isUninstallOnly) {
			return 'Unsubscribe';
		} else if (this.isReviewSubscriptionStage) {
			return 'Unsubscribe';
		}
		return 'Next';
	}

	get isUninstallOnly() {
		return this.isPackage && !this.isProductSubscribed;
	}

	private _loadUpcomingInvoice$ =
		// Fetch the upcoming invoice
		this._stripeService.previewUnsubscribeProduct(this.data.productId).pipe(
			tap(preview => {
				this.upcomingInvoice = preview?.upcomingCosts;
				this.recurringInvoice = preview?.recurringCosts;
			})
		);

	private _loadPackageData$ = forkJoin([
		this._practiceAreaService.getPracticeAreaList({
			sortBy: 'name',
			sortDirection: EnumSortDirection.Asc
		}),
		this._marketplaceService.getDetailsForUninstallPackage({
			productId: this.marketplaceListing.id
		})
	]).pipe(
		tap(([practiceAreas, packageUninstallDetails]) => {
			this.configurePackageData = {
				practiceAreas: practiceAreas?.records,
				uninstallPackageDetails: packageUninstallDetails
			};
		})
	);

	// Setup ConfigurePackageComponent
	@ViewChild('configurePackageRef') configRef: ConfigurePackageComponent;
	configurePackageOperationType = ConfigurePackageOperationType.Uninstall;
	configurePackageData: IPackageConfigurationData;
	configurePackageForm: FormGroupTyped<IPackageConfiguration> = null;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: IRemoveSubscriptionItemDialogData,
		private _stripeService: StripeService,
		private _notifService: NotificationService,
		private _dialogRef: MatDialogRef<RemoveSubscriptionItemDialogComponent, boolean>,
		private _marketplaceService: MarketplaceService,
		private _practiceAreaService: PracticeAreasService,
		private _settingsService: GeneralSettingsService,
		private _fb: FormBuilder,
		private _router: Router
	) {
		super(data);
	}

	ngOnInit(): void {
		if (this.isPackage) {
			if (this.isProductSubscribed && this.isSubscriptionCancelled) {
				this.showRenewSubscriptionMessageBox();
			} else {
				this.StartLoadingState('Loading Package Data...', true);

				this.configurePackageForm = this._fb.group({
					practiceAreaMappings: this._fb.array([]),
					practiceAreaIds: [null, CustomValidators.required()],
					termsAccepted: !this.hasProductTerms
						? false
						: [false, CustomValidators.isTrue('Terms and Conditions')],
					mapPracticeAreas: false
				}) as FormGroupTyped<IPackageConfiguration>;

				this.subscriptions.add(
					this._loadPackageData$.subscribe({
						next: () => {
							this.StopLoadingState();
							this.startPackageConfirmStage();
						},
						error: (errors: DomainError[]) => {
							this.handleDomainError(errors);
						}
					})
				);
			}
		} else if (this.isModule) {
			if (this.isSubscriptionCancelled) {
				this.showRenewSubscriptionMessageBox();
			} else {
				this.startReviewStage();
				this.StartLoadingState('Preparing Upcoming Invoice...', true);
				this.subscriptions.add(
					this._loadUpcomingInvoice$.subscribe({
						next: () => this.StopLoadingState(),
						error: (errors: DomainError[]) => {
							this.handleDomainError(errors);
						}
					})
				);
			}
		}

		this.subscriptions.add(
			this._settingsService.getLicencingInfoDetailed().subscribe(info => {
				if (!!info.stripeInfo?.recurringInterval) {
					this.interval = info.stripeInfo.recurringInterval;
				}
			})
		);
	}

	private showRenewSubscriptionMessageBox() {
		this._dialogRef.addPanelClass('mat-dialog-hide');
		this.subscriptions.add(
			this._notifService
				.showCallToAction(
					'Subscription Required',
					`<p>An active subscription to <b>${this.siteName}</b> is required to make any billing changes.</p>`,
					'Renew ' + this.siteName + ' subscription',
					'Cancel'
				)
				.subscribe((isConfirmed: boolean) => {
					if (isConfirmed) {
						this.goToCustomerPortal();
					} else {
						this._dialogRef.close(false);
					}
				})
		);
	}

	onProceedButtonClick(): void {
		if (this.isPackageConfirmStage) {
			if (this.isProductSubscribed) {
				this.startReviewStage(true);
			} else {
				this.uninstallAndUnsubscribe();
			}
		} else if (this.isReviewSubscriptionStage) {
			this.uninstallAndUnsubscribe();
		} else if (this.isFinishStage) {
			this._dialogRef.close(true);
		}
	}

	private goToCustomerPortal() {
		this.subscriptions.add(
			this._stripeService.customerPortal({ returnURL: '/system/subscription' }).subscribe(customerPortalURL => {
				window.location.href = customerPortalURL;
			})
		);
	}

	private startPackageConfirmStage(): void {
		this.currentDialogStage = RemoveSubscriptionDialogStage.UninstallPackageConfirmStage;
	}

	private startReviewStage(shouldLoadStripeData?: boolean) {
		this.currentDialogStage = RemoveSubscriptionDialogStage.ReviewSubscriptionStage;

		if (shouldLoadStripeData) {
			this.StartLoadingState();
			this.subscriptions.add(this._loadUpcomingInvoice$.subscribe(() => this.StopLoadingState()));
		}
	}

	uninstallAndUnsubscribe(): void {
		let serverCall$: Observable<any>;

		const removeSubscription$ = this._stripeService.unsubscribeProduct(this.data.productId).pipe(
			tap(() => {
				this.setFinishStage('Subscription has been updated.');
			})
		);

		if (this.isPackage) {
			const uninstallPackage$ = this._marketplaceService
				.uninstallPackage({
					productId: this.marketplaceListingId
				})
				.pipe(
					tap(() => {
						this.setFinishStage('<b>' + this.productName + '</b> is unsubscribed.');
					})
				);

			serverCall$ = uninstallPackage$;
			this.StartLoadingState('Uninstalling package...');

			if (this.isProductSubscribed) {
				serverCall$ = uninstallPackage$.pipe(
					switchMap(() => {
						this.StartLoadingState('Unsubscribing Product...');
						return removeSubscription$;
					})
				);
			}
		} else {
			this.StartLoadingState('Unsubscribing Product...');
			serverCall$ = removeSubscription$;
		}

		this.subscriptions.add(
			serverCall$.subscribe({
				error: (errors: DomainError[]) => {
					this.handleDomainError(errors);
				}
			})
		);
	}

	setFinishStage(message: string) {
		this.StopLoadingState();
		this.finishStageMessages.push(message);
		this.currentDialogStage = RemoveSubscriptionDialogStage.FinishStage;
	}

	handleDomainError(errors: DomainError[]) {
		this.StopLoadingState();
		this.subscriptions.add(this._notifService.showCriticalErrors(errors).subscribe(() => this._dialogRef.close()));
	}

	currentDialogStage = RemoveSubscriptionDialogStage.None;
	get isPackageConfirmStage() {
		return this.currentDialogStage == RemoveSubscriptionDialogStage.UninstallPackageConfirmStage;
	}
	get isReviewSubscriptionStage() {
		return this.currentDialogStage == RemoveSubscriptionDialogStage.ReviewSubscriptionStage;
	}
	get isFinishStage() {
		return this.currentDialogStage == RemoveSubscriptionDialogStage.FinishStage;
	}
}

enum RemoveSubscriptionDialogStage {
	None,
	UninstallPackageConfirmStage,
	ReviewSubscriptionStage,
	FinishStage
}

export interface IRemoveSubscriptionItemDialogData extends IBaseModifySubscriptionItemComponent {
	productId: string;
}
