import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { INotificationMessage, NotificationService } from '@common/notification';

import { ApplicationInsightsService } from '../application-insights/application-insights.service';

@Injectable()
export class AppErrorHandler implements ErrorHandler {
	constructor(
		private injector: Injector,
		private notifService: NotificationService,
		private activatedRoute: ActivatedRoute
	) {}

	handleError(error: any): void {
		const applicationInsights = this.injector.get(ApplicationInsightsService);
		if (applicationInsights) {
			applicationInsights.trackException(error);
		}

		console.error(error);

		const stackTrace = (error as TypeError)?.stack;

		if (
			stackTrace &&
			stackTrace.toLowerCase().indexOf('htmliframeelement') > -1 &&
			this.activatedRoute?.snapshot?.children?.find(child => child.outlet === 'preview')
		) {
			const errorMessage: INotificationMessage = {
				text: 'Trouble loading document? ',
				isExternalLink: true,
				linkText: 'Click to learn more',
				linkRoute: ['https://support.mattero.com.au/hc/en-us/articles/4403392702617']
			};

			this.notifService.showNotificationLink(errorMessage, 6000);
		}
	}
}
