// This file has been generated from ActivityLogListRequest.cs
// Do not modify this file directly!!
// tslint:disable

import { Moment } from 'moment-timezone';
import { DateFormat, SetDateFormat } from '@common/utils/date-format';

import { EnumSortDirection } from '../../Common/EnumSortDirection';

// LawMaster.Amalthea.Domain.ActivityLogs.List.ActivityLogListRequest
export class ActivityLogListRequest {

	// Search
	search: string;
	// SortBy
	sortBy: string;
	// SortDirection
	sortDirection: keyof typeof EnumSortDirection;
	// PageIndex
	pageIndex: number;
	// PageSize
	pageSize: number;
	// PageIndexForId
	pageIndexForId: string;
	// RelatedToType
	relatedToType: string;
	// RelatedToId
	relatedToId: string;
	// UserId
	userId: string;
	// TimestampStart
	@SetDateFormat(DateFormat.DateTime)
	timestampStart: Moment;
	// TimestampEnd
	@SetDateFormat(DateFormat.DateTime)
	timestampEnd: Moment;
	// ChangeField
	changeField: string;
	// Operation
	operation: string;

	constructor() {

	}
}