import { Pipe, PipeTransform } from '@angular/core';

import { CurrencyPipe } from '@angular/common';
import { CostingMethod } from '@common/models/Matters/Common/CostingMethod';

/*
 * Resolve costing method display string by the enum key
 */
@Pipe({ name: 'costingMethod' })
export class CostingMethodPipe implements PipeTransform {
	transform(key: keyof typeof CostingMethod): string {
		if (key) {
			if (!CostingMethod[key]) {
				throw Error(`Dev error: The costing method enum does not contain the key ${key}`);
			}
			return CostingMethod[key];
		} else return null;
	}
}
