// This file has been generated from UnitsPerHourCreateUpdateDto.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.Domain.Settings.Setting.Item.UnitsPerHourCreateUpdateDto
export class UnitsPerHourCreateUpdateDto {

	// UnitsPerHour
	unitsPerHour: number;

	constructor() {

	}
}