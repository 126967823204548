// This file has been generated from DocumentTagController.cs
// Do not modify this file directly!!
// tslint:disable

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { TrimStringsOnObject } from '@common/utils/string-format';

import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';
import { ListResponse } from '../../models/Generic/ListResponse';
import { DocumentTagDto } from '../../models/Settings/DocumentTags/Item/DocumentTagDto';
import { DocumentTagListItemDto } from '../../models/Settings/DocumentTags/List/DocumentTagListItemDto';
import { DocumentTagListRequest } from '../../models/Settings/DocumentTags/List/DocumentTagListRequest';

@Injectable({
	providedIn: 'root'
})
export class DocumentTagService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'api/v1/settings/DocumentTag', 'Document Tag');
	}

	getDocumentTag(id: string): Observable<DocumentTagDto> {
		return this.getItem<void, DocumentTagDto>(`${id}`);
	}

	getDocumentTagList(request?: Partial<DocumentTagListRequest>): Observable<ListResponse<DocumentTagListItemDto>> {
		return this.getList<DocumentTagListRequest, DocumentTagListItemDto>('', request);
	}

	createDocumentTag(newDocumentTagDto: DocumentTagDto): Observable<MutationResponseDto> {
		return this.post<DocumentTagDto, MutationResponseDto>(
			'',
			TransformDatesOnObject(DocumentTagDto, TrimStringsOnObject(newDocumentTagDto))
		);
	}

	updateDocumentTag(id: string, documentTagDto: DocumentTagDto): Observable<MutationResponseDto> {
		return this.put(`${id}`, TransformDatesOnObject(DocumentTagDto, TrimStringsOnObject(documentTagDto)));
	}

	deleteDocumentTag(id: string): Observable<MutationResponseDto> {
		return this.delete(`${id}`);
	}
}
