<mat-form-field [style.font-size]="FontSize">
	<mat-placeholder
		>{{ Placeholder }}
		<sup class="color-warn" *ngIf="Required">*</sup>
	</mat-placeholder>
	<mat-hint>
		<div *ngIf="!!selectedValue">{{ getDocumentVersionDisplayNumber(selectedValue) }}</div>
		<div *ngif="!!Hint">{{ Hint }}</div>
	</mat-hint>

	<div class="input-row">
		<mat-icon class="input-icon" *ngIf="!!documentIcon" [svgIcon]="documentIcon" [ngClass]="documentIcon"></mat-icon>
		<input
			[class.input-icon-field]="!!documentIcon"
			matInput
			#autoCompleteInput
			[matAutocomplete]="auto"
			[formControl]="inputDisplayCtrl"
			[value]="inputDisplayCtrl.value"
			[autofocus]="HasAutofocus"
			(keyup.esc)="escPressed()"
			(keydown.tab)="tabPressed()"
			(focus)="inputFocused($event)"
			(input)="autoCompleteChanged()"
		/>
	</div>
	<mat-error>
		<error-messages [for]="FormControl"></error-messages>
	</mat-error>

	<mat-autocomplete #auto="matAutocomplete" (optionSelected)="autocompleteSelected($event)">
		<mat-option *ngFor="let option of options" [value]="option.id">
			<div class="selected-document">
				<mat-icon [svgIcon]="getFileIcon(option.fileExtension)" [ngClass]="getFileIcon(option.fileExtension)"></mat-icon>

				<div class="selected-document-content">
					<div class="document-title-version-preview">
						<a>
							{{ option.title }}
						</a>
						<div class="version-number">{{ getDocumentVersionDisplayNumber(option) }}</div>
					</div>
					<div *ngIf="!!option.associatedContact" class="extra-details">
						<mat-icon class="icon">person</mat-icon>
						<span class="text">{{ option.associatedContact.name }}</span>
					</div>

					<div *ngIf="!!option.associatedMatter" class="extra-details">
						<mat-icon class="icon">gavel</mat-icon>
						<span class="text">{{ option.associatedMatter.name }}</span>
					</div>
				</div>
			</div>
		</mat-option>
		<mat-option [disabled]="true" *ngIf="inputDisplayCtrl.value && options?.length == 0">
			<div *ngIf="isSearching" class="searching">Searching...</div>
			<div *ngIf="noLookupResults && inputDisplayCtrl.value && options?.length == 0" class="no-matches">No matches found</div>
		</mat-option>
	</mat-autocomplete>
</mat-form-field>
