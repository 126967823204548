import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { BulkTagDocumentsDto } from '@common/models/Documents/Item/BulkTagDocumentsDto';
import { MatterLookupDto } from '@common/models/Matters/Lookup/MatterLookupDto';
import { NotificationService } from '@common/notification';
import { CustomValidators } from '@common/validation/custom.validators';
import { Store } from '@ngrx/store';

import { processRecords } from 'app/core/state/lists/document-list/document-list.actions';
import { getCurrentPageLookup } from 'app/core/state/misc/current-page/current-page.reducer';
import { ICurrentPageState } from 'app/core/state/misc/current-page/current-page.state';
import { DocumentsService } from 'app/services/documents.service';

@Component({
	selector: 'bulk-add-tags-dialog',
	templateUrl: './bulk-add-tags-dialog.component.html'
})
export class BulkAddTagsDialogComponent implements OnInit, OnDestroy {
	form: FormGroupTyped<BulkTagDocumentsDto>;

	private _matterId$: Observable<string>;
	private _practiceAreaIds$: Observable<string[]>;

	get matterId$() {
		return this._matterId$;
	}

	get practiceAreaIds$() {
		return this._practiceAreaIds$;
	}

	private _subscriptions = new Subscription();

	constructor(
		@Optional() @Inject(MAT_DIALOG_DATA) public data: IBulkAddTagsDialogData,
		private documentsService: DocumentsService,
		private notificationService: NotificationService,
		private store: Store<ICurrentPageState>,
		private fb: FormBuilder,
		private dialogRef: MatDialogRef<BulkAddTagsDialogComponent>
	) {}

	ngOnInit(): void {
		const nonEmptyValidator = CustomValidators.nonEmptyArray();

		this.form = this.fb.group({
			documentIds: [this.data.documentIds, nonEmptyValidator],
			tags: [[], nonEmptyValidator]
		}) as FormGroupTyped<BulkTagDocumentsDto>;

		const lookup$ = this.store
			.select(getCurrentPageLookup)
			.pipe(map(lookup => (!(lookup as MatterLookupDto)?.number ? null : (lookup as MatterLookupDto))));

		this._matterId$ = lookup$.pipe(map(lookup => lookup?.id));

		this._practiceAreaIds$ = lookup$.pipe(
			map(lookup => (!lookup?.practiceArea?.id ? null : [lookup.practiceArea.id]))
		);
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}

	saveChanges() {
		if (!!this.form.valid) {
			this._subscriptions.add(
				this.documentsService.bulkTagDocuments(this.form.value).subscribe({
					next: response => {
						this.store.dispatch(processRecords({ response: response }));
						this.dialogRef.close(true);
					},
					error: errors => this.notificationService.showErrors('Error tagging document(s)', errors)
				})
			);
		}
	}
}

export interface IBulkAddTagsDialogData {
	documentIds: string[];
}
