import { BasicWorkflowListItemDto } from '@common/models/Settings/BasicWorkflows/List/BasicWorkflowListItemDto';
import { createSelector, MemoizedSelector } from '@ngrx/store';

import { IAppState } from '../../../app.state';

export const selectBasicWorkflowList = (state: IAppState) => state.basicWorkflowList;

export const selectBasicWorkflowListRecords: MemoizedSelector<IAppState, BasicWorkflowListItemDto[]> = createSelector(
	selectBasicWorkflowList,
	state => state?.list?.records || []
);
export const selectBasicWorkflowListRequest: MemoizedSelector<IAppState, Partial<any>> = createSelector(
	selectBasicWorkflowList,
	state => state?.request
);
export const selectBasicWorkflowListPageIndex = createSelector(
	selectBasicWorkflowList,
	state => state?.list?.pageIndex || 0
);
export const selectBasicWorkflowListTotalRecords = createSelector(
	selectBasicWorkflowList,
	state => state?.list?.totalRecords || 0
);
export const selectBasicWorkflowIsFetching = createSelector(selectBasicWorkflowList, state => !!state?.isFetching);
