import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ListRequest } from '@common/models/Generic/ListRequest';
import { UserViewDto } from '@common/models/Users/Item/UserViewDto';
import { NotificationService } from '@common/notification';
import { ContactsService } from '@common/services/contacts.service';
import { GeneralSettingsService } from '@common/services/settings/generalsettings.service';
import { UsersService } from '@common/services/settings/users.service';
import { LocalStorageService } from '@common/services/storage/local-storage.service';
import { StripeService } from '@common/services/stripe.service';
import { ICurrentUserData } from '@common/state/models/current-user-data';
import { ITenantData } from '@common/state/models/tenant-data';
import { Store } from '@ngrx/store';
import { get } from 'lodash-es';

import { AppConfig } from 'app/app.config';
import { AuthService } from 'app/core/auth.service';
import { CustomValidators } from '@common/validation/custom.validators';
import { AppBrandingService } from 'app/services/app-branding.service';

@Component({
	selector: 'subscription-dialog',
	styleUrls: ['./subscription-dialog.component.scss'],
	templateUrl: 'subscription-dialog.component.html'
})
export class SubscriptionDialogComponent implements OnInit, OnDestroy {
	private subscriptions = new Subscription();
	numberArray: number[] = Array.from({ length: 20 }, (_, i) => i + 1); // Get an array from 1 to 20
	form: FormGroup;
	user: UserViewDto;
	isABNMandatory: boolean = true;

	get siteName() {
		return AppBrandingService.getSiteName();
	}

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: ISubscriptionDialogData,
		private store: Store<{ currentUserData: ICurrentUserData; tenantData: ITenantData }>,
		private stripeService: StripeService,
		private notifService: NotificationService,
		private settingsService: GeneralSettingsService,
		private usersService: UsersService,
		private fb: FormBuilder,
		private contactService: ContactsService,
		private localStorageService: LocalStorageService,
		private authService: AuthService
	) {}

	ngOnInit(): void {
		this.form = this.fb.group({
			numberOfUsers: 1,
			customerName: [null, CustomValidators.required('Name')],
			abn: [
				null,
				CustomValidators.requiredWhen(() => !!this.isABNMandatory, 'ABN'),
				CustomValidators.validateABN(this.contactService)
			]
		});

		this.subscriptions.add(
			this.settingsService.getTenantContact().subscribe(contact => {
				this.form.get('customerName').setValue(contact?.fullName);
				this.form.get('abn').setValue(contact?.abn);
				// Don't make ABN Mandatory if the company has a primary address that is not Australia.
				this.isABNMandatory = !contact?.address || contact?.regionCode === 'AU';
				this.form.get('abn').updateValueAndValidity();
			})
		);

		this.subscriptions.add(
			this.usersService.getUserList(new ListRequest()).subscribe(userList => {
				const activeUsers = userList.records.filter(x => !x.disabled && x.licenseType != 'Free')?.length;
				this.form.get('numberOfUsers').setValue(activeUsers);
			})
		);

		this.subscriptions.add(
			this.store
				.select(state => state?.currentUserData?.currentUser)
				.pipe(filter(Boolean))
				.subscribe(
					(user: UserViewDto) => {
						this.user = user;
					},
					e => this.notifService.showErrors('Error getting user info', e)
				)
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	get userId(): string {
		return get(this.user, ['id']);
	}

	continueToPayment() {
		this.subscriptions.add(
			this.stripeService
				.createCheckoutSession({
					numberOfUsers: this.form.get('numberOfUsers').value,
					promocode: this.localStorageService.getItem(AppConfig.promoCodeLocalStorageKey),
					priceId: this.data.priceId,
					name: this.form.get('customerName').value,
					email: this.user.primaryEmail,
					abn: this.form.get('abn').value,
					productId: this.data.productId,
					successURL: '/system/subscription?success=true&session_id={{CHECKOUT_SESSION_ID}}',
					cancelURL: '/system/subscription?cancelled=true'
				})
				.subscribe(
					checkoutUrl => {
						window.location.href = checkoutUrl;
					},
					err => {
						this.notifService.showErrors('Payment Service Error', err);
					}
				)
		);
	}

	decrementQuantity() {
		let quantity = this.form.get('numberOfUsers').value;
		if (!quantity || quantity === 1) this.form.get('numberOfUsers').setValue(1);
		else this.form.get('numberOfUsers').setValue(--quantity);
	}
	incrementQuantity() {
		let quantity = this.form.get('numberOfUsers').value;
		if (!quantity) this.form.get('numberOfUsers').setValue(1);
		else this.form.get('numberOfUsers').setValue(++quantity);
	}
}

export interface ISubscriptionDialogData {
	priceId: string;
	productId: string;
	planName: string;
}
