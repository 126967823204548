<mat-card>
	<section class="page-path"><a [routerLink]="['..']">System</a> / Matter Custom Fields</section>
	<h1>Matter Custom Fields</h1>

	<div class="flex-row">
		<div class="flex-filter-row">
			<button mat-button color="primary" title="Create Group" throttleButton (throttledClick)="createCustomFieldGroup()">
				<mat-icon>add_circle</mat-icon>
				<span> <span class="hiddenXSmall">Create</span> New Group</span>
			</button>
			<button mat-button color="primary" title="Create Custom Field" throttleButton (throttledClick)="createCustomField()">
				<mat-icon>add_circle</mat-icon>
				<span> <span class="hiddenXSmall">Create</span> New Custom Field</span>
			</button>
			<filter-mobile-span [filterRoot]="filter.root" [filterParams]="request" [filterComponent]="filterDialog"></filter-mobile-span>
		</div>

		<button class="action-button" mat-raised-button [matMenuTriggerFor]="menu">
			Bulk Actions
			<mat-icon>keyboard_arrow_down</mat-icon>
		</button>
		<mat-menu #menu="matMenu" [overlapTrigger]="false">
			<button mat-menu-item throttleButton (throttledClick)="patchSelectedFields('enabled', true)">
				<mat-icon>visibility_on</mat-icon>
				<span>Enable Fields</span>
			</button>
			<button mat-menu-item throttleButton (throttledClick)="patchSelectedFields('enabled', false)">
				<mat-icon>visibility_off</mat-icon>
				<span>Disable Fields</span>
			</button>
			<button mat-menu-item throttleButton (throttledClick)="patchSelectedFields('mandatory', true)">
				<mat-icon>priority_high</mat-icon>
				<span>Mark as Mandatory</span>
			</button>
			<button mat-menu-item throttleButton (throttledClick)="patchSelectedFields('mandatory', false)">
				<mat-icon>info</mat-icon>
				<span>Mark as Not Mandatory</span>
			</button>

			<button mat-menu-item [matMenuTriggerFor]="addGroupsMenu">
				<mat-icon>drive_file_move</mat-icon>
				<span>Add Group</span>
			</button>
			<mat-menu #addGroupsMenu="matMenu">
				<div *ngFor="let group of customFieldGroups">
					<ng-container>
						<button mat-menu-item throttleButton (throttledClick)="addGroupToFields(group)">
							<mat-icon>drive_file_move</mat-icon> <span>{{ group.name }}</span>
						</button>
					</ng-container>
				</div>
			</mat-menu>
			<button mat-menu-item [disabled]="(selected$ | async)?.length == 0" [matMenuTriggerFor]="removeGroupsMenu">
				<mat-icon>drive_file_move</mat-icon>
				<span>Remove Group</span>
			</button>
			<mat-menu #removeGroupsMenu="matMenu">
				<div *ngFor="let group of getGroupsToRemove() | async">
					<ng-container>
						<button mat-menu-item throttleButton (throttledClick)="removeGroupFromFields(group)">
							<mat-icon>drive_file_move</mat-icon> <span>{{ group.name }}</span>
						</button>
					</ng-container>
				</div>
			</mat-menu>
			<button mat-menu-item [matMenuTriggerFor]="moveGroupsMove">
				<mat-icon>drive_file_move</mat-icon>
				<span>Move to Group</span>
			</button>
			<mat-menu #moveGroupsMove="matMenu">
				<div *ngFor="let group of customFieldGroups">
					<ng-container>
						<button mat-menu-item throttleButton (throttledClick)="moveFieldsToGroup(group)">
							<mat-icon>drive_file_move</mat-icon> <span>{{ group.name }}</span>
						</button>
					</ng-container>
				</div>
			</mat-menu>

			<button mat-menu-item [matMenuTriggerFor]="addPracticeAreaMenu">
				<mat-icon matListIcon>view_list</mat-icon>
				<span>Add Practice Area</span>
			</button>
			<mat-menu #addPracticeAreaMenu="matMenu">
				<ng-container>
					<button mat-menu-item throttleButton (throttledClick)="addPracticeAreaToFields(null)">
						<mat-icon matListIcon>view_list</mat-icon> <span>All</span>
					</button>
				</ng-container>
				<div *ngFor="let practiceArea of practiceAreas">
					<ng-container>
						<button mat-menu-item throttleButton (throttledClick)="addPracticeAreaToFields(practiceArea)">
							<mat-icon matListIcon>view_list</mat-icon> <span>{{ practiceArea.name }}</span>
						</button>
					</ng-container>
				</div>
			</mat-menu>
			<button mat-menu-item [disabled]="(selected$ | async)?.length == 0" [matMenuTriggerFor]="removePracticeAreasMenu">
				<mat-icon matListIcon>view_list</mat-icon>
				<span>Remove Practice Area</span>
			</button>
			<mat-menu #removePracticeAreasMenu="matMenu">
				<div *ngFor="let practiceArea of getPracticeAreasToRemove() | async">
					<ng-container>
						<button mat-menu-item throttleButton (throttledClick)="removePracticeAreaFromFields(practiceArea)">
							<mat-icon matListIcon>view_list</mat-icon> <span>{{ practiceArea.name }}</span>
						</button>
					</ng-container>
				</div>
			</mat-menu>
			<button *ngIf="!!canShowReportField" mat-menu-item [disabled]="selectedListItems?.length == 0" [matMenuTriggerFor]="reportsMenu">
				<mat-icon matListIcon>bar_chart</mat-icon>
				<span>Reports</span>
			</button>
			<mat-menu #reportsMenu="matMenu">
				<button mat-menu-item throttleButton (throttledClick)="enableShowInReportsForSelected()" [disabled]="!canEnableShowInReportsForSelected() | async">
					<mat-icon>visibility_on</mat-icon>
					<span>Show in Reports</span>
				</button>
				<button mat-menu-item throttleButton (throttledClick)="disableShowInReportsForSelected()" [disabled]="!canDisableShowInReportsForSelected() | async">
					<mat-icon>visibility_off</mat-icon>
					<span>Hide from Reports</span>
				</button>
			</mat-menu>
			<mat-divider></mat-divider>
			<button mat-menu-item [disabled]="true">
				<span class="info-text"> {{ rowSelectionLabel }}</span>
			</button>
		</mat-menu>
	</div>
	<matter-custom-field-list-filter [request]="request"></matter-custom-field-list-filter>
	<br />
	<div class="container">
		<div class="group-container">
			<div class="header">
				<h3>Custom Field Groups</h3>
				<span *ngIf="selectedGroupId" class="clear-all" throttleButton (throttledClick)="selectGroup(null)"
					><mat-icon>refresh</mat-icon><a>clear selection</a></span
				>
			</div>

			<div cdkDropList class="custom-field-list" (cdkDropListDropped)="dropGroup($event)">
				<ng-container *ngFor="let customFieldGroup of customFieldGroups">
					<div
						*ngIf="customFieldGroup.id !== 'Uncategorised'"
						class="custom-field-box"
						cdkDrag
						throttleButton
						(throttledClick)="selectGroup(customFieldGroup?.id)"
						[ngClass]="customFieldGroup?.id === selectedGroupId ? 'selected-group' : ''"
					>
						<div class="group-info">
							<mat-icon>drag_indicator</mat-icon>
							<span>{{ customFieldGroup?.name }}</span>
							<mat-icon
								*ngIf="!!hasPackages(customFieldGroup)"
								color="accent"
								class="packages-icon"
								[matTooltip]="getPackageNames(customFieldGroup)"
								>storefront</mat-icon
							>
						</div>

						<mat-icon class="edit-group" throttleButton (throttledClick)="selectGroup(customFieldGroup?.id)" [matMenuTriggerFor]="menu"
							>more_vert</mat-icon
						>

						<mat-menu #menu="matMenu">
							<button mat-menu-item throttleButton (throttledClick)="editCustomFieldGroup(customFieldGroup)">
								<mat-icon>edit</mat-icon>
								<span>Edit Details</span>
							</button>
							<button mat-menu-item throttleButton (throttledClick)="deleteCustomFieldGroup(customFieldGroup)">
								<mat-icon>delete</mat-icon>
								<span>Remove</span>
							</button>
						</mat-menu>
					</div>
				</ng-container>
				<ng-container *ngFor="let customFieldGroup of customFieldGroups">
					<div
						class="custom-field-box"
						*ngIf="customFieldGroup.id === 'Uncategorised'"
						throttleButton
						(throttledClick)="selectGroup(customFieldGroup?.id)"
						[ngClass]="customFieldGroup?.id === selectedGroupId ? 'selected-group' : ''"
					>
						<div class="group-info">
							<mat-icon>drag_indicator</mat-icon>
							<span>{{ customFieldGroup?.name }}</span>
							<mat-icon
								*ngIf="!!hasPackages(customFieldGroup)"
								color="accent"
								class="packages-icon"
								[matTooltip]="getPackageNames(customFieldGroup)"
								>storefront</mat-icon
							>
						</div>
					</div>
				</ng-container>
			</div>
		</div>

		<mat-table
			[dataSource]="(isFetching$ | async) ? [] : (data$ | async)"
			cdkDropList
			#customFieldsList="cdkDropList"
			[cdkDropListData]="(isFetching$ | async) ? [] : (data$ | async)"
			(cdkDropListDropped)="dropFieldRow($event)"
		>
			<ng-container matColumnDef="description">
				<mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
				<mat-cell *matCellDef="let row">
					<div class="name-container">
						<mat-icon>drag_indicator</mat-icon>

						<div class="name-content">
							<div *ngIf="!!getInstalledPackages(row.installationRecords)?.length">
								<div class="embedded-package" *ngFor="let installationRecord of getInstalledPackages(row.installationRecords)">
									<mat-icon color="accent">storefront</mat-icon>
									<span>{{ installationRecord.name }}</span>
								</div>
							</div>
							<div class="embedded-type-enabled">
								<div class="embedded-field-type">{{ row.fieldType }}</div>
								<div class="embedded-enabled">{{ row.enabled ? 'Enabled' : 'Disabled' }}</div>
							</div>
							<div class="field-name">
								{{ row.description }}
								<sup *ngIf="row.mandatory" class="asterisk color-warn" title="Mandatory">*</sup>
							</div>
							<div class="flexrow">
								<div class="embedded-practice-areas">
									<span *ngIf="!row.practiceAreas?.length">All Practice Areas</span>
									<ul>
										<li *ngFor="let pa of row.practiceAreas">{{ pa.name }}</li>
									</ul>
								</div>
								<div class="embedded-groups">
									<ul>
										<li *ngFor="let group of row.customFieldGroups">{{ group.name }}</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef="installationRecords">
				<mat-header-cell *matHeaderCellDef>Package</mat-header-cell>
				<mat-cell *matCellDef="let row">
					<ul *ngIf="!!getInstalledPackages(row.installationRecords)?.length">
						<li *ngFor="let installationRecord of getInstalledPackages(row.installationRecords)">
							<mat-icon color="accent">storefront</mat-icon><span>{{ installationRecord.name }}</span>
						</li>
					</ul>
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef="customFieldGroups">
				<mat-header-cell *matHeaderCellDef>Group</mat-header-cell>
				<mat-cell *matCellDef="let row">
					<ul>
						<li *ngFor="let group of row.customFieldGroups">{{ group.name }}</li>
					</ul>
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef="fieldType">
				<mat-header-cell *matHeaderCellDef>Field Type</mat-header-cell>
				<mat-cell *matCellDef="let row">
					{{ row.fieldType }}
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef="enabled">
				<mat-header-cell *matHeaderCellDef>Enabled</mat-header-cell>
				<mat-cell *matCellDef="let row">
					{{ row.enabled ? 'Yes' : 'No' }}
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef="practiceAreas">
				<mat-header-cell *matHeaderCellDef>Practice Areas</mat-header-cell>
				<mat-cell *matCellDef="let row">
					<span *ngIf="!row.practiceAreas?.length">All</span>
					<ul>
						<li *ngFor="let pa of row.practiceAreas">{{ pa.name }}</li>
					</ul>
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef="showInReports">
				<mat-header-cell *matHeaderCellDef>Show in Reports</mat-header-cell>
				<mat-cell *matCellDef="let row">
					{{ row.showInReports ? 'Yes' : 'No' }}
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef="actions">
				<mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
				<mat-cell *matCellDef="let row">
					<button mat-icon-button [matMenuTriggerFor]="menu" title="Actions">
						<mat-icon>more_vert</mat-icon>
					</button>
					<mat-menu #menu="matMenu">
						<button mat-menu-item throttleButton (throttledClick)="editCustomField(row.id)">
							<mat-icon>edit</mat-icon>
							<span>Edit Details</span>
						</button>
						<button mat-menu-item throttleButton (throttledClick)="patchCustomField([row.id], 'enabled', !row.enabled)">
							<mat-icon> {{ row.enabled ? 'visibility_off' : 'visibility_on' }}</mat-icon>
							<span>{{ row.enabled ? 'Mark as Disabled' : 'Mark as Enabled' }}</span>
						</button>
						<button mat-menu-item throttleButton (throttledClick)="patchCustomField([row.id], 'mandatory', !row.mandatory)">
							<mat-icon> {{ row.mandatory ? 'info' : 'priority_high' }}</mat-icon>
							<span>{{ row.mandatory ? 'Mark as Not Mandatory' : 'Mark as Mandatory' }}</span>
						</button>
						<button
							*ngIf="!!canShowReportField"
							mat-menu-item
							throttleButton
							(throttledClick)="showInReports(row.id, !row.showInReports)"
							[disabled]="!row.showInReports && (row.fieldType === 'Paragraph' || row.fieldType === 'Calculated')"
						>
							<mat-icon> {{ !row.showInReports ? 'visibility_on' : 'visibility_off' }}</mat-icon>
							<span>{{ !row.showInReports ? 'Show in Reports' : 'Hide from Reports' }}</span>
						</button>
						<button mat-menu-item throttleButton (throttledClick)="deleteCustomField(row.id)">
							<mat-icon>delete</mat-icon>
							<span>Delete</span>
						</button>
					</mat-menu>
				</mat-cell>
			</ng-container>

			<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
			<mat-row
				*matRowDef="let row; columns: displayedColumns"
				cdkDrag
				throttleButton
				(throttledClick)="selectRow(row, $event)"
				[ngClass]="{ highlight: row.isHighlighted }"
			></mat-row>
		</mat-table>
	</div>
</mat-card>
