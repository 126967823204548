import { DomainError } from '@common/models/Validation/DomainError';
import { Moment } from 'moment';

export interface IJobStatusFilterState {
	isFetching: boolean;
	list: string[];

	cacheExpiry: Moment;
	// errrors
	error: DomainError;
}

export const initialState: IJobStatusFilterState = {
	isFetching: false,
	list: null,
	error: null,
	cacheExpiry: null
};
