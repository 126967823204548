import { Component, OnDestroy } from '@angular/core';

@Component({
	selector: 'zendesk-replacement-dialog',
	styleUrls: ['./zendesk-replacement.dialog.component.scss'],
	templateUrl: 'zendesk-replacement.dialog.component.html'
})
export class ZendeskReplacementDialogComponent implements OnDestroy {
	constructor(
	) {}

	ngOnDestroy(): void {
	}
}
