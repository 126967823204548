import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { BriefDetails } from '@common/models/DocumentBriefs/List/BriefDetails';
import { DocumentCreateNewVersionDto } from '@common/models/Documents/Item/DocumentCreateNewVersionDto';
import { DocumentViewDto } from '@common/models/Documents/Item/DocumentViewDto';
import { DocumentListRequest } from '@common/models/Documents/List/DocumentListRequest';
import { ListResponse } from '@common/models/Generic/ListResponse';
import { NotificationService } from '@common/notification';
import { CustomValidators } from '@common/validation/custom.validators';
import { Store } from '@ngrx/store';
import * as moment from 'moment-timezone';

import { IAppState } from 'app/core/state/app.state';
import { processRecords } from 'app/core/state/lists/document-list/document-list.actions';
import { DocumentsService } from 'app/services/documents.service';
import { DocumentTagComponent } from 'app/shared/components/document-tag.component';

import { DocumentListItemExtDto } from '../list/DocumentListItemExtDto';

@Component({
	selector: 'version-document',
	styleUrls: ['./version-document.component.scss'],
	templateUrl: './version-document.component.html'
})
export class VersionDocumentComponent implements OnInit, AfterViewInit, OnDestroy {
	form: FormGroupTyped<DocumentCreateNewVersionDto>;
	warnings: string[] = [];
	@ViewChild(DocumentTagComponent)
	tagComponent: DocumentTagComponent;

	briefs: BriefDetails[] = [];

	isFormSubmissionActive: boolean = false;

	private subscriptions: Subscription = new Subscription();

	get selectedVersion(): number {
		if (this.data?.versions?.length && this.data.versions.indexOf(this.data.versionFromDocumentId) > -1) {
			return this.data.versions.indexOf(this.data.versionFromDocumentId) + 1;
		}

		return 1;
	}

	get latestVersionNumber(): number {
		return this.data?.versions?.length ?? 1;
	}

	get latestVersionId(): string {
		if (!this?.data?.versions) return this.data.versionFromDocumentId;
		return this?.data?.versions[this.data?.versions?.length - 1 ?? 0];
	}

	get nextVersion(): number {
		return this.latestVersionNumber + 1;
	}

	get isCreatedFromLatestVersion(): boolean {
		return this.selectedVersion === this.latestVersionNumber;
	}

	constructor(
		private dialogRef: MatDialogRef<VersionDocumentComponent>,
		@Inject(MAT_DIALOG_DATA) private data: IVersionDocumentData,
		private fb: FormBuilder,
		private docService: DocumentsService,
		private notifService: NotificationService,
		private store: Store<IAppState>
	) {}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	get briefControls() {
		if (this.form && this.form.get('checkboxes')) {
			const controls = this.form.get('checkboxes') as unknown as FormArray;
			return controls;
		}

		return null;
	}

	ngOnInit(): void {
		this.form = this.fb.group({
			id: [null, CustomValidators.required('Id')],
			title: [null, CustomValidators.required('Title')],
			createdDate: [null, CustomValidators.required('Date')],
			documentTags: [],
			checkboxes: new FormArray([])
		}) as FormGroupTyped<DocumentCreateNewVersionDto>;

		const request: Partial<DocumentListRequest> = {
			documentIds: [this.data.versionFromDocumentId],
			showDisabled: true,
			includeBriefData: true
		};

		this.subscriptions.add(
			this.docService
				.getDocumentList(request)
				.pipe(
					switchMap((list: ListResponse<DocumentListItemExtDto>) => {
						if (list.totalRecords > 0) {
							return of(list.records[0]);
						} else {
							throw new Error('Unable to load selected document');
						}
					})
				)
				.subscribe({
					next: document => {
						this.briefs = document.associatedBriefs;

						this.briefs.forEach(() => {
							this.briefControls.push(new FormControl(true));
						});

						this.form.patchValue({
							title: document.title,
							id: document.id,
							createdDate: moment()
						});

						this.tagComponent.ActiveTags = document.documentTags ?? [];
						this.tagComponent.matterId = document.associatedMatter.id;
					},
					error: errs => {
						this.notifService.showErrors(`Error`, errs);
					}
				})
		);
	}

	ngAfterViewInit(): void {
		if (!this.isCreatedFromLatestVersion) {
			this.subscriptions.add(
				this.docService
					.getDocument(this.latestVersionId)
					.pipe(
						switchMap((x: DocumentViewDto) => {
							return of(x);
						})
					)
					.subscribe((doc: DocumentViewDto) => {
						this.warnings.push(
							`A later version (v${this.latestVersionNumber}) of this document exists. Modified by ${
								doc.lastModifiedBy
							} on ${moment(doc.lastModifiedDate).format('DD/MM/YYYY')}`
						);
					})
			);
		}
	}

	save(): void {
		this.isFormSubmissionActive = true;
		// The Timeout here is use to wait document-tag processing ActiveTags
		// The issue is when type tags without separator and click save, the tags is not saved
		setTimeout(() => {
			const request: DocumentCreateNewVersionDto = this.form.value;

			request.attachedBriefs = this.briefControls.getRawValue().map((val, i) => {
				return {
					briefId: this.briefs[i].briefId,
					isAttached: val ?? false
				};
			});

			this.subscriptions.add(
				this.docService.newVersion(request).subscribe({
					next: ref => {
						if (!!ref) {
							this.store.dispatch(processRecords({ response: ref }));
						}

						this.notifService.showNotification(`New version created: ${ref.name} (V${this.nextVersion})`);
						this.dialogRef.close(ref);
					},
					error: errs => {
						this.notifService.showErrors(`Error creating document`, errs);
					},
					complete: () => {
						this.isFormSubmissionActive = false;
					}
				})
			);
		}, 150);
	}
}

export interface IVersionDocumentData {
	versionFromDocumentId: string;
	versions: string[];
}
