// tslint:disable:max-classes-per-file
import { createAction, props } from '@ngrx/store';

export enum TenantCompanyActions {
	RefreshTenantCompany = 'RefreshTenantCompany',
	SetTenantCompany = 'SetTenantCompany',
	UpdateRegionCode = 'UpdateRegionCode'
}

export const setTenantCompany = createAction(TenantCompanyActions.SetTenantCompany, props<{ regionCode: string }>());

export const updateRegionCode = createAction(TenantCompanyActions.UpdateRegionCode, props<{ regionCode: string }>());
