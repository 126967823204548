import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { Subscription } from 'rxjs';

import { BriefSection } from '@common/models/DocumentBriefs/Item/BriefSection';
import { DocumentListItemDto } from '@common/models/Documents/List/DocumentListItemDto';
import { DocumentListRequestUnpaginated } from '@common/models/Documents/List/DocumentListRequestUnpaginated';
import { NotificationService } from '@common/notification';
import { DocumentBriefsService } from '@common/services/documentbriefs.service';
import { getFileIcon } from '@common/utils/file-extensions';
import { Store } from '@ngrx/store';

import { IAppState } from 'app/core/state/app.state';
import { processRecords } from 'app/core/state/lists/document-list/document-list.actions';
import { DocumentsService } from 'app/services/documents.service';

import { DocumentListItemExtDto } from './DocumentListItemExtDto';
import { DocumentListFilterComponent } from './filter/document-list-filter.component';
import { DocumentListFilterConfig } from './filter/document-list-filter.config';

@Component({
	selector: 'document-list-dialog',
	styleUrls: ['./document-list-dialog.component.scss'],
	templateUrl: './document-list-dialog.component.html'
})
export class DocumentListDialogComponent implements OnInit, AfterViewInit, OnDestroy {
	private subscriptions: Subscription = new Subscription();
	dataSource = new MatTableDataSource<DocumentListItemDto>();
	documentSections: { [id: string]: IDocumentSection } = {};

	displayedColumns: string[] = ['createdDate', 'title', 'documentTags', 'lastModifiedBy', 'section', 'action'];
	documentList: DocumentListItemDto[];
	sections: BriefSection[];
	isRequestInProgress: boolean;

	@ViewChild(DocumentListFilterComponent, { static: true })
	filter: DocumentListFilterComponent;
	@ViewChild(MatSort) sort: MatSort;

	filterRequest: Partial<DocumentListRequestUnpaginated> = {
		createdDateEnd: null,
		createdDateStart: null,
		modifiedDateStart: null,
		modifiedDateEnd: null,
		documentCategoryId: null,
		documentTags: [],
		exc: [],
		inc: [],
		search: null,
		uploadedById: null,
		matterId: this.data.matterId,
		notInBriefId: this.data.briefId
	};

	config: Partial<DocumentListFilterConfig> = {
		request: this.filterRequest,
		showEntityFilter: false,
		showReportTypeFilter: false,
		hideCategoryFilter: true
	};

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: IDocumentListDialogData,
		private documentsService: DocumentsService,
		private documentBriefsService: DocumentBriefsService,
		private notifService: NotificationService,
		private dialogRef: MatDialogRef<DocumentListDialogComponent>,
		private store: Store<IAppState>
	) {}

	ngOnInit(): void {
		this.subscriptions.add(this.refreshList(true));
		if (!!this.data.briefId) {
			this.subscriptions.add(
				this.documentBriefsService.getDocumentBrief(this.data.briefId).subscribe(brief => {
					this.sections = brief.sections;
				})
			);
		}
	}

	ngAfterViewInit(): void {
		this.subscriptions.add(
			this.filter?.root?.filterChange.subscribe(() => {
				this.refreshList();
			})
		);
		this.dataSource.sort = this.sort;
	}

	assignDatasource(documentList: DocumentListItemDto[]) {
		this.documentList = documentList;
		this.dataSource.data = this.dataSource.sortData(documentList, this.sort);
		setTimeout(() => {
			this.onDataLoaded();
		}, 0);
	}

	refreshList(isFirstTimeLoad: boolean = false) {
		this.isRequestInProgress = true;
		const request = this.filter?.root?.filter ?? this.filterRequest;
		request.matterId = this.data.matterId;
		(request as DocumentListRequestUnpaginated).notInBriefId = this.data.briefId;

		this.subscriptions.add(
			this.documentsService.getDocumentsNotInBrief(request).subscribe(records => {
				this.isRequestInProgress = false;
				if (isFirstTimeLoad && records?.length === 0) {
					this.dialogRef.close();
					this.notifService.showError(
						'No documents to Add',
						'There are no documents to add. This could be because:<ul><li>There are no documents on this matter</li><li>All documents are already associated to this brief</li></ul>'
					);
					return;
				}
				this.assignDatasource(records);
			})
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	// Resolve icons on loading of the data set
	onDataLoaded(): void {
		this.documentList.forEach(rec => {
			(rec as DocumentListItemExtDto).icon = this.getFileIcon(rec.fileExtension, rec.hasAttachments);
			(rec as DocumentListItemExtDto).class = this.getFileIcon(rec.fileExtension);
		});
	}

	selectionChanged(documentId: string, event: MatSelectChange) {
		this.documentSections[documentId] = event.value;
	}

	addDocumentToSection(row: DocumentListItemDto) {
		const sectionId: any = this.documentSections[row.id];
		this.subscriptions.add(
			this.documentsService
				.addDocumentsToBrief({
					briefId: this.data.briefId,
					documentIds: [row.id],
					sectionId
				})
				.subscribe({
					next: response => {
						this.store.dispatch(processRecords({ response: response }));

						const documentList = this.documentList.filter(item => item.id !== row.id);
						this.assignDatasource(documentList);
						if (!!sectionId) {
							const sectionName = this.sections.filter(x => x.id === sectionId)[0].name;
							this.notifService.showNotification(
								`Document ${row.title} has been added to section ${sectionName} `
							);
						} else this.notifService.showNotification(`Document ${row.title} has been added to the Brief`);
					},
					error: errors => this.notifService.showErrors('Error adding document to brief ', errors)
				})
		);
	}

	// File icon for the file extension
	protected getFileIcon(extension: string, hasAttachments: boolean = false): string {
		return getFileIcon(extension, hasAttachments);
	}
}

export interface IDocumentSection {
	documentId: string;
	sectionId: string;
}

export interface IDocumentListDialogData {
	matterId: string;
	briefId: string;
	sectionId: string;
}
