// This file has been generated from GeneralSettingsController.cs
// Do not modify this file directly!!
// tslint:disable

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Moment } from 'moment-timezone';

import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { TrimStringsOnObject } from '@common/utils/string-format';

import { DocumentBriefExportOptionsDto } from '../../models/Settings/Setting/Item/DocumentBriefExportOptionsDto';
import { GstRateDto } from '../../models/Settings/Setting/Item/GstRateDto';
import { LicencingInfoDetailed } from '../../models/Settings/Setting/Item/LicencingInfoDetailed';
import { LicensingInfo } from '../../models/Settings/Setting/Item/LicensingInfo';
import { LoginOptionRulesDto } from '../../models/Settings/Setting/Item/LoginOptionRulesDto';
import { LoginOptionRulesUpdateDto } from '../../models/Settings/Setting/Item/LoginOptionRulesUpdateDto';
import { MatterMailToDto } from '../../models/Settings/Setting/Item/MatterMailToDto';
import { MatterNumberingDto } from '../../models/Settings/Setting/Item/MatterNumberingDto';
import { MatterTimerConfigCreateUpdateDto } from '../../models/Settings/Setting/Item/MatterTimerConfigCreateUpdateDto';
import { MatterTimerConfigDto } from '../../models/Settings/Setting/Item/MatterTimerConfigDto';
import { MediaTypeUpdateDto } from '../../models/Settings/Setting/Item/MediaTypeUpdateDto';
import { SearchServiceSettingsDto } from '../../models/Settings/Setting/Item/SearchServiceSettingsDto';
import { SettingTenantContactCreateUpdateDto } from '../../models/Settings/Setting/Item/SettingTenantContactCreateUpdateDto';
import { ShareLinkExpiryDto } from '../../models/Settings/Setting/Item/ShareLinkExpiryDto';
import { StripeInfo } from '../../models/Settings/Setting/Item/StripeInfo';
import { TenantCompanyDto } from '../../models/Settings/Setting/Item/TenantCompanyDto';
import { TenantFeatureStateDto } from '../../models/Settings/Setting/Item/TenantFeatureStateDto';
import { TenantFeatureStateUpdateDto } from '../../models/Settings/Setting/Item/TenantFeatureStateUpdateDto';
import { TenantInformationDto } from '../../models/Settings/Setting/Item/TenantInformationDto';
import { TenantInitializationDto } from '../../models/Settings/Setting/Item/TenantInitializationDto';
import { UnitsPerHourCreateUpdateDto } from '../../models/Settings/Setting/Item/UnitsPerHourCreateUpdateDto';
import { WorkflowDocumentTemplateDto } from '../../models/Settings/Setting/Item/WorkflowDocumentTemplateDto';
import { MediaTypeListItemDto } from '../../models/Settings/Setting/List/MediaTypeListItemDto';
import { TransactionType } from '../../models/Trust/Common/TransactionType';

@Injectable({
	providedIn: 'root'
})
export class GeneralSettingsService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'api/v1/settings/GeneralSettings');
	}

	getTenantInfo(): Observable<TenantInformationDto> {
		return this.getItem<void, TenantInformationDto>(`GetTenantInfo`);
	}

	getTenantContact(): Observable<TenantCompanyDto> {
		return this.getItem<void, TenantCompanyDto>(`TenantContact`);
	}

	saveTenantContact(dto: SettingTenantContactCreateUpdateDto): Observable<any> {
		return this.post<SettingTenantContactCreateUpdateDto, any>(`TenantContact`, TransformDatesOnObject(SettingTenantContactCreateUpdateDto, TrimStringsOnObject(dto)));
	}

	getFeatureStates(): Observable<TenantFeatureStateDto[]> {
		return this.getArray<void, TenantFeatureStateDto>(`features`);
	}

	updateFeatures(dto: TenantFeatureStateUpdateDto): Observable<any> {
		return this.put(`features`, TransformDatesOnObject(TenantFeatureStateUpdateDto, TrimStringsOnObject(dto)));
	}

	initializeTenant(dto: TenantInitializationDto): Observable<any> {
		return this.post<TenantInitializationDto, any>(`InitializeTenant`, TransformDatesOnObject(TenantInitializationDto, TrimStringsOnObject(dto)));
	}

	hasTenantInitialized(): Observable<boolean> {
		return this.getItem<void, boolean>(`TenantInitialized`);
	}

	getUnits(): Observable<number> {
		return this.getItem<void, number>(`units`);
	}

	saveUnits(dto: UnitsPerHourCreateUpdateDto): Observable<any> {
		return this.put(`units`, TransformDatesOnObject(UnitsPerHourCreateUpdateDto, TrimStringsOnObject(dto)));
	}

	getMediaTypes(transactionType: keyof typeof TransactionType): Observable<MediaTypeListItemDto[]> {
		return this.getArray<string, MediaTypeListItemDto>(`mediaType`, !!transactionType ? { transactionType: transactionType.toString() } : null);
	}

	saveMediaType(dto: MediaTypeUpdateDto): Observable<any> {
		return this.put(`saveMediaType`, TransformDatesOnObject(MediaTypeUpdateDto, TrimStringsOnObject(dto)));
	}

	getGstRate(dto: GstRateDto): Observable<number> {
		return this.getItem<GstRateDto, number>(`gstRate`, dto);
	}

	getTenantActivationDate(): Observable<Moment> {
		return this.getItem<void, Moment>(`tenantActivationDate`);
	}

	getTenantStripeInfo(): Observable<StripeInfo> {
		return this.getItem<void, StripeInfo>(`tenantStripeInfo`);
	}

	getLicensingInfo(): Observable<LicensingInfo> {
		return this.getItem<void, LicensingInfo>(`licensingInfo`);
	}

	getLicencingInfoDetailed(): Observable<LicencingInfoDetailed> {
		return this.getItem<void, LicencingInfoDetailed>(`licencingInfoDetailed`);
	}

	getMatterTimerConfig(): Observable<MatterTimerConfigDto> {
		return this.getItem<void, MatterTimerConfigDto>(`matterTimerConfig`);
	}

	saveMatterTimerConfig(dto: MatterTimerConfigCreateUpdateDto): Observable<any> {
		return this.put(`matterTimerConfig`, TransformDatesOnObject(MatterTimerConfigCreateUpdateDto, TrimStringsOnObject(dto)));
	}

	getSearchServiceSettings(): Observable<SearchServiceSettingsDto> {
		return this.getItem<void, SearchServiceSettingsDto>(`GetSearchServiceSettings`);
	}

	saveSearchServiceSettings(dto: SearchServiceSettingsDto): Observable<any> {
		return this.put(`SaveSearchServiceSettings`, TransformDatesOnObject(SearchServiceSettingsDto, TrimStringsOnObject(dto)));
	}

	getDocumentBriefExportOptions(): Observable<DocumentBriefExportOptionsDto> {
		return this.getItem<void, DocumentBriefExportOptionsDto>(`GetDocumentBriefExportOptions`);
	}

	saveDocumentBriefExportOptions(dto: DocumentBriefExportOptionsDto): Observable<any> {
		return this.put(`SaveDocumentBriefExportOptions`, TransformDatesOnObject(DocumentBriefExportOptionsDto, TrimStringsOnObject(dto)));
	}

	getLoginOptionRules(): Observable<LoginOptionRulesDto> {
		return this.getItem<void, LoginOptionRulesDto>(`login-options`);
	}

	updateLoginOptionRules(dto: LoginOptionRulesUpdateDto): Observable<any> {
		return this.put(`login-options`, TransformDatesOnObject(LoginOptionRulesUpdateDto, TrimStringsOnObject(dto)));
	}

	getMatterMailToConfig(): Observable<MatterMailToDto> {
		return this.getItem<void, MatterMailToDto>(`GetMatterMailToConfig`);
	}

	setMatterMailToConfig(dto: MatterMailToDto): Observable<any> {
		return this.put(`SetMatterMailToConfig`, TransformDatesOnObject(MatterMailToDto, TrimStringsOnObject(dto)));
	}

	getWorkflowDocumentTemplateConfig(): Observable<WorkflowDocumentTemplateDto> {
		return this.getItem<void, WorkflowDocumentTemplateDto>(`GetWorkflowDocumentTemplateConfig`);
	}

	setWorkflowDocumentTemplateConfig(dto: WorkflowDocumentTemplateDto): Observable<any> {
		return this.put(`SetWorkflowDocumentTemplateConfig`, TransformDatesOnObject(WorkflowDocumentTemplateDto, TrimStringsOnObject(dto)));
	}

	getMatterNumberingConfig(): Observable<MatterNumberingDto> {
		return this.getItem<void, MatterNumberingDto>(`GetMatterNumberingConfig`);
	}

	setMatterNumberingConfig(dto: MatterNumberingDto): Observable<any> {
		return this.put(`SetMatterNumberingConfig`, TransformDatesOnObject(MatterNumberingDto, TrimStringsOnObject(dto)));
	}

	getShareLinkExpiry(): Observable<ShareLinkExpiryDto> {
		return this.getItem<void, ShareLinkExpiryDto>(`GetShareLinkExpiry`);
	}

	setShareLinkExpiry(dto: ShareLinkExpiryDto): Observable<any> {
		return this.put(`SetShareLinkExpiry`, TransformDatesOnObject(ShareLinkExpiryDto, TrimStringsOnObject(dto)));
	}

}
