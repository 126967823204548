<ng-container [formGroup]="mediaForm">
	<div class="flex-row">
		<mat-form-field>
			<mat-placeholder
				>Media Type
				<sup class="color-warn">*</sup>
			</mat-placeholder>
			<mat-select [formControl]="mediaDetails.controls.mediaType">
				<mat-option *ngFor="let mediaType of mediaTypes" [value]="mediaType.id" (onSelectionChange)="onMediaTypeChanged($event, mediaType)">{{
					mediaType.name
				}}</mat-option>
			</mat-select>
		</mat-form-field>
		<div>
			<div class="ripple-element" [class.highlight-clearance-date]="isClearanceDateHighlighted"></div>
			<mat-form-field class="small-form-field" *ngIf="transactionType === transactionTypeEnum.Receipt">
				<mat-placeholder>Clearance Date<sup class="color-warn">*</sup> </mat-placeholder>
				<input [formControl]="mediaDetails.controls.clearanceDate" matInput [matDatepicker]="datePickerClearance" autocomplete="off" />
				<mat-datepicker-toggle matSuffix [for]="datePickerClearance"></mat-datepicker-toggle>
				<mat-datepicker #datePickerClearance></mat-datepicker>
				<mat-error> <error-messages [for]="mediaDetails.controls.clearanceDate"></error-messages> </mat-error>
			</mat-form-field>
		</div>
	</div>

	<div class="flex-row" *ngIf="chequeInfoRequired">
		<mat-form-field>
			<input [formControl]="mediaDetails.controls.chequeDrawer" matInput autocomplete="off" />
			<mat-placeholder
				>Cheque Drawer
				<sup class="color-warn">*</sup>
			</mat-placeholder>
			<mat-error>
				<error-messages [for]="mediaDetails.controls.chequeDrawer"></error-messages>
			</mat-error>
		</mat-form-field>

		<mat-form-field>
			<input [formControl]="mediaDetails.controls.chequeBank" matInput autocomplete="off" />
			<mat-placeholder
				>Cheque Bank Details
				<sup class="color-warn">*</sup>
			</mat-placeholder>
			<mat-error>
				<error-messages [for]="mediaDetails.controls.chequeBank"></error-messages>
			</mat-error>
		</mat-form-field>
	</div>

	<ng-container *ngIf="paymentEFTInfoRequired || paymentPexaDirectDebitInfoRequired">
		<div class="flex-row" *ngIf="isPayment">
			<mat-form-field>
				<input [formControl]="mediaDetails.controls.paymentEFTAccountName" matInput autocomplete="off" />
				<mat-placeholder
					>Account Name
					<sup class="color-warn">*</sup>
				</mat-placeholder>
				<mat-error>
					<error-messages [for]="mediaDetails.controls.paymentEFTAccountName"></error-messages>
				</mat-error>
			</mat-form-field>
			<mat-form-field>
				<input [formControl]="mediaDetails.controls.paymentEFTBankAccountNumber" matInput autocomplete="off" />
				<mat-placeholder
					>Account Number
					<sup class="color-warn">*</sup>
				</mat-placeholder>
				<mat-hint>Account Number / BSB-Account Number</mat-hint>
				<mat-error>
					<error-messages [for]="mediaDetails.controls.paymentEFTBankAccountNumber"></error-messages>
				</mat-error>
			</mat-form-field>
		</div>
		<div class="flex-row">
			<mat-form-field>
				<input [formControl]="mediaDetails.controls.paymentEFTBankReference" matInput autocomplete="off" />
				<mat-placeholder
					>Bank Reference
					<sup class="color-warn">*</sup>
				</mat-placeholder>
				<mat-error>
					<error-messages [for]="mediaDetails.controls.paymentEFTBankReference"></error-messages>
				</mat-error>
			</mat-form-field>
		</div>
	</ng-container>
</ng-container>
