import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot } from '@angular/router';

import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { Features } from '@common/models/Settings/Modules/Features';
import { TenantFeatureStateDto } from '@common/models/Settings/Setting/Item/TenantFeatureStateDto';
import { NotificationService } from '@common/notification';
import { ITenantData } from '@common/state/models/tenant-data';
import { Store } from '@ngrx/store';

import { IPaywallDialogData, PaywallDialogComponent } from './paywall-dialog.component';

@Injectable({
	providedIn: 'root'
})
export class AccessRouteGuard implements OnDestroy {
	private subscriptions: Subscription = new Subscription();
	constructor(
		protected _notificationService: NotificationService,
		private _store: Store<{ tenantData: ITenantData }>,
		private dialog: MatDialog
	) {}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
	canActivate(route: ActivatedRouteSnapshot) {
		if (!!route?.data?.protectedFeature) return this.isFeatureEnabled(route.data.protectedFeature);
		return true;
	}

	isFeatureEnabled(feature: keyof typeof Features) {
		return this._store
			.select(state => state?.tenantData?.tenantInformation?.featureStates)
			.pipe(
				filter(Boolean),
				distinctUntilChanged(),
				map((states: TenantFeatureStateDto[]) => {
					if (
						states
							.filter(states => !!states.isEnabled)
							.map(states => states.type)
							.includes(feature)
					)
						return true;

					var data: IPaywallDialogData = { feature: feature };
					this.subscriptions.add(
						this.dialog.open(PaywallDialogComponent, { data }).afterClosed().subscribe()
					);
					return false;
				})
			);
	}
}
