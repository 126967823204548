<h2 mat-dialog-title>
	<span>Download Progress</span>
</h2>
<mat-dialog-content>
	<div class="item-spacing">
		<div class="full-width" *ngIf="!data.hideProgress">
			<div class="progress-row">
				{{ progressPercent | number: '1.0-0' }}% downloaded

				<span class="light-text">{{ this.data.totalSize | bytes: 2 }} </span>
			</div>

			<div *ngIf="fileDownloadStarted">
				<mat-progress-bar [value]="progressPercent"> </mat-progress-bar>
			</div>
			<div class="progress-row">
				<span class="light-text"
					>remaining <b>{{ this.timeRemainingHuman }}</b>
				</span>
			</div>
		</div>
		<div *ngIf="data.hideProgress">
			Downloading the zip file.
			<mat-progress-bar mode="buffer"></mat-progress-bar>
		</div>
	</div>
	<div *ngIf="fileDownloadStarted">
		<ng-template [ngIf]="isDownloadError">
			<span class="error">An error occured while downloading files. Please try again.{{ errorText ? ' Error: ' + errorText : '' }}</span>
		</ng-template>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<button throttleButton (throttledClick)="cancelDownloads()" mat-button>Cancel</button>
</mat-dialog-actions>
