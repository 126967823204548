import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { never, Observable, of } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';

import { EntityReference } from '@common/models/Common/EntityReference';
import { IUploadFile, IUploadMultipleFiles, UploadStatus } from '@common/models/Common/IFileUploader';
import { IResultWithAttachments } from '@common/models/Common/IResultWithAttachments';
import { ImportEntityType } from '@common/models/Imports/Common/ImportEntityType';
import { ImportStatus } from '@common/models/Imports/Common/ImportStatus';
import { ImportUploadDto } from '@common/models/Imports/Item/ImportUploadDto';
import { ImportListItemDto } from '@common/models/Imports/List/ImportListItemDto';
import { ImportListRequest } from '@common/models/Imports/List/ImportListRequest';
import { INotificationMessage, NotificationService } from '@common/notification';
import { ImportService } from '@common/services/import.service';
import { getFilenameFromHttpHeader } from '@common/utils/fileNameUtil';
import * as FileSaver from 'file-saver';
import { get } from 'lodash';
import * as moment from 'moment-timezone';

import { AppBrandingService } from 'app/services/app-branding.service';
import { GridViewService } from 'app/services/grid-view.service';
import { GenericListComponent } from 'app/shared/generics/generic.list.component';
import { DragEvents } from 'app/shared/multiple-file-uploader';
import { FileValidationService } from 'app/shared/multiple-file-uploader/filevalidation.service';
import { IErrorContainer } from 'app/shared/utils/IErrorContainer';
import { handleHttpOperationError } from 'app/shared/utils/uploadResponseHandler';

@Component({
	selector: 'import-entities',
	styleUrls: ['./import-entities.component.scss'],
	templateUrl: './import-entities.component.html'
})
export class ImportEntitiesComponent
	extends GenericListComponent<ImportListItemDto, ImportListRequest>
	implements OnInit
{
	static readonly maxUploadSizeMb: number = 50;

	@Input()
	entityType: ImportEntityType;

	dragOver: boolean;
	showProgress: boolean = false;
	progressPercentage: number = 0;
	error: IErrorContainer = { message: '' };
	newestUpload: EntityReference = null;
	disableSubmit: boolean = false;

	constructor(
		dialog: MatDialog,
		router: Router,
		activatedRoute: ActivatedRoute,
		private importService: ImportService,
		private fileValidationService: FileValidationService,
		private notificationService: NotificationService,
		gridViewService: GridViewService
	) {
		super(
			dialog,
			router,
			activatedRoute,
			importService,
			[
				'modifiedDate',
				'fileName',
				'totalEntityCount',
				'invalidEntityCount',
				'modifiedBy',
				'importStatus',
				'actions',
				'moreActions'
			],
			gridViewService
		);
	}

	ngOnInit() {
		if (!this.FilterBase) {
			this.FilterBase = new ImportListRequest();
		}
		this.FilterBase.importType = this.entityType;
	}

	download(importId: string) {
		this.subscriptions.add(
			this.importService.downloadFile(importId).subscribe(response => {
				FileSaver.saveAs(response.body, getFilenameFromHttpHeader(response.headers.get('content-disposition')));
			})
		);
	}

	deleteImport(importId: string) {
		this.subscriptions.add(
			this.notificationService
				.showConfirmation('Delete Import', `Are you sure you want to delete the import record?`)
				.pipe(
					filter(Boolean),
					switchMap(() => {
						this.disableSubmit = true;
						return this.importService.deleteImport(importId);
					})
				)
				.subscribe(() => {
					this.notificationService.showNotification('Import deleted');
					this.refreshList();
					this.disableSubmit = false;
				})
		);
	}

	submit(importId: string) {
		this.subscriptions.add(
			this.notificationService
				.showConfirmation('Submit Import', `This process may take some time. Are you sure you want to submit?`)
				.pipe(
					filter(Boolean),
					switchMap(() => {
						this.disableSubmit = true;
						return this.importService.submitImport(importId);
					})
				)
				.subscribe(
					() => {
						this.notificationService.showNotification('Import submitted');
						this.refreshList();
						this.disableSubmit = false;
					},
					errors => {
						this.notificationService.showErrors(`Error submitting the file`, errors);
					}
				)
		);
	}

	canSubmit(row: ImportListItemDto): boolean {
		if (
			row.importStatus === ImportStatus.Valid &&
			row.invalidEntityCount === 0 &&
			row.totalEntityCount > 0 &&
			this.datasource.dataLoaded
		) {
			const newest = this.datasource.response.records.sort(
				(a, b) => b.modifiedDate.valueOf() - a.modifiedDate.valueOf()
			);
			return newest[0].id === row.id;
		}
		return false;
	}

	shouldHighlight(id: string) {
		return this.newestUpload && id === this.newestUpload.id;
	}

	onDragEvent(output: DragEvents): void {
		if (output === DragEvents.dragOver) {
			this.dragOver = true;
		} else if (output === DragEvents.dragOut) {
			this.dragOver = false;
		} else if (output === DragEvents.drop) {
			this.dragOver = false;
		}
	}

	onFilesDropped(fileList: FileList) {
		// Get a file object from the input[type='file']
		const target: HTMLInputElement = event.target as HTMLInputElement;
		if (target == null) {
			throw new Error("The onChange event wasn't thrown on a file input control");
		}
		this.onFilesSelected(fileList);
	}

	downloadTemplate() {
		this.subscriptions.add(
			this.importService.downloadTemplate({ entityType: this.entityType }).subscribe(response => {
				FileSaver.saveAs(response.body, getFilenameFromHttpHeader(response.headers.get('content-disposition')));
			})
		);
	}

	downloadErrors(row: ImportListItemDto) {
		if (row.importStatus === ImportStatus.Invalid) {
			this.subscriptions.add(
				this.importService.getImportErrors(row.id).subscribe(response => {
					FileSaver.saveAs(
						response.body,
						getFilenameFromHttpHeader(response.headers.get('content-disposition'))
					);
				})
			);
		} else if (row.importStatus === ImportStatus.Errored) {
			this.notificationService.showError(
				'Validation Error',
				`An error has occured. Please contact ${AppBrandingService.getSiteName()} support for more information.`
			);
		}
	}

	onFileBrowsed(event: Event) {
		// Get a file object from the input[type='file']
		const target: HTMLInputElement = event.target as HTMLInputElement;
		if (target == null) {
			throw new Error("The onChange event wasn't thrown on a file input control");
		}
		this.onFilesSelected(target.files);

		// clear the file browser value (i.e. file path) otherwise the event will
		// not fire if the user picks a file with the same name again.
		target.value = null;
	}

	private onFilesSelected(fileList: FileList): void {
		this.error.message = this.validateFiles(fileList);
		if (this.error.message) return;

		if (fileList != null && fileList.length > 0) {
			this.uploadFileInfoUpdated(this.makeUploadFile(fileList[0]));
		}
	}

	private uploadFileInfoUpdated(uploadFileInfo: IUploadFile) {
		this.subscriptions.add(
			this.notificationService
				.showConfirmation(
					'Upload for validation',
					`This process may take some time. Press OK to run validation on ${uploadFileInfo.name}`,
					'OK',
					'Cancel'
				)
				.pipe(
					filter(Boolean),
					switchMap(() => {
						this.disableSubmit = true;
						const importUploadDto: ImportUploadDto = {
							entityType: this.entityType,
							attachments: []
						};
						const filesToUpload: IUploadMultipleFiles = {
							progress: null,
							nativeFiles: [uploadFileInfo.nativeFile]
						};
						return this.importService.upload(importUploadDto, filesToUpload);
					}),
					catchError((errorResponse: HttpErrorResponse) => {
						this.showProgress = false;
						return handleHttpOperationError(errorResponse, this.error);
					})
				)
				.subscribe(
					response => this.handleUploadOperationResult(response),
					errorResponse => {
						this.showProgress = false;
						return handleHttpOperationError(errorResponse, this.error);
					}
				)
		);
	}

	private makeUploadFile(file: File): IUploadFile {
		if (!file) return null;

		return {
			name: file.name,
			size: file.size,
			type: file.type,
			documentTags: null,
			categoryId: 'Import',
			practiceAreaIds: '',
			errorText: null,
			nativeFile: file,
			progress: {
				status: UploadStatus.Queued
			},
			lastModified: moment(new Date(file.lastModified)),
			created: moment(new Date(file.lastModified)),
			extension: !!file && file.name.includes('.') ? '.' + file.name.split('.').pop() : null
		};
	}

	private validateFiles(addedFiles: FileList): string {
		if (addedFiles.length > 1) {
			return 'Cannot upload more than one file at a time.';
		}

		if (!this.fileValidationService.validFileSizes(addedFiles, ImportEntitiesComponent.maxUploadSizeMb)) {
			return `The file size exceeds the maximum size of ${ImportEntitiesComponent.maxUploadSizeMb}MB.`;
		}

		for (let i = 0; i < addedFiles.length; i++) {
			const file = addedFiles.item(i);
			if (!this.fileValidationService.validFileType(file, ['.xlsx', '.xls', '.xlsm'])) {
				return `Only excel files are allowed. Cannot add "${file.name}"`;
			}
		}
		return null;
	}

	private handleUploadOperationResult(result: Partial<IResultWithAttachments>): Observable<INotificationMessage> {
		if (!get(result, 'uploadFiles.progress.status')) {
			// waiting for upload status to be updated
			return never();
		}

		if (result.uploadFiles.progress.status === UploadStatus.Uploading) {
			const percentage = result.uploadFiles.progress.data.percentage;
			this.progressPercentage = percentage;
			this.showProgress = true;
			return never();
		}

		if (result.uploadFiles.progress.status === UploadStatus.Completed) {
			this.showProgress = false;
			this.notificationService.showNotification('Validation completed');
			this.subscriptions.add(this.refreshList());
			return this.handleCompleted(result);
		}

		return never();
	}

	private handleCompleted(result: Partial<IResultWithAttachments>): Observable<INotificationMessage> {
		this.showProgress = false;
		if (result.mutationResponse) {
			this.newestUpload = result.mutationResponse;
			const notification = { text: `Data uploaded` };
			this.disableSubmit = false;
			return of(notification);
		}

		return of({
			text: 'Something went wrong during the upload or validation.'
		});
	}
}
