import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';

import { SharedDirectivesModule } from '@common/directives/shared-directives.module';
import { SharedPipesModule } from '@common/pipes/shared-pipes.module';
import { NgMathPipesModule } from 'angular-pipes';

import { ComponentsModule } from '../components';
import { PipesModule } from '../pipes/pipes.module';
import { DocumentConfirmDialogComponent } from './document-confirm-dialog.component';
import { NgFileDropDirective } from './file-drop.directive';
import { FileValidationService } from './filevalidation.service';

@NgModule({
	declarations: [DocumentConfirmDialogComponent, NgFileDropDirective],
	exports: [DocumentConfirmDialogComponent, NgFileDropDirective],
	imports: [
		CommonModule,
		ComponentsModule,
		FormsModule,
		MatButtonModule,
		MatDatepickerModule,
		MatDialogModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		MatProgressBarModule,
		MatSelectModule,
		NgMathPipesModule,
		ReactiveFormsModule,
		PipesModule,
		SharedPipesModule,
		SharedDirectivesModule
	],
	providers: [FileValidationService]
})
export class MultipleFileUploaderModule {}
