<h2 mat-dialog-title class="title">{{ data?.entityType === 'Invoice' ? 'Download Invoice' : 'New Document' }} From Template</h2>
<mat-dialog-content *ngIf="!!form" [formGroup]="form" class="flex-column">
	<div class="flex-row" *ngIf="!!data && !data.disableTemplateSelect">
		<document-template-lookup
			#templateLookup
			Placeholder="{{ data?.entityType }} Template"
			[Required]="true"
			[FormControl]="templateIdControl"
			[HasAutofocus]="true"
			[entityType]="data.entityType"
			(Selected)="templateSelected($event.id)"
			[autoSelectIfOnlyOne]="true"
			[Hint]="subscriptionRequiredMessageHtml(selectedTemplateInstallationRecordLicenceStatus)"
		>
		</document-template-lookup>
	</div>
	<div class="flex-row" *ngIf="showMatterContacts">
		<mat-form-field>
			<mat-placeholder
				>Select a Contact
				<sup class="color-warn">*</sup>
			</mat-placeholder>
			<mat-select formControlName="genericContactId">
				<mat-option *ngFor="let contact of contactsOnMatter" [value]="contact.role.displayRoleName + '-' + contact.id">
					<span
						><b>{{ contact.role.displayRoleName }}</b></span
					>
					<span>{{ ': ' + contact.fullName }}</span>
				</mat-option>
			</mat-select>
			<mat-hint>The selected Matter Contacts data will be populated on merge of document template</mat-hint>
			<mat-error>
				<error-messages [for]="form?.get('genericContactId')"></error-messages>
			</mat-error>
		</mat-form-field>
	</div>
	<div class="flex-row">
		<mat-form-field>
			<mat-placeholder
				>Document Title
				<sup class="color-warn">*</sup>
			</mat-placeholder>
			<input matInput formControlName="documentName" (keypress)="stopSyncingDocumentName()" (blur)="onDocumentNameBlur()" autocomplete="off" />
			<mat-error>
				<error-messages [for]="form?.get('documentName')"></error-messages>
			</mat-error>
		</mat-form-field>
	</div>

	<document-tag
		*ngIf="showDocumentTags"
		[matterId]="matterId"
		[ActiveTags]="selectedTemplate?.documentTags"
		[FormControl]="form?.get('documentTags')"
	></document-tag>
	<br />

	<div
		class="flex-row"
		*ngIf="data?.entityType === 'Invoice' || data?.entityType === 'Payment' || data?.entityType === 'Matter' || data?.entityType === 'Contact'"
	>
		<mat-radio-group formControlName="isPdf">
			<mat-radio-button [checked]="true" value="false"
				>{{ data?.entityType === 'Matter' || data?.entityType === 'Contact' ? 'Save' : 'Download' }} as Word</mat-radio-button
			>
			<mat-radio-button value="true">{{ data?.entityType === 'Matter' || data?.entityType === 'Contact' ? 'Save' : 'Download' }} as PDF</mat-radio-button>
		</mat-radio-group>
	</div>

	<div class="flex-row" *ngIf="data?.entityType === 'Invoice'">
		<mat-checkbox formControlName="addDocumentToMatter">Save Invoice document to Matter</mat-checkbox>
	</div>

	<mat-expansion-panel *ngIf="!!selectedTemplate && relatedMatterContacts?.value?.length" class=".custom-field-expansion-panel" [expanded]="true">
		<mat-expansion-panel-header>
			<mat-panel-title>
				<mat-icon>build</mat-icon>
				<span>Matter Contacts</span>
			</mat-panel-title>
			<mat-panel-description> </mat-panel-description>
		</mat-expansion-panel-header>

		<div class="flexrow">
			<div class="custom-field-container" *ngFor="let matterContact of relatedMatterContacts | async">
				<div *ngIf="!!matterContact.role?.displayRoleName" class="property">
					<div class="field-label">{{ matterContact.role?.displayRoleName }}</div>
					<div *ngIf="!matterContact.fullName" class="field-value field-noitem">
						no {{ matterContact.role?.displayRoleName | lowercase }} recorded
					</div>
					<div *ngIf="!!matterContact.fullName" class="field-value">{{ matterContact.fullName }}</div>
				</div>
			</div>
		</div>
	</mat-expansion-panel>

	<div *ngIf="nonCustomFieldData?.length">
		<h2 mat-dialog-title class="title">Variables in the Document</h2>

		<mat-expansion-panel class=".custom-field-expansion-panel" [expanded]="true">
			<mat-expansion-panel-header>
				<mat-panel-title>
					<mat-icon>build</mat-icon>
					<span>{{ data.entityType === 'Contact' ? 'Contact' : 'Matter' }} Details</span>
				</mat-panel-title>
				<mat-panel-description> </mat-panel-description>
			</mat-expansion-panel-header>

			<div class="flexrow">
				<div class="custom-field-container" *ngFor="let nonCustomField of nonCustomFieldData">
					<div class="property">
						<div class="field-label">{{ nonCustomField.name }}</div>
						<div class="field-value">{{ nonCustomField.value }}</div>
					</div>
				</div>
			</div>
		</mat-expansion-panel>
	</div>

	<div *ngFor="let groupedData of customFieldsGroupedData">
		<mat-expansion-panel class=".custom-field-expansion-panel" [expanded]="true" [class.view-mode]="!isEditMode">
			<mat-expansion-panel-header>
				<mat-panel-title>
					<mat-icon>build</mat-icon>
					<span>{{ groupedData.group.name }}</span>
				</mat-panel-title>
				<mat-panel-description> </mat-panel-description>
			</mat-expansion-panel-header>

			<div class="flex-column">
				<editable-custom-fields
					class="flexrow"
					[hideContacts]="false"
					[editMode]="true"
					[fieldConfigValues]="groupedData.customFields"
					[customFieldsValues]="model?.customFields"
					[control]="form"
				></editable-custom-fields>
			</div>
		</mat-expansion-panel>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-raised-button color="primary" [mat-dialog-close]="formValue" [disabled]="form?.invalid">
		{{ data?.entityType === 'Invoice' ? 'Download' : 'Save' }}
	</button>
	<button mat-raised-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
