import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatOption, MatOptionSelectionChange } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelect, MatSelectChange } from '@angular/material/select';

import { Subscription, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AppcuesService } from '@common/appcues/appcues.service';
import { BaseDtoWithTrimmedId } from '@common/models/Common/BaseDtoWithTrimmedId';
import { EnumSortDirection } from '@common/models/Common/EnumSortDirection';
import { DocumentMarginDetailsDto } from '@common/models/Documents/Item/DocumentMarginDetailsDto';
import { DocumentTemplateUpdateDto } from '@common/models/Documents/Item/DocumentTemplateUpdateDto';
import { DocumentTemplateViewDto } from '@common/models/Documents/Item/DocumentTemplateViewDto';
import { TemplateEntityType } from '@common/models/Documents/TemplateDto/TemplateEntityType';
import { DocumentCategoryListItemDto } from '@common/models/Settings/DocumentCategories/List/DocumentCategoryListItemDto';
import { PracticeAreaListItemDto } from '@common/models/Settings/PracticeAreas/List/PracticeAreaListItemDto';
import { NotificationService } from '@common/notification';
import { DocumentCategoryService } from '@common/services/settings/documentcategory.service';
import { DocumentTemplatesService } from '@common/services/settings/documenttemplates.service';
import { PracticeAreasService } from '@common/services/settings/practiceareas.service';
import { CustomValidators } from '@common/validation/custom.validators';
import { Store } from '@ngrx/store';
import { cloneDeep, groupBy, isEmpty, isNil, remove } from 'lodash-es';

import { IAppState } from 'app/core/state/app.state';
import { processRecords } from 'app/core/state/lists/document-list/document-list.actions';
import { DocumentTagComponent } from 'app/shared/components/document-tag.component';

@Component({
	selector: 'template-properties',
	styleUrls: ['./template-properties.component.scss'],
	templateUrl: './template-properties.component.html'
})
export class TemplatePropertiesComponent implements OnInit, OnDestroy {
	@ViewChild('detailsInput')
	detailsInput: ElementRef;
	@ViewChild('allOption')
	allOption: MatOption;
	@ViewChild('selectPracticeAreas')
	selectPracticeAreas: MatSelect;
	@ViewChild(DocumentTagComponent, { static: false })
	tagComponent: DocumentTagComponent;

	form: FormGroup;
	practiceAreas: PracticeAreaListItemDto[];
	documentCategories: DocumentCategoryListItemDto[];
	entityTypeKeys = Object.keys(TemplateEntityType) as Array<keyof typeof TemplateEntityType>;
	hideCategory: boolean;
	conflictText: string;

	allowHeaderFooterTemplate: boolean;

	private subscription: Subscription = new Subscription();

	constructor(
		private dialogRef: MatDialogRef<TemplatePropertiesComponent>,
		@Inject(MAT_DIALOG_DATA) private data: BaseDtoWithTrimmedId,
		private fb: FormBuilder,
		private templateService: DocumentTemplatesService,
		private practiceAreaService: PracticeAreasService,
		private documentCategoryService: DocumentCategoryService,
		private notifService: NotificationService,
		private appcuesService: AppcuesService,
		private store: Store<IAppState>
	) {}

	ngOnInit(): void {
		this.form = this.fb.group({
			associatedPracticeAreaIds: [],
			documentCategoryId: null,
			entityType: null,
			title: [null, [CustomValidators.required('Title'), CustomValidators.validateFileName()]],
			documentTags: null,
			documentHeaderFooterTemplateId: null
		});

		this.subscription.add(
			this.practiceAreaService
				.getPracticeAreaList({ sortBy: 'name', sortDirection: EnumSortDirection.Asc, showDisabled: true })
				.subscribe(next => (this.practiceAreas = next.records))
		);

		if (this.data.id) {
			// load the record
			this.subscription.add(
				this.templateService
					.getDocumentTemplate(this.data.id)
					.pipe(
						tap((document: DocumentTemplateViewDto) => {
							this.form.patchValue({
								associatedPracticeAreaIds: document.associatedPracticeAreaIds,
								documentCategoryId: document.documentCategoryId,
								entityType: document.entityType,
								title: document.title,
								documentTags: document.documentTags,
								documentHeaderFooterTemplateId: document.documentHeaderFooterTemplateId
							});

							this.updateRelatedTo(document.entityType);

							if (
								document.fileExtension === '.docx' ||
								document.fileExtension === '.doc' ||
								document.mimeType === 'application/msword' ||
								document.mimeType ===
									'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
							) {
								this.allowHeaderFooterTemplate = true;
							}

							// If no practice areas are available for the template 'All' option is selected
							if (isEmpty(document.associatedPracticeAreaIds)) {
								setTimeout(() => {
									if (this.allOption) {
										this.allOption.select();
									}
								}, 0);
							}
						}),
						switchMap(() => this.populateDocumentCategories$())
					)
					.subscribe()
			);
		} else {
			this.form.reset();
		}

		this.subscription.add(
			this.form.get('associatedPracticeAreaIds').valueChanges.subscribe(value => {
				if (this.form.get('entityType').value === TemplateEntityType.Matter) {
					this.tagComponent.practiceAreaIds = value;
				} else {
					this.tagComponent.systemTags = [];
					this.tagComponent.inputCtrl.updateValueAndValidity();
				}
			})
		);

		this.subscription.add(
			this.form.get('documentHeaderFooterTemplateId').valueChanges.subscribe(value => {
				if (!!value) {
					this.templateService
						.hasMarginConflict({
							id: this.data.id,
							documentHeaderFooterTemplateId: value
						})
						.subscribe(response => {
							if (response.length > 0) {
								this.conflictText = this.generateConflictText(response);
							}
						});
				} else {
					this.conflictText = '';
				}
			})
		);
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	save(): void {
		const request: DocumentTemplateUpdateDto = cloneDeep(this.form.value);
		remove(request.associatedPracticeAreaIds, isNil);

		if (this.hideCategory) {
			request.documentCategoryId = null;
		}

		if (this.form.get('entityType').value !== 'Matter') {
			request.associatedPracticeAreaIds = null;
		}

		this.subscription.add(
			this.templateService.updateTemplate(this.data.id, request).subscribe({
				next: reference => {
					this.store.dispatch(processRecords({ response: reference }));
					this.notifService.showNotification(`Template updated: ${reference.name}`);
					this.dialogRef.close(reference);
				},
				error: errors => this.notifService.showErrors(`Error updating template`, errors)
			})
		);
		this.appcuesService.trackEvent('SaveDocumentTemplate');
	}

	displaySelected(values: string[]) {
		if (!values?.length) {
			return 'All';
		}

		const results = this.practiceAreas
			.filter(area => !!values.filter(id => id === area.id)?.length)
			.map(area => area.name);

		if (!results?.length) {
			return 'All';
		}

		if (results.length == 1) {
			return results[0];
		}

		return results.reduce((left, right) => `${left}, ${right}`);
	}

	allOptionSelected(event: MatOptionSelectionChange) {
		if (event.source.selected) {
			// When selecting the 'All' option, deselect all practice areas
			this.selectPracticeAreas.options.forEach(opt => {
				if (opt.selected && opt.id !== this.allOption.id) {
					opt.deselect();
				}
			});
		} else if (!this.selectPracticeAreas.options.some(opt => opt.selected)) {
			// If nothing is selected, select the 'All' option
			this.allOption.select();
		}
	}

	optionSelected(event: MatOptionSelectionChange) {
		if (this.allOption.selected && event.source.selected && event.source.id !== this.allOption.id) {
			// When selecting a practice area, deselect the 'All' option
			this.allOption.deselect();
		} else if (!this.selectPracticeAreas.options.some(opt => opt.selected)) {
			// If nothing is selected, select the 'All' option
			this.allOption.select();
		}
	}

	entityTypeChanged(event: MatSelectChange) {
		this.updateRelatedTo(event.value);

		if (event.value === TemplateEntityType.Contact) {
			this.form.patchValue(
				{
					associatedPracticeAreaIds: null
				},
				{ emitEvent: false }
			);
		} else if (event.value === TemplateEntityType.Matter) {
			setTimeout(() => this.allOption.select(), 0);
		}
	}

	updateRelatedTo(entityType: keyof typeof TemplateEntityType) {
		const displayText = TemplateEntityType[entityType];
		this.hideCategory =
			displayText === TemplateEntityType.TrustStatement ||
			displayText === TemplateEntityType.Receipt ||
			displayText === TemplateEntityType.Payment;
	}

	private populateDocumentCategories$() {
		// Get all available Document Categories
		return this.documentCategoryService
			.getDocumentCategoryList({ sortBy: 'name', sortDirection: EnumSortDirection.Asc })
			.pipe(tap(categories => (this.documentCategories = categories.records)));
	}

	generateConflictText(values: DocumentMarginDetailsDto[]) {
		const docByProperty = groupBy(values, x => `${x.sectionNumber},${x.property}`);
		let text = 'Warning: Conflicting margins or paper sizes may lead to text misalignment.';
		text += '<ul>';
		Object.entries(docByProperty).forEach(val => {
			const group = val[0].split(',');
			text += `<li>Section ${Number(group[0]) + 1} of this template uses ${group[1]} <b>${
				val[1][0].value
			}</b>, while the header footer template uses <b>${val[1][1].value}</b></li>`;
		});
		text += '</ul>';

		return text;
	}
}
