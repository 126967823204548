// This file has been generated from UpdateUserDto.cs
// Do not modify this file directly!!
// tslint:disable

import { Moment } from 'moment-timezone';
import { DateFormat, SetDateFormat } from '@common/utils/date-format';

import { SetComplexType, SetSimpleType } from '@common/utils/class-transform';

import { Address } from '../../Contacts/Common/Address';
import { Gender } from '../../Contacts/Common/Gender';
import { CollectionPrimary } from '../../Contacts/Item/CollectionPrimary';
import { PhoneCreateUpdateDto } from '../../Contacts/Item/PhoneCreateUpdateDto';

// LawMaster.Amalthea.Domain.Users.Item.UpdateUserDto
export class UpdateUserDto {

	// FullName
	fullName: string;
	// Addresses
	@SetComplexType(CollectionPrimary)
	addresses: CollectionPrimary<Address>;
	// Phones
	@SetComplexType(CollectionPrimary)
	phones: CollectionPrimary<PhoneCreateUpdateDto>;
	// Emails
	emails: CollectionPrimary<string>;
	// Website
	website: string;
	// Notes
	notes: string;
	// CustomFields
	customFields: { [key: string]: any; };
	// Abn
	abn: string;
	// IgnoreCustomFields
	@SetSimpleType(Boolean)
	ignoreCustomFields: boolean;
	// TenantRegionCode
	tenantRegionCode: string;
	// FirstName
	firstName: string;
	// MiddleName
	middleName: string;
	// LastName
	lastName: string;
	// Birthday
	@SetDateFormat(DateFormat.LocalDate)
	birthday: Moment;
	// Company
	company: string;
	// JobTitle
	jobTitle: string;
	// Title
	title: string;
	// Gender
	gender: keyof typeof Gender;
	// ChargeRate
	chargeRate: number;
	// UserTypeId
	userTypeId: string;

	constructor() {
		this.addresses = new CollectionPrimary<Address>();
		this.phones = new CollectionPrimary<PhoneCreateUpdateDto>();
		this.emails = new CollectionPrimary<string>();
	}
}