<filter-root #theFilter [filter]="request" [listRequestType]="listRequestType" (filterChange)="rootFilterChange($event)">
	<filter-multi-entity-reference-list
		[root]="theFilter"
		name="contactTypes"
		label="Contact Type"
		[items]="contactTypes"
		[showTitleAsPrefix]="true"
	></filter-multi-entity-reference-list>
	<filter-multi-entity-reference-list
		[root]="theFilter"
		name="fieldType"
		label="Field Type"
		[items]="fieldTypes"
		[showTitleAsPrefix]="true"
	></filter-multi-entity-reference-list>
	<filter-checkbox-button [root]="theFilter" name="mandatory" label="Mandatory" [showTitleAsPrefix]="true"></filter-checkbox-button>
	<filter-checkbox-button [root]="theFilter" name="enabled" label="Enabled" [showTitleAsPrefix]="true"></filter-checkbox-button>
	<filter-checkbox-button [root]="theFilter" name="hasDefaultValue" label="Default Value" [showTitleAsPrefix]="true"></filter-checkbox-button>
	<filter-installed-package-button
		[root]="theFilter"
		label="Package"
		name="installationRecordId"
		[featureFlag]="featureFlags.marketplace"
	></filter-installed-package-button>
	<filter-search-button [root]="theFilter" label="Search" name="search" [showTitleAsPrefix]="true"></filter-search-button>
</filter-root>
