import { ReferralReasonListItemDto } from '@common/models/Settings/ReferralReasons/List/ReferralReasonListItemDto';
import { createSelector, MemoizedSelector } from '@ngrx/store';

import { IAppState } from '../../../app.state';

export const selectReferralReasonList = (state: IAppState) => state.referralReasonList;

export const selectReferralReasonListRecords: MemoizedSelector<IAppState, ReferralReasonListItemDto[]> = createSelector(
	selectReferralReasonList,
	state => state?.list?.records || []
);
export const selectReferralReasonListRequest: MemoizedSelector<IAppState, Partial<any>> = createSelector(
	selectReferralReasonList,
	state => state?.request
);
export const selectReferralReasonListPageIndex = createSelector(
	selectReferralReasonList,
	state => state?.list?.pageIndex || 0
);
export const selectReferralReasonListTotalRecords = createSelector(
	selectReferralReasonList,
	state => state?.list?.totalRecords || 0
);
export const selectReferralReasonIsFetching = createSelector(selectReferralReasonList, state => !!state?.isFetching);
