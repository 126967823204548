// This file has been generated from CollectionTaskCustomFieldsDto.cs
// Do not modify this file directly!!
// tslint:disable

import { CustomFieldGroupSelection } from '../Common/CustomFieldGroupSelection';

// LawMaster.Amalthea.Domain.Tasks.Item.CollectionTaskCustomFieldsDto
export class CollectionTaskCustomFieldsDto {

	// SelectedCustomFieldGroups
	selectedCustomFieldGroups: CustomFieldGroupSelection[];

	constructor() {
		this.selectedCustomFieldGroups = [];
	}
}