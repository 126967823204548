import { CurrentUserViewDto } from '@common/models/Users/Item/CurrentUserViewDto';
import { Action, createReducer, on } from '@ngrx/store';

import { loadCurrentUserError, loadCurrentUserSuccess, updateCurrentUserListColumns } from '../actions/current-user.actions';
import { ICurrentUserData } from '../models/current-user-data';

export const initialState: ICurrentUserData = {
	currentUser: null
};

const _currentUserReducer = createReducer(
	initialState,

	on(loadCurrentUserSuccess, (state, { currentUser }) => {
		return { currentUser: currentUser };
	}),

	on(loadCurrentUserError, (state, { error }) => ({ ...state, error: error })),

	on(updateCurrentUserListColumns, (state, { listType, columns }) => {
		if (!state?.currentUser) {
			return { ...state };
		}

		let currentUser: CurrentUserViewDto = { ...state.currentUser };

		if (!currentUser.customizedListColumns) {
			currentUser.customizedListColumns = {};
		} else {
			currentUser.customizedListColumns = { ...currentUser.customizedListColumns };
		}

		currentUser.customizedListColumns[listType] = columns;

		return { currentUser: currentUser };
	})
);

export function currentUserReducer(state: ICurrentUserData, action: Action) {
	return _currentUserReducer(state, action);
}
