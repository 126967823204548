import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { filter, map, switchMap } from 'rxjs';

import { PracticeAreaListItemDto } from '@common/models/Settings/PracticeAreas/List/PracticeAreaListItemDto';
import { PracticeAreaListRequest } from '@common/models/Settings/PracticeAreas/List/PracticeAreaListRequest';
import { DomainError } from '@common/models/Validation/DomainError';
import { NotificationService } from '@common/notification';
import { PracticeAreasService } from '@common/services/settings/practiceareas.service';
import { Store } from '@ngrx/store';

import { IAppState } from 'app/core/state/app.state';
import { PracticeAreaListActions } from 'app/core/state/lists/settings/practice-area-list/practice-area-list.actions';
import {
	selectPracticeAreaIsFetching,
	selectPracticeAreaListRecords,
	selectPracticeAreaListRequest,
	selectPracticeAreaListTotalRecords
} from 'app/core/state/lists/settings/practice-area-list/practice-area-list.selectors';
import { GridViewService } from 'app/services/grid-view.service';
import {
	ActionTypes,
	GenericListStateComponent,
	SelectorTypes
} from 'app/shared/generics/generic.list.state.component';

@Component({
	selector: 'practice-areas',
	styleUrls: ['./practice-areas-list.component.scss'],
	templateUrl: './practice-areas-list.component.html'
})
export class PracticeAreasListComponent
	extends GenericListStateComponent<PracticeAreaListItemDto, PracticeAreaListRequest>
	implements OnInit
{
	get actions(): ActionTypes {
		return {
			init: PracticeAreaListActions.Init,
			load: PracticeAreaListActions.Load,
			setFilters: PracticeAreaListActions.SetFilters,
			setPageIndex: PracticeAreaListActions.SetPageIndex,
			setPageIndexForId: PracticeAreaListActions.SetPageIndexForId,
			setPageSize: PracticeAreaListActions.SetPageSize,
			setSortBy: PracticeAreaListActions.SetSortBy,
			setSortDirection: PracticeAreaListActions.SetSortDirection,
			selected: PracticeAreaListActions.SelectRecords
		};
	}
	get selectors(): SelectorTypes {
		return {
			records: selectPracticeAreaListRecords,
			isFetching: selectPracticeAreaIsFetching,
			request: selectPracticeAreaListRequest,
			totalRecords: selectPracticeAreaListTotalRecords
		};
	}
	constructor(
		store: Store<IAppState>,
		dialog: MatDialog,
		router: Router,
		activatedRoute: ActivatedRoute,
		private practiceAreaService: PracticeAreasService,
		private notifService: NotificationService,
		gridViewService: GridViewService
	) {
		super(
			['name', 'description', 'securityRoles', 'isDisabled', 'action'],
			dialog,
			store,
			router,
			activatedRoute,
			gridViewService
		);
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.FilterBase = { showDisabled: true };
	}

	// Not using displayedColumns because it need to return string[]
	get displayColumns(): Observable<string[]> {
		return this.accessControlInUse().pipe(
			map(accessControlInUse =>
				this.defaultDisplayedColumns.reduce((accumulator, current) => {
					if (!accessControlInUse && current === 'securityRoles') {
						return accumulator;
					}
					return [...accumulator, current];
				}, [])
			)
		);
	}

	deletePracticeArea(row: PracticeAreaListItemDto) {
		this.subscriptions.add(
			this.practiceAreaService.deletePracticeArea(row.id).subscribe({
				next: response => {
					this.notifService.showNotification(`Practice Area '${row.name}' was deleted`);
					this.store.dispatch({ type: PracticeAreaListActions.RemoveRecords, response: response });
				},
				error: (errs: DomainError[]) => this.notifService.showErrors(`Error deleting Practice Area`, errs)
			})
		);
	}

	editPracticeArea(editId: string) {
		this.router.navigate([editId], { relativeTo: this.activatedRoute });
	}

	accessControlTooltip(row: PracticeAreaListItemDto): string {
		const roles = (row.readers ?? []).concat(row.contributors ?? []);
		const distinctRoles = roles.filter((n, i) => roles.indexOf(n) === i).sort();
		return distinctRoles.join('\n');
	}

	getSecurityRoles(row: PracticeAreaListItemDto): string[] {
		const roles = (row.readers ?? []).concat(row.contributors ?? []);
		const distinctRoles = roles.filter((n, i) => roles.indexOf(n) === i).sort();
		return distinctRoles;
	}

	accessControlInUse(): Observable<boolean> {
		return this.store.select(this.selectors.records).pipe(
			map(records => {
				var data = records as PracticeAreaListItemDto[];
				return data.some(
					x => (x.readers && x.readers.length > 0) || (x.contributors && x.contributors.length > 0)
				);
			})
		);
	}

	enablePracticeArea(row: PracticeAreaListItemDto) {
		this.subscriptions.add(
			this.practiceAreaService.enablePracticeArea(row.id).subscribe({
				next: response => {
					this.notifService.showNotification(`Practice Area '${row.name}' was enabled`);
					this.store.dispatch({ type: PracticeAreaListActions.UpdateRecords, response: response });
				},
				error: (errs: DomainError[]) => this.notifService.showErrors(`Error enabling Practice Area`, errs)
			})
		);
	}

	disablePracticeArea(row: PracticeAreaListItemDto) {
		this.subscriptions.add(
			this.notifService
				.showConfirmation(
					`Disable Practice Area - ${row.name}`,
					`<p>Are you sure want to disable the Practice Area <b>${row.name}</b>?</p>`,
					'Proceed',
					'Cancel'
				)
				.pipe(
					filter(Boolean),
					switchMap(() => this.practiceAreaService.disablePracticeArea(row.id))
				)

				.subscribe({
					next: response => {
						this.notifService.showNotification(`Practice Area '${row.name}' was disabled`);
						this.store.dispatch({ type: PracticeAreaListActions.UpdateRecords, response: response });
					},
					error: (errs: DomainError[]) => this.notifService.showErrors(`Error disabling Practice Area`, errs)
				})
		);
	}
}
