// This file has been generated from UserContactReference.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.Domain.Contacts.Common.UserContactReference
export class UserContactReference {

	// Id
	id: string;
	// Name
	name: string;
	// FullName
	fullName: string;
	// UserId
	userId: string;

	constructor() {

	}
}