import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';

import { EMPTY, Observable, of as ObservableOf, of, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';

import { BaseDtoWithTrimmedId } from '@common/models/Common/BaseDtoWithTrimmedId';
import { EntityReference } from '@common/models/Common/EntityReference';
import { lockedAsync, lockedSwitchMap } from '@common/utils/lockUtils';
import AwaitLock from 'await-lock';
import { isString } from 'lodash-es';

@Component({
	selector: 'base-lookup',
	styleUrls: ['./base-lookup.component.scss'],
	templateUrl: 'base-lookup.component.html'
})
export class BaseLookupComponent<TLookupDto extends BaseDtoWithTrimmedId> implements OnInit, OnDestroy {
	@ViewChild('autoCompleteInput', { static: true }) autoCompleteInput: ElementRef;
	@ViewChild('auto', { read: MatAutocomplete }) matAutoComplete: MatAutocomplete;
	@ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;
	@Input() FormControl: FormControl;
	@Input() FontSize: string;
	@Input() HasAutofocus: boolean;
	@Input() DisplayCreatedOption: TLookupDto;
	@Input() Placeholder: string;
	@Input() Hint: string;
	@Input() Required: boolean;
	@Input() disabled: boolean;
	@Input()
	clearable: boolean;
	@Output() EscPressed: EventEmitter<any> = new EventEmitter();
	@Output() Selected: EventEmitter<ILookupReference> = new EventEmitter<ILookupReference>();
	form: FormGroup;
	// the control used for displaying the value, the id will go into FormControl
	inputDisplayCtrl: FormControl = new FormControl();
	options: TLookupDto[];
	noLookupResults = false;
	isSearching = false;
	isTabbed = false;
	isManual = false;
	isSelectedFromInput = false;
	skipNoMatchValidation: boolean;
	selectedValue: TLookupDto;
	oldValue: TLookupDto;
	protected subscription = new Subscription();

	private lock = new AwaitLock();

	private inputProcessing: boolean = false;
	protected canHaveNoMatch: boolean = false;
	protected ignoreNextFormControlChange: boolean = false;
	protected ignoreNextDisplayControlChange: boolean = false;

	clearValue() {
		this.isManual = true;
		this.options = null;
		this.FormControl.setValue(null);
		this.inputDisplayCtrl.setValue(null);
	}

	setValue(dto: TLookupDto) {
		this.isManual = true;
		this.FormControl.setValue(dto.id);
		this.setSelectedValue(dto);
	}

	ngOnInit() {
		this.inputDisplayCtrl.validator = this.FormControl?.validator;

		this.registerInputChangedListener();
		this.registerFormControlChangedListener();

		// set the initial disabled state
		if (this.FormControl.disabled) {
			this.inputDisplayCtrl.disable();
		}

		// update on any changes of disabled state
		this.FormControl.registerOnDisabledChange((disabled: boolean) => {
			if (disabled) {
				this.inputDisplayCtrl.disable();
			} else {
				this.inputDisplayCtrl.enable();
			}
		});

		// trigger the event above to get the description if there is a value pre-populated
		const value = this.FormControl.value;
		if (value && value.hasOwnProperty('id')) {
			this.FormControl.setValue(value.id);
		} else {
			this.FormControl.setValue(value);
		}
	}

	lookup(id: string): Observable<TLookupDto> {
		throw new Error(`'lookup' must be implemented`);
	}

	search(term: string): Observable<TLookupDto[]> {
		throw new Error(`'search' must be implemented`);
	}

	optionHtmlText(input: TLookupDto): string {
		throw new Error(`'optionHtmlText' must be implemented`);
	}

	displayText(value: TLookupDto): string {
		throw new Error(`'displayText' must be implemented`);
	}

	// Virtual method - returns empty if not implemented in inherited class
	getAdditionalInfo(value: TLookupDto): string {
		return '';
	}

	searchIfTermEmpty(): boolean {
		return false;
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	// Handle click on the suggestion
	autocompleteSelected(event: MatAutocompleteSelectedEvent): void {
		const id: string = event.option.value;
		const value = this.options.find(item => item.id === id);
		// update the form controls
		this.setSelectedValue(value);
		// Mark the form as Dirty for nested controls
		this.FormControl.markAsDirty();
	}

	// Emit event on pressing Esc
	escPressed(): void {
		this.EscPressed.emit();
	}

	reset() {
		this.inputDisplayCtrl.setValue(null);
		this.inputDisplayCtrl.markAsUntouched();

		if (!this.FormControl) {
			this.FormControl.setValue(null);
			this.FormControl.markAsUntouched();
		}
	}

	updateValueAndValidity() {
		if (!!this.inputDisplayCtrl) {
			this.inputDisplayCtrl.updateValueAndValidity();
			this.inputDisplayCtrl.markAsTouched();
		}

		if (!!this.FormControl) {
			this.FormControl?.updateValueAndValidity();
			this.FormControl?.markAsTouched();
		}
	}

	inputClicked() {
		// For Overriding
	}

	async inputFocused(event: FocusEvent) {
		await lockedAsync(this.lock, () => {
			(event.target as any).select();
		});
	}

	async tabPressed() {
		await lockedAsync(this.lock, () => {
			if (!!this.options?.length) {
				let option: TLookupDto = null;

				if (this.options.length === 1) {
					// Select single if able
					option = this.options[0];
				} else {
					// Try Selected highlighted option
					const filteredOptions = this.matAutoComplete.options.filter(option => option.active);

					if (filteredOptions?.length === 1) {
						const foundOptions = this.options.filter(option => option.id === filteredOptions[0].value);
						if (foundOptions?.length === 1) {
							option = foundOptions[0];
						}
					}
				}

				if (!!option && (!this.selectedValue ? this.oldValue !== option : this.selectedValue !== option)) {
					this.setSelectedValue(option);
					// Mark the form as Dirty for nested controls
					this.FormControl.markAsDirty();
					this.isTabbed = false;
				} else {
					this.isTabbed = true;
				}
			} else if (!!this.inputProcessing) {
				this.isTabbed = true;
			}
		});
	}

	private registerInputChangedListener() {
		// Subscribe for typing a name in the control and build a list of autocomplete suggestions
		this.subscription.add(
			this.inputDisplayCtrl.valueChanges
				.pipe(
					filter(() => {
						if (!!this.ignoreNextDisplayControlChange) {
							this.ignoreNextDisplayControlChange = false;
							return false;
						}

						return true;
					}),
					lockedSwitchMap(this.lock, (term: string) => {
						this.inputProcessing = true;
						return of(term);
					}),
					debounceTime(500),
					distinctUntilChanged(),
					lockedSwitchMap(this.lock, (term: string) => {
						if (this.isManual) {
							return this.search(term);
						}

						this.isSearching = true;
						this.noLookupResults = false;
						if (!term || !(this.selectedValue && term === this.displayText(this.selectedValue))) {
							// clear out selection
							this.FormControl.setValue(null);
							if (!!term && !this.skipNoMatchValidation) {
								if (!this.canHaveNoMatch) {
									this.FormControl.setErrors({ errorMessage: 'No matches found' });
									this.inputDisplayCtrl.setErrors({ errorMessage: 'No matches found' });
								} else {
									this.FormControl.setErrors({ errorMessage: 'No option selected' });
									this.inputDisplayCtrl.setErrors({ errorMessage: 'No option selected' });
								}
							}
						}
						this.skipNoMatchValidation = false;
						if (term && this.selectedValue && term === this.displayText(this.selectedValue)) {
							this.isSelectedFromInput = true;

							this.FormControl.setValue(this.selectedValue.id);

							this.isSelectedFromInput = false;

							return ObservableOf([this.selectedValue]);
						}
						return !this.searchIfTermEmpty() && !term ? ObservableOf([]) : this.search(term);
					}),
					lockedSwitchMap(this.lock, (next: TLookupDto[]) => {
						this.options = next;

						if (this.isManual) {
							this.isManual = false;
							return EMPTY;
						}

						this.noLookupResults = !next || next.length === 0;
						this.isSearching = false;

						if (!!this.isTabbed && !!this.options?.length && this.options.length === 1) {
							// Select single if able
							this.setSelectedValue(this.options[0]);
						}

						return EMPTY;
					})
				)
				.subscribe(
					() => {
						lockedAsync(this.lock, () => {
							this.inputProcessing = false;
							this.isTabbed = false;
						});
					},
					() => {
						lockedAsync(this.lock, () => {
							this.inputProcessing = false;
							this.isTabbed = false;
						});
					}
				)
		);
	}

	protected formControlChanged(value: TLookupDto) {}

	private registerFormControlChangedListener() {
		// Subscribe to changing the entity ID (bound to the exposed to the parent form) and retrieve the description
		this.subscription.add(
			this.FormControl.valueChanges
				.pipe(
					distinctUntilChanged(),
					filter(val => !this.isSelectedFromInput && !!val && isString(val)),
					switchMap((id: string) => this.lookup(id)),
					tap(val => this.formControlChanged(val)),
					lockedSwitchMap(this.lock, (next: TLookupDto) => {
						this.oldValue = this.selectedValue;
						this.selectedValue = next;
						if (!!this.selectedValue) {
							this.inputDisplayCtrl.setValue(this.displayText(next));
						}

						return EMPTY;
					})
				)
				.subscribe()
		);
	}

	protected setSelectedValue(value: TLookupDto) {
		this.oldValue = this.selectedValue;
		this.selectedValue = value;
		if (!!this.selectedValue || this.searchIfTermEmpty()) {
			// NOTE: there are cases where setting the control value here is not enough to set the form as valid.
			// 		in those cases Selected event (emitted in the next line) must be handled in the caller.
			// This indicates a refactoring might be required with this base-lookup and its usages
			// 		if the issue keeps coming up.
			this.inputDisplayCtrl.setValue(this.displayText(value));

			// Emit event with EntityRef structure
			this.Selected.emit(
				!!value
					? {
							id: value.id,
							name: this.displayText(value),
							additionalInfo: this.getAdditionalInfo(value)
					  }
					: null
			);
		}
	}
}

export interface ILookupReference extends EntityReference {
	additionalInfo: string;
}
