import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { NotificationService } from '@common/notification';
import { capitalize, isNil } from 'lodash-es';

import { AppConfigHelper, IFeatureFlags } from 'app/app.config';

@Component({
	selector: 'app-config-page',
	templateUrl: './app-config-page.component.html',
	styleUrls: ['./app-config-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppConfigPageComponent implements OnInit {
	private _persistedKeys: string[];
	private _currentEnvironment = AppConfigHelper.environment;
	private _model: any;

	get modelKeys(): string[] {
		return Object.keys(this._model);
	}

	constructor(private _cdr: ChangeDetectorRef, private _nofifService: NotificationService) {}

	ngOnInit() {
		const persistence = AppConfigHelper.ReadAppConfigLocalStorage();
		this._model = Object.assign(this.copyDefaults(), persistence);
		this.updatePersistedKeys(persistence);
		this._cdr.detectChanges();
	}

	isKeyPersisted(key: string) {
		return this._persistedKeys.includes(key);
	}

	getDisplayModelValue(key: string): boolean {
		let prop = this._model[key];

		if (isNil(prop)) {
			prop = false;
		}
		// LocalStorage is stored outside our control so just ensure its a boolean here
		else if (typeof prop !== 'boolean') {
			prop = !!prop;
		}

		return prop;
	}

	getKeyDisplayName(key: string) {
		return capitalize(key.split(/(?=[A-Z])/).join(' '));
	}

	toggle(key: string) {
		let value = this._model[key];

		if (isNil(value)) {
			value = true;
		} else if (typeof value === 'boolean') {
			value = !value;
		} else {
			value = false;
		}

		// update page model
		this._model[key] = value;
		this.persistPropertyValue(key, value);

		this._cdr.detectChanges();
	}

	resetValue(key: string) {
		const defaultValue = this.getDefaultModelPropValue(key);
		if (isNil(defaultValue)) {
			delete this._model[key];
			this._nofifService.showNotification(
				`(${this.getKeyDisplayName(key)}) was deleted as there was no default feature flag for this environment`
			);
		} else {
			this._model[key] = defaultValue;
			this._nofifService.showNotification(
				`Reset the "${this.getKeyDisplayName(key)}" for the "${this._currentEnvironment}" environment`
			);
		}

		const persistence = AppConfigHelper.ReadAppConfigLocalStorage() as any;

		delete persistence[key];

		this.setLocalStore(persistence);
	}

	private persistPropertyValue(key: string, value: boolean) {
		const persistence: any = AppConfigHelper.ReadAppConfigLocalStorage() ?? {};

		persistence[key] = value;

		this.setLocalStore(persistence);

		this._nofifService.showNotification(
			`(${value ? 'Enabled' : 'Disabled'}) "${this.getKeyDisplayName(key)}" for the "${
				this._currentEnvironment
			}" environment`
		);
	}

	private updatePersistedKeys(persistence: any) {
		if (!!persistence) this._persistedKeys = Object.keys(persistence);
		else this._persistedKeys = [];
	}

	private copyDefaults(): IFeatureFlags {
		return { ...AppConfigHelper.activeFlags };
	}

	private getDefaultModelPropValue(key: string): boolean {
		const model = this.copyDefaults() as any;
		return model[key];
	}

	private setLocalStore(persistence: any) {
		this.updatePersistedKeys(persistence);
		AppConfigHelper.UpdateAppConfigLocalStorage(persistence);
	}
}
