import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Subscription } from 'rxjs';

import { ContactDeleteInfoDto } from '@common/models/Contacts/Item/ContactDeleteInfoDto';
import { ContactViewDto } from '@common/models/Contacts/Item/ContactViewDto';
import { Stage } from '@common/models/Settings/PracticeAreas/Stage';
import { NotificationService } from '@common/notification';
import { ContactsService } from '@common/services/contacts.service';

import { CustomValidators } from '@common/validation/custom.validators';
import { UserDeleteInfoDto } from '@common/models/Users/Item/UserDeleteInfoDto';
import { UsersService } from '@common/services/settings/users.service';
import { EntityReference } from '@common/models/Common/EntityReference';
import { UserViewDto } from '@common/models/Users/Item/UserViewDto';

@Component({
	styleUrls: ['delete-user-dialog.component.scss'],
	templateUrl: 'delete-user-dialog.component.html'
})
export class DeleteUserDialogComponent implements OnInit, OnDestroy {
	form: FormGroup;
	stages: Stage[];
	userDeleteInfo: UserDeleteInfoDto;

	protected subscription = new Subscription();
	user: UserViewDto;
	contact: EntityReference;

	constructor(
		public dialogRef: MatDialogRef<DeleteUserDialogComponent>,
		private userService: UsersService,
		private notificationService: NotificationService,
		private fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.user = data.user;
		this.contact = data.user.contact;
	}

	ngOnInit() {
		this.form = this.fb.group({
			fullName: [null, CustomValidators.required('Full Name')]
		});

		this.userService.getDeleteInfo(this.user.id).subscribe(
			(userDeleteInfo: UserDeleteInfoDto) => {
				this.userDeleteInfo = userDeleteInfo;
			},
			errors => {
				this.dialogRef.close();
				this.notificationService.showErrors('Error deleting user', errors);
			}
		);
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
