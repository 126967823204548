import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { catchError, filter, switchMap } from 'rxjs/operators';

import { JobListItemDto } from '@common/models/Settings/Jobs/List/JobListItemDto';
import { JobListRequest } from '@common/models/Settings/Jobs/List/JobListRequest';
import { NotificationService } from '@common/notification';
import { BillsService } from '@common/services/bills.service';
import { JobsService } from '@common/services/settings/jobs.service';
import { IJobsData } from '@common/state/models/jobs-data';
import { Store } from '@ngrx/store';

import { GridViewService } from 'app/services/grid-view.service';
import { DownloadProgressDialogComponent } from 'app/shared/documents/list/download-dialog/download-progress-dialog.component';
import { IDownloadDialogData } from 'app/shared/documents/list/download-dialog/IDownloadDialogData';
import { GenericListComponent } from 'app/shared/generics/generic.list.component';

@Component({
	selector: 'job-list',
	styleUrls: ['./job-list.component.scss'],
	templateUrl: 'job-list.component.html'
})
export class JobListComponent extends GenericListComponent<JobListItemDto, JobListRequest> implements OnInit {
	request: Partial<JobListRequest> = {};

	constructor(
		dialog: MatDialog,
		private store: Store<{ jobsData: IJobsData }>,
		router: Router,
		activatedRoute: ActivatedRoute,
		private jobsService: JobsService,
		private notificationService: NotificationService,
		private billsService: BillsService,
		private cdr: ChangeDetectorRef,
		gridViewService: GridViewService
	) {
		super(
			dialog,
			router,
			activatedRoute,
			jobsService,
			[
				'startedUtc',
				'matterNumber',
				'title',
				'userName',
				'status',
				'progressPercentage',
				'progressComment',
				'hasErrors',
				'actions'
			],
			gridViewService
		);
	}

	ngOnInit() {
		this.subscriptions.add(
			this.store
				.select(state => state?.jobsData?.list)
				.subscribe(newList => {
					newList.forEach(element => {
						const match = this.datasource?.response?.records?.find(x => x.id === element.id);
						if (match) {
							const changed = this.updateJobDetails(match, element);
							if (changed) {
								this.cdr.detectChanges();
							}
						}
					});
				})
		);
	}

	private updateJobDetails(source: JobListItemDto, newValues: JobListItemDto): boolean {
		let changed = false;
		if (source.progressPercentage !== newValues.progressPercentage) {
			source.progressPercentage = newValues.progressPercentage;
			changed = true;
		}
		if (source.status !== newValues.status) {
			source.status = newValues.status;
			changed = true;
		}
		if (source.completedUtc !== newValues.completedUtc) {
			source.completedUtc = newValues.completedUtc;
			changed = true;
		}
		if (source.errors !== newValues.errors) {
			source.errors = newValues.errors;
			changed = true;
		}
		if (source.progressComment !== newValues.progressComment) {
			source.progressComment = newValues.progressComment;
			changed = true;
		}
		if (source.targetEntityId !== newValues.targetEntityId) {
			source.targetEntityId = newValues.targetEntityId;
			changed = true;
		}
		return changed;
	}

	getIconName(row: JobListItemDto): string {
		return row?.type === 'BriefPdf' || row?.type === 'InvoicePdf' ? 'file-pdf' : null;
	}

	hasDownload(job: JobListItemDto): boolean {
		return job.type === 'InvoicePdf';
	}

	canDownload(job: JobListItemDto): boolean {
		return job.status === 'Complete' && job.type === 'InvoicePdf';
	}

	canClick(job: JobListItemDto): boolean {
		return (job.status === 'Complete' && job.type === 'BriefPdf' && !!job.targetEntityId) || this.canDownload(job);
	}

	handleClick(job: JobListItemDto) {
		if (!this.canClick(job)) {
			return;
		}

		if (job.type === 'BriefPdf') {
			const url = this.getDocumentUrl(job);

			if (!url) {
				return;
			}

			this.router.navigateByUrl(url);
		} else if (job.type === 'InvoicePdf') {
			this.download(job.id);
		}
	}

	getDocumentUrl(job: JobListItemDto) {
		return !job?.targetEntityId
			? null
			: !job.associatedMatter
			? `/documents?pageIndexForId=${job.targetEntityId}`
			: `/matters/${job.associatedMatter.id}/documents(preview:document/${job.targetEntityId})?pageIndexForId=${job.targetEntityId}&sortBy=lastModifiedDate&sortDirection=desc`;
	}

	canCancel(row: JobListItemDto) {
		return !!row && !row.completedUtc;
	}

	canDeleteJob(row: JobListItemDto) {
		return !!row?.completedUtc;
	}

	cancel(row: JobListItemDto) {
		this.subscriptions.add(
			this.notificationService
				.showConfirmation('Cancel Job', `Are you sure you want to cancel the job?`)
				.pipe(
					filter(Boolean),
					switchMap(() => this.jobsService.cancel(row.id))
				)
				.subscribe(
					() => this.notificationService.showNotification('Job cancel command sent'),
					error => this.notificationService.showErrors('Error cancelling job', error)
				)
		);
	}

	deleteJob(row: JobListItemDto) {
		this.subscriptions.add(
			this.notificationService
				.showConfirmation('Delete Job', `This action is permanent. Are you sure you want to delete the job?`)
				.pipe(
					filter(Boolean),
					switchMap(() => this.jobsService.deleteJob(row.id))
				)
				.subscribe(
					() => {
						this.refreshList();
						this.notificationService.showNotification('Job delete command sent');
					},
					error => this.notificationService.showErrors('Error deleting job', error)
				)
		);
	}

	download(jobId: string): void {
		this.subscriptions.add(
			this.billsService
				.getFileSizeFromJob(jobId)
				.pipe(
					switchMap(sizeDto => {
						const data: IDownloadDialogData = {
							documentIds: [jobId],
							downloadFn: ((id: string) => this.billsService.downloadFromJob(id)).bind(this),
							totalSize: sizeDto.fileSize
						};

						return this.dialog.open(DownloadProgressDialogComponent, { data }).afterClosed();
					}),
					catchError(error => this.notificationService.showErrors('Error Downloading File', error)),
					filter(Boolean)
				)
				.subscribe()
		);
	}
}
