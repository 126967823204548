import { ListResponse } from '@common/models/Generic/ListResponse';
import { TrustListItemDto } from '@common/models/Trust/List/TrustListItemDto';
import { TrustListRequest } from '@common/models/Trust/List/TrustListRequest';
import { DomainError } from '@common/models/Validation/DomainError';

import { AppConfig } from 'app/app.config';

export interface ITrustListState {
	isFetching: boolean;
	list: ListResponse<TrustListItemDto>;
	lastSelectedRecordId: string;
	request: Partial<TrustListRequest>;

	// errors
	error: DomainError;
}

export const initialState: ITrustListState = {
	isFetching: false,
	list: null,
	lastSelectedRecordId: null,
	request: {
		pageSize: AppConfig.PageSize,
		pageIndex: 0,
		pageIndexForId: null,

		sortBy: null,
		sortDirection: null
	},

	error: null
};
