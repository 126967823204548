import { SortDirection } from '@angular/material/sort';

import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';
import { ListResponse } from '@common/models/Generic/ListResponse';
import { TrustListItemDto } from '@common/models/Trust/List/TrustListItemDto';
import { TrustListRequest } from '@common/models/Trust/List/TrustListRequest';
import { DomainError } from '@common/models/Validation/DomainError';
import { createAction, props } from '@ngrx/store';

import { SelectionType } from '../../SelectionType';

export enum TrustListActions {
	Init = '[Trust List] Initialise',
	Load = '[Trust List] Load',
	Loading = '[Trust List] Loading',
	LoadSuccess = '[Trust List] Load Success',
	LoadFailed = '[Trust List] Load Failed',

	SetFilters = '[Trust List] Set Filters',
	SetPageSize = '[Trust List] Set Page Size',
	SetPageIndex = '[Trust List] Set Page Index',
	SetPageIndexForId = '[Trust List] Set Page Index For Id',

	SetSortBy = '[Trust List] Set Sort By',
	SetSortDirection = '[Trust List] Set Sort Direction',

	InsertRecords = '[Trust List] Insert Records',
	UpdateRecords = '[Trust List] Update Records',
	RemoveRecords = '[Trust List] Remove Records',
	SelectRecords = '[Trust List] Select Records'
}

export const init = createAction(TrustListActions.Init);
export const load = createAction(TrustListActions.Load);
export const loading = createAction(TrustListActions.Loading);
export const loadSuccess = createAction(
	TrustListActions.LoadSuccess,
	props<{ response: ListResponse<TrustListItemDto> }>()
);
export const loadFailed = createAction(TrustListActions.LoadFailed, props<{ error: DomainError }>());

export const setFilters = createAction(TrustListActions.SetFilters, props<{ filter: Partial<TrustListRequest> }>());
export const setPageSize = createAction(TrustListActions.SetPageSize, props<{ pageSize: number }>());
export const setPageIndex = createAction(TrustListActions.SetPageIndex, props<{ pageIndex: number }>());
export const setPageIndexForId = createAction(TrustListActions.SetPageIndexForId, props<{ id: string }>());

export const setSortBy = createAction(TrustListActions.SetSortBy, props<{ sortBy: string }>());
export const setSortDirection = createAction(
	TrustListActions.SetSortDirection,
	props<{ sortDirection: SortDirection }>()
);

export const insertRecords = createAction(
	TrustListActions.InsertRecords,
	props<{ response: MutationResponseDto | MutationResponseDto[] }>()
);
export const updateRecords = createAction(
	TrustListActions.UpdateRecords,
	props<{ response: MutationResponseDto | MutationResponseDto[] }>()
);
export const removeRecords = createAction(
	TrustListActions.RemoveRecords,
	props<{ response: MutationResponseDto | MutationResponseDto[] }>()
);
export const selectRecords = createAction(
	TrustListActions.SelectRecords,
	props<{ row: TrustListItemDto; selectionType: SelectionType }>()
);
