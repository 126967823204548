import { Location } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	styleUrls: ['./critical-error-dialog.component.scss'],
	templateUrl: `./critical-error-dialog.component.html`
})
export class CriticalErrorDialogComponent {
	get phoneNumber(): string {
		const octopusValue: string = '#{TechSupportPhoneNumber}';
		return octopusValue.lastIndexOf('#', 0) === 0 ? '1300 135 214' : octopusValue;
	}
	date: Date = new Date(Date.now());
	constructor(private location: Location, @Inject(MAT_DIALOG_DATA) public data: ICriticalErrorDialogParams) {}

	goHome() {
		// Use Angular's navigation to honour the BASE URL
		this.location.go('/');
		// Reload the page with vanilla JavaScript
		location.reload();
	}
}

export interface ICriticalErrorDialogParams {
	html: string;
}
