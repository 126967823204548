<h2 mat-dialog-title class="title">{{ isCreateMode ? 'Create' : 'Edit' }} Cost Code</h2>
<mat-dialog-content [formGroup]="form" class="flex-column">
	<div class="flex-row">
		<mat-form-field>
			<mat-placeholder>
				Name
				<sup class="color-warn">*</sup>
			</mat-placeholder>
			<input #costCodeInput matInput formControlName="name" autocomplete="off" />
			<mat-error>
				<error-messages [for]="form.controls.name"></error-messages>
			</mat-error>
		</mat-form-field>
	</div>
	<div class="description">
		Cost Codes can be defined for Time/Fee, Expense or both.  On creation of Time/Fee or Expense records the specific codes will be displayed to the user
		for selection.
	</div>
	<mat-card>
		<mat-card-title>
			<mat-checkbox formControlName="isTimeCode">Time / Fee</mat-checkbox>
		</mat-card-title>
		<div class="flex-row no-border">
			<mat-radio-group formControlName="timeType" aria-label="Select if this code is chargeable or non-chargeable">
				<mat-radio-button [value]="chargeableKey">Chargeable</mat-radio-button>
				<mat-radio-button [value]="nonChargeableKey">Non-Chargeable</mat-radio-button>
			</mat-radio-group>
			<editable-numeric-value
				placeHolder="Fixed Fee Rate"
				#amount
				[control]="form.controls.chargeRate"
				[isCurrency]="true"
				[editMode]="true"
				tooltip=""
			></editable-numeric-value>
		</div>
		<div class="description">Note: Fixed Fee records must have a Fixed Fee Rate defined.</div>
	</mat-card>
	<mat-card>
		<mat-card-title>
			<mat-checkbox formControlName="isExpense">Expense</mat-checkbox>
		</mat-card-title>
		<div class="flex-row no-border">
			<mat-radio-group formControlName="expenseType" aria-label="Select if this expense includes or excludes GST">
				<mat-radio-button [value]="incGstKey">GST Applicable</mat-radio-button>
				<mat-radio-button [value]="exGstKey">GST Free</mat-radio-button>
			</mat-radio-group>
		</div>
	</mat-card>
	<mat-checkbox formControlName="isDisabled">Disabled</mat-checkbox>
</mat-dialog-content>
<mat-dialog-actions>
	<div>
		<button mat-raised-button color="primary" throttleButton (throttledClick)="save()" [disabled]="form.invalid">Ok</button>
		<button mat-raised-button mat-dialog-close>Cancel</button>
	</div>
	<button mat-stroked-button color="warn" throttleButton (throttledClick)="delete()" *ngIf="!isCreateMode">Delete</button>
</mat-dialog-actions>
