import { TenantFeatureStateDto } from '@common/models/Settings/Setting/Item/TenantFeatureStateDto';
import { TenantInformationDto } from '@common/models/Settings/Setting/Item/TenantInformationDto';
import { Action, createReducer, on } from '@ngrx/store';

import { loadTenantError, loadTenantSuccess, updateTaxOption, updateTenantFeatures } from '../actions/tenant.actions';
import { ITenantData } from '../models/tenant-data';

export const initialState: ITenantData = {
	tenantInformation: null
};

const _tenantReducer = createReducer(
	initialState,

	on(loadTenantSuccess, (state, { tenantInformation }) => {
		return { ...state, tenantInformation: tenantInformation };
	}),

	on(loadTenantError, (state, { error }) => ({ ...state, error: error })),

	on(updateTaxOption, (state, { taxConfiguration }) => {
		const newState: TenantInformationDto = { ...state.tenantInformation, taxConfiguration };
		return { ...state, tenantInformation: newState };
	}),

	on(updateTenantFeatures, (state, { featureStates }) => {
		const newFeatures: TenantFeatureStateDto[] = state.tenantInformation.featureStates.map(current => {
			return featureStates.find(x => x.type == current.type) ?? current;
		});
		const newState: TenantInformationDto = { ...state.tenantInformation, featureStates: newFeatures };
		return { ...state, tenantInformation: newState };
	})
);

export function tenantReducer(state: ITenantData, action: Action) {
	return _tenantReducer(state, action);
}
