<h2 mat-dialog-title class="title">Invite User</h2>
<mat-dialog-content [formGroup]="form">
	<p>Send email invitation to {{ data.firstName }} {{ data.lastName }} via the following email address.</p>

	<mat-form-field>
		<mat-placeholder>
			Email Address
			<sup class="color-warn">*</sup>
		</mat-placeholder>
		<input id="invite-email" type="email" formControlName="email" matInput autocomplete="off" />
		<mat-error>
			<error-messages [for]="form.get('email')"></error-messages>
		</mat-error>
	</mat-form-field>

	<p>Alternatively, use this URL to complete registration.</p>

	<mat-form-field>
		<mat-placeholder>Registration URL</mat-placeholder>
		<input #inviteUrl matInput formControlName="registrationUrl" readonly="readonly" />
		<button mat-icon-button matSuffix color="primary" title="Copy URL to clipboard" throttleButton (throttledClick)="copyUrl()">
			<mat-icon>content_copy</mat-icon>
		</button>
	</mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-raised-button color="primary" [mat-dialog-close]="form.value" [disabled]="form.invalid">Send Email</button>
	<button mat-raised-button mat-dialog-close>Close</button>
</mat-dialog-actions>
