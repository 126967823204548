// This file has been generated from XeroInvoiceReferenceVariables.cs
// Do not modify this file directly!!

// LawMaster.Amalthea.Domain.Bills.Common.XeroInvoiceReferenceVariables
export enum XeroInvoiceReferenceVariables {
	LawyerName = 'Lawyer Name',
	LawyerShortName = 'Lawyer Short Name',
	MatterNumber = 'Matter Number',
	MatterTitle = 'Matter Title',
	TrustCreditAmount = 'Trust Credit Amount'
}
