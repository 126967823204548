// This file has been generated from TemplateFieldType.cs
// Do not modify this file directly!!

// LawMaster.Amalthea.Domain.Documents.TemplateDto.TemplateFieldType
export enum TemplateFieldType {
	Unknown = 'Unknown',
	String = 'String',
	Numeric = 'Numeric',
	Decimal = 'Decimal',
	DateTime = 'DateTime',
	Collection = 'Collection'
}
