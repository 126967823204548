import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { EnumSortDirection } from '@common/models/Common/EnumSortDirection';
import { Store } from '@ngrx/store';

import { IAppState } from 'app/core/state/app.state';
import { setFilters } from 'app/core/state/filters/practice-area-filter/practice-area-filter.actions';
import { selectPracticeAreaFilterRecords } from 'app/core/state/filters/practice-area-filter/practice-area-filter.selectors';

import { FilterBaseComponent } from './filter-base.component';

@Component({
	selector: 'filter-practice-area',
	styleUrls: ['./filter-base.component.scss', './filter-practice-area.component.scss'],
	template: `
		<filter-multi-entity-list
			[root]="root"
			[name]="name"
			[label]="label"
			[items]="source$ | async"
			[showTitleAsPrefix]="showTitleAsPrefix"
			[itemTemplate]="itemTemplate"
		></filter-multi-entity-list>

		<ng-template #itemTemplate let-item="item">
			<div class="c-item">
				<span class="c-item__title">{{ item.name }}</span>
				<span class="c-item__disabled" *ngIf="!!item.isDisabled">Disabled</span>
			</div>
		</ng-template>
	`
})
export class FilterPracticeAreaComponent extends FilterBaseComponent implements OnInit, OnDestroy {
	private _showOnlyMyAreas: boolean = true;
	private _showDisabled: boolean;
	private _subscriptions = new Subscription();
	private _isInitialized: boolean;

	@Input()
	set showOnlyMyAreas(value: boolean) {
		this._showOnlyMyAreas = value;

		if (this._isInitialized) {
			this.refresh();
		}
	}

	@Input()
	set showDisabled(value: boolean) {
		this._showDisabled = value;

		if (this._isInitialized) {
			this.refresh();
		}
	}

	source$ = this._store.select(selectPracticeAreaFilterRecords);

	constructor(private _store: Store<IAppState>) {
		super();
	}

	ngOnInit(): void {
		this._isInitialized = true;

		super.ngOnInit();

		this.refresh();
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}

	private refresh() {
		this._store.dispatch(
			setFilters({
				filter: {
					sortBy: 'name',
					sortDirection: EnumSortDirection.Asc,
					showOnlyMyAreas: !!this._showOnlyMyAreas,
					showDisabled: !!this._showDisabled
				}
			})
		);
	}
}
