// This file has been generated from DocumentTagDto.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.Domain.Settings.DocumentTags.Item.DocumentTagDto
export class DocumentTagDto {

	// Id
	id: string;
	// Name
	name: string;
	// PracticeAreaIds
	practiceAreaIds: string[];

	constructor() {

	}
}