import { OnDestroy, OnInit, ViewChild, Directive } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { BaseListItemDto } from '@common/models/Common/BaseListItemDto';
import { GenericHttpService } from '@common/services/generic.http.service';
import { Subscription } from 'rxjs';

// This is a basic version of generic list.
// Gets all the records in TListItemDto[] format without any request parameters.
// Note: Unlike generic.list.component, it does NOT support: paging, filtering, exporting, row selection
@Directive()
export abstract class GenericBasicListComponent<TListItemDto extends BaseListItemDto> implements OnInit, OnDestroy {
	@ViewChild(MatSort, { static: true })
	sort: MatSort;

	protected subscription = new Subscription();
	private _tableDataSource: MatTableDataSource<TListItemDto>;
	private getItemsFunction: Observable<TListItemDto[]>;

	// Displayed columns. Override this property if you need conditional hiding of some columns
	get displayedColumns(): string[] {
		return this.defaultDisplayedColumns;
	}

	get tableDataSource(): MatTableDataSource<TListItemDto> {
		return this._tableDataSource;
	}

	/*
	constructor(protected service: GenericHttpService, protected defaultDisplayedColumns: string[]) {
		this.getItemsFunction = () => this.service.getAll<TListItemDto>();
	}
	*/

	constructor(protected func: Observable<TListItemDto[]>, protected defaultDisplayedColumns: string[]) {
		this.getItemsFunction = func;
	}

	ngOnInit() {
		this.refreshList();
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	refreshList(): void {
		this.subscription.add(
			this.getItemsFunction.subscribe(result => {
				this._tableDataSource = new MatTableDataSource(result);
				this._tableDataSource.sort = this.sort;
			})
		);
	}

	clearSorting(): void {
		this.sort.sort({ id: null, start: null, disableClear: false });
	}

	isColumnVisible(columnName: string): boolean {
		return this.displayedColumns.indexOf(columnName) !== -1;
	}
}
