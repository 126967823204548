import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { forkJoin, Subscription } from 'rxjs';
import { concatMap, map, switchMap, take, tap } from 'rxjs/operators';

import { MatterNumberSource } from '@common/models/Matters/Common/MatterNumberSource';
import { NotificationService } from '@common/notification';
import { MattersService } from '@common/services/matters.service';
import { GeneralSettingsService } from '@common/services/settings/generalsettings.service';
import { CustomValidators } from '@common/validation/custom.validators';
import { Store } from '@ngrx/store';
import * as moment from 'moment-timezone';

import {
	MatterNumberingConfigActions,
	SetMatterNumberingConfigAction
} from 'app/core/state/misc/matter-numbering-config/matter-numbering-config.actions';
import { IMatterNumberingConfigState } from 'app/core/state/misc/matter-numbering-config/matter-numbering-config.state';

import { MatterNumberingEditorComponent } from './matter-numbering-editor.component';

@Component({
	selector: 'matter-numbering-config',
	styleUrls: ['./matter-numbering-config.component.scss'],
	templateUrl: 'matter-numbering-config.component.html'
})
export class MatterNumberingConfigComponent implements OnInit, OnDestroy {
	form: FormGroup;
	private _subscriptions = new Subscription();

	private _originalNextMatterNumberForTenant: number;
	private _originalNextMatterNumberForTenantForYear: number;

	private get originalNextMatterNumber(): number {
		return !!this.usingYearlyCounter
			? this._originalNextMatterNumberForTenantForYear
			: this._originalNextMatterNumberForTenant;
	}

	private set originalNextMatterNumber(value: number) {
		if (!!this.usingYearlyCounter) {
			this._originalNextMatterNumberForTenantForYear = value;
		} else {
			this._originalNextMatterNumberForTenant = value;
		}
	}

	@ViewChild(MatterNumberingEditorComponent)
	nextNumberEditor: MatterNumberingEditorComponent;

	get usingYearlyCounter(): boolean {
		const useYearReferenceFormat: boolean = this.form.get('useYearReferenceFormat').value;
		const useShortYearReferenceFormat: boolean = this.form.get('useShortYearReferenceFormat').value;

		return !!useYearReferenceFormat || !!useShortYearReferenceFormat;
	}

	get isEditingSequence(): boolean {
		return !!this.nextNumberEditor?.isEditMode;
	}

	get numberReferenceFormat(): string {
		const useShortYearReferenceFormat: boolean = this.form.get('useShortYearReferenceFormat').value;

		return this.usingYearlyCounter
			? `${!!useShortYearReferenceFormat ? 'YearShort' : 'YearFull'},Number`
			: 'Number';
	}

	get exampleNumberReferenceFormat(): string {
		const numberReferenceFormat = this.numberReferenceFormat;

		const nextNumber: number = this.form.get('nextNumber').value;
		const numberPadding: number = this.form.get('numberPadding').value;

		const now = moment();

		return numberReferenceFormat
			.replace(',', '-')
			.replace('Number', nextNumber?.toString()?.padStart(numberPadding ?? 0, '0') ?? '')
			.replace('YearFull', now.format('YYYY'))
			.replace('YearShort', now.format('YY'));
	}

	constructor(
		private _fb: FormBuilder,
		private _notificationService: NotificationService,
		private _mattersService: MattersService,
		private _generalSettingsService: GeneralSettingsService,
		private _store: Store<{ matterNumberingConfigData: IMatterNumberingConfigState }>
	) {}

	ngOnInit() {
		this._store.dispatch({ type: MatterNumberingConfigActions.Refresh });

		this.form = this._fb.group({
			nextNumber: null,
			numberPadding: [null, CustomValidators.required('Digits')],
			useYearReferenceFormat: false,
			useShortYearReferenceFormat: false
		});

		this._subscriptions.add(
			forkJoin([
				this._mattersService.getNextMatterNumber({ source: MatterNumberSource.ByTenant }),
				this._mattersService.getNextMatterNumber({ source: MatterNumberSource.ByTenantByYear })
			])
				.pipe(
					concatMap(([nextNumberForTenant, nextNumberForTenantForYear]) =>
						this._store
							.select(state => state.matterNumberingConfigData)
							.pipe(
								take(1),
								map(data => ({
									nextNumberForTenant,
									nextNumberForTenantForYear,
									numberingConfig: data?.dto
								}))
							)
					)
				)
				.subscribe(results => {
					this._originalNextMatterNumberForTenant = results.nextNumberForTenant;
					this._originalNextMatterNumberForTenantForYear = results.nextNumberForTenantForYear;

					this.form.get('nextNumber').setValue(this.originalNextMatterNumber);
					this.form.get('numberPadding').setValue(results.numberingConfig.numberPadding);

					if (
						results.numberingConfig.referenceFormat.includes('YearFull') ||
						results.numberingConfig.referenceFormat.includes('YearShort')
					) {
						this.form.get('useYearReferenceFormat').setValue(true);

						if (results.numberingConfig.referenceFormat.includes('YearShort')) {
							this.form.get('useShortYearReferenceFormat').setValue(true);
						}
					}

					this.nextNumberEditor?.reset();
				})
		);

		this._subscriptions.add(
			this.form.get('numberPadding').valueChanges.subscribe(() => {
				this.form.markAsDirty();
				this.form.updateValueAndValidity();
			})
		);

		this._subscriptions.add(
			this.form.get('nextNumber').valueChanges.subscribe(() => {
				this.form.markAsDirty();
				this.form.updateValueAndValidity();
			})
		);

		this._subscriptions.add(
			this.form.get('useYearReferenceFormat').valueChanges.subscribe(flag => {
				if (!flag) {
					this.form.get('useShortYearReferenceFormat').setValue(false);
				}

				this.form.get('nextNumber').setValue(this.originalNextMatterNumber);
				this.nextNumberEditor?.reset();
			})
		);
	}

	handleSlideToggleChange(key: string, event: any) {
		const control = this.form.get(key);
		control.setValue(event.checked);

		control.markAsTouched();
		control.markAsDirty();
		control.updateValueAndValidity();

		this.form.markAsTouched();
		this.form.markAsDirty();
		this.form.updateValueAndValidity();
	}

	saveChanges() {
		const nextNumber: number = this.form.get('nextNumber').value;
		const numberPadding: number = this.form.get('numberPadding').value;

		let observable = this._generalSettingsService.setMatterNumberingConfig({
			referenceFormat: this.numberReferenceFormat,
			numberPadding: numberPadding
		});

		if (this.originalNextMatterNumber !== nextNumber) {
			observable = observable.pipe(
				switchMap(() => this._mattersService.setNextMatterNumber(nextNumber)),
				tap(() => this.nextNumberEditor.reset())
			);

			this.originalNextMatterNumber = nextNumber;
		}

		this._subscriptions.add(
			observable.subscribe(
				() => {
					this._store.dispatch(
						new SetMatterNumberingConfigAction({
							dto: {
								referenceFormat: this.numberReferenceFormat,
								numberPadding: numberPadding
							}
						})
					);

					this._notificationService.showNotification('Saved matter numbering config');
					this.form.markAsPristine();
				},
				errors => this._notificationService.showErrors('Error saving matter numbering config', errors)
			)
		);
	}

	ngOnDestroy() {
		this._subscriptions.unsubscribe();
	}

	modifySequence() {
		this.nextNumberEditor.startEditing();
	}
}
