import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

import { BaseDocumentTagComponent } from '@common/components/base-document-tag.component';
import { ErrorMessagesComponent } from '@common/components/error-messages.component';
import { CreateContactLookupDialogComponent } from '@common/components/lookups//create-contact-lookup.component';
import { BaseLookupComponent } from '@common/components/lookups/base-lookup.component';
import { ContactLookupComponent } from '@common/components/lookups/contact-lookup.component';
import { SpeechTextAreaComponent } from '@common/components/speech-textarea.component';
import { SharedDirectivesModule } from '@common/directives/shared-directives.module';
import { SharedPipesModule } from '@common/pipes/shared-pipes.module';
import { NgObjectPipesModule } from 'angular-pipes';

import { TrustMediaDetailsComponent } from 'app/create-forms/trust/trust-media-details.component';
import { InfoTrackConnectDialogComponent } from 'app/matter/item/infoTrack/infotrack-connect-dialog.component';
import { InfoTrackConnectComponent } from 'app/matter/item/infoTrack/infotrack-connect.component';

import { PipesModule } from '../pipes/pipes.module';
import { BulkActionsMenuComponent } from './actions/bulk-actions-menu.component';
import { AutocompleteDropdownComponent } from './autocomplete-dropdown/autocomplete-dropdown.component';
import { BriefLookupComponent } from './brief-lookup.component';
import { AsyncButtonComponent } from './buttons/async-button.component';
import { BulletButtonComponent } from './buttons/bullet-button.component';
import { CalculationVariableLookupComponent } from './calculation-variable-lookup.component';
import { ColumnPickerDialogComponent } from './column-picker/column-picker-dialog.component';
import { ContactFilterComponent } from './contact-filter.component';
import { ContactLookupDialogComponent } from './contact-lookup-dialog/contact-lookup-dialog.component';
import { ContactLookupLocalComponent } from './contact-lookup-local.component';
import { CustomFieldGroupLookupComponent } from './custom-field-group-lookup.component';
import { CustomFieldLookupComponent } from './custom-field-lookup.component';
import { CustomFieldSelectionComponent } from './custom-fields/custom-field-selection.component';
import { InputCustomFieldsComponent } from './custom-fields/input-custom-fields.component';
import { DocumentHeaderFooterTemplateLookupComponent } from './document-header-footer-template-lookup.component';
import { DocumentLookupComponent } from './document-lookup.component';
import { DocumentTagComponent } from './document-tag.component';
import { DocumentTemplateLookupComponent } from './document-template-lookup.component';
import { ExportButtonComponent } from './export-button';
import { ExportDialogComponent } from './export-dialog.component';
import { FontLookupComponent } from './font-lookup.component';
import { FontOptionComponent } from './font-option/font-option.component';
import { GridLayoutChangesDialogComponent } from './grid-layout/grid-layout-changes-dialog.component';
import { GroupedLocalLookupComponent } from './grouped-local-lookup.component';
import { InstalledPackageFilterComponent } from './installed-package-filter.component';
import { ItemMenuComponent } from './item-menu';
import { MatSpinnerBtnComponent } from './mat-spinner-btn/mat-spinner-btn.component';
import { MatterLookupComponent } from './matter-lookup.component';
import { PopupDatepickerComponent } from './popup-datepicker.component';
import { QuantitySliderComponent } from './quantity-slider.component';
import { SecurityRoleLookupComponent } from './security-role-lookup.component';
import { TimezoneLookupComponent } from './time-zone-lookup.component';
import { UserLookupComponent } from './user-lookup.component';
import { WorkTimerComponent } from './work-timer/work-timer.component';
import { XeroSyncConfirmDialogComponent } from './xero-sync-confirm-dialog.component';
import { XeroSyncUnqualifiedInvoicesComponent } from './xero-sync-unqualified-invoices.component';

@NgModule({
	declarations: [
		AsyncButtonComponent,
		AutocompleteDropdownComponent,
		BaseDocumentTagComponent,
		BaseLookupComponent,
		BriefLookupComponent,
		BulkActionsMenuComponent,
		ColumnPickerDialogComponent,
		BulletButtonComponent,
		ContactFilterComponent,
		ContactLookupComponent,
		ContactLookupDialogComponent,
		ContactLookupLocalComponent,
		CreateContactLookupDialogComponent,
		CustomFieldLookupComponent,
		CustomFieldGroupLookupComponent,
		DocumentHeaderFooterTemplateLookupComponent,
		DocumentLookupComponent,
		DocumentTagComponent,
		DocumentTemplateLookupComponent,
		ErrorMessagesComponent,
		ExportButtonComponent,
		ExportDialogComponent,
		FontLookupComponent,
		FontOptionComponent,
		GridLayoutChangesDialogComponent,
		GroupedLocalLookupComponent,
		InfoTrackConnectComponent,
		InfoTrackConnectDialogComponent,
		InputCustomFieldsComponent,
		InstalledPackageFilterComponent,
		ItemMenuComponent,
		MatSpinnerBtnComponent,
		MatterLookupComponent,
		PopupDatepickerComponent,
		SecurityRoleLookupComponent,
		SpeechTextAreaComponent,
		TimezoneLookupComponent,
		TrustMediaDetailsComponent,
		UserLookupComponent,
		WorkTimerComponent,
		XeroSyncConfirmDialogComponent,
		XeroSyncUnqualifiedInvoicesComponent,
		CalculationVariableLookupComponent,
		QuantitySliderComponent,
		CustomFieldSelectionComponent
	],
	exports: [
		AsyncButtonComponent,
		AutocompleteDropdownComponent,
		BriefLookupComponent,
		BulletButtonComponent,
		BulkActionsMenuComponent,
		ColumnPickerDialogComponent,
		ContactFilterComponent,
		ContactLookupComponent,
		ContactLookupLocalComponent,
		CustomFieldLookupComponent,
		CustomFieldGroupLookupComponent,
		DocumentHeaderFooterTemplateLookupComponent,
		DocumentLookupComponent,
		DocumentTagComponent,
		DocumentTemplateLookupComponent,
		ErrorMessagesComponent,
		ExportButtonComponent,
		FontLookupComponent,
		FontOptionComponent,
		GridLayoutChangesDialogComponent,
		GroupedLocalLookupComponent,
		InfoTrackConnectComponent,
		InfoTrackConnectDialogComponent,
		InputCustomFieldsComponent,
		InstalledPackageFilterComponent,
		ItemMenuComponent,
		MatAutocompleteModule,
		MatSpinnerBtnComponent,
		MatterLookupComponent,
		PopupDatepickerComponent,
		SecurityRoleLookupComponent,
		SpeechTextAreaComponent,
		TimezoneLookupComponent,
		TrustMediaDetailsComponent,
		UserLookupComponent,
		WorkTimerComponent,
		CalculationVariableLookupComponent,
		QuantitySliderComponent,
		CustomFieldSelectionComponent
	],
	imports: [
		DragDropModule,
		ClipboardModule,
		CommonModule,
		FormsModule,
		MatAutocompleteModule,
		MatButtonModule,
		MatCardModule,
		MatCheckboxModule,
		MatChipsModule,
		MatDatepickerModule,
		MatDialogModule,
		MatExpansionModule,
		MatIconModule,
		MatInputModule,
		MatListModule,
		MatMenuModule,
		MatTableModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatRadioModule,
		MatSelectModule,
		MatTabsModule,
		MatTooltipModule,
		NgObjectPipesModule,
		ReactiveFormsModule,
		RouterModule,
		PipesModule,
		SharedPipesModule,
		SharedDirectivesModule
	]
})
export class ComponentsModule {}
