// This file has been generated from ApiKeyCreateUpdateDto.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.Domain.Settings.ApiKeys.Item.ApiKeyCreateUpdateDto
export class ApiKeyCreateUpdateDto {

	// Description
	description: string;

	constructor() {

	}
}