import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { DocumentListItemDto } from '@common/models/Documents/List/DocumentListItemDto';
import { NotificationService } from '@common/notification';
import { DocumentHeaderFooterTemplatesService } from '@common/services/settings/documentheaderfootertemplates.service';
import { TasksService } from '@common/services/tasks.service';
import { Store } from '@ngrx/store';

import { FeatureFlags, isFeatureFlagEnabled } from 'app/app.config';
import { EntityCreatedNotificationService } from 'app/core/entityCreatedNotification.service';
import { IAppState } from 'app/core/state/app.state';
import { DocumentServiceType } from 'app/core/state/lists/document-list/document-list.state';
import { DocumentsEditService } from 'app/services/documents-edit.service';
import { DocumentsService } from 'app/services/documents.service';
import { GridViewService } from 'app/services/grid-view.service';
import { DocumentListBaseComponent } from 'app/shared/documents/list/document-list-base.component';
import { DocumentListItemExtDto } from 'app/shared/documents/list/DocumentListItemExtDto';
import { FileInfo } from 'app/shared/multiple-file-uploader';

import { DeleteHeaderFooterTemplateDialogComponent } from '../delete-header-footer-template-dialog.component';
import { HeaderFooterTemplatePropertiesComponent } from '../header-footer-template-properties.component';

@Component({
	selector: 'document-header-footer-template-list',
	styleUrls: ['./document-header-footer-template-list.component.scss'],
	templateUrl: 'document-header-footer-template-list.component.html'
})
export class DocumentHeaderFooterTemplateListComponent extends DocumentListBaseComponent<DocumentListItemExtDto> {
	constructor(
		dialog: MatDialog,
		router: Router,
		activatedRoute: ActivatedRoute,
		notification: NotificationService,
		docService: DocumentsService,
		private docListService: DocumentHeaderFooterTemplatesService,
		documentsEditService: DocumentsEditService<DocumentListItemExtDto>,
		@Inject(DOCUMENT) document: Document,
		entityCreationNotifService: EntityCreatedNotificationService,
		gridViewService: GridViewService,
		store: Store<IAppState>,
		taskService: TasksService
	) {
		super(
			DocumentHeaderFooterTemplateListComponent.filterColumns([
				'createdDate',
				'title',
				'documentTags',
				'lastModifiedBy',
				'installationRecordTitle',
				'action'
			]),
			dialog,
			router,
			activatedRoute,
			gridViewService,
			store,
			notification,
			docService,
			entityCreationNotifService,
			document,
			documentsEditService,
			taskService
		);
	}

	ngOnInit(): void {
		this.serviceType = DocumentServiceType.HeaderFooterTemplate;

		super.ngOnInit();

		this.data$ = this.data$.pipe(
			map(documents =>
				documents.map(document => {
					const icon = this.getFileIcon(document.fileExtension);

					return {
						...document,
						icon: icon,
						class: icon
					};
				})
			)
		);
	}

	editDetails(document: DocumentListItemDto): void {
		const data = { id: document.id };

		this.subscriptions.add(
			this.dialog
				.open(HeaderFooterTemplatePropertiesComponent, { data })
				.afterClosed()
				.pipe(filter(Boolean))
				.subscribe()
		);
	}

	deleteDocument(document: DocumentListItemExtDto): void {
		this.subscriptions.add(
			this.docListService
				.getTemplatesAssociatedWithHeaderFooters(document.id)
				.pipe(
					switchMap(documents => {
						if (!!documents?.length) {
							const data = { affectedDocuments: documents };

							return this.dialog
								.open(DeleteHeaderFooterTemplateDialogComponent, { data: data })
								.afterClosed();
						} else {
							return this.notification.showConfirmation(
								'Delete Header Footer Template',
								`Are you sure you want to delete the document "${document.fullFileName}"?`
							);
						}
					}),
					filter(Boolean),
					switchMap(() => this.deleteOperation$(document))
				)
				.subscribe()
		);
	}

	validateProcessFileUploads$(files: FileInfo[]): Observable<boolean> {
		const docxFiles = files.filter(info => info.file.name.endsWith('.docx'));

		if (docxFiles.length === files.length) {
			return of(true);
		}

		this.notification.showError('Upload Error', 'The only supported file type for Header/Footer templates is docx');
		return of(false);
	}

	protected getServiceUrl(): string {
		return 'api/v1/settings/documentheaderfootertemplates/';
	}

	private static filterColumns(columns: string[]) {
		if (!isFeatureFlagEnabled(FeatureFlags.marketplace)) {
			columns = columns.filter(column => column.toLocaleLowerCase() !== 'installationrecordtitle');
		}

		return columns;
	}
}
