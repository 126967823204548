import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { NotificationIcon } from './confirmation-dialog.component';

@Component({
	template: `
		<h2 mat-dialog-title>
			<span *ngIf="hasIcon" class="notification-icon"
				><mat-icon [color]="iconColour">{{ iconName }}</mat-icon></span
			>
			<span> {{ data.title }}</span>
		</h2>

		<mat-dialog-content>
			<div [innerHTML]="data.html"></div>
		</mat-dialog-content>
		<mat-dialog-actions>
			<button mat-raised-button color="primary" [mat-dialog-close]="true">
				{{ data.confirmationText || 'Subscribe' }}
			</button>
			<button mat-raised-button mat-dialog-close>{{ data.cancellationText || 'Cancel' }}</button>
		</mat-dialog-actions>
	`,
	styles: [
		`
			mat-icon.mat-icon {
				font-size: 40px;
				width: 40px;
				height: 40px;
			}

			h2 {
				display: flex;
				flex-direction: column;
				align-items: center;
				padding-bottom: 16px;
				font-size: 28px;
			}

			mat-dialog-content.mat-dialog-content {
				text-align: center;
				font-size: 16px;
				margin-bottom: 16px;
			}
		`
	]
})
export class CallToActionComponent {
	iconName: string = '';
	iconColour: string = '';

	constructor(@Inject(MAT_DIALOG_DATA) public data: ISubscriptionRequiredData) {
		switch (data.icon) {
			case NotificationIcon.Information: {
				this.iconName = 'info';
				this.iconColour = 'accent';
				break;
			}
			case NotificationIcon.Warning: {
				this.iconName = 'warning';
				this.iconColour = 'warn';
				break;
			}

			case NotificationIcon.Error: {
				this.iconName = 'error';
				this.iconColour = 'error';
				break;
			}
			default: {
				this.iconName = '';
				this.iconColour = '';
			}
		}
	}

	get hasIcon(): boolean {
		if (this.data?.icon != NotificationIcon.None) return true;
		return false;
	}
}

export interface ISubscriptionRequiredData {
	title: string;
	html: string;
	confirmationText: string;
	cancellationText: string;
	icon?: NotificationIcon;
}
