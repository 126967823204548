import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { MediaTypeUpdateDto } from '@common/models/Settings/Setting/Item/MediaTypeUpdateDto';

import { CustomValidators } from '@common/validation/custom.validators';

@Component({
	selector: 'media-type-dialog',
	styleUrls: ['./media-type-dialog.component.scss'],
	templateUrl: './media-type-dialog.component.html'
})
export class MediaTypeDialogComponent implements OnInit {
	@ViewChild('mediaTypeNameInput')
	mediaTypeNameInput: ElementRef;

	form: FormGroup;

	constructor(@Inject(MAT_DIALOG_DATA) public data: MediaTypeUpdateDto, private fb: FormBuilder) {}

	ngOnInit() {
		this.form = this.fb.group({
			clearanceDateOffset: [this.data.clearanceDateOffset, [CustomValidators.required('Clearance Days')]],
			id: this.data.id,
			name: this.data.name
		});
	}
}
