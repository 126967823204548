import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { AppcuesService } from '@common/appcues/appcues.service';
import { EntityReference } from '@common/models/Common/EntityReference';
import { ListResponse } from '@common/models/Generic/ListResponse';
import { InstallationRecordReference } from '@common/models/Marketplace/InstallationRecords/Common/InstallationRecordReference';
import { CustomFieldEntityType } from '@common/models/Settings/CustomFields/Common/CustomFieldEntityType';
import { ChangeOrderNumberDto } from '@common/models/Settings/CustomFields/Item/ChangeOrderNumberDto';
import { ContactCustomFieldCreateDto } from '@common/models/Settings/CustomFields/Item/ContactCustomFieldCreateDto';
import { ContactCustomFieldUpdateDto } from '@common/models/Settings/CustomFields/Item/ContactCustomFieldUpdateDto';
import { CustomFieldGroupCreateUpdateDto } from '@common/models/Settings/CustomFields/Item/CustomFieldGroupCreateUpdateDto';
import { DeleteCustomFieldResponseDto } from '@common/models/Settings/CustomFields/Item/DeleteCustomFieldResponseDto';
import { ContactCustomFieldListItemDto } from '@common/models/Settings/CustomFields/List/ContactCustomFieldListItemDto';
import { ContactCustomFieldListRequest } from '@common/models/Settings/CustomFields/List/ContactCustomFieldListRequest';
import { CustomFieldGroupListItemDto } from '@common/models/Settings/CustomFields/List/CustomFieldGroupListItemDto';
import { NotificationService } from '@common/notification';
import { ContactCustomFieldsService } from '@common/services/customfields-contact.service';
import { CustomFieldsGroupService } from '@common/services/customfields-group.service';
import { isEmpty, sortBy } from 'lodash-es';

import { GridViewService } from 'app/services/grid-view.service';
import { FilterChangeProperties } from 'app/shared/filter-controls/filter-change-properties';
import { multirowSelectText } from 'app/shared/utils/stringUtil';
import { BaseCustomFieldListComponent } from 'app/system/custom-fields/base-custom-field-list.component';

import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';
import { Store } from '@ngrx/store';
import { IAppState } from 'app/core/state/app.state';
import { ContactCustomFieldListActions } from 'app/core/state/lists/settings/contact-custom-field-list/contact-custom-field-list.actions';
import {
	selectContactCustomFieldIsFetching,
	selectContactCustomFieldListRecords,
	selectContactCustomFieldListRequest,
	selectContactCustomFieldListTotalRecords
} from 'app/core/state/lists/settings/contact-custom-field-list/contact-custom-field-list.selectors';
import { ActionTypes, SelectorTypes } from 'app/shared/generics/generic.list.state.component';
import { ICreateCustomFieldData } from '../../base-custom-field-dialog.component';
import { CustomFieldGroupDialogComponent } from '../../custom-field-group-dialog.component';
import { ContactCustomFieldDialogComponent } from '../contact-custom-field-dialog/contact-custom-field-dialog.component';
import { ContactCustomFieldListFilterDialogComponent } from '../filter-dialog/contact-custom-field-list-filter-dialog.component';
import { ContactCustomFieldListFilterComponent } from '../filter/contact-custom-field-list-filter.component';

@Component({
	selector: 'contact-custom-field-list',
	styleUrls: ['contact-custom-field-list.component.scss'],
	templateUrl: 'contact-custom-field-list.component.html'
})
export class ContactCustomFieldListComponent
	extends BaseCustomFieldListComponent<
		ContactCustomFieldCreateDto,
		ContactCustomFieldListRequest,
		ContactCustomFieldListItemDto,
		ContactCustomFieldUpdateDto,
		CustomFieldGroupCreateUpdateDto
	>
	implements OnInit
{
	get actions(): ActionTypes {
		return {
			init: ContactCustomFieldListActions.Init,
			load: ContactCustomFieldListActions.Load,
			setFilters: ContactCustomFieldListActions.SetFilters,
			setPageIndex: ContactCustomFieldListActions.SetPageIndex,
			setPageIndexForId: ContactCustomFieldListActions.SetPageIndexForId,
			setPageSize: ContactCustomFieldListActions.SetPageSize,
			setSortBy: ContactCustomFieldListActions.SetSortBy,
			setSortDirection: ContactCustomFieldListActions.SetSortDirection,
			selected: ContactCustomFieldListActions.SelectRecords
		};
	}
	get selectors(): SelectorTypes {
		return {
			records: selectContactCustomFieldListRecords,
			isFetching: selectContactCustomFieldIsFetching,
			request: selectContactCustomFieldListRequest,
			totalRecords: selectContactCustomFieldListTotalRecords
		};
	}

	@ViewChild(ContactCustomFieldListFilterComponent, { static: true })
	filter: ContactCustomFieldListFilterComponent;
	request: Partial<ContactCustomFieldListRequest> = {
		contactTypes: [],
		enabled: null,
		fieldType: [],
		mandatory: null,
		search: null,
		groupId: null,
		installationRecordId: null,
		hasDefaultValue: null
	};
	filterDialog = ContactCustomFieldListFilterDialogComponent;
	@Input() rowSelectionLabel: string = multirowSelectText();
	constructor(
		store: Store<IAppState>,
		private customFieldsService: ContactCustomFieldsService,
		dialog: MatDialog,
		router: Router,
		activatedRoute: ActivatedRoute,
		notificationService: NotificationService,
		private appcuesService: AppcuesService,
		gridViewService: GridViewService,
		private customFieldsGroupService: CustomFieldsGroupService
	) {
		super(
			store,
			[
				'description',
				'contactTypes',
				'customFieldGroups',
				'fieldType',
				'enabled',
				'installationRecords',
				'actions'
			],
			dialog,
			router,
			activatedRoute,
			notificationService,
			gridViewService
		);
	}
	ngOnInit() {
		this.filterComponent = this.filter.root;
		this.refreshGroups();
		this.pageSize = 10000;

		this.subscriptions.add(
			this.filterComponent.filterChange
				.pipe(
					filter(Boolean),
					switchMap((prop: FilterChangeProperties<ContactCustomFieldListRequest>) => {
						if (!prop.filter.groupId) return this.getGroupsListObservable();
						else return of<ListResponse<CustomFieldGroupListItemDto>>();
					})
				)
				.subscribe(response => {
					this.customFieldGroups = sortBy(response.records, 'orderNumber');
				})
		);
		super.ngOnInit();
	}

	ngAfterViewInit(): void {
		super.ngAfterViewInit();
	}

	refreshGroups() {
		this.subscriptions.add(
			this.subscriptions.add(
				this.getGroupsListObservable().subscribe(response => {
					this.customFieldGroups = sortBy(response.records, 'orderNumber');
				})
			)
		);
	}

	getInstalledPackages(packages: InstallationRecordReference[]): InstallationRecordReference[] {
		return packages?.filter(tenantPackage => !!tenantPackage.isInstalled);
	}

	getGroupsListObservable(): Observable<ListResponse<CustomFieldGroupListItemDto>> {
		return this.customFieldsGroupService.getCustomFieldGroupList({
			entityType: 'Contact',
			contactTypes: this.filterComponent.filter?.contactTypes,
			fieldTypes: this.filterComponent.filter?.fieldType,
			enabled: this.filterComponent.filter?.enabled,
			mandatory: this.filterComponent.filter?.mandatory,
			search: this.filterComponent.filter?.search,
			hasDefaultValue: this.filterComponent.filter?.hasDefaultValue
		});
	}

	selectGroup(groupId: string) {
		if (this.selectedGroupId === groupId) return;
		else this.selectedGroupId = groupId;
		this.filterComponent.applyFilter(true);
		this.filterComponent.filter.groupId = this.selectedGroupId;
		this.filterComponent.filter.pageSize = this.pageSize;
		this.filterComponent.resetFilter(this.filterComponent.filter);
	}

	createCustomFieldGroup(): void {
		const data: Partial<CustomFieldGroupCreateUpdateDto> = { entityType: CustomFieldEntityType.Contact };
		super.afterGroupCreateClosed(this.dialog.open(CustomFieldGroupDialogComponent, { data }).afterClosed());
	}

	editCustomFieldGroup(customFieldGroup: CustomFieldGroupListItemDto) {
		const data: Partial<CustomFieldGroupCreateUpdateDto> = {
			name: customFieldGroup.name,
			entityType: 'Contact'
		};
		super.afterGroupEditClosed(
			this.dialog.open(CustomFieldGroupDialogComponent, { data }).afterClosed(),
			customFieldGroup.id
		);
	}

	createCustomField(): void {
		const data: Partial<ICreateCustomFieldData> = { selectedGroupId: this.selectedGroupId };
		super.afterCreateClosed(this.dialog.open(ContactCustomFieldDialogComponent, { data }).afterClosed());
		this.appcuesService.trackEvent('CreatedContactCustomField');
	}
	editCustomField(id: string, openBuilder: boolean = false, builderEntityId: string = null): void {
		const data: Partial<ICreateCustomFieldData> = { id, openBuilder, builderEntityId };
		super.afterEditClosed(this.dialog.open(ContactCustomFieldDialogComponent, { data }).afterClosed(), id);
	}

	protected serviceCreate(data: ContactCustomFieldCreateDto): Observable<MutationResponseDto> {
		return this.customFieldsService.createContactCustomField(data);
	}
	protected serviceUpdate(id: string, data: ContactCustomFieldUpdateDto): Observable<MutationResponseDto> {
		return this.customFieldsService.updateContactCustomField(id, data);
	}

	protected serviceDelete(id: string): Observable<MutationResponseDto> {
		return this.customFieldsService.deleteContactCustomField(id);
	}

	protected validateServiceDelete(id: string): Observable<DeleteCustomFieldResponseDto> {
		return this.customFieldsService.validateContactCustomField(id);
	}
	protected servicePatch(ids: string[], propertyName: string, value: string): Observable<MutationResponseDto> {
		return this.customFieldsService.patchContactCustomFields(ids, { [propertyName]: value });
	}
	protected serviceDeleteGroup(id: string): Observable<MutationResponseDto> {
		return this.customFieldsGroupService.deleteCustomFieldGroup(id);
	}
	protected changeOrderNumber(dto: ChangeOrderNumberDto): Observable<MutationResponseDto> {
		return this.customFieldsService.changeOrderNumber(dto);
	}
	protected changeGroupOrderNumber(sourceId: string, destinationId: string): Observable<MutationResponseDto> {
		return this.customFieldsGroupService.changeGroupOrderNumber(sourceId, destinationId);
	}
	protected serviceCreateGroup(data: CustomFieldGroupCreateUpdateDto): Observable<MutationResponseDto> {
		return this.customFieldsGroupService.createCustomFieldGroup(data);
	}

	protected serviceUpdateGroup(id: string, data: CustomFieldGroupCreateUpdateDto): Observable<EntityReference> {
		return this.customFieldsGroupService.updateCustomFieldGroup(id, data);
	}
	protected serviceAddGroupToFields(groupId: string, ids: string[]): Observable<MutationResponseDto> {
		return ContactCustomFieldListComponent.componentInstance.customFieldsGroupService.addGroupToFields({
			groupId,
			ids
		});
	}
	protected serviceRemoveGroupToFields(groupId: string, ids: string[]): Observable<MutationResponseDto> {
		return ContactCustomFieldListComponent.componentInstance.customFieldsGroupService.removeGroupFromFields({
			groupId,
			ids
		});
	}
	protected serviceMoveFieldsToGroup(groupId: string, ids: string[]): Observable<MutationResponseDto> {
		return ContactCustomFieldListComponent.componentInstance.customFieldsGroupService.moveFieldsToGroup({
			groupId,
			ids
		});
	}
	protected refreshList(dto: MutationResponseDto): void {
		this.store.dispatch({ type: ContactCustomFieldListActions.UpdateRecords, response: dto });
	}
}
