import { ListResponse } from '@common/models/Generic/ListResponse';
import { PracticeAreaListItemDto } from '@common/models/Settings/PracticeAreas/List/PracticeAreaListItemDto';
import { PracticeAreaListRequest } from '@common/models/Settings/PracticeAreas/List/PracticeAreaListRequest';
import { DomainError } from '@common/models/Validation/DomainError';
import { createAction, props } from '@ngrx/store';

export enum PracticeAreaFilterActions {
	InvalidateCache = '[Practice Area Filter] Invalidate Cache',
	Load = '[Practice Area Filter] Load',
	Loading = '[Practice Area Filter] Loading',
	LoadSuccess = '[Practice Area Filter] Load Success',
	LoadFailed = '[Practice Area Filter] Load Failed',

	SetFilters = '[Practice Area Filter] Set Filters'
}

export const load = createAction(PracticeAreaFilterActions.Load);
export const loading = createAction(PracticeAreaFilterActions.Loading);
export const loadSuccess = createAction(
	PracticeAreaFilterActions.LoadSuccess,
	props<{ response: ListResponse<PracticeAreaListItemDto> }>()
);
export const loadFailed = createAction(PracticeAreaFilterActions.LoadFailed, props<{ error: DomainError }>());

export const setFilters = createAction(
	PracticeAreaFilterActions.SetFilters,
	props<{ filter: Partial<PracticeAreaListRequest> }>()
);

export const invalidateCache = createAction(PracticeAreaFilterActions.InvalidateCache);
