<mat-card>
	<div class="description">Define the payment terms for your invoices<br /></div>
	<hr />
	<div [formGroup]="form">
		<div class="container">
			<span>Due Date is&nbsp;</span>
			<mat-form-field class="input-field">
				<input matInput formControlName="numberOfDays" autocomplete="off" />
			</mat-form-field>
			<span>&nbsp;days after the issue date of the invoice</span>
		</div>
	</div>
	<div class="action-pane">
		<button mat-raised-button color="primary" [disabled]="form.invalid || form.disabled || form.pristine" throttleButton (throttledClick)="save()">
			Save
		</button>
	</div>
</mat-card>
