// This file has been generated from TaskTemplateUpdateOrderNumberDto.cs
// Do not modify this file directly!!
// tslint:disable

import { TaskTemplateOrderNumber } from './TaskTemplateOrderNumber';

// LawMaster.Amalthea.Domain.Settings.TaskTemplates.Item.TaskTemplateUpdateOrderNumberDto
export class TaskTemplateUpdateOrderNumberDto {

	// TaskTemplateOrdering
	taskTemplateOrdering: TaskTemplateOrderNumber[];

	constructor() {
		this.taskTemplateOrdering = [];
	}
}