import { SortDirection } from '@angular/material/sort';

import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';
import { CostRecordListItemDto } from '@common/models/CostRecords/List/CostRecordListItemDto';
import { CostRecordListRequest } from '@common/models/CostRecords/List/CostRecordListRequest';
import { CostRecordListResponse } from '@common/models/CostRecords/List/CostRecordListResponse';
import { DomainError } from '@common/models/Validation/DomainError';
import { createAction, props } from '@ngrx/store';

import { SelectionType } from '../../SelectionType';

export enum CostListActions {
	Init = '[Cost List] Initialise',
	Load = '[Cost List] Load',
	Loading = '[Cost List] Loading',
	LoadSuccess = '[Cost List] Load Success',
	LoadFailed = '[Cost List] Load Failed',

	SetFilters = '[Cost List] Set Filters',
	SetPageSize = '[Cost List] Set Page Size',
	SetPageIndex = '[Cost List] Set Page Index',
	SetPageIndexForId = '[Cost List] Set Page Index For Id',

	SetSortBy = '[Cost List] Set Sort By',
	SetSortDirection = '[Cost List] Set Sort Direction',

	InsertRecords = '[Cost List] Insert Records',
	UpdateRecords = '[Cost List] Update Records',
	RemoveRecords = '[Cost List] Remove Records',
	SelectRecords = '[Cost List] Select Records'
}

export const init = createAction(CostListActions.Init);
export const load = createAction(CostListActions.Load);
export const loading = createAction(CostListActions.Loading);
export const loadSuccess = createAction(
	CostListActions.LoadSuccess,
	props<{ response: CostRecordListResponse<CostRecordListItemDto> }>()
);
export const loadFailed = createAction(CostListActions.LoadFailed, props<{ error: DomainError }>());

export const setFilters = createAction(CostListActions.SetFilters, props<{ filter: Partial<CostRecordListRequest> }>());
export const setPageSize = createAction(CostListActions.SetPageSize, props<{ pageSize: number }>());
export const setPageIndex = createAction(CostListActions.SetPageIndex, props<{ pageIndex: number }>());
export const setPageIndexForId = createAction(CostListActions.SetPageIndexForId, props<{ id: string }>());

export const setSortBy = createAction(CostListActions.SetSortBy, props<{ sortBy: string }>());
export const setSortDirection = createAction(
	CostListActions.SetSortDirection,
	props<{ sortDirection: SortDirection }>()
);

export const insertRecords = createAction(
	CostListActions.InsertRecords,
	props<{ response: MutationResponseDto | MutationResponseDto[] }>()
);
export const updateRecords = createAction(
	CostListActions.UpdateRecords,
	props<{ response: MutationResponseDto | MutationResponseDto[] }>()
);
export const removeRecords = createAction(
	CostListActions.RemoveRecords,
	props<{ response: MutationResponseDto | MutationResponseDto[] }>()
);
export const selectRecords = createAction(
	CostListActions.SelectRecords,
	props<{ row: CostRecordListItemDto; selectionType: SelectionType }>()
);
