import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { catchError, concat, from, Observable, of, Subscription, tap } from 'rxjs';

import { DownloadStatus, IDownloadFile } from '@common/models/Files/IFileDownloader';
import { DomainError } from '@common/models/Validation/DomainError';
import { NotificationService } from '@common/notification';
import { isNil, isNumber, isString } from 'lodash-es';

import { IDownloadDialogData } from './IDownloadDialogData';

@Component({
	selector: 'download-progress-dialog',
	styleUrls: ['./download-progress-dialog.component.scss'],
	templateUrl: './download-progress-dialog.component.html'
})
export class DownloadProgressDialogComponent implements OnInit {
	fileDownloadStarted: boolean;
	progressPercent: number = 0;
	isDownloadError: boolean;
	errorText: string;
	speed: number = 0;
	timeRemainingHuman: string;

	private subscriptions: Subscription = new Subscription();

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: IDownloadDialogData,
		private dialogRef: MatDialogRef<DownloadProgressDialogComponent>,
		private notificationService: NotificationService
	) {}

	ngOnInit() {
		let previousCompleted: number = 0;
		const observables: Array<Observable<IDownloadFile>> = [];

		if (this.data.downloadParam) {
			observables.push(this.data.downloadFn(this.data.downloadParam));
		} else {
			this.data.documentIds.forEach(id => {
				observables.push(this.data.downloadFn(id));
			});
		}

		this.fileDownloadStarted = true;
		let totalSizeCompleted = 0;
		let totalCountCompleted = 0;

		const startTime: number = new Date().getTime();

		this.subscriptions.add(
			concat(...observables)
				.pipe(
					tap(file => {
						this.data.totalSize = !this.data.totalSize ? file.size : this.data.totalSize;
						if (isNumber(file.progress.data.currentLoaded)) {
							totalSizeCompleted = previousCompleted + file.progress.data.currentLoaded;
							const progressPercent = (totalSizeCompleted * 100) / this.data.totalSize;
							this.progressPercent = Math.min(100, Math.round(progressPercent * 100) / 100);

							const totalTime = (new Date().getTime() - startTime) / 1000; // in seconds
							this.speed = Math.round(file.progress.data.currentLoaded / totalTime);
							if (file.progress.data.currentLoaded > 0 && this.speed > 0) {
								const eta = Math.ceil(
									Math.max(0, this.data.totalSize - file.progress.data.currentLoaded) / this.speed
								);
								this.timeRemainingHuman = this.secondsToHuman(eta);
							}
						}
						if (file.progress.status === DownloadStatus.Completed) {
							if (
								isNil(this.data?.documentIds?.length) ||
								++totalCountCompleted >= this.data.documentIds.length
							) {
								this.dialogRef.close(true);
								if (!this.data.suppressNotification) {
									this.notificationService.showNotification(
										`${this.data.documentIds?.length ?? ''} ${
											this.data.documentIds?.length > 1 ? 'documents' : 'document'
										} downloaded succesfully`
									);
								}
							} else {
								previousCompleted += file.progress.data.currentLoaded;
							}
						}
					}),
					catchError(error => {
						if (isString(error)) this.errorText = error.substring(0, 100);
						else if (error.error && isString(error.error)) this.errorText = error.error;
						else {
							return this.parseErrorBlob$<DomainError[]>(error).pipe(
								tap(result => {
									if (!!result?.length) {
										this.errorText = result[0].message;
									}

									this.isDownloadError = true;
								})
							);
						}

						this.isDownloadError = true;
						return of();
					})
				)
				.subscribe()
		);
	}

	private parseErrorBlob$<T>(err: HttpErrorResponse): Observable<T> {
		return from(this.parseErrorBlobAsync<T>(err));
	}

	private parseErrorBlobAsync<T>(err: HttpErrorResponse): Promise<T> {
		return new Promise<T>(resolve => {
			const reader: FileReader = new FileReader();
			reader.onloadend = _ => {
				const json = JSON.parse(reader.result as string);
				resolve(json);
			};

			reader.onerror = _ => resolve(null);

			try {
				reader.readAsText(err.error);
			} catch {
				resolve(null);
			}
		});
	}

	cancelDownloads() {
		this.subscriptions.unsubscribe();
		this.dialogRef.close();
	}

	private secondsToHuman(sec: number): string {
		return new Date(sec * 1000).toISOString().substr(11, 8);
	}
}
