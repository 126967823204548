<h2 mat-dialog-title class="title">{{ isCreateMode ? 'Add' : 'Edit' }} Team Member</h2>
<mat-dialog-content [formGroup]="form" class="flex-column">
	<contact-lookup [Required]="true" [StaffOnly]="true" [FormControl]="form.get('contactId')" Placeholder="Team Member"></contact-lookup>

	<div class="flex-row">
		<editable-selection
			placeHolder="User Type"
			[control]="form.get('userTypeId')"
			[options]="userTypes"
			optionValue="id"
			optionName="name"
			[editMode]="true"
			hintText="Used when the matters costing method is set to 'Cost Template'"
			>x</editable-selection
		>
		<editable-numeric-value
			placeHolder="Charge Rate $/hr"
			[control]="form.get('rate')"
			[isCurrency]="true"
			[editMode]="true"
			hintText="Used when the matter costing method is set to 'Time'"
		></editable-numeric-value>
	</div>

	<div class="flex-row" *ngIf="!!form.get('userTypeId')?.value">
		<mat-checkbox formControlName="isMain">Is Main Team Member</mat-checkbox>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-raised-button color="primary" throttleButton (throttledClick)="save()" [disabled]="form.invalid">Ok</button>
	<button mat-raised-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
