import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Subject } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';

import { GoogleAnalyticsService } from '@common/google-analytics/google-analytics.service';

declare var webkitSpeechRecognition: any;

@Component({
	selector: 'speech-to-textarea',
	styleUrls: ['./speech-textarea.component.scss'],
	templateUrl: './speech-textarea.component.html'
})
export class SpeechTextAreaComponent implements OnInit, OnDestroy {
	@Input()
	HasAutofocus: boolean;
	@Input()
	control: AbstractControl; // FormControl | FormGroup | FormArray;
	@Input()
	placeHolder: string;
	@Input()
	required: boolean;
	get formControl(): FormControl {
		return this.control as FormControl;
	}
	@ViewChild('textAreaInput') textAreaInput: ElementRef<HTMLInputElement>;
	private subscriptions: Subscription = new Subscription();
	recognition: any;
	isSpeechRecognitionOn = false;
	finalTranscript: string = '';
	interimTranscript: string;
	voiceRecongnitionProcessing = new Subject<boolean>();
	recognitionStarted: boolean = false;

	constructor(private googleAnalyticsService: GoogleAnalyticsService) {}

	get isSpeechRecognitionSupported(): boolean {
		return 'webkitSpeechRecognition' in window;
	}

	ngOnInit() {
		this.recognition = this.isSpeechRecognitionSupported ? new webkitSpeechRecognition() : null;
		if (!!this.recognition) {
			this.recognition.interimResults = true;
			this.recognition.lang = 'en-AU';
			this.recognition.continuous = true;

			// Because of a lot of reasons, the speech recognition might fail.
			// Some of the reasons are microphone going idle.
			// Microphone becoming active in the other tab.
			// User denying permissions to a microphone.
			// Microphone not correctly configured.
			// In case user clicks the record button but speech recognition never starts, redirect them to mattero support with debugging steps
			this.recognition.onstart = (event: any) => {
				this.recognitionStarted = true;
			};

			this.recognition.onend = (event: any) => {
				if (this.isSpeechRecognitionOn) this.recognition.start();
			};
			let timer: NodeJS.Timeout;
			this.recognition.onresult = (event: any) => {
				// Create the interim transcript string locally because we don't want it to persist like final transcript
				this.interimTranscript = '';

				// Loop through the results from the speech recognition object.
				for (let i = event.resultIndex; i < event.results.length; ++i) {
					// If the result item is Final, add it to Final Transcript, Else add it to Interim transcript
					if (event.results[i].isFinal) {
						this.finalTranscript += event.results[i][0].transcript + ' ';
						this.control.setValue(this.finalTranscript);
						this.control.enable();
					} else {
						this.interimTranscript += event.results[i][0].transcript;
						this.control.setValue(this.finalTranscript + this.interimTranscript);

						// Disable the control when the speech recognition is in progress. It gets enabled when the final transcript is ready.
						// However in some cases, only interim results are processed and final result is not processed.
						// In those cases, wait for 3 seconds and enable the control anyway.
						this.control.disable();
						clearTimeout(timer);
						timer = setTimeout(() => {
							this.control.enable();
						}, 3000);
					}
				}
			};
		}
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
		this.stop();
	}

	start() {
		this.textAreaInput.nativeElement.focus();
		if (!this.isSpeechRecognitionOn) {
			this.isSpeechRecognitionOn = true;
			this.recognition.start();
		}
		this.googleAnalyticsService.trackSpeechToText();
	}
	stop() {
		if (this.isSpeechRecognitionOn) {
			this.isSpeechRecognitionOn = false;
			this.recognition.stop();
		}
	}

	toggleMic() {
		if (this.isSpeechRecognitionOn) this.stop();
		else this.start();
	}

	onManualTyping(event: KeyboardEvent) {
		this.finalTranscript = (event.target as HTMLInputElement).value;
	}
}
