import { Component, Inject, Injectable, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';

import { Features } from '@common/models/Settings/Modules/Features';
import { TenantFeatureStateDto } from '@common/models/Settings/Setting/Item/TenantFeatureStateDto';
import { ITenantData } from '@common/state/models/tenant-data';
import { Store } from '@ngrx/store';

import { SecurityPermissionService } from '../security-permissions.service';

const countries = require('app/shared/editable-controls/countries.json');

@Injectable({
	providedIn: 'root'
})
@Component({
	selector: 'paywall',
	styleUrls: ['./paywall-dialog.component.scss'],
	templateUrl: 'paywall-dialog.component.html'
})
export class PaywallDialogComponent implements OnInit, OnDestroy {
	private subscriptions = new Subscription();
	tenantFeatures: TenantFeatureStateDto[];
	hasAccessToSettings: boolean = false;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: IPaywallDialogData,
		private _store: Store<{ tenantData: ITenantData }>,
		private router: Router,
		private securityPermissionService: SecurityPermissionService
	) {}

	ngOnInit(): void {
		this.subscriptions.add(
			this._store
				.select(state => state?.tenantData?.tenantInformation?.featureStates)
				.pipe(
					filter(states => !!states?.length),
					first(),
					map(states =>
						states.slice().sort((left, right) => {
							if (left.type < right.type) {
								return -1;
							}

							if (left.type > right.type) {
								return 1;
							}

							return 0;
						})
					)
				)
				.subscribe(states => {
					this.tenantFeatures = states;
				})
		);
		this.hasAccessToSettings = this.securityPermissionService.hasAccessToSettings;
	}

	getFeature(feature: keyof typeof Features) {
		if (!feature) return null;
		return Features[feature];
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	goToUpgrades() {
		this.router.navigate(['system/subscription']);
	}

	getAvailableIn(key: keyof typeof Features) {
		const availableIn = this.tenantFeatures.filter(x => x.type === key)[0]?.availableIn;
		if (!!availableIn) {
			return [availableIn.slice(0, -1).join(', '), availableIn.slice(-1)[0]].join(
				availableIn.length < 2 ? '' : ' and '
			);
		}
		return null;
	}
}

export interface IPaywallDialogData {
	feature: keyof typeof Features;
}
