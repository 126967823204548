import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	template: `
		<h2 mat-dialog-title>
			<span *ngIf="hasIcon" class="notification-icon"
				><mat-icon [color]="iconColour">{{ iconName }}</mat-icon></span
			>
			<span> {{ data.title }}</span>
		</h2>

		<mat-dialog-content [formGroup]="confirmationForm">
			<div [innerHTML]="data.html"></div>
			<br />
			<div *ngIf="data.deleteValidationText && data.deleteValidationValue">
				<div>{{ data.deleteValidationText }}</div>
				<mat-form-field>
					<input
						[placeholder]="data.deleteValidationPlaceHolder"
						matInput
						formControlName="deleteValidationValue"
						autocomplete="off"
					/>
				</mat-form-field>
			</div>
		</mat-dialog-content>
		<mat-dialog-actions>
			<button
				mat-raised-button
				color="primary"
				[mat-dialog-close]="true"
				[disabled]="
					data.deleteValidationText &&
					(!confirmationForm.valid ||
						confirmationForm.get('deleteValidationValue')?.value !== data.deleteValidationValue)
				"
			>
				{{ data.confirmationText || 'Yes' }}
			</button>
			<button mat-raised-button mat-dialog-close>{{ data.cancellationText || 'No' }}</button>
		</mat-dialog-actions>
	`,
	styles: [
		`
			h2 {
				display: flex;
				flex-direction: row;
			}

			span.notification-icon {
				display: flex;
				align-items: center;
				margin-right: 1em;
			}
		`
	]
})
export class ConfirmationDialogComponent {
	static required(fieldName?: string): ValidatorFn {
		return (control: AbstractControl): ValidationErrors => {
			return !control.value ? { customRequired: `${fieldName || 'This field'} must be entered` } : null;
		};
	}
	confirmationForm: FormGroup;
	iconName: string = '';
	iconColour: string = '';

	constructor(@Inject(MAT_DIALOG_DATA) public data: IConfirmationDialogParams, private fb: FormBuilder) {
		this.confirmationForm = this.fb.group({
			deleteValidationValue: [null, ConfirmationDialogComponent.required('Delete Text')]
		});

		switch (data.icon) {
			case NotificationIcon.Information: {
				this.iconName = 'info';
				this.iconColour = 'accent';
				break;
			}
			case NotificationIcon.Warning: {
				this.iconName = 'warning';
				this.iconColour = 'warn';
				break;
			}

			case NotificationIcon.Error: {
				this.iconName = 'error';
				this.iconColour = 'error';
				break;
			}
			default: {
				this.iconName = '';
				this.iconColour = '';
			}
		}
	}

	get hasIcon(): boolean {
		if (this.data?.icon != NotificationIcon.None) return true;
		return false;
	}
}

export interface IConfirmationDialogParams {
	title: string;
	html: string;
	confirmationText: string;
	cancellationText: string;
	deleteValidationText: string;
	deleteValidationValue: string;
	deleteValidationPlaceHolder: string;
	icon: NotificationIcon;
}

export enum NotificationIcon {
	None,
	Information,
	Warning,
	Error
}
