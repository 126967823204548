import { ListResponse } from '@common/models/Generic/ListResponse';
import { TrustAccountListItemDto } from '@common/models/Settings/TrustSettings/TrustAccounts/List/TrustAccountListItemDto';
import { DomainError } from '@common/models/Validation/DomainError';

export interface ITrustAccountListState {
	isFetching: boolean;
	list: ListResponse<TrustAccountListItemDto>;
	lastSelectedRecordId: string;

	// errrors
	error: DomainError;
}

export const initialState: ITrustAccountListState = {
	isFetching: false,
	list: null,
	lastSelectedRecordId: null,

	error: null
};
