import * as moment from 'moment-timezone';

// Parse file name from the response header
export function getFilenameFromHttpHeader(contentDisposition: string): string {
	const result = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
	const fileName = !!result && result.length > 1 ? result[1] : null;

	return !!fileName?.startsWith('"')
		? fileName.endsWith('"')
			? fileName.substring(1, fileName.length - 1)
			: fileName
		: fileName;
}

export function hasIllegalFileNameCharacters(fileName: string): boolean {
	const hasMatch = getIllegalFileNameCharactersExp().test(fileName);
	return hasMatch;
}

export function getIllegalFileNameCharactersExp(): RegExp {
	return /[\x00-\x1f\\?*:\"|/<>]/gi;
}

export function hasIllegalFileNameGlobCharacters(fileName: string): boolean {
	const hasMatch = getIllegalFileNameGlobCharactersExp().test(fileName);
	return hasMatch;
}

export function getIllegalFileNameGlobCharactersExp(): RegExp {
	return /[\x00-\x1f\\?:\"|/<>]/gi;
}

export function getFileExtension(fileName: string): string {
	const index = fileName.lastIndexOf('.');

	if (index > 0) {
		return `${fileName.substring(index, fileName.length)}`;
	}

	return '';
}

function convertDateFormat(res: string) {
	var allowedDateFormats = ['DD/MM/YYYY', 'MM/DD/YYYY'];
	const dateString = res.match(/\d{2}\/\d{2}\/\d{4}/g);
	if (!!dateString?.length && moment(dateString[0], allowedDateFormats, true).isValid()) {
		return res.replace(/\//g, '-');
	}
	return res;
}

export function stripIllegalFileNameCharacters(fileName: string) {
	return fileName.replace(containDatePatternExp(), convertDateFormat).replace(getIllegalFileNameCharactersExp(), '');
}

export function containDatePatternExp(): RegExp {
	return /([^\d]|^)(((0?[1-9])|([12]\d)|(3[01]))\/){2}(19|20)\d\d([^\d]|$)/g;
}
