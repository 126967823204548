// This file has been generated from ReferralReasonDto.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.Domain.Settings.ReferralReasons.Item.ReferralReasonDto
export class ReferralReasonDto {

	// Id
	id: string;
	// Name
	name: string;
	// Description
	description: string;

	constructor() {

	}
}