import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

import { SharedDirectivesModule } from '@common/directives/shared-directives.module';
import { SharedPipesModule } from '@common/pipes/shared-pipes.module';
import { NgMathPipesModule, NgObjectPipesModule } from 'angular-pipes';

import { DirectivesModule } from 'app/directives/directives.module';
import { ComponentsModule } from 'app/shared/components';
import { EditableControlsModule } from 'app/shared/editable-controls';
import { MultipleFileUploaderModule } from 'app/shared/multiple-file-uploader';
import { PipesModule } from 'app/shared/pipes/pipes.module';

import { CreateContactComponent } from './contact/create-contact.component';
import { CreateExpenseComponent } from './expense/create-expense.component';
import { MultiFileAttachmentComponent } from './file-attachment/multi-file-attachment.component';
import { MatterWorkbenchComponent } from './matter-workbench/matter-workbench.component';
import { MatterDialogComponent } from './matter/matter-dialog.component';
import { CreateNoteComponent } from './note/create-note.component';
import { CreateReferralDialogComponent } from './referrals/create-referral-dialog.component';
import { CreateTaskComponent } from './task/create-task.component';
import { CreateTimeRecordComponent } from './time-record/create-time-record.component';
import { DurationFieldComponent } from './time-record/duration-field/duration-field.component';
import { CreateTrustRecordComponent } from './trust/create-trust-record.component';
import { CreateTrustJournalComponent } from './trust/journal/create-trust-journal.component';
import { TrustOpeningBalanceDialogComponent } from './trust/openingBalanceReceipt/trust-opening-balance-receipt-dialog.component';
import { TrustOpeningBalanceReceiptComponent } from './trust/openingBalanceReceipt/trust-opening-balance-receipt.component';
import { MatterAllocationsComponent } from './trust/receiptPayment/matter-allocations.component';
import { TrustReceiptPaymentComponent } from './trust/receiptPayment/trust-receipt-payment.component';

@NgModule({
	declarations: [
		CreateContactComponent,
		CreateNoteComponent,
		CreateExpenseComponent,
		CreateTrustRecordComponent,
		CreateTrustJournalComponent,
		TrustReceiptPaymentComponent,
		TrustOpeningBalanceReceiptComponent,
		TrustOpeningBalanceDialogComponent,
		CreateTaskComponent,
		CreateTimeRecordComponent,
		DurationFieldComponent,
		MultiFileAttachmentComponent,
		MatterAllocationsComponent,
		MatterWorkbenchComponent,
		MatterDialogComponent,
		CreateReferralDialogComponent
	],
	exports: [
		CreateContactComponent,
		CreateNoteComponent,
		CreateExpenseComponent,
		CreateTrustRecordComponent,
		CreateTrustJournalComponent,
		TrustReceiptPaymentComponent,
		TrustOpeningBalanceReceiptComponent,
		TrustOpeningBalanceDialogComponent,
		CreateTaskComponent,
		CreateTimeRecordComponent,
		MultiFileAttachmentComponent,
		MatterAllocationsComponent,
		MatterWorkbenchComponent,
		MatterDialogComponent,
		CreateReferralDialogComponent
	],
	imports: [
		CommonModule,
		ComponentsModule,
		DirectivesModule,
		EditableControlsModule,
		FormsModule,
		MatButtonModule,
		MatCardModule,
		MatCheckboxModule,
		MatDatepickerModule,
		MatDialogModule,
		MatIconModule,
		MatInputModule,
		MatOptionModule,
		MatProgressBarModule,
		MatRadioModule,
		MatSelectModule,
		MatSlideToggleModule,
		MultipleFileUploaderModule,
		NgMathPipesModule,
		NgObjectPipesModule,
		ReactiveFormsModule,
		PipesModule,
		SharedPipesModule,
		RouterModule,
		SharedDirectivesModule,
		MatTableModule,
		MatExpansionModule,
		MatDialogModule,
		MatTooltipModule
	]
})
export class CreateFormsModule {}
