import { Dictionary } from 'lodash';
import { transform } from 'lodash-es';
import * as moment from 'moment-timezone';

import { CustomFieldType } from '../models/Settings/CustomFields/Common/CustomFieldType';
import { GetComplexType } from './class-transform';

const dateformatMetadataKey = Symbol('DateFormat');

// The list of supported .Net date types
export enum DateFormat {
	// The standard System.DateTime structure
	DateTime,
	// NodaTime.LocalDate
	LocalDate
}

// Date format property decorator
export function SetDateFormat(value: DateFormat): PropertyDecorator {
	return (target, property) => {
		Reflect.defineMetadata(dateformatMetadataKey, value, target, property);
	};
}

// Read the date format property decorator to get the format
export function GetDateFormat(target: any, propertyKey: string): DateFormat {
	return Reflect.getMetadata(dateformatMetadataKey, target, propertyKey);
}

// Update all date/moment values to be in the format from the date format property decorations
export function TransformDatesOnObject<T>(type: { new (): T }, input: any): any {
	return transform(input as Dictionary<any>, (res, val, key) => {
		if (Array.isArray(val)) {
			const nestedType = GetComplexType(new type(), key);

			if (!!nestedType) {
				res[key] = val.map(value => TransformDatesOnObject(nestedType, value));
			} else {
				res[key] = val;
			}
		} else {
			if (val === null) res[key] = null;
			else {
				const nestedType = GetComplexType(new type(), key);

				if (!!nestedType) {
					res[key] = TransformDatesOnObject(nestedType, val);
				} else {
					res[key] = TransformDateFromObject(type, key, val);
				}
			}
		}
	});
}

// Update a single value to be in the format from it's date format property decorator
export function TransformDateFromObject<T>(type: { new (): T }, property: string, value: any): any {
	if (moment.isMoment(value) || moment.isDate(value)) {
		// Check which date format we need to use from the list request type decorator
		switch (!!type ? GetDateFormat(new type(), property) : null) {
			case DateFormat.LocalDate:
				value = moment(value).format('YYYY-MM-DD');
				break;
			default:
				value = moment(value).toISOString();
				break;
		}
	}
	return value;
}

// Format values for Custom Fields with type 'Date' (get rid of the time component)
// Parameters:
//     'customFields' - dictionary of Custom Field Values on Contact/Matter
//     'customFieldConfig' - all available custom fields for Contact/Matter
// Returns:
//     Processed dictionary of Custom Field Values
export function ProcessDateCustomFields(
	customFields: { [key: string]: any },
	customFieldConfig: ICustomFieldDataTypeConfig[]
): { [key: string]: any } {
	if (customFields == null || customFieldConfig == null || customFieldConfig.length === 0) {
		return customFields;
	}
	// IDs of Custom Fields with type 'Date'
	const dateFieldIds = customFieldConfig.filter(c => c.fieldType === CustomFieldType.Date).map(c => c.id);

	return Object.entries(customFields).reduce((prev, [key, val]) => {
		// For each 'Date' custom field
		if (dateFieldIds.indexOf(key) > -1 && val != null) {
			// Drop the time component
			val = moment(val).format('YYYY-MM-DD');
		}
		return Object.assign(prev, { [key]: val });
	}, {});
}

export interface ICustomFieldDataTypeConfig {
	id: string;
	fieldType: keyof typeof CustomFieldType;
}
