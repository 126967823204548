<mat-card>
	<section class="page-path"><a [routerLink]="['..']">System</a> / Cost Codes</section>
	<h1>Cost Codes</h1>

	<div class="flex-filter-row">
		<button mat-button color="primary" title="Create New" throttleButton (throttledClick)="createCostCode()">
			<mat-icon class="create-new">add_circle</mat-icon>
			<span>Create New Cost Code</span>
		</button>
		<filter-mobile-span [filterRoot]="filter.root" [filterParams]="request" [filterComponent]="filterDialog"></filter-mobile-span>
	</div>
	<cost-code-list-filter [request]="request"></cost-code-list-filter>

	<mat-table [dataSource]="(isFetching$ | async) ? [] : (data$ | async)" matSort matSortActive="name" matSortDirection="asc">
		<ng-container matColumnDef="name">
			<mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<a class="name" throttleButton (throttledClick)="editCostCode(row)">{{ row.name }}</a>
			</mat-cell>
		</ng-container>
		<ng-container matColumnDef="type">
			<mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
			<mat-cell *matCellDef="let row">
				{{ getRowTimeDescription(row) }}
				<br *ngIf="isExpenseCode(row) && isTimeCode(row)" />
				{{ getRowExpenseDescription(row) }}
			</mat-cell>
		</ng-container>
		<ng-container matColumnDef="chargeRate">
			<mat-header-cell *matHeaderCellDef>Charge Rate</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<div *ngIf="row.chargeRate">
					{{ '$' + row.chargeRate }}
				</div>
			</mat-cell>
		</ng-container>
		<ng-container matColumnDef="isDisabled">
			<mat-header-cell *matHeaderCellDef>Enabled</mat-header-cell>
			<mat-cell *matCellDef="let row">
				{{ !row.isDisabled ? 'Yes' : 'No' }}
			</mat-cell>
		</ng-container>

		<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
		<mat-row
			*matRowDef="let row; columns: displayedColumns"
			throttleButton
			(throttledClick)="selectRow(row, $event)"
			[ngClass]="{ highlight: row.isHighlighted }"
		></mat-row>
	</mat-table>
	<mat-paginator
		[length]="totalRecords$ | async"
		[pageIndex]="pageIndex$ | async"
		[pageSize]="pageSize$ | async"
		[pageSizeOptions]="pageSizeOptions"
		[class.hidden]="(isFetching$ | async) || !(data$ | async)"
	></mat-paginator>
</mat-card>
