import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

import { Subscription } from 'rxjs';

import { DocumentComposeWithAttachmentsDto } from '@common/models/Documents/Item/DocumentComposeWithAttachmentsDto';
import { NotificationService } from '@common/notification';
import { getFileIcon, isPdfConversionSupported } from '@common/utils/file-extensions';

import { DocumentsService } from 'app/services/documents.service';

@Component({
	selector: 'email-documents-dialog',
	styleUrls: ['./email-documents-dialog.component.scss'],
	templateUrl: './email-documents-dialog.component.html'
})
export class EmailDocumentsDialogComponent implements OnInit, OnDestroy {
	private _subscriptions: Subscription = new Subscription();

	private _documents = new MatTableDataSource<IEmailDocumentState>();

	get canConvertAnyToPdf() {
		return !!this._documents.data?.filter(row => !!row.canConvertToPdf)?.length;
	}

	get documents() {
		return this._documents;
	}

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: IEmailDocumentsDialogData,
		private _documentsService: DocumentsService,
		private _notificationService: NotificationService,
		private _dialogReference: MatDialogRef<EmailDocumentsDialogComponent>
	) {}

	ngOnInit(): void {
		this._documents.data = (this.data?.documents as IEmailDocumentState[]) ?? [];

		this._documents.data.forEach(row => {
			row.icon = getFileIcon(row.fileExtension, !!row.hasAttachments);
			row.class = getFileIcon(row.fileExtension);
			row.canConvertToPdf = isPdfConversionSupported(row.fileExtension);
		});
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}

	onEmailClick() {
		const dto: DocumentComposeWithAttachmentsDto = {
			documents:
				this._documents?.data?.map(document => ({
					documentId: document.id,
					convertToPdf: !!document.convertToPdf
				})) ?? []
		};

		this._subscriptions.add(
			this._documentsService.composeEmailWithAttachments(dto).subscribe({
				next: () => {
					this._notificationService.showNotification('Ensure the Outlook Add-in is open and pinned');
					this._dialogReference.close();
				},
				error: error => this._notificationService.showErrors('Error Emailing Documents', error)
			})
		);
	}
}

export interface IEmailDocumentsDialogData {
	documents: IEmailDocument[];
}

export interface IEmailDocument {
	id: string;
	title: string;
	fileExtension: string;
	hasAttachments: boolean;
}

export interface IEmailDocumentState extends IEmailDocument {
	canConvertToPdf: boolean;
	convertToPdf: boolean;
	icon: string;
	class: string;
}
