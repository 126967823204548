import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { BaseDtoWithTrimmedId } from '@common/models/Common/BaseDtoWithTrimmedId';
import { ContactListItemDto } from '@common/models/Contacts/List/ContactListItemDto';
import { ContactLookupDto } from '@common/models/Contacts/Lookup/ContactLookupDto';
import { ContactsService } from '@common/services/contacts.service';
import { arraysEqual } from '@common/utils/arrayUtils';
import { isEmptyOrWhitespace } from '@common/utils/stringUtils';
import { flatten, orderBy } from 'lodash-es';
import { Observable, of as ObservableOf, of } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { BaseLookupComponent } from '@common/components/lookups/base-lookup.component';

@Component({
	selector: 'contact-lookup-local',
	styleUrls: ['./contact-lookup-local.component.scss'],
	templateUrl: './contact-lookup-local.component.html'
})
export class ContactLookupLocalComponent extends BaseLookupComponent<IContactLookupDto> {
	@Input()
	FormControl: FormControl;
	@Input()
	HasAutofocus: boolean;
	@Input()
	Placeholder: string;
	@Input()
	Hint: string;
	@Input()
	Required: boolean;
	@Output()
	IsNoMatch: EventEmitter<void> = new EventEmitter<void>();
	@Input()
	disabled: boolean;
	@Input()
	autocompletePanelWidth: string;
	@Input()
	autoSelectIfOnlyOne: boolean;

	private _allowedContactIds: string[];
	@Input()
	set allowedContactIds(value: string[]) {
		const hasChanged = !arraysEqual(this._allowedContactIds, value);

		this._allowedContactIds = value;

		if (hasChanged) {
			this.refreshContacts();
		}
	}

	private _contacts: ContactListItemDto[];
	private _filteredContacts: IContactLookupDto[];

	private _isFiltering = false;
	private _isRefreshing = true;

	private _onRefresh: EventEmitter<IContactLookupDto[]> = new EventEmitter<ContactLookupDto[]>();

	get contacts() {
		return this._contacts;
	}

	get filteredContacts() {
		return this._filteredContacts;
	}

	constructor(private service: ContactsService) {
		super();
	}

	ngOnInit() {
		super.ngOnInit();

		this.refreshContacts();
	}

	refreshContacts() {
		this._isRefreshing = true;

		this.subscription.add(
			this.service.getContactList({ ids: this._allowedContactIds }).subscribe(list => {
				this._contacts = orderBy(list.records, ['fullName']);

				this._filteredContacts = this.searchContacts(this.inputDisplayCtrl?.value);

				if (!!this._contacts) {
					const options = flatten(Object.values(this._contacts));
					const selectedOption = !!this.FormControl.value
						? options.find(x => x.id === this.FormControl.value)
						: null;
					if (!!selectedOption) {
						this.setSelectedValue(selectedOption);
					} else if (this.autoSelectIfOnlyOne && options.length === 1) {
						this.setValue(options[0]);
					}
				}

				this._isRefreshing = false;
				this._onRefresh.emit(this._contacts);
			})
		);
	}

	clearInput() {
		this.FormControl.setValue(null);
		this.setSelectedValue(null);

		this.FormControl.markAsTouched();
		this.FormControl.markAsDirty();
		this.FormControl.updateValueAndValidity();
	}

	inputChanged(event: any) {
		const inputText = event.target.value;

		this.options = flatten(Object.values(this._contacts));
		if (!this.options.find(x => x.fullName === inputText)) {
			this.IsNoMatch.emit();
		}
	}

	autocompleteSelected(event: MatAutocompleteSelectedEvent): void {
		this._isFiltering = false;

		this.options = flatten(Object.values(this._contacts));
		super.autocompleteSelected(event);

		this._filteredContacts = this.searchContacts(this.inputDisplayCtrl.value);
	}

	lookup(id: string): Observable<IContactLookupDto> {
		if (!!this._contacts) {
			const result = flatten(Object.values(this._contacts)).filter(value => value.id === id);

			this._filteredContacts = this.searchContacts(id, true);

			return !!result?.length ? of(result[0]) : ObservableOf();
		}

		return ObservableOf();
	}

	optionHtmlText(input: IContactLookupDto): string {
		return input?.fullName ?? null;
	}

	displayText(input: IContactLookupDto) {
		return input?.fullName ?? null;
	}

	searchIfTermEmpty(): boolean {
		return true;
	}

	getAvailableContacts(): Observable<IContactLookupDto[]> {
		if (!!this._isRefreshing) {
			return this._onRefresh.pipe(first());
		} else {
			return of(this._contacts);
		}
	}

	hasAvailableContacts(): Observable<boolean> {
		return this.getAvailableContacts().pipe(
			map(templates => {
				if (!templates) {
					return false;
				}

				return !!flatten(Object.values(this._contacts))?.length;
			})
		);
	}

	private searchContacts(termOrId: string, isId = false): IContactLookupDto[] {
		let filtered: IContactLookupDto[];

		if (!!this._isFiltering) {
			if (!!this._contacts) {
				if (!!isEmptyOrWhitespace(termOrId)) {
					filtered = this._contacts;
				} else if (!isId) {
					const lowercaseTerm = termOrId.toLowerCase();

					filtered = this._contacts.filter(value => value.fullName.toLowerCase().includes(lowercaseTerm));
				} else {
					filtered = this._contacts.filter(value => value.id === termOrId);
				}
			}
		} else {
			filtered = this._contacts;
		}

		return Object.keys(filtered).length > 0 ? filtered : null;
	}

	search(term: string): Observable<IContactLookupDto[]> {
		this._isFiltering = true;

		this._filteredContacts = this.searchContacts(term);

		return of(!!this._contacts ? flatten(Object.values(this._contacts)) : []);
	}
}

export interface IContactLookupDto extends BaseDtoWithTrimmedId {
	fullName: string;
}
