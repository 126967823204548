import { Component } from '@angular/core';
import { ImportEntityType } from '@common/models/Imports/Common/ImportEntityType';

@Component({
	selector: 'import-matters',
	styleUrls: ['./import-matters.component.scss'],
	templateUrl: './import-matters.component.html'
})
export class ImportMattersComponent {
	importType = ImportEntityType.Matter;
}
