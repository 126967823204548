import { Component, Inject, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatOption, MatOptionSelectionChange } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelect, MatSelectChange } from '@angular/material/select';

import { combineLatest, empty, from, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { AppcuesService } from '@common/appcues/appcues.service';
import { ApplicationInsightsService } from '@common/application-insights/application-insights.service';
import { EnumSortDirection } from '@common/models/Common/EnumSortDirection';
import { IUploadFile, UploadStatus } from '@common/models/Common/IFileUploader';
import { DocumentBriefViewDto } from '@common/models/DocumentBriefs/Item/DocumentBriefViewDto';
import { TemplateEntityType } from '@common/models/Documents/TemplateDto/TemplateEntityType';
import { MatterStatus } from '@common/models/Matters/Common/MatterStatus';
import { MatterLookupDto } from '@common/models/Matters/Lookup/MatterLookupDto';
import { DocumentRequest } from '@common/models/RequestParameters/DocumentRequest';
import { DocumentTemplateRequest } from '@common/models/RequestParameters/DocumentTemplateRequest';
import { DocumentCategoryListItemDto } from '@common/models/Settings/DocumentCategories/List/DocumentCategoryListItemDto';
import { PracticeAreaListItemDto } from '@common/models/Settings/PracticeAreas/List/PracticeAreaListItemDto';
import { DocumentBriefsService } from '@common/services/documentbriefs.service';
import { DocumentCategoryService } from '@common/services/settings/documentcategory.service';
import { PracticeAreasService } from '@common/services/settings/practiceareas.service';
import { allocateArray } from '@common/utils/arrayUtils';
import { stringToArrayBuffer } from '@common/utils/bufferUtils';
import { getFileIcon } from '@common/utils/file-extensions';
import { CustomValidators } from '@common/validation/custom.validators';
import { Store } from '@ngrx/store';
import { get, isEmpty, isString, reject, uniq } from 'lodash-es';
import * as moment from 'moment-timezone';
import { Moment } from 'moment-timezone';

import { EntityType } from 'app/core/dialog.config';
import { CurrentPageType, ICurrentPageState } from 'app/core/state/misc/current-page/current-page.state';
import { getCurrentPage } from 'app/core/state/misc/current-page/current-page.reducer';
import { DocumentsService } from 'app/services/documents.service';
import { MsgMetadataService } from 'app/services/file-metadata/msg-metadata.service';
import { OfficeXmlMetadataService } from 'app/services/file-metadata/office-xml-metadata.service';
import { PdfMetadataService } from 'app/services/file-metadata/pdf-metadata.service';

import { FileInfo } from '.';
import { DocumentTagComponent } from '../components/document-tag.component';
import { FileValidationService } from './filevalidation.service';
import { IFileDialogData } from './IFileDialogData';
import { processRecords } from 'app/core/state/lists/document-list/document-list.actions';

@Component({
	selector: 'document-confirm-dialog',
	styleUrls: ['./document-confirm-dialog.component.scss'],
	templateUrl: './document-confirm-dialog.component.html'
})
export class DocumentConfirmDialogComponent implements OnInit, OnDestroy {
	@ViewChild('allOption')
	allOption: MatOption;
	@ViewChild('practiceAreaIds')
	selectPracticeAreas: MatSelect;

	serviceURL: string;
	fileList: FileInfo[];
	brief: DocumentBriefViewDto;
	fileUploadStarted: boolean;
	fileUploadCancelled: boolean;
	isAnyUploaded: boolean;
	formData: FormData;
	files: IUploadFile[] = [];
	form: FormGroup;
	practiceAreas: PracticeAreaListItemDto[];
	documentCategories: DocumentCategoryListItemDto[];
	entityTypeKeys = Object.keys(TemplateEntityType) as Array<keyof typeof TemplateEntityType>;

	@ViewChildren(DocumentTagComponent) tagComponents: QueryList<DocumentTagComponent>;

	private isTemplate: boolean;
	private subscriptions: Subscription = new Subscription();
	private uploadedFileCount: number = 0;

	get allTags() {
		return uniq(
			this.tagComponents
				?.map(component => component.ActiveTags ?? [])
				?.reduce((accumlator, next) => accumlator.concat(next)) ?? []
		);
	}

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: IFileDialogData,
		private docService: DocumentsService,
		private dialogRef: MatDialogRef<DocumentConfirmDialogComponent>,
		private fb: FormBuilder,
		private practiceAreaService: PracticeAreasService,
		private documentCategoryService: DocumentCategoryService,
		private fileValidationService: FileValidationService,
		private briefsService: DocumentBriefsService,
		private store: Store<ICurrentPageState>,
		private appInsights: ApplicationInsightsService,
		private officeXmlMetadataService: OfficeXmlMetadataService,
		private pdfMetadataService: PdfMetadataService,
		private msgMetadataService: MsgMetadataService,
		private appcuesService: AppcuesService
	) {
		this.fileList = data.fileList;
		this.serviceURL = data.serviceUrl;
		this.isTemplate = data.isTemplate;

		if (!!data.isEmailTemplate) {
			this.entityTypeKeys = [TemplateEntityType.Matter, TemplateEntityType.Contact];
		}
	}

	getFileCreatedDate(index: number): FormControl {
		return (this.form.controls.createdDates as FormArray).at(index) as FormControl;
	}

	ngOnInit(): void {
		this.form = this.fb.group({
			documentCategoryId: null,
			entityType: [null, CustomValidators.requiredWhen(() => this.isTemplate, 'Related To')],
			practiceAreaIds: null,
			matterId: null,
			documentTagsCommaSeparated: null,
			createdDates: this.fb.array(
				allocateArray(
					this.fileList.length,
					() => new FormControl(null, CustomValidators.required('Created Date'))
				)
			),
			fileName: null,
			sectionId: null,
			briefId: null
		});

		let fileJobs: Observable<IUploadFile>[] = [];

		for (let i = 0; i < this.fileList.length; i++) {
			fileJobs.push(
				this.makeUploadFile(this.fileList[i], i).pipe(
					switchMap(file => {
						this.files.push(file);
						return of(file);
					})
				)
			);
		}

		this.subscriptions.add(
			combineLatest(fileJobs).subscribe(files => {
				files.forEach((file, index) => {
					(this.form.controls.createdDates as FormArray).at(index)?.setValue(file.created ?? moment());
				});
			})
		);

		if (this.isTemplate) {
			// If a single file is uploaded, auto select entity type by file name
			if (this.fileList.length === 1) {
				const fileName = this.fileList[0].file.name.toLowerCase();
				if (fileName.includes('statement')) {
					if (fileName.includes('financial')) {
						this.form.get('entityType').patchValue('FinancialStatement');
					} else if (fileName.includes('trust')) {
						this.form.get('entityType').patchValue('TrustStatement');
					}
				} else if (fileName.includes('brief title')) {
					this.form.get('entityType').patchValue('BriefTitlePage');
				}
			}

			this.subscriptions.add(
				this.practiceAreaService
					.getPracticeAreaList({ sortBy: 'name', sortDirection: EnumSortDirection.Asc })
					.subscribe(next => {
						this.practiceAreas = next.records;
						// By Default 'All' practice areas should be selected
						setTimeout(() => {
							if (this.form.get('entityType').value === EntityType.Matter) {
								this.allOption.select();
							}
						}, 0);
					})
			);
		}

		if (this.data?.briefId) {
			this.subscriptions.add(
				this.briefsService.getDocumentBrief(this.data.briefId).subscribe(brief => {
					this.brief = brief;
				})
			);
			if (this.data?.sectionId) {
				this.form.get('sectionId').patchValue(this.data.sectionId);
			}
		}

		// Get all available Document Categories
		this.subscriptions.add(
			this.documentCategoryService
				.getDocumentCategoryList({ sortBy: 'name', sortDirection: EnumSortDirection.Asc })
				.subscribe(categories => (this.documentCategories = categories.records))
		);

		this.subscriptions.add(
			this.getCurrentOpenMatter()
				.pipe(filter(Boolean))
				.subscribe((page: ICurrentPageState) => {
					this.form.get('matterId').setValue(page.id);
				})
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	uploadFiles(): void {
		this.appcuesService.trackEvent('UploadDocument');
		this.fileUploadStarted = true;
		if (!this.displayCategory) {
			this.form.patchValue({ documentCategoryId: null });
		}
		if (!this.displayPracticeArea) {
			this.form.patchValue({ practiceAreaIds: null });
		}

		// The Timeout here is use to wait document-tag processing ActiveTags
		// The issue is when type tags without separator and click save, the tags is not saved
		setTimeout(() => {
			let index = 0;
			const fileObservables = from(this.files).pipe(
				mergeMap(
					(file: IUploadFile) =>
						this.validateFile(file).pipe(
							switchMap((f: IUploadFile) => {
								this.form.get('documentTagsCommaSeparated').setValue(f.documentTags);
								this.form.get('briefId').setValue(this.data?.briefId);
								this.form.get('fileName').setValue(f.name);

								const request: DocumentRequest = {
									file: null,
									documentTagsCommaSeparated: this.form.value.documentTagsCommaSeparated,
									fileName: this.form.value.fileName,
									created: this.getFileCreatedDate(index++).value,
									briefId: this.data?.briefId,
									entityType: this.form.value.entityType,
									sectionId: this.form.get('sectionId').value
								};

								if (this.isTemplate) {
									(request as DocumentTemplateRequest).practiceAreaIds =
										this.form.value.practiceAreaIds;
									(request as DocumentTemplateRequest).documentCategoryId =
										this.form.value.documentCategoryId;
								}

								return this.docService.uploadMatterDocument(f, this.serviceURL, request);
							}),
							tap(uploadFile => {
								if (uploadFile.progress.status === UploadStatus.Completed) {
									this.isAnyUploaded = true;
									this.uploadedFileCount++;

									if (!!uploadFile.mutation) {
										this.store.dispatch(processRecords({ response: uploadFile.mutation }));
									}
								}
							}),
							catchError((errorResponse: any) => {
								if (isString(errorResponse)) file.errorText = errorResponse.substring(0, 100);
								// handles domain operation errors
								else if (!isEmpty(errorResponse)) file.errorText = errorResponse[0].message;

								file.progress = {
									status: UploadStatus.Error
								};
								this.uploadedFileCount++;
								return empty();
							})
						),
					!!this.data?.briefId ? 1 : 3 // Number of concurrent requests to the server.
					// If we are uploading documents on the brief, this needs to be 1 as multiple
					// documents could change the same brief leading to change vector error
				)
			);

			this.subscriptions.add(fileObservables.subscribe());
		}, 150);
	}

	cancelUploads(): void {
		this.subscriptions.unsubscribe();
		this.fileUploadCancelled = true;
	}

	removeFile(index: number) {
		this.files.splice(index, 1);
		(this.form.controls.createdDates as FormArray).removeAt(index);
		if (this.files && this.files.length === 0) {
			this.dialogRef.close(false);
		}
	}

	makeUploadFile(fileInfo: FileInfo, index: number): Observable<IUploadFile> {
		const file = fileInfo.file;

		const uploadFile: IUploadFile = {
			categoryId: null,
			errorText: null,
			name: this.getFileNameWithoutExtension(file.name),
			nativeFile: file,
			documentTags: [],
			practiceAreaIds: '',
			progress: {
				data: {
					percentage: 0,
					speed: 0,
					startTime: null,
					timeRemaining: null,
					timeRemainingHuman: null
				},
				status: UploadStatus.Queued
			},
			size: file.size,
			type: file.type,
			lastModified: moment(new Date(file.lastModified)),
			created: null,
			extension: '.' + file.name.split('.').pop()
		};

		const fileType = getFileIcon(uploadFile.extension);

		// Blob.arrayBuffer is a newer api but not supported by some previous Safari versions. Fallback to FileReader in that case
		// https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsArrayBuffer
		const buffer = !!file.arrayBuffer ? file.arrayBuffer() : this.blobArrayBuffer(file);

		let observable: Observable<any>;

		switch (fileType) {
			case 'file-pdf':
				observable = this.extractCreatedDateFromPdf(uploadFile, buffer).pipe(
					catchError(() => of({})),
					switchMap(() => this.extractTagsFromPdf(uploadFile, buffer)),
					catchError(() => of({}))
				);
				break;
			case 'file-word':
			case 'file-excel':
			case 'file-powerpoint':
				observable = this.extractCreatedDateFromOfficeDocs(uploadFile, buffer).pipe(
					catchError(() => of({})),
					switchMap(() => this.extractTagsFromOfficeDocs(uploadFile, buffer)),
					catchError(() => of({}))
				);
				break;
			case 'email-outline':
				if (uploadFile.extension.toLowerCase() === '.msg') {
					observable = this.extractCreatedDateFromMsg(uploadFile, buffer).pipe(catchError(() => of({})));
				}
				break;
		}

		if (!!observable) {
			return observable?.pipe(
				map(() => {
					uploadFile.documentTags = uniq((fileInfo.tags ?? []).concat(uploadFile.documentTags ?? []));
					return uploadFile;
				})
			);
		}

		uploadFile.documentTags = uniq((fileInfo.tags ?? []).concat(uploadFile.documentTags ?? []));
		return of(uploadFile);
	}

	extractTagsFromOfficeDocs(uploadFile: IUploadFile, data: ArrayBuffer | Promise<ArrayBuffer>): Observable<any> {
		return of(this.officeXmlMetadataService.isSupported(uploadFile.extension)).pipe(
			switchMap(success => {
				return !success
					? throwError(new Error(`File extension '${uploadFile.extension}' is not supported.`))
					: this.officeXmlMetadataService.readArrayBuffer(data);
			}),
			switchMap(metadata => {
				try {
					const tags = metadata.getFirstMetadataValueAsArray(OfficeXmlMetadataService.TagsKeys);
					uploadFile.documentTags = reject(tags, isEmpty);
					return of({});
				} catch (error) {
					return throwError(error);
				}
			}),
			catchError((error: Error) => {
				this.appInsights.trackException(error);
				return throwError(error);
			})
		);
	}

	extractCreatedDateFromOfficeDocs(
		uploadFile: IUploadFile,
		data: ArrayBuffer | Promise<ArrayBuffer>
	): Observable<any> {
		return of(this.officeXmlMetadataService.isSupported(uploadFile.extension)).pipe(
			switchMap(success => {
				return !success
					? throwError(new Error(`File extension '${uploadFile.extension}' is not supported.`))
					: this.officeXmlMetadataService.readArrayBuffer(data);
			}),
			switchMap(metadata => {
				try {
					const created = metadata.getFirstMetadataValue(OfficeXmlMetadataService.CreatedKeys);

					if (!!created) {
						uploadFile.created = moment(created);
					}

					return of({});
				} catch (error) {
					return throwError(error);
				}
			}),
			catchError((error: Error) => {
				this.appInsights.trackException(error);
				return throwError(error);
			})
		);
	}

	extractTagsFromPdf(uploadFile: IUploadFile, data: ArrayBuffer | Promise<ArrayBuffer>): Observable<any> {
		return of(this.pdfMetadataService.isSupported(uploadFile.extension)).pipe(
			switchMap(success => {
				try {
					return !success
						? throwError(new Error(`File extension '${uploadFile.extension}' is not supported.`))
						: this.pdfMetadataService.readArrayBuffer(data);
				} catch (error) {
					return throwError(error);
				}
			}),
			switchMap(metadata => {
				try {
					const tags = metadata.getFirstMetadataValueAsArray(PdfMetadataService.TagsKeys);
					uploadFile.documentTags = reject(tags, isEmpty);
					return of({});
				} catch (error) {
					return throwError(error);
				}
			}),
			catchError((error: Error) => {
				this.appInsights.trackException(error);
				return throwError(error);
			})
		);
	}

	extractCreatedDateFromPdf(uploadFile: IUploadFile, data: ArrayBuffer | Promise<ArrayBuffer>): Observable<any> {
		return of(this.pdfMetadataService.isSupported(uploadFile.extension)).pipe(
			switchMap(success => {
				try {
					return !success
						? throwError(new Error(`File extension '${uploadFile.extension}' is not supported.`))
						: this.pdfMetadataService.readArrayBuffer(data);
				} catch (error) {
					return throwError(error);
				}
			}),
			switchMap(metadata => {
				try {
					const created = metadata.getFirstMetadataValue(PdfMetadataService.CreatedKeys);

					if (!!created) {
						uploadFile.created = this.convertPdfDateTimeToMoment(created);
					}
					return of({});
				} catch (error) {
					return throwError(error);
				}
			}),
			catchError((error: Error) => {
				this.appInsights.trackException(error);
				return throwError(error);
			})
		);
	}

	extractCreatedDateFromMsg(uploadFile: IUploadFile, data: ArrayBuffer | Promise<ArrayBuffer>) {
		return of(this.msgMetadataService.isSupported(uploadFile.extension)).pipe(
			switchMap(success => {
				return !success
					? throwError(new Error(`File extension '${uploadFile.extension}' is not supported.`))
					: this.msgMetadataService.readArrayBuffer(data);
			}),
			switchMap(metadata => {
				try {
					const created = metadata.getFirstMetadataValue(MsgMetadataService.CreatedKeys);

					if (!!created) {
						uploadFile.created = moment(created);
					}
					return of({});
				} catch (error) {
					return throwError(error);
				}
			}),
			catchError((error: Error) => {
				this.appInsights.trackException(error);
				return throwError(error);
			})
		);
	}

	private convertPdfDateTimeToMoment(dateTime: string): Moment {
		dateTime = dateTime.substring(2);

		const year = dateTime.substring(0, 4);
		const month = dateTime.substring(4, 6);
		const day = dateTime.substring(6, 8);

		const hours = dateTime.substring(8, 10);
		const minutes = dateTime.substring(10, 12);
		const seconds = dateTime.substring(12, 14);

		// Example string: "D:20220921131335+09'30'"
		const exp = /([+-]\d{1,2}([':]\d{2})?)'/;
		const matches = exp.exec(dateTime);
		const offset = !matches ? 'Z' : matches[1].replace("'", ':');

		const dateTimeString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${offset}`;

		return moment(dateTimeString);
	}

	fileIcon(fileName: string): string {
		const exp = /(?:\.([^.]+))?$/;
		const extension = exp.exec(fileName)[0];
		return getFileIcon(extension);
	}

	isUploadCompleted(file: IUploadFile): boolean {
		return get(file, 'progress.status') === UploadStatus.Completed;
	}

	isUploadError(file: IUploadFile): boolean {
		return get(file, 'progress.status') === UploadStatus.Error;
	}

	allOptionSelected(event: MatOptionSelectionChange): void {
		if (event.source.selected) {
			// When selecting the 'All' option, deselect all practice areas
			this.selectPracticeAreas.options.forEach(opt => {
				if (opt.selected && opt.id !== this.allOption.id) {
					opt.deselect();
				}
			});
		} else if (!this.selectPracticeAreas.options.some(opt => opt.selected)) {
			// If nothing is selected, select the 'All' option
			this.allOption.select();
		}
	}

	optionSelected(event: MatOptionSelectionChange): void {
		if (this.allOption.selected && event.source.selected && event.source.id !== this.allOption.id) {
			// When selecting a practice area, deselect the 'All' option
			this.allOption.deselect();
		} else if (!this.selectPracticeAreas.options.some(opt => opt.selected)) {
			// If nothing is selected, select the 'All' option
			this.allOption.select();
		}
	}
	// If a contact is selected, clear the practice areas. If matter is selected set 'All' as selected by default
	entityTypeChanged(event: MatSelectChange): void {
		if (event.value === TemplateEntityType.Contact) {
			this.form.get('practiceAreaIds').setValue(null, { emitModelToViewChange: false });
		} else if (event.value === TemplateEntityType.Matter) {
			setTimeout(() => this.allOption.select(), 0);
		}
	}

	bulkAddTag(tag: string): void {
		this.tagComponents.forEach(component => {
			if (!component.ActiveTags) component.ActiveTags = [];

			if (!component.ActiveTags.includes(tag)) {
				component.ActiveTags.push(tag);
			}
		});
	}

	bulkRemoveTag(tag: string): void {
		this.tagComponents.forEach(component => {
			if (!!component.ActiveTags?.length && component.ActiveTags.indexOf(tag) !== -1) {
				component.ActiveTags.splice(component.ActiveTags.indexOf(tag), 1);
			}
		});
	}

	applyTags() {
		this.tagComponents.forEach(component => {
			component.ActiveTags = this.allTags;
		});
	}

	clearTags() {
		this.tagComponents.forEach(component => {
			component.ActiveTags = [];
		});
	}

	private getFileNameWithoutExtension(fileName: string): string {
		return fileName?.split('.')?.slice(0, -1)?.join('.');
	}

	private getCurrentOpenMatter(): Observable<ICurrentPageState> {
		return this.store
			.select(getCurrentPage)
			.pipe(
				map(page =>
					page.pageType === CurrentPageType.Matter &&
					page.id !== null &&
					(page.lookup as MatterLookupDto).status === MatterStatus.Open
						? page
						: null
				)
			);
	}

	get fileUploadComplete(): boolean {
		return this.files && this.files.length && this.files.length === this.uploadedFileCount;
	}
	get isDocumentTemplate(): boolean {
		return !this.fileUploadStarted && this.isTemplate;
	}
	get displayPracticeArea(): boolean {
		return this.form.get('entityType').value === 'Matter';
	}
	get displayCategory(): boolean {
		const entityType: keyof typeof TemplateEntityType = this.form?.get('entityType')?.value;
		const displayText = TemplateEntityType[entityType];
		return (
			displayText !== TemplateEntityType.TrustStatement &&
			displayText !== TemplateEntityType.Receipt &&
			displayText !== TemplateEntityType.Payment
		);
	}

	private validateFile(file: IUploadFile): Observable<IUploadFile> {
		if (!this.fileValidationService.validFileType(file)) {
			return throwError('Executable files are not allowed');
		}

		if (!this.fileValidationService.validFileSize(file)) {
			return throwError('The file exceeds the maximum size for this browser');
		}

		file.name = file.name?.trim();
		return of(file);
	}

	private blobArrayBuffer(file: Blob): Promise<ArrayBuffer> {
		return new Promise<ArrayBuffer>(resolve => {
			const fileReader = new FileReader();
			fileReader.onload = () => {
				if (typeof fileReader.result === 'string' || fileReader.result instanceof String) {
					const buffer = stringToArrayBuffer(fileReader.result as string);
					resolve(buffer);
				} else {
					resolve(fileReader.result as ArrayBuffer);
				}
			};

			fileReader.readAsArrayBuffer(file);
		});
	}
}
