<div
	[ngClass]="clickCooldown ? 'disable-pointer-events' : ''"
	[matTooltipDisabled]="clickCooldown || !tooltipMessage"
	[matTooltipPosition]="tooltipPosition"
	[matTooltip]="tooltipMessage"
	class="bullet-button-container"
>
	<div class="bullet-button">
		<div class="bullet-body" throttleButton (throttledClick)="bulletClicked($event)">
			<div class="arrow-container">
				<mat-icon>{{ arrowState == 'left' ? 'chevron_left' : 'chevron_right' }} </mat-icon>
			</div>
		</div>
	</div>
</div>
