import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { of, Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { SecuredUrlMappingResponseDto } from '@common/models/InfoTrack/Orders/Item/SecuredUrlMappingResponseDto';
import { SearchCostCodesDto } from '@common/models/Settings/Setting/Item/SearchCostCodesDto';
import { NotificationService } from '@common/notification';
import { GeneralSettingsService } from '@common/services/settings/generalsettings.service';

import { InfoTrackAuthService } from 'app/services/infotrack-auth.service';

@Component({
	selector: 'infotrack-connect',
	template: '<span></span>'
})
export class InfoTrackConnectComponent implements OnDestroy {
	private subscriptions = new Subscription();

	constructor(
		private router: Router,
		private infoTrackAuthService: InfoTrackAuthService,
		private settingsService: GeneralSettingsService,
		private notificationService: NotificationService
	) {}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	connectToInfoTrack(matterNumber: string) {
		this.subscriptions.add(
			this.settingsService
				.getSearchServiceSettings()
				.pipe(
					switchMap((searchCostCodes: SearchCostCodesDto) => {
						if (!searchCostCodes.gstApplicableCostCodeId || !searchCostCodes.gstFreeCostCodeId) {
							this.notificationService.showError(
								'No Cost Codes',
								'No Cost Codes defined for Search expenses. Please <a href="/system/search-settings">click here</a> to define from System Settings.'
							);
						} else {
							return of(true);
						}
						return of(false);
					}),
					filter(Boolean),
					switchMap(() => this.infoTrackAuthService.requestSecuredInfoTrackUrl(matterNumber))
				)
				.subscribe(
					(response: SecuredUrlMappingResponseDto) => {
						if (!!response?.url) {
							window.open(response.url, '_blank');
						} else {
							this.notificationService.showError(
								'Connection error',
								'Cannot reach Infotrack services at this time. Please try again later.'
							);
						}
					},
					() => {
						this.notificationService.showError(
							'No connection',
							'Cannot connect to InfoTrack. Please try again later.'
						);
					}
				)
		);
	}
}
