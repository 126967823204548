<mat-form-field [style.font-size]="FontSize">
	<mat-placeholder>
		{{ Placeholder }}
		<sup class="color-warn" *ngIf="Required">*</sup>
	</mat-placeholder>
	<mat-hint>{{ Hint }}</mat-hint>

	<div class="input-clear-container">
		<input
			matInput
			#autoCompleteInput
			[matAutocomplete]="auto"
			[formControl]="inputDisplayCtrl"
			[value]="inputDisplayCtrl.value"
			(change)="inputChanged($event)"
			[autofocus]="HasAutofocus"
			(keyup.esc)="escPressed()"
			(keydown.tab)="tabPressed()"
			(focus)="inputFocused($event)"
			[attr.disabled]="!!disabled ? true : null"
			autocomplete="off"
		/>
	</div>

	<mat-error>
		<error-messages [for]="FormControl"></error-messages>
	</mat-error>

	<mat-autocomplete class="lookup-results" #auto="matAutocomplete" (optionSelected)="autocompleteSelected($event)" [panelWidth]="autocompletePanelWidth">
		<mat-option class="option" *ngFor="let contact of filteredContacts" [value]="contact.id">
			<div class="contact">
				<span class="contact-title">{{ contact.fullName }}</span>
			</div>
		</mat-option>
		<mat-option class="option" [disabled]="true" *ngIf="inputDisplayCtrl.value && options?.length == 0">
			<div *ngIf="isSearching" class="searching">Searching...</div>
			<div *ngIf="noLookupResults && inputDisplayCtrl.value && options?.length == 0" class="no-matches">No matches found</div>
		</mat-option>
	</mat-autocomplete>
</mat-form-field>
