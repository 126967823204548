// This file has been generated from TemplateEntityType.cs
// Do not modify this file directly!!

// LawMaster.Amalthea.Domain.Documents.TemplateDto.TemplateEntityType
export enum TemplateEntityType {
	Matter = 'Matter',
	Contact = 'Contact',
	Invoice = 'Invoice',
	Deposit = 'Deposit',
	Payment = 'Payment',
	Receipt = 'Receipt',
	TrustStatement = 'Trust Statement',
	BriefTitlePage = 'Brief Title Page',
	FinancialStatement = 'Financial Statement'
}
