<mat-dialog-content>
	<h1>Please confirm details</h1>
	<mat-card>
		<div class="container" [formGroup]="form">
			<div class="plan-name">
				<span class="label"> Plan:</span> <span class="value">{{ data.planName }}</span>
			</div>
			<mat-form-field>
				<mat-placeholder>Name on the Invoice</mat-placeholder>
				<input matInput formControlName="customerName" autocomplete="off" />
				<mat-hint>Enter the name that should appear on the invoice</mat-hint>
				<mat-error>
					<error-messages [for]="form.get('customerName')"></error-messages>
				</mat-error>
			</mat-form-field>

			<mat-form-field>
				<mat-placeholder>ABN Number<sup *ngIf="isABNMandatory" class="color-warn">*</sup></mat-placeholder>
				<input matInput formControlName="abn" autocomplete="off" />
				<mat-hint>Enter the ABN number to be displayed on your {{ siteName }} invoices</mat-hint>
				<mat-error>
					<error-messages [for]="form.get('abn')"></error-messages>
				</mat-error>
			</mat-form-field>
			<mat-form-field class="quantity-field">
				<mat-icon class="decrement-icon" throttleButton (throttledClick)="decrementQuantity()">remove</mat-icon>
				<input matInput type="number" formControlName="numberOfUsers" autocomplete="off" min="1" />
				<mat-icon class="increment-icon" throttleButton (throttledClick)="incrementQuantity()">add</mat-icon>
				<mat-placeholder> Quantity </mat-placeholder>
				<mat-error>
					<error-messages [for]="form.controls.quantity"></error-messages>
				</mat-error>
			</mat-form-field>

			<div class="flex-column" *ngIf="!!user">
				<div class="flex-row">
					<div>
						<div class="field-label">Contact Name</div>
						<div class="field-value">{{ user.contact.name }}</div>
					</div>
					<mat-icon mat-dialog-close matTooltip="Update Contact Details" class="manage-contact" routerLink="./system/users/{{ userId }}"
						>edit</mat-icon
					>
				</div>

				<div class="flex-row">
					<div class="field">
						<div class="field-label">Phone</div>
						<div class="field-value" *ngFor="let phone of user.phones">{{ phone }}</div>
						<div *ngIf="!user.phones.length" class="view-noitem">Phone number not recorded</div>
					</div>

					<div class="field">
						<div class="field-label">Email</div>
						<div class="field-value">{{ user.primaryEmail }}</div>
						<div *ngIf="!user.primaryEmail" class="view-noitem">Email not recorded</div>
					</div>
				</div>
			</div>
		</div>
	</mat-card>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-raised-button mat-dialog-close>Cancel</button>
	<button mat-raised-button color="primary" [disabled]="form.invalid" throttleButton (throttledClick)="continueToPayment()">Continue to Payment</button>
</mat-dialog-actions>
