// This file has been generated from LoginOptionRulesUpdateDto.cs
// Do not modify this file directly!!
// tslint:disable

import { SetSimpleType } from '@common/utils/class-transform';



// LawMaster.Amalthea.Domain.Settings.Setting.Item.LoginOptionRulesUpdateDto
export class LoginOptionRulesUpdateDto {

	// AllowEmailCredentials
	@SetSimpleType(Boolean)
	allowEmailCredentials: boolean;
	// AllowGoogleCredentials
	@SetSimpleType(Boolean)
	allowGoogleCredentials: boolean;
	// AllowMicrosoftCredentials
	@SetSimpleType(Boolean)
	allowMicrosoftCredentials: boolean;
	// AllowedDomains
	allowedDomains: string[];

	constructor() {

	}
}