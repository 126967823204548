<filter-root #theFilter [filter]="config.request" [listRequestType]="listRequestType">
	<filter-entity-reference-list
		[root]="theFilter"
		*ngIf="config.showEntityFilter"
		name="entityType"
		label="Related To"
		[items]="entityTypes"
	></filter-entity-reference-list>
	<filter-entity-reference-list
		[root]="theFilter"
		*ngIf="!!config.showReportTypeFilter && !!documentTypes?.length"
		name="types"
		label="Report Type"
		[items]="documentTypes"
	></filter-entity-reference-list>
	<filter-file-extension-button *ngIf="!config.hideTypeFilter" [root]="theFilter" label="Type" name="inc" nameExcluded="exc"></filter-file-extension-button>
	<filter-practice-area
		[root]="theFilter"
		*ngIf="config.showPracticeArea"
		name="practiceAreaIds"
		label="Practice Areas"
		[showTitleAsPrefix]="true"
		[showDisabled]="true"
	></filter-practice-area>
	<filter-document-category [root]="theFilter" *ngIf="!config.hideCategoryFilter" name="documentCategoryId" label="Group"> </filter-document-category>
	<ng-container *tenantFeature="'TrustAccounting'">
		<filter-trust-account *ngIf="config.showTrustAccountFilter" [root]="theFilter" name="trustAccountIds" label="Trust Account"></filter-trust-account>
	</ng-container>
	<filter-date-range-button
		[root]="theFilter"
		label="Created Date"
		nameStart="createdDateStart"
		nameEnd="createdDateEnd"
		[showPast]="true"
		[showToday]="true"
	></filter-date-range-button>
	<filter-date-range-button
		[root]="theFilter"
		label="Modified Date"
		nameStart="modifiedDateStart"
		nameEnd="modifiedDateEnd"
		[showPast]="true"
		[showToday]="true"
	></filter-date-range-button>
	<filter-contact-button [root]="theFilter" label="User" name="uploadedById" [ShowStaffMembersOnly]="true"></filter-contact-button>
	<filter-checkbox-button
		[root]="theFilter"
		*ngIf="!!config.showDisabledFilter"
		label="Show Disabled"
		name="showDisabled"
		[featureFlag]="featureFlags.marketplace"
	></filter-checkbox-button>
	<filter-installed-package-button
		[root]="theFilter"
		*ngIf="!!config.showPackageFilter"
		label="Package"
		name="installationRecordId"
		[featureFlag]="featureFlags.marketplace"
	></filter-installed-package-button>
	<filter-full-text-search-button
		[root]="theFilter"
		label="Search"
		name="search"
		optionLabel="Within Documents"
		optionName="useFullTextSearch"
	></filter-full-text-search-button>
</filter-root>
