// This file has been generated from CustomerPortalDto.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.AppServer.Infrastructure.StripeIntegration.Models.CustomerPortalDto
export class CustomerPortalDto {

	// ReturnURL
	returnURL: string;

	constructor() {

	}
}