// This file has been generated from TimeRecordCreateUpdateDto.cs
// Do not modify this file directly!!
// tslint:disable

import { Moment } from 'moment-timezone';
import { DateFormat, SetDateFormat } from '@common/utils/date-format';

import { PricingMethod } from '../../Settings/CostCodes/Common/PricingMethod';

// LawMaster.Amalthea.Domain.CostRecords.Item.TimeRecordCreateUpdateDto
export class TimeRecordCreateUpdateDto {

	// AmountExclTax
	amountExclTax: number;
	// CostCodeId
	costCodeId: string;
	// Description
	description: string;
	// AssociatedMatterId
	associatedMatterId: string;
	// Date
	@SetDateFormat(DateFormat.LocalDate)
	date: Moment;
	// StaffContactId
	staffContactId: string;
	// GstAmountOverride
	gstAmountOverride: number;
	// DurationMinutes
	durationMinutes: number;
	// Quantity
	quantity: number;
	// PricingMethod
	pricingMethod: keyof typeof PricingMethod;
	// ChargeRate
	chargeRate: number;
	// Units
	units: number;
	// OverrideDescription
	overrideDescription: boolean;

	constructor() {

	}
}