import { createReducer, on } from '@ngrx/store';
import moment from 'moment';

import { invalidateCache, loadFailed, loading, loadSuccess, setFilters } from './infotrack-status-filter.actions';
import { initialState } from './infotrack-status-filter.state';

const cacheExpirySeconds = 300; // 5 Minutes;

export const infotrackStatusFilterReducer = createReducer(
	initialState,
	on(loading, state => ({ ...state, isFetching: true, list: null, error: null })),
	on(loadSuccess, (state, { response }) => ({
		...state,
		isFetching: false,
		list: response,
		oldRequest: state.request,
		error: null,
		cacheExpiry: moment().add(cacheExpirySeconds, 'seconds')
	})),

	on(loadFailed, (state, { error }) => ({ ...state, isFetching: false, list: null, error: error })),

	on(setFilters, (state, { filter }) => ({ ...state, request: filter })),

	on(invalidateCache, state => ({ ...state, cacheExpiry: null }))
);
