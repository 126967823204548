// tslint:disable:max-classes-per-file
import { DomainError } from '@common/models/Validation/DomainError';
import { createAction, props } from '@ngrx/store';

import { TimeZoneItem } from './time-zone-list.state';

export enum TimeZoneListActions {
	Load = '[Time Zone List] Load',
	Loading = '[Time Zone List] Loading',
	LoadSuccess = '[Time Zone List] Load Success',
	LoadFailed = '[Time Zone List] Load Failed'
}

export const load = createAction(TimeZoneListActions.Load);

export const loading = createAction(TimeZoneListActions.Loading);

export const loadSuccess = createAction(TimeZoneListActions.LoadSuccess, props<{ response: TimeZoneItem[] }>());

export const loadFailed = createAction(TimeZoneListActions.LoadFailed, props<{ error: DomainError }>());
