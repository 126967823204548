// This file has been generated from BankDetails.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.Domain.Settings.TrustSettings.BankDetails
export class BankDetails {

	// Id
	id: string;
	// TenantId
	tenantId: string;
	// AccountName
	accountName: string;
	// BankName
	bankName: string;
	// CountryId
	countryId: string;
	// AccountNumber
	accountNumber: string;
	// BSB
	bsb: string;

	constructor() {

	}
}