// This file has been generated from CancelTransactionDto.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.Domain.Trust.Item.CancelTransactionDto
export class CancelTransactionDto {

	// Id
	id: string;
	// CancellationReason
	cancellationReason: string;

	constructor() {

	}
}