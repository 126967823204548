import { CostTemplateListItemDto } from '@common/models/Settings/CostTemplates/List/CostTemplateListItemDto';
import { createSelector, MemoizedSelector } from '@ngrx/store';

import { IAppState } from '../../../app.state';

export const selectCostTemplateList = (state: IAppState) => state.costTemplateList;

export const selectCostTemplateListRecords: MemoizedSelector<IAppState, CostTemplateListItemDto[]> = createSelector(
	selectCostTemplateList,
	state => state?.list?.records || []
);
export const selectCostTemplateListRequest: MemoizedSelector<IAppState, Partial<any>> = createSelector(
	selectCostTemplateList,
	state => state?.request
);
export const selectCostTemplateListPageIndex = createSelector(
	selectCostTemplateList,
	state => state?.list?.pageIndex || 0
);
export const selectCostTemplateListTotalRecords = createSelector(
	selectCostTemplateList,
	state => state?.list?.totalRecords || 0
);
export const selectCostTemplateIsFetching = createSelector(selectCostTemplateList, state => !!state?.isFetching);
