import { Injectable } from '@angular/core';

import { interval, of } from 'rxjs';
import { catchError, debounce, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { IAppState } from '../../../app.state';
import { loading, ContactCustomFieldListActions } from './contact-custom-field-list.actions';
import { ContactCustomFieldsService } from '@common/services/customfields-contact.service';

@Injectable()
export class ContactCustomFieldListEffects {
	loadContactCustomFields$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				ContactCustomFieldListActions.Load,
				ContactCustomFieldListActions.SetPageIndex,
				ContactCustomFieldListActions.SetPageSize,
				ContactCustomFieldListActions.SetSortBy,
				ContactCustomFieldListActions.SetSortDirection,
				ContactCustomFieldListActions.SetFilters
			),
			tap(action => this.store.dispatch(loading())),
			withLatestFrom(this.store),
			debounce(() => interval(200)),
			switchMap(([action, state]) => {
				return this.contactCustomFieldService.getContactCustomFieldList(state.contactCustomFieldList.request).pipe(
					map(response => {
						return { type: ContactCustomFieldListActions.LoadSuccess, response };
					}),
					catchError(error => of({ type: ContactCustomFieldListActions.LoadFailed, error }))
				);
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<IAppState>,
		private contactCustomFieldService: ContactCustomFieldsService
	) {}
}
