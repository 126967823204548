// This file has been generated from ReferralRecipientDto.cs
// Do not modify this file directly!!
// tslint:disable

import { Moment } from 'moment-timezone';
import { DateFormat, SetDateFormat } from '@common/utils/date-format';



// LawMaster.Amalthea.Domain.Tasks.Item.ReferralRecipientDto
export class ReferralRecipientDto {

	// ContactId
	contactId: string;
	// Reason
	reason: string;
	// DueDate
	@SetDateFormat(DateFormat.LocalDate)
	dueDate: Moment;

	constructor() {

	}
}