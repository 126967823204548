import { Injectable } from '@angular/core';
import { BaseStorageService } from './base-storage.service';

// Implementation of native session browser storage
@Injectable({
	providedIn: 'root'
})
export class SessionStorageService extends BaseStorageService {
	constructor() {
		super();

		try {
			this.storage = window.sessionStorage;
		} catch {
			console.warn('Failed to attach session storage');
		}
	}
}