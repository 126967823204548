import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AvailableTagsRequest } from '../models/Documents/List/AvailableTagsRequest';
import { GenericHttpService } from './generic.http.service';

export abstract class BaseDocumentsService extends GenericHttpService {
	constructor(protected httpClient: HttpClient, private _appServerUrl: string) {
		super(httpClient, _appServerUrl, 'api/v1/Documents', 'Document');
	}

	abstract getSystemAndMatterTags(request?: Partial<AvailableTagsRequest>): Observable<string[]> ;
	abstract getSystemTags(request?: Partial<AvailableTagsRequest>): Observable<string[]>;
}