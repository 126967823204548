import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	Optional,
	Output,
	QueryList,
	ViewChild,
	ViewChildren
} from '@angular/core';
import { FormBuilder, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import { ILookupReference } from '@common/components/lookups/base-lookup.component';
import { TemplateEntityType } from '@common/models/Documents/TemplateDto/TemplateEntityType';
import { MatterStatus } from '@common/models/Matters/Common/MatterStatus';
import { MatterLookupDto } from '@common/models/Matters/Lookup/MatterLookupDto';
import { CustomFieldEntityType } from '@common/models/Settings/CustomFields/Common/CustomFieldEntityType';
import { CustomFieldGroupSelection } from '@common/models/Tasks/Common/CustomFieldGroupSelection';
import { TaskPriority } from '@common/models/Tasks/Common/TaskPriority';
import { TaskStatus } from '@common/models/Tasks/Common/TaskStatus';
import { TaskType } from '@common/models/Tasks/Common/TaskType';
import { TaskViewDto } from '@common/models/Tasks/Item/TaskViewDto';
import { INotificationMessage } from '@common/notification';
import { MattersService } from '@common/services/matters.service';
import { TasksService } from '@common/services/tasks.service';
import { CustomValidators } from '@common/validation/custom.validators';
import { Store } from '@ngrx/store';
import { isNil, uniq } from 'lodash-es';
import * as moment from 'moment-timezone';

import { FeatureFlags, isFeatureFlagEnabled } from 'app/app.config';
import { DialogType } from 'app/core/dialog.config';
import { IAppState } from 'app/core/state/app.state';
import { getCurrentPage } from 'app/core/state/misc/current-page/current-page.reducer';
import { CurrentPageType, ICurrentPageState } from 'app/core/state/misc/current-page/current-page.state';
import { TaskListActions } from 'app/core/state/lists/task-list/task-list.actions';
import { ICustomFieldGroupSelection } from 'app/shared/components/custom-fields/custom-field-selection.component';
import { DocumentTemplateLookupComponent } from 'app/shared/components/document-template-lookup.component';

import { ICreateComponent } from '../create-component.interface';
import { IEditComponent } from '../edit-component.interface';

enum SupportedEntityTypes {
	Matter = 'Matter',
	Contact = 'Contact'
}

@Component({
	selector: 'create-task',
	styleUrls: ['./create-task.component.scss'],
	templateUrl: 'create-task.component.html'
})
export class CreateTaskComponent implements OnInit, AfterViewInit, OnDestroy, ICreateComponent, IEditComponent {
	@ViewChildren('documentTemplateControl', { read: DocumentTemplateLookupComponent })
	documenTemplatetControlRefs: QueryList<DocumentTemplateLookupComponent>;

	@Output()
	titleChanged = new EventEmitter<string>();

	featureFlags: typeof FeatureFlags = FeatureFlags;

	private _type: keyof typeof TaskType;
	private _practiceAreaIds: string[];
	private _selectedCustomFieldGroups: CustomFieldGroupSelection[] = [];

	private _uniqueDocumentTemplatesValidator = this.uniqueArrayValidator('documentTemplateIds', 'Document Template');

	get type(): keyof typeof TaskType {
		return this._type;
	}

	set type(value: keyof typeof TaskType) {
		this._type = value;

		this.titleChanged.emit(
			!value ? null : `${!this.editId ? DialogType['Create'] : DialogType['Edit']} ${TaskType[value]}`
		);

		if (!this.editId) {
			if (!!this.isMergeTask) {
				this.addDocumentTemplate();
			}
		}
	}

	get isCollectionTask() {
		return this._type == 'CollectionTask';
	}

	get isMergeTask() {
		return this._type === 'DocumentTemplateMerge';
	}

	get isGenericTask() {
		return this._type === 'Generic';
	}

	get practiceAreaIds() {
		return this._practiceAreaIds;
	}

	get documentTemplateEntityType() {
		return !!this.form.get('associatedMatterId')?.value
			? TemplateEntityType.Matter
			: !!this.form.get('associatedContactId')?.value
			? TemplateEntityType.Contact
			: null;
	}

	get customFieldEntityType() {
		return !!this.form.get('associatedMatterId')?.value
			? CustomFieldEntityType.Matter
			: !!this.form.get('associatedContactId')?.value
			? CustomFieldEntityType.Contact
			: null;
	}

	get documentTemplateIdControls() {
		return (this.form.get('documentTemplateIds') as FormArray)?.controls;
	}

	get customFieldGroupIdControls() {
		return (this.form.get('customFieldGroupIds') as FormArray)?.controls;
	}

	get customFieldIdControls() {
		return (this.form.get('customFieldIds') as FormArray)?.controls;
	}

	@Input()
	editId: string;
	@Output()
	isValidChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	@ViewChild('descriptionInput')
	descriptionInput: ElementRef;
	form: FormGroup;
	associatedEntityTypes: SupportedEntityTypes[] = [SupportedEntityTypes.Matter, SupportedEntityTypes.Contact];
	associatedEntityType: SupportedEntityTypes = this.associatedEntityTypes[0];
	statuses: Array<keyof typeof TaskStatus>;
	priorities: Array<keyof typeof TaskPriority>;

	private subscription: Subscription = new Subscription();

	constructor(
		@Optional() @Inject(MAT_DIALOG_DATA) public data: any,
		private fb: FormBuilder,
		private taskService: TasksService,
		private store: Store<IAppState>,
		private mattersService: MattersService
	) {
		this.statuses = Object.keys(TaskStatus) as Array<keyof typeof TaskStatus>;
		this.priorities = Object.keys(TaskPriority) as Array<keyof typeof TaskPriority>;
	}

	ngOnInit(): void {
		if (!isFeatureFlagEnabled(FeatureFlags.mergeTask) && !isFeatureFlagEnabled(FeatureFlags.collectionTask)) {
			this.type = 'Generic';
		}

		// TaskCreateUpdateDto
		this.form = this.fb.group({
			assignedToId: [null, CustomValidators.required('Assigned To')],
			associatedContactId: [
				null,
				CustomValidators.requiredWhen(
					() => this.associatedEntityType === SupportedEntityTypes.Contact,
					SupportedEntityTypes.Contact
				)
			],
			associatedMatterId: [
				null,
				CustomValidators.requiredWhen(
					() => this.associatedEntityType === SupportedEntityTypes.Matter,
					SupportedEntityTypes.Matter
				)
			],
			description: [null, CustomValidators.required('Description')],
			dueDate: [null, CustomValidators.required('Due Date')],
			status: this.statuses.find(key => TaskStatus[key] === TaskStatus.ToDo),
			priority: this.priorities.find(key => TaskPriority[key] === TaskPriority.Normal),
			documentTemplateIds: this.fb.array(
				[],
				[CustomValidators.nonEmptyArrayWhen(() => this.type === 'DocumentTemplateMerge')]
			),
			allCollectionFieldsMandatory: false
		});

		this.subscription.add(
			this.form.statusChanges.subscribe(next => {
				return this.isValidChange.next(next === 'VALID');
			})
		);

		if (this.editId) {
			this._type = 'Generic';

			this.subscription.add(
				this.taskService.getTask(this.editId).subscribe((taskDto: TaskViewDto) => {
					this._type = taskDto.type;

					if (!!taskDto.associatedContact) {
						this.associatedEntityType = SupportedEntityTypes.Contact;
						// we need to re-evaluate validator condition for this field (it is absent in the taskDto object)
						taskDto.associatedMatter = null;
					}

					if (!!taskDto.associatedMatter) {
						this.associatedEntityType = SupportedEntityTypes.Matter;
						// we need to re-evaluate validator condition for this field (it is absent in the taskDto object)
						taskDto.associatedContact = null;
					}

					this.form.patchValue({
						assignedToId: taskDto.assignedTo?.id,
						associatedContactId: taskDto.associatedContact?.id,
						associatedMatterId: taskDto.associatedMatter?.id,
						description: taskDto.description,
						dueDate: taskDto.dueDate,
						status: taskDto.status,
						priority: taskDto.priority
					});
				})
			);
		} else {
			// default in the current user
			this.subscription.add(
				this.store
					.select(state => state?.currentUserData?.currentUser)
					.pipe(filter(Boolean), take(1))
					.subscribe(user => {
						this.form.patchValue({ assignedToId: user.contact?.id });
					})
			);

			// default in the current date
			this.form.patchValue({ dueDate: moment() });

			this.defaultToCurrentOpenMatterOrContact();
			this.setDefaultDueDate();
		}

		this.subscription.add(
			this.form
				.get('associatedMatterId')
				.valueChanges.pipe(
					filter(() => !!this.isMergeTask),
					switchMap(id => (!!id ? this.mattersService.getMatter(id) : of(null)))
				)
				.subscribe(
					matter => (this._practiceAreaIds = !!matter?.practiceArea?.id ? [matter.practiceArea.id] : null)
				)
		);

		this.subscription.add(
			this.form
				.get('associatedContactId')
				.valueChanges.pipe(filter(id => !!this.isMergeTask && !isNil(id)))
				.subscribe(() => (this._practiceAreaIds = null))
		);
	}

	ngAfterViewInit() {
		if (this.descriptionInput) {
			setTimeout(() => this.descriptionInput.nativeElement.focus(), 0);
		}
	}

	ngOnDestroy() {
		this.isValidChange.complete();
		this.subscription.unsubscribe();
	}

	associatedEntityTypeChange() {
		// clear out the associated contact and matter whenever the associated entity type changes
		this.form.patchValue({
			associatedContactId: null,
			associatedMatterId: null
		});
	}

	Create(): Observable<INotificationMessage> {
		if (!this.isMergeTask && !this.isGenericTask && !this.isCollectionTask) {
			throw new Error('Invalid task type');
		}

		const result$ = !!this.isMergeTask
			? this.taskService.createTemplateMergeTask(this.form.value)
			: !!this.isCollectionTask
			? this.taskService.createCollectionTask({
					selectedCustomFieldGroups: this._selectedCustomFieldGroups,
					...this.form.value
			  })
			: this.taskService.createTask(this.form.value);

		return result$.pipe(
			tap(response => this.store.dispatch({ type: TaskListActions.InsertRecords, response })),
			map(response => {
				const contactId = this.form.controls.associatedContactId.value;
				const matterId = this.form.controls.associatedMatterId.value;
				const route = [];

				if (matterId) {
					route.push('/matters', matterId, 'tasks');
				} else if (contactId) {
					route.push('/contacts', contactId, 'tasks');
				} else {
					route.push('/tasks');
				}

				return {
					linkParams: { pageIndexForId: response.id },
					linkRoute: route,
					linkText: this.form.value.description,
					text: 'Task created:',
					associatedMatterId: matterId
				};
			})
		);
	}

	Edit(): Observable<INotificationMessage> {
		return this.taskService.updateTask(this.editId, this.form.value).pipe(
			tap(response => this.store.dispatch({ type: TaskListActions.UpdateRecords, response })),
			map(() => {
				const matterId = this.form.controls.associatedMatterId.value;

				return {
					text: `Task updated: ${this.form.value.description}`,
					associatedMatterId: matterId
				};
			})
		);
	}

	onContactSelected(event: ILookupReference) {
		this.form.controls.associatedContactId.setValue(event.id);
	}

	addDocumentTemplate(): void {
		if (!this.isMergeTask) {
			return;
		}

		this.addControlToFormArray('documentTemplateIds', this._uniqueDocumentTemplatesValidator);
	}

	removeDocumentTemplate(index: number) {
		this.removeControlFromFormArray('documentTemplateIds', index);
	}

	onSelectedGroupsChange(value: ICustomFieldGroupSelection[]) {
		this._selectedCustomFieldGroups = value;
	}

	private addControlToFormArray(arrayName: string, validator: ValidatorFn) {
		const control = this.fb.control(null, validator);

		(this.form.get(arrayName) as FormArray).push(control);

		setTimeout(() => {
			this.form.get(arrayName).updateValueAndValidity();
			this.form.updateValueAndValidity();
		}, 0);
	}

	private removeControlFromFormArray(arrayName: string, index: number) {
		const control = this.form.get(arrayName) as FormArray;
		control.removeAt(index);

		setTimeout(() => {
			this.form.get(arrayName).updateValueAndValidity();
			this.form.updateValueAndValidity();
		}, 0);
	}

	private uniqueArrayValidator(arrayName: string, typeName: string): ValidatorFn {
		return (control: AbstractControl): ValidationErrors => {
			if (!this.form) {
				return null;
			}

			const hasDuplicates = this.hasDuplicates(
				(this.form.get(arrayName) as FormArray)?.controls
					?.filter(control => !!control.value)
					.map(control => control.value as string) ?? []
			);

			if (!!hasDuplicates) {
				return { error: `${typeName} must be unique` };
			} else if (!control.value) {
				return { error: `${typeName} must be entered` };
			} else {
				return null;
			}
		};
	}

	private hasDuplicates(array: any[]) {
		return uniq(array).length !== array.length;
	}

	private defaultToCurrentOpenMatterOrContact() {
		this.subscription.add(
			this.store
				.select(getCurrentPage)
				.pipe(
					filter(page => page.id !== null),
					take(1),
					map(page => this.isPageWithOpenMatter(page))
				)
				.subscribe(page => {
					if (page.pageType === CurrentPageType.Contact) {
						this.associatedEntityType = SupportedEntityTypes.Contact;
						this.form.patchValue({ associatedContactId: page.id });
					} else if (page.pageType === CurrentPageType.Matter) {
						this.associatedEntityType = SupportedEntityTypes.Matter;
						this.form.patchValue({ associatedMatterId: page.id });
					}
				})
		);
	}

	private isPageWithOpenMatter(page: ICurrentPageState): ICurrentPageState {
		if (page.pageType === CurrentPageType.Matter) {
			if ((page.lookup as MatterLookupDto).status === MatterStatus.Open) {
				return page;
			}
			return { id: '', lookup: null, pageType: page.pageType };
		}
		return page;
	}

	private setDefaultDueDate() {
		if (!!this.data?.payload?.dueDate) {
			this.form.controls.dueDate.setValue(this.data?.payload?.dueDate);
		}
	}
}
