import { Injectable } from '@angular/core';

import { interval, of } from 'rxjs';
import { catchError, debounce, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { IAppState } from '../../../app.state';
import { loading, DocumentBriefTemplateListActions } from './document-brief-template-list.actions';
import { DocumentBriefTemplatesService } from '@common/services/documentbrieftemplates.service';

@Injectable()
export class DocumentBriefTemplateListEffects {
	loadDocumentBriefTemplates$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				DocumentBriefTemplateListActions.Load,
				DocumentBriefTemplateListActions.SetPageIndex,
				DocumentBriefTemplateListActions.SetPageSize,
				DocumentBriefTemplateListActions.SetSortBy,
				DocumentBriefTemplateListActions.SetSortDirection,
				DocumentBriefTemplateListActions.SetFilters
			),
			tap(action => this.store.dispatch(loading())),
			withLatestFrom(this.store),
			debounce(() => interval(200)),
			switchMap(([action, state]) => {
				return this.documentBriefTemplateService.getTemplates(state.documentBriefTemplateList.request).pipe(
					map(response => {
						return { type: DocumentBriefTemplateListActions.LoadSuccess, response };
					}),
					catchError(error => of({ type: DocumentBriefTemplateListActions.LoadFailed, error }))
				);
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<IAppState>,
		private documentBriefTemplateService: DocumentBriefTemplatesService
	) {}
}
