import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { TimeZoneService } from '@common/services/settings/timezone.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { uniqBy } from 'lodash';
import moment from 'moment-timezone';

import { IAppState } from '../../app.state';
import { loadFailed, loading, loadSuccess, TimeZoneListActions } from './time-zone-list.actions';
import { TimeZoneItem } from './time-zone-list.state';

@Injectable()
export class TimeZoneListEffects {
	loadList$ = createEffect(() =>
		this._actions$.pipe(
			ofType(TimeZoneListActions.Load),
			withLatestFrom(this._store),
			filter(([_, state]) => !state?.timeZoneList?.timeZones?.length),
			debounceTime(500),
			tap(() => this._store.dispatch(loading())),
			switchMap(() => this._timeZoneService.getAvailableTimeZones()),
			map(response => {
				const localTimeZones = moment.tz.names();

				return uniqBy(
					response
						.filter(timeZone => !!localTimeZones.includes(timeZone))
						.map(timeZone => {
							const timeZoneData = moment.tz(timeZone);
							const timeZoneParts = timeZone.split('/');

							const offsetText = timeZoneData.format('Z');
							const shortName = timeZoneParts[timeZoneParts.length - 1].replace('_', ' ');

							if (shortName === 'GMT-0' || shortName === 'GMT0' || shortName === 'GMT+0') {
								return null;
							}

							return {
								id: timeZone,
								name: `(${offsetText}) ${shortName}`,
								offset: timeZoneData.utcOffset()
							} as TimeZoneItem;
						})
						.filter(timeZone => !!timeZone),
					timeZone => timeZone.name
				);
			}),
			map(response => loadSuccess({ response: response })),
			catchError(error => of(loadFailed({ error: error })))
		)
	);

	constructor(
		private _actions$: Actions,
		private _store: Store<IAppState>,
		private _timeZoneService: TimeZoneService
	) {}
}
