// This file has been generated from SectionOptions.cs
// Do not modify this file directly!!
// tslint:disable

import { SetSimpleType } from '@common/utils/class-transform';



// LawMaster.Amalthea.Domain.DocumentBriefs.Item.SectionOptions
export class SectionOptions {

	// SelectedSectionsOnly
	@SetSimpleType(Boolean)
	selectedSectionsOnly: boolean;
	// SectionIds
	sectionIds: string[];

	constructor() {

	}
}