<mat-card>
	<section class="page-path"><a [routerLink]="['..']">System</a> / Document Templates</section>

	<mat-tab-group
		[selectedIndex]="selectedTabIndex"
		animationDuration="0ms"
		(selectedTabChange)="onSelectedTabChange($event)"
		[class.change-background]="!hideFilterButton"
	>
		<mat-tab>
			<ng-template mat-tab-label>
				<span class="hiddenPhone">Document</span>
				<span>Templates</span>
			</ng-template>
			<ng-template matTabContent>
				<document-template-list [filterComponent]="theTemplateFilter.root">
					<document-list-filter #theTemplateFilter [config]="templateConfig"></document-list-filter>
				</document-template-list>
			</ng-template>
		</mat-tab>
		<mat-tab *ngIf="!!canShowEmailTemplates">
			<ng-template mat-tab-label>
				<span>Email</span>
				<span class="hiddenPhone">Templates</span>
			</ng-template>
			<ng-template matTabContent>
				<document-email-template-list [filterComponent]="theTemplateFilter.root">
					<document-list-filter #theTemplateFilter [config]="emailTemplateConfig"></document-list-filter>
				</document-email-template-list>
			</ng-template>
		</mat-tab>
		<mat-tab>
			<ng-template mat-tab-label> <span class="hiddenPhone">Available</span> <span>Fields</span> </ng-template>
			<ng-template matTabContent>
				<available-fields-list></available-fields-list>
			</ng-template>
		</mat-tab>
		<mat-tab>
			<ng-template mat-tab-label>
				<span>Header/Footer</span>
				<span class="hiddenPhone">Templates</span>
			</ng-template>
			<ng-template matTabContent>
				<document-header-footer-template-list [filterComponent]="theHeaderFooterTemplateFilter.root">
					<document-list-filter #theHeaderFooterTemplateFilter [config]="headerFooterTemplateConfig"></document-list-filter>
				</document-header-footer-template-list>
			</ng-template>
		</mat-tab>
		<mat-tab>
			<ng-template mat-tab-label>
				<span class="hiddenPhone">Template</span>
				<span>Groups</span>
			</ng-template>
			<ng-template matTabContent>
				<document-category-list></document-category-list>
			</ng-template>
		</mat-tab>
		<mat-tab [disabled]="true">
			<ng-template mat-tab-label>
				<filter-mobile-span
					[hidden]="hideFilterButton"
					[class.hide-filter]="hideFilterButton"
					[filterRoot]="filter?.root"
					[filterParams]="config"
					[filterComponent]="filterDialog"
					[isTabbedFilter]="true"
				></filter-mobile-span>
			</ng-template>
		</mat-tab>
	</mat-tab-group>
</mat-card>
