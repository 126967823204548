import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { CustomFieldGroupDto } from '@common/models/Settings/CustomFields/Item/CustomFieldGroupDto';

import { CustomValidators } from '@common/validation/custom.validators';

@Component({
	selector: 'custom-field-group-dialog',
	styleUrls: ['./custom-field-group-dialog.component.scss'],
	templateUrl: './custom-field-group-dialog.component.html'
})
export class CustomFieldGroupDialogComponent implements OnInit, AfterViewInit {
	@ViewChild('customFieldGroupInput', { static: true })
	customFieldGroupInput: ElementRef;
	form: FormGroup;
	isCreateMode: boolean;

	constructor(@Inject(MAT_DIALOG_DATA) public data: CustomFieldGroupDto, private fb: FormBuilder) {}

	ngOnInit() {
		this.form = this.fb.group({
			id: this.data?.id,
			name: [this.data?.name, CustomValidators.required('Custom Field Group')],
			entityType: this.data.entityType
		});
		this.isCreateMode = !this.data.id;
	}
	ngAfterViewInit() {
		if (this.customFieldGroupInput) {
			setTimeout(() => this.customFieldGroupInput.nativeElement.focus(), 0);
		}
	}
}
