import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ContactLookupComponent } from '@common/components/lookups/contact-lookup.component';
import { CustomFieldEntityType } from '@common/models/Settings/CustomFields/Common/CustomFieldEntityType';

import { CustomValidators } from '@common/validation/custom.validators';

const selectionListFieldName = 'contactSelectionList';
const newEntryFieldName = 'contactSelectionListEntryName';
const showSelectionListFieldName = 'contactShowSelectionList';

@Component({
	selector: 'custom-field-contact-list-config',
	styleUrls: ['./custom-field-contact-list-config.component.scss'],
	templateUrl: './custom-field-contact-list-config.component.html'
})
export class CustomFieldContactListConfigComponent implements OnInit, OnDestroy {
	private subscriptions = new Subscription();

	selectionList: FormArray;
	validationEnabled: boolean;

	@Input()
	entityType: keyof typeof CustomFieldEntityType;

	@Input()
	formGroup: FormGroup;

	public static get selectionListFieldName() {
		return selectionListFieldName;
	}

	public static get showSelectionListFieldName() {
		return showSelectionListFieldName;
	}

	@Input()
	set enabled(value: boolean) {
		this.validationEnabled = value;

		// Force validation when the field type changes, clear the list when changing to a non-list type
		// protect against calls before ngOnInit
		if (this.selectionList) {
			if (!this.validationEnabled) {
				while (this.selectionList.length > 0) {
					this.selectionList.removeAt(0);
				}
			}
			this.selectionList.updateValueAndValidity();
		}
	}

	@ViewChild('newEntryContactLookup', { read: ContactLookupComponent })
	newEntryContactLookup: ContactLookupComponent;

	@Output()
	selectionChanged = new EventEmitter<void>();

	get showSelectionListControl() {
		return this.formGroup.get(showSelectionListFieldName);
	}

	get showSelectionList() {
		return !!this.showSelectionListControl?.value;
	}

	constructor(private fb: FormBuilder) {}

	ngOnInit(): void {
		const uniqueValidator = CustomValidators.uniqueArrayField();
		const nonEmptyValidator = CustomValidators.nonEmptyArray();
		this.selectionList = this.fb.array(
			[],
			[
				c =>
					!!this.validationEnabled && !!this.formGroup.get(showSelectionListFieldName).value
						? uniqueValidator(c)
						: null,
				c =>
					!!this.validationEnabled && !!this.formGroup.get(showSelectionListFieldName).value
						? nonEmptyValidator(c)
						: null
			]
		);
		this.formGroup.addControl(selectionListFieldName, this.selectionList);
		this.formGroup.addControl(newEntryFieldName, this.fb.control(null));
		this.formGroup.addControl(showSelectionListFieldName, this.fb.control(false));

		this.subscriptions.add(
			this.formGroup
				.get(newEntryFieldName)
				.valueChanges.pipe(filter(Boolean))
				.subscribe(() => this.onNewEntryEntered())
		);

		this.subscriptions.add(
			this.formGroup.get(showSelectionListFieldName).valueChanges.subscribe(() => {
				this.formGroup.get(selectionListFieldName).updateValueAndValidity();
				this.formGroup.updateValueAndValidity();
			})
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	// Update the form data when editing an existing custom field
	updateModel(data: string[]) {
		data.forEach(item => this.addNewEntry(item));

		if (!!data?.length) {
			this.formGroup.get(showSelectionListFieldName).setValue(true);
		}
	}

	addNewEntry(id: string) {
		const control = this.fb.control(id, CustomValidators.required());
		this.selectionList.push(control);

		this.subscriptions.add(control.valueChanges.subscribe(() => this.selectionChanged.emit()));
		control.markAsTouched();
	}

	// When first changing the new item line, add another new item below it and update the available options
	onNewEntryEntered() {
		const control = this.formGroup.get(newEntryFieldName);
		const contactId = control.value;
		if (contactId) {
			this.addNewEntry(contactId);
		}

		this.newEntryContactLookup.clearValue();
	}

	moveUp(index: number) {
		if (index > 0) {
			const controlToMove = this.selectionList.controls[index];
			// Remove the lower item first, so the indexes don't change for the insert
			this.selectionList.removeAt(index);
			this.selectionList.insert(index - 1, controlToMove);
		}
	}

	moveDown(index: number) {
		// prevent moving past the end of the list
		if (index >= 0 && index < this.selectionList.length - 1) {
			// Move down is the same as moving the next item up
			this.moveUp(index + 1);
		}
	}

	delete(index: number) {
		this.selectionList.removeAt(index);
		this.selectionChanged.emit();
	}
}
