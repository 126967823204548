import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { filter, switchMap } from 'rxjs/operators';

import { EntityReference } from '@common/models/Common/EntityReference';
import { DocumentCategoryDto } from '@common/models/Settings/DocumentCategories/Item/DocumentCategoryDto';
import { DocumentCategoryListItemDto } from '@common/models/Settings/DocumentCategories/List/DocumentCategoryListItemDto';
import { DomainError } from '@common/models/Validation/DomainError';
import { NotificationService } from '@common/notification';
import { DocumentCategoryService } from '@common/services/settings/documentcategory.service';

import { GridViewService } from 'app/services/grid-view.service';
import { GenericListComponent } from 'app/shared/generics/generic.list.component';

import { AddDocumentCategoryComponent } from '../add-document-category/add-document-category.component';

@Component({
	selector: 'document-category-list',
	styleUrls: ['./document-category-list.component.scss'],
	templateUrl: './document-category-list.component.html'
})
export class DocumentCategoryListComponent extends GenericListComponent<DocumentCategoryListItemDto, void> {
	constructor(
		dialog: MatDialog,
		router: Router,
		activatedRoute: ActivatedRoute,
		private documentCategoryService: DocumentCategoryService,
		private notificationService: NotificationService,
		gridViewService: GridViewService
	) {
		super(dialog, router, activatedRoute, documentCategoryService, ['name', 'actions'], gridViewService);
	}

	createDocumentCategory() {
		this.dialog
			.open(AddDocumentCategoryComponent, { data: new DocumentCategoryDto() })
			.afterClosed()
			.pipe(
				filter(Boolean),
				switchMap((data: DocumentCategoryDto) => this.documentCategoryService.createDocumentCategory(data))
			)
			.subscribe(this.onCreateSuccess, this.onError);
	}

	editDocumentCategory(row: DocumentCategoryListItemDto) {
		this.dialog
			.open(AddDocumentCategoryComponent, { data: row })
			.afterClosed()
			.pipe(
				filter(Boolean),
				switchMap((data: DocumentCategoryDto) =>
					this.documentCategoryService.updateDocumentCategory(data.id, data)
				)
			)
			.subscribe(this.onUpdateSuccess, this.onError);
	}

	deleteDocumentCategory(row: DocumentCategoryListItemDto) {
		this.notificationService
			.showConfirmation(
				'Delete Document Template Group',
				`Are you sure you want to delete the document template group "${row.name}"?`
			)
			.pipe(
				filter(Boolean),
				switchMap(() => this.documentCategoryService.deleteDocumentCategory(row.id))
			)
			.subscribe(
				(next: EntityReference) => {
					this.notificationService.showNotification(`Document Template Group deleted: ${next.name}`);
					this.refreshList();
				},
				error => this.notificationService.showErrors('Error deleting Document Template Group', error)
			);
	}
	private onError = (errors: DomainError[]) => {
		this.notificationService.showErrors('Error', errors);
	};

	private onCreateSuccess = (result: EntityReference) => {
		this.notificationService.showNotification(`Document Template Group ${result.name} created`);
		this.refreshList();
	};

	private onUpdateSuccess = (result: EntityReference) => {
		this.notificationService.showNotification(`Document Template Group ${result.name} updated`);
		this.refreshList();
	};
}
