import { ListResponse } from '@common/models/Generic/ListResponse';
import { ContactCustomFieldListItemDto } from '@common/models/Settings/CustomFields/List/ContactCustomFieldListItemDto';
import { ContactCustomFieldListRequest } from '@common/models/Settings/CustomFields/List/ContactCustomFieldListRequest';
import { DomainError } from '@common/models/Validation/DomainError';

import { AppConfig } from 'app/app.config';

export interface IContactCustomFieldListState {
	isFetching: boolean;
	list: ListResponse<ContactCustomFieldListItemDto>;
	lastSelectedRecordId: string;
	request: Partial<ContactCustomFieldListRequest>;

	// errrors
	error: DomainError;
}

export const initialState: IContactCustomFieldListState = {
	isFetching: false,
	list: null,
	lastSelectedRecordId: null,
	request: {
		search: null,

		pageSize: AppConfig.PageSize,
		pageIndex: 0,
		pageIndexForId: null,

		sortBy: null,
		sortDirection: null
	},

	error: null
};
