import { Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatRipple } from '@angular/material/core';

import { distinctUntilChanged, Subscription } from 'rxjs';

import { isNil } from 'lodash';

import { formattedMasking } from '../utils/stringUtil';
import { BaseEditableComponent } from './base.editable.component';

@Component({
	selector: 'editable-numeric-value',
	styleUrls: ['./base.editable.component.scss'],
	template: `
		<span *ngIf="!editMode" [class]="value() ? '' : 'view-noitem'">
			{{
				formatDisplay(
					value() === null || value() === undefined
						? blankMessage
						: isCurrency
						? (value() | currency : 'AUD' : 'symbol-narrow' : currencyFormat)
						: !!maskingText
						? displayTextWithMasking()
						: (value() | number)
				)
			}}
		</span>
		<mat-form-field *ngIf="editMode">
			<mat-placeholder *ngIf="placeHolder"
				>{{ placeHolder }} <sup class="color-warn" *ngIf="required">*</sup>
			</mat-placeholder>
			<span matPrefix *ngIf="isCurrency && (control.enabled || value())">$&nbsp;</span>
			<div matRipple [matRippleDisabled]="true">
				<input
					#input
					matInput
					type="text"
					[title]="tooltip || ''"
					[formControl]="displayControl"
					autocomplete="off"
					(focus)="onFocus()"
					(blur)="onBlur()"
				/>
			</div>
			<mat-error> <error-messages [for]="control"></error-messages> </mat-error>
			<div matSuffix><ng-content></ng-content></div>
			<mat-hint *ngIf="hintText">{{ hintText }}</mat-hint>
		</mat-form-field>
	`
})
export class EditableNumericValueComponent extends BaseEditableComponent<number> implements OnInit, OnDestroy {
	// Flag how to format the value: currency or number
	@Input()
	isCurrency: boolean;
	@Input()
	alwaysShowDecimals: boolean = false;
	@Input()
	rippleOnChanged: boolean = false;
	@Input()
	tooltip: string;
	// Min value for the input field. If specified, then
	//  - sets form validator
	//  - sets the min value as the default value
	@Input()
	minValue: number;

	@Input()
	integersOnly: boolean;

	displayControl: FormControl;

	@ViewChild('input')
	private inputEl: ElementRef;
	@ViewChild(MatRipple)
	rippleEl: MatRipple;
	private subscription: Subscription = new Subscription();
	hasFocus: boolean = false;

	@Input()
	maskingText: string;

	constructor(private renderer: Renderer2, formBuilder: FormBuilder) {
		super();

		this.displayControl = formBuilder.control(null);
	}

	get currencyFormat(): string {
		return this.alwaysShowDecimals ? '1.2-2' : '1.0-2';
	}

	private _updatedInputFromDisplay: boolean;

	ngOnInit(): void {
		if (!!this.displayControl) {
			this.subscription.add(
				this.displayControl.valueChanges.subscribe((value: string) => {
					let formattedValue = this.parseNumber(value);
					if (this.isCurrency && !isNil(formattedValue)) {
						formattedValue = this.toFixed(formattedValue);
					}

					const deltaLength = (formattedValue?.length ?? 0) - value.length;

					const element = this.inputEl.nativeElement as HTMLInputElement;
					const start = Math.max(element.selectionStart + deltaLength, 0);
					const end = Math.max(Math.min(element.selectionEnd + deltaLength, formattedValue?.length ?? 0), 0);

					this.displayControl.setValue(formattedValue, { emitEvent: false });

					let controlValue = null;
					if (!isNil(formattedValue)) {
						element.setSelectionRange(start, end);

						controlValue = parseFloat(formattedValue);
						if (isNaN(controlValue)) {
							if (formattedValue !== '') {
								console.warn(
									'Unable to parseFloat() on the value (' + formattedValue + ') for formControl',
									this.control
								);
							}
							controlValue = '';
						}
					}

					if (this.control.value !== controlValue) {
						this._updatedInputFromDisplay = true;
						this.control.markAsDirty();
						this.control.setValue(controlValue);
					}

					// Run ripple anmiation only if enabled and change was triggered programatically
					if (this.rippleOnChanged && !this.hasFocus) {
						this.rippleEl.launch({ centered: true });
					}
				})
			);

			if (!isNil(this._control?.value)) {
				this.displayControl.setValue(this._control.value, { emitEvent: false });
			}
		}

		if (!!this.control) {
			this.subscription.add(
				this.control.valueChanges.pipe(distinctUntilChanged()).subscribe((value: number) => {
					if (!!this._updatedInputFromDisplay) {
						this._updatedInputFromDisplay = false;
						return;
					}

					if (!isNil(value)) {
						this.displayControl.setValue(value, { emitEvent: false });
					}
				})
			);
		}
	}
	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	onFocus() {
		this.hasFocus = true;
		this.inputEl.nativeElement.select();

		if (this.control) {
			this.control.markAsTouched();
		}
	}

	onBlur() {
		this.hasFocus = false;
	}

	protected editModeChanged(val: boolean): void {
		setTimeout(() => {
			if (val && !isNil(this.minValue)) {
				this.renderer.setAttribute(this.inputEl.nativeElement, 'min', this.minValue.toString());
				this.control.setValidators(Validators.min(this.minValue));
				if (isNil(this.value())) {
					this.control.setValue(this.minValue);
				}
			}

			if (val && this.integersOnly) {
				const regExp = /^\d+$/;
				this.control.setValidators(Validators.pattern(regExp));
			}
		}, 0);
	}

	private parseNumber(value: string): string {
		let newValue = '';
		let hasDecimal = false;

		for (let index = 0; index < value.length; index++) {
			const char = value.charAt(index);

			if (char === '-' && index === 0) {
				newValue += char;
			} else if (char === '.' && !hasDecimal) {
				newValue += char;
				hasDecimal = true;
			} else if (char >= '0' && char <= '9') {
				newValue += char;
			}
		}

		return newValue;
	}

	private toFixed(value: string): string {
		try {
			const number = value?.match(/^-?\d+(?:\.\d{0,2})?/)[0];

			// Do not round the number. fix it to 2 digits
			// https://stackoverflow.com/a/4187164/3377661
			return number;
		} catch {
			return value;
		}
	}

	displayTextWithMasking() {
		return formattedMasking(this.value(), this.maskingText);
	}
}
