import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import 'moment-duration-format';

import { BillNumberFormatDto } from '@common/models/Settings/BillSettings/Item/BillNumberFormatDto';
import { NotificationService } from '@common/notification';
import { BillsService } from '@common/services/settings/bills.service';
import { padStart } from 'lodash';

import { CustomValidators } from '@common/validation/custom.validators';

import { BillNextNumberDialogComponent } from './bill-next-number-dialog.component';
import { IBillNextNumberData } from './IBillNextNumberData';

@Component({
	selector: 'bill-number-format',
	styleUrls: ['./bill-number-format.component.scss'],
	templateUrl: './bill-number-format.component.html'
})
export class BillNumberFormatComponent implements OnInit, OnDestroy {
	form: FormGroup;
	nextNumber: number;
	@Input() invoiceNumberFormat: string;

	private subscriptions = new Subscription();

	constructor(
		private fb: FormBuilder,
		private billSettingsService: BillsService,
		private notificationService: NotificationService,
		private dialog: MatDialog
	) {}

	ngOnInit() {
		// split the standard format we have like INV-{0:0000}
		const formatParts = this.invoiceNumberFormat.split('{0:');

		this.form = this.fb.group({
			// find out the prefix (INV-)
			invoiceNumberPrefix: formatParts[0],
			// find out the number of digits length (0000...)
			numberOfDigits: [
				formatParts[1].length - 1,
				[CustomValidators.required('Number of digits'), Validators.min(0), Validators.max(20)]
			]
		});

		this.subscriptions.add(
			this.billSettingsService.getLastNumericPart().subscribe(lastNumber => (this.nextNumber = lastNumber + 1))
		);
	}

	getFormattedInvoiceNumber(): string {
		const prefix = this.form.controls.invoiceNumberPrefix.value;
		return !!this.nextNumber
			? prefix + padStart(this.nextNumber.toString(), Math.min(this.form.controls.numberOfDigits.value, 20), '0')
			: '';
	}

	save(): void {
		const prefix = this.form.controls.invoiceNumberPrefix.value;
		const numberOfDigits = this.form.controls.numberOfDigits.value;

		const billNumberFormat: BillNumberFormatDto = { value: `${prefix}{0:${'0'.repeat(numberOfDigits)}}` };

		this.subscriptions.add(
			this.billSettingsService.saveBillNumberFormat(billNumberFormat).subscribe(
				() => {
					this.notificationService.showNotification('Invoice number format saved successfully');
					this.form.markAsPristine();
				},
				error => this.notificationService.showError('Error saving format', error)
			)
		);
	}

	setNextBillNumber(): void {
		const data: IBillNextNumberData = { nextNumber: this.nextNumber };
		this.subscriptions.add(
			this.dialog
				.open(BillNextNumberDialogComponent, { data })
				.afterClosed()
				.pipe(filter(Boolean))
				.subscribe((chosenNextNumber: number) => (this.nextNumber = chosenNextNumber))
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}
}
