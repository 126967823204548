<h2 mat-dialog-title class="title">{{ isCreateMode ? 'Add' : 'Edit' }} Document Template</h2>
<mat-dialog-content class="flex-column" [formGroup]="form">
	<div class="flex-row">
		<div class="document-template-container">
			<document-template-lookup
				#templateLookup
				Placeholder="{{ matterTemplateEntityType }} Template"
				[Required]="true"
				[FormControl]="form.controls.documentTemplateId"
				[HasAutofocus]="true"
				(Selected)="templateSelected($event)"
				[entityType]="matterTemplateEntityType"
				[practiceAreaIds]="data.practiceAreas"
			>
			</document-template-lookup>
		</div>
	</div>

	<div class="flex-row" *ngIf="isGenericContactRequired">
		<mat-form-field>
			<mat-select formControlName="matterContactRoleName" placeholder="Select Matter Contact Role">
				<mat-option *ngFor="let role of roles" [value]="role">{{ role }}</mat-option>
			</mat-select>
			<mat-error>
				<error-messages [for]="form.get('matterContactRoleName')"></error-messages>
			</mat-error>
		</mat-form-field>
	</div>

	<div class="flexrow sample-row">
		<span class="description">Example document name on matter: &nbsp; </span>
		<h3 class="sample-text">
			<span *ngFor="let item of items; let first = first">
				<span *ngIf="!first">&nbsp;</span>
				<b *ngIf="item.variableKey == 'Hyphen'; else notHypen">-</b>
				<ng-template #notHypen>
					<b *ngIf="item.variableKey == 'OpenBracket'; else notOpenBracket">(</b>
					<ng-template #notOpenBracket>
						<b *ngIf="item.variableKey == 'ClosedBracket'; else notClosedBracket">)</b>
						<ng-template #notClosedBracket>
							<b>{{ item.example }}</b>
						</ng-template>
					</ng-template>
				</ng-template>
			</span>
		</h3>
	</div>
	
	<div cdkDropListGroup>
		<div class="flex-column">
			<h2 class="description">Document Name Configuration</h2>
			<span [ngClass]="items?.length > 0 ? 'drag-drop-label-right' : 'drag-drop-label-center'"
				><mat-icon>highlight_alt</mat-icon>Drag and drop variables below</span
			>
			<div cdkDropList cdkDropListOrientation="horizontal" [cdkDropListData]="items" class="drag-drop-box" (cdkDropListDropped)="drop($event)">
					<div class="flexrow">
					<div *ngFor="let item of items; let first = first; let i = index" class="chip-flex-row">
						<span class="chip-hyphen" *ngIf="!first"> &nbsp; </span>
						<div class="chip-flex-column">
							<mat-chip
								[ngClass]="item.allowMany ? 'chip-primary-multi' : 'chip-primary'"
								cdkDrag
								[selectable]="true"
								[removable]="true"
								(removed)="remove(i)"
							>
								{{ item.title }}
								<mat-icon [ngClass]="item.allowMany ? 'multi-remove' : 'primary-remove'" matChipRemove *ngIf="true">cancel</mat-icon>
							</mat-chip>
							<span *ngIf="item.example">({{ item.example }})</span>
						</div>
					</div>
				</div>
			</div>
		</div>
			<div class="flex-column">
			<h2 class="description">Available variables</h2>
				<div cdkDropList cdkDropListOrientation="horizontal" [cdkDropListData]="basket" class="drag-drop-box" (cdkDropListDropped)="drop($event)">
				<div class="flexrow">
					<div *ngFor="let item of basket" class="chip-flex-column">
						<mat-chip cdkDrag [selectable]="true" [removable]="false" [ngClass]="item.allowMany ? 'chip-basket-multi' : 'chip-basket'">
							{{ item.title }}<mat-icon>drag_indicator</mat-icon> </mat-chip
						><span *ngIf="item.example">({{ item.example }})</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-raised-button color="primary" throttleButton (throttledClick)="save()" [disabled]="!isSaveFormEnabled">Save</button>
	<button mat-raised-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
