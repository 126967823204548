// This file has been generated from PracticeAreaCreateUpdateDto.cs
// Do not modify this file directly!!
// tslint:disable

import { SetComplexType, SetSimpleType } from '@common/utils/class-transform';

import { Stage } from '../Stage';

// LawMaster.Amalthea.Domain.Settings.PracticeAreas.Item.PracticeAreaCreateUpdateDto
export class PracticeAreaCreateUpdateDto {

	// Stages
	@SetComplexType(Stage)
	stages: Stage[];
	// Name
	name: string;
	// Description
	description: string;
	// Contributors
	contributors: string[];
	// Readers
	readers: string[];
	// InstallationRecordIds
	installationRecordIds: string[];
	// UniqueIdentifier
	uniqueIdentifier: string;
	// IsDisabled
	@SetSimpleType(Boolean)
	isDisabled: boolean;

	constructor() {
		this.stages = [];
	}
}