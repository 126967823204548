import { Component, OnInit, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder } from '@angular/forms';

import { CreateUserDto } from '@common/models/Users/Item/CreateUserDto';

import { CustomValidators } from '@common/validation/custom.validators';
import { Subscription } from 'rxjs';

@Component({
	selector: 'create-user',
	styleUrls: ['./create-user.component.scss'],
	templateUrl: './create-user.component.html'
})
export class CreateUserComponent implements OnInit, OnDestroy {
	form: FormGroupTyped<CreateUserDto>;
	private subscription: Subscription = new Subscription();

	constructor(private fb: FormBuilder) {}

	get firstName(): FormControlTyped<string> {
		return this.form.controls.firstName;
	}

	get lastName(): FormControlTyped<string> {
		return this.form.controls.lastName;
	}

	get contactId(): FormControlTyped<string> {
		return this.form.controls.contactId;
	}

	ngOnInit() {
		this.form = this.fb.group({
			contactId: null,
			firstName: null,
			lastName: null
		}) as FormGroupTyped<CreateUserDto>;

		this.firstName.setValidators(CustomValidators.requiredWhen(() => !this.contactId.value, 'First Name'));
		this.lastName.setValidators(CustomValidators.requiredWhen(() => !this.contactId.value, 'Last Name'));

		this.subscription.add(this.contactId.valueChanges.subscribe(value => this.contactChanged(value)));
		this.subscription.add(this.firstName.valueChanges.subscribe((value: string) => this.nameChanged(value)));
		this.subscription.add(this.lastName.valueChanges.subscribe((value: string) => this.nameChanged(value)));
	}

	createPanelDisabled(): boolean {
		return !!this.contactId.value;
	}

	existingPanelDisabled(): boolean {
		return !!this.firstName.value || !!this.lastName.value;
	}

	nameChanged(value: string) {
		if (!!value) {
			if (this.contactId.enabled) {
				this.contactId.disable({ emitEvent: false });
			}
		} else {
			if (this.contactId.disabled) {
				this.contactId.enable({ emitEvent: false });
			}
		}
	}

	contactChanged(value: string) {
		if (!!value) {
			if (this.firstName.enabled) {
				this.firstName.disable({ emitEvent: false });
			}
			if (this.lastName.enabled) {
				this.lastName.disable({ emitEvent: false });
			}
		} else {
			if (this.firstName.disabled) {
				this.firstName.enable({ emitEvent: false });
			}
			if (this.lastName.disabled) {
				this.lastName.enable({ emitEvent: false });
			}
		}
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
