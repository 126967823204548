import { Action, createReducer, on } from '@ngrx/store';

import { JobViewDto } from '../../models/Settings/Jobs/Item/JobViewDto';
import { JobListItemDto } from '../../models/Settings/Jobs/List/JobListItemDto';
import { deleteJob, listLoadedSuccess, updateJob } from '../actions/jobs.actions';
import { IJobsData } from '../models/jobs-data';

export const initialState: IJobsData = {
	list: []
};

const _jobsReducer = createReducer(
	initialState,

	on(deleteJob, (state, { id }) => {
		const newJobs = (state.list as JobListItemDto[]).filter(x => x.id != id);

		return { ...state, list: newJobs };
	}),

	on(updateJob, (state, { job }) => {
		const typedJob = job as JobViewDto;
		const newJobs: JobListItemDto[] = (state.list as JobListItemDto[]).map(x => {
			if (x.id == typedJob.id && x.status !== 'Complete' && x.status !== 'Cancelled') {
				return {
					id: typedJob.id,
					isHighlighted: true,
					progressComment: typedJob.progressComment,
					progressPercentage: typedJob.progressPercentage,
					lastUpdateUtc: typedJob.lastUpdateUtc,
					startedUtc: typedJob.startedUtc,
					status: typedJob.status,
					title: typedJob.title,
					type: typedJob.type,
					user: typedJob.user,
					sourceEntityId: typedJob.sourceEntityId,
					targetEntityId: typedJob.targetEntityId,
					associatedMatter: typedJob.associatedMatter,
					completedUtc: typedJob.completedUtc,
					errors: typedJob.errors
				};
			}
			return x;
		});
		if (newJobs.findIndex(x => x.id == job.id) < 0) {
			newJobs.splice(0, 0, {
				id: typedJob.id,
				isHighlighted: true,
				progressComment: typedJob.progressComment,
				progressPercentage: typedJob.progressPercentage,
				lastUpdateUtc: typedJob.lastUpdateUtc,
				startedUtc: typedJob.startedUtc,
				status: typedJob.status,
				title: typedJob.title,
				type: typedJob.type,
				user: typedJob.user,
				sourceEntityId: typedJob.sourceEntityId,
				targetEntityId: typedJob.targetEntityId,
				associatedMatter: typedJob.associatedMatter,
				completedUtc: typedJob.completedUtc,
				errors: typedJob.errors
			});
			if (newJobs.length > 5) {
				newJobs.pop();
			}
		}
		return { ...state, list: newJobs };
	}),

	on(listLoadedSuccess, (state, { list }) => ({ ...state, list: list?.records }))
);

export function jobsReducer(state: IJobsData, action: Action) {
	return _jobsReducer(state, action);
}
