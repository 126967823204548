<h2 mat-dialog-title>
	<span>{{ disableMatterLookup ? 'Update' : 'Add' }} Matter Timer</span>
</h2>
<mat-dialog-content [formGroup]="form">
	<matter-lookup
		#matterLookup
		class="related-to-entity"
		Placeholder="Related To Matter"
		[Status]="'Open'"
		[FormControl]="form.get('matterId')"
		[disabled]="disableMatterLookup"
	></matter-lookup>
	<mat-form-field style="width: 100%;">
		<input
			#timerDescription
			formControlName="description"
			type="text"
			matInput
			autocomplete="off" />
		<mat-placeholder>
			Description
		</mat-placeholder>
		<mat-error>
			<error-messages [for]="form.get('description')"></error-messages>
		</mat-error>
	</mat-form-field>
</mat-dialog-content>
<mat-dialog-actions class="action-buttons">
	<button mat-raised-button color="primary" [mat-dialog-close]="dialogResult" mat-dialog-close [disabled]="form.invalid">Save</button>
	<button mat-raised-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
