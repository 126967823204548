import { Injectable } from '@angular/core';

import { interval, of } from 'rxjs';
import { catchError, debounce, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { ListResponse } from '@common/models/Generic/ListResponse';
import { TaskListItemDto } from '@common/models/Tasks/List/TaskListItemDto';
import { TasksService } from '@common/services/tasks.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash-es';

import { IAppState } from '../../app.state';
import { EntityChangedEventService } from '../../entity-change-event.service';
import { insertRecords, loading, removeRecords, TaskListActions, updateRecords } from './task-list.actions';

@Injectable()
export class TaskListEffects {
	loadTasks$ = createEffect(() =>
		this._actions$.pipe(
			ofType(
				TaskListActions.Load,
				TaskListActions.SetPageIndex,
				TaskListActions.SetPageSize,
				TaskListActions.SetSortBy,
				TaskListActions.SetSortDirection,
				TaskListActions.SetFilters
			),
			withLatestFrom(this._store),
			filter(([_, state]) => !isEqual(state.taskList.request, state.taskList.oldRequest)),
			tap(() => this._store.dispatch(loading())),
			debounce(() => interval(200)),
			switchMap(([_, state]) => {
				return this._tasksService.getTaskList(state.taskList.request).pipe(
					map((response: ListResponse<TaskListItemDto>) => {
						return { type: TaskListActions.LoadSuccess, response };
					}),
					catchError(error => of({ type: TaskListActions.LoadFailed, error }))
				);
			})
		)
	);

	tasksChanged$ = createEffect(
		() =>
			this._actions$.pipe(
				ofType(insertRecords, updateRecords, removeRecords),
				tap(action => {
					this._entityChangedEventService.fireEntityChangedEvent(action.response);
				})
			),
		{ dispatch: false }
	);

	constructor(
		private _actions$: Actions,
		private _store: Store<IAppState>,
		private _tasksService: TasksService,
		private _entityChangedEventService: EntityChangedEventService
	) {}
}
