<h2 mat-dialog-title>
	<span>Filters</span>
	<button mat-icon-button mat-dialog-close>
		<mat-icon>close</mat-icon>
	</button>
</h2>
<mat-dialog-content>
	<document-tag-list-filter [request]="request" (filterChange)="rootFilterChange($event)"></document-tag-list-filter>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-button throttleButton (throttledClick)="clear()">Document List View</button>
	<button mat-raised-button color="primary" throttleButton (throttledClick)="apply()">Apply</button>
</mat-dialog-actions>
