import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { filter, forkJoin, Subscription, switchMap, tap } from 'rxjs';

import { MatterStaffMemberCreateUpdateDto } from '@common/models/Matters/Item/MatterStaffMemberCreateUpdateDto';
import { CostTemplateListItemDto } from '@common/models/Settings/CostTemplates/List/CostTemplateListItemDto';
import { UserTypeListItemDto } from '@common/models/Settings/UserTypes/List/UserTypeListItemDto';
import { UserViewDto } from '@common/models/Users/Item/UserViewDto';
import { NotificationService } from '@common/notification';
import { MattersService } from '@common/services/matters.service';
import { CostTemplatesService } from '@common/services/settings/costtemplates.service';
import { UsersService } from '@common/services/settings/users.service';
import { UserTypesService } from '@common/services/settings/usertypes.service';
import { CustomValidators } from '@common/validation/custom.validators';
import { first } from 'lodash-es';

@Component({
	selector: 'matter-member-dialog.component',
	styleUrls: ['./matter-member-dialog.component.scss'],
	templateUrl: './matter-member-dialog.component.html'
})
export class MatterMemberDialogComponent implements OnInit, OnDestroy {
	private subscriptions = new Subscription();
	form: FormGroupTyped<MatterStaffMemberCreateUpdateDto>;
	userTypes: UserTypeListItemDto[] = [];
	costTemplates: CostTemplateListItemDto[] = [];
	matterCostTemplateId: string = null;

	get currentUserType(): UserTypeListItemDto {
		if (!!this.form.controls.userTypeId?.value) {
			return first(this.userTypes.filter(x => !!x?.id && x.id == this.form.controls.userTypeId.value));
		}
		return null;
	}

	selectedUser: UserViewDto = null;
	blockingUserTypeChangeDetection: boolean = false;

	get isCreateMode(): boolean {
		return !this._data?.contactId;
	}

	constructor(
		@Inject(MAT_DIALOG_DATA) private _data: IMatterMemberDialogComponentData,
		private userTypesService: UserTypesService,
		private costTemplatesService: CostTemplatesService,
		private matterService: MattersService,
		private usersService: UsersService,
		private fb: FormBuilder,
		private notifService: NotificationService,
		private dialogRef: MatDialogRef<MatterMemberDialogComponent>
	) {}

	ngOnInit() {
		this.form = this.fb.group({
			contactId: [this._data?.contactId, [CustomValidators.required('Employee')]],
			userTypeId: this._data?.userTypeId,
			rate: this._data?.rate,
			isMain: false
		}) as FormGroupTyped<MatterStaffMemberCreateUpdateDto>;

		this.subscriptions.add(
			forkJoin([
				this.costTemplatesService.getCostTemplateList(),
				this.userTypesService.getUserTypeList()
			]).subscribe(([costTemplateResponse, userTypesReponse]) => {
				this.costTemplates = costTemplateResponse.records;
				this.userTypes = [null].concat(userTypesReponse.records);
			})
		);

		this.subscriptions.add(
			this.form.controls.contactId.valueChanges
				.pipe(
					tap(incomingContactId => {
						const currentContactId = this.form.value.contactId;

						if (!!currentContactId) {
							if (currentContactId != incomingContactId) {
								this.form.controls.userTypeId.setValue(null);
								this.form.controls.rate.setValue(null);
							}
						}

						if (currentContactId != incomingContactId) {
							{
								this.selectedUser = null;
							}
						}
					}),
					filter(Boolean),
					switchMap(selectedContactId => this.usersService.getUserByContactId(selectedContactId))
				)
				.subscribe(selectedUser => {
					this.selectedUser = selectedUser;
				})
		);
	}
	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	save() {
		var dto: MatterStaffMemberCreateUpdateDto = this.form.value;

		if (this._data?.matterId) {
			this.subscriptions.add(
				this.matterService.updateMatterStaffMember(this._data.matterId, dto).subscribe({
					next: response => this.dialogRef.close(response),
					error: err => this.notifService.showErrors('Error updating team member', err)
				})
			);
		} else {
			this.dialogRef.close({
				userType: this.currentUserType,
				rate: dto?.rate,
				contactReference: { fullName: this.selectedUser.contact.name, ...this.selectedUser.contact },
				isMain: dto?.isMain
			});
		}
	}
}

export interface IMatterMemberDialogComponentData {
	matterId?: string;
	contactId?: string;
	userTypeId?: string;
	rate?: number;
}
