import { Component, ViewChild } from '@angular/core';

import { ActivityLogListRequest } from '@common/models/ActivityLogs/List/ActivityLogListRequest';

import { ActivityLogListFilterDialogComponent } from '../filter-dialog/activity-log-list-filter-dialog.component';
import { ActivityLogListFilterComponent } from '../filter/activity-log-list-filter.component';
import { ActivityLogListFilterConfig } from '../filter/ActivityLogListFilterConfig';
import { ActivityLogListComponent } from './activity-log-list.component';

@Component({
	selector: 'global-activity-log-list',
	styleUrls: ['./global-activity-log-list.component.scss'],
	templateUrl: './global-activity-log-list.component.html'
})
export class GlobalActivityLogListComponent {
	@ViewChild(ActivityLogListFilterComponent, { static: true })
	filter: ActivityLogListFilterComponent;
	@ViewChild('list')
	activityLogList: ActivityLogListComponent;

	filterDialog = ActivityLogListFilterDialogComponent;

	filterRequest: Partial<ActivityLogListRequest> = {
		search: null,
		timestampStart: null,
		timestampEnd: null,
		relatedToType: null
	};

	get config(): ActivityLogListFilterConfig {
		return {
			request: this.filterRequest
		};
	}
}
