<mat-card>
	<mat-card-header> </mat-card-header>
	<mat-card-content class="content">
		<div *ngIf="connectionStatus === 'UserAndTenantConnected'" class="status">
			<span class="title">Connection Status:</span>
			<span><span class="green">Connected</span> to </span><a [href]="xeroOrganisationDirectUrl">{{ xeroSettings.xeroTenant.xeroTenantName }}</a>
		</div>
		<div *ngIf="connectionStatus === 'TenantConnected'" class="status">
			<span class="title">Connection Status:</span>
			<span><span class="red">Disconnected</span> from </span><a [href]="xeroOrganisationDirectUrl">{{ xeroSettings.xeroTenant.xeroTenantName }}</a>
		</div>
		<div *ngIf="showConnectButton" class="connect">
			<span>Connect {{ siteNme }} with Xero to synchronise invoices and payments</span>
			<button mat-flat-button throttleButton (throttledClick)="connectToXeroClicked()"><img src="/assets/img/xero/connect-blue.svg" /></button>
		</div>
		<div *ngIf="showSettings" class="settings">
			<xero-settings
				#xeroSettingsControl
				[xeroSettings]="xeroSettings"
				[showOrganisationList]="false"
				(isValidChange)="onValidChange($event)"
			></xero-settings>
		</div>
	</mat-card-content>
	<mat-card-footer *ngIf="showSettings">
		<button
			mat-raised-button
			color="primary"
			throttleButton
			(throttledClick)="saveClicked()"
			[disabled]="invalid || connectionStatus !== 'UserAndTenantConnected'"
		>
			Save
		</button>
		<button *ngIf="this.xeroSettings?.xeroTenant" mat-stroked-button color="warn" class="reset-button" throttleButton (throttledClick)="removeXeroSync()">
			Remove Xero Sync
		</button>
	</mat-card-footer>
</mat-card>
