import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output
} from '@angular/core';
import { FormBuilder, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';

import { EntityReference } from '@common/models/Common/EntityReference';
import { TrustAccountListItemDto } from '@common/models/Settings/TrustSettings/TrustAccounts/List/TrustAccountListItemDto';
import { MatterAllocationWithReference } from '@common/models/Trust/Common/MatterAllocationWithReference';
import { TrustOpeningBalanceReceiptCreateDto } from '@common/models/Trust/Item/TrustOpeningBalanceReceiptCreateDto';
import { TrustViewDto } from '@common/models/Trust/Item/TrustViewDto';
import { DomainError } from '@common/models/Validation/DomainError';
import { NotificationService } from '@common/notification';
import { TrustAccountsCachedService } from '@common/services/settings/trustaccounts-cached.service';
import { TrustAccountsService } from '@common/services/settings/trustaccounts.service';
import { TrustService } from '@common/services/trust.service';
import { map } from 'lodash';

import { round } from 'app/shared/utils/mathUtil';
import { CustomValidators } from '@common/validation/custom.validators';

import { BaseTrustRecordComponent } from '../base-trust-record.component';

@Component({
	selector: 'trust-opening-balance-receipt',
	styleUrls: ['./trust-opening-balance-receipt.scss'],
	templateUrl: './trust-opening-balance-receipt.html'
})
export class TrustOpeningBalanceReceiptComponent
	extends BaseTrustRecordComponent<TrustOpeningBalanceReceiptCreateDto>
	implements OnInit, AfterViewInit, OnDestroy
{
	@Input()
	isReadOnly?: boolean;
	@Input()
	matterAllocationList: FormArray;
	@Output()
	dialogCloseRequested = new EventEmitter<null>();
	matterAllocations: MatterAllocationWithReference[];
	warningText: string;
	saveClicked: boolean;

	get matterAllocationControls() {
		if (this.form && this.form.controls.matterAllocations) {
			return (this.form.controls.matterAllocations as AbstractControl as FormArray).controls as FormGroup[];
		}
		return null;
	}

	constructor(
		private fb: FormBuilder,
		private service: TrustService,
		private cdr: ChangeDetectorRef,
		trustAccountService: TrustAccountsService,
		trustAccountsCachedService: TrustAccountsCachedService,
		private dialogRef: MatDialogRef<TrustOpeningBalanceReceiptComponent>,
		private notifService: NotificationService
	) {
		super(trustAccountService, trustAccountsCachedService);
	}

	ngOnInit(): void {
		this.transactionType = 'Receipt';
		const uniqueValidator = CustomValidators.uniqueArrayField('matterId', 'Matter allocations should be unique');
		const nonEmptyValidator = CustomValidators.nonEmptyArray();
		this.form = this.fb.group({
			amount: [null, [CustomValidators.required('Amount')]],
			date: [null, CustomValidators.required('Date')],
			matterAllocations: this.fb.array(
				[],
				[c => uniqueValidator(c), c => nonEmptyValidator(c), c => this.matterAllocationsSumValidator(c)]
			),
			number: null,
			transactionType: this.transactionType,
			trustAccountId: [null, CustomValidators.required('Trust Account')]
		}) as FormGroupTyped<TrustOpeningBalanceReceiptCreateDto>;

		this.addAllocation();

		this.subscription.add(
			this.form.controls.amount.valueChanges.subscribe(amount => {
				if (this.form.controls.matterAllocations.touched) {
					this.form.controls.matterAllocations.updateValueAndValidity();
				}
			})
		);
		super.ngOnInit();

		if (!!this.editId) {
			this.subscription.add(
				this.service.getTrustRecord(this.editId).subscribe((response: TrustViewDto) => {
					const trustRecord = this.convertToTrustReceiptPaymentCreateDto(response);
					this.form.patchValue(trustRecord);
					if (this.isReadOnly) {
						this.matterAllocations = response.matterAllocations;
					}
				})
			);
		}
	}

	ngAfterViewInit(): void {
		if (this.isReadOnly) this.form.disable();

		this.cdr.detectChanges();
	}

	initAllocation(): FormGroup {
		return this.fb.group({
			amount: [null, CustomValidators.required('Amount')],
			matterId: [null, CustomValidators.required('Matter')]
		});
	}

	addAllocation() {
		const control = this.form.controls['matterAllocations'] as AbstractControl as FormArray;
		control.push(this.initAllocation());
	}

	removeAllocation(index: number) {
		const control = this.form.controls['matterAllocations'] as AbstractControl as FormArray;
		control.removeAt(index);
		setTimeout(() => {
			this.form.controls.matterAllocations.updateValueAndValidity();
		}, 0);
	}

	trustAccountChanged(event: MatOptionSelectionChange, item: TrustAccountListItemDto) {
		setTimeout(() => {
			if (!!this.form.controls.matterAllocations) this.form.controls.matterAllocations.updateValueAndValidity();
		}, 0);
		super.trustAccountChanged(event, item);
	}

	onSave() {
		const request = this.form.value;
		// Since clearance date property is inside media details property, need to transform it manually.
		// Auto-transform in the service doesn't work with nested properties.
		return this.service.createOpeningBalanceReceipt(request).subscribe(
			(result: EntityReference) => {
				this.notifService.showNotification(`Opening Balance Receipt created succesfully`);
				this.dialogRef.close(result);
			},
			(errs: DomainError[]) => {
				this.notifService.showErrors(`Error creating Opening Balance Receipt`, errs);
				this.saveClicked = false;
			}
		);
	}

	closeDialog() {
		this.dialogCloseRequested.emit();
	}

	private matterAllocationsSumValidator: ValidatorFn = (): ValidationErrors => {
		if (!this.form) return null;
		this.warningText = '';

		const allocatedAmount = this.form.controls.matterAllocations.value
			.map(x => x.amount)
			.reduce((sum: number, current: number) => round(sum + current), 0);

		const totalAmount = this.form.controls.amount.value;

		if (allocatedAmount < totalAmount) {
			return { error: `The allocated Amount is less than the total amount on the ${this.transactionType}` };
		} else if (allocatedAmount > totalAmount) {
			return { error: `The allocated Amount is greater than the total amount on the ${this.transactionType}` };
		} else {
			return null;
		}
	};

	private convertToTrustReceiptPaymentCreateDto(viewDto: TrustViewDto) {
		const dto: Partial<TrustOpeningBalanceReceiptCreateDto> = {
			amount: viewDto.amount,
			date: viewDto.date,
			isCancelled: viewDto.isCancelled,
			isReversal: viewDto.isReversal,
			matterAllocations: map(viewDto.matterAllocations, m => {
				return {
					amount: m.amount,
					matterId: m.matter.id,
					description: m.description
				};
			}),
			number: viewDto.number,
			relevantContactId: viewDto.relevantContactId,
			transactionType: viewDto.transactionType,
			trustAccountId: viewDto.trustAccountId
		};
		return dto;
	}
}
