// This file has been generated from CustomReportController.cs
// Do not modify this file directly!!
// tslint:disable
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { TrimStringsOnObject } from '@common/utils/string-format';

import { MutationResponseDto } from '../models/Common/MutationResponseDto';
import { CustomReportCreateUpdateDto } from '../models/CustomReports/Item/CustomReportCreateUpdateDto';
import { CustomReportViewDto } from '../models/CustomReports/Item/CustomReportViewDto';
import { CustomReportListItemDto } from '../models/CustomReports/List/CustomReportListItemDto';
import { CustomReportListRequest } from '../models/CustomReports/List/CustomReportListRequest';
import { ListResponse } from '../models/Generic/ListResponse';

@Injectable({
	providedIn: 'root'
})
export class CustomReportService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'api/v1/CustomReport', 'CustomReport');
	}

	createCustomReport(dto: CustomReportCreateUpdateDto): Observable<MutationResponseDto> {
		return this.post<CustomReportCreateUpdateDto, MutationResponseDto>(
			'',
			TransformDatesOnObject(CustomReportCreateUpdateDto, TrimStringsOnObject(dto))
		);
	}

	getCustomReportList(request?: Partial<CustomReportListRequest>): Observable<ListResponse<CustomReportListItemDto>> {
		return this.getList<CustomReportListRequest, CustomReportListItemDto>('', request);
	}

	getCustomReport(id: string): Observable<CustomReportViewDto> {
		return this.getItem<void, CustomReportViewDto>(`${id}`);
	}

	updateCustomReport(id: string, dto: CustomReportCreateUpdateDto): Observable<MutationResponseDto> {
		return this.put(`${id}`, TransformDatesOnObject(CustomReportCreateUpdateDto, TrimStringsOnObject(dto)));
	}

	deleteCustomReport(id: string): Observable<MutationResponseDto> {
		return this.delete(`${id}`);
	}
}
