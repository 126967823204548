import { DocumentTagListItemDto } from '@common/models/Settings/DocumentTags/List/DocumentTagListItemDto';
import { createSelector, MemoizedSelector } from '@ngrx/store';

import { IAppState } from '../../../app.state';

export const selectDocumentTagList = (state: IAppState) => state.documentTagList;

export const selectDocumentTagListRecords: MemoizedSelector<IAppState, DocumentTagListItemDto[]> = createSelector(
	selectDocumentTagList,
	state => state?.list?.records || []
);
export const selectDocumentTagListRequest: MemoizedSelector<IAppState, Partial<any>> = createSelector(
	selectDocumentTagList,
	state => state?.request
);
export const selectDocumentTagListPageIndex = createSelector(
	selectDocumentTagList,
	state => state?.list?.pageIndex || 0
);
export const selectDocumentTagListTotalRecords = createSelector(
	selectDocumentTagList,
	state => state?.list?.totalRecords || 0
);
export const selectDocumentTagIsFetching = createSelector(selectDocumentTagList, state => !!state?.isFetching);
