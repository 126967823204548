import { Component, Input } from '@angular/core';

@Component({
	selector: 'font-option',
	styleUrls: ['font-option.component.scss'],
	templateUrl: 'font-option.component.html'
})
export class FontOptionComponent {
	@Input() FormGroup: FormGroupTyped<IFontOption>;
	@Input() DisplaySettings: IFontOptionComponentDisplaySettings;

	constructor() {
		if (this.DisplaySettings == null) this.DisplaySettings = { isDisplayStrikeoutOption: false };
	}

	toggleBold(): void {
		this.FormGroup.controls.isBold.setValue(!this.isBold);
		this.FormGroup.markAsDirty();
	}

	toggleItalic(): void {
		this.FormGroup.controls.isItalic.setValue(!this.isItalic);
		this.FormGroup.markAsDirty();
	}

	toggleUnderline(): void {
		this.FormGroup.controls.isUnderline.setValue(!this.isUnderline);
		this.FormGroup.markAsDirty();
	}

	toggleStrikeout(): void {
		this.FormGroup.controls.isStrikeout.setValue(!this.isStrikeout);
		this.FormGroup.markAsDirty();
	}

	get isBold(): boolean {
		return this.FormGroup.controls.isBold.value;
	}

	get isItalic(): boolean {
		return this.FormGroup.controls.isItalic.value;
	}

	get isUnderline(): boolean {
		return this.FormGroup.controls.isUnderline.value;
	}

	get isStrikeout(): boolean {
		return this.FormGroup.controls.isStrikeout.value;
	}

	get isHideStrikeoutOption(): boolean {
		return !this.DisplaySettings.isDisplayStrikeoutOption;
	}
}

export interface IFontOption {
	// FontSize
	fontSize: number;
	// IsBold
	isBold: boolean;
	// IsItalic
	isItalic: boolean;
	// IsUnderline
	isUnderline: boolean;
	// IsStrikeout
	isStrikeout: boolean;
}

export interface IFontOptionComponentDisplaySettings {
	isDisplayStrikeoutOption: boolean;
}
