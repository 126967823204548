import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { XeroIntegrationSettings } from '@common/models/Settings/BillSettings/Common/XeroIntegrationSettings';

import { XeroSettingsComponent } from '../xero-settings.component';

@Component({
	selector: 'xero-settings-dialog',
	templateUrl: './xero-settings-dialog.component.html'
})
export class XeroSettingsDialogComponent {
	@ViewChild('xeroSettingsControl', { static: true })
	xeroSettingsControl: XeroSettingsComponent;

	invalid: boolean = true;

	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: { xeroSettings: XeroIntegrationSettings }
	) {}

	onValidChange(valid: boolean): void {
		this.invalid = !valid;
	}
}
