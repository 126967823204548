// This file has been generated from TrustReceiptPaymentUpdateDto.cs
// Do not modify this file directly!!
// tslint:disable

import { Moment } from 'moment-timezone';
import { DateFormat, SetDateFormat } from '@common/utils/date-format';

import { SetComplexType, SetSimpleType } from '@common/utils/class-transform';

import { CompanyContactCreateUpdateDto } from '../../Contacts/Item/CompanyContactCreateUpdateDto';
import { PersonContactCreateUpdateDto } from '../../Contacts/Item/PersonContactCreateUpdateDto';
import { PluralContactCreateUpdateDto } from '../../Contacts/Item/PluralContactCreateUpdateDto';
import { TransactionType } from '../Common/TransactionType';
import { TrustRecordMediaDetails } from '../Common/TrustRecordMediaDetails';

// LawMaster.Amalthea.Domain.Trust.Item.TrustReceiptPaymentUpdateDto
export class TrustReceiptPaymentUpdateDto {

	// Date
	@SetDateFormat(DateFormat.LocalDate)
	date: Moment;
	// TrustAccountId
	trustAccountId: string;
	// RelevantContactId
	relevantContactId: string;
	// TransactionType
	transactionType: keyof typeof TransactionType;
	// MatterFromId
	matterFromId: string;
	// MatterToId
	matterToId: string;
	// CreatePersons
	createPersons: { [key: string]: PersonContactCreateUpdateDto; };
	// CreateCompanies
	createCompanies: { [key: string]: CompanyContactCreateUpdateDto; };
	// CreatePlurals
	createPlurals: { [key: string]: PluralContactCreateUpdateDto; };
	// Number
	number: string;
	// IsCancelled
	@SetSimpleType(Boolean)
	isCancelled: boolean;
	// IsReversal
	@SetSimpleType(Boolean)
	isReversal: boolean;
	// MediaDetails
	@SetComplexType(TrustRecordMediaDetails)
	mediaDetails: TrustRecordMediaDetails;
	// Attachments
	attachments: File[];
	// RelevantContactFreeTextName
	relevantContactFreeTextName: string;
	// DocumentIdsToRemove
	documentIdsToRemove: string[];

	constructor() {
		this.mediaDetails = new TrustRecordMediaDetails();
	}
}