<h2 mat-dialog-title class="title">{{ isCreateMode ? 'Create' : 'Edit' }} Cost Template</h2>
<mat-dialog-content [formGroup]="form" class="flex-column">
	<div class="flex-row">
		<mat-form-field>
			<mat-placeholder
				>Name
				<sup class="color-warn">*</sup>
			</mat-placeholder>
			<input #costTemplateInput matInput formControlName="name" autocomplete="off" />
			<mat-error>
				<error-messages [for]="form.controls.name"></error-messages>
			</mat-error>
		</mat-form-field>
	</div>
	<div class="flex-row">
		<mat-form-field>
			<mat-placeholder>Description </mat-placeholder>
			<textarea matInput formControlName="description"></textarea>
		</mat-form-field>
	</div>

	<div class="info-message color-accent">
		<mat-icon color="accent">info</mat-icon>
		<span> If a Charge Rate is not defined on a user type, then the charge rate on the user profile will be used</span>
	</div>

	<div class="container" *ngIf="userTypes?.length">
		<span class="description"> User Type </span>
		<span class="description"> Charge Rate/ hour </span>
	</div>
	<span class="description" *ngIf="!userTypes?.length">
		User types must be created before an hourly rate can be set.
		<a [routerLink]="['/system/user-types']" throttleButton (throttledClick)="closeDialog()">Go to User Types</a>
	</span>

	<div *ngFor="let userType of userTypes">
		<div class="container">
			<span [ngClass]="getTypeRateControl(userType.id)?.value === null ? 'lighten-row' : 'highlight-row'">{{ userType.name }} </span>
			<mat-form-field floatLabel="never">
				<mat-placeholder>Charge Rate not defined </mat-placeholder>
				<span matPrefix>$&nbsp;</span>
				<input #input matInput type="number" [formControl]="getTypeRateControl(userType.id)" autocomplete="off" />
			</mat-form-field>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<div>
		<button mat-raised-button color="primary" throttleButton (throttledClick)="save()" [disabled]="form.invalid">Ok</button>
		<button mat-raised-button mat-dialog-close>Cancel</button>
	</div>
</mat-dialog-actions>
