// This file has been generated from MatterTrustBalanceReportRequestDto.cs
// Do not modify this file directly!!
// tslint:disable

import { Moment } from 'moment-timezone';
import { DateFormat, SetDateFormat } from '@common/utils/date-format';



// LawMaster.Amalthea.Domain.Matters.Item.MatterTrustBalanceReportRequestDto
export class MatterTrustBalanceReportRequestDto {

	// DateStart
	@SetDateFormat(DateFormat.LocalDate)
	dateStart: Moment;
	// DateEnd
	@SetDateFormat(DateFormat.LocalDate)
	dateEnd: Moment;
	// MatterId
	matterId: string;
	// FileName
	fileName: string;
	// TemplateId
	templateId: string;

	constructor() {

	}
}