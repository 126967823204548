import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { JobsService } from '@common/services/settings/jobs.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import moment from 'moment';

import { IAppState } from '../../app.state';
import { JobStatusFilterActions, loading } from './job-status-filter.actions';

@Injectable()
export class JobStatusFilterEffects {
	loadJobStatuses$ = createEffect(() =>
		this._actions$.pipe(
			ofType(JobStatusFilterActions.Load, JobStatusFilterActions.InvalidateCache),
			map(action => action as { type: JobStatusFilterActions }),
			withLatestFrom(this._store),
			filter(([action, state]) => {
				const jobStatusState = state.jobStatusFilterData;

				if (action.type === JobStatusFilterActions.InvalidateCache && !jobStatusState.list) {
					return false;
				}

				return !jobStatusState.cacheExpiry || !!moment().isSameOrAfter(jobStatusState.cacheExpiry);
			}),
			debounceTime(500),
			tap(() => this._store.dispatch(loading())),
			switchMap(() =>
				this._jobsService.getStatusList().pipe(
					map(response => ({
						type: JobStatusFilterActions.LoadSuccess,
						response
					})),
					catchError(error => of({ type: JobStatusFilterActions.LoadFailed, error }))
				)
			)
		)
	);

	constructor(private _actions$: Actions, private _store: Store<IAppState>, private _jobsService: JobsService) {}
}
