import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { exhaustMap, map } from 'rxjs/operators';

import { GeneralSettingsService } from '@common/services/settings/generalsettings.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ExternalPortalRouteService } from 'app/services/external-portal-route-service';

import { setTenantCompany, TenantCompanyActions } from './tenant-company.actions';

@Injectable()
export class TenantCompanyEffects {
	loadTenantCompany$ = createEffect(() =>
		this.actions$.pipe(
			ofType(TenantCompanyActions.RefreshTenantCompany),
			exhaustMap(() =>
				this.portalRouteService.isUserRoute
					? this.generalSettingService
							.getTenantContact()
							.pipe(map(tenantCompany => setTenantCompany({ regionCode: tenantCompany.regionCode })))
					: of(setTenantCompany({ regionCode: null }))
			)
		)
	);

	constructor(
		private actions$: Actions,
		private generalSettingService: GeneralSettingsService,
		private portalRouteService: ExternalPortalRouteService
	) {}
}
