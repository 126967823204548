import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { CurrentTaskDto } from '@common/models/Tasks/List/CurrentTaskDto';
import { CurrentUserViewDto } from '@common/models/Users/Item/CurrentUserViewDto';
import { CustomizeUserListColumnsFieldsDto } from '@common/models/Users/Item/CustomizeUserListColumnsFieldsDto';
import { UpdateUserDto } from '@common/models/Users/Item/UpdateUserDto';
import { UserNotificationOptions } from '@common/models/Users/PersistenceLayer/UserNotificationOptions';
import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { TrimStringsOnObject } from '@common/utils/string-format';

import { UserRecentItemDto } from '../models/ActivityLogs/List/UserRecentItemDto';
import { ContactLookupDto } from '../models/Contacts/Lookup/ContactLookupDto';
import { CurrentTaskResponse } from '../models/Tasks/List/CurrentTaskResponse';
import { UserLoginOptions } from '../models/UserLoginOptions';
import { OutlookUpdateCurrentUserDto } from '../models/Users/Item/OutlookUpdateCurrentUserDto';
import { UserInfoTrackTokenDto } from '../models/Users/Item/UserInfoTrackTokenDto';

@Injectable({
	providedIn: 'root'
})
export class UserCurrentService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'api/v1/users/current', 'Current User');
	}

	getCurrentUser(): Observable<CurrentUserViewDto> {
		return this.getItem<void, CurrentUserViewDto>('');
	}

	getRecentlyModified(): Observable<UserRecentItemDto[]> {
		return this.getArray<void, UserRecentItemDto>(`GetRecentlyModified`);
	}

	getCurrentTasks(dto: CurrentTaskDto): Observable<CurrentTaskResponse> {
		return this.getItem<CurrentTaskDto, CurrentTaskResponse>(`GetCurrentTasks`, dto);
	}

	getLoginOptions(): Observable<UserLoginOptions> {
		return this.getItem<void, UserLoginOptions>(`GetLoginOptions`);
	}

	linkAuthProviderUrl(providerName: string, redirectUrl: string): Observable<string> {
		return this.getItem<any, string>(`LinkAuthProviderUrl`, { providerName, redirectUrl });
	}

	unlinkAuthProviderForCurrentUser(providerName: string): Observable<any> {
		return this.post<void, any>(`UnlinkAuthProviderForCurrentUser/${providerName}`);
	}

	updateOutlookSettings(dto: OutlookUpdateCurrentUserDto): Observable<any> {
		return this.put(
			`UpdateOutlookSettings`,
			TransformDatesOnObject(OutlookUpdateCurrentUserDto, TrimStringsOnObject(dto))
		);
	}

	updateLastCheckedJobs(): Observable<any> {
		return this.put(`UpdateLastCheckedJobs`, null, true);
	}

	updateUser(dto: UpdateUserDto): Observable<any> {
		return this.put(`UpdateUser`, TransformDatesOnObject(UpdateUserDto, TrimStringsOnObject(dto)), true);
	}

	getInfoTrackTokens(): Observable<UserInfoTrackTokenDto> {
		return this.getItem<void, UserInfoTrackTokenDto>(`GetInfoTrackTokens`);
	}

	deleteInfoTrackTokens(): Observable<any> {
		return this.delete(`DeleteInfoTrackTokens`);
	}

	getRecentlyAccessedClients(): Observable<ContactLookupDto[]> {
		return this.getArray<void, ContactLookupDto>(`GetRecentlyAccessedClients`);
	}

	getRecentlyAccessedStaffMembers(): Observable<ContactLookupDto[]> {
		return this.getArray<void, ContactLookupDto>(`GetRecentlyAccessedStaffMembers`);
	}

	addRecentlyAccessedClient(contactId: string): Observable<any> {
		return this.post<void, any>(`AddRecentlyAccessedClient/${contactId}`);
	}

	addRecentlyAccessedStaffMember(contactId: string): Observable<any> {
		return this.post<void, any>(`AddRecentlyAccessedStaffMember/${contactId}`);
	}

	setCustomizedListColumns(dto: CustomizeUserListColumnsFieldsDto): Observable<any> {
		return this.put<CustomizeUserListColumnsFieldsDto, any>(
			'SetCustomizedListColumns',
			TransformDatesOnObject(CustomizeUserListColumnsFieldsDto, TrimStringsOnObject(dto))
		);
	}

	markTermsAndConditionsAcknowledged(): Observable<any> {
		return this.put<void, any>(`MarkTermsAndConditionsAcknowledged`);
	}

	getUserNotificationOptions(): Observable<UserNotificationOptions> {
		return this.getItem<void, UserNotificationOptions>('GetUserNotificationOptions');
	}

	updateUserNotificationOptions(dto: UserNotificationOptions): Observable<any> {
		return this.put(
			`UpdateUserNotificationOptions`,
			TransformDatesOnObject(UserNotificationOptions, TrimStringsOnObject(dto)),
			true
		);
	}
}
