<h2 mat-dialog-title>
	<span>File Upload</span>
</h2>
<mat-dialog-content>
	<!-- <div class="tag-actions" *ngIf="fileList.length > 1 && !!allTags?.length">
		<a class="tag-action" throttleButton (throttledClick)="applyTags()">Apply Tags</a>
		<a class="tag-action" throttleButton (throttledClick)="clearTags()">Clear Tags</a>
	</div> -->

	<ng-container *ngIf="!fileUploadStarted && brief">
		<span>
			The below {{ fileList.length > 1 ? 'files' : 'file' }} will be uploaded to the Brief: <b>{{ brief.name }}</b>
		</span>

		<mat-form-field *ngIf="brief && brief?.sections?.length" class="section-dropdown" [formGroup]="form">
			<mat-placeholder> Select Section </mat-placeholder>
			<mat-select #sectionInput formControlName="sectionId">
				<mat-option *ngFor="let section of brief?.sections" [value]="section.id">
					<span>{{ section.name }}</span>
				</mat-option>
			</mat-select>
			<mat-error>
				<error-messages [for]="form.get('practiceType')"></error-messages>
			</mat-error>
		</mat-form-field>
	</ng-container>

	<div *ngFor="let f of files; let i = index" class="item-spacing">
		<div class="flex-row-progress">
			<mat-icon [svgIcon]="fileIcon(f.nativeFile.name)" [ngClass]="fileIcon(f.nativeFile.name)"></mat-icon>
			<div class="full-width">
				<div class="flex-row-progress">
					<input class="file-name" matInput [(ngModel)]="f.name" />
					<span class="file-size-text">{{ f.size | bytes }}</span>
					<mat-icon *ngIf="!fileUploadStarted" class="clickable-icon" throttleButton (throttledClick)="removeFile(i)">cancel</mat-icon>
				</div>
				<div *ngIf="fileUploadStarted" class="flex-row-progress">
					<mat-progress-bar [value]="f.progress?.data?.percentage"> </mat-progress-bar>
				</div>
			</div>
		</div>
		<div class="progress-data" *ngIf="fileUploadStarted">
			<ng-template [ngIf]="!isUploadError(f)">
				<span [class.error]="fileUploadCancelled" *ngIf="f.progress?.data?.percentage !== 100">
					{{ fileUploadCancelled ? 'Upload cancelled' : f.progress?.data?.percentage + '% Uploading...' }}
				</span>
				<span [class.error]="fileUploadCancelled" *ngIf="f.progress?.data?.percentage === 100 && !isUploadCompleted(f)">
					{{ fileUploadCancelled ? 'Upload status unknown' : 'Processing...' }}
				</span>
				<span [class.is-done]="isUploadCompleted(f)" *ngIf="isUploadCompleted(f)">File uploaded successfully</span>
				<span *ngIf="f.progress?.data?.percentage !== 0 && f.progress?.data?.percentage !== 100">
					<span class="light-text">{{ f.progress?.data?.speed | bytes: 2 }}/s </span>
				</span>
				<span *ngIf="f.progress?.data?.percentage !== 0 && f.progress?.data?.percentage !== 100">
					<span class="light-text">remaining {{ f.progress?.data?.timeRemainingHuman }}</span>
				</span>
			</ng-template>
			<ng-template [ngIf]="isUploadError(f)">
				<span class="error">There was an error uploading the file{{ f.errorText ? ': ' + f.errorText : '' }}</span>
			</ng-template>
		</div>

		<div class="flex-row flex-row--end additional-fields" *ngIf="!fileUploadStarted">
			<mat-form-field *ngIf="f.extension !== '.msg' && f.extension !== '.eml'">
				<mat-placeholder>
					Created Date
					<sup class="color-warn">*</sup>
				</mat-placeholder>
				<input [formControl]="getFileCreatedDate(i)" matInput [matDatepicker]="createdDatePicker" autocomplete="off" />
				<mat-datepicker-toggle matSuffix [for]="createdDatePicker"></mat-datepicker-toggle>
				<mat-datepicker #createdDatePicker></mat-datepicker>
				<mat-error>
					<error-messages [for]="getFileCreatedDate(i)"></error-messages>
				</mat-error>
			</mat-form-field>

			<document-tag
				class="document-tags"
				[matterId]="form.get('matterId').value"
				[practiceAreaIds]="form.get('entityType').value === 'Matter' ? form.get('practiceAreaIds').value || [] : ''"
				[(ActiveTags)]="files[i].documentTags"
				[showBulkOptions]="!!files?.length && files.length > 1"
				(BulkAddCalled)="bulkAddTag($event)"
				(BulkRemoveCalled)="bulkRemoveTag($event)"
			></document-tag>
		</div>
	</div>
	<div [formGroup]="form" class="item-spacing">
		<div class="flex-row" *ngIf="isDocumentTemplate">
			<mat-form-field>
				<mat-placeholder
					>Related To
					<sup class="color-warn">*</sup>
				</mat-placeholder>
				<mat-select formControlName="entityType" (selectionChange)="entityTypeChanged($event)">
					<mat-option *ngFor="let key of entityTypeKeys" [value]="key">{{ key | templateEntityType }}</mat-option>
				</mat-select>
				<mat-error>
					<error-messages [for]="form.get('entityType')"></error-messages>
				</mat-error>
			</mat-form-field>
			<mat-form-field *ngIf="displayPracticeArea">
				<mat-placeholder>Practice Areas</mat-placeholder>
				<mat-select formControlName="practiceAreaIds" #practiceAreaIds multiple>
					<mat-option (onSelectionChange)="allOptionSelected($event)" #allOption>All</mat-option>
					<mat-option (onSelectionChange)="optionSelected($event)" *ngFor="let practiceArea of practiceAreas" [value]="practiceArea.id">
						{{ practiceArea.name }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<ng-container *ngIf="displayCategory && isDocumentTemplate">
			<div class="flex-row" *ngIf="!fileUploadStarted">
				<mat-form-field>
					<mat-select placeholder="Group" formControlName="documentCategoryId">
						<mat-option></mat-option>
						<mat-option *ngFor="let category of documentCategories" [value]="category.id">{{ category.name }}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="info-message color-accent">
				<mat-icon color="accent">info</mat-icon>
				<span>
					The selected category {{ displayPracticeArea ? 'and practice area' : '' }} will be applied to
					{{ fileList.length > 1 ? 'all of the files' : 'this file' }}
				</span>
			</div>
		</ng-container>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<div *ngIf="!fileUploadStarted">
		<button mat-raised-button color="primary" [disabled]="form.invalid" throttleButton (throttledClick)="uploadFiles()">Upload</button>
		<button mat-raised-button mat-dialog-close>Cancel</button>
	</div>
	<button *ngIf="fileUploadStarted && !fileUploadComplete && !fileUploadCancelled" throttleButton (throttledClick)="cancelUploads()" mat-button>
		Cancel
	</button>
	<button *ngIf="fileUploadComplete || fileUploadCancelled" mat-raised-button color="primary" [mat-dialog-close]="isAnyUploaded || fileUploadCancelled">
		OK
	</button>
</mat-dialog-actions>
