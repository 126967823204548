<mat-card>
	<section class="page-path"><a [routerLink]="['..']">System</a> / Company Details</section>
	<h1>Company Details</h1>
	<mat-table [dataSource]="datasource" matSort matSortActive="fullName" matSortDirection="asc">
		<ng-container matColumnDef="fullName">
			<mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<div class="flexrow">
					<div *ngIf="row.role?.roleName" class="embedded-role">
						<b>{{ row.role?.roleName }}</b>
					</div>
					<div class="contact-name">
						<a [routerLink]="['/contacts', row.id]">{{ row.fullName }}</a>
						<div *ngIf="!row.fullName" class="view-noitem">no {{ row.role?.roleName | lowercase }} recorded</div>
					</div>
				</div>
				<div class="embedded-contacts-block" *ngIf="row.email || row.phone">
					<span *ngIf="row.phone" class="narrow-column">
						<mat-icon color="accent">phone</mat-icon>
						<a href="tel:{{ row.phone?.rfc3966 }}">{{ row.phone?.number }}</a>
					</span>
					<span *ngIf="row.email" class="wide-column">
						<mat-icon color="accent">email</mat-icon>
						<a href="mailto:{{ row.email }}">{{ row.email }}</a>
					</span>
				</div>
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="email">
			<mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<a href="mailto:{{ row.email }}">{{ row.email }}</a>
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="role">
			<mat-header-cell *matHeaderCellDef>Role</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<div>
					<b> {{ row.role?.roleName }}</b>
					<sup *ngIf="row.role?.mandatory" class="color-warn">*</sup>
				</div>
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="phone">
			<mat-header-cell *matHeaderCellDef mat-sort-header>Phone</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<a *ngIf="!!row.phone?.rfc3966" href="tel:{{ row.phone?.rfc3966 }}">{{ row.phone?.number }}</a>
				<span *ngIf="!row.phone?.rfc3966">{{ row.phone?.number }}</span>
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="address">
			<mat-header-cell *matHeaderCellDef mat-sort-header>Address</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<span>
					{{ row.address?.replace('\n', ', ') }}
					<span *ngIf="!!row.regionCode && row.regionCode != 'AU'">
						{{ countries[row.regionCode]?.toUpperCase() }}
					</span> </span
				>&nbsp;
				<img
					*ngIf="!!row.regionCode && row.regionCode != 'AU'"
					src="/assets/img/country/{{ row.regionCode.toLowerCase() }}.png"
					title="{{ countries[row.regionCode] }}"
					alt="{{ countries[row.regionCode] }}"
					height="16"
					width="16"
				/>
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="practiceType">
			<mat-header-cell *matHeaderCellDef>Practice Type</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<div>{{ row.practiceType | practiceType }}</div>
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="actions">
			<mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<button mat-icon-button [matMenuTriggerFor]="menu" title="Actions">
					<mat-icon>more_vert</mat-icon>
				</button>
				<mat-menu #menu="matMenu">
					<button mat-menu-item throttleButton (throttledClick)="editRelatedContact(row)">
						<mat-icon>edit</mat-icon>
						<span>Assign Company</span>
					</button>
				</mat-menu>
			</mat-cell>
		</ng-container>

		<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
		<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
	</mat-table>
</mat-card>
