// This file has been generated from ContentsPageOptions.cs
// Do not modify this file directly!!
// tslint:disable

import { SetSimpleType } from '@common/utils/class-transform';



// LawMaster.Amalthea.Domain.DocumentBriefs.Item.ContentsPageOptions
export class ContentsPageOptions {

	// IncludeContentsPage
	@SetSimpleType(Boolean)
	includeContentsPage: boolean;
	// HidePageNumbering
	@SetSimpleType(Boolean)
	hidePageNumbering: boolean;

	constructor() {

	}
}