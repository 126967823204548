// This file has been generated from Stage.cs
// Do not modify this file directly!!
// tslint:disable

import { SetSimpleType } from '@common/utils/class-transform';



// LawMaster.Amalthea.Domain.Settings.PracticeAreas.Stage
export class Stage {

	// Id
	id: number;
	// Name
	name: string;
	// Description
	description: string;
	// IsMatterClosed
	@SetSimpleType(Boolean)
	isMatterClosed: boolean;

	constructor() {

	}
}