<button
	mat-icon-button
	class="c-jobs-button"
	[class.c-jobs-button--pending]="hasJobsInProgress$ | async"
	#jobMenuTrigger="matMenuTrigger"
	[matMenuTriggerFor]="jobsMenu"
	(menuOpened)="onMenuOpened()"
	(menuClosed)="onMenuClosed()"
	[autofocus]="false"
	*ngIf="showJobsMenu"
>
	<mat-chip-list *ngIf="hasJobsDone$ | async">
		<mat-chip color="accent" selected="true" throttleButton (throttledClick)="buttonTriggerReference.toggleMenu()">!</mat-chip>
	</mat-chip-list>

	<div class="c-jobs-button__icon">
		<div class="c-jobs-button__icon__background">
			<svg viewBox="0 0 24 24">
				<circle cx="50%" cy="50%" r="11px"></circle>
			</svg>
		</div>

		<div class="c-jobs-button__icon__content">
			<mat-icon>work</mat-icon>
		</div>
	</div>
</button>

<mat-menu #jobsMenu="matMenu" class="jobs-menu speech-menu" [overlapTrigger]="false" xPosition="before">
	<div class="jobs-list">
		<button mat-menu-item disabled>
			<span>Scheduled Jobs</span>
		</button>
		<ng-template ngFor let-job [ngForOf]="jobsList$ | async">
			<button
				mat-menu-item
				class="mat-menu-text job-button"
				[ngClass]="!job.targetEntityId ? 'not-finished' : ''"
				[class.clickable]="canClick(job)"
				throttleButton
				(throttledClick)="onJobClick(job)"
			>
				<div class="job-card">
					<div class="job-row1">
						<mat-icon svgIcon="file-pdf"></mat-icon><span class="title">{{ job.title }}</span>
					</div>
					<div class="job-row2">
						<mat-progress-bar mode="determinate" [color]="progressBarColour(job)" [value]="job.progressPercentage"></mat-progress-bar>
					</div>
					<div [ngClass]="job.errors.length > 0 ? 'error job-row3' : 'job-row3'">
						<span class="comment">{{ job.errors.length > 0 ? job.errors[0] : job.progressComment || job.status }}</span>
						<span class="updated">{{ humanizeTimestamp(job.lastUpdateUtc) }}</span>
					</div>
				</div>
			</button>
		</ng-template>
		<a mat-menu-item [routerLink]="['/system/jobs/my']" class="additional-action view-all">
			<mat-icon class="view-all-icon">search</mat-icon>View all scheduled jobs
		</a>
	</div>
</mat-menu>
