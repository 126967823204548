import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { XeroInvoiceReferenceVariables } from '@common/models/Bills/Common/XeroInvoiceReferenceVariables';
import { CustomFieldType } from '@common/models/Settings/CustomFields/Common/CustomFieldType';
import { MatterCustomFieldListItemDto } from '@common/models/Settings/CustomFields/List/MatterCustomFieldListItemDto';
import { MatterCustomFieldsService } from '@common/services/customfields-matter.service';
import { newGuid } from '@common/utils/create-guid';
import { isEmptyOrWhitespace } from '@common/utils/stringUtils';
import { groupBy, includes, isNil, join, sortBy } from 'lodash-es';
import * as moment from 'moment-timezone';

@Component({
	selector: 'xero-invoice-reference-dialog',
	styleUrls: ['./xero-invoice-reference-dialog.component.scss'],
	templateUrl: './xero-invoice-reference-dialog.component.html'
})
export class XeroInvoiceReferenceDialogComponent implements OnInit, OnDestroy {
	private subscriptions = new Subscription();

	form: FormGroup;

	errorMessage: string;
	separator: string = ',';
	items: IDrapDropData[] = [];
	basket: { name: string; items: IDrapDropData[] }[] = [];
	trustCreditAmountKey: keyof typeof XeroInvoiceReferenceVariables = 'TrustCreditAmount';
	private availableVariable: IDrapDropData[] = [
		{
			id: newGuid(),
			group: 'System',
			variableKey: 'LawyerName',
			title: XeroInvoiceReferenceVariables.LawyerName,
			example: 'Smith, John'
		},
		{
			id: newGuid(),
			group: 'System',
			variableKey: 'LawyerShortName',
			title: XeroInvoiceReferenceVariables.LawyerShortName,
			example: 'Smith J'
		},
		{
			id: newGuid(),
			group: 'System',
			variableKey: 'MatterNumber',
			title: XeroInvoiceReferenceVariables.MatterNumber,
			example: '167'
		},
		{
			id: newGuid(),
			group: 'System',
			variableKey: 'MatterTitle',
			title: XeroInvoiceReferenceVariables.MatterTitle,
			example: 'Jones vs. White'
		},
		{
			id: newGuid(),
			group: 'System',
			variableKey: 'TrustCreditAmount',
			title: XeroInvoiceReferenceVariables.TrustCreditAmount,
			example: '1550'
		}
	];

	private static exampleWords: string[] = [
		'Lorem',
		'Ipsum',
		'Dolor',
		'Sit',
		'Amet',
		'Consectetur',
		'Adipiscing',
		'Elit',
		'Integer',
		'At',
		'Lacinia',
		'Urna',
		'In',
		'Aliquet',
		'Tortor'
	];

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private matterCustomFieldsService: MatterCustomFieldsService,
		private formBuilder: FormBuilder
	) {}

	ngOnInit() {
		this.form = this.formBuilder.group({
			basketSearch: null
		});

		this.subscriptions.add(
			this.form
				.get('basketSearch')
				.valueChanges.pipe(debounceTime(250))
				.subscribe(() => this.rebuildBasket())
		);

		this.subscriptions.add(
			this.matterCustomFieldsService
				.getMatterCustomFieldList({
					enabled: true,
					fieldType: Object.keys(CustomFieldType)
						.filter(type => type !== CustomFieldType.Calculated)
						.map(type => type as keyof typeof CustomFieldType)
				})
				.subscribe(fields => {
					const customVariables: IDrapDropData[] = fields.records
						.map(field =>
							field.customFieldGroups.map(group => ({
								id: field.id,
								group: group.name,
								variableKey: field.id,
								title: field.name,
								example: this.getFieldExample(field)
							}))
						)
						.reduce((left, right) => left.concat(right));

					this.availableVariable.push(...customVariables);

					const selectedKeys = this.data.invoiceFormat.split(this.separator).filter((el: string) => {
						return !isNil(el) && el !== '';
					});

					this.items = selectedKeys.map((key: string) => {
						return this.availableVariable.filter(x => x.variableKey === key)[0];
					});

					this.rebuildBasket();
				})
		);
	}

	rebuildBasket() {
		const selectedKeys = this.items.map(data => data.variableKey);
		const search = this.form.get('basketSearch')?.value?.toLowerCase();

		let query = this.availableVariable.filter(item => selectedKeys.indexOf(item.variableKey) === -1);

		if (!isEmptyOrWhitespace(search)) {
			query = query.filter(
				item => item.group.toLowerCase().includes(search) || item.title.toLowerCase().includes(search)
			);
		}

		const groupedBasket = groupBy(query, 'group');

		const basket = Object.keys(groupedBasket).map(key => ({
			name: key,
			items: sortBy(groupedBasket[key], 'title')
		}));

		this.basket = basket.length > 0 ? [basket[0]].concat(sortBy(basket.slice(1), 'name')) : basket;
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	get invoiceFormat(): string {
		return this.items
			? join(
					this.items.map(x => x.variableKey),
					this.separator
			  )
			: null;
	}

	remove(value: IDrapDropData) {
		this.items = this.items.filter(e => e !== value);

		this.rebuildBasket();
		this.validateTrustCreditAmount();
	}

	drop(event: CdkDragDrop<any>) {
		if (event.previousContainer === event.container) {
			moveItemInArray(event.container.data, event.container.data.indexOf(event.item.data), event.currentIndex);
		} else if (event.container.data === this.basket) {
			this.items = this.items.filter(e => e !== event.item.data);
		} else if (event.container.data === this.items) {
			transferArrayItem([event.item.data], event.container.data, 0, event.currentIndex);
		}

		this.rebuildBasket();
		this.validateTrustCreditAmount();
	}

	private validateTrustCreditAmount() {
		if (
			this.items &&
			this.items.length > 1 &&
			includes(
				this.items.map(x => x.variableKey),
				this.trustCreditAmountKey
			)
		) {
			this.errorMessage = `The ${XeroInvoiceReferenceVariables.TrustCreditAmount}
			cannot be used in conjunction with the other variables`;
		} else {
			this.errorMessage = '';
		}
	}

	private random(min: number, max: number) {
		return Math.random() * (max - min) + min;
	}

	private nextExampleWord(): string {
		return XeroInvoiceReferenceDialogComponent.exampleWords[
			Math.round(this.random(0, XeroInvoiceReferenceDialogComponent.exampleWords.length - 1))
		];
	}

	private randomDate() {
		const minDate = moment();
		const maxDate = moment(minDate).add(36, 'M');

		const randomDate = moment(this.random(minDate.valueOf(), maxDate.valueOf()));
		return randomDate;
	}

	private getFieldExample(field: MatterCustomFieldListItemDto): string {
		if (field.fieldType === CustomFieldType.Email) {
			return `${this.nextExampleWord().toLocaleLowerCase()}.${this.nextExampleWord().toLocaleLowerCase()}@${this.nextExampleWord().toLocaleLowerCase()}.com`;
		} else if (field.fieldType === CustomFieldType.Contact) {
			return `${this.nextExampleWord()} ${this.nextExampleWord()}`;
		} else if (field.fieldType === CustomFieldType.Date) {
			return this.randomDate().format('DD/MM/YYYY');
		} else if (field.fieldType === CustomFieldType.Numeric) {
			return `${Math.round(this.random(0, 10000))}`;
		} else if (field.fieldType === CustomFieldType.Currency) {
			return `$${Math.round(this.random(0, 10000))}`;
		} else if (field.fieldType === CustomFieldType.Checkbox) {
			return Math.round(Math.random()) === 1 ? 'Yes' : 'No';
		} else {
			return this.nextExampleWord();
		}
	}
}

export interface IDrapDropData {
	id: string;
	group: string;
	variableKey: string;
	title: string;
	example: string;
}
