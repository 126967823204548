import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { interval } from 'rxjs';

import * as moment from 'moment-timezone';
import { Moment } from 'moment-timezone';

@Component({
	template: `
		<h2 mat-dialog-title>Rate Limit Reached</h2>
		<mat-dialog-content>
			<div>
				<p>
					The application reached the server's rate limit. If this error continues please contact mattero
					support. The rate limit will end at {{ waitTill?.toString() | moment : 'mediumTime' }}.
				</p>
				<p class="focus">The application will automatically reload in {{ retryIn }} seconds.</p>
			</div>
		</mat-dialog-content>
	`,
	styles: [
		`
			p {
				padding-bottom: 8px;
			}
			p.focus {
				font-size: 15px;
			}
		`
	]
})
export class RateLimitDialogComponent implements OnInit {
	waitTill: Moment;
	retryAfter: number;
	retryIn: number;

	constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
		// Take one second off the retry delay in an attempt to restart mattero Angular app before the next rate limit minute starts.
		// This helps ensure that if there is a infinite loop running in the background, it won't trigger rate limit again after the
		// minute has expired.
		this.retryAfter = data?.retryAfter ? data.retryAfter - 1 : 59;

		this.waitTill = moment().add(this.retryAfter, 'seconds');
		this.retryIn = this.waitTill.diff(moment(), 'seconds');
	}

	ngOnInit(): void {
		interval(200).subscribe(() => {
			this.retryIn = this.waitTill.diff(moment(), 'seconds');
		});
		setTimeout(() => window.location.reload(), this.retryAfter * 1000);
	}
}
