import { Component, OnDestroy, OnInit } from '@angular/core';

import {
	combineLatest,
	distinctUntilChanged,
	filter,
	map,
	of,
	startWith,
	Subject,
	Subscription,
	switchMap,
	tap
} from 'rxjs';

import { MatterListItemDto } from '@common/models/Matters/List/MatterListItemDto';
import { GeneralSettingsService } from '@common/services/settings/generalsettings.service';

import { SignalrService } from 'app/core/signalr/signalr.service';
import { WorkTimerService } from 'app/services/worker-timer.service';
import { ICreateMatterTimer, WorkTimerStateManager } from 'app/shared/components/work-timer/WorkTimerStateManager';
import { WorkTimerUtils } from 'app/shared/components/work-timer/WorkTimerUtils';
import { MatDialog } from '@angular/material/dialog';
import { CreateMatterTimerDialogComponent } from '../create-matter-timer-dialog/create-matter-timer-dialog.component';
import { WorkTimerState } from 'app/shared/components/work-timer/WorkTimerState';
import { AlertDialogComponent } from '@common/notification/alert-dialog.component';

@Component({
	selector: 'timers-menu-button',
	styleUrls: ['./timers-menu-button.component.scss'],
	templateUrl: 'timers-menu-button.component.html'
})
export class TimersMenuButtonComponent implements OnInit, OnDestroy {
	private _subscriptions = new Subscription();
	private _menuOpened = new Subject<void>();
	private _timers: MatterListItemDto[] = [];
	private _showButton: boolean;
	private _activeTimer$: Subject<string> = new Subject<string>();
	private timerInterval: number = 500;
	private _storageSubject$: Subject<StorageEvent> = new Subject<StorageEvent>();

	private abortController = new AbortController();

	activeDuration: string = WorkTimerUtils.formatDuration(0);
	durationIntervalId: NodeJS.Timeout | null = null;
	tooltip: string = null;

	get activeTimer$() {
		return this._activeTimer$.asObservable();
	}

	get timers() {
		return this._timers;
	}

	get showButton() {
		return this._showButton;
	}

	constructor(
		private _workTimerService: WorkTimerService,
		private _workTimerStateManager: WorkTimerStateManager,
		private _generalSettingsService: GeneralSettingsService,
		private _signalrService: SignalrService,
		private dialog: MatDialog
	) {}

	ngOnInit(): void {
		this._subscriptions.add(this.fetchMatters$().subscribe());

		this._subscriptions.add(
			this._generalSettingsService
				.getMatterTimerConfig()
				.subscribe(config => this.onTimerConfigChanged(!!config.isGlobalEnabled))
		);

		this._subscriptions.add(
			this._signalrService
				.onTimerConfigChanged()
				.subscribe(dto => this.onTimerConfigChanged(!!dto?.isGlobalEnabled))
		);

		this._subscriptions.add(this._menuOpened.pipe(switchMap(() => this.fetchMatters$())).subscribe());

		this._subscriptions.add(
			combineLatest([
				this._workTimerStateManager.onStartState$.pipe(startWith(null)),
				this._workTimerStateManager.onPauseState$.pipe(startWith(null)),
				this._workTimerStateManager.onResetState$.pipe(startWith(null)),
				this._workTimerStateManager.onClearState$.pipe(startWith(null))
			])
			.pipe(switchMap(() => this.fetchMatters$()))
			.subscribe(() => this._activeTimer$.next(this._workTimerStateManager.getActiveTimerId()))
		);

		this._subscriptions.add(
			this._storageSubject$
				.pipe(
					startWith(null),
					distinctUntilChanged(),
					switchMap($event => {
						if (
							!$event ||
							!$event.oldValue ||
							!$event.newValue ||
							!this._timers.find(
								timer =>
									timer.id === $event.key.substring(WorkTimerStateManager.dataStorageKeyPrefix.length)
							)
						) {
							return this.fetchMatters$();
						}
						this._activeTimer$.next(this._workTimerStateManager.getActiveTimerId());
						return of(true);
					})
				)
				.subscribe()
		);

		this._subscriptions.add(
			this.activeTimer$
				.pipe(
					startWith(this._workTimerStateManager.getActiveTimerId()),
					distinctUntilChanged(),
					tap(timerId => {
						this.clearDurationInterval();

						if (timerId === null) {
							this.activeDuration = WorkTimerUtils.formatDuration(0);
							this.tooltip = null;
							return;
						}

						this.durationIntervalId = setInterval(() => {
							const currentState = this._workTimerStateManager.pollState(timerId);
							const currentDuration = WorkTimerUtils.getCurrentDuration(currentState);
							this.activeDuration = WorkTimerUtils.formatDuration(currentDuration);

							if (!this.tooltip) {
								const timer = this.timers.find(t => t.id === timerId);
								if (!timer) {
									this.tooltip = null;
								} else {
									this.tooltip = `${timer.number} - ${timer.title}`;
								}
							}
						}, this.timerInterval);
					})
				)
				.subscribe()
		);

		window.addEventListener(
			'storage',
			e => {
				if (e.key.startsWith(WorkTimerStateManager.dataStorageKeyPrefix)) {
					this._storageSubject$.next(e);
				}
			},
			{ signal: this.abortController.signal }
		);
	}

	private onTimerConfigChanged(isEnabled: boolean) {
		this._showButton = isEnabled;
		if (!isEnabled) {
			this._workTimerStateManager.pauseOtherTimers();
		}
	}

	clearDurationInterval() {
		if (this.durationIntervalId === null) {
			return;
		}

		clearInterval(this.durationIntervalId);
		this.durationIntervalId = null;
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
		this.clearDurationInterval();
		this.abortController.abort();
	}

	onActionMenuOpened() {
		this._menuOpened.next();
	}

	private fetchMatters$() {
		return this._workTimerService.getMatters$({ pageSize: 5 }).pipe(
			map(response => {
				if (response.totalRecords == 0) {
					return null;
				}

				const timerIds = this._workTimerStateManager.getTimerIds();
				let foundTimers: {
					dto: MatterListItemDto;
					state: WorkTimerState;
				}[] = [];

				let runningTimerId: string = null;

				response.records.forEach(dto => {
					const foundTimerIds = timerIds.filter(id => id.startsWith(dto.id));
					if (foundTimerIds && foundTimerIds.length > 0) {
						foundTimerIds.forEach(foundTimerId => {
							const timerState = this._workTimerStateManager.getState(foundTimerId);
							if (runningTimerId === null && timerState.data.isRunning) {
								runningTimerId = foundTimerId;
							}
							foundTimers.push({
								dto: {
									...dto,
									id: foundTimerId
								},
								state: timerState
							});
						});
					}
				});

				foundTimers = [
					...foundTimers
						.sort((left, right) => {
							const leftActive = !!left.state.data.isRunning;
							const rightActive = !!right.state.data.isRunning;
							if (leftActive != rightActive) {
								return +rightActive - +leftActive;
							}

							const leftDuration = WorkTimerUtils.getCurrentDuration(left.state);
							const rightDuration = WorkTimerUtils.getCurrentDuration(right.state);
							if (leftDuration != rightDuration) {
								return rightDuration - leftDuration;
							}

							return 0;
						})
						.slice(0, 5)
				];

				this._timers = foundTimers.map(foundTimer => foundTimer.dto);
				return runningTimerId;
			}),
			tap(timerId => {
				this._activeTimer$.next(timerId);
			})
		);
	}

	openCreateTimer() {
		this._subscriptions.add(
			this.dialog
				.open(CreateMatterTimerDialogComponent, { width: '600px' })
				.afterClosed()
				.pipe(
					filter(Boolean),
					tap((response: ICreateMatterTimer) => {
						let { matterId, description } = response;
						if (
							this._workTimerStateManager
								.getTimerIds()
								.some(
									id =>
										id.startsWith(matterId) &&
										(
											this._workTimerStateManager.getState(id)?.data?.description || ''
										).toLowerCase() === (description || '').toLowerCase()
								)
						) {
							this.dialog.open(AlertDialogComponent, {
								data: {
									html: 'A timer with this description already exists for this matter.',
									title: 'Multi-timers Error'
								}
							});
							return;
						}
						if (!!this._workTimerStateManager.getState(matterId)) {
							matterId += `-${new Date().getTime()}`;
						}
						this._workTimerStateManager.startState(matterId, null, false, description);
					})
				)
				.subscribe()
		);
	}

	getDescription(timer: MatterListItemDto) {
		const state = this._workTimerStateManager.getState(timer.id);
		return state?.data?.description || '';
	}
}
