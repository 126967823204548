<mat-card>
	<section class="page-path"><a [routerLink]="['..']">System</a> / Practice Areas</section>
	<h1>Practice Areas</h1>
	<button routerLink="create" mat-button color="primary" title="Create New">
		<mat-icon class="create-new">add_circle</mat-icon>
		<span>Create New Practice Area</span>
	</button>
	<mat-table [dataSource]="(isFetching$ | async) ? [] : (data$ | async)" matSort matSortActive="name" matSortDirection="asc">
		<ng-container matColumnDef="name">
			<mat-header-cell *matHeaderCellDef mat-sort-header>Practice Area</mat-header-cell>
			<mat-cell class="embedded-cell" *matCellDef="let row">
				<a [routerLink]="[row.id]">{{ row.name }}</a>
				<div class="embedded-description">
					{{ row.description }}
				</div>
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="description">
			<mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
			<mat-cell *matCellDef="let row">
				{{ row.description }}
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="securityRoles">
			<mat-header-cell *matHeaderCellDef>Security Roles</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<div class="security-roles">
					<a *ngFor="let item of getSecurityRoles(row)" [routerLink]="['..', 'security-roles']">{{ item }}</a>
				</div>
				<span *ngIf="getSecurityRoles(row).length == 0" class="security-roles">Unrestricted</span>
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="isDisabled">
			<mat-header-cell *matHeaderCellDef>Enabled</mat-header-cell>
			<mat-cell *matCellDef="let row">
				{{ !row.isDisabled ? 'Yes' : 'No' }}
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="action">
			<mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<button mat-icon-button [matMenuTriggerFor]="menu" title="Actions">
					<mat-icon>more_vert</mat-icon>
				</button>
				<mat-menu #menu="matMenu">
					<button mat-menu-item throttleButton (throttledClick)="editPracticeArea(row.id)">
						<mat-icon>edit</mat-icon>
						<span>Edit Details</span>
					</button>
					<button *ngIf="!!row.isDisabled" mat-menu-item throttleButton (throttledClick)="enablePracticeArea(row)">
						<mat-icon>visibility</mat-icon>
						<span>Mark as Enabled</span>
					</button>
					<button *ngIf="!row.isDisabled" mat-menu-item throttleButton (throttledClick)="disablePracticeArea(row)">
						<mat-icon>visibility_off</mat-icon>
						<span>Mark as Disabled</span>
					</button>
					<button mat-menu-item throttleButton (throttledClick)="deletePracticeArea(row)">
						<mat-icon svgIcon="delete"></mat-icon>
						<span>Delete</span>
					</button>
				</mat-menu>
			</mat-cell>
		</ng-container>

		<mat-header-row *matHeaderRowDef="(isFetching$ | async) ? [] : (displayColumns | async)"></mat-header-row>
		<mat-row
			*matRowDef="let row; columns: (isFetching$ | async) ? [] : (displayColumns | async)"
			class="practice-areas-row"
			throttleButton
			(throttledClick)="selectRow(row, $event)"
			[ngClass]="{ highlight: row.isHighlighted }"
		></mat-row>
	</mat-table>
	<mat-paginator
		[length]="totalRecords$ | async"
		[pageIndex]="pageIndex$ | async"
		[pageSize]="pageSize$ | async"
		[pageSizeOptions]="pageSizeOptions"
		[class.hidden]="(isFetching$ | async) || !(data$ | async)"
	></mat-paginator>
</mat-card>

<ng-template #falseBlock><div>&nbsp;</div></ng-template>
