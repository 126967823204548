import { EventEmitter, Input, OnDestroy, OnInit, Output, Directive } from '@angular/core';
import { FormControl } from '@angular/forms';

import { Observable, of as ObservableOf, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';

import { BaseDtoWithTrimmedId } from '@common/models/Common/BaseDtoWithTrimmedId';
import { EntityReference } from '@common/models/Common/EntityReference';

/*
	This class was added temporarily for a transition period converting lookups from 'autocomplete' to a custom overlay.
	Currently, most of functionality is duplicated with BaseLookupComponent.
*/
@Directive()
export abstract class BaseFilterLookupComponent<TLookupDto extends BaseDtoWithTrimmedId> implements OnInit, OnDestroy {
	@Input() FormControl: FormControl;
	@Input() HasAutofocus: boolean;
	@Input() Placeholder: string;
	@Input() Hint: string;
	@Output() EscPressed: EventEmitter<any> = new EventEmitter();
	@Output() Selected: EventEmitter<EntityReference> = new EventEmitter<EntityReference>();

	SelectedValue: TLookupDto;

	inputCtrl: FormControl = new FormControl();
	options: TLookupDto[];

	noLookupResults = false;
	isSearching = false;

	protected subscription = new Subscription();

	displayValue(input: TLookupDto): string {
		throw new Error(`'displayValue' must be implemented`);
	}

	lookup(id: string): Observable<TLookupDto> {
		throw new Error(`'lookup' must be implemented`);
	}

	search(term: string): Observable<TLookupDto[]> {
		throw new Error(`'search' must be implemented`);
	}

	ngOnInit() {
		// Subscribe for typing a name in the control and build a list of autocomplete suggestions
		this.subscription.add(
			this.inputCtrl.valueChanges
				.pipe(
					filter(Boolean),
					distinctUntilChanged(),
					debounceTime(500),
					switchMap((term: string): Observable<TLookupDto[]> => {
						this.isSearching = true;
						this.noLookupResults = false;
						// clear out selection
						if (!term && this.FormControl) {
							this.FormControl.setValue(null);
						}

						return term ? this.search(term) : ObservableOf([]);
					})
				)
				.subscribe((next: TLookupDto[]) => {
					this.options = next;
					this.noLookupResults = !next || next.length === 0;
					this.isSearching = false;
				})
		);
		if (this.FormControl) {
			// Subscribe to changing the entity ID (bound to the exposed to the parent form) and retrieve the description
			this.subscription.add(
				this.FormControl.valueChanges
					.pipe(
						distinctUntilChanged(),
						filter(
							(val: string) =>
								!!val &&
								(!this.SelectedValue || (typeof val === 'string' && val !== this.SelectedValue.id))
						),
						switchMap((id: string) => this.lookup(id))
					)
					.subscribe((next: TLookupDto) => {
						this.SelectedValue = next;
						if (next) {
							this.addAndUpdateRecentlyAccessedItems(next.id);
							this.onSelected();
						}
					})
			);

			// trigger the event above to get the description if there is a value pre-populated
			const value = this.FormControl.value;
			if (value && value.hasOwnProperty('id')) {
				this.FormControl.setValue(value.id);
			} else {
				this.FormControl.setValue(value); // 'value' is an ID
			}
		}
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	// Handle click on the suggestion
	itemSelected(item: TLookupDto): void {
		this.SelectedValue = item;

		const id = !!item ? item.id : null;

		// update the form controls
		if (this.FormControl && this.FormControl.value !== id) this.FormControl.setValue(id);
		// Emit event with EntityRef structure
		this.onSelected();
		this.inputCtrl.reset();

		this.addAndUpdateRecentlyAccessedItems(id);
	}

	// Emit event on pressing Esc
	escPressed(): void {
		this.EscPressed.emit();
	}

	// Emit 'Selected' event with EntityRef structure
	protected onSelected(): void {
		this.Selected.emit(
			!!this.SelectedValue
				? { id: this.SelectedValue.id, name: this.displayValue(this.SelectedValue) }
				: { id: null, name: '' }
		);
	}

	// Add a new item by ID into the list of recently accessed items
	// and update the bound list of options
	protected addAndUpdateRecentlyAccessedItems(id: string): void {
		throw new Error(`'addAndUpdateRecentlyAccessedItems' must be implemented`);
	}
}
