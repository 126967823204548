<mat-card>
	<section class="page-path"><a [routerLink]="['..']">System</a> / Document Briefs</section>
	<h1>Document Brief Export Settings</h1>
	<div class="flex-column" [formGroup]="form">
		<div class="checkbox-field titlePageOptionsSection" formGroupName="titlePageOptions">
			<mat-checkbox #includeTitlePageCheck formControlName="includeTitlePage">Title Page</mat-checkbox>
			<mat-hint class="check-hint">Adds a front page to the document based on a predefined template</mat-hint>
			<div class="titlePageOptionsSettings" *ngIf="includeTitlePageCheck.checked">
				<document-template-lookup
					Placeholder="Template"
					[Required]="true"
					[FormControl]="form.controls.titlePageOptions.get('templateId')"
					[HasAutofocus]="true"
					entityType="BriefTitlePage"
					[autoSelectIfOnlyOne]="false"
				>
				</document-template-lookup>
			</div>
		</div>

		<div class="checkbox-field" formGroupName="contentsPageOptions">
			<mat-checkbox #includeContentsPageCheck formControlName="includeContentsPage">Contents Page</mat-checkbox>
			<mat-hint class="check-hint">Adds a contents page at the beginning of the document</mat-hint>
			<div class="checkbox-field indented" *ngIf="includeContentsPageCheck.checked">
				<mat-checkbox formControlName="hidePageNumbering" [disabled]="!form.controls.pageNumberOptions?.value?.includePageNumber"
					>Hide Page Numbering</mat-checkbox
				>
				<mat-hint class="check-hint">Hide page numbering on contents page</mat-hint>
			</div>
		</div>

		<div class="checkbox-field">
			<mat-checkbox formControlName="sectionTitlePage">Section Title Page</mat-checkbox>
			<mat-hint class="check-hint">Adds a title page before each section</mat-hint>
		</div>

		<div class="flex-row">
			<div class="checkbox-field">
				<mat-checkbox formControlName="documentSummaryPage">Document Summary Pages</mat-checkbox>
				<mat-hint class="check-hint">Adds a summary description page before each document</mat-hint>
			</div>

			<div class="checkbox-field">
				<mat-checkbox formControlName="includeEmailAttachments">Include Email Attachments</mat-checkbox>
				<mat-hint class="check-hint">Includes email attachments as pdf attachments and thier own page under the parent email</mat-hint>
			</div>
		</div>

		<div class="checkbox-field pageOptionsSection" formGroupName="pageNumberOptions">
			<mat-checkbox #includePage formControlName="includePageNumber">Page Numbering</mat-checkbox>
			<mat-hint class="check-hint">Adds a page number to each page</mat-hint>
			<div class="pageOptionsSettings" *ngIf="form.controls.pageNumberOptions?.value?.includePageNumber">
				<div class="flex-row">
					<mat-form-field>
						<mat-placeholder>Position</mat-placeholder>
						<mat-select formControlName="pagePosition">
							<mat-option *ngFor="let key of pagePositionKeys" [value]="key">
								<span>{{ key | pagePosition }}</span>
							</mat-option>
						</mat-select>
					</mat-form-field>

					<mat-form-field>
						<input type="text" formControlName="pageNumberFormat" matInput autocomplete="off" />
						<mat-placeholder>Format</mat-placeholder>
						<mat-hint>Use <b>[page]</b> for page number <b>[total]</b> for total page count</mat-hint>
						<mat-error>
							<error-messages [for]="form.get('pageNumberFormat')"></error-messages>
						</mat-error>
					</mat-form-field>
				</div>
				<div class="flex-row">
					<mat-form-field>
						<input type="number" formControlName="horizontalOffset" matInput autocomplete="off" />
						<mat-placeholder>Horizontal Offset</mat-placeholder>
						<mat-hint>Offset from the edge of the page</mat-hint>
						<mat-error>
							<error-messages [for]="form.get('horizontalOffset')"></error-messages>
						</mat-error>
					</mat-form-field>
					<mat-form-field>
						<input type="number" formControlName="verticalOffset" matInput autocomplete="off" />
						<mat-placeholder>Vertical Offset</mat-placeholder>
						<mat-hint>Offset from the edge of the page</mat-hint>
						<mat-error>
							<error-messages [for]="form.get('verticalOffset')"></error-messages>
						</mat-error>
					</mat-form-field>
				</div>
				<div class="font-lookup-options">
					<font-lookup Placeholder="Font Name" [FormControl]="form.controls.pageNumberOptions.get('fontName')" [HasAutofocus]="true"></font-lookup>
					<font-option [FormGroup]="pageNumberOptionsControl"></font-option>
				</div>
			</div>
		</div>
	</div>

	<div class="container action-pane">
		<button mat-raised-button color="primary" [disabled]="form.invalid || form.disabled || form.pristine" throttleButton (throttledClick)="save()">
			Save
		</button>
	</div>
</mat-card>
