import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { CalculateExpressionRequestDto } from '@common/models/Calculations/Item/CalculateExpressionRequestDto';
import { CalculateExpressionResultDto } from '@common/models/Calculations/Item/CalculateExpressionResultDto';
import { EntityCalculationDetailsDto } from '@common/models/Calculations/List/EntityCalculationDetailsDto';
import { EntityReference } from '@common/models/Common/EntityReference';
import { ListResponse } from '@common/models/Generic/ListResponse';
import { ChangeOrderNumberDto } from '@common/models/Settings/CustomFields/Item/ChangeOrderNumberDto';
import { ContactCustomFieldCreateDto } from '@common/models/Settings/CustomFields/Item/ContactCustomFieldCreateDto';
import { ContactCustomFieldUpdateDto } from '@common/models/Settings/CustomFields/Item/ContactCustomFieldUpdateDto';
import { ContactCustomFieldViewDto } from '@common/models/Settings/CustomFields/Item/ContactCustomFieldViewDto';
import { DeleteCustomFieldResponseDto } from '@common/models/Settings/CustomFields/Item/DeleteCustomFieldResponseDto';
import { ContactCustomFieldListItemDto } from '@common/models/Settings/CustomFields/List/ContactCustomFieldListItemDto';
import { ContactCustomFieldListRequest } from '@common/models/Settings/CustomFields/List/ContactCustomFieldListRequest';
import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TrimStringsOnObject } from '@common/utils/string-format';
import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';

@Injectable({
	providedIn: 'root'
})
export class ContactCustomFieldsService extends GenericHttpService {
	constructor(httpClient: HttpClient, settingsResolver: SettingsResolverService) {
		super(
			httpClient,
			settingsResolver.getAppServerUrl(),
			'api/v1/settings/CustomFields/contacts',
			'Contact Custom Field'
		);
	}

	createContactCustomField(dto: ContactCustomFieldCreateDto): Observable<MutationResponseDto> {
		return this.post<ContactCustomFieldCreateDto, MutationResponseDto>(``, TrimStringsOnObject(dto));
	}

	changeOrderNumber(dto: ChangeOrderNumberDto): Observable<MutationResponseDto> {
		return this.post<ChangeOrderNumberDto, MutationResponseDto>(`changeOrderNumber`, dto);
	}

	getContactCustomFieldList(
		request?: Partial<ContactCustomFieldListRequest>
	): Observable<ListResponse<ContactCustomFieldListItemDto>> {
		return this.postGetList<ContactCustomFieldListRequest, ContactCustomFieldListItemDto>(
			`getContactCustomFieldList`,
			request
		);
	}

	getContactCustomField(id: string): Observable<ContactCustomFieldViewDto> {
		return this.getItem<void, ContactCustomFieldViewDto>(`${id}`);
	}

	updateContactCustomField(id: string, dto: ContactCustomFieldUpdateDto): Observable<MutationResponseDto> {
		return this.put(`${id}`, TrimStringsOnObject(dto));
	}

	patchContactCustomFields(
		ids: string[],
		contactPatch: Partial<ContactCustomFieldUpdateDto>
	): Observable<MutationResponseDto> {
		return this.patchMultiple<ContactCustomFieldUpdateDto, MutationResponseDto>(ids, contactPatch);
	}

	deleteContactCustomField(id: string): Observable<MutationResponseDto> {
		return this.delete<MutationResponseDto>(`${id}`);
	}

	validateContactCustomField(id: string): Observable<DeleteCustomFieldResponseDto> {
		return this.getItem<void, DeleteCustomFieldResponseDto>(`ValidateDeleteContactCustomField/${id}`);
	}

	getContactCalculationDetails(): Observable<EntityCalculationDetailsDto> {
		return this.getItem<void, EntityCalculationDetailsDto>('details');
	}

	calculateContactExpression(
		contactId: string,
		request: CalculateExpressionRequestDto
	): Observable<CalculateExpressionResultDto> {
		return this.post<CalculateExpressionRequestDto, CalculateExpressionResultDto>(
			`${contactId}/calculate`,
			request
		);
	}
}
