<mat-card>
	<h1>System</h1>
	<mat-nav-list>
		<div>
			<h3 mat-subheader>Subscription<span *ngIf="showMarketPlace">&nbsp;& Marketplace</span></h3>
			<div>
				<a mat-list-item routerLink="./subscription" *ngIf="showSubscriptions">
					<mat-icon color="accent" matListIcon>subscriptions</mat-icon>
					<div mat-line>
						<h4 class="title">Account and Billing</h4>
						<p>Choose your plan and continue using {{ siteName }} to help run your firm.</p>
					</div>
				</a>
				<ng-container *tenantFeature="featureTypes.Marketplace">
					<a *ngIf="showMarketPlace" mat-list-item routerLink="./marketplace">
						<mat-icon color="accent" matListIcon>storefront</mat-icon>
						<div mat-line>
							<h4 matLine class="title">Marketplace</h4>
							<p matLine>Add additional functionality to your {{ siteName }}</p>
						</div>
					</a>
				</ng-container>
				<a mat-list-item routerLink="./features">
					<mat-icon color="accent" matListIcon>view_module</mat-icon>
					<div mat-line>
						<h4 class="title">Manage Features</h4>
						<p>Manage visible features within {{ siteName }}</p>
					</div>
				</a>
			</div>
		</div>

		<div *ngIf="hasRolePermission('SystemDocuments')">
			<h3 mat-subheader>Documents</h3>
			<div>
				<a mat-list-item routerLink="./document-templates">
					<mat-icon color="accent" matListIcon>description</mat-icon>
					<div mat-line>
						<h4 class="title">Document Templates</h4>
						<p>Create or modify document templates</p>
					</div>
				</a>
				<a mat-list-item routerLink="./document-templates" [queryParams]="{ activeTab: 'documenttemplategroups' }">
					<mat-icon color="accent" matListIcon>assignment</mat-icon>
					<div mat-line>
						<h4 class="title">Document Template Groups</h4>
						<p>Create or modify document template groups</p>
					</div>
				</a>
				<a mat-list-item routerLink="./document-tags">
					<mat-icon color="accent" matListIcon>local_offer</mat-icon>
					<div mat-line>
						<h4 class="title">Document Tags</h4>
						<p>Create or modify document tags</p>
					</div>
				</a>
				<a *tenantFeature="featureTypes.Briefs" mat-list-item routerLink="./document-brief-templates">
					<mat-icon color="accent" matListIcon>business_center</mat-icon>
					<div mat-line>
						<h4 class="title">Document Brief Templates</h4>
						<p>Customise document brief templates by defining the sections, document tags & practice area</p>
					</div>
				</a>
				<a *tenantFeature="featureTypes.Briefs" [featureFlag]="featureFlags.mergePdf" mat-list-item routerLink="./document-brief-export-settings">
					<mat-icon svgIcon="file-pdf" matListIcon color="accent"></mat-icon>
					<div mat-line>
						<h4 class="title">Document Brief Export</h4>
						<p>Configure default document brief export settings</p>
					</div>
				</a>
				<a
					mat-list-item
					routerLink="./document-templates"
					[featureFlag]="featureFlags.emailTemplates"
					[queryParams]="{ activeTab: 'documentemailtemplates' }"
				>
					<mat-icon color="accent" matListIcon>email_outline</mat-icon>
					<div mat-line>
						<h4 class="title">Email Templates</h4>
						<p>Create or modify email templates</p>
					</div>
				</a>
			</div>
		</div>

		<div *ngIf="hasRolePermission('SystemCustomFields')">
			<h3 mat-subheader>Custom Fields</h3>
			<div>
				<a mat-list-item routerLink="./matter-custom-fields">
					<mat-icon color="accent" matListIcon>view_list</mat-icon>
					<div mat-line>
						<h4 class="title">Matter Custom Fields</h4>
						<p>Create or modify matter custom fields</p>
					</div>
				</a>
				<a mat-list-item routerLink="./contact-custom-fields">
					<mat-icon color="accent" matListIcon>view_list</mat-icon>
					<div mat-line>
						<h4 class="title">Contact Custom Fields</h4>
						<p>Create or modify contact custom Fields</p>
					</div>
				</a>
			</div>
		</div>

		<div>
			<h3 mat-subheader>Time & Costing</h3>

			<div>
				<ng-container *ngIf="hasRolePermission('SystemTimeCost')">
					<ng-container *tenantFeatureAny="['CostRecords', featureTypes.Invoicing, 'XeroIntegration']">
						<a *tenantFeatureAny="[featureTypes.Invoicing, 'XeroIntegration']" mat-list-item routerLink="./bill-settings">
							<mat-icon color="accent" matListIcon>cached</mat-icon>
							<div mat-line>
								<h4 class="title">Invoice and Xero Settings</h4>
								<p>Invoice properties and synchronisation with Xero</p>
							</div>
						</a>
						<a *tenantFeature="'CostRecords'" mat-list-item routerLink="./cost-codes">
							<mat-icon color="accent" matListIcon>done</mat-icon>
							<div mat-line>
								<h4 class="title">Cost Codes</h4>
								<p>Create or modify cost codes that can be used when recording time or expense</p>
							</div>
						</a>
						<a *tenantFeature="'CostRecords'" mat-list-item routerLink="./units">
							<mat-icon color="accent" matListIcon>done</mat-icon>
							<div mat-line>
								<h4 class="title">Units</h4>
								<p>Define units per hour</p>
							</div>
						</a>
						<a *tenantFeature="'CostRecords'" mat-list-item routerLink="./cost-templates">
							<mat-icon color="accent" matListIcon>attach_money</mat-icon>
							<div mat-line>
								<h4 class="title">Costing Templates</h4>
								<p>Define matter costing templates based on user role & hourly rate</p>
							</div>
						</a>
					</ng-container>
				</ng-container>

				<a mat-list-item routerLink="./time-zone">
					<mat-icon color="accent" matListIcon>language</mat-icon>
					<div mat-line>
						<h4 class="title">Time Zone</h4>
						<p>Configure your Organization's Time Zone</p>
					</div>
				</a>
			</div>
		</div>

		<div *ngIf="hasRolePermission('SystemMatters')">
			<h3 mat-subheader>Matters</h3>
			<div>
				<a mat-list-item routerLink="./practice-areas">
					<mat-icon color="accent" matListIcon>view_list</mat-icon>
					<div mat-line>
						<h4 class="title">Practice Areas</h4>
						<p>Create or modify practice areas and their related stages</p>
					</div>
				</a>
				<a
					*tenantFeature="featureTypes.InfoTrack"
					[featureFlag]="featureFlags.infoTrack"
					class="search-settings"
					mat-list-item
					routerLink="./search-settings"
				>
					<mat-icon color="accent" matListIcon>find_in_page</mat-icon>
					<div mat-line>
						<h4 class="title">Searching and Services</h4>
						<p>Configure and connect to Infotrack</p>
					</div>
				</a>
				<a mat-list-item routerLink="./timers">
					<mat-icon color="accent" matListIcon>schedule</mat-icon>
					<div mat-line>
						<h4 class="title">Timers</h4>
						<p>Configure matter timers</p>
					</div>
				</a>
				<a mat-list-item routerLink="./matter-numbering-config">
					<mat-icon color="accent" matListIcon>format_list_numbered</mat-icon>
					<div mat-line>
						<h4 class="title">Numbering</h4>
						<p>Modify matter numbering</p>
					</div>
				</a>
				<a mat-list-item routerLink="./matter-mailto-config">
					<mat-icon color="accent" matListIcon>email</mat-icon>
					<div mat-line>
						<h4 class="title">Email Configuration</h4>
						<p>Configure email subject line</p>
					</div>
				</a>
				<a mat-list-item routerLink="./matter-workflows" *tenantFeature="'MatterWorkflows'">
					<mat-icon color="accent" matListIcon>auto_awesome_mosaic</mat-icon>
					<div mat-line>
						<h4 class="title">Matter Workflows</h4>
						<p>Define matter workflows such as automatically generating tasks on newly created matters.</p>
					</div>
				</a>
			</div>
		</div>

		<ng-container *ngIf="hasRolePermission('SystemTrust')">
			<div *tenantFeature="'TrustAccounting'" [featureFlag]="featureFlags.trustAccountingFeature">
				<h3 mat-subheader>Trust Accounting</h3>
				<div>
					<a mat-list-item routerLink="./trust-settings">
						<mat-icon color="accent" matListIcon>account_balance</mat-icon>
						<div mat-line>
							<h4 class="title">Trust & Bank Accounts</h4>
							<p>Create or modify trust and bank accounts</p>
						</div>
					</a>
					<a mat-list-item routerLink="./media-types">
						<mat-icon color="accent" matListIcon>payment</mat-icon>
						<div mat-line>
							<h4 class="title">Transaction Media Types</h4>
							<p>Modify media types for bank transactions</p>
						</div>
					</a>
				</div>
			</div>
		</ng-container>

		<div *ngIf="hasRolePermission('SystemUserAccounts')">
			<h3 mat-subheader>Users</h3>
			<div>
				<a mat-list-item routerLink="./users">
					<mat-icon color="accent" matListIcon>person</mat-icon>
					<div mat-line>
						<h4 class="title">User Accounts</h4>
						<p>Create or modify user accounts</p>
					</div>
				</a>
				<a mat-list-item routerLink="./user-types">
					<mat-icon color="accent" matListIcon>people_outline</mat-icon>
					<div mat-line>
						<h4 class="title">User Types</h4>
						<p>Create or modify user types</p>
					</div>
				</a>
				<a mat-list-item routerLink="./security-roles">
					<mat-icon color="accent" matListIcon>admin_panel_settings</mat-icon>
					<div mat-line>
						<h4 class="title">Security Roles</h4>
						<p>Create or modify security roles</p>
					</div>
				</a>
				<a mat-list-item routerLink="./login-option-rules">
					<mat-icon color="accent" matListIcon>login</mat-icon>
					<div mat-line>
						<h4 class="title">Login Option Rules</h4>
						<p>Manage Rules for user Authentication methods</p>
					</div>
				</a>
			</div>
		</div>

		<ng-container *ngIf="hasRolePermission('SystemReferralReasons')">
			<div *tenantFeature="featureTypes.Referrals">
				<h3 mat-subheader>Referrals</h3>
				<div>
					<a mat-list-item routerLink="./referral-reasons">
						<mat-icon color="accent" matListIcon>subject</mat-icon>
						<div mat-line>
							<h4 class="title">Referral Reasons</h4>
							<p>Create or modify referral reasons</p>
						</div>
					</a>
				</div>
			</div>
		</ng-container>

		<div *ngIf="hasRolePermission('SystemSettingCompany')">
			<h3 mat-subheader>Settings</h3>
			<div>
				<a mat-list-item routerLink="./company-details">
					<mat-icon color="accent" matListIcon>business_center</mat-icon>
					<div mat-line>
						<h4 class="title">Company Details</h4>
						<p>Assign company details</p>
					</div>
				</a>
				<a mat-list-item routerLink="./import">
					<mat-icon color="accent" matListIcon>import_export</mat-icon>
					<div mat-line>
						<h4 class="title">Import</h4>
						<p>Import data into {{ siteName }}</p>
					</div>
				</a>
				<a *tenantFeature="'ApiKeys'" [featureFlag]="featureFlags.publicApi" mat-list-item routerLink="./api-keys">
					<mat-icon color="accent" matListIcon>vpn_key</mat-icon>
					<div mat-line>
						<h4 class="title">API Keys</h4>
						<p>Manage keys used for integrating with third party systems</p>
					</div>
				</a>
				<a mat-list-item routerLink="./share-links">
					<mat-icon color="accent" matListIcon>share</mat-icon>
					<div mat-line>
						<h4 class="title">Share Links</h4>
						<p>Manage share link settings</p>
					</div>
				</a>
			</div>
		</div>
		<div *ngIf="hasRolePermission('SystemReports')">
			<h3 mat-subheader>Logs</h3>
			<div>
				<a mat-list-item routerLink="./activity-log">
					<mat-icon color="accent" matListIcon>history</mat-icon>
					<div mat-line>
						<h4 class="title">Activity Log</h4>
						<p>View all system activity information</p>
					</div>
				</a>
			</div>
		</div>
	</mat-nav-list>
</mat-card>
