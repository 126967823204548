import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { orderBy } from 'lodash';

import { EntityReference } from '@common/models/Common/EntityReference';
import { ContactType } from '@common/models/Contacts/Common/ContactType';
import { CustomFieldType } from '@common/models/Settings/CustomFields/Common/CustomFieldType';
import { ContactCustomFieldListRequest } from '@common/models/Settings/CustomFields/List/ContactCustomFieldListRequest';
import { FilterRootComponent } from 'app/shared/filter-controls';
import { FilterChangeProperties } from 'app/shared/filter-controls/filter-change-properties';
import { FeatureFlags } from 'app/app.config';

@Component({
	selector: 'contact-custom-field-list-filter',
	styleUrls: ['./contact-custom-field-list-filter.component.scss'],
	templateUrl: './contact-custom-field-list-filter.component.html'
})
export class ContactCustomFieldListFilterComponent {
	featureFlags: typeof FeatureFlags = FeatureFlags;

	@ViewChild(FilterRootComponent, { static: true })
	root: FilterRootComponent<ContactCustomFieldListRequest>;

	@Input()
	request: Partial<ContactCustomFieldListRequest>;
	@Output()
	filterChange = new EventEmitter<Partial<ContactCustomFieldListRequest>>();

	listRequestType: { new (): ContactCustomFieldListRequest } = ContactCustomFieldListRequest;

	fieldTypes = orderBy<EntityReference>(
		(Object.keys(CustomFieldType) as Array<keyof typeof CustomFieldType>).map(key => {
			return { id: key, name: CustomFieldType[key] };
		}),
		order => order.name
	);

	contactTypes = orderBy<EntityReference>(
		(Object.keys(ContactType) as Array<keyof typeof ContactType>).map(key => {
			return { id: key, name: ContactType[key] };
		}),
		order => order.name
	);

	rootFilterChange($event: FilterChangeProperties<Partial<ContactCustomFieldListRequest>>) {
		if (!!$event) this.filterChange.emit($event.filter);
	}
}
