import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';

import { map, startWith } from 'rxjs/operators';

import { get, isNil } from 'lodash';

@Component({
	selector: 'autocomplete-dropdown',
	styleUrls: ['./autocomplete-dropdown.component.scss'],
	templateUrl: './autocomplete-dropdown.component.html'
})
export class AutocompleteDropdownComponent<T> implements OnInit {
	protected _control: AbstractControl;
	@Input()
	set control(value: AbstractControl) {
		this._control = value;
	}
	get control(): AbstractControl {
		return this._control;
	}
	get formControl(): FormControl {
		return this.control as FormControl;
	}
	// Available options for binding to the dropdown
	@Input() options: T[];
	// The 'ID' field in the 'Options'
	@Input() optionValue: keyof T;
	// The 'Name' field in the 'Options'
	@Input() optionName: keyof T;
	// The mat-placeholder value
	@Input() placeHolder: string;
	// The hint text
	@Input() hintText: string;
	// Fired when mat-autocomplete selection is made
	@Output() dropdownChange = new EventEmitter();

	filteredOptions$: Observable<T[]>;
	@ViewChild('trigger') autocompleteTrigger: MatAutocompleteTrigger;

	ngOnInit() {
		this.filteredOptions$ = this.formControl.valueChanges.pipe(
			startWith(''),
			map((inputValue: any) => {
				return typeof inputValue === 'string' ? inputValue : this.getValue(inputValue);
			}),
			map(value => {
				const filteredOptions = !!value ? this._filterByName(value) : this.options.slice();
				return filteredOptions;
			})
		);
	}

	private _filterByName(value: string): T[] {
		const filterValue = value.toLowerCase();
		let filteredOptionSet = this.options.filter((opt: T) => {
			return this.getName(opt)?.toLowerCase().startsWith(filterValue);
		});
		return filteredOptionSet;
	}

	private _filterByValue(value: string): T {
		const filterValue = value.toLowerCase();
		let filteredOptionSet = this.options.filter((opt: T) => {
			return this.getValue(opt)?.toLowerCase().startsWith(filterValue);
		});
		return filteredOptionSet[0];
	}

	displayFn(option: T): string {
		if (typeof option === 'string') {
			let filteredOption = this._filterByValue(option);
			if (!!filteredOption) option = filteredOption;
		}
		return this.getName(option);
	}

	getValue(val: T) {
		return isNil(this.optionValue) ? val : get(val, this.optionValue);
	}

	getName(val: T): string {
		return isNil(this.optionName) ? val : get(val, this.optionName);
	}

	selected(event: MatAutocompleteSelectedEvent) {
		var eventValue = this.getValue(event.option.value);
		this.formControl.setValue(eventValue);

		this.dropdownChange.emit(event);
	}

	clearInput() {
		this.formControl.setValue(null);
		this.formControl.markAsTouched();
		this.formControl.markAsDirty();
		this.formControl.updateValueAndValidity();
		this.openPanel();
	}

	openPanel() {
		setTimeout(() => {
			this.autocompleteTrigger.openPanel();
		}, 1);
	}
}
