// This file has been generated from SaveBillTemplateDto.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.Domain.Settings.BillSettings.Item.SaveBillTemplateDto
export class SaveBillTemplateDto {

	// TemplateId
	templateId: string;

	constructor() {

	}
}