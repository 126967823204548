// This file has been generated from PracticeAreaTimerUpdateDto.cs
// Do not modify this file directly!!
// tslint:disable

import { SetSimpleType } from '@common/utils/class-transform';



// LawMaster.Amalthea.Domain.Settings.PracticeAreas.Item.PracticeAreaTimerUpdateDto
export class PracticeAreaTimerUpdateDto {

	// IsEnabled
	@SetSimpleType(Boolean)
	isEnabled: boolean;

	constructor() {

	}
}