// This file has been generated from MatterUpdateDto.cs
// Do not modify this file directly!!
// tslint:disable

import { Moment } from 'moment-timezone';
import { DateFormat, SetDateFormat } from '@common/utils/date-format';

import { SetSimpleType } from '@common/utils/class-transform';

import { CompanyContactCreateUpdateDto } from '../../Contacts/Item/CompanyContactCreateUpdateDto';
import { PersonContactCreateUpdateDto } from '../../Contacts/Item/PersonContactCreateUpdateDto';
import { PluralContactCreateUpdateDto } from '../../Contacts/Item/PluralContactCreateUpdateDto';
import { CostingMethod } from '../Common/CostingMethod';
import { MatterStaffMemberDto } from '../Common/MatterStaffMemberDto';

// LawMaster.Amalthea.Domain.Matters.Item.MatterUpdateDto
export class MatterUpdateDto {

	// Title
	title: string;
	// Description
	description: string;
	// PracticeAreaId
	practiceAreaId: string;
	// CostingMethod
	costingMethod: keyof typeof CostingMethod;
	// CostTemplateId
	costTemplateId: string;
	// DateOpen
	@SetDateFormat(DateFormat.DateTime)
	dateOpen: Moment;
	// CalculateTaxes
	@SetSimpleType(Boolean)
	calculateTaxes: boolean;
	// ClientId
	clientId: string;
	// CreatePersons
	createPersons: { [key: string]: PersonContactCreateUpdateDto; };
	// CreateCompanies
	createCompanies: { [key: string]: CompanyContactCreateUpdateDto; };
	// CreatePlurals
	createPlurals: { [key: string]: PluralContactCreateUpdateDto; };
	// LawyerId
	lawyerId: string;
	// DateEstimatedCompletion
	@SetDateFormat(DateFormat.LocalDate)
	dateEstimatedCompletion: Moment;
	// EstimatedFee
	estimatedFee: number;
	// ReferredById
	referredById: string;
	// CustomFields
	customFields: { [key: string]: any; };
	// MatterStaffMembers
	matterStaffMembers: MatterStaffMemberDto[];

	constructor() {
		this.matterStaffMembers = [];
	}
}