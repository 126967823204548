// This file has been generated from PaymentIdentifiersDto.cs
// Do not modify this file directly!!
// tslint:disable

import { PaymentIdentifier } from '../Common/PaymentIdentifier';

// LawMaster.Amalthea.Domain.Bills.Item.PaymentIdentifiersDto
export class PaymentIdentifiersDto {

	// Payments
	payments: PaymentIdentifier[];

	constructor() {
		this.payments = [];
	}
}