import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Input,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Router } from '@angular/router';

import { of as ObservableOf, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, map, switchMap } from 'rxjs/operators';

import { ListResponse } from '@common/models/Generic/ListResponse';
import { ExtendedOmnisearchResponse } from '@common/models/Omnisearch/ExtendedOmnisearchResponse';
import { OmnisearchResponse } from '@common/models/Omnisearch/OmnisearchResponse';
import { TenantFeatureStateDto } from '@common/models/Settings/Setting/Item/TenantFeatureStateDto';
import { OmnisearchService } from '@common/services/omnisearch.service';
import { GeneralSettingsService } from '@common/services/settings/generalsettings.service';
import { ITenantData } from '@common/state/models/tenant-data';
import { getFileIcon } from '@common/utils/file-extensions';
import { Store } from '@ngrx/store';
import { trimEnd } from 'lodash-es';
import * as moment from 'moment-timezone';

import { FeatureFlags } from 'app/app.config';
import { routes } from 'app/system/system.routing';

import { SecurityPermissionService } from '../security-permissions.service';
import { IMatterNumberingConfigState } from '../state/misc/matter-numbering-config/matter-numbering-config.state';
import { OmnisearchDialogComponent } from './omnisearch-dialog.component';

type entityRouteType = 'matters' | 'contacts' | 'documents' | 'system';

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'omnisearch',
	styleUrls: ['./omnisearch.component.scss'],
	templateUrl: 'omnisearch.component.html'
})
export class OmnisearchComponent implements OnInit, AfterViewInit, OnDestroy {
	readonly input = new FormControl();
	readonly sections: Array<keyof ExtendedOmnisearchResponse>;
	options: ExtendedOmnisearchResponse;
	isMobile: boolean = false;
	@Input()
	parent: OmnisearchDialogComponent;
	@ViewChild('searchInput')
	searchInput: ElementRef;
	tenantFeatures: TenantFeatureStateDto[] = null;
	disabledSettingsRoutes: string[] = [];
	featureFlags: typeof FeatureFlags = FeatureFlags;

	matterNumberReferenceFormat: string;

	private term: string;
	private readonly MATTER_ROUTE: entityRouteType = 'matters';
	private readonly CONTACT_ROUTE: entityRouteType = 'contacts';
	private readonly DOCUMENT_ROUTE: entityRouteType = 'documents';
	private subscriptions = new Subscription();

	constructor(
		private generalSettingsService: GeneralSettingsService,
		private service: OmnisearchService,
		private breakpoint: BreakpointObserver,
		private cdr: ChangeDetectorRef,
		private router: Router,
		private securityPermissionService: SecurityPermissionService,
		private _store: Store<{ tenantData: ITenantData; matterNumberingConfigData: IMatterNumberingConfigState }>
	) {
		this.sections = ['matters', 'contacts', 'documents', 'system'];
	}

	ngOnInit() {
		this.subscriptions.add(
			this._store
				.select(data => data.matterNumberingConfigData)
				.subscribe(data => (this.matterNumberReferenceFormat = data?.dto?.referenceFormat))
		);

		this.subscriptions.add(
			this._store
				.select(state => state?.tenantData?.tenantInformation?.featureStates)
				.pipe(
					filter(states => !!states?.length),
					first(),
					map(states =>
						states.slice().sort((left, right) => {
							if (left.type < right.type) {
								return -1;
							}

							if (left.type > right.type) {
								return 1;
							}

							return 0;
						})
					)
				)
				.subscribe(states => {
					this.disabledSettingsRoutes = states
						.filter(x => !x.isEnabled)
						.map(feature => {
							return feature.type;
						});
				})
		);

		const ignoreLicencing$ = this._store
			.select(state => state.tenantData?.tenantInformation)
			.pipe(map(info => info?.ignoreLicensing ?? true));

		const isNotSubscribed$ = this.generalSettingsService
			.getTenantStripeInfo()
			.pipe(map(stripeInfo => !stripeInfo?.subscriptionId));

		this.subscriptions.add(
			ignoreLicencing$
				.pipe(
					switchMap(ignoreLicencing => {
						if (!ignoreLicencing) return ObservableOf(false);
						else return isNotSubscribed$;
					})
				)
				.subscribe(ignoreLicencing => {
					if (ignoreLicencing) this.disabledSettingsRoutes.push('Licensing');
				})
		);

		this.subscriptions.add(
			this.input.valueChanges
				.pipe(
					debounceTime(500),
					distinctUntilChanged(),
					switchMap((term: string) => {
						return this.getEnabledFeatures$().pipe(
							switchMap(features => {
								this.term = term;

								let numberTerm = term;
								if (!!numberTerm?.length && /(^\d+)$/g.test(numberTerm)) {
									numberTerm = !!this.matterNumberReferenceFormat?.includes('YearShort')
										? `${moment().format('YY')}-${numberTerm}`
										: !!this.matterNumberReferenceFormat?.includes('YearFull')
										? `${moment().format('YYYY')}-${numberTerm}`
										: numberTerm;
								}

								return term
									? this.service.search({
											term,
											enabledFeatures: features,
											numberTerm: numberTerm
									  })
									: ObservableOf(null);
							})
						);
					})
				)
				.subscribe((next: OmnisearchResponse) => {
					this.options = {
						contacts: next?.contacts,
						documents: next?.documents,
						matters: next?.matters,
						system: null
					};
					this.appendSettingsRoutes();
				})
		);
	}

	private getEnabledFeatures$() {
		return this._store
			.select(state =>
				state?.tenantData?.tenantInformation?.featureStates
					.filter(state => !!state.isEnabled)
					.map(state => state.type)
			)
			.pipe(first());
	}

	appendSettingsRoutes() {
		if (this.securityPermissionService.hasAccessToSettings && !!this.term && !!this.options) {
			this.options.system = new ListResponse();
			this.options.system.records = [];
			routes
				.filter(
					x =>
						x.path?.toLowerCase().includes(this.term.toLowerCase()) ||
						x.data?.title?.toLowerCase().includes(this.term.toLowerCase()) ||
						x.data?.description?.toLowerCase().includes(this.term.toLowerCase())
				)
				.forEach(route => {
					if (this.disabledSettingsRoutes.includes(route?.data?.featureType)) return;

					if (!!route.data && !!route.data.title && !!route.data.description && !!route.data.icon) {
						this.options.system.records.push({
							title: route.data?.title,
							description: route.data?.description,
							route: route.path,
							icon: route.data?.icon
						});
					}
				});
		}
	}

	highlightText(input: string): string {
		if (!input) {
			return '';
		}
		// Remove special characters in the string
		const parsedStr = (this.input.value || '').replace('\\', '');

		const regEx = '[\\s,;:"\\{\\}\\[\\]\\|\\/`~!@#$%^&*\\(\\)_=\\+]';

		const exp: string = (parsedStr || '')
			.split(new RegExp(regEx, 'gi'))
			.filter(Boolean)
			.map((token: string) => `^${token}|${regEx}${token}`)
			.join('|');
		const regex: RegExp = new RegExp(`(${exp})`, 'gi');
		return input.replace(regex, '<strong>$1</strong>');
	}

	onSelect(event: MatAutocompleteSelectedEvent): void {
		if (this.parent) {
			this.parent.closeDialog();
		}
		const id = event.option.value;
		const section = event.option.group.label.toLowerCase() as entityRouteType;
		if (section !== 'system') this.routeToSection(section, id);
	}

	routeToSection(section: entityRouteType, id: string = ''): void {
		this.input.setValue(null);
		// Build special navigation for Documents
		if (section === this.DOCUMENT_ROUTE) {
			if (!!this.options.documents && !!id) {
				const doc = this.options.documents.records.find(r => r.id === id);

				let route;
				if (doc.associatedMatter != null) route = `/${this.MATTER_ROUTE}/${doc.associatedMatter.id}`;
				else if (doc.associatedContact != null) route = `/${this.CONTACT_ROUTE}/${doc.associatedContact.id}`;
				if (!route) throw new Error('Document must be linked either to a matter or contact');

				this.router.navigate([route, this.DOCUMENT_ROUTE], { queryParams: { pageIndexForId: id } });
			} else {
				this.router.navigate(
					[trimEnd(`/${section}/${id}`, '/')],
					id ? {} : { queryParams: { search: this.term, useFullTextSearch: true } }
				);
			}
		} else {
			// Build standard navigation for matters and contacts
			this.router.navigate([trimEnd(`/${section}/${id}`, '/')], id ? {} : { queryParams: { search: this.term } });
		}
	}

	ngAfterViewInit() {
		if (this.searchInput) {
			setTimeout(() => this.searchInput.nativeElement.focus(), 0);
		}

		this.subscriptions.add(
			this.breakpoint.observe([Breakpoints.Handset]).subscribe(result => {
				this.isMobile = result.matches;
				this.cdr.detectChanges();
			})
		);
	}

	// File icon for the file extension
	getFileIcon(extension: string): string {
		return getFileIcon(extension);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	// trigger the option selected event on a mobile device as autocomplete onslecet event is not triggered
	onOptionSelect(section: entityRouteType, optionId?: string) {
		if (!this.isMobile) return;
		if (this.parent) {
			this.parent.closeDialog();
		}
		this.routeToSection(section, optionId);
	}
}
