import {
	HttpClient,
	HttpErrorResponse,
	HttpEvent,
	HttpEventType,
	HttpHeaders,
	HttpRequest,
	HttpResponse
} from '@angular/common/http';

import { Observable, of, throwError as observableThrowError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IUploadMultipleFiles, UploadStatus } from '@common/models/Common/IFileUploader';
import { IResultWithAttachments } from '@common/models/Common/IResultWithAttachments';
import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';
import { DomainErrorResolver } from '@common/utils/domain-error.resolver';
import { getFilenameFromHttpHeader } from '@common/utils/fileNameUtil';
import { toQueryParams } from '@common/utils/toQueryParams';
import * as FileSaver from 'file-saver';
import { get, isEmpty, trim, trimEnd } from 'lodash-es';
import { isMoment } from 'moment-timezone';

import { DownloadStatus, IDownloadFile } from '../models/Files/IFileDownloader';
import { ListResponse } from '../models/Generic/ListResponse';
import { toHttpParams } from '../utils/toHttpParams';

export abstract class GenericHttpService {
	private url: string;

	constructor(
		protected httpClient: HttpClient,
		private appServerUrl: string,
		url: string,
		private entityType: string = ''
	) {
		this.url = this.getBaseUrl(url);
	}

	// Note: This method is public as it is used by generic.data.source but it should not be used anywhere else
	getList<TRequestDto, TListItemDto>(
		urlExtension?: string,
		dto?: Partial<TRequestDto>
	): Observable<ListResponse<TListItemDto>> {
		return this.httpClient
			.get<ListResponse<TListItemDto>>(this.getFullUrl(urlExtension), {
				params: toHttpParams(dto)
			})
			.pipe(catchError(e => this.handleErrorObservable(e)));
	}

	// Note: This method is public as it is used by generic.data.source but it should not be used anywhere else
	postGetList<TRequestDto, TListItemDto>(
		urlExtension?: string,
		body?: Partial<TRequestDto>,
		hideSpinner?: boolean
	): Observable<ListResponse<TListItemDto>> {
		return (
			this.httpClient
				// always post with a content type of application/json to prevent Http Status 415 errors
				.post<ListResponse<TListItemDto>>(this.getFullUrl(urlExtension), body, {
					headers: !hideSpinner
						? { 'Content-Type': 'application/json' }
						: { 'Content-Type': 'application/json', spinner: 'no-spinner' }
				})
				.pipe(catchError(e => this.handleErrorObservable(e)))
		);
	}

	// This method gets all the records without paging and filtering
	getAll<TListItemDto>(urlExtension?: string): Observable<TListItemDto[]> {
		return this.httpClient
			.get<TListItemDto[]>(this.getFullUrl(urlExtension))
			.pipe(catchError(e => this.handleErrorObservable(e)));
	}

	// Note: This method is public as it is used by preview-document.component but it should not be used anywhere else
	getBlob<TRequestDto>(urlExtension?: string, dto?: Partial<TRequestDto>): Observable<HttpResponse<Blob>> {
		return this.httpClient
			.get(this.getFullUrl(urlExtension), {
				observe: 'response',
				params: toHttpParams(dto),
				responseType: 'blob'
			})
			.pipe(catchError(e => this.handleErrorObservable(e)));
	}

	getDownload<TRequestDto>(
		urlExtension?: string,
		dto?: Partial<TRequestDto>,
		reportProgress: boolean = true
	): Observable<IDownloadFile> {
		const file: IDownloadFile = {
			progress: { data: { currentLoaded: 0 }, status: DownloadStatus.Queued },
			size: 0
		};

		const request = new HttpRequest('GET', this.getFullUrl(urlExtension), {
			params: toHttpParams(dto),
			reportProgress: reportProgress,
			responseType: 'blob'
		});

		return this.httpClient.request(request).pipe(
			switchMap(httpEvent => {
				// Via this API, you get access to the raw event stream.
				// Look for download progress events.
				if (httpEvent.type === HttpEventType.DownloadProgress) {
					file.size = httpEvent.total;
					file.progress = {
						data: {
							currentLoaded: httpEvent.loaded
						},
						status: DownloadStatus.Downloading
					};
				} else if (httpEvent instanceof HttpResponse) {
					const fileName = getFilenameFromHttpHeader(httpEvent.headers.get('content-disposition'));
					FileSaver.saveAs(httpEvent.body as Blob, fileName);
					file.size = (httpEvent.body as Blob).size;
					file.progress = {
						data: {
							currentLoaded: file.size
						},
						status: DownloadStatus.Completed
					};
				}
				return of(file);
			}),
			catchError(e => this.handleErrorObservable(e))
		);
	}

	protected createUpdateWithHttpProgress<T>(
		urlExtension: string,
		dto: T,
		filesToUpload: IUploadMultipleFiles,
		method: 'POST' | 'PUT',
		showSpinner: boolean = false
	): Observable<Partial<IResultWithAttachments>> {
		const formData = new FormData();

		if (filesToUpload && filesToUpload.nativeFiles) {
			for (const file of filesToUpload.nativeFiles) {
				formData.append('attachments', file);
			}
		}

		this.mapPropertiesToFormData<T>(dto, formData);

		const req = new HttpRequest(method, this.getFullUrl(urlExtension), formData, {
			headers: showSpinner ? new HttpHeaders() : new HttpHeaders({ spinner: 'no-spinner' }),
			reportProgress: true
		});

		return this.httpUploadProgressHandler(this.httpClient.request(req));
	}

	protected httpUploadProgressHandler(
		httpRequest: Observable<HttpEvent<any>>
	): Observable<Partial<IResultWithAttachments>> {
		return httpRequest.pipe(
			map(e => {
				let result: Partial<IResultWithAttachments> = null;
				// Look for upload progress events.
				if (e.type === HttpEventType.UploadProgress) {
					const percentage = Math.round((e.loaded * 100) / e.total);
					result = {
						uploadFiles: {
							progress: {
								data: { percentage },
								status: UploadStatus.Uploading
							}
						}
					};
				} else if (e.type === HttpEventType.Response) {
					result = {
						mutationResponse: (e as HttpResponse<MutationResponseDto>).body,
						uploadFiles: {
							progress: {
								data: { percentage: 100 },
								status: UploadStatus.Completed
							}
						}
					};
				}
				return result;
			})
		);
	}

	protected getText<TRequestDto>(urlExtension?: string, dto?: Partial<TRequestDto>): Observable<string> {
		return this.httpClient
			.get(this.getFullUrl(urlExtension), {
				params: toHttpParams(dto),
				responseType: 'text'
			})
			.pipe(catchError(e => this.handleErrorObservable(e)));
	}

	protected getItem<TRequestDto, TItemDto>(urlExtension?: string, dto?: Partial<TRequestDto>): Observable<TItemDto> {
		return this.httpClient
			.get<TItemDto>(this.getFullUrl(urlExtension), {
				params: toHttpParams(dto)
			})
			.pipe(catchError(e => this.handleErrorObservable(e)));
	}

	protected getArray<TRequestDto, TListItemDto>(
		urlExtension?: string,
		dto?: Partial<TRequestDto> | { [param: string]: string | string[] }
	): Observable<TListItemDto[]> {
		return this.httpClient
			.get<TListItemDto[]>(this.getFullUrl(urlExtension), {
				params: toHttpParams(dto)
			})
			.pipe(catchError(e => this.handleErrorObservable(e)));
	}

	protected patch<TItemDto, TResultDto>(
		id: string,
		replacements: Partial<TItemDto>,
		hideSpinner?: boolean
	): Observable<TResultDto> {
		const body = Object.keys(replacements).map(path => ({ op: 'replace', path, value: get(replacements, path) }));
		return this.httpClient
			.patch<TResultDto>(this.getFullUrl(id), body, {
				headers: !hideSpinner ? {} : { spinner: 'no-spinner' }
			})
			.pipe(catchError(e => this.handleErrorObservable(e)));
	}

	protected patchMultiple<TItemDto, TResultDto>(
		ids: string[],
		replacements: Partial<TItemDto>,
		hideSpinner?: boolean
	): Observable<TResultDto> {
		const body = Object.keys(replacements).map(path => ({ op: 'replace', path, value: get(replacements, path) }));
		return this.httpClient
			.patch<TResultDto>(this.getFullUrl(), body, {
				params: toQueryParams(ids, 'ids'),
				headers: !hideSpinner ? {} : { spinner: 'no-spinner' }
			})
			.pipe(catchError(e => this.handleErrorObservable(e)));
	}

	protected patchProperty<TResultDto>(
		id: string,
		propertyName: string,
		value: any,
		hideSpinner?: boolean
	): Observable<TResultDto> {
		return this.patch(id, { [propertyName]: value }, hideSpinner);
	}

	protected post<TItemDto, TResultDto>(
		urlExtension?: string,
		body?: TItemDto,
		hideSpinner?: boolean
	): Observable<TResultDto> {
		return (
			this.httpClient
				// always post with a content type of application/json to prevent Http Status 415 errors
				.post<TResultDto>(this.getFullUrl(urlExtension), body, {
					headers: !hideSpinner
						? { 'Content-Type': 'application/json' }
						: { 'Content-Type': 'application/json', spinner: 'no-spinner' }
				})
				.pipe(catchError(e => this.handleErrorObservable(e)))
		);
	}

	protected postFormData<TResultDto>(
		urlExtension?: string,
		formData?: FormData,
		hideSpinner?: boolean
	): Observable<TResultDto> {
		return this.httpClient
			.post<TResultDto>(this.getFullUrl(urlExtension), formData, {
				headers: !hideSpinner ? {} : { spinner: 'no-spinner' }
			})
			.pipe(catchError(e => this.handleErrorObservable(e)));
	}

	protected put<TItemDto, TResultDto>(
		urlExtension?: string,
		body?: TItemDto,
		hideSpinner?: boolean
	): Observable<TResultDto> {
		return this.httpClient
			.put<TResultDto>(this.getFullUrl(urlExtension), body, {
				headers: !hideSpinner ? {} : { spinner: 'no-spinner' }
			})
			.pipe(catchError(e => this.handleErrorObservable(e)));
	}

	protected delete<TResultDto>(urlExtension?: string, hideSpinner?: boolean): Observable<TResultDto> {
		return this.httpClient
			.delete<TResultDto>(this.getFullUrl(urlExtension), {
				headers: !hideSpinner ? {} : { spinner: 'no-spinner' }
			})
			.pipe(catchError(e => this.handleErrorObservable(e)));
	}

	protected getFullUrl(urlExtension?: string): string {
		if (!isEmpty(urlExtension)) {
			const lowCaseUrl = urlExtension.toLowerCase();
			// Third-party service (e.g. doc preview)
			if (lowCaseUrl.startsWith('http')) return urlExtension;
			// End-point on a different controller
			if (lowCaseUrl.startsWith('api') || lowCaseUrl.startsWith('/api')) return this.getBaseUrl(urlExtension);
		}
		return `${this.url}${isEmpty(urlExtension) ? '' : '/'}${trim(urlExtension, '/')}`;
	}

	protected handleErrorObservable(response: HttpErrorResponse) {
		const errors = DomainErrorResolver.getDomainErrors(response, this.entityType);
		return observableThrowError(errors);
	}

	// Recursively maps dto to formdata for http request progress. Supports nested properties, arrays...
	// taken from https://gist.github.com/ghinda/8442a57f22099bdb2e34 with some changes
	protected mapPropertiesToFormData<T>(obj: T, form: FormData, namespace: string = null) {
		let formKey;

		for (const propertyName in obj) {
			if (obj.hasOwnProperty(propertyName)) {
				const propertyValue = get(obj, propertyName);
				formKey = namespace ? namespace + '[' + propertyName + ']' : propertyName;

				if (!!isMoment(propertyValue)) {
					form.append(formKey, propertyValue.toISOString());
				}
				// if the property is an object, but not a File, apply recursion.
				else if (typeof propertyValue === 'object' && !(propertyValue instanceof File)) {
					this.mapPropertiesToFormData(propertyValue, form, formKey);
				} else {
					// if it's a string or a File object
					form.append(formKey, propertyValue);
				}
			}
		}

		return form;
	}

	private getBaseUrl(url: string): string {
		return url.toLowerCase().startsWith('http') ? url : `${trimEnd(this.appServerUrl, '/ ')}/${trim(url, '/ ')}`;
	}
}
