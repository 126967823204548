import { SortDirection } from '@angular/material/sort';

import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';
import { DomainError } from '@common/models/Validation/DomainError';
import { createAction, props } from '@ngrx/store';

import { SelectionType } from '../../../SelectionType';
import { BankAccountListItemDto } from '@common/models/Settings/TrustSettings/BankAccounts/List/BankAccountListItemDto';

export enum BankAccountListActions {
	Init = '[Bank Account List] Initialise',
	Load = '[Bank Account List] Load',
	Loading = '[Bank Account List] Loading',
	LoadSuccess = '[Bank Account List] Load Success',
	LoadFailed = '[Bank Account List] Load Failed',

	SetPageSize = '[Bank Account List] Set Page Size',
	SetPageIndex = '[Bank Account List] Set Page Index',
	SetPageIndexForId = '[Bank Account List] Set Page Index For Id',

	SetSortBy = '[Bank Account List] Set Sort By',
	SetSortDirection = '[Bank Account List] Set Sort Direction',

	InsertRecords = '[Bank Account List] Insert Records',
	UpdateRecords = '[Bank Account List] Update Records',
	RemoveRecords = '[Bank Account List] Remove Records',
	SelectRecords = '[Bank Account List] Select Records'
}

export const init = createAction(BankAccountListActions.Init);
export const load = createAction(BankAccountListActions.Load);
export const loading = createAction(BankAccountListActions.Loading);
export const loadSuccess = createAction(
	BankAccountListActions.LoadSuccess,
	props<{ response: BankAccountListItemDto }>()
);
export const loadFailed = createAction(BankAccountListActions.LoadFailed, props<{ error: DomainError }>());

export const setPageSize = createAction(BankAccountListActions.SetPageSize, props<{ pageSize: number }>());
export const setPageIndex = createAction(BankAccountListActions.SetPageIndex, props<{ pageIndex: number }>());
export const setPageIndexForId = createAction(BankAccountListActions.SetPageIndexForId, props<{ id: string }>());

export const setSortBy = createAction(BankAccountListActions.SetSortBy, props<{ sortBy: string }>());
export const setSortDirection = createAction(
	BankAccountListActions.SetSortDirection,
	props<{ sortDirection: SortDirection }>()
);

export const insertRecords = createAction(
	BankAccountListActions.InsertRecords,
	props<{ response: MutationResponseDto | MutationResponseDto[] }>()
);
export const updateRecords = createAction(
	BankAccountListActions.UpdateRecords,
	props<{ response: MutationResponseDto | MutationResponseDto[] }>()
);
export const removeRecords = createAction(
	BankAccountListActions.RemoveRecords,
	props<{ response: MutationResponseDto | MutationResponseDto[] }>()
);
export const selectRecords = createAction(
	BankAccountListActions.SelectRecords,
	props<{ row: BankAccountListItemDto; selectionType: SelectionType }>()
);
