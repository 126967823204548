import { Directive, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatOption, MatOptionSelectionChange } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';

import { BehaviorSubject, debounceTime, map, Subscription, switchMap } from 'rxjs';

import { CalculationVariableListItemDto } from '@common/models/Calculations/List/CalculationVariableListItemDto';
import { EnumSortDirection } from '@common/models/Common/EnumSortDirection';
import { BasicWorkflowType } from '@common/models/Settings/BasicWorkflows/Common/BasicWorkflowType';
import { BasicWorkflowCreateUpdateDto } from '@common/models/Settings/BasicWorkflows/Item/BasicWorkflowCreateUpdateDto';
import { PracticeAreaListItemDto } from '@common/models/Settings/PracticeAreas/List/PracticeAreaListItemDto';
import { NotificationService } from '@common/notification';
import { PracticeAreasService } from '@common/services/settings/practiceareas.service';
import { isNil, uniq } from 'lodash-es';

import { FeatureFlags, isFeatureFlagEnabled } from 'app/app.config';

@Directive()
export abstract class BaseWorkflowComponent implements OnInit, OnDestroy {
	@ViewChild('selectPracticeAreas')
	selectPracticeAreas: MatSelect;

	@ViewChild('allOption')
	allOption: MatOption;

	@ViewChild('selectStage')
	selectStage: MatSelect;

	subscriptions = new Subscription();
	form: FormGroupTyped<BasicWorkflowCreateUpdateDto>;

	onUpdatePracticeAreaIds = new BehaviorSubject<string[]>([]);

	practiceAreas: PracticeAreaListItemDto[];
	stageNames: string[];
	workflowTypes: Array<keyof typeof BasicWorkflowType>;

	variables: CalculationVariableListItemDto[];

	get selectedStageName(): string {
		return this.form?.controls?.stageName?.value ?? '';
	}

	readonly matterCreated_WorkflowTypeKey: keyof typeof BasicWorkflowType = 'MatterCreated';
	readonly matterUpdated_WorkflowTypeKey: keyof typeof BasicWorkflowType = 'MatterUpdated';
	readonly stageChanged_WorkflowTypeKey: keyof typeof BasicWorkflowType = 'StageChanged';
	readonly defaultWorkflowTypeKey: keyof typeof BasicWorkflowType = this.matterCreated_WorkflowTypeKey;

	constructor(protected practiceAreasService: PracticeAreasService, protected notifService: NotificationService) {
		this.workflowTypes = Object.keys(BasicWorkflowType) as Array<keyof typeof BasicWorkflowType>;

		if (!isFeatureFlagEnabled(FeatureFlags.matterUpdatedWorkflows)) {
			this.workflowTypes = this.workflowTypes.filter(x => x != 'MatterUpdated');
		}
	}

	ngOnInit(): void {
		// Get all available Practice Areas
		this.subscriptions.add(
			this.practiceAreasService
				.getPracticeAreaList({
					sortBy: 'name',
					sortDirection: EnumSortDirection.Asc,
					showDisabled: true
				})
				.subscribe({
					next: practiceAreas => {
						this.practiceAreas = practiceAreas.records;

						setTimeout(() => {
							if (!this.form?.controls?.associatedPracticeAreaIds?.value?.length && !!this.allOption) {
								this.allOption.select();
								this.form.markAsPristine();
							}
						}, 0);
					},
					error: e => this.notifService.showErrors('Error loading practice areas ', e)
				})
		);

		this.subscriptions.add(
			this.onUpdatePracticeAreaIds
				.pipe(
					debounceTime(100),
					map(inputValue => inputValue.filter(x => !isNil(x))),
					switchMap(practiceAreaIds =>
						this.practiceAreasService.getPracticeAreaStageList({
							practiceAreaIds: practiceAreaIds.length > 0 ? practiceAreaIds : null
						})
					),
					map(stages => uniq(stages.map(stage => stage.name)))
				)
				.subscribe({
					next: stages => {
						this.stageNames = stages;
						// Clear selected value if user changes practice area where selected stage doesnt exist
						if (!!this.selectedStageName) {
							if (!this.stageNames.includes(this.selectedStageName))
								this.form.controls.stageName.patchValue(null);
						}
					},
					error: e => this.notifService.showErrors('Error getting stages', e)
				})
		);

		this.subscriptions.add(
			this.form.controls.associatedPracticeAreaIds.valueChanges.subscribe(value => {
				this.onUpdatePracticeAreaIds.next(value ?? []);
			})
		);

		this.subscriptions.add(
			this.form.controls.workflowType.valueChanges.subscribe(value => {
				this.onUpdatePracticeAreaIds.next(this.form.value?.associatedPracticeAreaIds ?? []);
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	displaySelectedPracticeAreas(values: string[]) {
		if (!values?.length) {
			return 'All';
		}

		if (values.length == 0 && isNil(values[0])) {
			return 'All';
		}

		const results = this.practiceAreas
			.filter(area => !!values.filter(id => id === area.id)?.length)
			.map(area => area.name);

		if (!results?.length) {
			return 'All';
		}

		if (results.length == 1) {
			return results[0];
		}

		return results.reduce((left, right) => `${left}, ${right}`);
	}

	allOptionSelected(event: MatOptionSelectionChange) {
		if (event.source.selected) {
			// When selecting the 'All' option, deselect all practice areas
			this.selectPracticeAreas.options.forEach(opt => {
				if (opt.selected && opt.id !== this.allOption.id) {
					opt.deselect();
				}
			});
		} else if (!this.selectPracticeAreas.options.some(opt => opt.selected)) {
			// If nothing is selected, select the 'All' option
			this.allOption.select();
		}
	}

	optionSelected(event: MatOptionSelectionChange, selectedOption: MatSelect) {
		if (this.allOption.selected && event.source.selected && event.source.id !== this.allOption.id) {
			// When selecting a practice area, deselect the 'All' option
			this.allOption.deselect();
		} else if (!selectedOption.options.some(opt => opt.selected)) {
			// If nothing is selected, select the 'All' option
			this.allOption.select();
		}
	}
}
