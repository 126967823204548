// This file has been generated from UserNotificationOptions.cs
// Do not modify this file directly!!
// tslint:disable

import { SetSimpleType } from '@common/utils/class-transform';



// LawMaster.Amalthea.Domain.Users.PersistenceLayer.UserNotificationOptions
export class UserNotificationOptions {

	// IsEnabled
	@SetSimpleType(Boolean)
	isEnabled: boolean;
	// NotifyDateOffset
	notifyDateOffset: number;

	constructor() {

	}
}