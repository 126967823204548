import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';

import { MatterWorkflowCondition } from '@common/models/Settings/BasicWorkflows/Item/MatterWorkflowCondition';
import { CustomValidators } from '@common/validation/custom.validators';

@Component({
	selector: 'basic-workflow-conditions',
	templateUrl: './basic-workflow-conditions.component.html',
	styleUrls: ['./basic-workflow-conditions.component.scss']
})
export class BasicWorkflowConditionsComponent {
	private _form: FormGroup;
	private _formArrayName: string;

	@Input()
	set form(value: FormGroup) {
		this._form = value;
	}

	@Input()
	set formArrayName(value: string) {
		this._formArrayName = value;
	}

	get matterWorkflowConditions() {
		return this._form?.get(this._formArrayName) as FormArray;
	}

	constructor(private _formBuilder: FormBuilder) {}

	addMatterWorkflowCondition(data?: MatterWorkflowCondition) {
		const newCondition = this._formBuilder.group({
			variableName: new FormControl(data?.variableName, CustomValidators.required()),
			operator: new FormControl(data?.operator || '', CustomValidators.required()),
			expectedValue: new FormControl(data?.expectedValue, [])
		});

		this.matterWorkflowConditions.push(newCondition);
		this._form.markAsDirty();
	}

	removeMatterWorkflowCondition(index: number) {
		this.matterWorkflowConditions.removeAt(index);
		this._form.markAsDirty();
	}
}
