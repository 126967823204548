import {
	AfterViewInit,
	Component,
	ContentChild,
	ElementRef,
	Input,
	OnChanges,
	Renderer2,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import { MatButton } from '@angular/material/button';

@Component({
	selector: 'mat-spinner-btn',
	styleUrls: ['./mat-spinner-btn.component.scss'],
	templateUrl: './mat-spinner-btn.component.html'
})
export class MatSpinnerBtnComponent implements AfterViewInit, OnChanges {
	@ViewChild('spinnerRef', { read: ElementRef, static: true }) spinnerElement: ElementRef;
	@ContentChild(MatButton, { read: ElementRef }) buttonElement: ElementRef;
	@ContentChild(MatButton, { static: true }) button: MatButton;

	@Input() loading: boolean;

	constructor(private renderer: Renderer2) {}

	ngAfterViewInit() {
		this.renderer.appendChild(this.buttonElement.nativeElement, this.spinnerElement.nativeElement);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if ('loading' in changes) {
			this.button.disabled = !!this.loading;
		}
	}
}
