// This file has been generated from DocumentBriefAccessTokensController.cs
// Do not modify this file directly!!
// tslint:disable

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { TrimStringsOnObject } from '@common/utils/string-format';

import { EntityReference } from '../../models/Common/EntityReference';
import { ListResponse } from '../../models/Generic/ListResponse';
import { AccessTokenValidationDto } from '../../models/Settings/AccessTokens/Item/AccessTokenValidationDto';
import { DocumentBriefAccessTokenCreateUpdateDto } from '../../models/Settings/AccessTokens/Item/DocumentBriefAccessTokenCreateUpdateDto';
import { DocumentBriefAccessTokenViewDto } from '../../models/Settings/AccessTokens/Item/DocumentBriefAccessTokenViewDto';
import { ValidateAccessTokenDto } from '../../models/Settings/AccessTokens/Item/ValidateAccessTokenDto';
import { DocumentBriefAccessTokenListItemDto } from '../../models/Settings/AccessTokens/List/DocumentBriefAccessTokenListItemDto';
import { DocumentBriefAccessTokenListRequest } from '../../models/Settings/AccessTokens/List/DocumentBriefAccessTokenListRequest';

@Injectable({
	providedIn: 'root'
})
export class DocumentBriefAccessTokensService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'api/v1/settings/access-tokens/document-briefs', 'DocumentBriefAccessToken');
	}

	getAccessToken(tokenOrId: string): Observable<DocumentBriefAccessTokenViewDto> {
		return this.getItem<void, DocumentBriefAccessTokenViewDto>(`${tokenOrId}`);
	}

	getAccessTokenList(request?: Partial<DocumentBriefAccessTokenListRequest>): Observable<ListResponse<DocumentBriefAccessTokenListItemDto>> {
		return this.getList<DocumentBriefAccessTokenListRequest, DocumentBriefAccessTokenListItemDto>('', request);
	}

	createAccessToken(dto: DocumentBriefAccessTokenCreateUpdateDto): Observable<EntityReference> {
		return this.post<DocumentBriefAccessTokenCreateUpdateDto, EntityReference>('', TransformDatesOnObject(DocumentBriefAccessTokenCreateUpdateDto, TrimStringsOnObject(dto)));
	}

	updateAccessToken(tokenOrId: string, dto: DocumentBriefAccessTokenCreateUpdateDto): Observable<any> {
		return this.put(`${tokenOrId}`, TransformDatesOnObject(DocumentBriefAccessTokenCreateUpdateDto, TrimStringsOnObject(dto)));
	}

	revokeAccessToken(tokenOrId: string): Observable<any> {
		return this.delete(`${tokenOrId}`);
	}

	validateToken(briefId: string, token: ValidateAccessTokenDto): Observable<AccessTokenValidationDto> {
		return this.getItem<ValidateAccessTokenDto, AccessTokenValidationDto>(`validate/${briefId}`, token);
	}

}
