import { SortDirection } from '@angular/material/sort';

import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';
import { DomainError } from '@common/models/Validation/DomainError';
import { createAction, props } from '@ngrx/store';

import { SelectionType } from '../../../SelectionType';
import { DocumentBriefTemplateListItemDto } from '@common/models/DocumentBriefTemplates/List/DocumentBriefTemplateListItemDto';
import { DocumentBriefTemplateListRequest } from '@common/models/DocumentBriefTemplates/List/DocumentBriefTemplateListRequest';

export enum DocumentBriefTemplateListActions {
	Init = '[Document Brief Template List] Initialise',
	Load = '[Document Brief Template List] Load',
	Loading = '[Document Brief Template List] Loading',
	LoadSuccess = '[Document Brief Template List] Load Success',
	LoadFailed = '[Document Brief Template List] Load Failed',

	SetFilters = '[Document Brief Template List] Set Filters',
	SetPageSize = '[Document Brief Template List] Set Page Size',
	SetPageIndex = '[Document Brief Template List] Set Page Index',
	SetPageIndexForId = '[Document Brief Template List] Set Page Index For Id',

	SetSortBy = '[Document Brief Template List] Set Sort By',
	SetSortDirection = '[Document Brief Template List] Set Sort Direction',

	InsertRecords = '[Document Brief Template List] Insert Records',
	UpdateRecords = '[Document Brief Template List] Update Records',
	RemoveRecords = '[Document Brief Template List] Remove Records',
	SelectRecords = '[Document Brief Template List] Select Records'
}

export const init = createAction(DocumentBriefTemplateListActions.Init);
export const load = createAction(DocumentBriefTemplateListActions.Load);
export const loading = createAction(DocumentBriefTemplateListActions.Loading);
export const loadSuccess = createAction(
	DocumentBriefTemplateListActions.LoadSuccess,
	props<{ response: DocumentBriefTemplateListItemDto }>()
);
export const loadFailed = createAction(DocumentBriefTemplateListActions.LoadFailed, props<{ error: DomainError }>());

export const setFilters = createAction(
	DocumentBriefTemplateListActions.SetFilters,
	props<{ filter: Partial<DocumentBriefTemplateListRequest> }>()
);
export const setPageSize = createAction(DocumentBriefTemplateListActions.SetPageSize, props<{ pageSize: number }>());
export const setPageIndex = createAction(DocumentBriefTemplateListActions.SetPageIndex, props<{ pageIndex: number }>());
export const setPageIndexForId = createAction(
	DocumentBriefTemplateListActions.SetPageIndexForId,
	props<{ id: string }>()
);

export const setSortBy = createAction(DocumentBriefTemplateListActions.SetSortBy, props<{ sortBy: string }>());
export const setSortDirection = createAction(
	DocumentBriefTemplateListActions.SetSortDirection,
	props<{ sortDirection: SortDirection }>()
);

export const insertRecords = createAction(
	DocumentBriefTemplateListActions.InsertRecords,
	props<{ response: MutationResponseDto | MutationResponseDto[] }>()
);
export const updateRecords = createAction(
	DocumentBriefTemplateListActions.UpdateRecords,
	props<{ response: MutationResponseDto | MutationResponseDto[] }>()
);
export const removeRecords = createAction(
	DocumentBriefTemplateListActions.RemoveRecords,
	props<{ response: MutationResponseDto | MutationResponseDto[] }>()
);
export const selectRecords = createAction(
	DocumentBriefTemplateListActions.SelectRecords,
	props<{ row: DocumentBriefTemplateListItemDto; selectionType: SelectionType }>()
);
