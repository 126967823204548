// This file has been generated from BillsController.cs
// Do not modify this file directly!!
// tslint:disable

import { HttpClient, HttpResponse } from '@angular/common/http';
import { IDownloadFile } from '../models/Files/IFileDownloader';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { TrimStringsOnObject } from '@common/utils/string-format';

import { BillBatchCreateDto } from '../models/Bills/Item/BillBatchCreateDto';
import { BillBatchCreateResponseDto } from '../models/Bills/Item/BillBatchCreateResponseDto';
import { BillCostRecordsDto } from '../models/Bills/Item/BillCostRecordsDto';
import { BillCreateUpdateDto } from '../models/Bills/Item/BillCreateUpdateDto';
import { BillFileSizeDto } from '../models/Bills/Item/BillFileSizeDto';
import { BillStatusBulkUpdateDto } from '../models/Bills/Item/BillStatusBulkUpdateDto';
import { BillStatusUpdateDto } from '../models/Bills/Item/BillStatusUpdateDto';
import { BillUpdateTemplateDto } from '../models/Bills/Item/BillUpdateTemplateDto';
import { BillViewDto } from '../models/Bills/Item/BillViewDto';
import { BillWriteOffDto } from '../models/Bills/Item/BillWriteOffDto';
import { ExportBillToPdfDto } from '../models/Bills/Item/ExportBillToPdfDto';
import { IgnoreXeroSyncDto } from '../models/Bills/Item/IgnoreXeroSyncDto';
import { PaymentIdentifiersDto } from '../models/Bills/Item/PaymentIdentifiersDto';
import { BillListItemDto } from '../models/Bills/List/BillListItemDto';
import { BillListRequest } from '../models/Bills/List/BillListRequest';
import { BillListResponse } from '../models/Bills/List/BillListResponse';
import { EntityReference } from '../models/Common/EntityReference';
import { IdCollectionRequest } from '../models/Common/IdCollectionRequest';
import { EntityFromTemplateDto } from '../models/Documents/Item/EntityFromTemplateDto';
import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';

@Injectable({
	providedIn: 'root'
})
export class BillsService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'api/v1/Bills', 'Invoice');
	}

	create(dto: BillCreateUpdateDto): Observable<MutationResponseDto> {
		return this.post<BillCreateUpdateDto, MutationResponseDto>(
			'',
			TransformDatesOnObject(BillCreateUpdateDto, TrimStringsOnObject(dto))
		);
	}

	batchCreate(dto: BillBatchCreateDto): Observable<BillBatchCreateResponseDto> {
		return this.post<BillBatchCreateDto, BillBatchCreateResponseDto>(
			`batchCreate`,
			TransformDatesOnObject(BillBatchCreateDto, TrimStringsOnObject(dto)),
			true
		);
	}

	update(id: string, dto: BillCreateUpdateDto): Observable<MutationResponseDto> {
		return this.put(`${id}`, TransformDatesOnObject(BillCreateUpdateDto, TrimStringsOnObject(dto)));
	}

	updateTemplate(id: string, dto: BillUpdateTemplateDto): Observable<MutationResponseDto> {
		return this.put(`${id}/template`, TransformDatesOnObject(BillUpdateTemplateDto, TrimStringsOnObject(dto)));
	}

	writeOff(id: string, dto: BillWriteOffDto): Observable<MutationResponseDto> {
		return this.put(`${id}/write-off`, TransformDatesOnObject(BillWriteOffDto, TrimStringsOnObject(dto)));
	}

	publishBill(templateDto: EntityFromTemplateDto): Observable<any> {
		return this.post<EntityFromTemplateDto, any>(
			`publishBill`,
			TransformDatesOnObject(EntityFromTemplateDto, TrimStringsOnObject(templateDto))
		);
	}

	updateStatus(statusUpdateDto: BillStatusUpdateDto): Observable<MutationResponseDto> {
		return this.put(
			`UpdateStatus`,
			TransformDatesOnObject(BillStatusUpdateDto, TrimStringsOnObject(statusUpdateDto))
		);
	}

	removePayments(paymentIdentifiers: PaymentIdentifiersDto): Observable<MutationResponseDto[]> {
		return this.post<PaymentIdentifiersDto, MutationResponseDto[]>(
			`RemovePayments`,
			TransformDatesOnObject(PaymentIdentifiersDto, TrimStringsOnObject(paymentIdentifiers))
		);
	}

	exportBillToPdf(exportDto: ExportBillToPdfDto): Observable<any> {
		return this.post<ExportBillToPdfDto, any>(
			`ExportBillToPdf`,
			TransformDatesOnObject(ExportBillToPdfDto, TrimStringsOnObject(exportDto))
		);
	}

	updateStatusInBulk(dto: BillStatusBulkUpdateDto): Observable<MutationResponseDto[]> {
		return this.put(
			`UpdateStatusInBulk`,
			TransformDatesOnObject(BillStatusBulkUpdateDto, TrimStringsOnObject(dto))
		);
	}

	addCostRecordsToBill(id: string, dto: BillCostRecordsDto): Observable<MutationResponseDto> {
		return this.put(`${id}/AddCostRecords`, TransformDatesOnObject(BillCostRecordsDto, TrimStringsOnObject(dto)));
	}

	deleteBill(id: string): Observable<MutationResponseDto> {
		return this.delete(`${id}`);
	}

	ignoreXeroSync(dto: IgnoreXeroSyncDto): Observable<MutationResponseDto> {
		return this.put(`IgnoreXeroSync`, TransformDatesOnObject(IgnoreXeroSyncDto, TrimStringsOnObject(dto)));
	}

	getBillList(request?: Partial<BillListRequest>): Observable<BillListResponse<BillListItemDto>> {
		return this.getItem<BillListRequest, BillListResponse<BillListItemDto>>('', request);
	}

	getBillItem(id: string): Observable<BillViewDto> {
		return this.getItem<void, BillViewDto>(`${id}`);
	}

	getFileSizeFromJob(jobId: string): Observable<BillFileSizeDto> {
		return this.getItem<void, BillFileSizeDto>(`${jobId}/GetFileSizeFromJob`);
	}

	downloadFromJob(jobId: string): Observable<IDownloadFile> {
		return this.getDownload<void>(`${jobId}/DownloadFromJob`);
	}

	exportByFilter(request?: Partial<BillListRequest>): Observable<HttpResponse<Blob>> {
		return this.getBlob<BillListRequest>(`ExportByFilter`, request);
	}

	exportByIds(request: IdCollectionRequest): Observable<HttpResponse<Blob>> {
		return this.getBlob<IdCollectionRequest>(`ExportByIds`, request);
	}
}
