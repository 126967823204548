import { Pipe, PipeTransform } from '@angular/core';

import { TaskStatus } from '@common/models/Tasks/Common/TaskStatus';

/*
 * Resolve task status display string by the enum key
 */
@Pipe({ name: 'taskStatus' })
export class TaskStatusPipe implements PipeTransform {
	transform(key: keyof typeof TaskStatus): string {
		if (!TaskStatus[key]) {
			throw Error(`Dev error: The Task Status enum does not contain the key ${key}`);
		}
		return TaskStatus[key];
	}
}
