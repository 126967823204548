// This file has been generated from MatterMailToReferenceVariables.cs
// Do not modify this file directly!!

// LawMaster.Amalthea.Domain.Settings.Setting.Common.MatterMailToReferenceVariables
export enum MatterMailToReferenceVariables {
	ClientName = 'Client Name',
	ClientShortName = 'Client Short Name',
	MatterNumber = 'Matter Number',
	MatterTitle = 'Matter Title',
	MatterConstant = 'Matter',
	Hyphen = '—'
}
