// This file has been generated from TitlePageOptions.cs
// Do not modify this file directly!!
// tslint:disable

import { SetSimpleType } from '@common/utils/class-transform';



// LawMaster.Amalthea.Domain.DocumentBriefs.Item.TitlePageOptions
export class TitlePageOptions {

	// IncludeTitlePage
	@SetSimpleType(Boolean)
	includeTitlePage: boolean;
	// TemplateId
	templateId: string;

	constructor() {

	}
}