// This file has been generated from TrustAccountsController.cs
// Do not modify this file directly!!
// tslint:disable

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { TrimStringsOnObject } from '@common/utils/string-format';

import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';
import { ListResponse } from '@common/models/Generic/ListResponse';
import { EntityReference } from '../../models/Common/EntityReference';
import { TrustAccountCreateUpdateDto } from '../../models/Settings/TrustSettings/TrustAccounts/Item/TrustAccountCreateUpdateDto';
import { TrustAccountSequenceSettingsViewDto } from '../../models/Settings/TrustSettings/TrustAccounts/Item/TrustAccountSequenceSettingsViewDto';
import { TrustAccountViewDto } from '../../models/Settings/TrustSettings/TrustAccounts/Item/TrustAccountViewDto';
import { TrustTransactionSequenceTypeDto } from '../../models/Settings/TrustSettings/TrustAccounts/Item/TrustTransactionSequenceTypeDto';
import { TrustTransactionSequenceTypeUpdateDto } from '../../models/Settings/TrustSettings/TrustAccounts/Item/TrustTransactionSequenceTypeUpdateDto';
import { TrustAccountListItemDto } from '../../models/Settings/TrustSettings/TrustAccounts/List/TrustAccountListItemDto';

@Injectable({
	providedIn: 'root'
})
export class TrustAccountsService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'api/v1/settings/TrustAccounts', 'Trust Account');
	}

	hasTrustAccounts(): Observable<boolean> {
		return this.getItem<void, boolean>(`exists`);
	}

	getTrustAccount(id: string): Observable<TrustAccountViewDto> {
		return this.getItem<void, TrustAccountViewDto>(`${id}`);
	}

	getTrustAccountList(): Observable<ListResponse<TrustAccountListItemDto>> {
		return this.getList<void, TrustAccountListItemDto>('');
	}

	createTrustAccount(newTrustAccountDto: TrustAccountCreateUpdateDto): Observable<MutationResponseDto> {
		return this.post<TrustAccountCreateUpdateDto, MutationResponseDto>('', TransformDatesOnObject(TrustAccountCreateUpdateDto, TrimStringsOnObject(newTrustAccountDto)));
	}

	updateTrustAccount(id: string, trustAccountDto: TrustAccountCreateUpdateDto): Observable<MutationResponseDto> {
		return this.put(`${id}`, TransformDatesOnObject(TrustAccountCreateUpdateDto, TrimStringsOnObject(trustAccountDto)));
	}

	deleteTrustAccount(id: string): Observable<MutationResponseDto> {
		return this.delete(`${id}`);
	}

	setDefaultTrustAccount(id: string): Observable<EntityReference> {
		return this.put(`SetDefaultTrustAccount/${id}`);
	}

	updateTrustSequenceSettings(trustAccountDto: TrustTransactionSequenceTypeUpdateDto): Observable<any> {
		return this.put(`updateTrustSequenceSettings`, TransformDatesOnObject(TrustTransactionSequenceTypeUpdateDto, TrimStringsOnObject(trustAccountDto)));
	}

	getAllTrustSequenceSettings(trustAccountId: string): Observable<TrustAccountSequenceSettingsViewDto[]> {
		return this.getArray<string, TrustAccountSequenceSettingsViewDto>(`getAllTrustSequenceSettings`, !!trustAccountId ? { trustAccountId: trustAccountId.toString() } : null);
	}

	getTrustSequenceSettings(trustAccountDto: TrustTransactionSequenceTypeDto): Observable<TrustAccountSequenceSettingsViewDto> {
		return this.getItem<TrustTransactionSequenceTypeDto, TrustAccountSequenceSettingsViewDto>(`getTrustSequenceSettings`, trustAccountDto);
	}

	getLastSequenceNumber(trustAccountDto: TrustTransactionSequenceTypeDto): Observable<number> {
		return this.getItem<TrustTransactionSequenceTypeDto, number>(`getLastSequenceNumber`, trustAccountDto);
	}

}
