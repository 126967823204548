<h2 mat-dialog-title>
	<span>Share Brief</span>
</h2>

<mat-dialog-content>
	<div class="flex-column">
		<div>Manage third party access to the selected brief through the use of access codes.</div>
		<div class="flex-row add-form">
			<div class="flex-column">
				<div class="flex-row">
					<contact-lookup
						#contactLookup
						Placeholder="Contact"
						[AllowCreate]="false"
						[AllowFreeText]="true"
						[FormControl]="getControl('contactId')"
						[FreeTextFormControl]="getControl('name')"
						[Hint]="'Search for a contact'"
						(Selected)="onContactSelected($event)"
						[Required]="true"
						[matterId]="config?.matterId"
						[showMatterRole]="!!config?.matterId"
					></contact-lookup>
				</div>

				<div class="flex-row">
					<mat-form-field class="email-field">
						<input [formControl]="getControl('email')" matInput autocomplete="off" />
						<mat-placeholder> Email </mat-placeholder>
						<mat-error>
							<error-messages [for]="getControl('email')"></error-messages>
						</mat-error>
					</mat-form-field>

					<mat-form-field class="date-field">
						<mat-placeholder>Date of expiry<sup class="color-warn">*</sup> </mat-placeholder>
						<input [formControl]="getControl('expiry')" matInput [matDatepicker]="datePicker" autocomplete="off" />
						<mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
						<mat-datepicker #datePicker></mat-datepicker>
						<mat-error>
							<error-messages [for]="getControl('expiry')"></error-messages>
						</mat-error>
					</mat-form-field>
				</div>
			</div>
		</div>
		<div class="grant-access">
			<button mat-raised-button color="primary" throttleButton (throttledClick)="onSaveFormClick()" [disabled]="!canSaveForm" title="Grant Access">
				Grant Access
			</button>
		</div>

		<mat-table [dataSource]="dataSource" matSort matSortActive="user" matSortDirection="asc">
			<ng-container matColumnDef="name">
				<mat-header-cell *matHeaderCellDef>Access Details</mat-header-cell>
				<mat-cell *matCellDef="let row" class="name-block">
					<div *ngIf="!row.contactId">{{ row?.name }}</div>
					<a *ngIf="!!row.contactId" [routerLink]="['/contacts/' + row.contactId]">{{ row?.name }}</a>

					<div class="embedded-row">
						<div *ngIf="!!row?.email">
							Email: <a [href]="'mailto:' + row?.email">{{ row?.email }}</a>
						</div>
						<div *ngIf="!!row?.expiry">Expires: {{ row?.expiry | moment : 'dd/MM/yyyy' }}</div>
					</div>

					<div class="embedded-row-mobile">
						<div class="code-copy">
							Access Code: {{ row?.code }}
							<button mat-icon-button color="primary" throttleButton (throttledClick)="onCopyAccessCodeButtonClick(row)">
								<mat-icon>file_copy</mat-icon>
							</button>
						</div>
					</div>
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef="code">
				<mat-header-cell *matHeaderCellDef>Access Code</mat-header-cell>
				<mat-cell *matCellDef="let row" class="code-copy">
					<button mat-icon-button color="primary" throttleButton (throttledClick)="onCopyAccessCodeButtonClick(row)">
						<mat-icon>file_copy</mat-icon>
					</button>

					<span>{{ row?.code }}</span>
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef="actions">
				<mat-header-cell *matHeaderCellDef></mat-header-cell>
				<mat-cell *matCellDef="let row">
					<button matTooltip="Revoke access" mat-icon-button class="action-button" throttleButton (throttledClick)="remove(row)">
						<mat-icon>delete</mat-icon>
					</button>

					<button matTooltip="Email access instructions to guest" mat-icon-button class="action-button" throttleButton (throttledClick)="mailTo(row)">
						<mat-icon>email</mat-icon>
					</button>
				</mat-cell>
			</ng-container>

			<mat-header-row *matHeaderRowDef="['name', 'code', 'actions']; sticky: true"></mat-header-row>
			<mat-row *matRowDef="let row; columns: ['name', 'code', 'actions']"></mat-row>
		</mat-table>
	</div>
</mat-dialog-content>

<mat-dialog-actions>
	<span matTooltip="Use this link with an access code to view brief" class="share-link">
		External Link:&nbsp;<a href="{{ shareLink }}" target="_blank">{{ shareLink }}</a>
		<button mat-icon-button color="primary" throttleButton (throttledClick)="onCopyShareLinkButtonClick()">
			<mat-icon>file_copy</mat-icon>
		</button>
	</span>

	<button mat-raised-button mat-dialog-close>Close</button>
</mat-dialog-actions>
