// This file has been generated from MatterTimerConfigCreateUpdateDto.cs
// Do not modify this file directly!!
// tslint:disable

import { SetSimpleType } from '@common/utils/class-transform';



// LawMaster.Amalthea.Domain.Settings.Setting.Item.MatterTimerConfigCreateUpdateDto
export class MatterTimerConfigCreateUpdateDto {

	// IsGlobalEnabled
	@SetSimpleType(Boolean)
	isGlobalEnabled: boolean;

	// AutoDeleteTimer
	@SetSimpleType(Boolean)
	autoDeleteTimer: boolean;

	constructor() {

	}
}