<mat-card class="mat-card">
	<section class="page-path"><a [routerLink]="['..']">System</a> / Login Options</section>

	<div class="c-top-header">
		<h1>Login Option Rules</h1>
	</div>

	<div class="c-description">Configure allowed Authentication methods for users.</div>

	<div class="c-options">
		<div class="c-slide-toggle-container">
			<mat-slide-toggle
				[checked]="isToggleChecked('allowEmailCredentials')"
				(change)="onToggleChanged('allowEmailCredentials', $event)"
				[disabled]="isToggleDisabled('allowEmailCredentials')"
			>
			</mat-slide-toggle>

			<div class="c-slide-toggle-content">
				<span>Allow Email Credentials</span>
				<span class="c-hint">Allow users to use email and password to login</span>
			</div>
		</div>

		<div class="c-slide-toggle-container">
			<mat-slide-toggle
				[checked]="isToggleChecked('allowGoogleCredentials')"
				(change)="onToggleChanged('allowGoogleCredentials', $event)"
				[disabled]="isToggleDisabled('allowGoogleCredentials')"
			>
			</mat-slide-toggle>

			<div class="c-slide-toggle-content">
				<span>Allow Google Credentials</span>
				<span class="c-hint">Allow users to use Google Authentication to login</span>
			</div>
		</div>

		<div class="c-slide-toggle-container">
			<mat-slide-toggle
				[checked]="isToggleChecked('allowMicrosoftCredentials')"
				(change)="onToggleChanged('allowMicrosoftCredentials', $event)"
				[disabled]="isToggleDisabled('allowMicrosoftCredentials')"
			>
			</mat-slide-toggle>

			<div class="c-slide-toggle-content">
				<span>Allow Microsoft Credentials</span>
				<span class="c-hint">Allow users to use Microsoft Authentication to login</span>
			</div>
		</div>
	</div>

	<div class="c-allowed-domains">
		<h3 class="c-allowed-domains__title">Allowed Domains</h3>

		<button class="c-allowed-domains__button" mat-button color="primary" throttleButton (throttledClick)="onAddDomainClicked()">
			<mat-icon>add_circle</mat-icon>
			<span>&nbsp;New Domain</span>
		</button>

		<div class="c-allowed-domains__list" *ngIf="!!allowedDomainControls?.length">
			<div class="c-allowed-domain" *ngFor="let allowedDomainControl of allowedDomainControls; let i = index">
				<mat-form-field class="c-allowed-domain__input">
					<mat-placeholder>
						Domain
						<sup class="color-warn">*</sup>
					</mat-placeholder>
					<input [formControl]="allowedDomainControl" matInput autocomplete="off" />
					<mat-error>
						<error-messages [for]="allowedDomainControl"></error-messages>
					</mat-error>
				</mat-form-field>

				<button class="c-allowed-domain__button" mat-icon-button throttleButton (throttledClick)="onDeleteDomainClicked(i)">
					<mat-icon>delete</mat-icon>
				</button>
			</div>
		</div>

		<div class="c-allowed-domains__examples">
			<h5>Examples</h5>
			<ul>
				<li class="c-allowed-domains__example">jane.doe@gmail.com has the domain "gmail.com"</li>
				<li class="c-allowed-domains__example">jane.doe@hotmail.com has the domain "hotmail.com"</li>
			</ul>
		</div>
	</div>

	<div class="c-actions">
		<button mat-raised-button color="primary" [disabled]="form.invalid || form.disabled || form.pristine" throttleButton (throttledClick)="onSaveClicked()">
			Save
		</button>
	</div>
</mat-card>
