import { Injectable } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { Router } from '@angular/router';

import { LocalStorageService } from '@common/services/storage/local-storage.service';

@Injectable({
	providedIn: 'root'
})
export class GridViewService {
	constructor(protected _router: Router, protected _localStorageService: LocalStorageService) {}

	storeFilterDefaults(dto: IFilterDefaultsConfig) {
		if (!this._localStorageService.isSupported) {
			return;
		}

		const stored = this.getFilterDefaults();

		if (
			stored?.pageSize !== dto?.pageSize ||
			stored?.sortColumn !== dto.sortColumn ||
			stored?.sortDirection !== dto.sortDirection
		) {
			this._localStorageService.setItem(this.getStorageKey(), dto);
		}
	}

	resetFilterDefaults() {
		if (!this._localStorageService.isSupported) {
			return;
		}

		this._localStorageService.removeItem(this.getStorageKey());
	}

	getFilterDefaults(): IFilterDefaultsConfig {
		if (!this._localStorageService.isSupported) {
			return null;
		}

		const values = this._localStorageService.getItemAs<IFilterDefaultsConfig>(this.getStorageKey());

		return !values
			? null
			: {
					pageSize: values.pageSize,
					sortColumn: values.sortColumn,
					sortDirection: values.sortDirection
			  };
	}

	private getStorageKey(suffix?: string): string {
		let path = this._router.routerState.snapshot.url.split('?')[0].split('(')[0];

		if (path.startsWith('/')) {
			path = path.substring(1, path.length);
		}

		if (path.endsWith('/')) {
			path = path.substring(0, path.length - 1);
		}

		// Finds RavenID's inside the url and strips them out.
		// I.E replaces route '/matters/142-A/documents' with '/matters/documents'
		path = path.replace(/\/\d+-[a-zA-Z](?=\/|$)/g, '');

		suffix = !suffix ? '' : `${suffix}-`;

		return `mattero-filters-${suffix}${path.split('/').join('-')}`;
	}
}

export interface IFilterDefaultsConfig {
	pageSize: number;
	sortColumn: string;
	sortDirection: SortDirection;
}

export interface IDataGridColumns {
	key: string;
	dataField: string | (() => string);
	caption?: string | (() => string);
	width: string;
	allowSorting?: boolean;
	allowSearch?: boolean;
	allowGrouping?: boolean;
	calculateSortValue?: (data: any) => number;
	calculateCellValue?: (data: any) => string;
	cellTemplate?: string;
	groupIndex?: number;
	format?: { type: string; precision: number } | string;
	visible?: boolean;
	dataType?: string;
	sortOrder?: string;
	visibleIndex?: number;
	calculateDisplayValue?: (data: any) => string;
	showInColumnChooser?: boolean;
}
