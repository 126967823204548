import { HttpResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { from, of, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { MarketplaceListingType } from '@common/models/Marketplace/Listings/Common/MarketplaceListingType';
import { MarketplaceIconService } from '@common/services/settings/marketplaceicon.service';

@Component({
	selector: 'marketplace-icon',
	styleUrls: ['./marketplace-icon.component.scss'],
	templateUrl: 'marketplace-icon.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.ShadowDom
})
export class MarketplaceIconComponent implements OnDestroy {
	private _subscriptions = new Subscription();

	private static imageCache: { [key: string]: IImageResult } = {};

	private _id: string;
	private _type: keyof typeof MarketplaceListingType;

	private _data: string;
	private _isSvg: boolean;

	get data(): string {
		return this._data;
	}

	get isSvg(): boolean {
		return this._isSvg;
	}

	@Input()
	set type(value: keyof typeof MarketplaceListingType) {
		if (this._type !== value) {
			var matchingEnum = Object.values(MarketplaceListingType).filter(
				x => x.toLowerCase() == value.toLowerCase()
			);
			this._type = matchingEnum[0] ?? value;

			if (!!this._id && this._type) {
				this.getData(this._id, this._type);
			}
		}
	}

	@Input()
	set id(value: string) {
		if (this._id !== value) {
			this._id = value;

			if (!!this._id && this._type) {
				this.getData(this._id, this._type);
			}
		}
	}

	constructor(
		private _cdr: ChangeDetectorRef,
		private _marketplaceIconService: MarketplaceIconService,
		private _domSanitizer: DomSanitizer
	) {}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}

	bypassSecurity(html: string) {
		return this._domSanitizer.bypassSecurityTrustHtml(html);
	}

	private getFullId(id: string, type: keyof typeof MarketplaceListingType): string {
		return `${type}_${id}`;
	}

	private getData(id: string, type: keyof typeof MarketplaceListingType): void {
		let observable$: Observable<IImageResult>;

		const image = MarketplaceIconComponent.imageCache[this.getFullId(id, type)];

		observable$ = !image ? this.getDataFromServer$(id, type) : of(image);
		this._subscriptions.add(
			observable$.subscribe(image => {
				this._data = image.data;
				this._isSvg = image.isSvg;
				this._cdr.detectChanges();
			})
		);
	}

	private getDataFromServer$(id: string, type: keyof typeof MarketplaceListingType): Observable<IImageResult> {
		let observable$: Observable<HttpResponse<Blob>>;

		switch (type) {
			case MarketplaceListingType.Package:
				observable$ = this._marketplaceIconService.getPackage(id);
				break;
			case MarketplaceListingType.Module:
				observable$ = this._marketplaceIconService.getModule(id);
				break;
			case MarketplaceListingType.Service:
				observable$ = this._marketplaceIconService.getService(id);
				break;
		}

		return observable$.pipe(
			switchMap(response => {
				console.log('type', response.type);
				return this.blobToBase64$(response.body).pipe(
					tap(data => (MarketplaceIconComponent.imageCache[this.getFullId(id, type)] = data))
				);
			})
		);
	}

	private blobToBase64$(blob: Blob): Observable<IImageResult> {
		return from(
			new Promise<IImageResult>((resolve, reject) => {
				const reader = new FileReader();

				const isSvg = blob.type === 'image/svg+xml';

				reader.onloadend = () => resolve({ isSvg: isSvg, data: reader.result as string });

				reader.onerror = () => reject();

				if (!isSvg) {
					reader.readAsDataURL(blob);
				} else {
					reader.readAsText(blob);
				}
			})
		);
	}
}

interface IImageResult {
	isSvg: boolean;
	data: string;
}
