import { Injectable } from '@angular/core';
import { IUploadFile } from '@common/models/Common/IFileUploader';
import { sumBy } from 'lodash-es';

@Injectable()
export class FileValidationService {
	private maxFileSizeMb: number = 1000; // 1GB file size max as that is the current max size of the http request.
	private dictionary = ['.dll', '.exe', '.cmd', '.bat', '.com', '.ps1', '.msi', '.reg', '.vbs', '.js'];

	validFileType(file: IUploadFile | File, onlyAllowedExtensions: string[] = null): boolean {
		if (onlyAllowedExtensions && onlyAllowedExtensions.length > 0) {
			return onlyAllowedExtensions.findIndex(ext => file.name.endsWith(ext)) >= 0;
		} else {
			return this.dictionary.findIndex(ext => file.name.endsWith(ext)) === -1;
		}
	}

	validFileSize(file: IUploadFile | File, maxFileSizeMb: number = null): boolean {
		return file.size / 1024 / 1024 <= (maxFileSizeMb || this.maxFileSizeMb);
	}

	validFileSizes(files: FileList | File[], maxFileSizeMb: number = null): boolean {
		const totalSize = sumBy(files, file => file.size);
		return totalSize / 1024 / 1024 <= (maxFileSizeMb || this.maxFileSizeMb);
	}
}
