import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, ValidationErrors } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

import { Observable, of, Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { AppcuesService } from '@common/appcues/appcues.service';
import { ILookupReference } from '@common/components/lookups/base-lookup.component';
import { ContactLookupComponent } from '@common/components/lookups/contact-lookup.component';
import { EnumSortDirection } from '@common/models/Common/EnumSortDirection';
import { CostingMethod } from '@common/models/Matters/Common/CostingMethod';
import { MatterStaffMemberDto } from '@common/models/Matters/Common/MatterStaffMemberDto';
import { MatterCreateDto } from '@common/models/Matters/Item/MatterCreateDto';
import { CostTemplateListItemDto } from '@common/models/Settings/CostTemplates/List/CostTemplateListItemDto';
import { PracticeAreaListItemDto } from '@common/models/Settings/PracticeAreas/List/PracticeAreaListItemDto';
import { Stage } from '@common/models/Settings/PracticeAreas/Stage';
import { UserViewDto } from '@common/models/Users/Item/UserViewDto';
import { DomainError } from '@common/models/Validation/DomainError';
import { NotificationService } from '@common/notification';
import { MattersService } from '@common/services/matters.service';
import { CostTemplatesService } from '@common/services/settings/costtemplates.service';
import { PracticeAreasService } from '@common/services/settings/practiceareas.service';
import { isEmptyOrNil } from '@common/utils/stringUtils';
import { CustomValidators } from '@common/validation/custom.validators';
import { Store } from '@ngrx/store';
import { get, remove } from 'lodash';

import { EntityType } from 'app/core/dialog.config';
import { EntityCreatedNotificationService } from 'app/core/entityCreatedNotification.service';
import { insertRecords } from 'app/core/state/lists/matter-list/matter-list.actions';
import { IAppState } from 'app/core/state/app.state';
import { CreateTimeRecordDialogData } from 'app/create-forms/matter/CreateTimeRecordDialogData';
import { MatterMemberDialogComponent } from 'app/matter/item/contacts/matter-member-dialog.component';

@Component({
	selector: 'matter-dialog',
	styleUrls: ['matter-dialog.component.scss'],
	templateUrl: 'matter-dialog.component.html'
})
export class MatterDialogComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('titleInput')
	titleInput: ElementRef;
	@ViewChild('clientLookup')
	clientInput: ContactLookupComponent;
	@ViewChild('lawyerLookup')
	lawyerInput: ContactLookupComponent;
	displayedColumns: string[] = ['staffName', 'type', 'rate', 'actions'];
	form: FormGroupTyped<MatterCreateDto>;
	practiceAreas: PracticeAreaListItemDto[];
	stages: Stage[];
	costingMethodKeys = Object.keys(CostingMethod) as CostingMethod[]; // Array<keyof typeof CostingMethod>;
	costTemplates: CostTemplateListItemDto[];
	saveClicked: boolean;
	showTaxType: boolean;
	matterStaffMembers = new MatTableDataSource<MatterStaffMemberDto>();

	get lawyerIsSameAsClient(): boolean {
		if (isEmptyOrNil(this.form.controls.clientId?.value) || isEmptyOrNil(this.form.controls.lawyerId?.value)) {
			return false;
		} else {
			return this.form.controls.clientId.value === this.form.controls.lawyerId.value;
		}
	}

	get currentPracticeAreaId(): string {
		return this.form.controls.practiceAreaId.value;
	}

	get isCostingMethodCostTemplate(): boolean {
		if (this.form) {
			return this.form.controls.costingMethod.value === this.costTemplateKey;
		}
		return false;
	}

	get customFieldsControlGroup(): FormGroup {
		return this.form.get('customFields') as any as FormGroup;
	}

	private readonly costTemplateKey: keyof typeof CostingMethod = 'CostTemplate';
	private subscription: Subscription = new Subscription();

	constructor(
		private store: Store<IAppState>,
		private dialog: MatDialog,
		private dialogRef: MatDialogRef<MatterDialogComponent>,
		private notifService: NotificationService,
		private matterService: MattersService,
		private practiceAreasService: PracticeAreasService,
		private costTemplatesService: CostTemplatesService,
		private fb: FormBuilder,
		private entityCreationNotifService: EntityCreatedNotificationService,
		private appcuesService: AppcuesService,
		private router: Router
	) {}

	ngOnInit() {
		this.form = this.fb.group({
			clientId: [null, CustomValidators.required('Client')],
			costTemplateId: [
				null,
				CustomValidators.requiredWhen(() => this.isCostingMethodCostTemplate, 'Cost Template')
			],
			costingMethod: [this.costingMethodKeys[0], CustomValidators.required('Costing Method')],
			createCompanies: null,
			createPersons: null,
			createPlurals: null,
			customFields: this.fb.group({}),
			dateEstimatedCompletion: [null, CustomValidators.estimatedCompletionDate('dateOpen')],
			dateOpen: new Date(Date.now()),
			description: null,
			estimatedFee: null,
			lawyerId: [null, CustomValidators.required('Lawyer')],
			practiceAreaId: [null, CustomValidators.required('Practice Area')],
			stageId: [{ value: null, disabled: true }, CustomValidators.required('Stage')],
			title: [null, CustomValidators.required('Matter Title')],
			calculateTaxes: null,
			matterStaffMembers: []
		}) as FormGroupTyped<MatterCreateDto>;

		// Get all available Practice Areas
		this.subscription.add(
			this.practiceAreasService
				.getPracticeAreaList({ sortBy: 'name', sortDirection: EnumSortDirection.Asc, showOnlyMyAreas: true })
				.subscribe(
					practiceAreas => {
						this.practiceAreas = practiceAreas.records;
					},
					e => this.notifService.showErrors('Error getting Practice Areas.', e)
				)
		);

		this.subscription.add(
			this.costTemplatesService.getCostTemplateList().subscribe(costTemplatesList => {
				this.costTemplates = costTemplatesList.records;
				if (!get(this.costTemplates, 'length')) {
					remove(this.costingMethodKeys, costTemplate => costTemplate === this.costTemplateKey);
				}
			})
		);

		this.subscription.add(
			this.store
				.select(state => state?.currentUserData?.currentUser)
				.subscribe((user: UserViewDto) =>
					this.form.patchValue({
						lawyerId: user?.contact?.id
					})
				)
		);

		this.subscription.add(
			this.store
				.select(state => state?.tenantData?.tenantInformation?.taxConfiguration)
				.subscribe(x => {
					this.showTaxType = x === 'OptionalOn' || x === 'OptionalOff';
					if (!!this.showTaxType) {
						this.form.controls.calculateTaxes.setValue(x === 'OptionalOn');
					}
				})
		);
	}

	ngAfterViewInit() {
		if (this.titleInput) {
			setTimeout(() => this.titleInput.nativeElement.focus(), 0);
		}

		this.subscription.add(
			this.form.controls.clientId.valueChanges.subscribe(x => {
				if (this.lawyerIsSameAsClient) {
					const error: ValidationErrors = { lawyerSameAsClientError: 'Client must not be same as Lawyer' };
					this.clientInput.inputDisplayCtrl.setErrors(error);
					this.form.controls.clientId.setErrors(error);
				} else if (this.form.controls.lawyerId.hasError('lawyerSameAsClientError')) {
					this.lawyerInput.inputDisplayCtrl.setErrors(null);
					this.form.controls.lawyerId.setErrors(null);
				}
			})
		);

		this.subscription.add(
			this.form.controls.lawyerId.valueChanges.subscribe(x => {
				if (this.lawyerIsSameAsClient) {
					const error: ValidationErrors = { lawyerSameAsClientError: 'Lawyer must not be same as Client' };
					this.lawyerInput.inputDisplayCtrl.setErrors(error);
					this.form.controls.lawyerId.setErrors(error);
				} else if (this.form.controls.clientId.hasError('lawyerSameAsClientError')) {
					this.clientInput.inputDisplayCtrl.setErrors(null);
					this.form.controls.clientId.setErrors(null);
				}
			})
		);
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	costingMethodChanged() {
		const costTemplateElement = this.form.controls.costTemplateId;
		if (this.isCostingMethodCostTemplate) {
			costTemplateElement.markAsTouched();
		} else {
			costTemplateElement.patchValue('');
		}
	}

	addMember() {
		this.subscription.add(
			this.dialog
				.open(MatterMemberDialogComponent)
				.afterClosed()
				.pipe(filter(Boolean))
				.subscribe((result: MatterStaffMemberDto) => {
					this.updateTeamMemberList(result);
				})
		);
	}

	removeMember(row: MatterStaffMemberDto) {
		this.subscription.add(
			this.notifService
				.showConfirmation(
					'Remove Team Member',
					`Are you sure you want to remove "${row.contactReference.name}" from this matter?`
				)
				.pipe(
					filter(Boolean),
					map(() => this.deleteMember(row))
				)
				.subscribe({
					next: () => {
						this.matterStaffMembers.data = this.matterStaffMembers.data.filter(
							x => x.contactReference.id != row.contactReference.id
						);
						this.notifService.showNotification(`Team member removed succesfully`);
					},
					error: e => this.notifService.showErrors('Error removing team member', e)
				})
		);
	}

	deleteMember(dto: MatterStaffMemberDto) {
		const memberIndex = this.matterStaffMembers.data.findIndex(
			member => member.contactReference.id === dto.contactReference.id
		);

		const oldMember = this.matterStaffMembers.data[memberIndex];
		delete this.matterStaffMembers.data[memberIndex];

		if (!!oldMember.userType?.id && !!oldMember.isMain) {
			const member = this.matterStaffMembers.data.filter(
				member => member.userType?.id === oldMember.userType.id
			)[0];

			if (!!member) {
				member.isMain = true;
			}
		}
	}

	private updateTeamMemberList(dto: MatterStaffMemberDto) {
		const memberIndex = this.matterStaffMembers.data.findIndex(
			member => member.contactReference.id === dto.contactReference.id
		);

		const isCreate = memberIndex < 0;
		let matterStaffMember: MatterStaffMemberDto;

		if (isCreate) {
			matterStaffMember = new MatterStaffMemberDto();
			matterStaffMember.contactReference = dto.contactReference;
			this.matterStaffMembers.data.push(dto);
			matterStaffMember = this.matterStaffMembers.data[this.matterStaffMembers.data.length - 1];
		} else {
			matterStaffMember = this.matterStaffMembers.data[memberIndex];
		}

		matterStaffMember.isMain = false;

		// Handle Main Assignment (Per Unit Type)
		if (!!dto.userType?.id) {
			const matchingUserTypeMembers = this.matterStaffMembers.data.filter(
				member => member.userType?.id === dto.userType.id && member !== matterStaffMember
			);

			// Assign Main to dto if not other have Main
			if (!matchingUserTypeMembers?.length) {
				matterStaffMember.isMain = true;
				// If is Main deassign other Mains
			} else if (dto.isMain) {
				matchingUserTypeMembers.forEach(member => (member.isMain = false));

				matterStaffMember.isMain = true;
			}

			// Assign Main to Remaining Members of User Type
			if (!!matterStaffMember.userType?.id && matterStaffMember.userType.id !== dto.userType.id) {
				const member = this.matterStaffMembers.data.filter(
					member => member.userType?.id === matterStaffMember.userType.id && member !== matterStaffMember
				)[0];

				if (!!member) {
					member.isMain = true;
				}
			}
		}
		// Assign Main to Remaining Members of User Type
		else if (!!matterStaffMember.userType?.id) {
			const member = this.matterStaffMembers.data.filter(
				member => member.userType?.id === matterStaffMember.userType?.id
			)[0];

			if (!!member) {
				member.isMain = true;
			}
		}

		matterStaffMember.rate = dto.rate;
		matterStaffMember.userType = dto.userType;

		this.matterStaffMembers.data = this.matterStaffMembers.data; // Required for change detection
		this.notifService.showNotification(`Matter Team Members updated`);
	}

	save(): void {
		this.saveMatter(false);
	}

	saveAndOpen(): void {
		this.saveMatter(true);
	}

	saveAndShowTimeRecordsDialog(): void {
		this.subscription.add(
			this.createMatterAndShowNotification().subscribe(
				(matterId: string) => {
					const costingMethod = this.form.controls.costingMethod.value;
					const prepopulatedData: CreateTimeRecordDialogData = {
						associatedMatterId: matterId,
						costingMethod
					};

					if (costingMethod === CostingMethod.Fixed) {
						prepopulatedData.durationMinutes = 0;
						if (!!this.form.controls.estimatedFee.value) {
							prepopulatedData.amount = this.form.controls.estimatedFee.value;
						}
					}

					this.dialogRef.close(prepopulatedData);
				},
				(errs: DomainError[]) => {
					this.notifService.showErrors(`Error saving matter`, errs);
					this.saveClicked = false;
				}
			)
		);
	}

	// -------------------------------------------------------------------------
	// Get stages for selected 'Practice Area'
	practiceAreaChanged(event: MatSelectChange): void {
		const stageControl = this.form.controls.stageId;
		stageControl.setValue(null);
		this.subscription.add(
			this.practiceAreasService.getPracticeArea(event.value).subscribe(
				practiceArea => {
					this.stages = practiceArea.stages;
					stageControl.enable();
					if (this.stages && this.stages.length > 0) {
						// select the first stage automatically
						this.form.controls.stageId.patchValue(this.stages[0].id);
					}
				},
				e => this.notifService.showErrors(`Error getting Stages for Practice Area ${event.value}.`, e)
			)
		);
	}

	onContactSelected(event: ILookupReference) {
		this.form.controls.clientId.setValue(event.id);
	}

	private saveMatter(openMatter: boolean) {
		this.saveClicked = true;
		this.subscription.add(
			this.createMatterAndShowNotification().subscribe({
				next: matterId => {
					this.dialogRef.close();
					if (openMatter) {
						this.router.navigate([`/matters/${matterId}/dashboard`]);
					}
				},
				error: (errs: DomainError[]) => {
					this.notifService.showErrors(`Error saving matter`, errs);
					this.saveClicked = false;
				}
			})
		);
	}

	private createMatterAndShowNotification(): Observable<string> {
		this.form.value.matterStaffMembers = this.matterStaffMembers.data;

		return this.matterService.createMatter(this.form.value).pipe(
			switchMap(ref => {
				const notif = {
					linkRoute: ['/matters', ref.id],
					linkText: ref.name,
					text: 'Matter created:'
				};
				this.store.dispatch(insertRecords({ response: ref }));
				this.entityCreationNotifService.entityCreated(EntityType.Matter);
				this.notifService.showNotificationLink(notif);
				this.appcuesService.trackDialog(EntityType.Matter);
				return of(ref.id);
			})
		);
	}
}
