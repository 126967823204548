<mat-card>
	<section class="page-path"><a [routerLink]="['..']">System</a> / Manage Features</section>
	<div class="top-header">
		<h1>Manage Features</h1>
		<button mat-raised-button color="primary" [disabled]="form.invalid || form.disabled || form.pristine" throttleButton (throttledClick)="save()">
			Save
		</button>
	</div>
	<div class="description">Hide or show available features within {{ siteName }}</div>
	<hr />
	<div class="items" [formGroup]="form">
		<div
			class="container"
			[class.container--disabled]="!getFormGroupControl(formFeatureState, 'canUserEnable').value"
			[formGroup]="formFeatureState"
			*ngFor="let formFeatureState of formFeatureStates"
		>
			<ng-container *ngIf="isFeatureDescriptionAvailable(getFormGroupControl(formFeatureState, 'type').value)">
				<mat-icon>{{ getIcon(getFormGroupControl(formFeatureState, 'type').value) }}</mat-icon>

				<div class="label">
					<span class="title field-space">{{ getFormGroupControl(formFeatureState, 'type').value | featureType }}</span>
					<span class="description">{{ getTypeDescription(getFormGroupControl(formFeatureState, 'type').value) }}</span>
				</div>
				<mat-slide-toggle
					class="enable-toggle"
					[checked]="getFormGroupControl(formFeatureState, 'isEnabled').value"
					(change)="handleSlideToggle(formFeatureState, $event)"
					[disabled]="!getFormGroupControl(formFeatureState, 'canUserEnable').value"
				></mat-slide-toggle>
				<span *ngIf="getAvailableIn(getFormGroupControl(formFeatureState, 'type').value)"
					>Available in
					<a [routerLink]="['../subscription']" class="availableIn">{{
						getAvailableIn(getFormGroupControl(formFeatureState, 'type').value)
					}}</a></span
				>
				<mat-divider></mat-divider>
			</ng-container>
		</div>
	</div>
</mat-card>
