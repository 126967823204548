import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

import { FeatureFlags, isFeatureFlagEnabled } from 'app/app.config';

@Directive({
	selector: '[featureFlag]'
})
export class FeatureFlagDirective implements OnInit {
	// tslint:disable-next-line:no-input-rename
	@Input('featureFlag') featureFlag: FeatureFlags;

	constructor(private el: ElementRef, private renderer: Renderer2) {}

	ngOnInit(): void {
		if (!isFeatureFlagEnabled(this.featureFlag)) {
			this.renderer.setStyle(this.el.nativeElement, 'display', 'none');
		}
	}
}
