import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

import { SharedDirectivesModule } from '@common/directives/shared-directives.module';
import { SharedPipesModule } from '@common/pipes/shared-pipes.module';
import { NgObjectPipesModule } from 'angular-pipes';

import { ComponentsModule } from 'app/shared/components';

import { PipesModule } from '../pipes/pipes.module';
import { EditableCustomFieldsComponent } from './custom-fields/editable-custom-fields.component';
import { EditableAddressComponent } from './editable-address.component';
import { EditableAddressService } from './editable-address.service';
import { EditableCalculatedComponent } from './editable-calculated.component';
import { EditableCheckboxComponent } from './editable-checkbox.component';
import { EditableContactComponent } from './editable-contact.component';
import { EditableDateComponent } from './editable-date.component';
import { EditableEmailAddressComponent } from './editable-email-address.component';
import { EditableLongTextComponent } from './editable-long-text.component';
import { EditableNumericValueComponent } from './editable-numeric.component';
import { EditablePhoneComponent } from './editable-phone.component';
import { EditableSelectionComponent } from './editable-selection.component';
import { EditableShortTextComponent } from './editable-short-text.component';
import { EditableUrlComponent } from './editable-url.component';

@NgModule({
	declarations: [
		EditableAddressComponent,
		EditableCalculatedComponent,
		EditableCheckboxComponent,
		EditableContactComponent,
		EditableCustomFieldsComponent,
		EditableDateComponent,
		EditableEmailAddressComponent,
		EditableLongTextComponent,
		EditableNumericValueComponent,
		EditablePhoneComponent,
		EditableSelectionComponent,
		EditableShortTextComponent,
		EditableUrlComponent
	],
	exports: [
		EditableAddressComponent,
		EditableCalculatedComponent,
		EditableCheckboxComponent,
		EditableContactComponent,
		EditableCustomFieldsComponent,
		EditableDateComponent,
		EditableEmailAddressComponent,
		EditableLongTextComponent,
		EditableNumericValueComponent,
		EditablePhoneComponent,
		EditableSelectionComponent,
		EditableShortTextComponent,
		EditableUrlComponent
	],
	imports: [
		MatAutocompleteModule,
		CommonModule,
		ComponentsModule,
		FormsModule,
		MatButtonModule,
		MatCheckboxModule,
		MatDatepickerModule,
		MatIconModule,
		MatInputModule,
		MatRippleModule,
		MatSelectModule,
		MatTooltipModule,
		NgObjectPipesModule,
		ReactiveFormsModule,
		RouterModule,
		SharedDirectivesModule,
		PipesModule,
		SharedPipesModule
	],
	providers: [EditableAddressService]
})
export class EditableControlsModule {}
