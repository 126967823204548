<h2 mat-dialog-title>
	<span>{{ isEdit ? 'Edit' : 'Create' }} Brief</span>
</h2>
<div [formGroup]="form">
	<mat-dialog-content [formGroup]="form">
		<mat-form-field>
			<input #NameInput formControlName="name" matInput autocomplete="off" />
			<mat-placeholder>
				Name
				<sup class="color-warn">*</sup>
			</mat-placeholder>
			<mat-error>
				<error-messages [for]="form.get('name')"></error-messages>
			</mat-error>
		</mat-form-field>
		<mat-radio-group *ngIf="!isEdit" formControlName="createBriefType" class="flex-column options">
			<mat-radio-button [value]="tagsKey" (change)="checkBriefType(tagsKey)">By Document Tags</mat-radio-button>
			<mat-radio-button [value]="templateKey" (change)="checkBriefType(templateKey)">By Template</mat-radio-button>
		</mat-radio-group>
		<div [class.hide]="createBriefType !== tagsKey">
			<mat-autocomplete #auto="matAutocomplete">
				<mat-option *ngFor="let tag of availableTags" [value]="tag">
					<div>{{ tag }}</div>
				</mat-option>
			</mat-autocomplete>
			<div class="flexRow tags-container">
				<span *ngIf="!data?.briefId" class="tags-desc"
					>Create brief sections based on document tags. Documents assigned with the associated tag will be added to the corresponding section</span
				>
				<span *ngIf="data?.briefId" class="tags-desc">Drag and drop the sections to change their order</span>
				<button *ngIf="data?.briefId" mat-button color="primary" throttleButton (throttledClick)="addSection()">
					<mat-icon>add</mat-icon>
					<span>New Section</span>
				</button>
			</div>

			<mat-selection-list class="tags-list" cdkDropList (cdkDropListDropped)="drop($event)" *ngIf="!data?.briefId">
				<mat-list-option
					class="tag-box flexRow"
					cdkDrag  cdkDragPreviewContainer="parent"
					*ngFor="let section of sections; index as i; let first = first; let last = last"
					[value]="section.id"
				>
					<div class="flexRow">
						<mat-icon>drag_indicator</mat-icon>
						<input 
							(keydown)="$event.stopPropagation()"
							matInput
							#autoCompleteInput
							[matAutocomplete]="auto"
							[matAutocompleteDisabled]="!!getSectionControl(section.id).value"
							[formControl]="getSectionControl(section.id)"
							(change)="sectionNameEntered($event, section.id, i)"
						/>
						<div *ngIf="duplicateSectionErrors[i]" class="warning-color">Duplicate section name</div>
					</div>
					<mat-icon class="delete-icon" throttleButton (throttledClick)="removeSection(section.id, i)">delete</mat-icon>
				</mat-list-option>
			</mat-selection-list>
			<div class="tags-list" cdkDropList (cdkDropListDropped)="drop($event)" *ngIf="data?.briefId">
				<div class="tag-box flexRow" *ngFor="let section of sections; index as i; let first = first; let last = last">
					<div class="flexRow drag-indicator" cdkDrag>
						<mat-icon>drag_indicator</mat-icon>
						<mat-form-field>
							<mat-placeholder>Section Name</mat-placeholder>
							<input
								matInput
								#autoCompleteInput
								[formControl]="getSectionControl(section.id)"
								(change)="sectionNameEntered($event, section.id, i)"
							/>
						</mat-form-field>
						<div *ngIf="duplicateSectionErrors[i]" class="warning-color">Duplicate section name</div>
					</div>
					<mat-icon class="delete-icon" throttleButton (throttledClick)="removeSection(section.id, i)">delete</mat-icon>
				</div>
			</div>
		</div>

		<div [class.hide]="createBriefType !== templateKey">
			<div class="description-container">
				<span class="description"
					>Select the Brief Template to be used for this Brief. Documents assigned with the associated tag will be added to the corresponding
					section</span
				>
			</div>

			<div class="flex-row">
				<mat-form-field>
					<mat-placeholder>Brief Templates</mat-placeholder>
					<mat-select #selectTemplates formControlName="templateId">
						<mat-option *ngFor="let template of templates" [value]="template.id">
							{{ template.name }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div *ngIf="!templates?.length">
				<a [routerLink]="['/system/document-brief-templates']" throttleButton (throttledClick)="closeDialog()"
					>No brief templates configured. Click here to create.</a
				>
			</div>
		</div>
	</mat-dialog-content>
	<div class="actions">
		<div>
			<button mat-stroked-button *ngIf="data?.briefId" throttleButton (throttledClick)="delete()" color="warn">Delete</button>
		</div>
		<div>
			<button class="save-btn" mat-raised-button throttleButton (throttledClick)="save()" [disabled]="form.invalid" color="primary">Save</button>
			<button mat-raised-button mat-dialog-close>Cancel</button>
		</div>
	</div>
</div>
