<h2 mat-dialog-title>
	<span>Set Starting Number</span>
</h2>
<mat-dialog-content [formGroup]="form">
	<div class="description">Enter a starting number for your new invoices. Please note that this number must be greater than {{ data.nextNumber }}.</div>
	<br />
	<mat-form-field>
		<input formControlName="startingNumber" matInput type="number" autocomplete="off" />
		<mat-placeholder>
			Starting Number
			<sup class="color-warn">*</sup>
		</mat-placeholder>
		<mat-error>
			<error-messages [for]="form.get('startingNumber')"></error-messages>
		</mat-error>
	</mat-form-field>
	<div *ngIf="updateError" class="info-message color-warning">
		<mat-icon>error</mat-icon>
		<span>{{ updateError }}</span>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-raised-button throttleButton (throttledClick)="save()" [disabled]="form.invalid" color="primary">Save</button>
	<button mat-raised-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
