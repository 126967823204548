import { CustomFieldEntityType } from '@common/models/Settings/CustomFields/Common/CustomFieldEntityType';
import { createReducer, on } from '@ngrx/store';
import moment from 'moment';

import { invalidateCache, loadFailed, loading, loadSuccess } from './calculation-variable-list.actions';
import { initialState, initialVariablesState } from './calculation-variable-list.state';

const cacheExpirySeconds = 300; // 5 Minutes;

export const calculationVariableListReducer = createReducer(
	initialState,
	on(loading, (state, props) => {
		const cloneState = { ...state };

		if (props?.entityType === CustomFieldEntityType.Contact) {
			cloneState.contactVariables = {
				...(state.contactVariables ?? initialVariablesState),
				isFetching: true
			};
		} else {
			cloneState.matterVariables = {
				...(state.matterVariables ?? initialVariablesState),
				isFetching: true
			};
		}

		return cloneState;
	}),

	on(loadSuccess, (state, props) => {
		const cloneState = { ...state };

		if (props?.entityType === CustomFieldEntityType.Contact) {
			cloneState.contactVariables = {
				...(state.contactVariables ?? initialVariablesState),
				list: props?.response ?? [],
				isFetching: false,
				cacheExpiry: moment().add(cacheExpirySeconds, 'seconds')
			};
		} else {
			cloneState.matterVariables = {
				...(state.matterVariables ?? initialVariablesState),
				list: props?.response ?? [],
				isFetching: false,
				cacheExpiry: moment().add(cacheExpirySeconds, 'seconds')
			};
		}

		return cloneState;
	}),

	on(loadFailed, (state, props) => {
		const cloneState = { ...state };

		if (props?.entityType === CustomFieldEntityType.Contact) {
			cloneState.contactVariables = {
				...(state.contactVariables ?? initialVariablesState),
				error: props?.error,
				isFetching: false
			};
		} else {
			cloneState.matterVariables = {
				...(state.matterVariables ?? initialVariablesState),
				error: props?.error,
				isFetching: false
			};
		}

		return cloneState;
	}),

	on(invalidateCache, (state, props) => {
		const cloneState = { ...state };

		if (props?.entityType === CustomFieldEntityType.Contact) {
			cloneState.contactVariables = {
				...(state.contactVariables ?? initialVariablesState),
				cacheExpiry: null
			};
		} else {
			cloneState.matterVariables = {
				...(state.matterVariables ?? initialVariablesState),
				cacheExpiry: null
			};
		}

		return cloneState;
	})
);
