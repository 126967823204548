// This file has been generated from ChangeMatterPracticeAreaDto.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.Domain.Matters.Item.ChangeMatterPracticeAreaDto
export class ChangeMatterPracticeAreaDto {

	// PracticeAreaId
	practiceAreaId: string;
	// StageId
	stageId: number;

	constructor() {

	}
}