import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

import { Subscription } from 'rxjs';

import { NotificationService } from '@common/notification';
import { isNil } from 'lodash-es';

@Component({
	selector: 'matter-numbering-editor',
	styleUrls: ['./matter-numbering-editor.component.scss'],
	templateUrl: 'matter-numbering-editor.component.html'
})
export class MatterNumberingEditorComponent implements OnInit, OnDestroy {
	form: FormGroup;
	private _isEditMode: boolean;
	private _actualNextMatterNumber: number;
	private _nextMatterNumber: number;

	private _subscriptions = new Subscription();

	private _control: AbstractControl;
	private _controlSubscriptions: Subscription;

	get isEditMode(): boolean {
		return this._isEditMode;
	}

	@Input()
	set control(value: AbstractControl) {
		if (this._control !== value) {
			this._control = value;

			if (!!this._controlSubscriptions) {
				this._controlSubscriptions.unsubscribe();
				this._controlSubscriptions = null;
			}

			if (!!this._control) {
				this._controlSubscriptions = this._control.valueChanges.subscribe(number => {
					this.setMatterNumbers(number);
				});

				this.setMatterNumbers(this._control.value);
			}
		}
	}

	constructor(private _fb: FormBuilder, private _notficationService: NotificationService) { }

	ngOnInit() {
		this.form = this._fb.group({
			nextNumber: [null, this.performValidation()]
		});
	}

	ngOnDestroy(): void {
		this._controlSubscriptions?.unsubscribe();
		this._subscriptions.unsubscribe();
	}

	currentMatterNumber(): number {
		return this._actualNextMatterNumber - 1;
	}

	performValidation(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors => {
			if (isNil(control.value)) {
				return { val: 'Enter a value' };
			}
			if (control.value < 0) {
				return { val: 'Enter a positive value' };
			}
			if (control.value < this._actualNextMatterNumber) {
				return {
					val: 'The next Matter number must be greater than ' + this.currentMatterNumber()
				};
			}
			return null;
		};
	}

	update() {
		this._subscriptions.add(
			this._notficationService
				.showConfirmation(
					'Confirm Changes',
					'Are you sure you want to update the next Matter number? This change cannot be reversed and the starting number cannot be set back to a lower number.',
					'Yes',
					'No'
				)
				.subscribe(flag => {
					if (!!flag) {
						this.confirmChanges();
					} else {
						this.cancelEditing();
					}
				})
		);
	}

	cancelEditing() {
		this._isEditMode = false;
		this.form.markAsPristine();
	}

	startEditing() {
		this.form.get('nextNumber').setValue(this._nextMatterNumber);
		this._isEditMode = true;
	}

	reset() {
		if (!!this._control) {
			this.resetMatterNumbers(this._control.value);
		}
	}

	private confirmChanges() {
		const selectedNextMatterNumber = this.form.get('nextNumber').value;

		// If number is the same just reset the form
		if (selectedNextMatterNumber === this._nextMatterNumber) {
			this.form.reset();
			this._nextMatterNumber = this._actualNextMatterNumber;
		} else {
			this._control?.setValue(selectedNextMatterNumber);
			this._nextMatterNumber = selectedNextMatterNumber;
		}

		this._isEditMode = false;
	}

	private resetMatterNumbers(number: number) {
		this._nextMatterNumber = number;
		this._actualNextMatterNumber = number;
	}

	private setMatterNumbers(number: number) {
		if (!this._nextMatterNumber) {
			this.resetMatterNumbers(number);
		}
	}
}
