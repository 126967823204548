import { SortDirection } from '@angular/material/sort';

import { ListResponse } from '@common/models/Generic/ListResponse';
import { InfoTrackOrderListItemDto } from '@common/models/InfoTrack/Orders/List/InfoTrackOrderListItemDto';
import { InfoTrackOrderListRequest } from '@common/models/InfoTrack/Orders/List/InfoTrackOrderListRequest';
import { DomainError } from '@common/models/Validation/DomainError';
import { createAction, props } from '@ngrx/store';

import { SelectionType } from '../../SelectionType';

export enum InfoTrackListActions {
	Init = '[InfoTrack List] Initialise',
	Load = '[InfoTrack List] Load',
	Loading = '[InfoTrack List] Loading',
	LoadSuccess = '[InfoTrack List] Load Success',
	LoadFailed = '[InfoTrack List] Load Failed',

	SetFilters = '[InfoTrack List] Set Filters',
	SetPageSize = '[InfoTrack List] Set Page Size',
	SetPageIndex = '[InfoTrack List] Set Page Index',
	SetPageIndexForId = '[InfoTrack List] Set Page Index For Id',

	SetSortBy = '[InfoTrack List] Set Sort By',
	SetSortDirection = '[InfoTrack List] Set Sort Direction',

	InsertRecords = '[InfoTrack List] Insert Records',
	UpdateRecords = '[InfoTrack List] Update Records',
	SelectRecords = '[InfoTrack List] Select Records'
}

export const init = createAction(InfoTrackListActions.Init);
export const load = createAction(InfoTrackListActions.Load);
export const loading = createAction(InfoTrackListActions.Loading);
export const loadSuccess = createAction(
	InfoTrackListActions.LoadSuccess,
	props<{ response: ListResponse<InfoTrackOrderListItemDto> }>()
);
export const loadFailed = createAction(InfoTrackListActions.LoadFailed, props<{ error: DomainError }>());

export const setFilters = createAction(
	InfoTrackListActions.SetFilters,
	props<{ filter: Partial<InfoTrackOrderListRequest> }>()
);
export const setPageSize = createAction(InfoTrackListActions.SetPageSize, props<{ pageSize: number }>());
export const setPageIndex = createAction(InfoTrackListActions.SetPageIndex, props<{ pageIndex: number }>());
export const setPageIndexForId = createAction(InfoTrackListActions.SetPageIndexForId, props<{ id: string }>());

export const setSortBy = createAction(InfoTrackListActions.SetSortBy, props<{ sortBy: string }>());
export const setSortDirection = createAction(
	InfoTrackListActions.SetSortDirection,
	props<{ sortDirection: SortDirection }>()
);

export const selectRecords = createAction(
	InfoTrackListActions.SelectRecords,
	props<{ row: InfoTrackOrderListItemDto; selectionType: SelectionType }>()
);
