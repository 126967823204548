import { isArray, isEmpty, isNil } from 'lodash-es';

export function arraysEqual<TSource>(left: TSource[], right: TSource[]) {
	if (!left && !right) {
		return true;
	} else if ((!!left && !right) || (!left && !!right)) {
		return false;
	} else if (left.length !== right.length) {
		return false;
	} else {
		for (var index = 0; index < left.length; index++) {
			if (left[index] !== right[index]) return false;
		}

		return true;
	}
}

export function allocateArray<TSource>(size: number, getDefaultValue: () => TSource): TSource[] {
	let array: TSource[] = [];

	for (let index = 0; index < size; ++index) {
		array[index] = getDefaultValue();
	}

	return array;
}

export function isNullOrEmpty(item: any): boolean {
	// ignore empty values as well as empty arrays
	return isNil(item) || (isArray(item) && isEmpty(item));
}

export function arrayAs<T>(config: any[]) {
	return config as T[];
}

/**
 *
 * @param array The array to copy
 * @returns A clone of the input array using JSON Parse (note: does not handle functions)
 */
export function arrayDeepCopy<T>(array: T[]): T[] {
	return JSON.parse(JSON.stringify(array));
}
