// This file has been generated from BillCostRecordsDto.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.Domain.Bills.Item.BillCostRecordsDto
export class BillCostRecordsDto {

	// CostRecordIds
	costRecordIds: string[];

	constructor() {

	}
}