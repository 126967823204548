import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { filter, switchMap } from 'rxjs/operators';

import { DomainError } from '@common/models/Validation/DomainError';
import { NotificationService } from '@common/notification';
import { BillsService } from '@common/services/settings/bills.service';

import { CustomValidators } from '@common/validation/custom.validators';

import { IBillNextNumberData } from './IBillNextNumberData';

@Component({
	selector: 'bill-next-number-dialog',
	styleUrls: ['./bill-next-number-dialog.component.scss'],
	templateUrl: './bill-next-number-dialog.component.html'
})
export class BillNextNumberDialogComponent implements OnInit {
	form: FormGroupTyped<IBillNextNumberForm>;
	updateError: string;

	constructor(
		private dialogRef: MatDialogRef<BillNextNumberDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: IBillNextNumberData,
		private fb: FormBuilder,
		private billSettingsService: BillsService,
		private notificationService: NotificationService
	) {}

	ngOnInit(): void {
		this.form = this.fb.group({
			startingNumber: [null, [CustomValidators.required('Starting Number'), Validators.min(1)]]
		}) as FormGroupTyped<IBillNextNumberForm>;
	}

	save() {
		const startingNumber = this.form.controls.startingNumber.value;

		this.notificationService
			.showConfirmation(
				`Confirm change of Starting Number to ${startingNumber}`,
				`Do you want to proceed to update the Starting Number? <br>` +
					`This action cannot be reversed and the starting number cannot be set back to a lower number.`
			)
			.pipe(
				filter(Boolean),
				switchMap(() => this.billSettingsService.updateNextNumber(startingNumber))
			)
			.subscribe(
				() => {
					this.notificationService.showNotification(
						'Starting number successfully updated to ' + startingNumber
					);
					this.dialogRef.close(startingNumber);
					this.form.markAsPristine();
				},
				(error: DomainError[]) =>
					(this.updateError = !!error && error.length > 0 ? error[0].message : 'Unknown error')
			);
	}
}

export interface IBillNextNumberForm {
	startingNumber: number;
}
