import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, ValidatorFn } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { CostCodeExpenseType } from '@common/models/Settings/CostCodes/Common/CostCodeExpenseType';
import { CostCodeTimeType } from '@common/models/Settings/CostCodes/Common/CostCodeTimeType';
import { CostCodeDto } from '@common/models/Settings/CostCodes/Item/CostCodeDto';
import { DomainError } from '@common/models/Validation/DomainError';
import { NotificationService } from '@common/notification';
import { CostCodesService } from '@common/services/settings/costcodes.service';

import { CustomValidators } from '@common/validation/custom.validators';
import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';

@Component({
	selector: 'cost-codes-item',
	styleUrls: ['./cost-codes-item.component.scss'],
	templateUrl: './cost-codes-item.component.html'
})
export class CostCodesItemComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('costCodeInput', { static: true })
	costCodeInput: ElementRef;

	get isCreateMode(): boolean {
		return !this.data.id;
	}
	form: FormGroupTyped<CostCodeFormDto>;

	readonly chargeableKey: keyof typeof CostCodeTimeType = 'Chargeable';
	readonly nonChargeableKey: keyof typeof CostCodeTimeType = 'NonChargeable';
	readonly incGstKey: keyof typeof CostCodeExpenseType = 'ExpenseIncGst';
	readonly exGstKey: keyof typeof CostCodeExpenseType = 'ExpenseExGst';

	private data: CostCodeFormDto;
	private subscriptions = new Subscription();

	constructor(
		@Inject(MAT_DIALOG_DATA) data: CostCodeDto,
		private fb: FormBuilder,
		private costCodeService: CostCodesService,
		private dialogRef: MatDialogRef<CostCodesItemComponent>,
		private notificationService: NotificationService
	) {
		this.data = Object.assign(new CostCodeFormDto(), data);
	}

	ngOnInit(): void {
		this.form = this.fb.group(
			{
				id: this.data.id,
				isExpense: this.data.isExpense,
				isTimeCode: this.data.isTimeCode,
				name: [this.data.name, CustomValidators.required('Name')],
				timeType: { value: this.data.timeType, disabled: !this.data.timeType || this.data.timeType === 'None' },
				expenseType: {
					value: this.data.expenseType,
					disabled: !this.data.expenseType || this.data.expenseType === 'None'
				},
				isDisabled: this.data.isDisabled,
				chargeRate: this.data.chargeRate
			},
			{ validators: this.codeTypeSelected() }
		) as FormGroupTyped<CostCodeFormDto>;

		this.subscriptions.add(
			this.form.controls.isTimeCode.valueChanges.subscribe(value => {
				if (!value) {
					this.form.controls.timeType.disable();
					this.form.controls.timeType.setValue('None');
				} else {
					this.form.controls.timeType.enable();
					this.form.controls.timeType.setValue(this.chargeableKey);
				}
			})
		);

		this.subscriptions.add(
			this.form.controls.isExpense.valueChanges.subscribe(value => {
				if (value) {
					this.form.controls.expenseType.enable();
					this.form.controls.expenseType.setValue(this.incGstKey);
				} else {
					this.form.controls.expenseType.disable();
					this.form.controls.expenseType.setValue('None');
				}
			})
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	ngAfterViewInit(): void {
		if (this.costCodeInput) {
			setTimeout(() => this.costCodeInput.nativeElement.focus(), 0);
		}
	}

	delete(): void {
		this.subscriptions.add(
			this.notificationService
				.showConfirmation('Delete Cost Code', 'Are you sure you want to delete this Cost Code?')
				.pipe(
					filter(Boolean),
					switchMap(() => this.costCodeService.deleteCostCode(this.data.id))
				)
				.subscribe(
					(response: MutationResponseDto) => {
						this.notificationService.showNotification(`Cost Code '${this.data.name}' was deleted`);
						this.dialogRef.close({ isDelete: true, response: response });
					},
					errors => this.notificationService.showErrors('Error deleting custom field', errors)
				)
		);
	}

	save(): void {
		const saveObservable = !!this.data.id
			? this.costCodeService.updateCostCode(this.data.id, this.form.value)
			: this.costCodeService.createCostCode(this.form.value);
		this.subscriptions.add(
			saveObservable.subscribe(result => {
				this.notificationService.showNotification(
					`Cost Code ${result.name} ${!!this.data.id ? 'updated' : 'created'}`
				);
				this.dialogRef.close({ isDelete: false, response: result });
			}, this.onError)
		);
	}

	private codeTypeSelected(): ValidatorFn {
		return (formGroup: FormGroupTyped<CostCodeFormDto>) => {
			return formGroup.controls.isExpense.value || formGroup.controls.isTimeCode.value
				? null
				: { error: 'At least one control should be selected' };
		};
	}

	private onError = (errors: DomainError[]) => {
		this.notificationService.showErrors('Error', errors);
	};
}

// tslint:disable-next-line:max-classes-per-file
class CostCodeFormDto extends CostCodeDto {
	get isTimeCode(): boolean {
		return this.timeType && this.timeType !== 'None';
	}
	set isTimeCode(val: boolean) {
		if (!val) this.timeType = 'None';
		else if (this.timeType === 'None') this.timeType = 'Chargeable';
	}
	get isExpense(): boolean {
		return this.expenseType && this.expenseType !== 'None';
	}
	set isExpense(val: boolean) {
		if (!val) this.expenseType = 'None';
		else if (this.expenseType === 'None') this.expenseType = 'ExpenseIncGst';
	}
}
