import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { EMPTY, from, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { NotificationService } from '@common/notification';
import { lockedObservable } from '@common/utils/lockUtils';
import AwaitLock from 'await-lock';

import { ExternalPortalRouteService } from 'app/services/external-portal-route-service';
import { SystemSettingsResolverService } from 'app/services/settings-resolver.service';

import { AuthNotificationService } from './auth-notif.service';
import { AuthService } from './auth.service';

/**
 * @description
 * Add the authorisation headers here so they are injected on every HttpClient request
 * That way we don't need to add them everywhere they are used
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	private authRequestShown = false;

	private _lock = new AwaitLock();

	constructor(
		private authNotificationService: AuthNotificationService,
		private authService: AuthService,
		private settingResolverService: SystemSettingsResolverService,
		private notifService: NotificationService,
		private externalPortalRouteService: ExternalPortalRouteService
	) {}

	setAuthorization$(req: HttpRequest<any>): Observable<HttpRequest<any>> {
		return this.getAuthToken$().pipe(
			map(authToken => {
				return !!authToken
					? req.clone({
							setHeaders: {
								Authorization: authToken,
								'X-ClientId': this.authService.UserId
							},
							withCredentials: true
					  })
					: req;
			})
		);
	}

	getAuthToken$(): Observable<string> {
		return lockedObservable(this._lock, () => from(this.authService.SilentRefresh(false))).pipe(
			map(() => this.authService.HttpAuthorizationHeader)
		);
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// Skip this interceptor for external authed routes
		if (!!this.externalPortalRouteService.useExternalPortalAuth) return next.handle(req);

		// Only add auth headers when talking to the app server, auth server requests mustn't use withCredentials
		if (!req.url.startsWith(this.settingResolverService.getAppServerUrl())) {
			return next.handle(req);
		}

		return this.setAuthorization$(req).pipe(
			switchMap(updatedRequest => {
				return next.handle(updatedRequest).pipe(
					catchError(err => {
						// Catch the 401/403 status code to redirect to the login page
						if (err instanceof HttpErrorResponse && [401, 403].indexOf(err.status) > -1) {
							if (!this.authRequestShown) {
								this.authRequestShown = true;
								// Inform the user that the session is timed out & redirect to login.
								// Note: Don't use 'switchMap()' here and start a new flow via 'setTimeout()', as flipping HttpClient pipeline
								// to UI may cause nasty issues. The HttpClient pipeline need to finish as it's expected
								setTimeout(() =>
									this.notifService
										.showError(
											'Page refresh',
											'Your session needs to be refreshed. Please click OK and wait while the application reloads.'
										)
										.subscribe(() => {
											this.authService.Login();
											this.authRequestShown = false;
										})
								);
							}
							// Don't throw an error if the notification is already shown to the user
							return EMPTY;
						}

						return throwError(() => err);
					})
				);
			}),
			tap(response => {
				// Indicate a successful request using access_token on the primary WebAPI
				if (response instanceof HttpResponse) {
					if ([200, 204].indexOf(response.status) > -1) {
						setTimeout(() => this.authNotificationService.announceSuccessfulRequest());
					}
				}
			})
		);
	}
}
