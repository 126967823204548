import { Input, Directive, PipeTransform } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

import { get, isEmpty } from 'lodash';

/*
	Base component for element, which support two modes: 'read only' and 'editable control'
*/
@Directive()
export abstract class BaseEditableComponent<T> {
	protected _control: AbstractControl;
	@Input()
	placeHolder: string;
	@Input()
	displayPipe: PipeTransform;
	@Input()
	hintText: string;

	@Input()
	set control(value: AbstractControl) {
		this._control = value;
	}

	get control(): AbstractControl {
		return this._control;
	}

	get formControl(): FormControl {
		return this.control as FormControl;
	}

	private isEditMode: boolean;

	// Flag if the component in the 'Edit' mode
	@Input()
	get editMode(): boolean {
		return this.isEditMode;
	}
	set editMode(val: boolean) {
		this.isEditMode = val;
		this.editModeChanged(val);
	}

	get enabled(): boolean {
		return get(this.control, 'enabled', false);
	}

	get valid(): boolean {
		return this.control.valid;
	}

	get required(): boolean {
		return (
			this.control?.validator &&
			this.control?.validator({} as AbstractControl) &&
			this.control?.validator({} as AbstractControl).customRequired
		);
	}

	get blankMessage(): string {
		return this.placeHolder ? `no ${this.placeHolder.toLocaleLowerCase()} recorded` : 'not recorded';
	}

	childControl(...names: string[]): FormControl {
		return this.control.get(names.join('.')) as FormControl;
	}

	childArray(...names: string[]): FormArray {
		return this.control.get(names.join('.')) as FormArray;
	}

	value(...path: string[]): any {
		const fullPath = ['value'];

		if (!isEmpty(path)) {
			fullPath.push(...path);
		}

		return get(this.control, fullPath);
	}

	formatDisplay(value: any) {
		return !this.displayPipe ? value : this.displayPipe.transform(value);
	}

	protected editModeChanged(val: boolean): void {
		// A placehold for additional logic on switching the control's "Edit" mode
	}
}
