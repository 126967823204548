import { AfterContentInit, Component, Input, OnDestroy } from '@angular/core';

import { filter, interval, Subscription } from 'rxjs';

import { WorkTimerState } from 'app/shared/components/work-timer/WorkTimerState';
import { WorkTimerStateManager } from 'app/shared/components/work-timer/WorkTimerStateManager';
import { WorkTimerUtils } from 'app/shared/components/work-timer/WorkTimerUtils';
import moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'app/core/dialog.component';
import { DialogConfig, DialogType, EntityType } from 'app/core/dialog.config';

const timerInterval: number = 500;

@Component({
	selector: 'work-timer',
	styleUrls: ['./work-timer.component.scss'],
	templateUrl: 'work-timer.component.html'
})
export class WorkTimerComponent implements OnDestroy, AfterContentInit {
	private _timerId: string;
	private _allowAutostart: boolean = false;
	private _wasInitialized: boolean;

	get timerId(): string {
		return this._timerId;
	}

	@Input()
	set timerId(value: string) {
		this._timerId = value;

		if (!!this._wasInitialized && this._timerId != value) {
			this.initState();
		}
	}

	@Input()
	set allowAutostart(value: boolean) {
		this._allowAutostart = value;
	}

	private _useReverseLayout: boolean;

	@Input()
	set useReverseLayout(value: boolean) {
		this._useReverseLayout = value;
	}

	get useReverseLayout() {
		return this._useReverseLayout;
	}

	private _useWrap: boolean = true;

	@Input()
	set useWrap(value: boolean) {
		this._useWrap = value;
	}

	get useWrap() {
		return this._useWrap;
	}

	protected subscriptions: Subscription = new Subscription();

	// Cached state variable to reduce IO interactions
	protected state: WorkTimerState;

	// Cached number in milliseconds that has the total count
	// of the timer state
	protected currentDuration: number = null;

	get isRunning(): boolean {
		return !!this.state?.data?.isRunning;
	}

	private get concereteCurrentDuration(): number {
		return this.currentDuration ? this.currentDuration : 0;
	}

	get formattedDuration(): string {
		return WorkTimerUtils.formatDuration(this.concereteCurrentDuration);
	}

	constructor(private stateManager: WorkTimerStateManager, private dialog: MatDialog) {}
	ngAfterContentInit(): void {
		this.initState();

		// Registering polling subscription
		this.subscriptions.add(interval(timerInterval).subscribe(() => WorkTimerComponent.onPollInterval(this)));

		this._wasInitialized = true;
	}

	private initState(): void {
		// Setup state
		this.tryPullStoredState();
		this.state = WorkTimerUtils.ensureStateAllocated(this.state);
		WorkTimerUtils.updateVolatileData(this.state);

		if (!!this.state.data.canAutostart && !!this._allowAutostart) {
			this.start();
			this.tryStoreState();
		}

		// Update UI
		this.updateCurrentDuration();
	}

	ngOnDestroy(): void {
		// Teardown subscriptions
		this.subscriptions.unsubscribe();
	}

	// Start or resume timer if not already running
	start(isManual: boolean = true): void {
		if (this.isRunning) {
			return;
		}

		WorkTimerUtils.updateVolatileData(this.state);
		this.state = this.stateManager.startState(this.timerId, this.state, isManual);
		this.tryStoreState();

		this.updateCurrentDuration();
	}

	// Pause timer if running
	pause(isManual: boolean = false): void {
		if (!this.isRunning) {
			return;
		}

		this.state = this.stateManager.pauseState(this.timerId, this.state, isManual);
		this.tryStoreState();

		this.updateCurrentDuration();
	}

	// Reset timer to defaults
	reset(): void {
		this.state = this.stateManager.resetState(this.timerId, this.state, this._allowAutostart);
		this.tryStoreState();

		this.currentDuration = null;
	}

	// Stop timer and reset state
	stop(): void {
		this.stateManager.clearState(this.timerId);
		this.state = null;

		this.currentDuration = null;
	}

	createTimeRecord() {
		const state = this.stateManager.getState(this.timerId);
		const milliseconds = WorkTimerUtils.getCurrentDuration(state);

		this.subscriptions.add(
			this.dialog
				.open(DialogComponent, {
					data: new DialogConfig(
						DialogType.Create,
						EntityType.TimeRecord,
						null,
						{
							associatedMatterId: this.timerId.split('-').slice(0, 2).join('-'),
							durationMinutes: Math.ceil(milliseconds / 60000),
							timerId: this.timerId,
							timerDescription: state.data.description || ''
						},
						null,
						null,
						null
					)
				})
				.afterClosed()
				.pipe(filter(Boolean))
				.subscribe()
		);
	}

	onButtonTogglePlayPauseClick($event: { stopPropagation(): void }): void {
		if (this.isRunning) {
			this.pause(true);
		} else {
			this.start(true);
		}

		$event.stopPropagation();
	}

	onButtonStopClick($event: { stopPropagation(): void }): void {
		this.stop();

		$event.stopPropagation();
	}

	onButtonResetClick($event: { stopPropagation(): void }): void {
		this.reset();

		$event.stopPropagation();
	}

	getTooltip() {
		if (!this.state?.data?.setupDate) {
			return '';
		}
		return `Started on ${moment(this.state.data.setupDate || this.state.data.startDate).format('llll')}`;
	}

	onCreateTimeRecordClick($event: { stopPropagation(): void }) {
		this.createTimeRecord();

		$event.stopPropagation();
	}

	private updateCurrentDuration(): void {
		this.currentDuration = WorkTimerUtils.getCurrentDuration(this.state);
	}

	private tryPullStoredState(): void {
		this.state = this.stateManager.getState(this.timerId);
	}

	private tryStoreState(): void {
		this.stateManager.setState(this.timerId, this.state);
	}

	private static onPollInterval(timer: WorkTimerComponent): void {
		if (!timer || !timer.state) {
			return;
		}

		if (!!timer._allowAutostart || !timer.state.data.canAutostart) {
			// Update state
			timer.state = timer.stateManager.pollState(timer.timerId);
		} else {
			timer.tryPullStoredState();
			timer.state = WorkTimerUtils.ensureStateAllocated(timer.state);
		}

		// Update UI
		timer.updateCurrentDuration();
	}
}
