import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';

@Injectable({
	providedIn: 'root'
})
export class MarketplaceIconService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'marketplace/icons');
	}

	getPackage(productId: string): Observable<HttpResponse<Blob>> {
		return this.getBlob<void>(`package/${productId}`);
	}

	getModule(entityId: string): Observable<HttpResponse<Blob>> {
		return this.getBlob<void>(`module/${entityId}`);
	}

	getService(entityId: string): Observable<HttpResponse<Blob>> {
		return this.getBlob<void>(`service/${entityId}`);
	}
}
