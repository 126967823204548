import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { Features } from '@common/models/Settings/Modules/Features';
import { TenantFeatureStateDto } from '@common/models/Settings/Setting/Item/TenantFeatureStateDto';
import { ITenantData } from '@common/state/models/tenant-data';
import { Store } from '@ngrx/store';

@Directive({
	selector: '[tenantFeatureNot]'
})
export class TenantFeatureNotDirective implements OnInit, OnDestroy {
	@Input('tenantFeatureNot')
	tenantFeature: keyof typeof Features;

	private _subscriptions = new Subscription();

	constructor(
		private _store: Store<{ tenantData: ITenantData }>,
		private _templateRef: TemplateRef<any>,
		private _viewContainer: ViewContainerRef
	) {}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}

	ngOnInit(): void {
		if (!!this.tenantFeature) {
			this._viewContainer.clear();

			this._store
				.select(state => state?.tenantData?.tenantInformation?.featureStates)
				.pipe(
					filter(Boolean),
					distinctUntilChanged(),
					map(
						(states: TenantFeatureStateDto[]) =>
							!states
								.filter(states => !!states.isEnabled)
								.map(states => states.type)
								.includes(this.tenantFeature)
					)
				)
				.subscribe(flag => {
					this._viewContainer.clear();

					if (!!flag) {
						this._viewContainer.createEmbeddedView(this._templateRef);
					}
				});
		}
	}
}
