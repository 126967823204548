import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { MatterCustomFieldListRequest } from '@common/models/Settings/CustomFields/List/MatterCustomFieldListRequest';

@Component({
	selector: 'matter-custom-field-list-filter-dialog',
	styleUrls: ['./matter-custom-field-list-filter-dialog.component.scss'],
	templateUrl: './matter-custom-field-list-filter-dialog.component.html'
})
export class MatterCustomFieldListFilterDialogComponent {
	currentFilter: Partial<MatterCustomFieldListRequest>;

	constructor(
		@Inject(MAT_DIALOG_DATA) public request: Partial<MatterCustomFieldListRequest>,
		private dialogRef: MatDialogRef<MatterCustomFieldListFilterDialogComponent>
	) {}

	apply() {
		this.dialogRef.close(this.currentFilter);
	}

	clear() {
		this.dialogRef.close({});
	}

	rootFilterChange($event: Partial<MatterCustomFieldListRequest>) {
		this.currentFilter = $event;
	}
}
