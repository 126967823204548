<h2 mat-dialog-title>
	<span>Add Existing Documents to Brief</span>
</h2>
<mat-dialog-content>
	<document-list-filter [config]="config" #theFilter></document-list-filter>
	<div>
		<div>
			<table mat-table [dataSource]="dataSource" matSort matSortActive="createdDate" matSortDirection="desc">
				<ng-container matColumnDef="createdDate">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Created Date</th>
					<td mat-cell *matCellDef="let row">
						{{ row.createdDate | moment : 'dd/MM/yyyy' }}
					</td>
				</ng-container>

				<ng-container matColumnDef="title">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
					<td mat-cell *matCellDef="let row">
						<div class="flex-block">
							<mat-icon class="add-margin" [svgIcon]="row.icon" [ngClass]="row.class"></mat-icon>
							<a> {{ row.title }} </a>
						</div>
					</td>
				</ng-container>

				<ng-container matColumnDef="documentTags">
					<th mat-header-cell *matHeaderCellDef>Tags</th>

					<td mat-cell *matCellDef="let row">
						<mat-chip *ngFor="let tag of row.documentTags">
							{{ tag }}
						</mat-chip>
					</td>
				</ng-container>

				<ng-container matColumnDef="lastModifiedBy">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>User</th>
					<td mat-cell *matCellDef="let row">
						<a [routerLink]="['/contacts/' + row.lastModifiedBy?.id]">{{ row.lastModifiedBy?.name }}</a>
					</td>
				</ng-container>

				<ng-container matColumnDef="section">
					<th mat-header-cell *matHeaderCellDef>Section</th>

					<td mat-cell *matCellDef="let row">
						<mat-form-field>
							<mat-placeholder> Section </mat-placeholder>
							<mat-select (selectionChange)="selectionChanged(row.id, $event)">
								<mat-option *ngFor="let section of sections" [value]="section.id">{{ section.name }}</mat-option>
							</mat-select>
						</mat-form-field>
					</td>
				</ng-container>

				<ng-container matColumnDef="action">
					<th mat-header-cell *matHeaderCellDef>Action</th>

					<td mat-cell *matCellDef="let row">
						<button mat-raised-button throttleButton (throttledClick)="addDocumentToSection(row)" color="primary">
							<mat-icon>add</mat-icon>Add
						</button>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
			</table>
		</div>
	</div>
	<div *ngIf="!isRequestInProgress && dataSource.data.length === 0"><h3>No documents found</h3></div>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-raised-button mat-dialog-close>Close</button>
</mat-dialog-actions>
