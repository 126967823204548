<mat-table [dataSource]="dataSource">
	<ng-container matColumnDef="title">
		<mat-header-cell *matHeaderCellDef>Title</mat-header-cell>
		<mat-cell throttleButton (throttledClick)="edit(row)" *matCellDef="let row">
			<span class="title">{{ row.title }}</span>
		</mat-cell>
	</ng-container>

	<ng-container matColumnDef="templateTitle">
		<mat-header-cell *matHeaderCellDef>Template</mat-header-cell>
		<mat-cell *matCellDef="let row">
			<span class="templateTitle">{{ row.templateTitle }}</span>
		</mat-cell>
	</ng-container>

	<ng-container matColumnDef="actions">
		<mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
		<mat-cell *matCellDef="let row">
			<button mat-icon-button [matMenuTriggerFor]="menu" title="Actions">
				<mat-icon>more_vert</mat-icon>
			</button>
			<mat-menu #menu="matMenu">
				<button mat-menu-item throttleButton (throttledClick)="edit(row)">
					<mat-icon>edit</mat-icon>
					<span>Edit Details</span>
				</button>
				<button mat-menu-item throttleButton (throttledClick)="remove(row)">
					<mat-icon>delete</mat-icon>
					<span>Remove</span>
				</button>
			</mat-menu>
		</mat-cell>
	</ng-container>

	<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
	<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>
