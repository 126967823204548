// This file has been generated from TimeRecordsController.cs
// Do not modify this file directly!!
// tslint:disable

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { TrimStringsOnObject } from '@common/utils/string-format';

import { MutationResponseDto } from '../models/Common/MutationResponseDto';
import { ChargeRateRequestDto } from '../models/CostRecords/Item/ChargeRateRequestDto';
import { MatterStaffRateResult } from '../models/CostRecords/Item/MatterStaffRateResult';
import { TimeRecordChartRequest } from '../models/CostRecords/Item/TimeRecordChartRequest';
import { TimeRecordCreateUpdateDto } from '../models/CostRecords/Item/TimeRecordCreateUpdateDto';
import { TimeRecordViewForUpdateDto } from '../models/CostRecords/Item/TimeRecordViewForUpdateDto';
import { TimeRecordDashboardDto } from '../models/CostRecords/List/TimeRecordDashboardDto';

@Injectable({
	providedIn: 'root'
})
export class TimeRecordsService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'api/v1/TimeRecords', 'Time Record');
	}

	createTimeRecord(dto: TimeRecordCreateUpdateDto): Observable<MutationResponseDto> {
		return this.post<TimeRecordCreateUpdateDto, MutationResponseDto>('', TransformDatesOnObject(TimeRecordCreateUpdateDto, TrimStringsOnObject(dto)));
	}

	updateTimeRecord(id: string, dto: TimeRecordCreateUpdateDto): Observable<MutationResponseDto> {
		return this.put(`${id}`, TransformDatesOnObject(TimeRecordCreateUpdateDto, TrimStringsOnObject(dto)));
	}

	deleteTimeRecord(id: string): Observable<MutationResponseDto> {
		return this.delete(`${id}`);
	}

	getChartRecords(timeRecordChartRequest: TimeRecordChartRequest): Observable<TimeRecordDashboardDto> {
		return this.getItem<TimeRecordChartRequest, TimeRecordDashboardDto>(`mychartrecords`, timeRecordChartRequest);
	}

	getStaffRateForTimeRecord(dto: ChargeRateRequestDto): Observable<MatterStaffRateResult> {
		return this.getItem<ChargeRateRequestDto, MatterStaffRateResult>(`GetStaffRateForTimeRecord`, dto);
	}

	getTimeRecord(id: string): Observable<TimeRecordViewForUpdateDto> {
		return this.getItem<void, TimeRecordViewForUpdateDto>(`${id}`);
	}

}
