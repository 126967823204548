import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class SpinnerService {
	private _spinChanged = new Subject<boolean>();

	onSpinChanged() {
		return this._spinChanged.asObservable();
	}

	setSpin(forceSpinner: boolean) {
		this._spinChanged.next(!!forceSpinner);
	}
}
