// This file has been generated from DocumentBriefTemplateDto.cs
// Do not modify this file directly!!
// tslint:disable

import { BriefTemplateSection } from './BriefTemplateSection';

// LawMaster.Amalthea.Domain.DocumentBriefTemplates.Item.DocumentBriefTemplateDto
export class DocumentBriefTemplateDto {

	// Name
	name: string;
	// Sections
	sections: BriefTemplateSection[];
	// AssociatedPracticeAreaIds
	associatedPracticeAreaIds: string[];

	constructor() {
		this.sections = [];
	}
}