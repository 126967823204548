import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { Subscription } from 'rxjs';

import { AppcuesEvent, AppcuesService } from '@common/appcues/appcues.service';
import { UnitsPerHourCreateUpdateDto } from '@common/models/Settings/Setting/Item/UnitsPerHourCreateUpdateDto';
import { NotificationService } from '@common/notification';
import { GeneralSettingsService } from '@common/services/settings/generalsettings.service';

import { CustomValidators } from '@common/validation/custom.validators';

@Component({
	selector: 'units-field',
	styleUrls: ['./units-field.component.scss'],
	templateUrl: './units-field.component.html'
})
export class UnitsFieldComponent implements OnInit, OnDestroy {
	form: FormGroup;
	isEditMode: boolean;
	unitsList: number[] = [60, 30, 20, 15, 12, 10, 6, 5, 4, 3, 2, 1];
	selectedUnit: number;
	private subscriptions = new Subscription();

	constructor(
		private fb: FormBuilder,
		private notificationService: NotificationService,
		private settingsService: GeneralSettingsService,
		private appcuesService: AppcuesService
	) {}

	ngOnInit() {
		this.form = this.fb.group({
			unitsPerHour: [null, CustomValidators.required('Units')]
		});

		this.subscriptions.add(
			this.settingsService.getUnits().subscribe(unitsPerHour => {
				this.form.patchValue({
					unitsPerHour
				});
				this.selectedUnit = unitsPerHour;
			})
		);
	}

	getUnitDurationText(): string {
		const unitsPerHour = this.form.get('unitsPerHour').value;
		if (unitsPerHour) {
			const numberOfUnitsInAnHour = 60 / unitsPerHour;
			return numberOfUnitsInAnHour.toString() + ' min' + (numberOfUnitsInAnHour > 1 ? 's' : '');
		}
		return null;
	}

	getNumberOfUnitsText(): string {
		const unitSize = this.form.get('unitsPerHour').value;
		if (unitSize) {
			return unitSize.toString() + ' unit' + (unitSize > 1 ? 's' : '');
		}
		return null;
	}

	saveChanges() {
		const unitsPerHour = this.form.get('unitsPerHour').value;

		const settings: UnitsPerHourCreateUpdateDto = {
			unitsPerHour
		};

		this.settingsService.saveUnits(settings).subscribe(
			() => {
				this.notificationService.showNotification('Units saved.');
				this.selectedUnit = unitsPerHour;
				this.isEditMode = false;
			},
			errors => this.notificationService.showErrors('Error saving units', errors)
		);
		this.appcuesService.trackEvent('UnitsConfigured');
	}

	cancelEditing() {
		this.isEditMode = false;
		this.form.patchValue({
			unitsPerHour: this.selectedUnit
		});
	}

	startEditing() {
		this.isEditMode = true;
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}
}
