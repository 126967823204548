<div class="flex-column" [formGroup]="form">
	<div class="flex-row">
		<mat-form-field>
			<mat-placeholder>Title <sup class="color-warn">*</sup> </mat-placeholder>
			<input matInput formControlName="title" (keypress)="stopUpdatingTitle()" />
			<mat-error> <error-messages [for]="form.get('title')"></error-messages> </mat-error>
		</mat-form-field>
	</div>
	<div class="flex-row" *ngIf="isTextNote">
		<speech-to-textarea [control]="form.get('details')" placeHolder="Details" required="true"></speech-to-textarea>
	</div>
	<div class="flex-row" *ngIf="!isTextNote">
		<mat-form-field>
			<mat-select placeholder="Category" formControlName="documentCategoryId">
				<mat-option></mat-option>
				<mat-option *ngFor="let category of documentCategories" [value]="category.id">{{ category.name }}</mat-option>
			</mat-select>
		</mat-form-field>
	</div>

	<div class="flex-row">
		<div class="related-to">
			<mat-form-field class="related-to-type">
				<mat-select [(value)]="associatedEntityType" placeholder="Related To" (selectionChange)="associatedEntityTypeChange()">
					<mat-option *ngFor="let type of associatedEntityTypes" [value]="type">{{ type }}</mat-option>
				</mat-select>
			</mat-form-field>
			<matter-lookup
				class="related-to-entity"
				*ngIf="associatedEntityType == 'Matter'"
				[FormControl]="form.get('associatedMatterId')"
				Hint="Enter a matter number or title"
				Status="Open"
				[Required]="true"
			></matter-lookup>
			<contact-lookup
				class="related-to-entity"
				*ngIf="associatedEntityType == 'Contact'"
				[FormControl]="form.get('associatedContactId')"
				(Selected)="onContactSelected($event)"
				Hint="Enter a contact name"
				[Required]="true"
			></contact-lookup>
		</div>
		<mat-form-field class="small-form-field">
			<mat-placeholder>Created Date <sup class="color-warn">*</sup> </mat-placeholder>
			<input formControlName="date" matInput [matDatepicker]="datePicker" />
			<mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
			<mat-datepicker #datePicker></mat-datepicker>
			<mat-error> <error-messages [for]="form.get('date')"></error-messages> </mat-error>
		</mat-form-field>
	</div>
	<document-tag
		*ngIf="form.get('documentTags')"
		[matterId]="this.form?.controls?.associatedMatterId?.value"
		[FormControl]="form.get('documentTags')"
	></document-tag>
</div>
