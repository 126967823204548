import { Component, Inject, OnDestroy, OnInit, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContactType } from '@common/models/Contacts/Common/ContactType';
import { normalizeName } from '@common/utils/stringUtils';
import { CustomValidators } from '@common/validation/custom.validators';
import { Subscription } from 'rxjs';
import { ContactLookupComponent } from './contact-lookup.component';

@Component({
	selector: 'create-contact-lookup-dialog',
	styleUrls: ['./create-contact-lookup.component.scss'],
	templateUrl: './create-contact-lookup.component.html'
})
export class CreateContactLookupDialogComponent implements OnInit, OnDestroy {
	@ViewChildren('member')
	memberComponents: ContactLookupComponent[];

	contactForm: FormGroup;
	isFormValid: boolean;
	private _type: keyof typeof ContactType;

	set type(value: keyof typeof ContactType) {
		this._type = value;

		if (this.isPlural) {
			const member1 = this.fb.control(null);
			const member2 = this.fb.control(null);

			this.subscriptions.add(member1.valueChanges.subscribe(() => this.membersChange(member1)));
			this.subscriptions.add(member2.valueChanges.subscribe(() => this.membersChange(member2)));

			this.contactForm.addControl(
				'memberIds',
				this.fb.array(
					[member1, member2],
					[CustomValidators.uniqueArrayField(), CustomValidators.minLengthArray(2)]
				)
			);
		} else {
			if (!!this.contactForm.contains('memberIds')) {
				this.contactForm.removeControl('memberIds');
			}
		}
	}

	get type(): keyof typeof ContactType {
		return this._type;
	}
	private subscriptions = new Subscription();

	private isManual: boolean;
	private ignoreManual: boolean;

	constructor(
		public dialogRef: MatDialogRef<CreateContactLookupDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ICreateContactLookupDialogData,
		private fb: FormBuilder
	) {}

	onNoClick(): void {
		this.dialogRef.close();
	}

	ngOnInit() {
		let firstName: string = null;
		let lastName: string = null;

		if (!!this.data.name) {
			var normalizedName = normalizeName(this.data.name);
			var parts = normalizedName.split(' ');

			if (parts.length == 1) {
				firstName = parts[0];
			} else {
				lastName = parts[parts.length - 1];
				firstName = normalizedName.substr(0, normalizedName.length - lastName.length - 1);
			}
		}

		this.contactForm = this.fb.group({
			firstName: [firstName, CustomValidators.requiredWhen(() => this.isPerson, 'First Name')],
			middleName: null,
			fullName: [
				this.data.name ? this.data.name : null,
				CustomValidators.requiredWhen(() => this.isCompany, 'Full Name')
			],
			lastName: [lastName, CustomValidators.requiredWhen(() => this.isPerson, 'Last Name')],
			type: [null, CustomValidators.required],
			customFields: this.fb.group({}),
			createPersons: null,
			createCompanies: null
		});

		this.subscriptions.add(
			this.contactForm.get('fullName').valueChanges.subscribe(value => {
				if (!this.ignoreManual) {
					this.isManual = !!value;
				} else {
					this.ignoreManual = false;
				}
			})
		);
	}
	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	getControlGroup(name: string) {
		return this.contactForm?.get(name) as FormGroup;
	}

	supportsType(type: keyof typeof ContactType) {
		return !!this.data?.supportedTypes?.includes(type);
	}

	get isPerson(): boolean {
		return this.type === ContactType.Person;
	}

	get isCompany(): boolean {
		return this.type === ContactType.Company;
	}

	get isPlural(): boolean {
		return this.type === ContactType.Plural;
	}

	get memberArray() {
		return this.contactForm.get('memberIds') as FormArray;
	}

	get memberControls() {
		return this.memberArray.controls;
	}

	addMember() {
		const control = this.fb.control(null, CustomValidators.required('Contact'));

		this.subscriptions.add(control.valueChanges.subscribe(() => this.membersChange(control)));

		this.memberArray.push(control);
	}

	removeMember(index: number) {
		this.memberArray.removeAt(index);
		setTimeout(() => this.membersChange(), 0);
	}

	private membersChange(control?: AbstractControl) {
		if (!this.isManual) {
			const contacts = this.memberComponents
				.map(component =>
					component.selectedValue?.type === 'Person'
						? normalizeName(component.selectedValue.fullName)
						: component.selectedValue?.fullName
				)
				.filter((name, index) => !!name?.length && !!this.memberControls[index].value);

			let name = '';

			if (contacts.length == 1) {
				name = contacts[0];
			} else if (contacts.length > 1) {
				name = contacts.reduce((left, right) => `${left} & ${right}`);
			}

			this.ignoreManual = true;
			this.contactForm.get('fullName').setValue(name);
		}

		if (!!control?.dirty) {
			this.memberArray.markAsTouched();
		}
	}
}

export interface ICreateContactLookupDialogData {
	name: string;
	supportedTypes: (keyof typeof ContactType)[];
}
