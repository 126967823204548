import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedDirectivesModule } from '@common/directives/shared-directives.module';
import { SharedPipesModule } from '@common/pipes/shared-pipes.module';

import { PipesModule } from '../pipes/pipes.module';
import { UserAvatarComponent } from './user-avatar.component';

@NgModule({
	declarations: [UserAvatarComponent],
	exports: [UserAvatarComponent],
	imports: [CommonModule, SharedDirectivesModule, PipesModule, SharedPipesModule]
})
export class UserAvatarModule {}
