import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { filter, switchMap } from 'rxjs/operators';

import { BasicWorkflowListItemDto } from '@common/models/Settings/BasicWorkflows/List/BasicWorkflowListItemDto';
import { NotificationService } from '@common/notification';
import { BasicWorkflowService } from '@common/services/settings/basicworkflow.service';
import { Store } from '@ngrx/store';

import { IAppState } from 'app/core/state/app.state';
import { BasicWorkflowListActions } from 'app/core/state/lists/settings/matter-workflow-list/basic-workflow-list.actions';
import {
	selectBasicWorkflowIsFetching,
	selectBasicWorkflowListRecords,
	selectBasicWorkflowListRequest,
	selectBasicWorkflowListTotalRecords
} from 'app/core/state/lists/settings/matter-workflow-list/basic-workflow-list.selectors';
import { GridViewService } from 'app/services/grid-view.service';
import {
	ActionTypes,
	GenericListStateComponent,
	SelectorTypes
} from 'app/shared/generics/generic.list.state.component';

import { BasicWorkflowCreateDialogComponent } from '../create/basic-workflow-create-dialog.component';

@Component({
	selector: 'basic-workflows-list',
	templateUrl: 'basic-workflows-list.component.html',
	styleUrls: ['basic-workflows-list.component.scss']
})
export class BasicWorkflowsListComponent
	extends GenericListStateComponent<BasicWorkflowListItemDto, void>
	implements OnInit
{
	get actions(): ActionTypes {
		return {
			init: BasicWorkflowListActions.Init,
			load: BasicWorkflowListActions.Load,
			setFilters: BasicWorkflowListActions.SetFilters,
			setPageIndex: BasicWorkflowListActions.SetPageIndex,
			setPageIndexForId: BasicWorkflowListActions.SetPageIndexForId,
			setPageSize: BasicWorkflowListActions.SetPageSize,
			setSortBy: BasicWorkflowListActions.SetSortBy,
			setSortDirection: BasicWorkflowListActions.SetSortDirection,
			selected: BasicWorkflowListActions.SelectRecords
		};
	}
	get selectors(): SelectorTypes {
		return {
			records: selectBasicWorkflowListRecords,
			isFetching: selectBasicWorkflowIsFetching,
			request: selectBasicWorkflowListRequest,
			totalRecords: selectBasicWorkflowListTotalRecords
		};
	}
	constructor(
		store: Store<IAppState>,
		dialog: MatDialog,
		router: Router,
		activatedRoute: ActivatedRoute,
		basicWorkflowService: BasicWorkflowService,
		private notifService: NotificationService,
		gridViewService: GridViewService
	) {
		super(
			['name', 'practiceAreaNames', 'type', 'numTasks', 'action'],
			dialog,
			store,
			router,
			activatedRoute,
			gridViewService
		);
		this.basicWorkflowService = basicWorkflowService;
	}
	ngOnInit(): void {
		super.ngOnInit();
	}

	private basicWorkflowService: BasicWorkflowService;

	create() {
		this.subscriptions.add(
			this.dialog
				.open(BasicWorkflowCreateDialogComponent, {
					maxWidth: '900px',
					width: '100%',
					maxHeight: '95vh'
				})
				.afterClosed()
				.pipe(filter(Boolean))
				.subscribe((id: string) => {
					this.open(id);
				})
		);
	}

	open(id: string) {
		if (!!id) {
			this.router.navigate([`/system/matter-workflows/${id}`]);
		}
	}

	delete(row: BasicWorkflowListItemDto) {
		this.subscriptions.add(
			this.notifService
				.showConfirmation(
					'Delete Matter Workflow',
					`Are you sure you want to delete the Matter Workflow "${row.name}"?`
				)
				.pipe(
					filter(Boolean),
					switchMap(() => this.basicWorkflowService.deleteBasicWorkflow(row.id))
				)
				.subscribe({
					next: response => {
						this.notifService.showNotification(`${row.name} has been deleted`);
						this.store.dispatch({ type: BasicWorkflowListActions.RemoveRecords, response: response });
					},
					error: errors => {
						this.notifService.showErrors(`Error deleting ${row.name}`, errors);
					}
				})
		);
	}
}
