// This file has been generated from MattersController.cs
// Do not modify this file directly!!
// tslint:disable

import { HttpClient, HttpResponse } from '@angular/common/http';
import { IDownloadFile } from '../models/Files/IFileDownloader';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { TrimStringsOnObject } from '@common/utils/string-format';

import { EntityReference } from '../models/Common/EntityReference';
import { MutationResponseDto } from '../models/Common/MutationResponseDto';
import { DocumentReference } from '../models/Documents/PersistenceLayer/DocumentReference';
import { ListResponse } from '../models/Generic/ListResponse';
import { MatterContactRequest } from '../models/Matters/Common/MatterContactRequest';
import { MatterStaffMemberDto } from '../models/Matters/Common/MatterStaffMemberDto';
import { MatterDashboardDto } from '../models/Matters/Dashboard/MatterDashboardDto';
import { ChangeMatterPracticeAreaDto } from '../models/Matters/Item/ChangeMatterPracticeAreaDto';
import { ChangeMatterStageDto } from '../models/Matters/Item/ChangeMatterStageDto';
import { MatterCreateDto } from '../models/Matters/Item/MatterCreateDto';
import { MatterDeleteInfoDto } from '../models/Matters/Item/MatterDeleteInfoDto';
import { MatterDeleteResponseDto } from '../models/Matters/Item/MatterDeleteResponseDto';
import { MatterLawyerUpdateDto } from '../models/Matters/Item/MatterLawyerUpdateDto';
import { MatterSecurityUpdateDto } from '../models/Matters/Item/MatterSecurityUpdateDto';
import { MatterSecurityViewDto } from '../models/Matters/Item/MatterSecurityViewDto';
import { MatterStaffMemberCreateUpdateDto } from '../models/Matters/Item/MatterStaffMemberCreateUpdateDto';
import { MatterSummaryViewDto } from '../models/Matters/Item/MatterSummaryViewDto';
import { MatterTrustBalanceReportRequestDto } from '../models/Matters/Item/MatterTrustBalanceReportRequestDto';
import { MatterTrustBalanceReportsRequestDto } from '../models/Matters/Item/MatterTrustBalanceReportsRequestDto';
import { MatterTrustBalanceReportsSummaryDto } from '../models/Matters/Item/MatterTrustBalanceReportsSummaryDto';
import { MatterUpdateDto } from '../models/Matters/Item/MatterUpdateDto';
import { MatterViewDto } from '../models/Matters/Item/MatterViewDto';
import { MatterContactListItemDto } from '../models/Matters/List/MatterContactListItemDto';
import { MatterIdsCsvExportRequest } from '../models/Matters/List/MatterIdsCsvExportRequest';
import { MatterListCsvExportRequest } from '../models/Matters/List/MatterListCsvExportRequest';
import { MatterListItemDto } from '../models/Matters/List/MatterListItemDto';
import { MatterListRequest } from '../models/Matters/List/MatterListRequest';
import { MatterLookupDto } from '../models/Matters/Lookup/MatterLookupDto';
import { MatterLookupRequest } from '../models/Matters/Lookup/MatterLookupRequest';
import { MatterFinancialStatementRequestDto } from '../models/Reports/Dto/MatterFinancialStatementRequestDto';
import { DocumentRequest } from '../models/RequestParameters/DocumentRequest';
import { MatterNumberRequestDto } from '../models/RequestParameters/MatterNumberRequestDto';
import { TrustBalanceDto } from '../models/Trust/List/TrustBalanceDto';

@Injectable({
	providedIn: 'root'
})
export class MattersService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'api/v1/Matters', 'Matter');
	}

	setNextMatterNumber(nextMatterNumber: number): Observable<any> {
		return this.put(`SetNextMatterNumber/${nextMatterNumber}`);
	}

	getNextMatterNumber(dto: MatterNumberRequestDto): Observable<number> {
		return this.getItem<MatterNumberRequestDto, number>(`GetNextMatterNumber`, dto);
	}

	getMatterMailReference(id: string): Observable<string> {
		return this.getItem<void, string>(`GetMatterMailReference/${id}`);
	}

	createMatter(dto: MatterCreateDto): Observable<MutationResponseDto> {
		return this.post<MatterCreateDto, MutationResponseDto>('', TransformDatesOnObject(MatterCreateDto, TrimStringsOnObject(dto)));
	}

	deleteMatter(id: string): Observable<MatterDeleteResponseDto> {
		return this.delete(`${id}`);
	}

	getDeleteInfo(id: string): Observable<MatterDeleteInfoDto> {
		return this.getItem<void, MatterDeleteInfoDto>(`GetDeleteInfo/${id}`);
	}

	exportMattersCsvByFilter(request: MatterListCsvExportRequest): Observable<HttpResponse<Blob>> {
		return this.getBlob<MatterListCsvExportRequest>(`exportCsvByFilter`, request);
	}

	exportMattersCsvByIds(request: MatterIdsCsvExportRequest): Observable<HttpResponse<Blob>> {
		return this.getBlob<MatterIdsCsvExportRequest>(`exportCsvByIds`, request);
	}

	getMatterSummary(id: string): Observable<MatterSummaryViewDto> {
		return this.getItem<void, MatterSummaryViewDto>(`${id}/summary`);
	}

	getMatter(id: string): Observable<MatterViewDto> {
		return this.getItem<void, MatterViewDto>(`${id}`);
	}

	getRandomMatterReference(): Observable<EntityReference> {
		return this.getItem<void, EntityReference>(`random`);
	}

	getMatterList(request?: Partial<MatterListRequest>): Observable<ListResponse<MatterListItemDto>> {
		return this.getList<MatterListRequest, MatterListItemDto>('', request);
	}

	getMatterListPost(request?: Partial<MatterListRequest>): Observable<ListResponse<MatterListItemDto>> {
		return this.post<Partial<MatterListRequest>, ListResponse<MatterListItemDto>>(`GetMatterListPost`, request);
	}

	getMatterContactList(id: string): Observable<MatterContactListItemDto[]> {
		return this.getArray<void, MatterContactListItemDto>(`${id}/contacts`);
	}

	getMatterStaffMembersList(matterId: string): Observable<MatterStaffMemberDto[]> {
		return this.getArray<void, MatterStaffMemberDto>(`${matterId}/staff`);
	}

	getLastActive(count: number): Observable<MatterListItemDto[]> {
		return this.getArray<void, MatterListItemDto>(`recent/${count}`);
	}

	lookupMatterList(request: MatterLookupRequest): Observable<MatterLookupDto[]> {
		return this.getArray<MatterLookupRequest, MatterLookupDto>(`lookup`, request);
	}

	lookupMatter(id: string): Observable<MatterLookupDto> {
		return this.getItem<void, MatterLookupDto>(`${id}/lookup`);
	}

	dashboardTile(id: string): Observable<MatterDashboardDto> {
		return this.getItem<void, MatterDashboardDto>(`${id}/dashboard`);
	}

	getMatterTrustBalance(matterId: string): Observable<TrustBalanceDto> {
		return this.getItem<void, TrustBalanceDto>(`GetMatterTrustBalance/${matterId}`);
	}

	generateTrustMatterBalanceStatement(request: MatterTrustBalanceReportRequestDto): Observable<DocumentReference> {
		return this.post<MatterTrustBalanceReportRequestDto, DocumentReference>(`GenerateTrustMatterBalanceStatement`, TransformDatesOnObject(MatterTrustBalanceReportRequestDto, TrimStringsOnObject(request)));
	}

	generateTrustMatterBalanceStatementsForOpenMatters(request: MatterTrustBalanceReportsRequestDto): Observable<MatterTrustBalanceReportsSummaryDto> {
		return this.post<MatterTrustBalanceReportsRequestDto, MatterTrustBalanceReportsSummaryDto>(`GenerateTrustMatterBalanceStatementsForOpenMatters`, TransformDatesOnObject(MatterTrustBalanceReportsRequestDto, TrimStringsOnObject(request)));
	}

	generateMatterFinancialStatement(request: MatterFinancialStatementRequestDto): Observable<DocumentReference> {
		return this.post<MatterFinancialStatementRequestDto, DocumentReference>(`GenerateMatterFinancialStatement`, TransformDatesOnObject(MatterFinancialStatementRequestDto, TrimStringsOnObject(request)));
	}

	getMatterSecurityDetails(id: string): Observable<MatterSecurityViewDto> {
		return this.getItem<void, MatterSecurityViewDto>(`${id}/security`);
	}

	updateMatter(id: string, dto: MatterUpdateDto): Observable<any> {
		return this.put(`${id}`, TransformDatesOnObject(MatterUpdateDto, TrimStringsOnObject(dto)));
	}

	updateMatterContact(id: string, matterCustomField: MatterContactRequest): Observable<any> {
		return this.post<MatterContactRequest, any>(`${id}`, matterCustomField);
	}

	updateMatterStaffMember(matterId: string, dto: MatterStaffMemberCreateUpdateDto): Observable<MutationResponseDto> {
		return this.put(`${matterId}/staff`, TransformDatesOnObject(MatterStaffMemberCreateUpdateDto, TrimStringsOnObject(dto)));
	}

	promoteMatterStaffMember(matterId: string, staffContactId: string): Observable<MutationResponseDto> {
		return this.put(`${matterId}/staff/${staffContactId}`);
	}

	removeMatterStaffMember(matterId: string, staffContactId: string): Observable<MutationResponseDto> {
		return this.delete(`${matterId}/staff/${staffContactId}`);
	}

	updateLawyer(matterLawyerUpdateDto: MatterLawyerUpdateDto): Observable<any> {
		return this.put(`updateLawyer`, TransformDatesOnObject(MatterLawyerUpdateDto, TrimStringsOnObject(matterLawyerUpdateDto)));
	}

	patchCustomFields(id: string, matterCustomFieldsPatch: Partial<MatterCreateDto>): Observable<any> {
		return this.patch<MatterCreateDto, any>(`patchCustomFields/${id}`, TransformDatesOnObject(MatterCreateDto, TrimStringsOnObject(matterCustomFieldsPatch)));
	}

	changeMatterPracticeArea(id: string, dto: ChangeMatterPracticeAreaDto): Observable<MutationResponseDto> {
		return this.post<ChangeMatterPracticeAreaDto, MutationResponseDto>(`${id}/changeMatterPracticeArea`, TransformDatesOnObject(ChangeMatterPracticeAreaDto, TrimStringsOnObject(dto)));
	}

	changeMatterStage(id: string, dto: ChangeMatterStageDto): Observable<MutationResponseDto> {
		return this.post<ChangeMatterStageDto, MutationResponseDto>(`${id}/changeMatterStage`, TransformDatesOnObject(ChangeMatterStageDto, TrimStringsOnObject(dto)));
	}

	updateMatterSecurity(id: string, dto: MatterSecurityUpdateDto): Observable<any> {
		return this.put(`${id}/security`, TransformDatesOnObject(MatterSecurityUpdateDto, TrimStringsOnObject(dto)));
	}

	addDocument(id: string, request: DocumentRequest): Observable<EntityReference> {
		return this.post<DocumentRequest, EntityReference>(`${id}/documents`, request);
	}

}
