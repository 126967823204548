import { EntityReference } from '@common/models/Common/EntityReference';
import { DomainError } from '@common/models/Validation/DomainError';
import { createAction, props } from '@ngrx/store';

export enum MatterRoleFilterActions {
	InvalidateCache = '[Matter Role Filter] Invalidate Cache',
	Load = '[Matter Role Filter] Load',
	Loading = '[Matter Role Filter] Loading',
	LoadSuccess = '[Matter Role Filter] Load Success',
	LoadFailed = '[Matter Role Filter] Load Failed'
}

export const load = createAction(MatterRoleFilterActions.Load);
export const loading = createAction(MatterRoleFilterActions.Loading);
export const loadSuccess = createAction(MatterRoleFilterActions.LoadSuccess, props<{ response: EntityReference[] }>());
export const loadFailed = createAction(MatterRoleFilterActions.LoadFailed, props<{ error: DomainError }>());

export const invalidateCache = createAction(MatterRoleFilterActions.InvalidateCache);
