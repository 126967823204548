import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
	selector: 'pdfjs-viewer',
	template: `<iframe
		title="pdfjs-viewer"
		[hidden]="externalWindow || (!externalWindow && !pdfSrc)"
		#iframe
		width="100%"
		height="100%"
	></iframe>`
})
export class PdfJsViewerComponent implements OnInit {
	@ViewChild('iframe', { static: true }) iframe: ElementRef;
	@Input() viewerId: string;
	@Output() beforePrint: EventEmitter<any> = new EventEmitter();
	@Output() afterPrint: EventEmitter<any> = new EventEmitter();
	@Output() documentLoad: EventEmitter<any> = new EventEmitter();
	@Output() pageChange: EventEmitter<any> = new EventEmitter();
	@Input() viewerFolder: string;
	@Input() externalWindow: boolean = false;
	@Input() showSpinner: boolean = true;
	@Input() downloadFileName: string;
	@Input() openFile: boolean = true;
	@Input() download: boolean = true;
	@Input() startDownload: boolean;
	@Input() viewBookmark: boolean = true;
	@Input() print: boolean = true;
	@Input() startPrint: boolean;
	@Input() fullScreen: boolean = true;
	// @Input()  showFullScreen: boolean;
	@Input() find: boolean = true;
	@Input() zoom: string;
	@Input() nameddest: string;
	@Input() pagemode: string;
	@Input() lastPage: boolean;
	@Input() rotatecw: boolean;
	@Input() rotateccw: boolean;
	@Input() cursor: string;
	@Input() scroll: string;
	@Input() spread: string;
	@Input() locale: string;
	@Input() useOnlyCssZoom: boolean = false;
	@Input() errorOverride: boolean = false;
	@Input() errorAppend: boolean = true;
	@Input() errorMessage: string;
	@Input() diagnosticLogs: boolean = true;

	@Input() externalWindowOptions: string;
	viewerTab: any;
	private _src: string | Blob | Uint8Array;
	private _page: number;

	@Input()
	set page(_page: number) {
		this._page = _page;
		if (this.PDFViewerApplication) {
			this.PDFViewerApplication.page = this._page;
		} else {
			if (this.diagnosticLogs) {
				console.warn(
					`Document is not loaded yet!!!. Try to set page# after full load. 
					Ignore this warning if you are not setting page# using '.' notation. (E.g. pdfViewer.page = 5;)`
				);
			}
		}
	}

	get page() {
		if (this.PDFViewerApplication) {
			return this.PDFViewerApplication.page;
		} else {
			if (this.diagnosticLogs) {
				console.warn('Document is not loaded yet!!!. Try to retrieve page# after full load.');
			}
			return null;
		}
	}

	@Input()
	set pdfSrc(_src: string | Blob | Uint8Array) {
		this._src = _src;
	}

	get pdfSrc() {
		return this._src;
	}

	get PDFViewerApplicationOptions() {
		let pdfViewerOptions = null;
		if (this.externalWindow) {
			if (this.viewerTab) {
				pdfViewerOptions = this.viewerTab.PDFViewerApplicationOptions;
			}
		} else {
			if (this.iframe.nativeElement.contentWindow) {
				pdfViewerOptions = this.iframe.nativeElement.contentWindow.PDFViewerApplicationOptions;
			}
		}
		return pdfViewerOptions;
	}

	get PDFViewerApplication() {
		let pdfViewer = null;
		if (this.externalWindow) {
			if (this.viewerTab) {
				pdfViewer = this.viewerTab.PDFViewerApplication;
			}
		} else {
			if (this.iframe.nativeElement.contentWindow) {
				pdfViewer = this.iframe.nativeElement.contentWindow.PDFViewerApplication;
			}
		}
		return pdfViewer;
	}

	receiveMessage(viewerEvent: any) {
		if (viewerEvent.data && viewerEvent.data.viewerId && viewerEvent.data.event) {
			const viewerId = viewerEvent.data.viewerId;
			const event = viewerEvent.data.event;
			const param = viewerEvent.data.param;
			if (this.viewerId === viewerId) {
				if (this.beforePrint && event === 'beforePrint') {
					this.beforePrint.emit();
				} else if (this.afterPrint && event === 'afterPrint') {
					this.afterPrint.emit();
				} else if (this.documentLoad && event === 'pagesLoaded') {
					this.documentLoad.emit(param);
				} else if (this.pageChange && event === 'pageChange') {
					this.pageChange.emit(param);
				}
			}
		}
	}

	ngOnInit(): void {
		window.addEventListener('message', this.receiveMessage.bind(this), false);
		if (!this.externalWindow) {
			// Load pdf for embedded views
			this.loadPdf();
		}
	}

	refresh(): void {
		// Needs to be invoked for external window or when needs to reload pdf
		this.loadPdf();
	}

	private loadPdf() {
		if (!this._src) {
			return;
		}

		// console.log(`Tab is - ${this.viewerTab}`);
		// if (this.viewerTab) {
		//   console.log(`Status of window - ${this.viewerTab.closed}`);
		// }

		if (this.externalWindow && (typeof this.viewerTab === 'undefined' || this.viewerTab.closed)) {
			this.viewerTab = window.open('', '_blank', this.externalWindowOptions || '');
			if (this.viewerTab == null) {
				if (this.diagnosticLogs) {
					console.error(
						"ng2-pdfjs-viewer: For 'externalWindow = true'. i.e opening in new tab to work, pop-ups should be enabled."
					);
				}
				return;
			}

			if (this.showSpinner) {
				this.viewerTab.document.write(`
          <style>
          .loader {
            position: fixed;
            left: 40%;
            top: 40%;
            border: 16px solid #f3f3f3;
            border-radius: 50%;
            border-top: 16px solid #3498db;
            width: 120px;
            height: 120px;
            animation: spin 2s linear infinite;
          }
          @keyframes spin {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
          </style>
          <div class="loader"></div>
        `);
			}
		}

		let fileUrl;
		// if (typeof this.src === "string") {
		//  fileUrl = this.src;
		// }
		if (this._src instanceof Blob) {
			fileUrl = encodeURIComponent(URL.createObjectURL(this._src));
		} else if (this._src instanceof Uint8Array) {
			const blob = new Blob([this._src], { type: 'application/pdf' });
			fileUrl = encodeURIComponent(URL.createObjectURL(blob));
		} else {
			fileUrl = this._src;
		}

		let viewerUrl;
		if (this.viewerFolder) {
			viewerUrl = `${this.viewerFolder}/web/viewer.html`;
		} else {
			viewerUrl = `assets/pdfjs/web/viewer.html`;
		}

		viewerUrl += `?file=${fileUrl}`;

		if (typeof this.viewerId !== 'undefined') {
			viewerUrl += `&viewerId=${this.viewerId}`;
		}
		if (typeof this.beforePrint !== 'undefined') {
			viewerUrl += `&beforePrint=true`;
		}
		if (typeof this.afterPrint !== 'undefined') {
			viewerUrl += `&afterPrint=true`;
		}
		if (typeof this.documentLoad !== 'undefined') {
			viewerUrl += `&pagesLoaded=true`;
		}
		if (typeof this.pageChange !== 'undefined') {
			viewerUrl += `&pageChange=true`;
		}

		if (this.downloadFileName) {
			if (!this.downloadFileName.endsWith('.pdf')) {
				this.downloadFileName += '.pdf';
			}
			viewerUrl += `&fileName=${this.downloadFileName}`;
		}
		if (typeof this.openFile !== 'undefined') {
			viewerUrl += `&openFile=${this.openFile}`;
		}
		if (typeof this.download !== 'undefined') {
			viewerUrl += `&download=${this.download}`;
		}
		if (this.startDownload) {
			viewerUrl += `&startDownload=${this.startDownload}`;
		}
		if (typeof this.viewBookmark !== 'undefined') {
			viewerUrl += `&viewBookmark=${this.viewBookmark}`;
		}
		if (typeof this.print !== 'undefined') {
			viewerUrl += `&print=${this.print}`;
		}
		if (this.startPrint) {
			viewerUrl += `&startPrint=${this.startPrint}`;
		}
		if (typeof this.fullScreen !== 'undefined') {
			viewerUrl += `&fullScreen=${this.fullScreen}`;
		}
		// if (this.showFullScreen) {
		//   viewerUrl += `&showFullScreen=${this.showFullScreen}`;
		// }
		if (typeof this.find !== 'undefined') {
			viewerUrl += `&find=${this.find}`;
		}
		if (this.lastPage) {
			viewerUrl += `&lastpage=${this.lastPage}`;
		}
		if (this.rotatecw) {
			viewerUrl += `&rotatecw=${this.rotatecw}`;
		}
		if (this.rotateccw) {
			viewerUrl += `&rotateccw=${this.rotateccw}`;
		}
		if (this.cursor) {
			viewerUrl += `&cursor=${this.cursor}`;
		}
		if (this.scroll) {
			viewerUrl += `&scroll=${this.scroll}`;
		}
		if (this.spread) {
			viewerUrl += `&spread=${this.spread}`;
		}
		if (this.locale) {
			viewerUrl += `&locale=${this.locale}`;
		}
		if (this.useOnlyCssZoom) {
			viewerUrl += `&useOnlyCssZoom=${this.useOnlyCssZoom}`;
		}

		if (this._page || this.zoom || this.nameddest || this.pagemode) viewerUrl += '#';
		if (this._page) {
			viewerUrl += `&page=${this._page}`;
		}
		if (this.zoom) {
			viewerUrl += `&zoom=${this.zoom}`;
		}
		if (this.nameddest) {
			viewerUrl += `&nameddest=${this.nameddest}`;
		}
		if (this.pagemode) {
			viewerUrl += `&pagemode=${this.pagemode}`;
		}
		if (this.errorOverride || this.errorAppend) {
			viewerUrl += `&errorMessage=${this.errorMessage}`;

			if (this.errorOverride) {
				viewerUrl += `&errorOverride=${this.errorOverride}`;
			}
			if (this.errorAppend) {
				viewerUrl += `&errorAppend=${this.errorAppend}`;
			}
		}

		if (this.externalWindow) {
			this.viewerTab.location.href = viewerUrl;
		} else {
			this.iframe.nativeElement.src = viewerUrl;
		}

		// console.log(`
		//   pdfSrc = ${this.pdfSrc}
		//   fileUrl = ${fileUrl}
		//   externalWindow = ${this.externalWindow}
		//   downloadFileName = ${this.downloadFileName}
		//   viewerFolder = ${this.viewerFolder}
		//   openFile = ${this.openFile}
		//   download = ${this.download}
		//   startDownload = ${this.startDownload}
		//   viewBookmark = ${this.viewBookmark}
		//   print = ${this.print}
		//   startPrint = ${this.startPrint}
		//   fullScreen = ${this.fullScreen}
		//   find = ${this.find}
		//   lastPage = ${this.lastPage}
		//   rotatecw = ${this.rotatecw}
		//   rotateccw = ${this.rotateccw}
		//   cursor = ${this.cursor}
		//   scrollMode = ${this.scroll}
		//   spread = ${this.spread}
		//   page = ${this.page}
		//   zoom = ${this.zoom}
		//   nameddest = ${this.nameddest}
		//   pagemode = ${this.pagemode}
		//   pagemode = ${this.errorOverride}
		//   pagemode = ${this.errorAppend}
		//   pagemode = ${this.errorMessage}
		// `);
	}
}
