import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';

import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { DocumentListRequest } from '@common/models/Documents/List/DocumentListRequest';
import { GetSimpleType } from '@common/utils/class-transform';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { isArray, isEmpty, isNil, omitBy, orderBy } from 'lodash-es';

import { FilterChangeProperties } from './filter-change-properties';

@Component({
	selector: 'filter-root',
	styleUrls: ['./filter-root.component.scss'],
	template: `
		<mat-expansion-panel [formGroup]="form" [expanded]="showFilters">
			<ng-content></ng-content>
			<button *ngIf="showApplyButton" mat-icon-button throttleButton (throttledClick)="applyFilter()">
				<mat-icon>search</mat-icon>
			</button>
		</mat-expansion-panel>
	`
})
export class FilterRootComponent<T> implements OnInit, OnDestroy {
	@Input()
	showApplyButton: boolean;
	@Input()
	showFilters: boolean = true;
	@Input()
	filter: T;
	@Input()
	listRequestType: new () => T;
	@Output()
	filterChange = new EventEmitter<FilterChangeProperties<T>>();

	form: FormGroup;

	private subscriptions: Subscription;

	constructor(private activatedRoute: ActivatedRoute, private fb: FormBuilder) {}

	ngOnInit() {
		this.form = this.initialiseEmptyFormGroup();
		this.subscriptions = this.activatedRoute.queryParamMap.pipe(distinctUntilChanged()).subscribe(params => {
			// when there is a value for 'applyFilters' it is handled by the list component
			if (!params.has('applyFilters')) {
				this.applyQueryParameters(params);
			}
		});
	}

	ngOnDestroy() {
		if (this.subscriptions) {
			this.subscriptions.unsubscribe();
		}
	}

	applyQueryParameters(params: ParamMap) {
		// convert the ParamMap to an object
		const formParams = orderBy(params.keys).reduce((obj, key) => {
			// params.getAll(key) return an array whereas params.get(key) will only return the first value
			let value: string | string[] | { [x: string]: string } =
				this.filter[key as keyof T] instanceof Array ? params.getAll(key) : params.get(key);
			if (key.indexOf('[') >= 0) {
				// convert dictionaries to objects
				const [parent, child] = key.split(/\[|\]/);
				value = Object.assign(obj[parent as keyof {}] || {}, { [child]: params.get(key) });
				key = parent;
			}

			const simpleType = GetSimpleType(DocumentListRequest.prototype, key);
			if (simpleType == Boolean) {
				value = value.toString().toLowerCase();

				return Object.assign(obj, {
					[key]: value === 'true' ? true : value === 'false' ? false : value
				});
			}

			return Object.assign(obj, {
				[key]: value
			});
		}, {});

		this.form.reset(formParams);
		this.applyFilter();
	}

	setFilter(newValues: Partial<T>) {
		if (this.filter) {
			newValues = Object.assign({}, this.filter, newValues);
		}
		this.form.reset(newValues);
		this.applyFilter();
	}

	resetFilter(newValues: Partial<T>, replaceUrl: boolean = false) {
		if (!!this.form) this.form.reset(newValues);
		this.applyFilter(replaceUrl);
	}

	applyFilter(replaceUrl: boolean = false) {
		const formValue = omitBy(
			TransformDatesOnObject(this.listRequestType, this.form ? this.form.value : null),
			this.isNull
		) as T;

		this.filter = formValue;
		this.filterChange.emit(new FilterChangeProperties<T>(formValue, replaceUrl));
	}

	private isNull(item: any): boolean {
		// ignore empty values as well as empty arrays
		return isNil(item) || (isArray(item) && isEmpty(item));
	}

	private initialiseEmptyFormGroup(): FormGroup {
		return this.fb.group(
			Object.keys(this.filter).reduce(
				(prev, curr) =>
					Object.assign(prev, { [curr]: this.filter[curr as keyof T] instanceof Array ? [] : null }),
				{}
			)
		);
	}
}
