<div class="filter-row">
	<button mat-button color="primary" title="Create Document Template Group" throttleButton (throttledClick)="createDocumentCategory()">
		<mat-icon>add_circle</mat-icon>
		<span>Create New Document Template Group</span>
	</button>
</div>
<mat-table [dataSource]="datasource" matSort matSortActive="name" matSortDirection="asc">
	<ng-container matColumnDef="name">
		<mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
		<mat-cell *matCellDef="let row">
			<div class="field-name">{{ row.name }}</div>
		</mat-cell>
	</ng-container>

	<ng-container matColumnDef="actions">
		<mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
		<mat-cell *matCellDef="let row">
			<button mat-icon-button [matMenuTriggerFor]="menu" title="Actions">
				<mat-icon>more_vert</mat-icon>
			</button>
			<mat-menu #menu="matMenu">
				<button mat-menu-item throttleButton (throttledClick)="editDocumentCategory(row)">
					<mat-icon>edit</mat-icon>
					<span>Edit Details</span>
				</button>
				<button mat-menu-item throttleButton (throttledClick)="deleteDocumentCategory(row)">
					<mat-icon>delete</mat-icon>
					<span>Delete</span>
				</button>
			</mat-menu>
		</mat-cell>
	</ng-container>

	<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
	<mat-row
		*matRowDef="let row; columns: displayedColumns"
		throttleButton
		(throttledClick)="selectRow(row, $event)"
		[ngClass]="{ highlight: row.isHighlighted }"
	></mat-row>
</mat-table>
<mat-paginator
	[length]="datasource?.response?.totalRecords"
	[pageIndex]="datasource?.response?.pageIndex"
	[pageSize]="pageSize"
	[pageSizeOptions]="pageSizeOptions"
></mat-paginator>
