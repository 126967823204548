<div *ngIf="!showViewer && !!exceededLimit" class="no-preview">
	<p>
		This file is too large to preview in browser. File size limit is {{ maxBytesAllowed | bytes }}. <br />
		You will need to click the Download button to view it on your machine.
	</p>
	<div class="btn-download-container">
		<button mat-raised-button class="btn-download" throttleButton (throttledClick)="downloadFile()" title="Download Document">
			<mat-icon color="accent">cloud_download</mat-icon>
			<span class="btn-download-text">&nbsp;&nbsp;Download Document</span>
		</button>
	</div>
</div>
<div class="container" *ngIf="!!showViewer">
	<div class="sent-date-text">{{ emailPreviewSentDateString | moment : 'EEE' }} {{ emailPreviewSentDateString | moment : 'dd/MM/yyyy' }}</div>
	<div class="from-text" *ngIf="emailPreviewDto?.from?.address">
		<a [matTooltip]="emailPreviewDto.from.address" href="mailto:{{ emailPreviewDto.from.address }}"> {{ emailPreviewDto.from.displayName }}</a>
	</div>
	<div class="subject-text">{{ emailPreviewSubject }}</div>

	<div *ngIf="emailPreviewDto?.to?.length" class="email-container">
		<div>To:</div>
		<div>
			<span class="small-email-text" *ngFor="let email of emailPreviewDto?.to">
				<a [matTooltip]="email.address" href="mailto:{{ email.address }}">{{ email.displayName }}</a>
			</span>
		</div>
	</div>
	<div *ngIf="emailPreviewDto?.cc?.length" class="email-container">
		<div>Cc:</div>
		<div>
			<span class="small-email-text" *ngFor="let email of emailPreviewDto?.cc">
				<a [matTooltip]="email.address" href="mailto:{{ email.address }}">{{ email.displayName }}</a>
			</span>
		</div>
	</div>
	<div *ngIf="emailPreviewDto?.bcc?.length" class="email-container">
		<div>Bcc:</div>
		<div>
			<span class="small-email-text" *ngFor="let email of emailPreviewDto?.bcc">
				<a [matTooltip]="email.address" href="mailto:{{ email.address }}">{{ email.displayName }}</a>
			</span>
		</div>
	</div>
	<div class="flexrow">
		<div *ngFor="let attachment of emailPreviewDto?.attachments">
			<mat-card throttleButton (throttledClick)="onPreviewClick(attachment)" title="Preview">
				<mat-icon [svgIcon]="getFileIcon(attachment.fileExtension)" [ngClass]="getFileIcon(attachment.fileExtension)"></mat-icon>
				<div class="attachment-details">
					<span>{{ attachment.fullFileName }}</span>
					<span class="attachment-details-size">{{ attachment.size | bytes }}</span>
				</div>
				<div class="flex-column-menu" throttleButton (throttledClick)="$event.stopPropagation(); menuTrigger.openMenu()">
					<button class="expand-link" mat-icon-button #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu" title="Actions">
						<mat-icon>expand_more</mat-icon>
					</button>
					<mat-menu #menu="matMenu">
						<button
							mat-menu-item
							throttleButton
							(throttledClick)="onPreviewClick(attachment)"
							*ngIf="getFileIcon(attachment.fileExtension) !== 'email-outline'"
						>
							<mat-icon>zoom_in</mat-icon>
							<span>Preview</span>
						</button>
						<button mat-menu-item throttleButton (throttledClick)="onDownloadClick(attachment)">
							<mat-icon>cloud_download</mat-icon>
							<span>Download</span>
						</button>
					</mat-menu>
				</div>
			</mat-card>
		</div>
	</div>
	<div class="body">
		<!-- 	Don't use <div innerHTML="">. 
			<iframe> with 'sandbox' attribute restricts the user to run scripts and access the sessionStorage.
			This is to protect XSS attacks.
			https://developer.mozilla.org/en-US/docs/Web/HTML/Element/iframe	-->
		<iframe sandbox="" *ngIf="!!safeHtmlBody" [srcdoc]="safeHtmlBody"></iframe>
	</div>
</div>
