import { SortDirection } from '@angular/material/sort';

import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';
import { ReferralReasonListItemDto } from '@common/models/Settings/ReferralReasons/List/ReferralReasonListItemDto';
import { DomainError } from '@common/models/Validation/DomainError';
import { createAction, props } from '@ngrx/store';

import { SelectionType } from '../../../SelectionType';
import { ListRequest } from '@common/models/Generic/ListRequest';

export enum ReferralReasonListActions {
	Init = '[Referral Reason List] Initialise',
	Load = '[Referral Reason List] Load',
	Loading = '[Referral Reason List] Loading',
	LoadSuccess = '[Referral Reason List] Load Success',
	LoadFailed = '[Referral Reason List] Load Failed',

	SetFilters = '[Referral Reason List] Set Filters',
	SetPageSize = '[Referral Reason List] Set Page Size',
	SetPageIndex = '[Referral Reason List] Set Page Index',
	SetPageIndexForId = '[Referral Reason List] Set Page Index For Id',

	SetSortBy = '[Referral Reason List] Set Sort By',
	SetSortDirection = '[Referral Reason List] Set Sort Direction',

	InsertRecords = '[Referral Reason List] Insert Records',
	UpdateRecords = '[Referral Reason List] Update Records',
	RemoveRecords = '[Referral Reason List] Remove Records',
	SelectRecords = '[Referral Reason List] Select Records'
}

export const init = createAction(ReferralReasonListActions.Init);
export const load = createAction(ReferralReasonListActions.Load);
export const loading = createAction(ReferralReasonListActions.Loading);
export const loadSuccess = createAction(
	ReferralReasonListActions.LoadSuccess,
	props<{ response: ReferralReasonListItemDto }>()
);
export const loadFailed = createAction(ReferralReasonListActions.LoadFailed, props<{ error: DomainError }>());

export const setFilters = createAction(ReferralReasonListActions.SetFilters, props<{ filter: Partial<ListRequest> }>());
export const setPageSize = createAction(ReferralReasonListActions.SetPageSize, props<{ pageSize: number }>());
export const setPageIndex = createAction(ReferralReasonListActions.SetPageIndex, props<{ pageIndex: number }>());
export const setPageIndexForId = createAction(ReferralReasonListActions.SetPageIndexForId, props<{ id: string }>());

export const setSortBy = createAction(ReferralReasonListActions.SetSortBy, props<{ sortBy: string }>());
export const setSortDirection = createAction(
	ReferralReasonListActions.SetSortDirection,
	props<{ sortDirection: SortDirection }>()
);

export const insertRecords = createAction(
	ReferralReasonListActions.InsertRecords,
	props<{ response: MutationResponseDto | MutationResponseDto[] }>()
);
export const updateRecords = createAction(
	ReferralReasonListActions.UpdateRecords,
	props<{ response: MutationResponseDto | MutationResponseDto[] }>()
);
export const removeRecords = createAction(
	ReferralReasonListActions.RemoveRecords,
	props<{ response: MutationResponseDto | MutationResponseDto[] }>()
);
export const selectRecords = createAction(
	ReferralReasonListActions.SelectRecords,
	props<{ row: ReferralReasonListItemDto; selectionType: SelectionType }>()
);
