import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ContactLookupComponent } from '@common/components/lookups/contact-lookup.component';
import { EntityReference } from '@common/models/Common/EntityReference';
import { ContactsService } from '@common/services/contacts.service';
import { get, isObjectLike } from 'lodash-es';

import { ContactLookupLocalComponent } from '../components/contact-lookup-local.component';
import { BaseEditableComponent } from './base.editable.component';

@Component({
	selector: 'editable-contact',
	styleUrls: ['./base.editable.component.scss'],
	template: `
		<span *ngIf="!editMode && !value()" [class]="'view-noitem'">{{ formatDisplay(blankMessage) }}</span>
		<a
			*ngIf="!editMode && value()"
			[routerLink]="['/contacts/' + (!!value() && value()?.id ? value().id : value())]"
			>{{ formatDisplay(contactName) }}</a
		>
		<contact-lookup
			#contactLookup
			[Placeholder]="placeHolder"
			name="contactLookup"
			*ngIf="!!editMode && !allowedContactIds?.length"
			[FormControl]="formControl"
			[HasAutofocus]="hasAutoFocus"
			[Required]="required"
			[StaffOnly]="staffOnly"
			[Hint]="hintText"
			(Selected)="contactSelected($event)"
		>
		</contact-lookup>
		<contact-lookup-local
			#contactLookupLocal
			[Placeholder]="placeHolder"
			name="contactLookup"
			*ngIf="!!editMode && !!allowedContactIds?.length"
			[FormControl]="formControl"
			[HasAutofocus]="hasAutoFocus"
			[Required]="required"
			[Hint]="hintText"
			(Selected)="contactSelected($event)"
			[allowedContactIds]="allowedContactIds"
		>
		</contact-lookup-local>
	`
})
export class EditableContactComponent extends BaseEditableComponent<EntityReference> implements OnInit, OnDestroy {
	@Input()
	staffOnly: boolean;
	@Input()
	contactName: string;
	@Input()
	allowedContactIds: string[];

	@ViewChild('contactLookup', { read: ContactLookupComponent })
	contactLookupComponent: ContactLookupComponent;

	@ViewChild('contactLookupLocal', { read: ContactLookupLocalComponent })
	contactLookupLocalComponent: ContactLookupLocalComponent;

	// false by default to prevent client freezing/locking on Safari (occurs during edid mode in matter-summary.component)
	@Input() hasAutoFocus: boolean;

	private subscription: Subscription = new Subscription();

	constructor(private contactService: ContactsService) {
		super();
	}

	ngOnInit() {
		this.subscription.add(
			this.control.valueChanges
				.pipe(filter(val => isObjectLike(val) && val.hasOwnProperty('id')))
				.subscribe(reference => this.contactSelected(reference))
		);

		const value = this.value();
		if (isObjectLike(value) && value.hasOwnProperty('id')) {
			this.contactSelected(this.value());
		}
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	contactSelected(contact: EntityReference): void {
		this.contactName = get(contact, 'name');
		this.control.setValue(get(contact, 'id'));
	}

	updateValueAndValidity() {
		this.contactLookupComponent?.updateValueAndValidity();
		this.contactLookupLocalComponent?.updateValueAndValidity();
	}
}
