export const EntityTypes: IRelatedEntityType[] = [
	{
		allowFilter: true,
		icon: 'gavel',
		id: 'Matters',
		link: 'matters',
		name: 'Matters'
	},
	{
		allowFilter: true,
		icon: 'settings',
		id: 'MatterCustomField',
		link: 'matter-custom-fields',
		name: 'Matter Custom Fields'
	},
	{
		allowFilter: true,
		icon: 'settings',
		id: 'CustomFieldGroups',
		link: '',
		name: 'Custom Field Groups'
	},
	{
		allowFilter: true,
		icon: 'settings',
		id: 'ContactCustomField',
		link: 'contact-custom-fields',
		name: 'Contact Custom Fields'
	},
	{
		allowFilter: true,
		icon: 'person',
		id: 'Contacts',
		link: 'contacts',
		name: 'Contacts'
	},
	{
		allowFilter: true,
		icon: 'account_box',
		id: 'Users',
		link: 'users',
		name: 'User Management'
	},
	{
		allowFilter: true,
		icon: 'description',
		id: 'Documents',
		link: 'documents',
		name: 'Documents'
	},
	{
		allowFilter: true,
		icon: 'assignment_turned_in',
		id: 'Tasks',
		link: 'tasks',
		name: 'Tasks'
	},
	{
		icon: 'access_time',
		id: 'TimeRecords',
		link: 'costs',
		name: 'Time/Fee Records'
	},
	{
		icon: 'local_atm',
		id: 'ExpenseRecords',
		link: 'costs',
		name: 'Expenses Records'
	},
	{
		icon: 'access_time',
		id: 'Units',
		link: 'units',
		name: 'Units'
	},
	{
		allowFilter: true,
		icon: 'attach_money',
		id: 'Invoices',
		link: 'invoices',
		name: 'Invoices'
	},
	{
		allowFilter: true,
		icon: 'account_balance',
		id: 'Trust',
		link: 'trust',
		name: 'Trust'
	},
	{
		allowFilter: true,
		icon: 'ballot',
		id: 'TrustReconciliations',
		link: 'reconciliation-editor',
		name: 'Trust Reconciliation'
	},
	{
		icon: 'import_export',
		id: 'Imports',
		link: 'system/import',
		name: 'Import'
	},
	{
		icon: 'storefront',
		id: 'MarketplaceTenantPackages',
		link: 'system/marketplace',
		name: 'Marketplace Package'
	}
];

export interface IRelatedEntityType {
	allowFilter?: boolean;
	icon: string;
	id: string;
	link: string;
	name: string;
}

export const getEntityType = (id: string) => {
	return EntityTypes.find(type => type.id === id);
};
