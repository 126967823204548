// This file has been generated from MemberReferenceRequestDto.cs
// Do not modify this file directly!!
// tslint:disable

import { CompanyContactCreateUpdateDto } from './CompanyContactCreateUpdateDto';
import { PersonContactCreateUpdateDto } from './PersonContactCreateUpdateDto';

// LawMaster.Amalthea.Domain.Contacts.Item.MemberReferenceRequestDto
export class MemberReferenceRequestDto {

	// CreatePersons
	createPersons: { [key: string]: PersonContactCreateUpdateDto; };
	// CreateCompanies
	createCompanies: { [key: string]: CompanyContactCreateUpdateDto; };
	// MemberId
	memberId: string;

	constructor() {

	}
}