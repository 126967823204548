import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { AppcuesService } from '@common/appcues/appcues.service';
import { ListResponse } from '@common/models/Generic/ListResponse';
import { InstallationRecordReference } from '@common/models/Marketplace/InstallationRecords/Common/InstallationRecordReference';
import { CustomFieldEntityType } from '@common/models/Settings/CustomFields/Common/CustomFieldEntityType';
import { CustomFieldType } from '@common/models/Settings/CustomFields/Common/CustomFieldType';
import { ChangeOrderNumberDto } from '@common/models/Settings/CustomFields/Item/ChangeOrderNumberDto';
import { CustomFieldGroupCreateUpdateDto } from '@common/models/Settings/CustomFields/Item/CustomFieldGroupCreateUpdateDto';
import { DeleteCustomFieldResponseDto } from '@common/models/Settings/CustomFields/Item/DeleteCustomFieldResponseDto';
import { MatterCustomFieldCreateDto } from '@common/models/Settings/CustomFields/Item/MatterCustomFieldCreateDto';
import { MatterCustomFieldUpdateDto } from '@common/models/Settings/CustomFields/Item/MatterCustomFieldUpdateDto';
import { CustomFieldGroupListItemDto } from '@common/models/Settings/CustomFields/List/CustomFieldGroupListItemDto';
import { MatterCustomFieldListItemDto } from '@common/models/Settings/CustomFields/List/MatterCustomFieldListItemDto';
import { MatterCustomFieldListRequest } from '@common/models/Settings/CustomFields/List/MatterCustomFieldListRequest';
import { PracticeAreaListItemDto } from '@common/models/Settings/PracticeAreas/List/PracticeAreaListItemDto';
import { DomainError } from '@common/models/Validation/DomainError';
import { NotificationService } from '@common/notification';
import { CustomFieldsGroupService } from '@common/services/customfields-group.service';
import { MatterCustomFieldsService } from '@common/services/customfields-matter.service';
import { PracticeAreasService } from '@common/services/settings/practiceareas.service';
import { isEmpty, sortBy } from 'lodash-es';

import { FeatureFlags, isFeatureFlagEnabled } from 'app/app.config';
import { GridViewService } from 'app/services/grid-view.service';
import { multirowSelectText } from 'app/shared/utils/stringUtil';
import { BaseCustomFieldListComponent } from 'app/system/custom-fields/base-custom-field-list.component';

import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';
import { Store } from '@ngrx/store';
import { IAppState } from 'app/core/state/app.state';
import { MatterCustomFieldListActions } from 'app/core/state/lists/settings/matter-custom-field-list/matter-custom-field-list.actions';
import {
	selectMatterCustomFieldIsFetching,
	selectMatterCustomFieldListRecords,
	selectMatterCustomFieldListRequest,
	selectMatterCustomFieldListTotalRecords
} from 'app/core/state/lists/settings/matter-custom-field-list/matter-custom-field-list.selectors';
import { ActionTypes, SelectorTypes } from 'app/shared/generics/generic.list.state.component';
import { ICreateCustomFieldData } from '../../base-custom-field-dialog.component';
import { CustomFieldGroupDialogComponent } from '../../custom-field-group-dialog.component';
import { MatterCustomFieldListFilterDialogComponent } from '../filter-dialog/matter-custom-field-list-filter-dialog.component';
import { MatterCustomFieldListFilterComponent } from '../filter/matter-custom-field-list-filter.component';
import { MatterCustomFieldDialogComponent } from '../matter-custom-field-dialog/matter-custom-field-dialog.component';

@Component({
	selector: 'matter-custom-field-list',
	styleUrls: ['./matter-custom-field-list.component.scss'],
	templateUrl: './matter-custom-field-list.component.html'
})
export class MatterCustomFieldListComponent
	extends BaseCustomFieldListComponent<
		MatterCustomFieldCreateDto,
		MatterCustomFieldListRequest,
		MatterCustomFieldListItemDto,
		MatterCustomFieldUpdateDto,
		CustomFieldGroupCreateUpdateDto
	>
	implements OnInit
{
	get actions(): ActionTypes {
		return {
			init: MatterCustomFieldListActions.Init,
			load: MatterCustomFieldListActions.Load,
			setFilters: MatterCustomFieldListActions.SetFilters,
			setPageIndex: MatterCustomFieldListActions.SetPageIndex,
			setPageIndexForId: MatterCustomFieldListActions.SetPageIndexForId,
			setPageSize: MatterCustomFieldListActions.SetPageSize,
			setSortBy: MatterCustomFieldListActions.SetSortBy,
			setSortDirection: MatterCustomFieldListActions.SetSortDirection,
			selected: MatterCustomFieldListActions.SelectRecords
		};
	}
	get selectors(): SelectorTypes {
		return {
			records: selectMatterCustomFieldListRecords,
			isFetching: selectMatterCustomFieldIsFetching,
			request: selectMatterCustomFieldListRequest,
			totalRecords: selectMatterCustomFieldListTotalRecords
		};
	}

	practiceAreas: PracticeAreaListItemDto[];

	@ViewChild(MatterCustomFieldListFilterComponent, { static: true })
	filter: MatterCustomFieldListFilterComponent;
	request: Partial<MatterCustomFieldListRequest> = {
		enabled: null,
		fieldType: [],
		mandatory: null,
		practiceAreaIds: [],
		search: null,
		groupId: null,
		installationRecordId: null,
		hasDefaultValue: null,
		canShowInReports: null
	};

	filterDialog = MatterCustomFieldListFilterDialogComponent;
	@Input() rowSelectionLabel: string = multirowSelectText();

	get canShowReportField() {
		return !!isFeatureFlagEnabled(FeatureFlags.reportCustomFields);
	}

	constructor(
		store: Store<IAppState>,
		private customFieldsService: MatterCustomFieldsService,
		dialog: MatDialog,
		router: Router,
		activatedRoute: ActivatedRoute,
		protected notificationService: NotificationService,
		private appcuesService: AppcuesService,
		private customFieldsGroupService: CustomFieldsGroupService,
		gridViewService: GridViewService,
		private practiceAreaService: PracticeAreasService
	) {
		let columns = [
			'description',
			'practiceAreas',
			'customFieldGroups',
			'fieldType',
			'enabled',
			'showInReports',
			'installationRecords',
			'actions'
		];

		if (!isFeatureFlagEnabled(FeatureFlags.reportCustomFields)) {
			columns = columns.filter(column => column !== 'showInReports');
		}

		super(
			store,
			columns,
			dialog,
			router,
			activatedRoute,
			notificationService,
			gridViewService
		);
	}

	ngOnInit() {
		this.filterComponent = this.filter.root;
		this.pageSize = 10000;
		this.refreshGroups();
		this.refreshPracticeAreas();

		this.subscriptions.add(
			this.filterComponent.filterChange
				.pipe(
					filter(Boolean),
					switchMap(() => {
						return this.getGroupsListObservable();
					})
				)
				.subscribe(response => {
					this.customFieldGroups = sortBy(response.records, 'orderNumber');
				})
		);
		super.ngOnInit();
	}

	ngAfterViewInit(): void {
		super.ngAfterViewInit();
	}

	refreshGroups() {
		this.subscriptions.add(
			this.subscriptions.add(
				this.getGroupsListObservable().subscribe(response => {
					this.customFieldGroups = sortBy(response.records, 'orderNumber');
				})
			)
		);
	}

	refreshPracticeAreas() {
		this.subscriptions.add(
			this.getPracticeAreasListObservable().subscribe(response => {
				this.practiceAreas = sortBy(response.records, 'name');
			})
		);
	}

	getInstalledPackages(packages: InstallationRecordReference[]): InstallationRecordReference[] {
		return packages?.filter(tenantPackage => !!tenantPackage.isInstalled);
	}

	getGroupsListObservable(): Observable<ListResponse<CustomFieldGroupListItemDto>> {
		return this.customFieldsGroupService.getCustomFieldGroupList({
			entityType: 'Matter',
			practiceAreaIds: this.filterComponent.filter?.practiceAreaIds,
			fieldTypes: this.filterComponent.filter?.fieldType,
			enabled: this.filterComponent.filter?.enabled,
			mandatory: this.filterComponent.filter?.mandatory,
			search: this.filterComponent.filter?.search,
			hasDefaultValue: this.filterComponent.filter?.hasDefaultValue
		});
	}

	getPracticeAreasListObservable(): Observable<ListResponse<PracticeAreaListItemDto>> {
		return this.practiceAreaService.getPracticeAreaList({
			showOnlyMyAreas: false
		});
	}

	selectGroup(groupId: string) {
		if (this.selectedGroupId === groupId) return;
		else this.selectedGroupId = groupId;
		this.filterComponent.applyFilter(true);
		this.filterComponent.filter.groupId = this.selectedGroupId;
		this.filterComponent.filter.pageSize = this.pageSize;
		this.filterComponent.resetFilter(this.filterComponent.filter);
	}

	createCustomFieldGroup(): void {
		const data: Partial<CustomFieldGroupCreateUpdateDto> = { entityType: CustomFieldEntityType.Matter };
		super.afterGroupCreateClosed(this.dialog.open(CustomFieldGroupDialogComponent, { data }).afterClosed());
	}

	editCustomFieldGroup(customFieldGroup: CustomFieldGroupListItemDto) {
		const data: Partial<CustomFieldGroupCreateUpdateDto> = {
			name: customFieldGroup.name,
			entityType: 'Matter'
		};
		super.afterGroupEditClosed(
			this.dialog.open(CustomFieldGroupDialogComponent, { data }).afterClosed(),
			customFieldGroup.id
		);
	}

	createCustomField(): void {
		const data: Partial<ICreateCustomFieldData> = { selectedGroupId: this.selectedGroupId };
		super.afterCreateClosed(this.dialog.open(MatterCustomFieldDialogComponent, { data }).afterClosed());
		this.appcuesService.trackEvent('CreatedMatterCustomField');
	}
	editCustomField(id: string, openBuilder: boolean = false, builderEntityId: string = null): void {
		const data: Partial<ICreateCustomFieldData> = { id, openBuilder, builderEntityId };
		super.afterEditClosed(this.dialog.open(MatterCustomFieldDialogComponent, { data }).afterClosed(), id);
	}

	protected serviceCreate(data: MatterCustomFieldCreateDto): Observable<MutationResponseDto> {
		return this.customFieldsService.createMatterCustomField(data);
	}
	protected serviceUpdate(id: string, data: MatterCustomFieldUpdateDto): Observable<MutationResponseDto> {
		return this.customFieldsService.updateMatterCustomField(id, data);
	}

	protected serviceDelete(id: string): Observable<MutationResponseDto> {
		return this.customFieldsService.deleteMatterCustomField(id);
	}

	protected validateServiceDelete(id: string): Observable<DeleteCustomFieldResponseDto> {
		return this.customFieldsService.validateMatterCustomField(id);
	}

	protected servicePatch(ids: string[], propertyName: string, value: string): Observable<MutationResponseDto> {
		return this.customFieldsService.patchMatterCustomFields(ids, { [propertyName]: value });
	}
	protected serviceDeleteGroup(id: string): Observable<MutationResponseDto> {
		return this.customFieldsGroupService.deleteCustomFieldGroup(id);
	}
	protected changeOrderNumber(dto: ChangeOrderNumberDto): Observable<MutationResponseDto> {
		return this.customFieldsService.changeOrderNumber(dto);
	}
	protected changeGroupOrderNumber(sourceId: string, destinationId: string): Observable<MutationResponseDto> {
		return this.customFieldsGroupService.changeGroupOrderNumber(sourceId, destinationId);
	}
	protected serviceCreateGroup(data: CustomFieldGroupCreateUpdateDto): Observable<MutationResponseDto> {
		return this.customFieldsGroupService.createCustomFieldGroup(data);
	}

	protected serviceUpdateGroup(id: string, data: CustomFieldGroupCreateUpdateDto): Observable<MutationResponseDto> {
		return this.customFieldsGroupService.updateCustomFieldGroup(id, data);
	}
	protected serviceAddGroupToFields(groupId: string, ids: string[]): Observable<MutationResponseDto> {
		return MatterCustomFieldListComponent.componentInstance.customFieldsGroupService.addGroupToFields({
			groupId,
			ids
		});
	}
	protected serviceRemoveGroupToFields(groupId: string, ids: string[]): Observable<MutationResponseDto> {
		return MatterCustomFieldListComponent.componentInstance.customFieldsGroupService.removeGroupFromFields({
			groupId,
			ids
		});
	}
	protected serviceMoveFieldsToGroup(groupId: string, ids: string[]): Observable<MutationResponseDto> {
		return MatterCustomFieldListComponent.componentInstance.customFieldsGroupService.moveFieldsToGroup({
			groupId,
			ids
		});
	}
	protected serviceAddPracticeAreaToFields(practiceAreaId: string, ids: string[]): Observable<MutationResponseDto> {
		return MatterCustomFieldListComponent.componentInstance.customFieldsGroupService.addPracticeAreaToFields({
			practiceAreaId,
			ids
		});
	}
	protected serviceRemovePracticeAreaFromFields(
		practiceAreaId: string,
		ids: string[]
	): Observable<MutationResponseDto> {
		return MatterCustomFieldListComponent.componentInstance.customFieldsGroupService.removePracticeAreaFromFields({
			practiceAreaId,
			ids
		});
	}
	protected refreshList(dto: MutationResponseDto): void {
		this.store.dispatch({ type: MatterCustomFieldListActions.UpdateRecords, response: dto });
	}

	canEnableShowInReportsForSelected() {
		if (!this.selected$) {
			return of(false);
		}

		return this.selected$.pipe(
			map(
				selected =>
					!!selected?.filter(
						dto =>
							!dto.showInReports &&
							dto.fieldType !== CustomFieldType.Paragraph &&
							dto.fieldType !== CustomFieldType.Calculated
					)?.length
			)
		);
	}

	canDisableShowInReportsForSelected() {
		if (!this.selected$) {
			return of(false);
		}

		return this.selected$.pipe(map(selected => !!selected?.filter(dto => !!dto.showInReports)?.length));
	}

	enableShowInReportsForSelected() {
		this.selected$
			.pipe(
				map(
					selected =>
						!!selected &&
						selected
							.filter(
								dto =>
									dto.fieldType !== CustomFieldType.Paragraph &&
									dto.fieldType !== CustomFieldType.Calculated
							)
							.map(dto => dto.id)
				)
			)
			.subscribe(ids => {
				this.customFieldsService.enableShowInReports({ ids: ids }).subscribe({
					next: () => {
						this.notificationService.showNotification(`Successfully enabled selected custom fields`);
						this.store.dispatch({ type: MatterCustomFieldListActions.UpdateRecords });
					},
					error: errors =>
						this.notificationService.showErrors(`Failed to enable selected custom fields`, errors)
				});
			});
	}

	disableShowInReportsForSelected() {
		this.selected$.pipe(map(selected => !!selected && selected.map(dto => dto.id))).subscribe(ids => {
			this.customFieldsService.disableShowInReports({ ids: ids }).subscribe({
				next: () => {
					this.notificationService.showNotification(`Successfully enabled selected custom fields`);
					this.store.dispatch({ type: MatterCustomFieldListActions.UpdateRecords });
				},
				error: errors => this.notificationService.showErrors(`Failed to enable selected custom fields`, errors)
			});
		});
	}

	showInReports(id: string, flag: boolean) {
		if (!flag) {
			this.disableShowInReports(id);
		} else {
			this.enableShowInReports(id);
		}
	}

	enableShowInReports(id: string) {
		this.subscriptions.add(
			this.customFieldsService.enableShowInReports({ ids: [id] }).subscribe({
				next: () => {
					this.notificationService.showNotification(`Successfully enabled custom field`);
					this.store.dispatch({ type: MatterCustomFieldListActions.UpdateRecords });
				},
				error: errors => this.notificationService.showErrors(`Failed to enable custom field`, errors)
			})
		);
	}

	disableShowInReports(id: string) {
		this.subscriptions.add(
			this.customFieldsService.disableShowInReports({ ids: [id] }).subscribe({
				next: () => {
					this.notificationService.showNotification(`Successfully disabled custom field`);
					this.store.dispatch({ type: MatterCustomFieldListActions.UpdateRecords });
				},
				error: errors => this.notificationService.showErrors(`Failed to disable custom field`, errors)
			})
		);
	}

	performPracticeAreaBulkOPeration(
		serviceMethod: (practiceAreaId: string, ids: string[]) => Observable<MutationResponseDto>,
		practiceArea: PracticeAreaListItemDto,
		message: string
	) {
		this.selected$.pipe(map(selected => selected)).subscribe(selectedListItems => {
			if (selectedListItems.length === 0) {
				this.notificationService.showNotification(`Please select the fields by Ctrl + Click`);
				return;
			}

			serviceMethod(
				practiceArea === null ? '' : practiceArea.id,
				selectedListItems.map(x => x.id)
			).subscribe({
				next: response => {
					this.store.dispatch({ type: MatterCustomFieldListActions.UpdateRecords, response: response });
					this.refreshPracticeAreas();
					this.notificationService.showNotification(message);
				},
				error: (errors: DomainError[]) => this.notificationService.showErrors('Action Failed', errors)
			});
		});
	}

	getPracticeAreasToRemove() {
		if (!this.selected$ || !this.practiceAreas) {
			return of([] as PracticeAreaListItemDto[]);
		}

		return this.selected$.pipe(
			map(selected => {
				let ids: string[] = [];
				selected.forEach(x => x.practiceAreas && x.practiceAreas.forEach(pa => ids.push(pa.id)));
				return this.practiceAreas.filter(x => ids.includes(x.id));
			})
		);
	}

	addPracticeAreaToFields(practiceArea: PracticeAreaListItemDto) {
		const message =
			practiceArea !== null
				? `Practice Area ${practiceArea.name} has been added to the selected custom fields.`
				: 'All Practice Areas have been added to the selected custom fields.';
		this.performPracticeAreaBulkOPeration(this.serviceAddPracticeAreaToFields, practiceArea, message);
	}

	removePracticeAreaFromFields(practiceArea: PracticeAreaListItemDto) {
		this.performPracticeAreaBulkOPeration(
			this.serviceRemovePracticeAreaFromFields,
			practiceArea,
			`Practice Area ${practiceArea.name} has been removed from the selected custom fields.`
		);
	}
}
