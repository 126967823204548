// This file has been generated from BasicWorkflowController.cs
// Do not modify this file directly!!
// tslint:disable

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { TrimStringsOnObject } from '@common/utils/string-format';

import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';
import { ListResponse } from '../../models/Generic/ListResponse';
import { BasicWorkflowCreateUpdateDto } from '../../models/Settings/BasicWorkflows/Item/BasicWorkflowCreateUpdateDto';
import { BasicWorkflowViewDto } from '../../models/Settings/BasicWorkflows/Item/BasicWorkflowViewDto';
import { WorkflowDocumentTemplatesSaveDto } from '../../models/Settings/BasicWorkflows/Item/WorkflowDocumentTemplatesSaveDto';
import { BasicWorkflowListItemDto } from '../../models/Settings/BasicWorkflows/List/BasicWorkflowListItemDto';
import { BasicWorkflowListRequest } from '../../models/Settings/BasicWorkflows/List/BasicWorkflowListRequest';

@Injectable({
	providedIn: 'root'
})
export class BasicWorkflowService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'api/v1/settings/BasicWorkflow', 'Basic Workflow');
	}

	getBasicWorkflow(id: string): Observable<BasicWorkflowViewDto> {
		return this.getItem<void, BasicWorkflowViewDto>(`${id}`);
	}

	getBasicWorkflowList(request?: Partial<BasicWorkflowListRequest>): Observable<ListResponse<BasicWorkflowListItemDto>> {
		return this.getList<BasicWorkflowListRequest, BasicWorkflowListItemDto>('', request);
	}

	createBasicWorkflow(basicWorkflowDto: BasicWorkflowCreateUpdateDto): Observable<MutationResponseDto> {
		return this.post<BasicWorkflowCreateUpdateDto, MutationResponseDto>('', TransformDatesOnObject(BasicWorkflowCreateUpdateDto, TrimStringsOnObject(basicWorkflowDto)));
	}

	updateBasicWorkflow(id: string, basicWorkflowDto: BasicWorkflowCreateUpdateDto): Observable<MutationResponseDto> {
		return this.put(`${id}`, TransformDatesOnObject(BasicWorkflowCreateUpdateDto, TrimStringsOnObject(basicWorkflowDto)));
	}

	saveDocumentTemplates(id: string, documentTemplates: WorkflowDocumentTemplatesSaveDto): Observable<MutationResponseDto> {
		return this.post<WorkflowDocumentTemplatesSaveDto, MutationResponseDto>(`${id}/saveDocumentTemplates`, TransformDatesOnObject(WorkflowDocumentTemplatesSaveDto, TrimStringsOnObject(documentTemplates)));
	}

	deleteBasicWorkflow(id: string): Observable<MutationResponseDto> {
		return this.delete(`${id}`);
	}

}
