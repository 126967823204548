import { Component } from '@angular/core';

import { EntityReference } from '@common/models/Common/EntityReference';
import { CostType } from '@common/models/CostRecords/Common/CostType';

import { FilterBaseComponent } from './filter-base.component';

@Component({
	selector: 'filter-cost-type',
	template: `
		<filter-entity-reference-list
			[root]="root"
			[name]="name"
			[label]="label"
			[items]="source"
			[showTitleAsPrefix]="showTitleAsPrefix"
		></filter-entity-reference-list>
	`
})
export class FilterCostTypeComponent extends FilterBaseComponent {
	private timeKey: keyof typeof CostType = 'Time';
	private expenseKey: keyof typeof CostType = 'Expense';

	// tslint:disable-next-line:member-ordering
	source: EntityReference[] = [
		{ id: this.timeKey, name: CostType.Time },
		{ id: this.expenseKey, name: CostType.Expense }
	];
}
