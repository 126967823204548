// This file has been generated from ExpenseRecordCreateDto.cs
// Do not modify this file directly!!
// tslint:disable

import { Moment } from 'moment-timezone';
import { DateFormat, SetDateFormat } from '@common/utils/date-format';



// LawMaster.Amalthea.Domain.CostRecords.Item.ExpenseRecordCreateDto
export class ExpenseRecordCreateDto {

	// AmountExclTax
	amountExclTax: number;
	// CostCodeId
	costCodeId: string;
	// Description
	description: string;
	// AssociatedMatterId
	associatedMatterId: string;
	// Date
	@SetDateFormat(DateFormat.LocalDate)
	date: Moment;
	// StaffContactId
	staffContactId: string;
	// GstAmountOverride
	gstAmountOverride: number;
	// GstAmount
	gstAmount: number;
	// Attachments
	attachments: File[];

	constructor() {

	}
}