// This file has been generated from ExportPDFDto.cs
// Do not modify this file directly!!
// tslint:disable

import { SetComplexType, SetSimpleType } from '@common/utils/class-transform';

import { ContentsPageOptions } from './ContentsPageOptions';
import { PageNumberOptions } from './PageNumberOptions';
import { SectionOptions } from './SectionOptions';
import { SecurityOptions } from './SecurityOptions';
import { TitlePageOptions } from './TitlePageOptions';

// LawMaster.Amalthea.Domain.DocumentBriefs.Item.ExportPDFDto
export class ExportPDFDto {

	// PageNumberOptions
	@SetComplexType(PageNumberOptions)
	pageNumberOptions: PageNumberOptions;
	// SecurityOptions
	@SetComplexType(SecurityOptions)
	securityOptions: SecurityOptions;
	// TitlePageOptions
	@SetComplexType(TitlePageOptions)
	titlePageOptions: TitlePageOptions;
	// ContentsPageOptions
	@SetComplexType(ContentsPageOptions)
	contentsPageOptions: ContentsPageOptions;
	// DocumentSummaryPage
	@SetSimpleType(Boolean)
	documentSummaryPage: boolean;
	// SectionTitlePage
	@SetSimpleType(Boolean)
	sectionTitlePage: boolean;
	// IncludeEmailAttachments
	@SetSimpleType(Boolean)
	includeEmailAttachments: boolean;
	// DocumentTitle
	documentTitle: string;
	// SectionOptions
	@SetComplexType(SectionOptions)
	sectionOptions: SectionOptions;

	constructor() {
		this.pageNumberOptions = new PageNumberOptions();
		this.securityOptions = new SecurityOptions();
		this.titlePageOptions = new TitlePageOptions();
		this.contentsPageOptions = new ContentsPageOptions();
		this.sectionOptions = new SectionOptions();
	}
}