import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

import { ContactFilterComponent } from 'app/shared/components/contact-filter.component';
import { FilterButtonComponent } from 'app/shared/filter-controls/filter-button.component';

import { FilterBaseComponent } from './filter-base.component';
import { get } from 'lodash-es';
import { ContactLookupEntityType } from '@common/models/Contacts/Lookup/ContactLookupEntityType';

@Component({
	selector: 'filter-contact-button',
	template: `
		<filter-button [root]="root" [name]="name" [showTitleAsPrefix]="showTitleAsPrefix" [label]="label">
			<contact-filter
				Placeholder="Find Contact"
				[ShowStaffMembersOnly]="ShowStaffMembersOnly"
				[ShowMe]="ShowStaffMembersOnly"
				[ShowUnassigned]="ShowUnassigned"
				[entityType]="entityType"
				[FreeTextFormControl]="freeTextFormControl"
				[FormControl]="btn.formControl"
				(Selected)="btn.entitySelected($event)"
				[hideClear]="hideClear"
			>
			</contact-filter>
		</filter-button>
	`
})
export class FilterContactButtonComponent extends FilterBaseComponent implements OnInit, OnDestroy {
	@Input() ShowStaffMembersOnly: boolean;
	@Input() ShowUnassigned: boolean;
	@Input() entityType: ContactLookupEntityType;
	@Input() freeTextName: string;

	freeTextFormControl: FormControl;

	@ViewChild(FilterButtonComponent, { static: true }) btn: FilterButtonComponent;
	@ViewChild(ContactFilterComponent, { static: true }) contact: ContactFilterComponent;

	private subscription = new Subscription();

	ngOnInit(): void {
		if (!!this.root) {
			if (!!this.freeTextName) {
				const form = get(this.root, 'form');
				if (form) {
					this.freeTextFormControl = form.get(this.freeTextName) as FormControl;
				}
			}

			// Handle a corner-case, when we clear the filtering condition
			// via emptying the corresponding FormControls without updating the page.
			// We still need to be careful if the same filter can be modified from two or more controls on the page
			if (!!this.name) {
				this.subscription = this.root.filterChange
					.pipe(
						distinctUntilChanged(),
						filter(() => !(this.root.form.get(this.name) && this.root.form.get(this.name).value))
					)
					.subscribe(() => {
						this.btn.selectedFilterTitle = '';
						this.btn.IsSelected = false;
						this.contact.SelectedValue = null;
					});
			}
		}
	}
	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
