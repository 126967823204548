import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ShareLinkExpiryDto } from '@common/models/Settings/Setting/Item/ShareLinkExpiryDto';
import { NotificationService } from '@common/notification';
import { GeneralSettingsService } from '@common/services/settings/generalsettings.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'access-tokens',
	styleUrls: ['./access-tokens.component.scss'],
	templateUrl: './access-tokens.component.html'
})
export class AccessTokensComponent implements OnInit, OnDestroy {
	private _form: FormGroupTyped<ShareLinkExpiryDto>;
	private _subscriptions = new Subscription();

	get isFormValid() {
		return !!this._form.invalid || !!this._form.disabled || !!this._form.pristine;
	}

	constructor(
		private _formBuilder: FormBuilder,
		private _generalSettingsService: GeneralSettingsService,
		private _notificationService: NotificationService
	) {}

	ngOnInit(): void {
		this._form = this._formBuilder.group({
			defaultExpiryInDays: null
		}) as FormGroupTyped<ShareLinkExpiryDto>;

		this._subscriptions.add(
			this._generalSettingsService.getShareLinkExpiry().subscribe(dto => this._form.patchValue(dto))
		);
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}

	getControl(key: string): AbstractControl {
		return this._form.get(key);
	}

	saveChanges(): void {
		const dto = this._form.value;

		this._subscriptions.add(
			this._generalSettingsService.setShareLinkExpiry(dto).subscribe(
				() => {
					this._notificationService.showNotification('Saved share link config');
					this._form.markAsPristine();
				},
				errors => this._notificationService.showErrors('Error saving share link config', errors)
			)
		);
	}
}
