import { Component, Inject, Optional, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelect, MatSelectChange } from '@angular/material/select';

import { map, switchMap, tap } from 'rxjs/operators';

import { EnumSortDirection } from '@common/models/Common/EnumSortDirection';
import { CustomFieldEntityType } from '@common/models/Settings/CustomFields/Common/CustomFieldEntityType';
import { CustomFieldType } from '@common/models/Settings/CustomFields/Common/CustomFieldType';
import { MatterCustomFieldCreateDto } from '@common/models/Settings/CustomFields/Item/MatterCustomFieldCreateDto';
import { MatterCustomFieldViewDto } from '@common/models/Settings/CustomFields/Item/MatterCustomFieldViewDto';
import { PracticeAreaListItemDto } from '@common/models/Settings/PracticeAreas/List/PracticeAreaListItemDto';
import { NotificationService } from '@common/notification';
import { CustomFieldsGroupService } from '@common/services/customfields-group.service';
import { MatterCustomFieldsService } from '@common/services/customfields-matter.service';
import { PracticeAreasService } from '@common/services/settings/practiceareas.service';
import { CustomValidators } from '@common/validation/custom.validators';

import { FeatureFlags, isFeatureFlagEnabled } from 'app/app.config';
import { BaseCustomFieldDialogComponent, ICreateCustomFieldData } from 'app/system/custom-fields/base-custom-field-dialog.component';

@Component({
	selector: 'matter-custom-field-dialog',
	styleUrls: ['../../base-custom-field-dialog.component.scss', './matter-custom-field-dialog.component.scss'],
	templateUrl: './matter-custom-field-dialog.component.html'
})
export class MatterCustomFieldDialogComponent extends BaseCustomFieldDialogComponent<
	MatterCustomFieldViewDto,
	MatterCustomFieldCreateDto,
	MatterCustomFieldDialogComponent
> {
	@ViewChild('selectPracticeAreas', { static: true })
	selectPracticeAreas: MatSelect;

	practiceAreas: PracticeAreaListItemDto[];

	get canShowReportField() {
		return !!isFeatureFlagEnabled(FeatureFlags.reportCustomFields);
	}

	constructor(
		@Optional()
		@Inject(MAT_DIALOG_DATA)
		public data: ICreateCustomFieldData,
		private fb: FormBuilder,
		private service: MatterCustomFieldsService,
		private practiceAreaService: PracticeAreasService,
		notificationService: NotificationService,
		groupsService: CustomFieldsGroupService,
		dialog: MatDialog,
		dialogRef: MatDialogRef<MatterCustomFieldDialogComponent>
	) {
		super(data, CustomFieldEntityType.Matter, notificationService, groupsService, dialogRef, dialog, id =>
			this.service.getMatterCustomField(id).pipe(
				switchMap(field => {
					return this.practiceAreaService
						.getPracticeAreaList({
							sortBy: 'name',
							sortDirection: EnumSortDirection.Asc,
							showOnlyMyAreas: true,
							showDisabled: true
						})
						.pipe(
							tap(next => (this.practiceAreas = next.records)),
							map(() => field)
						);
				})
			)
		);
	}

	ngOnInit() {
		this.form = this.fb.group({
			enabled: true,
			groupIds: [
				this.data.selectedGroupId ? [this.data.selectedGroupId] : null,
				CustomValidators.nonEmptyArray()
			],
			fieldType: [null, CustomValidators.required('Field Type')],
			mandatory: false,
			name: [
				null,
				[CustomValidators.required('Name'), CustomValidators.pattern(new RegExp('^[a-zA-Z][a-zA-Z0-9- ]*$'))]
			],
			description: null,
			helpText: null,
			expression: [null, CustomValidators.requiredWhen(() => !!this.showExpression, 'Expression')],
			practiceAreaIds: null,
			showInReports: false,
			defaultValue: [
				null,
				CustomValidators.validateWhen(
					() => !!this.selectionListArray.length,
					() => {
						const value = this.form.get('defaultValue')?.value;
						return !value?.length || this.selectionListArray.includes(value as string);
					},
					'Default Value must match what is contained within the contact selection list'
				)
			],
			maskingText: [null, CustomValidators.pattern(new RegExp('^(?=#*)[\\s#]+$'))]
		});

		if (!!this.data.id) {
		} else {
			this.subscriptions.add(
				this.practiceAreaService
					.getPracticeAreaList({
						sortBy: 'name',
						sortDirection: EnumSortDirection.Asc,
						showOnlyMyAreas: true
					})
					.subscribe(next => (this.practiceAreas = next.records))
			);
		}

		super.ngOnInit();
	}

	displaySelected(values: string[]) {
		if (!values?.length) {
			return 'All';
		}

		const results = this.practiceAreas
			.filter(area => !!values.filter(id => id === area.id)?.length)
			.map(area => area.name);

		if (!results?.length) {
			return 'All';
		}

		if (results.length == 1) {
			return results[0];
		}

		return results.reduce((left, right) => `${left}, ${right}`);
	}

	allOptionSelected(event: MatOptionSelectionChange, selectedOption: MatSelect) {
		super.allOptionSelected(event, selectedOption);
	}

	optionSelected(event: MatOptionSelectionChange, selectedOption: MatSelect) {
		super.optionSelected(event, selectedOption);
	}

	practiceAreaSelectionChanged(event: MatSelectChange) {
		this.form.value.practiceAreaIds = event.value;
	}

	protected saveChanges(value: MatterCustomFieldCreateDto) {
		if (!!value.practiceAreaIds?.length) {
			value.practiceAreaIds = value.practiceAreaIds.filter(id => !!id);
		}

		if (value.fieldType === CustomFieldType.Paragraph || value.fieldType === CustomFieldType.Calculated) {
			value.showInReports = false;
		}

		super.saveChanges(value);
	}

	inputChanged(event: any) {
		const inputText = event.target.value;
		this.form.value.maskingText = inputText;
	}

	autocompleteSelected(event: MatAutocompleteSelectedEvent): void {
		this.form.value.maskingText = event.option.value;
	}
}
