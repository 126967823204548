import { Injectable } from '@angular/core';

import { interval, of } from 'rxjs';
import { catchError, debounce, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { CostRecordsService } from '@common/services/costrecords.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash-es';

import { IAppState } from '../../app.state';
import { CostListActions, loading } from './cost-list.actions';

@Injectable()
export class CostListEffects {
	loadCosts$ = createEffect(() =>
		this._actions$.pipe(
			ofType(
				CostListActions.Load,
				CostListActions.SetPageIndex,
				CostListActions.SetPageSize,
				CostListActions.SetSortBy,
				CostListActions.SetSortDirection,
				CostListActions.SetFilters
			),
			withLatestFrom(this._store),
			filter(([_, state]) => !isEqual(state.costList.request, state.costList.oldRequest)),
			tap(() => this._store.dispatch(loading())),
			debounce(() => interval(200)),
			switchMap(([_, state]) => {
				return this._costRecordsService.getCostRecordList(state.costList.request).pipe(
					map(response => ({ type: CostListActions.LoadSuccess, response })),
					catchError(error => of({ type: CostListActions.LoadFailed, error }))
				);
			})
		)
	);

	constructor(
		private _actions$: Actions,
		private _store: Store<IAppState>,
		private _costRecordsService: CostRecordsService
	) {}
}
