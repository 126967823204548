import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { ActivityLogListItemDto } from '@common/models/ActivityLogs/List/ActivityLogListItemDto';
import { ActivityLogListRequest } from '@common/models/ActivityLogs/List/ActivityLogListRequest';
import { DocumentReference } from '@common/models/Documents/PersistenceLayer/DocumentReference';
import { ImportReference } from '@common/models/Imports/Common/ImportReference';
import { NotificationService } from '@common/notification';
import { ActivityLogService } from '@common/services/activitylog.service';
import { getFileIcon } from '@common/utils/file-extensions';

import { GridViewService } from 'app/services/grid-view.service';
import { getEntityType } from 'app/shared/entity-type/entity-type';
import { GenericListComponent } from 'app/shared/generics/generic.list.component';

@Component({
	selector: 'activity-log-list',
	styleUrls: ['./activity-log-list.component.scss'],
	templateUrl: 'activity-log-list.component.html'
})
export class ActivityLogListComponent extends GenericListComponent<ActivityLogListItemDto, ActivityLogListRequest> {
	request: Partial<ActivityLogListRequest> = {
		relatedToType: null,
		search: null,
		timestampEnd: null,
		timestampStart: null
	};

	constructor(
		dialog: MatDialog,
		router: Router,
		activatedRoute: ActivatedRoute,
		service: ActivityLogService,
		private notificationService: NotificationService,
		gridViewService: GridViewService
	) {
		super(
			dialog,
			router,
			activatedRoute,
			service,
			['timestamp', 'relatedTo_Name', 'action', 'user_Name'],
			gridViewService
		);
	}

	getIconFromType(entityType: string): string {
		const et = getEntityType(entityType);
		return !!et ? et.icon : null;
	}

	getIconFromExtension(item: ActivityLogListItemDto) {
		const docRef = item.relatedTo as DocumentReference;
		return getFileIcon(docRef.fileExtension);
	}

	navigateRelatedTo(row: ActivityLogListItemDto) {
		if (row.operation === 'Deleted') {
			this.notificationService.showError(
				`${row.relatedToType.replace(/[s/]$/, '')} does not exist`,
				`Unable to view the ${row.relatedToType.replace(/[s/]$/, '')} as it has been deleted.`
			);
		} else {
			const url = this.relatedToLink(row);
			const params = this.relatedToParams(row);
			if (!!params) {
				this.router.navigate(url, { queryParams: params });
			} else {
				this.router.navigate(url);
			}
		}
	}

	relatedToLink(item: ActivityLogListItemDto): string[] {
		const result = ['/'];
		if (item.relatedToType === 'MatterCustomField' || item.relatedToType === 'ContactCustomField') {
			result.push('system');
			result.push(getEntityType(item.relatedToType).link.toLowerCase());
		} else if (item.relatedToType === 'Users') {
			result.push('system');
			result.push(getEntityType(item.relatedToType).link.toLowerCase());
			result.push(item.relatedTo.id);
		} else if (item.relatedToType === 'DocumentTemplate') {
			result.push('system');
			result.push('document-templates');
		} else if ((item.relatedToType === 'Documents' || item.relatedToType === 'Tasks') && item.actionReferenceType) {
			result.push(item.actionReferenceType.toLowerCase());
			result.push(item.actionReference.id);
			result.push(item.relatedToType.toLowerCase());
		} else if (item.relatedToType === 'Documents') {
			result.push(item.relatedToType.toLowerCase());
		} else if (item.relatedToType === 'Trust') {
			result.push(item.relatedToType.toLowerCase());
		} else if (item.relatedToType === 'Imports') {
			const importRef: ImportReference = item.relatedTo as ImportReference;
			result.push('system');
			result.push('import');
			result.push(importRef ? importRef.type.toLowerCase() : '');
		} else if (item.relatedToType === 'MarketplaceTenantPackages') {
			result.push('system');
			result.push('marketplace');
		} else {
			result.push(getEntityType(item.relatedToType).link.toLowerCase());
			if (item.relatedTo) {
				result.push(item.relatedTo.id);
			}
		}

		return result;
	}

	relatedToParams(item: ActivityLogListItemDto): any | undefined {
		if (
			item.relatedToType === 'Documents' ||
			item.relatedToType === 'Tasks' ||
			item.relatedToType === 'DocumentTemplate' ||
			item.relatedToType === 'Trust' ||
			item.relatedToType === 'Imports'
		) {
			return { pageIndexForId: item.relatedTo.id };
		}
	}
}
