import { NgModule } from '@angular/core';

import { MomentPipe } from './moment.pipe';
import { PricingMethodPipe } from './pricing-method.pipe';
import { TaskPriorityPipe } from './task-priority.pipe';

@NgModule({
	declarations: [MomentPipe, TaskPriorityPipe, PricingMethodPipe],
	exports: [MomentPipe, TaskPriorityPipe, PricingMethodPipe],
	providers: [MomentPipe]
})
export class SharedPipesModule {}
