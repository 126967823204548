import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as moment from 'moment-timezone';

import { ExternalPortalRouteService } from '../../../main/src/app/services/external-portal-route-service';
import { JobsService } from '../../services/settings/jobs.service';
import { JobActions } from '../actions/jobs.actions';

@Injectable()
export class JobsEffects {
	loadJobs$ = createEffect(() =>
		this.actions$.pipe(
			ofType(JobActions.LoadList),
			mergeMap(() =>
				this.portalRouteService.isUserRoute
					? this.jobsService
							.getJobList({
								myJobs: true,
								lastUpdateFrom: moment().subtract(7, 'days').startOf('day'),
								sortBy: 'LastUpdateUtc',
								sortDirection: 'Desc',
								pageSize: 5
							})
							.pipe(
								map(list => ({ type: JobActions.ListLoadedSuccess, list })),
								catchError(() => of({ type: JobActions.ListLoadedError }))
							)
					: of({ type: JobActions.ListLoadedSuccess, list: null })
			)
		)
	);

	constructor(
		private actions$: Actions,
		private jobsService: JobsService,
		private portalRouteService: ExternalPortalRouteService
	) {}
}
