import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { DocumentCategoryService } from '@common/services/settings/documentcategory.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash-es';
import moment from 'moment';

import { IAppState } from '../../app.state';
import { DocumentCategoryFilterActions, loading } from './document-category-filter.actions';

@Injectable()
export class DocumentCategoryFilterEffects {
	loadDocumentCategories$ = createEffect(() =>
		this._actions$.pipe(
			ofType(
				DocumentCategoryFilterActions.Load,
				DocumentCategoryFilterActions.SetFilters,
				DocumentCategoryFilterActions.InvalidateCache
			),
			map(action => action as { type: DocumentCategoryFilterActions }),
			withLatestFrom(this._store),
			filter(([action, state]) => {
				const documentCategoryState = state.documentCategoryFilterData;

				if (action.type === DocumentCategoryFilterActions.InvalidateCache && !documentCategoryState.list) {
					return false;
				}

				return (
					!documentCategoryState.cacheExpiry ||
					!!moment().isSameOrAfter(documentCategoryState.cacheExpiry) ||
					!isEqual(documentCategoryState.oldRequest, documentCategoryState.request)
				);
			}),
			debounceTime(500),
			tap(() => this._store.dispatch(loading())),
			switchMap(([_, state]) =>
				this._documentCategoryService.getDocumentCategoryList(state.documentCategoryFilterData.request).pipe(
					map(response => ({
						type: DocumentCategoryFilterActions.LoadSuccess,
						response
					})),
					catchError(error => of({ type: DocumentCategoryFilterActions.LoadFailed, error }))
				)
			)
		)
	);

	constructor(
		private _actions$: Actions,
		private _store: Store<IAppState>,
		private _documentCategoryService: DocumentCategoryService
	) {}
}
