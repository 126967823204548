// This file has been generated from AccessTokenStatus.cs
// Do not modify this file directly!!

// LawMaster.Amalthea.Domain.Settings.AccessTokens.Common.AccessTokenStatus
export enum AccessTokenStatus {
	None = 'None',
	Revoked = 'Revoked',
	Expired = 'Expired',
	RevokedAndExpired = 'RevokedAndExpired',
	Active = 'Active'
}
