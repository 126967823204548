import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { UserListRequest } from '@common/models/Users/List/UserListRequest';

import { FilterRootComponent } from 'app/shared/filter-controls';
import { FilterChangeProperties } from 'app/shared/filter-controls/filter-change-properties';

@Component({
	selector: 'user-list-filter',
	styleUrls: ['./user-list-filter.component.scss'],
	templateUrl: './user-list-filter.component.html'
})
export class UserListFilterComponent {
	@ViewChild(FilterRootComponent, { static: true })
	root: FilterRootComponent<UserListRequest>;

	@Input()
	request: Partial<UserListRequest>;
	@Output()
	filterChange = new EventEmitter<Partial<UserListRequest>>();

	listRequestType: new () => UserListRequest = UserListRequest;

	rootFilterChange($event: FilterChangeProperties<Partial<UserListRequest>>) {
		if (!!$event) this.filterChange.emit($event.filter);
	}
}
