import { Injectable } from '@angular/core';
import { CommentAssociatedEntityType } from '@common/models/Comments/Common/CommentAssociatedEntityType';
import { CommentListItemDto } from '@common/models/Comments/List/CommentListItemDto';
import { CustomFieldEntityType } from '@common/models/Settings/CustomFields/Common/CustomFieldEntityType';
import { JobType } from '@common/models/Settings/Jobs/Common/JobType';
import { JobViewDto } from '@common/models/Settings/Jobs/Item/JobViewDto';
import { Features } from '@common/models/Settings/Modules/Features';
import { TenantFeatureStateDto } from '@common/models/Settings/Setting/Item/TenantFeatureStateDto';
import { deleteJob, updateJob } from '@common/state/actions/jobs.actions';
import { updateTenantFeatures } from '@common/state/actions/tenant.actions';
import { IJobsData } from '@common/state/models/jobs-data';
import { sleep } from '@common/utils/promise-utils';
import * as signalR from '@microsoft/signalr';
import { IHttpConnectionOptions, IRetryPolicy } from '@microsoft/signalr';
import { Store } from '@ngrx/store';
import { AppConfig, FeatureFlags, isFeatureFlagEnabled } from 'app/app.config';
import * as moment from 'moment-timezone';
import { firstValueFrom, Observable, Subject } from 'rxjs';
import { AuthService } from '../auth.service';
import { updateRegionCode } from '../state/misc/tenant-company/tenant-company.actions';
import { MatterTimerConfigDto } from '@common/models/Settings/Setting/Item/MatterTimerConfigDto';

@Injectable({
	providedIn: 'root'
})
export class SignalrService {
	constructor(private store: Store<{ jobsData: IJobsData }>, private authService: AuthService) {}

	private _subscriptionUpdatedSubject = new Subject<void>();

	private _newCommentSubject = new Subject<{
		entityId: string;
		entityType: keyof typeof CommentAssociatedEntityType;
		dto: CommentListItemDto;
	}>();

	private _updateDocumentLockStatus = new Subject<{
		documentId: string;
		lockExpires: moment.Moment;
		lockedBy: string;
	}>();

	private _calculationVariablesChanged = new Subject<{
		entityTypes: (keyof typeof CustomFieldEntityType)[];
	}>();

	private _costCodesChanged = new Subject<void>();
	private _documentCategoriesChanged = new Subject<void>();
	private _practiceAreasChanged = new Subject<void>();
	private _trustAccountsChanged = new Subject<void>();

	private _emailDocumentAvailabilityChanged = new Subject<{ canEmail: boolean }>();

	private _timerConfigChanged = new Subject<MatterTimerConfigDto>();
	private _onConnectedSubject = new Subject<void>();

	private _isConnected: boolean;
	private _isClosed: boolean = true;

	get isConnected() {
		return !!this._isConnected;
	}

	get isClosed() {
		return !!this._isClosed;
	}

	init(): void {
		this.connect();
	}

	async connect() {
		if (!this._isClosed) {
			return;
		}

		const hubConnection = new signalR.HubConnectionBuilder()
			.configureLogging(signalR.LogLevel.Information)
			.withUrl(`${AppConfig.AppServerUrl}/notify`, this.connectionOptions)
			.withAutomaticReconnect(this.retryPolicy)
			.build();

		hubConnection.onreconnecting(() => {
			this._isConnected = false;
			console.log('Reconnecting to SignalR');
		});

		hubConnection.onreconnected(() => {
			this._isConnected = true;
			this._onConnectedSubject.next();
			console.log('Reconnected to SignalR');
		});

		hubConnection.onclose(() => {
			this._isConnected = false;
			this._isClosed = true;
			console.log('Disconnected from SignalR');
			this.connect();
		});

		this._isConnected = false;
		while (!this._isConnected) {
			try {
				console.log('Attempting to connect to SignalR');
				await hubConnection.start();
				console.log('Connected to SignalR');
				this._isConnected = true;
				this._isClosed = false;
				this._onConnectedSubject.next();
			} catch (error) {
				console.error(`Failed to connect to SignalR: ${error.toString()}`);
				await sleep(20 * 1000); // 20 Seconds
			}
		}

		// Register SignalR Events
		this.registerJobEvents(hubConnection);
		this.registerCommentEvents(hubConnection);
		this.registerSubscriptionEvents(hubConnection);
		this.registerGeneralSettingsEvents(hubConnection);
		this.registerDocumentEvents(hubConnection);
		this.registerContactEvents(hubConnection);
		this.registerTimerEvents(hubConnection);
		this.registerCalculationVariablesEvents(hubConnection);
		this.registerFilterEvents(hubConnection);
	}

	onConnected(): Observable<void> {
		return this._onConnectedSubject.asObservable();
	}

	onSubscriptionUpdated(): Observable<void> {
		return this._subscriptionUpdatedSubject.asObservable();
	}

	onNewComment(): Observable<{
		entityId: string;
		entityType: keyof typeof CommentAssociatedEntityType;
		dto: CommentListItemDto;
	}> {
		return this._newCommentSubject.asObservable();
	}

	onDocumentLockStatusChanged(): Observable<{
		documentId: string;
		lockExpires: moment.Moment;
		lockedBy: string;
	}> {
		return this._updateDocumentLockStatus.asObservable();
	}

	onEmailDocumentAvailabilityChanged(): Observable<{ canEmail: boolean }> {
		return this._emailDocumentAvailabilityChanged.asObservable();
	}

	onTimerConfigChanged(): Observable<MatterTimerConfigDto> {
		return this._timerConfigChanged.asObservable();
	}

	onCalculationVariablesChanged(): Observable<{ entityTypes: (keyof typeof CustomFieldEntityType)[] }> {
		return this._calculationVariablesChanged.asObservable();
	}

	onCostCodesChanged(): Observable<void> {
		return this._costCodesChanged.asObservable();
	}

	onDocumentCategoriesChanged(): Observable<void> {
		return this._documentCategoriesChanged.asObservable();
	}

	onPracticeAreasChanged(): Observable<void> {
		return this._practiceAreasChanged.asObservable();
	}

	onTrustAccountsChanged(): Observable<void> {
		return this._trustAccountsChanged.asObservable();
	}

	private registerJobEvents(hubConnection: signalR.HubConnection) {
		if (!!isFeatureFlagEnabled(FeatureFlags.mergePdf)) {
			hubConnection.on('DeleteJob', (id: string) => {
				this.store.dispatch(deleteJob({ id }));
			});

			hubConnection.on('UpdateJob', (job: JobViewDto) => {
				// SignalR sends enums as a number, this maps it back to an enum
				job.type = Object.keys(JobType)[job.type as any] as keyof typeof JobType;

				console.log(`UpdateJob: ${job.id}`);
				this.store.dispatch(updateJob({ job }));
			});
		}
	}

	private registerSubscriptionEvents(hubConnection: signalR.HubConnection) {
		hubConnection.on('SubscriptionUpdated', () => {
			console.log(`SubscriptionUpdated`);
			this._subscriptionUpdatedSubject.next();
		});
	}

	private registerCommentEvents(hubConnection: signalR.HubConnection) {
		hubConnection.on('NewComment', (entityId: string, entityType: number, dto: CommentListItemDto) => {
			const entityTypeEnum: keyof typeof CommentAssociatedEntityType = Object.keys(CommentAssociatedEntityType)[
				entityType
			] as keyof typeof CommentAssociatedEntityType;

			console.log(`NewComment: ${entityId}, ${entityTypeEnum}`);

			this._newCommentSubject.next({ entityId, entityType: entityTypeEnum, dto });
		});
	}

	private registerGeneralSettingsEvents(hubConnection: signalR.HubConnection) {
		hubConnection.on('UpdateFeatureStates', (featureStates: TenantFeatureStateDto[]) => {
			if (!!featureStates?.length) {
				const moduleTypeKeys = Object.keys(Features);

				// Fix Enum
				featureStates.forEach(featureState => {
					featureState.type = moduleTypeKeys[featureState.type as any as number] as keyof typeof Features;
				});

				this.store.dispatch(updateTenantFeatures({ featureStates }));
			}
		});
	}

	private registerContactEvents(hubConnection: signalR.HubConnection) {
		hubConnection.on('RegionCodeCompanyChanged', (regionCode: string) => {
			this.store.dispatch(updateRegionCode({ regionCode }));
		});
	}

	private registerDocumentEvents(hubConnection: signalR.HubConnection) {
		hubConnection.on('UpdateDocumentLockStatus', (id: string, lockExpires: string, lockedBy: string) => {
			this._updateDocumentLockStatus.next({ documentId: id, lockExpires: moment(lockExpires), lockedBy });
		});

		hubConnection.on('EmailDocumentAvailabilityChanged', (canEmail: boolean) => {
			this._emailDocumentAvailabilityChanged.next({ canEmail });
		});
	}

	private registerTimerEvents(hubConnection: signalR.HubConnection) {
		hubConnection.on('TimerConfigChanged', (dto: MatterTimerConfigDto) => {
			this._timerConfigChanged.next({ ...dto });
		});
	}

	private registerCalculationVariablesEvents(hubConnection: signalR.HubConnection) {
		hubConnection.on('CalculationVariablesChanged', (entityTypes: string[]) => {
			const keys = Object.keys(CustomFieldEntityType)
				.map(key => key as keyof typeof CustomFieldEntityType)
				.filter(key => !!entityTypes?.includes(key));

			this._calculationVariablesChanged.next({ entityTypes: keys });
		});
	}

	private registerFilterEvents(hubConnection: signalR.HubConnection) {
		hubConnection.on('CostCodesChanged', () => this._costCodesChanged.next());
		hubConnection.on('DocumentCategoriesChanged', () => this._documentCategoriesChanged.next());
		hubConnection.on('PracticeAreasChanged', () => this._practiceAreasChanged.next());
		hubConnection.on('TrustAccountsChanged', () => this._trustAccountsChanged.next());
	}

	private connectionOptions: IHttpConnectionOptions = {
		accessTokenFactory: () => this.getAuthTokenAsync()
	};

	private retryPolicy: IRetryPolicy = {
		nextRetryDelayInMilliseconds: (retryContext: signalR.RetryContext) => {
			// {retryContext.elapsedMilliseconds} is the amount of time that has elapsed since the disconnect occured.
			// By waiting the already elapsed time we can step up the next attempt time exponentially.
			// We add a random time on top of this to avoid literally all clients making reconnect calls at the exact same time.
			// As each subsequent connection attempt is made, the client's reconnect attempts will spread further apart.
			const timeToWait = retryContext.elapsedMilliseconds + Math.floor(Math.random() * 5000);
			return Math.min(timeToWait, 20 * 1000); // max 20 seconds
		}
	};

	private async getAuthTokenAsync(): Promise<string> {
		// Note that this method returns the token, not the header with the token
		// In other words this is the access token without the "Bearer " prefix
		const header = this.authService.accessToken;
		if (!header) {
			firstValueFrom(this.authService.SilentRefresh(false));
			return this.authService.accessToken;
		}
		return header;
	}
}
