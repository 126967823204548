// This file has been generated from DocumentEmailTemplateCreateUpdateDto.cs
// Do not modify this file directly!!
// tslint:disable

import { TemplateEntityType } from '../TemplateDto/TemplateEntityType';

// LawMaster.Amalthea.Domain.Documents.Item.DocumentEmailTemplateCreateUpdateDto
export class DocumentEmailTemplateCreateUpdateDto {

	// Title
	title: string;
	// Subject
	subject: string;
	// Content
	content: string;
	// AssociatedPracticeAreaIds
	associatedPracticeAreaIds: string[];
	// EntityType
	entityType: keyof typeof TemplateEntityType;
	// DocumentCategoryId
	documentCategoryId: string;
	// DocumentTags
	documentTags: string[];
	// DocumentTemplateIds
	documentTemplateIds: string[];
	// Attachments
	attachments: File[];
	// DocumentIdsToRemove
	documentIdsToRemove: string[];

	constructor() {

	}
}