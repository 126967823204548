// This file has been generated from DocumentBriefsController.cs
// Do not modify this file directly!!
// tslint:disable

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { TrimStringsOnObject } from '@common/utils/string-format';

import { MutationResponseDto } from '../models/Common/MutationResponseDto';
import { DocumentBriefCreateUpdateDto } from '../models/DocumentBriefs/Item/DocumentBriefCreateUpdateDto';
import { DocumentBriefViewDto } from '../models/DocumentBriefs/Item/DocumentBriefViewDto';
import { ExportBriefParametersDto } from '../models/DocumentBriefs/Item/ExportBriefParametersDto';
import { ExportPDFDto } from '../models/DocumentBriefs/Item/ExportPDFDto';
import { MoveSectionDto } from '../models/DocumentBriefs/Item/MoveSectionDto';
import { DocumentBriefListItemDto } from '../models/DocumentBriefs/List/DocumentBriefListItemDto';
import { DocumentBriefListRequest } from '../models/DocumentBriefs/List/DocumentBriefListRequest';
import { DocumentBriefLookupDto } from '../models/DocumentBriefs/Lookup/DocumentBriefLookupDto';
import { DocumentBriefLookupRequest } from '../models/DocumentBriefs/Lookup/DocumentBriefLookupRequest';
import { CreateUpdateBriefResponse } from '../models/Documents/Common/CreateUpdateBriefResponse';
import { ListResponse } from '../models/Generic/ListResponse';

@Injectable({
	providedIn: 'root'
})
export class DocumentBriefsService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'api/v1/DocumentBriefs', 'Document Brief');
	}

	getDocumentBrief(id: string): Observable<DocumentBriefViewDto> {
		return this.getItem<void, DocumentBriefViewDto>(`${id}`);
	}

	getDocumentBriefList(request?: Partial<DocumentBriefListRequest>): Observable<ListResponse<DocumentBriefListItemDto>> {
		return this.getList<DocumentBriefListRequest, DocumentBriefListItemDto>('', request);
	}

	lookupDocumentBriefList(request: DocumentBriefLookupRequest): Observable<DocumentBriefLookupDto[]> {
		return this.getArray<DocumentBriefLookupRequest, DocumentBriefLookupDto>(`lookup`, request);
	}

	lookupDocumentBrief(id: string): Observable<DocumentBriefLookupDto> {
		return this.getItem<void, DocumentBriefLookupDto>(`${id}/lookup`);
	}

	createDocumentBrief(newDocumentBrief: DocumentBriefCreateUpdateDto): Observable<CreateUpdateBriefResponse> {
		return this.post<DocumentBriefCreateUpdateDto, CreateUpdateBriefResponse>('', TransformDatesOnObject(DocumentBriefCreateUpdateDto, TrimStringsOnObject(newDocumentBrief)));
	}

	updateDocumentBrief(id: string, documentBrief: DocumentBriefCreateUpdateDto): Observable<CreateUpdateBriefResponse> {
		return this.put(`${id}`, TransformDatesOnObject(DocumentBriefCreateUpdateDto, TrimStringsOnObject(documentBrief)));
	}

	deleteDocumentBrief(id: string): Observable<any> {
		return this.delete(`${id}`);
	}

	moveDocumentsToSection(dto: MoveSectionDto): Observable<MutationResponseDto[]> {
		return this.post<MoveSectionDto, MutationResponseDto[]>(`moveDocumentsToSection`, TransformDatesOnObject(MoveSectionDto, TrimStringsOnObject(dto)));
	}

	exportToPDF(id: string, dto: ExportPDFDto): Observable<any> {
		return this.post<ExportPDFDto, any>(`exportToPDF/${id}`, TransformDatesOnObject(ExportPDFDto, TrimStringsOnObject(dto)));
	}

	getExportBriefParameters(id: string): Observable<ExportBriefParametersDto> {
		return this.getItem<void, ExportBriefParametersDto>(`exportParameters/${id}`);
	}

}
