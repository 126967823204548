import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { LocalStorageService } from '@common/services/storage/local-storage.service';
import { StripeService } from '@common/services/stripe.service';

import { AppConfig } from 'app/app.config';

const countries = require('app/shared/editable-controls/countries.json');

@Component({
	selector: 'subscription-success',
	styleUrls: ['./subscription-success-dialog.component.scss'],
	templateUrl: 'subscription-success-dialog.component.html'
})
export class SubscriptionSuccessDialogComponent implements OnInit, OnDestroy {
	private subscriptions = new Subscription();
	sessionId: string;

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private stripeService: StripeService,
		private localStorageService: LocalStorageService
	) {}

	ngOnInit(): void {
		this.subscriptions.add(
			this.activatedRoute.queryParams
				.pipe(
					filter(Boolean),
					switchMap((params: Params) => {
						this.sessionId = params['session_id'];
						this.localStorageService.removeItem(AppConfig.promoCodeLocalStorageKey);
						return this.stripeService.pushOrderToHubspot();
					})
				)
				.subscribe()
		);

		this.router.navigate([], {
			relativeTo: this.activatedRoute,
			queryParams: {},
			queryParamsHandling: 'merge' // remove to replace all query params by provided
		});
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	close() {
		this.router.navigate([], {
			queryParams: {
				session_id: null,
				success: null
			},
			queryParamsHandling: 'merge'
		});
	}
}
