import { ListRequest } from '@common/models/Generic/ListRequest';
import { ListResponse } from '@common/models/Generic/ListResponse';
import { DocumentCategoryListItemDto } from '@common/models/Settings/DocumentCategories/List/DocumentCategoryListItemDto';
import { DomainError } from '@common/models/Validation/DomainError';
import { Moment } from 'moment';

export interface IDocumentCategoryFilterState {
	isFetching: boolean;
	list: ListResponse<DocumentCategoryListItemDto>;

	request: Partial<ListRequest>;
	oldRequest: Partial<ListRequest>;

	cacheExpiry: Moment;
	// errrors
	error: DomainError;
}

export const initialState: IDocumentCategoryFilterState = {
	isFetching: false,
	list: null,
	request: {},
	oldRequest: null,
	error: null,
	cacheExpiry: null
};
