import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { from, of, Subscription } from 'rxjs';
import { concatMap, filter, switchMap, take, tap } from 'rxjs/operators';

import { EmailAttachmentDto } from '@common/models/Documents/Item/EmailAttachmentDto';
import { EmailPreviewDto } from '@common/models/Documents/Item/EmailPreviewDto';
import { getFileIcon } from '@common/utils/file-extensions';

import { DocumentsService } from 'app/services/documents.service';
import { isEmptyOrSpaces } from 'app/shared/utils/stringUtil';

@Component({
	selector: 'email-preview',
	styleUrls: ['./email-preview.component.scss'],
	templateUrl: './email-preview.component.html'
})
export class EmailPreviewComponent implements OnDestroy {
	showViewer: boolean;
	exceededLimit: boolean;

	get maxBytesAllowed() {
		return 52428800;
	}

	private _documentId: string;

	@Input()
	set documentId(value: string) {
		if (value !== this._documentId) {
			this.emailPreviewDto = null;
			this.safeHtmlBody = null;

			this.showViewer = false;
			this.exceededLimit = false;

			if (!!value) {
				this.subscriptions.add(
					this.getEmailPreviewDto$(value)
						.pipe(
							tap(dto => (this.exceededLimit = dto.hasExceededFileLimit)),
							filter(dto => !dto.hasExceededFileLimit),
							switchMap(() => this.getEmailPreviewData$(value))
						)
						.subscribe(() => (this.showViewer = true))
				);
			}

			this._documentId = value;
		}
	}
	@Output()
	emailAttachmentPreviewClicked = new EventEmitter<EmailAttachmentDto>();
	@Output()
	emailAttachmentDownloadClicked = new EventEmitter<EmailAttachmentDto>();

	/*
		The email's HTML content (marked as safe, but it's not sanitized!).
		Safe binding is possible only to <iframe> with 'sandbox' attribute.
			See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/iframe#Attributes
		Binding to this property prevents an Angular bug with refreshing the frame.
			See https://stackoverflow.com/q/39429293/3377661
	*/
	safeHtmlBody: SafeHtml;

	emailPreviewDto: EmailPreviewDto;

	private subscriptions = new Subscription();

	get emailPreviewSentDateString(): string {
		return this.emailPreviewDto?.sentDate as any as string;
	}

	get emailPreviewSubject(): string {
		return isEmptyOrSpaces(this.emailPreviewDto?.subject) ? '(no subject)' : this.emailPreviewDto.subject;
	}

	constructor(
		private docService: DocumentsService,
		private sanitizer: DomSanitizer,
		private httpClient: HttpClient
	) {}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	// File icon for the file extension
	getFileIcon(extension: string): string {
		return getFileIcon(extension);
	}
	onPreviewClick(attachment: EmailAttachmentDto) {
		this.emailAttachmentPreviewClicked.emit(attachment);
	}
	onDownloadClick(attachment: EmailAttachmentDto) {
		this.emailAttachmentDownloadClicked.emit(attachment);
	}

	downloadFile() {
		this.subscriptions.add(this.docService.downloadDocument(this._documentId).subscribe());
	}

	private getEmailPreviewDto$(documentId: string) {
		return this.docService.getEmailPreviewItem(documentId).pipe(
			take(1),
			tap(dto => (this.emailPreviewDto = dto))
		);
	}

	private getEmailPreviewData$(documentId: string): Observable<string> {
		return this.docService.downloadEmailPreview(documentId, request => this.reportProgress$(request));
	}

	private reportProgress$(request: HttpRequest<any>): Observable<string> {
		return this.httpClient.request(request).pipe(
			concatMap(httpEvent => {
				// Via this API, you get access to the raw event stream.
				// Look for download progress events.
				if (httpEvent instanceof HttpResponse) {
					return this.readBlob$(httpEvent.body as Blob);
				}

				return of(null);
			}),
			filter(Boolean),
			tap((html: string) => {
				// Bind raw HTML, as Angular sanitizes the content by default (https://stackoverflow.com/a/39630507/3377661)
				this.safeHtmlBody = this.sanitizer.bypassSecurityTrustHtml(html);
			})
		);
	}

	private readBlobAsync(blob: Blob): Promise<string> {
		return new Promise<string>(resolve => {
			var reader = new FileReader();
			reader.onload = () => resolve(reader.result as string);
			reader.readAsText(blob);
		});
	}

	private readBlob$(blob: Blob): Observable<string> {
		return from(this.readBlobAsync(blob));
	}
}
