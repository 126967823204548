import { Action } from '@ngrx/store';

import { ITaskViewState } from './task-view.state';

// tslint:disable:max-classes-per-file
export const SetTaskViewType = 'SetTaskViewType';
export const ClearTaskViewType = 'ClearTaskViewType';

export type TaskViewActions = SetTaskViewAction | ClearTaskViewAction;

export class SetTaskViewAction implements Action {
	readonly type = SetTaskViewType;

	constructor(public data: ITaskViewState) {}
}

export class ClearTaskViewAction implements Action {
	readonly type = ClearTaskViewType;
}
