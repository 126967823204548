import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Subscription } from 'rxjs';

import { TrustAccountSequenceSettingsViewDto } from '@common/models/Settings/TrustSettings/TrustAccounts/Item/TrustAccountSequenceSettingsViewDto';
import { TrustTransactionSequenceTypeDto } from '@common/models/Settings/TrustSettings/TrustAccounts/Item/TrustTransactionSequenceTypeDto';
import { TrustTransactionSequenceTypeUpdateDto } from '@common/models/Settings/TrustSettings/TrustAccounts/Item/TrustTransactionSequenceTypeUpdateDto';
import { TrustTransactionSequenceType } from '@common/models/Trust/Common/TrustTransactionSequenceType';
import { NotificationService } from '@common/notification';
import { TrustAccountsService } from '@common/services/settings/trustaccounts.service';
import { isNil, padStart } from 'lodash';

import { CustomValidators } from '@common/validation/custom.validators';

@Component({
	selector: 'trust-numbering-setiings-dialog',
	styleUrls: ['./edit-trust-numbering-setiings.component.scss'],
	templateUrl: './edit-trust-numbering-setiings.component.html'
})
export class TrustAccountNumberingSettingsDialogComponent implements OnInit, OnDestroy {
	form: FormGroupTyped<TrustAccountSequenceSettingsViewDto>;

	readonly receiptKey: keyof typeof TrustTransactionSequenceType = 'Receipt';
	readonly paymentEFTKey: keyof typeof TrustTransactionSequenceType = 'PaymentEFT';
	readonly journalKey: keyof typeof TrustTransactionSequenceType = 'Journal';
	readonly paymentChequeKey: keyof typeof TrustTransactionSequenceType = 'PaymentCheque';
	readonly paymentPexaDirectDebitKey: keyof typeof TrustTransactionSequenceType = 'PaymentPEXADirectDebit';
	transactionType: keyof typeof TrustTransactionSequenceType;
	updateError: string;
	sampleOfNextNumber: string;
	originalNextNumberVal: number;
	private selectedSequence: TrustAccountSequenceSettingsViewDto;
	private subscriptions: Subscription = new Subscription();

	constructor(
		@Inject(MAT_DIALOG_DATA) public id: string,
		private fb: FormBuilder,
		private trustAccountService: TrustAccountsService,
		private dialogRef: MatDialogRef<TrustAccountNumberingSettingsDialogComponent>,
		private notificationService: NotificationService
	) {}

	ngOnInit() {
		this.form = this.fb.group({
			digits: [0, [CustomValidators.required('Number of digits'), Validators.min(0), Validators.max(20)]],
			id: this.id,
			nextNumber: 0,
			prefix: ''
		}) as FormGroupTyped<TrustAccountSequenceSettingsViewDto>;
	}

	onSave() {
		const dto: TrustTransactionSequenceTypeUpdateDto = {
			digits: null,
			nextNumber: null,
			prefix: null,
			transactionSequenceType: this.transactionType,
			trustAccountId: this.id
		};

		if (this.form.get('digits').value) {
			dto.digits = this.form.controls.digits.value;
		}

		if (this.form.get('prefix')) {
			dto.prefix = this.form.controls.prefix.value;
		}

		if (
			this.form.get('nextNumber') &&
			this.form.controls.nextNumber.dirty &&
			this.form.controls.nextNumber.value !== this.originalNextNumberVal
		) {
			dto.nextNumber = this.form.controls.nextNumber.value;
		}

		if (this.id) {
			this.subscriptions.add(
				this.trustAccountService.updateTrustSequenceSettings(dto).subscribe({
					next: () => {
						this.dialogRef.close();
					},
					error: error => {
						this.notificationService.showErrors('Error updating trust sequence', error);
					}
				})
			);
		}
	}

	transactionTypeChange() {
		if (this.id && this.transactionType) {
			const dto: TrustTransactionSequenceTypeDto = {
				transactionSequenceType: this.transactionType,
				trustAccountId: this.id
			};

			this.subscriptions.add(
				this.trustAccountService.getTrustSequenceSettings(dto).subscribe(x => {
					this.selectedSequence = x;
					this.populate();
				})
			);
		}
	}

	getTitle() {
		if (this.transactionType === 'Receipt') {
			return 'Receipts';
		} else if (this.transactionType === 'PaymentCheque') {
			return 'Payment Cheque';
		} else if (this.transactionType === 'Journal') {
			return 'Journal';
		} else if (this.transactionType === 'PaymentEFT') {
			return 'Payment EFT';
		}

		return '';
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	getFormattedInvoiceNumber(): string {
		if (!isNil(this.selectedSequence)) {
			const prefix = this.form.controls.prefix.value;
			const nextNumber = this.form.controls.nextNumber.value;
			return !!nextNumber
				? prefix + padStart(nextNumber.toString(), Math.min(this.form.controls.digits.value, 20), '0')
				: '';
		}
		return '';
	}

	private populate() {
		if (!isNil(this.selectedSequence)) {
			this.form.controls.nextNumber.setValidators([
				this.validateNextNumber(this.trustAccountService, {
					transactionSequenceType: this.transactionType,
					trustAccountId: this.id
				})
			]);

			this.originalNextNumberVal = this.selectedSequence.nextNumber;
			this.form.controls.digits.setValue(this.selectedSequence.digits);
			this.form.controls.prefix.setValue(this.selectedSequence.prefix);
			this.form.controls.nextNumber.setValue(this.originalNextNumberVal);
		}
	}

	private validateNextNumber(service: TrustAccountsService, dto: TrustTransactionSequenceTypeDto): ValidatorFn {
		return (control: AbstractControl): ValidationErrors => {
			if (
				isNil(this.originalNextNumberVal) ||
				!control.dirty ||
				control.value === '' + this.originalNextNumberVal
			) {
				control.setErrors(null);
				return {};
			} else if (isNil(control.value)) {
				// Required
				return { error: 'This field must be entered' };
			} else if (control.value < 1) {
				// Min(1)
				return { error: 'This field must be greater than 1' };
			} else {
				this.subscriptions.add(
					service.getLastSequenceNumber(dto).subscribe(lastVal => {
						const nextNumber = lastVal + 1;
						const res = control.value <= nextNumber;
						if (res) {
							control.setErrors(
								{ error: 'Value must be greater than ' + nextNumber },
								{ emitEvent: true }
							);
						} else {
							control.setErrors(null);
						}
					})
				);
				return {};
			}
		};
	}
}
