import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

export const FAVOURITE_DOCUMENT = 'FAVOURITE_DOCUMENT';

@Injectable({
	providedIn: 'root'
})
export class AppMessagingService {
	// Observable for subscribing on changing the current item (e.g. email)
	private _messagingSubject = new Subject<string>();

	onMessageReceived(eventName: string): Observable<string> {
		return this._messagingSubject.pipe(filter(name => eventName === name));
	}

	fireMessage(eventName: string) {
		this._messagingSubject.next(eventName);
	}
}
