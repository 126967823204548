import { Pipe, PipeTransform } from '@angular/core';

/*
 * Resolve task status display string by the enum key
 */
@Pipe({ name: 'formatAbn' })
export class AbnFormatterPipe implements PipeTransform {
	transform(abn: string): string {
		if (!!abn && abn.length == 11) {
			return `${abn.substr(0, 2)} ${abn.substr(2, 3)} ${abn.substr(5, 3)} ${abn.substr(8, 3)}`;
		}

		return abn;
	}
}
