import { Component, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { BehaviorSubject, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { TransactionType } from '@common/models/Trust/Common/TransactionType';
import { INotificationMessage } from '@common/notification';
import { TrustAccountsService } from '@common/services/settings/trustaccounts.service';

import { DialogComponent } from 'app/core/dialog.component';
import { SecurityPermissionService } from 'app/core/security-permissions.service';
import { AppBrandingService } from 'app/services/app-branding.service';

import { ICreateComponent } from '../create-component.interface';
import { CreateTrustJournalComponent } from './journal/create-trust-journal.component';
import { TrustReceiptPaymentComponent } from './receiptPayment/trust-receipt-payment.component';
import { Store } from '@ngrx/store';
import { IAppState } from 'app/core/state/app.state';
import { TrustListActions } from 'app/core/state/lists/trust-list/trust-list.actions';

@Component({
	selector: 'create-trust-record',
	styleUrls: ['./create-trust-record.component.scss'],
	templateUrl: 'create-trust-record.component.html'
})
export class CreateTrustRecordComponent implements OnInit, OnDestroy, ICreateComponent {
	@Output()
	isValidChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	@Output()
	titleChanged: BehaviorSubject<string> = new BehaviorSubject<string>(null);
	@Output()
	dialogClosed: Subject<boolean> = new ReplaySubject<boolean>();

	@ViewChild(TrustReceiptPaymentComponent)
	createRecordComponent: TrustReceiptPaymentComponent;

	@ViewChild(CreateTrustJournalComponent)
	createJournalComponent: CreateTrustJournalComponent;

	hasTrustAccounts: boolean;

	readonly receiptKey: keyof typeof TransactionType = 'Receipt';
	readonly paymentKey: keyof typeof TransactionType = 'Payment';
	readonly journalKey: keyof typeof TransactionType = 'Journal';
	readonly billPayKey: keyof typeof TransactionType = 'BillPayment';

	transactionType: 'Receipt' | 'Payment' | 'Journal' | 'BillPayment';
	transactionTypeEnum: typeof TransactionType = TransactionType;
	hasAccessToTrust = false;
	hasAccessToSettings = false;

	private subscription: Subscription = new Subscription();

	get siteName() {
		return AppBrandingService.getSiteName();
	}

	constructor(
		protected store: Store<IAppState>,
		private securityPermissionService: SecurityPermissionService,
		private trustAccountsService: TrustAccountsService
	) {
		this.hasAccessToTrust = this.securityPermissionService.hasAccessToTrust;
		this.hasAccessToSettings = this.securityPermissionService.hasAccessToSettings;
	}

	ngOnInit(): void {
		this.subscription.add(
			this.trustAccountsService.hasTrustAccounts().subscribe(flag => {
				this.hasTrustAccounts = flag;
			})
		);
	}

	ngOnDestroy() {
		this.isValidChange.complete();
		this.subscription.unsubscribe();
	}

	onFormStatusChanged(formStatus: string): void {
		return this.isValidChange.next(formStatus === 'VALID');
	}

	onTitleChanged(newTitle: string): void {
		return this.titleChanged.next(newTitle);
	}

	Create(): Observable<INotificationMessage> {
		const component = this.getComponent();
		let route = '/trust';
		if (!this.securityPermissionService.hasAccessToTrust) {
			route = '/dashboard';
		}

		return component.save().pipe(
			map(response => {
				this.store.dispatch({ type: TrustListActions.InsertRecords, response })
				return {
					linkParams: { pageIndexForId: response.id },
					linkRoute: [route],
					linkText: response.name,
					text: `Trust ${this.transactionTypeEnum[this.transactionType]} saved: `
				};
			})
		);
	}

	subscribeToErrors(parent: DialogComponent) {
		this.subscription.add(
			parent.onErrorEvent.subscribe(() => {
				const component = this.getComponent();
				component.onSaveError();
			})
		);
	}

	closeDialog() {
		this.dialogClosed.next(true);
	}

	get isReceipt(): boolean {
		return this.transactionType === this.receiptKey;
	}

	get isPayment(): boolean {
		return this.transactionType === this.paymentKey;
	}

	get isJournal(): boolean {
		return this.transactionType === this.journalKey;
	}

	private getComponent(): TrustReceiptPaymentComponent | CreateTrustJournalComponent {
		let component: TrustReceiptPaymentComponent | CreateTrustJournalComponent;
		if (this.transactionType === this.receiptKey || this.transactionType === this.paymentKey) {
			component = this.createRecordComponent;
		} else if (this.transactionType === this.journalKey) {
			component = this.createJournalComponent;
		}

		return component;
	}
}
