// This file has been generated from CostTemplateDto.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.Domain.Settings.CostTemplates.Item.CostTemplateDto
export class CostTemplateDto {

	// Name
	name: string;
	// Description
	description: string;
	// UserTypeRates
	userTypeRates: { [key: string]: number; };

	constructor() {

	}
}