// This file has been generated from MatterTrustBalanceReportsRequestDto.cs
// Do not modify this file directly!!
// tslint:disable

import { Moment } from 'moment-timezone';
import { DateFormat, SetDateFormat } from '@common/utils/date-format';



// LawMaster.Amalthea.Domain.Matters.Item.MatterTrustBalanceReportsRequestDto
export class MatterTrustBalanceReportsRequestDto {

	// DateStart
	@SetDateFormat(DateFormat.LocalDate)
	dateStart: Moment;
	// DateEnd
	@SetDateFormat(DateFormat.LocalDate)
	dateEnd: Moment;
	// TemplateId
	templateId: string;

	constructor() {

	}
}