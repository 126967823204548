import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	CanActivateChild,
	Router,
	RouterStateSnapshot,
	UrlTree
} from '@angular/router';

import { Observable } from 'rxjs';

import { NotificationService } from '@common/notification';

import { SecurityPermissionService } from 'app/core/security-permissions.service';

@Injectable({
	providedIn: 'root'
})
export class ReportsRouteGuard implements CanActivateChild, CanActivate {
	constructor(
		private securityPermissionService: SecurityPermissionService,
		private notificationService: NotificationService,
		private router: Router
	) {}

	canActivate(route: ActivatedRouteSnapshot) {
		if (!!this.securityPermissionService.hasAccessToReports) {
			return true;
		}

		this.showNotification('Reports');
		return false;
	}

	canActivateChild(
		childRoute: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		if (!!this.securityPermissionService.hasAccessToReports) {
			return true;
		}

		this.showNotification('Reports');
		return false;
	}

	private showNotification(pageName: string) {
		this.notificationService
			.showError('No Permission', `Sorry, you don't have the necessary permissions to access ${pageName} page.`)
			.subscribe(() => {
				this.router.navigate(['/dashboard']);
			});
	}
}
