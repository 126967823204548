import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { TenantMessaging } from '@common/models/Settings/Setting/Item/TenantMessaging';

@Component({
	template: `
		<h2 mat-dialog-title>Notice</h2>
		<mat-dialog-content [innerHTML]="data.dialogMessage"></mat-dialog-content>
		<br />
		<mat-dialog-actions style="place-content: flex-end;">
			<button
				*ngIf="data.dialogButtonText"
				mat-raised-button
				color="primary"
				throttleButton
				(throttledClick)="onClick($event)"
			>
				{{ data.dialogButtonText }}
			</button>
		</mat-dialog-actions>
	`
})
export class TenantMessageDialogComponent {
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: TenantMessaging,
		private router: Router,
		private dialogRef: MatDialogRef<TenantMessageDialogComponent>
	) {}

	onClick(event: Event) {
		event.preventDefault();
		if (this.data?.dialogButtonUrl) {
			if (this.data.dialogButtonUrl.toLowerCase().startsWith('http')) {
				if (this.data.isLockedOut) window.location.href = this.data.dialogButtonUrl;
				else window.open(this.data.dialogButtonUrl, '_blank').focus();
			} else {
				this.router.navigateByUrl(this.data.dialogButtonUrl);
			}
		}
		if (!this.data.isLockedOut) {
			this.dialogRef.close(true);
		}
	}
}
