import { AfterViewInit, Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';

import { EntityReference } from '@common/models/Common/EntityReference';
import { BankDetails } from '@common/models/Settings/TrustSettings/BankDetails';

import { CustomValidators } from '@common/validation/custom.validators';
import { Subscription } from 'rxjs';

@Component({
	selector: 'bank-details',
	styleUrls: ['./bank-details.component.scss'],
	templateUrl: './bank-details.component.html'
})
export class BankDetailsComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('bankNameInput')
	bankNameInput: ElementRef;
	@Input()
	mainForm: FormGroup;
	@Input()
	bankDetails?: BankDetails;
	@Input()
	isEditMode: boolean;

	validateBSB: boolean;
	bankDetailsForm: FormGroupTyped<BankDetails>;

	countries: EntityReference[] = [
		{ id: 'AU', name: 'Australia' },
		{ id: 'NZ', name: 'New Zealand' }
	];

	private subscriptions: Subscription = new Subscription();

	constructor(@Inject(MAT_DIALOG_DATA) public id: string, private fb: FormBuilder) {}

	ngOnInit() {
		this.mainForm.controls.bankDetails = this.fb.group({
			accountName: [null, CustomValidators.required('Account name')],
			accountNumber: [null, CustomValidators.required('Account Number')],
			bankName: [null, CustomValidators.required('Bank name')],
			bsb: [
				null,
				[
					CustomValidators.requiredWhen(() => this.validateBSB, 'BSB'),
					CustomValidators.pattern(new RegExp('^\\d{3}-?\\d{3}$'))
				]
			],
			countryId: [null, CustomValidators.required('Country')]
		}) as FormGroupTyped<BankDetails>;

		this.bankDetailsForm = this.mainForm.controls.bankDetails as FormGroupTyped<BankDetails>;

		if (this.isEditMode) {
			this.bankDetailsForm.patchValue({
				accountName: this.bankDetails.accountName,
				accountNumber: this.bankDetails.accountNumber,
				bankName: this.bankDetails.bankName,
				bsb: this.bankDetails.bsb,
				countryId: this.bankDetails.countryId
			});
		}

		this.subscriptions.add(
			this.bankDetailsForm.valueChanges.subscribe(() => {
				this.mainForm.updateValueAndValidity();
			})
		);
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.bankNameInput.nativeElement.focus();
		}, 0);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	countryChanged(countryEvent: MatSelectChange): void {
		if (countryEvent.value === 'AU') {
			this.validateBSB = true;
		} else {
			this.validateBSB = false;
			// Clear BSB when NZ is selected
			this.bankDetailsForm.controls.bsb.patchValue('');
		}
		// run updateValueAndValidity so BSB validations fire.
		this.bankDetailsForm.controls.bsb.updateValueAndValidity();
	}
}
