<div class="preview-title">
	<h3 class="filename" *ngIf="!attachmentFilename; else breadcrumb">{{ previewDocument?.fullFileName }}</h3>
	<ng-template #breadcrumb>
		<h3 class="filename"><a class="back-to-email" throttleButton (throttledClick)="closePreview()">Back to Email</a> / {{ attachmentFilename }}</h3>
	</ng-template>
	<ng-content></ng-content>
	<button *ngIf="!!canClose" class="close" mat-mini-fab throttleButton (throttledClick)="closePreview()">
		<mat-icon>close</mat-icon>
	</button>
</div>

<div
	*ngIf="
		isOfficeDocument &&
			getFileIconType(previewDocument.fileExtension) === fileTypeLimit?.fileType &&
			previewDocument?.size > fileTypeLimit?.maxBytesAllowed;
		else previewNormal
	"
	class="no-preview"
>
	<div>
		This file is too large to preview in browser. File size limit is {{ fileTypeLimit?.maxBytesAllowed | bytes }}. <br />
		Click the Edit button to open in {{ getOfficeEditorName(previewDocument.fileExtension) }}.
	</div>
	<div class="btn-download-container">
		<button mat-raised-button class="btn-download" throttleButton (throttledClick)="editFile()" title="Edit Document">
			<mat-icon color="accent">edit</mat-icon>
			<span class="btn-download-text">&nbsp;&nbsp;Edit Document</span>
		</button>
	</div>
</div>

<span *ngIf="!previewSrc && !emailDocumentId && !previewPdf" class="no-preview">No Preview Available</span>
<ng-template #previewNormal>
	<div *ngIf="previewSrc || emailDocumentId || previewPdf" class="preview">
		<ng-template [ngIf]="previewSrc">
			<img *ngIf="getFileIconType(previewDocument.fileExtension) == 'file-image'; else previewFrame" [src]="previewSrc" />
			<ng-template #previewFrame>
				<iframe [ngClass]="isPreviewNote ? 'preview-note' : ''" [src]="previewSrc"></iframe>
			</ng-template>
		</ng-template>
		<email-preview
			*ngIf="emailDocumentId"
			[documentId]="emailDocumentId"
			(emailAttachmentPreviewClicked)="emailAttachmentPreviewClicked($event)"
			(emailAttachmentDownloadClicked)="emailAttachmentDownloadClicked($event)"
		></email-preview>

		<pdf-preview *ngIf="previewPdf" [previewDto]="previewDocument"></pdf-preview>
	</div>
</ng-template>
