<div *ngFor="let field of customFieldConfig" class="custom-field-container">
	<div *ngIf="showContactField(field)">
		<div class="field-label" *ngIf="!editMode || (field.fieldType == 'Calculated' && !!forceShowCalculated)">
			{{ field.description }}
		</div>
		<div class="field-value" [ngSwitch]="field.fieldType">
			<editable-calculated
				*ngSwitchCase="'Calculated'"
				[control]="childControl(formGroupName, field.id)"
				[editMode]="editMode && !forceShowCalculated"
				[placeHolder]="field.description"
				[hintText]="field.helpText"
			></editable-calculated>
			<editable-long-text
				*ngSwitchCase="'Paragraph'"
				[control]="childControl(formGroupName, field.id)"
				[editMode]="editMode"
				[placeHolder]="field.description"
				[hintText]="field.helpText"
				[displayPipe]="displayPipe"
			></editable-long-text>
			<editable-short-text
				*ngSwitchCase="'Text'"
				[control]="childControl(formGroupName, field.id)"
				[editMode]="editMode"
				[placeHolder]="field.description"
				[hintText]="field.helpText"
				[displayPipe]="displayPipe"
			></editable-short-text>
			<editable-date
				*ngSwitchCase="'Date'"
				[control]="childControl(formGroupName, field.id)"
				[editMode]="editMode"
				[placeHolder]="field.description"
				[hintText]="field.helpText"
				[displayPipe]="displayPipe"
			>
			</editable-date>
			<editable-checkbox
				*ngSwitchCase="'Checkbox'"
				[control]="childControl(formGroupName, field.id)"
				[editMode]="editMode"
				[placeHolder]="field.description"
				[hintText]="field.helpText"
				[displayPipe]="displayPipe"
			></editable-checkbox>
			<editable-contact
				*ngSwitchCase="'Contact'"
				[control]="childControl(formGroupName, field.id)"
				[editMode]="editMode"
				[placeHolder]="field.description"
				[hintText]="field.helpText"
				[displayPipe]="displayPipe"
				[allowedContactIds]="field.contactSelectionList"
			></editable-contact>
			<editable-email-address
				*ngSwitchCase="'Email'"
				[control]="childControl(formGroupName, field.id)"
				[editMode]="editMode"
				[placeHolder]="field.description"
				[hintText]="field.helpText"
				[displayPipe]="displayPipe"
			></editable-email-address>
			<editable-selection
				*ngSwitchCase="'List'"
				[control]="childControl(formGroupName, field.id)"
				[editMode]="editMode"
				[options]="field.selectionList"
				optionValue="id"
				optionName="name"
				[placeHolder]="field.description"
				[hintText]="field.helpText"
				[displayPipe]="displayPipe"
				[useAutocomplete]="true"
			></editable-selection>
			<editable-numeric-value
				*ngSwitchCase="'Numeric'"
				[control]="childControl(formGroupName, field.id)"
				[editMode]="editMode"
				[placeHolder]="field.description"
				[hintText]="field.helpText"
				[displayPipe]="displayPipe"
				[maskingText]="field.maskingText"
			></editable-numeric-value>
			<editable-numeric-value
				*ngSwitchCase="'Currency'"
				[control]="childControl(formGroupName, field.id)"
				[editMode]="editMode"
				[isCurrency]="true"
				[placeHolder]="field.description"
				[hintText]="field.helpText"
				[displayPipe]="displayPipe"
			></editable-numeric-value>
		</div>
	</div>
</div>
