<mat-card class="mat-card">
	<section class="page-path"><a [routerLink]="['..']">System</a> / Matter Numbering</section>

	<h1>Matter Numbering</h1>

	<div class="flex-column">
		<div class="control-row">
			<div class="input-label">Format by Year</div>

			<div class="input-control">
				<mat-slide-toggle
					[checked]="form.get('useYearReferenceFormat').value"
					(change)="handleSlideToggleChange('useYearReferenceFormat', $event)"
				></mat-slide-toggle>
			</div>
		</div>

		<div class="control-row">
			<div class="input-label">Short Year Format</div>

			<div class="input-control">
				<mat-slide-toggle
					[checked]="form.get('useShortYearReferenceFormat').value"
					(change)="handleSlideToggleChange('useShortYearReferenceFormat', $event)"
					[disabled]="!form.get('useYearReferenceFormat').value"
				></mat-slide-toggle>
			</div>
		</div>

		<div class="control-row">
			<div class="input-label">Minimum Digits</div>

			<div class="input-control">
				<editable-numeric-value
					[control]="form.get('numberPadding')"
					tooltip="Number of digits to use in the Matter number"
					[editMode]="true"
				></editable-numeric-value>
			</div>
		</div>

		<div class="control-row">
			<div class="input-label">Next Number</div>

			<div class="input-control">
				<matter-numbering-editor [control]="form.get('nextNumber')">
					<span class="example">
						The next Matter number is<span class="example-number">&nbsp;{{ exampleNumberReferenceFormat }}</span>
					</span>
				</matter-numbering-editor>
			</div>
		</div>
	</div>

	<div class="actions">
		<button
			mat-raised-button
			[disabled]="!!form.invalid || !!form.disabled || !!form.pristine || !!isEditingSequence"
			color="primary"
			throttleButton
			(throttledClick)="saveChanges()"
		>
			Save
		</button>

		<button mat-stroked-button color="warn" [disabled]="!!isEditingSequence" throttleButton (throttledClick)="modifySequence()">Modifiy Number</button>
	</div>
</mat-card>
