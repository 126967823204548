// This file has been generated from ServiceRequestCreateDto.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.Domain.Marketplace.ServiceRequests.Item.ServiceRequestCreateDto
export class ServiceRequestCreateDto {

	// MarketplaceServiceId
	marketplaceServiceId: string;
	// FirstName
	firstName: string;
	// LastName
	lastName: string;
	// Phone
	phone: string;
	// Email
	email: string;
	// Message
	message: string;

	constructor() {

	}
}