// This file has been generated from RolePermission.cs
// Do not modify this file directly!!

// LawMaster.Amalthea.Common.Models.RolePermission
export enum RolePermission {
	None = 'None',
	SystemMarketplace = 'Subscription & Marketplace',
	SystemDocuments = 'Documents',
	SystemCustomFields = 'Custom Fields',
	SystemTimeCost = 'Time & Costing',
	SystemMatters = 'Matters',
	SystemTrust = 'Trust Accounting',
	SystemUserAccounts = 'Users',
	SystemReferralReasons = 'Referrals',
	SystemSettingCompany = 'Settings',
	SystemReports = 'Logs',
	TrustCreateTransactions = 'Create Trust Transactions',
	TrustCancelTransactions = 'Cancel Trust Transactions',
	GlobalCostList = 'All Costs',
	GlobalInvoiceList = 'All Invoices'
}
