import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { filter } from 'rxjs/operators';

import { CostCodeExpenseType } from '@common/models/Settings/CostCodes/Common/CostCodeExpenseType';
import { CostCodeTimeType } from '@common/models/Settings/CostCodes/Common/CostCodeTimeType';
import { CostCodeDto } from '@common/models/Settings/CostCodes/Item/CostCodeDto';
import { CostCodeListItemDto } from '@common/models/Settings/CostCodes/List/CostCodeListItemDto';
import { CostCodeListRequest } from '@common/models/Settings/CostCodes/List/CostCodeListRequest';
import { Store } from '@ngrx/store';

import { IAppState } from 'app/core/state/app.state';
import { CostCodeListActions } from 'app/core/state/lists/settings/cost-code-list/cost-code-list.actions';
import {
	selectCostCodeIsFetching,
	selectCostCodeListRecords,
	selectCostCodeListRequest,
	selectCostCodeListTotalRecords
} from 'app/core/state/lists/settings/cost-code-list/cost-code-list.selectors';
import { GridViewService } from 'app/services/grid-view.service';
import {
	ActionTypes,
	GenericListStateComponent,
	SelectorTypes
} from 'app/shared/generics/generic.list.state.component';

import { CostCodesItemComponent } from '../item/cost-codes-item.component';
import { CostCodeListFilterDialogComponent } from './filter-dialog/cost-code-filter-dialog.component';
import { CostCodeListFilterComponent } from './filter/cost-code-list-filter.component';

@Component({
	selector: 'cost-codes',
	styleUrls: ['./cost-codes-list.component.scss'],
	templateUrl: './cost-codes-list.component.html'
})
export class CostCodesListComponent extends GenericListStateComponent<CostCodeListItemDto, CostCodeListRequest> {
	get actions(): ActionTypes {
		return {
			init: CostCodeListActions.Init,
			load: CostCodeListActions.Load,
			setFilters: CostCodeListActions.SetFilters,
			setPageIndex: CostCodeListActions.SetPageIndex,
			setPageIndexForId: CostCodeListActions.SetPageIndexForId,
			setPageSize: CostCodeListActions.SetPageSize,
			setSortBy: CostCodeListActions.SetSortBy,
			setSortDirection: CostCodeListActions.SetSortDirection,
			selected: CostCodeListActions.SelectRecords
		};
	}
	get selectors(): SelectorTypes {
		return {
			records: selectCostCodeListRecords,
			isFetching: selectCostCodeIsFetching,
			request: selectCostCodeListRequest,
			totalRecords: selectCostCodeListTotalRecords
		};
	}
	@ViewChild(CostCodeListFilterComponent, { static: true })
	filter: CostCodeListFilterComponent;
	request: Partial<CostCodeListRequest> = {
		costType: null,
		isEnabled: null,
		isFixedFee: null,
		search: null
	};
	filterDialog = CostCodeListFilterDialogComponent;

	ChargeableTimeKey: keyof typeof CostCodeTimeType = 'Chargeable';
	NonChargeableTimeKey: keyof typeof CostCodeTimeType = 'NonChargeable';
	IncGstKey: keyof typeof CostCodeExpenseType = 'ExpenseIncGst';
	ExGstKey: keyof typeof CostCodeExpenseType = 'ExpenseExGst';

	constructor(
		store: Store<IAppState>,
		dialog: MatDialog,
		router: Router,
		activatedRoute: ActivatedRoute,
		gridViewService: GridViewService
	) {
		super(['name', 'type', 'chargeRate', 'isDisabled'], dialog, store, router, activatedRoute, gridViewService);
	}

	ngOnInit() {
		super.ngOnInit();
		this.filterComponent = this.filter.root;
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
		this.filter.root.resetFilter({
			isEnabled: true
		});
	}

	createCostCode() {
		this.openCostCodeDialog(new CostCodeDto());
	}

	editCostCode(row: CostCodeListItemDto) {
		this.openCostCodeDialog(row);
	}

	isExpenseCode(row: CostCodeListItemDto) {
		return row && row.expenseType !== 'None';
	}

	isTimeCode(row: CostCodeListItemDto) {
		return row && row.timeType !== 'None';
	}

	getRowTimeDescription(row: CostCodeListItemDto) {
		switch (row.timeType) {
			case 'Chargeable':
				if (row.chargeRate && row.chargeRate > 0) return ' Time / Fixed Fee';
				else return 'Time';

			case 'NonChargeable':
				return 'Time (Non Chargeable)';

			default:
				return '';
		}
	}

	getRowExpenseDescription(row: CostCodeListItemDto) {
		switch (row.expenseType) {
			case 'ExpenseIncGst':
				return 'Expense';

			case 'ExpenseExGst':
				return 'Expense (GST Free)';

			default:
				return '';
		}
	}

	private openCostCodeDialog(data: CostCodeDto) {
		this.subscriptions.add(
			this.dialog
				.open(CostCodesItemComponent, { data })
				.afterClosed()
				.pipe(filter(Boolean))
				.subscribe((response: any) => {
					if (!!response && response.isDelete) {
						this.store.dispatch({ type: CostCodeListActions.RemoveRecords, response: response.response });
					} else {
						if (!!data && !data.id) {
							this.store.dispatch({
								type: CostCodeListActions.InsertRecords,
								response: response.response
							});
						} else {
							this.store.dispatch({
								type: CostCodeListActions.UpdateRecords,
								response: response.response
							});
						}
					}
				})
		);
	}
}
