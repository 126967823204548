<mat-dialog-content>
	<h1 *ngIf="!isFinishStage">
		Confirm <b>{{ productName }}</b> {{ this.isTrial ? 'trial' : 'subscription' }}
	</h1>
	<h1 *ngIf="isFinishStage">Success</h1>

	<ng-container *ngIf="!!isConfigureStage && !!marketplaceListing && !!configurePackageData">
		<p>
			You are subscribing to <b>{{ productName }}</b
			>.
		</p>

		<configure-package
			#configurePackageRef
			[operation]="configurePackageOperationType"
			[productName]="marketplaceListing.productName"
			[data]="configurePackageData"
			[form]="configurePackageForm"
		>
		</configure-package>
	</ng-container>

	<div class="container" *ngIf="isReviewStage">
		<div *ngIf="isTrial">
			<p>Your {{ trialDays }} day trial will expire on {{ trialEndDate | moment : 'dd/MM/yyyy' }}</p>
		</div>

		<div *ngIf="!!upcomingInvoice && !isTrial">
			<p>
				<b>{{ data.quantity }}</b> licence{{ data.quantity > 1 ? 's' : '' }} of <b>{{ data.marketplaceListing.productName }}</b> will be added to your
				{{ data.billingCycle.toLowerCase() }} subscription
			</p>

			<div *ngIf="data?.quantity && data?.quantity < numberOfActiveUsers" class="warning">
				<mat-icon color="warn">warning_amber</mat-icon>
				<span> There are {{ numberOfActiveUsers }} active users. You will need {{ numberOfActiveUsers - data?.quantity }} more License(s) </span>
			</div>

			<div *ngIf="overlappingModules?.length > 0" class="warning">
				<mat-icon color="warn">warning_amber</mat-icon>
				<span [innerHtml]="overlappingFeaturesWarningHtml"></span>
			</div>

			<upcoming-invoice-breakdown
				[upcomingInvoice]="upcomingInvoice"
				[recurringInvoice]="recurringInvoice"
				[interval]="interval"
			></upcoming-invoice-breakdown>
		</div>

		<div class="flex-column c-checkbox-container" [class.c-checkbox-container--top-margin]="!!isReviewStage" *ngIf="!!productTermsUrl">
			<mat-checkbox color="primary" [disabled]="!!isLoading" [formControl]="configurePackageForm.get('termsAccepted')">
				<span>
					By checking this box I agree to the
					<a [href]="productTermsUrl" target="_blank">terms & conditions</a>.
				</span>
			</mat-checkbox>
		</div>
	</div>

	<div class="container" *ngIf="isFinishStage">
		<div *ngIf="finishStageMessages">
			<span *ngFor="let msg of finishStageMessages">
				<p [innerHtml]="msg"></p>
			</span>
		</div>
	</div>
</mat-dialog-content>

<mat-dialog-actions>
	<button mat-raised-button mat-dialog-close *ngIf="!isFinishStage">Cancel</button>
	<button mat-raised-button color="primary" [disabled]="!!isProceedButtonDisabled" throttleButton (throttledClick)="onProceedButtonClick()">
		{{ nextButtonText }}
	</button>
	<div *ngIf="!!isLoading" class="loadingContainer">
		<p *ngIf="loadingMessage">{{ loadingMessage }}</p>
		<mat-progress-bar [mode]="this.isQueryLoadingBar ? 'query' : 'indeterminate'"></mat-progress-bar>
	</div>
</mat-dialog-actions>
