import { HttpResponse } from '@angular/common/http';
import { AfterViewInit, Directive, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';

import { BehaviorSubject, combineLatest, fromEvent, Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';

import { BaseListItemDto } from '@common/models/Common/BaseListItemDto';
import { IdCollectionRequest } from '@common/models/Common/IdCollectionRequest';
import { arraysEqual } from '@common/utils/arrayUtils';
import { getFilenameFromHttpHeader } from '@common/utils/fileNameUtil';
import { nameof } from '@common/utils/nameof';
import { MemoizedSelector, Store } from '@ngrx/store';
import * as FileSaver from 'file-saver';
import { get, isNil, isPlainObject, omitBy } from 'lodash-es';
import * as moment from 'moment-timezone';

import { AppConfig } from 'app/app.config';
import { IAppState } from 'app/core/state/app.state';
import { SelectionType } from 'app/core/state/SelectionType';
import { GridViewService, IFilterDefaultsConfig } from 'app/services/grid-view.service';

import { ExportDialogComponent } from '../components/export-dialog.component';
import { GridLayoutChangesDialogComponent } from '../components/grid-layout/grid-layout-changes-dialog.component';
import { FilterRootComponent } from '../filter-controls';
import { FilterChangeProperties } from '../filter-controls/filter-change-properties';

@Directive()
export abstract class GenericListStateComponent<TListItemDto extends BaseListItemDto, TFilter>
	implements OnInit, AfterViewInit, OnDestroy
{
	@ViewChild(MatPaginator, { static: true })
	paginator: MatPaginator;
	@ViewChild(MatSort, { static: true })
	sort: MatSort;

	// Reference to the component containing filters
	@Input()
	filterComponent: FilterRootComponent<TFilter>;

	// Add additional filtering applied before the Filter Component
	@Input()
	set FilterBase(val: Partial<TFilter>) {
		this.setFilterBase(val);
	}
	get FilterBase(): Partial<TFilter> {
		return this.filterBase.getValue();
	}

	// Displayed columns. Override this property if you need conditional hiding of some columns
	get displayedColumns(): string[] {
		return this.defaultDisplayedColumns;
	}

	pageSizeOptions: number[] = AppConfig.PageSizeOptions;

	protected subscriptions = new Subscription();
	protected filterBase = new BehaviorSubject<Partial<TFilter>>(null);

	data$: Observable<TListItemDto[]>;
	selected$: Observable<TListItemDto[]>;
	isFetching$: Observable<boolean>;
	pageSize$: Observable<number>;
	pageSize: number;
	pageIndex$: Observable<number>;
	pageIndex: number = 0;
	totalRecords$: Observable<number>;

	routesPageSize = new BehaviorSubject<number>(null);
	routesPageIndex = new BehaviorSubject<number>(null);
	routesSortBy = new BehaviorSubject<string>(null);
	routesSortDirection = new BehaviorSubject<'asc' | 'desc' | null>(null);
	routesPageIndexForId = new BehaviorSubject<string>(null);

	private filterDefaultsSet = false;
	protected enableSortStorage = false;

	abstract get actions(): ActionTypes;
	abstract get selectors(): SelectorTypes;

	constructor(
		protected defaultDisplayedColumns: string[],
		protected dialog: MatDialog,
		protected store: Store<IAppState>,
		protected router: Router,
		protected activatedRoute: ActivatedRoute,
		protected gridViewService: GridViewService
	) {}

	ngOnInit(): void {
		this.store.dispatch({ type: this.actions.init });
		this.store.dispatch({ type: this.actions.load });
		this.data$ = this.store.select(this.selectors.records).pipe(map(records => records as TListItemDto[]));
		this.selected$ = this.data$.pipe(map(records => records?.filter(record => !!record.isHighlighted)));
		this.isFetching$ = this.store.select(this.selectors.isFetching);
		this.pageSize$ = this.store.select(this.selectors.request).pipe(
			map(x => x.pageSize),
			filter(pageSize => pageSize !== null && pageSize !== undefined),
			distinctUntilChanged()
		);
		this.subscriptions.add(this.pageSize$.subscribe(value => (this.pageSize = value)));
		this.pageIndex$ = this.store.select(this.selectors.request).pipe(
			map(x => x.pageIndex),
			filter(pageIndex => pageIndex !== null && pageIndex !== undefined),
			distinctUntilChanged()
		);
		this.subscriptions.add(
			this.pageIndex$.subscribe(value => {
				this.pageIndex = value;
				if (value === 0 && !!this.paginator) this.paginator.firstPage();
			})
		);
		this.totalRecords$ = this.store.select(this.selectors.totalRecords);

		if (this.filterBase) {
			this.subscriptions.add(
				this.filterBase
					.pipe(filter(Boolean), distinctUntilChanged())
					.subscribe(() =>
						this.store.dispatch({ type: this.actions.setFilters, filter: this.mergeFilters(true) })
					)
			);
		}

		if (this.filterComponent) {
			this.subscriptions.add(
				this.filterComponent.filterChange
					.pipe(
						filter(event => !!event?.filter),
						map(event => event.filter),
						distinctUntilChanged()
					)
					.subscribe(() =>
						this.store.dispatch({ type: this.actions.setFilters, filter: this.mergeFilters(true) })
					)
			);
		}

		if (this.paginator) {
			this.subscriptions.add(
				this.paginator.page
					.pipe(
						map(event => event.pageIndex),
						distinctUntilChanged()
					)
					.subscribe(pageIndex => this.store.dispatch({ type: this.actions.setPageIndex, pageIndex }))
			);
			this.subscriptions.add(
				this.paginator.page
					.pipe(
						map(event => event.pageSize),
						distinctUntilChanged()
					)
					.subscribe(pageSize => this.store.dispatch({ type: this.actions.setPageSize, pageSize }))
			);
		}

		if (this.sort) {
			this.subscriptions.add(
				this.sort.sortChange
					.pipe(
						map(event => event.active),
						filter(Boolean),
						distinctUntilChanged()
					)
					.subscribe(sortBy => this.store.dispatch({ type: this.actions.setSortBy, sortBy }))
			);
			this.subscriptions.add(
				this.sort.sortChange
					.pipe(
						map(event => event.direction),
						distinctUntilChanged()
					)
					.subscribe(sortDirection =>
						this.store.dispatch({ type: this.actions.setSortDirection, sortDirection })
					)
			);
		}
	}

	ngAfterViewInit() {
		// Use route values to initialize request
		this.subscriptions.add(
			this.activatedRoute.queryParamMap.pipe(distinctUntilChanged()).subscribe(params => {
				if (!!params.has('pageIndexForId')) this.routesPageIndexForId.next(params.get('pageIndexForId'));
				if (!!this.sort && !!params.has('sortBy')) {
					this.routesSortBy.next(params.get('sortBy'));
					this.routesSortDirection.next(params.get('sortDirection') === 'desc' ? 'desc' : 'asc');
				}
				if (!!this.paginator) {
					if (!!params.has('pageSize')) this.routesPageSize.next(+params.get('pageSize'));
					if (!!params.has('pageIndex')) this.routesPageIndex.next(+params.get('pageIndex'));
				}
			})
		);
		this.subscriptions.add(
			this.routesPageIndexForId
				.pipe(distinctUntilChanged())
				.subscribe(id => this.store.dispatch({ type: this.actions.setPageIndexForId, id }))
		);
		this.subscriptions.add(
			this.routesSortBy
				.pipe(distinctUntilChanged())
				.subscribe(sortBy => this.store.dispatch({ type: this.actions.setSortBy, sortBy }))
		);
		this.subscriptions.add(
			this.routesSortDirection
				.pipe(distinctUntilChanged())
				.subscribe(sortDirection => this.store.dispatch({ type: this.actions.setSortDirection, sortDirection }))
		);
		this.subscriptions.add(
			this.routesPageSize
				.pipe(distinctUntilChanged())
				.subscribe(pageSize => this.store.dispatch({ type: this.actions.setPageSize, pageSize }))
		);
		this.subscriptions.add(
			this.routesPageIndex
				.pipe(distinctUntilChanged())
				.subscribe(pageIndex => this.store.dispatch({ type: this.actions.setPageIndex, pageIndex }))
		);

		// Update route on request change
		this.subscriptions.add(
			this.store
				.select(this.selectors.request)
				.pipe(
					filter(Boolean),
					map(triggerObject => {
						const replaceUrl =
							get(triggerObject, nameof<FilterChangeProperties<any>>('replaceUrl'), false) || true;
						// Get merged filter from all the Query Parameters
						const accruedFilter = this.mergeFilters(false);
						return {
							queryParams: accruedFilter,
							replaceUrl
						};
					}),
					distinctUntilChanged((a, b) => {
						if (a.replaceUrl !== b.replaceUrl) {
							return false;
						}
						if (!a.queryParams && !b.queryParams) {
							return true;
						}
						if (!!a.queryParams && !!b.queryParams) {
							const aSearch = get(a.queryParams, 'search') ?? null;
							const bSearch = get(b.queryParams, 'search') ?? null;
							if (aSearch !== bSearch) this.clearSorting();
							return arraysEqual(Object.values(a.queryParams), Object.values(b.queryParams));
						}
						return false;
					}),
					debounceTime(200)
				)
				.subscribe(request => {
					// Update the QueryString
					this.router.navigate([], {
						queryParams: request.queryParams,
						relativeTo: this.activatedRoute,
						replaceUrl: request.replaceUrl
					});
				})
		);

		this.subscriptions.add(
			fromEvent(document, 'keydown')
				.pipe(filter(Boolean))
				.subscribe((e: KeyboardEvent) => {
					if (document.activeElement?.localName !== 'body') return;
					const key = e.key.toLowerCase();
					if (e.ctrlKey && key === 'a') {
						this.selectAllKeyPressed(e);
					} else if (key === 'escape') {
						this.clearSelectionKeyPressed(e);
					}
				})
		);

		// Get Filter Values
		// This is required for the ExpressionChangedAfterItHasBeenCheckedError error.
		setTimeout(() => this.loadFilterDefaultsValues(), 0);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	clearSorting(): void {
		this.sort?.sort({ id: null, start: null, disableClear: true });
	}

	isColumnVisible(columnName: string): boolean {
		return this.displayedColumns.indexOf(columnName) !== -1;
	}

	selectRow(selectedRow: TListItemDto, event: MouseEvent): void {
		if (event.ctrlKey) {
			this.handleRowSelectionWithCtrlKey(selectedRow);
		} else if (event.shiftKey) {
			this.handleRowSelectionWithShiftKey(selectedRow);
			this.clearTextSelection();
		} else {
			// no keys pressed. default behaviour
			this.handleRowSelectionWithNoKeys(selectedRow);
		}
	}

	protected setFilterBase(val: Partial<TFilter>) {
		if (val !== this.FilterBase) this.filterBase.next(val);
	}

	protected showExportDialog<TIdCollectionRequest extends IdCollectionRequest>(
		title: string,
		dialog: MatDialog,
		exportByIds: (request: TIdCollectionRequest) => Observable<HttpResponse<Blob>>,
		exportByFilter: (request: TFilter) => Observable<HttpResponse<Blob>>,
		message: string = null,
		exportButtonText: string = 'Export'
	): void {
		// Initiate download on the prompted action
		this.subscriptions.add(
			combineLatest([this.data$, this.totalRecords$])
				.pipe(
					take(1),
					switchMap(([data, totalRecords]) => {
						const selectedRows = data?.filter(x => !!x.isHighlighted);
						const recordsCount = selectedRows?.length || totalRecords;
						const dialogRef = dialog.open(ExportDialogComponent, {
							data: {
								recordsCount,
								title: title,
								displayedColumns: this.displayedColumns,
								message: message,
								exportButtonText: exportButtonText
							},
							width: '350px'
						});
						return dialogRef.afterClosed().pipe(
							filter(Boolean),
							switchMap(() => {
								// Selected items have priority over the filter
								if (!!selectedRows?.length) {
									const request = {
										ids: selectedRows.map(r => r.id)
									} as TIdCollectionRequest;
									return exportByIds(request);
								}
								return exportByFilter(this.mergeFilters(true));
							})
						);
					})
				)
				// Call browser download function for the server response
				.subscribe(response => {
					FileSaver.saveAs(
						response.body,
						getFilenameFromHttpHeader(response.headers.get('content-disposition'))
					);
					this.clearSelection();
				})
		);
	}

	protected paginationHandler(page: PageEvent): void {
		// Implementation in 'document-list.component.ts'
	}

	protected filterSortBy(sortBy: string): string {
		return sortBy;
	}

	// Add filtering for the list
	protected mergeFilters(includeFilterBase: boolean): TFilter {
		const sorting = !!this.sort?.active
			? this.sort.direction === 'desc'
				? { sortBy: this.sort.active, sortDirection: this.sort.direction }
				: { sortBy: this.sort.active }
			: null;
		const accruedFilter: any = Object.assign(
			{},
			includeFilterBase ? this.FilterBase : {},
			this.filterComponent ? this.filterComponent.filter : {},
			this.pageSize
				? {
						pageSize: this.pageSize,
						pageIndex: this.pageIndex
				  }
				: {},
			sorting ? sorting : {}
		);

		return Object.keys(accruedFilter).reduce<TFilter>((prev, curr) => {
			let value = { [curr]: accruedFilter[curr] };
			// Convert 'Custom Filters' to a dictionary (flatten out filters, so they can go to the query string)
			if (isPlainObject(accruedFilter[curr])) {
				value = Object.keys(omitBy(accruedFilter[curr], isNil)).reduce(
					(p, c) =>
						Object.assign(p, {
							[`${curr}[${c}]`]: moment.isMoment(accruedFilter[curr][c])
								? accruedFilter[curr][c].format('YYYY-MM-DD')
								: accruedFilter[curr][c]
						}),
					{}
				);
			}
			return Object.assign(prev, value);
		}, {} as TFilter);
	}

	protected selectAll(): void {
		this.store.dispatch({ type: this.actions.selected, row: null, selectionType: SelectionType.all });
	}

	protected clearSelection(): void {
		this.store.dispatch({ type: this.actions.selected, row: null, selectionType: SelectionType.clear });
	}

	// This is a hack to address sorting bugs present in angular MatSort
	// https://github.com/angular/components/issues/10242#issuecomment-470726829
	//
	// Todo: Cleanup when Google fixes the issue
	protected sortBy(column: string, direction: 'asc' | 'desc' | null): void {
		direction = direction || 'asc';

		this.sort?.sort({ id: null, start: direction, disableClear: false });
		this.sort?.sort({ id: column, start: direction, disableClear: false });

		(this.sort?.sortables.get(column) as MatSortHeader)?._setAnimationTransitionState({ toState: 'active' });
	}

	hasStoredFilterValues() {
		return this.filterDefaultsSet;
	}

	protected loadFilterDefaultsValues() {
		if (!this.enableSortStorage) {
			return false;
		}

		const stored = this.gridViewService.getFilterDefaults();

		let foundValues = false;

		if (!!stored?.pageSize && !!this.paginator) {
			this.paginator.pageSize = stored.pageSize;
			foundValues = true;
		}

		if (!!stored?.sortColumn || !!stored?.sortDirection) {
			this.sortBy(stored.sortColumn, stored.sortDirection === 'desc' ? 'desc' : 'asc');
			foundValues = true;
		}

		this.filterDefaultsSet = true;

		return foundValues;
	}

	getDefaultFilterSettings(): IFilterDefaultsConfig {
		throw new Error('Not Implemented');
	}

	storeFilterDefaults() {
		if (!this.enableSortStorage) {
			return;
		}

		const defaults = this.getDefaultFilterSettings();

		const dto: IFilterDefaultsConfig = {
			pageSize: this.paginator?.pageSize ?? defaults.pageSize,
			sortColumn: this.sort?.active ?? defaults.sortColumn,
			sortDirection: this.sort?.direction ?? defaults.sortDirection
		};

		this.subscriptions.add(
			this.dialog
				.open(GridLayoutChangesDialogComponent, {
					data: {
						old: this.displayFilterConfig(this.gridViewService.getFilterDefaults() ?? defaults),
						new: this.displayFilterConfig(dto),
						isReset: false
					}
				})
				.afterClosed()
				.pipe(filter(Boolean))
				.subscribe(() => this.gridViewService.storeFilterDefaults(dto))
		);
	}

	private displayFilterConfig(dto: IFilterDefaultsConfig): IFilterDefaultsConfig {
		return {
			sortColumn: this.displayColumnName(dto.sortColumn),
			sortDirection: dto.sortDirection,
			pageSize: dto.pageSize
		};
	}

	protected columnDefinitionNameMap: { [key: string]: () => string };

	private displayColumnName(columnDefintionName: string) {
		const mapFunction = this.columnDefinitionNameMap[columnDefintionName];

		if (!!mapFunction) {
			return mapFunction();
		}

		return columnDefintionName;
	}

	getFilterDefaults() {
		return this.gridViewService.getFilterDefaults() ?? this.getDefaultFilterSettings();
	}

	resetFilterDefaults() {
		if (!this.enableSortStorage) {
			return;
		}

		const defaults = this.getDefaultFilterSettings();

		this.subscriptions.add(
			this.dialog
				.open(GridLayoutChangesDialogComponent, {
					data: {
						old: this.displayFilterConfig(this.gridViewService.getFilterDefaults() ?? defaults),
						new: this.displayFilterConfig(defaults),
						isReset: true
					}
				})
				.afterClosed()
				.pipe(filter(Boolean))
				.subscribe(() => {
					this.gridViewService.resetFilterDefaults();

					this.sortBy(defaults.sortColumn, defaults.sortDirection === 'desc' ? 'desc' : 'asc');
					if (this.paginator) this.paginator.pageSize = defaults.pageSize;
				})
		);
	}

	private handleRowSelectionWithNoKeys(selectedRow: TListItemDto): void {
		this.store.dispatch({ type: this.actions.selected, row: selectedRow, selectionType: SelectionType.select });
	}

	private handleRowSelectionWithCtrlKey(selectedRow: TListItemDto): void {
		this.store.dispatch({
			type: this.actions.selected,
			row: selectedRow,
			selectionType: SelectionType.toggle
		});
	}

	private handleRowSelectionWithShiftKey(selectedRow: TListItemDto): void {
		this.store.dispatch({
			type: this.actions.selected,
			row: selectedRow,
			selectionType: SelectionType.range
		});
	}

	private clearTextSelection(): void {
		const selection = window.getSelection ? window.getSelection() : get(document, 'selection');
		if (selection) {
			if (selection.removeAllRanges) {
				selection.removeAllRanges();
			} else if (selection.empty) {
				selection.empty();
			}
		}
	}

	private selectAllKeyPressed(e: KeyboardEvent): void {
		e.stopPropagation();
		e.preventDefault();
		this.selectAll();
	}

	private clearSelectionKeyPressed(e: KeyboardEvent): void {
		e.stopPropagation();
		e.preventDefault();
		this.clearSelection();
	}
}

export interface ActionTypes {
	init: string;
	load: string;
	setFilters?: string;
	setPageIndex: string;
	setPageIndexForId: string;
	setPageSize: string;
	setSortBy: string;
	setSortDirection: string;
	selected: string;
}

export interface SelectorTypes {
	records: MemoizedSelector<IAppState, BaseListItemDto[]>;
	isFetching: MemoizedSelector<IAppState, boolean>;
	request: MemoizedSelector<IAppState, Partial<any>>;
	totalRecords: MemoizedSelector<IAppState, number>;
}
