<mat-card>
	<section class="page-path"><a [routerLink]="['..']">System</a> / Email Subject Configuration</section>
	<div class="top-header">
		<h1>Email Subject Configuration</h1>
	</div>
	<div class="description">Select the format that should appear for the subject line in Outlook</div>

	<div class="flexrow sample-row">
		<span class="description">Example email subject line in Outlook: &nbsp; </span>
		<h3 class="sample-text">
			<span *ngFor="let item of items; let first = first">
				<span *ngIf="!first">&nbsp;</span>
				<b *ngIf="item.variableKey == 'Hyphen'; else notHypen">-</b>
				<ng-template #notHypen>
					<b *ngIf="item.variableKey == 'MatterConstant'; else notMatterConstant">Matter</b>
					<ng-template #notMatterConstant>
						<b>{{ item.example }}</b>
					</ng-template>
				</ng-template>
			</span>
		</h3>
	</div>

	<div class="flex-column" cdkDropListGroup>
		<span class="error">{{ errorMessage }}</span>

		<div class="flex-column">
			<h2 class="description">Matter reference</h2>

			<div cdkDropList cdkDropListOrientation="horizontal" [cdkDropListData]="items" class="drag-drop-box" (cdkDropListDropped)="drop($event)">
				<span [ngClass]="items?.length > 0 ? 'drag-drop-label-right' : 'drag-drop-label-center'"
					><mat-icon>highlight_alt</mat-icon>Drag and drop variables here</span
				>
				<div class="flexrow">
					<div *ngFor="let item of items; let first = first; let i = index" class="chip-flex-row">
						<span class="chip-hyphen" *ngIf="!first"> &nbsp; </span>
						<div class="chip-flex-column">
							<mat-chip
								[ngClass]="item.allowMany ? 'chip-primary-multi' : 'chip-primary'"
								cdkDrag
								[selectable]="true"
								[removable]="true"
								(removed)="remove(i)"
							>
								{{ item.title }}
								<mat-icon [ngClass]="item.allowMany ? 'multi-remove' : 'primary-remove'" matChipRemove *ngIf="true">cancel</mat-icon>
							</mat-chip>
							<span *ngIf="item.example">({{ item.example }})</span>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="flex-column">
			<h2 class="description">Available variables</h2>

			<div cdkDropList cdkDropListOrientation="horizontal" [cdkDropListData]="basket" class="drag-drop-box" (cdkDropListDropped)="drop($event)">
				<div class="flexrow">
					<div *ngFor="let item of basket" class="chip-flex-column">
						<mat-chip cdkDrag [selectable]="true" [removable]="false" [ngClass]="item.allowMany ? 'chip-basket-multi' : 'chip-basket'">
							{{ item.title }}<mat-icon>drag_indicator</mat-icon> </mat-chip
						><span *ngIf="item.example">({{ item.example }})</span>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="flex-column buttons">
		<button
			mat-raised-button
			[disabled]="errorMessage?.length > 0 || !isDirty || !!errorMessage?.length"
			color="primary"
			throttleButton
			(throttledClick)="saveChanges()"
		>
			Save
		</button>
	</div>
</mat-card>
