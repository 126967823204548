export function arrayBufferToString(buffer: ArrayBuffer): string {
	return String.fromCharCode.apply(null, new Uint16Array(buffer));
}
  
export function stringToArrayBuffer(string: string): ArrayBuffer {
	const buffer = new ArrayBuffer(string.length * 2); // 2 bytes for each char
	const bufferView = new Uint16Array(buffer);

	for (let index = 0; index <string.length; index++) {
		bufferView[index] = string.charCodeAt(index);
	}

	return buffer;
}