<h2 mat-dialog-title>
	<ng-container *tenantFeature="'DocumentSharing'">
		<span>Share Document</span>
	</ng-container>
	<ng-container *tenantFeatureNot="'DocumentSharing'">
		<span>Manage Share</span>
	</ng-container>
</h2>

<mat-dialog-content>
	<div class="flex-column">
		<ng-container *tenantFeature="'DocumentSharing'">
			<div>Manage third party access to the selected document through the use of a secure link.</div>
			<div class="flex-row add-form">
				<div class="flex-column">
					<div class="flex-row">
						<contact-lookup
							#contactLookup
							Placeholder="Contact"
							[AllowCreate]="false"
							[AllowFreeText]="true"
							[FormControl]="getControl('contactId')"
							[FreeTextFormControl]="getControl('name')"
							[Hint]="'Search for a contact'"
							(Selected)="onContactSelected($event)"
							[Required]="true"
							[matterId]="config?.matterId"
							[showMatterRole]="!!config?.matterId"
						></contact-lookup>
					</div>

					<div class="flex-row">
						<mat-form-field class="email-field">
							<input [formControl]="getControl('email')" matInput autocomplete="off" />
							<mat-placeholder> Email </mat-placeholder>
							<mat-error>
								<error-messages [for]="getControl('email')"></error-messages>
							</mat-error>
						</mat-form-field>

						<mat-form-field class="date-field">
							<mat-placeholder>Date of expiry<sup class="color-warn">*</sup> </mat-placeholder>
							<input [formControl]="getControl('expiry')" matInput [matDatepicker]="datePicker" autocomplete="off" />
							<mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
							<mat-datepicker #datePicker></mat-datepicker>
							<mat-error>
								<error-messages [for]="getControl('expiry')"></error-messages>
							</mat-error>
						</mat-form-field>
					</div>
				</div>
			</div>
			<div class="grant-access">
				<button mat-raised-button color="primary" throttleButton (throttledClick)="onSaveFormClick()" [disabled]="!canSaveForm" title="Grant Access">
					Grant Access
				</button>
			</div>
		</ng-container>

		<mat-table [dataSource]="dataSource" matSort matSortActive="user" matSortDirection="asc">
			<ng-container matColumnDef="name">
				<mat-header-cell *matHeaderCellDef>Access Details</mat-header-cell>
				<mat-cell *matCellDef="let row" class="name-block">
					<div *ngIf="!row.contactId">{{ row?.name }}</div>
					<a *ngIf="!!row.contactId" [routerLink]="['/contacts/' + row.contactId]">{{ row?.name }}</a>

					<div class="embedded-row">
						<div *ngIf="!!row?.email">
							Email: <a [href]="'mailto:' + row?.email">{{ row?.email }}</a>
						</div>
						<div *ngIf="!!row?.expiry">Expires: {{ row?.expiry | moment : 'dd/MM/yyyy' }}</div>
					</div>
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef="actions">
				<mat-header-cell *matHeaderCellDef></mat-header-cell>
				<mat-cell *matCellDef="let row">
					<button matTooltip="Revoke access" mat-icon-button class="action-button" throttleButton (throttledClick)="remove(row)">
						<mat-icon>delete</mat-icon>
					</button>

					<button matTooltip="Email access instructions to guest" mat-icon-button class="action-button" throttleButton (throttledClick)="mailTo(row)">
						<mat-icon>email</mat-icon>
					</button>

					<button matTooltip="Copy Link" mat-icon-button class="action-button" throttleButton (throttledClick)="onCopyAccessLinkButtonClick(row)">
						<mat-icon>link</mat-icon>
					</button>
				</mat-cell>
			</ng-container>

			<mat-header-row *matHeaderRowDef="['name', 'actions']; sticky: true"></mat-header-row>
			<mat-row *matRowDef="let row; columns: ['name', 'actions']"></mat-row>
		</mat-table>
	</div>
</mat-dialog-content>

<mat-dialog-actions>
	<button mat-raised-button mat-dialog-close>Close</button>
</mat-dialog-actions>
