<h2 mat-dialog-title>{{ !data?.isReset ? 'Save' : 'Reset' }} Layout</h2>
<mat-dialog-content>
	<div class="c-layout-changes">
		<div class="c-layout-changes__rows">
			<div class="c-layout-changes__row c-layout-changes__row--headers">
				<span>Previous Value</span>
				<span>New Value</span>
			</div>
			<div class="c-layout-changes__row c-layout-changes__row--title">
				<span>Column</span>
			</div>
			<div class="c-layout-changes__row c-layout-changes__row--changes">
				<span>{{ data.old.sortColumn }}</span>
				<mat-icon class="">east</mat-icon>
				<span>{{ data.new.sortColumn }}</span>
			</div>
			<div class="c-layout-changes__row c-layout-changes__row--title">
				<span>Direction</span>
			</div>
			<div class="c-layout-changes__row c-layout-changes__row--changes">
				<span>{{ data.old.sortDirection === 'desc' ? 'Descending' : 'Ascending' }}</span>
				<mat-icon class="">east</mat-icon>
				<span>{{ data.new.sortDirection === 'desc' ? 'Descending' : 'Ascending' }}</span>
			</div>
			<div class="c-layout-changes__row c-layout-changes__row--title">
				<span>Page Size</span>
			</div>
			<div class="c-layout-changes__row c-layout-changes__row--changes">
				<span>{{ data.old.pageSize }}</span>
				<mat-icon class="">east</mat-icon>
				<span>{{ data.new.pageSize }}</span>
			</div>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions style="place-content: flex-end">
	<button mat-raised-button color="primary" [mat-dialog-close]="true" mat-dialog-close>OK</button>
	<button mat-raised-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
