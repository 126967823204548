import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { InfoTrackService } from '@common/services/infotrack.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash-es';
import moment from 'moment';

import { IAppState } from '../../app.state';
import { InfotrackStatusFilterActions, loading } from './infotrack-status-filter.actions';

@Injectable()
export class InfotrackStatusFilterEffects {
	loadInfotrackStatuses$ = createEffect(() =>
		this._actions$.pipe(
			ofType(
				InfotrackStatusFilterActions.Load,
				InfotrackStatusFilterActions.SetFilters,
				InfotrackStatusFilterActions.InvalidateCache
			),
			map(action => action as { type: InfotrackStatusFilterActions }),
			withLatestFrom(this._store),
			filter(([action, state]) => {
				const infotrackStatusState = state.infotrackStatusFilterData;

				if (action.type === InfotrackStatusFilterActions.InvalidateCache && !infotrackStatusState.list) {
					return false;
				}

				return (
					!infotrackStatusState.cacheExpiry ||
					!!moment().isSameOrAfter(infotrackStatusState.cacheExpiry) ||
					!isEqual(infotrackStatusState.oldRequest, infotrackStatusState.request)
				);
			}),
			debounceTime(500),
			tap(() => this._store.dispatch(loading())),
			switchMap(([_, state]) =>
				this._infotrackService.getStatusList(state.infotrackStatusFilterData.request).pipe(
					map(response => ({
						type: InfotrackStatusFilterActions.LoadSuccess,
						response
					})),
					catchError(error => of({ type: InfotrackStatusFilterActions.LoadFailed, error }))
				)
			)
		)
	);

	constructor(
		private _actions$: Actions,
		private _store: Store<IAppState>,
		private _infotrackService: InfoTrackService
	) {}
}
