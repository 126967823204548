// This file has been generated from TaskStatusUpdateDto.cs
// Do not modify this file directly!!
// tslint:disable

import { TaskStatus } from '../Common/TaskStatus';

// LawMaster.Amalthea.Domain.Tasks.Item.TaskStatusUpdateDto
export class TaskStatusUpdateDto {

	// Ids
	ids: string[];
	// Status
	status: keyof typeof TaskStatus;

	constructor() {

	}
}