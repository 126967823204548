// This file has been generated from DocumentListCsvExportColumnsEnum.cs
// Do not modify this file directly!!

// LawMaster.Amalthea.Domain.Documents.List.DocumentListCsvExportColumnsEnum
export enum DocumentListCsvExportColumnsEnum {
	CreatedDate = 'CreatedDate',
	LastModifiedDate = 'LastModifiedDate',
	MatterTitle = 'MatterTitle',
	MatterNumber = 'MatterNumber',
	AssociatedContactName = 'AssociatedContactName',
	FileName = 'FileName',
	FileExtension = 'FileExtension',
	Type = 'Type',
	CreatedBy = 'CreatedBy',
	LastModifiedBy = 'LastModifiedBy',
	DocumentTags = 'DocumentTags',
	VersionNumber = 'VersionNumber'
}
