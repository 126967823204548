import { Pipe, PipeTransform } from '@angular/core';

import { TaskTemplateDueDateOffsetType } from '@common/models/Settings/TaskTemplates/Common/TaskTemplateDueDateOffsetType';

/*
 * Resolve task template due date offset type pipe display string by the enum key
 */
@Pipe({ name: 'taskTemplateDueDateOffsetType' })
export class TaskTemplateDueDateOffsetTypePipe implements PipeTransform {
	transform(key: keyof typeof TaskTemplateDueDateOffsetType): string {
		if (!TaskTemplateDueDateOffsetType[key]) {
			throw Error(`Dev error: The Task Template Due Date Offset Type enum does not contain the key ${key}`);
		}
		return TaskTemplateDueDateOffsetType[key];
	}
}
