<div *ngIf="isDataLoaded" [ngClass]="isStackedLayout ? 'container-stacked' : 'container'">
	<ng-template #groups>
		<div class="sub-container">
			<div class="header">
				<h3>Groups</h3>
				<span *ngIf="!!currentGroupIdSelected" class="clear-all" throttleButton (throttledClick)="currentGroupIdSelected = null"
					><mat-icon>refresh</mat-icon><a>clear selection</a></span
				>
			</div>

			<div class="groups-list-container">
				<div class="rows" *ngIf="!!selectedGroupIds?.length" cdkDropList (cdkDropListDropped)="dropGroup($event)">
					<ng-container *ngFor="let groupId of selectedGroupIds">
						<div
							[ngClass]="groupId === currentGroupIdSelected ? 'selected' : 'orderable'"
							class="row"
							cdkDrag
							throttleButton
							(throttledClick)="onGroupClick(groupId)"
						>
							<div class="row-info">
								<mat-icon>drag_indicator</mat-icon>
								<span>{{ displayGroupName(groupId) }}</span>
							</div>
							<mat-checkbox
								class="checkbox"
								[indeterminate]="groupCheckboxState(groupId) === 'PARTIAL'"
								[checked]="groupCheckboxState(groupId) === 'FULL'"
								(click)="$event.preventDefault(); $event.stopPropagation(); toggleGroupById(groupId)"
							></mat-checkbox>
						</div>
					</ng-container>
				</div>
				<div class="rows" *ngIf="!!unselectedGroupIds?.length">
					<ng-container *ngFor="let groupId of unselectedGroupIds">
						<div class="row" throttleButton (throttledClick)="(void)">
							<div class="row-info muted">
								<span>{{ displayGroupName(groupId) }}</span>
							</div>
							<mat-checkbox
								class="checkbox"
								[indeterminate]="groupCheckboxState(groupId) === 'PARTIAL'"
								[checked]="groupCheckboxState(groupId) === 'FULL'"
								(click)="$event.preventDefault(); $event.stopPropagation(); toggleGroupById(groupId)"
							></mat-checkbox>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</ng-template>
	<ng-template #fields>
		<div class="sub-container">
			<form [formGroup]="fieldSearchForm">
				<mat-form-field class="fieldsearch" appearance="fill">
					<mat-label>Search custom fields</mat-label>
					<input #autoCompleteInput type="text" matInput formControlName="customFieldSearch" [matAutocomplete]="autoGroup" />
					<mat-autocomplete #autoGroup="matAutocomplete" (optionSelected)="autocompleteSelected($event)">
						<mat-optgroup *ngFor="let opt of fieldSearchOptions$ | async" [label]="opt.group.name">
							<mat-option *ngFor="let customField of opt.customFields" [value]="{ group: opt, customField }">
								{{ customField.name }}
							</mat-option>
						</mat-optgroup>
					</mat-autocomplete>
				</mat-form-field>
			</form>

			<div class="fields-list-container" *ngIf="!!selectedGroups?.length">
				<div [id]="group.customFieldGroupId" *ngFor="let group of selectedGroups">
					<div class="rows" *ngIf="!!group.customFieldIds?.length" cdkDropList (cdkDropListDropped)="dropField($event, group.customFieldGroupId)">
						<ng-container *ngFor="let fieldId of group.customFieldIds">
							<div
								class="row"
								[ngClass]="currentGroupIdSelected === group.customFieldGroupId ? 'orderable' : ''"
								cdkDrag
								throttleButton
								(throttledClick)="(void)"
							>
								<div class="row-info">
									<mat-icon *ngIf="currentGroupIdSelected === group.customFieldGroupId">drag_indicator</mat-icon>
									<span>{{ displayCustomFieldName(fieldId) }}</span>
								</div>
								<button mat-icon-button throttleButton (throttledClick)="toggleCustomField(group.customFieldGroupId, fieldId)">
									<mat-icon matToolTip="Remove from list">remove_circle_outline</mat-icon>
								</button>
							</div>
						</ng-container>
					</div>
				</div>
			</div>
		</div>
	</ng-template>

	<mat-accordion *ngIf="!!isStackedLayout">
		<mat-expansion-panel (opened)="fieldsPanelOpenState = true" (closed)="fieldsPanelOpenState = false">
			<mat-expansion-panel-header *ngIf="!fieldsPanelOpenState">
				<mat-panel-description>Search by custom field name</mat-panel-description>
			</mat-expansion-panel-header>
			<ng-container *ngTemplateOutlet="fields"></ng-container>
		</mat-expansion-panel>
		<mat-expansion-panel (opened)="groupsPanelOpenState = true" (closed)="groupsPanelOpenState = false">
			<mat-expansion-panel-header *ngIf="!groupsPanelOpenState">
				<mat-panel-description>Pick custom field groups</mat-panel-description>
			</mat-expansion-panel-header>
			<ng-container *ngTemplateOutlet="groups"></ng-container>
		</mat-expansion-panel>
	</mat-accordion>

	<ng-container *ngIf="!isStackedLayout">
		<ng-container *ngTemplateOutlet="groups"></ng-container>
		<ng-container *ngTemplateOutlet="fields"></ng-container>
	</ng-container>
</div>
