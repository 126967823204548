import { CurrentUserEffects } from '@common/state/effects/current-user.effects';
import { JobsEffects } from '@common/state/effects/jobs.effects';
import { TenantEffects } from '@common/state/effects/tenant.effects';

import { CostCodeFilterEffects } from './filters/cost-code-filter/cost-code-filter.effects';
import { DocumentCategoryFilterEffects } from './filters/document-category-filter/document-category-filter.effects';
import { InfotrackStatusFilterEffects } from './filters/infotrack-status-filter/infotrack-status-filter.effects';
import { JobStatusFilterEffects } from './filters/job-status-fiiter/job-status-filter.effects';
import { MatterRoleFilterEffects } from './filters/matter-role-fiiter/matter-role-filter.effects';
import { PracticeAreaFilterEffects } from './filters/practice-area-filter/practice-area-filter.effects';
import { TrustAccountFilterEffects } from './filters/trust-account-filter/trust-account-filter.effects';
import { BillListEffects } from './lists/bill-list/bill-list.effects';
import { CalculationVariableListEffects } from './lists/calculation-variable-list/calculation-variable-list.effect';
import { ContactListEffects } from './lists/contact-list/contact-list.effects';
import { CostListEffects } from './lists/cost-list/cost-list.effects';
import { CustomReportListEffects } from './lists/custom-report-list/custom-report-list.effects';
import { DocumentListEffects } from './lists/document-list/document-list.effects';
import { InfoTrackListEffects } from './lists/infotrack-list/infotrack-list.effects';
import { MatterListEffects } from './lists/matter-list/matter-list.effects';
import { PracticeAreaListEffects } from './lists/settings/practice-area-list/practice-area-list.effects';
import { TaskListEffects } from './lists/task-list/task-list.effects';
import { TrustListEffects } from './lists/trust-list/trust-list.effects';
import { MatterNumberingConfigEffects } from './misc/matter-numbering-config/matter-numbering-config.effects';
import { PracticeAreaStagesEffects } from './misc/practice-area-stages/practice-area-stages.effects';
import { TenantCompanyEffects } from './misc/tenant-company/tenant-company.effects';
import { TimeZoneListEffects } from './misc/time-zone-list/time-zone-list.effects';
import { BankAccountListEffects } from './lists/settings/bank-account-list/bank-account-list.effects';
import { UserTypeListEffects } from './lists/settings/user-type-list/user-type-list.effects';
import { TrustAccountListEffects } from './lists/settings/trust-account-list/trust-account-list.effects';
import { ReferralReasonListEffects } from './lists/settings/referral-reason-list/referral-reason-list.effects';
import { BasicWorkflowListEffects } from './lists/settings/matter-workflow-list/basic-workflow-list.effects';
import { DocumentTagListEffects } from './lists/settings/document-tag-list/document-tag-list.effects';
import { CostTemplateListEffects } from './lists/settings/cost-template-list/cost-template-list.effects';
import { DocumentBriefTemplateListEffects } from './lists/settings/document-brief-template-list/document-brief-template-list.effects';
import { CostCodeListEffects } from './lists/settings/cost-code-list/cost-code-list.effects';
import { MatterCustomFieldListEffects } from './lists/settings/matter-custom-field-list/matter-custom-field-list.effects';
import { ContactCustomFieldListEffects } from './lists/settings/contact-custom-field-list/contact-custom-field-list.effects';

export const effects = [
	// Misc
	CurrentUserEffects,
	TenantEffects,
	JobsEffects,
	PracticeAreaStagesEffects,
	MatterNumberingConfigEffects,
	TenantCompanyEffects,

	// Lists
	MatterListEffects,
	ContactListEffects,
	CostListEffects,
	InfoTrackListEffects,
	TaskListEffects,
	CalculationVariableListEffects,
	CustomReportListEffects,
	TimeZoneListEffects,
	PracticeAreaListEffects,
	BillListEffects,
	TrustListEffects,
	BankAccountListEffects,
	UserTypeListEffects,
	TrustAccountListEffects,
	ReferralReasonListEffects,
	BasicWorkflowListEffects,
	DocumentTagListEffects,
	CostTemplateListEffects,
	DocumentBriefTemplateListEffects,
	CostCodeListEffects,
	MatterCustomFieldListEffects,
	ContactCustomFieldListEffects,
	DocumentListEffects,

	// Filters
	CostCodeFilterEffects,
	DocumentCategoryFilterEffects,
	InfotrackStatusFilterEffects,
	JobStatusFilterEffects,
	MatterRoleFilterEffects,
	PracticeAreaFilterEffects,
	TrustAccountFilterEffects
];
