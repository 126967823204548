// This file has been generated from StageDto.cs
// Do not modify this file directly!!
// tslint:disable

import { SetSimpleType } from '@common/utils/class-transform';



// LawMaster.Amalthea.Domain.Settings.PracticeAreas.Item.StageDto
export class StageDto {

	// Id
	id: number;
	// Name
	name: string;
	// Description
	description: string;
	// IsMatterClosed
	@SetSimpleType(Boolean)
	isMatterClosed: boolean;
	// UsedByMatters
	usedByMatters: number;

	constructor() {

	}
}