import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { DocumentTagListRequest } from '@common/models/Settings/DocumentTags/List/DocumentTagListRequest';

@Component({
	selector: 'document-tag-list-filter-dialog',
	styleUrls: ['./document-tag-list-filter-dialog.component.scss'],
	templateUrl: './document-tag-list-filter-dialog.component.html'
})
export class DocumentTagListFilterDialogComponent {
	currentFilter: Partial<DocumentTagListRequest>;

	constructor(
		@Inject(MAT_DIALOG_DATA) public request: Partial<DocumentTagListRequest>,
		private dialogRef: MatDialogRef<DocumentTagListFilterDialogComponent>
	) {}

	apply() {
		this.dialogRef.close(this.currentFilter);
	}

	clear() {
		this.dialogRef.close({});
	}

	rootFilterChange($event: Partial<DocumentTagListRequest>) {
		this.currentFilter = $event;
	}
}
