<h2 mat-dialog-title class="title">Expression Builder</h2>
<mat-dialog-content>
	<div class="c-sidebar">
		<div class="c-search">
			<mat-form-field class="c-search__container">
				<input [formControl]="searchControl" matInput placeholder="Search" autocomplete="off" />
				<button *ngIf="!!searchValue" matSuffix mat-icon-button aria-label="Clear" throttleButton (throttledClick)="clearSearch()">
					<mat-icon>close</mat-icon>
				</button>
				<mat-error>
					<error-messages [for]="expressionControl"></error-messages>
				</mat-error>
			</mat-form-field>
		</div>

		<mat-tab-group *ngIf="!!variables?.length || !!operators?.length || !!functions?.length || !!constants?.length">
			<mat-tab label="Operators" *ngIf="!!operators?.length">
				<div *ngFor="let operator of operators" class="c-sidebar__option" matRipple throttleButton (throttledClick)="onOperatorClicked(operator)">
					<div class="c-sidebar__option__title">{{ operator.name }}</div>
					<div class="c-sidebar__option__description">{{ operator.symbol }}</div>
				</div>
			</mat-tab>
			<mat-tab label="Variables" *ngIf="!!variables?.length">
				<div *ngFor="let variable of variables" class="c-sidebar__option" matRipple throttleButton (throttledClick)="onVariableClicked(variable)">
					<div class="c-sidebar__option__title">{{ variable.name }}</div>
					<div class="c-sidebar__option__description" *ngIf="!variable.isCustomField">Field</div>
					<div class="c-sidebar__option__description" *ngIf="!!variable.isCustomField">Custom Field</div>
				</div>
			</mat-tab>
			<mat-tab label="Functions" *ngIf="!!functions?.length">
				<div *ngFor="let function of functions" class="c-sidebar__option" matRipple throttleButton (throttledClick)="onFunctionClicked(function)">
					<div class="c-sidebar__option__title">{{ function.name }}</div>

					<div class="c-sidebar__option__parameters" *ngIf="!!function.parameters?.length">
						<div class="c-sidebar__option__parameters_title">Parameters</div>

						<div class="c-sidebar__option__parameters__list">
							<span *ngFor="let parameter of function.parameters">{{ parameter }}</span>
						</div>
					</div>
				</div>
			</mat-tab>
			<mat-tab label="Constants" *ngIf="!!constants?.length">
				<div *ngFor="let constant of constants" class="c-sidebar__option" matRipple throttleButton (throttledClick)="onConstantClicked(constant)">
					<div class="c-sidebar__option__title">{{ constant.name }}</div>
				</div>
			</mat-tab>
		</mat-tab-group>
	</div>

	<div class="c-content">
		<div class="c-content__controls">
			<div class="c-content__expression">
				<mat-form-field class="c-content__expression__container">
					<mat-placeholder>Expression <sup class="color-warn">*</sup> </mat-placeholder>
					<textarea
						#expressionInput
						[formControl]="expressionControl"
						matInput
						cdkTextareaAutosize
						[cdkAutosizeMinRows]="4"
						autocomplete="off"
					></textarea>
					<button *ngIf="!!expressionValue" matSuffix mat-icon-button aria-label="Clear" throttleButton (throttledClick)="clearExpression()">
						<mat-icon>close</mat-icon>
					</button>
					<mat-error>
						<error-messages [for]="expressionControl"></error-messages>
					</mat-error>
				</mat-form-field>
			</div>

			<matter-lookup Placeholder="Preview Matter" [FormControl]="entityIdControl" *ngIf="isMatterType"></matter-lookup>
			<contact-lookup Placeholder="Preview Contact" [FormControl]="entityIdControl" *ngIf="isContactType"></contact-lookup>

			<div class="c-content__result">
				<div class="c-content__result__title">Preview</div>
				<div class="c-content__result__value" *ngIf="!!expressionResult">
					{{ isDate(expressionResult) ? (toLocalDate(expressionResult) | moment : 'dd/MM/yyyy hh:mm:ss a') : expressionResult }}
				</div>
				<div class="c-content__result__value c-content__result__value--nothing" *ngIf="!expressionResult">No result</div>
			</div>
		</div>

		<div class="c-content__examples">
			<div class="c-content__examples__title">Examples</div>

			<div class="c-example" *ngIf="!!isMatterType">
				<div class="c-example__heading">Construct String</div>

				<div class="c-example__body">
					<div class="c-example__column c-example__column--double">
						<div class="c-example__title">Expression</div>
						<div class="c-example__description">Matter__Number + " - " + Matter__Title</div>
					</div>
					<div class="c-example__column">
						<div class="c-example__title">Result</div>
						<div class="c-example__description">53 - Enright vs Herbet</div>
					</div>
				</div>
			</div>

			<div class="c-example" *ngIf="!!isMatterType">
				<div class="c-example__heading">To Lowercase</div>

				<div class="c-example__body">
					<div class="c-example__column c-example__column--double">
						<div class="c-example__title">Expression</div>
						<div class="c-example__description">Lower(Matter__Title)</div>
					</div>
					<div class="c-example__column">
						<div class="c-example__title">Result</div>
						<div class="c-example__description">enright vs herbet</div>
					</div>
				</div>
			</div>

			<div class="c-example" *ngIf="!!isContactType">
				<div class="c-example__heading">Construct String</div>

				<div class="c-example__body">
					<div class="c-example__column c-example__column--double">
						<div class="c-example__title">Expression</div>
						<div class="c-example__description">Contact__FirstName + ", " + Contact__LastName</div>
					</div>
					<div class="c-example__column">
						<div class="c-example__title">Result</div>
						<div class="c-example__description">George, Enright</div>
					</div>
				</div>
			</div>

			<div class="c-example" *ngIf="!!isContactType">
				<div class="c-example__heading">To Lowercase</div>

				<div class="c-example__body">
					<div class="c-example__column c-example__column--double">
						<div class="c-example__title">Expression</div>
						<div class="c-example__description">Lower(Contact__FullName)</div>
					</div>
					<div class="c-example__column">
						<div class="c-example__title">Result</div>
						<div class="c-example__description">enright, george</div>
					</div>
				</div>
			</div>

			<div class="c-example">
				<div class="c-example__heading">List Maximum</div>

				<div class="c-example__body">
					<div class="c-example__column c-example__column--double">
						<div class="c-example__title">Expression</div>
						<div class="c-example__description">AggregateMax([5, 3, 2, 1])</div>
					</div>
					<div class="c-example__column">
						<div class="c-example__title">Result</div>
						<div class="c-example__description">5</div>
					</div>
				</div>
			</div>

			<div class="c-example">
				<div class="c-example__heading">Conditional</div>

				<div class="c-example__body">
					<div class="c-example__column c-example__column--double">
						<div class="c-example__title">Expression</div>
						<div class="c-example__description">If(5 > 3, "Greater", "Less")</div>
					</div>
					<div class="c-example__column">
						<div class="c-example__title">Result</div>
						<div class="c-example__description">Greater</div>
					</div>
				</div>
			</div>

			<div class="c-example">
				<div class="c-example__heading">Area of a Circle</div>

				<div class="c-example__body">
					<div class="c-example__column c-example__column--double">
						<div class="c-example__title">Expression</div>
						<div class="c-example__description">(CustomField__Radius^2) * Pi</div>
					</div>
					<div class="c-example__column">
						<div class="c-example__title">Result</div>
						<div class="c-example__description">78.53981633974483</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-raised-button color="primary" throttleButton (throttledClick)="onSaveClicked()" [disabled]="!formValid">Done</button>
	<button mat-raised-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
