<mat-form-field>
	<mat-placeholder *ngIf="placeHolder"
		>{{ placeHolder }}
		<sup class="color-warn" *ngIf="required">*</sup>
	</mat-placeholder>
	<input matInput #trigger="matAutocompleteTrigger" #autoCompleteInput [matAutocomplete]="auto" [formControl]="formControl" />
	<button *ngIf="formControl.value" matSuffix mat-icon-button aria-label="Clear" throttleButton (throttledClick)="clearInput()">
		<mat-icon>close</mat-icon>
	</button>
	<button *ngIf="!formControl.value" matSuffix mat-icon-button aria-label="Open Dropdown" throttleButton (throttledClick)="openPanel()">
		<mat-icon>arrow_drop_down</mat-icon>
	</button>
	<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" [displayWith]="displayFn.bind(this)">
		<mat-option *ngFor="let option of filteredOptions$ | async" [value]="option">
			{{ getName(option) }}
		</mat-option>
	</mat-autocomplete>
	<mat-hint *ngIf="!!hintText?.length">{{ hintText }}</mat-hint>
	<mat-error>
		<error-messages [for]="control"></error-messages>
	</mat-error>
</mat-form-field>
