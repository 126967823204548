<h2 mat-dialog-title class="title">{{ isCreateMode ? 'Create' : 'Edit' }} Document Template Group</h2>
<mat-dialog-content [formGroup]="form" class="flex-column">
	<div class="flex-row">
		<mat-form-field>
			<mat-placeholder
				>Name
				<sup class="color-warn">*</sup>
			</mat-placeholder>
			<input #documentCategoryInput matInput formControlName="name" />
			<mat-error>
				<error-messages [for]="form.get('name')"></error-messages>
			</mat-error>
		</mat-form-field>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<div>
		<button mat-raised-button color="primary" [mat-dialog-close]="form.value" [disabled]="form.invalid">Ok</button>
		<button mat-raised-button mat-dialog-close>Cancel</button>
	</div>
</mat-dialog-actions>
