import { Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { filter, switchMap } from 'rxjs/operators';

import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';
import { ReferralReasonDto } from '@common/models/Settings/ReferralReasons/Item/ReferralReasonDto';
import { ReferralReasonListItemDto } from '@common/models/Settings/ReferralReasons/List/ReferralReasonListItemDto';
import { DomainError } from '@common/models/Validation/DomainError';
import { NotificationService } from '@common/notification';
import { ReferralReasonsService } from '@common/services/settings/referralreasons.service';
import { Store } from '@ngrx/store';

import { IAppState } from 'app/core/state/app.state';
import { ReferralReasonListActions } from 'app/core/state/lists/settings/referral-reason-list/referral-reason-list.actions';
import {
	selectReferralReasonIsFetching,
	selectReferralReasonListRecords,
	selectReferralReasonListRequest,
	selectReferralReasonListTotalRecords
} from 'app/core/state/lists/settings/referral-reason-list/referral-reason-list.selectors';
import { GridViewService } from 'app/services/grid-view.service';
import {
	ActionTypes,
	GenericListStateComponent,
	SelectorTypes
} from 'app/shared/generics/generic.list.state.component';

import { ReferralReasonItemComponent } from '../item/referral-reason-item.component';

@Component({
	styleUrls: ['./referral-reason-list.component.scss'],
	templateUrl: './referral-reason-list.component.html'
})
export class ReferralReasonListComponent
	extends GenericListStateComponent<ReferralReasonListItemDto, void>
	implements OnDestroy
{
	get actions(): ActionTypes {
		return {
			init: ReferralReasonListActions.Init,
			load: ReferralReasonListActions.Load,
			setFilters: ReferralReasonListActions.SetFilters,
			setPageIndex: ReferralReasonListActions.SetPageIndex,
			setPageIndexForId: ReferralReasonListActions.SetPageIndexForId,
			setPageSize: ReferralReasonListActions.SetPageSize,
			setSortBy: ReferralReasonListActions.SetSortBy,
			setSortDirection: ReferralReasonListActions.SetSortDirection,
			selected: ReferralReasonListActions.SelectRecords
		};
	}
	get selectors(): SelectorTypes {
		return {
			records: selectReferralReasonListRecords,
			isFetching: selectReferralReasonIsFetching,
			request: selectReferralReasonListRequest,
			totalRecords: selectReferralReasonListTotalRecords
		};
	}
	constructor(
		store: Store<IAppState>,
		dialog: MatDialog,
		router: Router,
		activatedRoute: ActivatedRoute,
		private referralReasonsService: ReferralReasonsService,
		private notificationService: NotificationService,
		gridViewService: GridViewService
	) {
		super(['name', 'description', 'actions'], dialog, store, router, activatedRoute, gridViewService);
	}

	createReferralReason() {
		this.subscriptions.add(
			this.dialog
				.open(ReferralReasonItemComponent, { data: new ReferralReasonDto() })
				.afterClosed()
				.pipe(
					filter(Boolean),
					switchMap((data: ReferralReasonDto) => this.referralReasonsService.createReferralReason(data))
				)
				.subscribe(this.onCreateSuccess, this.onError)
		);
	}

	editReferralReason(row: ReferralReasonListItemDto) {
		this.subscriptions.add(
			this.dialog
				.open(ReferralReasonItemComponent, { data: row })
				.afterClosed()
				.pipe(
					filter(Boolean),
					switchMap((data: ReferralReasonDto) => {
						return this.referralReasonsService.updateReferralReason(row.id, { ...data, id: row.id });
					})
				)
				.subscribe(this.onUpdateSuccess, this.onError)
		);
	}

	deleteReferralReason(row: ReferralReasonListItemDto) {
		this.subscriptions.add(
			this.notificationService
				.showConfirmation(
					'Delete Referral Reason',
					`Are you sure you want to delete the referral reason "${row.name}"?`
				)
				.pipe(
					filter(Boolean),
					switchMap(() => this.referralReasonsService.deleteReferralReason(row.id))
				)
				.subscribe({
					next: response => {
						this.notificationService.showNotification(`Referral Reason deleted: ${response.name}`);
						this.store.dispatch({ type: ReferralReasonListActions.RemoveRecords, response: response });
					},
					error: error => this.notificationService.showErrors('Error deleting Referral Reason', error)
				})
		);
	}

	private onError = (errors: DomainError[]) => {
		this.notificationService.showErrors('Error', errors);
	};

	private onCreateSuccess = (result: MutationResponseDto) => {
		this.notificationService.showNotification(`Referral Reason ${result.name} created`);
		this.store.dispatch({ type: ReferralReasonListActions.InsertRecords, response: result });
	};

	private onUpdateSuccess = (result: MutationResponseDto) => {
		this.notificationService.showNotification(`Referral Reason ${result.name} updated`);
		this.store.dispatch({ type: ReferralReasonListActions.UpdateRecords, response: result });
	};
}
