import { Pipe, PipeTransform } from '@angular/core';

import { TaskType } from '@common/models/Tasks/Common/TaskType';

/*
 * Resolve task status display string by the enum key
 */
@Pipe({ name: 'taskType' })
export class TaskTypePipe implements PipeTransform {
	transform(key: keyof typeof TaskType): string {
		if (!TaskType[key]) {
			throw Error(`Dev error: The Task Status enum does not contain the key ${key}`);
		}

		return TaskType[key];
	}
}
