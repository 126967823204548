// This file has been generated from TimeZoneController.cs
// Do not modify this file directly!!
// tslint:disable

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { TrimStringsOnObject } from '@common/utils/string-format';

import { TimeZoneDto } from '../../models/Common/TimeZoneDto';

@Injectable({
	providedIn: 'root'
})
export class TimeZoneService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'api/v1/settings/timezone');
	}

	updateTimeZone(dto: TimeZoneDto): Observable<any> {
		return this.put('', TransformDatesOnObject(TimeZoneDto, TrimStringsOnObject(dto)));
	}

	getAvailableTimeZones(): Observable<string[]> {
		return this.getArray<void, string>('');
	}

	getCurrentTimeZone(): Observable<string> {
		return this.getItem<void, string>(`current`);
	}

}
