<mat-form-field [style.font-size]="FontSize">
	<mat-placeholder
		>{{ Placeholder }}
		<sup class="color-warn" *ngIf="Required">*</sup>
	</mat-placeholder>
	<mat-hint>{{ Hint }}</mat-hint>
	<input
		matInput
		#autoCompleteInput
		[matAutocomplete]="auto"
		[formControl]="inputDisplayCtrl"
		[value]="inputDisplayCtrl.value"
		throttleButton
		(throttledClick)="inputClicked()"
		[autofocus]="HasAutofocus"
		(keyup.esc)="escPressed()"
		(keydown.tab)="tabPressed()"
		(focus)="inputFocused($event)"
		[attr.disabled]="!!disabled ? true : null"
	/>
	<button *ngIf="!!FormControl.value || !!inputDisplayCtrl.value" matSuffix mat-icon-button aria-label="Clear" throttleButton (throttledClick)="clearValue()">
		<mat-icon>close</mat-icon>
	</button>
	<mat-error>
		<error-messages [for]="FormControl"></error-messages>
	</mat-error>

	<mat-autocomplete #auto="matAutocomplete" (optionSelected)="autocompleteSelected($event)">
		<mat-option *ngFor="let option of options" [value]="option.id">
			<div [innerHtml]="optionHtmlText(option) | safeHtml"></div>
		</mat-option>
		<mat-option [disabled]="true" *ngIf="inputDisplayCtrl.value && options?.length == 0">
			<div *ngIf="isSearching" class="searching">Searching...</div>
			<div *ngIf="noLookupResults && inputDisplayCtrl.value && options?.length == 0" class="no-matches">No matches found</div>
		</mat-option>
	</mat-autocomplete>
</mat-form-field>
