import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { GlobalListPersonalization } from '@common/models/Common/GlobalListPersonalization';
import { JobListRequest } from '@common/models/Settings/Jobs/List/JobListRequest';
import { GetRoutePaths } from '@common/utils/router-util';
import { isEmpty, isUndefined } from 'lodash-es';

import { JobListFilterDialogComponent } from '../filter-dialog/job-list-filter-dialog.component';
import { JobListFilterComponent } from '../filter/job-list-filter.component';
import { JobListFilterConfig } from '../filter/JobListFilterConfig';
import { JobListComponent } from './job-list.component';

@Component({
	selector: 'global-job-list',
	styleUrls: ['./global-job-list.component.scss'],
	templateUrl: './global-job-list.component.html'
})
export class GlobalJobListComponent implements OnDestroy, OnInit {
	@ViewChild(JobListFilterComponent, { static: true })
	filter: JobListFilterComponent;
	@ViewChild('list')
	jobsList: JobListComponent;
	selectedTabIndex: number = 0;
	showUser: boolean = false;
	isMobile: boolean = false;
	private subscriptions: Subscription = new Subscription();
	private isNavigatedOnTabChange: boolean;

	filterDialog = JobListFilterDialogComponent;

	filterRequest: Partial<JobListRequest> = {
		search: null,
		userId: null,
		status: null,
		startedTo: null,
		startedFrom: null,
		completedFrom: null,
		completedTo: null,
		lastUpdateFrom: null,
		lastUpdateTo: null,
		isComplete: null,
		myJobs: null
	};

	constructor(private router: Router, private breakpoint: BreakpointObserver, private cdr: ChangeDetectorRef) {}

	ngOnInit(): void {
		this.subscriptions.add(
			this.breakpoint.observe([Breakpoints.Handset]).subscribe(result => {
				this.isMobile = result.matches;
				if (this.isMobile) {
					this.toggleFilters(false);
				}
				this.cdr.detectChanges();
			})
		);

		const urlTree = this.router.parseUrl(this.router.url);
		this.isNavigatedOnTabChange = isEmpty(urlTree.queryParams);
		const routePaths = GetRoutePaths(urlTree);
		this.arrangeTabInstance(
			routePaths.includes('my') ? GlobalListPersonalization.My : GlobalListPersonalization.All
		);
	}
	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	get config(): JobListFilterConfig {
		return {
			request: this.filterRequest,
			showUser: this.showUser
		};
	}

	selectedTabChanged(tabEvent: MatTabChangeEvent): void {
		console.log(`event: ${tabEvent.index} selected: ${this.selectedTabIndex}`);
		if (this.selectedTabIndex !== tabEvent.index) {
			switch (tabEvent.index) {
				case 0:
					this.router.navigate(['/system/jobs/my']);
					this.arrangeTabInstance(GlobalListPersonalization.My);
					break;
				case 1:
					this.router.navigate(['/system/jobs']);
					this.arrangeTabInstance(GlobalListPersonalization.All);
					break;
			}
		}
	}

	toggleFilters(value?: boolean): void {
		this.filter.root.showFilters = this.isMobile
			? false
			: isUndefined(value)
			? !this.filter.root.showFilters
			: value;
	}

	private arrangeTabInstance(activeTab: GlobalListPersonalization): void {
		switch (activeTab) {
			case GlobalListPersonalization.My:
				this.selectedTabIndex = 0;
				this.showUser = false;
				this.applyMyJobs();
				break;
			case GlobalListPersonalization.All:
				this.selectedTabIndex = 1;
				this.showUser = true;
				this.applyAllJobs();
				break;
			default:
				throw new Error('Invalid list tab value');
		}
	}

	private applyMyJobs(): void {
		this.showUser = false;
		const defaults: Partial<JobListRequest> = {
			...this.filterRequest,
			userId: null,
			myJobs: true
		};

		this.filterRequest = defaults;
		this.filter.root.resetFilter(this.filterRequest);
	}

	private applyAllJobs(): void {
		this.showUser = true;
		const defaults: Partial<JobListRequest> = {
			...this.filterRequest,
			myJobs: null
		};

		this.filterRequest = defaults;
		this.filter.root.resetFilter(this.filterRequest);
	}
}
