import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { AsyncValidatorFn, ValidationErrors } from '@angular/forms';

import { of, Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { MatterStatus } from '@common/models/Matters/Common/MatterStatus';
import { MatterLookupDto } from '@common/models/Matters/Lookup/MatterLookupDto';
import { TransactionType } from '@common/models/Trust/Common/TransactionType';
import { MattersService } from '@common/services/matters.service';
import { TrustService } from '@common/services/trust.service';
import { lockedObservable } from '@common/utils/lockUtils';
import { Store } from '@ngrx/store';
import AwaitLock from 'await-lock';
import { isNil } from 'lodash';

import { getCurrentPage } from 'app/core/state/misc/current-page/current-page.reducer';
import { CurrentPageType, ICurrentPageState } from 'app/core/state/misc/current-page/current-page.state';
import { MatterLookupComponent } from 'app/shared/components/matter-lookup.component';
import { round } from 'app/shared/utils/mathUtil';

@Component({
	selector: 'matter-allocations',
	styleUrls: ['./matter-allocations.component.scss'],
	templateUrl: './matter-allocations.component.html'
})
export class MatterAllocationsComponent implements AfterViewInit, OnDestroy {
	@Input()
	allocationGroup: FormGroup;
	@Input()
	trustAccountId: string;
	@Input()
	totalTrustAccounBalance: number;
	@Input()
	statutoryMatterId: string;
	@Input()
	hideDelete: boolean;
	@Input()
	index: number;
	@Input()
	transactionType: keyof typeof TransactionType;
	@Output()
	addAllocationClicked = new EventEmitter();
	@Output()
	removeAllocationClicked = new EventEmitter<number>();

	@ViewChild('amountCtrl', { read: ElementRef })
	amountCtrl: ElementRef;

	@ViewChild('matterLookup', { static: true })
	matterLookup: MatterLookupComponent;
	totalBalance: number;
	clearedBalance: number;
	warningText: string;

	@Input()
	hideDescription: boolean;

	private getMatterHasActiveRecordsCache: { [trustAccountId: string]: boolean } = {};
	private lock = new AwaitLock();

	private subscriptions: Subscription = new Subscription();

	get hintText(): string {
		const totalHint = this.totalBalance !== undefined ? `Total Balance : $${round(this.totalBalance)} ` : '';
		const clearedHint =
			this.clearedBalance !== undefined
				? ` Cleared balance : $${this.clearedBalance > 0 ? round(this.clearedBalance) : 0}`
				: '';

		return totalHint + clearedHint;
	}

	constructor(
		private matterService: MattersService,
		private trustService: TrustService,
		private store: Store<ICurrentPageState>
	) {}

	getAllocationGroupControl(name: string): FormControl {
		return this.allocationGroup.get(name) as FormControl;
	}

	ngAfterViewInit() {
		if (this.index === 0) {
			this.defaultToCurrentOpenMatter();
		}
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	removeAllocation() {
		this.removeAllocationClicked.emit(this.index);
		this.matterLookup = null;
	}

	addAllocation() {
		this.addAllocationClicked.emit();
	}

	onMatterSelected() {
		this.subscriptions.add(
			this.matterService.getMatterTrustBalance(this.matterLookup.selectedValue.id).subscribe(x => {
				this.clearedBalance = round(x.clearedBalance);
				this.totalBalance = round(x.totalBalance);
				this.allocationGroup.updateValueAndValidity();
			})
		);

		this.allocationGroup.parent.addAsyncValidators([this.trustAccountValidator()]);
	}

	private validateMatterHasTrustAccount$(): Observable<ValidationErrors> {
		if (
			!isNil(this.matterLookup.selectedValue.trustAccountId) &&
			this.matterLookup.selectedValue.trustAccountId !== this.trustAccountId
		) {
			return this.getMatterHasActiveRecords$(this.matterLookup.selectedValue.id).pipe(
				map(hasActiveRecords => {
					if (!!hasActiveRecords) {
						return {
							error: `Matter '${this.matterLookup.selectedValue.number}' is not associated to the selected trust account`
						};
					} else {
						return null;
					}
				})
			);
		} else {
			return of(null);
		}
	}

	private trustAccountValidator(): AsyncValidatorFn {
		return () => {
			this.warningText = '';
			if (this.trustAccountId && this.matterLookup && this.matterLookup.selectedValue) {
				return this.validateMatterHasTrustAccount$().pipe(
					map(errors => {
						if (!!errors) {
							return errors;
						}

						if (
							this.transactionType === 'Payment' &&
							this.allocationGroup.get('amount') &&
							this.allocationGroup.get('amount').value
						) {
							const amount: number = round(this.allocationGroup.get('amount').value);
							// If Payment Amount > Cleared Funds AND there are Uncleared Funds on Matter
							if (amount > this.clearedBalance && this.totalBalance > this.clearedBalance) {
								return {
									error: `Insufficient funds available for matter '${
										this.matterLookup.selectedValue.number
									}'.
									This transaction will overdraw cleared funds by $${round(
										amount - this.clearedBalance
									)}. Trust funds must be cleared prior to proceeding.`
								};
							}
							// If Payment Amount > Cleared Funds AND there are no Uncleared Funds on Matter
							else if (amount > this.totalBalance && this.totalBalance === this.clearedBalance) {
								if (amount > this.totalTrustAccounBalance) {
									this.warningText = `This transaction will overdraw cleared matter
										funds by $${round(amount - this.clearedBalance)}.`;
								} else {
									this.warningText = `This transaction will overdraw cleared funds by $${round(
										amount - this.clearedBalance
									)}.`;
								}

								if (this.statutoryMatterId === this.matterLookup?.selectedValue?.id) {
									this.warningText +=
										' This payment will not be reported as part of the Trust Overdrawn Report';
								} else {
									this.warningText =
										`Insufficient funds available in trust for matter '${this.matterLookup.selectedValue.number}'. ` +
										this.warningText;
								}
							}
						}

						return null;
					})
				);
			}

			return of(null);
		};
	}

	private getMatterHasActiveRecords$(matterId: string): Observable<boolean> {
		return lockedObservable(this.lock, () => {
			if (Object.keys(this.getMatterHasActiveRecordsCache).filter(key => key === matterId).length > 0) {
				return of(this.getMatterHasActiveRecordsCache[matterId]);
			}

			return this.trustService
				.getAnyActiveRecordsForMatter(matterId)
				.pipe(tap(result => (this.getMatterHasActiveRecordsCache[matterId] = result)));
		});
	}

	private defaultToCurrentOpenMatter() {
		this.subscriptions.add(
			this.store
				.select(getCurrentPage)
				.pipe(
					filter(page => page.id !== null),
					map(page => this.isPageWithOpenMatter(page))
				)
				.subscribe(page => {
					var lookup = page.lookup as MatterLookupDto;
					if (lookup) {
						this.matterLookup.setValue(lookup);
					}
				})
		);
	}

	private isPageWithOpenMatter(page: ICurrentPageState): ICurrentPageState {
		if (page.pageType === CurrentPageType.Matter) {
			if ((page.lookup as MatterLookupDto).status === MatterStatus.Open) {
				return page;
			}
			return { id: '', lookup: null, pageType: page.pageType };
		}
		return { id: '', lookup: null, pageType: page.pageType };
	}
}
