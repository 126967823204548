import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { forkJoin, Observable, Subscription } from 'rxjs';

import { EntityReference } from '@common/models/Common/EntityReference';
import { CostTemplateDto } from '@common/models/Settings/CostTemplates/Item/CostTemplateDto';
import { UserTypeRateDto } from '@common/models/Settings/CostTemplates/List/UserTypeRateDto';
import { UserTypeListItemDto } from '@common/models/Settings/UserTypes/List/UserTypeListItemDto';
import { DomainError } from '@common/models/Validation/DomainError';
import { NotificationService } from '@common/notification';
import { CostTemplatesService } from '@common/services/settings/costtemplates.service';
import { UserTypesService } from '@common/services/settings/usertypes.service';
import { get, isNil } from 'lodash';

import { CustomValidators } from '@common/validation/custom.validators';
import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';

@Component({
	styleUrls: ['./cost-template-item.component.scss'],
	templateUrl: './cost-template-item.component.html'
})
export class CostTemplateItemComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('costTemplateInput', { static: true })
	costTemplateInput: ElementRef;
	form: FormGroupTyped<CostTemplateDto>;
	isCreateMode: boolean;
	userTypes: UserTypeListItemDto[];
	private subscriptions = new Subscription();

	constructor(
		@Inject(MAT_DIALOG_DATA) public editId: string,
		private fb: FormBuilder,
		private userTypesService: UserTypesService,
		private costTemplatesService: CostTemplatesService,
		private dialogRef: MatDialogRef<CostTemplateItemComponent>,
		private notificationService: NotificationService
	) {
		this.form = this.fb.group({
			description: null,
			name: [null, CustomValidators.required('Name')],
			userTypeRates: this.fb.group([])
		}) as FormGroupTyped<CostTemplateDto>;
	}

	ngOnInit() {
		this.isCreateMode = !this.editId;

		const sources: Array<Observable<any>> = [this.userTypesService.getUserTypeList({ sortBy: 'name' })];
		if (this.editId) sources.push(this.costTemplatesService.getCostTemplate(this.editId));

		this.subscriptions.add(
			forkJoin(...sources).subscribe(([userTypes, costTemplateDto]) => {
				this.userTypes = userTypes.records;
				this.form.patchValue({
					description: costTemplateDto ? costTemplateDto.description : null,
					name: costTemplateDto ? costTemplateDto.name : null
				});
				this.createArrayGroup(userTypes.records, costTemplateDto ? costTemplateDto.userTypeRates : null);
			})
		);
	}
	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	ngAfterViewInit() {
		setTimeout(() => {
			if (this.costTemplateInput) this.costTemplateInput.nativeElement.focus();
		}, 0);
	}

	getTypeRateControl(typeId: string): FormControl {
		return (this.form.controls.userTypeRates as AbstractControl as FormGroup).controls[typeId] as FormControl;
	}

	save() {
		// Set 0$/hr rate foe ones with null values
		const userTypeRates: { [key: string]: number } = this.form.controls.userTypeRates.value;
		for (const key of Object.keys(userTypeRates)) {
			userTypeRates[key] = isNil(userTypeRates[key]) ? null : userTypeRates[key];
		}
		this.form.controls.userTypeRates.setValue(userTypeRates);
		// Save the cost template
		const costTemnplateObservable: Observable<MutationResponseDto> = !!this.editId
			? this.costTemplatesService.updateCostTemplate(this.editId, this.form.value)
			: this.costTemplatesService.createCostTemplate(this.form.value);
		this.subscriptions.add(costTemnplateObservable.subscribe(this.onSuccess, this.onError));
	}
	closeDialog() {
		this.dialogRef.close();
	}

	private onError = (errors: DomainError[]) => {
		this.notificationService.showErrors('Error', errors);
	};

	private onSuccess = (result: MutationResponseDto) => {
		this.notificationService.showNotification(
			`Cost Template ${result.name} ${!!this.editId ? 'updated' : 'created'}`
		);
		this.dialogRef.close(result);
	};

	private createArrayGroup(userTypes: UserTypeListItemDto[], userTypeRates: UserTypeRateDto[]): void {
		if (get(userTypes, 'length') > 0) {
			const items = this.form.controls.userTypeRates as AbstractControl as FormGroup;
			userTypes.forEach(userType => {
				items.addControl(
					userType.id,
					new FormControl(userTypeRates ? userTypeRates[userType.id as keyof object] : null)
				);
			});
		}
	}
}
