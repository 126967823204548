<h2 mat-dialog-title class="title">{{ isCreateMode ? 'Add' : 'Edit' }} Task Template</h2>
<mat-dialog-content class="flex-column">
	<ng-template [ngIf]="!type">
		<mat-radio-group [(ngModel)]="type" class="flex-column">
			<mat-radio-button value="Generic">{{ 'Generic' | taskType }}</mat-radio-button>
			<mat-radio-button [featureFlag]="featureFlags.collectionTask" value="CollectionTask">{{ 'CollectionTask' | taskType }}</mat-radio-button>
			<mat-radio-button [featureFlag]="featureFlags.mergeTask" value="DocumentTemplateMerge">{{ 'DocumentTemplateMerge' | taskType }}</mat-radio-button>
		</mat-radio-group>
	</ng-template>
	<div *ngIf="!!type" [formGroup]="form">
		<div class="flex-row">
			<mat-form-field>
				<mat-placeholder>
					Description
					<sup class="color-warn">*</sup>
				</mat-placeholder>
				<input #costCodeInput matInput formControlName="description" autocomplete="off" />
				<mat-error>
					<error-messages [for]="form.controls.description"></error-messages>
				</mat-error>
			</mat-form-field>
		</div>
		<div class="flex-row">
			<mat-form-field>
				<mat-select formControlName="priority" placeholder="Priority">
					<mat-option *ngFor="let option of priorities" [value]="option">{{ option | taskPriority }}</mat-option>
				</mat-select>
				<mat-error>
					<error-messages [for]="form.get('priority')"></error-messages>
				</mat-error>
			</mat-form-field>
			<mat-form-field>
				<mat-select formControlName="status" placeholder="Status">
					<mat-option *ngFor="let option of statuses" [value]="option">{{ option | taskStatus }}</mat-option>
				</mat-select>
				<mat-error>
					<error-messages [for]="form.get('status')"></error-messages>
				</mat-error>
			</mat-form-field>
		</div>
		<div class="flex-row form-group-assigned-contact" formGroupName="assignedToContactOptions">
			<mat-form-field>
				<mat-select formControlName="source" placeholder="Assigned to Contact Type">
					<mat-optgroup *ngFor="let group of assignedToContactTypes" [label]="group.name">
						<mat-option *ngFor="let option of group.items" [value]="option.id">{{ option.display }}</mat-option>
					</mat-optgroup>
				</mat-select>
				<mat-hint *ngIf="!isSelectedContactOptionSelected && !!isUserTypeOptionSelected">
					The task will be assigned to the <b>Main</b> team member of this <b>User Type</b>. If this user type is not defined on the matter, the task
					will be assigned to the <b>Assigned Lawyer</b> on the <b>Matter</b>.
				</mat-hint>
				<mat-hint *ngIf="!isSelectedContactOptionSelected && !!isAssignedLawyerOptionSelected">
					The task will be assigned to the <b>Assigned Lawyer</b> on the <b>Matter</b>.
				</mat-hint>
				<mat-error>
					<error-messages [for]="form.get('assignedToContactOptions').get('source')"></error-messages>
				</mat-error>
			</mat-form-field>
			<div *ngIf="isSelectedContactOptionSelected">
				<editable-contact
					placeHolder="Assigned to Contact"
					[staffOnly]="true"
					[control]="form.get('assignedToContactOptions').get('contactId')"
					[contactName]="data.model?.assignedToContact?.name"
					[editMode]="true"
					[hasAutoFocus]="false"
				></editable-contact>
			</div>
		</div>

		<mat-label class="due-date-label">Due Date</mat-label>
		<div class="flex-row offset-fields-container" formGroupName="dueDateOptions">
			<grouped-local-lookup
				class="due-date-source-field"
				[FormControl]="form.get('dueDateOptions').get('dueDateSource')"
				Placeholder="From"
				[dataSource]="dueDateSources"
			></grouped-local-lookup>

			<div class="flex-row">
				<editable-numeric-value
					placeHolder="Add or Minus"
					[control]="form.controls.dueDateOptions.get('offsetFactor')"
					[isCurrency]="false"
					[editMode]="true"
				></editable-numeric-value>

				<mat-form-field>
					<mat-select formControlName="offsetType">
						<mat-option *ngFor="let option of offsetTypes" [value]="option">{{ option | taskTemplateDueDateOffsetType }}</mat-option>
					</mat-select>
					<mat-error>
						<error-messages [for]="form.get('offsetType')"></error-messages>
					</mat-error>
				</mat-form-field>
			</div>
		</div>

		<div *ngIf="type === 'DocumentTemplateMerge'" class="document-templates">
			<div class="list-header">
				<h3 class="title-text">Document Templates</h3>
				<button class="add-button" mat-button color="primary" throttleButton (throttledClick)="addDocumentTemplate()">
					<mat-icon>add_circle</mat-icon>
					<span>&nbsp;New document template</span>
				</button>
			</div>
			<div class="flex-row">
				<div class="flex-column">
					<div
						class="list-item--mobile"
						*ngFor="let documentTemplateIdControl of documentTemplateIdControls; let i = index"
						formArrayName="documentTemplateIds"
					>
						<div class="flex-row">
							<document-template-lookup
								#documentTemplateControl
								Placeholder="Document Template"
								[Required]="true"
								[FormControl]="documentTemplateIdControl"
								entityType="Matter"
								[practiceAreaIds]="data.practiceAreaIds"
							>
							</document-template-lookup>

							<button
								mat-icon-button
								title="Remove"
								class="delete-button delete-button--full"
								throttleButton
								(throttledClick)="removeDocumentTemplate(i)"
							>
								<mat-icon>delete</mat-icon>
							</button>
						</div>

						<div class="flex-row flex-row--mobile">
							<button
								mat-raised-button
								title="Remove"
								class="delete-button delete-button--mobile"
								throttleButton
								(throttledClick)="removeDocumentTemplate(i)"
							>
								<mat-icon>delete</mat-icon>
								<span>Remove</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div *ngIf="type === 'CollectionTask'">
			<div class="flex-row">
				<custom-field-selection
					[entityType]="customFieldEntityType"
					[practiceAreaIds]="practiceAreaIds"
					[SelectedGroups]="selectedCustomFieldGroups"
					(SelectedGroupsChange)="onSelectedGroupsChange($event)"
					[isStackedLayout]="true"
				></custom-field-selection>
			</div>

			<div class="flex-row">
				<mat-checkbox formControlName="allCollectionFieldsMandatory">All fields are required before task can be completed</mat-checkbox>
			</div>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-raised-button color="primary" throttleButton (throttledClick)="save()" [disabled]="!isSaveFormEnabled">Ok</button>
	<button mat-raised-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
