import { Pipe, PipeTransform } from '@angular/core';

import { Features } from '@common/models/Settings/Modules/Features';

/*
 * Resolve task status display string by the enum key
 */
@Pipe({ name: 'featureType' })
export class FeatureTypePipe implements PipeTransform {
	transform(key: keyof typeof Features): string {
		if (!Features[key]) {
			throw Error(`Dev error: The Feature Type enum does not contain the key ${key}`);
		}
		return Features[key];
	}
}
