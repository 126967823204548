<h2 mat-dialog-title>
	<span>Upgrade Plan</span>
</h2>
<mat-dialog-content>
	<div class="container">
		<span>
			<b>{{ getFeature(data?.feature) }} </b> is not included in your subscription plan.
		</span>
		<br />

		<span *ngIf="getAvailableIn(data.feature)">
			<span *ngIf="hasAccessToSettings">
				It is available in <a [routerLink]="['../system/subscription']" mat-dialog-close class="availableIn">{{ getAvailableIn(data.feature) }}</a>
			</span>
			<span *ngIf="!hasAccessToSettings"> It is available in {{ getAvailableIn(data.feature) }} </span>
		</span>
		<br />
		<span *ngIf="hasAccessToSettings">
			Please upgrade your plan to start using <b>{{ getFeature(data?.feature) }}</b>
		</span>
		<span *ngIf="!hasAccessToSettings">
			Please contact your administrator to upgrade your plan to start using <b>{{ getFeature(data?.feature) }}</b>
		</span>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-raised-button [color]="hasAccessToSettings ? '' : 'primary'" mat-dialog-close>{{ hasAccessToSettings ? 'Cancel' : 'OK' }}</button>
	<button mat-raised-button *ngIf="hasAccessToSettings" color="primary" mat-dialog-close throttleButton (throttledClick)="goToUpgrades()">
		<mat-icon>auto_awesome</mat-icon>Upgrade
	</button>
</mat-dialog-actions>
