import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Location } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { forkJoin, of, Subscription } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

import { PricedMarketplaceListingViewDto } from '@common/models/Infrastructure/StripeIntegration/Models/PricedMarketplaceListingViewDto';
import { InstallationRecordStatus } from '@common/models/Marketplace/InstallationRecords/Common/InstallationRecordStatus';
import { MarketplaceListingType } from '@common/models/Marketplace/Listings/Common/MarketplaceListingType';
import { Features } from '@common/models/Settings/Modules/Features';
import { StripeInfo } from '@common/models/Settings/Setting/Item/StripeInfo';
import { StripeSubscriptionItem } from '@common/models/Settings/Setting/Item/StripeSubscriptionItem';
import { NotificationService } from '@common/notification';
import { GeneralSettingsService } from '@common/services/settings/generalsettings.service';
import { MarketplaceService } from '@common/services/settings/marketplace.service';

import { AppConfig } from 'app/app.config';
import { SignalrService } from 'app/core/signalr/signalr.service';
import { AddSubscriptionItemDialogComponent, IAddSubscriptionItemDialogData } from 'app/system/subscription/add-subscription-item-dialog.component';
import { IRemoveSubscriptionItemDialogData, RemoveSubscriptionItemDialogComponent } from 'app/system/subscription/remove-subscription-item-dialog.component';

import { Pricing, ResolvePricing, ResolveRecurringInterval } from '../common/pricing-helpers';
import { IServiceRequestDialogData, ServiceRequestDialogComponent } from '../service/service-request-dialog.component';
import { IMarketplaceListingOperationDialogData, MarketplaceListingOperationDialogComponent, MarketplaceListingOperationDialogDataType } from './marketplace-listing-operation-dialog.component';

@Component({
	selector: 'marketplace-listing-overview',
	styleUrls: ['./marketplace-listing-overview.component.scss'],
	templateUrl: 'marketplace-listing-overview.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarketplaceListingOverviewComponent implements OnInit, OnDestroy {
	private _marketplaceListingId: string;
	private _isSmall: boolean = false;

	@Input()
	set marketplaceListingId(value: string) {
		if (this._marketplaceListingId !== value) {
			this._marketplaceListingId = value;
			this._subscriptions.add(this.refresh$().subscribe());
		}
	}
	get marketplaceListingId() {
		return this._marketplaceListingId;
	}

	private _listingType: keyof typeof MarketplaceListingType;
	@Input()
	set listingType(value: keyof typeof MarketplaceListingType) {
		if (this._listingType !== value) {
			var matchingEnum = Object.values(MarketplaceListingType).filter(
				x => x.toLowerCase() === value.toLowerCase()
			);
			this._listingType = matchingEnum[0] ?? value;
		}
	}
	get listingType() {
		return this._listingType;
	}

	private _subscriptions = new Subscription();
	private _marketplaceListing: PricedMarketplaceListingViewDto;
	private _categories: string[] = [];
	private _tenantStripeInfo: StripeInfo;
	private _stripeSubscriptionItem: StripeSubscriptionItem;
	private _recentPurchase: boolean = false;
	private _pricing: Pricing;

	@Output()
	stateChanged = new EventEmitter<any>();

	get isViewDataLoaded() {
		return !!this._marketplaceListing;
	}

	get categories() {
		return this._categories;
	}

	get productName(): string {
		return this._marketplaceListing?.productName;
	}

	get features(): (keyof typeof Features)[] {
		return this._marketplaceListing?.features;
	}

	get productDescription() {
		const safeHTML = this.sanitizer.bypassSecurityTrustHtml(this._marketplaceListing?.productDescription);
		return safeHTML;
	}

	get productCategories(): string[] {
		return this._marketplaceListing?.productCategories;
	}

	get isService(): boolean {
		return this.listingType === 'Service';
	}

	get isPackage(): boolean {
		return this.listingType === 'Package';
	}

	get isModule(): boolean {
		return this.listingType === 'Module';
	}

	get isFreePackage(): boolean {
		return this.isPackage && this._marketplaceListing?.packageDetails?.pricingModel == 'Free';
	}

	get requestServiceButtonText(): string {
		return this._marketplaceListing?.serviceDetails?.callToActionButtonText || 'Request Service';
	}

	get productImageSrc(): string {
		let imageUrl = AppConfig.AppServerUrl + '/marketplace/icons/';
		if (this.isPackage) return imageUrl + 'Package/' + this.marketplaceListingId;
		else if (this.isModule) return imageUrl + 'Module/' + this.marketplaceListingId;
		else if (this.isService) return imageUrl + 'Service/' + this.marketplaceListingId;
		else return '';
	}

	get vendorName(): string {
		return this._marketplaceListing?.packageDetails?.vendorName;
	}

	get id(): string {
		return this._marketplaceListing?.id;
	}

	public get primaryPriceDisplay(): string {
		if (this._pricing?.isFree) return 'Free';
		else if (this._pricing?.primary?.hasPrice) {
			return `$${this._pricing.primary.cost}`;
		}
		return '';
	}

	public get primaryPriceDescription(): string {
		const price = this._pricing?.primary;
		if (!this._pricing?.isFree && price?.hasPrice) {
			return `user / ${ResolveRecurringInterval(price.billingCycle)}`;
		}
		return '';
	}

	public get secondaryPriceDescription(): string {
		const price = this._pricing?.secondary;
		if (!this._pricing?.isFree && price?.hasPrice) {
			return `$${price.cost} user / ${ResolveRecurringInterval(price.billingCycle)}`;
		}
		return '';
	}

	get lastModifiedBy(): string {
		return this._marketplaceListing?.lastModifiedBy?.fullName;
	}

	// Stripe Flags
	get isTenantSubscriptionActive() {
		return !!this._tenantStripeInfo?.subscriptionId;
	}
	get isProductSubscribed() {
		return !!this._stripeSubscriptionItem?.subscriptionItemId;
	}
	get hasStripePricing() {
		return !!this._pricing?.primary?.hasPrice;
	}

	// Environment
	get isTestEnvironment() {
		return !!this._marketplaceListing.marketplaceTestingEnabled;
	}

	// Subscribe/Install
	get showSubscribeButton() {
		if (this.isModule) {
			return this.canSubscribe;
		} else if (this.isPackage) {
			if (!!this.canInstallTrial) return true;
			// If the package doesnt have a price point but is already installed
			if (!this.hasStripePricing && this.isPackageInstalled) return false;

			return this.canSubscribe || this.canInstall;
		}

		return false;
	}
	get canSubscribe() {
		return !this.isService && !this._recentPurchase && !this.isProductSubscribed;
	}
	get canInstall() {
		return !this.isPackageInstalled && this.isProductSubscribed;
	}
	get canInstallTrial() {
		return !!this.canInstall && this._marketplaceListing?.packageDetails?.trialableDays > 0;
	}
	get subscribeButtonText() {
		if (!!this.isFreePackage) {
			return 'Install';
		} else if (
			!this.isPackageInstalled &&
			!this._pricing?.isFree &&
			this.isPackage &&
			!!this._marketplaceListing.packageDetails &&
			this._marketplaceListing.packageDetails.trialableDays > 0
		)
			return `${this._marketplaceListing.packageDetails.trialableDays} day trial`;
		else {
			return this.hasStripePricing ? `${this.primaryPriceDisplay} ${this.primaryPriceDescription}` : 'Subscribe';
		}
	}

	// Unsubscribe/Uninstall
	get showUnsubscribeButton() {
		if (this.isModule) {
			return this.canUnsubscribe;
		} else if (this.isPackage) {
			return this.canUnsubscribe || this.canUninstall;
		}

		return false;
	}
	get canUnsubscribe() {
		return !this._recentPurchase && this.isProductSubscribed && this.isTenantSubscriptionActive;
	}
	get canUninstall() {
		return this.isPackageInstalled;
	}
	get unsubscribeButtonText() {
		if (!!this.isProductSubscribed) return 'Unsubscribe';
		return 'Uninstall';
	}

	// Package Flags
	get isPackageInstalled() {
		return this.isPackage && this._marketplaceListing.installationStatus === InstallationRecordStatus.Installed;
	}

	get canUpdate() {
		return this.isPackageInstalled && !!this._marketplaceListing?.packageDetails?.hasUpdate;
	}

	get canConfigure() {
		return this.isPackageInstalled;
	}

	constructor(
		private _router: Router,
		private _location: Location,
		private _dialog: MatDialog,
		private _marketplaceService: MarketplaceService,
		private _generalSettingsService: GeneralSettingsService,
		private _notificationService: NotificationService,
		private _signalRService: SignalrService,
		private sanitizer: DomSanitizer,
		private cdr: ChangeDetectorRef,
		private _breakpointObserver: BreakpointObserver
	) {}

	ngOnInit(): void {
		this._subscriptions.add(
			this._marketplaceService.getAllCategories().subscribe(categories => {
				this._categories = categories
					.filter(category => !!category)
					.sort((left, right) => left.localeCompare(right));
			})
		);

		this._subscriptions.add(this.refresh$().subscribe());

		this._subscriptions.add(
			this._signalRService
				.onSubscriptionUpdated()
				.pipe(
					switchMap(() => {
						this._notificationService.showNotification('Subscription Updated');
						this._recentPurchase = false;
						return this.refresh$();
					})
				)
				.subscribe()
		);

		this._subscriptions.add(
			this._breakpointObserver.observe([Breakpoints.XSmall]).subscribe(state => {
				this._isSmall = state.matches;
			})
		);
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}

	update(): void {
		this._subscriptions.add(
			this.openOperationDialog$(MarketplaceListingOperationDialogDataType.Update).subscribe()
		);
	}

	addSubscription(): void {
		this._subscriptions.add(this.openAddSubscriptionDialog$().subscribe());
	}

	unsubscribe(): void {
		this._subscriptions.add(this.openRemoveSubscriptionDialog$().subscribe());
	}

	configure(): void {
		this._subscriptions.add(
			this.openOperationDialog$(MarketplaceListingOperationDialogDataType.Configure).subscribe()
		);
	}

	requestService(): void {
		const data: IServiceRequestDialogData = {
			dto: this._marketplaceListing
		};
		this._dialog.open(ServiceRequestDialogComponent, { data });
	}

	goBack() {
		if (window.history.length > 1) {
			this._location.back();
		} else {
			this._router.navigate(['system', 'marketplace']);
		}
	}

	private refresh$() {
		return !this.marketplaceListingId?.length
			? of({})
			: forkJoin([
					this._marketplaceService.getActiveListing(this.listingType.toString(), this.marketplaceListingId),
					this._generalSettingsService.getTenantStripeInfo()
			  ]).pipe(
					tap(([listing, stripeInfo]) => {
						this._marketplaceListing = listing;
						this._tenantStripeInfo = stripeInfo;

						var primaryInterval = ResolveRecurringInterval(stripeInfo?.recurringInterval);
						this._pricing = ResolvePricing(listing, primaryInterval);

						if (this._tenantStripeInfo?.subscriptions?.length > 0) {
							this._stripeSubscriptionItem = this._tenantStripeInfo.subscriptions.find(
								si => si.productId === this._marketplaceListing.stripeProductId
							);
						} else {
							this._stripeSubscriptionItem = null;
						}

						this._recentPurchase = false;

						this.cdr.detectChanges();
					})
			  );
	}

	private fullWidthDialog: MatDialogConfig = {
		maxHeight: '90vh',
		maxWidth: '95vw',
		width: '95%'
	};

	private openAddSubscriptionDialog$() {
		var data: IAddSubscriptionItemDialogData = {
			marketplaceListing: this._marketplaceListing,
			priceId: this._pricing?.primary?.priceId,
			quantity: this._tenantStripeInfo?.quantity,
			billingCycle: this._pricing?.primary?.billingCycle,
			stripeInfo: this._tenantStripeInfo
		};

		var dialogCfg = this._isSmall ? { ...{ data }, ...this.fullWidthDialog } : { data };

		return this._dialog
			.open(AddSubscriptionItemDialogComponent, dialogCfg)
			.afterClosed()
			.pipe(
				filter(Boolean),
				switchMap(() => {
					this.stateChanged.emit();
					this._recentPurchase = true;
					return this.refresh$();
				})
			);
	}

	private openRemoveSubscriptionDialog$() {
		var data: IRemoveSubscriptionItemDialogData = {
			marketplaceListing: this._marketplaceListing,
			productId: this._marketplaceListing.stripeProductId,
			stripeInfo: this._tenantStripeInfo
		};

		var dialogCfg = this._isSmall ? { ...{ data }, ...this.fullWidthDialog } : { data };

		return this._dialog
			.open(RemoveSubscriptionItemDialogComponent, dialogCfg)
			.afterClosed()
			.pipe(
				filter(Boolean),
				switchMap(() => {
					this.stateChanged.emit();
					this._recentPurchase = true;
					return this.refresh$();
				})
			);
	}

	private openOperationDialog$(operationType: MarketplaceListingOperationDialogDataType) {
		return this._dialog
			.open(MarketplaceListingOperationDialogComponent, {
				data: {
					operationType,
					productId: this._marketplaceListing?.id,
					productTermsUrl: this._marketplaceListing?.productTermsUrl,
					productName: this._marketplaceListing?.productName,
					packageVersion:
						operationType === MarketplaceListingOperationDialogDataType.Configure
							? this._marketplaceListing?.packageDetails?.currentVersion
							: this._marketplaceListing?.packageDetails?.version
				} as IMarketplaceListingOperationDialogData
			})
			.afterClosed()
			.pipe(
				filter(Boolean),
				tap(() => this.stateChanged.emit()),
				switchMap(() => this.refresh$())
			);
	}
}
