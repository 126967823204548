import { Injectable } from '@angular/core';

import { interval, of } from 'rxjs';
import { catchError, debounce, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { IAppState } from '../../../app.state';
import { UserTypesService } from '@common/services/settings/usertypes.service';
import { UserTypeListActions, loading } from './user-type-list.actions';

@Injectable()
export class UserTypeListEffects {
	loadUserTypes$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				UserTypeListActions.Load,
				UserTypeListActions.SetPageIndex,
				UserTypeListActions.SetPageSize,
				UserTypeListActions.SetSortBy,
				UserTypeListActions.SetSortDirection
			),
			tap(action => this.store.dispatch(loading())),
			withLatestFrom(this.store),
			debounce(() => interval(200)),
			switchMap(([action, state]) => {
				return this.userTypeService.getUserTypeList(state.userTypeList.request).pipe(
					map(response => {
						return { type: UserTypeListActions.LoadSuccess, response };
					}),
					catchError(error => of({ type: UserTypeListActions.LoadFailed, error }))
				);
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<IAppState>,
		private userTypeService: UserTypesService
	) {}
}
