import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { Observable } from 'rxjs';

import { BaseLookupComponent } from '@common/components/lookups/base-lookup.component';
import { UserLookupDto } from '@common/models/Users/Lookup/UserLookupDto';
import { UsersService } from '@common/services/settings/users.service';
import * as moment from 'moment-timezone';

@Component({
	selector: 'user-lookup',
	styleUrls: ['./user-lookup.component.scss'],
	templateUrl: './user-lookup.component.html'
})
export class UserLookupComponent extends BaseLookupComponent<UserLookupDto> {
	@Input()
	FormControl: FormControl;
	@Input()
	FontSize: string;
	@Input()
	HasAutofocus: boolean;
	@Input()
	Placeholder: string;
	@Input()
	Hint: string;
	@Input()
	Required: boolean;
	@Input()
	ignoreIds: string[];

	constructor(private usersService: UsersService, private fb: FormBuilder, public dialog: MatDialog) {
		super();
	}

	optionHtmlText(input: UserLookupDto): string {
		return input.contactName;
	}

	displayText(input: UserLookupDto) {
		return input.contactName;
	}

	search(term: string): Observable<UserLookupDto[]> {
		return this.usersService.lookupUserList({
			term: term,
			ignoreIds: this.ignoreIds
		});
	}

	isActivated(input: UserLookupDto): boolean {
		return input && input.activationDate && moment(input.activationDate) > moment('2000-01-01');
	}
}
