import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppConfig } from 'app/app.config';
import { DialogType, EntityType } from 'app/core/dialog.config';
import { distinctUntilChanged, filter } from 'rxjs/operators';

// tslint:disable-next-line:ban-types
declare var gtag: Function;

@Injectable({
	providedIn: 'root'
})
export class GoogleAnalyticsService {
	googleAnalyticsKey: string = AppConfig.GoogleAnalyticsTrackingId;
	googleMarketingAnalyticsKey: string = AppConfig.GoogleMarketingAnalyticsTrackingId;
	constructor(private router: Router) {}

	// Initialise Google's tracking on change of the route
	init(): void {
		this.router.events
			.pipe(
				filter(event => event instanceof NavigationEnd),
				distinctUntilChanged()
			)
			.subscribe((event: NavigationEnd) => {
				// clean up the URL
				const cleanUrl = event.urlAfterRedirects
					.split(/(\?|#)/)[0] // Remove the query params and '#'
					.replace(/\/\d+\-\w/gi, '/id'); // Replace the IDs to the 'id' constant
				this.trackPageView(cleanUrl);
			});
	}

	// Track open dialogues
	trackDialog(dialogType: DialogType, entityType: EntityType): void {
		this.trackPageView(
			'dialog-' +
				(dialogType === DialogType.Edit ? 'edit-' : 'create-') +
				entityType.replace(' ', '').toLowerCase()
		);
	}

	trackMatterWorkbenchSave() {
		this.trackPageView('matter-workbench-save');
	}

	trackRecentItems() {
		this.trackPageView('recent-items-click');
	}
	trackBatchBilling(numberOfBills: number) {
		this.trackPageView(`batchbilling NumberOfBills:${numberOfBills}`);
	}
	trackSpeechToText() {
		this.trackPageView(`Speech To Text Started`);
	}

	trackRegistrationPage(page: string) {
		this.trackMarketingPageView(`new-tenant-${page}`);
	}

	private trackPageView(pagePath: string): void {
		this.trackPage(this.googleAnalyticsKey, pagePath);
	}

	private trackMarketingPageView(pagePath: string): void {
		this.trackPageView(pagePath);

		if (this.googleMarketingAnalyticsKey) {
			this.trackPage(this.googleMarketingAnalyticsKey, pagePath);
		}
	}

	private trackPage(analyticsKey: string, pagePath: string) {
		gtag('config', analyticsKey, {
			page_path: pagePath
		});
	}
}
