// This file has been generated from TrustAccountCreateUpdateDto.cs
// Do not modify this file directly!!
// tslint:disable

import { SetComplexType } from '@common/utils/class-transform';

import { BankDetails } from '../../BankDetails';

// LawMaster.Amalthea.Domain.Settings.TrustSettings.TrustAccounts.Item.TrustAccountCreateUpdateDto
export class TrustAccountCreateUpdateDto {

	// Id
	id: string;
	// BankDetails
	@SetComplexType(BankDetails)
	bankDetails: BankDetails;
	// StatutoryMatterId
	statutoryMatterId: string;
	// TrustCreditDays
	trustCreditDays: number;
	// ReceiptTemplateId
	receiptTemplateId: string;
	// DepositTemplateId
	depositTemplateId: string;

	constructor() {
		this.bankDetails = new BankDetails();
	}
}