// This file has been generated from CostRecordsController.cs
// Do not modify this file directly!!
// tslint:disable

import { HttpClient, HttpResponse } from '@angular/common/http';
import { IDownloadFile } from '../models/Files/IFileDownloader';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { TrimStringsOnObject } from '@common/utils/string-format';

import { IdCollectionRequest } from '../models/Common/IdCollectionRequest';
import { BatchMoveCostRecordsDto } from '../models/CostRecords/Item/BatchMoveCostRecordsDto';
import { CostRecordListItemDto } from '../models/CostRecords/List/CostRecordListItemDto';
import { CostRecordListRequest } from '../models/CostRecords/List/CostRecordListRequest';
import { CostRecordListResponse } from '../models/CostRecords/List/CostRecordListResponse';
import { MatterWipDto } from '../models/CostRecords/List/MatterWipDto';

@Injectable({
	providedIn: 'root'
})
export class CostRecordsService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'api/v1/CostRecords', 'Cost Record');
	}

	batchMove(dto: BatchMoveCostRecordsDto): Observable<any> {
		return this.post<BatchMoveCostRecordsDto, any>(`move`, TransformDatesOnObject(BatchMoveCostRecordsDto, TrimStringsOnObject(dto)));
	}

	getCostRecordList(request?: Partial<CostRecordListRequest>): Observable<CostRecordListResponse<CostRecordListItemDto>> {
		return this.getItem<CostRecordListRequest, CostRecordListResponse<CostRecordListItemDto>>('', request);
	}

	exportCostRecordsByFilter(request?: Partial<CostRecordListRequest>): Observable<HttpResponse<Blob>> {
		return this.getBlob<CostRecordListRequest>(`exportByFilter`, request);
	}

	exportCostRecordsByIds(request: IdCollectionRequest): Observable<HttpResponse<Blob>> {
		return this.getBlob<IdCollectionRequest>(`exportByIds`, request);
	}

	getWipForOpenMatters(): Observable<MatterWipDto[]> {
		return this.getArray<void, MatterWipDto>(`getWipForOpenMatters`);
	}

}
