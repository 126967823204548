import { CalculationVariableListItemDto } from '@common/models/Calculations/List/CalculationVariableListItemDto';
import { DomainError } from '@common/models/Validation/DomainError';
import { Moment } from 'moment-timezone';

export interface ICalculationVariableListState {
	matterVariables: ICalculationVariableEntityTypeListState;
	contactVariables: ICalculationVariableEntityTypeListState;
}

export interface ICalculationVariableEntityTypeListState {
	list: CalculationVariableListItemDto[];
	isFetching: boolean;
	error: DomainError;
	cacheExpiry?: Moment;
}

export const initialVariablesState: ICalculationVariableEntityTypeListState = {
	list: [],
	isFetching: false,
	error: null,
	cacheExpiry: null
};

export const initialState: ICalculationVariableListState = {
	matterVariables: initialVariablesState,
	contactVariables: initialVariablesState
};
