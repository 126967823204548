import { Component } from '@angular/core';

import { Phone } from '@common/models/Contacts/Common/Phone';
import { ContactsService } from '@common/services/contacts.service';
import { CustomValidators } from '@common/validation/custom.validators';
import { Store } from '@ngrx/store';

import { ITenantCompanyState } from 'app/core/state/misc/tenant-company/tenant-company.state';

import { BaseEditableComponent } from './base.editable.component';

@Component({
	selector: 'editable-phone',
	styleUrls: ['./editable-phone.component.scss'],
	template: `
		<ng-template [ngIf]="!editMode">
			<a *ngIf="!!value('rfc3966')" href="tel:{{ value('rfc3966') }}" title="call: {{ value('number') }}">
				{{ formatDisplay(value('number')) }}
			</a>
			<span *ngIf="!value('rfc3966')">{{ formatDisplay(value('number')) }}</span>
		</ng-template>
		<ng-template [ngIf]="editMode">
			<mat-form-field class="phone-number" floatLabel="never">
				<input
					matInput
					type="phone"
					placeholder="Phone Number"
					[formControl]="childControl('number')"
					autocomplete="off"
					(focus)="onFocus()"
				/>
				<mat-hint *ngIf="!!hintText?.length">{{ hintText }}</mat-hint>
				<mat-error>
					<error-messages [for]="childControl('number')"></error-messages>
				</mat-error>
			</mat-form-field>
		</ng-template>
	`
})
export class EditablePhoneComponent extends BaseEditableComponent<Phone> {
	validatePhoneNumber = CustomValidators.validatePhoneNumber(this.contactService, this.tenantStore);
	constructor(
		protected contactService: ContactsService,
		protected tenantStore: Store<{ tenantCompanyData: ITenantCompanyState }>
	) {
		super();
	}

	onFocus() {
		if (!this.control.get('number').hasAsyncValidator(this.validatePhoneNumber)) {
			this.control.get('number').addAsyncValidators(this.validatePhoneNumber);
		}
		this.control.get('number').updateValueAndValidity();

		this.control.get('dirty').setValue(true);
	}
}
