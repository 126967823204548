import { createReducer, on } from '@ngrx/store';

import { CommonReducers } from '../../common-reducers';
import {
	init,
	loadFailed,
	loading,
	loadSuccess,
	selectRecords,
	setFilters,
	setPageIndex,
	setPageIndexForId,
	setPageSize,
	setSortBy,
	setSortDirection
} from './infotrack-list.actions';
import { initialState } from './infotrack-list.state';

export const infotrackListReducer = createReducer(
	initialState,
	on(init, state => CommonReducers.init(state, initialState)),
	on(loading, state => CommonReducers.loading(state)),
	on(loadSuccess, (state, { response }) => CommonReducers.loadSuccess(state, response)),
	on(loadFailed, (state, { error }) => CommonReducers.loadFailed(state, error)),

	on(setFilters, (state, { filter }) => CommonReducers.setFilters(state, filter)),

	on(setPageSize, (state, { pageSize }) => CommonReducers.setPageSize(state, pageSize)),
	on(setPageIndex, (state, { pageIndex }) => CommonReducers.setPageIndex(state, pageIndex)),
	on(setPageIndexForId, (state, { id }) => CommonReducers.setPageIndexForId(state, id)),

	on(setSortBy, (state, { sortBy }) => CommonReducers.setSortBy(state, sortBy)),
	on(setSortDirection, (state, { sortDirection }) => CommonReducers.setSortDirection(state, sortDirection)),

	on(selectRecords, (state, { row, selectionType }) => CommonReducers.selectRecords(state, row, selectionType))
);
