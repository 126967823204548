// This file has been generated from TransactionType.cs
// Do not modify this file directly!!

// LawMaster.Amalthea.Domain.Trust.Common.TransactionType
export enum TransactionType {
	Receipt = 'Receipt',
	Payment = 'Payment',
	Journal = 'Journal',
	BillPayment = 'Invoice Payment',
	Deposit = 'Deposit'
}
