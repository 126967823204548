import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { SharedDirectivesModule } from '@common/directives/shared-directives.module';
import { SharedPipesModule } from '@common/pipes/shared-pipes.module';
import { NgMathPipesModule } from 'angular-pipes';

import { PipesModule } from 'app/shared/pipes/pipes.module';

import { EmailPreviewComponent } from './document/email-preview.component';
import { PdfPreviewComponent } from './document/pdf-preview.component';
import { PdfJsViewerComponent } from './document/pdfjs-viewer.component';
import { PreviewDocumentComponent } from './document/preview-document.component';

@NgModule({
	declarations: [PreviewDocumentComponent, EmailPreviewComponent, PdfPreviewComponent, PdfJsViewerComponent],
	exports: [PreviewDocumentComponent],
	imports: [
		CommonModule,
		MatButtonModule,
		MatCardModule,
		MatIconModule,
		MatProgressBarModule,
		MatTooltipModule,
		MatMenuModule,
		NgMathPipesModule,
		SharedDirectivesModule,
		PipesModule,
		SharedPipesModule
	]
})
export class PreviewModule {}
