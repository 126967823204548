<h2 mat-dialog-title class="title">Assign {{ data.title }}</h2>
<mat-dialog-content class="flex-column" [formGroup]="form">
	<div class="flex-row">
		<contact-lookup-local
			*ngIf="!!data.allowedContactIds?.length"
			[FormControl]="getControl('contactId')"
			(Selected)="onContactSelected($event)"
			[StaffOnly]="data.staffOnly"
			[Required]="data.mandatory"
			[Placeholder]="data.placeHolder"
			[Hint]="data.hint"
			[allowedContactIds]="data.allowedContactIds"
		></contact-lookup-local>
		<contact-lookup
			*ngIf="!data.allowedContactIds?.length"
			[FormControl]="getControl('contactId')"
			(Selected)="onContactSelected($event)"
			[CreatePersonsCtrl]="getControl('createPersons')"
			[CreateCompaniesCtrl]="getControl('createCompanies')"
			[CreatePluralCtrl]="getControl('createPlurals')"
			[StaffOnly]="data.staffOnly"
			[AllowCreate]="data.allowCreate"
			[Required]="data.mandatory"
			[Placeholder]="data.placeHolder"
			[Hint]="data.hint"
			[allowPlurals]="data.allowCreatePlural"
		></contact-lookup>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-raised-button color="primary" throttleButton (throttledClick)="saveRole()" [disabled]="form.invalid">Ok</button>
	<button mat-raised-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
