// This file has been generated from ImportController.cs
// Do not modify this file directly!!
// tslint:disable

import { HttpClient, HttpResponse } from '@angular/common/http';
import { IDownloadFile } from '../models/Files/IFileDownloader';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { TrimStringsOnObject } from '@common/utils/string-format';

import { EntityReference } from '../models/Common/EntityReference';
import { ListResponse } from '../models/Generic/ListResponse';
import { ImportRequestDto } from '../models/Imports/Item/ImportRequestDto';
import { ImportUploadDto } from '../models/Imports/Item/ImportUploadDto';
import { ImportListItemDto } from '../models/Imports/List/ImportListItemDto';
import { ImportListRequest } from '../models/Imports/List/ImportListRequest';
import { IUploadMultipleFiles } from '@common/models/Common/IFileUploader';
import { IResultWithAttachments } from '@common/models/Common/IResultWithAttachments';
@Injectable({
	providedIn: 'root'
})
export class ImportService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'api/v1/Import', 'Import');
	}

	getImportList(request?: Partial<ImportListRequest>): Observable<ListResponse<ImportListItemDto>> {
		return this.getList<ImportListRequest, ImportListItemDto>('', request);
	}

	getImportErrors(id: string): Observable<HttpResponse<Blob>> {
		return this.getBlob<void>(`errors/${id}`);
	}

	deleteImport(id: string): Observable<EntityReference> {
		return this.delete(`${id}`);
	}

	upload(request: ImportUploadDto, filesToUpload: IUploadMultipleFiles): Observable<Partial<IResultWithAttachments>> {
		return this.createUpdateWithHttpProgress<ImportUploadDto>(`Upload`, TransformDatesOnObject(ImportUploadDto, TrimStringsOnObject(request)), filesToUpload, 'POST', true);
	}

	submitImport(id: string): Observable<any> {
		return this.post<void, any>(`${id}`);
	}

	downloadTemplate(request: ImportRequestDto): Observable<HttpResponse<Blob>> {
		return this.getBlob<ImportRequestDto>(`template`, request);
	}

	downloadFile(importId: string): Observable<HttpResponse<Blob>> {
		return this.getBlob<void>(`download/${importId}`);
	}

}
