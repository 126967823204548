import { SortDirection } from '@angular/material/sort';

import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';
import { CostCodeListItemDto } from '@common/models/Settings/CostCodes/List/CostCodeListItemDto';
import { CostCodeListRequest } from '@common/models/Settings/CostCodes/List/CostCodeListRequest';
import { DomainError } from '@common/models/Validation/DomainError';
import { createAction, props } from '@ngrx/store';

import { SelectionType } from '../../../SelectionType';

export enum CostCodeListActions {
	Init = '[Cost Code List] Initialise',
	Load = '[Cost Code List] Load',
	Loading = '[Cost Code List] Loading',
	LoadSuccess = '[Cost Code List] Load Success',
	LoadFailed = '[Cost Code List] Load Failed',

	SetFilters = '[Cost Code List] Set Filters',
	SetPageSize = '[Cost Code List] Set Page Size',
	SetPageIndex = '[Cost Code List] Set Page Index',
	SetPageIndexForId = '[Cost Code List] Set Page Index For Id',

	SetSortBy = '[Cost Code List] Set Sort By',
	SetSortDirection = '[Cost Code List] Set Sort Direction',

	InsertRecords = '[Cost Code List] Insert Records',
	UpdateRecords = '[Cost Code List] Update Records',
	RemoveRecords = '[Cost Code List] Remove Records',
	SelectRecords = '[Cost Code List] Select Records'
}

export const init = createAction(CostCodeListActions.Init);
export const load = createAction(CostCodeListActions.Load);
export const loading = createAction(CostCodeListActions.Loading);
export const loadSuccess = createAction(CostCodeListActions.LoadSuccess, props<{ response: CostCodeListItemDto }>());
export const loadFailed = createAction(CostCodeListActions.LoadFailed, props<{ error: DomainError }>());

export const setFilters = createAction(
	CostCodeListActions.SetFilters,
	props<{ filter: Partial<CostCodeListRequest> }>()
);
export const setPageSize = createAction(CostCodeListActions.SetPageSize, props<{ pageSize: number }>());
export const setPageIndex = createAction(CostCodeListActions.SetPageIndex, props<{ pageIndex: number }>());
export const setPageIndexForId = createAction(CostCodeListActions.SetPageIndexForId, props<{ id: string }>());

export const setSortBy = createAction(CostCodeListActions.SetSortBy, props<{ sortBy: string }>());
export const setSortDirection = createAction(
	CostCodeListActions.SetSortDirection,
	props<{ sortDirection: SortDirection }>()
);

export const insertRecords = createAction(
	CostCodeListActions.InsertRecords,
	props<{ response: MutationResponseDto | MutationResponseDto[] }>()
);
export const updateRecords = createAction(
	CostCodeListActions.UpdateRecords,
	props<{ response: MutationResponseDto | MutationResponseDto[] }>()
);
export const removeRecords = createAction(
	CostCodeListActions.RemoveRecords,
	props<{ response: MutationResponseDto | MutationResponseDto[] }>()
);
export const selectRecords = createAction(
	CostCodeListActions.SelectRecords,
	props<{ row: CostCodeListItemDto; selectionType: SelectionType }>()
);
