import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatListOption } from '@angular/material/list';
import { MatSelect } from '@angular/material/select';

import { Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { EntityReference } from '@common/models/Common/EntityReference';
import { EnumSortDirection } from '@common/models/Common/EnumSortDirection';
import { BriefSection } from '@common/models/DocumentBriefs/Item/BriefSection';
import { DocumentBriefCreateUpdateDto } from '@common/models/DocumentBriefs/Item/DocumentBriefCreateUpdateDto';
import { DocumentBriefTemplateListItemDto } from '@common/models/DocumentBriefTemplates/List/DocumentBriefTemplateListItemDto';
import { CreateUpdateBriefResponse } from '@common/models/Documents/Common/CreateUpdateBriefResponse';
import { NotificationService } from '@common/notification';
import { DocumentBriefsService } from '@common/services/documentbriefs.service';
import { DocumentBriefTemplatesService } from '@common/services/documentbrieftemplates.service';
import { newGuid } from '@common/utils/create-guid';
import { CustomValidators } from '@common/validation/custom.validators';
import { get } from 'lodash-es';

import { DocumentsService } from 'app/services/documents.service';

import { ICreateBrief } from './ICreateBrief';

export enum CreateBriefType {
	Tags = 'Tags',
	Template = 'Template'
}

@Component({
	selector: 'create-brief-dialog',
	styleUrls: ['./create-brief-dialog.component.scss'],
	templateUrl: './create-brief-dialog.component.html'
})
export class CreateBriefDialogComponent implements OnInit, OnDestroy {
	private subscriptions: Subscription = new Subscription();
	sections: BriefSection[] = [];
	form: FormGroup;
	duplicateSectionErrors: boolean[] = [];

	@ViewChild(MatListOption) sectionList: MatListOption;
	tags: string[];
	templates: DocumentBriefTemplateListItemDto[];

	readonly tagsKey: keyof typeof CreateBriefType = 'Tags';
	readonly templateKey: keyof typeof CreateBriefType = 'Template';

	createBriefType: keyof typeof CreateBriefType;

	@ViewChild('selectTemplates', { static: true })
	selectTemplates: MatSelect;

	constructor(
		private fb: FormBuilder,
		private documentservice: DocumentsService,
		@Inject(MAT_DIALOG_DATA) public data: IDocumentBriefDialogData,
		private documentBriefsService: DocumentBriefsService,
		private documentBriefTemplatesService: DocumentBriefTemplatesService,
		private notificationService: NotificationService,
		private dialogRef: MatDialogRef<CreateBriefDialogComponent>
	) {}

	checkBriefType(type: keyof typeof CreateBriefType): void {
		if (this.createBriefType !== type) {
			this.createBriefType = type;
			this.form.updateValueAndValidity();
			this.form.controls.templateId.updateValueAndValidity();
		}
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	get availableTags() {
		if (this.tags) {
			return this.tags.filter(tag => !this.sections.map(x => x.name).includes(tag));
		}
		return null;
	}

	get isEdit() {
		return !!this.data?.briefId;
	}

	ngOnInit(): void {
		this.subscriptions.add(
			this.documentBriefTemplatesService
				.getTemplates({
					sortBy: 'name',
					sortDirection: EnumSortDirection.Asc,
					matterIds: !!this.data?.matterId ? [this.data.matterId] : null
				})
				.subscribe(next => (this.templates = next.records))
		);

		this.form = this.fb.group({
			name: [null, CustomValidators.required('Name')],
			templateId: [
				null,
				CustomValidators.requiredWhen(() => this.createBriefType === this.templateKey, 'TemplateId')
			],
			createBriefType: null,
			sections: this.fb.group([])
		}) as FormGroupTyped<ICreateBrief>;

		if (this.data?.matterId) {
			this.subscriptions.add(
				this.documentservice.getDocumentTags({ matterId: this.data.matterId }).subscribe(tags => {
					if (!this.data?.briefId) {
						const sections = tags.map((tag, index) => ({
							name: tag,
							id: newGuid(),
							orderNumber: null,
							selected: false,
							documentIds: []
						}));
						this.updateModel(sections);
					} else this.tags = tags;
				})
			);
		}
		if (this.isEdit) {
			this.subscriptions.add(
				this.documentBriefsService.getDocumentBrief(this.data.briefId).subscribe(brief => {
					this.createBriefType = this.tagsKey;

					this.form.patchValue(brief);
					this.updateModel(brief.sections);
					if (this.data.isTryingToAddNewSection) {
						this.addSection();
					}
				})
			);
		}
	}

	// Update the form data when editing an existing custom field
	updateModel(sections: BriefSection[]) {
		sections.forEach(section => this.addSection(section));
	}

	drop(event: CdkDragDrop<string[]>) {
		moveItemInArray(this.sections, event.previousIndex, event.currentIndex);
	}

	save() {
		if (this.data?.briefId) this.saveExistingBrief();
		else this.createBrief();
	}

	delete() {
		this.subscriptions.add(
			this.notificationService
				.showConfirmation('Delete Brief', `Are you sure you want to delete the brief?`)
				.pipe(
					filter(Boolean),
					switchMap(() => this.documentBriefsService.deleteDocumentBrief(this.data?.briefId))
				)
				.subscribe(response => {
					const dialogResponse: IDocumentBriefDialogResponseData = {
						brief: { id: response.id, name: response.name },
						operation: DialogOperation.Delete
					};
					this.dialogRef.close(dialogResponse);
				})
		);
	}

	createBrief() {
		this.subscriptions.add(
			this.handleSaveResponse(
				this.documentBriefsService.createDocumentBrief(
					!!this.sectionList
						? this.createBriefDto(this.sectionList?.selectionList?._value || [])
						: this.createBriefDto()
				),
				false
			)
		);
	}

	saveExistingBrief() {
		this.subscriptions.add(
			this.handleSaveResponse(
				this.documentBriefsService.updateDocumentBrief(this.data.briefId, this.createBriefDto()),
				true
			)
		);
	}

	closeDialog() {
		this.dialogRef.close();
	}

	private createBriefDto(selectedSections: string[] = null): DocumentBriefCreateUpdateDto {
		let sections = this.createBriefType === this.tagsKey ? this.sections : [];
		if (!!selectedSections && selectedSections.length > 0) {
			sections = sections.filter(x => selectedSections.includes(x.id));
		}

		return {
			name: this.form.get('name').value,
			matterId: this.data.matterId,
			sections:
				this.createBriefType === this.tagsKey
					? sections.map(
							(section, index) =>
								({
									name: this.form.get('sections').get(section.id).value,
									id: section.id,
									orderNumber: index + 1,
									documentIds: section.documentIds
								} as BriefSection)
					  )
					: null,
			templateId: this.createBriefType === this.templateKey ? this.form.get('templateId').value : null
		};
	}

	private handleSaveResponse(observable: Observable<CreateUpdateBriefResponse>, isUpdating: boolean) {
		this.subscriptions.add(
			observable.subscribe({
				next: response => {
					if (get(response, 'duplicates')?.length > 0) {
						let duplicateMessage: string = '';

						(response as CreateUpdateBriefResponse).duplicates.map(duplicate => {
							let sectionList = '';

							duplicate.sectionNames.forEach(sectionName => {
								sectionList += '<li>' + sectionName + '</li>';
							});

							duplicate.sectionNames.map(sectionName => {
								return '<li>' + sectionName + '</li>';
							});

							duplicateMessage +=
								'The document <b>' +
								duplicate.documentTitle +
								'</b> is duplicated in multiple sections:' +
								'<ul>' +
								sectionList +
								'</ul>';
						});

						this.notificationService.showError('Document in multiple sections', duplicateMessage);
					}
					this.dialogRef.close({
						brief: { id: response.documentReference.id, name: response.documentReference.name },
						operation: isUpdating ? DialogOperation.Update : DialogOperation.Create
					});
				},
				error: errors =>
					this.notificationService.showErrors(
						isUpdating ? 'Error updating Brief' : 'Error creating Brief',
						errors
					)
			})
		);
	}

	getSectionControl(sectionId: string): FormControl {
		return (this.form.controls.sections as FormGroup).controls[sectionId] as FormControl;
	}

	addSection(section: BriefSection = null) {
		const sectionId = section?.id || newGuid();
		const sectionControl = new FormControl(section?.name, CustomValidators.required());
		(this.form.get('sections') as FormGroup).addControl(sectionId, sectionControl);
		this.sections.push({
			id: sectionId,
			name: section?.name,
			orderNumber: this.sections.length,
			documentIds: section?.documentIds
		});

		// Set focus on the newly created control
		setTimeout(() => {
			const elements = document.getElementsByClassName('ng-invalid');
			// tslint:disable-next-line:prefer-for-of
			for (let i = 0; i < elements.length; i++) {
				(elements[i] as any).focus();
			}
		}, 0);
	}

	sectionNameEntered(event: any, sectionId: string, index: number) {
		const newSectionName = event.target.value;

		const foundIndex = this.sections.map(s => s.name).indexOf(newSectionName);

		const section = this.sections.find(s => s.id === sectionId);
		this.sections.splice(index, 1, {
			id: sectionId,
			name: newSectionName,
			orderNumber: section.orderNumber,
			documentIds: section.documentIds
		});

		this.duplicateSectionErrors[index] = foundIndex > -1;

		const duplicateSectionsExist = this.duplicateSectionErrors.some(e => e === true);
		if (duplicateSectionsExist) (this.form.get('sections') as FormGroup).setErrors({ duplicateSection: true });
	}

	removeSection(id: string, index: number) {
		(this.form.get('sections') as FormGroup).removeControl(id);

		const sectionName = this.sections[index].name;
		this.sections.splice(index, 1);
		this.duplicateSectionErrors.splice(index, 1);

		const sameNamedSections = this.sections.filter(s => s.name === sectionName);
		if (sameNamedSections.length <= 1) {
			this.duplicateSectionErrors[index] = false;
		}
		if (sameNamedSections.length === 1) {
			const duplicateIndex = this.sections.findIndex(x => x.name === sameNamedSections[0].name);
			this.duplicateSectionErrors[duplicateIndex] = false;
		}

		const duplicateSectionsExist = this.duplicateSectionErrors.some(e => e === true);
		if (duplicateSectionsExist) (this.form.get('sections') as FormGroup).setErrors({ duplicateSection: true });
	}
}

export interface IDocumentBriefDialogData {
	matterId: string;
	briefId: string;
	isTryingToAddNewSection: boolean;
}

export interface IDocumentBriefDialogResponseData {
	brief: EntityReference;
	operation: DialogOperation;
}

export enum DialogOperation {
	Create = 'Create',
	Delete = 'Delete',
	Update = 'Update'
}
