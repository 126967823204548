<h2 mat-dialog-title *ngIf="!!isInstall">Install Package</h2>
<h2 mat-dialog-title *ngIf="!!isUninstall">Uninstall Package</h2>
<h2 mat-dialog-title *ngIf="!!isUpdate">Update Package</h2>
<h2 mat-dialog-title *ngIf="!!isConfigure">Configure Package</h2>

<mat-dialog-content class="flex-column" [formGroup]="form">
	<ng-container *ngIf="!!isConfirmationStage">
		<p>
			Are you sure you want to {{ operationName.toLocaleLowerCase() }} the package <b>{{ productName }}</b
			>?
		</p>

		<ng-container *ngIf="!!isUpdate">
			<p>{{ operationAdjectiveName }} this package could change custom fields and remove document templates.</p>
		</ng-container>

		<ng-container *ngIf="!isUpdate">
			<p *ngIf="!!anyAffectedEntities">{{ operationAdjectiveName }} this package will {{ !!isInstall ? 'add' : 'remove' }} the following items:</p>
			<ul *ngIf="!!anyAffectedEntities">
				<li *ngIf="affectedCustomFieldsCount > 0">{{ affectedCustomFieldsCount }} Custom Fields</li>
				<li *ngIf="affectedCustomFieldGroupsCount > 0">{{ affectedCustomFieldGroupsCount }} Custom Field Groups</li>
				<li *ngIf="affectedDocumentTemplatesCount > 0">{{ affectedDocumentTemplatesCount }} Document Templates</li>
				<li *ngIf="affectedDocumentHeaderFooterTemplatesCount > 0">{{ affectedDocumentHeaderFooterTemplatesCount }} Header/Footer Templates</li>
				<li *ngIf="affectedEmailTemplatesCount > 0">{{ affectedEmailTemplatesCount }} Email Templates</li>
				<li *ngIf="affectedPracticeAreasCount > 0">{{ affectedPracticeAreasCount }} Practice Areas</li>
			</ul>
		</ng-container>

		<div class="flex-column c-checkbox-container" *ngIf="!!mapPracticeAreas">
			<mat-checkbox color="primary" formControlName="mapPracticeAreas">
				<span>Map package practice areas to existing practice areas in {{ siteName }}</span>
			</mat-checkbox>
		</div>
	</ng-container>

	<ng-container *ngIf="!!isConfigureStage">
		<div class="flex-column">
			<div class="flex-row" *ngIf="!mapPracticeAreas">
				<mat-form-field>
					<mat-placeholder>Practice Areas</mat-placeholder>
					<mat-select #selectPracticeAreas formControlName="practiceAreaIds" multiple>
						<mat-option (onSelectionChange)="allOptionSelected($event)" #allOption>All</mat-option>
						<mat-option (onSelectionChange)="optionSelected($event)" *ngFor="let practiceArea of practiceAreas" [value]="practiceArea.id">{{
							practiceArea.name
						}}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div class="flex-column" *ngIf="!!mapPracticeAreas">
				<p>
					Map package practice areas to existing practice areas within {{ siteName }}. Any unmapped package practice area will be added to
					{{ siteName }}.
				</p>

				<div class="flex-practice-area">
					<div class="flex-practice-area-container">
						<h4 class="flex-practice-area-heading flex-practice-area-heading--start">Package</h4>
						<h4 class="flex-practice-area-heading flex-practice-area-heading--end">{{ siteName }}</h4>

						<ng-container *ngFor="let practiceAreaControl of packagePracticeAreaControls">
							<div class="flex-practice-area-mapping-title">
								<span>{{ getPracticeArea(getControlValue(practiceAreaControl, 'practiceAreaId')).name }}</span>
								<span
									*ngIf="!!getPracticeArea(getControlValue(practiceAreaControl, 'practiceAreaId')).description"
									class="flex-practice-area-mapping-description"
									>{{ getPracticeArea(getControlValue(practiceAreaControl, 'practiceAreaId')).description }}</span
								>
							</div>
							<mat-icon class="flex-practice-area-mapping-icon">east</mat-icon>
							<mat-form-field class="flex-practice-area-mapping-field">
								<mat-placeholder>Practice Areas</mat-placeholder>
								<mat-select #selectPracticeAreas [formControl]="getControl(practiceAreaControl, 'practiceAreaMappingId')" multiple>
									<mat-option (onSelectionChange)="allOptionSelected($event)" #allOption>All</mat-option>
									<mat-option
										(onSelectionChange)="optionSelected($event)"
										*ngFor="let practiceArea of practiceAreas"
										[value]="practiceArea.id"
										>{{ practiceArea.name }}</mat-option
									>
								</mat-select>
							</mat-form-field>
						</ng-container>
					</div>
				</div>
			</div>

			<div class="flex-row c-checkbox-container" *ngIf="isTermsAgreementRequired">
				<mat-checkbox color="primary" formControlName="termsAccepted">
					<span>
						By checking this box I agree to the
						<a [href]="productTermsUrl" target="_blank">terms & conditions</a>.
					</span>
				</mat-checkbox>
			</div>
		</div>
	</ng-container>

	<div class="progress-container" *ngIf="!!isProcessingStage">
		<p>{{ operationAdjectiveName }} packages, please wait for this to finish</p>
		<mat-progress-bar mode="indeterminate"></mat-progress-bar>
	</div>
</mat-dialog-content>
<mat-dialog-actions *ngIf="!isProcessingStage">
	<button
		mat-raised-button
		color="primary"
		throttleButton
		(throttledClick)="executeOperation()"
		[disabled]="!isConfirmationStage && (!isConfigureStage || (!!form.invalid && !mapPracticeAreas))"
	>
		{{ !!isConfirmationStage ? 'Yes' : 'Confirm' }}
	</button>
	<button mat-raised-button mat-dialog-close>{{ !!isConfirmationStage ? 'No' : 'Cancel' }}</button>
</mat-dialog-actions>
