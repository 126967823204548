import { Component, Input, ViewChild } from '@angular/core';
import { MatOption, MatOptionSelectionChange } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';

import { InstallationRecordInstallDetailsSlimDto } from '@common/models/Marketplace/InstallationRecords/Item/InstallationRecordInstallDetailsSlimDto';
import { InstallationRecordUninstallDetailsSlimDto } from '@common/models/Marketplace/InstallationRecords/Item/InstallationRecordUninstallDetailsSlimDto';
import { PracticeAreaListItemDto } from '@common/models/Settings/PracticeAreas/List/PracticeAreaListItemDto';

import { AppBrandingService } from 'app/services/app-branding.service';

@Component({
	selector: 'configure-package',
	templateUrl: './configure-package.component.html',
	styleUrls: ['./configure-package.component.scss']
})
export class ConfigurePackageComponent {
	@ViewChild('allOption') allOption: MatOption;
	@ViewChild('selectPracticeAreas') selectPracticeAreas: MatSelect;

	@Input()
	operation: ConfigurePackageOperationType;
	@Input()
	data: IPackageConfigurationData;
	@Input()
	form: FormGroupTyped<IPackageConfiguration>;
	@Input()
	productName: string;

	get isUninstall() {
		return this.operation === ConfigurePackageOperationType.Uninstall;
	}
	get isInstall() {
		return this.operation === ConfigurePackageOperationType.Install;
	}
	get isUpdate() {
		return this.operation === ConfigurePackageOperationType.Update;
	}
	get isConfigure() {
		return this.operation === ConfigurePackageOperationType.Configure;
	}

	get siteName() {
		return AppBrandingService.getSiteName();
	}

	get practiceAreas() {
		return this.data.practiceAreas;
	}

	get operationAdjectiveName(): string {
		return !!this.isInstall
			? 'Subscribing'
			: !!this.isUninstall
			? 'Unsubscribing'
			: !!this.isUpdate
			? 'Updating'
			: '';
	}

	get affectedCustomFieldsCount() {
		return (
			this.data.installPackageDetails?.installableCustomFieldsCount ??
			this.data.uninstallPackageDetails?.removableCustomFieldsCount ??
			0
		);
	}

	get affectedCustomFieldGroupsCount() {
		return (
			this.data.installPackageDetails?.installableCustomFieldGroupsCount ??
			this.data.uninstallPackageDetails?.removableCustomFieldGroupsCount ??
			0
		);
	}

	get affectedDocumentTemplatesCount() {
		return (
			this.data.installPackageDetails?.installableDocumentTemplatesCount ??
			this.data.uninstallPackageDetails?.removableDocumentTemplatesCount ??
			0
		);
	}

	get affectedDocumentHeaderFooterTemplatesCount() {
		return (
			this.data.installPackageDetails?.installableDocumentHeaderFooterTemplatesCount ??
			this.data.uninstallPackageDetails?.removableDocumentHeaderFooterTemplatesCount ??
			0
		);
	}

	get affectedEmailTemplatesCount() {
		return (
			(this.data.installPackageDetails as InstallationRecordInstallDetailsSlimDto)
				?.installableDocumentEmailTemplatesCount ??
			(this.data.uninstallPackageDetails as InstallationRecordUninstallDetailsSlimDto)
				?.removableDocumentEmailTemplatesCount ??
			0
		);
	}

	get affectedPracticeAreasCount() {
		return this.data.installPackageDetails?.practiceAreas?.length ?? 0;
	}

	get anyAffectedEntities() {
		return (
			this.affectedCustomFieldsCount > 0 ||
			this.affectedCustomFieldGroupsCount > 0 ||
			this.affectedDocumentTemplatesCount > 0 ||
			this.affectedEmailTemplatesCount > 0
		);
	}

	get packagePracticeAreaControls() {
		return (this.form.get('practiceAreaMappings') as unknown as FormArray).controls as FormGroup[];
	}

	get packagePracticeAreas() {
		return this.data.installPackageDetails?.practiceAreas;
	}

	get canMapPracticeAreas() {
		return this.affectedPracticeAreasCount > 0;
	}

	get mustMapPracticeAreas() {
		return this.form.get('mapPracticeAreas').value;
	}

	getControl(formGroup: FormGroup, key: string) {
		return formGroup.get(key);
	}

	getControlValue(formGroup: FormGroup, key: string) {
		return formGroup.get(key)?.value;
	}

	getPracticeArea(id: string) {
		var results = this.packagePracticeAreas?.filter(practiceArea => practiceArea.id === id);

		return !!results?.length ? results[0] : null;
	}

	allOptionSelected(event: MatOptionSelectionChange) {
		if (event.source.selected) {
			// When selecting the 'All' option, deselect all practice areas
			this.selectPracticeAreas.options.forEach(opt => {
				if (opt.selected && opt.id !== this.allOption.id) {
					opt.deselect();
				}
			});
		} else if (!this.selectPracticeAreas.options.some(opt => opt.selected)) {
			// If nothing is selected, select the 'All' option
			this.allOption.select();
		}
	}

	optionSelected(event: MatOptionSelectionChange) {
		if (this.allOption.selected && event.source.selected && event.source.id !== this.allOption.id) {
			// When selecting a practice area, deselect the 'All' option
			this.allOption.deselect();
		} else if (!this.selectPracticeAreas.options.some(opt => opt.selected)) {
			// If nothing is selected, select the 'All' option
			this.allOption.select();
		}
	}
}

export enum ConfigurePackageOperationType {
	None = 0,
	Uninstall = 1,
	Install = 2,
	Update = 3,
	Configure = 4
}

export interface IPackageConfigurationData {
	practiceAreas?: PracticeAreaListItemDto[];
	installPackageDetails?: InstallationRecordInstallDetailsSlimDto;
	uninstallPackageDetails?: InstallationRecordUninstallDetailsSlimDto;
}

export interface IPackageConfiguration {
	practiceAreaMappings: FormArray;
	practiceAreaIds: string[];
	termsAccepted: boolean;
	mapPracticeAreas: boolean;
}
