import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { filter, switchMap } from 'rxjs/operators';

import { EntityReference } from '@common/models/Common/EntityReference';
import { ContactReference } from '@common/models/Contacts/Common/ContactReference';
import { SecurityPolicies } from '@common/models/Settings/SecurityRoles/Common/SecurityPolicies';
import { SecurityRoleCreateUpdateDto } from '@common/models/Settings/SecurityRoles/Item/SecurityRoleCreateUpdateDto';
import { SecurityRoleViewDto } from '@common/models/Settings/SecurityRoles/Item/SecurityRoleViewDto';
import { SecurityRoleListItemDto } from '@common/models/Settings/SecurityRoles/List/SecurityRoleListItemDto';
import { DomainError } from '@common/models/Validation/DomainError';
import { NotificationService } from '@common/notification';
import { SecurityRolesService } from '@common/services/settings/securityroles.service';

import { FeatureFlags, isFeatureFlagEnabled } from 'app/app.config';
import { AppBrandingService } from 'app/services/app-branding.service';
import { GridViewService } from 'app/services/grid-view.service';
import { GenericListComponent } from 'app/shared/generics/generic.list.component';

import { SecurityRoleItemComponent } from '../item/security-role-item.component';
import { SecurityRoleMembersComponent } from '../item/security-role-members.component';

@Component({
	styleUrls: ['./security-role-list.component.scss'],
	templateUrl: './security-role-list.component.html'
})
export class SecurityRoleListComponent
	extends GenericListComponent<SecurityRoleListItemDto, void>
	implements OnInit, OnDestroy
{
	get siteName() {
		return AppBrandingService.getSiteName();
	}

	constructor(
		dialog: MatDialog,
		router: Router,
		activatedRoute: ActivatedRoute,
		private securityRolesService: SecurityRolesService,
		private notificationService: NotificationService,
		gridViewService: GridViewService
	) {
		super(dialog, router, activatedRoute, securityRolesService, [], gridViewService);
	}

	ngOnInit(): void {
		this.defaultDisplayedColumns = [].concat('name', 'members', 'practiceAreas', this.policyKeys, 'actions');
	}

	create() {
		this.subscriptions.add(
			this.dialog
				.open(SecurityRoleItemComponent)
				.afterClosed()
				.pipe(
					filter(Boolean),
					switchMap((data: SecurityRoleCreateUpdateDto) => this.securityRolesService.createSecurityRole(data))
				)
				.subscribe(this.onCreateSuccess, this.onError)
		);
	}

	edit(row: SecurityRoleListItemDto) {
		this.subscriptions.add(
			this.securityRolesService
				.getSecurityRole(row.id)
				.pipe(
					switchMap((data: SecurityRoleViewDto) => {
						return this.dialog.open(SecurityRoleItemComponent, { data }).afterClosed();
					}),
					filter(Boolean),
					switchMap((data: SecurityRoleCreateUpdateDto) => {
						return this.securityRolesService.updateSecurityRole(row.id, data);
					})
				)
				.subscribe(this.onUpdateSuccess, this.onError)
		);
	}

	editMembers(row: SecurityRoleListItemDto) {
		this.subscriptions.add(
			this.securityRolesService
				.getSecurityRole(row.id)
				.pipe(
					switchMap((data: SecurityRoleViewDto) => {
						return this.dialog.open(SecurityRoleMembersComponent, { data }).afterClosed();
					}),
					filter(Boolean),
					switchMap((data: ContactReference[]) => {
						return this.securityRolesService.updateSecurityRoleMemberships(row.id, {
							contactIds: data.map(x => x.id)
						});
					})
				)
				.subscribe(this.onUpdateSuccess, this.onError)
		);
	}

	delete(row: SecurityRoleListItemDto) {
		const message =
			row.members.length === 0
				? `Are you sure you want to delete the security role "${row.name}"?`
				: `The security role "${row.name}" is associated with ${row.members.length} user${
						row.members.length === 1 ? '' : 's'
				  }. Are you sure you want to delete it?`;

		this.subscriptions.add(
			this.notificationService
				.showConfirmation('Delete Security Role', message)
				.pipe(
					filter(Boolean),
					switchMap(() => this.securityRolesService.deleteSecurityRole(row.id))
				)
				.subscribe(this.onDeleteSuccess, this.onError)
		);
	}

	hasPolicy(row: SecurityRoleListItemDto, key: keyof typeof SecurityPolicies): boolean {
		return row?.policies?.findIndex(x => x === key) >= 0;
	}

	policyDisplayName(key: keyof typeof SecurityPolicies): string {
		return SecurityPolicies[key];
	}

	get policyKeys(): (keyof typeof SecurityPolicies)[] {
		return Object.keys(SecurityPolicies)
			.filter(
				(key: keyof typeof SecurityPolicies) =>
					SecurityPolicies[key] !== SecurityPolicies.None &&
					(!!isFeatureFlagEnabled(FeatureFlags.matterSecurity) ||
						SecurityPolicies[key] !== SecurityPolicies.MatterSecurity)
			)
			.map(key => key as keyof typeof SecurityPolicies);
	}

	private onError = (errors: DomainError[]) => {
		this.notificationService.showErrors('Error', errors);
	};

	private onCreateSuccess = (result: EntityReference) => {
		this.notificationService.showNotification(`Security Role ${result?.name} created`);
		this.refreshList();
	};

	private onUpdateSuccess = (result: EntityReference) => {
		this.notificationService.showNotification(`Security Role ${result?.name} updated`);
		this.refreshList();
	};

	private onDeleteSuccess = (result: EntityReference) => {
		this.notificationService.showNotification(`Security Role ${result?.name} deleted`);
		this.refreshList();
	};
}
