import { Directive, OnDestroy } from '@angular/core';

import { PricedMarketplaceListingViewDto } from '@common/models/Infrastructure/StripeIntegration/Models/PricedMarketplaceListingViewDto';
import { InstallationRecordStatus } from '@common/models/Marketplace/InstallationRecords/Common/InstallationRecordStatus';
import { StripeInfo } from '@common/models/Settings/Setting/Item/StripeInfo';
import { StripeSubscriptionItem } from '@common/models/Settings/Setting/Item/StripeSubscriptionItem';

import { BaseStripeInfoComponent } from './base-stripe-info.component';

@Directive()
export class BaseModifyMarketplaceItemSubscription extends BaseStripeInfoComponent implements OnDestroy {
	private _marketplaceListing: PricedMarketplaceListingViewDto;
	public get marketplaceListing() {
		return this._marketplaceListing;
	}

	private _stripeSubscriptionItem: StripeSubscriptionItem;
	private installedStatusKey: keyof typeof InstallationRecordStatus = InstallationRecordStatus.Installed;

	protected get stripeProductId() {
		return this.marketplaceListing.stripeProductId;
	}

	protected get marketplaceListingId() {
		return this.marketplaceListing.id;
	}

	public get productName() {
		return this.marketplaceListing?.productName;
	}

	public get isPackage() {
		return this.marketplaceListing?.listingType == 'Package';
	}

	public get isModule() {
		return this.marketplaceListing?.listingType == 'Module';
	}

	public get hasProductTerms() {
		return !!this.isPackage && !!this.marketplaceListing?.productTermsUrl;
	}

	public get productTermsUrl() {
		return !!this.hasProductTerms ? this.marketplaceListing?.productTermsUrl : null;
	}

	get trialEndDate() {
		return this.marketplaceListing?.packageDetails?.trialExpiryDate;
	}

	get trialDays(): number {
		return this.marketplaceListing?.packageDetails?.trialableDays ?? 0;
	}

	get isTrial() {
		return !this.isFree && this.isPackage && this.trialDays > 0 && !this.isPackageInstalled;
	}

	get isFree() {
		return this.isPackage && this._marketplaceListing?.packageDetails?.pricingModel == 'Free';
	}

	public get isProductSubscribed(): boolean {
		return !!this._stripeSubscriptionItem?.subscriptionItemId;
	}

	public get isPackageInstalled(): boolean {
		return this.isPackage && this._marketplaceListing?.installationStatus == this.installedStatusKey;
	}

	constructor(data: IBaseModifySubscriptionItemComponent) {
		super({ stripeInfo: data.stripeInfo });
		this._marketplaceListing = data.marketplaceListing;
		if (this.stripeInfo?.subscriptions?.length > 0) {
			this._stripeSubscriptionItem = this.stripeInfo.subscriptions.find(
				si => si.productId === this._marketplaceListing.stripeProductId
			);
		}
	}

	ngOnDestroy(): void {
		super.ngOnDestroy();
	}
}

export interface IBaseModifySubscriptionItemComponent {
	marketplaceListing: PricedMarketplaceListingViewDto;
	stripeInfo?: StripeInfo;
}
