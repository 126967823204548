<div class="flex-column">
	<div class="add-condition">
		<button mat-button color="primary" throttleButton (throttledClick)="addMatterWorkflowCondition()">
			<mat-icon>add_circle</mat-icon>
			<span>&nbsp;Add Condition</span>
		</button>
	</div>
	<div>
		<basic-workflow-condition
			*ngFor="let item of matterWorkflowConditions.controls; let i = index"
			[formGroup]="item"
			(removeClicked)="removeMatterWorkflowCondition(i)"
		></basic-workflow-condition>
	</div>
</div>
