import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs/internal/Subscription';

import { RolePermission } from '@common/models/Common/RolePermission';
import { Features } from '@common/models/Settings/Modules/Features';
import { StripeInfo } from '@common/models/Settings/Setting/Item/StripeInfo';
import { GeneralSettingsService } from '@common/services/settings/generalsettings.service';
import { ITenantData } from '@common/state/models/tenant-data';
import { Store } from '@ngrx/store';

import { FeatureFlags, isFeatureFlagEnabled } from 'app/app.config';
import { SecurityPermissionService } from 'app/core/security-permissions.service';
import { AppBrandingService } from 'app/services/app-branding.service';

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'system',
	styleUrls: ['./system.component.scss'],
	templateUrl: './system.component.html'
})
export class SystemComponent implements OnInit, OnDestroy {
	featureFlags: typeof FeatureFlags = FeatureFlags;
	featureTypes: typeof Features = Features;
	ignoreLicensing: boolean;
	showMarketPlace: boolean;

	private _tenantStripeInfo: StripeInfo;

	private subscriptions: Subscription = new Subscription();

	get showSubscriptions() {
		if (this.ignoreLicensing) {
			return this.isSubscribed;
		}
		return true;
	}

	private get isSubscribed() {
		return !!this._tenantStripeInfo?.subscriptionId;
	}

	get siteName() {
		return AppBrandingService.getSiteName();
	}

	constructor(
		private store: Store<{ tenantData: ITenantData }>,
		private securityPermissionService: SecurityPermissionService,
		private generalSettingsService: GeneralSettingsService
	) {}

	ngOnInit(): void {
		this.subscriptions.add(
			this.generalSettingsService.getTenantStripeInfo().subscribe(x => (this._tenantStripeInfo = x))
		);

		this.subscriptions.add(
			this.store
				.select(state => state.tenantData?.tenantInformation)
				.subscribe(info => {
					this.ignoreLicensing =
						(info?.ignoreLicensing ?? true) || !isFeatureFlagEnabled(FeatureFlags.licensing);
				})
		);

		this.showMarketPlace =
			this.securityPermissionService.hasRolePermission('SystemMarketplace') &&
			isFeatureFlagEnabled(FeatureFlags.marketplace);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
	hasRolePermission(permission: keyof typeof RolePermission) {
		return this.securityPermissionService.hasRolePermission(permission);
	}
}
