import { Component, Input, ViewChild } from '@angular/core';

import { ActivityLogListRequest } from '@common/models/ActivityLogs/List/ActivityLogListRequest';
import { FilterRootComponent } from 'app/shared/filter-controls';
import { ActivityLogListFilterConfig } from './ActivityLogListFilterConfig';

@Component({
	selector: 'activity-log-list-filter',
	styleUrls: ['./activity-log-list-filter.component.scss'],
	templateUrl: './activity-log-list-filter.component.html'
})
export class ActivityLogListFilterComponent {
	@ViewChild(FilterRootComponent, { static: true }) root: FilterRootComponent<ActivityLogListRequest>;
	@Input() config: ActivityLogListFilterConfig;

	listRequestType: { new (): ActivityLogListRequest } = ActivityLogListRequest;
}
