import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { FeatureFlags, isFeatureFlagEnabled } from 'app/app.config';
import { AccessRouteGuard } from 'app/core/access-route-guard/access-route.guard';
import { AppBrandingService } from 'app/services/app-branding.service';

import { AccessTokensComponent } from './access-tokens/access-tokens.component';
import { GlobalActivityLogListComponent } from './activity-log';
import { ApiKeysComponent } from './api-keys/api-keys.component';
import { BasicWorkflowContainerComponent } from './basic-workflows/basic-workflow-container.component';
import { BasicWorkflowsListComponent } from './basic-workflows/list/basic-workflows-list.component';
import { BillSettingsComponent } from './bill-settings/bill-settings.component';
import { CompanyDetailsComponent } from './company-details/company-details.component';
import { CostCodesListComponent } from './cost-codes/list/cost-codes-list.component';
import { CostTemplateListComponent } from './cost-templates/list/cost-template-list.component';
import { ContactCustomFieldListComponent } from './custom-fields/contact-custom-fields/contact-custom-field-list/contact-custom-field-list.component';
import { MatterCustomFieldListComponent } from './custom-fields/matter-custom-fields/matter-custom-field-list/matter-custom-field-list.component';
import { DataImportComponent } from './data-import';
import { ImportContactsComponent } from './data-import/contacts/import-contacts.component';
import { ImportInvoicesComponent } from './data-import/invoices/import-invoices.component';
import { ImportMattersComponent } from './data-import/matters/import-matters.component';
import { DocumentBriefExportSettingsComponent } from './document-brief-export-settings/document-brief-export-settings.component';
import { DocumentTemplateBriefListComponent } from './document-brief-templates/document-brief-template-list.component';
import { DocumentTagListComponent } from './document-tags/document-tag-list/document-tag-list.component';
import { DocumentTemplateComponent } from './document-templates/list/document-template.component';
import { GlobalJobListComponent } from './jobs/list/global-job-list.component';
import { LoginOptionsRulesComponent } from './login-options-rules/login-options-rules.component';
import { MarketplaceListingsComponent } from './marketplace/listings/marketplace-listings.component';
import { MarketplaceVendorComponent } from './marketplace/vendor/marketplace-vendor.component';
import { MatterMailToConfigComponent } from './matter-mailto-config/matter-mailto-config.component';
import { MatterNumberingConfigComponent } from './matter-numbering-config/matter-numbering-config.component';
import { MediaTypeListComponent } from './media-types/list/media-type-list.component';
import { PracticeAreasComponent, PracticeAreasListComponent } from './practice-areas';
import { ReferralReasonListComponent } from './referral-reasons/list/referral-reason-list.component';
import { SearchSettingsComponent } from './search-settings/search-settings.component';
import { SecurityRoleListComponent } from './security-roles/list/security-role-list.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { SystemComponent } from './system.component';
import { TenantFeaturesComponent } from './tenant-modules/tenant-features.component';
import { TimeZoneConfigComponent } from './time-zone-config/time-zone-config.component';
import { TimerConfigComponent } from './timer-config/timer-config.component';
import { TrustSettingsComponent } from './trust-accounting/trust-settings.component';
import { UnitsFieldComponent } from './units/units-field.component';
import { UserManagementListComponent } from './user-management/list/user-management-list.component';
import { UserTypeListComponent } from './user-types/list/user-type-list.component';

export const routes: Routes = [
	{ path: '', component: SystemComponent, pathMatch: 'full' },
	{
		path: 'matter-workflows',
		data: {
			title: 'Matter Workflows',
			description: 'Define matter workflows such as automatically generating tasks on newly created matters.',
			icon: 'auto_awesome_mosaic',
			featureType: 'MatterWorkflows',
			protectedFeature: 'MatterWorkflows'
		},
		component: BasicWorkflowsListComponent,
		pathMatch: 'full',
		canActivate: [AccessRouteGuard]
	},
	{
		path: 'matter-workflows/:basicWorkflowId',
		component: BasicWorkflowContainerComponent,
		pathMatch: 'full',
		data: {
			featureType: 'MatterWorkflows',
			protectedFeature: 'MatterWorkflows'
		},
		canActivate: [AccessRouteGuard]
	},
	{
		path: 'cost-codes',
		data: {
			title: 'Cost Codes',
			description: 'Create or modify cost codes that can be used when recording time or expense',
			icon: 'done',
			featureType: 'CostRecords'
		},
		component: CostCodesListComponent,
		pathMatch: 'full'
	},
	{
		path: 'trust-settings',
		data: {
			title: 'Trust & Bank Accounts',
			description: 'Create or modify trust and bank accounts',
			icon: 'account_balance',
			featureType: 'TrustAccounting'
		},
		component: TrustSettingsComponent,
		pathMatch: 'full'
	},
	{
		path: 'media-types',
		component: MediaTypeListComponent,
		pathMatch: 'full',
		data: {
			title: 'Transaction Media Types',
			description: 'Modify media types for bank transactions',
			icon: 'payment',
			featureType: 'TrustAccounting'
		}
	},
	{
		path: 'activity-log',
		component: GlobalActivityLogListComponent,
		pathMatch: 'full',
		data: {
			title: 'Activity Log',
			description: 'View all system activity information',
			icon: 'history',
			featureType: ''
		}
	},
	{
		path: 'bill-settings',
		component: BillSettingsComponent,
		pathMatch: 'full',
		data: {
			title: 'Invoice and Xero Settings',
			description: 'Invoice properties and synchronisation with Xero',
			icon: 'cached',
			featureType: 'Invoicing'
		}
	},
	{
		path: 'search-settings',
		component: SearchSettingsComponent,
		pathMatch: 'full',
		data: {
			title: 'Searching and Services',
			description: 'Configure and connect to Infotrack',
			icon: 'find_in_page',
			featureType: 'InfoTrack'
		}
	},
	{
		path: 'company-details',
		component: CompanyDetailsComponent,
		pathMatch: 'full',
		data: {
			title: 'Company Details',
			description: 'Assign company details',
			icon: 'business_center',
			featureType: ''
		}
	},
	{
		path: 'contact-custom-fields',
		component: ContactCustomFieldListComponent,
		pathMatch: 'full',
		data: {
			title: 'Contact Custom Fields',
			description: 'Create or modify contact custom Fields',
			icon: 'view_list',
			featureType: ''
		}
	},
	{
		path: 'cost-templates',
		component: CostTemplateListComponent,
		pathMatch: 'full',
		data: {
			title: 'Costing Templates',
			description: 'Define matter costing templates based on user role & hourly rate',
			icon: 'attach_money',
			featureType: 'CostRecords'
		}
	},
	{
		path: 'document-templates',
		component: DocumentTemplateComponent,
		pathMatch: 'full',
		data: {
			title: 'Document Templates',
			description: 'Create or modify document templates',
			icon: 'description',
			featureType: ''
		}
	},
	{
		path: 'document-brief-templates',
		component: DocumentTemplateBriefListComponent,
		pathMatch: 'full',
		data: {
			title: 'Document Brief Templates',
			description: 'Customise document brief templates by defining the sections, document tags & practice area',
			icon: 'business_center',
			featureType: 'Briefs'
		}
	},
	{
		path: 'document-brief-export-settings',
		component: DocumentBriefExportSettingsComponent,
		pathMatch: 'full',
		data: {
			title: 'Document Brief Export',
			description: 'Configure default document brief export settings',
			icon: 'business_center',
			featureType: 'Briefs'
		}
	},
	{
		path: 'document-tags',
		component: DocumentTagListComponent,
		pathMatch: 'full',
		data: {
			title: 'Document Tags',
			description: 'Create or modify document tags',
			icon: 'local_offer',
			featureType: ''
		}
	},
	{
		path: 'import',
		component: DataImportComponent,
		pathMatch: 'full',
		data: {
			title: 'Import',
			description: `Import data into ${AppBrandingService.getSiteName()}`,
			icon: 'import_export',
			featureType: ''
		}
	},
	{
		path: 'import/contact',
		component: ImportContactsComponent,
		pathMatch: 'full',
		data: {
			title: 'Import Contacts',
			description: `Import contacts into ${AppBrandingService.getSiteName()}`,
			icon: 'import_export',
			featureType: ''
		}
	},
	{
		path: 'import/matter',
		component: ImportMattersComponent,
		pathMatch: 'full',
		data: {
			title: 'Import Matters',
			description: `Import matters into ${AppBrandingService.getSiteName()}`,
			icon: 'import_export',
			featureType: ''
		}
	},
	{
		path: 'import/invoice',
		component: ImportInvoicesComponent,
		pathMatch: 'full',
		data: {
			title: 'Import Invoices',
			description: `Import invoices into ${AppBrandingService.getSiteName()}`,
			icon: 'import_export',
			featureType: 'Invoicing'
		}
	},
	{
		path: 'matter-custom-fields',
		component: MatterCustomFieldListComponent,
		pathMatch: 'full',
		data: {
			title: 'Matter Custom Fields',
			description: 'Create or modify matter custom fields',
			icon: 'view_list',
			featureType: ''
		}
	},
	{
		path: 'practice-areas',
		component: PracticeAreasListComponent,
		pathMatch: 'full',
		data: {
			title: 'Practice Areas',
			description: 'Create or modify practice areas and their related stages',
			icon: 'view_list',
			featureType: ''
		}
	},
	{ path: 'practice-areas/:id', component: PracticeAreasComponent },
	{
		path: 'referral-reasons',
		component: ReferralReasonListComponent,
		pathMatch: 'full',
		data: {
			title: 'Referral Reasons',
			description: 'Create or modify referral reasons',
			icon: 'subject',
			featureType: 'Referrals'
		}
	},
	{
		path: 'units',
		component: UnitsFieldComponent,
		pathMatch: 'full',
		data: {
			title: 'Units',
			description: 'Define units per hour',
			icon: 'done',
			featureType: 'CostRecords'
		}
	},
	{
		path: 'users',
		component: UserManagementListComponent,
		pathMatch: 'full',
		data: {
			title: 'User Accounts',
			description: 'Create or modify user accounts',
			icon: 'person',
			featureType: ''
		}
	},
	{
		path: 'user-types',
		component: UserTypeListComponent,
		pathMatch: 'full',
		data: {
			title: 'User Types',
			description: 'Create or modify user types',
			icon: 'people_outline',
			featureType: ''
		}
	},
	{
		path: 'security-roles',
		component: SecurityRoleListComponent,
		pathMatch: 'full',
		data: {
			title: 'Security Roles',
			description: 'Create or modify security roles',
			icon: 'admin_panel_settings',
			featureType: ''
		}
	},
	{
		path: 'timers',
		component: TimerConfigComponent,
		pathMatch: 'full',
		data: {
			title: 'Timers',
			description: 'Configure matter timers',
			icon: 'schedule',
			featureType: ''
		}
	},
	{
		path: 'api-keys',
		component: ApiKeysComponent,
		pathMatch: 'full',
		canActivate: [AccessRouteGuard],
		data: {
			title: 'API Keys',
			description: 'Manage keys used for integrating with third party systems',
			icon: 'vpn_key',
			featureType: 'ApiKeys',
			protectedFeature: 'ApiKeys'
		}
	},
	{
		path: 'matter-numbering-config',
		component: MatterNumberingConfigComponent,
		pathMatch: 'full',
		data: {
			title: 'Matter Numbering',
			description: 'Modify the starting number of your Matters',
			icon: 'format_list_numbered',
			featureType: ''
		}
	},
	{
		path: 'matter-mailto-config',
		component: MatterMailToConfigComponent,
		pathMatch: 'full',
		data: {
			title: 'Matter Mail Config',
			description: 'Configure matter details for emailing',
			icon: 'email',
			featureType: ''
		}
	},
	{
		path: 'subscription',
		component: SubscriptionComponent,
		pathMatch: 'full',
		data: {
			title: 'Account and Billing',
			description: `Choose your plan and continue using ${AppBrandingService.getSiteName()} to help run your firm.`,
			icon: 'subscriptions',
			featureType: 'Licensing'
		}
	},
	{ path: 'marketplace/vendor', component: MarketplaceVendorComponent, pathMatch: 'full' },
	{
		path: 'marketplace',
		component: MarketplaceListingsComponent,
		pathMatch: 'prefix',
		children: [
			{ path: '', component: MarketplaceListingsComponent },
			{ path: 'installed', component: MarketplaceListingsComponent },
			{ path: 'updates', component: MarketplaceListingsComponent },
			{ path: ':listingType/:id', component: MarketplaceListingsComponent }
		],
		data: {
			title: 'Marketplace',
			description: `Customize your ${AppBrandingService.getSiteName()} experience.`,
			icon: 'storefront',
			featureType: ''
		}
	},
	{
		path: 'jobs',
		component: GlobalJobListComponent,
		pathMatch: 'prefix',
		children: [
			{ path: '', component: GlobalJobListComponent },
			{ path: 'my', component: GlobalJobListComponent }
		],
		data: { title: 'Jobs - ' + AppBrandingService.getSiteName() }
	},
	{
		path: 'features',
		component: TenantFeaturesComponent,
		pathMatch: 'full',
		data: {
			title: 'Manage Features',
			description: `Manage visible features within ${AppBrandingService.getSiteName()}`,
			icon: 'view_module',
			featureType: ''
		}
	},

	{
		path: 'login-option-rules',
		component: LoginOptionsRulesComponent,
		pathMatch: 'prefix',
		data: { title: 'Login Option Rules' }
	},

	{
		path: 'share-links',
		component: AccessTokensComponent,
		pathMatch: 'full',
		data: {
			title: 'Share Links',
			description: `Manage share link settings`,
			icon: 'share',
			featureType: ''
		}
	},

	{
		path: 'time-zone',
		component: TimeZoneConfigComponent,
		pathMatch: 'full',
		data: {
			title: 'Time Zone',
			description: `Configure your Organization's Time Zone`,
			icon: 'language',
			featureType: ''
		}
	}
].filter(x => {
	const data = x?.data as IRouteData;
	return !data?.featureFlag || (!data?.featureFlag && isFeatureFlagEnabled(data.featureFlag));
});

@NgModule({
	exports: [RouterModule],
	imports: [RouterModule.forChild(routes)]
})
export class SystemRouting {}

export interface IRouteData {
	title?: string;
	description?: string;
	icon?: string;
	featureType?: string;
	protectedFeature?: string;
	featureFlag?: FeatureFlags;
}
