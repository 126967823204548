import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';

import { Subject, Subscription } from 'rxjs';

import { MediaTypeListItemDto } from '@common/models/Settings/Setting/List/MediaTypeListItemDto';
import { TransactionType } from '@common/models/Trust/Common/TransactionType';
import { TrustRecordMediaDetails } from '@common/models/Trust/Common/TrustRecordMediaDetails';
import { GeneralSettingsService } from '@common/services/settings/generalsettings.service';
import { CustomValidators } from '@common/validation/custom.validators';
import { get, isNil } from 'lodash';
import * as moment from 'moment-timezone';

@Component({
	selector: 'trust-media-details',
	styleUrls: ['./trust-media-details.component.scss'],
	templateUrl: './trust-media-details.component.html'
})
export class TrustMediaDetailsComponent implements OnInit, AfterViewInit {
	@Input()
	mediaForm: FormGroup;
	@Input()
	transactionType: keyof typeof TransactionType;
	@Input()
	disabled: boolean;
	@Input()
	onReceiptDateChanged: Subject<moment.Moment>;

	@Output() mediaTypeChangedEvent = new EventEmitter<MediaTypeListItemDto>();

	mediaDetails: FormGroupTyped<TrustRecordMediaDetails>;
	chequeInfoRequired: boolean;
	paymentChequeInfoRequired: boolean;
	paymentEFTInfoRequired: boolean;
	paymentPexaDirectDebitInfoRequired: boolean;
	mediaTypes: MediaTypeListItemDto[];
	selectedMediaType: MediaTypeListItemDto;
	transactionTypeEnum: typeof TransactionType = TransactionType;
	isClearanceDateHighlighted: boolean;
	isPayment: boolean;

	protected subscription: Subscription = new Subscription();

	constructor(private fb: FormBuilder, private generalSettings: GeneralSettingsService) {}
	ngOnInit(): void {
		this.isPayment = this.transactionTypeEnum[this.transactionType] === this.transactionTypeEnum.Payment;
		this.mediaForm.controls.mediaDetails = this.fb.group({
			chequeBank: [null, CustomValidators.requiredWhen(() => this.chequeInfoRequired, 'Cheque Bank Details')],
			chequeDrawer: [null, CustomValidators.requiredWhen(() => this.chequeInfoRequired, 'Cheque Drawer')],
			clearanceDate: null,
			mediaType: [null, CustomValidators.required('Media Type')],
			paymentEFTAccountName: [
				null,
				CustomValidators.requiredWhen(() => this.isPayment && this.paymentEFTInfoRequired, 'Account Name')
			],
			paymentEFTBankAccountNumber: [
				null,
				CustomValidators.requiredWhen(() => this.isPayment && this.paymentEFTInfoRequired, 'Account Number')
			],
			paymentEFTBankReference: [
				null,
				CustomValidators.requiredWhen(() => this.paymentEFTInfoRequired, 'Bank Reference')
			]
		}) as FormGroupTyped<TrustRecordMediaDetails>;

		this.mediaDetails = this.mediaForm.controls.mediaDetails as FormGroupTyped<TrustRecordMediaDetails>;

		this.arrangeMediaTypeInfoDisplay();

		this.subscription.add(
			this.mediaDetails.controls.mediaType.valueChanges.subscribe(() => {
				// updating public properties
				this.arrangeMediaTypeInfoDisplay();

				// enforcing validations
				this.mediaDetails.controls.chequeBank.updateValueAndValidity();
				this.mediaDetails.controls.chequeDrawer.updateValueAndValidity();
				this.mediaDetails.controls.paymentEFTAccountName.updateValueAndValidity();
				this.mediaDetails.controls.paymentEFTBankAccountNumber.updateValueAndValidity();
				this.mediaDetails.controls.paymentEFTBankReference.updateValueAndValidity();

				if (!isNil(this.mediaTypeChangedEvent)) {
					this.mediaTypeChangedEvent.emit(this.selectedMediaType);
				}
			})
		);

		this.subscription.add(
			this.generalSettings.getMediaTypes(this.transactionType).subscribe(response => {
				this.mediaTypes = response;
			})
		);

		if (this.transactionType === TransactionType.Receipt) {
			this.subscription.add(
				this.onReceiptDateChanged.subscribe(receiptDate => {
					if (get(this.mediaDetails, 'controls.mediaType.value')) {
						this.updateClearanceDate(
							receiptDate,
							this.mediaTypes.find(x => x.id === this.mediaDetails.controls.mediaType.value)
						);
					}
				})
			);
		}

		this.subscription.add(
			this.mediaForm.controls.mediaDetails.valueChanges.subscribe(x => {
				this.mediaForm.updateValueAndValidity();
			})
		);
	}

	ngAfterViewInit(): void {
		if (this.disabled) this.mediaDetails.disable();
	}

	onMediaTypeChanged(event: MatOptionSelectionChange, mediaType: MediaTypeListItemDto) {
		if (event.source.selected) {
			this.selectedMediaType = mediaType;
			if (this.transactionType === TransactionType.Receipt && !isNil(this.mediaForm.controls.date)) {
				this.updateClearanceDate(this.mediaForm.controls.date.value, mediaType);
			}
		}
	}

	private updateClearanceDate(originalDate: moment.Moment, mediaType: MediaTypeListItemDto) {
		if (this.disabled) return;

		const oldDate = this.mediaDetails.controls.clearanceDate.value;
		this.mediaDetails.controls.clearanceDate.setValue(
			this.addWeekdays(originalDate, mediaType.clearanceDateOffset)
		);
		const newDate = this.mediaDetails.controls.clearanceDate.value;
		const valueChanged =
			isNil(oldDate) ||
			newDate.startOf('day').format('YYYY-MM-DD') !== oldDate.startOf('day').format('YYYY-MM-DD');

		if (valueChanged) {
			this.highlightClearanceDate();
		}
	}

	private highlightClearanceDate(): void {
		// 	// Reset the fading effect on the Clearance date field to the initial state
		this.isClearanceDateHighlighted = false;
		// Trigger the fading effect then the style has been removed
		setTimeout(() => {
			this.isClearanceDateHighlighted = true;
		}, 0);
	}

	// https://stackoverflow.com/questions/20788411/how-to-exclude-weekends-between-two-dates-using-moment-js
	// How to exclude weekends between two dates using Moment.js
	private addWeekdays(date: moment.Moment, days: number) {
		date = moment(date); // use a clone
		while (days > 0) {
			date = date.add(1, 'days');
			// decrease "days" only if it's a weekday.
			if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
				days -= 1;
			}
		}
		return date;
	}

	private arrangeMediaTypeInfoDisplay(): void {
		this.chequeInfoRequired = this.validateChequeInfo();
		this.paymentChequeInfoRequired = this.validatePaymentChequeInfo();
		this.paymentEFTInfoRequired = this.validatePaymentEFTInfo();
		this.paymentPexaDirectDebitInfoRequired = this.validatePaymentPexaDirectDebitInfo();
	}

	private validateChequeInfo(): boolean {
		return (
			this.transactionTypeEnum[this.transactionType] === this.transactionTypeEnum.Receipt &&
			get(this.mediaDetails, 'controls.mediaType.value') &&
			(this.mediaDetails.controls.mediaType.value === 'BankCheque' ||
				this.mediaDetails.controls.mediaType.value === 'Cheque' ||
				this.mediaDetails.controls.mediaType.value === 'DirectDepositCheque')
		);
	}

	private validatePaymentChequeInfo(): boolean {
		return (
			(this.transactionTypeEnum[this.transactionType] === this.transactionTypeEnum.Payment ||
				this.transactionTypeEnum[this.transactionType] === this.transactionTypeEnum.BillPayment) &&
			get(this.mediaDetails, 'controls.mediaType.value') &&
			this.mediaDetails.controls.mediaType.value === 'Cheque'
		);
	}

	private validatePaymentEFTInfo(): boolean {
		return (
			(this.transactionTypeEnum[this.transactionType] === this.transactionTypeEnum.Payment ||
				this.transactionTypeEnum[this.transactionType] === this.transactionTypeEnum.BillPayment) &&
			get(this.mediaDetails, 'controls.mediaType.value') &&
			(this.mediaDetails.controls.mediaType.value === 'EFT')
		);
	}

	private validatePaymentPexaDirectDebitInfo(): boolean {
		return (
			(this.transactionTypeEnum[this.transactionType] === this.transactionTypeEnum.Payment ||
				this.transactionTypeEnum[this.transactionType] === this.transactionTypeEnum.BillPayment) &&
			get(this.mediaDetails, 'controls.mediaType.value') &&
			this.mediaDetails.controls.mediaType.value === 'PEXADirectDebit'
		);
	}
}
