import { Component } from '@angular/core';
import { AppBrandingService } from 'app/services/app-branding.service';

@Component({
	selector: 'data-import',
	styleUrls: ['./data-import.component.scss'],
	templateUrl: './data-import.component.html'
})
export class DataImportComponent {
	get siteName() {
		return AppBrandingService.getSiteName();
	}
}
