// This file has been generated from MatterSecurityUpdateDto.cs
// Do not modify this file directly!!
// tslint:disable

import { SetSimpleType } from '@common/utils/class-transform';



// LawMaster.Amalthea.Domain.Matters.Item.MatterSecurityUpdateDto
export class MatterSecurityUpdateDto {

	// RoleIds
	roleIds: string[];
	// UserIds
	userIds: string[];
	// InheritRoles
	@SetSimpleType(Boolean)
	inheritRoles: boolean;

	constructor() {

	}
}