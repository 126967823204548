<div class="search-input">
	<mat-form-field floatLabel="never">
		<mat-placeholder>
			<small>Search Everything</small>
		</mat-placeholder>

		<input id="j4sr" #searchInput matInput [matAutocomplete]="auto" [formControl]="input" />
		<button matSuffix mat-icon-button disabled>
			<mat-icon>search</mat-icon>
		</button>

		<mat-autocomplete
			class="omnisearch-autocomplete"
			#auto="matAutocomplete"
			(optionSelected)="onSelect($event)"
			[hidden]="isMobile"
			[autoActiveFirstOption]="true"
		>
			<ng-template #omniSearch [ngIf]="options">
				<ng-template ngFor let-section [ngForOf]="sections">
					<mat-optgroup *ngIf="options[section]?.records?.length" [label]="section | titlecase">
						<mat-option
							throttleButton
							(throttledClick)="onOptionSelect(section, option.id)"
							*ngFor="let option of options[section]?.records"
							[value]="option?.id"
							[ngSwitch]="section"
						>
							<ng-template [ngSwitchCase]="'matters'">
								<mat-icon color="accent">gavel</mat-icon>
								<div class="option-description">
									<div class="main-description">
										<span class="matter-number" [innerHtml]="highlightText(option.number)"></span>
										<span [innerHtml]="highlightText(option.title)"></span>
										<span *ngIf="option.status === 'Closed'">&nbsp;&nbsp;<mat-icon color="primary">lock</mat-icon></span>
									</div>
									<div class="secondary-description">
										<span class="spaced-description"><mat-icon color="accent">person</mat-icon>{{ option.client.name }}</span>
										<span class="spaced-description"><mat-icon color="accent">work</mat-icon>{{ option.lawyer.name }}</span>
										<span class="practice-area-text spaced-description">{{ option.practiceAreaName }}</span>
									</div>
								</div>
							</ng-template>
							<ng-template [ngSwitchCase]="'contacts'">
								<mat-icon color="accent">person</mat-icon>
								<div class="option-description">
									<div>
										<span [innerHtml]="highlightText($any(option).fullName)"></span>
									</div>
									<div class="secondary-description">
										<span class="spaced-description">{{ $any(option).phone?.number }}</span>
										<span class="spaced-description" [innerHtml]="highlightText($any(option).email)"></span>
										<span class="spaced-description" [innerHtml]="highlightText($any(option).address)" *ngIf="!isMobile"></span>
									</div>
								</div>
							</ng-template>
							<ng-template [ngSwitchCase]="'documents'">
								<mat-icon [svgIcon]="getFileIcon($any(option).fileExtension)" [ngClass]="getFileIcon($any(option).fileExtension)"></mat-icon>
								<div class="option-description">
									<div class="main-description" [innerHtml]="highlightText(option.title)"></div>
									<div *ngIf="$any(option).associatedMatter" class="secondary-description">
										<span class="matter-number">{{ $any(option).associatedMatter.number }}</span>
										<span>{{ $any(option).associatedMatter.title }}</span>
									</div>
									<div *ngIf="$any(option).associatedContact" class="secondary-description">{{ $any(option).associatedContact.name }}</div>
								</div>
							</ng-template>
							<ng-template [ngSwitchCase]="'system'">
								<mat-icon>{{ option.icon }}</mat-icon>
								<div class="option-description" [routerLink]="['/system'].concat(option.route.split('/'))">
									<div class="main-description" [innerHtml]="highlightText(option.title)"></div>
									<div class="secondary-description" [innerHtml]="highlightText(option.description)"></div>
								</div>
							</ng-template>
						</mat-option>
						<mat-option throttleButton (throttledClick)="onOptionSelect(section)" value="" class="view-all" *ngIf="section !== 'system'">
							<mat-icon>search</mat-icon>
							<div>View all found {{ section }}</div>
						</mat-option>
					</mat-optgroup>
				</ng-template>
			</ng-template>
		</mat-autocomplete>
	</mat-form-field>
</div>
<div class="search-dialog-container">
	<ng-container *ngIf="isMobile && options; then omniSearch; else empty"></ng-container>
</div>

<ng-template #empty> </ng-template>
