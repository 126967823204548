import { NgModule } from '@angular/core';

import { AuthModule, LogLevel } from 'angular-auth-oidc-client';

import { AppConfig } from 'app/app.config';

import { AuthService } from './auth.service';

@NgModule({
	imports: [
		AuthModule.forRoot({
			config: {
				authority: AppConfig.MatteroAuthServerUrl,
				redirectUrl: window.location.origin,
				postLogoutRedirectUri: window.location.origin,
				triggerAuthorizationResultEvent: true,
				clientId: '3e0857f9-b47c-40fa-8f44-a60677618022',
				scope: 'openid profile email offline_access AmaltheaAPI',
				responseType: 'code',
				silentRenew: true,
				useRefreshToken: true,
				logLevel: LogLevel.Warn,
				maxIdTokenIatOffsetAllowedInSeconds: AuthService.maxIatOffsetAllowedInMinutes * 60
			}
		})
	],
	exports: [AuthModule],
	providers: [AuthService]
})
export class AuthenticationModule {}
