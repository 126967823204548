import { WorkTimerUtils } from "./WorkTimerUtils";

export const version2 = 'v2';

// Work timer state data where tab concurrency on reads and writes does matter
export class WorkTimerStateData {
	// Variable that stores whether or not the state is currently counting
	isRunning: boolean = false;
	// Variable that stores the start data in milliseconds for a state
	// that is running
	startDate: number = null;
	// A sum of previously elapsed milliseconds between stage where the state
	// is running
	elapsedMilliseconds: number = null;
	// A variable that dictates if the state is able to autostart
	canAutostart: boolean = false;
	// description
	description?: string;
	// first time the timer start
	setupDate?: number;
	// version
	version?: string

	constructor() {
		this.setupDate = WorkTimerUtils.getDateNowMilliseconds();
		this.version = version2;
	}
}