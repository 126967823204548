// This file has been generated from MoveSectionDto.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.Domain.DocumentBriefs.Item.MoveSectionDto
export class MoveSectionDto {

	// BriefId
	briefId: string;
	// DocumentIds
	documentIds: string[];
	// FromSectionId
	fromSectionId: string;
	// ToSectionId
	toSectionId: string;

	constructor() {

	}
}