import { HttpParams } from '@angular/common/http';

import { isNil } from 'lodash-es';
import { isMoment } from 'moment';

import { HttpParamForcedUriCodec } from './http-param-forced-uri-codec';

export const toHttpParams = (input: { [key: string]: any }): HttpParams => {
	const paramsObject: { [key: string]: any } = {};

	if (!!input) {
		Object.keys(input).forEach(key => {
			let value = input[key];
			if (!isNil(value)) {
				if (isMoment(value)) {
					value = value.format('YYYY-MM-DD');
				}
				paramsObject[key] = value;
			}
		});
	}
	return new HttpParams({
		encoder: new HttpParamForcedUriCodec(),
		fromObject: paramsObject
	});
};
