// This file has been generated from BillUpdateTemplateDto.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.Domain.Bills.Item.BillUpdateTemplateDto
export class BillUpdateTemplateDto {

	// TemplateId
	templateId: string;

	constructor() {

	}
}