// This file has been generated from JobListRequest.cs
// Do not modify this file directly!!
// tslint:disable

import { Moment } from 'moment-timezone';
import { DateFormat, SetDateFormat } from '@common/utils/date-format';

import { SetSimpleType } from '@common/utils/class-transform';

import { EnumSortDirection } from '../../../Common/EnumSortDirection';

// LawMaster.Amalthea.Domain.Settings.Jobs.List.JobListRequest
export class JobListRequest {

	// Search
	search: string;
	// SortBy
	sortBy: string;
	// SortDirection
	sortDirection: keyof typeof EnumSortDirection;
	// PageIndex
	pageIndex: number;
	// PageSize
	pageSize: number;
	// PageIndexForId
	pageIndexForId: string;
	// MyJobs
	@SetSimpleType(Boolean)
	myJobs: boolean;
	// UserId
	userId: string;
	// Status
	status: string;
	// StartedFrom
	@SetDateFormat(DateFormat.LocalDate)
	startedFrom: Moment;
	// StartedTo
	@SetDateFormat(DateFormat.LocalDate)
	startedTo: Moment;
	// CompletedFrom
	@SetDateFormat(DateFormat.LocalDate)
	completedFrom: Moment;
	// CompletedTo
	@SetDateFormat(DateFormat.LocalDate)
	completedTo: Moment;
	// LastUpdateFrom
	@SetDateFormat(DateFormat.LocalDate)
	lastUpdateFrom: Moment;
	// LastUpdateTo
	@SetDateFormat(DateFormat.LocalDate)
	lastUpdateTo: Moment;
	// IsComplete
	@SetSimpleType(Boolean)
	isComplete: boolean;

	constructor() {

	}
}