// This file has been generated from ShareLinkExpiryDto.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.Domain.Settings.Setting.Item.ShareLinkExpiryDto
export class ShareLinkExpiryDto {

	// DefaultExpiryInDays
	defaultExpiryInDays: number;

	constructor() {

	}
}