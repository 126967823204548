// This file has been generated from MatterNumberingDto.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.Domain.Settings.Setting.Item.MatterNumberingDto
export class MatterNumberingDto {

	// NumberPadding
	numberPadding: number;
	// ReferenceFormat
	referenceFormat: string;

	constructor() {

	}
}