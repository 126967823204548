<mat-card>
	<section class="page-path"><a [routerLink]="['..']">System</a> / Referral Reasons</section>
	<h1>Referral Reasons</h1>
	<button mat-button color="primary" title="Create Referral Reason" throttleButton (throttledClick)="createReferralReason()">
		<mat-icon>add_circle</mat-icon>
		<span>Create New Referral Reason</span>
	</button>
	<mat-table [dataSource]="(isFetching$ | async) ? [] : (data$ | async)" matSort matSortActive="name" matSortDirection="asc">
		<ng-container matColumnDef="name">
			<mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<div class="field-name">{{ row.name }}</div>
			</mat-cell>
		</ng-container>
		<ng-container matColumnDef="description">
			<mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<div>{{ row.description }}</div>
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="actions">
			<mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<button mat-icon-button [matMenuTriggerFor]="menu" title="Actions">
					<mat-icon>more_vert</mat-icon>
				</button>
				<mat-menu #menu="matMenu">
					<button mat-menu-item throttleButton (throttledClick)="editReferralReason(row)">
						<mat-icon>edit</mat-icon>
						<span>Edit Details</span>
					</button>
					<button mat-menu-item throttleButton (throttledClick)="deleteReferralReason(row)">
						<mat-icon>delete</mat-icon>
						<span>Delete</span>
					</button>
				</mat-menu>
			</mat-cell>
		</ng-container>

		<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
		<mat-row
			*matRowDef="let row; columns: displayedColumns"
			throttleButton
			(throttledClick)="selectRow(row, $event)"
			[ngClass]="{ highlight: row.isHighlighted }"
		></mat-row>
	</mat-table>
	<mat-paginator
		[length]="totalRecords$ | async"
		[pageIndex]="pageIndex$ | async"
		[pageSize]="pageSize$ | async"
		[pageSizeOptions]="pageSizeOptions"
		[class.hidden]="(isFetching$ | async) || !(data$ | async)"
	></mat-paginator>
</mat-card>
