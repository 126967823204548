<multi-file-attachment
	[maxNumberOfFiles]="3"
	[isEditable]="isEftPayment || isPEXAPayment"
	[showProgress]="showProgress"
	[existingDocuments]="existingDocuments"
	(errorMessageUpdated)="onErrorMessageUpdated($event)"
	(documentIdsToRemoveUpdated)="onDocumentIdsToRemoveUpdated($event)"
	(uploadFilesInfoUpdated)="onUploadFilesInfoUpdated($event)"
>
	<div class="flex-column" [formGroup]="form">
		<div class="flex-row">
			<mat-form-field>
				<mat-placeholder
					>Trust Account
					<sup class="color-warn">*</sup>
				</mat-placeholder>
				<mat-select formControlName="trustAccountId">
					<mat-option
						(onSelectionChange)="trustAccountChanged($event, trustAccount)"
						*ngFor="let trustAccount of trustAccounts"
						[value]="trustAccount.id"
						>{{ trustAccount.bankDetails.accountName }}</mat-option
					>
				</mat-select>
				<mat-hint *ngIf="!isReadOnly && currentTrustAccount?.currentBalance !== undefined">
					<div class="flex-row">
						<span>Total : {{ currentTrustAccount?.currentBalance | currency : '$' }}</span>
					</div>
				</mat-hint>
			</mat-form-field>
			<editable-numeric-value
				#amount
				placeHolder="Amount"
				tooltip="Total Amount on the transaction"
				[control]="form.controls.amount"
				[isCurrency]="true"
				[editMode]="true"
			>
			</editable-numeric-value>
		</div>

		<div class="flex-row">
			<contact-lookup
				[Placeholder]="transactionTypeEnum[transactionType] === transactionTypeEnum.Receipt ? 'Received From' : 'Paid To'"
				[AllowCreate]="true"
				[AllowFreeText]="true"
				[CreatePersonsCtrl]="form.controls.createPersons"
				[CreateCompaniesCtrl]="form.controls.createCompanies"
				[CreatePluralCtrl]="form.controls.createPlurals"
				[FormControl]="form.controls.relevantContactId"
				[FreeTextFormControl]="form.controls.relevantContactFreeTextName"
				[Hint]="isReadOnly ? '' : 'Search for a contact'"
				(Selected)="onContactSelected($event)"
				[Required]="true"
			></contact-lookup>
			<mat-form-field>
				<mat-placeholder>Date of {{ transactionTypeEnum[transactionType] }}<sup class="color-warn">*</sup> </mat-placeholder>
				<input formControlName="date" matInput [matDatepicker]="datePicker" autocomplete="off" />
				<mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
				<mat-datepicker #datePicker></mat-datepicker>
				<mat-error>
					<error-messages [for]="form.controls.date"></error-messages>
				</mat-error>
			</mat-form-field>
		</div>

		<trust-media-details
			*ngIf="transactionTypeEnum[transactionType] !== transactionTypeEnum.Journal"
			[disabled]="isReadOnly"
			[mediaForm]="form"
			[transactionType]="transactionType"
			[onReceiptDateChanged]="receiptDateChanged"
			(mediaTypeChangedEvent)="onMediaTypeChanged($event)"
		></trust-media-details>

		<div class="flex-row" *ngIf="isPaymentCheque()">
			<mat-form-field>
				<input formControlName="number" matInput autocomplete="off" />
				<mat-placeholder
					>Cheque Number
					<sup class="color-warn">*</sup>
				</mat-placeholder>
				<mat-error>
					<error-messages [for]="form.controls.number"></error-messages>
				</mat-error>
			</mat-form-field>
		</div>

		<div *ngIf="transactionTypeEnum[transactionType] === transactionTypeEnum.Receipt && !isReadOnly" class="flex-row">
			<mat-slide-toggle [disabled]="autoPublishWarningText" [checked]="isDownloadReceiptAfterSave" (change)="isDownloadReceiptAfterSave = $event.checked"
				>Download receipt after save</mat-slide-toggle
			>
		</div>

		<ng-container>
			<div class="matter-allocation-header">
				<h3 class="title-text">Matter Allocations</h3>
				<button class="add-allocation" *ngIf="!isReadOnly" mat-button color="primary" throttleButton (throttledClick)="addAllocation()">
					<mat-icon>add_circle</mat-icon>
					<span>&nbsp;New <span class="allocation-word">allocation</span></span>
				</button>
			</div>
			<div class="matter-allocation-container" *ngFor="let allocationgroup of matterAllocationControls; let i = index" formArrayName="matterAllocations">
				<matter-allocations
					*ngIf="!isReadOnly"
					[trustAccountId]="form.get('trustAccountId').value"
					[transactionType]="transactionType"
					[totalTrustAccounBalance]="currentTrustAccount?.currentBalance"
					[statutoryMatterId]="currentTrustAccount?.statutoryMatterId"
					[allocationGroup]="allocationgroup"
					(addAllocationClicked)="addAllocation()"
					(removeAllocationClicked)="removeAllocation($event)"
					[index]="i"
					[hideDelete]="matterAllocationControls.length === 1"
				></matter-allocations>
			</div>
			<ng-container *ngIf="isReadOnly">
				<section class="matter-readonly">
					<header>
						<div class="matter"><b>Matter</b></div>
						<div class="amount"><b>Amount</b></div>
						<div class="description"><b>Description</b></div>
					</header>
					<hr />
					<div *ngFor="let matterAllocation of matterAllocations">
						<div class="row">
							<div class="matter">
								<a throttleButton (throttledClick)="closeDialog()" [routerLink]="['/matters', matterAllocation.matter.id]">{{
									matterAllocation.matter.name
								}}</a>
							</div>
							<div class="amount">{{ matterAllocation.amount | currency : '$' }}</div>
							<div class="description">{{ matterAllocation.description }}</div>
						</div>
						<hr />
					</div>
				</section>
			</ng-container>
			<div *ngIf="warningText && form.valid" class="info-message color-warning">
				<mat-icon>info</mat-icon>
				<span>
					{{ warningText }}
				</span>
			</div>
			<mat-error> <error-messages [for]="form.get('matterAllocations')"></error-messages> </mat-error>
		</ng-container>
		<div *ngIf="autoPublishWarningText" class="info-message color-warning">
			<mat-icon>info</mat-icon>
			<span>
				{{ autoPublishWarningText }}
			</span>
		</div>
		<div *ngIf="systemReceiptTemplates?.records?.length === 0">
			<p></p>
			Please click <a href="https://support.mattero.com.au/hc/en-us/articles/900005679663-Sample-Document-Templates" target="_blank">here</a> for
			instructions to download and install {{ siteName }} templates.
		</div>
		<div *ngIf="showProgress">
			<span>{{ editId ? 'Updating' : 'Creating' }} {{ isEftPayment === 'EFT' ? 'EFT' : 'PEXA Direct Debit' }} payment, please wait...</span><br />
			<mat-progress-bar [value]="progress.percentage"> </mat-progress-bar>
		</div>
		<div class="upload-error" *ngIf="error.message">
			<mat-icon>cancel</mat-icon>&nbsp;<span class="err-text-container" [innerHTML]="error.message"></span>
		</div>
	</div>
</multi-file-attachment>
