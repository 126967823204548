import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class SystemFontsService {
	availableSystemFonts: FontLookupDto[] = [
		'Times New Roman',
		'Arial',
		'Calibri',
		'Georgia',
		'Verdana',
		'Consolas'
	].map(x => ({ id: x }));

	lookupFontList(request: FontLookupRequest): FontLookupDto[] {
		return this.availableSystemFonts.filter(font => {
			return font.id.toLocaleLowerCase().includes(request.term.toLowerCase().trim());
		});
	}

	lookupFont(id: string): FontLookupDto {
		return this.availableSystemFonts.filter(font => {
			return font.id.toLocaleLowerCase() === id.toLocaleLowerCase().trim();
		})[0];
	}
}

export class FontLookupDto {
	// Id
	id: string;
}

export class FontLookupRequest {
	// Term
	term: string;
}
