import { MutationType } from '@common/models/Common/MutationType';
import { BriefDetails } from '@common/models/DocumentBriefs/List/BriefDetails';
import { createReducer, on } from '@ngrx/store';

import { CommonReducers } from '../../common-reducers';
import {
	init,
	loadFailed,
	loading,
	loadSuccess,
	processRecords,
	selectRecords,
	setFilterProp,
	setFilters,
	setPageIndex,
	setPageIndexForId,
	setPageSize,
	setSortBy,
	setSortDirection
} from './document-list.actions';
import { initialState } from './document-list.state';

export const documentListReducer = createReducer(
	initialState,
	on(init, state => CommonReducers.init(state, initialState)),
	on(loading, state => CommonReducers.loading(state)),
	on(loadSuccess, (state, { response }) => CommonReducers.loadSuccess(state, response)),
	on(loadFailed, (state, { error }) => CommonReducers.loadFailed(state, error)),

	on(setFilters, (state, { filter }) => CommonReducers.setFilters(state, filter)),
	on(setFilterProp, (state, { prop, value }) => CommonReducers.setFilterProp(state, prop, value)),

	on(setPageSize, (state, { pageSize }) => CommonReducers.setPageSize(state, pageSize)),
	on(setPageIndex, (state, { pageIndex }) => CommonReducers.setPageIndex(state, pageIndex)),
	on(setPageIndexForId, (state, { id }) => CommonReducers.setPageIndexForId(state, id)),

	on(setSortBy, (state, { sortBy }) => CommonReducers.setSortBy(state, sortBy)),
	on(setSortDirection, (state, { sortDirection }) => CommonReducers.setSortDirection(state, sortDirection)),

	on(processRecords, (state, { response }) => {
		let mutations = response instanceof Array ? response : [response];

		// Add to UI if needed
		if (!!state.request.briefId) {
			mutations = mutations.map(mutation =>
				!!(mutation.values['AssociatedBriefs'] as BriefDetails[])?.filter(
					brief => brief.briefId === state.request.briefId
				)?.length && !state.list?.records?.filter(record => record.id === mutation.id)?.length
					? { ...mutation, mutationType: MutationType.Create }
					: mutation
			);

			if (!!state.request.sectionId) {
				mutations = mutations.map(mutation =>
					!!(mutation.values['AssociatedBriefs'] as BriefDetails[])?.filter(
						brief =>
							brief.briefId === state.request.briefId &&
							!!brief.sections?.filter(section => section.id === state.request.sectionId)?.length
					)?.length && !state.list?.records?.filter(record => record.id === mutation.id)?.length
						? { ...mutation, mutationType: MutationType.Create }
						: mutation
				);
			}
		}

		// Remove from UI if not needed
		if (!!state.request.briefId) {
			mutations = mutations.map(mutation =>
				!(mutation.values['AssociatedBriefs'] as BriefDetails[])?.filter(
					brief => brief.briefId === state.request.briefId
				)?.length
					? { ...mutation, mutationType: MutationType.Delete }
					: mutation
			);

			if (!!state.request.sectionId) {
				mutations = mutations.map(mutation =>
					!(mutation.values['AssociatedBriefs'] as BriefDetails[])?.filter(
						brief =>
							brief.briefId === state.request.briefId &&
							!!brief.sections?.filter(section => section.id === state.request.sectionId)?.length
					)?.length
						? { ...mutation, mutationType: MutationType.Delete }
						: mutation
				);
			}
		}

		return CommonReducers.processMutations(state, mutations);
	}),
	on(selectRecords, (state, { row, selectionType }) => CommonReducers.selectRecords(state, row, selectionType))
);
