import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeAu from '@angular/common/locales/en-AU';
import { ApplicationRef, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PreloadAllModules, RouterModule } from '@angular/router';

import { NotificationModule } from '@common/notification';
import { SharedPipesModule } from '@common/pipes/shared-pipes.module';
import { AppErrorHandler } from '@common/services/app.errorhandler';
import { BaseDocumentsService } from '@common/services/documents.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { LocalStorageService } from '@common/services/storage/local-storage.service';
import { SessionStorageService } from '@common/services/storage/session-storage.service';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { DxReportDesignerModule, DxReportViewerModule } from 'devexpress-reporting-angular';
import { environment } from 'environments/environment';
import * as moment from 'moment-timezone';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

import { AppComponent } from './app.component';
import { AppConfig } from './app.config';
import { ROUTES } from './app.routes';
import { CoreModule } from './core';
import { AuthenticationModule } from './core/authentication.module';
import { effects } from './core/state/app.effects';
import { metaReducers, reducers } from './core/state/app.reducers';
import { NoContentComponent } from './no-content';
import { PreviewModule } from './preview';
import { DocumentsService } from './services/documents.service';
import { InfoTrackAuthService } from './services/infotrack-auth.service';
import { SystemSettingsResolverService } from './services/settings-resolver.service';
import { SystemFontsService } from './services/system-fonts.service';
import { SystemInformationService } from './services/systeminformation.service';
import { PipesModule } from './shared/pipes/pipes.module';

// register the locale (so we get local date format instead of american)
registerLocaleData(localeAu);
moment.locale('en-AU');

/*
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
	bootstrap: [AppComponent],
	declarations: [AppComponent, NoContentComponent],
	/*
	 * Import Angular's modules.
	 */
	imports: [
		BrowserAnimationsModule, // animations for Material components
		BrowserModule,
		CoreModule,
		DxReportViewerModule,
		DxReportDesignerModule,
		FormsModule,
		HttpClientModule, // this is what developers should use in code
		MatIconModule,
		MatSnackBarModule,
		MatSidenavModule,
		// Use MomentDateModule instead of MatMomentDateModule as we are providing MAT_DATE_FORMATS
		// see https://material.angular.io/components/datepicker/overview#customizing-the-parse-and-display-formats
		MomentDateModule,
		NgHttpLoaderModule.forRoot(), // Show a waiting spinner automatically for all HTTP requests
		PreviewModule,
		RouterModule.forRoot(ROUTES, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' }),
		AuthenticationModule,
		/*
			Two services registered below - It's a magical way to import a module with services, which
			need to be SINGLETON for lazy-loading modules. Otherwise,
			the lazy modules will have new instances of services created for them
			See https://angular.io/guide/singleton-services#the-forroot-pattern.
			Another way of doing it - https://stackoverflow.com/a/41294239/968003
		*/
		CoreModule.forRoot(),
		NotificationModule.forRoot(),
		StoreModule.forRoot(reducers, { metaReducers }),
		EffectsModule.forRoot(effects),
		StoreDevtoolsModule.instrument({
			maxAge: 25, // Retains last 25 states
			logOnly: environment.production // Restrict extension to log-only mode
		}),

		PipesModule,
		SharedPipesModule,

		RecaptchaV3Module
	],
	/*
	 * Expose our Services and Providers into Angular's dependency injection.
	 */
	providers: [
		environment.ENV_PROVIDERS,
		{ provide: SettingsResolverService, useExisting: SystemSettingsResolverService },
		{ provide: LOCALE_ID, useValue: 'en-AU' }, // Need for the DatePicker (also affects the date & the currency pipes)
		{
			// set the date format to have leading zeros for date pickers
			provide: MAT_DATE_FORMATS,
			useValue: {
				display: {
					dateA11yLabel: 'LL',
					dateInput: 'L',
					monthYearA11yLabel: 'MMMM YYYY',
					monthYearLabel: 'MMM YYYY'
				},
				parse: {
					dateInput: 'L'
				}
			}
		},
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: { width: '700px', maxWidth: '80vw', hasBackdrop: true, disableClose: true }
		}, // value is MatDialogConfig
		{ provide: ErrorHandler, useClass: AppErrorHandler },
		SystemInformationService,
		LocalStorageService,
		SessionStorageService,
		InfoTrackAuthService,
		{ provide: BaseDocumentsService, useClass: DocumentsService },
		SystemFontsService,
		{
			provide: RECAPTCHA_V3_SITE_KEY,
			useValue: AppConfig.RecaptchaSiteKey
		}
	]
})
export class AppModule {
	constructor(public appRef: ApplicationRef, matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
		// Add additional svg to <mat-icon>
		// See Angular 2.x/4.x on https://materialdesignicons.com/getting-started
		matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/mdi.svg'));
		matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('/assets/img/custom.svg'));
	}
}
