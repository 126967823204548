// This file has been generated from ContactReference.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.Domain.Contacts.Common.ContactReference
export class ContactReference {

	// Id
	id: string;
	// Name
	name: string;
	// FullName
	fullName: string;

	constructor() {

	}
}