<ng-container>
	<div class="flex-column">
		<div>
			<button class="new-button" mat-button color="primary" title="Create Trust Account" throttleButton (throttledClick)="createTrustAccount()">
				<mat-icon>add_circle</mat-icon>
				<span>&nbsp;Create Trust Account</span>
			</button>
		</div>
		<div>
			<mat-table [dataSource]="(isFetching$ | async) ? [] : (data$ | async)" matSort matSortActive="accountName" matSortDirection="asc">
				<ng-container matColumnDef="accountName">
					<mat-header-cell *matHeaderCellDef mat-sort-header>Account Name</mat-header-cell>
					<mat-cell *matCellDef="let row">
						<div class="field-name">
							<a class="cursor-hand" throttleButton (throttledClick)="editTrustAccount(row)">{{ row.bankDetails?.accountName }}</a>
							&nbsp;<mat-chip class="default-chip" *ngIf="row.isDefault">Default</mat-chip>
						</div>
						<div class="embedded-balance">
							<div [ngClass]="row.currentBalance > 0 ? 'green-money' : row.currentBalance < 0 ? 'red-money' : ''">
								Current Balance: ${{ row.currentBalance }}
							</div>
							<div>Cleared Balance: ${{ row.clearedBalance > 0 ? row.clearedBalance : 0 }}</div>
						</div>
					</mat-cell>
				</ng-container>
				<ng-container matColumnDef="bankName">
					<mat-header-cell *matHeaderCellDef mat-sort-header>Bank Name</mat-header-cell>
					<mat-cell *matCellDef="let row">
						<div class="field-balance">{{ row.bankDetails?.bankName }}</div>
					</mat-cell>
				</ng-container>
				<ng-container matColumnDef="currentBalance">
					<mat-header-cell arrowPosition="before" *matHeaderCellDef mat-sort-header>Current Balance</mat-header-cell>
					<mat-cell *matCellDef="let row">
						<div class="field-balance" [ngClass]="row.currentBalance > 0 ? 'green-money' : row.currentBalance < 0 ? 'red-money' : ''">
							{{ row.currentBalance | currency : '$' }}
						</div>
					</mat-cell>
				</ng-container>

				<ng-container matColumnDef="clearedBalance">
					<mat-header-cell arrowPosition="before" *matHeaderCellDef mat-sort-header>Cleared Balance</mat-header-cell>
					<mat-cell *matCellDef="let row">
						<div class="field-balance" [ngClass]="row.clearedBalance > 0 ? 'green-money' : row.clearedBalance < 0 ? 'red-money' : ''">
							{{ (row.clearedBalance > 0 ? row.clearedBalance : 0) | currency : '$' }}
						</div>
					</mat-cell>
				</ng-container>

				<ng-container matColumnDef="countryId">
					<mat-header-cell *matHeaderCellDef mat-sort-header>Country</mat-header-cell>
					<mat-cell *matCellDef="let row">
						<div>{{ row.bankDetails?.countryId }}</div>
					</mat-cell>
				</ng-container>

				<ng-container matColumnDef="accountNumber">
					<mat-header-cell *matHeaderCellDef mat-sort-header>Account Number</mat-header-cell>
					<mat-cell *matCellDef="let row">
						<div>{{ row.bankDetails?.bsb }} {{ row.bankDetails?.bsb ? ' - ' : '' }} {{ row.bankDetails?.accountNumber }}</div>
					</mat-cell>
				</ng-container>

				<ng-container matColumnDef="actions">
					<mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
					<mat-cell *matCellDef="let row">
						<button mat-icon-button [matMenuTriggerFor]="menu" title="Actions">
							<mat-icon>more_vert</mat-icon>
						</button>
						<mat-menu #menu="matMenu">
							<button mat-menu-item throttleButton (throttledClick)="editTrustAccount(row)">
								<mat-icon>edit</mat-icon>
								<span>Edit Details</span>
							</button>
							<button mat-menu-item throttleButton (throttledClick)="editNumberingSettings(row)">
								<mat-icon>double_arrow</mat-icon>
								<span>Edit Number Sequence</span>
							</button>
							<button mat-menu-item throttleButton (throttledClick)="addOpeningBalanceReceipts(row)">
								<mat-icon>add</mat-icon>
								<span>Add Opening Balance</span>
							</button>
							<button mat-menu-item throttleButton (throttledClick)="deleteTrustAccount(row)">
								<mat-icon>delete</mat-icon>
								<span>Delete</span>
							</button>
							<button *ngIf="!row.isDefault" mat-menu-item throttleButton (throttledClick)="setDefault(row)">
								<mat-icon>check_box</mat-icon>
								<span>Set Default</span>
							</button>
						</mat-menu>
					</mat-cell>
				</ng-container>

				<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
				<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
			</mat-table>
			<p>&nbsp;</p>
		</div>
	</div>
</ng-container>
