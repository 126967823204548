import { PracticeAreaStageListItemDto } from '@common/models/Settings/PracticeAreas/List/PracticeAreaStageListItemDto';
import { PracticeAreaStageListRequest } from '@common/models/Settings/PracticeAreas/List/PracticeAreaStageListRequest';
import { DomainError } from '@common/models/Validation/DomainError';
import { createAction, props } from '@ngrx/store';

export enum PracticeAreaStagesActions {
	InvalidateCache = '[Practice Area Stages] Invalidate Cache',
	Load = '[Practice Area Stages] Load',
	Loading = '[Practice Area Stages] Loading',
	LoadSuccess = '[Practice Area Stage] Load Success',
	LoadFailed = '[Practice Area Stages] Load Failed',

	SetFilters = '[Practice Area Stages] Set Filters'
}

export const load = createAction(PracticeAreaStagesActions.Load);
export const loading = createAction(PracticeAreaStagesActions.Loading);
export const loadSuccess = createAction(
	PracticeAreaStagesActions.LoadSuccess,
	props<{ response: PracticeAreaStageListItemDto[] }>()
);
export const loadFailed = createAction(PracticeAreaStagesActions.LoadFailed, props<{ error: DomainError }>());

export const setFilters = createAction(
	PracticeAreaStagesActions.SetFilters,
	props<{ filter: Partial<PracticeAreaStageListRequest> }>()
);

export const invalidateCache = createAction(PracticeAreaStagesActions.InvalidateCache);
