// This file has been generated from UserListRequest.cs
// Do not modify this file directly!!
// tslint:disable

import { SetSimpleType } from '@common/utils/class-transform';

import { EnumSortDirection } from '../../Common/EnumSortDirection';

// LawMaster.Amalthea.Domain.Users.List.UserListRequest
export class UserListRequest {

	// Search
	search: string;
	// SortBy
	sortBy: string;
	// SortDirection
	sortDirection: keyof typeof EnumSortDirection;
	// PageIndex
	pageIndex: number;
	// PageSize
	pageSize: number;
	// PageIndexForId
	pageIndexForId: string;
	// IsEnabled
	@SetSimpleType(Boolean)
	isEnabled: boolean;
	// Ids
	ids: string[];

	constructor() {

	}
}