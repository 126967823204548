import { Component, Inject, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

import { of, Subscription } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import { ILookupReference } from '@common/components/lookups/base-lookup.component';
import { ContactLookupComponent } from '@common/components/lookups/contact-lookup.component';
import { EntityReference } from '@common/models/Common/EntityReference';
import { MatterStatus } from '@common/models/Matters/Common/MatterStatus';
import { MatterLookupDto } from '@common/models/Matters/Lookup/MatterLookupDto';
import { TaskPriority } from '@common/models/Tasks/Common/TaskPriority';
import { TaskStatus } from '@common/models/Tasks/Common/TaskStatus';
import { ReferralTaskCreateUpdateDto } from '@common/models/Tasks/Item/ReferralTaskCreateUpdateDto';
import { TaskViewDto } from '@common/models/Tasks/Item/TaskViewDto';
import { DomainError } from '@common/models/Validation/DomainError';
import { NotificationService } from '@common/notification';
import { ReferralReasonsService } from '@common/services/settings/referralreasons.service';
import { TasksService } from '@common/services/tasks.service';
import { UserCurrentService } from '@common/services/usercurrent.service';
import { CustomValidators } from '@common/validation/custom.validators';
import { Store } from '@ngrx/store';
import { uniq } from 'lodash-es';
import * as moment from 'moment-timezone';

import { FeatureFlags, isFeatureFlagEnabled } from 'app/app.config';
import { DialogComponent } from 'app/core/dialog.component';
import { DialogConfig, DialogType, EntityType } from 'app/core/dialog.config';
import { IAppState } from 'app/core/state/app.state';
import { getCurrentPage } from 'app/core/state/misc/current-page/current-page.reducer';
import { CurrentPageType, ICurrentPageState } from 'app/core/state/misc/current-page/current-page.state';
import { TaskListActions } from 'app/core/state/lists/task-list/task-list.actions';
import { DocumentsService } from 'app/services/documents.service';
import { DocumentLookupComponent } from 'app/shared/components/document-lookup.component';

enum SupportedEntityTypes {
	Matter = 'Matter',
	Contact = 'Contact'
}

@Component({
	styleUrls: ['./create-referral-dialog.component.scss'],
	templateUrl: 'create-referral-dialog.component.html'
})
export class CreateReferralDialogComponent implements OnInit, OnDestroy {
	@ViewChildren('documentControl', { read: DocumentLookupComponent })
	documentControlRefs: QueryList<DocumentLookupComponent>;

	@ViewChildren('recipientControl', { read: ContactLookupComponent })
	recipientControlRefs: QueryList<ContactLookupComponent>;

	form: FormGroup;
	private subscriptions: Subscription = new Subscription();

	associatedEntityTypes: SupportedEntityTypes[] = [SupportedEntityTypes.Matter, SupportedEntityTypes.Contact];
	associatedEntityType: SupportedEntityTypes = this.associatedEntityTypes[0];
	statuses: (keyof typeof TaskStatus)[];
	priorities: (keyof typeof TaskPriority)[];
	reasons: string[];

	hasCurrentEntity: boolean;
	saving: boolean;

	get isEdit(): boolean {
		return !!this.data?.editId;
	}

	get recipientControls() {
		if (this.form && this.form.controls.recipients) {
			return (this.form.controls.recipients as FormArray).controls as FormGroup[];
		}

		return null;
	}

	get documentControls() {
		if (this.form && this.form.controls.documents) {
			return (this.form.controls.documents as FormArray).controls as FormGroup[];
		}

		return null;
	}

	get showSaveAndCreate() {
		return !!isFeatureFlagEnabled(FeatureFlags.activityTimeEntry);
	}

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: ICreateUpdateReferralDialogData,
		private fb: FormBuilder,
		private tasksService: TasksService,
		private store: Store<IAppState>,
		private userService: UserCurrentService,
		private documentsService: DocumentsService,
		private referralReasonsService: ReferralReasonsService,
		private dialogRef: MatDialogRef<CreateReferralDialogComponent>,
		private notificationService: NotificationService,
		private dialog: MatDialog
	) {
		this.statuses = Object.keys(TaskStatus) as (keyof typeof TaskStatus)[];
		this.priorities = Object.keys(TaskPriority) as (keyof typeof TaskPriority)[];
	}

	ngOnInit() {
		const nonEmptyValidator = CustomValidators.nonEmptyArray();
		this.form = this.fb.group({
			assignedToId: [null, CustomValidators.required('Assigned To')],
			associatedContactId: [
				null,
				CustomValidators.requiredWhen(
					() => this.associatedEntityType === SupportedEntityTypes.Contact,
					SupportedEntityTypes.Contact
				)
			],
			associatedMatterId: [
				null,
				CustomValidators.requiredWhen(
					() => this.associatedEntityType === SupportedEntityTypes.Matter,
					SupportedEntityTypes.Matter
				)
			],
			remarks: [null, CustomValidators.required('Remarks / Instruction')],
			description: null,
			dueDate: [null, (c: AbstractControl) => this.rootDueDateValidator(c)],
			status: this.statuses.find(key => TaskStatus[key] === TaskStatus.ToDo),
			priority: this.priorities.find(key => TaskPriority[key] === TaskPriority.Normal),
			recipients: this.fb.array([]),
			documents: this.fb.array([], [c => nonEmptyValidator(c)])
		}) as FormGroupTyped<ReferralTaskCreateUpdateDto>;

		this.subscriptions.add(this.form.controls.dueDate.valueChanges.subscribe(() => this.updateChildDueDates()));

		this.subscriptions.add(
			this.referralReasonsService.getAllReferralReasons().subscribe(reasons => {
				this.reasons = reasons.map(reason => reason.name);
			})
		);

		if (!!this.data?.documentIds?.length) {
			this.data.documentIds.forEach(documentId => {
				const documentGroup = this.fb.group({
					documentId: [null, CustomValidators.required('Document')]
				});

				documentGroup.controls.documentId.setValue(documentId);

				this.documentControls.push(documentGroup);
			});

			this.subscriptions.add(this.updateAssociatedEntity().subscribe());
		}

		this.addRecipient(false);

		if (!!this.data?.editId) {
			this.subscriptions.add(
				this.tasksService.getTask(this.data.editId).subscribe((taskDto: TaskViewDto) => {
					if (!!taskDto.associatedContact) {
						this.associatedEntityType = SupportedEntityTypes.Contact;
						// we need to re-evaluate validator condition for this field (it is absent in the taskDto object)
						taskDto.associatedMatter = null;
					}

					if (!!taskDto.associatedMatter) {
						this.associatedEntityType = SupportedEntityTypes.Matter;
						// we need to re-evaluate validator condition for this field (it is absent in the taskDto object)
						taskDto.associatedContact = null;
					}

					this.form.patchValue({
						assignedToId: taskDto.assignedTo?.id,
						associatedContactId: taskDto.associatedContact?.id,
						associatedMatterId: taskDto.associatedMatter?.id,
						description: taskDto.description,
						dueDate: taskDto.dueDate,
						status: taskDto.status,
						priority: taskDto.priority
					});

					this.updateChildDueDates();
				})
			);
		} else {
			// default in the current user
			this.subscriptions.add(
				this.userService.getCurrentUser().subscribe(user => {
					this.form.patchValue({ assignedToId: user.contact.id });
				})
			);

			// default in the current date
			this.form.patchValue({ dueDate: moment() });

			this.defaultToCurrentOpenMatterOrContact();

			this.updateChildDueDates();
		}
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	getFormControl(name: string): FormControl {
		return this.form.get(name) as FormControl;
	}

	getRecipientFormControl(index: number, name: string): FormControl {
		return this.recipientControls[index].get(name) as FormControl;
	}

	geDocumentFormControl(index: number, name: string): FormControl {
		return this.documentControls[index].get(name) as FormControl;
	}

	onContactSelected(recipientsControl: AbstractControl, event: ILookupReference) {
		recipientsControl.get('contactId').setValue(event?.id ?? null);
	}

	onDocumentSelected(documentsControl: AbstractControl, event: EntityReference) {
		documentsControl.get('documentId').setValue(event?.id ?? null);
	}

	addDocument(autoFocus = true): void {
		const documentGroup = this.fb.group({
			documentId: [null, this.uniqueDocumentsValidator]
		});

		this.documentControls.push(documentGroup);

		if (!!autoFocus) {
			setTimeout(() => {
				this.documentControlRefs.last.setFocus();
			}, 0);
		}

		this.subscriptions.add(
			documentGroup.controls.documentId.valueChanges
				.pipe(switchMap(() => this.updateAssociatedEntity()))
				.subscribe(() => {
					setTimeout(() => {
						this.form.controls.documents.updateValueAndValidity();
					}, 0);
				})
		);

		this.subscriptions.add(this.updateAssociatedEntity().subscribe());

		setTimeout(() => {
			this.form.controls.documents.updateValueAndValidity();
		}, 0);
	}

	addRecipient(autoFocus = true): void {
		const recipientGroup = this.fb.group({
			contactId: [null, CustomValidators.required('Assignee')],
			reason: [null, CustomValidators.required('Reason')],
			dueDate: null
		});

		if (!!this.form.controls.dueDate.value) {
			recipientGroup.controls.dueDate.setValue(this.form.controls.dueDate.value);
		}

		this.recipientControls.push(recipientGroup);

		if (!!autoFocus) {
			setTimeout(() => {
				this.recipientControlRefs.last.setFocus();
			}, 0);
		}

		this.subscriptions.add(
			recipientGroup.controls.contactId.valueChanges.subscribe(() => {
				setTimeout(() => {
					this.form.controls.recipients.updateValueAndValidity();
				}, 0);
			})
		);

		this.subscriptions.add(
			recipientGroup.controls.reason.valueChanges.subscribe(() => {
				setTimeout(() => {
					this.form.controls.recipients.updateValueAndValidity();
				}, 0);
			})
		);

		this.subscriptions.add(
			recipientGroup.controls.dueDate.valueChanges.subscribe(() => {
				setTimeout(() => {
					this.form.controls.recipients.updateValueAndValidity();
					this.form.controls.dueDate.updateValueAndValidity();

					if (this.form.controls.dueDate.invalid) {
						this.form.controls.dueDate.markAsTouched();
					}
				}, 0);
			})
		);

		setTimeout(() => {
			this.form.controls.recipients.updateValueAndValidity();
		}, 0);
	}

	removeDocument(index: number) {
		const control = this.form.controls.documents as FormArray;
		control.removeAt(index);

		this.subscriptions.add(this.updateAssociatedEntity().subscribe());

		setTimeout(() => {
			this.form.controls.documents.updateValueAndValidity();
		}, 0);
	}

	removeRecipient(index: number) {
		const control = this.form.controls.recipients as FormArray;
		control.removeAt(index);

		setTimeout(() => {
			this.form.controls.recipients.updateValueAndValidity();
		}, 0);
	}

	save(isEnteringTime: boolean = false): void {
		this.saving = true;
		const dto = this.form.getRawValue() as ReferralTaskCreateUpdateDto;

		const observable =
			dto.documents.length > 1
				? of(`Referral for multiple documents`)
				: this.documentsService.getDocument(dto.documents[0].documentId).pipe(map(document => document.title));

		this.subscriptions.add(
			observable
				.pipe(
					tap(description => (dto.description = description)),
					switchMap(() => this.tasksService.createReferralTask(dto)),
					tap(response => this.store.dispatch({ type: TaskListActions.InsertRecords, response }))
				)
				.subscribe({
					next: response => {
						if (isEnteringTime) {
							const matterId = this.form.controls.associatedMatterId.value;
							this.dialog.open(DialogComponent, {
								data: new DialogConfig(
									DialogType.Create,
									EntityType.TimeRecord,
									null,
									null,
									null,
									null,
									matterId
								)
							});
						}

						this.notificationService.showNotificationLink({
							linkRoute: ['referrals', response.id],
							linkText: dto.description,
							text: 'Referral Created:'
						});
						this.dialogRef.close();
						this.saving = false;
					},
					error: (errs: DomainError[]) => {
						this.saving = false;
						this.notificationService.showErrors(`Error saving referral`, errs);
					}
				})
		);
	}

	associatedEntityTypeChange() {
		// clear out the associated contact and matter whenever the associated entity type changes
		this.form.patchValue({
			associatedContactId: null,
			associatedMatterId: null
		});
	}

	private updateChildDueDates() {
		const value = this.form.controls.dueDate.value;
		const recipients = this.form.controls.recipients as FormArray;

		if (!!recipients?.length) {
			recipients.controls
				.map(recipient => recipient as FormGroup)
				.filter(recipient => !!recipient.controls.dueDate.pristine)
				.forEach(recipient => {
					recipient.patchValue(
						{
							dueDate: value
						},
						{ emitEvent: false }
					);
				});
		}
	}

	private hasDuplicates(array: any[]) {
		return uniq(array).length !== array.length;
	}

	private uniqueDocumentsValidator: ValidatorFn = (control: AbstractControl): ValidationErrors => {
		if (!this.form) {
			return null;
		}

		const hasDuplicates = this.hasDuplicates(
			this.documentControls
				.filter(control => !!control.get('documentId').value)
				.map(control => control.get('documentId').value as string)
		);

		if (!!hasDuplicates) {
			return { error: `Document must be unique` };
		} else if (!control.value) {
			return { error: `Document must be entered` };
		} else {
			return null;
		}
	};

	private rootDueDateValidator: ValidatorFn = (control: AbstractControl): ValidationErrors => {
		if (!this.form) return null;

		if (!control.value) return { error: `Due Date must be entered` };

		const dueDate = moment(control.value);

		const isBefore =
			this.recipientControls.filter(control => {
				const dueDateControl = control.get('dueDate');

				return !!dueDateControl.value && dueDate.isBefore(moment(dueDateControl.value));
			}).length > 0;

		if (!!isBefore) {
			return { error: `Due Date must be after all recipient due dates` };
		} else {
			return null;
		}
	};

	private defaultToCurrentOpenMatterOrContact() {
		this.hasCurrentEntity = false;

		this.subscriptions.add(
			this.store
				.select(getCurrentPage)
				.pipe(
					filter(page => page.id !== null),
					map(page => this.isPageWithOpenMatter(page))
				)
				.subscribe(page => {
					if (page.pageType === CurrentPageType.Contact) {
						this.associatedEntityType = SupportedEntityTypes.Contact;
						this.form.patchValue({ associatedContactId: page.id });

						this.form.controls.associatedMatterId.disable();
						this.form.controls.associatedContactId.disable();

						this.hasCurrentEntity = true;
					} else if (page.pageType === CurrentPageType.Matter) {
						this.associatedEntityType = SupportedEntityTypes.Matter;
						this.form.patchValue({ associatedMatterId: page.id });

						this.form.controls.associatedMatterId.disable();
						this.form.controls.associatedContactId.disable();

						this.hasCurrentEntity = true;
					}
				})
		);
	}

	private isPageWithOpenMatter(page: ICurrentPageState): ICurrentPageState {
		if (page.pageType === CurrentPageType.Matter) {
			if ((page.lookup as MatterLookupDto).status === MatterStatus.Open) {
				return page;
			}

			return { id: '', lookup: null, pageType: page.pageType };
		}
		return page;
	}

	private updateAssociatedEntity(): Observable<void> {
		let result = of(void 0);

		if (!this.hasCurrentEntity) {
			const documentIds = this.documentControls
				.map(control => control.get('documentId').value)
				.filter(value => !!value);

			if (!!documentIds?.length) {
				if (documentIds.length === 1) {
					result = result.pipe(
						switchMap(() => this.documentsService.getDocument(documentIds[0])),
						map(document => {
							if (!!document?.associatedMatter?.id) {
								this.form.controls.associatedMatterId.setValue(document.associatedMatter.id);
								this.associatedEntityType = SupportedEntityTypes.Matter;
							} else if (!!document?.associatedContact?.id) {
								this.form.controls.associatedContactId.setValue(document.associatedContact.id);
								this.associatedEntityType = SupportedEntityTypes.Contact;
							}

							return void 0;
						})
					);
				}

				result = result.pipe(
					tap(() => {
						this.form.controls.associatedMatterId.disable();
						this.form.controls.associatedContactId.disable();
					})
				);
			} else {
				result = result.pipe(
					tap(() => {
						this.form.controls.associatedMatterId.enable();
						this.form.controls.associatedContactId.enable();
					})
				);
			}
		}

		return result;
	}
}

export interface ICreateUpdateReferralDialogData {
	editId: string;
	documentIds: string[];
}
