// This file has been generated from TaskType.cs
// Do not modify this file directly!!

// LawMaster.Amalthea.Domain.Tasks.Common.TaskType
export enum TaskType {
	Generic = 'Basic Task',
	Referral = 'Main Referral',
	Referred = 'Referral Task',
	DocumentTemplateMerge = 'Document Merge Task',
	CollectionTask = 'Data Collection Task'
}
