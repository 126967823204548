import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { JobListFilterComponent } from '../filter/job-list-filter.component';
import { JobListFilterConfig } from '../filter/JobListFilterConfig';

@Component({
	selector: 'job-list-filter-dialog',
	styleUrls: ['./job-list-filter-dialog.component.scss'],
	templateUrl: './job-list-filter-dialog.component.html'
})
export class JobListFilterDialogComponent implements OnInit, OnDestroy {
	@ViewChild(JobListFilterComponent, { static: true }) filter: JobListFilterComponent;
	private subscriptions: Subscription = new Subscription();

	constructor(
		@Inject(MAT_DIALOG_DATA) public config: JobListFilterConfig,
		private dialogRef: MatDialogRef<JobListFilterDialogComponent>
	) {}

	ngOnInit(): void {
		this.subscriptions.add(this.dialogRef.beforeClosed().subscribe(() => this.filter.root.applyFilter()));
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	apply() {
		this.dialogRef.close(this.filter.root.filter);
	}

	clear() {
		this.dialogRef.close({});
	}
}
