import { EventEmitter, Injectable, Output } from '@angular/core';
import { EntityType } from 'app/core/dialog.config';

@Injectable({
	providedIn: 'root'
})
export class EntityCreatedNotificationService {
	@Output() TaskCreated = new EventEmitter<void>();
	@Output() TimeRecordCreated = new EventEmitter<void>();
	@Output() ExpenseRecordCreated = new EventEmitter<void>();
	@Output() CostRecordCreated = new EventEmitter<void>();
	@Output() MatterCreated = new EventEmitter<void>();
	@Output() ContactCreated = new EventEmitter<void>();
	@Output() NoteCreated = new EventEmitter<void>();
	@Output() TrustCreated = new EventEmitter<void>();
	@Output() CustomReportCreated = new EventEmitter<void>();

	entityCreated(entityType: EntityType) {
		switch (entityType) {
			case EntityType.Task:
				this.TaskCreated.emit();
				break;

			case EntityType.TimeRecord:
				this.TimeRecordCreated.emit();
				this.CostRecordCreated.emit();
				break;

			case EntityType.Expense:
				this.ExpenseRecordCreated.emit();
				this.CostRecordCreated.emit();
				break;

			case EntityType.Matter:
				this.MatterCreated.emit();
				break;

			case EntityType.Note:
				this.NoteCreated.emit();
				break;

			case EntityType.Contact:
				this.ContactCreated.emit();
				break;

			case EntityType.Trust:
				this.TrustCreated.emit();
				break;

			case EntityType.CustomReport:
				this.CustomReportCreated.emit();
				break;

			default:
				throw new Error('Type "' + entityType + '" is not implemented');
		}
	}
}
