import { Pipe, PipeTransform } from '@angular/core';

import moment from 'moment';

/*
 * Resolve practice type display string by the enum key
 */
@Pipe({ name: 'moment' })
export class MomentPipe implements PipeTransform {
	transform(value: Date | string | number, format?: string): string {
		const parsedFormat = format?.replace('dd', 'DD')?.replace('yyyy', 'YYYY');

		return moment(value).format(parsedFormat);
	}
}
