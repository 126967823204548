import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { AppConfig } from 'app/app.config';

export enum AppThemes {
	Mattero = 'mattero',
	LawMaster = 'lawmaster'
}

@Injectable({
	providedIn: 'root'
})
export class AppBrandingService {
	private static _theme: AppThemes;

	private static getTheme() {
		if (!this._theme) {
			this._theme = !!AppConfig.IsLawMasterHost ? AppThemes.LawMaster : AppThemes.Mattero;
		}

		return this._theme;
	}

	get mainIcon() {
		return AppBrandingService.getTheme() === AppThemes.Mattero
			? 'logo_mattero.png'
			: AppBrandingService.getTheme() === AppThemes.LawMaster
			? 'logo_lawmaster.svg'
			: '';
	}

	get alternativeIcon() {
		return AppBrandingService.getTheme() === AppThemes.Mattero
			? 'logo_mattero.svg'
			: AppBrandingService.getTheme() === AppThemes.LawMaster
			? 'logo_lawmaster_alternate.svg'
			: '';
	}

	get favIcon() {
		return AppBrandingService.getTheme() === AppThemes.Mattero
			? 'mattero/favicon-32x32.png'
			: AppBrandingService.getTheme() === AppThemes.LawMaster
			? 'LawMaster/favicon-32x32.png'
			: '';
	}

	get imageAlt() {
		return AppBrandingService.getTheme() === AppThemes.Mattero
			? 'mattero logo'
			: AppBrandingService.getTheme() === AppThemes.LawMaster
			? 'LawMaster logo'
			: '';
	}

	get favIconX32() {
		return AppBrandingService.getTheme() === AppThemes.Mattero
			? '/assets/icon/mattero/favicon-32x32.png'
			: AppBrandingService.getTheme() === AppThemes.LawMaster
			? '/assets/icon/LawMaster/favicon-32x32.png'
			: '';
	}

	get primaryColor() {
		return AppBrandingService.getTheme() === AppThemes.Mattero
			? '#5C7BD9'
			: AppBrandingService.getTheme() === AppThemes.LawMaster
			? '#0069E3'
			: '';
	}

	get accentColor() {
		return AppBrandingService.getTheme() === AppThemes.Mattero
			? '#A20467'
			: AppBrandingService.getTheme() === AppThemes.LawMaster
			? '#E1503F'
			: '';
	}

	static getSiteName() {
		return AppBrandingService.getTheme() === AppThemes.Mattero
			? 'mattero'
			: AppBrandingService.getTheme() === AppThemes.LawMaster
			? 'LawMaster'
			: '';
	}

	constructor(@Inject(DOCUMENT) private document: Document) {}

	private setTheme(theme: AppThemes) {
		this.document.body.classList.remove(...Object.values(AppThemes).map(theme => this.formatTheme(theme)));

		const themeClass = this.formatTheme(theme);

		if (!this.document.body.classList.contains(themeClass)) {
			this.document.body.classList.add(themeClass);
		}
	}

	private formatTheme(theme: AppThemes) {
		return `t-${theme}`;
	}

	trySetTheme(): boolean {
		const theme = AppBrandingService.getTheme();
		if (!theme) {
			return false;
		}

		this.setTheme(theme);
		return true;
	}
}
