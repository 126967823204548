// This file has been generated from BillNumberFormatDto.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.Domain.Settings.BillSettings.Item.BillNumberFormatDto
export class BillNumberFormatDto {

	// Value
	value: string;

	constructor() {

	}
}