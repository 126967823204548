import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';

import { CustomFieldType } from '@common/models/Settings/CustomFields/Common/CustomFieldType';
import { CustomFieldListItemDto } from '@common/models/Settings/CustomFields/List/CustomFieldListItemDto';
import { isEmpty, isNil, isString } from 'lodash';
import { get } from 'lodash-es';

import { SecurityPermissionService } from 'app/core/security-permissions.service';

import { BaseEditableComponent } from '../base.editable.component';

@Component({
	selector: 'editable-custom-fields',
	styleUrls: ['./editable-custom-fields.component.scss'],
	templateUrl: './editable-custom-fields.component.html'
})
export class EditableCustomFieldsComponent extends BaseEditableComponent<any> implements OnInit, OnDestroy {
	@Input()
	formGroupName: string = DEFAULT_FORM_GROUP_NAME;
	@Input()
	hideContacts: boolean;
	@Input()
	forceShowMandatoryContacts?: boolean;
	@Input()
	forceShowCalculated?: boolean;
	@Input()
	set customFieldsValues(value: { [key: string]: any }) {
		this.customFieldsValuesSubject.next(value);
	}
	@Input()
	set fieldConfigValues(value: CustomFieldListItemDto[]) {
		this.fieldConfigValuesSubject.next(value);
	}
	@Input()
	sortFields: boolean = true;

	@Input()
	validateContacts: boolean = true;

	customFieldConfig: CustomFieldListItemDto[];

	private _isMatter: boolean;
	private _isContact: boolean;
	private _entityId: string;

	private subscriptions: Subscription = new Subscription();

	get isMatter() {
		return !!this._isMatter;
	}

	get isContact() {
		return !!this._isContact;
	}

	get entityId() {
		return this._entityId;
	}

	get hasAccessToSettings() {
		return this.securityPermissionService.hasAccessToSettings;
	}

	private customFieldsValuesSubject = new BehaviorSubject<{
		[key: string]: any;
	}>(null);

	private fieldConfigValuesSubject = new BehaviorSubject<CustomFieldListItemDto[]>(null);

	constructor(private router: Router, private securityPermissionService: SecurityPermissionService) {
		super();
	}

	ngOnInit() {
		if (isNil(this.control?.get(this.formGroupName))) {
			throw new Error(
				`Form Group '${this.formGroupName}' is not defined on 'this.control'. Have you misspelled a form control in form.get('...')? Note: Form group must now be setup outside of the editable-custom-fields.component`
			);
		}

		const combined = combineLatest([this.customFieldsValuesSubject, this.fieldConfigValuesSubject]);

		this.subscriptions.add(
			combined.subscribe(([fieldVals, fieldCfg]) => {
				this.filterAndSortConfig(fieldVals, fieldCfg);
			})
		);

		if (!!this.router.url) {
			const url = this.router.url.toLowerCase();

			this._isMatter = url.startsWith('/matters/');
			this._isContact = url.startsWith('/contacts/');

			if (!!this._isMatter || !!this._isContact) {
				let trimmedUrl = this._isMatter ? url.replace('/matters/', '') : url.replace('/contacts/', '');

				const slashIndex = trimmedUrl.indexOf('/');
				if (slashIndex > 0) {
					trimmedUrl = trimmedUrl.substring(0, slashIndex);
				}

				this._entityId = trimmedUrl.toUpperCase();
			}
		}
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	showContactField(field: CustomFieldListItemDto): boolean {
		if (field.fieldType === CustomFieldType.Contact && this.hideContacts) {
			if (this.forceShowMandatoryContacts && field.mandatory && this.editMode) {
				return true;
			}
			return false;
		}
		return true;
	}

	private filterAndSortConfig(fieldVals: { [key: string]: any }, fieldCfg: CustomFieldListItemDto[]): void {
		if (isEmpty(fieldCfg)) return;

		// Ignore if the value is disabled and has no value
		fieldCfg = fieldCfg.filter(value => value.enabled || !this.isValueEmpty(get(fieldVals, value.id)));

		if (this.sortFields) {
			this.customFieldConfig = fieldCfg.sort(
				(a, b) => 0 - (a.fieldType === CustomFieldType.Contact && this.hideContacts ? -1 : 1)
			);
		} else {
			this.customFieldConfig = fieldCfg;
		}
	}

	private isValueEmpty(value: any): boolean {
		return isNil(value) || (isString(value) && value === '');
	}
}

export const DEFAULT_FORM_GROUP_NAME = 'customFields';
