<mat-table [dataSource]="datasource" matSort matSortActive="lastModifiedUtc" matSortDirection="desc">
	<ng-container matColumnDef="productName">
		<mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
		<mat-cell *matCellDef="let row"> {{ row.productName }} </mat-cell>
	</ng-container>

	<ng-container matColumnDef="version">
		<mat-header-cell *matHeaderCellDef mat-sort-header>Version</mat-header-cell>
		<mat-cell *matCellDef="let row"> {{ row.version }} </mat-cell>
	</ng-container>

	<ng-container matColumnDef="status">
		<mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
		<mat-cell *matCellDef="let row"> {{ row.status }} </mat-cell>
	</ng-container>

	<ng-container matColumnDef="documentTemplateCount">
		<mat-header-cell *matHeaderCellDef mat-sort-header>Template Count</mat-header-cell>
		<mat-cell *matCellDef="let row"> {{ row.documentTemplateCount || 0 }} </mat-cell>
	</ng-container>

	<ng-container matColumnDef="documentEmailTemplateCount">
		<mat-header-cell *matHeaderCellDef mat-sort-header>Email Count</mat-header-cell>
		<mat-cell *matCellDef="let row"> {{ row.documentEmailTemplateCount || 0 }} </mat-cell>
	</ng-container>

	<ng-container matColumnDef="documentHeaderFooterTemplateCount">
		<mat-header-cell *matHeaderCellDef mat-sort-header>Header/Footer Count</mat-header-cell>
		<mat-cell *matCellDef="let row"> {{ row.documentHeaderFooterTemplateCount || 0 }} </mat-cell>
	</ng-container>

	<ng-container matColumnDef="customFieldCount">
		<mat-header-cell *matHeaderCellDef mat-sort-header>Custom Field Count</mat-header-cell>
		<mat-cell *matCellDef="let row"> {{ row.customFieldCount || 0 }} </mat-cell>
	</ng-container>

	<ng-container matColumnDef="practiceAreaCount">
		<mat-header-cell *matHeaderCellDef mat-sort-header>Practice Area Count</mat-header-cell>
		<mat-cell *matCellDef="let row"> {{ row.practiceAreaCount || 0 }} </mat-cell>
	</ng-container>

	<ng-container matColumnDef="lastModifiedUtc">
		<mat-header-cell *matHeaderCellDef mat-sort-header>Last Modified</mat-header-cell>
		<mat-cell *matCellDef="let row"> {{ row.lastModifiedUtc | moment : 'dd/MM/yyyy' }} </mat-cell>
	</ng-container>

	<ng-container matColumnDef="validationStatus">
		<mat-header-cell *matHeaderCellDef>Validation Status</mat-header-cell>
		<mat-cell *matCellDef="let row">
			<span *ngIf="row.validationStatus !== 'Invalid'">{{ row.validationStatus }}</span>
			<div *ngIf="row.validationStatus === 'Invalid'" class="invalid-cell">
				<a throttleButton (throttledClick)="showErrors(row)">{{ row.validationStatus }}</a>
				<span class="error-count">Error count: {{ row.errors.length }}</span>
			</div></mat-cell
		>
	</ng-container>

	<ng-container matColumnDef="actions">
		<mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
		<mat-cell *matCellDef="let row">
			<button mat-icon-button [matMenuTriggerFor]="menu" title="Actions">
				<mat-icon>more_vert</mat-icon>
			</button>
			<mat-menu #menu="matMenu">
				<button mat-menu-item>
					<mat-icon>delete</mat-icon>
					<span>Delete</span>
				</button>
			</mat-menu>
		</mat-cell>
	</ng-container>

	<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
	<mat-row
		*matRowDef="let row; columns: displayedColumns"
		throttleButton
		(throttledClick)="selectRow(row, $event)"
		[ngClass]="{ highlight: row.isHighlighted }"
	></mat-row>
</mat-table>
<mat-paginator
	[length]="datasource?.response?.totalRecords"
	[pageIndex]="datasource?.response?.pageIndex"
	[pageSize]="pageSize"
	[pageSizeOptions]="pageSizeOptions"
></mat-paginator>
