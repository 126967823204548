import { Component, Input } from '@angular/core';
import { BaseEditableTextComponent } from './base-editable-text.component';

@Component({
	selector: 'editable-long-text',
	styleUrls: ['./base.editable.component.scss'],
	template: `
		<span *ngIf="!editMode" class="readonly-text" [class]="value() ? '' : 'view-noitem'">
			<ng-container *ngIf="!!value()">
				<ng-container *ngFor="let block of displayBlocks">
					<span *ngIf="!block.link">{{ block.text }}</span>
					<a *ngIf="!!block.link" [matTooltip]="block.link" [href]="block.link">{{ block.text }}</a>
				</ng-container>
			</ng-container>
			<ng-container *ngIf="!value()">
				{{ blankMessage }}
			</ng-container>
		</span>
		<mat-form-field *ngIf="editMode" class="editable-text">
			<mat-placeholder *ngIf="!!placeHolder"
				>{{ placeHolder }}
				<sup class="color-warn" *ngIf="!!required">*</sup>
			</mat-placeholder>
			<textarea
				matInput
				cdkTextareaAutosize
				[formControl]="formControl"
				[autofocus]="hasAutofocus"
				[cdkAutosizeMinRows]="initialSize"
			></textarea>
			<mat-hint *ngIf="!!hintText?.length">{{ hintText }}</mat-hint>
			<mat-error>
				<error-messages [for]="control"></error-messages>
			</mat-error>
		</mat-form-field>
	`
})
export class EditableLongTextComponent extends BaseEditableTextComponent {
	@Input() hasAutofocus: boolean;
	@Input() initialSize: number = 3;
}
