// Base implementation for native storage implementations
export class BaseStorageService {
	protected storage: Storage;

	get isSupported(): boolean {
		return !!this.storage;
	}

	getKeys() {
		return Object.keys(this.storage);
	}

	setItem(key: string, value: any) {
		const json = JSON.stringify(value);
		this.storage.setItem(key, json);
	}

	getItem(key: string): any {
		const json = this.storage.getItem(key);

		if (json) {
			return JSON.parse(json);
		}

		return null;
	}

	getItemAs<T>(key: string): T {
		const value = this.getItem(key);

		if (value) {
			return value as T;
		}

		return null;
	}

	removeItem(key: string): void {
		this.storage.removeItem(key);
	}

	containsKey(key: string): boolean {
		return !!this.getItem(key);
	}

	clear(): void {
		this.storage.clear();
	}
}
