import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';

import { SharedDirectivesModule } from '@common/directives/shared-directives.module';
import { SharedPipesModule } from '@common/pipes/shared-pipes.module';

import { ComponentsModule } from '../components';
import { PipesModule } from '../pipes/pipes.module';
import { FilterBillStatusButtonComponent } from './filter-bill-status-button.component';
import { FilterBilledStatusComponent } from './filter-billed-status.component';
import { FilterButtonComponent } from './filter-button.component';
import { FilterCheckboxButtonComponent } from './filter-checkbox-button.component';
import { FilterContactButtonComponent } from './filter-contact-button.component';
import { FilterContactTypeButtonComponent } from './filter-contact-type-button.component';
import { FilterCostCodeComponent } from './filter-cost-code.component';
import { FilterCostPricingMethodComponent } from './filter-cost-pricing-method.component';
import { FilterCostTypeComponent } from './filter-cost-type.component';
import { FilterCostingMethodComponent } from './filter-costing-method.component';
import { FilterCustomReportTypeButtonComponent } from './filter-custom-report-type-button.component';
import { FilterDateButtonComponent } from './filter-date-button.component';
import { FilterDateRangeButtonComponent } from './filter-date-range-button.component';
import { FilterDateRangeComponent } from './filter-date-range.component';
import { FilterDocumentCategoryComponent } from './filter-document-category.component';
import { FilterEntityReferenceListComponent } from './filter-entity-reference-list.component';
import { FilterFileExtensionButtonComponent } from './filter-file-extension-button';
import { FilterFullTextSearchButtonComponent } from './filter-full-text-search-button.component';
import { FilterInfoTrackStatusComponent } from './filter-infotrack-status.component';
import { FilterInstalledPackageButtonComponent } from './filter-installed-package-button.component';
import { FilterJobStatusButtonComponent } from './filter-job-status-button.component';
import { FilterMatterRoleButtonComponent } from './filter-matter-role-button.component';
import { FilterMatterStatusComponent } from './filter-matter-status.component';
import { FilterMobileSpanComponent } from './filter-mobile-span.component';
import { FilterMultiEntityListComponent } from './filter-multi-entity-list.component';
import { FilterMultiEntityReferenceListComponent } from './filter-multi-entity-reference-list.component';
import { FilterNumericButtonComponent } from './filter-numeric-button.component';
import { FilterNumericRangeButtonComponent } from './filter-numeric-range-button.component';
import { FilterPracticeAreaStageComponent } from './filter-practice-area-stage.component';
import { FilterPracticeAreaComponent } from './filter-practice-area.component';
import { FilterRelatedEntityTypeComponent } from './filter-related-entity-type.component';
import { FilterRootComponent } from './filter-root.component';
import { FilterSearchButtonComponent } from './filter-search-button.component';
import { FilterSearchComponent } from './filter-search.component';
import { FilterTaskDueComponent } from './filter-task-due.component';
import { FilterTaskEntityTypeComponent } from './filter-task-entity-type.component';
import { FilterTaskPriorityButtonComponent } from './filter-task-priority-button.component';
import { FilterTaskStatusButtonComponent } from './filter-task-status-button.component';
import { FilterTaskStatusComponent } from './filter-task-status.component';
import { FilterTaskTypeButtonComponent } from './filter-task-type-button.component';
import { FilterToggleButtonComponent } from './filter-toggle-button.component';
import { FilterTrustAccountComponent } from './filter-trust-account.component';
import { TrustCashbookReportTypeComponent } from './filter-trust-cashbook-report-type.component';
import { FilterTrustCashbookTransactionTypeComponent } from './filter-trust-cashbook-transaction-type.component';
import { FilterTrustClearanceStatusComponent } from './filter-trust-clearance-status.component';
import { FilterTrustDepositedStatusComponent } from './filter-trust-deposited-status.component';
import { FilterTrustLedgerTransactionTypeComponent } from './filter-trust-ledger-transaction-type.component';
import { FilterTrustMediaTypeComponent } from './filter-trust-media-type.component.';
import { FilterTrustReceiptPublishedComponent } from './filter-trust-receipt-published.component';
import { FilterTrustReconciliationStatusComponent } from './filter-trust-reconciliation-status.component';

@NgModule({
	declarations: [
		FilterContactTypeButtonComponent,
		FilterCostCodeComponent,
		FilterBillStatusButtonComponent,
		FilterBilledStatusComponent,
		FilterButtonComponent,
		FilterCheckboxButtonComponent,
		FilterContactButtonComponent,
		FilterCostingMethodComponent,
		FilterCostTypeComponent,
		FilterCostPricingMethodComponent,
		FilterDateButtonComponent,
		FilterDateRangeButtonComponent,
		FilterDateRangeComponent,
		FilterDocumentCategoryComponent,
		FilterEntityReferenceListComponent,
		FilterFileExtensionButtonComponent,
		FilterFullTextSearchButtonComponent,
		FilterInfoTrackStatusComponent,
		FilterInstalledPackageButtonComponent,
		FilterJobStatusButtonComponent,
		FilterMatterStatusComponent,
		FilterMobileSpanComponent,
		FilterMultiEntityListComponent,
		FilterMultiEntityReferenceListComponent,
		FilterNumericRangeButtonComponent,
		FilterNumericButtonComponent,
		FilterPracticeAreaComponent,
		FilterPracticeAreaStageComponent,
		FilterRelatedEntityTypeComponent,
		FilterRootComponent,
		FilterSearchButtonComponent,
		FilterSearchComponent,
		FilterTaskDueComponent,
		FilterTaskPriorityButtonComponent,
		FilterTaskStatusButtonComponent,
		FilterTaskStatusComponent,
		FilterTaskEntityTypeComponent,
		FilterTaskTypeButtonComponent,
		FilterToggleButtonComponent,
		FilterTrustClearanceStatusComponent,
		FilterTrustReconciliationStatusComponent,
		FilterTrustDepositedStatusComponent,
		FilterTrustReceiptPublishedComponent,
		FilterTrustLedgerTransactionTypeComponent,
		FilterTrustMediaTypeComponent,
		FilterTrustCashbookTransactionTypeComponent,
		TrustCashbookReportTypeComponent,
		FilterTrustAccountComponent,
		FilterMatterRoleButtonComponent,
		FilterCustomReportTypeButtonComponent
	],
	exports: [
		FilterContactTypeButtonComponent,
		FilterCostCodeComponent,
		FilterBillStatusButtonComponent,
		FilterBilledStatusComponent,
		FilterButtonComponent,
		FilterCheckboxButtonComponent,
		FilterContactButtonComponent,
		FilterCostingMethodComponent,
		FilterCostTypeComponent,
		FilterCostPricingMethodComponent,
		FilterDateButtonComponent,
		FilterDateRangeButtonComponent,
		FilterDateRangeComponent,
		FilterDocumentCategoryComponent,
		FilterEntityReferenceListComponent,
		FilterFileExtensionButtonComponent,
		FilterFullTextSearchButtonComponent,
		FilterInfoTrackStatusComponent,
		FilterInstalledPackageButtonComponent,
		FilterJobStatusButtonComponent,
		FilterMatterStatusComponent,
		FilterMobileSpanComponent,
		FilterMultiEntityListComponent,
		FilterMultiEntityReferenceListComponent,
		FilterNumericRangeButtonComponent,
		FilterNumericButtonComponent,
		FilterPracticeAreaComponent,
		FilterPracticeAreaStageComponent,
		FilterRelatedEntityTypeComponent,
		FilterRootComponent,
		FilterSearchButtonComponent,
		FilterSearchComponent,
		FilterTaskDueComponent,
		FilterTaskPriorityButtonComponent,
		FilterTaskStatusButtonComponent,
		FilterTaskStatusComponent,
		FilterTaskEntityTypeComponent,
		FilterTaskTypeButtonComponent,
		FilterToggleButtonComponent,
		FilterTrustClearanceStatusComponent,
		FilterTrustReconciliationStatusComponent,
		FilterTrustDepositedStatusComponent,
		FilterTrustReceiptPublishedComponent,
		FilterTrustLedgerTransactionTypeComponent,
		FilterTrustMediaTypeComponent,
		FilterTrustCashbookTransactionTypeComponent,
		TrustCashbookReportTypeComponent,
		FilterTrustAccountComponent,
		FilterMatterRoleButtonComponent,
		FilterCustomReportTypeButtonComponent
	],
	imports: [
		CommonModule,
		ComponentsModule,
		MatButtonModule,
		MatDatepickerModule,
		MatExpansionModule,
		MatIconModule,
		MatInputModule,
		MatListModule,
		MatButtonToggleModule,
		MatSelectModule,
		OverlayModule,
		ReactiveFormsModule,
		MatCheckboxModule,
		SharedDirectivesModule,
		PipesModule,
		SharedPipesModule,
		MatMenuModule
	]
})
export class FilterControlsModule {}
