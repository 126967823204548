// This file has been generated from EntityReference.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.Domain.Common.EntityReference
export class EntityReference {

	// Id
	id: string;
	// Name
	name: string;

	constructor() {

	}
}