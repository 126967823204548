import { ListResponse } from '@common/models/Generic/ListResponse';
import { CostCodeListItemDto } from '@common/models/Settings/CostCodes/List/CostCodeListItemDto';
import { CostCodeListRequest } from '@common/models/Settings/CostCodes/List/CostCodeListRequest';
import { DomainError } from '@common/models/Validation/DomainError';
import { Moment } from 'moment';

export interface ICostCodeFilterState {
	isFetching: boolean;
	list: ListResponse<CostCodeListItemDto>;

	request: Partial<CostCodeListRequest>;
	oldRequest: Partial<CostCodeListRequest>;

	cacheExpiry: Moment;
	// errrors
	error: DomainError;
}

export const initialState: ICostCodeFilterState = {
	isFetching: false,
	list: null,
	request: {},
	oldRequest: null,
	error: null,
	cacheExpiry: null
};
