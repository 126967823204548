// This file has been generated from MatterCustomFieldListRequest.cs
// Do not modify this file directly!!
// tslint:disable

import { SetSimpleType } from '@common/utils/class-transform';

import { EnumSortDirection } from '../../../Common/EnumSortDirection';
import { CustomFieldType } from '../Common/CustomFieldType';

// LawMaster.Amalthea.Domain.Settings.CustomFields.List.MatterCustomFieldListRequest
export class MatterCustomFieldListRequest {

	// Search
	search: string;
	// SortBy
	sortBy: string;
	// SortDirection
	sortDirection: keyof typeof EnumSortDirection;
	// PageIndex
	pageIndex: number;
	// PageSize
	pageSize: number;
	// PageIndexForId
	pageIndexForId: string;
	// Ids
	ids: string[];
	// Name
	name: string;
	// FieldType
	fieldType: Array<keyof typeof CustomFieldType>;
	// ExcludeFieldType
	excludeFieldType: Array<keyof typeof CustomFieldType>;
	// Mandatory
	@SetSimpleType(Boolean)
	mandatory: boolean;
	// Enabled
	@SetSimpleType(Boolean)
	enabled: boolean;
	// CanShowInReports
	@SetSimpleType(Boolean)
	canShowInReports: boolean;
	// GroupId
	groupId: string;
	// InstallationRecordId
	installationRecordId: string;
	// HasDefaultValue
	@SetSimpleType(Boolean)
	hasDefaultValue: boolean;
	// IncludeCustomFieldIds
	includeCustomFieldIds: string[];
	// IncludeCustomFieldGroupIds
	includeCustomFieldGroupIds: string[];
	// PracticeAreaIds
	practiceAreaIds: string[];

	constructor() {
		this.fieldType = [];
		this.excludeFieldType = [];
	}
}