import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { DocumentListFilterComponent } from '../filter/document-list-filter.component';
import { DocumentListFilterConfig } from '../filter/document-list-filter.config';

@Component({
	selector: 'document-list-filter-dialog',
	styleUrls: ['./document-list-filter-dialog.component.scss'],
	templateUrl: './document-list-filter-dialog.component.html'
})
export class DocumentListFilterDialogComponent {
	@ViewChild(DocumentListFilterComponent, { static: true }) filter: DocumentListFilterComponent;

	constructor(
		@Inject(MAT_DIALOG_DATA) public config: Partial<DocumentListFilterConfig>,
		private dialogRef: MatDialogRef<DocumentListFilterDialogComponent>
	) {
		dialogRef.beforeClosed().subscribe(() => this.filter.root.applyFilter());
	}

	apply() {
		this.dialogRef.close(this.filter.root.filter);
	}

	clear() {
		this.dialogRef.close({});
	}
}
