import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { LocalStorageService } from '@common/services/storage/local-storage.service';

import { WorkTimerState } from 'app/shared/components/work-timer/WorkTimerState';
import { version2, WorkTimerStateData } from 'app/shared/components/work-timer/WorkTimerStateData';
import { WorkTimerStateDataVolatile } from 'app/shared/components/work-timer/WorkTimerStateDataVolatile';
import { WorkTimerUtils } from 'app/shared/components/work-timer/WorkTimerUtils';

export interface ICreateMatterTimer {
	matterId: string;
	description: string;
}

// The class for managing and storing state, everything the interacts with the work timer will probably
// need to use this class
@Injectable({
	providedIn: 'root'
})
export class WorkTimerStateManager {
	constructor(private storageService: LocalStorageService) {
		this.migrate();
	}

	private _startStateSubject$ = new Subject<WorkTimerState>();
	private _createdStateSubject$ = new Subject<WorkTimerState>();
	private _pauseStateSubject$ = new Subject<WorkTimerState>();
	private _resetStateSubject$ = new Subject<WorkTimerState>();
	private _clearStateSubject$ = new Subject<string>();

	get onStartState$() {
		return this._startStateSubject$.asObservable();
	}

	get onCreatedState$() {
		return this._createdStateSubject$.asObservable();
	}

	get onPauseState$() {
		return this._pauseStateSubject$.asObservable();
	}

	get onResetState$() {
		return this._resetStateSubject$.asObservable();
	}

	get onClearState$() {
		return this._clearStateSubject$.asObservable();
	}

	// Regular interval poll, can handles auto starts and regularly
	// updates volatile data
	pollState(id: string): WorkTimerState {
		let state = this.getState(id);

		if (!state) {
			return null;
		}

		let hasStarted = false;
		if (!state.data.isRunning && state.data.canAutostart) {
			this.internalStartState(state);
			hasStarted = true;
		}

		state = WorkTimerUtils.ensureStateAllocated(state);
		WorkTimerUtils.updateVolatileData(state);
		state.raiseVolatileDataChanged();

		this.setState(id, state);

		if (!!hasStarted) {
			this._startStateSubject$.next(state);
		}

		return state;
	}

	getTimerIds(excludeIds?: string[]) {
		const keys = this.storageService.getKeys();
		return keys
			.filter(key => key.startsWith(WorkTimerStateManager.dataStorageKeyPrefix))
			.map(key => key.substring(WorkTimerStateManager.dataStorageKeyPrefix.length))
			.filter(key => !excludeIds || excludeIds.length <= 0 || !excludeIds.some(excluded => excluded === key))
			;
	}

	getActiveTimerId() {
		const timerIds = this.getTimerIds();
		if (!timerIds && timerIds.length <= 0) {
			return null;
		}

		for (const id of timerIds) {
			const state = this.getState(id);
			if (!!state && state.data.isRunning) {
				return id;
			}
		}

		return null;
	}

	pauseOtherTimers(id?: string) {
		const timerIds = this.getTimerIds(!!id ? [id] : undefined);
		if (!timerIds && timerIds.length <= 0) {
			return;
		}
		timerIds.forEach(timerId => this.pauseState(timerId));
	}

	// If the state is not running start or resume the timer state
	startState(id: string, state: WorkTimerState = null, isManual: boolean = true, description: string = null): WorkTimerState {
		if (!state) {
			state = this.getState(id);
		}

		this.pauseOtherTimers(id);

		const storedInLocalStorage = !!state;

		state = WorkTimerUtils.ensureStateAllocated(state);

		const now = WorkTimerUtils.getDateNowMilliseconds();
		state.volatileData.lastCheckedDate = now;
		state.raiseVolatileDataChanged();

		if (!!description && description !== null) {
			state.data.description = description;
		}

		if (isManual) {
			state.data.canAutostart = false;
			state.raiseDataChanged();
		}

		if (state.data.isRunning) {
			this.setState(id, state);
			return state;
		}

		this.internalStartState(state);
		this.setState(id, state);

		this._startStateSubject$.next(state);

		if (!storedInLocalStorage) {
			this._createdStateSubject$.next(state);
		}

		return state;
	}

	// Reset a running state to defaults, otherwise clear the state entirelly
	resetState(id: string, state: WorkTimerState = null, canAutostart: boolean = true): WorkTimerState {
		if (!!state?.data) {
			this.pauseOtherTimers(id);

			state = WorkTimerUtils.ensureStateAllocated(state);

			const now = WorkTimerUtils.getDateNowMilliseconds();

			state.data.setupDate = now;
			state.data.elapsedMilliseconds = null;
			state.data.startDate = now;
			this.startState(id, state);

			this._resetStateSubject$.next(state);
		}

		return state;
	}

	updateState(id: string, description: string) {
		const state = this.getState(id);
		if (!state) {
			return;
		}

		state.data.description = description;
		state.raiseDataChanged();
		this.setState(id, state);
	}

	// If the state is running pause the timer state
	pauseState(id: string, state: WorkTimerState = null, isManual: boolean = true): WorkTimerState {
		if (!state) {
			state = this.getState(id);
		}

		if (!state?.data) {
			return null;
		}

		state = WorkTimerUtils.ensureStateAllocated(state);

		if (!state.data.isRunning) {
			return null;
		}

		if (!state.data.elapsedMilliseconds) {
			state.data.elapsedMilliseconds = 0;
		}

		state.data.isRunning = false;
		state.data.elapsedMilliseconds += WorkTimerUtils.getMillisecondsFromDate(
			state.data.startDate,
			state.volatileData.lastCheckedDate
		);

		state.data.startDate = null;

		if (isManual) {
			state.data.canAutostart = false;
		}

		state.raiseDataChanged();

		state.volatileData.lastCheckedDate = WorkTimerUtils.getDateNowMilliseconds();
		state.raiseVolatileDataChanged();

		this.setState(id, state);

		this._pauseStateSubject$.next(state);

		return state;
	}

	// Update the changed members of state in the provided storage
	setState(id: string, state: WorkTimerState): void {
		if (this.storageService.isSupported) {
			if (state && state.data && state.volatileData) {
				if (state.hasDataChanged) {
					const dataKey = WorkTimerStateManager.getDataStorageKey(id);
					this.storageService.setItem(dataKey, state.data);

					state.hasDataChanged = false;
				}

				if (state.hasVolatileDataChanged) {
					const volatileDataKey = WorkTimerStateManager.getVolatileDataStorageKey(id);
					this.storageService.setItem(volatileDataKey, state.volatileData);

					state.hasVolatileDataChanged = false;
				}
			} else {
				const dataKey = WorkTimerStateManager.getDataStorageKey(id);
				const volatileDataKey = WorkTimerStateManager.getVolatileDataStorageKey(id);

				this.storageService.removeItem(dataKey);
				this.storageService.removeItem(volatileDataKey);
			}
		}
	}

	// Get the current state from storage
	getState(id: string): WorkTimerState {
		if (this.storageService.isSupported) {
			const dataKey = WorkTimerStateManager.getDataStorageKey(id);
			const volatileDataKey = WorkTimerStateManager.getVolatileDataStorageKey(id);

			const data = this.storageService.getItemAs<WorkTimerStateData>(dataKey);
			const volatileData = this.storageService.getItemAs<WorkTimerStateDataVolatile>(volatileDataKey);

			if (data && volatileData) {
				return new WorkTimerState({ data, volatileData });
			}
		}

		return null;
	}

	// Delete the current state from storage
	clearState(id: string): void {
		if (this.storageService.isSupported) {
			const dataKey = WorkTimerStateManager.getDataStorageKey(id);
			const volatileDataKey = WorkTimerStateManager.getVolatileDataStorageKey(id);

			this.storageService.removeItem(dataKey);
			this.storageService.removeItem(volatileDataKey);

			this._clearStateSubject$.next(id);
		}
	}

	internalStartState(state: WorkTimerState) {
		const now = WorkTimerUtils.getDateNowMilliseconds();

		state.data.isRunning = true;
		state.data.startDate = now;
		state.raiseDataChanged();

		state.volatileData.lastCheckedDate = now;
		state.raiseVolatileDataChanged();
	}

	private migrate() {
		this.migrateV1ToV2();
	}

	private migrateV1ToV2()
	{
		this.getTimerIds().forEach((id) => {
			let state = this.getState(id);
			state = WorkTimerUtils.ensureStateAllocated(state);
			if (!state.data.version) {
				state.data.version = version2;
				this.pauseState(id, state);
			}
		});
	}

	private static getDataStorageKey(id: string): string {
		if (id) {
			return `${WorkTimerStateManager.dataStorageKeyPrefix}${id}`;
		} else {
			return `${WorkTimerStateManager.dataStorageKeyPrefix}${WorkTimerStateManager.globalStorageKeySuffix}`;
		}
	}

	private static getVolatileDataStorageKey(id: string): string {
		if (id) {
			return `${WorkTimerStateManager.volatileDataStorageKeyPrefix}${id}`;
		} else {
			return `${WorkTimerStateManager.volatileDataStorageKeyPrefix}${WorkTimerStateManager.globalStorageKeySuffix}`;
		}
	}

	static readonly dataStorageKeyPrefix = 'mattero-wt-data-';
	static readonly volatileDataStorageKeyPrefix = 'mattero-wt-volatile-data-';
	static readonly globalStorageKeySuffix = 'global';
}
