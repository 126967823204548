import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { map, startWith, switchMap, tap } from 'rxjs/operators';

import { EntityReference } from '@common/models/Common/EntityReference';
import { CustomFieldEntityType } from '@common/models/Settings/CustomFields/Common/CustomFieldEntityType';
import { CustomFieldListItemDto } from '@common/models/Settings/CustomFields/List/CustomFieldListItemDto';
import { NotificationService } from '@common/notification';
import { ContactCustomFieldsService } from '@common/services/customfields-contact.service';
import { CustomFieldsGroupService } from '@common/services/customfields-group.service';
import { MatterCustomFieldsService } from '@common/services/customfields-matter.service';
import { cloneDeep, flatten, isEmpty, isNil, xor } from 'lodash-es';

@Component({
	selector: 'custom-field-selection',
	templateUrl: './custom-field-selection.component.html',
	styleUrls: ['./custom-field-selection.component.scss']
})
export class CustomFieldSelectionComponent implements OnInit, OnDestroy {
	// Properties
	private _subscriptions = new Subscription();
	private _selectedGroups: ICustomFieldGroupSelection[] = [];
	private _customFields: CustomFieldListItemDto[];
	private _customFieldGroups: IAvailableGroupsSummary[]; // build this onInit to use as a reference to to search for custom fields and determine if a group is fully selected
	private _practiceAreaIdsSubject: BehaviorSubject<string[]> = new BehaviorSubject(null);
	private _entityType: keyof typeof CustomFieldEntityType;
	fieldsPanelOpenState = false;
	groupsPanelOpenState = false;
	fieldSearchForm: FormGroup;
	fieldSearchOptions$: Observable<IAvailableGroupsSummary[]>; // filter
	currentGroupIdSelected: string;

	// Input/Output
	@Input()
	isStackedLayout: boolean = false;

	@Input()
	set entityType(value: CustomFieldEntityType) {
		this._entityType = value;
	}
	@Input()
	set practiceAreaIds(value: string[]) {
		this._practiceAreaIdsSubject.next(isNil(value) ? [] : value);
	}

	@Input() // Drives the ordering of groups and custom fields
	set SelectedGroups(value: ICustomFieldGroupSelection[]) {
		this._selectedGroups = cloneDeep(value);
	}

	@Output()
	SelectedGroupsChange = new EventEmitter<ICustomFieldGroupSelection[]>();

	// Getters
	get isDataLoaded() {
		return !!this._customFields && !!this._customFieldGroups;
	}
	get availableGroups() {
		return this._customFieldGroups;
	}
	get practiceAreaIds() {
		return this._practiceAreaIdsSubject.value;
	}
	get selectedGroups() {
		return this._selectedGroups;
	}
	get selectedGroupIds() {
		return this._selectedGroups?.map(x => x.customFieldGroupId) ?? [];
	}
	get unselectedGroupIds() {
		return this._customFieldGroups?.map(x => x.group.id)?.filter(x => !this.selectedGroupIds.includes(x)) ?? [];
	}
	get customFieldSearchControl(): AbstractControl {
		return this.fieldSearchForm?.get('customFieldSearch');
	}

	// Custom Field Getters
	get availableCustomFields() {
		return this._customFields;
	}
	get availableCustomFieldIds(): string[] {
		return this._customFields.map(x => x.id);
	}
	get unselectedCustomFieldIds(): string[] {
		return this.availableCustomFieldIds?.filter(id => !this.selectedCustomFieldIds.includes(id)) ?? [];
	}
	get selectedCustomFieldIds(): string[] {
		return flatten(this._selectedGroups?.map(x => x.customFieldIds)) ?? [];
	}

	constructor(
		private _fb: FormBuilder,
		private _notifService: NotificationService,
		private _matterCustomFieldsService: MatterCustomFieldsService,
		private _contactCustomFieldsService: ContactCustomFieldsService,
		private _customFieldsGroupService: CustomFieldsGroupService,
		private _cdr: ChangeDetectorRef
	) {}

	ngOnInit() {
		this.fieldSearchForm = this._fb.group({
			customFieldSearch: ''
		});

		if (this._entityType == 'Matter') {
			this._subscriptions.add(
				this._practiceAreaIdsSubject
					.pipe(switchMap(practiceAreaIds => this.refresh$(practiceAreaIds)))
					.subscribe({
						error: errors => this._notifService.showErrors('Error getting matter custom fields', errors)
					})
			);
		} else if (this._entityType == 'Contact') {
			this._subscriptions.add(
				this.refresh$().subscribe({
					error: errors => this._notifService.showErrors('Error getting contact custom fields', errors)
				})
			);
		}

		this.fieldSearchOptions$ = this.fieldSearchForm.get('customFieldSearch')!.valueChanges.pipe(
			startWith(''),
			map(value => this.filterSearchOptions(value || ''))
		);
	}

	ngOnDestroy(): void {
		this._subscriptions.unsubscribe();
	}

	displayGroupName(groupId: string) {
		if (!groupId) return null;

		const summary = this._customFieldGroups?.find(x => x.group.id == groupId);
		return summary?.group?.name;
	}

	displayCustomFieldName(customFieldId: string) {
		if (!customFieldId) return null;

		const item = this.availableCustomFields.find(x => x.id == customFieldId);
		return item?.description ?? item?.name;
	}

	groupCheckboxState(groupId: string): 'FULL' | 'PARTIAL' | 'NONE' {
		if (!groupId) return 'NONE';

		const selectedGroup = this.selectedGroups.find(x => x.customFieldGroupId == groupId);

		if (!selectedGroup) return 'NONE';

		const referenceGroup = this.availableGroups.find(x => x.group.id == groupId);

		if (
			this.areArraySetsEqual(
				selectedGroup.customFieldIds,
				referenceGroup.customFields.map(x => x.id)
			)
		) {
			return 'FULL';
		} else {
			return 'PARTIAL';
		}
	}

	onGroupClick(groupId: string) {
		if (this.currentGroupIdSelected !== groupId) {
			this.currentGroupIdSelected = groupId;

			// Scroll to the top of the group in the customFields list
			const itemToScrollTo = document.getElementById(groupId);
			if (itemToScrollTo) {
				itemToScrollTo.scrollIntoView(true);
			}
		} else {
			this.currentGroupIdSelected = null;
		}
	}

	toggleGroupById(groupId: string) {
		if (!groupId) return;

		const itemIndex = this._selectedGroups.findIndex(x => x.customFieldGroupId == groupId);
		const availableGroupSummary = this.availableGroups.find(x => x.group.id == groupId);

		if (itemIndex > -1) {
			var selectedGroup = this._selectedGroups[itemIndex];
			if (this.summaryGroupEqualsEntityGroup(availableGroupSummary, selectedGroup)) {
				this._selectedGroups.splice(itemIndex, 1);
			} else {
				this._selectedGroups.splice(itemIndex, 1, this.mapSummaryToEntity(availableGroupSummary));
			}
		} else {
			this._selectedGroups.push({
				customFieldGroupId: availableGroupSummary.group.id,
				customFieldIds: availableGroupSummary.customFields.map(x => x.id)
			});
		}

		this.currentGroupIdSelected = null;
		this.emitChanges();
	}

	/**
	 * Checks to see if the values of both arrays, as distinct sets, are equal.
	 * Ignores order and duplicates
	 */
	private areArraySetsEqual(left: string[], right: string[]): boolean {
		return isEmpty(xor(left, right));
	}

	private summaryGroupEqualsEntityGroup(left: IAvailableGroupsSummary, right: ICustomFieldGroupSelection) {
		return (
			left.group.id == right.customFieldGroupId &&
			this.areArraySetsEqual(
				left.customFields.map(x => x.id),
				right.customFieldIds
			)
		);
	}

	private mapSummaryToEntity(summary: IAvailableGroupsSummary): ICustomFieldGroupSelection {
		return {
			customFieldGroupId: summary.group.id,
			customFieldIds: summary.customFields.map(x => x.id)
		};
	}

	toggleCustomField(groupId: string, customFieldId: string, isAddingOnly: boolean = false) {
		if (!customFieldId) return;

		const groupIndex = this._selectedGroups.findIndex(x => x.customFieldGroupId == groupId);
		if (groupIndex < 0) {
			this._selectedGroups.push({ customFieldGroupId: groupId, customFieldIds: [customFieldId] });
		} else {
			const selectedGroup = this._selectedGroups[groupIndex];

			const fieldIndex = selectedGroup.customFieldIds.findIndex(x => x == customFieldId);
			if (fieldIndex < 0) {
				this.selectedGroups[groupIndex].customFieldIds.push(customFieldId);
			} else if (!isAddingOnly) {
				selectedGroup.customFieldIds.splice(fieldIndex, 1);

				if (selectedGroup.customFieldIds.length == 0) {
					this._selectedGroups.splice(groupIndex, 1);
				}
			}
		}
		this.emitChanges();
	}

	private emitChanges() {
		this._cdr.detectChanges();
		this.SelectedGroupsChange.emit(this._selectedGroups);
	}

	private refresh$(practiceAreaIds: string[] = []) {
		const customFields$ =
			this._entityType === 'Matter'
				? this._matterCustomFieldsService.getMatterCustomFieldList({
						excludeFieldType: ['Calculated'],
						enabled: true,
						practiceAreaIds: practiceAreaIds,
						includeCustomFieldIds: this.selectedCustomFieldIds
				  })
				: this._contactCustomFieldsService.getContactCustomFieldList({
						excludeFieldType: ['Calculated'],
						enabled: true,
						includeCustomFieldIds: this.selectedCustomFieldIds
				  });

		const customFieldGroups$ = this._customFieldsGroupService.getCustomFieldGroupList({
			entityType: this._entityType,
			enabled: true,
			includeCustomFieldIds: this.selectedCustomFieldIds,
			includeCustomFieldGroupIds: this._selectedGroups?.map(x => x.customFieldGroupId) ?? [],
			practiceAreaIds: practiceAreaIds
		});

		return combineLatest([customFields$, customFieldGroups$]).pipe(
			tap(([customFields, customFieldGroups]) => {
				// Initial data setup here
				this._customFields = (customFields.records as CustomFieldListItemDto[]).filter(
					x =>
						x.fieldType !== 'Calculated' ||
						(x.fieldType === 'Calculated' && this.selectedCustomFieldIds.includes(x.id))
				);
				this._customFieldGroups = customFieldGroups?.records?.map(grp => ({
					group: { id: grp.id, name: grp.name },
					customFields: grp.customFieldIds.reduce((accu: EntityReference[], curr: string) => {
						const name = this.displayCustomFieldName(curr);
						if (!!name) {
							accu = [...accu, ...[{ id: curr, name }]];
						}

						return accu;
					}, [])
				}));

				this.currentGroupIdSelected = null;
			})
		);
	}

	dropGroup(event: CdkDragDrop<string[]>) {
		if (event.previousIndex === event.currentIndex) return;
		if (event.previousContainer === event.container) {
			moveItemInArray(this._selectedGroups, event.previousIndex, event.currentIndex);

			this.emitChanges();
		}
	}

	dropField(event: CdkDragDrop<string[]>, groupId: string) {
		if (event.previousIndex === event.currentIndex) return;
		if (event.previousContainer === event.container) {
			const group = this._selectedGroups.find(x => x.customFieldGroupId == groupId);
			if (!group) return;

			moveItemInArray(group.customFieldIds, event.previousIndex, event.currentIndex);

			this.emitChanges();
		}
	}

	autocompleteSelected(event: MatAutocompleteSelectedEvent): void {
		const value: { group: IAvailableGroupsSummary; customField: EntityReference } = event.option.value;

		this.customFieldSearchControl?.setValue('', { emitEvent: false });

		this.toggleCustomField(value.group.group.id, value.customField.id, true);
		this.emitChanges();
	}

	private filterSearchOptions(value: string): IAvailableGroupsSummary[] {
		if (value) {
			return this._customFieldGroups
				.map(opt => ({ group: opt.group, customFields: _filter(opt.customFields, value) }))
				.filter(opt => opt.customFields.length > 0);
		}

		return this._customFieldGroups;
	}
}

export interface ICustomFieldGroupSelection {
	customFieldIds: string[];
	customFieldGroupId: string;
}

interface IAvailableGroupsSummary {
	group: EntityReference;
	customFields: EntityReference[];
}

const _filter = (opt: EntityReference[], value: string): EntityReference[] => {
	const filterValue = typeof value === 'string' ? value?.toLowerCase() ?? '' : '';

	return opt.filter(item => item.name.toLowerCase().includes(filterValue));
};
