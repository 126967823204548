import { Component, Input } from '@angular/core';

import { RecurringInvoiceDto } from '@common/models/Infrastructure/StripeIntegration/Models/RecurringInvoiceDto';
import { UpcomingInvoiceDto } from '@common/models/Infrastructure/StripeIntegration/Models/UpcomingInvoiceDto';

@Component({
	selector: 'upcoming-invoice-breakdown',
	templateUrl: './upcoming-invoice-breakdown.component.html',
	styleUrls: ['./upcoming-invoice-breakdown.component.scss']
})
export class UpcomingInvoiceBreakdownComponent {
	constructor() {}

	@Input()
	upcomingInvoice: UpcomingInvoiceDto;

	@Input()
	recurringInvoice: RecurringInvoiceDto;

	@Input()
	interval: string;

	panelOpenState = false;
}
