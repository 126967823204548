<mat-form-field [style.font-size]="FontSize">
	<mat-placeholder>
		{{ Placeholder }}
		<sup class="color-warn" *ngIf="Required">*</sup>
	</mat-placeholder>
	<mat-hint [innerHtml]="Hint"></mat-hint>

	<div class="input-clear-container">
		<input
			matInput
			#autoCompleteInput
			[matAutocomplete]="auto"
			[formControl]="inputDisplayCtrl"
			[value]="inputDisplayCtrl.value"
			throttleButton
			(throttledClick)="inputClicked()"
			(change)="inputChanged($event)"
			[autofocus]="HasAutofocus"
			(keyup.esc)="escPressed()"
			(keydown.tab)="tabPressed()"
			(focus)="inputFocused($event)"
			[attr.disabled]="!!disabled ? true : null"
			autocomplete="off"
		/>

		<div class="button-container">
			<button mat-icon-button throttleButton (throttledClick)="clearInput()" [disabled]="!!disabled">
				<mat-icon>clear</mat-icon>
			</button>
		</div>
	</div>

	<mat-error>
		<error-messages [for]="FormControl"></error-messages>
	</mat-error>

	<mat-autocomplete class="lookup-results" #auto="matAutocomplete" (optionSelected)="autocompleteSelected($event)">
		<mat-optgroup *ngFor="let group of filteredGroups" [label]="group.name">
			<mat-option class="option" *ngFor="let item of group.items" [value]="item.id">{{ item.display }}</mat-option>
		</mat-optgroup>
		<mat-option class="option" [disabled]="true" *ngIf="inputDisplayCtrl.value && options?.length == 0">
			<div *ngIf="isSearching" class="searching">Searching...</div>
			<div *ngIf="noLookupResults && inputDisplayCtrl.value && options?.length == 0" class="no-matches">No matches found</div>
		</mat-option>
	</mat-autocomplete>
</mat-form-field>
