<div
	class="drop-container"
	[ngClass]="dragOver ? 'drag-border' : 'no-drag-border'"
	[fileDrop]="true"
	(filesDragged)="onDragEvent($event)"
	(filesDropped)="onFilesDropped($event)"
>
	<div class="import-container">
		<div class="download-template">
			<button throttleButton (throttledClick)="downloadTemplate()" mat-button color="primary" title="Download Template">
				<mat-icon class="create-new">cloud_download</mat-icon>
				<span>Download Template</span>
			</button>
		</div>
		<div class="file-browser">
			<div *ngIf="!showProgress" class="file-browse-container">
				<div class="img-upload"><mat-icon class="material-icons-outlined">cloud_upload</mat-icon></div>
				<div>
					Drop files here to run validation or <label for="file_input">browse</label
					><input type="file" id="file_input" (change)="onFileBrowsed($event)" />
				</div>
			</div>
			<div *ngIf="showProgress">
				<span>Running validation on uploaded file, please wait...</span><br />
				<mat-progress-bar [value]="progressPercentage"></mat-progress-bar>
			</div>
			<div *ngIf="error.message" class="upload-error">
				<mat-icon>cancel</mat-icon><span class="err-text-container" [innerHTML]="error.message"></span>
			</div>
		</div>
		<div class="spacer-right"><span>&nbsp;&nbsp;&nbsp;</span></div>
	</div>
	<div>
		<mat-table [dataSource]="datasource" matSort matSortActive="modifiedDate" matSortDirection="desc">
			<ng-container matColumnDef="modifiedDate">
				<mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
				<mat-cell *matCellDef="let row">
					{{ row.modifiedDate | moment : 'dd/MM/yyyy HH:mm:ss' }}
				</mat-cell>
			</ng-container>
			<ng-container matColumnDef="fileName">
				<mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
				<mat-cell *matCellDef="let row">
					{{ row.fileName }}
				</mat-cell>
			</ng-container>
			<ng-container matColumnDef="totalEntityCount">
				<mat-header-cell *matHeaderCellDef mat-sort-header>Total Records Detected</mat-header-cell>
				<mat-cell *matCellDef="let row">
					{{ row.totalEntityCount }}
				</mat-cell>
			</ng-container>
			<ng-container matColumnDef="invalidEntityCount">
				<mat-header-cell *matHeaderCellDef mat-sort-header>Invalid Records</mat-header-cell>
				<mat-cell *matCellDef="let row" [ngClass]="{ invalid: row.invalidEntityCount > 0 }">
					{{ row.invalidEntityCount }}
				</mat-cell>
			</ng-container>
			<ng-container matColumnDef="modifiedBy">
				<mat-header-cell *matHeaderCellDef mat-sort-header>User</mat-header-cell>
				<mat-cell *matCellDef="let row">
					{{ row.modifiedBy?.name }}
				</mat-cell>
			</ng-container>
			<ng-container matColumnDef="importStatus">
				<mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
				<mat-cell *matCellDef="let row" [ngClass]="{ invalid: row.importStatus === 'Invalid' }">
					{{ row.importStatus }}
				</mat-cell>
			</ng-container>
			<ng-container matColumnDef="actions">
				<mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
				<mat-cell *matCellDef="let row">
					<button
						*ngIf="canSubmit(row); else elseBlock"
						mat-raised-button
						color="primary"
						throttleButton
						(throttledClick)="submit(row.id)"
						[disabled]="disableSubmit"
					>
						<span>Submit</span>
					</button>
					<ng-template #elseBlock>
						<a
							*ngIf="row.importStatus === 'Invalid' || row.importStatus === 'Errored'"
							[routerLink]=""
							throttleButton
							(throttledClick)="downloadErrors(row)"
							>View Errors</a
						>
					</ng-template>
				</mat-cell>
			</ng-container>

			<ng-container matColumnDef="moreActions">
				<mat-header-cell *matHeaderCellDef></mat-header-cell>
				<mat-cell *matCellDef="let row">
					<button mat-icon-button [matMenuTriggerFor]="menu" title="Actions">
						<mat-icon>more_vert</mat-icon>
					</button>
					<mat-menu #menu="matMenu">
						<button mat-menu-item throttleButton (throttledClick)="download(row.id)">
							<mat-icon>cloud_download</mat-icon>
							<span>Download</span>
						</button>
						<button mat-menu-item throttleButton (throttledClick)="deleteImport(row.id)">
							<mat-icon>delete</mat-icon>
							<span>Delete</span>
						</button>
					</mat-menu>
				</mat-cell>
			</ng-container>

			<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
			<mat-row
				*matRowDef="let row; columns: displayedColumns"
				throttleButton
				(throttledClick)="selectRow(row, $event)"
				[ngClass]="{ highlight: shouldHighlight(row.id) }"
			></mat-row>
		</mat-table>
		<mat-paginator
			[length]="datasource?.response?.totalRecords"
			[pageIndex]="datasource?.response?.pageIndex"
			[pageSize]="pageSize"
			[pageSizeOptions]="pageSizeOptions"
		></mat-paginator>
	</div>
</div>
