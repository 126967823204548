// This file has been generated from PracticeAreaViewDto.cs
// Do not modify this file directly!!
// tslint:disable

import { SetComplexType, SetSimpleType } from '@common/utils/class-transform';

import { StageDto } from './StageDto';

// LawMaster.Amalthea.Domain.Settings.PracticeAreas.Item.PracticeAreaViewDto
export class PracticeAreaViewDto {

	// Stages
	@SetComplexType(StageDto)
	stages: StageDto[];
	// Name
	name: string;
	// Description
	description: string;
	// Contributors
	contributors: string[];
	// Readers
	readers: string[];
	// Id
	id: string;
	// IsMatterTimerEnabled
	@SetSimpleType(Boolean)
	isMatterTimerEnabled: boolean;
	// InstallationRecordIds
	installationRecordIds: string[];
	// UniqueIdentifier
	uniqueIdentifier: string;
	// IsDisabled
	@SetSimpleType(Boolean)
	isDisabled: boolean;

	constructor() {
		this.stages = [];
	}
}