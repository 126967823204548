import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';

import { filter } from 'rxjs/operators';

import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { get } from 'lodash-es';

import { AppConfig } from 'app/app.config';

@Injectable({
	providedIn: 'root'
})
export class ApplicationInsightsService {
	private appInsights = new ApplicationInsights({
		config: {
			instrumentationKey: AppConfig.InstrumentationKey
		}
	});

	constructor(private router: Router, private dialogs: MatDialog) {
		if (!AppConfig.InstrumentationKey) {
			// Only initialise application insights if we have an instrumentation key
			throw new Error('Dev Error: Instrumentation key does not exist for App Insights');
		}
		this.appInsights.loadAppInsights();
		this.init();
	}

	init(): void {
		// Initialise application insights

		// Track page views
		this.router.events
			.pipe(filter(event => event instanceof NavigationStart))
			.subscribe((event: NavigationStart) => this.startTrackPage(event.url));
		this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe((event: NavigationEnd) => this.stopTrackPage(event.url));
		this.router.events
			.pipe(filter(event => event instanceof NavigationCancel))
			.subscribe((event: NavigationCancel) => this.stopTrackPage(event.url));

		// Track dialog components as page views
		this.dialogs.afterOpened.subscribe((dialog: MatDialogRef<any>) => {
			this.trackPageView(
				dialog.componentInstance.constructor.name,
				null,
				this.getDialogConfig(dialog.componentInstance),
				null
			);
		});
	}

	trackException(
		exception: Error,
		properties?: { [name: string]: string },
		measurements?: { [name: string]: number },
		severityLevel?: SeverityLevel
	) {
		this.appInsights.trackException({ exception, properties, measurements, severityLevel });
	}
	trackMetric(
		name: string,
		average: number,
		sampleCount?: number,
		min?: number,
		max?: number,
		properties?: { [name: string]: string }
	) {
		this.appInsights.trackMetric({ name, average, sampleCount, min, max }, properties);
	}

	setAuthenticatedUserContext(authenticatedUserId: string, accountId?: string, storeInCookie?: boolean) {
		this.appInsights.setAuthenticatedUserContext(authenticatedUserId, accountId, storeInCookie);
	}
	clearAuthenticatedUserContext() {
		this.appInsights.clearAuthenticatedUserContext();
	}

	trackEvent(name: string, properties?: { [name: string]: string }, measurements?: { [name: string]: number }) {
		this.appInsights.trackEvent({ name, properties, measurements });
	}

	private getFunctionParameters(func: () => any): string[] {
		return new RegExp(func.name + '\\s*\\((.*?)\\)')
			.exec(func.toString().replace(/\n/g, ''))[1]
			.replace(/\/\*.*?\*\//g, '')
			.replace(/ /g, '')
			.split(',');
	}

	private getDialogConfig(component: any): any {
		// Find if there are any injection tokens on parameters in the constructor
		const parameters = get(component, 'constructor.__paramaters__');

		if (parameters == null) {
			return;
		}

		// Find which parameter has the MatDialogData injection token
		const configIndex = parameters.findIndex(
			(param: any) =>
				param instanceof Array && param.find(annotation => get(annotation, 'token._desc') === 'MatDialogData')
		);

		if (configIndex == null) {
			return;
		}

		// Get the names of the arguments for the constructor
		const constructorArguments = this.getFunctionParameters(component.constructor);
		// get the property off the component that matches the MatDialogData
		return get(component, constructorArguments[configIndex]);
	}

	private startTrackPage(name?: string) {
		this.appInsights.startTrackPage(name);
	}
	private stopTrackPage(
		name?: string,
		url?: string,
		properties?: { [name: string]: string },
		measurements?: { [name: string]: number }
	) {
		this.appInsights.stopTrackPage(name, url, properties, measurements);
	}
	private trackPageView(
		name?: string,
		uri?: string,
		properties?: { [name: string]: string },
		measurements?: { [name: string]: number }
	) {
		this.appInsights.trackPageView({ name, uri, properties, measurements });
	}
}
