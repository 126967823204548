// This file has been generated from DocumentEmailTemplatesController.cs
// Do not modify this file directly!!
// tslint:disable

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GenericHttpService } from '@common/services/generic.http.service';
import { SettingsResolverService } from '@common/services/settings-resolver.service';
import { TransformDatesOnObject } from '@common/utils/date-format';
import { TrimStringsOnObject } from '@common/utils/string-format';

import { MutationResponseDto } from '../../models/Common/MutationResponseDto';
import { DocumentEmailTemplateAttachmentItemDto } from '../../models/Documents/Item/DocumentEmailTemplateAttachmentItemDto';
import { DocumentEmailTemplateCreateUpdateDto } from '../../models/Documents/Item/DocumentEmailTemplateCreateUpdateDto';
import { DocumentEmailTemplateViewDto } from '../../models/Documents/Item/DocumentEmailTemplateViewDto';
import { DocumentFromTemplateDto } from '../../models/Documents/Item/DocumentFromTemplateDto';
import { DocumentEmailTemplateListItemDto } from '../../models/Documents/List/DocumentEmailTemplateListItemDto';
import { DocumentListRequest } from '../../models/Documents/List/DocumentListRequest';
import { ListResponse } from '../../models/Generic/ListResponse';
import { IUploadMultipleFiles } from '@common/models/Common/IFileUploader';
import { IResultWithAttachments } from '@common/models/Common/IResultWithAttachments';
@Injectable({
	providedIn: 'root'
})
export class DocumentEmailTemplatesService extends GenericHttpService {
	constructor(httpClient: HttpClient, settings: SettingsResolverService) {
		super(httpClient, settings.getAppServerUrl(), 'api/v1/settings/email-templates', 'Document Email Template');
	}

	getDocumentEmailTemplate(id: string): Observable<DocumentEmailTemplateViewDto> {
		return this.getItem<void, DocumentEmailTemplateViewDto>(`${id}`);
	}

	getDocumentEmailTemplateList(request?: Partial<DocumentListRequest>): Observable<ListResponse<DocumentEmailTemplateListItemDto>> {
		return this.getList<DocumentListRequest, DocumentEmailTemplateListItemDto>('', request);
	}

	getAttachmentPreviews(dto: DocumentFromTemplateDto): Observable<DocumentEmailTemplateAttachmentItemDto[]> {
		return this.post<DocumentFromTemplateDto, DocumentEmailTemplateAttachmentItemDto[]>(`attachments`, TransformDatesOnObject(DocumentFromTemplateDto, TrimStringsOnObject(dto)));
	}

	create(dto: DocumentEmailTemplateCreateUpdateDto, filesToUpload: IUploadMultipleFiles): Observable<Partial<IResultWithAttachments>> {
		return this.createUpdateWithHttpProgress<DocumentEmailTemplateCreateUpdateDto>('', TransformDatesOnObject(DocumentEmailTemplateCreateUpdateDto, TrimStringsOnObject(dto)), filesToUpload, 'POST', true);
	}

	update(id: string, dto: DocumentEmailTemplateCreateUpdateDto, filesToUpload: IUploadMultipleFiles): Observable<Partial<IResultWithAttachments>> {
		return this.createUpdateWithHttpProgress<DocumentEmailTemplateCreateUpdateDto>(`${id}`, TransformDatesOnObject(DocumentEmailTemplateCreateUpdateDto, TrimStringsOnObject(dto)), filesToUpload, 'PUT', true);
	}

}
