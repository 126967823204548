<h2 mat-dialog-title class="title">Edit Media Type</h2>
<mat-dialog-content [formGroup]="form" class="flex-column">
	<div class="flex-column">
		<mat-form-field>
			<mat-placeholder>Name<sup class="color-warn">*</sup></mat-placeholder>
			<input matInput readonly="true" formControlName="name" />
			<mat-error>
				<error-messages [for]="form.get('name')"></error-messages>
			</mat-error>
		</mat-form-field>
		<editable-numeric-value
			placeHolder="Clearance Days"
			[control]="form.get('clearanceDateOffset')"
			[isCurrency]="false"
			[editMode]="true"
			[minValue]="0"
			[integersOnly]="true"
		></editable-numeric-value>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<div>
		<button mat-raised-button color="primary" [mat-dialog-close]="form.value" [disabled]="form.invalid">Save</button>
		<button mat-raised-button mat-dialog-close>Cancel</button>
	</div>
</mat-dialog-actions>
