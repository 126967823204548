import { CalculationVariableListItemDto } from '@common/models/Calculations/List/CalculationVariableListItemDto';
import { createSelector, MemoizedSelector } from '@ngrx/store';

import { IAppState } from '../../app.state';
import { ICalculationVariableListState } from './calculation-variable-list.state';

export const selectCalculationVariableList = (state: IAppState) => state.calculationVariableList;

export const selectMatterCalculationVariableRecords: MemoizedSelector<IAppState, CalculationVariableListItemDto[]> =
	createSelector(
		selectCalculationVariableList,
		(state: ICalculationVariableListState) => state.matterVariables?.list ?? []
	);

export const selectContactCalculationVariableRecords: MemoizedSelector<IAppState, CalculationVariableListItemDto[]> =
	createSelector(
		selectCalculationVariableList,
		(state: ICalculationVariableListState) => state.contactVariables?.list ?? []
	);
