// This file has been generated from BillCreateUpdateDto.cs
// Do not modify this file directly!!
// tslint:disable

import { Moment } from 'moment-timezone';
import { DateFormat, SetDateFormat } from '@common/utils/date-format';

import { BillStatus } from '../Common/BillStatus';

// LawMaster.Amalthea.Domain.Bills.Item.BillCreateUpdateDto
export class BillCreateUpdateDto {

	// Number
	number: string;
	// TemplateId
	templateId: string;
	// BillContactId
	billContactId: string;
	// CostRecordIds
	costRecordIds: string[];
	// TimeRecordAdjustments
	timeRecordAdjustments: { [key: string]: number; };
	// Status
	status: keyof typeof BillStatus;
	// PaymentDate
	@SetDateFormat(DateFormat.DateTime)
	paymentDate: Moment;
	// DueDate
	@SetDateFormat(DateFormat.LocalDate)
	dueDate: Moment;
	// IssueDate
	@SetDateFormat(DateFormat.LocalDate)
	issueDate: Moment;
	// TrustCreditAmount
	trustCreditAmount: number;
	// AmountPaid
	amountPaid: number;

	constructor() {

	}
}