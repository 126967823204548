// This file has been generated from MatterLawyerUpdateDto.cs
// Do not modify this file directly!!
// tslint:disable



// LawMaster.Amalthea.Domain.Matters.Item.MatterLawyerUpdateDto
export class MatterLawyerUpdateDto {

	// Ids
	ids: string[];
	// ContactId
	contactId: string;

	constructor() {

	}
}