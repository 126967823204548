import { Pipe, PipeTransform } from '@angular/core';

/*
 * Turn a comma separated string to a more readable ' - '
 */
@Pipe({ name: 'commaToHyphen' })
export class CommaToHyphenPipe implements PipeTransform {
	transform(key: string): string {
		return key?.replace(/,/g, ' - ');
	}
}
