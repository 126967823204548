import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { BehaviorSubject, Subscription } from 'rxjs';
import { delay, filter, startWith } from 'rxjs/operators';

import { EntityReference } from '@common/models/Common/EntityReference';

import { FilterBaseComponent } from './filter-base.component';
import { FilterButtonComponent } from './filter-button.component';
import { FilterChangeProperties } from './filter-change-properties';

@Component({
	selector: 'filter-entity-reference-list',
	styleUrls: ['./filter-base.component.scss'],
	template: `
		<filter-button
			[root]="root"
			[name]="name"
			[label]="label"
			[showTitleAsPrefix]="showTitleAsPrefix"
			[trimDisplayText]="trimDisplayText"
		>
			<mat-nav-list>
				<mat-list-item
					*ngFor="let item of items"
					[class.filter-selected]="selectedId == item.id"
					throttleButton
					(throttledClick)="applyFilter(item)"
				>
					{{ item.name }}
				</mat-list-item>
			</mat-nav-list>
			<div *ngIf="!hideClear" class="action-buttons">
				<span throttleButton (throttledClick)="applyFilter(null)">Clear</span>
			</div>
		</filter-button>
	`
})
export class FilterEntityReferenceListComponent extends FilterBaseComponent implements OnInit, OnDestroy {
	@ViewChild(FilterButtonComponent, { static: true })
	btn: FilterButtonComponent;
	@Input()
	get items(): EntityReference[] {
		return this._items.getValue();
	}
	set items(value: EntityReference[]) {
		this._items.next(value);
	}

	@Input()
	trimDisplayText: boolean;

	selectedId: string;

	protected subscriptions: Subscription = new Subscription();
	protected _items: BehaviorSubject<EntityReference[]> = new BehaviorSubject<EntityReference[]>(null);

	ngOnInit() {
		this.subscriptions.add(
			this.root.filterChange
				.pipe(
					startWith(new FilterChangeProperties<any>(this.root.filter)), // Fire the 1st event with the current value
					delay(0), // Otherwise get an error on changing the state after it's been checked
					filter(f => !!f.filter)
				)
				.subscribe(() => this.updateLabel())
		);

		// if we use the AsyncPipe for items, it doesn't set the label on page load for queryParams
		// so wait until it populates the data and then update the label
		this.subscriptions.add(this._items.subscribe(v => this.updateLabel()));
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	applyFilter(entity: EntityReference): void {
		this.btn.entitySelected(entity);
	}

	private updateLabel(): void {
		this.selectedId = String(this.root.form.get(this.name).value);
		const selectedItem = this.items ? this.items.find(p => p.id === this.selectedId) : null;
		this.applyFilter(selectedItem);
	}
}
