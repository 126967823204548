<h2 mat-dialog-title>
	<mat-icon>campaign</mat-icon>
	<span>Announcement</span>
</h2>
<mat-dialog-content>
	<div class="flex-column">
		<div [innerHtml]="termsMessage"></div>
		<!-- <div>
			Click on the following links to read the updated
			<a href="https://www.mattero.com.au/terms-and-conditions/" target="_blank">terms & conditions</a>
			and
			<a href="https://www.mattero.com.au/privacy-policy/" target="_blank">privacy policy</a>.
		</div> -->
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-raised-button throttleButton (throttledClick)="onCloseClick()">Close</button>
</mat-dialog-actions>
