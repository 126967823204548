import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { get, isNil, isString } from 'lodash-es';
import { combineLatest, Observable, of } from 'rxjs';
import { map, share } from 'rxjs/operators';

import { GenericHttpService } from '@common/services/generic.http.service';

import { SystemSettingsResolverService } from 'app/services/settings-resolver.service';

@Injectable()
export class EditableAddressService extends GenericHttpService {
	private countryDefaults: Observable<IAddressData>;

	constructor(httpClient: HttpClient, settingsResolver: SystemSettingsResolverService) {
		super(
			httpClient,
			settingsResolver.getAppServerUrl(),
			'https://chromium-i18n.appspot.com/ssl-address/data',
			'Address'
		);
	}

	getDefaults(): Observable<IAddressData> {
		if (!this.countryDefaults) {
			this.countryDefaults = this.getCountry('ZZ').pipe(share());
			this.countryDefaults.subscribe(user => {
				this.countryDefaults = of(user);
			});
		}
		return this.countryDefaults;
	}

	getCountry(countryCode: string): Observable<IAddressData> {
		const lang = countryCode === 'HK' ? '--en' : '';
		return combineLatest(
			countryCode === 'ZZ' ? of(null) : this.getDefaults(),
			isNil(countryCode) ? of(null) : this.getItem(`${countryCode}${lang}`)
		).pipe(
			map(values => Object.assign({}, ...values)),
			map(this.transformResult)
		);
	}

	getAdministrativeArea(countryCode: string, adminArea: string): Observable<IAddressData> {
		if (isNil(countryCode) || isNil(adminArea)) return of(null);
		const lang = countryCode === 'HK' ? '--en' : '';
		return this.getItem<void, IAddressData>(`${countryCode}/${adminArea}${lang}`).pipe(map(this.transformResult));
	}

	getLocality(countryCode: string, adminArea: string, locality: string): Observable<IAddressData> {
		if (isNil(countryCode) || isNil(adminArea) || isNil(locality)) return of(null);
		const lang = countryCode === 'HK' ? '--en' : '';
		return this.getItem<void, IAddressData>(`${countryCode}/${adminArea}/${locality}${lang}`).pipe(
			map(this.transformResult)
		);
	}

	getSubLocality(
		countryCode: string,
		adminArea: string,
		locality: string,
		subLocality: string
	): Observable<IAddressData> {
		if (isNil(countryCode) || isNil(adminArea) || isNil(locality) || isNil(subLocality)) return of(null);
		const lang = countryCode === 'HK' ? '--en' : '';
		return this.getItem<void, IAddressData>(`${countryCode}/${adminArea}/${locality}/${subLocality}${lang}`).pipe(
			map(this.transformResult)
		);
	}

	private transformResult = <T>(obj: T): T =>
		Object.keys(obj).reduce(
			(prev, curr) =>
				Object.assign(prev, {
					[curr]: curr.startsWith('sub_') ? this.splitValues(get(obj, curr, '')) : get(obj, curr, '')
				}),
			{}
		) as T;
	private splitValues = (value: string): string | string[] =>
		isString(value) && value.indexOf('~') >= 0 ? value.split('~') : value;
}

export interface ICountries {
	id: string;
	countries: string[];
}

export interface IAddressData {
	fmt?: string;
	id: string;
	isoid?: string;
	key?: string;
	lang?: string;
	languages?: string;
	lfname?: string; // latinised full name
	lname?: string; // latinised name
	locality_name_type?: string;
	name?: string;
	posturl?: string;
	require?: string;
	state_name_type?: string;
	sub_isoids?: string[];
	sub_keys?: string[]; // denotes the existence of sub-region data
	sub_lnames?: string[]; // sub-region latinised names
	sub_mores?: string[];
	sub_names?: string[]; // sub-region names
	sub_zipexs?: string[];
	sub_zips?: string[];
	sublocality_name_type?: string;
	upper?: string;
	zip_name_type?: string;
	zip?: string;
	zipex?: string;
}

export enum AddressField {
	Name = 'N',
	Organisation = 'O',
	Address = 'A',
	SubLocality = 'D', // district/suburb
	Locality = 'C',
	AdministrativeArea = 'S', // state/province
	ZipCode = 'Z',
	SortingCode = 'X'
}
