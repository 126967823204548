<multi-file-attachment
	[maxNumberOfFiles]="3"
	[isEditable]="true"
	[showProgress]="false"
	[existingDocuments]="existingDocuments"
	(errorMessageUpdated)="onErrorMessageUpdated($event)"
	(documentIdsToRemoveUpdated)="onDocumentIdsToRemoveUpdated($event)"
	(uploadFilesInfoUpdated)="onUploadFilesInfoUpdated($event)"
>
	<div class="flex-column" [formGroup]="form">
		<div class="flex-row">
			<mat-form-field>
				<mat-placeholder
					>Trust Account
					<sup class="color-warn">*</sup>
				</mat-placeholder>
				<mat-select formControlName="trustAccountId">
					<mat-option
						(onSelectionChange)="trustAccountChanged($event, trustAccount)"
						*ngFor="let trustAccount of trustAccounts"
						[value]="trustAccount.id"
						>{{ trustAccount.bankDetails?.accountName }}</mat-option
					>
				</mat-select>
				<mat-hint *ngIf="!isReadOnly && currentTrustAccount?.currentBalance !== undefined"
					>Current Balance: {{ currentTrustAccount?.currentBalance | currency: '$' }}
				</mat-hint>
			</mat-form-field>
			<mat-form-field class="small-form-field">
				<mat-placeholder>Date of Transfer<sup class="color-warn">*</sup> </mat-placeholder>
				<input formControlName="date" matInput [matDatepicker]="datePicker" autocomplete="off" />
				<mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
				<mat-datepicker #datePicker></mat-datepicker>
				<mat-error>
					<error-messages [for]="form.get('date')"></error-messages>
				</mat-error>
			</mat-form-field>
		</div>
		<div class="flex-row">
			<matter-lookup
				#matterLookupFrom
				class="related-to-entity"
				(Selected)="onMatterFromSelected()"
				Placeholder="From Matter"
				[Required]="true"
				[Status]="'Open'"
				[FormControl]="form.get('matterFromId')"
				[Hint]="isReadOnly ? '' : hintFromText"
			></matter-lookup>
			<matter-lookup
				#matterLookupTo
				class="related-to-entity"
				(Selected)="onMatterToSelected()"
				Placeholder="To Matter"
				[Required]="true"
				[Status]="'Open'"
				[FormControl]="form.get('matterToId')"
				[Hint]="isReadOnly ? '' : hintToText"
			></matter-lookup>
		</div>
		<div class="flex-row">
			<editable-numeric-value
				#amount
				placeHolder="Amount"
				tooltip="Total Amount on the receipt"
				[control]="form.get('amount')"
				[isCurrency]="true"
				[editMode]="true"
			></editable-numeric-value>
			<contact-lookup
				[Placeholder]="
					transactionType === transactionTypeEnum.Receipt
						? 'Received From'
						: transactionType === transactionTypeEnum.Payment
						? 'Paid To'
						: 'Authorised By'
				"
				[AllowCreate]="false"
				[StaffOnly]="true"
				[FormControl]="form.get('relevantContactId')"
				[Hint]="isReadOnly ? '' : 'Search for a contact'"
				[Required]="true"
			></contact-lookup>
		</div>

		<div class="flex-row">
			<mat-form-field>
				<input formControlName="description" matInput autocomplete="off" />
				<mat-placeholder
					>Description
					<sup class="color-warn">*</sup>
				</mat-placeholder>
				<mat-hint *ngIf="!isReadOnly">Reason for transfer</mat-hint>
				<mat-error>
					<error-messages [for]="form.get('description')"></error-messages>
				</mat-error>
			</mat-form-field>
		</div>
		<div *ngFor="let error of errors">
			<span class="error">{{ error }}</span>
		</div>
		<div class="upload-error" *ngIf="error.message">
			<mat-icon>cancel</mat-icon>&nbsp;<span class="err-text-container" [innerHTML]="error.message"></span>
		</div>
	</div>
</multi-file-attachment>

<mat-slide-toggle [(ngModel)]="downloadAfterSave" *ngIf="!editId">Download authorisation document on save</mat-slide-toggle>
