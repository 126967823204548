import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { ApiKeyListItemDto } from '@common/models/Settings/ApiKeys/List/ApiKeyListItemDto';
import { NotificationService } from '@common/notification';
import { ApiKeysService } from '@common/services/settings/apikeys.service';

import { AppConfig } from 'app/app.config';
import { GridViewService } from 'app/services/grid-view.service';
import { GenericListComponent } from 'app/shared/generics/generic.list.component';

import { ApiKeyDialogComponent } from './api-key-dialog.component';

@Component({
	selector: 'api-keys',
	styleUrls: ['./api-keys.component.scss'],
	templateUrl: './api-keys.component.html'
})
export class ApiKeysComponent extends GenericListComponent<ApiKeyListItemDto, void> {
	apiServerUrl: string = AppConfig.ApiDocServerUrl;

	constructor(
		dialog: MatDialog,
		router: Router,
		activatedRoute: ActivatedRoute,
		private apiKeysService: ApiKeysService,
		private notifService: NotificationService,
		gridViewService: GridViewService
	) {
		super(
			dialog,
			router,
			activatedRoute,
			apiKeysService,
			['description', 'status', 'user_Name', 'creation', 'expiry', 'action'],
			gridViewService
		);
	}

	onCreateButtonClick(): void {
		this.subscriptions.add(
			this.dialog
				.open(ApiKeyDialogComponent, { width: '600px' })
				.afterClosed()
				.subscribe(
					() => this.refreshList(),
					errs => this.notifService.showErrors(`Error creating API Key`, errs)
				)
		);
	}

	onEditButtonClick(row: ApiKeyListItemDto): void {
		this.subscriptions.add(
			this.dialog
				.open(ApiKeyDialogComponent, {
					data: {
						id: row.id,
						description: row.description,
						isEdit: true
					},
					width: '600px'
				})
				.afterClosed()
				.subscribe(
					() => this.refreshList(),
					errs => this.notifService.showErrors(`Error creating API Key`, errs)
				)
		);
	}

	onRevokeButtonClick(row: ApiKeyListItemDto): void {
		this.subscriptions.add(
			this.apiKeysService.revokeApiKey(row.id).subscribe(
				() => {
					this.notifService.showNotification(`API Key for '${row.user.name}' was revoked`);
					this.refreshList();
				},
				errs => this.notifService.showErrors(`Error revoking API Key`, errs)
			)
		);
	}
}
