import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

import { ContactReference } from '@common/models/Contacts/Common/ContactReference';
import { SecurityRoleViewDto } from '@common/models/Settings/SecurityRoles/Item/SecurityRoleViewDto';
import { UserLookupDto } from '@common/models/Users/Lookup/UserLookupDto';
import * as moment from 'moment-timezone';
import { Moment } from 'moment-timezone';

import { UserLookupComponent } from 'app/shared/components/user-lookup.component';

@Component({
	styleUrls: ['./security-role-members.component.scss'],
	templateUrl: './security-role-members.component.html'
})
export class SecurityRoleMembersComponent implements AfterViewInit {
	@ViewChild('addMemberInput')
	addMemberInput: UserLookupComponent;
	dataSource: MatTableDataSource<ContactReference>;
	dirty: boolean = false;
	addMemberFormControl: FormControl = new FormControl();

	constructor(@Inject(MAT_DIALOG_DATA) data: SecurityRoleViewDto) {
		this.dataSource = new MatTableDataSource(data?.members ?? []);
	}

	ngAfterViewInit() {
		if (this.addMemberInput) {
			setTimeout(() => this.addMemberInput.autoCompleteInput?.nativeElement?.focus(), 0);
		}
	}

	remove(row: ContactReference) {
		if (!row) {
			return;
		}
		this.dataSource.data = this.dataSource.data.filter(x => x.id !== row.id);
		this.dirty = true;
	}

	addUser() {
		const selected: UserLookupDto = this.addMemberInput?.selectedValue;
		if (
			!selected ||
			this.dataSource.data.find(x => x.id === selected.contactId) ||
			this.isNotActivated(selected.activationDate)
		) {
			return;
		}
		this.dataSource.data = this.dataSource.data.concat([
			{
				id: selected.contactId,
				name: selected.contactName,
				fullName: selected.contactName
			}
		]);
		this.dirty = true;
		this.addMemberInput.inputDisplayCtrl?.reset();
	}

	canAdd() {
		const current: UserLookupDto = this.addMemberInput?.selectedValue;
		return current && !this.isNotActivated(current.activationDate);
	}

	isNotActivated(activationDate: Moment) {
		return activationDate && moment(activationDate) < moment('2000-01-01');
	}
}
