// This file has been generated from TrustRecordMediaDetails.cs
// Do not modify this file directly!!
// tslint:disable

import { Moment } from 'moment-timezone';
import { DateFormat, SetDateFormat } from '@common/utils/date-format';

import { TrustMediaType } from '../../Settings/Setting/Item/TrustMediaType';

// LawMaster.Amalthea.Domain.Trust.Common.TrustRecordMediaDetails
export class TrustRecordMediaDetails {

	// MediaType
	mediaType: keyof typeof TrustMediaType;
	// ClearanceDate
	@SetDateFormat(DateFormat.LocalDate)
	clearanceDate: Moment;
	// ChequeDrawer
	chequeDrawer: string;
	// ChequeBank
	chequeBank: string;
	// PaymentEFTAccountName
	paymentEFTAccountName: string;
	// PaymentEFTBankAccountNumber
	paymentEFTBankAccountNumber: string;
	// PaymentEFTBankReference
	paymentEFTBankReference: string;

	constructor() {

	}
}