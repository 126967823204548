import { CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { AbnFormatterPipe } from './abn-formatter.pipe';
import { AcnFormatterPipe } from './acn-formatter.pipe';
import { BasicWorkflowTypePipe } from './basic-workflow-type.pipe';
import { CommaToHyphenPipe } from './comma-to-hyphen';
import { CostingMethodPipe } from './costing-method.pipe';
import { DocumentTypePipe } from './document-type.pipe';
import { FeatureTypePipe } from './feature-type.pipe';
import { LongTextEllipsisPipe } from './long-text-ellipsis.pipe';
import { Nl2BrPipe } from './newline-to-br';
import { PagePositionPipe } from './page-position.pipe';
import { PracticeTypePipe } from './practice-type.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { TaskStatusPipe } from './task-status.pipe';
import { TaskTemplateAssignedToContactTypePipe } from './task-template-assigned-to-contact-type.pipe';
import { TaskTemplateDueDateOffsetTypePipe } from './task-template-due-date-offset-type.pipe';
import { TaskTemplateDueDateSourcePipe } from './task-template-due-date-source.pipe';
import { TaskTypePipe } from './task-type.pipe';
import { TaxConfigurationPipe } from './tax-configuration.pipe';
import { TemplateEntityTypePipe } from './template-entity-type.pipe';
import { TransactionAmountPipe } from './transaction-amount.pipe';

@NgModule({
	declarations: [
		BasicWorkflowTypePipe,
		CommaToHyphenPipe,
		CostingMethodPipe,
		DocumentTypePipe,
		FeatureTypePipe,
		LongTextEllipsisPipe,
		Nl2BrPipe,
		PagePositionPipe,
		PracticeTypePipe,
		SafeHtmlPipe,
		TaskStatusPipe,
		TaskTemplateAssignedToContactTypePipe,
		TaskTemplateDueDateOffsetTypePipe,
		TaskTemplateDueDateSourcePipe,
		TaskTypePipe,
		TaxConfigurationPipe,
		TemplateEntityTypePipe,
		TransactionAmountPipe
	],
	exports: [
		BasicWorkflowTypePipe,
		CommaToHyphenPipe,
		CostingMethodPipe,
		DocumentTypePipe,
		FeatureTypePipe,
		LongTextEllipsisPipe,
		Nl2BrPipe,
		PagePositionPipe,
		PracticeTypePipe,
		SafeHtmlPipe,
		TaskStatusPipe,
		TaskTemplateAssignedToContactTypePipe,
		TaskTemplateDueDateOffsetTypePipe,
		TaskTemplateDueDateSourcePipe,
		TaskTypePipe,
		TaxConfigurationPipe,
		TemplateEntityTypePipe,
		TransactionAmountPipe
	],
	providers: [AbnFormatterPipe, AcnFormatterPipe, CurrencyPipe]
})
export class PipesModule {}
