import { createFeatureSelector, createSelector } from '@ngrx/store';
import { get } from 'lodash-es';
import * as moment from 'moment-timezone';

import {
	ClearTrustAccountsType,
	SetTrustAccountsType,
	TrustAccountsActions
} from '../actions/trust-accounts-data.actions';
import { ITrustAccountsData } from '../models/trust-accounts-data';

export const initialState: ITrustAccountsData = {
	trustAccounts: null,
	expiry: moment()
};

export function trustAccountsReducer(state = initialState, action: TrustAccountsActions): ITrustAccountsData {
	switch (action.type) {
		case SetTrustAccountsType:
			return get(action, 'data', initialState);
		case ClearTrustAccountsType:
			return initialState;
		default:
			return state;
	}
}

export const getTrustAccountsState = createFeatureSelector<ITrustAccountsData>('trustAccountsData');

export const getTrustAccounts = createSelector(getTrustAccountsState, (state: ITrustAccountsData) => state);
