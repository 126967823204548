import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { BehaviorSubject, combineLatest, map, Observable, of, tap } from 'rxjs';

import { BaseLookupComponent } from '@common/components/lookups/base-lookup.component';
import { CalculationVariableListItemDto } from '@common/models/Calculations/List/CalculationVariableListItemDto';
import { BaseDtoWithTrimmedId } from '@common/models/Common/BaseDtoWithTrimmedId';
import { CustomFieldEntityType } from '@common/models/Settings/CustomFields/Common/CustomFieldEntityType';
import { CustomFieldType } from '@common/models/Settings/CustomFields/Common/CustomFieldType';
import { arraysEqual } from '@common/utils/arrayUtils';
import { Store } from '@ngrx/store';
import { isNil } from 'lodash-es';

import { load } from 'app/core/state/lists/calculation-variable-list/calculation-variable-list.actions';
import {
	selectContactCalculationVariableRecords,
	selectMatterCalculationVariableRecords
} from 'app/core/state/lists/calculation-variable-list/calculation-variable-list.selector';
import { IAppState } from 'app/core/state/app.state';

@Component({
	selector: 'calculation-variable-lookup',
	styleUrls: ['../../../../../common/components/lookups/base-lookup.component.scss'],
	templateUrl: '../../../../../common/components/lookups/base-lookup.component.html'
})
export class CalculationVariableLookupComponent
	extends BaseLookupComponent<CalculationVariableListItemDtoEx>
	implements OnInit
{
	@ViewChild('autoCompleteInput', { static: true })
	inputCtrl: ElementRef;

	private _entityType: keyof typeof CustomFieldEntityType;

	@Input()
	set entityType(value: keyof typeof CustomFieldEntityType) {
		this._entityType = value;
	}

	private _oldVariables: CalculationVariableListItemDto[];
	private _variables$ = new BehaviorSubject<CalculationVariableListItemDtoEx[]>(null);

	constructor(private _store: Store<IAppState>) {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();

		if (!this._entityType) {
			throw new Error('Must have an entity type');
		}

		this._store.dispatch(load({ entityType: this._entityType }));

		this.subscription.add(
			(this._entityType === CustomFieldEntityType.Contact
				? this._store.select(selectContactCalculationVariableRecords)
				: this._store.select(selectMatterCalculationVariableRecords)
			)
				.pipe(
					tap(variables => {
						if (!!arraysEqual(this._oldVariables, variables)) {
							return;
						}

						this._oldVariables = variables;

						const mappedVariables = (variables ?? [])
							.filter(
								variable =>
									isNil(variable.fieldType) || variable.fieldType !== CustomFieldType.Calculated
							)
							.map(variable => {
								const id = variable.isCustomField ? `CustomField.${variable.name}` : variable.name;
								const name = (
									variable.isCustomField
										? `CustomField__${variable.name.replace(/ /g, '_')}`
										: variable.name
								).replace(/\./g, '__');

								return {
									...variable,
									id: id,
									name: name
								};
							});

						if (!!arraysEqual(this._variables$.value, mappedVariables)) {
							return;
						}

						this._variables$.next(mappedVariables);
					})
				)
				.subscribe()
		);
	}

	inputClicked() {
		this._store.dispatch(load({ entityType: this._entityType }));

		this.subscription.add(
			this.latestVariables$().subscribe(variables => {
				this.options = variables;
				this.trigger?.openPanel();
			})
		);
	}

	optionHtmlText(input: CalculationVariableListItemDtoEx): string {
		return input.name;
	}

	displayText(value: CalculationVariableListItemDtoEx) {
		return value.name;
	}

	lookup(id: string): Observable<CalculationVariableListItemDtoEx> {
		this._store.dispatch(load({ entityType: this._entityType }));

		return this.latestVariables$().pipe(map(variables => variables?.filter(variable => variable.id == id)[0]));
	}

	search(term: string): Observable<CalculationVariableListItemDtoEx[]> {
		this._store.dispatch(load({ entityType: this._entityType }));

		const lowercaseTerm = term?.toLowerCase() ?? '';

		const terms = lowercaseTerm.split(/(\s+)/).filter(match => !!match?.trim()?.length);

		return this.latestVariables$().pipe(
			map(variables =>
				variables
					?.filter(
						variable =>
							terms.filter(term => variable.name.toLowerCase().includes(term)).length == terms.length
					)
					?.sort((op1, op2) => {
						let comp1 = op1.name.toLowerCase().indexOf(lowercaseTerm);
						if (comp1 < 0) {
							comp1 = 9999;
						}

						let comp2 = op2.name.toLowerCase().indexOf(lowercaseTerm);
						if (comp2 < 0) {
							comp2 = 9999;
						}

						if (comp1 < comp2) {
							return -1;
						}

						if (comp1 > comp2) {
							return 1;
						}

						return op1.name.localeCompare(op2.name);
					})
			)
		);
	}

	setFocus() {
		if (this.inputCtrl != null) {
			setTimeout(() => {
				this.inputCtrl.nativeElement.focus();
			}, 0);
		}
	}

	searchIfTermEmpty(): boolean {
		return true;
	}

	private latestVariables$() {
		const currentValue = this._variables$.value;

		return !!currentValue
			? of(currentValue)
			: combineLatest([this._variables$.asObservable()]).pipe(map(([variables]) => variables));
	}
}

class CalculationVariableListItemDtoEx extends BaseDtoWithTrimmedId implements CalculationVariableListItemDto {
	name: string;
	fieldType: string;
	isCustomField: boolean;
}
