import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';

import { RecurringInvoiceDto } from '@common/models/Infrastructure/StripeIntegration/Models/RecurringInvoiceDto';
import { UpcomingInvoiceDto } from '@common/models/Infrastructure/StripeIntegration/Models/UpcomingInvoiceDto';
import { OverlappingModuleDto } from '@common/models/Settings/Modules/Item/OverlappingModuleDto';
import { StripeInfo } from '@common/models/Settings/Setting/Item/StripeInfo';
import { NotificationService } from '@common/notification';
import { ModuleService } from '@common/services/module.service';
import { StripeService } from '@common/services/stripe.service';
import { CustomValidators } from '@common/validation/custom.validators';
import { uniq } from 'lodash-es';

import { BaseStripeInfoComponent } from './common/base-stripe-info.component';

@Component({
	selector: 'change-subscription-dialog',
	styleUrls: ['./change-subscription-dialog.component.scss'],
	templateUrl: 'change-subscription-dialog.component.html'
})
export class ChangeSubscriptionDialogComponent extends BaseStripeInfoComponent implements OnInit, OnDestroy {
	upcomingInvoice: UpcomingInvoiceDto;
	recurringInvoice: RecurringInvoiceDto;
	form: FormGroup;
	overlappingModules: OverlappingModuleDto[] = [];

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: IChangeSubscriptionDialogData,
		private _stripeService: StripeService,
		private _notifService: NotificationService,
		private _fb: FormBuilder,
		private _dialogRef: MatDialogRef<ChangeSubscriptionDialogComponent>,
		private _moduleService: ModuleService,
		private _router: Router
	) {
		super({ stripeInfo: data.currentStripeInfo });
	}

	get isFormValid(): boolean {
		if (this.form.invalid) return false;
		else if (this.isChangingProduct) return true;
		else if (this.isChangingPaymentInterval) return true;
		else if (this.quantity !== this.oldQuantity) return true;
		else return false;
	}

	get isChangingSubscriptionModel(): boolean {
		return this.isChangingProduct || this.isChangingPaymentInterval;
	}

	get isChangingPaymentInterval(): boolean {
		return this.data.paymentInterval !== this.data.oldPaymentInterval;
	}

	get isChangingProduct(): boolean {
		return !!this.oldProductName ? this.oldProductName !== this.newProductName : false;
	}

	get isChangingQuantity(): boolean {
		return !!this.oldQuantity;
	}

	get quantity(): number {
		return this.form?.controls?.quantity?.value;
	}

	get oldQuantity(): number {
		return this.data?.oldQuantity;
	}

	get oldProductName(): string {
		return this.data?.oldProductName;
	}

	get newProductName(): string {
		return this.data?.newProductName;
	}

	get numberOfActiveUsers(): number {
		return this.data?.numberOfActiveUsers ?? 0;
	}

	get interval(): string {
		return this.displayInterval(this.data?.paymentInterval);
	}

	get oldInterval(): string {
		return this.displayInterval(this.data?.oldPaymentInterval);
	}

	get oldSubscriptionModelText(): string {
		const text = [];
		if (this.isChangingProduct) text.push(this.oldProductName);
		if (this.isChangingPaymentInterval) text.push(this.oldInterval);
		return text.join(' ');
	}

	get newSubscriptionModelText(): string {
		const text = [];
		if (this.isChangingProduct) text.push(this.newProductName);
		if (this.isChangingPaymentInterval) text.push(this.interval);
		return text.join(' ');
	}

	get overlappingFeatures(): string[] {
		return uniq(this.overlappingModules.map(x => x.overlappingFeatures.toString()));
	}

	get overlappingModulesNameString() {
		return this.overlappingModules
			.map(x => {
				return x.name + ' Module';
			})
			.join(', ');
	}

	private displayInterval(interval: 'month' | 'year') {
		if (!interval) return '';
		return interval === 'month' ? 'monthly' : 'yearly';
	}

	private get previewSubscription$() {
		this.StartLoadingState('Preparing Invoice Preview...');
		return this._stripeService.previewSubscriptionChange(this.data.priceId, this.quantity).pipe(
			tap(preview => {
				this.StopLoadingState();
				this.upcomingInvoice = preview?.upcomingCosts;
				this.recurringInvoice = preview?.recurringCosts;
			})
		);
	}

	ngOnInit(): void {
		this.form = this._fb.group({
			quantity: [this.data?.quantity, CustomValidators.required('Quantity')]
		});

		this.subscriptions.add(
			this.form
				.get('quantity')
				.valueChanges.pipe(
					filter(value => {
						return value > 0;
					}),
					debounceTime(200),
					distinctUntilChanged(),
					switchMap(() => {
						if (!this.isChangingQuantity) this.data.oldQuantity = this.data.quantity;
						return this.previewSubscription$;
					})
				)
				.subscribe({
					error: error => {
						this._notifService.showErrors('Error fetching invoice', error);
					}
				})
		);

		if (this.isSubscriptionCancelled) {
			this.showSubscriptionRequiredMessageBox();
		} else {
			this.subscriptions.add(
				this.previewSubscription$.subscribe({
					error: error => {
						this._notifService.showErrors('Error fetching invoice', error);
					}
				})
			);

			if (!!this.data.moduleId) {
				this.subscriptions.add(
					this._moduleService
						.findOverlappingFeatures(this.data.moduleId)
						.pipe(
							map(modules =>
								modules.filter(x => x.tenantHasActiveSubscription === true && x.type === 'Marketplace')
							)
						)
						.subscribe(x => {
							this.overlappingModules = x;
						})
				);
			}
		}
	}

	private showSubscriptionRequiredMessageBox() {
		this._dialogRef.addPanelClass('mat-dialog-hide');
		this.subscriptions.add(
			this._notifService
				.showCallToAction(
					'Subscription Required',
					`<p>An active subscription to <b>${this.siteName}</b> is required.</p>`,
					'Renew ' + this.siteName + ' subscription',
					'Cancel'
				)
				.subscribe((isConfirmed: boolean) => {
					if (isConfirmed) {
						if (this.isSubscriptionCancelled) {
							this.subscriptions.add(
								this._stripeService
									.customerPortal({ returnURL: '/system/subscription' })
									.subscribe(customerPortalURL => {
										window.location.href = customerPortalURL;
									})
							);
						} else {
							this._router.navigate(['system', 'subscription']);
						}
					} else {
						this._dialogRef.close(false);
					}
				})
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	confirmSubscription() {
		this.StartLoadingState('Processing Payment...');
		this.subscriptions.add(
			this._stripeService.changeSubscription(this.data.priceId, this.quantity).subscribe(
				() => {
					this._dialogRef.close(true);
				},
				err => {
					this._notifService.showErrors('Error changing subscription', err);
				}
			)
		);
	}
}

export interface IChangeSubscriptionDialogData {
	currentStripeInfo: StripeInfo;
	moduleId: string;
	priceId: string;
	oldProductName: string;
	newProductName: string;
	quantity: number;
	oldQuantity: number;
	numberOfActiveUsers: number;
	paymentInterval: 'month' | 'year';
	oldPaymentInterval: 'month' | 'year';
}
