import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { filter, switchMap } from 'rxjs/operators';

import { MutationResponseDto } from '@common/models/Common/MutationResponseDto';
import { DocumentTagDto } from '@common/models/Settings/DocumentTags/Item/DocumentTagDto';
import { DocumentTagListItemDto } from '@common/models/Settings/DocumentTags/List/DocumentTagListItemDto';
import { DocumentTagListRequest } from '@common/models/Settings/DocumentTags/List/DocumentTagListRequest';
import { DomainError } from '@common/models/Validation/DomainError';
import { NotificationService } from '@common/notification';
import { DocumentTagService } from '@common/services/settings/documenttag.service';
import { Store } from '@ngrx/store';

import { IAppState } from 'app/core/state/app.state';
import { DocumentTagListActions } from 'app/core/state/lists/settings/document-tag-list/document-tag-list.actions';
import {
	selectDocumentTagIsFetching,
	selectDocumentTagListRecords,
	selectDocumentTagListRequest,
	selectDocumentTagListTotalRecords
} from 'app/core/state/lists/settings/document-tag-list/document-tag-list.selectors';
import { GridViewService } from 'app/services/grid-view.service';
import {
	ActionTypes,
	GenericListStateComponent,
	SelectorTypes
} from 'app/shared/generics/generic.list.state.component';

import { DocumentTagDialogComponent } from '../document-tag-dialog/document-tag-dialog.component';
import { DocumentTagListFilterDialogComponent } from '../filter-dialog/document-tag-list-filter-dialog.component';
import { DocumentTagListFilterComponent } from '../filter/document-tag-list-filter.component';

@Component({
	selector: 'document-tag-list',
	styleUrls: ['./document-tag-list.component.scss'],
	templateUrl: './document-tag-list.component.html'
})
export class DocumentTagListComponent
	extends GenericListStateComponent<DocumentTagListItemDto, DocumentTagListRequest>
	implements OnInit
{
	get actions(): ActionTypes {
		return {
			init: DocumentTagListActions.Init,
			load: DocumentTagListActions.Load,
			setFilters: DocumentTagListActions.SetFilters,
			setPageIndex: DocumentTagListActions.SetPageIndex,
			setPageIndexForId: DocumentTagListActions.SetPageIndexForId,
			setPageSize: DocumentTagListActions.SetPageSize,
			setSortBy: DocumentTagListActions.SetSortBy,
			setSortDirection: DocumentTagListActions.SetSortDirection,
			selected: DocumentTagListActions.SelectRecords
		};
	}
	get selectors(): SelectorTypes {
		return {
			records: selectDocumentTagListRecords,
			isFetching: selectDocumentTagIsFetching,
			request: selectDocumentTagListRequest,
			totalRecords: selectDocumentTagListTotalRecords
		};
	}
	@ViewChild(DocumentTagListFilterComponent, { static: true })
	filter: DocumentTagListFilterComponent;
	request: Partial<DocumentTagListRequest> = {
		practiceAreaIds: [],
		search: null
	};
	filterDialog = DocumentTagListFilterDialogComponent;

	constructor(
		store: Store<IAppState>,
		dialog: MatDialog,
		router: Router,
		activatedRoute: ActivatedRoute,
		private documentTagService: DocumentTagService,
		private notificationService: NotificationService,
		gridViewService: GridViewService
	) {
		super(['name', 'practiceAreas', 'actions'], dialog, store, router, activatedRoute, gridViewService);
	}

	ngOnInit() {
		super.ngOnInit();
		this.filterComponent = this.filter.root;
	}

	createDocumentTag() {
		this.subscriptions.add(
			this.dialog
				.open(DocumentTagDialogComponent)
				.afterClosed()
				.pipe(
					filter(Boolean),
					switchMap((data: DocumentTagDto) => this.documentTagService.createDocumentTag(data))
				)
				.subscribe(this.onCreateSuccess, this.onError)
		);
	}

	editDocumentTag(row: DocumentTagListItemDto) {
		this.subscriptions.add(
			this.dialog
				.open(DocumentTagDialogComponent, { data: row.id })
				.afterClosed()
				.pipe(
					filter(Boolean),
					switchMap((data: DocumentTagDto) => {
						return this.documentTagService.updateDocumentTag(data.id, data);
					})
				)
				.subscribe(this.onUpdateSuccess, this.onError)
		);
	}

	deleteDocumentTag(row: DocumentTagListItemDto) {
		this.subscriptions.add(
			this.notificationService
				.showConfirmation(
					'Delete Document Tag',
					`Are you sure you want to delete the document tag "${row.name}"?`
				)
				.pipe(
					filter(Boolean),
					switchMap(() => this.documentTagService.deleteDocumentTag(row.id))
				)
				.subscribe({
					next: response => {
						this.notificationService.showNotification(`Document Tag deleted: ${response.name}`);
						this.store.dispatch({ type: DocumentTagListActions.RemoveRecords, response: response });
					},
					error: error => this.notificationService.showErrors('Error deleting Document Tag', error)
				})
		);
	}
	private onError = (errors: DomainError[]) => {
		this.notificationService.showErrors('Error', errors);
	};

	private onCreateSuccess = (result: MutationResponseDto) => {
		console.log('result', result);
		this.notificationService.showNotification(`Document Tag ${result.name} created`);
		this.store.dispatch({ type: DocumentTagListActions.InsertRecords, response: result });
	};

	private onUpdateSuccess = (result: MutationResponseDto) => {
		console.log('result', result);
		this.notificationService.showNotification(`Document Tag ${result.name} updated`);
		this.store.dispatch({ type: DocumentTagListActions.UpdateRecords, response: result });
	};
}
